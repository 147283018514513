{
  "1inch-logo": "Logotip 1inch",
  "aave-logo": "Logotip Aave",
  "about": "Vizitka",
  "about-ethereum-org": "O ethereum.org",
  "about-us": "O nas",
  "alt-eth-blocks": "Illustration of blocks being organized like an ETH symbol",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Nazaj na vrh",
  "banner-page-incomplete": "Ta stran je nepopolna. Če ste strokovnjak za to temo, uredite stran in ji dodajte svojo modrost.",
  "beacon-chain": "Oddajniška veriga",
  "binance-logo": "Logotip Binance",
  "bittrex-logo": "Logotip Bittrex",
  "brand-assets": "Gradivo znamke",
  "bug-bounty": "Nagrade za odpravljanje napak",
  "coinbase-logo": "Logotip Coinbase",
  "coinmama-logo": "Logotip Coinmama",
  "community": "Skupnost",
  "community-menu": "Community Menu",
  "compound-logo": "Logotip Compound",
  "cons": "Slabosti",
  "contact": "Stik",
  "content-versions": "Content Versions",
  "contributing": "Prispevajte",
  "contributors": "Sodelavci",
  "contributors-thanks": "Vsi, ki ste prispevali k tej strani – hvala!",
  "cookie-policy": "Pravilnik o piškotkih",
  "copied": "Kopirano",
  "copy": "Kopiraj",
  "dark-mode": "Temni način",
  "data-provided-by": "Ponudnik podatkov",
  "decentralized-applications-dapps": "Decentralizirane aplikacije (dapps)",
  "deposit-contract": "Pogodba za polog",
  "devcon": "Devcon",
  "developers": "Razvijalci",
  "developers-home": "Začetna stran za razvijalce",
  "docs": "Dokumenti",
  "documentation": "Dokumentacija",
  "dydx-logo": "Logotip Dydx",
  "ecosystem": "Ekosistem",
  "edit-page": "Uredi stran",
  "ef-blog": "Spletni dnevnik ustanove Ethereum Foundation",
  "eips": "Predlogi za izboljšanje Ethereuma",
  "enterprise": "Za podjetja",
  "enterprise-menu": "Meni za podjetja",
  "esp": "Program za podporo ekosistema",
  "eth-current-price": "Trenutna cena ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Odprtokodni pregledovalnik oddajniške verige Eth2",
  "eth2-beaconscan-desc": "Pregledovalnik oddajniške verige Eth2 – Etherscan za Eth2",
  "eth2-become-staker": "Postanite zastavljavec",
  "eth2-become-staker-desc": "Zastavljanje je na voljo. Če želite zastaviti svoj ETH in pomagati zaščititi omrežje, poskrbite, da se zavedate tveganj.",
  "eth2-explore": "Raziščite podatke",
  "eth2-no-action-needed": "Z ETH-jem, ki ga že hranite, vam ni treba storiti nič. Bodite pozorni na prevarante, ki bodo želeli, da jim pošljete ETH, če ga želite zamenjati.",
  "eth2-run-beacon-chain": "Izvajajte oddajniškega odjemalca",
  "eth2-run-beacon-chain-desc": "Ethereum potrebuje čim več aktivnih odjemalcev. Pomagajte v javno dobro Ethereuma.",
  "eth2-service-announcement": "Obvestilo o storitvi Eth2",
  "eth2-what-shipping": "Kdaj bo na voljo?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Gradivo blagovne znamke Ethereum",
  "ethereum-community": "Skupnost za Ethereum",
  "ethereum-foundation": "Ethereum Foundation",
  "ethereum-foundation-logo": "Logotip za Ethereum Foundation",
  "ethereum-glossary": "Slovar za Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Logotip Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Ethereum Studio",
  "ethereum-wallets": "Denarnice Ethereum",
  "ethereum-whitepaper": "Bela knjiga o Ethereumu",
  "example-projects": "Primeri projektov",
  "find-wallet": "Poišči denarnico",
  "foundation": "Fundacija",
  "gemini-logo": "Logotip Gemini",
  "get-eth": "Pridobite ETH",
  "get-involved": "Sodelujte",
  "get-started": "Začnite",
  "gitcoin-logo": "Logotip Gitcoin",
  "glossary": "Slovar",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Vodniki in viri",
  "history": "Zgodovina",
  "history-of-ethereum": "Zgodovina Ethereuma",
  "home": "Začetna stran",
  "how-ethereum-works": "Kako deluje Ethereum",
  "image": "slika",
  "in-this-section": "V tem razdelku",
  "individuals": "Posamezniki",
  "individuals-menu": "Meni za posameznike",
  "jobs": "Jobs",
  "kraken-logo": "Logotip Kraken",
  "language-ar": "Arabic",
  "language-bg": "Bulgarian",
  "language-bn": "Bengali",
  "language-ca": "Catalan",
  "language-cs": "Czech",
  "language-de": "German",
  "language-el": "Greek",
  "language-en": "English",
  "language-es": "Spanish",
  "language-fa": "Farsi",
  "language-fi": "Finnish",
  "language-fr": "French",
  "language-hu": "Hungarian",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonesian",
  "language-ig": "Igbo",
  "language-it": "Italian",
  "language-ja": "Japanese",
  "language-ko": "Korean",
  "language-lt": "Lithuanian",
  "language-ml": "Malayalam",
  "language-nb": "Norwegian",
  "language-nl": "Dutch",
  "language-pl": "Polish",
  "language-pt": "Portuguese",
  "language-pt-br": "Portuguese (Brazilian)",
  "language-ro": "Romanian",
  "language-ru": "Russian",
  "language-se": "Swedish",
  "language-sk": "Slovak",
  "language-sl": "Slovenian",
  "language-support": "Jezikovna podpora",
  "language-tr": "Turkish",
  "language-uk": "Ukrainian",
  "language-vi": "Vietnamese",
  "language-zh": "Chinese Simplified",
  "language-zh-tw": "Chinese Traditional",
  "languages": "Jeziki",
  "last-24-hrs": "Zadnjih 24 ur",
  "last-edit": "Nazadnje urejeno",
  "learn": "Učenje",
  "learn-by-coding": "Učenje s kodiranjem",
  "learn-menu": "Meni za učenje",
  "learn-more": "Več informacij",
  "less": "Manj",
  "light-mode": "Svetli način",
  "listing-policy-disclaimer": "Izdelki, navedeni na tej strani, ne predstavljajo uradne podpore in so na voljo zgolj za informativne namene. Če želite dodati izdelek ali deliti povratne informacije o pravilniku, ustvarite novo zadevo na spletnem mestu GitHub.",
  "listing-policy-raise-issue-link": "Ustvari zadevo",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Nalaganje ...",
  "loading-error": "Napaka pri nalaganju.",
  "loading-error-refresh": "Napaka pri nalaganju podatkov. Poskusite osvežiti stran.",
  "logo": "logotip",
  "loopring-logo": "Logotip Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Glavno omrežje Ethereum",
  "makerdao-logo": "Logotip MakerDao",
  "matcha-logo": "Logotip Matcha",
  "merge": "Merge",
  "more": "Več",
  "nav-beginners": "Začetniki",
  "next": "Naprej",
  "oasis-logo": "Logotip Oasis",
  "on-this-page": "Na tej strani",
  "page-content": "Vsebina strani",
  "page-enterprise": "Za podjetja",
  "page-last-updated": "Zadnja posodobitev strani",
  "previous": "Nazaj",
  "privacy-policy": "Pravilnik o zasebnosti",
  "private-ethereum": "Zasebni Ethereum",
  "pros": "Prednosti",
  "read-more": "Preberite več",
  "refresh": "Osvežite stran.",
  "review-progress": "Pregled napredka",
  "search": "Iskanje",
  "search-box-blank-state-text": "Začnite iskati!",
  "search-eth-address": "To je videti kot naslov Ethereum. Ne zagotavljamo podatkov za posamezne naslove. Poskusite ga poiskati v pregledovalniku blokov, kot je",
  "search-no-results": "Ni rezultatov iskanja",
  "security": "Security",
  "see-contributors": "Pokaži sodelavce",
  "set-up-local-env": "Nastavitev lokalnega okolja",
  "shard-chains": "Razdrobljene verige",
  "show-all": "Prikaži vse",
  "show-less": "Prikaži manj",
  "site-description": "Ethereum je globalna, decentralizirana platforma za denar in nove vrste aplikacij. V Ethereumu lahko pišete programsko kodo, ki upravlja denar, in gradite aplikacije, ki so dostopne povsod po svetu.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stabilni kovanci",
  "staking": "Zastavljanje",
  "summary": "Povzetek",
  "terms-of-use": "Pogoji uporabe",
  "transaction-fees": "Kaj so provizije za transakcijo?",
  "translation-banner-body-new": "To stran vidite v angleščini, ker je še nismo prevedli. Pomagajte nam prevesti to vsebino.",
  "translation-banner-body-update": "Na voljo je nova različica te strani, vendar je zaenkrat samo v angleščini. Pomagajte nam prevesti najnovejšo različico.",
  "translation-banner-button-join-translation-program": "Pridružite se programu prevajanja",
  "translation-banner-button-learn-more": "Več informacij",
  "translation-banner-button-see-english": "Oglejte si angleško različico",
  "translation-banner-button-translate-page": "Prevedi stran",
  "translation-banner-title-new": "Pomagajte prevesti to stran",
  "translation-banner-title-update": "Pomagajte posodobiti to stran",
  "translation-program": "Translation Program",
  "translation-progress": "Napredek prevajanja",
  "tutorials": "Vadnice",
  "uniswap-logo": "Logotip Uniswap",
  "use": "Uporaba",
  "use-ethereum": "Uporaba Ethereuma",
  "use-ethereum-menu": "Meni Uporaba Ethereuma",
  "vision": "Vizija",
  "wallets": "Denarnice",
  "website-last-updated": "Zadnja posodobitev spletnega mesta",
  "what-is-ether": "Kaj je ether (ETH)?",
  "what-is-ethereum": "Kaj je Ethereum?",
  "whitepaper": "Bela knjiga",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Zahtevajte funkcijo",
  "page-about-h3": "V delu",
  "page-about-h3-1": "Izvedene funkcije",
  "page-about-h3-2": "Načrtovane funkcije",
  "page-about-li-1": "v teku",
  "page-about-li-2": "načrtovano",
  "page-about-li-3": "izvedeno",
  "page-about-li-4": "izvedeno",
  "page-about-link-1": "Izvorna koda tega skladišča je licencirana na podlagi licence MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Oglejte si celoten seznam opravil, ki potekajo na Githubu",
  "page-about-link-4": "Pridružite se našemu strežniku Discord",
  "page-about-link-5": "Obrnite se na nas na Twitterju",
  "page-about-link-6": "Oglejte si celoten seznam izvedenih opravil na Githubu",
  "page-about-link-7": "Ustvarite zadevo na Githubu",
  "page-about-p-1": "Že od ustanovitve ethereum.org si prizadevamo za preglednost pri svojem delovanju. To je ena naših temeljnih vrednot, ker menimo, da je preglednost ključnega pomena za uspeh Ethereuma.",
  "page-about-p-2": "Uporabljamo",
  "page-about-p-3": "kot glavno orodje za upravljanje projektov. Naša opravila organiziramo v 3 kategorije:",
  "page-about-p-4": "  Po svojih najboljših močeh obveščamo skupnost o stanju določenega opravila.",
  "page-about-p-5": "Opravila, ki jih izvajamo.",
  "page-about-p-6": "Opravila, ki smo jih postavili v čakalno vrsto in bodo naslednja na vrsti za izvedbo.",
  "page-about-p-7": "Nedavno končana opravila.",
  "page-about-p-8": "Imate zasmisel, kako izboljšati ethereum.org? Želimo sodelovati z vami.",
  "page-assets-bazaar": "Bazar Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Gradniki",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge uporablja decentralizirane aplikacije",
  "page-assets-download-artist": "Umetnik:",
  "page-assets-download-download": "Prenos",
  "page-assets-enterprise": "Ethereum za podjetja",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "ETH diamant (barven)",
  "page-assets-eth-diamond-glyph": "ETH diamant (glif)",
  "page-assets-eth-diamond-gray": "ETH diamant (siv)",
  "page-assets-eth-diamond-purple": "ETH diamant (vijoličen)",
  "page-assets-eth-diamond-white": "ETH diamant (bel)",
  "page-assets-eth-glyph-video-dark": "Video glifa ETH (temen)",
  "page-assets-eth-glyph-video-light": "Video glifa ETH (svetel)",
  "page-assets-eth-logo-landscape-gray": "Ležeče usmerjen logotip ETH (siv)",
  "page-assets-eth-logo-landscape-purple": "Ležeče usmerjen logotip ETH (vijoličen)",
  "page-assets-eth-logo-landscape-white": "Ležeče usmerjen logotip ETH (bel)",
  "page-assets-eth-logo-portrait-gray": "Pokončno usmerjen logotip ETH (siv)",
  "page-assets-eth-logo-portrait-purple": "Pokončno usmerjen logotip ETH (vijoličen)",
  "page-assets-eth-logo-portrait-white": "Pokončno usmerjen logotip ETH (bel)",
  "page-assets-eth-wordmark-gray": "Besedni znak ETH (siv)",
  "page-assets-eth-wordmark-purple": "Besedni znak ETH (vijoličen)",
  "page-assets-eth-wordmark-white": "Besedni znak ETH (bel)",
  "page-assets-ethereum-brand-assets": "Gradivo »blagovne znamke« Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "Gradivo ethereum.org",
  "page-assets-hero": "Glavna pasica ethereum.org",
  "page-assets-hero-particles": "Delci ETH, slika",
  "page-assets-historical-artwork": "Zgodovinsko umetniško delo",
  "page-assets-illustrations": "Ilustracije",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Raziščite in prenesite gradivo, ilustracije in medije blagovne znamke Ethereum in ethereum.org.",
  "page-assets-meta-title": "Gradivo blagovne znamke Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Enobarvno ozadje",
  "page-assets-page-assets-transparent-background": "Prozorno ozadje",
  "page-assets-robot": "Robotska denarnica",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "Logotip 1inch",
  "page-dapps-aave-logo-alt": "Logotip Aave",
  "page-dapps-add-button": "Predlagaj dapp",
  "page-dapps-add-title": "Dodajanje decentralizirane aplikacije",
  "page-dapps-audius-logo-alt": "Logotip Audius",
  "page-dapps-augur-logo-alt": "Logotip Augur",
  "page-dapps-axie-infinity-logo-alt": "Logotip Axie Infinity",
  "page-dapps-brave-logo-alt": "Logotip Brave",
  "page-dapps-category-arts": "Umetnost in moda",
  "page-dapps-category-browsers": "Brskalniki",
  "page-dapps-category-collectibles": "Digitalni zbirateljski predmeti",
  "page-dapps-category-competitive": "Tekmovanje",
  "page-dapps-category-computing": "Orodja za razvijalce",
  "page-dapps-category-dex": "Menjave žetonov",
  "page-dapps-category-investments": "Naložbe",
  "page-dapps-category-lending": "Kreditiranje in posojila",
  "page-dapps-category-lottery": "Množično financiranje",
  "page-dapps-category-marketplaces": "Tržišča",
  "page-dapps-category-music": "Glasba",
  "page-dapps-category-payments": "Plačila",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Trgovanje in napovedni trgi",
  "page-dapps-category-utilities": "Pripomočki",
  "page-dapps-category-worlds": "Virtualni svetovi",
  "page-dapps-choose-category": "Izberite kategorijo",
  "page-dapps-collectibles-benefits-1-description": "Ko se umetnost na Ethereumu pretvori v žetone, je mogoče dokazati lastništvo, kar lahko vidijo vsi. Potovanju svoje umetnine lahko sledite vse od stvaritve do trenutnega imetnika. To preprečuje ponaredke.",
  "page-dapps-collectibles-benefits-1-title": "Lastništvo je mogoče dokazati",
  "page-dapps-collectibles-benefits-2-description": "Plačevanje za pretočno predvajanje glasbe ali kupovanje umetnin je precej bolj pošteno do izvajalcev in umetnikov. Z Ethereumom je potrebe po posrednikih manjša, če pa so potrebni, njihovi stroški niso tako visoki, saj platformam ni potrebno plačati infrastrukture omrežja.",
  "page-dapps-collectibles-benefits-2-title": "Bolj pošteno do ustvarjalcev",
  "page-dapps-collectibles-benefits-3-description": "Zbirateljski predmeti v obliki žetonov so vezani na naslov Ethereum, ne na platformo. Tako lahko stvari, kot so elementi v igrah, prodate na poljubnem trgu za Ethereum, ne samo v igri sami.",
  "page-dapps-collectibles-benefits-3-title": "Zbirateljski predmeti gredo z vami",
  "page-dapps-collectibles-benefits-4-description": "Orodja in strani, s katerimi lahko svojo umetnost pretvorite v obliko žetonov in prodate, že obstajajo. Svoje žetone lahko prodate na prav vseh platformah z zbirateljskimi predmeti Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Infrastruktura je že vzpostavljena",
  "page-dapps-collectibles-benefits-description": "To so aplikacije, ki so osredotočene na digitalno lastništvo in povečujejo potencialne prihodke ustvarjalcev ter izumljajo nove načine vlaganja v priljubljene ustvarjalce in njihovo delo.",
  "page-dapps-collectibles-benefits-title": "decentralizirani zbirateljski predmeti in pretočno predvajanje",
  "page-dapps-collectibles-button": "Umetnost in zbirateljski predmeti",
  "page-dapps-collectibles-description": "To so aplikacije, ki so osredotočene na digitalno lastništvo in povečujejo potencialne prihodke ustvarjalcev ter izumljajo nove načine vlaganja v priljubljene ustvarjalce in njihovo delo.",
  "page-dapps-collectibles-title": "Decentralizirane umetnine in zbirateljski predmeti",
  "page-dapps-compound-logo-alt": "Logotip Compound",
  "page-dapps-cryptopunks-logo-alt": "Logotip CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Logotip Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Pomaga vam, da se izognete visokemu odstopanju med cenami s skupnim seštevkom najboljših cen.",
  "page-dapps-dapp-description-aave": "Posojajte svoje žetone in si prislužite obresti ter kadarkoli opravite dvig.",
  "page-dapps-dapp-description-async-art": "Ustvarjajte, zbirajte in trgujte #ProgramabilnoUmetnost – digitalne risbe, razdeljene v »plasti«, ki jih lahko dodajate skupni sliki. Vsaka matrica in plast je ERC721 žeton.",
  "page-dapps-dapp-description-audius": "Decentralizirana platforma za pretočno predvajanje. Poslušanje = denar za ustvarjalce, ne založbe.",
  "page-dapps-dapp-description-augur": "Stavite na rezultate športnih, gospodarskih in drugih svetovnih dogodkov.",
  "page-dapps-dapp-description-axie-infinity": "Trgujte in se bojujte z bitji, imenovanimi Axieji. Služite z igranjem – na voljo za mobilne naprave",
  "page-dapps-dapp-description-brave": "Služite žetone z brskanjem in z njimi podpirajte svoje najljubše ustvarjalce.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Posojajte svoje žetone in služite obresti ter kadarkoli opravite dvig.",
  "page-dapps-dapp-description-cryptopunks": "Kupujte, oddajajte ponudbe in prodajajte pankerje – eni izmed prvih zbirateljskih predmetov v obliki žetonov na Ethereumu.",
  "page-dapps-dapp-description-cryptovoxels": "Ustvarjajte umetnostne galerije, postavljajte trgovine in kupujte zemljišča – virtualni svet na Ethereumu.",
  "page-dapps-dapp-description-dark-forest": "Osvajajte planete v neskončnem vesolju, ki je postopkovno generirano in kriptografsko določeno.",
  "page-dapps-dapp-description-decentraland": "Zbirajte virtualna zemljišča v in trgujte z njimi v virtualnem svetu, ki ga lahko raziskujete.",
  "page-dapps-dapp-description-dydx": "Odprite kratke pozicije ali pozicije z največ 10-kratnim vzvodom. Na voljo so tudi kreditiranje in posojila.",
  "page-dapps-dapp-description-ens": "Uporabnikom prijazna imena za naslove Ethereum in decentralizirana spletna mesta.",
  "page-dapps-dapp-description-foundation": "Vlagajte v edinstvene izdaje digitalnih umetnin in z izdelki trgujte z drugimi kupci.",
  "page-dapps-dapp-description-gitcoin": "Zaslužite kriptovalute s sodelovanjem na odprtokodni programski opremi.",
  "page-dapps-dapp-description-gitcoin-grants": "Množično financiranje projektov skupnosti Ethereum s povečanimi prispevki.",
  "page-dapps-dapp-description-gods-unchained": "Strateška igra z zbirateljskimi karticami. Z igranjem zaslužite kartice, ki jih lahko prodate v resničnem življenju.",
  "page-dapps-dapp-description-golem": "Dostopajte do računalniške zmogljivosti ali oddajte svoje vire.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Platforma za trgovanje v omrežju enakovrednih uporabnikov, zasnovana za hitrost.",
  "page-dapps-dapp-description-marble-cards": "Ustvarjajte edinstvene digitalne kartice na podlagi URL-jev in trgujte z njimi.",
  "page-dapps-dapp-description-matcha": "Išče po številnih borzah in vam pomaga najti najboljše cene.",
  "page-dapps-dapp-description-nifty-gateway": "Na verigi kupujte izdelke vrhunskih umetnikov, športnikov, blagovnih znamk in ustvarjalcev.",
  "page-dapps-dapp-description-oasis": "Trgujte s stabilnim kovancem Ethereum, imenovanim Dai, si izposojajte in varčujte z njim.",
  "page-dapps-dapp-description-opensea": "Kupujte, prodajajte, odkrivajte in trgujte z omejenimi izdajami izdelkov.",
  "page-dapps-dapp-description-opera": "V brskalniku pošiljajte kriptovalute trgovcem, drugim uporabnikom in aplikacijam.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Stavite na rezultate. Trgujte na informacijskih trgih.",
  "page-dapps-dapp-description-pooltogether": "Loterija, kjer ne morete izgubiti. Nagrade vsak teden.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Ustvarjajte, prodajajte in kupujte zbirateljske predmete v obliki žetonov.",
  "page-dapps-dapp-description-sablier": "Sproti pretočno predvajajte denar.",
  "page-dapps-dapp-description-superrare": "Kupujte digitalne umetnine neposredno od umetnikov ali na sekundarnih trgih.",
  "page-dapps-dapp-description-token-sets": "Strategije vlaganja v kriptovalute, ki se samodejno znova uravnovesijo.",
  "page-dapps-dapp-description-tornado-cash": "Pošiljajte anonimne transakcije na Ethereumu.",
  "page-dapps-dapp-description-uniswap": "Preprosto menjajte žetone ali zagotovite žetone za % nagrade.",
  "page-dapps-dark-forest-logo-alt": "Logotip Dark Forest",
  "page-dapps-decentraland-logo-alt": "Logotip Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Poiščite aplikacijo Ethereum in jo preizkusite.",
  "page-dapps-doge-img-alt": "Slika psa »doge«, ki uporablja računalnik",
  "page-dapps-dydx-logo-alt": "Logotip dYdX",
  "page-dapps-editors-choice-dark-forest": "Igrajte proti drugim in osvajajte planete ter preizkusite vrhunsko tehnologijo za razširljivost in zasebnost Ethereuma. Morda je primernejše za tiste, ki že poznajo Ethereum.",
  "page-dapps-editors-choice-description": "Nekaj decentraliziranih aplikacij, ki jih ekipa ethereum.org trenutno obožuje. Spodaj raziščite več aplikacij.",
  "page-dapps-editors-choice-foundation": "Vlagajte v kulturo. Kupujte, trgujte in prodajajte edinstvene digitalne umetnine in modne izdelke nekaterih neverjetnih umetnikov, glasbenikov in blagovnih znamk.",
  "page-dapps-editors-choice-header": "Izbira urednikov",
  "page-dapps-editors-choice-pooltogether": "Kupite srečko za loterijo, v kateri ne morete izgubiti. Vsak teden se obresti, ki jih proizvede celoten sklad srečk, pošljejo enemu srečnem zmagovalcu. Denar lahko dobite nazaj, kadar želite.",
  "page-dapps-editors-choice-uniswap": "Preprosto zamenjajte svoje žetone. Priljubljena aplikacija v skupnosti, ki omogoča trgovanje žetonov z drugimi v omrežju.",
  "page-dapps-ens-logo-alt": "Logotip Ethereum Name Service",
  "page-dapps-explore-dapps-description": "Mnoge decentralizirane aplikacije so še vedno eksperimentalne in preskušajo možnosti decentraliziranih omrežij. Je pa na trgu nekaj uspešnih zgodnjih projektov v kategorijah tehnologije, financ, igralništva in zbirateljskih predmetov.",
  "page-dapps-explore-dapps-title": "Raziščite dec. aplikacije",
  "page-dapps-features-1-description": "Po uvedbi kode decentraliziranih aplikacij v Ethereum je ni mogoče odstraniti. Prav tako lahko vsak uporablja funkcije decentraliziranih aplikacij. Aplikacijo lahko še vedno uporabljate, tudi če ekipa, ki jo je razvila, razpade. Ko je enkrat na Ethereumu, tam tudi ostane.",
  "page-dapps-features-1-title": "Brez lastnikov",
  "page-dapps-features-2-description": "Vaše uporabe decentralizirane aplikacije ali pošiljanja transakcije ni mogoče blokirati. Če bi Twitter na primer deloval na Ethereumu, nihče ne bi mogel blokirati vašega računa ali tvita.",
  "page-dapps-features-2-title": "Brez cenzure",
  "page-dapps-features-3-description": "Ker Ethereum vključuje ETH, plačila potekajo neposredno v Ethereumu. Razvijalcem ni treba zapravljati časa z integracijo s tretjimi ponudniki plačil.",
  "page-dapps-features-3-title": "Vgrajena plačila",
  "page-dapps-features-4-description": "Koda decentraliziranih aplikacij je pogosto privzeto odprta in združljiva. Ekipe redno razvijajo na podlagi dela drugih ekip. Če želite uporabnikom omogočiti menjavo žetonov v vaši decentralizirani aplikaciji, lahko preprosto dodate kodo druge decentralizirane aplikacije.",
  "page-dapps-features-4-title": "Plug and play",
  "page-dapps-features-5-description": "Pri večini decentraliziranih aplikacij vam ni treba razkriti, kdo ste v resničnem svetu. Račun Ethereum uporabite za prijavo in potrebujete le še denarnico.",
  "page-dapps-features-5-title": "Ena anonimna prijava",
  "page-dapps-features-6-description": "Kriptografija zagotavlja, da napadalci ne morejo ponarejati transakcij in drugih interakcij z decentralizirano aplikacijo v vašem imenu. Dejanja v decentraliziranih aplikacijah pooblastite s svojim računom Ethereum, običajno prek denarnice, zato varno spravite svoje poverilnice.",
  "page-dapps-features-6-title": "Zaščiteno s kriptografijo",
  "page-dapps-features-7-description": "Ko se aplikacija izvaja na Ethereumu, bo prenehala delovati samo, če preneha delovati tudi Ethereum. Omrežja Ethereumove velikosti je izjemno težko napasti.",
  "page-dapps-features-7-title": "Brez prekinitev delovanja",
  "page-dapps-finance-benefits-1-description": "Finančne storitve, ki delujejo na Ethereumu, nimajo zahtev za prijavo. Če imate sredstva in internetno povezavo, imate vse, kar potrebujete.",
  "page-dapps-finance-benefits-1-title": "Odprt dostop",
  "page-dapps-finance-benefits-2-description": "Obstaja celoten svet žetonov, ki jih lahko uporabljate v teh finančnih produktih. Ljudje več čas razvijajo nove žetone na Ethereumu.",
  "page-dapps-finance-benefits-2-title": "Nova ekonomija žetonov",
  "page-dapps-finance-benefits-3-description": "Nekatere ekipe so razvile stabilne kovance – manj nestabilno kriptovaluto. Ti vam bodo omogočali eksperimentiranje in uporabo kriptovalut brez tveganja in negotovosti.",
  "page-dapps-finance-benefits-3-title": "Stablecoins",
  "page-dapps-finance-benefits-4-description": "Vsi finančni produktu v svetu Ethereuma so modularni in združljivi drug z drugim. Na trg ves čas prihajajo nove konfiguracije teh modulov in širijo možnosti uporabe kriptovalut.",
  "page-dapps-finance-benefits-4-title": "Medsebojno povezane finančne storitve",
  "page-dapps-finance-benefits-description": "Kateri vidik Ethereuma omogoča uspešnost decentraliziranih finančnih aplikacij?",
  "page-dapps-finance-benefits-title": "decentralizirane finance",
  "page-dapps-finance-button": "Finance",
  "page-dapps-finance-description": "Te aplikacije se osredotočajo na razvoj finančnih storitev z uporabo kriptovalut. Ponujajo storitve, kot so kreditiranje, posojila, služenje obresti in zasebna plačila – osebni podatki niso potrebni.",
  "page-dapps-finance-title": "Decentralizirane finance",
  "page-dapps-foundation-logo-alt": "Logotip Ethereum Foundation",
  "page-dapps-gaming-benefits-1-description": "Naj gre za virtualna zemljišča ali menjalne karte – s svojimi predmeti lahko trgujete na trgih za združljive predmete. Predmeti v igrah imajo vrednost tudi v resničnem svetu.",
  "page-dapps-gaming-benefits-1-title": "Predmeti v igrah služijo tudi kot žetoni",
  "page-dapps-gaming-benefits-2-description": "Svoje predmete in v nekaterih primerih tudi napredek, imate v lasti vi, ne igričarska podjetja. To pomeni, da ne boste ničesar izgubili, če je podjetje, ki upravlja igro, napadeno, pri njem pride do okvar strežnika ali razpade.",
  "page-dapps-gaming-benefits-2-title": "Shranjene igre so na varnem",
  "page-dapps-gaming-benefits-3-description": "Tako kot so plačila na Ethereum na voljo vsem in jih lahko vsakdo potrdi, lahko igre to lastnost uporabijo za zagotavljanje poštenosti. V teoriji je mogoče potrditi vse, od število kritičnih zadetkov do velikosti vojnega plena nasprotnika.",
  "page-dapps-gaming-benefits-3-title": "Dokazljiva poštenost",
  "page-dapps-gaming-benefits-description": "Kateri vidik Ethereuma omogoča uspešnost decentraliziranih iger?",
  "page-dapps-gaming-benefits-title": "decentralizirane igre",
  "page-dapps-gaming-button": "Igre",
  "page-dapps-gaming-description": "To so aplikacije, ki se osredotočajo na ustvarjanje virtualnih svetov in bitke z drugimi igralci z uporabo zbirateljskih predmetov, ki imajo vrednost v resničnem svetu.",
  "page-dapps-gaming-title": "Decentralizirane igre",
  "page-dapps-get-some-eth-description": "Dejanja v decentraliziranih aplikacijah vključujejo provizijo",
  "page-dapps-get-started-subtitle": "Če želite poskusiti decentralizirano aplikacijo, boste potrebovali denarnico in nekaj ETH. Denarnica vam bo omogočala povezavo ali prijavo, ETH pa boste potrebovali za plačilo provizij na transakcije.",
  "page-dapps-get-started-title": "Začnite",
  "page-dapps-gitcoin-grants-logo-alt": "Logotip Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Logotip Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Logotip Gods Unchained",
  "page-dapps-golem-logo-alt": "Logotip Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Orodja in storitve, ki jih poganja Ethereum",
  "page-dapps-hero-subtitle": "Decentralizirane aplikacije so rastoče gibanje aplikacij, ki uporabljajo Ethereum za preobrazbo poslovnih modelov ali razvijanje novih.",
  "page-dapps-how-dapps-work-p1": "Zaledna koda (pametne pogodbe) decentraliziranih aplikacij se izvaja v decentraliziranem omrežju in ne v centraliziranem strežniku. Verigo blokov Ethereum uporabljajo za shrambo podatkov, pametne pogodbe pa za logiko svoje aplikacije.",
  "page-dapps-how-dapps-work-p2": "Pametna pogodba je kot nabor pravil na verigi, ki so vidna vsem, in se izvaja v skladu s temi pravili. Predstavljajte si prodajni avtomat: če vstavite dovolj sredstev in izberete pravo možnost, boste prejeli želeni izdelek. Tako kot prodajni avtomati lahko pametne pogodbe hranijo vaša sredstva na podoben način kot Ethereum račun. To kodi omogoča posredovanje sporazumov in transakcij.",
  "page-dapps-how-dapps-work-p3": "Ko so decentralizirane aplikacije uvedene v omrežje Ethereum, jih ni več mogoče spremeniti. Decentralizirane aplikacije so lahko decentralizirane, ker jih nadzira logika, napisana v pogodbi, in ne posameznik ali podjetje.",
  "page-dapps-how-dapps-work-title": "Delovanje dec. aplikacij",
  "page-dapps-learn-callout-button": "Začnite razvijati",
  "page-dapps-learn-callout-description": "Portal naše skupnosti za razvijalce vsebuje dokumente, orodja in okvire, ki vam bodo pomagali začeti razvijati decentralizirano aplikacijo.",
  "page-dapps-learn-callout-image-alt": "Ilustracija roke, ki sestavlja simbol ETH iz lego kock.",
  "page-dapps-learn-callout-title": "Kako razviti decentralizirano aplikacijo",
  "page-dapps-loopring-logo-alt": "Logotip Loopring",
  "page-dapps-magic-behind-dapps-description": "Uporaba decentraliziranih aplikacij se morda zdi podobna običajnimi aplikacijam, vendar imajo v zakulisju nekaj posebnih lastnosti, ki jih prevzemajo od Ethereumovih supermoči. V tem se decentralizirane aplikacije razlikujejo od aplikacij.",
  "page-dapps-magic-behind-dapps-link": "Zakaj je Ethereum odličen?",
  "page-dapps-magic-behind-dapps-title": "Čarovnija v zakulisju dec. aplikacij",
  "page-dapps-magic-title-1": "Čarovnija",
  "page-dapps-magic-title-2": "v zakulisju",
  "page-dapps-magician-img-alt": "Slika čarovnikov",
  "page-dapps-marble-cards-logo-alt": "marble.cards logotip",
  "page-dapps-matcha-logo-alt": "Logotip Matcha",
  "page-dapps-mobile-options-header": "Brskajte po drugi kategoriji",
  "page-dapps-nifty-gateway-logo-alt": "Logotip Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Logotip Oasis",
  "page-dapps-opensea-logo-alt": "Logotip OpenSea",
  "page-dapps-opera-logo-alt": "Logotip Opera",
  "page-dapps-polymarket-logo-alt": "Logotip Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Logotip PoolTogether",
  "page-dapps-rarible-logo-alt": "Logotip Rarible",
  "page-dapps-ready-button": "Začni",
  "page-dapps-ready-description": "Izberite dec. aplikacijo, ki jo želite preskusiti",
  "page-dapps-ready-title": "Pripravljeni?",
  "page-dapps-sablier-logo-alt": "Logotip Sablier",
  "page-dapps-set-up-a-wallet-button": "Poišči denarnico",
  "page-dapps-set-up-a-wallet-description": "Denarnica je vaša »prijava« za decentralizirano aplikacijo",
  "page-dapps-set-up-a-wallet-title": "Nastavite denarnico",
  "page-dapps-superrare-logo-alt": "Logotip SuperRare",
  "page-dapps-technology-button": "Tehnologija",
  "page-dapps-technology-description": "To so aplikacije, ki so osredotočene na decentralizacijo razvijalskih orodij, vključevanje kriptoekonomskih sistemov v obstoječe tehnologije in ustvarjanje trgov za odprtokodni razvoj.",
  "page-dapps-technology-title": "Decentralizirana tehnologija",
  "page-dapps-token-sets-logo-alt": "Logotip Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Logotip Tornado cash",
  "page-dapps-uniswap-logo-alt": "Logotip Uniswap",
  "page-dapps-wallet-callout-button": "Poišči denarnico",
  "page-dapps-wallet-callout-description": "Tudi denarnice so decentralizirane aplikacije. Poiščite ustrezno glede na funkcije, ki vam ustrezajo.",
  "page-dapps-wallet-callout-image-alt": "Slika robota.",
  "page-dapps-wallet-callout-title": "Prikaži denarnice",
  "page-dapps-warning-header": "Zadevo vedno preučite sami",
  "page-dapps-warning-message": "Ethereum je nova tehnologija in večina aplikacij je novih. Preden položite morebitne večje vsote denarja, poskrbite, da boste razumeli tveganja.",
  "page-dapps-what-are-dapps": "Kaj so dec. aplikacije?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Računi",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Dodatno",
  "docs-nav-backend-apis": "Zaledni API-ji",
  "docs-nav-block-explorers": "Pregledovalniki blokov",
  "docs-nav-blocks": "Bloki",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Prevajanje pametnih pogodb",
  "docs-nav-composability": "Sestavljivost",
  "docs-nav-consensus-mechanisms": "Mehanizmi za doseganje soglasja",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Podatki in analitika",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Uvajanje pametnih pogodb",
  "docs-nav-development-frameworks": "Razvojna ogrodja",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Razvojna omrežja",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API-ji odjemalca Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Sklad Ethereum",
  "docs-nav-evm": "Navidezni računalnik za Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Temeljne teme",
  "docs-nav-gas": "Gas",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Integrirana razvojna okolja (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Uvod v decentralizirane aplikacije",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Uvod v Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Uvod v sklad",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "API-ji za JavaScript",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Rudarjenje",
  "docs-nav-networks": "Omrežja",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Vozlišča in odjemalci",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Vozlišča kot storitev",
  "docs-nav-oracles": "Oracles",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Programski jeziki",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Dokaz o deležu",
  "docs-nav-proof-of-work": "Dokaz o delu",
  "docs-nav-python": "Python",
  "docs-nav-readme": "BERIME",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Širjenje",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Varnost",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomija pametnih pogodb",
  "docs-nav-smart-contract-languages": "Jeziki pametnih pogodb",
  "docs-nav-smart-contracts": "Pametne pogodbe",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Knjižnice pametnih pogodb",
  "docs-nav-standards": "Standardi",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Shramba",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Preskušanje pametnih pogodb",
  "docs-nav-token-standards": "Standardi za žetone",
  "docs-nav-transactions": "Transakcije",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Primerjava: Web2 in Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Če ste strokovnjak za to temo in želite prispevati, uredite to stran in ji dodajte svojo modrost.",
  "page-calltocontribute-desc-2": "Prejeli boste zasluge in pomagali boste skupnosti Ethereum.",
  "page-calltocontribute-desc-3": "Uporabite to prilagodljivo",
  "page-calltocontribute-desc-4": "Imate vprašanja? Vprašajte nas na kanalu #content na našem",
  "page-calltocontribute-link": "predlogo za dokumentacijo",
  "page-calltocontribute-link-2": "strežniku Discord",
  "page-calltocontribute-span": "Uredi stran",
  "page-calltocontribute-title": "Pomagajte nam s to stranjo",
  "page-developer-meta-title": "Viri za razvijalce za Ethereum",
  "page-developers-about": "O teh virih za razvijalce",
  "page-developers-about-desc": "ethereum.org vam bo pomagal graditi z Ethereumom z dokumentacijo o temeljinih pojmih in razvojnem skladu. Na voljo so tudi vadnice, da da boste lahko hitro začeli.",
  "page-developers-about-desc-2": "Navdahnil nas je Mozilla Developer Network in menili smo, da Ethereum potrebuje mesto, kjer bodo skupaj zbrani odlični viri in vsebina za razvijalce. Tako kot pri naših prijateljih pri Mozilli je vse tukaj odprtokodno in pripravljeno, da boste lahko izboljševali in širili.",
  "page-developers-account-desc": "Pogodbe ali ljudje v omrežju",
  "page-developers-accounts-link": "Računi",
  "page-developers-advanced": "Dodatno",
  "page-developers-api-desc": "Uporaba knjižnic za interakcijo s pametnimi pogodbami",
  "page-developers-api-link": "Zaledni API-ji",
  "page-developers-aria-label": "Meni za razvijalce",
  "page-developers-block-desc": "Paketi transakcij, dodani verigi blokov",
  "page-developers-block-explorers-desc": "Vaš portal v podatke o Ethereumu",
  "page-developers-block-explorers-link": "Pregledovalniki blokov",
  "page-developers-blocks-link": "Bloki",
  "page-developers-browse-tutorials": "Prebrskajte vadnice",
  "page-developers-choose-stack": "Izberite svoj sklad",
  "page-developers-contribute": "Prispevajte",
  "page-developers-dev-env-desc": "Okolja IDE, ki so primerna za razvijanje decentraliziranih aplikacij",
  "page-developers-dev-env-link": "Razvojna okolja",
  "page-developers-discord": "Pridružite se Discordu",
  "page-developers-docs-introductions": "Uvodi",
  "page-developers-evm-desc": "Računalnik, ki obdeluje transakcije",
  "page-developers-evm-link": "Navidezni stroj za Ethereum (EVM)",
  "page-developers-explore-documentation": "Raziščite dokumentacijo",
  "page-developers-feedback": "Če imate povratne informacije, se na nas obrnite prek primera v GitHubu ali na našem strežniku za Discord.",
  "page-developers-frameworks-desc": "Orodja, ki pomagajo pospešiti razvoj",
  "page-developers-frameworks-link": "Razvojna ogrodja",
  "page-developers-fundamentals": "Osnove",
  "page-developers-gas-desc": "Eter, potreben za poganjanje transakcij",
  "page-developers-gas-link": "Plin",
  "page-developers-get-started": "Kako želite začeti?",
  "page-developers-improve-ethereum": "Pomagajte nam izboljšati ethereum.org",
  "page-developers-improve-ethereum-desc": "Tako kot spletno mesto ethereum.org so tudi ti dokumenti rezultat skupinskega truda. Ustvarite PR, če vidite napake, stvari, ki bi jih bilo treba izboljšati, ali nove priložnosti za pomoč razvijalcem Ethereuma.",
  "page-developers-into-eth-desc": "Uvod v verigo blokov in Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Uvod v decentralizirane aplikacije",
  "page-developers-intro-dapps-link": "Uvod v decentralizirane aplikacije",
  "page-developers-intro-eth-link": "Uvod v Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Uvod v sklad",
  "page-developers-intro-stack-desc": "Uvod v Ethereumov sklad",
  "page-developers-js-libraries-desc": "Uporaba javascripta za interakcijo s pametnimi pogodbami",
  "page-developers-js-libraries-link": "Knjižnice za javascript",
  "page-developers-language-desc": "Uporaba Ethereuma s poznanimi jeziki",
  "page-developers-languages": "Programski jeziki",
  "page-developers-learn": "Naučite se razvijati za Ethereum",
  "page-developers-learn-desc": "Preberite naše dokumente o osnovnih pojmih in Ethereumovem skladu",
  "page-developers-learn-tutorials": "Učite se z vadnicami",
  "page-developers-learn-tutorials-cta": "Oglejte si vadnice",
  "page-developers-learn-tutorials-desc": "Po korakih se pri razvijalcih, ki so to že storili, naučite, kako razvijati za Ethereum.",
  "page-developers-meta-desc": "Dokumentacija, vadnice in orodja za razvijalce, ki gradijo na Ethereumu",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Kako se ustvarjajo novi bloki in dosega konsenz",
  "page-developers-mining-link": "Rudarjenje",
  "page-developers-networks-desc": "Pregled omrežja Mainnet in preskusnih omrežij",
  "page-developers-networks-link": "Omrežja",
  "page-developers-node-clients-desc": "Kako se v omrežju preverjajo bloki in transakcije",
  "page-developers-node-clients-link": " Vozlišča in odjemalci",
  "page-developers-oracle-desc": "Prenos podatkov zunaj verige v pametne pogodbe",
  "page-developers-oracles-link": "Oracles",
  "page-developers-play-code": "Poigrajte se s kodo",
  "page-developers-read-docs": "Preberite dokumentacijo",
  "page-developers-scaling-desc": "Rešitve za hitrejše transakcije",
  "page-developers-scaling-link": "Širjenje",
  "page-developers-smart-contract-security-desc": "Varnostni ukrepi, ki jih je treba imeti v mislih pri razvijanju",
  "page-developers-smart-contract-security-link": "Varnost",
  "page-developers-set-up": "Nastavitev lokalnega okolja",
  "page-developers-setup-desc": "Pripravite sklad za razvijanje s konfiguriranjem razvojnega okolja.",
  "page-developers-smart-contracts-desc": "Logika, na kateri temeljijo decentralizirane aplikacije – samoizvajajoče pogodbe",
  "page-developers-smart-contracts-link": "Pametne pogodbe",
  "page-developers-stack": "Sklad",
  "page-developers-start": "Začnite eksperimentirati",
  "page-developers-start-desc": "Ali želite najprej eksperimentirati in vprašanja postavljati pozneje?",
  "page-developers-storage-desc": "Kako delati s shrambo za decentralizirane aplikacije",
  "page-developers-storage-link": "Shramba",
  "page-developers-subtitle": "Priročnik za razvijalce, ki delajo z Ethereumom – od razvijalcev za razvijalce.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "razvijalec",
  "page-developers-title-3": "viri",
  "page-developers-token-standards-desc": "Pregled sprejetih standardov za žetone",
  "page-developers-token-standards-link": "Standardi za žetone",
  "page-developers-transactions-desc": "Način, kako se spreminjaj stanje Ethereuma",
  "page-developers-transactions-link": "Transakcije",
  "page-developers-web3-desc": "Kako se razlikuje svet razvijanja web3",
  "page-developers-web3-link": "Primerjava: Web2 in Web3",
  "page-learning-tools-bootcamps": "Izobraževalni tabori za razvijalce",
  "page-learning-tools-bootcamps-desc": "Plačljivi spletni tečaji, s katerimi boste hitro na tekočem.",
  "page-learning-tools-browse-docs": "Prebrskajte dokumente",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Logotip ChainShot",
  "page-learning-tools-coding": "Učenje s kodiranjem",
  "page-learning-tools-coding-subtitle": "Ta orodja vam bodo pomagala eksperimentirati z Ethereumom, če imate raje interaktivno učno izkušnjo.",
  "page-learning-tools-consensys-academy-description": "Spletni izobraževalni tabor za razvijalce na Ethereumu.",
  "page-learning-tools-consensys-academy-logo-alt": "Logotip ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Naučite se jezika Solidity z razvijanjem igre z zombiji.",
  "page-learning-tools-cryptozombies-logo-alt": "Logotip CryptoZombies",
  "page-learning-tools-documentation": "Učenje z dokumentacijo",
  "page-learning-tools-documentation-desc": "Želite izvedeti več? Obiščite našo dokumentacijo in poiščite razlage, ki jih potrebujete.",
  "page-learning-tools-eth-dot-build-description": "Izobraževalni peskovnik za web3, vključno s programiranjem povleci in spusti ter odprtokodnimi gradniki.",
  "page-learning-tools-eth-dot-build-logo-alt": "Logotip Eth.build",
  "page-learning-tools-ethernauts-description": "Dokončajte ravni z vdiranjem v pametne pogodbe.",
  "page-learning-tools-ethernauts-logo-alt": "Logotip Ethernauts",
  "page-learning-tools-game-tutorials": "Interaktivne vadnice z igrami",
  "page-learning-tools-game-tutorials-desc": "Učite se z igranjem. Te vadnice vam bodo osnove predstavile z igranjem.",
  "page-learning-tools-meta-desc": "Spletna orodja za kodiranje in interaktivne učne izkušnje za lažje eksperimentiranje z razvijanjem na Ethereumu.",
  "page-learning-tools-meta-title": "Učna orodja za razvijalce",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Razvijajte, uvajajte in upravljajte pametne pogodbe za Ethereum. Sledite vadnicam z vtičnikom Learneth.",
  "page-learning-tools-remix-description-2": "Remix ni samo peskovnik. Številni razvijalci pišejo, prevajajo in uvajajo svoje pametne pogodbe z uporabo orodja Remix.",
  "page-learning-tools-remix-logo-alt": "Logotip Remix",
  "page-learning-tools-sandbox": "Peskovniki za kodiranje",
  "page-learning-tools-sandbox-desc": "Ti peskovniki ponujajo prostor za eksperimentiranje s pisanjem pametnih pogodb in razumevanjem Ethereuma.",
  "page-learning-tools-studio-description": "Spletno integrirano razvojno okolje, kjer lahko sledite vadnicam za ustvarjanje in preskušanje pametnih pogodb ter jim ustvarite čelno komponento.",
  "page-learning-tools-vyperfun-description": "Naučite se programskega jezika Vyper tako, da ustvarite igro Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Logotip Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Razvojni in preskuševalni okvir za pametne pogodbe z uporabo navideznega računalnika za Ethereum v programskem jeziku Python.",
  "page-local-environment-brownie-logo-alt": "Logotip Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Celostna razvijalska platforma za ustvarjanje in uvajanje decentraliziranih aplikacij.",
  "page-local-environment-embark-logo-alt": "Logotip Embark",
  "page-local-environment-epirus-desc": "Platforma za razvijanje, uvajanje in nadziranje aplikacij na verigi blokov v navideznem računalniku Java",
  "page-local-environment-epirus-logo-alt": "Logotip Epirus",
  "page-local-environment-eth-app-desc": "Z enim ukazom ustvarjajte aplikacije, ki jih poganja Ethereum. Vsebuje široko ponudbo ogrodij za uporabniške vmesnike in predloge za DeFi, med katerimi lahko izbirate.",
  "page-local-environment-eth-app-logo-alt": "Logotip Create Eth App",
  "page-local-environment-framework-feature-1": "Funkcije za zagon lokalnega primerka verige blokov.",
  "page-local-environment-framework-feature-2": "Uporablja se za prevajanje in preskušanje pametnih pogodb.",
  "page-local-environment-framework-feature-3": "Odjemalski razvijalski dodatki, s katerimi lahko znotraj istega projekta/skladišča oblikujete svojo uporabniško usmerjeno aplikacijo.",
  "page-local-environment-framework-feature-4": "Konfiguracija za povezovanje z omrežji Ethereum in uvajanje pogodb v primerku, ki se izvaja lokalno, ali v enem od Ethereumovih javnih omrežij.",
  "page-local-environment-framework-feature-5": "Distribucija decentraliziranih aplikacij – integracija z možnostmi shrambe, kot je IPFS.",
  "page-local-environment-framework-features": "Ti okviri omogočajo raznoliko izvirno funkcionalnost, kot je:",
  "page-local-environment-frameworks-desc": " Priporočamo, da izberete ogrodje, zlasti če šele začenjate. Za razvoj dovršene decentralizirane aplikacije so potrebne različne tehnologije. Ogrodja vključujejo številne potrebne funkcije ali zagotavljajo preproste sisteme vtičnikov za izbiro želenih orodij.",
  "page-local-environment-frameworks-title": "Ogrodja in vnaprej izdelani skladi",
  "page-local-environment-hardhat-desc": "Hardhat je razvojno orodje na Ethereumu za strokovnjake.",
  "page-local-environment-hardhat-logo-alt": "Logotip Hardhat",
  "page-local-environment-openZeppelin-desc": "Prihranite ure razvijanja s prevajanjem, nadgrajevanjem, uvajanjem in interakcijo s pametnimi pogodbami z našim vmesnikom ukazne vrstice.",
  "page-local-environment-openZeppelin-logo-alt": "Logotip OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App: vse, kar potrebujete, da začnete razvijati decentralizirane aplikacije, ki jih poganjajo pametne pogodbe",
  "page-local-environment-scaffold-eth-logo-alt": "Logotip scaffold-eth",
  "page-local-environment-setup-meta-desc": "Vodnik za izbiro sklada programske opreme za razvoj na Ethereumu.",
  "page-local-environment-setup-meta-title": "Nastavitev lokalnega razvojnega okolja Ethereum",
  "page-local-environment-setup-subtitle": "Če ste pripravljeni, da začnete graditi, je čas, da izberete svoj sklad.",
  "page-local-environment-setup-subtitle-2": " Tukaj so orodja in ogrodja, ki jih lahko uporabite za lažji razvoj svoje aplikacije na Ethereumu.",
  "page-local-environment-setup-title": "Nastavite svoje lokalno razvojno okolje",
  "page-local-environment-solidity-template-desc": "GitHubova predloga za vnaprej pripravljeno namestitev za pametne pogodbe Solidity. Vključuje lokalno omrežje Hardhat, Waffle za preskuse, Ethers za uvedbo denarnice in več.",
  "page-local-environment-solidity-template-logo-alt": "Logotip predloge Solidity",
  "page-local-environment-truffle-desc": "Zbirka Truffle pomaga razvijalcem čim udobneje priti od ideje do decentralizirane aplikacije.",
  "page-local-environment-truffle-logo-alt": "Logotip Truffle",
  "page-local-environment-waffle-desc": "Najnaprednejša knjižnica za preskušanje pametnih pogodb. Uporabite jo samo ali s storitvijo Scaffold-eth ali Hardhat.",
  "page-local-environment-waffle-logo-alt": "Logotip Waffle",
  "comp-tutorial-metadata-minute-read": "minutno branje",
  "page-tutorial-listing-policy-intro": "Pred pošiljanjem vodnika preberite naš pravilnik o dodajanju na seznam.",
  "comp-tutorial-metadata-tip-author": "Pošljite napitnino avtorju",
  "page-tutorial-listing-policy": "pravilnik o dodajanju člankov na seznam",
  "page-tutorial-new-github": "Ste novi na GitHubu?",
  "page-tutorial-new-github-desc": "Ustvarite zadevo – preprosto vnesite zahtevane informacije in prilepite svoj vodnik.",
  "page-tutorial-pull-request": "Ustvari zahtevo za uveljavitev sprememb",
  "page-tutorial-pull-request-btn": "Ustvari zahtevo za uveljavitev sprememb",
  "page-tutorial-pull-request-desc-1": "Upoštevajte",
  "page-tutorial-pull-request-desc-2": "tutorials/your-tutorial-name/index.md",
  "page-tutorial-pull-request-desc-3": "strukturo za poimenovanje.",
  "page-tutorial-raise-issue-btn": "Ustvari zadevo",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Objavi vadnico",
  "page-tutorial-submit-tutorial": "Če želite objaviti vadnico, boste morali uporabiti GitHub. Vabimo vas, da ustvarite zadevo ali zahtevo za uveljavitev sprememb.",
  "page-tutorial-subtitle": "Pozdravljeni na našem skrbno izbranem seznamu vadnic, ki so jih prispevali člani skupnosti.",
  "page-tutorial-tags-error": "Ni vadnic s temi oznakami",
  "page-tutorial-title": "Vadnice za razvoj na Ethereumu",
  "page-tutorials-meta-description": "Prebrskajte pregledane vadnice članov skupnosti Ethereum in jih filtrirajte po temah.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Vadnice za razvoj na Ethereumu",
  "page-eth-buy-some": "Želite kupiti Ethereum?",
  "page-eth-buy-some-desc": "Pogosta zmota je, da zamenjamo Ethereum in ETH. Ethereum je veriga blokov, ETH pa je primarno sredstvo na Ethereumu. Verjetno želite kupiti ETH.",
  "page-eth-cat-img-alt": "Grafika glifa ETH s kalejdoskopom mačk",
  "page-eth-collectible-tokens": "Zbirateljski žetoni",
  "page-eth-collectible-tokens-desc": "Žetoni, ki predstavljajo zbirateljski predmet v igri, digitalno umetnino ali druga edinstvena sredstva. Pogosto so imenovani nezamenljivi žetoni (NFT-ji).",
  "page-eth-cryptography": "Zaščiteno s kriptografijo",
  "page-eth-cryptography-desc": "Internetni denar je morda nekaj novega, vendar je zaščiten z dokazano kriptografijo. To varuje vašo denarnico, vaš ETH in vaše transakcije. ",
  "page-eth-currency-for-apps": "To je valuta aplikacij na Ethereumu.",
  "page-eth-currency-for-future": "Valuta za digitalno prihodnost",
  "page-eth-description": "ETH je kriptovaluta. Je redek digitalni denar, ki ga lahko uporabljate na internetu – podobno kot Bitcoin. Če ste novi v svetu kriptovalut, je tukaj povzetek razlik med ETH in tradicionalnim denarjem.",
  "page-eth-earn-interest-link": "Prislužite obresti",
  "page-eth-ethhub-caption": "Pogosto posodobljeno",
  "page-eth-ethhub-overview": "Če ga želite, ima EthHub odličen pregled",
  "page-eth-flexible-amounts": "Na voljo v prilagodljivih količinah",
  "page-eth-flexible-amounts-desc": "ETH je deljiv na 18 decimalnih mest, tako da vam ni treba kupiti 1 celega žetona ETH. Vsakič lahko kupite le delež – če želite, že od 0,000000000000000001 ETH.",
  "page-eth-fuels": "ETH poganja in varuje Ethereum",
  "page-eth-fuels-desc": "ETH je življenjska sila Ethereuma. Ko pošiljate ETH ali uporabljate aplikacijo na Ethereumu, plačate majhno provizijo v ETH za uporabo omrežja Ethereum. Ta provizija je spodbuda, da bo rudar obdelal in potrdil, kar počnete.",
  "page-eth-fuels-desc-2": "Rudarji delujejo kot zapisnikarji na Ethereumu – preverjajo udeležence in dokazujejo, da nihče ne goljufa. Rudarji, ki to počno, so prav tako nagrajeni z majhnimi zneski novoizdanega ETH.",
  "page-eth-fuels-desc-3": "Delo, ki ga opravljajo rudarji, zagotavlja varnost Ethereuma in svobodo pred centraliziranim nadzorom. Drugače rečeno,",
  "page-eth-fuels-more-staking": "Več o zastavljanju",
  "page-eth-fuels-staking": "ETH bo postal še pomembnejši z zastavljanjem. Ko zastavite svoj ETH, boste pomagali zaščititi Ethereum in si prislužili nagrade. V tem sistemu grožnja za izgubo ETH odvrača napade.",
  "page-eth-get-eth-btn": "Pridobite ETH",
  "page-eth-gov-tokens": "Upravni žetoni",
  "page-eth-gov-tokens-desc": "Žetoni, ki predstavljajo volilno moč v decentraliziranih organizacijah.",
  "page-eth-has-value": "Zakaj ima ETH vrednost?",
  "page-eth-has-value-desc": "ETH je za različne ljudi dragocen na različne načine.",
  "page-eth-has-value-desc-2": "Za uporabnike Ethereuma je ETH dragocen, ker je mogoče z njim plačati provizije na transakcije.",
  "page-eth-has-value-desc-3": "Drugi ga vidijo kot digitalno shrambo vrednosti, saj se ustvarjanje novih žetonov ETH sčasoma upočasnjuje.",
  "page-eth-has-value-desc-4": "ETH ke pred nedavnim postal dragocen za uporabnike finančnih aplikacij na Ethereumu. Razlog je ta, da ga je mogoče uporabiti kot jamstvo za kriptoposojila ali kot plačilni sistem.",
  "page-eth-has-value-desc-5": "Seveda ga mnogi vidijo tudi kot naložbo podobno Bitcoinu ali drugim kriptovalutam.",
  "page-eth-how-to-buy": "Kako kupiti Ether",
  "page-eth-how-to-buy-caption": "Pogosto posodobljeno",
  "page-eth-is-money": "ETH je digitalni globalni denar.",
  "page-eth-last-updated": "januar 2019",
  "page-eth-mining-link": "Več o rudarjenju",
  "page-eth-monetary-policy": "Ethereumova monetarna politika",
  "page-eth-more-on-ethereum-link": "Več o Ethereumu",
  "page-eth-no-centralized": "Brez centraliziranega nadzora ",
  "page-eth-no-centralized-desc": "ETH je decentraliziran in globalen. Ni podjetja ali banke, ki bi se lahko odločila natisniti več ETH ali spremeniti pogoje uporabe.",
  "page-eth-non-fungible-tokens-link": "Nezamenljivi žetoni",
  "page-eth-not-only-crypto": "ETH ni edina kriptovaluta na Ethereumu",
  "page-eth-not-only-crypto-desc": "Na Ethereumu lahko vsak ustvarja nove vrste sredstev, imenovane »žetoni«, in z njimi trguje. Uporabniki so v obliko žetonov pretvorili tradicionalne valute, svoje nepremičnine, umetnine in celo sebe!",
  "page-eth-not-only-crypto-desc-2": "Na Ethereumu domuje več tisoč žetonov – nekateri so bolj uporabni in dragoceni kot drugi. Razvijalci nenehno razvijajo nove žetone, ki odklepajo nove možnosti in odpirajo nove trge.",
  "page-eth-not-only-crypto-desc-3": "Če želite prebrati več o žetonih, so naši prijatelji iz EthHuba napisali nekaj odličnih pregledov:",
  "page-eth-open": "Odprt za vse",
  "page-eth-open-desc": "Če želite sprejemati ETH, potrebujete samo internetno povezavo in denarnico. Za sprejemanje plačil ne potrebujete dostopa do bančnega računa.",
  "page-eth-p2p-payments": "Plačila P2P",
  "page-eth-p2p-payments-desc": "ETH lahko pošljete brez vsakršne posredniške storitve, kot je banka. Podobno je izmenjavi denarja v živo, vendar je mogoče transakcije opravljati z vsakomer, vedno in povsod.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Priljubljene vrste žetonov",
  "page-eth-powers-ethereum": "ETH poganja Ethereum",
  "page-eth-shit-coins": "Sh*tcoini",
  "page-eth-shit-coins-desc": "Ker je ustvarjanje novih žetonov preprosto, lahko to stori vsak – tudi tisti s slabimi ali neprimernimi nameni. Preden jih začnete uporabljati, jih preučite.",
  "page-eth-stablecoins": "Stabilni kovanci",
  "page-eth-stablecoins-desc": "To so žetoni, ki odražajo vrednost tradicionalnih valut, kot so dolarji. To rešuje težavo, ki jo predstavlja nestabilnost številnih kriptovalut.",
  "page-eth-stablecoins-link": "Pridobite stabilne kovance",
  "page-eth-stream-link": "Pretočno predvajajte ETH",
  "page-eth-tokens-link": "Žetoni Ethereum",
  "page-eth-trade-link-2": "Menjava žetonov",
  "page-eth-underpins": "ETH je temelj finančnega sistema Ethereum",
  "page-eth-underpins-desc": "Ker skupnost Ethereum ni zadovoljna samo s plačili, razvija celoten finančni sistem, ki poteka med enakovrednimi računalniki (P2P) in je dostopen vsem.",
  "page-eth-underpins-desc-2": "ETH lahko uporabite kot jamstvo za generiranje povsem drugih kriptovalut na Ethereumu. Poleg tega lahko ETH in druge žetone, ki temeljijo na ETH, posojate, si jih izposojate in si prislužite obresti nanje.",
  "page-eth-uses": "Število uporab za ETH je vsak dan večje",
  "page-eth-uses-desc": "Ker je Ethereum mogoče programirati, lahko razvijalci ETH oblikujejo na številne načine.",
  "page-eth-uses-desc-2": "Leta 2015 je bilo ETH mogoče samo poslati iz enega Ethereum računa na drugega. Tukaj je samo nekaj stvari, ki jih je mogoče storiti danes.",
  "page-eth-uses-desc-3": "sprotno plačevanje ali prejemanje sredstev;",
  "page-eth-uses-desc-4": "trgovanje med ETH in drugimi žetoni, vključno z bitcoinom;",
  "page-eth-uses-desc-5": "na ETH in drugih žetonih, ki temeljijo na ETH.",
  "page-eth-uses-desc-6": "dostopate do sveta kriptovalut s stabilno in manj volatilno valuto.",
  "page-eth-value": "Zakaj je ether dragocen",
  "page-eth-video-alt": "Video glifa ETH",
  "page-eth-whats-eth": "Kaj je ether (ETH)?",
  "page-eth-whats-eth-hero-alt": "Slika skupine ljudi, ki osuplo občudujejo glif ether (ETH)",
  "page-eth-whats-eth-meta-desc": "Kaj morate vedeti, če želite razumeti ETH in njegovo mesto v Ethereumu.",
  "page-eth-whats-eth-meta-title": "Kaj je ether (ETH)?",
  "page-eth-whats-ethereum": "Kaj je Ethereum?",
  "page-eth-whats-ethereum-desc": "Če želite več informacij o Ethereumu in tehnologiji, na kateri deluje ETH, preberite našo predstavitev.",
  "page-eth-whats-unique": "Zakaj je ETH edinstven?",
  "page-eth-whats-unique-desc": "Obstajajo številne kriptovalute in veliko drugih žetonov na Ethereumu, vendar so določene stvari, ki jih zmore samo ETH.",
  "page-eth-where-to-buy": "Kje dobiti ETH",
  "page-eth-where-to-buy-desc": "ETH lahko dobite na borzi ali denarnici, vendar imajo različne države različne pravilnike. Oglejte si storitve, ki omogočajo nakup ETH.",
  "page-eth-yours": "Zares je vaš",
  "page-eth-yours-desc": "Z ETH-jem lahko postanete svoja banka. Sami nadzirate svoja sredstva, lastništvo pa dokažete z denarnico brez zunanjih posrednikov.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Naslov pogodbe za polog Eth2",
  "page-eth2-deposit-contract-address-caption": "Dodali smo presledke, tako da je naslov lažje prebrati",
  "page-eth2-deposit-contract-address-check-btn": "Preverite naslov pogodbe za polog",
  "page-eth2-deposit-contract-checkbox1": "Uporabil/-a sem zagonsko platformo in nastavil/-a svojega potrjevalca Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Razumem, da moram za zastavljanje uporabiti zagonsko platformo. Preprosti prenosi na ta naslov ne bodo delovali.",
  "page-eth2-deposit-contract-checkbox3": "Naslov pogodbe za polog bom preveril/-a pri drugih virih.",
  "page-eth2-deposit-contract-confirm-address": "Potrdite za razkritje naslova",
  "page-eth2-deposit-contract-copied": "Kopiran naslov",
  "page-eth2-deposit-contract-copy": "Kopiraj naslov",
  "page-eth2-deposit-contract-etherscan": "Prikaži pogodbo na Etherscanu",
  "page-eth2-deposit-contract-h2": "Tukaj ne zastavljate ETH",
  "page-eth2-deposit-contract-launchpad": "Zastavljajte z uporabo zagonske platforme",
  "page-eth2-deposit-contract-launchpad-2": "Uporabi zagonsko platformo",
  "page-eth2-deposit-contract-meta-desc": "Preverite naslov pogodbe za polog za zastavljanje Eth2.",
  "page-eth2-deposit-contract-meta-title": "Naslov pogodbe za polog Eth2",
  "page-eth2-deposit-contract-read-aloud": "Naslov preberi naglas",
  "page-eth2-deposit-contract-reveal-address-btn": "Razkrij naslov",
  "page-eth2-deposit-contract-staking": " Če želite zastaviti svoj ETH in Eth2, morate uporabiti namensko zagonsko platformo in slediti navodilom. S pošiljanjem ETH na naslov na tej strani ne boste postali zastavljavec, rezultat pa bo neuspela transakcija.",
  "page-eth2-deposit-contract-staking-check": "Preverite te vire",
  "page-eth2-deposit-contract-staking-check-desc": "Pričakujemo, da je veliko lažnih naslovov in goljufij. Da boste varni, preverite naslov za zastavljanje Eth2, ki ga uporabljate, in ga primerjajte z naslovom na tej strani. Priporočamo, da ga primerjate tudi z drugimi zaupanja vrednimi viri.",
  "page-eth2-deposit-contract-staking-more-link": "Več o zastavljanju",
  "page-eth2-deposit-contract-stop-reading": "Ustavi branje",
  "page-eth2-deposit-contract-subtitle": " To je naslov pogodbe za zastavljanje Eth2. Uporabite to stran za potrditev, da pri zastavljanju pošiljate sredstva na pravilen naslov.",
  "page-eth2-deposit-contract-warning": "Pozorno preverite vsak znak.",
  "page-eth2-deposit-contract-warning-2": "Pošiljanje sredstev na ta naslov ne bo delovalo in s tem ne boste zastavili svojega Eth. Upoštevati morate navodila na zagonski platformi.",
  "page-eth2-bug-bounty-annotated-specs": "specifikacija z opombo",
  "page-eth2-bug-bounty-annotations": "Morda vam bo v pomoč, če si ogledate naslednje opombe:",
  "page-eth2-bug-bounty-client-bugs": "Napake v odjemalcu Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Odjemalci bodo oddajniško verigo izvajali po izvedbi nadgradnje. Odjemalci bodo morali slediti logiki, določeni v specifikaciji, in zagotoviti zaščito pred morebitnimi napadi. Napake, ki jih iščemo, so povezane z izvedbo protokola.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Do te nagrade so trenutno upravičene napake v odjemalcih Lighthouse, Nimbus, Teku in Prysm. Več odjemalcev bo morda dodanih, ko dokončajo revizije in postanejo pripravljeni na izdajo.",
  "page-eth2-bug-bounty-clients": "Odjemalci, vključeni v nagrade",
  "page-eth2-bug-bounty-clients-type-1": "težave z neskladnostjo specifikacije,",
  "page-eth2-bug-bounty-clients-type-2": "nepričakovane zrušitve ali ranljivosti za zavrnitev storitve (DOS),",
  "page-eth2-bug-bounty-clients-type-3": "vse težave, ki povzročijo nepopravljiva razhajanja glede soglasja od ostalega omrežja.",
  "page-eth2-bug-bounty-docking": "spojitev",
  "page-eth2-bug-bounty-email-us": "Pošljite e-pošto:",
  "page-eth2-bug-bounty-help-links": "Koristne povezave",
  "page-eth2-bug-bounty-hunting": "Pravila za iskanje napak",
  "page-eth2-bug-bounty-hunting-desc": "Program nagrad za odkrivanje napak je eksperimentalen in diskrecijski program nagrad za našo aktivno skupnost Ethereum, s katerim spodbujamo in nagrajujemo vse, ki pomagajo izboljšati platformo. To ni tekmovanje. Zavedati se morate, da lahko program kadarkoli prekličemo, nagrade pa se dodeljujejo po izključni presoji odbora za program nagrad za odkrivanje napak fundacije Ethereum Foundation. Poleg tega ne moremo dodeljevati nagrad posameznikom, ki so na seznamih sankcij ali so v državah, ki so na seznamih sankcij (npr. Severna Koreja, Iran itd.). Sami ste odgovorni za davke. Za vse nagrade velja upoštevna zakonodaja. Vaše preskušanje ne sme kršiti nobenega zakona ali ogrožati morebitnih podatkov, ki niso vaši.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Lestvica vodilnih pri iskanju napak",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Poiščite napake v Eth2, če želite biti dodani na lestvico",
  "page-eth2-bug-bounty-hunting-li-1": "Težave, ki so jih že predložili drugi uporabniki ali jih vzdrževalci specifikacij in odjemalcev že poznajo, niso upravičene do nagrad za odkrivanje napak.",
  "page-eth2-bug-bounty-hunting-li-2": "V primeru javnega razkritja, ranljivost ni upravičena do nagrade.",
  "page-eth2-bug-bounty-hunting-li-3": "Raziskovalci fundacije Ethereum Foundation in zaposleni v ekipah odjemalcev Eth2 niso upravičeni do nagrad.",
  "page-eth2-bug-bounty-hunting-li-4": "Ethereumov program za odkrivanje napak pri določanju nagrad upošteva številne spremenljivke. Odločitve glede upravičenosti, rezultata in vseh pogojev, povezanih z nagrado, se sprejemajo po izključni in končni presoji odbora za program nagrad za odkrivanje napak fundacije Ethereum Foundation.",
  "page-eth2-bug-bounty-leaderboard": "Prikaži celotno lestvico",
  "page-eth2-bug-bounty-leaderboard-points": "točk",
  "page-eth2-bug-bounty-ledger-desc": "Specifikacija oddajniške verige vsebuje podroben opis razlogov za zasnovo in predlaganih sprememb Ethereuma prek nadgradnje oddajniške verige.",
  "page-eth2-bug-bounty-ledger-title": "Napake v specifikaciji oddajniške verige",
  "page-eth2-bug-bounty-meta-description": "Pregled programa za iskanje napak Eth2: kako sodelovati in informacije o nagradah.",
  "page-eth2-bug-bounty-meta-title": "Program nagrad za odkrivanje napak Eth2",
  "page-eth2-bug-bounty-not-included": "Ni na voljo",
  "page-eth2-bug-bounty-not-included-desc": "Razdrobljene verige in nadgradnje spojitve so še vedno v aktivnem razvoju in zato še niso vključene kot del tega programa za odkrivanje napak.",
  "page-eth2-bug-bounty-owasp": "Metoda OWASP",
  "page-eth2-bug-bounty-points": "Fundacija Ethereum Foundation bo točke dodeljevala tudi glede na:",
  "page-eth2-bug-bounty-points-error": "Napaka pri nalaganju podatkov ... osvežite.",
  "page-eth2-bug-bounty-points-exchange": "Zamenjava točk",
  "page-eth2-bug-bounty-points-loading": "Nalaganje podatkov ...",
  "page-eth2-bug-bounty-points-payout-desc": "Fundacija Ethereum Foundation bo vrednost v USD izplačala v ETH ali DAI.",
  "page-eth2-bug-bounty-points-point": "1 točka",
  "page-eth2-bug-bounty-points-rights-desc": "Fundacija Ethereum Foundation si pridržuje pravico do sprememb brez predhodnega obvestila.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Kakovost opisa",
  "page-eth2-bug-bounty-quality-desc": ": višje nagrade so izplačane za jasne in kakovostno napisane predložitve.",
  "page-eth2-bug-bounty-quality-fix": "Kakovost izboljšave",
  "page-eth2-bug-bounty-quality-fix-desc": ", če je izvedena: višje nagrade so izplačane za vloge z jasnim opisom odpravljanja težave.",
  "page-eth2-bug-bounty-quality-repro": "Kakovost možnosti poustvarjanja",
  "page-eth2-bug-bounty-quality-repro-desc": ": priložite preskusno kodo, skripte in podrobna navodila. Lažje kot bomo poustvarili in potrdili ranljivost, višja bo nagrada.",
  "page-eth2-bug-bounty-questions": "Imate vprašanja?",
  "page-eth2-bug-bounty-rules": "Preberi pravila",
  "page-eth2-bug-bounty-shard-chains": "razdrobljene verige",
  "page-eth2-bug-bounty-slogan": "Nagrade za odkrivanje napak Eth2",
  "page-eth2-bug-bounty-specs": "Preberite celotno specifikacijo",
  "page-eth2-bug-bounty-specs-docs": "Dokumenti specifikacij",
  "page-eth2-bug-bounty-submit": "Pošlji napako",
  "page-eth2-bug-bounty-submit-desc": "Za vsako napako, ki jo odkrijete, boste nagrajeni s točkami. Točke, ki jih prislužite, so odvisne od resnosti napake. Fundacija Ethereum Foundation (EF) resnost določa z uporabo metode OWASP.",
  "page-eth2-bug-bounty-subtitle": "Zaslužite do 50.000 USD in mesto na lestvici z iskanjem napak v protokolu in odjemalcih Eth2.",
  "page-eth2-bug-bounty-title": "Oddajanje vlog je odprto",
  "page-eth2-bug-bounty-title-1": "Oddajniška veriga",
  "page-eth2-bug-bounty-title-2": "Izbira vejitve",
  "page-eth2-bug-bounty-title-3": "Pogodba za polog Solidity",
  "page-eth2-bug-bounty-title-4": "Uporaba omrežij enakovrednih računalnikov",
  "page-eth2-bug-bounty-type-1": "napake, ki razveljavljajo varnost/dokončnost.",
  "page-eth2-bug-bounty-type-2": "vektorji zavrnitve storitve (DOS)",
  "page-eth2-bug-bounty-type-3": "neskladnosti pri domnevah, kot so okoliščine, kjer se lahko zmanjšajo nagrade poštenih validatorjev.",
  "page-eth2-bug-bounty-type-4": "neskladnosti pri izračunih ali parametrih.",
  "page-eth2-bug-bounty-types": "Vrste napak",
  "page-eth2-bug-bounty-validity": "Veljavne napake",
  "page-eth2-bug-bounty-validity-desc": "Ta program nagrad za odkrivanje napak se osredotoča na iskanje napak v jedrni specifikaciji oddajniške verige Eth2 in izvedbah odjemalcev Prysm, Lighthouse in Teku.",
  "page-eth2-bug-bountycard-critical": "Kritično",
  "page-eth2-bug-bountycard-critical-risk": "Pošljite napako s kritično stopnjo tveganja",
  "page-eth2-bug-bountycard-h2": "Srednje",
  "page-eth2-bug-bountycard-high": "Visoko",
  "page-eth2-bug-bountycard-high-risk": "Pošljite napako z visoko stopnjo tveganja",
  "page-eth2-bug-bountycard-label-1": "Do 1000 točk",
  "page-eth2-bug-bountycard-label-2": "Do 2000 DAI",
  "page-eth2-bug-bountycard-label-3": "Do 5000 točk",
  "page-eth2-bug-bountycard-label-4": "Do 10.000 DAI",
  "page-eth2-bug-bountycard-label-5": "Do 10.000 točk",
  "page-eth2-bug-bountycard-label-6": "Do 20.000 DAI",
  "page-eth2-bug-bountycard-label-7": "Do 25.000 točk",
  "page-eth2-bug-bountycard-label-8": "Do 50.000 DAI",
  "page-eth2-bug-bountycard-li-1": "Nizek vpliv, srednja verjetnost",
  "page-eth2-bug-bountycard-li-2": "Srednji vpliv, nizka verjetnost",
  "page-eth2-bug-bountycard-li-3": "Visok vpliv, nizka verjetnost",
  "page-eth2-bug-bountycard-li-4": "Srednji vpliv, srednja verjetnost",
  "page-eth2-bug-bountycard-li-5": "Nizek vpliv, visoka verjetnost",
  "page-eth2-bug-bountycard-li-6": "Visok vpliv, srednja verjetnost",
  "page-eth2-bug-bountycard-li-7": "Srednji vpliv, visoka verjetnost",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Nizko",
  "page-eth2-bug-bountycard-low-risk": "Pošljite napako z nizko stopnjo tveganja",
  "page-eth2-bug-bountycard-medium-risk": "Pošljite napako s srednjo stopnjo tveganja",
  "page-eth2-bug-bountycard-subheader": "Resnost",
  "page-eth2-bug-bountycard-subheader-2": "Primer",
  "page-eth2-bug-bountycard-text": "Napadalec lahko vozlišče včasih preklopi v stanje, zaradi katerega spusti vsako stoto potrditev, ki jo opravi validator",
  "page-eth2-bug-bountycard-text-1": "Napadalec lahko uspešno izvede napade z mrkom vozlišča z uporabo ID-jev v omrežju enakopravnih računalnikov s 4 vodilnimi ničlami",
  "page-eth2-bug-bountycard-text-2": "To je napaka soglasja med dvema odjemalcema, vendar je napadalcu težko in nepraktično sprožiti dogodek.",
  "page-eth2-bug-bountycard-text-3": "To je napaka soglasja med dvema odjemalcema, vendar je sprožitev dogodka za napadalca trivialna.",
  "page-eth2-get-involved-btn-1": "Prikaži odjemalce",
  "page-eth2-get-involved-btn-2": "Več o zastavljanju",
  "page-eth2-get-involved-btn-3": "Iskanje napak",
  "page-eth2-get-involved-bug": "Napaka je lahko:",
  "page-eth2-get-involved-bug-hunting": "Pojdi na iskanje napak",
  "page-eth2-get-involved-bug-hunting-desc": "Poiščite in poročajte o napakah v specifikacijah ali samih odjemalcih za nadgradnjo Eth2. Zaslužite lahko do 50.000 USD in si prislužite mesto na lestvici vodilnih.",
  "page-eth2-get-involved-bug-li": "težave z neskladnostjo specifikacije,",
  "page-eth2-get-involved-bug-li-2": "napake, ki razveljavljajo dokončnost,",
  "page-eth2-get-involved-bug-li-3": "vektorji zavrnitve storitve (DOS),",
  "page-eth2-get-involved-bug-li-4": "in drugo ...",
  "page-eth2-get-involved-date": "Datum zaključka: 23. december 2020",
  "page-eth2-get-involved-desc-1": "Izvajanje odjemalca pomeni, da boste aktivni udeleženec v Ethereumu. Vaš odjemalec bo beležil transakcije in preverjal nove bloke.",
  "page-eth2-get-involved-desc-2": "Če imate ETH, ga lahko zastavite in postanete validator ter pomagate zaščititi omrežje. Kot validator lahko zaslužite nagrade v ETH.",
  "page-eth2-get-involved-desc-3": "Pridružiti se pobudi za preskušanje s strani skupnosti. Pomagajte preskušati nadgradnje Eth2 pred njihovo uvedbo, poiščite napake in zaslužite nagrade.",
  "page-eth2-get-involved-ethresearch-1": "Razdrobitev",
  "page-eth2-get-involved-ethresearch-2": "Prehod iz Eth1 v Eth2",
  "page-eth2-get-involved-ethresearch-3": "Drobci in izvajanje stanj",
  "page-eth2-get-involved-ethresearch-4": "Vse raziskovalne teme",
  "page-eth2-get-involved-grants": "Skladi za skupnost zastavljavcev",
  "page-eth2-get-involved-grants-desc": "Pomagajte ustvarjati orodje in izobraževalne vsebine za skupnost zastavljavcev",
  "page-eth2-get-involved-how": "Kako želite sodelovati?",
  "page-eth2-get-involved-how-desc": "Skupnost Ethereum bo imela vedno koristi, če bo več ljudi izvajalo odjemalce, zastavljalo in iskalo napake.",
  "page-eth2-get-involved-join": "Pridružite se raziskavi",
  "page-eth2-get-involved-join-desc": " Kot marsikaj pri Ethereum je veliko raziskav javnih. To pomeni, da lahko sodelujete v razpravah ali preprosto preberete, kaj imajo povedati raziskovalci Ethereuma. ethresear.ch zajema več kot samo nadgradnje Eth2, vendar je Eth2 osrednja tema.",
  "page-eth2-get-involved-meta-description": "Kako sodelovati v Eth2: izvajajte odjemalce, zastavljajte, iščite napake in več.",
  "page-eth2-get-involved-more": "Več informacij",
  "page-eth2-get-involved-run-clients": "Izvajanje odjemalcev oddajniške verige",
  "page-eth2-get-involved-run-clients-desc": "Močna porazdelitev odjemalcev je ključna za dolgoročno zdravje Ethereuma. Odjemalec je programska oprema, ki izvaja verigo blokov, preverja transakcije in ustvarja nove bloke. Vsak odjemalec ima edinstvene lastnosti, zato ga izberite glede na to, kaj znate uporabljati.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Zastavite svoj ETH",
  "page-eth2-get-involved-stake-desc": "Zdaj lahko zastavite svoj ETH, če želite pomagati zaščititi oddajniško verigo.",
  "page-eth2-get-involved-stake-eth": "Zastavite ETH",
  "page-eth2-get-involved-subtitle": "Tukaj so vsi načini, kako lahko pomagate Ethereumu in pri prihodnjih pobudah v povezavi z Eth2.",
  "page-eth2-get-involved-title-1": "Izvajajte odjemalca",
  "page-eth2-get-involved-title-2": "Zastavite svoj ETH",
  "page-eth2-get-involved-title-3": "Iščite napake",
  "page-eth2-get-involved-written-go": "Napisano v jeziku Go",
  "page-eth2-get-involved-written-java": "Napisano v jeziku Java",
  "page-eth2-get-involved-written-javascript": "Napisano v jeziku JavaScript",
  "page-eth2-get-involved-written-net": "Napisano v jeziku .NET",
  "page-eth2-get-involved-written-nim": "Napisano v jeziku Nim",
  "page-eth2-get-involved-written-python": "Napisano v jeziku Python",
  "page-eth2-get-involved-written-rust": "Napisano v jeziku Rust",
  "eth2-client-cortex-logo-alt": "Logotip Cortex",
  "eth2-client-lighthouse-logo-alt": "Logotip Lighthouse",
  "eth2-client-lodestar-logo-alt": "Logotip Lodestar",
  "eth2-client-nimbus-logo-alt": "Logotip Nimbus",
  "eth2-client-prysm-logo-alt": "Logotip Prysm",
  "eth2-client-teku-logo-alt": "Logotip Teku",
  "eth2-rhino-img-alt": "Grafika nosoroga Eth 2, ki drvi naprej",
  "page-eth2-answer-1": "Na Eth2 lahko gledate kot sklop nadgradenj, ki bodo dodane za izboljšanje Ethereuma, kot ga uporabljamo danes. Te nadgradnje vključujejo ustvarjanje nove verige, imenovane oddajniška verige, in do 64 verig, imenovanih drobci.",
  "page-eth2-answer-2": "Ti dve nadgradnji sta ločeni od glavnega omrežja Ethereum, ki ga uporabljamo danes, in ga ne bosta nadomestili. Namesto tega se bo glavno omrežje spojilo ali »združilo« s tem vzporednim sistemom, ki ga sčasoma dodajamo.",
  "page-eth2-answer-4": "Z drugimi besedami bo Ethereum, ki ga uporabljamo danes, sčasoma vključeval vse funkcije, na katere ciljamo v viziji za Eth2.",
  "page-eth2-beacon-chain-btn": "Več o oddajniški verigi",
  "page-eth2-beacon-chain-date": "Oddajniška veriga se je začela izvajati 1. decembra 2020.",
  "page-eth2-beacon-chain-desc": "Je prvi dodatek Eth2 ekosistemu. Oddajniška veriga v Ethereum dodaja zastavljanje, predstavlja osnovo za nadaljnje nadgradnje in bo sčasoma usklajevala nov sistem.",
  "page-eth2-beacon-chain-estimate": "Oddajniška veriga je aktivna",
  "page-eth2-beacon-chain-title": "Oddajniška veriga",
  "page-eth2-bug-bounty": "Oglejte si program nagrad za iskanje napak",
  "page-eth2-clients": "Oglejte si odjemalce Eth2",
  "page-eth2-deposit-contract-title": "Preverite naslov pogodbe za polog",
  "page-eth2-diagram-ethereum-mainnet": "Glavno omrežje Ethereum",
  "page-eth2-diagram-h2": "Kako bodo nadgradnje delovale skupaj",
  "page-eth2-diagram-link-1": "Več o dokazu o delu",
  "page-eth2-diagram-link-2": "Več o verigah drobcev",
  "page-eth2-diagram-mainnet": "Glavno omrežje",
  "page-eth2-diagram-p": "Glavno omrežje Ethereum bo še nekaj časa obstajalo v svoji trenutni obliki. To pomeni, da nadgradnje oddajniške verige in drobcev ne bodo zmotile omrežja.",
  "page-eth2-diagram-p-1": "Glavno omrežje se bo sčasoma združilo z novim sistemom, ki bo na voljo z izvedbo nadgradenj Eth2.",
  "page-eth2-diagram-p-2": "Oddajniška veriga bo postala dirigent Ethereuma in bo usklajevala validatorje ter določala hitrost ustvarjanja blokov.",
  "page-eth2-diagram-p-3": "Na začetku bo obstajala ločeno od glavnega omrežja in upravljala validatorje – na noben način ne bo povezana s pametnimi pogodbami, transakcijami ali računi.",
  "page-eth2-diagram-p-4": "Drobci bodo zagotovili veliko dodatnih podatkov, ki bodo pomagali izboljšati število transakcij, ki jih lahko glavno omrežje obdela. Usklajevala jih bo oddajniška veriga.",
  "page-eth2-diagram-p-5": "Vendar se bodo vse transakcije še vedno zanašale na glavno omrežje, ki bo še naprej obstajalo, kot ga poznamo danes – zaščiteno z dokazom o delu in rudarji.",
  "page-eth2-diagram-p-6": "Glavno omrežje se bo združilo s sistemom dokaza o delu, ki ga usklajuje oddajniška veriga.",
  "page-eth2-diagram-p-8": "To bo glavno omrežje spremenilo v drobec v novem sistemu. Rudarji ne bodo več potrebni, saj bodo celoten Ethereum varovali validatorji.",
  "page-eth2-diagram-p10": "Eth2 ni migracija ali ena sama stvar. Opisuje sklop nadgradenj, na katerih delamo, da bi odklenili Ethereumov resnični potencial. Tako bodo vse sestavljale celoto.",
  "page-eth2-diagram-scroll": "Pomaknite se navzdol za raziskovanje – tapnite za več informacij.",
  "page-eth2-diagram-shard": "Drobec (1)",
  "page-eth2-diagram-shard-1": "Drobec (...)",
  "page-eth2-diagram-shard-2": "Drobec (2)",
  "page-eth2-diagram-shard-3": "Drobec (...)",
  "page-eth2-diagram-validators": "Več o validatorjih",
  "page-eth2-dive": "Poglobite se v vizijo",
  "page-eth2-dive-desc": "Kako bomo dosegli, da bo Ethereum bolj razšriljiv, varnejši in bolj trajnosten ter hkrati ostali zvesti Ethereumovem osrednjem načelu decentralizacije?",
  "page-eth2-docking": "Spojitev",
  "page-eth2-docking-answer-1": "Spojitev pomeni trenutek, ko se bo glavno omrežje spremenilo v drobec. To bo sledilo uspešni izvedbi razdrobljenih verig.",
  "page-eth2-docking-btn": "Več o spojitvi",
  "page-eth2-docking-desc": "Glavno omrežje Ethereum se bo moralo enkrat v prihodnosti »spojiti« ali »združiti« z oddajniško verigo. To bo omogočalo zastavljanje za celotno omrežje in predstavljalo konec energijsko potratnega rudarjenja.",
  "page-eth2-docking-estimate": "Predvideno: 2022",
  "page-eth2-docking-mainnet": "Kaj je glavno omrežje?",
  "page-eth2-docking-mainnet-eth2": "Spojitev glavnega omrežja z Eth2",
  "page-eth2-eth-blog": "Spletni dnevnik ethereum.org",
  "page-eth2-explore-btn": "Raziščite nadgradnje",
  "page-eth2-get-involved": "Sodelujte pri Eth2",
  "page-eth2-get-involved-2": "Sodelujte",
  "page-eth2-head-to": "Obiščite",
  "page-eth2-help": "Ali želite pomagati pri Eth2?",
  "page-eth2-help-desc": "Na voljo so številne priložnosti, da podate svoje mnenje o nadgradnjah Eth2, pomagate pri preskušanju in si celo prislužite nagrade.",
  "page-eth2-index-staking": "Zastavljanje je tukaj",
  "page-eth2-index-staking-desc": "Uvedba zastavljanja je ključi del nadgradenj Eth2. Če želite uporabljati svoj ETH in pomagati zaščititi omrežje Ethereum, sledite tem korakom.",
  "page-eth2-index-staking-learn": "Več o zastavljanju",
  "page-eth2-index-staking-learn-desc": "Oddajniška veriga bo Ethereumu dodala zastavljanje. Če imate ETH, to pomeni, da lahko delujete v korist javnosti in pomagate zaščititi omrežje ter hkrati zaslužite še več ETH.",
  "page-eth2-index-staking-step-1": "1. Nastavitev z zagonsko platformo",
  "page-eth2-index-staking-step-1-btn": "Obiščite zagonsko platformo za zastavljanje",
  "page-eth2-index-staking-step-1-desc": "Za zastavljanje v Eth2 boste morali uporabiti zagonsko platformo – to vas bo vodilo skozi postopek.",
  "page-eth2-index-staking-step-2": "2. Potrditev naslova za zastavljanje",
  "page-eth2-index-staking-step-2-btn": "Potrdite naslov pogodbe za polog",
  "page-eth2-index-staking-step-2-desc": "Preden zastavite svoj ETH preverite, ali imate pravilen naslov. Pred tem korakom ste morali opraviti vse potrebno na zagonski platformi.",
  "page-eth2-index-staking-sustainability": "Bolj trajnostno",
  "page-eth2-just-docking": "Več o spojitvi",
  "page-eth2-meta-desc": "Pregled nadgradenj Ethereum 2.0 in vizije, ki naj bi jo uresničile.",
  "page-eth2-meta-title": "Nadgradnje Eth2",
  "page-eth2-miners": "Več o rudarjih",
  "page-eth2-more-on-upgrades": "Več o nadgradnjah Eth2",
  "page-eth2-proof-of-stake": "dokaz o deležu",
  "page-eth2-proof-of-work": "dokaz o delu",
  "page-eth2-proof-stake-link": "Več o dokazu o deležu",
  "page-eth2-question-1-desc": "Eth2 je sklop ločenih nadgradenj z različnimi datumi uvedbe.",
  "page-eth2-question-1-title": "Kdaj bo Eth2 uveden?",
  "page-eth2-question-2-desc": "Gledati na Eth2 kot na ločeno verigo blokov ni pravilno.",
  "page-eth2-question-2-title": "Ali je Eth2 ločena veriga blokov?",
  "page-eth2-question-3-answer-2": "Razdrobljena veriga in nadgradnje ob spojitvi lahko vplivajo na razvijalce decentraliziranih aplikacij. Vendar specifikacije še niso bile določene, zato takojšnji ukrepi niso potrebni.",
  "page-eth2-question-3-answer-3": "Pogovorite se z ekipo za raziskovanje in razvoj Eth2 na ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Obiščite ethresear.ch",
  "page-eth2-question-3-desc": "Trenutno vam za pripravo na Eth2 ni treba storiti ničesar.",
  "page-eth2-question-3-title": "Kako se pripravim na Eth2?",
  "page-eth2-question-4-answer-1": "Ko danes pošljete transakcijo ali uporabite decentralizirano aplikacijo, uporabljate Eth1. To je Ethereum, ki ga varujejo rudarji.",
  "page-eth2-question-4-answer-2": "»Eth1« ali glavno omrežje bo do spojitve še naprej delovalo, kot običajno.",
  "page-eth2-question-4-answer-3": "Po spojitvi bodo validatorji celotno omrežje varovali z dokazom o deležu.",
  "page-eth2-question-4-answer-6": "Vsak lahko postane validator tako, da zastavi svoj ETH.",
  "page-eth2-question-4-answer-7": "Več o zastavljanju",
  "page-eth2-question-4-answer-8": "Nadgradnje oddajniške verige in razdrobljenih verig ne bodo zmotile Eth1, saj se razvijajo posebej.",
  "page-eth2-question-4-desc": "Eth1 se nanaša na glavno omrežje Ethereum, ki ga uporabljate danes.",
  "page-eth2-question-4-title": "Kaj je Eth1?",
  "page-eth2-question-5-answer-1": "Če želite postati polni validator v omrežju, boste morali zastaviti 32 ETH. Če jih nimate toliko ali jih niste pripravljeni toliko zastaviti, se lahko pridružite skladom za zastavljanje. Ti skladi vam bodo omogočali, da boste zastavili manj in zaslužili del skupnih nagrad.",
  "page-eth2-question-5-desc": "Če se želite pridružiti skladu za zastavljanje, boste morali uporabiti zagonsko platformo za zastavljanje.",
  "page-eth2-question-5-title": "Kako zastavljam?",
  "page-eth2-question-6-answer-1": "Zaenkrat vam ni treba storiti ničesar. Vendar priporočamo, da spremljate razvoj na področju nadgradenj razdrobljenih verig in spojitve.",
  "page-eth2-question-6-answer-3": "Danny Ryan iz fundacije Ethereum Foundation pogosto obvešča skupnost:",
  "page-eth2-question-6-answer-4": "Ben Edgington iz podjetja ConsenSys objavlja tedensko glasilo Eth2:",
  "page-eth2-question-6-answer-5": "Lahko se tudi pridružite razpravi o raziskovanju in razvoju Eth2 na ethresear.ch.",
  "page-eth2-question-6-desc": "Bližajoče se nadgradnje ne bodo vplivale na vašo decentralizirano aplikacijo. Vendar pa bodo prihodnje nadgradnje morda zahtevale nekatere spremembe.",
  "page-eth2-question-6-title": "Kaj moram storiti s svojo decentralizirano aplikacijo?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(izvedba .NET)",
  "page-eth2-question-7-desc": "Številne različne ekipe v celotni skupnosti delajo na različnih nadgradnjah Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(izvedba Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(izvedba JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(izvedba Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(izvedba Go)",
  "page-eth2-question-7-teams": "Ekipe odjemalcev Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(izvedba Java)",
  "page-eth2-question-7-title": "Kdo razvija Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(izvedba Python)",
  "page-eth2-question-8-answer-1": "Nadgradnje Eth2 bodo pomagale pri razširljivost Ethereuma na decentraliziran način, ohranjale varnost in povečale trajnostnost.",
  "page-eth2-question-8-answer-2": "Najbolj očitna težava je najbrž dejstvo, da mora biti Ethereum sposoben obdelati več kot 15–45 transakcij na sekundo. Vendar bodo nadgradnje Eth2 odpravile tudi nekaj težav z današnjim Ethereumom.",
  "page-eth2-question-8-answer-3": "Povpraševanje po omrežju je tako visoko, da je uporaba Ethereuma zaradi tega draga. Vozlišča v omrežju imajo težave z velikostjo Ethereuma in količino podatkov, ki jih morajo njihovi računalniki obdelati. Osnovni algoritem, ki zagotavlja varnost in decentralizacijo Ethereuma, pa je energijsko potraten in mora postati bolj ekološko sprejemljiv.",
  "page-eth2-question-8-answer-4": "Številne stvari, ki se spreminjajo, so bile vedno del Ethereumovega načrta že od leta 2015. Vendar so zaradi trenutnih razmer potrebe po nadgradnjah še večje.",
  "page-eth2-question-8-answer-6": "Raziščite vizijo Eth2",
  "page-eth2-question-8-desc": "Ethereum, ki ga uporabljamo danes, mora končnim uporabnikom in udeležencem v omrežju ponujati boljšo izkušnjo.",
  "page-eth2-question-8-title": "Zakaj potrebujemo Eth2?",
  "page-eth2-question-9-answer-1": "Najaktivnejša vloga, ki jo lahko igrate, je da zastavite svoj ETH.",
  "page-eth2-question-9-answer-2": "Prav tako lahko izvajate drugega odjemalca in pomagate izboljšati raznolikost odjemalcev.",
  "page-eth2-question-9-answer-3": "Če ste bolje tehnično podkovani, lahko pomagate iskati napake v novih odjemalcih.",
  "page-eth2-question-9-answer-4": "Prav tako lahko podate svoje mnenje v tehničnih razpravah z Ethereumovimi raziskovalci na ethresear.ch.",
  "page-eth2-question-9-desc": "Za sodelovanje ne potrebujete tehničnih veščin. Skupnost išče prispevke glede na vse vrste sposobnosti.",
  "page-eth2-question-9-stake-eth": "Zastavite ETH",
  "page-eth2-question-9-title": "Kako lahko prispevam k Eth2?",
  "page-eth2-question-10-answer-1": "Neradi preveč govorimo o tehničnem načrtu, saj gre za programsko opremo: stvari se lahko spremenijo. Menimo, da je lažje razumeti, kaj se dogaja, če preberete več o rezultatih.",
  "page-eth2-question-10-answer-1-link": "Prikaži nadgradnje",
  "page-eth2-question-10-answer-2": "Če pa ste sledili razpravam, je tukaj več informacij o tem, kako bodo nadgradnje ustrezale tehničnim načrtom.",
  "page-eth2-question-10-answer-3": "Faza 0 opisuje delo, potrebno za zagon oddajniške verige.",
  "page-eth2-question-10-answer-5": "Faza 1 v tehničnih načrtih se osredotoča na izvedbo razdrobljenih verig.",
  "page-eth2-question-10-answer-6": "Spojitev glavnega omrežja v Eth2 je razširitev dela, ki je bilo opravljeno za izvedbo razdrobljenih verig in se imenuje tudi faza 1.5. Vendar gre za pomemben trenutek, saj se bo Ethereum, kot ga poznamo danes, združil z drugimi nadgradnjami Eth2. Poleg tega bo takrat Ethereum tudi povsem preklopil na dokaz o deležu.",
  "page-eth2-question-10-answer-7": "Trenutno ni povsem jasno, kaj se bo zgodilo okrog faze 2. To je še vedno stvar intenzivnih raziskav in razprav. Začetni načrt je bil dodati nekaj dodatne funkcionalnosti razdrobljenim verigam, vendar to morda ne bo potrebno zaradi načrta, osredotočenega na skupne vrednosti.",
  "page-eth2-question-10-answer-8": "Več o načrtu, osredotočenem na skupne vrednosti",
  "page-eth2-question-10-desc": "Faze se nanašajo na faze dela in prizadevanj na tehničnem načrtu za Eth2.",
  "page-eth2-question-10-title": "Kaj so faze Eth2?",
  "page-eth2-question-title": "Pogosta vprašanja",
  "page-eth2-question3-answer-1": "Imetnikom ETH trenutno ni treba storiti ničesar. Vašega ETH ne bo treba spremeniti ali nadgraditi. Vendar pa bodo prav gotovo pojavile goljufije, ki vas bodo prepričevale v nasprotno, zato bodite previdni.",
  "page-eth2-scalable": "Bolj razširljiv",
  "page-eth2-scalable-desc": "Ethereum mora za hitrejšo in cenejšo uporabo aplikacij podpirati več tisoč transakcij na sekundo.",
  "page-eth2-secure": "Varnejši",
  "page-eth2-secure-desc": "Ethereum mora biti varnejši. Z uveljavitvijo Ethereuma mora protokol postati odpornejši proti vsem vrstam napadov.",
  "page-eth2-shard-button": "Več o razdrobljenih verigah",
  "page-eth2-shard-date": "Uvedba razdrobljenih verig, ki predstavljajo drugo nadgradnjo, je pričakovana enkrat v letu 2021.",
  "page-eth2-shard-desc": "Razdrobljene verige bodo razširile Ethereumovo zmogljivost obdelovanja transakcij in obdelave podatkov. Sami drobci bodo čez čas pridobili več funkcij, ki bodo izvedene v več fazah.",
  "page-eth2-shard-estimate": "Predvideno: 2021",
  "page-eth2-shard-lower": "Več o razdrobljenih verigah",
  "page-eth2-shard-title": "Razdrobljene verige",
  "page-eth2-stay-up-to-date": "Ostanite na tekočem",
  "page-eth2-stay-up-to-date-desc": "Preberite najnovejše informacije raziskovalcev in razvijalcev, ki delajo na nadgradnjah Eth2.",
  "page-eth2-sustainable-desc": "Ethereum mora biti boljši za okolje. Današnja tehnologija zahteva preveč računalniške moči in energije.",
  "page-eth2-take-part": "Sodelujte v raziskavah",
  "page-eth2-take-part-desc": "Tukaj se srečujejo raziskovalci Ethereuma in zanesenjaki ter razpravljajo o raziskavah, vključno z vsem v povezavi z Eth2.",
  "page-eth2-the-upgrades": "Nadgradnje Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 je sklop nadgradenj, ki bodo izboljšale razširljivost, varnost in trajnost Ethereuma. Čeprav delo na vseh poteka vzporedno, imajo določene odvisnosti, ki bodo določile njihovo uvedbo.",
  "page-eth2-unofficial-roadmap": "To ni uradni načrt. Tako vidimo, kaj se dogaja, na podlagi razpoložljivih informacij. Vendar gre za tehnologijo in se lahko stvari hitro spremenijo. Zato tega ne razumite kot zavezo.",
  "page-eth2-upgrade-desc": "Ethereum, kot ga poznamo in imamo radi, vendar bolj razširljiv, varen in trajnosten ...",
  "page-eth2-upgrades": "Nadgradnje Eth2",
  "page-eth2-upgrades-aria-label": "Meni nadgradenj Eth2",
  "page-eth2-upgrades-beacon-chain": "Oddajniška veriga",
  "page-eth2-upgrades-docking": "Spojitev glavnega omrežja in Eth2",
  "page-eth2-upgrades-guide": "Vodnik po nadgradnjah Eth2",
  "page-eth2-upgrades-shard-chains": "Razdrobljene verige",
  "page-eth2-upgrading": "Nadgrajevanje Ethereuma na radikalno višji nivo",
  "page-eth2-vision": "Vizija",
  "page-eth2-vision-btn": "Več o viziji Eth2",
  "page-eth2-vision-desc": "Če želimo Ethereum predstaviti širokim množicam in ga uporabljati v dobro vsega človeštva, moramo zagotoviti, da postane bolj razširljiv, varen in trajnosten.",
  "page-eth2-vision-upper": "Vizija Eth2",
  "page-eth2-what-to-do": "Kaj morate storiti?",
  "page-eth2-what-to-do-desc": "Če ste uporabnik decentraliziranih aplikacij ali imetnik ETH, vam ni treba storiti ničesar. Če ste razvijalec ali želite začeti zastavljati, so načini, kako lahko začnete sodelovati še danes.",
  "page-eth2-whats-eth2": "Kaj je Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 pomeni sklop medsebojno povezanih nadgradenj, ki bodo zagotovile, da bo Ethereum postal bolj razširljiv, varen in trajnosten. Te nadgradnje razvijajo različne ekipe v celotnem ekosistemu Ethereum.",
  "page-eth2-whats-ethereum": "Počakajte, kaj je Ethereum?",
  "page-eth2-whats-new": "Kaj je novega pri Eth2?",
  "page-eth2-just-staking": "Zastavljanje",
  "page-eth2-staking-image-alt": "Slika maskote nosoroga za zagonsko platformo eth2.",
  "page-eth2-staking-51-desc-1": "Zastavljanje pomeni, da je pridružitev omrežju kot validator ugodnejša, tako da bo v omrežju verjetno delovalo več validatorjev, kot jih je danes. Zato bodo te vrste napadi še težji, saj se bodo stroški napada povečali.",
  "page-eth2-staking-accessibility": "Dostopnejši",
  "page-eth2-staking-accessibility-desc": "Z lažjimi zahtevami glede strojne opreme in možnostjo pridružitve skladu, če nimate 32 Eth, se bo lahko več ljudi pridružilo omrežju. S tem bo Ethereum postal bolj decentraliziran in varnejši, saj bo manj vektorjev za napad.",
  "page-eth2-staking-at-stake": "Na kocki je vaš ETH",
  "page-eth2-staking-at-stake-desc": "Ker morate zastaviti svoj ETH za potrjevanje transakcij in ustvarjanje novih blokov, ga lahko izgubite, če se odločite poskusiti ogoljufati sistem.",
  "page-eth2-staking-benefits": "Ugodnosti zastavljanja na Ethereumu",
  "page-eth2-staking-check-address": "Preverite naslov za polog",
  "page-eth2-staking-consensus": "Več o mehanizmu za doseganje soglasja",
  "page-eth2-staking-deposit-address": "Preverite naslov za polog",
  "page-eth2-staking-deposit-address-desc": "Če ste že upoštevali navodila za nastavitev na zagonski platformi, veste, da boste morali poslati transakcijo na pogodbo za polog za zastavljanje. Priporočamo, da zelo skrbno preverite naslov. Uradni naslov boste našli na ethereum.org in številnih drugih zaupanja vrednih spletnih mestih.",
  "page-eth2-staking-desc-1": "Nagrade se dodeljujejo za dejanja, ki pomagajo omrežju doseči soglasje. Nagrade boste prejeli za združevanje transakcij v nov blok ali preverjanje dela drugih validatorjev, saj tako veriga varno deluje.",
  "page-eth2-staking-desc-2": "Kljub temu, da lahko zaslužite nagrade za delo, ki koristi omrežju, lahko izgubite ETH za zlonamerna dejanja, izgubo povezave ali neuspešno potrjevanje.",
  "page-eth2-staking-desc-3": "Če želite postati polni validator, boste potrebovali 32 ETH, če se želite zgolj pridružiti skladu za zastavljanje, pa le nekaj ETH. Prav tako boste morali izvajati odjemalca glavnega omrežja »Eth1«. Na zagonski platformi boste našli opis postopka in zahteve glede strojne opreme. Namesto tega lahko uporabite zaledni API.",
  "page-eth2-staking-description": "Zastavljanje je postopek pologa 32 ETH za aktivacijo programske opreme za validatorje. Kot validator boste odgovorni za shrambo podatkov, obdelavo transakcij in dodajanje novih blokov v verigo blokov. Tako bo Ethereum ostal varen za vse, vi pa boste v postopku zaslužili ETH. Ta postopek, imenovan dokaz o deležu, bo uveden z oddajniško verigo.",
  "page-eth2-staking-docked": "Več o spojitvi",
  "page-eth2-staking-dyor": "Zadevo vedno preučite sami",
  "page-eth2-staking-dyor-desc": "Nobena od navedenih storitev za zastavljanje ne predstavlja uradne podpore. Ne pozabite opraviti svoje raziskave in ugotoviti, katera storitev vam najbolje ustreza.",
  "page-eth2-staking-header-1": "Zastavite svoj ETH, če želite postati validator za Ethereum",
  "page-eth2-staking-how-much": "Koliko ste pripravljeni zastaviti?",
  "page-eth2-staking-how-to-stake": "Kako zastavljati",
  "page-eth2-staking-how-to-stake-desc": "Vse je odvisno od tega, koliko ste pripravljeni zastaviti. Če želite postati polni validator, boste potrebovali 32 ETH, vendar je mogoče zastaviti tudi manj.",
  "page-eth2-staking-join": "Pridružite se",
  "page-eth2-staking-join-community": "Pridruži se skupnosti zastavljalcev",
  "page-eth2-staking-join-community-desc": "r/ethstaker je skupnost, kjer lahko vsi razpravljajo o zastavljanju na Ethereumu – pridružite se za nasvete, podporo in pogovor o vsem, kar je povezano z zastavljanjem.",
  "page-eth2-staking-less-than": "Manj kot",
  "page-eth2-staking-link-1": "Prikaži zaledne API-je",
  "page-eth2-staking-meta-description": "Pregled zastavljanja na Ethereumu: tveganja, nagrade, zahteve in kje sodelovati.",
  "page-eth2-staking-meta-title": "Zastavljanje na Ethereumu",
  "page-eth2-staking-more-sharding": "Več o razdrobitvi",
  "page-eth2-staking-pool": "Zastavite v skladu",
  "page-eth2-staking-pool-desc": "Če imate manj kot 32 ETH, boste lahko v sklade za zastavljanje dodali manjši delež. Nekatera podjetja lahko vse to storijo v vašem imenu, tako da vam ne bo treba skrbeti, ali ste povezani. Tukaj je nekaj podjetij, ki jih lahko preverite.",
  "page-eth2-staking-pos-explained": "Razlaga dokaza o deležu",
  "page-eth2-staking-pos-explained-desc": "Zastavljanje predstavlja dejanje, ki ga morate opraviti, če želite postati validator v sistemu z dokazom o deležu. To je mehanizem za doseganje soglasja, ki bo nadomestil vse sisteme z dokazom o delu, ki trenutno delujejo. Mehanizmi za doseganje soglasja zagotavljajo, da verige blokov, kot je Ethereum, ostanejo varne in decentralizirane.",
  "page-eth2-staking-pos-explained-desc-1": "Dokaz o deležu pomaga zaščititi omrežje na različne načine:",
  "page-eth2-staking-services": "Prikaži storitve zastavljanja",
  "page-eth2-staking-sharding": "Odklene razdrobitev",
  "page-eth2-staking-sharding-desc": "Razdrobitev je mogoča samo s sistemom z dokazom o deležu. Razdrobitev kot sistem z dokazom o delu bi zmanjšala računalniško moč, potrebno za škodovanje omrežju, kar bi zlonamernim rudarjem omogočalo lažje nadziranje drobcev. Z uporabo naključno dodeljenih zastavljalcev pri dokazu o deležu se temu izognemo.",
  "page-eth2-staking-solo": "Zastavljajte sami in delujte kot validator",
  "page-eth2-staking-solo-desc": "Če želite začeti postopek zastavljanja, boste morali uporabiti zagonsko platformo Eth2. To vas bo postopoma vodilo po procesu nastavitve. Del zastavljanja je izvajanje odjemalca Eth2, ki je lokalna kopija verige blokov. Prenos tega v računalnik lahko traja nekaj časa.",
  "page-eth2-staking-start": "Začnite zastavljati",
  "page-eth2-staking-subtitle": "Zastavljanje je javno dobro za ekosistem Ethereum. Pomagate lahko zaščititi omrežje in si hkrati prislužite nagrade.",
  "page-eth2-staking-sustainability": "Bolj trajnostno",
  "page-eth2-staking-sustainability-desc": "Validatorji ne potrebujejo energijsko potratnih računalnikov za sodelovanje v sistemu z dokazom o deležu – zadostuje prenosni računalnik ali pametni telefon. Zato bo Ethereum postal boljši za okolje.",
  "page-eth2-staking-the-beacon-chain": "Več o oddajniški verigi",
  "page-eth2-staking-title-1": "Nagrade",
  "page-eth2-staking-title-2": "Tveganja",
  "page-eth2-staking-title-3": "Zahteve",
  "page-eth2-staking-title-4": "Kako zastaviti svoj ETH",
  "page-eth2-staking-upgrades-li": "Dokaz o deležu upravlja oddajniška veriga.",
  "page-eth2-staking-upgrades-li-2": "Ethereum bo imel v nadaljnji prihodnosti oddajniško verigo z dokazom o deležu in glavno omrežje z dokazom o delu. Glavno omrežje je Ethereum, ki ga uporabljamo že več let.",
  "page-eth2-staking-upgrades-li-3": "V tem času bodo zastavljavci v oddajniško verigo dodajali nove bloke, vendar ne bodo obdelovali transakcij glavnega omrežja.",
  "page-eth2-staking-upgrades-li-4": "Ethereum bo povsem preklopil na sistem z dokazom o deležu, ko glavno omrežje Ethereum postane drobec.",
  "page-eth2-staking-upgrades-li-5": "Svoj delež boste lahko dvignili šele takrat.",
  "page-eth2-staking-upgrades-title": "Dokaz o deležu in nadgradnje Eth2",
  "page-eth2-staking-validators": "Več validatorjev, večja varnost",
  "page-eth2-staking-validators-desc": "Verigo blokov, kot je Ethereum, je mogoče okvariti, če nadziraš 51 % omrežja. Dosegli bi lahko na primer, da bi 51 % validatorjev izjavilo, da je vaše stanje 1.000.000 ETH in ne 1 ETH. Vendar je za nadzor nad 51 % validatorji potrebno lastništvo 51 % ETH v sistemu – to je zelo veliko.",
  "page-eth2-staking-withdrawals": "Dvigi ne bodo takoj aktivni",
  "page-eth2-staking-withdrawals-desc": "Svojega deleža ne boste mogli dvigniti do uvedbe nadaljnjih nadgradenj. Dvigi bodo na voljo, ko se bo glavno omrežje spojilo s sistemom z oddajniško verigo.",
  "page-eth2-vision-2014": "Preberite objavo na spletnem dnevniku iz leta 2014, ki podrobno opisuje dokaz o deležu",
  "page-eth2-vision-beacon-chain": "Oddajniška veriga",
  "page-eth2-vision-beacon-chain-btn": "Več o oddajniški verigi",
  "page-eth2-vision-beacon-chain-date": "Oddajniška veriga je aktivna",
  "page-eth2-vision-beacon-chain-desc": "Je prvi Eth2 dodatek ekosistemu. Oddajniška veriga v Ethereum dodaja zastavljanje, predstavlja osnovo za nadaljnje nadgradnje in bo sčasoma usklajevala nov sistem.",
  "page-eth2-vision-beacon-chain-upper": "Oddajniška veriga",
  "page-eth2-vision-desc-1": "Ethereum mora zmanjšati prenatrpanost omrežja in izboljšati hitrosti za zagotavljanje boljših storitev globalni bazi uporabnikov.",
  "page-eth2-vision-desc-2": "Izvajanje vozlišča postaja z rastjo omrežja vse težje. To bo postalo še težje s prizadevanji za razširitev omrežja.",
  "page-eth2-vision-desc-3": "Ethereum porabi preveč elektrike. Tehnologija, ki varuje omrežje, mora biti bolj trajnostna.",
  "page-eth2-vision-docking-date": "Predvideno: 2022",
  "page-eth2-vision-docking-desc": "Glavno omrežje Ethereum se bo moralo enkrat v prihodnosti »spojiti« z oddajniško verigo. To bo omogočalo zastavljanje za celotno omrežje in omogočilo konec energijsko intenzivnega rudarjenja.",
  "page-eth2-vision-ethereum-node": "Več o vozliščih",
  "page-eth2-vision-explore-upgrades": "Raziščite nadgradnje",
  "page-eth2-vision-future": "Digitalna prihodnost na globalni ravni",
  "page-eth2-vision-meta-desc": "Pregled vpliva, ki ga bodo imele nadgradnje Eth2 na Ethereum, in izzivov, ki jih morajo premagati.",
  "page-eth2-vision-meta-title": "Vizija Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Več o rudarjenju",
  "page-eth2-vision-problems": "Današnje težave",
  "page-eth2-vision-scalability": "Razširljivost",
  "page-eth2-vision-scalability-desc": "Ethereum mora biti sposoben obdelovati več transakcij na sekundo brez povečanja velikosti vozlišč v omrežju. Vozlišča so ključna za udeležence v omrežju, ki hranijo in izvajajo verigo blokov. Povečanje velikosti vozlišč ni praktično, saj bi lahko to storili samo tisti z zmogljivimi in dragimi računalniki. Ethereum za razširljivost potrebuje več transakcij na sekundo v kombinaciji z več vozlišči. Več vozlišč pomeni večjo varnost.",
  "page-eth2-vision-scalability-desc-3": "Nadgradnja razdrobljenih verig bo razdelila obremenitev omrežja med 64 novih verig. To bo Ethereumu omogočilo, da zadiha, saj bo zmanjšalo prenatrpanost in izboljšalo hitrosti nad trenutno omejitev 15–45 transakcij na sekundo.",
  "page-eth2-vision-scalability-desc-4": "In čeprav bo več verig, bodo imeli validatorji – vzdrževalci omrežja – dejansko manj dela. Validatorji bodo morali »izvajati« samo svoj drobec in ne celotne verige Ethereum. To pomeni, da so vozlišča lažja in omogoča Ethereumu, da postane razširljiv in ostane decentraliziran.",
  "page-eth2-vision-security": "Varnost",
  "page-eth2-vision-security-desc": "Nadgradnje Eth2 izboljšujejo varnost Ethereuma pred usklajenimi napadi, kot je napad z 51 % deležem. To je vrsta napada, kjer lahko nekdo z nadzorom nad omrežjem vsili zlonamerne spremembe.",
  "page-eth2-vision-security-desc-3": "Prehod na dokaz o deležu pomenu, da ima protokol Ethereum boljše odvrnitve pred napadom. Razlog je ta, da morajo pri dokazu o deležu, validatorji, ki varujejo omrežje, v protokol zastaviti bistvene vsote ETH. Če poskusijo napasti omrežje, lahko protokol samodejno uniči njihov ETH.",
  "page-eth2-vision-security-desc-5": "Pri dokazu o delu temu ni tako, saj protokol ne more storiti drugega, kot da prisili osebe, ki varujejo omrežje (rudarji), da izgubijo nagrade za rudarjenje, ki bi jih v nasprotnem primeru zaslužili. Za dosego enakega učinka v dokazu o delu, bi moral biti protokol sposoben uničiti vso opremo rudarjev, ki poskušajo goljufati.",
  "page-eth2-vision-security-desc-5-link": "Več o dokazu o delu",
  "page-eth2-vision-security-desc-8": "Prav tako se mora Ethereumov varnostni model spremeniti zaradi uvedbe",
  "page-eth2-vision-security-desc-10": "Zastavljanje prav tako pomeni, da vam ni treba vlagati v elitno strojno opremo za »izvajanje« vozlišča Ethereum. To bi moralo spodbuditi več ljudi, da postanejo validatorji, in povečati decentralizacijo omrežja ter zmanjšati prostor za napad.",
  "page-eth2-vision-security-staking": "Zastavite ETH",
  "page-eth2-vision-security-validator": "Validator lahko postanete tako, da zastavite svoj ETH.",
  "page-eth2-vision-shard-chains": "razdrobljenih verig",
  "page-eth2-vision-shard-date": "Predvideno: 2021",
  "page-eth2-vision-shard-desc-4": "Razdrobljene verige bodo obremenitev omrežja razdelile med 64 novih verig blokov. Drobci lahko potencialno drastično izboljšajo hitrost transakcij – do 100.000 na sekundo.",
  "page-eth2-vision-shard-upgrade": "Več o razdrobljenih verigah",
  "page-eth2-vision-staking-lower": "Več o zastavljanju",
  "page-eth2-vision-subtitle": "Razvijmo Ethereum, dokler ne bo tako zmogljiv, da bo lahko pomagal vsemu človeštvu.",
  "page-eth2-vision-sustainability": "Trajnostnost",
  "page-eth2-vision-sustainability-desc-1": "Nobena skrivnost ni, da so Ethereum in druge verige blokov, kot je bitcoin, energetsko intenzivne zaradi rudarjenja.",
  "page-eth2-vision-sustainability-desc-2": "Vendar se Ethereum premika proti temu, da ga bo varoval, ETH, ne računalniška moč – prek zastavljanja.",
  "page-eth2-vision-sustainability-desc-3": "Čeprav bo zastavljanje uvedeno z oddajniško verigo, bo Ethereum, kot ga poznamo danes, nekaj časa vzporedno deloval, preden se »združi« ali »spoji« z nadgradnjami Eth2. En sistem varuje ETH, drugega računalniška moč. Razlog je ta, da razdrobljene verige na začetku ne bodo mogle obdelovati stvari, kot so naši računi ali decentralizirane aplikacije. Zato ne smemo kar pozabiti na rudarjenje in glavno omrežje.",
  "page-eth2-vision-sustainability-desc-8": "Ko bodo nadgradnje oddajniške verige in razdrobljenih verig aktivne, se bo začelo delo na spojitvi glavnega omrežja z novim sistemom. To bo glavno omrežje spremenilo v drobec, tako da bo zaščiten z ETH in veliko manj energetsko intenziven.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum mora biti bolj zelen.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "Vizija Eth2",
  "page-eth2-vision-title-1": "Zamašeno omrežje",
  "page-eth2-vision-title-2": "Prostor na disku",
  "page-eth2-vision-title-3": "Preveč energije",
  "page-eth2-vision-trilemma-cardtext-1": "Nadgradnje Eth2 bodo poskrbele, da bo Ethereum postal razširljiv, varen in decentraliziran. Zastavljanje bo zagotovilo, da bo Ethereum bolj razširljiv, z izboljšanjem števila transakcij na sekundo in zmanjšanjem moči, potrebne za izvajanje vozlišča in potrjevanje verige. Oddajniška veriga bo zaščitila Ethereum z usklajevanjem validatorjem v različnih drobcih. Prav tako bo zastavljanje znižalo prag za sodelovanje in ustvarilo večje in bolj decentralizirano omrežje.",
  "page-eth2-vision-trilemma-cardtext-2": "Varna in decentralizirana omrežja verig blokov zahtevajo, da vsako vozlišče potrdi vsako transakcijo, ki jo obdela veriga. Ta količina dela omejuje število transakcij, ki se lahko izvedejo v danem trenutku. Decentralizacija in varnost predstavljata verigo Ethereum, kot jo poznamo danes.",
  "page-eth2-vision-trilemma-cardtext-3": "Decentralizirana omrežja delujejo s pošiljanjem informacij o transakcijah prek vozlišč – celotno omrežje mora poznati vsako spremembo stanja. Razširjanje števila transakcij na sekundo v decentraliziranih omrežjih predstavlja varnostna tveganja zaradi večjega števila transakcij – daljša kot je zakasnitev, večja je možnost napada med prenosom podatkov.",
  "page-eth2-vision-trilemma-cardtext-4": "Povečanje velikosti in moči Ethereumovih vozlišč bi lahko povečalo število transakcij na sekundo na varen način, vendar bi omejitve glede strojne opreme omejevale, kdo bi to lahko izvajal, to pa ogroža decentralizacijo. Upamo, da bosta zastavljanje in dokaz o deležu omogočala razširljivost Ethereuma s povečanjem števila vozlišč, ne pa velikosti vozlišč.",
  "page-eth2-vision-trilemma-h2": "Izziv decentralizirane razširljivosti",
  "page-eth2-vision-trilemma-modal-tip": "Tapnite spodnje kroge, če želite bolje razumeti težave z decentralizirano razširljivostjo",
  "page-eth2-vision-trilemma-p": "Naiven način reševanja Ethereumovih težav bi bil, da postane bolj centraliziran. Vendar je decentralizacija preveč pomembna. Decentralizacija Ethereumu zagotavlja odpornost na cenzuro, odprtost, zasebnost podatkov in skoraj nezlomljivo varnost.",
  "page-eth2-vision-trilemma-p-1": "Vizija za Ethereum je, da postane bolj razširljiv in varen, vendar še vedno decentraliziran. Doseganje teh treh lastnosti imenujemo tudi »trilema razširljivosti«.",
  "page-eth2-vision-trilemma-p-2": "Nadgradnje Eth2 poskušajo rešiti to trilemo, vendar obstajajo tudi precejšnji izzivi.",
  "page-eth2-vision-trilemma-press-button": "Tapnite kroge na trikotniku, če želite bolje razumeti težave z decentralizirano razširljivostjo.",
  "page-eth2-vision-trilemma-text-1": "Decentralizacija",
  "page-eth2-vision-trilemma-text-2": "Varnost",
  "page-eth2-vision-trilemma-text-3": "Razširljivost",
  "page-eth2-vision-trilemma-title-1": "Raziščite trilemo glede razširljivosti",
  "page-eth2-vision-trilemma-title-2": "Nadgradnje Eth2 in decentralizirana razširljivost",
  "page-eth2-vision-trilemma-title-3": "Varen in decentraliziran",
  "page-eth2-vision-trilemma-title-4": "Decentraliziran in razširljiv",
  "page-eth2-vision-trilemma-title-5": "Razširljiv in varen",
  "page-eth2-vision-understanding": "Razumevanje vizije Eth2",
  "page-eth2-vision-upgrade-needs": "Potrebe bo nadgradnjah Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Protokol Ethereum, ki je bil uveden leta 2015, je neverjetno uspešen. Vendar je Ethereumova skupnost vedno pričakovala, da bo za doseganje polnega potenciala Ethereuma potrebnih nekaj ključnih nadgradenj.",
  "page-eth2-vision-upgrade-needs-desc-2": "Visoko povpraševanje veča provizije na transakcije, zaradi česar je Ethereum drag za povprečnega uporabnika. Prostor na disku, ki je potreben za izvajanje odjemalca Ethereum, zelo hitro raste. Osnovni algoritem za doseganje soglasja z dokazom o delu, ki varuje Ethereum in zagotavlja njegovo decentralizacijo, pa ima velik vpliv na okolje.",
  "page-eth2-vision-upgrade-needs-desc-3": "Kar se običajno imenuje Eth2 je sklop nadgradenj, ki obravnavajo te težave in druge. Ta sploh nadgradenj je bil sprva imenovan »Serenity« in je predmet aktivnih raziskav in razvoja od leta 2014.",
  "page-eth2-vision-upgrade-needs-desc-6": " To pomeni, da Eth2 ne bo vklopljen s klikom na gumb. Izboljšave bodo uvedene postopoma.",
  "page-eth2-vision-upgrade-needs-serenity": "Preberite si objavo na spletnem dnevniku iz leta 2015, imenovano »Serenity«",
  "page-eth2-vision-uprade-needs-desc-5": "Zdaj, ko je tehnologija pripravljena, bodo te nadgradnje spremenile arhitekturo Ethereuma, tako da bo postal bolj razširljiv, varnejši in bolj trajnosten, kar bo olajšalo življenje obstoječih uporabnikov in privabilo nove. Hkrati pa bo ohranilo temeljno vrednoto Ethereuma, in sicer decentralizacijo.",
  "page-get-eth-cex": "Centralizirane borze",
  "page-get-eth-cex-desc": "Borze so podjetja, ki omogočajo nakup kriptovalut z uporabo tradicionalnih valut. Imajo skrbništvo nad vsem ETH, ki ga kupite, dokler ga ne pošljete v denarnico, ki je pod vašim nadzorom.",
  "page-get-eth-checkout-dapps-btn": "Preverite dec. aplikacije",
  "page-get-eth-community-safety": "Objave skupnosti o varnosti",
  "page-get-eth-description": "Ethereum in ETH nista pod nadzorom katerekoli vlade ali podjetja – sta decentralizirana. To pomeni, da lahko vsak uporablja ETH.",
  "page-get-eth-dex": "Decentralizirane borze (DEX)",
  "page-get-eth-dex-desc": "Če želite več nadzora, kupite ETH od enakovrednega uporabnika. Z decentralizirano borzo lahko trgujete, ne da bi nadzor nad svojimi sredstvi dodelili centraliziranemu podjetju.",
  "page-get-eth-dexs": "Decentralizirane borze (DEX)",
  "page-get-eth-dexs-desc": "Decentralizirane borze so odprti trgi za ETH in druge žetone. Neposredno povezujejo kupce in prodajalce.",
  "page-get-eth-dexs-desc-2": "Namesto, da bi za varovanje sredstev pri transakciji uporabljale zaupanja vredno tretjo osebo, uporabljajo kodo. Prodajalčev ETH bo prenesen šele, ko bo plačilo zagotovljeno. Ta vrsta kode se imenuje pametna pogodba.",
  "page-get-eth-dexs-desc-3": "To pomeni, da je manj geografskih omejitev kot pri centraliziranih alternativah. Če nekdo prodaja, kar želite, in sprejema način plačila, ki ga lahko zagotovite, ste pripravljeni za trgovanje. Decentralizirane borze omogočajo kupovanje ETH z drugimi žetoni, PayPalom ali celo dostavo denarja v živo.",
  "page-get-eth-do-not-copy": "PRIMER: NE KOPIRAJ",
  "page-get-eth-exchanges-disclaimer": "Te informacije smo zbrali ročno. Če opazite kakršnokoli napako, nas obvestite na",
  "page-get-eth-exchanges-empty-state-text": "Vnesite svojo državo prebivališča za ogled seznama denarnic in borz, ki jih lahko uporabite za nakup ETH",
  "page-get-eth-exchanges-except": "Razen",
  "page-get-eth-exchanges-header": "V kateri državi živite?",
  "page-get-eth-exchanges-header-exchanges": "Borze",
  "page-get-eth-exchanges-header-wallets": "Denarnice",
  "page-get-eth-exchanges-intro": "Borze in denarnice imajo omejitve glede tega, kje lahko prodajajo kriptovalute.",
  "page-get-eth-exchanges-no-exchanges": "Žal ne poznamo borz, ki bi omogočale kupovanje ETH v tej državi. Če jih poznate vi, nam sporočite na",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Žal ne poznamo borz ali denarnic, ki bi omogočale kupovanje ETH v tej državi. Če jih poznate vi, nam sporočite na",
  "page-get-eth-exchanges-no-wallets": "Žal ne poznamo denarnic, ki bi omogočale kupovanje ETH v tej državi. Če jih poznate vi, nam sporočite na",
  "page-get-eth-exchanges-success-exchange": "Registracija na borzi lahko traja več dni zaradi njihovih pravnih pregledov.",
  "page-get-eth-exchanges-success-wallet-link": "denarnice",
  "page-get-eth-exchanges-success-wallet-paragraph": "V kraju, kjer živite, lahko ETH kupite neposredno prek teh denarnic. Preberite več o",
  "page-get-eth-exchanges-usa": "Združene države Amerike (ZDA)",
  "page-get-eth-get-wallet-btn": "Pridobite denarnico",
  "page-get-eth-hero-image-alt": "Uvodna slika Pridobite ETH",
  "page-get-eth-keep-it-safe": "Varovanje vašega ETH",
  "page-get-eth-meta-description": "Kako kupiti ETH glede na vaše prebivališče in nasveti, kako paziti nanj.",
  "page-get-eth-meta-title": "Kako kupiti ETH",
  "page-get-eth-need-wallet": "Za uporabo decentralizirane borze boste potrebovali denarnico.",
  "page-get-eth-new-to-eth": "Ali ste nov uporabnik ETH? Tukaj preberite, kako začeti.",
  "page-get-eth-other-cryptos": "Kupite z drugimi kriptovalutami",
  "page-get-eth-protect-eth-desc": "Če nameravate kupiti veliko ETH, ga boste morda želeli hraniti na denarnici pod svojim nadzorom, ne na borzi. Razlog je ta, da so borze pogoste tarče za hekerje. Če heker pridobi dostop, lahko izgubite vsa sredstva, sicer pa denarnico nadzirate samo vi.",
  "page-get-eth-protect-eth-in-wallet": "Zaščitite svoj ETH v denarnici",
  "page-get-eth-search-by-country": "Iskanje po državi",
  "page-get-eth-security": "Vendar to pomeni tudi, da morate varnost svojih sredstev jemati resno. Z ETH ne zaupate banki, da bo pazila na vaš denar, temveč zaupate samemu sebi.",
  "page-get-eth-smart-contract-link": "Več o pametnih pogodbah",
  "page-get-eth-swapping": "Zamenjajte svoje žetone za ETH drugih. In obratno.",
  "page-get-eth-traditional-currencies": "Nakup s tradicionalnimi valutami",
  "page-get-eth-traditional-payments": "Kupite ETH s tradicionalnimi načini plačila kar neposredno od prodajalcev.",
  "page-get-eth-try-dex": "Poskusite DEX",
  "page-get-eth-use-your-eth": "Uporabite svoj ETH",
  "page-get-eth-use-your-eth-dapps": "Zdaj, ko imate nekaj ETH, si oglejte nekaj aplikacij na Ethereumu (decentralizirane aplikacije). Na voljo so aplikacije za finance, družbene medije, igre in številne druge kategorije.",
  "page-get-eth-wallet-instructions": "Sledite navodilom denarnice",
  "page-get-eth-wallet-instructions-lost": "Če izgubite dostop do denarnice, boste izgubili dostop do svojih sredstev. Denarnica mora imeti navodila, kako se zaščititi pred tem. Skrbno jih upoštevajte. V večini primerov vam ne more nihče pomagati, če izgubite dostop do denarnice.",
  "page-get-eth-wallets": "Denarnice",
  "page-get-eth-wallets-link": "Več o denarnicah",
  "page-get-eth-wallets-purchasing": "Nekatere denarnice omogočajo kupovanje kriptovalut z debetno/kreditno kartico, bančnim nakazilom ali celo storitvijo Apple Pay. Veljajo geografske omejitve.",
  "page-get-eth-warning": "Te decentralizirane borze niso namenjene začetnikom, saj boste za uporabo potrebovali nekaj ETH.",
  "page-get-eth-what-are-DEX's": "Kaj so dec. borze?",
  "page-get-eth-whats-eth-link": "Kaj je ETH?",
  "page-get-eth-where-to-buy-desc": "ETH lahko kupite prek borz ali neposredno prek denarnic.",
  "page-get-eth-where-to-buy-desc-2": "Preverite, katere storitve lahko uporabljate, glede na to, kje živite.",
  "page-get-eth-where-to-buy-title": "Kje kupiti ETH",
  "page-get-eth-your-address": "Vaš naslov ETH",
  "page-get-eth-your-address-desc": "Ko prenesete denarnico, bo ta v vašem imenu ustvarila javni naslov za ETH. Videti je tako:",
  "page-get-eth-your-address-desc-3": "Na ta naslov lahko gledate kot na svoj e-poštni naslovu, samo da lahko namesto pošte prejmete ETH. Če želite ETH prenesti z borze v svojo denarnico, kot cilj uporabite svoj naslov. Pred pošiljanjem ga vedno dvakrat preverite!",
  "page-get-eth-your-address-wallet-link": "Oglejte si denarnice",
  "page-index-hero-image-alt": "temeljna slika za ethereum.org",
  "page-index-meta-description": "Ethereum je globalna, decentralizirana platforma za denar in nove vrste aplikacij. V Ethereumu lahko pišete programsko kodo, ki upravlja denar, in gradite aplikacije, ki so dostopne povsod po svetu.",
  "page-index-meta-title": "Začetna stran",
  "page-index-title": "Ethereum je globalna in odprtokodna platforma za decentralizirane aplikacije.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Jezikovna podpora",
  "page-languages-interested": "Ste pripravljeni prispevati?",
  "page-languages-learn-more": "Preberite več o našem programu prevajanja",
  "page-languages-meta-desc": "Gradivo za vse podprte jezike spletnega mesta ethereum.org in načini, kako lahko sodelujete kot prevajalec.",
  "page-languages-meta-title": "Jezikovni prevodi ethereum.org",
  "page-languages-p1": "Ethereum je globalen projekt in ključno je, da je ethereum.org na voljo vsem ne glede na njihovo narodnost ali jezik. Naša skupnost si močno prizadeva uresničiti to vizijo.",
  "page-languages-translations-available": "ethereum.org je na voljo v naslednjih jezikih",
  "page-languages-want-more-header": "Ali želite ethereum.org v drugem jeziku?",
  "page-languages-want-more-link": "programu prevajanja",
  "page-languages-want-more-paragraph": "Prevajalci ethereum.org vedno prevajajo strani v čimveč jezikov. Če si želite ogledati, na čem trenutno delajo, ali se želite prijaviti in se jim pridružiti, preberite več o našem",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Kripto varščina",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Z Ethereumom si lahko izposojate neposredno od drugih uporabnikov, ne da bi morali zamenjati svoj ETH. To vam lahko ponudi vzvod – nekateri to uporabljajo za nabiranje več ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Toda ker je cena ETH volatilna, boste potrebovali presežno varščino. To pomeni, da boste verjetno potrebovali okoli 150 USD v ETH, če si boste želeli izposoditi 100 stabilnih kovancev. To varuje sistem in posojilodajalce.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Več o stabilnih kovancih na podlagi kriptovalut",
  "page-stablecoins-accordion-borrow-pill": "Dodatno",
  "page-stablecoins-accordion-borrow-places-intro": "Te decentralizirane aplikacije omogočajo izposojanje stabilnih kovancev z uporabo kriptovalut kot varščine. Nekateri poleg ETH sprejemajo tudi druge žetone.",
  "page-stablecoins-accordion-borrow-places-title": "Kje si lahko izposodite stabilne kovance",
  "page-stablecoins-accordion-borrow-requirement-1": "Denarnica Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Za uporabo decentralizirane aplikacije boste potrebovali denarnico",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "ETH boste potrebovali za varščino in/ali provizije na transakcije",
  "page-stablecoins-accordion-borrow-requirements-description": "Za izposojo stabilnih kovancev boste morali uporabiti pravilno decentralizirano aplikacijo. Prav tako boste potrebovali denarnico in nekaj ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Če uporabite ETH kot varščino in njegova cena pade, varščina ne bo krila stabilnih kovancev, ki ste jih generirali. Zaradi tega bo vaš ETH likvidiran in morda boste morali plačati kazen. Če si izposojate stabilne kovance, boste morali biti torej pozorni na ceno ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Zadnja cena ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Tveganja",
  "page-stablecoins-accordion-borrow-text-preview": "Izposodite si lahko nekaj stabilnih kovancev z uporabo kriptovalut kot varščine, ki jo morate nato vrniti.",
  "page-stablecoins-accordion-borrow-title": "Izposoja",
  "page-stablecoins-accordion-buy-exchanges-title": "Priljubljene borze",
  "page-stablecoins-accordion-buy-requirement-1": "Kriptoborze in denarnice",
  "page-stablecoins-accordion-buy-requirement-1-description": "Preverite, katere storitve lahko uporabljate, kjer živite",
  "page-stablecoins-accordion-buy-requirements-description": "Račun na borzi ali denarnici, kjer lahko neposredno kupite kriptovalute. Morda ste ga že uporabljali, da ste pridobili nekaj ETH. Preverite, katere storitve lahko uporabljate, kjer živite.",
  "page-stablecoins-accordion-buy-text-preview": "Številne borze in denarnice omogočajo neposredno izposojo stabilnih kovancev. Geografske omejitve lahko veljajo.",
  "page-stablecoins-accordion-buy-title": "Nakup",
  "page-stablecoins-accordion-buy-warning": "Na centraliziranih borzah lahko kotirajo samo stabilni kovanci, ki temeljijo na fiat valutah, kot so USDC, Tether in drugi. Morda jih ne morete kupiti neposredno, vendar bi jih morali biti zmožni zamenjati za ETH ali druge kriptovalute, ki jih je mogoče kupiti na platformi.",
  "page-stablecoins-accordion-earn-project-1-description": "Večinoma tehnično delo za gibanje za odprtokodno programsko opremo.",
  "page-stablecoins-accordion-earn-project-2-description": "Tehnologija, vsebina in drugo delo za skupnost MakerDao (ekipa, ki vam je ponudila Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Ko se zares spoznate, iščite napake in služite Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Nagrade Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Nagrade za odkrivanje napak Eth2",
  "page-stablecoins-accordion-earn-project-community": "Skupnost MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "To so platforme, ki vam bodo za delo plačale v stabilnih kovancih.",
  "page-stablecoins-accordion-earn-projects-title": "Kje zaslužiti stabilne kovance",
  "page-stablecoins-accordion-earn-requirement-1": "Denarnica Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Če želite prejeti zaslužene stabilne kovance, boste potrebovali denarnico",
  "page-stablecoins-accordion-earn-requirements-description": "Stabilni kovanci so odličen način za prejem plačila za delo in storitve, saj je njihova vrednost stabilna. Vendar boste za prejem plačila potrebovali denarnico.",
  "page-stablecoins-accordion-earn-text-preview": "Stabilne kovance lahko zaslužite z delom na projektih v ekosistemu Ethereum.",
  "page-stablecoins-accordion-earn-title": "Zaslužite",
  "page-stablecoins-accordion-less": "Manj",
  "page-stablecoins-accordion-more": "Več",
  "page-stablecoins-accordion-requirements": "Kaj boste potrebovali",
  "page-stablecoins-accordion-swap-dapp-intro": "Če že imate ETH in denarnico, lahko uporabite te decentralizirane aplikacije in ga zamenjate za stabilne kovance.",
  "page-stablecoins-accordion-swap-dapp-link": "Več o decentraliziranih borzah",
  "page-stablecoins-accordion-swap-dapp-title": "Decentralizirane aplikacije za menjavo žetonov",
  "page-stablecoins-accordion-swap-editors-tip": "Nasvet urednika",
  "page-stablecoins-accordion-swap-editors-tip-button": "Poiščite denarnice",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Poiščite denarnico, ki vam bo omogočala kupovanje ETH in neposredno zamenjavo za žetone, vključno s stabilnimi kovanci.",
  "page-stablecoins-accordion-swap-pill": "Priporočeno",
  "page-stablecoins-accordion-swap-requirement-1": "Denarnica Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Če želite odobriti zamenjavo in shraniti kovance, boste potrebovali denarnico",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Za plačilo zamenjave",
  "page-stablecoins-accordion-swap-text-preview": "Večino stabilnih kovancev lahko pridobite na decentraliziranih borzah. Tako lahko svoje žetone zamenjate za stabilne kovance, ki jih želite.",
  "page-stablecoins-accordion-swap-title": "Zamenjava",
  "page-stablecoins-algorithmic": "Algoritmična",
  "page-stablecoins-algorithmic-con-1": "Zaupati morate algoritmu (ali ga znati prebrati).",
  "page-stablecoins-algorithmic-con-2": "Vaše stanje kovancev se bo spremenilo glede na skupno zalogo.",
  "page-stablecoins-algorithmic-description": "Ti stabilni kovanci ne temeljijo na nobenem drugem sredstvu. Namesto tega bo algoritem prodal žetone, če cena pade pod želeno vrednost, in zagotovil nove žetone, če vrednost naraste nad želeno vrednost. Ker se število teh žetonov v obtoku nenehno spreminja, se bo število žetonov, ki jih imate v lasti, spremenilo, vendar bo vedno odražalo vaš delež.",
  "page-stablecoins-algorithmic-pro-1": "Varščina ni potrebna.",
  "page-stablecoins-algorithmic-pro-2": "Pod nadzorom javnega algoritma.",
  "page-stablecoins-bank-apy": "0,05 %",
  "page-stablecoins-bank-apy-source": "Povprečna provizija, ki jo banke izplačujejo na osnovnih, zvezno zavarovanih varčevalnih računih, v ZDA.",
  "page-stablecoins-bank-apy-source-link": "Vir",
  "page-stablecoins-bitcoin-pizza": "Zloglasna Bitcoin pica",
  "page-stablecoins-bitcoin-pizza-body": "Leta 2010 je nekdo kupil 2 pici za 10.000 bitcoinov. Takrat je bilo to enakovredno približno 41 USD. Na današnjem trgu je vrednost narasla na več milijonov dolarjev. V Ethereumovi zgodovini obstajajo številne podobno transakcije, vredne obžalovanja. Stabilni kovanci rešujejo to težavo, tako da lahko uživate v pici in obdržite svoj ETH.",
  "page-stablecoins-coin-price-change": "Spremembe cen kovancev (zadnjih 30 dni)",
  "page-stablecoins-crypto-backed": "Temeljijo na kriptografiji",
  "page-stablecoins-crypto-backed-con-1": "Manj stabilni kot stabilni kovanci, ki temeljijo na fiat valutah.",
  "page-stablecoins-crypto-backed-con-2": "Pozorni morate biti na vrednost svoje kriptovarščine.",
  "page-stablecoins-crypto-backed-description": "Ti stabilni kovanci temeljijo na drugih kripto sredstvih, kot je ETH. Njihova cena je odvisna od vrednosti osnovnega sredstva (ali varščine), ki je lahko volatilno. Ker lahko vrednost ETH niha, imajo ti stabilni kovanci presežno varščino, tako da cena ostane čim dlje stabilna. To pomeni, da je primerneje reči, da ima stabilni kovanec v vrednosti 1 USD osnovno kriptosredstvo v vrednosti vsaj 2 USD. Če cena ETH pade, je treba za vnovični nakup stabilnega kovanca torej porabiti več ETH, sicer bi stabilni kovanci izgubili svojo vrednost.",
  "page-stablecoins-crypto-backed-pro-1": "Pregledni in povsem decentralizirani.",
  "page-stablecoins-crypto-backed-pro-2": "Hitro jih je mogoče pretvoriti v druga kripto sredstva.",
  "page-stablecoins-crypto-backed-pro-3": "Brez zunanjih skrbnikov – vsa sredstva nadzira račun Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai je verjetno najbolj znan decentraliziran stabilni kovanec. Njegova vrednost je približno en dolar, sprejemajo pa ga številne decentralizirane aplikacije.",
  "page-stablecoins-dai-banner-learn-button": "Več o kovancu Dai",
  "page-stablecoins-dai-banner-swap-button": "Zamenjajte ETH za Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Logotip Dai",
  "page-stablecoins-editors-choice": "Izbira urednikov",
  "page-stablecoins-editors-choice-intro": "To sta trenutno verjetno najbolj znana primera stabilnih kovancev in kovanca, ki sta bila za nas zelo uporabna pri uporabi decentraliziranih aplikacij.",
  "page-stablecoins-explore-dapps": "Raziščite dec. aplikacije",
  "page-stablecoins-fiat-backed": "Na podlagi fiat valut",
  "page-stablecoins-fiat-backed-con-1": "Centralizirani – nekdo mora izdajati žetone.",
  "page-stablecoins-fiat-backed-con-2": "Potrebne so revizije za zagotavljanje, da ima podjetje ustrezne rezerve.",
  "page-stablecoins-fiat-backed-description": "V bistvu zadolžnica za tradicionalno fiat valuto (običajno dolarje). Uporabite fiat valuto za nakup stabilnega kovanca, ki ga lahko kasneje unovčite in zamenjate za prvotno valuto.",
  "page-stablecoins-fiat-backed-pro-1": "Varni pred nestabilnostjo kriptovalut.",
  "page-stablecoins-fiat-backed-pro-2": "Spremembe cen so minimalne.",
  "page-stablecoins-find-stablecoin": "Poiščite stabilni kovanec",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Kako pridobiti stabilne kovance",
  "page-stablecoins-find-stablecoin-intro": "Na voljo je več sto stabilnih kovancev. Tukaj jih je nekaj, ki bi vam lahko bili v pomoč. Če ste novi uporabnik Ethereuma, priporočamo, da zadevno najprej malo preučite.",
  "page-stablecoins-find-stablecoin-types-link": "Različne vrste stabilnih kovancev",
  "page-stablecoins-get-stablecoins": "Kako pridobiti stabilne kovance",
  "page-stablecoins-hero-alt": "Trije največji stabilni kovanci po tržni kapitalizaciji: Dai, USDC in Tether.",
  "page-stablecoins-hero-button": "Pridobite stabilne kovance",
  "page-stablecoins-hero-header": "Digitalni denar za vsakodnevno uporabo",
  "page-stablecoins-hero-subtitle": "Stabilni kovanci so žetoni na Ethereumu, zasnovani tako, da ohranijo fiksno vrednost, tudi ko se cena ETH spremeni.",
  "page-stablecoins-interest-earning-dapps": "Decentralizirane aplikacije z obrestmi",
  "page-stablecoins-meta-description": "Predstavitev stabilnih kovancev na Ethereumu: kaj so, kako jih dobiti in zakaj so pomembni.",
  "page-stablecoins-precious-metals": "Plemenite kovine",
  "page-stablecoins-precious-metals-con-1": "Centralizirani – nekdo mora izdajati žetone.",
  "page-stablecoins-precious-metals-con-2": "Zaupati morate izdajatelju žetona in rezervam plemenitih kovin.",
  "page-stablecoins-precious-metals-description": "Kot kovanci, ki temeljijo na fiat valutah, samo da ti stabilni kovanci za ohranjanje vrednosti uporabljajo vire, kot je zlato.",
  "page-stablecoins-precious-metals-pro-1": "Varni pred nestabilnostjo kriptovalut.",
  "page-stablecoins-prices": "Cene stabilnih kovancev",
  "page-stablecoins-prices-definition": "Stabilni kovanci so kriptovalute brez nestabilnosti. Imajo številne enake funkcije kot ETH, le da je njihova vrednost stabilna podobno kot pri tradicionalnih valutah. Tako boste imeli dostop do stabilnega denarja, ki ga lahko uporabljate na Ethereumu. ",
  "page-stablecoins-prices-definition-how": "Kaj stabilnim kovancem zagotavlja njihovo stabilnost",
  "page-stablecoins-research-warning": "Ethereum je nova tehnologija, večina aplikacij pa je novih. Poskrbite, da se zavedate tveganja in položite samo, kolikor ste pripravljeni izgubiti.",
  "page-stablecoins-research-warning-title": "Vedno opravite lastno raziskavo",
  "page-stablecoins-save-stablecoins": "Varčujte s stabilnimi kovanci",
  "page-stablecoins-save-stablecoins-body": "Stabilni kovanci pogosto ponujajo nadpovprečno visoko obrestno mero, ker je veliko povpraševanja po izposoji teh kovancev. Obstajajo decentralizirane aplikacije, ki omogočajo, da si sproti prislužite  obresti za stabilne kovance, tako da jih položite v posojilni sklad. Kot v svetu bančništva zagotavljate žetone za posojilojemalce, vendar lahko svoje žetone in obresti kadar koli dvignete.",
  "page-stablecoins-saving": "Dobro izkoristite svoje prihranke v obliki stabilnih kovancev in si prislužite nekaj obresti. Kot pri vseh stvareh, povezanimi s kriptovalutami, se lahko predvideni letni donos v odstotkih (APY) vsak dan spremeni glede na sprotno povpraševanje/ponudbo.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Preverite decentralizirane aplikacije na Ethereumu – za vsakodnevne transakcije so stabilni kovanci pogosto najbolj uporabni.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Slika psa doge.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Uporabite svoje stabilne kovance",
  "page-stablecoins-stablecoins-dapp-description-1": "Trgi za številne stabilne kovance, vključno s kovanci Dai, USDC, TUSD, USDT in drugimi. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Posojajte stabilne kovance in zaslužite obresti v $COMP, lastnem žetonu platforme Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Platforma za trgovanje, kjer lahko zaslužite obresti na svoj Dai in USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Aplikacija, zasnovana za varčevanje s kovancem Dai.",
  "page-stablecoins-stablecoins-feature-1": "Stabilni kovanci so globalni in jih je mogoče poslati prek interneta. Ko imate račun za Ethereum, jih je povsem preprosto prejemati in pošiljati.",
  "page-stablecoins-stablecoins-feature-2": "Povpraševanje po izposoji stabilnih kovancev je visoko, tako da si lahko prislužite obresti za izposojo svojih. Pred posojanjem se ne pozabite seznaniti s tveganji.",
  "page-stablecoins-stablecoins-feature-3": "Stabilne kovance je mogoče zamenjati za ETH in druge žetone Ethereum. Številne decentralizirane aplikacije uporabljajo stabilne kovance.",
  "page-stablecoins-stablecoins-feature-4": "Stabilne kovance varuje kriptografija. Nihče ne more ponarejati transakcij v vašem imenu.",
  "page-stablecoins-stablecoins-meta-description": "Predstavitev stabilnih kovancev na Ethereumu: kaj so, kako jih dobiti in zakaj so pomembni.",
  "page-stablecoins-stablecoins-table-header-column-1": "Valuta",
  "page-stablecoins-stablecoins-table-header-column-2": "Tržna kapitalizacija",
  "page-stablecoins-stablecoins-table-header-column-3": "Vrsta varščine",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Kripto",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Fiat",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Plemenite kovine",
  "page-stablecoins-table-error": "Stabilnih kovancev ni bilo mogoče naložiti. Poskusite osvežiti stran.",
  "page-stablecoins-table-loading": "Nalaganje podatkov o stabilnih kovancih ...",
  "page-stablecoins-title": "Stabilni kovanci",
  "page-stablecoins-top-coins": "Vodilni stabilni kovanci po tržni kapitalizaciji",
  "page-stablecoins-top-coins-intro": "Tržna kapitalizacija je",
  "page-stablecoins-top-coins-intro-code": "skupno število žetonov, ki obstajajo, pomnoženo z vrednostjo žetona. Seznam je dinamičen, projekti, navedeni tukaj, pa niso nujno podprti s strani ekipe ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Kako delujejo: vrste stabilnih kovancev",
  "page-stablecoins-usdc-banner-body": "Dai je verjetno najbolj znan stabilni kovanec, ki temelji na fiat valuti. Njegova vrednost je približno en dolar, podpirata pa ga družbi Circle in Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Več o kovancu USDC",
  "page-stablecoins-usdc-banner-swap-button": "Zamenjajte ETH za USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Logotip USDC",
  "page-stablecoins-why-stablecoins": "Zakaj stabilni kovanci?",
  "page-stablecoins-how-they-work-button": "Kako delujejo",
  "page-stablecoins-why-stablecoins-body": "Ker gre za novi tehnologiji, je cena ETH nestabilna podobno kot pri bitcoinu. Zato ga morda ne želite redno zapravljati. Stabilni kovanci odražajo vrednost tradicionalnih valut, da vam omogočijo dostop do stabilnega denarja, ki ga lahko uporabljate na Ethereumu.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Če želite, da dodamo denarnico,",
  "page-find-wallet-alpha-logo-alt": "Logotip AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Logotip Ambo",
  "page-find-wallet-argent-logo-alt": "Logotip Argent",
  "page-find-wallet-buy-card": "Kupite kripto s kartico",
  "page-find-wallet-buy-card-desc": "Kupite ETH neposredno iz denarnice, in sicer z bančno kartico. Veljajo lahko geografske omejitve.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Oglejte si dec. aplikacije",
  "page-find-wallet-clear": "Počisti filtre",
  "page-find-wallet-coinbase-logo-alt": "Logotip Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Zato izberite denarnico glede na funkcije, ki jih želite.",
  "page-find-wallet-description": "Denarnice imajo številne neobvezne funkcije, ki vam bodo morda všeč.",
  "page-find-wallet-description-alpha": "Povsem odprtokodna denarnica Ethereum, ki uporablja vso moč sistema Secure Enclave v mobilnih napravah, ponuja popolno podporo za testno omrežje in sprejema standard TokenScript.",
  "page-find-wallet-description-ambo": "Preskočite neposredno na vlaganje in pridite do svoje prve naložbe v nekaj minutah od prenosa aplikacije",
  "page-find-wallet-description-argent": "Enkrat tapnite in si prislužite obresti in vlagajte; izposojajte si, varčujte in pošiljajte. Poskusite.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Varna aplikacija, s katero lahko sami shranite kriptovalute",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma je vaš portal za DeFi. Nič več varnostnih fraz in razširitev za Chrome.",
  "page-find-wallet-description-enjin": "Neprebojna, s številnimi funkcijami in priročna — ustvarjena za trgovce, igričarje in razvijalce",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Najbolj zaupanja vredna platforma za shranjevanje digitalnih sredstev na Ethereumu",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken je preprosta in varna digitalna denarnica, ki ji zaupajo milijoni",
  "page-find-wallet-description-ledger": "Zaščitite svoje premoženje z najvišjimi varnostnimi standardi",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Začnite raziskovati aplikacije na verigi blokov v nekaj sekundah. Zaupa ji več kot 1 milijon uporabnikov po vsem svetu.",
  "page-find-wallet-description-monolith": "Edina samoskrbniška denarnica na svetu v kombinaciji z debetno kartico Visa. Na voljo v Združenem kraljestvu in EU ter uporabna po vsem svetu.",
  "page-find-wallet-description-multis": "Multis je račun za kriptovalute, zasnovan za podjetja. Z Multis lahko podjetja hranijo sredstva z nadzorom dostopa, prislužijo obresti na prihranke ter izboljšajo plačila in računovodstvo.",
  "page-find-wallet-description-mycrypto": "MyCrypto je vmesnik za upravljanje vseh vaših računov. Kriptovalute menjajte, pošiljajte in kupujte z denarnicami, kot so MetaMask, Ledger, Trezor in več.",
  "page-find-wallet-description-myetherwallet": "Brezplačen vmesnik za odjemalce, ki omogoča lažjo interakcijo z verigo blokov Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Neskrbniška denarnica v lasti skupnosti z lastnim plačilnim omrežjem na drugi ravni (L2).",
  "page-find-wallet-description-portis": "Neksrbniška denarnica za verige blokov, ki poenostavlja aplikacije za vsakogar",
  "page-find-wallet-description-rainbow": "Boljši dom za vaša sredstva Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Aplikacija za varno sporočanje, kripto denarnica in brskalnik za Web3 z vgrajeno najsodobnejšo tehnologijo",
  "page-find-wallet-description-tokenpocket": "TokenPocket：Varna in priročna vodilna denarnica za digitalne valute in portal do decentraliziranih aplikacij s podporo za več verig.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Prijava za Web 3.0 z enim klikom",
  "page-find-wallet-description-trezor": "Prva in originalna strojna denarnica",
  "page-find-wallet-description-trust": "Trust Wallet je decentralizirana denarnica za kriptovalute s podporo za več kovancev. Kupujte kriptovalute, raziskujte decentralizirane aplikacije, menjajte sredstva in več ter ohranite nadzor nad svojimi ključi.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo je prva kriptodenarnica brez ključev. Pri ZenGo ni zasebnih ključev, gesel ali varnostnih fraz, ki jih morate upravljati in jih lahko izgubite. Kupujte, trgujte in hranite Ethereum preprosto in varno, kot še nikoli doslej",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Logotip Dharma",
  "page-find-wallet-enjin-logo-alt": "Logotip Enjin",
  "page-find-wallet-Ethereum-wallets": "Denarnice Ethereum",
  "page-find-wallet-explore-dapps": "Raziščite dec. aplikacije",
  "page-find-wallet-explore-dapps-desc": "Te denarnice so zasnovane tako, da se boste lažje povezali z decentraliziranimi aplikacijami Ethereum.",
  "page-find-wallet-feature-h2": "Izberite funkcije denarnice, ki so pomembne za vas",
  "page-find-wallet-fi-tools": "Dostop do finančnih orodij",
  "page-find-wallet-fi-tools-desc": "Izposojajte si, posojajte in si prislužite obresti neposredno v denarnici.",
  "page-find-wallet-following-features": "z naslednjimi funkcijami:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Logotip Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Uvodna slika za iskanje denarnice",
  "page-find-wallet-imtoken-logo-alt": "Logotip imToken",
  "page-find-wallet-last-updated": "Nazadnje posodobljeno",
  "page-find-wallet-ledger-logo-alt": "Logotip Ledger",
  "page-find-wallet-limits": "Zaščita omejitev",
  "page-find-wallet-limits-desc": "Zaščitite svoje premoženje z nastavljanjem limitov, ki preprečujejo izpraznitev računa.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "pravilniku za dodajanje projektov",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Poiščite in primerjajte denarnice Ethereum glede na funkcije, ki jih želite.",
  "page-find-wallet-meta-title": "Poiščite denarnico Ethereum",
  "page-find-wallet-metamask-logo-alt": "Logotip MetaMask",
  "page-find-wallet-monolith-logo-alt": "Logotip Monolith",
  "page-find-wallet-multis-logo-alt": "Logotip Multis",
  "page-find-wallet-multisig": "Računi z več podpisi",
  "page-find-wallet-multisig-desc": "Denarnice z več podpisi za dodatno varnost zahtevajo več kot en račun za odobritev določenih transakcij.",
  "page-find-wallet-mycrypto-logo-alt": "Logotip MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Logotip MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Prvič uporabljate denarnice? Tukaj si lahko ogledate pregled, kako začeti.",
  "page-find-wallet-new-to-wallets-link": "Denarnice Ethereum",
  "page-find-wallet-not-all-features": "Vseh teh funkcij nima še nobena denarnica",
  "page-find-wallet-not-endorsements": "Denarnice, navedene na tej strani, ne predstavljajo uradne podpore in so navedene samo za informativne namene. Njihove opise so zagotovila sama podjetja, ki so razvila denarnice. Izdelke na to stran dodajamo na podlagi meril v našem",
  "page-find-wallet-overwhelmed": "denarnic Ethereum je prikazanih spodaj. Imate preveč izbire? Poskusite filtrirati po funkcijah.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Logotip Pillar",
  "page-find-wallet-portis-logo-alt": "Logotip Portis",
  "page-find-wallet-rainbow-logo-alt": "Logotip Rainbow",
  "page-find-wallet-raise-an-issue": "ustvarite zadevo na Githubu",
  "page-find-wallet-search-btn": "Iskanje izbranih funkcij",
  "page-find-wallet-showing": "Prikazano ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Logotip Status",
  "page-find-wallet-swaps": "Decentralizirana zamenjava žetonov",
  "page-find-wallet-swaps-desc": "Trgujte med ETH in drugimi žetoni neposredno v svoji denarnici.",
  "page-find-wallet-title": "Poiščite denarnico",
  "page-find-wallet-tokenpocket-logo-alt": "Logotip TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Logotip Torus",
  "page-find-wallet-trezor-logo-alt": "Logotip Trezor",
  "page-find-wallet-trust-logo-alt": "Logotip Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Poskusite odstraniti funkcijo ali dve",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Zdaj, ko imate denarnico, preverite nekaj aplikacij na Ethereumu (decentralizirane aplikacije). Na voljo so aplikacije za finance, družbene medije, igre in številne druge kategorije.",
  "page-find-wallet-use-your-wallet": "Uporabite denarnico",
  "page-find-wallet-voluem-desc": "Če želite hraniti veliko ETH, izberite denarnico, ki omogoča nakup več kot 2000 USD v ETH hkrati.",
  "page-find-wallet-volume": "Nakupi velikih količin",
  "page-find-wallet-we-found": "Našli smo",
  "page-find-wallet-withdraw": "Dvigni na banko",
  "page-find-wallet-withdraw-desc": "Vrednost ETH lahko izplačate neposredno na svoj bančni račun, ne da bi morali uporabiti borzo.",
  "page-find-wallet-yet": ".",
  "page-find-wallet-zengo-logo-alt": "Logotip ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Zastavite ETH",
  "page-wallets-accounts-addresses": "Denarnice, računi in naslovi",
  "page-wallets-accounts-addresses-desc": "Vredno je razumeti razliko med nekaterimi ključnimi izrazi.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Slika robota s trezorjem namesto telesa, ki predstavlja Ethereum denarnico",
  "page-wallets-ethereum-account": "Račun Ethereum",
  "page-wallets-blog": "Spletni dnevnik Coinbase",
  "page-wallets-bookmarking": "Dodajte denarnico med zaznamke",
  "page-wallets-bookmarking-desc": "Če uporabljate spletno denarnico, dodajte to spletno mesto med zaznamke in se zaščitite pred goljufijami z lažnim predstavljanjem.",
  "page-wallets-cd": "Fizične strojne denarnice, ki omogočajo shranjevanje kriptovalut brez spletne povezave – zelo varne",
  "page-wallets-converted": "Ste navdušeni glede kriptovalut?",
  "page-wallets-converted-desc": "Če želite hraniti večje zneske, priporočamo strojno denarnico, saj so te najbolj varne. Ali pa denarnico z obvestili o goljufijah in omejitvami dvigov.",
  "page-wallets-curious": "Vas zanimajo kriptovalute?",
  "page-wallets-curious-desc": "Če ste novi uporabnik kriptovalut in želite dobiti samo malce občutka zanje, priporočamo nekaj, kar vam bo dalo priložnost za raziskovanje aplikacij na Ethereumu ali nakup prvega ETH neposredno iz denarnice.",
  "page-wallets-desc-2": "Denarnico potrebujete za pošiljanje sredstev in upravljanje ETH.",
  "page-wallets-desc-2-link": "Več o ETH",
  "page-wallets-desc-3": "Denarnica je samo orodje za upravljanje vašega Ethereum računa. To pomeni, da lahko kadarkoli zamenjate ponudnike denarnic. Številne denarnice omogočajo tudi upravljanje različnih Ethereum računov iz ene aplikacije.",
  "page-wallets-desc-4": "Razlog je ta, da denarnice nimajo skrbništva nad vašimi sredstvi, temveč ga imate vi. So samo orodje za upravljanje s tistim, kar je dejansko vaše.",
  "page-wallets-description": "Denarnice Ethereum so aplikacije, ki omogočajo interakcijo z računom Ethereum. Nanje lahko gledate kot na aplikacije za internetno bančništvo, vendar brez banke. Denarnica omogoča preverjanje stanja, pošiljanje transakcij in povezovanje z aplikacijami.",
  "page-wallets-desktop": "Namizne aplikacije, če svoja sredstva rajši upravljate prek sistemov MacOS, Windows ali Linux",
  "page-wallets-ethereum-wallet": "Denarnica",
  "page-wallets-explore": "Raziščite Ethereum",
  "page-wallets-features-desc": "Lahko vam pomagamo izbrati denarnico glede na funkcije, ki so vam pomembne.",
  "page-wallets-features-title": "Ali želite denarnico izbrati glede na funkcije?",
  "page-wallets-find-wallet-btn": "Poišči denarnico",
  "page-wallets-find-wallet-link": "Poiščite denarnico",
  "page-wallets-get-some": "Pridobite nekaj ETH",
  "page-wallets-get-some-alt": "Slika roke, ki ustvarja logotip ETH iz lego kock",
  "page-wallets-get-some-btn": "Pridobite nekaj ETH",
  "page-wallets-get-some-desc": "ETH je izvorna kriptovaluta Ethereuma. Za uporabo aplikacij na Ethereumu boste potrebovali nekaj ETH v svoji denarnici.",
  "page-wallets-get-wallet": "Pridobite denarnico",
  "page-wallets-get-wallet-desc": "Izbirate lahko med številnimi različnimi denarnicami. Želimo vam pomagati izbrati tisto, ki vam najbolj ustreza.",
  "page-wallets-get-wallet-desc-2": "Ne pozabite: ta odločitev ni dokončna – račun Ethereum ni vezan na vašega ponudnika denarnice.",
  "page-wallets-how-to-store": "Kako hraniti digitalna sredstva na Ethereumu",
  "page-wallets-keys-to-safety": "Temelji zaščite kriptovalut",
  "page-wallets-manage-funds": "Aplikacija za upravljanje premoženja",
  "page-wallets-manage-funds-desc": "Vaša denarnica prikazuje vaša stanja in zgodovino transakcij ter ponuja način pošiljanja/prejemanja sredstev. Nekatere denarnice lahko ponujajo še več.",
  "page-wallets-meta-description": "Kaj morate vedeti za uporabo denarnic Ethereum.",
  "page-wallets-meta-title": "Ethereum denarnice",
  "page-wallets-mobile": "Mobilne aplikacije, ki omogočajo dostop do sredstev kjerkoli",
  "page-wallets-more-on-dapps-btn": "Več o decentraliziranih aplikacijah",
  "page-wallets-most-wallets": "Večina denarnic omogoča generiranje računa Ethereum. Zato ga pred prenosom denarnice ne potrebujete.",
  "page-wallets-protecting-yourself": "Kako zaščitite sebe in svoje premoženje",
  "page-wallets-seed-phrase": "Napišite svojo varnostno frazo",
  "page-wallets-seed-phrase-desc": "Denarnice vam bodo pogosto ponudile varnostno frazo, ki jo morate zapisati na varno mesto. To je edini način, na katerega boste lahko obnovili denarnico.",
  "page-wallets-seed-phrase-example": "Tukaj je primer:",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "Ne shranite je v računalnik. Zapišite si jo in jo shranite na varno.",
  "page-wallets-slogan": "Ključ do vaše digitalne prihodnosti",
  "page-wallets-stay-safe": "Kako ostanete varni",
  "page-wallets-stay-safe-desc": "Denarnice predstavljajo manjši premik v načinu razmišljanja. Finančna svoboda in sposobnost uporabe sredstev kjerkoli vključujeta določeno stopnjo odgovornosti – v svetu kriptovalut ni podpore za stranke.",
  "page-wallets-subtitle": "Denarnice omogočajo dostop do vaših sredstev in aplikacij na Ethereumu. Samo vi bi morali imeti dostop do svoje denarnice.",
  "page-wallets-take-responsibility": "Prevzemite odgovornost za svoje premoženje",
  "page-wallets-take-responsibility-desc": "Centralizirane borze bodo povezale vašo denarnico z uporabniškim imenom in geslom, ki ju je mogoče obnoviti na običajen način. Toda ne pozabite, da skrbništvo svojih sredstev zaupate borzi. Če je to podjetje napadeno ali gre v srečaj, so vaša sredstva ogrožena.",
  "page-wallets-tips": "Več nasvetov za zagotavljanje varnosti",
  "page-wallets-tips-community": "Iz skupnosti",
  "page-wallets-title": "Denarnice Ethereum",
  "page-wallets-triple-check": "Trikrat preverite vse",
  "page-wallets-triple-check-desc": "Ne pozabite, da transakcij ni mogoče razveljaviti, denarnic pa ni mogoče preprosto obnoviti, zato bodite previdni.",
  "page-wallets-try-dapps": "Poskusite dec. aplikacije",
  "page-wallets-try-dapps-alt": "Slika članov skupnosti Ethereum, ki sodelujejo",
  "page-wallets-try-dapps-desc": "Decentralizirane aplikacije so aplikacije, razvite na Ethereumu. So cenejše, bolj poštene in bolj podatkom prijazne od tradicionalnih aplikacij.",
  "page-wallets-types": "Vrste denarnic",
  "page-wallets-web-browser": "Spletne denarnice, ki omogočajo interakcijo z računom prek spletnega brskalnika",
  "page-wallets-whats-a-wallet": "Kaj je denarnica Ethereum?",
  "page-wallets-your-ethereum-account": "Vaš račun Ethereum",
  "page-wallets-your-ethereum-account-desc": "Vaša denarnica je okno v vaš račun Ethereum – stanje, zgodovina transakcij in drugo. Vendar lahko ponudnike denarnice kadarkoli zamenjate.",
  "page-wallets-your-login": "Vaša prijava za aplikacije na Ethereumu",
  "page-wallets-your-login-desc": "Denarnica vam omogoča povezovanje s poljubno decentralizirano aplikacijo z uporabo Ethereum računa. Deluje kot prijava, ki jo lahko uporabite prek številnih decentraliziranih aplikacij.",
  "page-what-is-ethereum-101": "Osnove Ethereuma",
  "page-what-is-ethereum-101-desc": "Ethereum je tehnologija, ki omogoča, da komurkoli pošiljate kriptovalute z nizko provizijo. Poganja tudi aplikacije, ki jih lahko uporabljajo vsi in jih nihče ne more odstraniti.",
  "page-what-is-ethereum-101-desc-2": "Ethereum gradi na inovaciji, ki jo je predstavljal bitcoin, ima pa tudi nekaj velikih razlik.",
  "page-what-is-ethereum-101-desc-3": "Oba omogočata uporabo digitalnega denarja brez ponudnikov plačil ali bank. Vendar je Ethereum mogoče programirati, tako da ga lahko uporabite tudi za številna druga digitalna sredstva – vključno z bitcoinom.",
  "page-what-is-ethereum-101-desc-4": "Ti pomeni tudi, da je Ethereum namenjen za več kot samo plačila. Je trg za finančne storitve, igre in aplikacije, ki ne morejo ukrasti vaših podatkov ali vas cenzurirati.",
  "page-what-is-ethereum-101-italic": "svetovna veriga blokov, ki jo je mogoče programirati.",
  "page-what-is-ethereum-101-strong": "Je ",
  "page-what-is-ethereum-accessibility": "Ethereum je na voljo za vse.",
  "page-what-is-ethereum-adventure": "Izberite svojo pustolovščino.",
  "page-what-is-ethereum-alt-img-bazaar": "Slika osebe, ki kuka na tržnico, ki naj bi predstavljala Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Slika članov skupnosti Ethereum, ki sodelujejo",
  "page-what-is-ethereum-alt-img-lego": "Slika roke, ki ustvarja logotip ETH iz lego kock",
  "page-what-is-ethereum-alt-img-social": "Slika likov v družabnem okolju, namenjenem Ethereumu, z velikim logotipom ETH",
  "page-what-is-ethereum-banking-card": "Bančništvo za vse",
  "page-what-is-ethereum-banking-card-desc": "Dostop do finančnih storitev ni nekaj, kar bi imeli vsi. Vse, kar potrebujete za dostop do Ethereuma in produktov za kreditiranje, posojila in varčevanje, ki so na voljo na njem, pa je internetna povezava.",
  "page-what-is-ethereum-build": "Ustvarite nekaj z Ethereumom",
  "page-what-is-ethereum-build-desc": "Če želite začeti razvijati na Ethereumu, preberite naše dokumente, poskusite nekaj vadnic ali si oglejte orodja, ki jih potrebujete za začetek.",
  "page-what-is-ethereum-censorless-card": "Odporen na cenzuro",
  "page-what-is-ethereum-censorless-card-desc": "Nobena vlada ali podjetje nima nadzora nad Ethereumom. Zaradi te decentralizacije je skoraj nemogoče, da bi vam kdorkoli poskušal preprečiti prejemanje plačil ali uporabo storitev na Ethereumu.",
  "page-what-is-ethereum-comm-desc": "Naša skupnost vključuje ljudi iz različnih ozadij, vključno z umetniki, kriptouporniki, podjetji s seznama Fortune 500, in zdaj tudi vas. Preberite več o tem, kako se lahko vključite že danes.",
  "page-what-is-ethereum-commerce-card": "Zagotovila pri trgovanju",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum ustvarja pravičnejše okolje. Stranke imajo varno, vgrajeno zagotovilo, da bodo njihova sredstva prenesena šele, ko zagotovite, kar ste se dogovorili. Za poslovanje ne potrebujete vpliva velikih podjetij.",
  "page-what-is-ethereum-community": "Skupnost Ethereum",
  "page-what-is-ethereum-compatibility-card": "Zmagovalna združljivost",
  "page-what-is-ethereum-compatibility-card-desc": "Stalno se razvijajo boljši izdelki in izkušnje, saj so izdelki Ethereum privzeto združljivi. Podjetja lahko gradijo na uspehu drugih podjetij.",
  "page-what-is-ethereum-dapps-desc": "Izdelki in storitve, ki se izvajajo na Ethereumu. Na voljo so decentralizirane aplikacije za finance, delo, družbene medije, igre in več – spoznajte aplikacije za digitalno prihodnost.",
  "page-what-is-ethereum-dapps-img-alt": "Slika psa doge, ki v računalniku uporablja aplikacijo Ethereum",
  "page-what-is-ethereum-dapps-title": "Decentralizirane aplikacije Ethereum",
  "page-what-is-ethereum-desc": "Osnova za digitalno prihodnost",
  "page-what-is-ethereum-explore": "Raziščite Ethereum",
  "page-what-is-ethereum-get-started": "Če se želite naučiti več, je najboljši način ta, da prenesete denarnico, pridobite nekaj ETH in poskusite decentralizirano aplikacijo Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum zagotavlja odprt dostop do digitalnega denarja in podatkovno prijaznih storitev za vse ne glede na ozadje posameznika ali njegovo lokacijo. Je tehnologija, ki jo je razvila skupnost, in poganja kriptovaluto ether (ETH) ter več tisoč aplikacij, ki jih lahko uporabljate že danes.",
  "page-what-is-ethereum-internet-card": "Zasebnejši internet",
  "page-what-is-ethereum-internet-card-desc": "Za uporabo aplikacije Ethereum vam ni potrebno navesti vseh svojih osebnih podatkov. Ethereum gradi gospodarstvo na osnovi vrednosti, ne nadzora.",
  "page-what-is-ethereum-meet-comm": "Spoznajte skupnost",
  "page-what-is-ethereum-meta-description": "Preberite več o Ethereumu, zakaj ga je mogoče uporabiti in kako ga lahko poskusite sami.",
  "page-what-is-ethereum-meta-title": "Kaj je Ethereum?",
  "page-what-is-ethereum-native-alt": "Simbol za ether (ETH)",
  "page-what-is-ethereum-native-crypto": "Ethereumova izvorna kriptovaluta, primerljiva z bitcoinom. ETH lahko uporabljate v aplikacijah Ethereum ali za pošiljanje vrednosti prijateljem in sorodnikom.",
  "page-what-is-ethereum-native-img-alt": "Slika robota s sefom namesto telesa, ki predstavlja denarnice Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Omrežje enakovrednih računalnikov",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum omogoča pošiljanje denarja ali sklepanje pogodb neposredno z drugimi. Uporaba posredniških podjetij ni potrebna.",
  "page-what-is-ethereum-singlecard-desc": "Če vas zanimajo verige blokov in tehnična plat Ethereuma, imamo vse, kar potrebujete.",
  "page-what-is-ethereum-singlecard-link": "Kako deluje Ethereum",
  "page-what-is-ethereum-singlecard-title": "Kako deluje Ethereum",
  "page-what-is-ethereum-start-building-btn": "Začnite razvijati",
  "page-what-is-ethereum-title": "Kaj je Ethereum?",
  "page-what-is-ethereum-tools-needed": "Za sodelovanje potrebujete samo denarnico.",
  "page-what-is-ethereum-try": "Poskusite Ethereum",
  "page-what-is-ethereum-tryit": "Torej stopite na tržnico in poskusite ...",
  "page-what-is-ethereum-wallets": "Denarnice",
  "page-what-is-ethereum-wallets-desc": "Kako upravljate svoj ETH in račun Ethereum: za začetek boste potrebovali denarnico. Pomagali vam jo bomo izbrati.",
  "page-what-is-ethereum-welcome": "Pozdravljeni v Ethereumu",
  "page-what-is-ethereum-welcome-2": "Upamo, da boste tukaj tudi ostali.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Zastavljanje je prispelo! Če želite zastaviti svoj ETH,",
  "banner-staking-2": "potrdite naslov pogodbe za polog",
  "docking": "Spojitev",
  "page-eth2-vision-security-desc-9": "nam omogoča naključno dodeljevanje validatorjev različnih drobcem – zato je praktično nemogoče, da bi validatorji kadarkoli sodelovali in skupaj napadli določen drobec. Razdrobitev na verigi blokov z dokazom o delu ni tako varna, saj protokol ne more nadzirati rudarjev na ta način.",
  "page-index-sections-developers-desc": "Spoznajte tehnologijo, na kateri temeljijo Ethereum in njegove aplikacije, da boste lahko začeli razvijati z njim.",
  "page-index-sections-developers-image-alt": "Slike roke, ki sestavlja glif za Ethereum, sestavljene iz kock Lego",
  "page-index-sections-developers-link-text": "Začnite razvijati",
  "page-index-sections-developers-title": "Razvijalci",
  "page-index-sections-enterprise-desc": "Oglejte si, kako lahko Ethereum odpre nove poslovne modele, zniža vaše stroške in pripravi vaše podjetje na prihodnost.",
  "page-index-sections-enterprise-image-alt": "Slika skupine, ki dela na projektu Ethereum, zbrane okoli prenosnega računalnika",
  "page-index-sections-enterprise-link-text": "Ethereum za poslovna okolja",
  "page-index-sections-enterprise-title": "Za podjetja",
  "page-index-sections-individuals-desc": "Spoznajte Ethereum, eter, denarnice, žetone in drugo, tako da boste lahko začeli uporabljati aplikacije Ethereum.",
  "page-index-sections-individuals-image-alt": "Slika psa »doge«, ki sedi ob računalniku",
  "page-index-sections-individuals-link-text": "Začnite uporabljati Ethereum",
  "page-index-sections-individuals-title": "O Ethereumu",
  "page-index-subtitle": "Na platformi Ethereum lahko napišete kodo, ki nadzoruje digitalno vrednost, se izvaja natančno tako, kot ste jo programirali, in je dostopna povsod po svetu.",
  "page-find-wallet-authereum-logo-alt": "Logotip Authereum",
  "page-find-wallet-description-authereum": "Brez prenosov, brez varnostnih fraz. Poljuben brskalnik, kadarkoli, v mobilni napravi ali brskalniku",
  "page-wallets-accounts-addresses-desc-2": "je subjekt, ki lahko pošilja transakcije in ima stanje.",
  "page-wallets-accounts-has": "Račun Ethereum ima",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "Naslov Ethereum",
  "page-wallets-ethereum-addresses-2": ", tako kot ima e-poštni nabiralnik svoj e-poštni naslov. Uporabljate ga lahko za pošiljanje sredstev na račun.",
  "page-wallets-ethereum-wallet-2": "je izdelek, ki omogoča upravljanje vašega računa Ethereum, na primer za preverjanje stanja na računu, pošiljanje transakcij in drugo."
}
