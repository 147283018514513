{
  "1inch-logo": "1inch徽标",
  "aave-logo": "Aave徽标",
  "about": "关于",
  "about-ethereum-org": "关于etherum.org",
  "about-us": "关于我们",
  "alt-eth-blocks": "组织成类似ETH符号的区块示例",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "返回顶部",
  "banner-page-incomplete": "此页不完整。如果您是这方面的专家，请编辑这一页，并将您的智慧撒在上面。",
  "beacon-chain": "信标链",
  "binance-logo": "Binance徽标",
  "bittrex-logo": "Bittrex徽标",
  "brand-assets": "品牌资产",
  "bug-bounty": "漏洞悬赏",
  "coinbase-logo": "Coinbase徽标",
  "coinmama-logo": "Coinmama徽标",
  "community": "社区",
  "community-menu": "Community Menu",
  "compound-logo": "Compound徽标",
  "cons": "缺点",
  "contact": "联系我们",
  "content-versions": "Content Versions",
  "contributing": "参与贡献",
  "contributors": "贡献者",
  "contributors-thanks": "所有对本页有贡献的人- 谢谢！",
  "cookie-policy": "Cookie 政策",
  "copied": "已复制",
  "copy": "复制",
  "dark-mode": "夜间模式",
  "data-provided-by": "数据提供者",
  "decentralized-applications-dapps": "去中心化应用 (dapps)",
  "deposit-contract": "存款合约",
  "devcon": "测试版",
  "developers": "开发者",
  "developers-home": "开发者主页",
  "docs": "相关文档",
  "documentation": "相关文档",
  "dydx-logo": "Dydx徽标",
  "ecosystem": "生态系统",
  "edit-page": "编辑页面",
  "ef-blog": "以太坊基金会博客",
  "eips": "以太坊改进提议",
  "enterprise": "企业级应用",
  "enterprise-menu": "企业菜单",
  "esp": "生态系统支持方案",
  "eth-current-price": "当前ETH价格 (美元)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "开源的Eth2信标链浏览器",
  "eth2-beaconscan-desc": "Eth2信标链浏览器——Ethersscan for Eth2",
  "eth2-become-staker": "成为贡献者",
  "eth2-become-staker-desc": "质押已经上线！如果您想要通过质押您的ETH去帮助巩固此网络，请确保您了解其中的风险。",
  "eth2-explore": "查看数据",
  "eth2-no-action-needed": "您不需要对持有的以太币做任何事情，但需注意诈骗者以交易为名要求您发送以太币。",
  "eth2-run-beacon-chain": "运行信标客户端",
  "eth2-run-beacon-chain-desc": "以太坊需要尽可能多地处在运行中的客户端支持，为这个以太坊公共产品提供帮助吧！",
  "eth2-service-announcement": "Eth2服务公告",
  "eth2-what-shipping": "何时发布？",
  "ethereum": "以太坊",
  "ethereum-2-0": "以太坊 2.0",
  "ethereum-brand-assets": "以太坊品牌资产",
  "ethereum-community": "以太坊社区",
  "ethereum-foundation": "以太坊基金会",
  "ethereum-foundation-logo": "以太坊基金会博客",
  "ethereum-glossary": "以太坊词汇表",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "以太坊徽标",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "以太坊工作室",
  "ethereum-wallets": "以太坊钱包",
  "ethereum-whitepaper": "以太坊白皮书",
  "example-projects": "示例项目",
  "find-wallet": "查找钱包",
  "foundation": "基金会",
  "gemini-logo": "Gemini徽标",
  "get-eth": "获取 ETH",
  "get-involved": "参与其中",
  "get-started": "开始体验",
  "gitcoin-logo": "Gitcoin徽标",
  "glossary": "词汇表",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "指南和资源",
  "history": "历史",
  "history-of-ethereum": "以太坊的历史",
  "home": "首页",
  "how-ethereum-works": "以太坊如何运作",
  "image": "图片",
  "in-this-section": "在这个部分",
  "individuals": "个人",
  "individuals-menu": "个人菜单",
  "jobs": "Jobs",
  "kraken-logo": "Kraken徽标",
  "language-ar": "阿拉伯语",
  "language-bg": "Bulgarian",
  "language-bn": "孟加拉语",
  "language-ca": "Catalan",
  "language-cs": "捷克语",
  "language-de": "德语",
  "language-el": "希腊语",
  "language-en": "英语",
  "language-es": "西班牙语",
  "language-fa": "波斯语",
  "language-fi": "芬兰语",
  "language-fr": "法文",
  "language-hu": "匈牙利语",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "印度尼西亚语",
  "language-ig": "伊博语",
  "language-it": "意大利语",
  "language-ja": "日语",
  "language-ko": "韩语/朝鲜语",
  "language-lt": "立陶宛语",
  "language-ml": "马拉雅拉姆语",
  "language-nb": "挪威语",
  "language-nl": "荷兰语",
  "language-pl": "波兰语",
  "language-pt": "葡萄牙语",
  "language-pt-br": "葡萄牙语 (巴西)",
  "language-ro": "罗马尼亚语",
  "language-ru": "俄语",
  "language-se": "瑞典语",
  "language-sk": "斯洛伐克语",
  "language-sl": "斯洛文尼亚语",
  "language-support": "语言支持",
  "language-tr": "土耳其语",
  "language-uk": "乌克兰语",
  "language-vi": "越南语",
  "language-zh": "中文（简体）",
  "language-zh-tw": "中文（繁体）",
  "languages": "语言",
  "last-24-hrs": "最近24小时",
  "last-edit": "上次编辑",
  "learn": "学习",
  "learn-by-coding": "通过编码来学习",
  "learn-menu": "学习菜单",
  "learn-more": "查看更多",
  "less": "更少",
  "light-mode": "日间模式",
  "listing-policy-disclaimer": "本页所列产品并非官方认可，仅供参考。如果您想添加产品或对策略提供反馈，请在GitHub中提出问题。",
  "listing-policy-raise-issue-link": "提出问题",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "正在加载...",
  "loading-error": "加载错误。",
  "loading-error-refresh": "加载数据出错。请尝试刷新页面。",
  "logo": "徽标",
  "loopring-logo": "Loopring徽标",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "主网以太坊",
  "makerdao-logo": "MakerDao徽标",
  "matcha-logo": "Matcha徽标",
  "merge": "Merge",
  "more": "更多",
  "nav-beginners": "初学者",
  "next": "下一个",
  "oasis-logo": "Oasis徽标",
  "on-this-page": "在本页面",
  "page-content": "页面内容",
  "page-enterprise": "企业级应用",
  "page-last-updated": "页面最后更新",
  "previous": "前一页",
  "privacy-policy": "隐私政策",
  "private-ethereum": "私人以太坊",
  "pros": "优点",
  "read-more": "了解更多",
  "refresh": "请刷新页面。",
  "review-progress": "审核进度",
  "search": "搜索​​​​",
  "search-box-blank-state-text": "开始探索！",
  "search-eth-address": "这看起来像一个以太坊地址，但我们不提供针对地址的特定数据，请尝试在区块浏览器上搜索它，就像这样",
  "search-no-results": "您的搜索没有结果",
  "security": "Security",
  "see-contributors": "查看贡献者",
  "set-up-local-env": "设置本地环境",
  "shard-chains": "碎片链",
  "show-all": "显示全部",
  "show-less": "收起",
  "site-description": "以太坊是一个全球性的、分散的资金和新型应用程序平台。 在以太坊，您可以写代码来控制钱，并在世界任何地方建立可以访问的应用程序。",
  "site-title": "ethereum.org/zh/",
  "smart-contracts": "Smart contracts",
  "stablecoins": "稳定币",
  "staking": "权益质押",
  "summary": "概览",
  "terms-of-use": "使用条款",
  "transaction-fees": "什么是交易费？",
  "translation-banner-body-new": "由于尚未翻译，本页面内容以英文显示。请帮助我们翻译此内容。",
  "translation-banner-body-update": "本页面有新版本，但现在只有英文版。请帮助我们翻译最新版本。",
  "translation-banner-button-join-translation-program": "加入翻译计划",
  "translation-banner-button-learn-more": "查看更多",
  "translation-banner-button-see-english": "查看英文",
  "translation-banner-button-translate-page": "翻译页面",
  "translation-banner-title-new": "帮助翻译此页面",
  "translation-banner-title-update": "帮助更新此页面",
  "translation-program": "Translation Program",
  "translation-progress": "翻译进度",
  "tutorials": "教程",
  "uniswap-logo": "Uniswap徽标",
  "use": "使用",
  "use-ethereum": "使用以太坊",
  "use-ethereum-menu": "使用以太坊菜单",
  "vision": "愿景",
  "wallets": "钱包",
  "website-last-updated": "网站最后更新",
  "what-is-ether": "什么是以太币 (ETH)？",
  "what-is-ethereum": "以太坊简介",
  "whitepaper": "白皮书",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "功能建议",
  "page-about-h3": "进行中的工作",
  "page-about-h3-1": "实现的功能",
  "page-about-h3-2": "计划添加的功能",
  "page-about-li-1": "进行中",
  "page-about-li-2": "已计划",
  "page-about-li-3": "已实现",
  "page-about-li-4": "已实现",
  "page-about-link-1": "这个仓库的源代码是根据MIT许可证授权的",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "查看Github正在进行的任务的完整列表",
  "page-about-link-4": "加入我们的Discord服务器",
  "page-about-link-5": "在Twitter与我们联系",
  "page-about-link-6": "查看Github上已完成任务的完整列表",
  "page-about-link-7": "在Github上创建问题",
  "page-about-p-1": "自从etherum.org启动以来，我们努力使我们的运作方式具有透明度。 这是我们的核心价值观之一，因为我们认为透明度对以太坊的成功至关重要。",
  "page-about-p-2": "我们使用",
  "page-about-p-3": "作为我们的主要项目管理工具。我们的任务分为三类：",
  "page-about-p-4": "  我们尽最大努力让社区了解具体任务的情况。",
  "page-about-p-5": "正在执行的任务。",
  "page-about-p-6": "我们排队等待执行的任务。",
  "page-about-p-7": "最近完成的任务。",
  "page-about-p-8": "您对如何改进etherum.org有想法吗？我们很乐意与您合作！",
  "page-assets-bazaar": "以太坊市场",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "构建区块",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge正在使用去中心化应用",
  "page-assets-download-artist": "艺术家：",
  "page-assets-download-download": "下载",
  "page-assets-enterprise": "企业级以太坊",
  "page-assets-eth": "以太币(ETH)",
  "page-assets-eth-diamond-color": "ETH钻石（彩色）",
  "page-assets-eth-diamond-glyph": "ETH钻石（字符）",
  "page-assets-eth-diamond-gray": "ETH钻石（灰色）",
  "page-assets-eth-diamond-purple": "ETH钻石（紫色）",
  "page-assets-eth-diamond-white": "ETH钻石（白色）",
  "page-assets-eth-glyph-video-dark": "ETH字符视频（黑色）",
  "page-assets-eth-glyph-video-light": "ETH字符视频（白色）",
  "page-assets-eth-logo-landscape-gray": "ETH徽标着陆页（灰色）",
  "page-assets-eth-logo-landscape-purple": "ETH徽标着陆页（紫色）",
  "page-assets-eth-logo-landscape-white": "ETH徽标着陆页（白色）",
  "page-assets-eth-logo-portrait-gray": "ETH徽标图像（灰色）",
  "page-assets-eth-logo-portrait-purple": "ETH徽标图像（紫色）",
  "page-assets-eth-logo-portrait-white": "ETH徽标图像（白色）",
  "page-assets-eth-wordmark-gray": "ETH字符水印(灰色)",
  "page-assets-eth-wordmark-purple": "ETH字符水印（紫色）",
  "page-assets-eth-wordmark-white": "ETH字符水印（白色）",
  "page-assets-ethereum-brand-assets": "以太坊 “品牌” 资产",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "ethereum.org资产",
  "page-assets-hero": "etherum.org主图",
  "page-assets-hero-particles": "ETH颗粒图像",
  "page-assets-historical-artwork": "历史上的艺术作品",
  "page-assets-illustrations": "插图",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "浏览并下载以太坊和etherum.org品牌、插图和媒体。",
  "page-assets-meta-title": "以太坊品牌资产",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "坚实的背景",
  "page-assets-page-assets-transparent-background": "透明背景",
  "page-assets-robot": "机器人钱包",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1inch徽标",
  "page-dapps-aave-logo-alt": "Aave徽标",
  "page-dapps-add-button": "建议去中心化应用",
  "page-dapps-add-title": "添加去中心化应用",
  "page-dapps-audius-logo-alt": "Audius徽标",
  "page-dapps-augur-logo-alt": "Augur徽标",
  "page-dapps-axie-infinity-logo-alt": "Axie Infinity徽标",
  "page-dapps-brave-logo-alt": "Brave徽标",
  "page-dapps-category-arts": "艺术与时尚",
  "page-dapps-category-browsers": "浏览器",
  "page-dapps-category-collectibles": "数字收藏品",
  "page-dapps-category-competitive": "竞赛",
  "page-dapps-category-computing": "开发者工具",
  "page-dapps-category-dex": "代币交易所",
  "page-dapps-category-investments": "投资",
  "page-dapps-category-lending": "贷款与借贷",
  "page-dapps-category-lottery": "众筹",
  "page-dapps-category-marketplaces": "市场",
  "page-dapps-category-music": "音乐",
  "page-dapps-category-payments": "支付",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "交易和预测市场",
  "page-dapps-category-utilities": "实用工具",
  "page-dapps-category-worlds": "虚拟世界",
  "page-dapps-choose-category": "选择分类",
  "page-dapps-collectibles-benefits-1-description": "当艺术作品在以太坊上被数字化为代币(Token)之后，这件艺术作品的所有权将会被大家有目共睹，您可以追踪这件艺术作品从创作者到现持有人的全部历程。这样可以防止山寨。",
  "page-dapps-collectibles-benefits-1-title": "所有权是可验证的",
  "page-dapps-collectibles-benefits-2-description": "消费者（利用以太坊）来购买在线音乐或购买艺术品对艺术家来说将会更加的公平。通过以太坊可以减少不必要的中间商。而且，即使需要中间商，他们的成本也不会高，因为平台不需要支付网络基础设施的费用。",
  "page-dapps-collectibles-benefits-2-title": "对创作者更公平",
  "page-dapps-collectibles-benefits-3-description": "代币化的收藏品是与您的以太坊地址绑定的，而不是平台。所以您可以在任何以太坊市场上出售游戏内物品等东西，而不仅仅是在游戏本身。",
  "page-dapps-collectibles-benefits-3-title": "收藏品与您同行",
  "page-dapps-collectibles-benefits-4-description": "工具和产品已经存在，您可以将您的艺术作品代币化，并进行销售！您的代币可以在任何和所有的以太坊收藏品平台上销售。",
  "page-dapps-collectibles-benefits-4-title": "已有的基础设施",
  "page-dapps-collectibles-benefits-description": "这些应用专注于数字所有权，增加创作者的收入潜力，并发明新的方式来投资您喜爱的创作者及其作品。",
  "page-dapps-collectibles-benefits-title": "分散式收藏品和流媒体",
  "page-dapps-collectibles-button": "艺术品和收藏品",
  "page-dapps-collectibles-description": "这些应用专注于数字所有权，增加创作者的收入潜力，并发明新的方式来投资您喜爱的创作者及其作品。",
  "page-dapps-collectibles-title": "去中心化艺术品和收藏品",
  "page-dapps-compound-logo-alt": "Compound徽标",
  "page-dapps-cryptopunks-logo-alt": "CryptoPunks徽标",
  "page-dapps-cryptovoxels-logo-alt": "Cryptovoxels徽标",
  "page-dapps-dapp-description-1inch": "通过聚合最优价格，帮助您避免高的滑点。",
  "page-dapps-dapp-description-aave": "借出您的代币赚取利息，并可随时提取。",
  "page-dapps-dapp-description-async-art": "创建、收集和交易#可编程艺术--数字画作被分割成 \"层\"，您可以用它来影响整体图像。每个主图层和图层都是一个ERC721令牌。",
  "page-dapps-dapp-description-audius": "去中心化的流媒体平台。听众=创作者的钱，而不是标签。",
  "page-dapps-dapp-description-augur": "投注体育、经济和更多世界事件的结果。",
  "page-dapps-dapp-description-axie-infinity": "交易和战斗的生物称为Axies。边玩边赚 - 可在手机上使用",
  "page-dapps-dapp-description-brave": "通过浏览赚取代币，用代币支持您喜欢的创作者。",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "借出您的代币赚取利息，并可随时提取。",
  "page-dapps-dapp-description-cryptopunks": "购买、竞价和提供punk for sale--这是以太坊上第一批代币收藏品之一。",
  "page-dapps-dapp-description-cryptovoxels": "创建艺术画廊，建立商店，购买土地--一个以太坊虚拟世界。",
  "page-dapps-dapp-description-dark-forest": "在一个无限的、程序生成的、密码学指定的宇宙中征服行星。",
  "page-dapps-dapp-description-decentraland": "在您可以探索的虚拟世界中收集、交易虚拟土地。",
  "page-dapps-dapp-description-dydx": "开空或杠杆，杠杆高达10倍。也可借贷。",
  "page-dapps-dapp-description-ens": "用户友好的以太坊地址和去中心化网站的名称。",
  "page-dapps-dapp-description-foundation": "投资独特版本的数字艺术品，并与其他买家交易作品。",
  "page-dapps-dapp-description-gitcoin": "在开源软件上工作，赚取加密货币。",
  "page-dapps-dapp-description-gitcoin-grants": "为以太坊社区项目进行众筹，并扩大捐献。",
  "page-dapps-dapp-description-gods-unchained": "策略性的交易卡游戏。通过玩，赚取卡，您可以在现实生活中出售。",
  "page-dapps-dapp-description-golem": "访问共享计算能力或租用自己的资源。",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "点对点交易平台，为速度而生。",
  "page-dapps-dapp-description-marble-cards": "创建和交易独特的基于URL的数字卡。",
  "page-dapps-dapp-description-matcha": "搜索多个交易所，帮您找到最佳价格。",
  "page-dapps-dapp-description-nifty-gateway": "在区块链上购买顶级艺术家、运动员、品牌和创作者的作品。",
  "page-dapps-dapp-description-oasis": "用Dai这个以太坊稳定币进行交易、借贷和储蓄。",
  "page-dapps-dapp-description-opensea": "购买、出售、发现和交易限量版商品。",
  "page-dapps-dapp-description-opera": "从您的浏览器向商家、其他用户和应用程序发送加密货币。",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "在结果上下注。在信息市场上交易。",
  "page-dapps-dapp-description-pooltogether": "您不会输的彩票。每周都有奖。",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "创建、出售和购买通证化的收藏品。",
  "page-dapps-dapp-description-sablier": "实时流转资金。",
  "page-dapps-dapp-description-superrare": "直接从艺术家或在二级市场购买数字艺术作品。",
  "page-dapps-dapp-description-token-sets": "自动再平衡的加密投资策略。",
  "page-dapps-dapp-description-tornado-cash": "在以太坊上发送匿名交易。",
  "page-dapps-dapp-description-uniswap": "简单地交换代币或提供代币换取%的奖励。",
  "page-dapps-dark-forest-logo-alt": "Dark Forest徽标",
  "page-dapps-decentraland-logo-alt": "Decentraland徽标",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "找一个以太坊应用试试。",
  "page-dapps-doge-img-alt": "用电脑设计一只狗",
  "page-dapps-dydx-logo-alt": "dYdX徽标",
  "page-dapps-editors-choice-dark-forest": "与他人对弈，征服星球，尝试最前沿的以太坊扩展/隐私技术。也许是对于那些已经熟悉以太坊的人来说。",
  "page-dapps-editors-choice-description": "一些ethereum.org团队现在喜欢的去中心化应用。下面是更多的去中心化应用。",
  "page-dapps-editors-choice-foundation": "投资于文化。购买、交易和出售来自一些不可思议的艺术家、音乐家和品牌的独特数字艺术品和时尚。",
  "page-dapps-editors-choice-header": "编辑的选择",
  "page-dapps-editors-choice-pooltogether": "买一张无损彩票。每周将整个票池产生的利息寄给一位幸运儿。只要您愿意，随时可以拿回您的钱。",
  "page-dapps-editors-choice-uniswap": "轻松交换您的代币。一个社区的宠儿，让您可以和全网的人交换代币。",
  "page-dapps-ens-logo-alt": "以太坊名称服务徽标",
  "page-dapps-explore-dapps-description": "很多去中心化应用还在实验中，测试去中心化网络的可能性。但在科技、金融、游戏和收藏品类中，已经有一些成功的先行者。",
  "page-dapps-explore-dapps-title": "探索一些去中心化应用",
  "page-dapps-features-1-description": "一旦部署到以太坊上，去中心化应用代码就无法删除。而且任何人都可以使用去中心化应用的功能。即使去中心化应用背后的团队解散了，您仍然可以使用它。一旦在以太坊上，它就会留在那里。",
  "page-dapps-features-1-title": "没有所有者",
  "page-dapps-features-2-description": "您不能被阻止使用去中心化应用或提交交易。例如，如果Twitter是在以太坊上，没有人可以屏蔽您的帐户或阻止您发推特。",
  "page-dapps-features-2-title": "抗审查",
  "page-dapps-features-3-description": "因为以太坊有以太币可以直接用于支付。开发者不需要花时间去集成第三方的支付。",
  "page-dapps-features-3-title": "内置付款",
  "page-dapps-features-4-description": "去中心化应用的代码通常是开源和默认互相兼容的。开发团队之间通常情况下都可以互相引用。例如：想要让用户在您的去中心化应用中交易代币，您只需要引入支持交易代币的合约代码并使用即可。",
  "page-dapps-features-4-title": "即插即用",
  "page-dapps-features-5-description": "在使用大部分去中心化应用时，您不需要分享您的真实身份，只需要一个以太坊账户与付款账户即可进行登录。",
  "page-dapps-features-5-title": "匿名登录",
  "page-dapps-features-6-description": "密码学可以保证攻击者无法伪造交易和去中心化应用交互行为。您可以授权去中心化应用来操作您的以太坊账户，通常是通过您的钱包程序，因此您需要确保证书的安全。",
  "page-dapps-features-6-title": "加密保护",
  "page-dapps-features-7-description": "一旦去中心化应用在以太坊上线就无法单独下线，它只能随着以太坊一起下线，以太坊的规模大到难以攻击。",
  "page-dapps-features-7-title": "无需下载",
  "page-dapps-finance-benefits-1-description": "您无需注册便可使用以太坊的交易服务。只要您有资金和网络连接，就可以进行交易了。",
  "page-dapps-finance-benefits-1-title": "开源",
  "page-dapps-finance-benefits-2-description": "你可以通过这些金融产品和全世界的代币互动，人们随时随刻在以太网络的基础上铸造新的代币",
  "page-dapps-finance-benefits-2-title": "一个新的代币经济",
  "page-dapps-finance-benefits-3-description": "团队新推出的稳定币 — 一种更稳定的加密货币。这允许您可以在没有风险和不确定性的情况下去实验并使用加密货币。",
  "page-dapps-finance-benefits-3-title": "稳定币",
  "page-dapps-finance-benefits-4-description": "以太坊领域的金融产品都是模块化并且彼此兼容的。这些模块的配置在不断地更新，赋予了您手中加密货币更多的能力。",
  "page-dapps-finance-benefits-4-title": "互联金融服务",
  "page-dapps-finance-benefits-description": "以太坊为什么可以让分散式金融应用得以蓬勃发展？",
  "page-dapps-finance-benefits-title": "去中心化金融",
  "page-dapps-finance-button": "金融",
  "page-dapps-finance-description": "这些是专注在构建使用加密货币进行金融服务的应用，它们提供像借、贷、赚取利息，以及不需要个人信息的私密支付等服务。",
  "page-dapps-finance-title": "去中心化金融",
  "page-dapps-foundation-logo-alt": "基金会徽标",
  "page-dapps-gaming-benefits-1-description": "无论是虚拟土地还是可交易卡片，您的收藏品在收藏品市场上都是可交易的，您的游戏物品有真实世界价值。",
  "page-dapps-gaming-benefits-1-title": "游戏物品兼做代币",
  "page-dapps-gaming-benefits-2-description": "您的物品（有些时候包括进程）的所有权完全归您，而不是游戏公司所有，所以您无需担心游戏公司的服务器被攻击所带来的服务器故障或解散。",
  "page-dapps-gaming-benefits-2-title": "您的储蓄是安全的",
  "page-dapps-gaming-benefits-3-description": "就像是以太坊支付可以交给任何人认证一样，游戏也可以使用这个机制确保公平性。理论上来说，从暴击到对手宝箱的大小都可以可以核查的。",
  "page-dapps-gaming-benefits-3-title": "可证明的公正性",
  "page-dapps-gaming-benefits-description": "以太坊凭借什么可以让去中心化游戏蓬勃发展？",
  "page-dapps-gaming-benefits-title": "去中心化游戏",
  "page-dapps-gaming-button": "游戏",
  "page-dapps-gaming-description": "这些侧重于创造虚拟世界和同其他玩家竞技的应用使用具有现实世界价值的收藏品。",
  "page-dapps-gaming-title": "去中心化游戏",
  "page-dapps-get-some-eth-description": "去中心化应用的操作有交易费用作为成本",
  "page-dapps-get-started-subtitle": "如果想要使用去中心化应用，您得先有一个钱包以及一些以太币。钱包可以使您连接并登录，而以太币可以用来支付任何交易费。",
  "page-dapps-get-started-title": "开始体验",
  "page-dapps-gitcoin-grants-logo-alt": "Gitcoin Grants徽标",
  "page-dapps-gitcoin-logo-alt": "Gitcoin徽标",
  "page-dapps-gods-unchained-logo-alt": "Gods Unchained徽标",
  "page-dapps-golem-logo-alt": "Golem徽标",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "由以太坊提供支持的工具和服务",
  "page-dapps-hero-subtitle": "去中心化应用是运用以太坊网络来打破传统商业模式或发明新商业模式的蓬勃发展的应用新运动。",
  "page-dapps-how-dapps-work-p1": "去中心化应用的后端代码（智能合约）运行在一个去中心化的网络上，而非中心化的服务器。他们使用以太坊区块链网络作为数据存储端，并且用智能合约实现app的业务逻辑。",
  "page-dapps-how-dapps-work-p2": "一个智能合约像是一套设立在区块链上的规则，所有人都可以准确地观察和运行这套规则。设想一下，有一台自动贩卖机：如果向它提供足够的资金和正确的选择，您将得到您想要的货品。正如自动贩卖机一样，智能合约可以像您的以太坊账户一样存储持有资金。这允许代码居间协议和交易。",
  "page-dapps-how-dapps-work-p3": "一旦去中心化应用部署到以太坊网络，您将无法更改他们。去中心化应用之所以可以被去中心化就是因为他们被合约中的逻辑所控制，而不是公司或者个人。",
  "page-dapps-how-dapps-work-title": "去中心化应用工作原理",
  "page-dapps-learn-callout-button": "开始构建",
  "page-dapps-learn-callout-description": "我们的社区开发者门户拥有文档、工具和框架来帮助您开始构建一个去中心化应用。",
  "page-dapps-learn-callout-image-alt": "一幅用手拼乐高积木版的以太币符号的插图。",
  "page-dapps-learn-callout-title": "学习创建一个去中心化应用",
  "page-dapps-loopring-logo-alt": "Loopring徽标",
  "page-dapps-magic-behind-dapps-description": "去中心化应用可能会让人觉得是普通的应用。但在背后，它们有一些不一样的优秀特性，因为它们继承了所有以太坊的超能力。点击这里了解为什么去中心化应用不同于一般应用。",
  "page-dapps-magic-behind-dapps-link": "为什么我们说以太坊伟大？",
  "page-dapps-magic-behind-dapps-title": "去中心化应用的魔法",
  "page-dapps-magic-title-1": "魔法",
  "page-dapps-magic-title-2": "背后",
  "page-dapps-magician-img-alt": "魔法师插图",
  "page-dapps-marble-cards-logo-alt": "marble.cards徽标",
  "page-dapps-matcha-logo-alt": "Matcha徽标",
  "page-dapps-mobile-options-header": "浏览另一个类别",
  "page-dapps-nifty-gateway-logo-alt": "Nifty Gateway徽标",
  "page-dapps-oasis-logo-alt": "Oasis徽标",
  "page-dapps-opensea-logo-alt": "OpenSea徽标",
  "page-dapps-opera-logo-alt": "Opera徽标",
  "page-dapps-polymarket-logo-alt": "Polymarket徽标",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "PoolOnlogo徽标",
  "page-dapps-rarible-logo-alt": "Rarible徽标",
  "page-dapps-ready-button": "出发",
  "page-dapps-ready-description": "选择一个去中心化应用来尝试",
  "page-dapps-ready-title": "准备好了吗？",
  "page-dapps-sablier-logo-alt": "Sablier徽标",
  "page-dapps-set-up-a-wallet-button": "查找钱包",
  "page-dapps-set-up-a-wallet-description": "一个钱包地址是您“登录”去中心化应用的凭证",
  "page-dapps-set-up-a-wallet-title": "创建一个钱包",
  "page-dapps-superrare-logo-alt": "SuperRare徽标",
  "page-dapps-technology-button": "科技",
  "page-dapps-technology-description": "这些应用侧重于开发者工具的去中心化，将加密经济系统纳入现有技术，并为开发开放源码的开发工作创建市场。",
  "page-dapps-technology-title": "去中心化技术",
  "page-dapps-token-sets-logo-alt": "Token Sets徽标",
  "page-dapps-tornado-cash-logo-alt": "Tornado cash徽标",
  "page-dapps-uniswap-logo-alt": "Uniswap徽标",
  "page-dapps-wallet-callout-button": "查找钱包",
  "page-dapps-wallet-callout-description": "钱包同样也是去中心化应用，根据功能最适合您的原则选择一个钱包。",
  "page-dapps-wallet-callout-image-alt": "机器人插图。",
  "page-dapps-wallet-callout-title": "查看钱包",
  "page-dapps-warning-header": "坚持独立思考和研究",
  "page-dapps-warning-message": "以太坊是一种新技术，大多数应用程序都是新的。在存放大量资金之前，请确保您了解风险。",
  "page-dapps-what-are-dapps": "什么是Dapp？",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "帐户",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "高级",
  "docs-nav-backend-apis": "后端API",
  "docs-nav-block-explorers": "区块浏览器",
  "docs-nav-blocks": "块",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "编译智能合约",
  "docs-nav-composability": "可组合性",
  "docs-nav-consensus-mechanisms": "共识机制",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "数据和分析",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "部署智能合约",
  "docs-nav-development-frameworks": "开发框架",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "开发网络",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20协议",
  "docs-nav-erc-721": "ERC-721协议",
  "docs-nav-erc-777": "ERC-777协议",
  "docs-nav-erc-1155": "ERC-1155协议",
  "docs-nav-ethereum-client-apis": "以太坊客户端API",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "以太坊权益",
  "docs-nav-evm": "以太坊虚拟机(EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "基础主题",
  "docs-nav-gas": "Gas费用",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "集成开发环境 (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "去中心化应用简介",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "以太坊简介",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "堆栈简介",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "JavaScript API",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "挖矿",
  "docs-nav-networks": "网络",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "节点和客户端",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "节点即服务",
  "docs-nav-oracles": "Oracle",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "编程语言",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "权益证明",
  "docs-nav-proof-of-work": "工作量证明",
  "docs-nav-python": "Python",
  "docs-nav-readme": "README",
  "docs-nav-rust": "Rust语言",
  "docs-nav-scaling": "缩放",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "安全性",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "智能合约结构",
  "docs-nav-smart-contract-languages": "智能合约语言",
  "docs-nav-smart-contracts": "智能合约",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "智能合约库",
  "docs-nav-standards": "标准",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "存储",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "测试用智能合约",
  "docs-nav-token-standards": "令牌标准",
  "docs-nav-transactions": "交易",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2与Web3对比",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "如果您是这方面的专家，并想发表意见，那么编辑此页分享您的智慧。",
  "page-calltocontribute-desc-2": "您将获得褒奖并会为以太坊社区提供帮助！",
  "page-calltocontribute-desc-3": "自由发挥",
  "page-calltocontribute-desc-4": "有任何疑问? 请在我们的频道中进行询问",
  "page-calltocontribute-link": "文档模板",
  "page-calltocontribute-link-2": "Discord服务器",
  "page-calltocontribute-span": "编辑页面",
  "page-calltocontribute-title": "帮助我们处理此页面",
  "page-developer-meta-title": "以太坊开发者资源",
  "page-developers-about": "关于这些开发者资源",
  "page-developers-about-desc": "etherum.org 帮助您通过以太坊编写关于基本概念和开发堆栈的相关文档，还有一些教程让您开始和运行。",
  "page-developers-about-desc-2": "在 Mozilla 开发者网络的启发下，我们认为以太坊需要一个地方来容纳伟大的开发者内容和资源。 像我们在 Mozilla 的朋友一样，这里的一切都是开源的，并准备好让您扩展和改进。",
  "page-developers-account-desc": "网络上的合约或人员",
  "page-developers-accounts-link": "帐户",
  "page-developers-advanced": "高级",
  "page-developers-api-desc": "使用库与智能合约互动",
  "page-developers-api-link": "后端 API",
  "page-developers-aria-label": "开发者菜单",
  "page-developers-block-desc": "交易批量添加到区块链中",
  "page-developers-block-explorers-desc": "您的以太坊数据门户网站",
  "page-developers-block-explorers-link": "区块浏览器",
  "page-developers-blocks-link": "块",
  "page-developers-browse-tutorials": "浏览教程",
  "page-developers-choose-stack": "选择您的堆栈",
  "page-developers-contribute": "贡献",
  "page-developers-dev-env-desc": "适合去中心化应用程序开发的 IDE",
  "page-developers-dev-env-link": "开发环境",
  "page-developers-discord": "加入 Discord",
  "page-developers-docs-introductions": "介绍",
  "page-developers-evm-desc": "处理交易的计算机",
  "page-developers-evm-link": "以太访虚拟机 (EVM)",
  "page-developers-explore-documentation": "浏览相关文档",
  "page-developers-feedback": "如果您有任何反馈，请通过 GitHub Issue 或我们的 Discord 服务器联系我们。",
  "page-developers-frameworks-desc": "帮助加速开发的工具",
  "page-developers-frameworks-link": "开发框架",
  "page-developers-fundamentals": "基础",
  "page-developers-gas-desc": "为交易赋能所需的以太",
  "page-developers-gas-link": "气体",
  "page-developers-get-started": "您想如何开始？",
  "page-developers-improve-ethereum": "帮助我们使 etherum.org 变得更好",
  "page-developers-improve-ethereum-desc": "就像 etherum.org 一样，这些文档是社区努力的结果。如果您看到错误、有待改进的地方或新的机会能帮到以太坊开发者，请创建一个 PR。",
  "page-developers-into-eth-desc": "关于区块链和以太坊的介绍",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "关于去中心化应用程序的介绍",
  "page-developers-intro-dapps-link": "去中心化应用程序简介",
  "page-developers-intro-eth-link": "以太坊简介",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "堆栈简介",
  "page-developers-intro-stack-desc": "关于以太坊堆栈的介绍",
  "page-developers-js-libraries-desc": "使用 javascript 与智能合约互动",
  "page-developers-js-libraries-link": "Javascript 库",
  "page-developers-language-desc": "使用熟悉语言的以太坊",
  "page-developers-languages": "编程语言",
  "page-developers-learn": "学习以太坊开发",
  "page-developers-learn-desc": "阅读我们的相关文档，了解核心概念和以太坊堆栈",
  "page-developers-learn-tutorials": "通过教程学习",
  "page-developers-learn-tutorials-cta": "查看教程",
  "page-developers-learn-tutorials-desc": "已经做过以太坊开发的构建者一步一步地引导您学习以太坊开发。",
  "page-developers-meta-desc": "本页为以太坊开发者提供相关文档、教程以及工具。",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "如何创建新块并达成协商一致意见",
  "page-developers-mining-link": "采矿",
  "page-developers-networks-desc": "主网和测试网络的概览",
  "page-developers-networks-link": "网络",
  "page-developers-node-clients-desc": "如何在网络中验证块和交易",
  "page-developers-node-clients-link": " 节点和客户端",
  "page-developers-oracle-desc": "正在获取链下数据到您的智能合约",
  "page-developers-oracles-link": "Oracle",
  "page-developers-play-code": "使用代码播放",
  "page-developers-read-docs": "阅读文档",
  "page-developers-scaling-desc": "快速交易解决方案",
  "page-developers-scaling-link": "缩放",
  "page-developers-smart-contract-security-desc": "开发过程中需要考虑的安全措施",
  "page-developers-smart-contract-security-link": "安全性",
  "page-developers-set-up": "设置本地环境",
  "page-developers-setup-desc": "通过配置开发环境使您的堆栈准备好构建。",
  "page-developers-smart-contracts-desc": "去中心化应用程序背后的逻辑 — 自动执行协议",
  "page-developers-smart-contracts-link": "智能合约",
  "page-developers-stack": "堆栈",
  "page-developers-start": "开始试用",
  "page-developers-start-desc": "想先尝试，稍后提问？",
  "page-developers-storage-desc": "如何处理去中心化应用程序存储",
  "page-developers-storage-link": "存储",
  "page-developers-subtitle": "以太坊的构建者手册。由构建者编写，为构建者所用。",
  "page-developers-title-1": "以太坊",
  "page-developers-title-2": "开发者",
  "page-developers-title-3": "资源",
  "page-developers-token-standards-desc": "已接受令牌标准的概览",
  "page-developers-token-standards-link": "令牌标准",
  "page-developers-transactions-desc": "以太坊状态的变化方式",
  "page-developers-transactions-link": "交易",
  "page-developers-web3-desc": "Web 3 开发世界的不同之处",
  "page-developers-web3-link": "Web2 与 Web3 对比",
  "page-learning-tools-bootcamps": "开发者引导中心",
  "page-learning-tools-bootcamps-desc": "付费在线课程，让您快速了解最新动态",
  "page-learning-tools-browse-docs": "浏览文档",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "ChainShot徽标",
  "page-learning-tools-coding": "通过编码来学习",
  "page-learning-tools-coding-subtitle": "如果您更喜欢在互动中学习，这些工具会帮您实践理解以太坊。",
  "page-learning-tools-consensys-academy-description": "线上以太坊开发者训练营。",
  "page-learning-tools-consensys-academy-logo-alt": "ConsenSys Academy徽标",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "学习Solidity，开发您自己的僵尸游戏。",
  "page-learning-tools-cryptozombies-logo-alt": "CryptoZombies徽标",
  "page-learning-tools-documentation": "通过文档学习",
  "page-learning-tools-documentation-desc": "想了解更多？请查阅我们的文档，找到您需要的解释。",
  "page-learning-tools-eth-dot-build-description": "一个web3的教育沙箱，包括拖放编程和开源构建块。",
  "page-learning-tools-eth-dot-build-logo-alt": "Eth.build徽标",
  "page-learning-tools-ethernauts-description": "通过破解智能合约来闯关。",
  "page-learning-tools-ethernauts-logo-alt": "Ethernauts徽标",
  "page-learning-tools-game-tutorials": "游戏式的互动教程",
  "page-learning-tools-game-tutorials-desc": "边玩边学。这些教程会让您通过玩游戏来了解基本知识。",
  "page-learning-tools-meta-desc": "基于网络的编码工具和交互式学习体验，帮助您体验以太坊的开发。",
  "page-learning-tools-meta-title": "开发者学习工具",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "开发、部署和管理以太坊的智能合约。跟随Learneth插件随附教程学习。",
  "page-learning-tools-remix-description-2": "Remix不仅仅是沙盒。许多开发人员使用Remix编写、编译和部署他们的智能合同。",
  "page-learning-tools-remix-logo-alt": "Remix徽标",
  "page-learning-tools-sandbox": "代码沙箱",
  "page-learning-tools-sandbox-desc": "代码沙箱提供您一个尝试写智能合约和理解以太坊的空间。",
  "page-learning-tools-studio-description": "一个基于网络的IDE，您可以在这里关注教程来创建和测试智能合约，并为它们建立一个前端。",
  "page-learning-tools-vyperfun-description": "学习Vyper构建您自己的Pokeon游戏。",
  "page-learning-tools-vyperfun-logo-alt": "Vyper.fun徽标",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "基于Python的智能合约开发和测试框架，针对以太坊虚拟机为目标。",
  "page-local-environment-brownie-logo-alt": "Brownie徽标",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "用于构建和部署去中心化应用的所有内部开发者平台。",
  "page-local-environment-embark-logo-alt": "Embark徽标",
  "page-local-environment-epirus-desc": "用于在Java虚拟机上开发、部署和监测区块链应用的平台",
  "page-local-environment-epirus-logo-alt": "Epirus徽标",
  "page-local-environment-eth-app-desc": "使用一个命令创建由以太坊提供支持的应用。使用广泛的UI框架和DeFi模板来选择。",
  "page-local-environment-eth-app-logo-alt": "创建以太币应用徽标",
  "page-local-environment-framework-feature-1": "旋转本地区块链实例的功能。",
  "page-local-environment-framework-feature-2": "编译和测试智能合约的工具。",
  "page-local-environment-framework-feature-3": "客户端开发附加组件，以在同一项目/仓库中构建您的面向用户的应用。",
  "page-local-environment-framework-feature-4": "连接到以太坊网络并部署合同的配置，无论是本地运行的实例还是以太坊的公共网络之一。",
  "page-local-environment-framework-feature-5": "去中心化的应用分发 - 与诸如IPFS之类的存储选项集成。",
  "page-local-environment-framework-features": "这些框架带有许多开箱即用功能，比如：",
  "page-local-environment-frameworks-desc": " 我们建议选择一个框架，特别是如果您刚刚开始使用。建立一个完整的去中心化应用需要不同的技术。框架包括许多所需的功能或提供简单的插件系统来选择您想要的工具。",
  "page-local-environment-frameworks-title": "框架和预先制作的堆栈",
  "page-local-environment-hardhat-desc": "“安全帽”是针对专业人员的以太坊开发环境。",
  "page-local-environment-hardhat-logo-alt": "安全帽徽标",
  "page-local-environment-openZeppelin-desc": "通过编译、升级、部署和与我们的CLI智能合同互动来节省开发时间。",
  "page-local-environment-openZeppelin-logo-alt": "OpenZepelin徽标",
  "page-local-environment-scaffold-eth-desc": "安全帽+开发以太坊应用程序：开始构建由智能合约支持的去中心化应用所需的一切",
  "page-local-environment-scaffold-eth-logo-alt": "scaffold-eth徽标",
  "page-local-environment-setup-meta-desc": "关于如何选择您的软件堆栈用于以太坊开发的指南。",
  "page-local-environment-setup-meta-title": "以太坊本地开发设置",
  "page-local-environment-setup-subtitle": "如果您准备好开始建造，就选择您的堆栈。",
  "page-local-environment-setup-subtitle-2": " 这里是您可以用来帮助您构建您的以太坊应用的工具和框架。",
  "page-local-environment-setup-title": "设置您的本地开发环境",
  "page-local-environment-solidity-template-desc": "一个GitHub模板，用于为Solidity智能合约预构建设置。包括一个安全帽本地网络，用于测试的华夫饼，用于钱包实现的以太等等。",
  "page-local-environment-solidity-template-logo-alt": "Solidity template徽标",
  "page-local-environment-truffle-desc": "Truffle Suite让开发者尽可能轻松地获取开发应用的主意。",
  "page-local-environment-truffle-logo-alt": "Truffle徽标",
  "page-local-environment-waffle-desc": "智能合约最先进的测试版块。单独使用或使用Scaffold-eth或安全帽。",
  "page-local-environment-waffle-logo-alt": "Waffle徽标",
  "comp-tutorial-metadata-minute-read": "一分钟阅读",
  "page-tutorial-listing-policy-intro": "在您提交教程之前，请阅读我们的上架政策。",
  "comp-tutorial-metadata-tip-author": "提示作者",
  "page-tutorial-listing-policy": "文章上架政策",
  "page-tutorial-new-github": "新到 GitHub？",
  "page-tutorial-new-github-desc": "提出问题 — — 只需填写所需信息并粘贴您的教程。",
  "page-tutorial-pull-request": "创建拉取请求",
  "page-tutorial-pull-request-btn": "创建拉取请求",
  "page-tutorial-pull-request-desc-1": "请跟随",
  "page-tutorial-pull-request-desc-2": "教程/您的教程名称/index.md",
  "page-tutorial-pull-request-desc-3": "命名结构。",
  "page-tutorial-raise-issue-btn": "提出问题",
  "page-tutorial-read-time": "分钟",
  "page-tutorial-submit-btn": "提交一个教程",
  "page-tutorial-submit-tutorial": "要提交一个教程，您需要使用 GitHub 。我们欢迎您创建一个问题或拉取请求。",
  "page-tutorial-subtitle": "欢迎来到我们管理的社区教程的列表。",
  "page-tutorial-tags-error": "没有教程包含所有这些标签",
  "page-tutorial-title": "以太坊开发教程",
  "page-tutorials-meta-description": "按主题浏览并过滤已审查的以太坊社区教程。",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "以太坊开发教程",
  "page-eth-buy-some": "想要买一些以太币吗？",
  "page-eth-buy-some-desc": "很容易将以太坊(Ethereum)和以太币(ETH)混淆。以太坊是区块链，以太币是以太坊上的主要资产。以太币很可能是您想要买的。",
  "page-eth-cat-img-alt": "ETH的图形，它有一只卡莱多斯的猫",
  "page-eth-collectible-tokens": "可收藏的代币",
  "page-eth-collectible-tokens-desc": "代表可收藏游戏项、数码艺术件或其他独特资产的代币。通常称为不可替代代币(NFT)。",
  "page-eth-cryptography": "由加密保护",
  "page-eth-cryptography-desc": "互联网资金可能是新的，但它是由以证明的加密技术保护的。这将保护您的钱包、您的ETH和您的交易。 ",
  "page-eth-currency-for-apps": "这是以太坊应用中的货币。",
  "page-eth-currency-for-future": "我们数字未来的货币",
  "page-eth-description": "ETH是一种加密货币。这是稀缺的数字货币，您可以在互联网上使用——类似于比特币。 如果您是新的用户，点击这里了解ETH如何不同于传统的钱。",
  "page-eth-earn-interest-link": "赚取利息",
  "page-eth-ethhub-caption": "定期更新",
  "page-eth-ethhub-overview": "EthHub有一个很好的概览（如果您想了解的话）",
  "page-eth-flexible-amounts": "具有很好的分割性",
  "page-eth-flexible-amounts-desc": "ETH可拆分多达18个小数点，因此您不必购买一整个ETH。您可以一次购买分片——如果您想购买，只需0.000000000001 ETH。译者注：您实际上一次最少买几百元的ETH",
  "page-eth-fuels": "ETH使以太坊运行并保持以太坊安全",
  "page-eth-fuels-desc": "ETH是以太坊的生命血液。当您发送ETH或使用以太坊应用时，您将用ETH支付少量费用以使用以太坊网络。这笔费用是对矿工处理和验证您试图做什么的奖励。",
  "page-eth-fuels-desc-2": "矿工像以太坊的记录管理员一样——他们检查并证明没有人在欺骗。 从事这项工作的矿工也得到少量新发行的ETH奖金。",
  "page-eth-fuels-desc-3": "矿工确实保证以太坊的安全和不受集中控制。换句话说，",
  "page-eth-fuels-more-staking": "更多关于权益质押的信息",
  "page-eth-fuels-staking": "ETH将会因为权益质押而更重要。当您质押您的ETH时将能帮助保证以太坊的安全并得到奖励。在这个系统中，不用担心您的ETH受到攻击。",
  "page-eth-get-eth-btn": "获取ETH",
  "page-eth-gov-tokens": "权利代币",
  "page-eth-gov-tokens-desc": "代表分散组织投票权的代币。",
  "page-eth-has-value": "为什么ETH有价值？",
  "page-eth-has-value-desc": "ETH以不同方式对不同的人都具有宝贵的价值。",
  "page-eth-has-value-desc-2": "对于Etherum用户来说，ETH很有价值，因为它让您支付交易费用。",
  "page-eth-has-value-desc-3": "另一些人认为它是一种价值的数字存储，因为新的ETH的创建随着时间的推移而减缓。",
  "page-eth-has-value-desc-4": "最近，ETH对以太坊上的金融应用的用户变得很有价值。 这是因为您可以使用ETH作为加密贷款的抵押品或作为付款系统。",
  "page-eth-has-value-desc-5": "当然，许多人也认为它是一种投资，类似于比特币或其他加密货币。",
  "page-eth-how-to-buy": "如何购买ETH",
  "page-eth-how-to-buy-caption": "定期更新",
  "page-eth-is-money": "ETH是数字化的全球性资金。",
  "page-eth-last-updated": "2019年1月",
  "page-eth-mining-link": "更多关于挖矿的信息",
  "page-eth-monetary-policy": "以太坊的货币政策",
  "page-eth-more-on-ethereum-link": "关于以太坊的更多信息",
  "page-eth-no-centralized": "没有中心化控制 ",
  "page-eth-no-centralized-desc": "ETH是分散的和全球性的，没有任何公司或银行能够决定发行更多的ETH，或改变使用条件。",
  "page-eth-non-fungible-tokens-link": "非同质化代币",
  "page-eth-not-only-crypto": "ETH不是以太坊上唯一的加密货币",
  "page-eth-not-only-crypto-desc": "任何人都可以创建新类型的资产并在以太坊交易它们。它们叫做“代币”(tokens)。 人们拥有象征性的传统货币、其房地产、艺术，甚至是自己！",
  "page-eth-not-only-crypto-desc-2": "以太坊有数千个代币——有一些代币比其他代币更有用和更宝贵。 开发者正在不断建立新的代币，解锁新的可能性并打开新的市场。",
  "page-eth-not-only-crypto-desc-3": "如果你想了解更多关于代币的信息，我们在 EthHub 上的朋友已经写了一些很好的概述：",
  "page-eth-open": "对任何人开放",
  "page-eth-open-desc": "您只需要互联网连接和钱包来接受ETH。您不需要访问银行帐户来接受付款。 ",
  "page-eth-p2p-payments": "点对点支付",
  "page-eth-p2p-payments-desc": "您可以在没有像银行这样的中介服务的情况下发送您的ETH。 这就像亲自交出现金，但是您可以与任何人、任何地方和任何时间安全地交出现金。",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "热门的代币类型",
  "page-eth-powers-ethereum": "ETH powers Etherum",
  "page-eth-shit-coins": "垃圾币",
  "page-eth-shit-coins-desc": "因为发行代币很容易，所以任何人都可以做到——甚至是有恶意或误导意图的人也可以做。在使用前您始终要做一些研究！",
  "page-eth-stablecoins": "稳定币",
  "page-eth-stablecoins-desc": "像美元这样的传统货币值的代币。这解决了许多加密货币的波动问题。",
  "page-eth-stablecoins-link": "获得稳定币",
  "page-eth-stream-link": "流通ETH",
  "page-eth-tokens-link": "以太坊代币",
  "page-eth-trade-link-2": "交换代币",
  "page-eth-underpins": "ETH是以太坊金融系统的基础",
  "page-eth-underpins-desc": "对付款不满意，以太坊社区正在建立一个所有人都能利用的同侪金融系统。",
  "page-eth-underpins-desc-2": "您可以用ETH作为抵押品，在Ethereum上生成完全不同的加密货币代币。此外，您可以借入、借出和赚取ETH以及其他以太坊支持的代币的利息。",
  "page-eth-uses": "ETH用户量每天都在增长",
  "page-eth-uses-desc": "因为以太坊是一个平台，提供各种模块让用户来搭建应用",
  "page-eth-uses-desc-2": "回到2015，您能做的仅仅是把ETH从一个地址发送到另一个地址，但现在这是您可做的一件很小的事。",
  "page-eth-uses-desc-3": "闪电收付款。",
  "page-eth-uses-desc-4": "您可以使用包括比特币在内的其他代币交易ETH。",
  "page-eth-uses-desc-5": "关于ETH和其他基于以Etherum为基础的代币。",
  "page-eth-uses-desc-6": "在加密货币世界里较为稳定、波动较小。",
  "page-eth-value": "为什么ETH是有价值的",
  "page-eth-video-alt": "具体抽象的ETH视频记录",
  "page-eth-whats-eth": "什么是以太币 (ETH)？",
  "page-eth-whats-eth-hero-alt": "人们对这些ETH的拥有者感到诧异",
  "page-eth-whats-eth-meta-desc": "您需要哪些数据来了解以太币和以太坊。",
  "page-eth-whats-eth-meta-title": "什么是以太币 (ETH)？",
  "page-eth-whats-ethereum": "以太坊简介",
  "page-eth-whats-ethereum-desc": "如果您想学习更多关于以太坊的知识以及以太币背后的技术，可以查阅我们的白皮书",
  "page-eth-whats-unique": "以太币有什么独特之处？",
  "page-eth-whats-unique-desc": "以太坊上有许多加密货币和许多其他代币，但有些东西只有以太币才能做到。",
  "page-eth-where-to-buy": "从哪里获取以太币",
  "page-eth-where-to-buy-desc": "您可以从交易所或钱包获取以太币，但不同的国家/地区有不同的策略。 选中以查看能让您购买以太币的服务。",
  "page-eth-yours": "这真的是您的",
  "page-eth-yours-desc": "以太币让您成为自己的银行。您可以用您的钱包控制您自己的资金作为所有权证明——第三方没有必要。",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Eth2存款合同地址",
  "page-eth2-deposit-contract-address-caption": "我们通过添加空格使得这个地址易读",
  "page-eth2-deposit-contract-address-check-btn": "确认存款合同地址",
  "page-eth2-deposit-contract-checkbox1": "我已经使用启动面板设置完成了我的Eth2验证者。",
  "page-eth2-deposit-contract-checkbox2": "我理解我需要使用启动面板来进行质押。简单地将ETH发送到这个地址无法奏效。",
  "page-eth2-deposit-contract-checkbox3": "我将核对从其他来源得到的存款合同地址。",
  "page-eth2-deposit-contract-confirm-address": "显示地址前的确认项",
  "page-eth2-deposit-contract-copied": "地址已复制",
  "page-eth2-deposit-contract-copy": "复制地址",
  "page-eth2-deposit-contract-etherscan": "在Etherscan中查看合约",
  "page-eth2-deposit-contract-h2": "您不应该在这里进行质押",
  "page-eth2-deposit-contract-launchpad": "使用启动面板进行质押",
  "page-eth2-deposit-contract-launchpad-2": "使用启动面板",
  "page-eth2-deposit-contract-meta-desc": "验证Eth2质押存款合约地址。",
  "page-eth2-deposit-contract-meta-title": "Eth2存款合约地址",
  "page-eth2-deposit-contract-read-aloud": "大声读出地址",
  "page-eth2-deposit-contract-reveal-address-btn": "显示地址",
  "page-eth2-deposit-contract-staking": " 想要在Eth2中质押您的ETH，您必须使用专用的启动面板并遵循指示操作。 发送ETH到此页面上的地址不会使您成为一个质押者，并将导致交易失败。",
  "page-eth2-deposit-contract-staking-check": "查看相关资源",
  "page-eth2-deposit-contract-staking-check-desc": "我们认为届时将会有很多虚假地址和诈骗。安全起见，请确保您使用的Eth2质押地址与此页面上的地址一致。我们建议使用其他可信的来源复查。",
  "page-eth2-deposit-contract-staking-more-link": "权益质押的更多信息",
  "page-eth2-deposit-contract-stop-reading": "停止阅读",
  "page-eth2-deposit-contract-subtitle": " 这是Eth2抵押合约的地址。请使用此页面确认您已向正确的地址发送资金，以进行质押。",
  "page-eth2-deposit-contract-warning": "请仔细检查每个字符。",
  "page-eth2-deposit-contract-warning-2": "向此地址发送资金将不会起作用，不会使您成为一个质押者。您必须遵循启动板的指示。",
  "page-eth2-bug-bounty-annotated-specs": "带注释的规范",
  "page-eth2-bug-bounty-annotations": "查看以下注释可能会有所帮助：",
  "page-eth2-bug-bounty-client-bugs": "Eth2客户端漏洞",
  "page-eth2-bug-bounty-client-bugs-desc": "一旦升级完成部署，这些客户端将运行信标链。客户端需要遵循规范中规定的逻辑，并确保免受潜在攻击。我们想要查找与协议实现相关的漏洞。",
  "page-eth2-bug-bounty-client-bugs-desc-2": "目前，Lighthouse、Nimbus、Teku和Prysm的bug有资格获得这个奖励。当更多的客户端完成审查并准备成为正式产品时，它们也可能会加入进来。",
  "page-eth2-bug-bounty-clients": "参与赏金计划的客户端",
  "page-eth2-bug-bounty-clients-type-1": "规范不兼容问题。",
  "page-eth2-bug-bounty-clients-type-2": "意外崩溃或拒绝服务(DOS)漏洞。",
  "page-eth2-bug-bounty-clients-type-3": "任何无法恢复的、针对网络其余部分的共识分裂问题。",
  "page-eth2-bug-bounty-docking": "对接",
  "page-eth2-bug-bounty-email-us": "发邮件给我们：",
  "page-eth2-bug-bounty-help-links": "帮助链接",
  "page-eth2-bug-bounty-hunting": "漏洞悬赏规则",
  "page-eth2-bug-bounty-hunting-desc": "漏洞悬赏计划是针对我们活跃的以太坊社区的一项实验性和可任意决定的奖励计划，旨在鼓励和奖励那些正在帮助改善平台的人。这不是比赛。您需要知道我们可以随时取消该计划，并且奖励金额由以太坊基金会漏洞悬赏小组全权决定。此外，我们无法向在制裁名单上或在制裁名单上的国家/地区（例如朝鲜、伊朗等）的个人颁发奖励。您需要为奖金支付相应税赋。所有奖励均受适用法律的约束。最后，您的测试不得违反任何法律或损害任何非您拥有的数据。",
  "page-eth2-bug-bounty-hunting-leaderboard": "漏洞悬赏排行榜",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "找到Eth2漏洞以加入排行榜",
  "page-eth2-bug-bounty-hunting-li-1": "已经被其它参与者提交或者已经被规范提及的问题，同时客户端的维护者不能参与赏金计划。",
  "page-eth2-bug-bounty-hunting-li-2": "已经公开的漏洞不适用于赏金计划。",
  "page-eth2-bug-bounty-hunting-li-3": "以太坊基金会的研究者和eth2客户端团队的雇员无法参与赏金计划。",
  "page-eth2-bug-bounty-hunting-li-4": "以太坊赏金计划将考虑一系列影响奖赏金额的因素。参与资格、分数以及所有奖赏相关的因素将由以太坊基金会漏洞悬赏小组全权决定。",
  "page-eth2-bug-bounty-leaderboard": "查看完整排行榜",
  "page-eth2-bug-bounty-leaderboard-points": "积分",
  "page-eth2-bug-bounty-ledger-desc": "信标链规范细化了设计初衷和信标链升级将对以太坊造成的影响。",
  "page-eth2-bug-bounty-ledger-title": "信标链规范漏洞",
  "page-eth2-bug-bounty-meta-description": "关于Eth2漏洞悬赏计划的简介：如何参与和奖励信息。",
  "page-eth2-bug-bounty-meta-title": "Eth2漏洞悬赏计划",
  "page-eth2-bug-bounty-not-included": "未包含",
  "page-eth2-bug-bounty-not-included-desc": "分片链和对接升级仍处于开发阶段，因此未包含在这个漏洞悬赏计划中。",
  "page-eth2-bug-bounty-owasp": "查看OWASP方法",
  "page-eth2-bug-bounty-points": "以太坊基金会将基于以下规则授予积分：",
  "page-eth2-bug-bounty-points-error": "加载数据发生错误，请刷新页面。",
  "page-eth2-bug-bounty-points-exchange": "积分汇率",
  "page-eth2-bug-bounty-points-loading": "正在加载数据...",
  "page-eth2-bug-bounty-points-payout-desc": " 以太坊基金会将会使用和美元等价的ETH或者DAI支付。",
  "page-eth2-bug-bounty-points-point": "1积分",
  "page-eth2-bug-bounty-points-rights-desc": "以太坊基金会保留在没有提前通知的情况修改规则的权力。",
  "page-eth2-bug-bounty-points-usd": "2美元",
  "page-eth2-bug-bounty-quality": "说明描述的质量",
  "page-eth2-bug-bounty-quality-desc": "：为组织清晰、描述清楚的提交文档支付更高的报酬。",
  "page-eth2-bug-bounty-quality-fix": "修复补丁的质量",
  "page-eth2-bug-bounty-quality-fix-desc": "如果包含：为提供清晰修复方案的提交支付更高的报酬。",
  "page-eth2-bug-bounty-quality-repro": "可复现能力的质量",
  "page-eth2-bug-bounty-quality-repro-desc": "：请包含测试代码、脚本或者其它详细的指令。我们越容易复现并确认问题，您将获得越高的报酬。",
  "page-eth2-bug-bounty-questions": "有问题？",
  "page-eth2-bug-bounty-rules": "阅读规则",
  "page-eth2-bug-bounty-shard-chains": "分片链",
  "page-eth2-bug-bounty-slogan": "Eth2漏洞悬赏",
  "page-eth2-bug-bounty-specs": "阅读完整规范",
  "page-eth2-bug-bounty-specs-docs": "规范文档",
  "page-eth2-bug-bounty-submit": "提交一个漏洞",
  "page-eth2-bug-bounty-submit-desc": "我们为您发现的每一个漏洞奖励积分。您获得的积分多少取决于漏洞的严重程度。以太坊基金(EF)会根据OWASP方法判断漏洞的严重程度。",
  "page-eth2-bug-bounty-subtitle": "通过查找Eth2协议和客户端的漏洞赢得最高$50,000美元的奖赏并在排行榜上占有一席之地。",
  "page-eth2-bug-bounty-title": "开放提交",
  "page-eth2-bug-bounty-title-1": "信标链",
  "page-eth2-bug-bounty-title-2": "分支选择",
  "page-eth2-bug-bounty-title-3": "Solidity存款合约",
  "page-eth2-bug-bounty-title-4": "点对点网络",
  "page-eth2-bug-bounty-type-1": "安全性/触发崩溃的确定性漏洞",
  "page-eth2-bug-bounty-type-2": "拒绝提供服务(DOS)的潜在漏洞",
  "page-eth2-bug-bounty-type-3": "假设的不一致，例如诚实的验证者大幅减少的情况。",
  "page-eth2-bug-bounty-type-4": "计算和参数的不一致性",
  "page-eth2-bug-bounty-types": "漏洞类型",
  "page-eth2-bug-bounty-validity": "有效漏洞",
  "page-eth2-bug-bounty-validity-desc": "本漏洞悬赏计划专注于查找Eth2信标链规范和Prysm、Lighthouse和Teku等客户端实现上的漏洞。",
  "page-eth2-bug-bountycard-critical": "高危",
  "page-eth2-bug-bountycard-critical-risk": "提交高危风险漏洞",
  "page-eth2-bug-bountycard-h2": "中等",
  "page-eth2-bug-bountycard-high": "高",
  "page-eth2-bug-bountycard-high-risk": "提交高风险漏洞",
  "page-eth2-bug-bountycard-label-1": "最多1,000积分",
  "page-eth2-bug-bountycard-label-2": "最多2,000 DAI",
  "page-eth2-bug-bountycard-label-3": "最多5,000积分",
  "page-eth2-bug-bountycard-label-4": "最多10,000 DAI",
  "page-eth2-bug-bountycard-label-5": "最多10,000积分",
  "page-eth2-bug-bountycard-label-6": "最多20,000 DAI",
  "page-eth2-bug-bountycard-label-7": "最多25,000积分",
  "page-eth2-bug-bountycard-label-8": "最多50,000 DAI",
  "page-eth2-bug-bountycard-li-1": "低影响力，中等可能性",
  "page-eth2-bug-bountycard-li-2": "中等影响力，低可能性",
  "page-eth2-bug-bountycard-li-3": "高影响力，低可能性",
  "page-eth2-bug-bountycard-li-4": "中等影响力，中等可能性",
  "page-eth2-bug-bountycard-li-5": "低影响力，高可能性",
  "page-eth2-bug-bountycard-li-6": "高影响力，中等可能性",
  "page-eth2-bug-bountycard-li-7": "中等影响力，高可能性",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "低",
  "page-eth2-bug-bountycard-low-risk": "提交低风险漏洞",
  "page-eth2-bug-bountycard-medium-risk": "提交中等风险漏洞",
  "page-eth2-bug-bountycard-subheader": "严重程度",
  "page-eth2-bug-bountycard-subheader-2": "样例",
  "page-eth2-bug-bountycard-text": "攻击者有时可以促使一个节点进入一种非正常的状态：以1/100的概率丢失验证者发来的投票信息。",
  "page-eth2-bug-bountycard-text-1": " 攻击者可以成功地针对以4个0字节作为节点ID的节点发动eclipse攻击",
  "page-eth2-bug-bountycard-text-2": "两个客户端之间存在共识分歧，且其并不容易成为攻击者的目标。",
  "page-eth2-bug-bountycard-text-3": "  当两个客户端之间存在共识分歧，很容易成为攻击者的目标。",
  "page-eth2-get-involved-btn-1": "查看客户端",
  "page-eth2-get-involved-btn-2": "权益质押的更多信息",
  "page-eth2-get-involved-btn-3": "查找漏洞",
  "page-eth2-get-involved-bug": "漏洞可能是：",
  "page-eth2-get-involved-bug-hunting": "定位程序漏洞",
  "page-eth2-get-involved-bug-hunting-desc": "查找并报告Eth2升级规范和客户端中的漏洞。您可以赢得高达$50,000美元的奖励并在排名榜上占有一席之地。",
  "page-eth2-get-involved-bug-li": "规范的兼容性问题",
  "page-eth2-get-involved-bug-li-2": "确定会触发程序崩溃的问题",
  "page-eth2-get-involved-bug-li-3": "拒绝提供服务(DOS)的潜在漏洞",
  "page-eth2-get-involved-bug-li-4": "及更多...",
  "page-eth2-get-involved-date": "结束日期：2020年12月23日",
  "page-eth2-get-involved-desc-1": "运行客户端意味着您将成为以太坊的积极参与者。您的客户端将帮助跟踪交易并检查新的区块。",
  "page-eth2-get-involved-desc-2": "如果您持有ETH，您可以通过质押成为验证者，帮助确保网络的安全。作为验证者，您可以获得ETH作为奖励。",
  "page-eth2-get-involved-desc-3": "加入社区的测试力量！在上线前帮助测试Eth2升级，查找漏洞并获得奖励。",
  "page-eth2-get-involved-ethresearch-1": "分片",
  "page-eth2-get-involved-ethresearch-2": "Eth1到Eth2的转换",
  "page-eth2-get-involved-ethresearch-3": "分片及状态执行",
  "page-eth2-get-involved-ethresearch-4": "所有研究专题",
  "page-eth2-get-involved-grants": "权益质押社区赠予",
  "page-eth2-get-involved-grants-desc": "帮助为质押者社区创建工具和宣导内容",
  "page-eth2-get-involved-how": "我应该如何参与？",
  "page-eth2-get-involved-how-desc": "以太坊社区将始终从运行客户端、质押和查找漏洞的更多行动中获益。",
  "page-eth2-get-involved-join": "参与研究",
  "page-eth2-get-involved-join-desc": " 和以太坊的其它东西一样，大量研究都是公开的。这意味着您可以参与讨论或者只是阅读它们。在ethresear.ch里包含了不局限于Eth2升级的大量信息。",
  "page-eth2-get-involved-meta-description": "如何参与Eth2：运行节点、质押、查找漏洞等等。",
  "page-eth2-get-involved-more": "更多信息",
  "page-eth2-get-involved-run-clients": "运行信标链客户端",
  "page-eth2-get-involved-run-clients-desc": "保证以太坊长期安全的关键是客户端去中心化的强度。客户端是运行区块链、检查交易并创造新区块的软件。每种客户端都有自己的功能列表，请按照您的需求进行选择。",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "质押您的ETH",
  "page-eth2-get-involved-stake-desc": "您现在可以质押您的ETH，帮助保证信标链的安全。",
  "page-eth2-get-involved-stake-eth": "质押ETH",
  "page-eth2-get-involved-subtitle": "这里是所有您能帮助以太坊发展或者和参与未来Eth2相关工作的所有方法：",
  "page-eth2-get-involved-title-1": "运行客户端",
  "page-eth2-get-involved-title-2": "质押您的ETH",
  "page-eth2-get-involved-title-3": "查找错误",
  "page-eth2-get-involved-written-go": "用Go进行编写",
  "page-eth2-get-involved-written-java": "用Java进行编写",
  "page-eth2-get-involved-written-javascript": "用JavaScript进行编写",
  "page-eth2-get-involved-written-net": "用.NET进行编写",
  "page-eth2-get-involved-written-nim": "用Nim进行编写",
  "page-eth2-get-involved-written-python": "用Python进行编写",
  "page-eth2-get-involved-written-rust": "用Rust进行编写",
  "eth2-client-cortex-logo-alt": "Cortex徽标",
  "eth2-client-lighthouse-logo-alt": "Lighthouse徽标",
  "eth2-client-lodestar-logo-alt": "Lodestar徽标",
  "eth2-client-nimbus-logo-alt": "Nimbus徽标",
  "eth2-client-prysm-logo-alt": "Prysm徽标",
  "eth2-client-teku-logo-alt": "Teku徽标",
  "eth2-rhino-img-alt": "图片：Eth2 Rhino正在充电",
  "page-eth2-answer-1": "Eth2可以看作是一组被添加到今天我们正在使用的ETH中的升级。这些升级包括创造一个叫做信标链的新链以及分片，这个分片可以包括多达64条链。",
  "page-eth2-answer-2": "这些将与我们正在使用的以太坊主网分开，但是这并不意味着它替代以太坊主网。恰恰相反，随着时间的推进，主网将和这个不断被增加的并行系统进行合并。",
  "page-eth2-answer-4": "换种说法，我们今天所使用的以太坊将会最终体现我们在ETH2上所期待的所有特点。",
  "page-eth2-beacon-chain-btn": "关于信标链的更多信息",
  "page-eth2-beacon-chain-date": "信标链在2020年12月1日正式启用。",
  "page-eth2-beacon-chain-desc": "这是Eth2对于生态系统的第一个升级。信标链将给以太坊带来权益质押，为将来的升级打下基础，最终成为新系统的协调者。",
  "page-eth2-beacon-chain-estimate": "信标链已经上线",
  "page-eth2-beacon-chain-title": "信标链",
  "page-eth2-bug-bounty": "查看错误赏金计划",
  "page-eth2-clients": "查看Eth2客户端",
  "page-eth2-deposit-contract-title": "确认存款合约地址",
  "page-eth2-diagram-ethereum-mainnet": "以太坊主网",
  "page-eth2-diagram-h2": "每一次升级如何适配在一起",
  "page-eth2-diagram-link-1": "关于工作量证明的更多信息",
  "page-eth2-diagram-link-2": "关于分片的更多信息",
  "page-eth2-diagram-mainnet": "主网",
  "page-eth2-diagram-p": "以太坊主网将以现在的形式继续存在一段时间，这也意味着信标链和分片升级将不会干扰主网的运行。",
  "page-eth2-diagram-p-1": "主网最终将与Eth2升级引入的新系统合并。",
  "page-eth2-diagram-p-2": "信标链将成为以太坊的控制者，对验证者进行协调，并控制出块速度。",
  "page-eth2-diagram-p-3": "首先，它将会独立于主网存在并管理验证者 —— 它暂时和智能合约、交易和账户无关。",
  "page-eth2-diagram-p-4": "分片将会提供额外的数据，以提高主网能够处理的交易数量。它们由信标链协调控制。",
  "page-eth2-diagram-p-5": "但是所有的交易仍然依赖主网，即当前存在的由矿工工作量保证安全的网络。",
  "page-eth2-diagram-p-6": "主网将和权益证明的系统进行合并，归由信标链控制协调。",
  "page-eth2-diagram-p-8": "这将使主网转变成新系统的一个分片。整个以太坊将不再需要矿工，转而由验证者保证安全。",
  "page-eth2-diagram-p10": "Eth2不是一次单独的合并或者升级。它指一系列激发以太坊潜力的升级。下图展示了它们之间如何配合。",
  "page-eth2-diagram-scroll": "请下拉探索 —— 点击以查看更多信息。",
  "page-eth2-diagram-shard": "分片 (1)",
  "page-eth2-diagram-shard-1": "分片 (...)",
  "page-eth2-diagram-shard-2": "分片 (2)",
  "page-eth2-diagram-shard-3": "分片 (...)",
  "page-eth2-diagram-validators": "验证者的更多信息",
  "page-eth2-dive": "深入了解愿景",
  "page-eth2-dive-desc": "在保证以太坊的去中心化作为核心理念的同时，我们将如何使得以太坊变得更具可扩展性，更安全，更具有可持续性发展？",
  "page-eth2-docking": "对接",
  "page-eth2-docking-answer-1": "对接发生在将主网转换成一个分片时。这将在分片链成功实现后发生。",
  "page-eth2-docking-btn": "对接的更多信息",
  "page-eth2-docking-desc": "在未来的某个时间点，以太坊主网将需要和信标链进行对接。这将标志着整个网络启用权益证明的共识机制并且意味着能量消耗型矿工的终结。",
  "page-eth2-docking-estimate": "预计上线时间：2022",
  "page-eth2-docking-mainnet": "什么是主网？",
  "page-eth2-docking-mainnet-eth2": "将主网对接入Eth2",
  "page-eth2-eth-blog": "ethereum.org博客",
  "page-eth2-explore-btn": "探索升级",
  "page-eth2-get-involved": "参与Eth2",
  "page-eth2-get-involved-2": "参与其中",
  "page-eth2-head-to": "打开",
  "page-eth2-help": "想帮助Eth2发展？",
  "page-eth2-help-desc": "有很多机会参与Eth2的升级，帮助测试或者获得奖励。",
  "page-eth2-index-staking": "质押",
  "page-eth2-index-staking-desc": "Eth2的关键是引入质押。如果您希望使用您的ETH来帮助保证以太坊网络的安全，请参考以下指引。",
  "page-eth2-index-staking-learn": "了解质押",
  "page-eth2-index-staking-learn-desc": "信标链将为以太坊引入质押。这意味着如果您拥有ETH，您可以在这个过程中帮助保证网络安全并获得更多ETH作为回报。",
  "page-eth2-index-staking-step-1": "1. 用启动面板进行设置",
  "page-eth2-index-staking-step-1-btn": "访问质押启动面板",
  "page-eth2-index-staking-step-1-desc": "想要参与Eth2的质押您需要使用启动面板 —— 它将会带领您完成整个过程。",
  "page-eth2-index-staking-step-2": "2. 确认质押地址",
  "page-eth2-index-staking-step-2-btn": "确认质押存款地址",
  "page-eth2-index-staking-step-2-desc": "在质押您的ETH之前，确保您的地址正确。在这一步之前您必须已经通过启动面板完成设置。",
  "page-eth2-index-staking-sustainability": "可持续发展的更多信息",
  "page-eth2-just-docking": "对接的更多信息",
  "page-eth2-meta-desc": "以太坊2.0的简介以及未来他们想要实现的愿景。",
  "page-eth2-meta-title": "Eth2升级",
  "page-eth2-miners": "矿工的更多信息",
  "page-eth2-more-on-upgrades": "Eth2升级的更多信息",
  "page-eth2-proof-of-stake": "权益证明",
  "page-eth2-proof-of-work": "工作量证明",
  "page-eth2-proof-stake-link": "权益证明的更多信息",
  "page-eth2-question-1-desc": "Eth2是一系列相互区分、独立上线的升级。",
  "page-eth2-question-1-title": "Eth2将在何时上线？",
  "page-eth2-question-2-desc": "将Eth2想象成一个单独的区块链并不准确。",
  "page-eth2-question-2-title": "Eth2是一个独立的区块链吗？",
  "page-eth2-question-3-answer-2": "分片链和对接升级将影响去中心化应用的开发者们。但是相关规范还未完成，所以并没有需要立即处理的事项。",
  "page-eth2-question-3-answer-3": "在ethresear.ch上和研究及开发团队进行沟通。",
  "page-eth2-question-3-answer-3-link": "访问ethresear.ch",
  "page-eth2-question-3-desc": "针对Eth2升级您目前不需要做任何事。",
  "page-eth2-question-3-title": "我需要为Eth2的到来做些什么准备？",
  "page-eth2-question-4-answer-1": "当前无论您发送一笔交易或者使用去中心化应用，您使用的都是Eth1。这是由矿工保证安全的以太坊。",
  "page-eth2-question-4-answer-2": "\"Eth1\"或者说主网，在对接前都将保持运行。",
  "page-eth2-question-4-answer-3": "在对接后，验证者们将会通过权益证明的方式保证整个网络的安全。",
  "page-eth2-question-4-answer-6": "任何人都可以通过质押他们的ETH成为一个验证者。",
  "page-eth2-question-4-answer-7": "质押的更多信息",
  "page-eth2-question-4-answer-8": "信标链和分片链的升级不会和Eth1发生冲突，因为它们是分开独立构建的。",
  "page-eth2-question-4-desc": "Eth1指您今天进行交易使用的以太坊主网。",
  "page-eth2-question-4-title": "什么是Eth1？",
  "page-eth2-question-5-answer-1": "想要成为网络的一个完全验证者，您需要质押32个ETH。如果您没有那么多，或者不想质押那么多，您可以参与质押池。这些质押池能够帮助您少量质押并获得相应份额的奖励。",
  "page-eth2-question-5-desc": "您可以使用质押控制板或者加入一个质押池。",
  "page-eth2-question-5-title": "如何质押？",
  "page-eth2-question-6-answer-1": "当前没有什么需要做的。但是我们推荐您对于分片链和对接的开发升级工作保持关注。",
  "page-eth2-question-6-answer-3": "以太坊基金会的Danny Ryan负责定期更新社区：",
  "page-eth2-question-6-answer-4": "ConsenSys的Ben Edgington拥有一份Eth2周报：",
  "page-eth2-question-6-answer-5": "您也可以加入ethresear.ch参与Eth2的研究讨论和开发。",
  "page-eth2-question-6-desc": "您的去中心化应用不会受到最近升级的任何影响。但是未来可能会需要一些更新。",
  "page-eth2-question-6-title": "针对我的去中心化应用需要做什么？",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "（.Net语言实现）",
  "page-eth2-question-7-desc": "社区中很多不同的团队都在为Eth2的升级工作。",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "（Rust语言实现）",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "（JavaScript语言实现）",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "（Nim语言实现）",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "（Go语言实现）",
  "page-eth2-question-7-teams": "Eth2的客户端团队：",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "（Java语言实现）",
  "page-eth2-question-7-title": "谁在构建Eth2？",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "（Python语言实现）",
  "page-eth2-question-8-answer-1": "Eth2升级将帮助以太坊以去中心化的方式进行扩展，同时保证安全性并提升可持续发展的能力。",
  "page-eth2-question-8-answer-2": "可能最显著的问题是以太坊需要处理超过每秒15-45笔交易的能力。但是Eth2升级同时还解决很多当前以太坊面临的其它问题。",
  "page-eth2-question-8-answer-3": "网络的使用需求激增使得以太坊变得昂贵。网络中的节点正在以太坊网络的扩张和自身需要处理的数据量之间的矛盾中挣扎。另外保证以太坊底层安全和去中心化的算法也需要改进以使其变得更加节能。",
  "page-eth2-question-8-answer-4": "以太坊的路线图上呈现了从2015年起正在发生的大量变化。但是当前环境使得升级变得更加紧迫。",
  "page-eth2-question-8-answer-6": "探索Eth2的愿景",
  "page-eth2-question-8-desc": "我们当前正在使用的以太坊需要为终端用户和网络参与者提供更好的体验。",
  "page-eth2-question-8-title": "为什么需要Eth2？",
  "page-eth2-question-9-answer-1": "当前您能发挥的最积极的作用是质押您的ETH。",
  "page-eth2-question-9-answer-2": "您可以运行第二个客户端来提高客户端的密度。",
  "page-eth2-question-9-answer-3": "如果您有技术专长，可以帮忙查找新客户端的漏洞。",
  "page-eth2-question-9-answer-4": "您可以在ethresear.ch和以太坊研究者进行各种讨论。",
  "page-eth2-question-9-desc": "您不需要成为技术专家来为以太坊作出贡献。社区需要各种技能的同路人贡献自己的力量。",
  "page-eth2-question-9-stake-eth": "质押ETH",
  "page-eth2-question-9-title": "如何为Eth2作出贡献？",
  "page-eth2-question-10-answer-1": "我们不想过多谈论技术路线图的相关术语，因为这是软件：情况随时可能改变。我们觉得介绍当先正在发生的事情更易于您理解。",
  "page-eth2-question-10-answer-1-link": "查看相关升级",
  "page-eth2-question-10-answer-2": "如果您已经关注了相关讨论，这里呈现了相关升级将如何适配技术路线图。",
  "page-eth2-question-10-answer-3": "阶段0描述了如何上线信标链的相关工作。",
  "page-eth2-question-10-answer-5": "技术路线图的阶段1将专注于实现分片链。",
  "page-eth2-question-10-answer-6": "在阶段1.5，主网将对接Eth2，这是分片链的扩展，并标志着分片链的完成。这是一个重要时刻，因为我们现在熟知的以太坊将和其它Eth2升级合并，另外以太坊将完全切换成权益证明的共识模式。",
  "page-eth2-question-10-answer-7": "现在对于阶段2将会部署的更新还未确定。仍需要一些深入研究和讨论。一开始的设想是为分片链加入一些额外功能，但是在以汇总为中心的路线图中，这似乎并不是必须的。",
  "page-eth2-question-10-answer-8": "以汇总为中心的路线图的更多信息",
  "page-eth2-question-10-desc": "请关注工作的各个阶段以及Eth2的技术路线图。",
  "page-eth2-question-10-title": "什么是Eth2升级的各个阶段？",
  "page-eth2-question-title": "常见问题",
  "page-eth2-question3-answer-1": "ETH的持有者不需要做任何事。您的ETH不需要兑换或者升级。但是一定会有一些骗子告诉您需要这么做，所以请小心。",
  "page-eth2-scalable": "更具可扩展性",
  "page-eth2-scalable-desc": "以太坊需要支持每秒1000个交易，以使得以太坊应用更快，易于被大众接受。",
  "page-eth2-secure": "更安全",
  "page-eth2-secure-desc": "以太坊需要变得更安全。随着以太坊的应用越来越广泛，协议本身需要变得更加安全，以对抗各种形式的攻击。",
  "page-eth2-shard-button": "分片链的更多信息",
  "page-eth2-shard-date": "关于分片链（作为第二个升级）的简介，预期于2021年上线。",
  "page-eth2-shard-desc": "分片链将扩展以太坊的容量以处理和存储更多的交易信息。这些分片将在以后分阶段实现更多功能。",
  "page-eth2-shard-estimate": "预估上线时间：2021",
  "page-eth2-shard-lower": "分片链的更多信息",
  "page-eth2-shard-title": "分片链",
  "page-eth2-stay-up-to-date": "新闻",
  "page-eth2-stay-up-to-date-desc": "获得研究者和开发者关于Eth2升级的工作的最新信息。",
  "page-eth2-sustainable-desc": "以太坊需要为保护环境变得更好。现在使用的技术消耗了太多的算力和能源。",
  "page-eth2-take-part": "参与研究",
  "page-eth2-take-part-desc": "以太坊的研究者和布道者聚在这里讨论研究成果以及以太坊的一切东西。",
  "page-eth2-the-upgrades": "Eth2升级",
  "page-eth2-the-upgrades-desc": "Eth2是一系列提升以太坊可扩展性、安全性和可持续发展能力的升级。虽然它们每一个都在并行开发，但是它们之间存在的依赖决定了它们的部署顺序。",
  "page-eth2-unofficial-roadmap": "这不是官方的路线图。这是我们从正在发生的视角汇总的信息。但是技术无时不在变化。因此请不要把它看做一份承诺。",
  "page-eth2-upgrade-desc": "依然是那个我们熟悉和深爱的以太坊，只是更加具有可扩展性，更安全，更支持可持续发展。",
  "page-eth2-upgrades": "Eth2升级",
  "page-eth2-upgrades-aria-label": "Eth2的升级内容",
  "page-eth2-upgrades-beacon-chain": "信标链",
  "page-eth2-upgrades-docking": "对接主网和Eth2",
  "page-eth2-upgrades-guide": "Eth2的升级指引",
  "page-eth2-upgrades-shard-chains": "分片链",
  "page-eth2-upgrading": "升级以太坊到一个重要的新高度",
  "page-eth2-vision": "愿景",
  "page-eth2-vision-btn": "Eth2愿景的更多信息",
  "page-eth2-vision-desc": "为了将以太坊带入人们的主流视野以造福全人类，我们需要让以太坊变得更具有可扩展性，更安全，更加可持续地发展。",
  "page-eth2-vision-upper": "Eth2愿景",
  "page-eth2-what-to-do": "您需要做什么？",
  "page-eth2-what-to-do-desc": "如果您是一个去中心化应用用户或者ETH的持有者，您可以坐享其成。如果您是一个开发者或者想要参与质押，这里有一些帮助您参与其中的指引。",
  "page-eth2-whats-eth2": "什么是Eth2？",
  "page-eth2-whats-eth2-desc": "Eth2是一系列相关的升级，目标是使得以太坊更加可扩展、更安全以及更节能。这些更新由以太坊生态下的多个团队共同开发。",
  "page-eth2-whats-ethereum": "等等，什么是以太坊？",
  "page-eth2-whats-new": "Eth2有哪些新功能？",
  "page-eth2-just-staking": "权益质押",
  "page-eth2-staking-image-alt": "插图：Rhino mascot的Eth2启动面板。",
  "page-eth2-staking-51-desc-1": "权益质押使得作为验证者加入网络变得更加容易。因此相对于如今而言，网络中将会有更多的验证者。这将使这种攻击更加困难，因为攻击的成本将增加。",
  "page-eth2-staking-accessibility": "更容易访问",
  "page-eth2-staking-accessibility-desc": "通过降低硬件需求，以及为没有32个ETH的人提供参与质押池的机会，会有更多的人加入网络。这将使得以太坊更加去中心化和安全，因为被攻击时遭受的影响将减少。",
  "page-eth2-staking-at-stake": "您的ETH处于质押状态",
  "page-eth2-staking-at-stake-desc": "因为您需要质押您的ETH才能验证交易并创建新的区块， 如果您决定尝试欺骗系统，您将会失去这些ETH。",
  "page-eth2-staking-benefits": "对以太坊权益质押的好处",
  "page-eth2-staking-check-address": "检查充值地址",
  "page-eth2-staking-consensus": "关于共识机制的更多信息",
  "page-eth2-staking-deposit-address": "检查充值地址",
  "page-eth2-staking-deposit-address-desc": "如果您已经完成了启动面板中的指示操作，您将需要向权益质押协议发送一个交易。我们建议您非常小心地确认接收地址。您可以在ethereum.org上找到官方地址和一系列其它受信站点。",
  "page-eth2-staking-desc-1": "对于向帮助网络达成共识机制的行为将会得到相应的奖励。这种奖励是为了鼓励将交易记录到新的区块的贡献，或者可以说是检测其他验证者节点产出结果的贡献，因为这将会让区块安全地运行。",
  "page-eth2-staking-desc-2": "即使您可以通过对网络做贡献而得到报酬，但恶意的行为、离线和失败的校验仍然可能会使您失去ETH。",
  "page-eth2-staking-desc-3": "您需要至少32个ETH才能加入资质抵押池。同时您将会需要运行一个‘Eth1’或者主网络链。启动台将会带您穿过进程和硬件需求。或者您可以使用后端的API。",
  "page-eth2-staking-description": "权益质押（Staking）是存入32个ETH以激活验证者软件的行为。 作为验证者，您将负责存储数据、处理交易和向区块链中添加新的块。 这将为所有人保证以太坊的安全，并在这个过程中赚取新的ETH。 这个由信标链引入的过程被称为质押权益证明。",
  "page-eth2-staking-docked": "对接的更多信息",
  "page-eth2-staking-dyor": "自行调查",
  "page-eth2-staking-dyor-desc": "列出的权益质押服务都不是官方背书的。请进行一些研究，找出哪个服务最适合您。",
  "page-eth2-staking-header-1": "质押您的以太币并成为以太坊验证者",
  "page-eth2-staking-how-much": "您愿意质押多少ETH？",
  "page-eth2-staking-how-to-stake": "如何质押",
  "page-eth2-staking-how-to-stake-desc": "这完全取决于您想要质押多少。您可以通过质押32个ETH来成为一个全功能验证者，同时也可以选择质押少量的ETH。",
  "page-eth2-staking-join": "加入",
  "page-eth2-staking-join-community": "加入权益质押者社区",
  "page-eth2-staking-join-community-desc": "r/ethstaker是一个社区，每个人都可以在其中讨论以太坊权益质押相关的事情——加入到这里来，为其他人提供咨询、支持并谈论所有和权益质押相关的事情。",
  "page-eth2-staking-less-than": "小于",
  "page-eth2-staking-link-1": "查看后端接口（API）",
  "page-eth2-staking-meta-description": "以太坊权益质押概述：风险、奖励、要求以及在哪里进行。",
  "page-eth2-staking-meta-title": "以太坊权益质押",
  "page-eth2-staking-more-sharding": "关于分区的更多信息",
  "page-eth2-staking-pool": "参与权益质押池",
  "page-eth2-staking-pool-desc": "如果您的ETH少于32个，您可以添加少量的质押到权益质押池中。有一些公司可以代表您去做权益质押，所以您不必担心需要保持在线。这里可以查找到相关公司。",
  "page-eth2-staking-pos-explained": "权益质押的解释",
  "page-eth2-staking-pos-explained-desc": "如果想在权益证明系统里成为验证者，您需要进行权益质押。这是一种即将取代工作量证明的共识机制。正是共识机制保证了区块链的安全和去中心化。",
  "page-eth2-staking-pos-explained-desc-1": "权益证明从以下几个方面确保网络的安全：",
  "page-eth2-staking-services": "查看质押服务",
  "page-eth2-staking-sharding": "解锁分区",
  "page-eth2-staking-sharding-desc": "分片只有在权益证明的系统中才是可行的。在工作量证明的系统中应用分片将减少损坏网络所需的算力，使得恶意矿工更容易地控制分片。而权益证明系统采用的随机分配验证者机制将避免这种情况。",
  "page-eth2-staking-solo": "独立质押并运行一个验证者节点",
  "page-eth2-staking-solo-desc": "为了开始质押流程，您需要使用Eth2启动面板。它会引导您完成所有设置。运行一个带有区块链本地拷贝的Eth2客户端是质押过程的一部分，下载它到您的电脑需要时间。",
  "page-eth2-staking-start": "开始质押",
  "page-eth2-staking-subtitle": "质押是以太坊生态中的公益行为。您可以在这个过程中保护网络的安全并获得奖励。",
  "page-eth2-staking-sustainability": "更环保",
  "page-eth2-staking-sustainability-desc": "验证者不再需要在工作量证明系统中使用的那些能源消耗型电脑 —— 只需要一台笔记本或者一部手机即可。这会让以太坊变得更环保。",
  "page-eth2-staking-the-beacon-chain": "关于信标链的更多信息",
  "page-eth2-staking-title-1": "奖励",
  "page-eth2-staking-title-2": "风险",
  "page-eth2-staking-title-3": "要求",
  "page-eth2-staking-title-4": "如何质押您的ETH",
  "page-eth2-staking-upgrades-li": "权益质押由信标链管理。",
  "page-eth2-staking-upgrades-li-2": "在可预见的未来以太坊就会拥有一个权益证明的信标链和一个工作量证明的主网。主网即我们现在正在使用的那个以太坊网络。",
  "page-eth2-staking-upgrades-li-3": "在这段时间里，质押者将负责向信标链添加新的区块，但是并不处理主网交易。",
  "page-eth2-staking-upgrades-li-4": "一旦主网变成一个分片，以太坊将会完整切换至权益证明的系统。",
  "page-eth2-staking-upgrades-li-5": "只有到那时，您才能取回您的质押。",
  "page-eth2-staking-upgrades-title": "权益质押和Eth2升级",
  "page-eth2-staking-validators": "越多的验证者，越安全",
  "page-eth2-staking-validators-desc": "在以太坊这样的区块链中，如果您拥有51%的算力就可能破坏网络。例如您可以让51%的验证者都声称您的账户收到1,000,000 ETH 而非 1 ETH。 但是，想要控制51%的验证者您需要控制51%的ETH —— 那真是太多了！",
  "page-eth2-staking-withdrawals": "取款功能并不会立即上线",
  "page-eth2-staking-withdrawals-desc": "在未来部署相关升级之前，您将不能取回您的质押。一旦当主网和信标链完成对接，质押物就可以取回。",
  "page-eth2-vision-2014": "查看2014年的一篇详细阐述权益证明的博客",
  "page-eth2-vision-beacon-chain": "信标链",
  "page-eth2-vision-beacon-chain-btn": "信标链的更多信息",
  "page-eth2-vision-beacon-chain-date": "信标链已经上线",
  "page-eth2-vision-beacon-chain-desc": "这是Eth2升级的第一个附加项。信标链将给以太坊带来权益质押，为将来的升级打下基础，最终成为新系统的协调者。",
  "page-eth2-vision-beacon-chain-upper": "信标链",
  "page-eth2-vision-desc-1": "以太坊需要减少网络拥堵，提高速度，更好地服务全球用户。",
  "page-eth2-vision-desc-2": "随着网络的增大，运行一个节点正在变得越来越难。而网络扩容只会让这变得更加困难。",
  "page-eth2-vision-desc-3": "以太坊占用了太多的电力。为了确保网络安全所使用的技术应该是可持续的。",
  "page-eth2-vision-docking-date": "预期上线时间：2022",
  "page-eth2-vision-docking-desc": "以太坊主网需要在未来某个时间点和信标链对接。这会实现整个网络的权益质押，终结能源消耗型的挖矿行为。",
  "page-eth2-vision-ethereum-node": "节点的更多信息",
  "page-eth2-vision-explore-upgrades": "探索升级项",
  "page-eth2-vision-future": "全球范围内的数字未来",
  "page-eth2-vision-meta-desc": "介绍Eth2升级对以太坊的影响以及必须克服的挑战的概述。",
  "page-eth2-vision-meta-title": "以太坊 2.0(Eth2) 愿景",
  "page-eth2-vision-mining": "挖矿的更多信息",
  "page-eth2-vision-problems": "当前面临的问题",
  "page-eth2-vision-scalability": "可扩展性",
  "page-eth2-vision-scalability-desc": "以太坊需要能够在不增加单节点能力的情况增加每秒交易处理数。节点是网络中存储和运行区块链的参与者。增加单节点的能力并不现实因为只有足够强大和昂贵的计算机才能这么做。为了扩展，以太坊需要更多节点来提高每秒交易处理数。越多的节点意味着越安全。",
  "page-eth2-vision-scalability-desc-3": "分片链升级将把网络的承载能力提升至64个新链。这将会给以太坊带来喘息的机会，网络拥堵将减少，交易处理速度也将会突破当前每秒15-45个的能力。",
  "page-eth2-vision-scalability-desc-4": "即使现在有更多的链，实际上验证者节点（网络的维护者）将会减少工作量。因为验证者节点只需要运行它们自己的分片，而非整个以太坊。这让节点更轻量，运行以太坊继续在去中心化的前提下进行扩展。",
  "page-eth2-vision-security": "安全性",
  "page-eth2-vision-security-desc": "Eth2升级将会提高以太坊抵抗协同攻击（类似51%攻击）的能力。这是一种通过控制网络的大多数节点来篡改事实的能力。",
  "page-eth2-vision-security-desc-3": "向权益证明的切换将使得以太坊具有更好的抗攻击能力。这是因为在权益证明中，保证网络安全的验证者们需要提前质押一定数量的ETH。如果他们尝试攻击网络，这些ETH将会被自动销毁。",
  "page-eth2-vision-security-desc-5": "这种惩罚在工作量证明制度的运作下是无法实现的，这种协议能做的最多是没收作弊者的挖矿所得。如果实现相同的惩罚效果，协议则需要拥有能够摧毁作弊者挖矿硬件的能力。",
  "page-eth2-vision-security-desc-5-link": "工作量证明的更多信息",
  "page-eth2-vision-security-desc-8": "以太坊的安全模型也需要作出相应调整，因为引入了",
  "page-eth2-vision-security-desc-10": "权益质押还意味者您不需要投资硬件就可以运行一个以太坊节点。这将鼓励更多的人成为验证者，增加网络的去中心化，减少受到攻击时的不利影响。",
  "page-eth2-vision-security-staking": "质押ETH",
  "page-eth2-vision-security-validator": "通过质押您的ETH，您就可以成为一个验证者。",
  "page-eth2-vision-shard-chains": "分片链",
  "page-eth2-vision-shard-date": "预期上线时间：2021",
  "page-eth2-vision-shard-desc-4": "分片链将扩展网络的承载能力至64个新区块链。分片能够大幅度地提高交易处理速度 - 高达100,000/秒。",
  "page-eth2-vision-shard-upgrade": "分片链的更多信息",
  "page-eth2-vision-staking-lower": "权益质押的更多信息",
  "page-eth2-vision-subtitle": "以太坊将持续增长，直到造福全人类。",
  "page-eth2-vision-sustainability": "可持续发展",
  "page-eth2-vision-sustainability-desc-1": "由于挖矿的存在，以太坊以及像比特币这样的区块链大量消耗能力早已不是秘密。",
  "page-eth2-vision-sustainability-desc-2": "但是通过质押，以太坊将转由ETH来保证安全，而不再是算力。",
  "page-eth2-vision-sustainability-desc-3": "虽然信标链即将引入权益质押，我们正在使用的以太坊将在合并Eth2更新前的一段时间内并行运作。一个系统由ETH保护，一个系统由算力保护。这是因为在一开始，分片链还无法和类似我们的账户或者去中心化应用这样的实体交互，因此我们无法直接丢弃挖矿或者主网。",
  "page-eth2-vision-sustainability-desc-8": "一旦信标链和分片链的升级上线运行，改造主网以适配新系统的工作就将启动。这会把现在的主网改造成一个由ETH保障安全的分片，因此节省大量能量开销。",
  "page-eth2-vision-sustainability-subtitle": "以太坊需要变得更加绿色环保。",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "Eth2愿景",
  "page-eth2-vision-title-1": "网络拥堵",
  "page-eth2-vision-title-2": "磁盘空间",
  "page-eth2-vision-title-3": "能量损耗",
  "page-eth2-vision-trilemma-cardtext-1": "Eth2升级将会保证以太坊可扩展，安全和去中心化的特性。分片将通过提高每秒处理交易数，以及减少运行节点和验证区块链所需能源来提高以太坊的可扩展性。信标链将会通过在分片间协调验证者来保证以太坊的安全。权益质押将会降低参与的门槛，创建一个更大更加去中心化的网络。",
  "page-eth2-vision-trilemma-cardtext-2": "安全和去中心化的区块链网络要求每个节点确认每一笔链上交易。这种工作量限制了任意时间点可以处理的交易数量。安全和去中心化是今天的以太网所具有的特征。",
  "page-eth2-vision-trilemma-cardtext-3": "去中心化网络通过在节点间传递交易信息工作 - 整个网络都需要知道任何状态的改变。在去中心化网络中增加每秒处理交易的规模将会增大安全风险，这是因为交易越多，延迟的时间越长，在信息传递的过程中遭遇攻击的可能性越大。",
  "page-eth2-vision-trilemma-cardtext-4": "增加以太网节点的能力能够安全地提高每秒处理交易数。但是这种硬件要求将会成为节点的门槛，进而威胁到去中心化的原则。人们将期望寄予通过分片和权益证明技术提高以太网的节点数量，而非质量。",
  "page-eth2-vision-trilemma-h2": "去中心化扩展的挑战",
  "page-eth2-vision-trilemma-modal-tip": "点击圆圈以更好地理解去中心化扩展遇到的问题",
  "page-eth2-vision-trilemma-p": "解决以太坊已有问题的一个天真方案是中心化。但是去中心化真的是太重要了，是它赋予了以太坊反审查、开放、保障隐私和近乎绝对的安全。",
  "page-eth2-vision-trilemma-p-1": "以太坊的愿景是在去中心化的前提下变得更具有可扩展性并且更安全。如何同时达成这3个目标被称为可扩展性的难题。",
  "page-eth2-vision-trilemma-p-2": "Eth2升级致力于解决这个三难问题，但是显然难度很大。",
  "page-eth2-vision-trilemma-press-button": "点击三角形上的按钮来深入理解去中心化扩展将会遇到的问题。",
  "page-eth2-vision-trilemma-text-1": "去中心化",
  "page-eth2-vision-trilemma-text-2": "安全性",
  "page-eth2-vision-trilemma-text-3": "可扩展性",
  "page-eth2-vision-trilemma-title-1": "探索可扩展性的三难问题",
  "page-eth2-vision-trilemma-title-2": "Eth2升级和去中心化扩展",
  "page-eth2-vision-trilemma-title-3": "安全和去中心化",
  "page-eth2-vision-trilemma-title-4": "去中心化和可扩展",
  "page-eth2-vision-trilemma-title-5": "可扩展性和安全性",
  "page-eth2-vision-understanding": "理解Eth2的愿景",
  "page-eth2-vision-upgrade-needs": "Eth2升级的必要性",
  "page-eth2-vision-upgrade-needs-desc": "2015年发布的以太坊协议已经获得了难以置信的成功。但是以太坊社区仍然期盼一些关键升级能够激发以太坊的所有潜能。",
  "page-eth2-vision-upgrade-needs-desc-2": "需求量激增正在推动抬高交易费，使得普通用户难以承受。运行一个以太坊客户端所需的磁盘空间也在快速增长。在底层，确保以太坊安全和去中心化的工作量共识算法对整个环境的影响也愈发显著。",
  "page-eth2-vision-upgrade-needs-desc-3": "通常被称为Eth2的一系列升级将致力于解决这些问题。这套升级的初始代号为宁静(Serenity)，自从2014年以来就已经成为一个研究和开发的活跃领域。",
  "page-eth2-vision-upgrade-needs-desc-6": " 这意味着Eth2的升级不是一蹴而就的，而是随着时间增量迭代。",
  "page-eth2-vision-upgrade-needs-serenity": "查看2015年的一篇讨论“Serenity”的博客",
  "page-eth2-vision-uprade-needs-desc-5": "现在这项技术已经成熟，这些升级将重构以太坊，使它能够可持续发展，具有更好的可扩展性、更安全，并因此更好地服务于现有和潜在用户。这一切都不会改变以太坊去中心化的核心价值。",
  "page-get-eth-cex": "中心化交易所",
  "page-get-eth-cex-desc": "交易所是可以让您使用传统法定货币购买加密数字货币的企业。它们将负责保管尚未发送至您实际控制的钱包地址的ETH。",
  "page-get-eth-checkout-dapps-btn": "查看去中心化应用",
  "page-get-eth-community-safety": "社区上关于安全问题的文章",
  "page-get-eth-description": "以太坊和ETH不被任何政府或组织控制，它们是去中心化的。这代表ETH会向所有人开放使用。",
  "page-get-eth-dex": "去中心化交易所(DEX)",
  "page-get-eth-dex-desc": "如果您希望交易更加可控，可以尝试与其他持有者点对点交易ETH。通过DEX，您可以使您的交易在不经过任何第三方干扰的情形下进行。",
  "page-get-eth-dexs": "去中心化交易所(DEX)",
  "page-get-eth-dexs-desc": "去中心化交易所是用于交易ETH或者其他货币的开放的自由市场，它可以让买卖双方点对点直接交易，避免中间商赚差价。",
  "page-get-eth-dexs-desc-2": "他们通过使用一段程序代码，而不是委托第三方来保证一笔订单的资金安全。只有当这次支付被确定时，ETH才会从支付方转出。这种程序代码称为“智能合约”。",
  "page-get-eth-dexs-desc-3": "这意味着与集中式的交易方案相比，地域上的限制将会更少。如果有人卖您想要的东西，同时他接受某种您可以使用的支付方式，那你们就已经准备万全了。DEX可以让您以各种方式进行交易，不论是使用其他数字加密货币或法定货币，乃至于Paypal、支付宝，甚至线下的现金交易，都可以用来在DEX进行交易。",
  "page-get-eth-do-not-copy": "例子：请勿拷贝",
  "page-get-eth-exchanges-disclaimer": "我们是以人工来收集这些信息的，如果您发现出现错误，请发邮件给",
  "page-get-eth-exchanges-empty-state-text": "输入您所在的国家或地区以查询您可以用于交易ETH的钱包或交易所。",
  "page-get-eth-exchanges-except": "除此之外",
  "page-get-eth-exchanges-header": "请问您居住于哪个国家或地区？",
  "page-get-eth-exchanges-header-exchanges": "交易所",
  "page-get-eth-exchanges-header-wallets": "钱包",
  "page-get-eth-exchanges-intro": "钱包应用或交易所会限制可以进行加密货币交易的地区。",
  "page-get-eth-exchanges-no-exchanges": "抱歉，我们暂未得知允许交易者在您所在的国家或地区购买ETH的交易所。如果您找到了，请告诉我们，可以发送邮件给",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "抱歉，我们暂未得知允许交易者在您所在的国家或地区购买ETH的交易所或钱包应用。如果您找到了，请告诉我们，可以发送邮件给",
  "page-get-eth-exchanges-no-wallets": "抱歉，我们暂未得知允许交易者在您所在的国家或地区购买ETH的钱包应用。如果您找到了，请告诉我们，可以发送邮件给",
  "page-get-eth-exchanges-success-exchange": "根据不同交易所的合规要求，您在交易所的注册请求可能需要等待几天才能通过。",
  "page-get-eth-exchanges-success-wallet-link": "钱包",
  "page-get-eth-exchanges-success-wallet-paragraph": "您可以在您所居住的国家或地区通过这些钱包应用直接购买ETH。了解更多",
  "page-get-eth-exchanges-usa": "美国(USA)",
  "page-get-eth-get-wallet-btn": "获取一个钱包",
  "page-get-eth-hero-image-alt": "去查阅ETH先驱者的画像",
  "page-get-eth-keep-it-safe": "保护您的ETH于安全状态",
  "page-get-eth-meta-description": "如何根据您所在地区选择购买ETH方式以及关于如何看管ETH的建议。",
  "page-get-eth-meta-title": "如何购买ETH",
  "page-get-eth-need-wallet": "如果希望使用DEX，您必须持有一个钱包地址。",
  "page-get-eth-new-to-eth": "刚刚接触ETH？这里有一份入门指南。",
  "page-get-eth-other-cryptos": "使用其他加密货币购买",
  "page-get-eth-protect-eth-desc": "如果您打算购买大量价值的ETH，您最好将他们保存在您自己控制的钱包应用而不是交由交易所代管。这是由于交易所更容易遭到黑客攻击。一旦交易所系统被攻破，您的资金很有可能永久丢失。同时，您的钱包应用将只有您自己拥有控制权。",
  "page-get-eth-protect-eth-in-wallet": "使用钱包应用保护您的ETH",
  "page-get-eth-search-by-country": "基于国家或地区搜索",
  "page-get-eth-security": "然而，这也代表您必须认真仔细地保证您的资金安全。不存在可信的银行来代管您的ETH。可以信任的人唯有您自己。",
  "page-get-eth-smart-contract-link": "了解更多关于智能合约的信息",
  "page-get-eth-swapping": "用您的其他加密货币与他人交易ETH。反之亦然。",
  "page-get-eth-traditional-currencies": "使用法定货币购买",
  "page-get-eth-traditional-payments": "通过传统的支付方式直接向卖方购买ETH。",
  "page-get-eth-try-dex": "尝试DEX",
  "page-get-eth-use-your-eth": "使用您的ETH",
  "page-get-eth-use-your-eth-dapps": "现在，您已经持有了一些ETH。您可以查看一些以太坊网络应用（去中心化应用）。有很多类型的去中心化应用，包括金融、社交、媒体、游戏，还有其他各种领域。",
  "page-get-eth-wallet-instructions": "遵循钱包应用的使用指南",
  "page-get-eth-wallet-instructions-lost": "如果您丢失了您钱包应用的访问方式，您将永久地失去您的资金。您的钱包可能会给您防止这种情况的建议。请务必遵循——大部分情况下，如果您没法控制您的钱包，老天爷也救不了您。",
  "page-get-eth-wallets": "钱包",
  "page-get-eth-wallets-link": "更多关于钱包的说明",
  "page-get-eth-wallets-purchasing": "某些钱包应用准许您通过信用卡、借记卡、银行转账，甚至是Apple Pay来购买加密货币。当然，这些方法会有地域限制。",
  "page-get-eth-warning": "这些DEX需要您持有一定的ETH才可以使用，因此不适合初学者。",
  "page-get-eth-what-are-DEX's": "什么是DEX？",
  "page-get-eth-whats-eth-link": "什么是ETH？",
  "page-get-eth-where-to-buy-desc": "您可以通过交易所或者钱包应用直接购买ETH。",
  "page-get-eth-where-to-buy-desc-2": "搜索您的所在地区有哪些可用服务。",
  "page-get-eth-where-to-buy-title": "在哪里购买ETH",
  "page-get-eth-your-address": "您的ETH地址",
  "page-get-eth-your-address-desc": "在您下载钱包的时候它会为您创建一个公开的地址，如下图所示：",
  "page-get-eth-your-address-desc-3": "把它想像成您的电子邮箱地址，只是它用来接收的是ETH。如果您想从一个交易所发送ETH到您的钱包，这个地址就是目的地。在发送前一定要多次检查，确保地址正确！",
  "page-get-eth-your-address-wallet-link": "查看钱包",
  "page-index-hero-image-alt": "etherum.org主图",
  "page-index-meta-description": "以太坊是一个全球性的、分散的资金和新型应用程序平台。 在以太坊，您可以写代码来控制钱，并在世界任何地方建立可以访问的应用程序。",
  "page-index-meta-title": "首页",
  "page-index-title": "以太坊是一个为去中心化应用程序而生的全球开源平台。",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "语言支持",
  "page-languages-interested": "有兴趣做贡献吗？",
  "page-languages-learn-more": "了解更多关于我们翻译计划的信息",
  "page-languages-meta-desc": "以太坊支持的所有语言的资源，以及参与翻译的方式。",
  "page-languages-meta-title": "etherum.org语言翻译",
  "page-languages-p1": "以太坊是一个全球性项目，每个人，不论其国籍或语言，都可以访问ethereum.org。 我们社区一直在努力使这一愿景成为现实。",
  "page-languages-translations-available": "ethereum.org有以下语言版本",
  "page-languages-want-more-header": "想用不同的语言查看etherum.org吗？",
  "page-languages-want-more-link": "翻译计划",
  "page-languages-want-more-paragraph": "etherum.org的翻译者总是以尽可能多的语言进行翻译。 要看看他们正在做什么或注册加入他们，请阅读我们的",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "加密抵押物",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "使用以太坊，您可以直接从其他用户借贷，而无需交易您的ETH。 这可以让您发挥杠杆作用——一些人这样做来尝试积累更多ETH。",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "但由于ETH的价格波动，您将需要超额担保。 这意味着如果您想借100个稳定币，您可能需要至少150美元的价值的ETH。这将保护系统和贷方。",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "更多关于加密支持的稳定币",
  "page-stablecoins-accordion-borrow-pill": "高级",
  "page-stablecoins-accordion-borrow-places-intro": "这些去中心化应用让您借入以加密货币作为抵押品的稳定币。有些接受ETH和其它代币。",
  "page-stablecoins-accordion-borrow-places-title": "借用稳定币的地方",
  "page-stablecoins-accordion-borrow-requirement-1": "以太坊钱包",
  "page-stablecoins-accordion-borrow-requirement-1-description": "您需要钱包才能使用去中心化应用",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "您将需要ETH来支付抵押和/或交易费用",
  "page-stablecoins-accordion-borrow-requirements-description": "要借到稳定币，您需要正确使用去中心化应用。您还需要一个钱包和一些ETH。",
  "page-stablecoins-accordion-borrow-risks-copy": "如果您使用ETH作为抵押品，其价值下降，您的抵押品将无法抵扣您生成的稳定币。 这将导致您的ETH清算，您可能会面临惩罚。所以如果您借用稳定币，您需要观看ETH价格。",
  "page-stablecoins-accordion-borrow-risks-link": "最新的ETH价格",
  "page-stablecoins-accordion-borrow-risks-title": "风险",
  "page-stablecoins-accordion-borrow-text-preview": "您可以通过使用加密货币作为抵押借入一些稳定币，但您必须偿还。",
  "page-stablecoins-accordion-borrow-title": "借款",
  "page-stablecoins-accordion-buy-exchanges-title": "热门交易所",
  "page-stablecoins-accordion-buy-requirement-1": "加密交易所和钱包",
  "page-stablecoins-accordion-buy-requirement-1-description": "请查看您当地的可用服务",
  "page-stablecoins-accordion-buy-requirements-description": "您可以从一个绑定交易所或者钱包的账户中直接购买加密货币。您可能已经使用它们获取了一些ETH。请查看您当地可用的服务。",
  "page-stablecoins-accordion-buy-text-preview": "大量交易所或者钱包能够让您直接买入稳定币，这种能力可能受到当地政策限制。",
  "page-stablecoins-accordion-buy-title": "购买",
  "page-stablecoins-accordion-buy-warning": "中心化交易所有可能只是列出了类似USDC、Tether这些由法币支持的稳定币。您无法直接购买它们，但是您应能够在这些平台上用您的ETH或者其它加密货币购买它们。",
  "page-stablecoins-accordion-earn-project-1-description": "开放源代码软件运动的大部分技术性工作。",
  "page-stablecoins-accordion-earn-project-2-description": "MakerDao社区（为您带来Dai的团队）的技术、内容和其他工作。",
  "page-stablecoins-accordion-earn-project-3-description": "当您精通此道时，可以通过寻找漏洞赢得Dai。",
  "page-stablecoins-accordion-earn-project-bounties": "Gitcoin悬赏",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Eth2漏洞悬赏",
  "page-stablecoins-accordion-earn-project-community": "MakerDao社区",
  "page-stablecoins-accordion-earn-projects-copy": "这些平台将为您的工作支付稳定币作为酬劳。",
  "page-stablecoins-accordion-earn-projects-title": "从哪里赚取稳定币",
  "page-stablecoins-accordion-earn-requirement-1": "以太坊钱包",
  "page-stablecoins-accordion-earn-requirement-1-description": "您需要一个钱包来接收您赚取的稳定币",
  "page-stablecoins-accordion-earn-requirements-description": "因为价值稳定，稳定币是为工作和服务支付酬劳的好方法。但是您首先需要一个钱包。",
  "page-stablecoins-accordion-earn-text-preview": "您可以通过为以太坊生态下的项目工作来赚取稳定币。",
  "page-stablecoins-accordion-earn-title": "收益",
  "page-stablecoins-accordion-less": "更少",
  "page-stablecoins-accordion-more": "更多",
  "page-stablecoins-accordion-requirements": "您所需要的",
  "page-stablecoins-accordion-swap-dapp-intro": "如果您已经拥有ETH和一个钱包，您就可以使用这些去中心化应用来交换稳定币。",
  "page-stablecoins-accordion-swap-dapp-link": "关于去中心化交易所的更多信息",
  "page-stablecoins-accordion-swap-dapp-title": "交换代币的应用",
  "page-stablecoins-accordion-swap-editors-tip": "编辑提示",
  "page-stablecoins-accordion-swap-editors-tip-button": "查找钱包",
  "page-stablecoins-accordion-swap-editors-tip-copy": "获取一个钱包，通过它购买ETH并直接兑换成包括稳定币在内的其它代币。",
  "page-stablecoins-accordion-swap-pill": "推荐",
  "page-stablecoins-accordion-swap-requirement-1": "以太坊钱包",
  "page-stablecoins-accordion-swap-requirement-1-description": "您需要一个钱包来授权兑换和存储代币",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "为兑换支付费用",
  "page-stablecoins-accordion-swap-text-preview": "您可以在去中心化的交易所中找到绝大多数稳定币。因此您可以使用您可能拥有的任何代币去兑换这些稳定币。",
  "page-stablecoins-accordion-swap-title": "交换",
  "page-stablecoins-algorithmic": "算法",
  "page-stablecoins-algorithmic-con-1": "您需要相信（或者能够读懂）算法。",
  "page-stablecoins-algorithmic-con-2": "您的代币数量将随着供给总量而变化。",
  "page-stablecoins-algorithmic-description": "这些稳定币没有其它资产作为支撑。相反，一种算法将确保当价格低于预期时出售代币，在价格高于预期时获得更多代币。由于流通中的代币数量经常变化，您所持有的代币数量也会随之改变，但是总能反应出您所占的份额。",
  "page-stablecoins-algorithmic-pro-1": "无需抵押物。",
  "page-stablecoins-algorithmic-pro-2": "由公开算法控制。",
  "page-stablecoins-bank-apy": "0.05%",
  "page-stablecoins-bank-apy-source": "由美国联邦政府保证的银行基础利率。",
  "page-stablecoins-bank-apy-source-link": "来源",
  "page-stablecoins-bitcoin-pizza": "臭名昭著的比特币比萨",
  "page-stablecoins-bitcoin-pizza-body": "2010年，有人用10000枚比特币购买了2个比萨，在当时，这些比特币价值 $41美元。而在现在，那就是百万美元。在以太坊的历史上还有很多类似令人懊悔的交易。稳定币可以解决这个问题，因此您可以一边手握ETH，一边享用您的披萨。",
  "page-stablecoins-coin-price-change": "币价波动（最近30天）",
  "page-stablecoins-crypto-backed": "由加密算法支撑",
  "page-stablecoins-crypto-backed-con-1": "不如法币支撑的稳定币稳定。",
  "page-stablecoins-crypto-backed-con-2": "您需要对抵押的加密货币的价值波动保持关注。",
  "page-stablecoins-crypto-backed-description": "这些稳定币由其它加密资产（例如ETH）作为支撑。它们的价格随着底层资产（即抵押物）的价值波动而波动。由于ETH的价格可能波动，这些稳定币需要超额抵押来确保价值稳定。这种方法意味着价值$1美元的稳定币需要有至少价值$2美元的加密资产进行抵押。如果ETH的价格下跌，更多的ETH将会用于支撑稳定币，否则稳定币将会失去价值。",
  "page-stablecoins-crypto-backed-pro-1": "透明且完全去中心化。",
  "page-stablecoins-crypto-backed-pro-2": "快速转化为其它加密资产。",
  "page-stablecoins-crypto-backed-pro-3": "没有外部监管 - 所有资产由以太坊账户完全控制。",
  "page-stablecoins-dai-banner-body": "Dai可能是最著名的分布式稳定币。它和1美元等价并被去中心化应用广泛接受。",
  "page-stablecoins-dai-banner-learn-button": "了解Dai",
  "page-stablecoins-dai-banner-swap-button": "将ETH转换成Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Dai徽标",
  "page-stablecoins-editors-choice": "主编推荐",
  "page-stablecoins-editors-choice-intro": "这些是当前最知名的稳定币，并被去中心化应用广泛使用。",
  "page-stablecoins-explore-dapps": "探索去中心化应用",
  "page-stablecoins-fiat-backed": "法币支持",
  "page-stablecoins-fiat-backed-con-1": "中心化 - 必须有人签发代币。",
  "page-stablecoins-fiat-backed-con-2": "需要审计确保这些公司拥有足够的资产储备。",
  "page-stablecoins-fiat-backed-description": "简单来说由传统意义上的法币（通常是美元）来抵押。您使用您的法币购入稳定币，并可以随时兑换回来。",
  "page-stablecoins-fiat-backed-pro-1": "具有对抗加密货币价值波动的安全性。",
  "page-stablecoins-fiat-backed-pro-2": "价格波动很小。",
  "page-stablecoins-find-stablecoin": "查找稳定币",
  "page-stablecoins-find-stablecoin-how-to-get-them": "如何获取稳定币",
  "page-stablecoins-find-stablecoin-intro": "有上百种可用的稳定币。这里有一份指南，如果您初次接触以太坊，我们建议您先做一些研究。",
  "page-stablecoins-find-stablecoin-types-link": "稳定币的不同类型",
  "page-stablecoins-get-stablecoins": "如何获取稳定币",
  "page-stablecoins-hero-alt": "市值前三的稳定币：Dai、USDC和Tether。",
  "page-stablecoins-hero-button": "获取稳定币",
  "page-stablecoins-hero-header": "用于日常使用的电子货币",
  "page-stablecoins-hero-subtitle": "稳定币是币值稳定的以太坊代币，即使以太坊的价值存在波动。",
  "page-stablecoins-interest-earning-dapps": "赚取利息的去中心化应用",
  "page-stablecoins-meta-description": "以太坊稳定币简介：它们是什么，如何获取以及为什么重要。",
  "page-stablecoins-precious-metals": "贵金属",
  "page-stablecoins-precious-metals-con-1": "中心化 - 必须有人签发代币。",
  "page-stablecoins-precious-metals-con-2": "您需要相信代币发行者拥有相应的贵金属储备。",
  "page-stablecoins-precious-metals-description": "就像法币一样，这些加密货币使用黄金等资源维持它们的价值。",
  "page-stablecoins-precious-metals-pro-1": "具有对抗加密货币价值波动的安全性。",
  "page-stablecoins-prices": "稳定币价格",
  "page-stablecoins-prices-definition": "稳定币是不具有波动性的加密货币。它们具有很多和ETH相同的能力，同时它们的价值稳定，更像传统货币。因此您可以在以太坊上像使用传统货币一样使用它们。 ",
  "page-stablecoins-prices-definition-how": "稳定币如何维持稳定价值",
  "page-stablecoins-research-warning": "以太坊是一种新技术，同时绝大部分应用也是新鲜出炉。请确保您了解这些风险。",
  "page-stablecoins-research-warning-title": "不要道听途说",
  "page-stablecoins-save-stablecoins": "储蓄稳定币",
  "page-stablecoins-save-stablecoins-body": "由于有大量借入需求，稳定币通常拥有高于平均的收益率。有些去中心化应用能够让您通过将稳定币放入借贷池赚取即时收益。就像在金融界一样，您出借稳定币，且可以随时撤回，获取收益。",
  "page-stablecoins-saving": "妥善利用您的稳定币储蓄以赚取利息。就像加密世界中的任何东西，预期的收益率曲线(APY)总是根据供需每天实时变化。",
  "page-stablecoins-stablecoins-dapp-callout-description": "查看以太坊上的去中心化应用 - 日常交易中稳定币更实用。",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "插图：一只拟人狗。",
  "page-stablecoins-stablecoins-dapp-callout-title": "使用您的稳定币",
  "page-stablecoins-stablecoins-dapp-description-1": "包括Dai、USDC、TUSD、USDT等在内的稳定币市场。 ",
  "page-stablecoins-stablecoins-dapp-description-2": "出借稳定币以赚取利息和$COMP，一种Compound自己的代币。",
  "page-stablecoins-stablecoins-dapp-description-3": "一个能够从您的Dai和USDC上赚取利息的交易平台。",
  "page-stablecoins-stablecoins-dapp-description-4": "一个保存Dai的应用。",
  "page-stablecoins-stablecoins-feature-1": "稳定币可以在全球范围内的互联网上发送。只要您有以太坊账户，就可以简单地发送或接收它们。",
  "page-stablecoins-stablecoins-feature-2": "对稳定币的需求很大，因此您可以通过出借赚取利息。但是请确保您明确其中的风险。",
  "page-stablecoins-stablecoins-feature-3": "稳定币可以和ETH以及其它以太坊代币互相转换。大量去中心化应用依赖稳定币。",
  "page-stablecoins-stablecoins-feature-4": "稳定币由加密算法保证安全。没有人能够以您的身份伪造交易。",
  "page-stablecoins-stablecoins-meta-description": "以太坊稳定币简介：它们是什么，如何获取以及为什么重要。",
  "page-stablecoins-stablecoins-table-header-column-1": "货币",
  "page-stablecoins-stablecoins-table-header-column-2": "市值",
  "page-stablecoins-stablecoins-table-header-column-3": "抵押类型",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "加密货币",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "法币",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "贵金属",
  "page-stablecoins-table-error": "无法加载稳定币数据。请刷新页面。",
  "page-stablecoins-table-loading": "正在加载稳定币数据...",
  "page-stablecoins-title": "稳定币",
  "page-stablecoins-top-coins": "处于领先地位的稳定币（按市值排序）",
  "page-stablecoins-top-coins-intro": "市值等于",
  "page-stablecoins-top-coins-intro-code": "代币总量乘以每个代币的价值。这个列表是动态变化的，并且其中的项目并非都已获得ethereum.org团队背书。",
  "page-stablecoins-types-of-stablecoin": "它们如何运作：稳定币的类型",
  "page-stablecoins-usdc-banner-body": "USDc可能是最著名的以法币支持的稳定币。它由Circle和Coinbase发行，通常等值于一美元。",
  "page-stablecoins-usdc-banner-learn-button": "了解USDC",
  "page-stablecoins-usdc-banner-swap-button": "将ETH交换成USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "USDC徽标",
  "page-stablecoins-why-stablecoins": "为什么使用稳定币？",
  "page-stablecoins-how-they-work-button": "它们如何运作",
  "page-stablecoins-why-stablecoins-body": "ETH，和比特币一样是一种新技术，所以价值存在波动。因此您可能不会想在日常生活中使用它。稳定币作为传统货币价值的替代品，成为您能够在以太坊上使用的稳定资金。",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": "。如果您想要我们添加一个钱包，",
  "page-find-wallet-alpha-logo-alt": "AlphaWallet徽标",
  "page-find-wallet-ambo-logo-alt": "Ambo徽标",
  "page-find-wallet-argent-logo-alt": "Argent徽标",
  "page-find-wallet-buy-card": "使用信用卡购买加密货币",
  "page-find-wallet-buy-card-desc": "在钱包里直接通过信用卡购买ETH。该功能可能会受限于地域政策。",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "查看去中心化应用",
  "page-find-wallet-clear": "重置过滤器",
  "page-find-wallet-coinbase-logo-alt": "Coinbase徽标",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "根据您的需求选择适合您的钱包。",
  "page-find-wallet-description": "钱包包含大量您可能会喜欢的可选特性。",
  "page-find-wallet-description-alpha": "一个完全开源的以太网钱包，充分利用了内置于手机的安全协处理器，提供完整的测试网络支持并且符合TokenScript标准。",
  "page-find-wallet-description-ambo": "下载APP的几分钟内就能完成首次投资",
  "page-find-wallet-description-argent": "一键获取投资收益；出借、保存和发送。来使用它吧。",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "一个您自己保存加密货币的安全应用",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma是您使用DeFi的门户。不需要助记词，不需要Chrome扩展。",
  "page-find-wallet-description-enjin": "对于交易者、玩家和开发者安全可靠，功能完善，方便构建",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "在以太坊上存储数字资产最值得信任的平台",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken是一款已被百万人使用的易用和安全的数字钱包",
  "page-find-wallet-description-ledger": "使用最高安全标准来保障您的资产安全",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "几秒内便可探索区块链应用，拥有世界范围内的百万用户。",
  "page-find-wallet-description-monolith": "世界上唯一能够和Visa借记卡绑定的数字钱包。可在联合王国及欧盟的应用商店里下载，并在全球范围内使用。",
  "page-find-wallet-description-multis": "Multis是一个为商用设计的加密账户。使用Multis，企业可以进行权限控制，从存款中赚取收益，简化支付和审计。",
  "page-find-wallet-description-mycrypto": "MyCrypto是一个同时管理多个加密账户的界面。在其中通过类似MetaMask、Ledger、Trezor的钱包交换、发送或者购买加密货币。",
  "page-find-wallet-description-myetherwallet": "一个免费的客户端界面，帮助您和以太坊区块链进行交互",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "非托管、归属社区的钱包，拥有自己的L2支付网络。",
  "page-find-wallet-description-portis": "非托管的区块链钱包，让所有人都能方便地使用区块链应用",
  "page-find-wallet-description-rainbow": "为您的以太坊资产准备的更好的大本营",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "使用最新技术构建的安全通讯软件、加密钱包以及Web3浏览器",
  "page-find-wallet-description-tokenpocket": "TokenPocket是一个安全、便捷、全球领先的数字货币钱包，同时它还是一个支持多链的去中心化应用门户。",
  "page-find-wallet-description-bitkeep": "BitKeep是一款为全球用户提供一站式数字资产管理服务的去中心化多链数字钱包。",
  "page-find-wallet-description-torus": "一键登录Web 3.0",
  "page-find-wallet-description-trezor": "第一个纯正的硬件钱包",
  "page-find-wallet-description-trust": "Trust是一个分布式的多币种加密钱包。在购买/交易加密货币，探索去中心化应用的同时保持对密钥的控制权。",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo是第一个无需密钥的加密钱包。ZenGo中不需要管理难以记忆的私钥、密钥或者助记词。通过它简单安全地交易和存储ETH。",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Dharma徽标",
  "page-find-wallet-enjin-logo-alt": "Enjin徽标",
  "page-find-wallet-Ethereum-wallets": "以太坊钱包",
  "page-find-wallet-explore-dapps": "探索去中心化应用",
  "page-find-wallet-explore-dapps-desc": "这些钱包能够帮助您链接到以太坊去中心化应用。",
  "page-find-wallet-feature-h2": "选择您需要的钱包功能",
  "page-find-wallet-fi-tools": "使用金融工具",
  "page-find-wallet-fi-tools-desc": "在您的钱包中直接出借、借入和赚取利息。",
  "page-find-wallet-following-features": "拥有以下功能特性：",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Gnosis Safe徽标",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "插图：查找钱包英雄",
  "page-find-wallet-imtoken-logo-alt": "imToken徽标",
  "page-find-wallet-last-updated": "最近更新",
  "page-find-wallet-ledger-logo-alt": "Ledger徽标",
  "page-find-wallet-limits": "限额保护",
  "page-find-wallet-limits-desc": "通过设置限额保护您的资产，减少意外损失。",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "展示政策",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "按照您要的功能查找并比较这些以太坊钱包。",
  "page-find-wallet-meta-title": "找到一个以太坊钱包",
  "page-find-wallet-metamask-logo-alt": "MetaMask徽标",
  "page-find-wallet-monolith-logo-alt": "Monolith徽标",
  "page-find-wallet-multis-logo-alt": "Multis徽标",
  "page-find-wallet-multisig": "多签名账户",
  "page-find-wallet-multisig-desc": "为了提高安全性，多签名账户需要多个账户同时授权才能发起交易。",
  "page-find-wallet-mycrypto-logo-alt": "MyCrypto徽标",
  "page-find-wallet-myetherwallet-logo-alt": "MyEtherWallet徽标",
  "page-find-wallet-new-to-wallets": "第一次了解钱包？这里有一份入门指南。",
  "page-find-wallet-new-to-wallets-link": "以太坊钱包",
  "page-find-wallet-not-all-features": "没有钱包能够拥有所有这些功能",
  "page-find-wallet-not-endorsements": "这里列出的钱包并非获得官方背书，仅供信息展示。它们各自的描述由其公司自行提供。我们添加这些产品时的依据是我们的",
  "page-find-wallet-overwhelmed": "下面是以太坊的钱包列表。觉得眼花缭乱？尝试按照功能过滤吧。",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Pillar徽标",
  "page-find-wallet-portis-logo-alt": "Portis徽标",
  "page-find-wallet-rainbow-logo-alt": "Rainbow徽标",
  "page-find-wallet-raise-an-issue": "在GitHub上提交一个问题",
  "page-find-wallet-search-btn": "按已选择功能搜索",
  "page-find-wallet-showing": "显示中 ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "状态图标",
  "page-find-wallet-swaps": "分布式的代币转换",
  "page-find-wallet-swaps-desc": "在钱包中直接进行ETH和其它代币的交易。",
  "page-find-wallet-title": "获取一个钱包",
  "page-find-wallet-tokenpocket-logo-alt": "TokenPocket徽标",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep徽标",
  "page-find-wallet-torus-logo-alt": "Torus徽标",
  "page-find-wallet-trezor-logo-alt": "Trezor徽标",
  "page-find-wallet-trust-logo-alt": "Trust徽标",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "尝试删除一两个特性",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "现在您已经有了一个钱包，接下来下载一些以太坊应用（去中心化应用）吧。这里有各种去中心化应用，涵盖金融、社交媒体、游戏及很多其它领域。",
  "page-find-wallet-use-your-wallet": "使用您的钱包",
  "page-find-wallet-voluem-desc": "如果您想要拥有大量ETH，选择一个能让您一次购进价值$2000 ETH的钱包。",
  "page-find-wallet-volume": "大宗采购",
  "page-find-wallet-we-found": "我们发现",
  "page-find-wallet-withdraw": "变现至银行",
  "page-find-wallet-withdraw-desc": "无需交易所，您就可以将您的ETH直接变现并存入您的银行账户。",
  "page-find-wallet-yet": "",
  "page-find-wallet-zengo-logo-alt": "ZenGo徽标",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "质押ETH",
  "page-wallets-accounts-addresses": "钱包、帐户和地址",
  "page-wallets-accounts-addresses-desc": "需要理解这些关键术语之间的差异。",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "插图：一个身体是保险箱的机器人，代表一个以太坊钱包",
  "page-wallets-ethereum-account": "以太坊账户",
  "page-wallets-blog": "Coinbase博客",
  "page-wallets-bookmarking": "将您的钱包页面加入浏览器的书签项",
  "page-wallets-bookmarking-desc": "如果您使用一个网页版的钱包，将这个站点加入书签以防止网络钓鱼。",
  "page-wallets-cd": "硬件钱包能够线下保存您的加密资产 - 非常安全",
  "page-wallets-converted": "加密老鸟？",
  "page-wallets-converted-desc": "如果您要保存大量资产，我们推荐使用硬件钱包，因为它们最为安全。或者一个自带风控告警和提款限制的钱包。",
  "page-wallets-curious": "加密新人？",
  "page-wallets-curious-desc": "如果您是第一次接触加密资产，想找找感觉，我们推荐一些可以帮助您探索以太坊去中心化应用或者支持直接购买以太币的钱包。",
  "page-wallets-desc-2": "您需要一个钱包来发送和管理你的ETH。",
  "page-wallets-desc-2-link": "ETH的更多信息",
  "page-wallets-desc-3": "您的钱包只是管理您以太坊账户的工具。这意味着您可以随时更换您的钱包应用。许多钱包应用都支持同时管理您的多个以太坊账户。",
  "page-wallets-desc-4": "是您，而不是钱包实际控制您的资产。它们只是管理您资产的工具。",
  "page-wallets-description": "以太坊钱包是一种帮助您与您的以太坊账户进行交互的工具。可以把它想像成一个背后没有银行的互联网银行应用。通过钱包您可以查看余额，发送交易或者链接到各种应用。",
  "page-wallets-desktop": "如果您更喜欢在MacOS、Windows或者Linux系统上管理您的资金，请使用桌面版应用",
  "page-wallets-ethereum-wallet": "钱包",
  "page-wallets-explore": "探索以太坊",
  "page-wallets-features-desc": "我们可以依据您的关注点推荐相应的钱包。",
  "page-wallets-features-title": "想要依据功能特点进行选择？",
  "page-wallets-find-wallet-btn": "获取一个钱包",
  "page-wallets-find-wallet-link": "获取一个钱包",
  "page-wallets-get-some": "获取一些ETH",
  "page-wallets-get-some-alt": "插图：一只正在搭建乐高版ETH徽标的手",
  "page-wallets-get-some-btn": "获取一些ETH",
  "page-wallets-get-some-desc": "ETH是以太坊内建的原生加密货币。要使用以太坊应用，您需要在您的钱包中预留一些以太币。",
  "page-wallets-get-wallet": "获取一个钱包",
  "page-wallets-get-wallet-desc": "有很多钱包应用可以选择。我们会帮助您找到最适合的那个。",
  "page-wallets-get-wallet-desc-2": "记住：未来您可以更改这个选择 - 您的以太坊账户并不和钱包提供商绑定。",
  "page-wallets-how-to-store": "如何在以太坊上存储您的数字资产",
  "page-wallets-keys-to-safety": "保证您加密货币安全的关键",
  "page-wallets-manage-funds": "一个管理您资金的应用",
  "page-wallets-manage-funds-desc": "您的钱包会展示您的余额、交易记录并帮助您发送/接收资金。有的还能提供更多功能。",
  "page-wallets-meta-description": "使用以太坊钱包需要知道的知识。",
  "page-wallets-meta-title": "以太坊钱包",
  "page-wallets-mobile": "想从任何地方访问您的资金，请选择手机应用",
  "page-wallets-more-on-dapps-btn": "去中心化应用的更多信息",
  "page-wallets-most-wallets": "绝大多数钱包都能为您生成以太坊地址，因此您不必在下载钱包前先生成一个。",
  "page-wallets-protecting-yourself": "保护您自己和您的资金",
  "page-wallets-seed-phrase": "将您的助记词写下来",
  "page-wallets-seed-phrase-desc": "钱包会提供给您一系列助记词，您必须把它们写下来并保存在安全的地方。这是当您丢失钱包时找回它的唯一方法。",
  "page-wallets-seed-phrase-example": "这里有一个案例：",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "不要把它保存在电脑里。写下来并确保它的安全。",
  "page-wallets-slogan": "通向您的数字未来的钥匙",
  "page-wallets-stay-safe": "如何保证安全",
  "page-wallets-stay-safe-desc": "钱包在思想上有一些转变。经济自由以及随时随地获取和使用资金的能力伴随着额外的责任 - 加密世界中没有客户支持。",
  "page-wallets-subtitle": "钱包能够访问您的资金和以太坊应用。应当只有您才能访问您的钱包。",
  "page-wallets-take-responsibility": "为您自己的资金负责",
  "page-wallets-take-responsibility-desc": "中心化交易所会将您的钱包和一组用户名密码进行关联，这样您就可以按照传统的方式找回它们。但是请记住您的信任使得交易所可以操纵您的资金，如果这家公司被攻击或者将要破产，您的资金将处于风险之中。",
  "page-wallets-tips": "保证安全的更多提示",
  "page-wallets-tips-community": "来自社区",
  "page-wallets-title": "以太坊钱包",
  "page-wallets-triple-check": "小心谨慎",
  "page-wallets-triple-check-desc": "记住，交易不可逆且钱包不能被轻易找回，请小心。",
  "page-wallets-try-dapps": "尝试一些去中心化应用",
  "page-wallets-try-dapps-alt": "插图：以太坊社区成员一起工作",
  "page-wallets-try-dapps-desc": "去中心化应用是构建于以太坊之上的应用。它们对待你的数据比普通的应用更经济、公平和友好。",
  "page-wallets-types": "钱包类型",
  "page-wallets-web-browser": "想要通过浏览器操作账户，请使用网页版钱包",
  "page-wallets-whats-a-wallet": "什么是以太坊钱包？",
  "page-wallets-your-ethereum-account": "您的以太坊账户",
  "page-wallets-your-ethereum-account-desc": "您的钱包是您与您的以太坊账户进行交互的窗口 - 查看余额、交易记录等等。但是您可以随时更换您的钱包提供商。",
  "page-wallets-your-login": "登录以太坊应用",
  "page-wallets-your-login-desc": "您的钱包让您能够使用以太坊账户链接任何去中心化应用。它是各种去中心化应用的统一登录入口。",
  "page-what-is-ethereum-101": "以太坊 101",
  "page-what-is-ethereum-101-desc": "以太坊是一种通过支付小额费用向任何人发送加密货币的技术。基于它还能创建永不停机、人人可用的各种应用。",
  "page-what-is-ethereum-101-desc-2": "以太坊以比特币带来的创新为基础，同时做出了很多改进。",
  "page-what-is-ethereum-101-desc-3": "虽然两者都能让您无需支付服务提供商或银行的支持即可使用数字货币，但是以太坊是可编程的，因此您可以基于它构建其它不同的数字资产 - 甚至是比特币！",
  "page-what-is-ethereum-101-desc-4": "因此以太坊并不仅仅意味着支付。它还是一个聚集了各种金融服务、游戏和应用的自由市场。在这里没有人可以监控或者窃取您的数据。",
  "page-what-is-ethereum-101-italic": "属于全世界的可编程区块链。",
  "page-what-is-ethereum-101-strong": "它是 ",
  "page-what-is-ethereum-accessibility": "以太坊向所有人开放。",
  "page-what-is-ethereum-adventure": "开始您的奇妙旅程吧！",
  "page-what-is-ethereum-alt-img-bazaar": "插图：一个人正在窥视代表以太坊的市集。",
  "page-what-is-ethereum-alt-img-comm": "插图：以太坊社区成员一起工作",
  "page-what-is-ethereum-alt-img-lego": "插图：一只正在搭建乐高版ETH徽标的手",
  "page-what-is-ethereum-alt-img-social": "插图：一个大型的ETH徽标",
  "page-what-is-ethereum-banking-card": "属于所有人的银行服务",
  "page-what-is-ethereum-banking-card-desc": "现实生活中不是每个人都能使用金融服务。但是只要您有网络，就可以访问基于以太坊的借贷和储蓄产品。",
  "page-what-is-ethereum-build": "基于以太坊创造一些玩意",
  "page-what-is-ethereum-build-desc": "如果您想尝试进行以太坊相关的开发，请阅读我们的文档，尝试一些教程或者下载开发工具作为开始。",
  "page-what-is-ethereum-censorless-card": "反审查",
  "page-what-is-ethereum-censorless-card-desc": "没有政府或者公司拥有以太坊的控制权。去中心化保障了您自由使用以太坊的权力。",
  "page-what-is-ethereum-comm-desc": "我们的社区成员来自五湖四海，各行各业，他们包括艺术家、加密无政府主义者，世界500强企业，现在，还有您。赶快来看看如何加入吧。",
  "page-what-is-ethereum-commerce-card": "商业保障",
  "page-what-is-ethereum-commerce-card-desc": "以太坊创造了一个更加公平的竞争环境。客户拥有内置的安全保障，只要提供足质足量的产品，就能获得对应的报酬。大公司的影响力并不是生意成功的前提。",
  "page-what-is-ethereum-community": "以太坊社区",
  "page-what-is-ethereum-compatibility-card": "合作共赢",
  "page-what-is-ethereum-compatibility-card-desc": "良好的兼容性使得基于以太坊的产品始终拥有良好的产品设计和用户体验。每个公司都可以在其它产品的基础上获得属于自己的成功。",
  "page-what-is-ethereum-dapps-desc": "运行在以太坊上的产品和服务。这些应用涵盖金融、工作、社交媒体、游戏等各种领域。来看看这些属于数字未来的应用吧。",
  "page-what-is-ethereum-dapps-img-alt": "插图：一只正在电脑前使用以太坊应用程序的拟人狗",
  "page-what-is-ethereum-dapps-title": "以太坊去中心化应用",
  "page-what-is-ethereum-desc": "我们数字未来的基石",
  "page-what-is-ethereum-explore": "探索以太坊",
  "page-what-is-ethereum-get-started": "了解更多的最好方式是下载一个钱包，获取一些ETH并尝试一个去中心化应用。",
  "page-what-is-ethereum-in-depth-description": "无论您是何背景，无论您身处何处，都可以通过以太坊的服务方便地访问属于您的数字资产。以太币(ETH)和上千款应用的背后是一整个开源社区的支持。",
  "page-what-is-ethereum-internet-card": "更好的隐私保护",
  "page-what-is-ethereum-internet-card-desc": "使用以太坊应用时您无需提供所有私人信息。以太坊的经济体系构建于价值，而非监管。",
  "page-what-is-ethereum-meet-comm": "我们的社区",
  "page-what-is-ethereum-meta-description": "学习以太坊，了解它是什么以及如何使用。",
  "page-what-is-ethereum-meta-title": "以太坊简介",
  "page-what-is-ethereum-native-alt": "以太币(ETH)",
  "page-what-is-ethereum-native-crypto": "类似于比特币的以太坊原生加密货币。您可以将它作为价值存储发送给家人和朋友，或者在去中心化应用中消费它。",
  "page-what-is-ethereum-native-img-alt": "插图：一个自带保险箱的机器人，用来代表以太坊钱包",
  "page-what-is-ethereum-native-title": "以太币(ETH)",
  "page-what-is-ethereum-p2p-card": "P2P网络",
  "page-what-is-ethereum-p2p-card-desc": "以太坊允许您跳过中间商，直接和任何人转移资产或者签订协议。",
  "page-what-is-ethereum-singlecard-desc": "如果您对区块链及以太坊的技术细节感兴趣，请点击查看相关内容。",
  "page-what-is-ethereum-singlecard-link": "以太坊如何运作",
  "page-what-is-ethereum-singlecard-title": "以太坊如何运作",
  "page-what-is-ethereum-start-building-btn": "开始构建",
  "page-what-is-ethereum-title": "以太坊简介",
  "page-what-is-ethereum-tools-needed": "您只需要一个钱包就可以一起参与。",
  "page-what-is-ethereum-try": "尝试以太坊",
  "page-what-is-ethereum-tryit": "快来探索这个新世界并且动手试一试吧。",
  "page-what-is-ethereum-wallets": "钱包",
  "page-what-is-ethereum-wallets-desc": "立即创建一个钱包来管理您的以太坊账户和以太币吧。",
  "page-what-is-ethereum-welcome": "欢迎来到以太坊",
  "page-what-is-ethereum-welcome-2": "这里有你想要的一切。",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "对于想存ETH生息的人，存币生息已经开始啦",
  "banner-staking-2": "确认存款合约地址",
  "docking": "对接",
  "page-eth2-vision-security-desc-9": "使得我们为不同的分片随机分配验证者 - 这使得验证者们串通起来攻击特定分片变得不现实。由于在工作量证明模式的区块链中，协议无法控制矿工，因此分片无法获得这样的高安全性。",
  "page-index-sections-developers-desc": "了解以太坊背后的技术及其应用，以便您可以开始构建。",
  "page-index-sections-developers-image-alt": "用石砖制作的以太坊玻璃纸构造的一只手",
  "page-index-sections-developers-link-text": "开始构建",
  "page-index-sections-developers-title": "开发者",
  "page-index-sections-enterprise-desc": "查看以太坊如何开启新的业务模式，降低您的成本，并使您的业务经得起未来的考验。",
  "page-index-sections-enterprise-image-alt": "聚集在笔记本电脑旁从事以太坊项目的一群人",
  "page-index-sections-enterprise-link-text": "企业级以太坊",
  "page-index-sections-enterprise-title": "企业级应用",
  "page-index-sections-individuals-desc": "了解以太坊、以太、钱包、令牌等等，您可以开始使用以太坊应用。",
  "page-index-sections-individuals-image-alt": "坐在计算机上的一只狗",
  "page-index-sections-individuals-link-text": "开始使用以太坊",
  "page-index-sections-individuals-title": "关于以太坊",
  "page-index-subtitle": "在以太坊上，您可以通过编写代码管理数字资产、运行程序，更重要的是，这一切都不受地域限制。",
  "page-find-wallet-authereum-logo-alt": "Authereum徽标",
  "page-find-wallet-description-authereum": "无需下载，没有助记词。支持任意平台任意浏览器随时使用",
  "page-wallets-accounts-addresses-desc-2": "是一个能够发送交易、拥有余额的实体",
  "page-wallets-accounts-has": "一个以太坊账户拥有一个",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "以太坊地址",
  "page-wallets-ethereum-addresses-2": "，就像收件箱有一个电子邮件地址。您需要它来发送资金到另一个账户。",
  "page-wallets-ethereum-wallet-2": "是一个帮助您管理自己以太坊账户的产品，例如查看账户余额，发送交易等等。"
}
