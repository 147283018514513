{
  "1inch-logo": "Logo de 1inch",
  "aave-logo": "Logo de Aave",
  "about": "À propos",
  "about-ethereum-org": "À propos d'ethereum.org",
  "about-us": "À propos de nous",
  "alt-eth-blocks": "Illustration de blocs organisés comme un symbole ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Retour en Haut",
  "banner-page-incomplete": "Cette page est incomplète. Si vous êtes un expert sur le sujet, veuillez éditer cette page et l'enrichir de votre sagesse.",
  "beacon-chain": "Chaîne phare",
  "binance-logo": "Logo Binance",
  "bittrex-logo": "Logo Bittrex",
  "brand-assets": "Actifs de la marque",
  "bug-bounty": "Prime de Bug",
  "coinbase-logo": "Logo Coinbase",
  "coinmama-logo": "Logo Coinmama",
  "community": "Communauté",
  "community-menu": "Community Menu",
  "compound-logo": "Logo Compound",
  "cons": "Inconvénients",
  "contact": "Contact",
  "content-versions": "Content Versions",
  "contributing": "Contribution",
  "contributors": "Contributeurs",
  "contributors-thanks": "Tous ceux qui ont contribué à cette page - merci !",
  "cookie-policy": "Politique en matière de cookies",
  "copied": "Copié",
  "copy": "Copier",
  "dark-mode": "Mode sombre",
  "data-provided-by": "Données fournies par",
  "decentralized-applications-dapps": "Applications décentralisées (DApps)",
  "deposit-contract": "Contrat de dépôt",
  "devcon": "Devcon",
  "developers": "Développeurs",
  "developers-home": "Page d'accueil des développeurs",
  "docs": "Docs",
  "documentation": "Documentation",
  "dydx-logo": "Logo Dydx",
  "ecosystem": "Écosystème",
  "edit-page": "Modifier la page",
  "ef-blog": "Blog de l'Ethereum Foundation",
  "eips": "Propositions d'amélioration d'Ethereum",
  "enterprise": "Entreprises",
  "enterprise-menu": "Menu Entreprise",
  "esp": "Programme de soutien aux écosystèmes",
  "eth-current-price": "Prix actuel de l'ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Explorateur open source de la chaîne phare Eth2",
  "eth2-beaconscan-desc": "Explorateur de la chaîne phare Eth2 - Etherscan pour Eth2",
  "eth2-become-staker": "Devenir un validateur",
  "eth2-become-staker-desc": "La mise en jeu est ouverte ! Si vous voulez miser vos ETH pour aider à sécuriser le réseau, soyez au courant des risques.",
  "eth2-explore": "Explorer les données",
  "eth2-no-action-needed": "Vous n'avez pas besoin de faire quoi que ce soit avec un ETH que vous détenez déjà. Méfiez-vous des escrocs qui vous demandent d'envoyer ETH pour l'échanger.",
  "eth2-run-beacon-chain": "Exécuter un client de la chaîne phare",
  "eth2-run-beacon-chain-desc": "Ethereum a besoin du plus grand nombre de clients en cours d'exécution. Aidez ce bien public Ethereum !",
  "eth2-service-announcement": "Message de service Eth2",
  "eth2-what-shipping": "Quand est-ce qu'il est expédié ?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Éléments de la marque Ethereum",
  "ethereum-community": "Communauté Ethereum",
  "ethereum-foundation": "Ethereum Foundation",
  "ethereum-foundation-logo": "Logo de l'Ethereum Foundation",
  "ethereum-glossary": "Glossaire Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Logo Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Ethereum Studio",
  "ethereum-wallets": "Portefeuilles Ethereum",
  "ethereum-whitepaper": "Livre blanc Ethereum",
  "example-projects": "Exemples de projets",
  "find-wallet": "Trouver un portefeuille",
  "foundation": "Fondation",
  "gemini-logo": "Logo Gemini",
  "get-eth": "Obtenir de l'ETH",
  "get-involved": "S’engager",
  "get-started": "Premiers pas",
  "gitcoin-logo": "Logo Gitcoin",
  "glossary": "Glossaire",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Guides et ressources",
  "history": "Historique",
  "history-of-ethereum": "L'histoire d'Ethereum",
  "home": "Accueil",
  "how-ethereum-works": "Fonctionnement d'Ethereum",
  "image": "image",
  "in-this-section": "Dans cette section",
  "individuals": "Particuliers",
  "individuals-menu": "Menu des particuliers",
  "jobs": "Jobs",
  "kraken-logo": "Logo Kraken",
  "language-ar": "Arabe",
  "language-bg": "Bulgarian",
  "language-bn": "Bengali",
  "language-ca": "Catalan",
  "language-cs": "Tchèque",
  "language-de": "Allemand",
  "language-el": "Grec",
  "language-en": "Anglais",
  "language-es": "Espagnol",
  "language-fa": "Farsi",
  "language-fi": "Finlandais",
  "language-fr": "Français",
  "language-hu": "Hongrois",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonésien",
  "language-ig": "Igbo",
  "language-it": "Italien",
  "language-ja": "Japonais",
  "language-ko": "Coréen",
  "language-lt": "Lituanien",
  "language-ml": "Malayalam",
  "language-nb": "Norvégien",
  "language-nl": "Hollandais",
  "language-pl": "Polonais",
  "language-pt": "Portugais",
  "language-pt-br": "Portugais (Brésil)",
  "language-ro": "Roumain",
  "language-ru": "Russe",
  "language-se": "Suédois",
  "language-sk": "Slovaque",
  "language-sl": "Slovène",
  "language-support": "Support linguistique",
  "language-tr": "Turc",
  "language-uk": "Ukrainien",
  "language-vi": "Vietnamien",
  "language-zh": "Chinois Simplifié",
  "language-zh-tw": "Chinois traditionnel",
  "languages": "Langues",
  "last-24-hrs": "Dernières 24 heures",
  "last-edit": "Dernière modification",
  "learn": "Apprendre",
  "learn-by-coding": "Apprendre en codant",
  "learn-menu": "Menu d'apprentissage",
  "learn-more": "En savoir plus",
  "less": "Moins",
  "light-mode": "Mode de lumière",
  "listing-policy-disclaimer": "Tous les produits répertoriés sur cette page ne sont pas des mentions officielles et sont fournis à titre informatif uniquement. Si vous souhaitez ajouter un produit ou fournir des commentaires sur la stratégie, indiquez le problème dans GitHub.",
  "listing-policy-raise-issue-link": "Soulever un problème",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Chargement...",
  "loading-error": "Erreur de chargement.",
  "loading-error-refresh": "Erreur lors du chargement des données. Essayez d'actualiser la page.",
  "logo": "logo",
  "loopring-logo": "Logo Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Réseau principal Ethereum",
  "makerdao-logo": "Logo MakerDao",
  "matcha-logo": "Logo Matcha",
  "merge": "Merge",
  "more": "Plus",
  "nav-beginners": "Débutants",
  "next": "Suivant",
  "oasis-logo": "Logo Oasis",
  "on-this-page": "Sur cette page",
  "page-content": "Contenu de la page",
  "page-enterprise": "Entreprises",
  "page-last-updated": "Dernière mise à jour de la page",
  "previous": "Précédent",
  "privacy-policy": "Politique de confidentialité",
  "private-ethereum": "Ethereum privé",
  "pros": "Avantages",
  "read-more": "Voir plus",
  "refresh": "Veuillez actualiser la page.",
  "review-progress": "Progression de la révision",
  "search": "Rechercher",
  "search-box-blank-state-text": "Cherchez !",
  "search-eth-address": "Cela ressemble à une adresse Ethereum. Nous ne fournissons pas de données spécifiques aux adresses. Essayez de la rechercher dans un explorateur de bloc comme",
  "search-no-results": "Aucun résultat pour votre recherche",
  "security": "Security",
  "see-contributors": "Voir les contributeurs",
  "set-up-local-env": "Configurer un environnement local",
  "shard-chains": "Chaines shard",
  "show-all": "Afficher tout",
  "show-less": "Afficher moins",
  "site-description": "Ethereum est une plateforme monétaire globale et décentralisée qui propose également de nouveaux types d'applications. Sur Ethereum, vous pouvez écrire du code qui contrôle de la monnaie, et construire des applications accessibles partout dans le monde.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoins",
  "staking": "Staking",
  "summary": "Résumé",
  "terms-of-use": "Conditions d'utilisation",
  "transaction-fees": "Quels sont les frais de transaction ?",
  "translation-banner-body-new": "Cette page s'affiche en anglais car nous ne l'avons pas encore traduite. Aidez-nous à traduire son contenu.",
  "translation-banner-body-update": "Il existe une nouvelle version de cette page, mais seulement en anglais pour le moment. Aidez-nous à traduire la dernière version.",
  "translation-banner-button-join-translation-program": "Rejoindre le programme de traduction",
  "translation-banner-button-learn-more": "En savoir plus",
  "translation-banner-button-see-english": "Voir l'anglais",
  "translation-banner-button-translate-page": "Traduire la page",
  "translation-banner-title-new": "Aider à traduire cette page",
  "translation-banner-title-update": "Aider à mettre à jour cette page",
  "translation-program": "Translation Program",
  "translation-progress": "Progression de la traduction",
  "tutorials": "Tutoriels",
  "uniswap-logo": "Logo Uniswap",
  "use": "Utiliser",
  "use-ethereum": "Utiliser Ethereum",
  "use-ethereum-menu": "Utiliser le menu Ethereum",
  "vision": "Vision",
  "wallets": "Portefeuille",
  "website-last-updated": "Dernière mise à jour du site",
  "what-is-ether": "Qu'est-ce que l'ether (ETH) ?",
  "what-is-ethereum": "Qu'est-ce qu'Ethereum ?",
  "whitepaper": "Livre blanc",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Suggérer une fonctionnalité",
  "page-about-h3": "En développement",
  "page-about-h3-1": "Fonctionnalités implémentées",
  "page-about-h3-2": "Fonctionnalités prévues",
  "page-about-li-1": "en cours",
  "page-about-li-2": "prévu",
  "page-about-li-3": "implémenté",
  "page-about-li-4": "implémenté",
  "page-about-link-1": "Le code source de ce répertoire est sous licence MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Voir la liste complète des tâches en cours sur GitHub",
  "page-about-link-4": "Rejoignez notre serveur Discord",
  "page-about-link-5": "Contactez-nous sur Twitter",
  "page-about-link-6": "Voir la liste complète des tâches implémentées sur GitHub",
  "page-about-link-7": "Créer une 'issue' sur GitHub",
  "page-about-p-1": "Depuis le lancement d'ethereum.org, nous nous efforçons d'être transparents sur la façon dont nous fonctionnons. C'est l'une de nos valeurs fondamentales, car nous pensons que la transparence est essentielle au succès d'Ethereum.",
  "page-about-p-2": "Nous utilisons",
  "page-about-p-3": "comme principal outil de gestion de projet. Nous organisons nos tâches en 3 catégories :",
  "page-about-p-4": "  Nous faisons de notre mieux pour que la communauté soit informée du statut d'une tâche spécifique.",
  "page-about-p-5": "Les tâches en cours d'implémentation.",
  "page-about-p-6": "Les tâches que nous avons mises en file d'attente pour les implémenter plus tard.",
  "page-about-p-7": "Les tâches récemment terminées.",
  "page-about-p-8": "Vous avez des idées pour améliorer ethereum.org ? Nous adorerions collaborer avec vous !",
  "page-assets-bazaar": "Le bazar d'Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Blocs de construction",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge avec DApps",
  "page-assets-download-artist": "Artiste :",
  "page-assets-download-download": "Téléchargement",
  "page-assets-enterprise": "Ethereum pour les Entreprises",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "Diamant ETH (couleur)",
  "page-assets-eth-diamond-glyph": "Diamant ETH (glyphe)",
  "page-assets-eth-diamond-gray": "Diamant ETH (gris)",
  "page-assets-eth-diamond-purple": "Diamant ETH (violet)",
  "page-assets-eth-diamond-white": "Diamant ETH (blanc)",
  "page-assets-eth-glyph-video-dark": "Vidéo de glyphe ETH (sombre)",
  "page-assets-eth-glyph-video-light": "Vidéo de glyphe ETH (clair)",
  "page-assets-eth-logo-landscape-gray": "Logo ETH paysage (gris)",
  "page-assets-eth-logo-landscape-purple": "Logo ETH paysage (violet)",
  "page-assets-eth-logo-landscape-white": "Logo ETH paysage (blanc)",
  "page-assets-eth-logo-portrait-gray": "Logo ETH portrait (gris)",
  "page-assets-eth-logo-portrait-purple": "Logo ETH portrait (violet)",
  "page-assets-eth-logo-portrait-white": "Logo ETH portrait (blanc)",
  "page-assets-eth-wordmark-gray": "Mot-symbole ETH (grise)",
  "page-assets-eth-wordmark-purple": "Mot-symbole ETH (violet)",
  "page-assets-eth-wordmark-white": "Mot-symbole ETH (blanc)",
  "page-assets-ethereum-brand-assets": "Actifs de la marque Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "actifs ethereum.org",
  "page-assets-hero": "Hero ethereum.org",
  "page-assets-hero-particles": "Image des particules d’ETH",
  "page-assets-historical-artwork": "Illustrations historiques",
  "page-assets-illustrations": "Illustrations",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Explorez et téléchargez les actifs, illustrations et médias des marques Ethereum et ethereum.org",
  "page-assets-meta-title": "Actifs de la marque Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Arrière-plan plein",
  "page-assets-page-assets-transparent-background": "Arrière-plan transparent",
  "page-assets-robot": "Portefeuille robot",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "Logo de 1inch",
  "page-dapps-aave-logo-alt": "Logo de Aave",
  "page-dapps-add-button": "Dapp suggérée",
  "page-dapps-add-title": "Ajouter une DApp",
  "page-dapps-audius-logo-alt": "Logo Audius",
  "page-dapps-augur-logo-alt": "Logo Augure",
  "page-dapps-axie-infinity-logo-alt": "Logo Axie Infinity",
  "page-dapps-brave-logo-alt": "Logo Brave",
  "page-dapps-category-arts": "Art et mode",
  "page-dapps-category-browsers": "Navigateurs",
  "page-dapps-category-collectibles": "Produits de collection numériques",
  "page-dapps-category-competitive": "Compétition",
  "page-dapps-category-computing": "Outils de développement",
  "page-dapps-category-dex": "Échanges de jetons",
  "page-dapps-category-investments": "Investissements",
  "page-dapps-category-lending": "Prêter et emprunter",
  "page-dapps-category-lottery": "Financement participatif",
  "page-dapps-category-marketplaces": "Marchés",
  "page-dapps-category-music": "Musique",
  "page-dapps-category-payments": "Paiements",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Marché de trading et marchés prédictifs",
  "page-dapps-category-utilities": "Utilitaires",
  "page-dapps-category-worlds": "Mondes virtuels",
  "page-dapps-choose-category": "Choisir une catégorie",
  "page-dapps-collectibles-benefits-1-description": "Lorsque de l'art est tokenisé sur Ethereum, la propriété peut être prouvée à tout le monde. Vous pouvez tracer le parcours de l'œuvre depuis sa création à son possesseur actuel. Cela empêche les contrefaçons.",
  "page-dapps-collectibles-benefits-1-title": "La propriété est démontrable",
  "page-dapps-collectibles-benefits-2-description": "Payer pour diffuser de la musique ou acheter des œuvres d'art est beaucoup plus juste pour les artistes. Avec Ethereum, il y a moins besoin d'intermédiaires. Et si des intermédiaires sont nécessaires, leurs coûts ne sont pas aussi élevés parce que les plateformes n'ont pas besoin de payer pour l'infrastructure du réseau.",
  "page-dapps-collectibles-benefits-2-title": "Plus équitable pour les créateurs",
  "page-dapps-collectibles-benefits-3-description": "Les objets de collection tokenisés sont liés à votre adresse Ethereum, pas à la plateforme. Ainsi, vous pouvez vendre des objets, comme par exemple des items in-game, non plus seulement dans le jeu lui-même mais sur n'importe quel marché Ethereum.",
  "page-dapps-collectibles-benefits-3-title": "Les objets de collection ne vous quittent plus",
  "page-dapps-collectibles-benefits-4-description": "Les outils et les produits existent déjà pour vous permettre de tokeniser votre art et de le vendre ! Et vos jetons peuvent être vendus sur toutes les plateformes d'objets de collection Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Infrastructure déjà en place",
  "page-dapps-collectibles-benefits-description": "Ce sont des applications qui se concentrent sur la propriété numérique, augmentant le potentiel de gains pour les créateurs, et inventant de nouvelles façons d'investir dans vos créateurs préférés et leur travail.",
  "page-dapps-collectibles-benefits-title": "collections décentralisées et streaming",
  "page-dapps-collectibles-button": "Arts et objets de collection",
  "page-dapps-collectibles-description": "Ce sont des applications qui se concentrent sur la propriété numérique, augmentant le potentiel de gains pour les créateurs, et inventant de nouvelles façons d'investir dans vos créateurs préférés et leur travail.",
  "page-dapps-collectibles-title": "Arts et objets de collection décentralisés",
  "page-dapps-compound-logo-alt": "Logo Compound",
  "page-dapps-cryptopunks-logo-alt": "Logo CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Logo Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Vous aide à éviter un glissement du prix à la hausse en agrégant les meilleurs prix.",
  "page-dapps-dapp-description-aave": "Prêtez vos jetons pour gagner des intérêts et retirez à tout moment.",
  "page-dapps-dapp-description-async-art": "Créez, collectionnez et échangez #ProgrammableArt - des peintures numériques divisées en \"calques\" que vous pouvez utiliser pour affecter l'image globale. Chaque maître et chaque calque est un jeton ERC721.",
  "page-dapps-dapp-description-audius": "Plateforme de streaming décentralisée. Écoutes = argent pour les créateurs, pas pour les labels.",
  "page-dapps-dapp-description-augur": "Parier sur les résultats du sport, de l'économie et plus d'événements mondiaux.",
  "page-dapps-dapp-description-axie-infinity": "Échangez et combattez des créatures appelées Axies. Et empochez en jouant, disponible sur mobile",
  "page-dapps-dapp-description-brave": "Gagnez des jetons en naviguant et soutenez vos créateurs préférés avec eux.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Prêtez vos jetons pour gagner des intérêts et retirez à tout moment.",
  "page-dapps-dapp-description-cryptopunks": "Achetez, pariez et offrez des punks à la vente – l'un des premiers jetons de collection sur Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Créez des galeries d'art, construisez des magasins et achetez des terrains – un monde virtuel Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Conquérez des planètes dans un univers infini, généré de façon procédurale et spécifié par cryptographie.",
  "page-dapps-dapp-description-decentraland": "Collectez et échangez des terrains virtuels dans un monde virtuel que vous pouvez explorer.",
  "page-dapps-dapp-description-dydx": "Ouvrez des positions courtes ou à effet de levier jusqu'à 10x. Prêts et emprunt également disponibles.",
  "page-dapps-dapp-description-ens": "Noms conviviaux pour les adresses Ethereum et les sites décentralisés.",
  "page-dapps-dapp-description-foundation": "Investissez dans des éditions uniques d'œuvres d'art numériques et échangez des pièces avec d'autres acheteurs.",
  "page-dapps-dapp-description-gitcoin": "Gagnez de la cryptomonnaie en travaillant sur des logiciels en open source.",
  "page-dapps-dapp-description-gitcoin-grants": "Financement participatif de projets communautaires Ethereum avec des contributions amplifiées",
  "page-dapps-dapp-description-gods-unchained": "Jeu stratégique de cartes de trading. Gagnez des cartes que vous pouvez vendre dans la vie réelle en jouant.",
  "page-dapps-dapp-description-golem": "Accédez à l'énergie informatique partagée ou louez vos propres ressources.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Plateforme de trading Peer-to-peer conçue pour la vitesse.",
  "page-dapps-dapp-description-marble-cards": "Créez et échangez des cartes numériques uniques basées sur des URL.",
  "page-dapps-dapp-description-matcha": "Analyse plusieurs marchés pour vous aider à trouver les meilleurs prix.",
  "page-dapps-dapp-description-nifty-gateway": "Achetez des œuvres sur la chaîne auprès des meilleurs artistes, athlètes, marques et créateurs.",
  "page-dapps-dapp-description-oasis": "Échangez, empruntez et économisez avec Dai, un stablecoin Ethereum.",
  "page-dapps-dapp-description-opensea": "Achetez, vendez, découvrez, et échangez des produits en édition limitée.",
  "page-dapps-dapp-description-opera": "Envoyez des cryptomonnaies à partir de votre navigateur aux commerçants, aux autres utilisateurs et aux applications.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Pariez sur les résultats, négociez sur les marchés de l'information.",
  "page-dapps-dapp-description-pooltogether": "Une loterie que vous ne pouvez pas perdre. Des prix chaque semaine.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Créez, vendez et achetez des objets de collection tokenisés.",
  "page-dapps-dapp-description-sablier": "Diffusez de l'argent en temps réel.",
  "page-dapps-dapp-description-superrare": "Achetez des œuvres numériques directement auprès des artistes ou sur les marchés secondaires.",
  "page-dapps-dapp-description-token-sets": "Stratégies d'investissement cryptographiques qui se rééquilibrent automatiquement.",
  "page-dapps-dapp-description-tornado-cash": "Effectuez des transactions anonymes grâce à Ethereum.",
  "page-dapps-dapp-description-uniswap": "Échangez facilement des jetons ou envoyez des jetons pour des récompenses en pourcentage.",
  "page-dapps-dark-forest-logo-alt": "Logo de Dark Forest",
  "page-dapps-decentraland-logo-alt": "Logo de Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Trouvez une application Ethereum a essayer.",
  "page-dapps-doge-img-alt": "Image d'un Shiba utilisant un ordinateur",
  "page-dapps-dydx-logo-alt": "Logo de dYdX",
  "page-dapps-editors-choice-dark-forest": "Jouez contre d'autres pour conquérir des planètes et essayez la technologie de mise à l'échelle/de confidentialité Ethereum de pointe. Seulement pour ceux qui sont familiarisés avec la technologie d'Ethereum.",
  "page-dapps-editors-choice-description": "Quelques DApps que l'équipe ethereum.org adorent en ce moment. Découvrez plus DApps ci-dessous.",
  "page-dapps-editors-choice-foundation": "Investissez dans la culture. Achetez, échangez, et vendez des illustrations numériques uniques et des articles de mode de certains artistes, musiciens et marques incroyables.",
  "page-dapps-editors-choice-header": "Choix de la rédaction",
  "page-dapps-editors-choice-pooltogether": "Achetez un billet gagnant pour la loterie. Chaque semaine, l'intérêt généré par l'ensemble du pool de billets est envoyé à un heureux gagnant. Récupérez votre argent quand vous le souhaitez.",
  "page-dapps-editors-choice-uniswap": "Échangez facilement vos jetons. Un favori de la communauté qui vous permet d'échanger des jetons avec le réseau.",
  "page-dapps-ens-logo-alt": "Logo du service d'Ethereum",
  "page-dapps-explore-dapps-description": "Beaucoup de DApps sont encore expérimentales, testant les possibilités de réseaux décentralisés. Mais il y a eu quelques premiers succès dans les catégories de la technologie, de la finance, des jeux et des objets de collection.",
  "page-dapps-explore-dapps-title": "Explorez des applications décentralisées",
  "page-dapps-features-1-description": "Une fois déployé sur Ethereum, le code d'une application décentralisée (DApp) ne peut pas être supprimé. Tout le monde peut utiliser les fonctionnalités d'une DApp. Même si l'équipe derrière l'application est dissoute, vous pourrez toujours l'utiliser. Une fois placé sur Ethereum, il y reste.",
  "page-dapps-features-1-title": "Aucun propriétaire",
  "page-dapps-features-2-description": "Vous ne pouvez pas être empêché d'utiliser une DApp ou de soumettre des transactions. Par exemple, si Twitter était sur Ethereum, personne ne pourrait bloquer votre compte ou vous empêcher de tweeter.",
  "page-dapps-features-2-title": "Incensurable",
  "page-dapps-features-3-description": "Étant donné que Ethereum a l'ETH, les paiements sont natifs d'Ethereum. Les développeurs n'ont pas besoin de passer du temps à intégrer des fournisseurs de paiement tiers.",
  "page-dapps-features-3-title": "Paiements intégrés",
  "page-dapps-features-4-description": "Le code d'une application décentralisée est souvent ouvert et compatible par défaut. Les équipes construisent régulièrement en utilisant le travail d'autres équipes. Si vous souhaitez permettre aux utilisateurs d'échanger des jetons dans votre DApp, il vous suffit de brancher le code d'une autre DApp.",
  "page-dapps-features-4-title": "Prêt à l'emploi",
  "page-dapps-features-5-description": "Avec la plupart des DApps, vous n'avez pas besoin de partager votre identité dans le monde réel. Votre compte Ethereum est votre identifiant et vous n'avez besoin que d'un portefeuille.",
  "page-dapps-features-5-title": "Une connexion anonyme",
  "page-dapps-features-6-description": "La cryptographie garantit que les attaquants ne peuvent pas falsifier les transactions et autres interactions DApp en votre nom. Vous autorisez les actions DApp avec votre compte Ethereum, généralement grâce à votre portefeuille, donc gardez vos identifiants en lieu sûr.",
  "page-dapps-features-6-title": "Sécurisé par la cryptographie",
  "page-dapps-features-7-description": "Une fois que la DApp est active sur Ethereum, elle ne s'arrêtera que si Ethereum lui-même s'arrête. Les réseaux de la taille d'Ethereum sont notoirement difficiles à attaquer.",
  "page-dapps-features-7-title": "Pas de temps d'arrêt",
  "page-dapps-finance-benefits-1-description": "Les services financiers fonctionnant sur Ethereum n'ont pas d'obligation d'inscription. Si vous avez des fonds et une connexion internet sécurisée, vous pouvez y aller.",
  "page-dapps-finance-benefits-1-title": "Accès libre",
  "page-dapps-finance-benefits-2-description": "Il existe tout un monde de jetons avec lesquels vous pouvez interagir à travers ces produits financiers. Les gens construisent sans arrêt de nouveaux jetons sur Ethereum.",
  "page-dapps-finance-benefits-2-title": "Une nouvelle économie de jetons",
  "page-dapps-finance-benefits-3-description": "Les équipes ont construit des stablecoins, une monnaie cryptographique moins volatile. Celles-ci vous permettent d'expérimenter et d'utiliser la cryptomonnaie sans risque ni incertitude.",
  "page-dapps-finance-benefits-3-title": "Stablecoins",
  "page-dapps-finance-benefits-4-description": "Les produits financiers de l'espace Ethereum sont tous modulaires et compatibles les uns avec les autres. De nouvelles configurations de ces modules apparaissent sans cesse sur le marché, augmentant ainsi ce que vous pouvez faire avec votre crypto.",
  "page-dapps-finance-benefits-4-title": "Services financiers interconnectés",
  "page-dapps-finance-benefits-description": "Qu'est-ce qui fait qu'Ethereum permet aux applications de financement décentralisés de prospérer ?",
  "page-dapps-finance-benefits-title": "finance décentralisée",
  "page-dapps-finance-button": "Finance",
  "page-dapps-finance-description": "Ce sont des applications qui se concentrent sur la mise en place de services financiers utilisant des cryptos. Elles vous permettent de prêter, d'emprunter, de gagner des intérêts et d'effectuer des paiements privés, sans avoir à fournir de données personnelles.",
  "page-dapps-finance-title": "Finance décentralisée",
  "page-dapps-foundation-logo-alt": "Logo de la fondation",
  "page-dapps-gaming-benefits-1-description": "Qu'il s'agisse de terrains virtuels ou de cartes à collectionner, vos objets sont négociables sur les marchés d'objets de collection. Vos objets en jeu ont une valeur réelle.",
  "page-dapps-gaming-benefits-1-title": "Les objets du jeu servent aussi de jetons",
  "page-dapps-gaming-benefits-2-description": "Vous êtes seul propriétaire de vos objets, et dans certains cas de vos progrès. Vous ne perdrez donc rien si l'entreprise à l'origine du jeu est attaquée, subit un dysfonctionnement du serveur ou se dissout.",
  "page-dapps-gaming-benefits-2-title": "Vos sauvegardes sont sûres",
  "page-dapps-gaming-benefits-3-description": "De la même façon que les paiements Ethereum sont accessibles à tous pour être vérifiés, les jeux peuvent user de cette qualité pour garantir l'équité. En théorie, tout est vérifiable, du nombre de coups critiques à la taille du trésor de guerre d'un adversaire.",
  "page-dapps-gaming-benefits-3-title": "L'équité prouvée",
  "page-dapps-gaming-benefits-description": "Qu'est-ce qui, dans Ethereum, permet au jeu décentralisé de prospérer ?",
  "page-dapps-gaming-benefits-title": "jeux décentralisés",
  "page-dapps-gaming-button": "Jeu",
  "page-dapps-gaming-description": "Ce sont des applications qui se concentrent sur la création de mondes virtuels et la lutte contre d'autres joueurs en utilisant des objets de collection qui ont une valeur réelle.",
  "page-dapps-gaming-title": "Jeux décentralisés",
  "page-dapps-get-some-eth-description": "Les actions Dapp entraînent des frais de transaction",
  "page-dapps-get-started-subtitle": "Pour essayer une DApp, vous aurez besoin d'un portefeuille et de quelques ETH. Un portefeuille vous permettra de vous connecter, ou d'ouvrir une session. Et vous aurez besoin de l'ETH pour payer les frais de transaction.",
  "page-dapps-get-started-title": "Premiers pas",
  "page-dapps-gitcoin-grants-logo-alt": "Logo Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Logo Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Logo de Gods Unchained",
  "page-dapps-golem-logo-alt": "Logo golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Outils et services alimenté par Ethereum",
  "page-dapps-hero-subtitle": "Les DApps, c'est un mouvement croissant d'applications qui utilisent Ethereum pour disrupter les modèles d'entreprises ou en inventer de nouveaux.",
  "page-dapps-how-dapps-work-p1": "Les DApps ont leur code backend (contrat intelligents) fonctionnant sur un réseau décentralisé et non pas sur un serveur centralisé. Elles utilisent la blockchain Ethereum pour le stockage de données et les contrats intelligents pour leur logique d'app.",
  "page-dapps-how-dapps-work-p2": "Un contrat intelligent est comme un ensemble de règles qui vit sur-chaîne à la vue de tous, et fonctionne exactement selon ces règles. Imaginez un distributeur de boissons : si vous lui fournissez suffisamment de fonds et entrez une sélection, vous recevrez la boisson que vous voulez. Et comme les distributeurs, les contrats intelligents peuvent détenir des fonds tout comme votre compte Ethereum. Cela permet de programmer des ententes et des transactions.",
  "page-dapps-how-dapps-work-p3": "Une fois que les DApps sont déployées sur le réseau Ethereum, vous ne pouvez pas les modifier. Les DApps peuvent être décentralisées car elles sont contrôlées par la logique inscrite dans le contrat, pas par un individu ou une entreprise.",
  "page-dapps-how-dapps-work-title": "Comment fonctionnent les DApps",
  "page-dapps-learn-callout-button": "Commencer à construire",
  "page-dapps-learn-callout-description": "Notre portail de développeurs a de la documentation, des outils et des structures pour vous aider à commencer à construire une DApp.",
  "page-dapps-learn-callout-image-alt": "Illustration d'une main frabriquant un symbole ETH au moyen de briques de lego.",
  "page-dapps-learn-callout-title": "Apprenez à élaborer une DApp",
  "page-dapps-loopring-logo-alt": "Logo Loopring",
  "page-dapps-magic-behind-dapps-description": "Les DApps peuvent donner l'impression d'être des applications normales mais elles ont des qualités spéciales parce qu'elles héritent de tous les superpouvoirs d'Ethereum. Voici ce qui différencie les DApps des applications.",
  "page-dapps-magic-behind-dapps-link": "Qu'est-ce qui rend Ethereum génial ?",
  "page-dapps-magic-behind-dapps-title": "La magie derrière les DApps",
  "page-dapps-magic-title-1": "La magie",
  "page-dapps-magic-title-2": "derrière",
  "page-dapps-magician-img-alt": "Illustration de magiciens",
  "page-dapps-marble-cards-logo-alt": "Logo marble.cards",
  "page-dapps-matcha-logo-alt": "Logo Matcha",
  "page-dapps-mobile-options-header": "Parcourir une autre catégorie",
  "page-dapps-nifty-gateway-logo-alt": "Logo Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Logo Oasis",
  "page-dapps-opensea-logo-alt": "Logo OpenSea",
  "page-dapps-opera-logo-alt": "Logo Opera",
  "page-dapps-polymarket-logo-alt": "Logo Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Logo PoolTogether",
  "page-dapps-rarible-logo-alt": "Logo Rarible",
  "page-dapps-ready-button": "Aller",
  "page-dapps-ready-description": "Choisissez une DApp à essayer",
  "page-dapps-ready-title": "Prêt ?",
  "page-dapps-sablier-logo-alt": "Logo Sablier",
  "page-dapps-set-up-a-wallet-button": "Trouver un portefeuille",
  "page-dapps-set-up-a-wallet-description": "Un portefeuille est votre \"nom d'utilisateur\" pour une DApp",
  "page-dapps-set-up-a-wallet-title": "Configurez un portefeuille",
  "page-dapps-superrare-logo-alt": "Logo SuperRare",
  "page-dapps-technology-button": "Technologie",
  "page-dapps-technology-description": "Ce sont des applications qui se concentrent sur la décentralisation des outils de développement, l'intégration de systèmes de chiffrement dans la technologie existante, et la création de marchés pour le développement des logiciels libres.",
  "page-dapps-technology-title": "Technologie décentralisée",
  "page-dapps-token-sets-logo-alt": "Logo Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Logo de Tornado Cash",
  "page-dapps-uniswap-logo-alt": "Logo Uniswap",
  "page-dapps-wallet-callout-button": "Trouver un portefeuille",
  "page-dapps-wallet-callout-description": "Les portefeuilles sont également des DApps. Trouvez-en un basé sur les fonctionnalités qui vous conviennent.",
  "page-dapps-wallet-callout-image-alt": "Illustration d'un robot.",
  "page-dapps-wallet-callout-title": "Voir les portefeuilles",
  "page-dapps-warning-header": "Faites toujours vos propres recherches",
  "page-dapps-warning-message": "Ethereum est une nouvelle technologie et la plupart des applications sont nouvelles. Avant de déposer de grandes quantités d'argent, assurez-vous de bien comprendre les risques.",
  "page-dapps-what-are-dapps": "En quoi consistent les DApps ?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Comptes",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Avancé",
  "docs-nav-backend-apis": "API back-end",
  "docs-nav-block-explorers": "Explorateurs de bloc",
  "docs-nav-blocks": "Blocs",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Compiler des contrats intelligents",
  "docs-nav-composability": "Composabilité",
  "docs-nav-consensus-mechanisms": "Mécanismes de consensus",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Données et statistiques",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Déployer des contrats intelligents",
  "docs-nav-development-frameworks": "Frameworks de développement",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Réseaux de développement",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API du client Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Pile d'Ethereum",
  "docs-nav-evm": "Machine virtuelle d'Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Sujets fondamentaux",
  "docs-nav-gas": "Carburant",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Environnements de développement intégrés (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Introduction aux DApps",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Introduction à Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Introduction à la pile",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "API JavaScript",
  "docs-nav-javascript": "JavaScript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Minage",
  "docs-nav-networks": "Réseaux",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Nœuds et clients",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Nœuds en tant que service",
  "docs-nav-oracles": "Oracles",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Langages de programmation",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Preuve d'enjeu",
  "docs-nav-proof-of-work": "Preuve de travail",
  "docs-nav-python": "Python",
  "docs-nav-readme": "LISEZ-MOI",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Mise à l'échelle",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Sécurité",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomie du contrat intelligent",
  "docs-nav-smart-contract-languages": "Langages du contrat intelligent",
  "docs-nav-smart-contracts": "Contrats intelligents",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Bibliothèques de contrats intelligents",
  "docs-nav-standards": "Normes",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Stockage",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Tester les contrats intelligents",
  "docs-nav-token-standards": "Normes de jetons",
  "docs-nav-transactions": "Transactions",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 vs Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Si vous êtes un expert sur le sujet et que vous souhaitez contribuer, modifiez cette page et enrichissez-la de votre sagesse.",
  "page-calltocontribute-desc-2": "Vous serez crédité et vous aiderez la communauté Ethereum !",
  "page-calltocontribute-desc-3": "Utilisez la flexibilité de ce",
  "page-calltocontribute-desc-4": "Des questions ? Demandez-nous dans le salon #content de notre",
  "page-calltocontribute-link": "Modèle de documentation",
  "page-calltocontribute-link-2": "Serveur Discord",
  "page-calltocontribute-span": "Modifier la page",
  "page-calltocontribute-title": "Aidez-nous avec cette page",
  "page-developer-meta-title": "Ressources pour les développeurs Ethereum",
  "page-developers-about": "À propos de ces ressources développeur",
  "page-developers-about-desc": "ethereum.org est là pour vous aider à développer avec Ethereum, et fournit de la documentation sur des concepts fondamentaux ainsi que sur la pile de développement. De plus, il existe des tutoriels pour vous aider à démarrer et à être opérationnel.",
  "page-developers-about-desc-2": "Inspirés par le réseau de développeurs Mozilla (MDN), nous avons pensé qu'Ethereum avait besoin d'un endroit pour héberger du contenu et ressources de qualité pour les développeurs. Comme chez nos amis de Mozilla, tout ici est en open source et disponible pour vous permettre de vous développer et de vous améliorer.",
  "page-developers-account-desc": "Contrats ou personnes sur le réseau",
  "page-developers-accounts-link": "Comptes",
  "page-developers-advanced": "Avancé",
  "page-developers-api-desc": "Utiliser les bibliothèques pour interagir avec les contrats intelligents",
  "page-developers-api-link": "API back-end",
  "page-developers-aria-label": "Menu des développeurs",
  "page-developers-block-desc": "Lots de transactions ajoutés à la blockchain",
  "page-developers-block-explorers-desc": "Votre portail vers les données Ethereum",
  "page-developers-block-explorers-link": "Explorateurs de bloc",
  "page-developers-blocks-link": "Blocs",
  "page-developers-browse-tutorials": "Parcourir les tutoriels",
  "page-developers-choose-stack": "Choisir sa pile",
  "page-developers-contribute": "Contribuer",
  "page-developers-dev-env-desc": "IDE adaptés au développement d'appd",
  "page-developers-dev-env-link": "Environnements de développement",
  "page-developers-discord": "Rejoindre Discord",
  "page-developers-docs-introductions": "Introductions",
  "page-developers-evm-desc": "L'ordinateur qui traite les transactions",
  "page-developers-evm-link": "La machine virtuelle Ethereum (EVM)",
  "page-developers-explore-documentation": "Explorer la documentation",
  "page-developers-feedback": "Si vous avez des commentaires, contactez-nous via un ticket GitHub ou notre serveur Discord.",
  "page-developers-frameworks-desc": "Outils pour accélérer le développement",
  "page-developers-frameworks-link": "Frameworks de développement",
  "page-developers-fundamentals": "Fondamentaux",
  "page-developers-gas-desc": "Ethers nécessaires pour alimenter les transactions",
  "page-developers-gas-link": "Carburant",
  "page-developers-get-started": "Comment souhaitez-vous commencer ?",
  "page-developers-improve-ethereum": "Aidez-nous à améliorer ethereum.org",
  "page-developers-improve-ethereum-desc": "Comme ethereum.org, ces documents sont des contributions de la communauté. Créez une Pull Request (PR) si vous voyez des erreurs, des possibilités d'amélioration ou de nouvelles opportunités d'aider les développeurs Ethereum.",
  "page-developers-into-eth-desc": "Introduction à la blockchain et à Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Introduction aux applications décentralisées",
  "page-developers-intro-dapps-link": "Introduction aux appd",
  "page-developers-intro-eth-link": "Introduction à Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Introduction à la pile",
  "page-developers-intro-stack-desc": "Introduction à la pile Ethereum",
  "page-developers-js-libraries-desc": "Utiliser JavaScript pour interagir avec les contrats intelligents",
  "page-developers-js-libraries-link": "Bibliothèques JavaScript",
  "page-developers-language-desc": "Utiliser Ethereum avec des langages courants",
  "page-developers-languages": "Langages de programmation",
  "page-developers-learn": "Apprendre le développement Ethereum",
  "page-developers-learn-desc": "Découvrez les concepts fondamentaux et la pile Ethereum en lisant nos documents.",
  "page-developers-learn-tutorials": "Apprendre avec les tutoriels",
  "page-developers-learn-tutorials-cta": "Visionner les tutoriels",
  "page-developers-learn-tutorials-desc": "Apprenez le développement Ethereum étape par étape auprès d'utilisateurs expérimentés.",
  "page-developers-meta-desc": "Documentation, tutoriels et outils pour les développeurs Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Comment créer de nouveaux blocs et atteindre un consensus",
  "page-developers-mining-link": "Minage",
  "page-developers-networks-desc": "Présentation du réseau principal et des réseaux de test",
  "page-developers-networks-link": "Réseaux",
  "page-developers-node-clients-desc": "Comment les blocs et les transactions sont vérifiés sur le réseau",
  "page-developers-node-clients-link": " Nœuds et clients",
  "page-developers-oracle-desc": "Récupération de données hors chaîne dans vos contrats intelligents",
  "page-developers-oracles-link": "Oracles",
  "page-developers-play-code": "Jouer avec le code",
  "page-developers-read-docs": "Lire la documentation",
  "page-developers-scaling-desc": "Solutions pour des transactions plus rapides",
  "page-developers-scaling-link": "Mise à l'échelle",
  "page-developers-smart-contract-security-desc": "Mesures de sécurité à prendre en compte pendant le développement",
  "page-developers-smart-contract-security-link": "Sécurité",
  "page-developers-set-up": "Configurer un environnement local",
  "page-developers-setup-desc": "Préparez votre pile en configurant un environnement de développement.",
  "page-developers-smart-contracts-desc": "La logique derrière les appd : des accords auto-exécutants",
  "page-developers-smart-contracts-link": "Contrats intelligents",
  "page-developers-stack": "La pile",
  "page-developers-start": "Commencer à expérimenter",
  "page-developers-start-desc": "Vous voulez commencer par expérimenter, et poser des questions plus tard ?",
  "page-developers-storage-desc": "Comment gérer le stockage d'appd",
  "page-developers-storage-link": "Stockage",
  "page-developers-subtitle": "Un manuel de développement pour Ethereum. Par les développeurs, pour les développeurs.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "développeur",
  "page-developers-title-3": "ressources",
  "page-developers-token-standards-desc": "Présentation des normes de jetons acceptées",
  "page-developers-token-standards-link": "Normes de jetons",
  "page-developers-transactions-desc": "Comment l'état d'Ethereum change",
  "page-developers-transactions-link": "Transactions",
  "page-developers-web3-desc": "Différences du monde de développement Web3",
  "page-developers-web3-link": "Web2 vs Web3",
  "page-learning-tools-bootcamps": "Camps de démarrage des développeurs",
  "page-learning-tools-bootcamps-desc": "Des cours en ligne payants pour vous permettre de gagner en vitesse, rapidement.",
  "page-learning-tools-browse-docs": "Parcourir la documentation",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Logo ChainShot",
  "page-learning-tools-coding": "Apprendre en codant",
  "page-learning-tools-coding-subtitle": "Ces outils vous aideront à vous familiariser avec Ethereum si vous préférez une expérience d'apprentissage plus interactive.",
  "page-learning-tools-consensys-academy-description": "Camp de démarrage des développeurs Ethereum en ligne.",
  "page-learning-tools-consensys-academy-logo-alt": "Logo ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Apprenez le langage Solidity en construisant votre propre jeu de zombie.",
  "page-learning-tools-cryptozombies-logo-alt": "Logo CryptoZombies",
  "page-learning-tools-documentation": "Apprendre avec la documentation",
  "page-learning-tools-documentation-desc": "Vous voulez en savoir plus ? Explorez notre documentation pour trouver l'explication dont vous avez besoin.",
  "page-learning-tools-eth-dot-build-description": "Un espace de test éducatif pour Web3, incluant la programmation par glisser-déposer et les blocs de construction open source.",
  "page-learning-tools-eth-dot-build-logo-alt": "Logo Eth.build",
  "page-learning-tools-ethernauts-description": "Terminez les niveaux en piratant des contrats intelligents.",
  "page-learning-tools-ethernauts-logo-alt": "Logo Ethereum",
  "page-learning-tools-game-tutorials": "Tutoriels interactifs",
  "page-learning-tools-game-tutorials-desc": "Apprenez en jouant. Ces tutoriels vous permettent de parcourir les bases de façon ludique.",
  "page-learning-tools-meta-desc": "Outils de codage en ligne et expériences d'apprentissage interactif pour vous aider à faire des expériences avec le développement d'Ethereum.",
  "page-learning-tools-meta-title": "Outils d'apprentissage pour les développeurs",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Développez, déployez et administrez des contrats intelligents pour Ethereum. Suivez des tutoriels avec le plugin Learneth.",
  "page-learning-tools-remix-description-2": "Remix n'est pas seulement un espace de test. De nombreux développeurs écrivent, compilent et déploient leurs contrats intelligents en utilisant Remix.",
  "page-learning-tools-remix-logo-alt": "Logo Remix",
  "page-learning-tools-sandbox": "Boîtes à sable de code",
  "page-learning-tools-sandbox-desc": "Ces espaces de test vous permettront de faire des expériences avec la rédaction de contrats intelligents et de mieux comprendre d'Ethereum.",
  "page-learning-tools-studio-description": "Un IDE basé sur le Web où vous pouvez suivre des tutoriels pour créer et tester des contrats intelligents, et construire un frontend pour eux.",
  "page-learning-tools-vyperfun-description": "Apprenez à développer en Vyper en construisant votre propre jeu de Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Logo Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Une structure de développement et de test basée sur Python pour les contrats intelligents ciblant la machine virtuelle Ethereum.",
  "page-local-environment-brownie-logo-alt": "Logo Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "La plateforme de développement tout-en-un pour la construction et le déploiement d'applications décentralisées.",
  "page-local-environment-embark-logo-alt": "Logo Embark",
  "page-local-environment-epirus-desc": "Une plateforme pour développer, déployer et surveiller des applications pour la blockchain sur la machine virtuelle Java",
  "page-local-environment-epirus-logo-alt": "Logo Epirus",
  "page-local-environment-eth-app-desc": "Créez des applications sur Ethereum avec une seule commande, qui fournit une offre diversifiée de frameworks d'interface utilisateur et de modèles de finance décentralisée (DeFi).",
  "page-local-environment-eth-app-logo-alt": "Logo Créer une application Eth",
  "page-local-environment-framework-feature-1": "Fonctionnalités pour faire tourner une instance locale de la blockchain.",
  "page-local-environment-framework-feature-2": "Des utilitaires pour compiler et tester vos contrats intelligents.",
  "page-local-environment-framework-feature-3": "Des modules de développement client pour construire votre application orientée utilisateur au sein du même projet / référentiel.",
  "page-local-environment-framework-feature-4": "Configuration pour se connecter aux réseaux Ethereum et déployer des contrats, que ce soit sur une instance exécutée localement ou sur l'un des réseaux publics d'Ethereum.",
  "page-local-environment-framework-feature-5": "Distribution d'applications décentralisée - intégrations avec des options de stockage comme IPFS.",
  "page-local-environment-framework-features": "Ces frameworks sont livrés avec de nombreuses fonctionnalités prêtes à l'emploi, telles que :",
  "page-local-environment-frameworks-desc": " Nous vous recommandons de choisir un framework, en particulier si vous êtes encore débutant. Construire une DApp à part entière nécessite différentes technologies. Les frameworks incluent de nombreuses fonctionnalités nécessaires ou fournissent des systèmes de plugins faciles pour choisir les outils que vous désirez.",
  "page-local-environment-frameworks-title": "Framework et piles préfabriquées",
  "page-local-environment-hardhat-desc": "Hardhat est un environnement de développement Ethereum pour les professionnels.",
  "page-local-environment-hardhat-logo-alt": "Logo Hardhat",
  "page-local-environment-openZeppelin-desc": "Épargnez-vous de longues heures de développement en compilant, en mettant à niveau, en déployant et en interagissant avec des contrats intelligents par le biais de notre Interface de ligne de commande.",
  "page-local-environment-openZeppelin-logo-alt": "Logo OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App: tout ce dont vous avez besoin pour commencer à créer des applications décentralisées alimentées par des contrats intelligents",
  "page-local-environment-scaffold-eth-logo-alt": "logo scaffold-eth",
  "page-local-environment-setup-meta-desc": "Guide sur comment choisir votre pile logicielle pour le développement Ethereum.",
  "page-local-environment-setup-meta-title": "Configuration du développement local Ethereum",
  "page-local-environment-setup-subtitle": "Si vous êtes prêt à commencer à construire, il est temps de choisir votre pile.",
  "page-local-environment-setup-subtitle-2": " Voici les outils et les frameworks que vous pouvez utiliser pour vous aider à créer votre application Ethereum.",
  "page-local-environment-setup-title": "Configurez votre environnement de développement local",
  "page-local-environment-solidity-template-desc": "Un modèle GitHub pour une configuration prédéfinie pour vos contrats intelligents Solidity. Comprend un réseau local Hardhat, Waffle pour les tests, Ethers pour l'implémentation de portefeuille, et plus encore.",
  "page-local-environment-solidity-template-logo-alt": "Logo de modèle Solidity",
  "page-local-environment-truffle-desc": "La suite Truffle permet aux développeurs de passer de l'idée à l'application le plus confortablement possible.",
  "page-local-environment-truffle-logo-alt": "Logo Truffle",
  "page-local-environment-waffle-desc": "La bibliothèque de test la plus avancée pour les contrats intelligents. Utiliser seul ou avec Scaffold-eth ou Hardhat.",
  "page-local-environment-waffle-logo-alt": "Logo Waffle",
  "comp-tutorial-metadata-minute-read": "minutes de lecture",
  "page-tutorial-listing-policy-intro": "Avant de soumettre un tutoriel, prenez le temps de lire notre politique d'inscription.",
  "comp-tutorial-metadata-tip-author": "Astuce de l'auteur",
  "page-tutorial-listing-policy": "politique de liste d'articles",
  "page-tutorial-new-github": "Nouveau sur GitHub ?",
  "page-tutorial-new-github-desc": "Soulever un problème –Remplissez simplement les informations demandées et collez votre tutoriel.",
  "page-tutorial-pull-request": "Créer une demande d'extraction (pull request)",
  "page-tutorial-pull-request-btn": "Créer une demande d'extraction",
  "page-tutorial-pull-request-desc-1": "Veuillez suivre la nomenclature",
  "page-tutorial-pull-request-desc-2": "tutoriels/nom-de-votre-tutoriel/index.md",
  "page-tutorial-pull-request-desc-3": ".",
  "page-tutorial-raise-issue-btn": "Soulever un problème",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Soumettre un tutoriel",
  "page-tutorial-submit-tutorial": "Pour soumettre un tutoriel, vous devrez utiliser GitHub. Nous vous invitons à créer un ticket ou une demande d'extraction.",
  "page-tutorial-subtitle": "Bienvenue dans notre liste organisée de tutoriels de la communauté.",
  "page-tutorial-tags-error": "Aucun tutoriel ne contient tous ces tags",
  "page-tutorial-title": "Tutoriels de développement Ethereum",
  "page-tutorials-meta-description": "Naviguez et filtrez les tutoriels de la communauté Ethereum par sujet.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Tutoriels de développement Ethereum",
  "page-eth-buy-some": "Vous souhaitez acheter de l'Ethereum ?",
  "page-eth-buy-some-desc": "Il est courant de confondre Ethereum et ETH. Ethereum désigne la blockchain tandis qu’ETH design le jeton qui lui est associé. Vous cherchez probablement à acheter de l’ETH.",
  "page-eth-cat-img-alt": "Graphique du glyphe ETH avec un kaléidoscope de chats",
  "page-eth-collectible-tokens": "Tokens de collection",
  "page-eth-collectible-tokens-desc": "Tokens qui représentent un item de jeu collectionnable, une oeuvre-d'art numérique ou d'autres actifs uniques. Généralement connus sous le nom de jetons non-fongibles (NFT en anglais).",
  "page-eth-cryptography": "Sécurisé par la cryptographie",
  "page-eth-cryptography-desc": "La monnaie virtuelle est peut-être nouvelle, mais elle est sécurisée par la cryptographie. Cela sécurise votre portefeuille, vos ETH, et vos transactions. ",
  "page-eth-currency-for-apps": "C'est la monnaie des applications Ethereum.",
  "page-eth-currency-for-future": "La monnaie de notre avenir numérique",
  "page-eth-description": "ETH est une cryptomonnaie. C'est une monnaie numérique que vous pouvez utiliser sur internet - comme le Bitcoin. Si vous êtes nouveau dans la crypto, voici pourquoi l'ETH est différent de la monnaie traditionnelle.",
  "page-eth-earn-interest-link": "Gagnez des intérêts",
  "page-eth-ethhub-caption": "Mis à jour régulièrement",
  "page-eth-ethhub-overview": "EthHub a un excellent aperçu si vous le souhaitez",
  "page-eth-flexible-amounts": "Une monnaie divisible",
  "page-eth-flexible-amounts-desc": "ETH peut être divisé jusqu’à atteindre 18 décimales, vous n’avez donc pas besoin d’acheter un ETH en entier. Vous pouvez en acheter seulement une fraction - et même jusqu'à 0,000000000000000001 ETH si vous le souhaitez.",
  "page-eth-fuels": "ETH alimente et sécurise Ethereum",
  "page-eth-fuels-desc": "ETH est le cœur d'Ethereum. Lorsque vous envoyez des ETH ou que vous utilisez une appli Ethereum, vous payerez un petit supplément à ETH pour utiliser le réseau Ethereum. Ces frais incitent les mineurs à traiter et à vérifier ce que vous tentez de faire.",
  "page-eth-fuels-desc-2": "Les mineurs sont comme les gardiens d'Ethereum - ils vérifient et prouvent que personne ne triche. Les mineurs qui font ce travail sont également récompensés par de petites quantités d'ETH nouvellement émises.",
  "page-eth-fuels-desc-3": "Les mineurs permettent de garder Ethereum sécurisé et sans contrôle centralisé. En d'autres termes,",
  "page-eth-fuels-more-staking": "Plus d'infos sur la mise en jeu",
  "page-eth-fuels-staking": "ETH deviendra encore plus important grâce à la mise en jeu. Quand vous mettez vos ETH en jeu, vous permettez d'aider à sécuriser Ethereum et vous gagnez des récompenses. Dans ce système, la menace de perdre vos ETH décourage les attaques.",
  "page-eth-get-eth-btn": "Obtenir de l'ETH",
  "page-eth-gov-tokens": "Tokens de gouvernance",
  "page-eth-gov-tokens-desc": "Tokens qui représentent le pouvoir de vote dans les organisations décentralisées.",
  "page-eth-has-value": "Pourquoi l'ETH a-t-il de la valeur ?",
  "page-eth-has-value-desc": "La valeur des ETH's est différente selon qui vous êtes.",
  "page-eth-has-value-desc-2": "Pour les utilisateurs d'Ethereum, ETH a de la valeur car vous payez des frais de transactions.",
  "page-eth-has-value-desc-3": "D'autres le voient comme un marché de valeur numérique car la création de nouveaux ETH ralentit au fil du temps.",
  "page-eth-has-value-desc-4": "Plus récemment, ETH a acquis une grande valeur pour les utilisateurs d'applis financières sur Ethereum, car il est possible d'utiliser ETH comme garantie pour des prêts de cryptomonnaies ou comme système de paiement.",
  "page-eth-has-value-desc-5": "Bien sûr, beaucoup y voient aussi un investissement, comme le Bitcoin ou d'autres cryptomonnaies.",
  "page-eth-how-to-buy": "Comment acheter de l'Ether",
  "page-eth-how-to-buy-caption": "Mis à jour régulièrement",
  "page-eth-is-money": "L'ETH est une monnaie numérique mondiale.",
  "page-eth-last-updated": "Janvier 2019",
  "page-eth-mining-link": "En savoir plus sur le minage",
  "page-eth-monetary-policy": "Politique monétaire d'Ethereum",
  "page-eth-more-on-ethereum-link": "En savoir plus sur Ethereum",
  "page-eth-no-centralized": "Aucun contrôle centralisé ",
  "page-eth-no-centralized-desc": "ETH est décentralisé et global. Il n'y a aucune société ou banque qui puissent décider d'imprimer plus d'ETH, ou de modifier les conditions d'utilisation.",
  "page-eth-non-fungible-tokens-link": "Tokens non-fongibles",
  "page-eth-not-only-crypto": "ETH n'est pas la seule crypto sur Ethereum",
  "page-eth-not-only-crypto-desc": "N'importe qui peut créer de nouveaux types d'actifs et les échanger sur Ethereum. Ils sont connus sous le nom de \"Tokens\". Les gens ont tokenisés les monnaies traditionnelles, leur bien immobilier, leur art, et même eux-mêmes !",
  "page-eth-not-only-crypto-desc-2": "Ethereum abrite des milliers de jetons - certains plus utiles et précieux que d'autres. Les développeurs sont constamment en train de créer de nouveaux jetons qui offrent de nouvelles possibilités et ouvrent de nouveaux marchés.",
  "page-eth-not-only-crypto-desc-3": "Si vous souhaitez en apprendre davantage sur les jetons, nos amis EthHub ont écrit quelques excellentes documentations :",
  "page-eth-open": "Ouvert à tous",
  "page-eth-open-desc": "Tout ce dont vous avez besoin est d'une connexion internet et d'un portefeuille pour recevoir les ETH. Il n'est pas du tout nécessaire d'avoir un compte bancaire pour accepter les paiements. ",
  "page-eth-p2p-payments": "Paiements P2P",
  "page-eth-p2p-payments-desc": "Vous pouvez envoyer vos ETH sans aucun service intermédiaire comme une banque. C'est comme donner de l'argent en personne, mais vous pouvez le faire en toute sécurité avec n'importe qui, n'importe où, n'importe quand.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Types de jeton populaires",
  "page-eth-powers-ethereum": "ETH alimente Ethereum",
  "page-eth-shit-coins": "Sh*t coins",
  "page-eth-shit-coins-desc": "Parce que faire de nouveaux jetons est facile, n'importe qui peut le faire - même les personnes avec de mauvaises intentions. Faites toujours vos propres recherches avant de les utiliser !",
  "page-eth-stablecoins": "Stablecoins",
  "page-eth-stablecoins-desc": "Tokens qui reflètent la valeur des monnaies traditionnelles comme le dollar. Cela empêche la volatilité qu'ont beaucoup de cryptomonnaies.",
  "page-eth-stablecoins-link": "Obtenir des stablecoins",
  "page-eth-stream-link": "Streaming d'ETH",
  "page-eth-tokens-link": "Tokens d'Ethereum",
  "page-eth-trade-link-2": "Échanger des jetons",
  "page-eth-underpins": "ETH est à la base du système financier d'Ethereum",
  "page-eth-underpins-desc": "Pas satisfait des paiements, la communauté Ethereum construit un système financier complet qui fonctionne en P2P et accessible à tous.",
  "page-eth-underpins-desc-2": "Vous pouvez utiliser ETH comme garantie pour générer des jetons de cryptomonnaie entièrement différents sur Ethereum. De plus, vous pouvez emprunter, prêter et gagner des intérêts sur ETH et d'autres jetons adossés à l'ETH.",
  "page-eth-uses": "Il y a de nouvelles utilisations de l'ETH tous les jours",
  "page-eth-uses-desc": "Étant donné que Ethereum est programmable, les développeurs peuvent façonner ETH de nombreuses façons.",
  "page-eth-uses-desc-2": "En 2015, tout ce que vous pouviez faire était d'envoyer des ETH d'un compte Ethereum à un autre. Voici quelques exemples de ce qu'il est maintenant possible de faire.",
  "page-eth-uses-desc-3": "payer une personne ou recevoir des fonds en temps réel.",
  "page-eth-uses-desc-4": "vous pouvez échanger des ETH avec d'autres jetons, y compris des Bitcoin.",
  "page-eth-uses-desc-5": "sur ETH et autres jetons basés sur Ethereum.",
  "page-eth-uses-desc-6": "accédez au monde des cryptomonnaies avec une valeur stable et moins volatile.",
  "page-eth-value": "Pourquoi l'éther est précieux",
  "page-eth-video-alt": "Vidéo de glyphe ETH",
  "page-eth-whats-eth": "Qu'est-ce que l'ether (ETH) ?",
  "page-eth-whats-eth-hero-alt": "Illustration représentant un groupe de personnes émerveillées devant un glyphe d'éther (ETH)",
  "page-eth-whats-eth-meta-desc": "Ce que vous devez savoir pour comprendre ETH et sa place dans Ethereum.",
  "page-eth-whats-eth-meta-title": "Qu'est-ce que l'ether (ETH) ?",
  "page-eth-whats-ethereum": "Qu'est-ce qu'Ethereum ?",
  "page-eth-whats-ethereum-desc": "Si vous souhaitez en savoir plus sur Ethereum, la technologie derrière l'ETH, jetez un œil à notre introduction.",
  "page-eth-whats-unique": "Pourquoi l'ETH est-il unique ?",
  "page-eth-whats-unique-desc": "Il existe de nombreuses cryptomonnaies et beaucoup d'autres jetons sur Ethereum, mais il y a certaines choses que seul ETH peut faire.",
  "page-eth-where-to-buy": "Où obtenir des ETH",
  "page-eth-where-to-buy-desc": "Vous pouvez obtenir de l'ETH à partir d'un échange ou d'un portefeuille, mais différents pays ont des politiques différentes. Vérifiez quels services vous permettront d'acheter ETH.",
  "page-eth-yours": "C'est vraiment à vous",
  "page-eth-yours-desc": "ETH vous permet d'être votre propre banque. Vous pouvez contrôler vos propres fonds avec votre portefeuille comme preuve de propriété, aucun tiers de confiance n'est nécessaire.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Adresse du contrat du dépôt Eth2",
  "page-eth2-deposit-contract-address-caption": "Nous avons ajouté des espaces pour rendre l'adresse plus facile à lire",
  "page-eth2-deposit-contract-address-check-btn": "Vérifier l'adresse de dépôt du contrat",
  "page-eth2-deposit-contract-checkbox1": "J’ai déjà utilisé le launchpad pour configurer mon validateur Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Je comprends que j’ai besoin d’utiliser le launchpad pour staker. Les transferts simples à cette adresse ne fonctionneront pas.",
  "page-eth2-deposit-contract-checkbox3": "Je vais vérifier l'adresse du contrat de dépôt avec d'autres sources.",
  "page-eth2-deposit-contract-confirm-address": "Confirmer pour révéler l'adresse",
  "page-eth2-deposit-contract-copied": "Adresse copiée",
  "page-eth2-deposit-contract-copy": "Copier l'adresse",
  "page-eth2-deposit-contract-etherscan": "Voir le contrat sur Etherscan",
  "page-eth2-deposit-contract-h2": "Ce n'est pas ici qu'il faut staker",
  "page-eth2-deposit-contract-launchpad": "Staker en utilisant le launchpad",
  "page-eth2-deposit-contract-launchpad-2": "Utiliser le launchpad",
  "page-eth2-deposit-contract-meta-desc": "Vérifiez l'adresse du contrat de dépôt pour la mise en jeu Eth2.",
  "page-eth2-deposit-contract-meta-title": "Adresse du contrat du dépôt Eth2",
  "page-eth2-deposit-contract-read-aloud": "Lire l'adresse à haute voix",
  "page-eth2-deposit-contract-reveal-address-btn": "Afficher l'adresse",
  "page-eth2-deposit-contract-staking": " Pour mettre en jeu (stake) votre ETH dans Eth2, vous devez utiliser le launchpad dédié et suivre les instructions. L'envoi d'ETH à l'adresse sur cette page ne fera pas de vous un validateur (staker) et entraînera l'échec de la transaction.",
  "page-eth2-deposit-contract-staking-check": "Consultez ces sources",
  "page-eth2-deposit-contract-staking-check-desc": "Nous nous attendons à ce qu'il y ait beaucoup de fausses adresses et d'escroqueries. Par mesure de sécurité, vérifiez toujours l'adresse de mise en jeu Eth2 que vous utilisez en la comparant à l'adresse sur cette page. Nous vous recommandons de la vérifier avec d'autres sources fiables.",
  "page-eth2-deposit-contract-staking-more-link": "Plus d'infos sur la mise en jeu",
  "page-eth2-deposit-contract-stop-reading": "Arrêter la lecture",
  "page-eth2-deposit-contract-subtitle": " Ceci est l'adresse du contrat de mise en jeu Eth2. Utilisez cette page pour confirmer que vous envoyez des fonds à la bonne adresse lorsque vous mettez en jeu vos ETH.",
  "page-eth2-deposit-contract-warning": "Vérifier soigneusement chaque caractère.",
  "page-eth2-deposit-contract-warning-2": "L'envoi de fonds à cette adresse ne fonctionnera pas et vous ne deviendrez pas un validateur (staker). Vous devez suivre les instructions du launchpad.",
  "page-eth2-bug-bounty-annotated-specs": "spécification annotée",
  "page-eth2-bug-bounty-annotations": "Il peut être utile de vérifier les annotations suivantes :",
  "page-eth2-bug-bounty-client-bugs": "Bogues du client Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Les clients exécuteront la chaîne phare une fois la mise à niveau déployée. Les clients devront suivre la logique décrite dans la spécification et être sécurisés contre les attaques potentielles. Les bogues que nous voulons trouver sont liés à l'implémentation du protocole.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Actuellement, les bogues de Lighthouse, Nimbus, Teku et Prysm sont éligibles pour cette prime. D'autres clients peuvent être ajoutés au fur et à mesure qu'ils terminent les audits et deviennent prêts pour la production.",
  "page-eth2-bug-bounty-clients": "Fonctionnalités \"clients\" donnant droit à récompense",
  "page-eth2-bug-bounty-clients-type-1": "spécification de problèmes de non conformité.",
  "page-eth2-bug-bounty-clients-type-2": "des crashes inattendus ou des failles de déni de service (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "tout problème causant un consensus irréparable se sépare du reste du réseau.",
  "page-eth2-bug-bounty-docking": "arrimage",
  "page-eth2-bug-bounty-email-us": "Envoyez-nous un courriel :",
  "page-eth2-bug-bounty-help-links": "Liens utiles",
  "page-eth2-bug-bounty-hunting": "Règles pour la chasse aux bogues",
  "page-eth2-bug-bounty-hunting-desc": "Le programme de récompenses des bogues est expérimental et discrétionnaire, et est destiné à notre communauté active afin d'encourager et récompenser ceux qui aident à l'amélioration de la plateforme. Ce n'est pas une compétition. Vous devez savoir que nous pouvons annuler le programme n'importe quand, et les récompenses sont attribuées à la discrétion du comité du programme de récompenses des bogues de l'Ethereum Foundation. De plus, nous ne pourrons pas attribuer de récompenses aux personnes qui sont sur la liste des personnes bannies ou faisant partie de pays étant sur la liste de pays bannis.(ex Corée du Nord, Iran, etc.). Vous êtes responsables pour les implications fiscales. Toutes les récompenses sont assujetties aux lois applicables. En conclusion, vos évaluations ne doivent pas violer de lois ou compromettre des données qui ne vous appartiennent pas.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Classement de chasse aux bogues",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Trouvez les bogues Eth2 à ajouter à ce classement",
  "page-eth2-bug-bounty-hunting-li-1": "Les problèmes qui ont déjà été soumis par un autre utilisateur ou qui sont déjà connus par les responsables des spécifications et du client ne sont pas éligibles pour des primes de récompenses.",
  "page-eth2-bug-bounty-hunting-li-2": "La divulgation publique d'une vulnérabilité la rend inadmissible à une récompense.",
  "page-eth2-bug-bounty-hunting-li-3": "Les chercheurs de l'Ethereum Foundation et les employés des équipes clientes d'Eth2 ne sont pas éligibles à une récompense.",
  "page-eth2-bug-bounty-hunting-li-4": "Le programme de récompenses d'Ethereum prend en compte plusieurs facteurs pour l'attribution des récompenses. Les conditions pour l'éligibilité, le pointage ainsi que les termes en lien avec l'attribution d'une récompense sont à la seule et entière discrétion du comité du programme de récompense de l'Ethereum Foundation.",
  "page-eth2-bug-bounty-leaderboard": "Voir le classement complet",
  "page-eth2-bug-bounty-leaderboard-points": "points",
  "page-eth2-bug-bounty-ledger-desc": "Les spécifications de la chaîne phare (la chaîne phare) détaillent la justification de la conception et les changements proposés pour Ehtereum via la mise à niveau de la chaîne phare (la chaîne phare).",
  "page-eth2-bug-bounty-ledger-title": "Les bogues de spécification de la chaîne phare",
  "page-eth2-bug-bounty-meta-description": "Présentation du programme de chasse aux bogues Eth2 : comment s'impliquer et récompenser les informations.",
  "page-eth2-bug-bounty-meta-title": "Programme de récompense de chasse aux bogues Eth2",
  "page-eth2-bug-bounty-not-included": "Non inclus",
  "page-eth2-bug-bounty-not-included-desc": "Les mises à niveau de la chaîne de fragments et de l'arrimage sont toujours en cours de développement et ne sont pas encore incluses dans ce programme de récompenses.",
  "page-eth2-bug-bounty-owasp": "Voir la méthode OWASP",
  "page-eth2-bug-bounty-points": "L'EF attribuera également des points sur la base de :",
  "page-eth2-bug-bounty-points-error": "Erreur lors du chargement des données... Veuillez actualiser.",
  "page-eth2-bug-bounty-points-exchange": "Echanger des points",
  "page-eth2-bug-bounty-points-loading": "Chargement des données en cours...",
  "page-eth2-bug-bounty-points-payout-desc": "L'Ethereum Foundation versera la valeur en USD convertie en cryptomonnaie ETH ou DAI.",
  "page-eth2-bug-bounty-points-point": "1 point",
  "page-eth2-bug-bounty-points-rights-desc": "L'Ethereum Foundation se réserve le droit de modifier ces conditions sans préavis.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Qualité de la description",
  "page-eth2-bug-bounty-quality-desc": ": Des récompenses plus élevées sont payées pour les soumissions claires et bien écrites.",
  "page-eth2-bug-bounty-quality-fix": "Qualité de la correction",
  "page-eth2-bug-bounty-quality-fix-desc": ", si inclus : Des récompenses plus élevées sont payées pour les soumissions décrivant clairement la façon de résoudre le problème.",
  "page-eth2-bug-bounty-quality-repro": "Qualité de reproductibilité",
  "page-eth2-bug-bounty-quality-repro-desc": ": Veuillez inclure le code de test, les scripts et les instructions détaillées. Plus il est facile pour nous de reproduire et de vérifier la vulnérabilité, plus la récompense est élevée.",
  "page-eth2-bug-bounty-questions": "Des questions ?",
  "page-eth2-bug-bounty-rules": "Lire le règlement",
  "page-eth2-bug-bounty-shard-chains": "chaînes de fragments",
  "page-eth2-bug-bounty-slogan": "Primes de bogues Eth2",
  "page-eth2-bug-bounty-specs": "Lire toutes les spécifications",
  "page-eth2-bug-bounty-specs-docs": "Documents de spécification",
  "page-eth2-bug-bounty-submit": "Soumettre un bogue",
  "page-eth2-bug-bounty-submit-desc": "Pour chaque bogue que vous trouverez vous recevrez des points. Les points que vous gagnez dépendent de la gravité du bogue. L'Ethereum Foundation (EF) détermine la sévérité à l'aide de la méthodologie OWASP.",
  "page-eth2-bug-bounty-subtitle": "Gagnez jusqu'à 50 000 $ US et une place dans le classement en trouvant des bogues du protocole Eth2 et du client.",
  "page-eth2-bug-bounty-title": "Ouvert pour soumissions",
  "page-eth2-bug-bounty-title-1": "Chaîne phare",
  "page-eth2-bug-bounty-title-2": "Choix de bifurcation",
  "page-eth2-bug-bounty-title-3": "Contrat de dépôt Solidity",
  "page-eth2-bug-bounty-title-4": "Réseau P2P",
  "page-eth2-bug-bounty-type-1": "bogues de sécurité/détournant la finalité.",
  "page-eth2-bug-bounty-type-2": "vecteurs de déni de service (DOS)",
  "page-eth2-bug-bounty-type-3": "les incohérences dans les hypothèses, comme les situations où des validateurs honnêtes peuvent être coupés.",
  "page-eth2-bug-bounty-type-4": "incohérences de calcul ou de paramètre.",
  "page-eth2-bug-bounty-types": "Types de bogues",
  "page-eth2-bug-bounty-validity": "Bogues valides",
  "page-eth2-bug-bounty-validity-desc": "Ce programme de chasse aux bogues est axé sur la recherche de bogues dans la spécifications principales de la chaîne phare Eth2 et dans les implémentations du client Prysm, Lighthouse et Teku.",
  "page-eth2-bug-bountycard-critical": "Critique",
  "page-eth2-bug-bountycard-critical-risk": "Soumettre un bogue à risque critique",
  "page-eth2-bug-bountycard-h2": "Modéré",
  "page-eth2-bug-bountycard-high": "Élevé",
  "page-eth2-bug-bountycard-high-risk": "Soumettre un bogue à risque élevé",
  "page-eth2-bug-bountycard-label-1": "Jusqu'à 1 000 points",
  "page-eth2-bug-bountycard-label-2": "Jusqu'à 2 000 DAI",
  "page-eth2-bug-bountycard-label-3": "Jusqu'à 5 000 points",
  "page-eth2-bug-bountycard-label-4": "Jusqu'à 10 000 DAI",
  "page-eth2-bug-bountycard-label-5": "Jusqu'à 10 000 points",
  "page-eth2-bug-bountycard-label-6": "Jusqu'à 20 000 DAI",
  "page-eth2-bug-bountycard-label-7": "Jusqu'à 25 000 points",
  "page-eth2-bug-bountycard-label-8": "Jusqu'à 50 000 DAI",
  "page-eth2-bug-bountycard-li-1": "Faible impact, probabilité moyenne",
  "page-eth2-bug-bountycard-li-2": "Impact modéré, probabilité faible",
  "page-eth2-bug-bountycard-li-3": "Impact élevé, probabilité faible",
  "page-eth2-bug-bountycard-li-4": "Impact moyen, probabilité moyenne",
  "page-eth2-bug-bountycard-li-5": "Impact faible, probabilité élevée",
  "page-eth2-bug-bountycard-li-6": "Impact élevé, probabilité moyenne",
  "page-eth2-bug-bountycard-li-7": "Impact moyen, probabilité élevée",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Faible",
  "page-eth2-bug-bountycard-low-risk": "Soumettre un bogue à risque faible",
  "page-eth2-bug-bountycard-medium-risk": "Soumettre un bogue à risque modéré",
  "page-eth2-bug-bountycard-subheader": "Gravité",
  "page-eth2-bug-bountycard-subheader-2": "Exemple",
  "page-eth2-bug-bountycard-text": "Les attaquants peuvent parfois mettre un nœud dans un état qui l'amène à ne pas traiter une attestation sur cent faite par un validateur",
  "page-eth2-bug-bountycard-text-1": "L'attaquant peut mener avec succès des attaques éclairs sur des nœuds avec des Ids de pairs commençant par 4 octets zéros",
  "page-eth2-bug-bountycard-text-2": "Il existe un bogue dans le consensus entre 2 clients, mais c'est difficile ou presque impossible pour un attaquant de le déclencher.",
  "page-eth2-bug-bountycard-text-3": "  Il existe un bogue de consensus entre deux clients, et il est facile pour un attaquant de déclencher l'événement.",
  "page-eth2-get-involved-btn-1": "Voir les clients",
  "page-eth2-get-involved-btn-2": "Plus d'infos sur la mise en jeu",
  "page-eth2-get-involved-btn-3": "Trouver des bogues",
  "page-eth2-get-involved-bug": "Un bogue pourrait être :",
  "page-eth2-get-involved-bug-hunting": "Aller à la chasse aux bogues",
  "page-eth2-get-involved-bug-hunting-desc": "Trouvez et signalez des bogues dans les spécifications de mise à niveau Eth2 ou dans les clients eux-mêmes. Vous pouvez gagner jusqu'à 50 000 USD ainsi qu' une place dans le classement.",
  "page-eth2-get-involved-bug-li": "problèmes de non conformité de spécification",
  "page-eth2-get-involved-bug-li-2": "bogues empêchant la finalisation des blocs",
  "page-eth2-get-involved-bug-li-3": "vecteurs de déni de service (DOS)",
  "page-eth2-get-involved-bug-li-4": "et plus encore...",
  "page-eth2-get-involved-date": "Date de clôture : 23 décembre 2020",
  "page-eth2-get-involved-desc-1": "Exécuter un logiciel client signifie que vous serez un participant actif dans Ethereum. Votre logiciel client vous aidera à garder une trace des transactions et à vérifier les nouveaux blocs.",
  "page-eth2-get-involved-desc-2": "Si vous avez des ETH, vous pouvez le mettre en jeu pour devenir un validateur et aider à sécuriser le réseau. En tant que validateur, vous pouvez gagner des récompenses en ETH.",
  "page-eth2-get-involved-desc-3": "Rejoignez la communauté des testeurs ! Aidez à tester les mises à niveau Eth2 avant qu'elles ne soient expédiées, trouvez des bogues et gagnez des récompenses.",
  "page-eth2-get-involved-ethresearch-1": "Fragmentation",
  "page-eth2-get-involved-ethresearch-2": "Transition de Eth1 à Eth2",
  "page-eth2-get-involved-ethresearch-3": "Fragments et exécution d'état",
  "page-eth2-get-involved-ethresearch-4": "Tous les sujets de recherche",
  "page-eth2-get-involved-grants": "Subventions de la communauté des miseurs",
  "page-eth2-get-involved-grants-desc": "Aidez à créer des outils et du contenu éducatif pour la communauté des miseurs",
  "page-eth2-get-involved-how": "Comment voulez-vous vous impliquer ?",
  "page-eth2-get-involved-how-desc": "La communauté Ethereum bénéficie toujours de l'arrivée de nouvelles personnes qui exécutent des logiciels clients, misent et chassent les bogues.",
  "page-eth2-get-involved-join": "Rejoignez la recherche",
  "page-eth2-get-involved-join-desc": " Comme souvent avec Ethereum, la plupart des recherches effectuées sont publiques. Cela signifie que vous pouvez prendre part aux discussions ou simplement lire ce que les chercheurs d'Ethereum ont à dire. ethresear.ch couvre un domaine plus large que simplement les mises à niveau Eth2, néanmoins il y a une forte focalisation au sujet de l'Eth2.",
  "page-eth2-get-involved-meta-description": "Comment participer à l'Eth2 : opérer des nœuds, mettez en jeu en stakant, débusquer les bogues et plus.",
  "page-eth2-get-involved-more": "Plus d'infos",
  "page-eth2-get-involved-run-clients": "Opérez des logiciels clients de la chaîne phare",
  "page-eth2-get-involved-run-clients-desc": "La clé de la sécurité à long terme d'Ethereum est une distribution solide des logiciels clients. Un logiciel client est un logiciel qui opère la blockchain, vérifie les transactions et crée de nouveaux blocs. Chaque client a ses propres caractéristiques, alors choisissez en fonction de ce qui vous convient le mieux.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Mettre en jeu vos ETH",
  "page-eth2-get-involved-stake-desc": "Vous pouvez dès à présent miser vos ETH pour contribuer à la sécurisation de la chaîne phare.",
  "page-eth2-get-involved-stake-eth": "Mettre en jeu de l'ETH",
  "page-eth2-get-involved-subtitle": "Voici toutes les façons dont vous pouvez aider Ethereum et les futurs efforts liés à Eth2.",
  "page-eth2-get-involved-title-1": "Exécuter un client",
  "page-eth2-get-involved-title-2": "Mettre en jeu vos ETH",
  "page-eth2-get-involved-title-3": "Trouver des bogues",
  "page-eth2-get-involved-written-go": "Écrit en Go",
  "page-eth2-get-involved-written-java": "Écrit en Java",
  "page-eth2-get-involved-written-javascript": "Écrit en JavaScript",
  "page-eth2-get-involved-written-net": "Écrit en .NET",
  "page-eth2-get-involved-written-nim": "Écrit en Nim",
  "page-eth2-get-involved-written-python": "Écrit en Python",
  "page-eth2-get-involved-written-rust": "Écrit en Rust",
  "eth2-client-cortex-logo-alt": "Logo de Cortex",
  "eth2-client-lighthouse-logo-alt": "Logo Lighthouse",
  "eth2-client-lodestar-logo-alt": "Logo Lodestar",
  "eth2-client-nimbus-logo-alt": "Logo de Nimbus",
  "eth2-client-prysm-logo-alt": "Logo de Prysm",
  "eth2-client-teku-logo-alt": "Logo de Teku",
  "eth2-rhino-img-alt": "Graphique de Rhino Eth 2 chargeant en avant",
  "page-eth2-answer-1": "Pensez à Eth2 comme un ensemble de mises à niveau qui sont ajoutées pour améliorer l'Ethereum que nous utilisons aujourd'hui. Ces améliorations incluent la création d'une nouvelle chaîne appelée chaîne phare ainsi que jusqu'à 64 chaînes connues sous le nom de fragments.",
  "page-eth2-answer-2": "Ceux-ci sont séparés du réseau principal Ethereum que nous utilisons aujourd'hui, mais ne le remplaceront pas. Au lieu de cela, le réseau principal va s’arrimer ou \"fusionner\" avec ce système parallèle qui est ajouté au fil du temps.",
  "page-eth2-answer-4": "En d'autres termes, l'Ethereum que nous utilisons aujourd'hui finira par incarner toutes les fonctionnalités que nous visons dans la vision Eth2.",
  "page-eth2-beacon-chain-btn": "En savoir plus sur la chaîne phare",
  "page-eth2-beacon-chain-date": "La chaîne phare a été mise en service le 1er décembre 2020.",
  "page-eth2-beacon-chain-desc": "Le premier ajout de l'Eth2 à l'écosystème. La chaîne phare intègre la mise en jeu sur Ethereum, pose les bases des futures mises à niveau et coordonnera éventuellement le nouveau système.",
  "page-eth2-beacon-chain-estimate": "La chaîne phare est active",
  "page-eth2-beacon-chain-title": "La chaîne phare",
  "page-eth2-bug-bounty": "Voir le programme de primes au bogue",
  "page-eth2-clients": "Découvrez les clients Eth2",
  "page-eth2-deposit-contract-title": "Vérifiez l'adresse du contrat de dépôt",
  "page-eth2-diagram-ethereum-mainnet": "Le réseau principal d'Ethereum",
  "page-eth2-diagram-h2": "Comment les améliorations s'intègrent",
  "page-eth2-diagram-link-1": "En savoir plus sur la preuve de travail",
  "page-eth2-diagram-link-2": "En savoir plus sur les chaînes de fragments",
  "page-eth2-diagram-mainnet": "Réseau principal",
  "page-eth2-diagram-p": "Le réseau principal d' Ethereum va continuer à exister sous sa forme actuelle pendant encore un certain temps. Cela signifie que les mises à niveau de la chaîne phare et des fragments ne perturberont pas le réseau.",
  "page-eth2-diagram-p-1": "Au final, le réseau principal fusionnera avec le nouveau système introduit par les mises à niveau de l' Eth2.",
  "page-eth2-diagram-p-2": "La chaîne phare guidera Ethereum, coordonnera les validateurs et donnera le rythme pour la création des blocs.",
  "page-eth2-diagram-p-3": "Au début, il existera séparément du réseau principal et gérera les validateurs - cela n'aura rien à voir avec des contrats, des transactions ou des comptes intelligents.",
  "page-eth2-diagram-p-4": "Les fragments fourniront de nombreuses données supplémentaires pour aider à augmenter le nombre de transactions que le réseau principal peut traiter. Ils seront coordonnés par la chaîne phare.",
  "page-eth2-diagram-p-5": "Mais toutes les transactions reposeront toujours sur le réseau principal, qui continuera d'exister tel que nous le connaissons aujourd'hui - sécurisé par des preuves de travail et des mineurs.",
  "page-eth2-diagram-p-6": "Le réseau principal fusionnera avec le système de preuve d'enjeu, coordonné par la chaîne phare.",
  "page-eth2-diagram-p-8": "Cela transformera le réseau principal en un fragment dans le nouveau système. Les mineurs ne seront plus nécessaires car tout Ethereum sera sécurisé par des validateurs.",
  "page-eth2-diagram-p10": "Eth2 n'est pas une simple migration ou amélioration. Il décrit un ensemble de mises à niveau en cours de développement pour libérer le véritable potentiel d'Ethereum. Voilà comment ces mises à niveau se rejoignent toutes.",
  "page-eth2-diagram-scroll": "Faites défiler pour explorer - touchez pour plus d'informations.",
  "page-eth2-diagram-shard": "Fragment (1)",
  "page-eth2-diagram-shard-1": "Fragment (...)",
  "page-eth2-diagram-shard-2": "Fragment (2)",
  "page-eth2-diagram-shard-3": "Fragment (...)",
  "page-eth2-diagram-validators": "En savoir plus sur les validateurs",
  "page-eth2-dive": "Plongez dans la vision",
  "page-eth2-dive-desc": "Comment allons-nous rendre Ethereum plus évolutif, plus sûr et plus durable ? Tout en gardant l'éthique de base de la décentralisation d'Ethereum.",
  "page-eth2-docking": "L'arrimage (docking)",
  "page-eth2-docking-answer-1": "L'arrimage se produit lorsque le réseau principal se transforme en un fragment. Cela viendra après une implémentation réussie des chaînes de fragments.",
  "page-eth2-docking-btn": "En savoir plus sur l'arrimage",
  "page-eth2-docking-desc": "Le réseau principal d'Ethereum devra \"s'arrimer\" ou \"fusionner\" avec la chaîne phare à un moment donné. Cela permettra de jalonner l'ensemble du réseau et de signaler la fin de l'exploitation minière à forte intensité énergétique.",
  "page-eth2-docking-estimate": "Estimation : 2022",
  "page-eth2-docking-mainnet": "Qu'est-ce que le réseau principal ?",
  "page-eth2-docking-mainnet-eth2": "Arrimer le réseau principal dans Eth2",
  "page-eth2-eth-blog": "blog ethereum.org",
  "page-eth2-explore-btn": "Explorez les améliorations",
  "page-eth2-get-involved": "Impliquez-vous dans Eth2",
  "page-eth2-get-involved-2": "S’engager",
  "page-eth2-head-to": "Rendez-vous sur",
  "page-eth2-help": "Vous voulez aider avec Eth2 ?",
  "page-eth2-help-desc": "Il existe de nombreuses opportunités de participer aux mises à niveau d'Eth2, d'aider aux tests et même de gagner des récompenses.",
  "page-eth2-index-staking": "La mise en jeu est ouverte",
  "page-eth2-index-staking-desc": "La clé des mises à niveau d'Eth2 est l'introduction de la mise en jeu. Si vous souhaitez utiliser votre ETH pour sécuriser le réseau Ethereum, assurez-vous de suivre ces étapes.",
  "page-eth2-index-staking-learn": "En savoir plus sur la mise en jeu",
  "page-eth2-index-staking-learn-desc": "La chaîne phare apportera la mise en jeu à Ethereum. Cela signifie que si vous avez des ETH, vous pouvez faire un bien public en sécurisant le réseau et gagner plus d'ETH par la même occasion.",
  "page-eth2-index-staking-step-1": "1. Configuration avec le launchpad",
  "page-eth2-index-staking-step-1-btn": "Visitez le launchpad de mise en jeu",
  "page-eth2-index-staking-step-1-desc": "Pour miser dans Eth2, vous devrez utiliser le launchpad - il vous guidera tout au long du processus.",
  "page-eth2-index-staking-step-2": "2. Confirmer l'adresse de mise en jeu",
  "page-eth2-index-staking-step-2-btn": "Confirmer l'adresse du contrat de dépôt",
  "page-eth2-index-staking-step-2-desc": "Avant de miser vos ETH, vérifiez que vous avez la bonne adresse. Vous devez avoir parcouru le launchpad avant de faire cela.",
  "page-eth2-index-staking-sustainability": "Plus durable",
  "page-eth2-just-docking": "En savoir plus sur l'arrimage",
  "page-eth2-meta-desc": "Un aperçu des mises à niveau d'Ethereum 2.0 et de la vision qu'elles espèrent concrétiser.",
  "page-eth2-meta-title": "Les mises à niveau Eth2",
  "page-eth2-miners": "En savoir plus sur les mineurs",
  "page-eth2-more-on-upgrades": "En savoir plus sur les mises à niveau Eth2",
  "page-eth2-proof-of-stake": "preuve d'enjeu",
  "page-eth2-proof-of-work": "preuve de travail",
  "page-eth2-proof-stake-link": "Plus d'infos sur la preuve d'enjeu",
  "page-eth2-question-1-desc": "Eth2 est un ensemble de mises à niveau distinctes avec des dates d'expédition différentes.",
  "page-eth2-question-1-title": "Quand Eth2 sera-t-il expédié ?",
  "page-eth2-question-2-desc": "On ne peut pas considérer Eth2 comme une blockchain distincte.",
  "page-eth2-question-2-title": "Eth2 est-il une blockchain distincte ?",
  "page-eth2-question-3-answer-2": "Les mises à niveau de la chaîne de fragments et de l'arrimage peuvent avoir un impact sur les développeurs de DApp. Mais les spécifications n'ont pas encore été finalisées, donc aucune action immédiate n'est requise.",
  "page-eth2-question-3-answer-3": "Parlez à l'équipe de recherche et développement Eth2 sur ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Visitez ethresear.ch",
  "page-eth2-question-3-desc": "Vous n'avez rien à faire pour le moment pour vous préparer à Eth2.",
  "page-eth2-question-3-title": "Comment me préparer à Eth2 ?",
  "page-eth2-question-4-answer-1": "Chaque fois que vous envoyez une transaction ou utilisez une DApp aujourd'hui, vous utilisez Eth1. C'est l'Ethereum qui est sécurisé par les mineurs.",
  "page-eth2-question-4-answer-2": "\"Eth1\", le réseau principal ou mainnet, continuera à fonctionner normalement jusqu'à l'arrimage.",
  "page-eth2-question-4-answer-3": "Après l'arrimage, les validateurs sécuriseront l'ensemble du réseau via une preuve d'enjeu.",
  "page-eth2-question-4-answer-6": "N'importe qui peut devenir validateur en mettant en jeu son ETH.",
  "page-eth2-question-4-answer-7": "Plus d'infos sur la mise en jeu",
  "page-eth2-question-4-answer-8": "Les mises à niveau de la chaîne phare et de la chaîne de fragments ne perturberont pas Eth1 car elles sont construites séparément.",
  "page-eth2-question-4-desc": "Eth1 fait référence au réseau principal Ethereum sur lequel vous faites des transactions aujourd'hui.",
  "page-eth2-question-4-title": "Qu'est-ce que Eth1 ?",
  "page-eth2-question-5-answer-1": "Pour devenir un validateur à part entière sur le réseau, vous devrez miser 32 ETH. Si vous n'avez pas assez d'ETH, ou si vous ne voulez pas en mettre autant en jeu, vous pouvez vous joindre à des groupes d'enjeux. Ces groupes vous permettront de miser une fraction de la somme, et donc de gagner une fraction de la récompense totale.",
  "page-eth2-question-5-desc": "Vous devrez utiliser le launchpad de mise en jeu ou rejoindre un groupe d'enjeux.",
  "page-eth2-question-5-title": "Comment puis-je staker ?",
  "page-eth2-question-6-answer-1": "Pour le moment, il n'y a aucune action à prendre, mais nous vous recommandons de rester informé des développements des mises à niveau de chaînes de fragments et de l'arrimage.",
  "page-eth2-question-6-answer-3": "Danny Ryan, de l'Ethereum Foundation, met régulièrement à jour la communauté:",
  "page-eth2-question-6-answer-4": "Ben Edgington de ConsenSys a une lettre d'information hebdomadaire d'Eth2 :",
  "page-eth2-question-6-answer-5": "Vous pouvez également vous joindre à la discussion sur la recherche et le développement Eth2 sur ethresear.ch.",
  "page-eth2-question-6-desc": "Votre DApp ne sera pas affectée par des mises à niveau imminentes. Cependant, les futures mises à niveau peuvent nécessiter quelques modifications.",
  "page-eth2-question-6-title": "Que dois-je faire avec mon DApp ?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(Implémentation .NET)",
  "page-eth2-question-7-desc": "De nombreuses équipes de toute la communauté travaillent sur les différentes mises à niveau Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Implémentation Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(Implémentation Java)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Implémentation Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Implémentation Go)",
  "page-eth2-question-7-teams": "Les équipes de clients Eth2 :",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Implémentation Java)",
  "page-eth2-question-7-title": "Qui construit Eth2 ?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Implémentation Python)",
  "page-eth2-question-8-answer-1": "Les mises à niveau d'Eth2 aideront l'évolution d'Ethereum dans la décentralisation, tout en maintenant la sécurité et la durabilité.",
  "page-eth2-question-8-answer-2": "Probablement le problème le plus évident, c'est qu'Ethereum doit pouvoir gérer plus de 15 à 45 transactions par seconde. Mais les mises à niveau de Eth2 traitent également d'autres problèmes avec Ethereum aujourd'hui.",
  "page-eth2-question-8-answer-3": "Le réseau est tellement exigeant qu'il rend Ethereum coûteux. Les nœuds du réseau sont en difficulté sous la taille d'Ethereum et la quantité de données que leur ordinateur doit traiter. Et l'algorithme sous-jacent qui maintient Ethereum sûr et décentralisé est énergivore et doit être plus écologique.",
  "page-eth2-question-8-answer-4": "Beaucoup de changements ont toujours été sur la feuille de route d'Ethereum, même depuis 2015. Mais les conditions actuelles rendent les mises à niveau encore plus nécessaires.",
  "page-eth2-question-8-answer-6": "Explorez la vision Eth2",
  "page-eth2-question-8-desc": "L'Ethereum que nous utilisons aujourd'hui doit offrir une meilleure expérience aux utilisateurs et participants du réseau.",
  "page-eth2-question-8-title": "Pourquoi Eth2 est-il nécessaire ?",
  "page-eth2-question-9-answer-1": "Le rôle le plus actif que vous pouvez jouer est de miser vos ETH.",
  "page-eth2-question-9-answer-2": "Vous pouvez également utiliser un deuxième client pour améliorer la diversité des clients.",
  "page-eth2-question-9-answer-3": "Si vous êtes plus technique, vous pouvez aider à trouver des bogues dans les nouveaux clients.",
  "page-eth2-question-9-answer-4": "Vous pouvez aussi participer aux discussions techniques avec les chercheurs Ethereum sur ethresear.ch.",
  "page-eth2-question-9-desc": "Vous n'avez pas besoin d'être technique pour contribuer. La communauté recherche des contributions de toutes sortes de compétences.",
  "page-eth2-question-9-stake-eth": "Mettre en jeu de l'ETH",
  "page-eth2-question-9-title": "Comment puis-je contribuer à Eth2 ?",
  "page-eth2-question-10-answer-1": "Nous sommes réticents à trop parler en termes de feuille de route technique, car il s'agit d'un logiciel : les choses peuvent changer. Nous pensons qu'il est plus facile de comprendre ce qui se passe lorsque vous lisez les résultats.",
  "page-eth2-question-10-answer-1-link": "Voir les mises à niveau",
  "page-eth2-question-10-answer-2": "Mais si vous avez suivi les discussions, voici comment les mises à niveau s'intègrent à des feuilles de route techniques.",
  "page-eth2-question-10-answer-3": "La phase 0 décrit les travaux pour que la chaîne phare soit opérationnelle.",
  "page-eth2-question-10-answer-5": "La phase 1 des feuilles de route techniques est axée sur l'implémentation des chaînes de fragments.",
  "page-eth2-question-10-answer-6": "L'arrimage du réseau principal dans Eth2 est une extension du travail réalisé pour implémenter des chaînes de fragments et a été appelé la phase 1.5. Mais c'est un moment important car l'Ethereum que nous connaissons aujourd'hui fusionnera avec les autres mises à niveau Eth2. De plus, c'est à ce moment qu'Ethereum adoptera totalement la preuve d'enjeu.",
  "page-eth2-question-10-answer-7": "On ne sait pas encore ce qui se passera autour de la Phase 2. C'est encore un sujet de recherche et de discussion intense. Le projet initial consistait à ajouter des fonctionnalités supplémentaires aux chaînes de fragments mais il se pourrait que cela ne soit pas nécessaire avec une feuille de route axée sur le regroupement (ou rollup).",
  "page-eth2-question-10-answer-8": "En savoir plus sur une feuille de route axée sur le regroupement (ou rollup)",
  "page-eth2-question-10-desc": "Les phases se rapportent aux phases de travail et se concentrent dans la feuille de route technique Eth2.",
  "page-eth2-question-10-title": "Quelles sont les phases de l'Eth2 ?",
  "page-eth2-question-title": "Questions fréquemment posées",
  "page-eth2-question3-answer-1": "Les détenteurs d'ETH n'ont pas besoin de faire quoi que ce soit. Votre ETH n'aura pas besoin de changer ou de se mettre à niveau. Il est presque certain que des escroqueries vous disent le contraire, alors soyez prudent.",
  "page-eth2-scalable": "Plus évolutif",
  "page-eth2-scalable-desc": "Ethereum a besoin de prendre en charge des milliers de transactions par seconde, pour rendre les applications plus rapides et moins coûteuses à utiliser.",
  "page-eth2-secure": "Plus sécurisé",
  "page-eth2-secure-desc": "L'Ethereum doit être plus sécurisé. À mesure que l'adoption d'Ethereum s'intensifie, le protocole doit être plus sécurisé contre toutes les formes d'attaque.",
  "page-eth2-shard-button": "En savoir plus sur les chaînes de fragments",
  "page-eth2-shard-date": "L'introduction des chaînes de fragments, la deuxième mise à niveau, devrait se faire en 2021.",
  "page-eth2-shard-desc": "Les chaînes de fragments augmenteront la capacité d'Ethereum à traiter les transactions et à stocker les données. Les fragments eux-mêmes gagneront plus de fonctionnalités au fil du temps, en plusieurs phases.",
  "page-eth2-shard-estimate": "Estimation : 2021",
  "page-eth2-shard-lower": "En savoir plus sur les chaînes de fragments",
  "page-eth2-shard-title": "Chaines de fragments",
  "page-eth2-stay-up-to-date": "Restez à jour",
  "page-eth2-stay-up-to-date-desc": "Obtenez les dernières informations des chercheurs et des développeurs travaillant sur les mises à niveau Eth2.",
  "page-eth2-sustainable-desc": "L'Ethereum doit être meilleur pour l'environnement. La technologie actuelle nécessite trop d'énergie et d'énergie informatique.",
  "page-eth2-take-part": "Participez à la recherche",
  "page-eth2-take-part-desc": "Les chercheurs et les enthousiastes d'Ethereum se rencontrent ici pour discuter des efforts de recherche, y compris de tout ce qui touche à Eth2.",
  "page-eth2-the-upgrades": "Les mises à niveau Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 est un ensemble de mises à niveau qui améliorent l'évolutivité, la sécurité et la durabilité d'Ethereum. Bien que chacun soit travaillé en parallèle, elles ont certaines dépendances qui déterminent quand elles seront déployées.",
  "page-eth2-unofficial-roadmap": "Ce n’est pas la feuille de route officielle, c’est juste ce que nous observons sur la base des informations disponibles. Mais il s'agit de technologie, les choses peuvent changer en un instant, donc s'il vous plaît ne prenez pas ces infos comme une promesse figée dans le marbre.",
  "page-eth2-upgrade-desc": "L'Ethereum que nous connaissons et aimons, en plus plus évolutif, plus sûr et plus durable...",
  "page-eth2-upgrades": "Les mises à niveau Eth2",
  "page-eth2-upgrades-aria-label": "Menu des mises à niveau Eth2",
  "page-eth2-upgrades-beacon-chain": "La chaîne phare",
  "page-eth2-upgrades-docking": "Arrimage du réseau principal avec Eth2",
  "page-eth2-upgrades-guide": "Guide des mises à niveau de Eth2",
  "page-eth2-upgrades-shard-chains": "Les chaînes de fragments",
  "page-eth2-upgrading": "Mise à niveau d'Ethereum vers de nouveaux sommets",
  "page-eth2-vision": "Notre vision",
  "page-eth2-vision-btn": "En savoir plus sur la vision Eth2",
  "page-eth2-vision-desc": "Pour intégrer Ethereum dans le courant général et servir toute l'humanité, nous devons rendre Ethereum plus évolutif, plus sécuritaire et plus durable.",
  "page-eth2-vision-upper": "Vision d'Eth2",
  "page-eth2-what-to-do": "Que devez-vous faire ?",
  "page-eth2-what-to-do-desc": "Si vous êtes un utilisateur de DApp ou un détenteur d'ETH, vous n'avez rien à faire. Si vous êtes un développeur ou que vous voulez commencer à miser, il y a des moyens pour vous impliquer aujourd'hui.",
  "page-eth2-whats-eth2": "Qu'est-ce que Eth2 ?",
  "page-eth2-whats-eth2-desc": "Eth2 désigne un ensemble d'améliorations interconnectées qui rendront Ethereum plus évolutif, plus sécurisé, et plus durable encore. Ces mises à niveau sont conçues par de multiples équipes dans tout l'écosystème Ethereum.",
  "page-eth2-whats-ethereum": "Attendez, qu'est-ce que Ethereum ?",
  "page-eth2-whats-new": "Quoi de nouveau sur Eth2 ?",
  "page-eth2-just-staking": "Staking",
  "page-eth2-staking-image-alt": "Image de la mascotte Rhino pour le launchpad eth2.",
  "page-eth2-staking-51-desc-1": "La fragmentation rend plus accessible le rôle de validateur sur le réseau, il est donc probable qu'il y aura plus de validateurs sur le réseau qu'il n'en existe aujourd'hui. Cela rendra ce type d'attaque encore plus difficile car le coût d'une attaque augmentera.",
  "page-eth2-staking-accessibility": "Plus accessible",
  "page-eth2-staking-accessibility-desc": "Avec des exigences matérielles allégées et la possibilité de mettre en commun si vous n'avez pas 32 Eth, davantage de personnes pourront rejoindre le réseau. Cela rendra Ethereum plus décentralisé et sécurisé en diminuant la surface d'attaque.",
  "page-eth2-staking-at-stake": "Votre ETH est en jeu",
  "page-eth2-staking-at-stake-desc": "Parce que vous devez miser vos ETH pour valider les transactions et créer de nouveaux blocs, vous pouvez le perdre si vous décidez d'essayer de tromper le système.",
  "page-eth2-staking-benefits": "Les avantages de la mise en jeu Ethereum",
  "page-eth2-staking-check-address": "Vérifier l'adresse de dépôt",
  "page-eth2-staking-consensus": "En savoir plus sur les mécanismes de consensus",
  "page-eth2-staking-deposit-address": "Vérifiez l'adresse de dépôt",
  "page-eth2-staking-deposit-address-desc": "Si vous avez déjà suivi les instructions de configuration du launchpad, vous savez que vous devez envoyer une transaction au contrat de dépôt. Nous vous recommandons de vérifier l'adresse très attentivement. Vous pouvez trouver l'adresse officielle sur ethereum.org et un certain nombre d'autres sites de confiance.",
  "page-eth2-staking-desc-1": "Des récompenses sont données pour les actions qui aident le réseau à atteindre un consensus. Vous obtiendrez des récompenses en inscrivant des transactions dans un nouveau bloc ou en vérifiant le travail d'autres validateurs, car c'est ce qui maintient la sécurité de la chaîne.",
  "page-eth2-staking-desc-2": "Bien que vous puissiez gagner des récompenses en faisant du travail qui profite au réseau, vous pouvez perdre votre ETH pour des actions malveillantes, en vous déconnectant et en ne validant pas.",
  "page-eth2-staking-desc-3": "Vous aurez besoin de 32 ETH pour devenir validateur complet ou de quelques ETH pour rejoindre un groupe d'enjeux. Vous devrez également exécuter un client 'Eth1' ou 'mainnet'. Le launchpad vous guidera à travers les exigences du processus et du matériel. Alternativement, vous pouvez utiliser une API d'arrière-plan.",
  "page-eth2-staking-description": "La mise en jeu est l'acte de déposer 32 ETH pour activer le logiciel de validateur. En tant que validateur, vous serez responsable du stockage des données, du traitement des transactions et de l'ajout de nouveaux blocs à la blockchain. Cela permettra de sécuriser Ethereum pour tout le monde et de vous faire gagner de nouveaux ETH au cours du processus. Ce processus, connu sous le nom de preuve d'enjeu, est introduit par la chaîne phare.",
  "page-eth2-staking-docked": "En savoir plus sur l'arrimage",
  "page-eth2-staking-dyor": "Faites vos propres recherches",
  "page-eth2-staking-dyor-desc": "Aucun des services de mise en jeu énumérés ne sont des endossements officiels. Assurez-vous de faire des recherches pour déterminer quel service pourrait être le meilleur pour vous.",
  "page-eth2-staking-header-1": "Mettez en jeu votre ETH pour devenir un validateur Ethereum",
  "page-eth2-staking-how-much": "Combien êtes-vous prêt à miser ?",
  "page-eth2-staking-how-to-stake": "Comment miser",
  "page-eth2-staking-how-to-stake-desc": "Tout dépend du montant que vous êtes prêt à investir. Vous aurez besoin de 32 ETH pour devenir un validateur à part entière, mais il est possible de miser une fraction de cette somme.",
  "page-eth2-staking-join": "Rejoindre",
  "page-eth2-staking-join-community": "Rejoignez la communauté des stakers",
  "page-eth2-staking-join-community-desc": "r/ethstaker est une communauté qui permet à tout le monde de discuter de la mise en jeu sur Ethereum – rejoignez-la pour obtenir des conseils, du soutien et parler de tout ce qui touche à la mise en jeu.",
  "page-eth2-staking-less-than": "Inférieure à",
  "page-eth2-staking-link-1": "Voir les API backend",
  "page-eth2-staking-meta-description": "Présentation de la mise en jeu d'Ethereum : les risques, les récompenses, les exigences et où le faire.",
  "page-eth2-staking-meta-title": "Staking d'Ethereum",
  "page-eth2-staking-more-sharding": "En savoir plus sur la fragmentation",
  "page-eth2-staking-pool": "Mise en jeu groupée",
  "page-eth2-staking-pool-desc": "Si n'avez pas 32 ETH à mettre en jeu, vous pourrez mettre en jeu une fraction de cette somme dans un groupe d'enjeux. Certaines entreprises peuvent tout faire en votre nom, donc vous n'aurez pas à vous soucier de rester en ligne. Voici quelques entreprises à vérifier.",
  "page-eth2-staking-pos-explained": "Explication de preuve d'enjeu",
  "page-eth2-staking-pos-explained-desc": "Miser est l'action que vous devez effectuer pour devenir validateur dans un système de preuves d'enjeu. Il s'agit d'un mécanisme de consensus qui va remplacer le système de preuve de travail actuellement en place. Les mécanismes de consensus sont ce qui maintient les blockchains comme Ethereum sécurisées et décentralisées.",
  "page-eth2-staking-pos-explained-desc-1": "La preuve d'enjeu aide à sécuriser le réseau de plusieurs façons :",
  "page-eth2-staking-services": "Voir les services de mise en jeu",
  "page-eth2-staking-sharding": "Permet la fragmentation",
  "page-eth2-staking-sharding-desc": "La fragmentation, ou sharding, n'est possible qu'avec un système de preuve. La fragmentation d'un système de preuve de travail diluerait la puissance de calcul nécessaire pour corrompre le réseau, ce qui permettrait aux mineurs malveillants de contrôler plus facilement les fragments. Ce n'est pas le cas avec les stakers assignés aléatoirement en preuve d'enjeu.",
  "page-eth2-staking-solo": "Staker solo et devenez un validateur",
  "page-eth2-staking-solo-desc": "Pour commencer le processus de mise en jeu, vous devrez utiliser le launchpad Eth2. Il vous guidera à travers toute la configuration. Une partie de la mise en jeu est l'exécution d'un client Eth2, qui est une copie locale de la blockchain. Cela peut prendre un certain temps à télécharger sur votre ordinateur.",
  "page-eth2-staking-start": "Commencer à miser",
  "page-eth2-staking-subtitle": "La mise en jeu est un bien public en faveur de l'écosystème Ethereum. Vous pouvez aider à sécuriser le réseau et gagner des récompenses par la même occasion.",
  "page-eth2-staking-sustainability": "Plus durable",
  "page-eth2-staking-sustainability-desc": "Les validateurs n’ont pas besoin d’ordinateurs à forte consommation d’énergie pour participer à un système de preuve d’enjeu – un ordinateur portable ou même un smartphone suffisent. Cela rendra Ethereum meilleur pour l'environnement.",
  "page-eth2-staking-the-beacon-chain": "En savoir plus sur la Chaîne de Balises",
  "page-eth2-staking-title-1": "Récompenses",
  "page-eth2-staking-title-2": "Risques",
  "page-eth2-staking-title-3": "Conditions requises",
  "page-eth2-staking-title-4": "Comment miser vos ETH",
  "page-eth2-staking-upgrades-li": "Le mécanisme de preuve d'enjeu est dirigé par la chaîne phare.",
  "page-eth2-staking-upgrades-li-2": "Dans un futur proche, Ethereum aura la chaîne phare à preuve d'enjeu et le réseau principal à preuve de travail. Le réseau principal est l'Ethereum que nous utilisons depuis des années.",
  "page-eth2-staking-upgrades-li-3": "Pendant ce temps, les stakers ajouteront de nouveaux blocs à la chaîne phare mais ne traiteront pas les transactions principales.",
  "page-eth2-staking-upgrades-li-4": "Ethereum passera entièrement à un système de preuve d'enjeu une fois que le réseau principal Ethereum deviendra un fragment.",
  "page-eth2-staking-upgrades-li-5": "Ce n'est qu'alors que vous pourrez retirer votre enjeu.",
  "page-eth2-staking-upgrades-title": "Améliorations de la preuve d'enjeu et de Eth2",
  "page-eth2-staking-validators": "Plus de validateurs, plus de sécurité",
  "page-eth2-staking-validators-desc": "Dans une blockchain comme Ethereum, il est possible de corrompre l'intégralité de la chaîne si vous contrôlez 51 % du réseau. Par exemple, vous pourriez obtenir 51% des validateurs pour déclarer que votre solde est de 1 000 000 ETH et non 1 ETH. Mais, pour contrôler 51% des validateurs, vous devez posséder 51% de l’ETH dans le système – ça fait beaucoup !",
  "page-eth2-staking-withdrawals": "Les retraits ne seront pas activés instantanément",
  "page-eth2-staking-withdrawals-desc": "Vous ne pourrez pas retirer votre satking tant que de futures améliorations ne seront pas déployées. Les retraits devraient être disponibles une fois le réseau principal arrimé dans le système de chaîne phare.",
  "page-eth2-vision-2014": "Voir un article de blog 2014 détaillant la preuve d'enjeu",
  "page-eth2-vision-beacon-chain": "La chaîne phare",
  "page-eth2-vision-beacon-chain-btn": "En savoir plus sur la Chaîne de Balises",
  "page-eth2-vision-beacon-chain-date": "La chaîne phare est active",
  "page-eth2-vision-beacon-chain-desc": "Le premier ajout de l'Eth2 à l'écosystème. La chaîne phare intègre la mise en jeu sur Ethereum, pose les bases des futures mises à niveau et coordonnera éventuellement le nouveau système.",
  "page-eth2-vision-beacon-chain-upper": "Chaîne phare",
  "page-eth2-vision-desc-1": "Ethereum a besoin de réduire la congestion du réseau et d'améliorer les vitesses pour mieux servir une base d'utilisateurs mondiale.",
  "page-eth2-vision-desc-2": "Exécuter un nœud devient plus difficile au fur et à mesure que le réseau grandit. Cela sera encre plus difficile avec les efforts de mise à l'échelle du réseau.",
  "page-eth2-vision-desc-3": "Ethereum consomme trop d'électricité. La technologie qui maintient la sécurité du réseau doit être plus durable.",
  "page-eth2-vision-docking-date": "Estimation : 2022",
  "page-eth2-vision-docking-desc": "Le mainnet, ou réseau principale, d'Ethereum devra \"s'arrimer\" à la chaîne phare à un moment donné. Cela permettra de basculer vers la preuve d'enjeu sur l'ensemble du réseau et de signaler la fin du minage à forte intensité énergétique.",
  "page-eth2-vision-ethereum-node": "En savoir plus sur les nœuds",
  "page-eth2-vision-explore-upgrades": "Explorez les améliorations",
  "page-eth2-vision-future": "Un avenir numérique à l'échelle mondiale",
  "page-eth2-vision-meta-desc": "Présentation de l'impact que les mises à niveau Eth2 auront sur Ethereum et des défis qu'elles doivent relever.",
  "page-eth2-vision-meta-title": "Vision d'Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "En savoir plus sur le minage",
  "page-eth2-vision-problems": "Problèmes actuels",
  "page-eth2-vision-scalability": "Évolutivité",
  "page-eth2-vision-scalability-desc": "Ethereum doit pouvoir gérer plus de transactions par seconde sans augmenter la taille des nœuds du réseau. Les nœuds sont des acteurs vitaux au réseau qui stockent et exécutent la blockchain. L'augmentation de la taille des nœuds n'est pas pratique car seuls ceux qui disposent d'ordinateurs puissants et coûteux pourraient le faire. Pour évoluer, Ethereum a besoin d'exécuter plus de transactions par seconde, couplé à plus de nœuds. Plus de nœuds signifie plus de sécurité.",
  "page-eth2-vision-scalability-desc-3": "La mise à niveaus des chaînes de fragments répartira la charge du réseau en 64 nouvelles chaînes. Cela permettra à Ethereum de respirer en réduisant la congestion et en améliorant les vitesses au-delà de la limite actuelle de 15 à 45 transactions par seconde.",
  "page-eth2-vision-scalability-desc-4": "Et même s'il y aura plus de chaînes, cela nécessitera en fait moins de travail de la part des validateurs - les \"responsables du réseau\". Les validateurs n'auront besoin que d'exécuter leur fragment et non de toute la chaîne Ethereum. Cela rend les nœuds plus légers, permettant à Ethereum d'évoluer et de rester décentralisé.",
  "page-eth2-vision-security": "Sécurité",
  "page-eth2-vision-security-desc": "Les mises à niveau d'Eth2 améliorent la sécurité d'Ethereum contre les attaques coordonnées, comme une attaque de 51 %. Il s'agit d'un type d'attaque où, si quelqu'un contrôle la majorité du réseau, il peut valider des modifications frauduleuses.",
  "page-eth2-vision-security-desc-3": "La transition vers une preuve d'enjeu signifie que le protocole Ethereum est plus dissuasif contre les attaques. Cela est dû au fait que les validateurs qui sécurisent le réseau doivent miser des quantités significatives d'ETH dans le protocole. S'ils tentent d'attaquer le réseau, le protocole peut automatiquement détruire leur ETH.",
  "page-eth2-vision-security-desc-5": "Ce n'est pas possible dans la preuve de travail, où le mieux qu'un protocole puisse faire est de forcer les entités qui sécurisent le réseau (les mineurs) à perdre des récompenses minières qu'ils auraient gagnées autrement. Pour obtenir l'effet équivalent en preuve de travail, le protocole devrait être en mesure de détruire tout l'équipement d'un mineur s'il tente de tricher.",
  "page-eth2-vision-security-desc-5-link": "En savoir plus sur la preuve de travail",
  "page-eth2-vision-security-desc-8": "Le modèle de sécurité d'Ethereum doit également être modifié en raison de l'introduction de",
  "page-eth2-vision-security-desc-10": "La mise en jeu signifie également que vous n'avez pas besoin d'investir dans du matériel d'élite pour exécuter un nœud Ethereum. Cela devrait encourager un plus grand nombre de personnes à devenir validateur, augmentant la décentralisation du réseau et réduisant la surface d’attaque.",
  "page-eth2-vision-security-staking": "Mettre en jeu de l'ETH",
  "page-eth2-vision-security-validator": "Vous pouvez devenir un validateur en stakant (en mettant en jeu) votre ETH.",
  "page-eth2-vision-shard-chains": "chaînes de fragments",
  "page-eth2-vision-shard-date": "Estimation : 2021",
  "page-eth2-vision-shard-desc-4": "Les chaînes de fragments répartiront la charge du réseau en 64 nouvelles blockchains. La fragmentation a le potentiel d’améliorer considérablement la vitesse de transaction – jusqu’à 100 000 par seconde.",
  "page-eth2-vision-shard-upgrade": "En savoir plus sur les chaînes de fragments",
  "page-eth2-vision-staking-lower": "Plus d'infos sur la mise en jeu",
  "page-eth2-vision-subtitle": "Développez Ethereum jusqu'à ce qu'il soit assez puissant pour aider toute l'humanité.",
  "page-eth2-vision-sustainability": "Développement durable",
  "page-eth2-vision-sustainability-desc-1": "Ce n'est pas un secret qu'Ethereum et d'autres blockchains comme Bitcoin sont fortement énergivores à cause de l'extraction.",
  "page-eth2-vision-sustainability-desc-2": "Mais Ethereum se dirige vers la sécurisation par l'ETH, et non par la puissance de calcul – grâce à la mise en jeu.",
  "page-eth2-vision-sustainability-desc-3": "Bien que la mise en jeu soit introduite par la chaîne phare, l'Ethereum que nous utilisons aujourd'hui fonctionnera en parallèle pendant une période de temps, avant qu'il ne 'fusionne' ou 's'arrime' avec les mises à niveau Eth2. Un système sécurisé par l'ETH, l'autre par la puissance informatique. Car, dans un premier temps, les chaînes de fragments ne seront pas en mesure de gérer des choses telles que nos comptes ou nos DApps. Nous ne pouvons donc pas nous contenter d'oublier le minage et le réseau principal.",
  "page-eth2-vision-sustainability-desc-8": "Une fois que les mises à niveau de la chaîne phare et des chaînes des fragments seront en place, le travail d'arrimage commencera entre le réseau principal et le nouveau système. Cela transformera le réseau principal en un fragment de sorte qu'il soit sécurisé par l'ETH et beaucoup moins énergivore.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum doit être plus écologique.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "La vision Eth2",
  "page-eth2-vision-title-1": "Réseau saturé",
  "page-eth2-vision-title-2": "Espace disque",
  "page-eth2-vision-title-3": "Trop d'énergie",
  "page-eth2-vision-trilemma-cardtext-1": "Les mises à niveau Eth2 rendront Ethereum évolutif, sécurisé et décentralisé. La fragmentation rendra Ethereum plus scalable en augmentant les transactions par seconde tout en réduisant la puissance nécessaire pour exécuter un nœud et valider la chaîne. La chaîne phare sécurisera Ethereum en coordonnant les validateurs à travers les fragments. Et la mise en jeu réduira la barrière à la participation, créant un réseau plus large – plus décentralisé.",
  "page-eth2-vision-trilemma-cardtext-2": "Les réseaux blockchain sécurisés et décentralisés exigent que chaque nœud vérifie chaque transaction traitée par la chaîne. Ce volume de travail limite le nombre de transactions qui peuvent se faire à un moment donné. Décentralisée et sécurisée sont les adjectifs qui caractérisent la chaîne Ethereum aujourd'hui.",
  "page-eth2-vision-trilemma-cardtext-3": "Les réseaux décentralisés fonctionnent en envoyant des informations sur les transactions à travers les nœuds – tout le réseau doit être au courant de tout changement d'état. L'évolution des transactions par seconde sur un réseau décentralisé comporte des risques de sécurité car plus il y a de transactions, plus le délai est long, plus la probabilité d'attaque est élevée lorsque l'information est en cours de transfert.",
  "page-eth2-vision-trilemma-cardtext-4": "L'augmentation de la taille et de la puissance des nœuds d'Ethereum pourrait augmenter les transactions par seconde de façon sécurisée, mais l’exigence matérielle restreindrait alors qui peut ou non le faire – c'est une menace sur la décentralisation. Nous espérons que la fragmentation et la preuve d'enjeu permettront à Ethereum d'augmenter la quantité de nœuds et non la taille de nœuds.",
  "page-eth2-vision-trilemma-h2": "Le défi de la mise à l'échelle décentralisée",
  "page-eth2-vision-trilemma-modal-tip": "Appuyez sur les cercles ci-dessous pour mieux comprendre les problèmes d'évolutivité décentralisée",
  "page-eth2-vision-trilemma-p": "Une façon naïve de résoudre les problèmes d'Ethereum serait de les centraliser, mais la décentralisation est trop importante. C’est la décentralisation qui offre la résistance à la censure, l'ouverture, la confidentialité des données et la sécurité quasi indestructible d’Ethereum.",
  "page-eth2-vision-trilemma-p-1": "La vision d’Ethereum est d’être plus évolutive et plus sûre, mais aussi de rester décentralisée. Atteindre ces 3 qualités est un problème connu sous le nom de trilemme d'évolutivité.",
  "page-eth2-vision-trilemma-p-2": "Les améliorations de l'Eth2 visent à résoudre le trilemme mais il y a des défis importants.",
  "page-eth2-vision-trilemma-press-button": "Appuyez sur les boutons du triangle pour mieux comprendre les problèmes de l'évolutivité décentralisée.",
  "page-eth2-vision-trilemma-text-1": "Décentralisation",
  "page-eth2-vision-trilemma-text-2": "Sécurité",
  "page-eth2-vision-trilemma-text-3": "Évolutivité",
  "page-eth2-vision-trilemma-title-1": "Explorer le trilemme d'évolutivité",
  "page-eth2-vision-trilemma-title-2": "Améliorations de l'Eth2 et évolution de la décentralisation",
  "page-eth2-vision-trilemma-title-3": "Sécurisé et décentralisé",
  "page-eth2-vision-trilemma-title-4": "Décentralisé et évolutif",
  "page-eth2-vision-trilemma-title-5": "Fiable et sécurisé",
  "page-eth2-vision-understanding": "Comprendre la vision d'Eth2",
  "page-eth2-vision-upgrade-needs": "La nécessité derrière les mises à niveau d'Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Le protocole Ethereum qui a été lancé en 2015 a connu un succès incroyable. Mais la communauté Ethereum s'attendait toujours à ce que quelques mises à niveau clés soient nécessaires pour débloquer le plein potentiel d'Ethereum.",
  "page-eth2-vision-upgrade-needs-desc-2": "La demande élevée augmente les frais de transaction qui font qu'Ethereum est coûteux pour l'utilisateur moyen. L'espace disque nécessaire au fonctionnement d'un client Ethereum augmente à un rythme rapide. Et l'algorithme de consensus sous-jacent sur la preuve de travail qui maintient Ethereum sécurisé et décentralisé a un impact environnemental important.",
  "page-eth2-vision-upgrade-needs-desc-3": "Ce qui est communément appelé Eth2 est un ensemble de mises à niveau qui traitent ces problèmes et bien plus encore. Cet ensemble d'améliorations s'appelait à l'origine \"Serenity\", et elles sont un domaine actif de recherche et de développement depuis 2014.",
  "page-eth2-vision-upgrade-needs-desc-6": " Cela signifie qu’il n’y a pas d’interrupteur pour Eth2. Les améliorations seront incorporées progressivement au fil du temps.",
  "page-eth2-vision-upgrade-needs-serenity": "Voir un article de blog 2015 traitant de \"Serenity\"",
  "page-eth2-vision-uprade-needs-desc-5": "Maintenant que la technologie est prête, ces mises à niveau réarchitectureront Ethereum pour le rendre plus évolutif, sécurisé et durable – pour améliorer la vie des utilisateurs existants et en attirer de nouveaux. Tout en préservant la valeur principale de décentralisation d'Ethereum.",
  "page-get-eth-cex": "Échanges centralisés",
  "page-get-eth-cex-desc": "Les échanges ou marchés sont des \"plateformes\" qui vous permettent d'acheter des cryptomonnaies en utilisant des monnaies traditionnelles. Elles ont la garde de tout ETH que vous achetez jusqu'à ce que vous l'envoyiez à un portefeuille que vous contrôlez.",
  "page-get-eth-checkout-dapps-btn": "Découvrez les DApps",
  "page-get-eth-community-safety": "Publications de la communauté sur la sécurité",
  "page-get-eth-description": "Ethereum et ETH ne sont contrôlés par aucun gouvernement ou entreprise - ils sont décentralisés. Cela signifie que l'ETH est ouvert à tout le monde.",
  "page-get-eth-dex": "Échanges décentralisés (DEX)",
  "page-get-eth-dex-desc": "Si vous voulez plus de contrôle, achetez des ETH en P2P. Avec un DEX vous pouvez trader sans donner le contrôle de vos fonds à une société centralisée.",
  "page-get-eth-dexs": "Échanges décentralisés (DEX)",
  "page-get-eth-dexs-desc": "Les échanges décentralisés sont des places de marché ouvertes pour ETH et autres jetons. Elles connectent directement les acheteurs et les vendeurs.",
  "page-get-eth-dexs-desc-2": "Au lieu d'utiliser un tiers de confiance pour protéger les fonds dans la transaction, elles utilisent du code. L'ETH du vendeur ne sera transféré que lorsque le paiement sera garanti. Ce type de code est connu sous le nom de contrat intelligent.",
  "page-get-eth-dexs-desc-3": "Cela signifie qu'il y a moins de restrictions géographiques qu'avec les alternatives centralisées. Si quelqu'un vend ce que vous voulez et accepte une méthode de paiement que vous pouvez fournir, la transaction est conclue. Les DEX peut vous permettre d'acheter de l'ETH avec d'autres jetons, PayPal ou même avec des mandats en espèces.",
  "page-get-eth-do-not-copy": "EXEMPLE : NE COPIEZ PAS",
  "page-get-eth-exchanges-disclaimer": "Nous avons recueilli ces informations manuellement. Si vous constatez quelque chose de faux, faites-le nous savoir à",
  "page-get-eth-exchanges-empty-state-text": "Entrez votre lieu de résidence pour voir la liste des portefeuilles et des échanges que vous pouvez utiliser pour acheter des ETH",
  "page-get-eth-exchanges-except": "Excepté",
  "page-get-eth-exchanges-header": "Dans quel pays vivez-vous ?",
  "page-get-eth-exchanges-header-exchanges": "Échanges",
  "page-get-eth-exchanges-header-wallets": "Portefeuilles",
  "page-get-eth-exchanges-intro": "Les échanges et les portefeuilles ont des restrictions sur l'endroit où ils peuvent vendre des cryptomonnaies.",
  "page-get-eth-exchanges-no-exchanges": "Désolés, nous ne connaissons pas d'échange qui vous permette d'acheter des ETH dans ce pays. Si vous en connaissez, dites-le nous à",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Désolés, nous ne connaissons pas d'échanges ou de portefeuilles qui vous permettent d'acheter ETH dans ce pays. Si vous en connaissez, dites-le nous à",
  "page-get-eth-exchanges-no-wallets": "Désolés, nous ne connaissons pas de portefeuille qui vous permette d'acheter des ETH dans ce pays. Si vous en connaissez, dites-le nous à",
  "page-get-eth-exchanges-success-exchange": "Il peut s'écouler plusieurs jours pour valider votre inscription à un échange en raison de leurs contrôles juridiques.",
  "page-get-eth-exchanges-success-wallet-link": "portefeuilles",
  "page-get-eth-exchanges-success-wallet-paragraph": "Là où vous vivez, vous pouvez acheter de l'ETH directement à partir de ces portefeuilles. En savoir plus sur",
  "page-get-eth-exchanges-usa": "États-Unis",
  "page-get-eth-get-wallet-btn": "Obtenir un portefeuille",
  "page-get-eth-hero-image-alt": "Image hero Obtenir de l'ETH",
  "page-get-eth-keep-it-safe": "Garder votre ETH en sécurité",
  "page-get-eth-meta-description": "Comment acheter des ETH en fonction de l'endroit où vous vivez et des conseils sur la façon de les protéger.",
  "page-get-eth-meta-title": "Comment acheter des ETH",
  "page-get-eth-need-wallet": "Vous aurez besoin d'un portefeuille pour utiliser un DEX.",
  "page-get-eth-new-to-eth": "Nouveau sur ETH ? Voici un aperçu pour vous aider à démarrer.",
  "page-get-eth-other-cryptos": "Acheter avec d'autres cryptomonnaies",
  "page-get-eth-protect-eth-desc": "Si vous prévoyez d'acheter beaucoup d'ETH, vous devriez peut-être le conserver dans un portefeuille que vous contrôlez et non dans un échange. C'est parce qu'un échange est une cible probable pour les pirates. Si un hacker obtient un accès, vous pourriez perdre vos fonds. Alternativement, seul vous avez le contrôle de votre portefeuille.",
  "page-get-eth-protect-eth-in-wallet": "Protégez votre ETH dans un portefeuille",
  "page-get-eth-search-by-country": "Rechercher par pays",
  "page-get-eth-security": "Mais cela signifie aussi que vous devez prendre au sérieux la sécurité de vos fonds. Avec ETH, vous ne faites pas confiance à une banque pour prendre soin de votre argent, vous faites confiance à vous-même.",
  "page-get-eth-smart-contract-link": "En savoir plus sur les contrats intelligents",
  "page-get-eth-swapping": "Échangez vos jetons contre l'ETH d'autres personnes. Et vice versa.",
  "page-get-eth-traditional-currencies": "Acheter avec des monnaies traditionnelles",
  "page-get-eth-traditional-payments": "Achetez des ETH avec des modes de paiement traditionnels directement auprès des vendeurs.",
  "page-get-eth-try-dex": "Essayer un Dex",
  "page-get-eth-use-your-eth": "Utilisez vos ETH",
  "page-get-eth-use-your-eth-dapps": "Maintenant que vous êtes propriétaire d'ETH, consultez quelques applications Ethereum (DApps). Il existe des DApps pour la finance, les médias sociaux, les jeux et beaucoup d'autres catégories.",
  "page-get-eth-wallet-instructions": "Suivez les instructions du portefeuille",
  "page-get-eth-wallet-instructions-lost": "Si vous perdez l'accès à votre portefeuille, vous perdrez l'accès à vos fonds. Votre portefeuille devrait vous donner des instructions pour vous protéger contre cela. Veuillez les suivre attentivement – dans la plupart des cas, personne ne peut vous aider si vous perdez l’accès à votre portefeuille.",
  "page-get-eth-wallets": "Portefeuilles",
  "page-get-eth-wallets-link": "Plus d'infos sur les portefeuilles",
  "page-get-eth-wallets-purchasing": "Certains portefeuilles vous permettent d'acheter des cryptomonnaies avec une carte de débit/crédit, un virement bancaire ou même Apple Pay. Des restrictions géographiques s'appliquent.",
  "page-get-eth-warning": "Ces DEX ne sont pas pour les débutants car vous aurez besoin de quelques ETH pour les utiliser.",
  "page-get-eth-what-are-DEX's": "En quoi consistent les DEX ?",
  "page-get-eth-whats-eth-link": "Qu'est-ce que l'ETH ?",
  "page-get-eth-where-to-buy-desc": "Vous pouvez acheter des ETH à partir des échanges ou des portefeuilles directement.",
  "page-get-eth-where-to-buy-desc-2": "Vérifiez quels services vous pouvez utiliser en fonction de l'endroit où vous résidez.",
  "page-get-eth-where-to-buy-title": "Où acheter des ETH",
  "page-get-eth-your-address": "Votre adresse ETH",
  "page-get-eth-your-address-desc": "Lorsque vous téléchargez un portefeuille, il créera une adresse ETH publique pour vous. Voici à quoi ressemble une adresse :",
  "page-get-eth-your-address-desc-3": "Pensez à cela comme votre adresse e-mail, mais au lieu du courrier, il peut recevoir de l'ETH. Si vous souhaitez transférer des ETH depuis un échange vers votre portefeuille, utilisez votre adresse comme destination. Assurez-vous de toujours la vérifier à deux reprises avant d'envoyer !",
  "page-get-eth-your-address-wallet-link": "Découvrez les portefeuilles",
  "page-index-hero-image-alt": "Image de premier plan ethereum.org",
  "page-index-meta-description": "Ethereum est une plateforme monétaire globale et décentralisée qui propose également de nouveaux types d'applications. Sur Ethereum, vous pouvez écrire du code qui contrôle de la monnaie, et construire des applications accessibles partout dans le monde.",
  "page-index-meta-title": "Accueil",
  "page-index-title": "Ethereum est une plateforme globale et open source pour des applications décentralisées.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Support linguistique",
  "page-languages-interested": "Vous souhaitez contribuer ?",
  "page-languages-learn-more": "En savoir plus sur notre programme de traduction",
  "page-languages-meta-desc": "Des ressources pour toutes les langues supportées d'ethereum.org et des moyens de s'impliquer en tant que traducteur.",
  "page-languages-meta-title": "Traductions d'ethereum.org",
  "page-languages-p1": "Ethereum est un projet mondial, et il est essentiel qu'ethereum.org soit accessible à tous, quelle que soit leur nationalité ou leur langue. Notre communauté a travaillé dur pour faire de cette vision une réalité.",
  "page-languages-translations-available": "ethereum.org est disponible dans les langues suivantes",
  "page-languages-want-more-header": "Voulez-vous voir ethereum.org dans une autre langue ?",
  "page-languages-want-more-link": "Programme de traduction",
  "page-languages-want-more-paragraph": "Les traducteurs d’ethereum.org traduisent constamment les pages dans le plus de langues possible. Pour voir ce sur quoi ils sont en train de travailler actuellement ou pour les rejoindre, lisez notre",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Crypto-collatéral",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Avec Ethereum, vous pouvez emprunter directement à d'autres utilisateurs sans échanger vos ETH. Cela peut vous donner un effet de levier – certains le font pour essayer d'accumuler plus d'ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Mais étant donné que le prix de l'ETH est volatile, vous devrez fournir plus en garantie. Cela signifie que si vous voulez emprunter 100 stablecoins, vous aurez probablement besoin d'au moins 150 $ d’ETH, ce qui protège le système et les prêteurs.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Plus d'infos sur les stablecoins crypto-adossés",
  "page-stablecoins-accordion-borrow-pill": "Avancé",
  "page-stablecoins-accordion-borrow-places-intro": "Ces DApps vous permettent d'emprunter des stablecoins en utilisant des cryptomonnaies comme garanties. Certains acceptent d'autres jetons ainsi que l'ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Où emprunter des stablecoins",
  "page-stablecoins-accordion-borrow-requirement-1": "Un portefeuille Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Vous aurez besoin d'un portefeuille pour utiliser une DApp",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Vous aurez besoin d'ETH comme garantie et/ou pour les frais de transaction",
  "page-stablecoins-accordion-borrow-requirements-description": "Pour emprunter des stablecoins, vous devrez utiliser la bonne DApp. Vous aurez également besoin d'un portefeuille et de quelques ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Si vous utilisez de l’ETH comme garantie et que sa valeur baisse, votre garantie ne couvrira plus les stablecoins que vous avez générés. Cela entraînera la liquidation de vos ETH et vous risquez de subir une pénalité. Donc, si vous empruntez des stablecoins, vous devrez surveiller le prix de l'ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Prix de l’ETH le plus récent",
  "page-stablecoins-accordion-borrow-risks-title": "Risques",
  "page-stablecoins-accordion-borrow-text-preview": "Vous pouvez emprunter des stablecoins en utilisant des cryptomonnaies comme garanties, que vous devez rembourser.",
  "page-stablecoins-accordion-borrow-title": "Emprunter",
  "page-stablecoins-accordion-buy-exchanges-title": "Échanges populaires",
  "page-stablecoins-accordion-buy-requirement-1": "Plateformes et portefeuilles de cryptomonnaies",
  "page-stablecoins-accordion-buy-requirement-1-description": "Vérifiez les services que vous pouvez utiliser où vous vivez",
  "page-stablecoins-accordion-buy-requirements-description": "Un compte avec un échange ou un portefeuille à partir duquel vous pouvez acheter des cryptomonnaies directement. Vous en avez peut-être déjà utilisé un pour obtenir des ETH. Vérifiez quels services vous pouvez utiliser là où vous résidez.",
  "page-stablecoins-accordion-buy-text-preview": "Beaucoup de plateformes d'échanges et de portefeuilles vous permettent d'acheter directement des stablecoins. Des restrictions géographiques s'appliqueront.",
  "page-stablecoins-accordion-buy-title": "Acheter",
  "page-stablecoins-accordion-buy-warning": "Les échanges centralisés ne peuvent lister que les stablecoins soutenus par fiat, comme les USDC, Tether et d'autres. Vous ne pourrez peut-être pas les acheter directement, mais vous devriez pouvoir les échanger contre des ETH ou d'autres cryptomonnaies que vous pouvez acheter sur la plateforme.",
  "page-stablecoins-accordion-earn-project-1-description": "Travail principalement technique pour le mouvement des logiciels libres.",
  "page-stablecoins-accordion-earn-project-2-description": "Technologie, contenu et autre travail pour la communauté MakerDao (l'équipe qui a créé Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Quand vous vous y connaissez vraiment, trouvez des bogues pour gagner du Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Récompenses Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Prime de bogues Eth2",
  "page-stablecoins-accordion-earn-project-community": "Communauté MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Ce sont des plateformes qui vous paieront avec des stablecoins pour votre travail.",
  "page-stablecoins-accordion-earn-projects-title": "Où gagner des stablecoins",
  "page-stablecoins-accordion-earn-requirement-1": "Un portefeuille Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Vous aurez besoin d'un portefeuille pour recevoir les stablecoins que vous avez gagnés",
  "page-stablecoins-accordion-earn-requirements-description": "Les stablecoins sont une excellente méthode de paiement pour le travail et les services, parce que leur valeur est stable. Mais vous aurez besoin d'un portefeuille pour être payé.",
  "page-stablecoins-accordion-earn-text-preview": "Vous pouvez gagner des stablecoins en travaillant sur des projets dans l'écosystème Ethereum.",
  "page-stablecoins-accordion-earn-title": "Gagner",
  "page-stablecoins-accordion-less": "Moins",
  "page-stablecoins-accordion-more": "Plus",
  "page-stablecoins-accordion-requirements": "Ce dont vous aurez besoin",
  "page-stablecoins-accordion-swap-dapp-intro": "Si vous avez déjà des ETH et un portefeuille, vous pouvez utiliser ces DApps pour échanger contre des stablecoins.",
  "page-stablecoins-accordion-swap-dapp-link": "Plus d'informations sur les échanges décentralisés",
  "page-stablecoins-accordion-swap-dapp-title": "DApps pour échanger des jetons",
  "page-stablecoins-accordion-swap-editors-tip": "Conseil des éditeurs",
  "page-stablecoins-accordion-swap-editors-tip-button": "Trouver un portefeuille",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Procurez-vous un portefeuille qui vous permettra d'acheter des ETH et de les échanger directement contre des jetons, y compris des stablecoins.",
  "page-stablecoins-accordion-swap-pill": "Recommandé",
  "page-stablecoins-accordion-swap-requirement-1": "Un portefeuille Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Vous aurez besoin d'un portefeuille pour autoriser l'échange et stocker vos pièces",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Pour payer l'échange",
  "page-stablecoins-accordion-swap-text-preview": "Vous pouvez trouver la plupart des stablecoins sur les échanges décentralisés. Vous pouvez donc échanger tous les jetons que vous pourriez avoir pour une stablecoin que vous voulez.",
  "page-stablecoins-accordion-swap-title": "Échanger",
  "page-stablecoins-algorithmic": "Algorithmique",
  "page-stablecoins-algorithmic-con-1": "Vous devez avoir confiance (ou être en mesure de lire) l'algorithme.",
  "page-stablecoins-algorithmic-con-2": "Votre solde de pièces changera en fonction de la quantité totale.",
  "page-stablecoins-algorithmic-description": "Ces stablecoins ne sont pas supportées par un autre actif. Au lieu de cela, un algorithme vendra des jetons si le prix tombe en dessous de la valeur désirée et fournira d'autres jetons si la valeur va au-delà du montant souhaité. Parce que le nombre de ces jetons en circulation change régulièrement, le nombre de jetons que vous possédez changera, mais reflètera toujours votre quote-part.",
  "page-stablecoins-algorithmic-pro-1": "Aucune garantie n'est nécessaire.",
  "page-stablecoins-algorithmic-pro-2": "Contrôlé par un algorithme public.",
  "page-stablecoins-bank-apy": "0.05 %",
  "page-stablecoins-bank-apy-source": "Le taux moyen payé par les banques sur des comptes d'épargne de base, assurés par le gouvernement fédéral, aux États-Unis.",
  "page-stablecoins-bank-apy-source-link": "Source",
  "page-stablecoins-bitcoin-pizza": "La célèbre pizza Bitcoin",
  "page-stablecoins-bitcoin-pizza-body": "En 2010, quelqu’un a acheté 2 pizzas pour 10 000 bitcoins. À l’époque, elles valaient environ 41 USD. Sur le marché d’aujourd’hui, cela représente des millions de dollars. Il existe beaucoup de transactions regrettables similaires dans l'histoire d'Ethereum. Les stablecoins résolvent ce problème, pour que vous puissiez profiter de votre pizza et conserver votre ETH.",
  "page-stablecoins-coin-price-change": "Variation du prix des pièces (30 derniers jours)",
  "page-stablecoins-crypto-backed": "Crypto adossé",
  "page-stablecoins-crypto-backed-con-1": "Moins stable que les stablecoins adossées à la monnaie fiduciaire.",
  "page-stablecoins-crypto-backed-con-2": "Vous devez garder un œil sur la valeur du crypto collatéral.",
  "page-stablecoins-crypto-backed-description": "Ces stablecoins sont adossées à d'autres crypto-actifs, comme l'ETH. Leur prix dépend de la valeur de l'actif sous-jacent (ou de la garantie), qui peut être volatile. Étant donné que la valeur de l'ETH peut fluctuer, ces pièces stables sont sur-collatéralisées pour s'assurer que le prix reste aussi stable que possible. Cela signifie qu'il est plus précis de dire qu'une stablecoin crypto-adossée à 1$ a en fait un actif de cryptomonnaie sous-jacent d'une valeur d'au moins 2 $. Donc, si le prix de l'ETH baisse, plus d'ETH doit être utilisé pour soutenir la stablecoin, sinon les stablecoins perdront leur valeur.",
  "page-stablecoins-crypto-backed-pro-1": "Transparent et entièrement décentralisé.",
  "page-stablecoins-crypto-backed-pro-2": "Rapide à convertir en d'autres actifs de cryptomonnaie.",
  "page-stablecoins-crypto-backed-pro-3": "Aucun tiers de confiance externe – tous les actifs sont contrôlés par des comptes Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai est probablement la stablecoin décentralisée la plus célèbre. Sa valeur est à peu près de un dollar et elle est largement acceptée dans les DApps.",
  "page-stablecoins-dai-banner-learn-button": "En savoir plus sur Dai",
  "page-stablecoins-dai-banner-swap-button": "Échanger des ETH pour Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Logo Dai",
  "page-stablecoins-editors-choice": "Choix de la rédaction",
  "page-stablecoins-editors-choice-intro": "Ce sont probablement les exemples les plus connus de stablecoins en ce moment et les pièces que nous avons trouvées utiles lors de l'utilisation de DApps.",
  "page-stablecoins-explore-dapps": "Explorez des applications décentralisées",
  "page-stablecoins-fiat-backed": "Adossé à une monnaie fiduciaire",
  "page-stablecoins-fiat-backed-con-1": "Centralisé : les jetons doivent être émis par quelqu'un.",
  "page-stablecoins-fiat-backed-con-2": "Nécessite un audit pour s'assurer que l'entreprise dispose de réserves suffisantes.",
  "page-stablecoins-fiat-backed-description": "Fondamentalement, un JVD (je vous dois ou IOU (i owe you en anglais)) pour une monnaie fiduciaire traditionnelle (généralement des dollars). Vous utilisez votre monnaie fiduciaire pour acheter une stablecoin que vous pouvez plus tard encaisser et échanger contre votre monnaie d’origine.",
  "page-stablecoins-fiat-backed-pro-1": "Protégé contre la volatilité des cryptomonnaies.",
  "page-stablecoins-fiat-backed-pro-2": "Les variations de prix sont minimes.",
  "page-stablecoins-find-stablecoin": "Trouver une stablecoin",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Comment obtenir des stablecoins",
  "page-stablecoins-find-stablecoin-intro": "Il existe des centaines de stablecoins disponibles. En voici quelques-unes pour vous aider à vous lancer. Si vous débutez tout juste sur Ethereum, nous vous recommandons de commencer par faire quelques recherches.",
  "page-stablecoins-find-stablecoin-types-link": "Différents types de stablecoin",
  "page-stablecoins-get-stablecoins": "Comment obtenir des stablecoins",
  "page-stablecoins-hero-alt": "Les trois plus grandes stablecoins par capitalisation boursière : Dai, USDC et Tether.",
  "page-stablecoins-hero-button": "Obtenir des stablecoins",
  "page-stablecoins-hero-header": "Argent numérique pour une utilisation quotidienne",
  "page-stablecoins-hero-subtitle": "Les stablecoins sont des jetons Ethereum conçus pour garder une valeur fixe, même lorsque le prix de l'ETH change.",
  "page-stablecoins-interest-earning-dapps": "Gagner des intérêts avec les DApps",
  "page-stablecoins-meta-description": "Introduction aux stablecoins Ethereum : qu'est-ce que c'est, comment en obtenir, et pourquoi sont-elles importantes.",
  "page-stablecoins-precious-metals": "Métaux précieux",
  "page-stablecoins-precious-metals-con-1": "Centralisé : les jetons doivent être émis par quelqu'un.",
  "page-stablecoins-precious-metals-con-2": "Vous devez faire confiance à l'émetteur de jetons et aux réserves de métal précieux.",
  "page-stablecoins-precious-metals-description": "Sur le même principe que les jetons adossés à une monnaie fiduciaire, ces stablecoins utilisent quant à elles des ressources comme l'or pour maintenir leur valeur.",
  "page-stablecoins-precious-metals-pro-1": "Protégé contre la volatilité des cryptomonnaies.",
  "page-stablecoins-prices": "Prix des stablecoins",
  "page-stablecoins-prices-definition": "Les stablecoins sont des cryptomonnaies sans volatilité. Elles partagent beaucoup des mêmes pouvoirs que l'ETH mais leur valeur est stable, plus comme une monnaie traditionnelle. Ainsi, vous avez accès à de l'argent stable que vous pouvez utiliser sur Ethereum. ",
  "page-stablecoins-prices-definition-how": "Comment les stablecoins obtiennent leur stabilité",
  "page-stablecoins-research-warning": "Ethereum est une nouvelle technologie et la plupart des applications sont nouvelles. Assurez-vous d'être au courant du risque et de ne déposer que ce que vous pouvez vous permettre de perdre.",
  "page-stablecoins-research-warning-title": "Faites toujours vos propres recherches",
  "page-stablecoins-save-stablecoins": "Économisez avec des stablecoins",
  "page-stablecoins-save-stablecoins-body": "Les stablecoins ont souvent un taux d'intérêt supérieur à la moyenne, car il y a beaucoup de demande pour les emprunter. Il existe des DApps qui vous permettent de gagner des intérêts sur vos stablecoins en temps réel en les déposant dans un groupe de prêts. Tout comme dans le monde bancaire, vous fournissez des jetons aux emprunteurs, mais vous pouvez retirer vos jetons et vos intérêts à tout moment.",
  "page-stablecoins-saving": "Mettez à profit vos économies de stablecoin et gagnez quelques intérêts. Comme tout ce qui est en cryptomonnaie, le pourcentage annuel de rendement prédit (APY) peut changer au jour le jour en fonction de l'offre ou de la demande en temps réel.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Découvrez les DApps d'Ethereum – les stablecoins sont souvent plus utiles pour les transactions quotidiennes.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Illustration d'un doge.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Utilisez vos stablecoins",
  "page-stablecoins-stablecoins-dapp-description-1": "Des marchés pour beaucoup de stablecoins, y compris Dai, USDC, TUSD, USDT, et plus. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Prêtez des stablecoins et gagnez des intérêts et des $COMP, le jeton propre de Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Une plateforme de trading où vous pouvez gagner des intérêts sur vos Dai et USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Une application conçue pour épargner des Dai.",
  "page-stablecoins-stablecoins-feature-1": "Les stablecoins ne sont pas limitées par les frontières traditionnelles et peuvent être envoyées par Internet. Elles sont faciles à recevoir ou envoyer une fois que vous avez un compte Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "La demande pour les stablecoins est élevée, vous pouvez donc gagner des intérêts en prêtant les vôtres. Assurez-vous de bien connaître et comprendre les risques encourus avant de prêter.",
  "page-stablecoins-stablecoins-feature-3": "Les stablecoins peuvent être échangées contre des ETH et d'autres jetons Ethereum. De nombreuses DApps dépendent des stablecoins.",
  "page-stablecoins-stablecoins-feature-4": "Les stablecoins sont sécurisées par la cryptographie. Personne ne peut forger des transactions en votre nom.",
  "page-stablecoins-stablecoins-meta-description": "Introduction aux stablecoins Ethereum : qu'est-ce que c'est, comment en obtenir, et pourquoi sont-elles importantes.",
  "page-stablecoins-stablecoins-table-header-column-1": "Devise",
  "page-stablecoins-stablecoins-table-header-column-2": "Capitalisation boursière",
  "page-stablecoins-stablecoins-table-header-column-3": "Type de collatéral",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Crypto",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Monnaie fiduciaire",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Métaux précieux",
  "page-stablecoins-table-error": "Impossible de charger les stablecoins. Essayez de rafraîchir la page.",
  "page-stablecoins-table-loading": "Chargement des données de stablecoin...",
  "page-stablecoins-title": "Stablecoins",
  "page-stablecoins-top-coins": "Top des stablecoins par capitalisation boursière",
  "page-stablecoins-top-coins-intro": "La capitalisation boursière est",
  "page-stablecoins-top-coins-intro-code": "le nombre total de jetons qui existent, multiplié par la valeur d'un jeton. Cette liste évolue constamment et les projets énumérés ici ne sont pas nécessairement soutenus par l'équipe ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Comment ça fonctionne : les types de stablecoin",
  "page-stablecoins-usdc-banner-body": "L'USDC est probablement la plus célèbre stablecoin soutenue par monnaie fiduciaire. Sa valeur est à peu près de un dollar et elle est soutenue par Circle et Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "En savoir plus sur USDC",
  "page-stablecoins-usdc-banner-swap-button": "Échanger des ETH pour USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Le logo USDC",
  "page-stablecoins-why-stablecoins": "Pourquoi les stablecoins ?",
  "page-stablecoins-how-they-work-button": "Comment ça marche ?",
  "page-stablecoins-why-stablecoins-body": "L'ETH, comme le Bitcoin, a un prix volatile parce que c'est une nouvelle technologie. Vous pouvez donc ne pas vouloir le dépenser trop souvent. Les stablecoins reflètent la valeur des monnaies traditionnelles pour vous donner accès à de l'argent stable que vous pouvez utiliser sur Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Si vous souhaitez que nous ajoutions un portefeuille,",
  "page-find-wallet-alpha-logo-alt": "Logo AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Logo Ambo",
  "page-find-wallet-argent-logo-alt": "Logo Argent",
  "page-find-wallet-buy-card": "Acheter des cryptomonnaies avec une carte de crédit",
  "page-find-wallet-buy-card-desc": "Achetez des ETH directement à partir de votre portefeuille avec une carte bancaire. Des restrictions géographiques peuvent s'appliquer.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Découvrez les DApps",
  "page-find-wallet-clear": "Réinitialiser les filtres",
  "page-find-wallet-coinbase-logo-alt": "Logo Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Choisissez donc votre portefeuille en fonction des fonctionnalités que vous souhaitez.",
  "page-find-wallet-description": "Les portefeuilles ont beaucoup de fonctionnalités facultatives que vous pourriez aimer.",
  "page-find-wallet-description-alpha": "Portefeuille Ethereum entièrement open source qui tire parti de l'enclave sécurisée sur mobile, offre une prise en charge complète du réseau de test et intègre la norme TokenScript.",
  "page-find-wallet-description-ambo": "Devenez investisseur et réalisez votre premier investissement en quelques minutes après le téléchargement de l'application",
  "page-find-wallet-description-argent": "Un simple clic pour gagner des intérêts et investir; emprunter, stocker et envoyer. Appropriez-le vous.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "L'application sécurisée pour stocker des cryptomonnaies vous-même",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma est votre portail vers la Finance Décentralisée. Plus besoin de seed phrases ni d'extension Chrome.",
  "page-find-wallet-description-enjin": "Impénétrable, complet et pratique, conçu pour les traders, les gamers et les développeurs",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "La plateforme la plus fiable pour stocker des ressources numériques sur Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken est un portefeuille numérique facile et sécurisé approuvé par des millions de personnes",
  "page-find-wallet-description-ledger": "Protégez vos actifs avec les normes de sécurité les plus élevées",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Commencez à explorer les applications blockchain en quelques secondes. Reconnu par plus d'un million d'utilisateurs dans le monde entier.",
  "page-find-wallet-description-monolith": "Le seul portefeuille au monde avec carte de débit Visa. Disponible au Royaume-Uni et en Europe et utilisable dans le monde entier.",
  "page-find-wallet-description-multis": "Multis est un compte de cryptomonnaie conçu pour les entreprises. Avec Multis, les entreprises peuvent stocker avec des contrôles d'accès, gagner des intérêts sur leurs épargnes et rationaliser leurs paiement et leur comptabilité.",
  "page-find-wallet-description-mycrypto": "MyCrypto est une interface qui vous permet de gérer tous vos comptes. Echangez, envoyez et achetez des cryptos avec des portefeuilles comme Metamask, Ledger, Trezor et plus encore.",
  "page-find-wallet-description-myetherwallet": "Une interface client gratuite qui vous aide à interagir avec la blockchain Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Un portefeuille libre de tout contrôle et possédé par sa propre communauté avec son réseau de paiement L2.",
  "page-find-wallet-description-portis": "Le portefeuille blockchain indépendant qui rend l'utilisation des applications facile pour tout le monde",
  "page-find-wallet-description-rainbow": "Un foyer chaud et douillet vos pièces Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Une application de messagerie sécurisée, un portefeuille de cryptomonnaies et un navigateur Web3 conçus avec une technologie de pointe",
  "page-find-wallet-description-tokenpocket": "TokenPocket : un portefeuille de monnaies numériques sûr, pratique et mondialement reconnu ainsi qu'un portail vers les DApps, avec un support multichaîne.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Connexion en un clic pour le Web 3.0",
  "page-find-wallet-description-trezor": "Le premier portefeuille matériel",
  "page-find-wallet-description-trust": "Trust Wallet est un portefeuille de cryptomonnaies décentralisé et multimonnaies. Achetez des cryptomonnaies, explorez les DApps, échangez des actifs et plus, tout en gardant le contrôle de vos clés.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo est le premier portefeuille de cryptomonnaies sans clé. Avec ZenGo, il n'y a pas de clé privée, de mot de passe ni de seed phrases à gérer ou que l'on risque de perdre. Achetez, échangez, gagnez et stockez des Ethereum avec une simplicité et une sécurité sans précédent",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Logo Dharma",
  "page-find-wallet-enjin-logo-alt": "Logo Enjin",
  "page-find-wallet-Ethereum-wallets": "Portefeuilles Ethereum",
  "page-find-wallet-explore-dapps": "Explorez des applications décentralisées",
  "page-find-wallet-explore-dapps-desc": "Ces portefeuilles ont été conçus pour vous aider à vous connecter aux DApps d'Ethereum.",
  "page-find-wallet-feature-h2": "Choisissez les fonctionnalités du portefeuilles qui vous intéressent",
  "page-find-wallet-fi-tools": "Accès aux outils financiers",
  "page-find-wallet-fi-tools-desc": "Empruntez, prêtez et gagnez des intérêts directement depuis votre portefeuille.",
  "page-find-wallet-following-features": "avec les fonctionnalités suivantes :",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Logo Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Image hero Trouver un portefeuille",
  "page-find-wallet-imtoken-logo-alt": "Logo imToken",
  "page-find-wallet-last-updated": "Dernière mise à jour",
  "page-find-wallet-ledger-logo-alt": "Logo Ledger",
  "page-find-wallet-limits": "Limites de protection",
  "page-find-wallet-limits-desc": "Protégez vos actifs en définissant des limites qui empêchent votre compte d'être vidé.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "règles de listage",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Trouvez et comparez les portefeuilles Ethereum en fonction des fonctionnalités que vous souhaitez.",
  "page-find-wallet-meta-title": "Trouver un portefeuille Ethereum",
  "page-find-wallet-metamask-logo-alt": "Logo MetaMask",
  "page-find-wallet-monolith-logo-alt": "Logo Monolith",
  "page-find-wallet-multis-logo-alt": "Logo Multis",
  "page-find-wallet-multisig": "Comptes multisignatures",
  "page-find-wallet-multisig-desc": "Pour plus de sécurité, les portefeuilles multisignatures requièrent plusieurs comptes pour autoriser certaines transactions.",
  "page-find-wallet-mycrypto-logo-alt": "Logo MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Logo MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Néophyte total en portefeuilles ? Voici un aperçu pour vous aider à démarrer.",
  "page-find-wallet-new-to-wallets-link": "Portefeuilles Ethereum",
  "page-find-wallet-not-all-features": "Aucun portefeuille ne possède toutes ces fonctionnalités",
  "page-find-wallet-not-endorsements": "Les portefeuilles listés sur cette page ne sont pas officiellement approuvés et vous sont présentés à titre informatif uniquement. Leurs descriptions ont été fournies par les entreprises de portefeuilles elles-mêmes. Nous ajoutons des produits sur cette page sur base des critères de notre",
  "page-find-wallet-overwhelmed": "Portefeuilles Ethereum ci-dessous. Trop de choix tue le choix ? Essayez de filtrer par fonctionnalité.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Logo Pillar",
  "page-find-wallet-portis-logo-alt": "Logo Portis",
  "page-find-wallet-rainbow-logo-alt": "Logo Rainbow",
  "page-find-wallet-raise-an-issue": "Créer une 'issue' sur GitHub",
  "page-find-wallet-search-btn": "Recherchez les fonctionnalités sélectionnées",
  "page-find-wallet-showing": "Affiché ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Logo Status",
  "page-find-wallet-swaps": "Échanges de jetons décentralisés",
  "page-find-wallet-swaps-desc": "Tradez entre ETH et d'autres jetons directement depuis votre portefeuille.",
  "page-find-wallet-title": "Trouver un portefeuille",
  "page-find-wallet-tokenpocket-logo-alt": "Logo TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Logo Torus",
  "page-find-wallet-trezor-logo-alt": "Logo Trezor",
  "page-find-wallet-trust-logo-alt": "Logo Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Essayez de supprimer une ou deux fonctionnalités",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Maintenant que vous possédez un portefeuille, consultez quelques applications Ethereum (DApps). Il existe des DApps pour la finance, les réseaux sociaux, les jeux et encore beaucoup d'autres catégories.",
  "page-find-wallet-use-your-wallet": "Utilisez votre portefeuille",
  "page-find-wallet-voluem-desc": "Si vous voulez détenir beaucoup d'ETH, choisissez le portefeuille qui vous permet d'acheter plus de 2000 $ d'ETH à la fois.",
  "page-find-wallet-volume": "Volume important d'achats",
  "page-find-wallet-we-found": "Nous avons trouvé",
  "page-find-wallet-withdraw": "Retrait bancaire",
  "page-find-wallet-withdraw-desc": "Vous avez la possibilité d'encaisser vos ETH directement sur votre compte en banque sans devoir passer par une plateforme d'échange.",
  "page-find-wallet-yet": "encore",
  "page-find-wallet-zengo-logo-alt": "Logo ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Mettre en jeu de l'ETH",
  "page-wallets-accounts-addresses": "Portefeuilles, comptes et adresses",
  "page-wallets-accounts-addresses-desc": "C’est important de comprendre la différence entre certains mots clés.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Illustration d'un robot ayant pour corps un coffre, représentant un portefeuille Ethereum",
  "page-wallets-ethereum-account": "Compte Ethereum",
  "page-wallets-blog": "Blog de Coinbase",
  "page-wallets-bookmarking": "Ajoutez votre portefeuille aux favoris",
  "page-wallets-bookmarking-desc": "Si vous utilisez un portefeuille en ligne, ajoutez le site dans vos favoris afin de vous protéger des arnaques par fishing.",
  "page-wallets-cd": "Les portefeuilles matériels physiques qui vous permettent de garder vos cryptomonnaies hors ligne - très sécurisés",
  "page-wallets-converted": "Conversion de cryptomonnaie ?",
  "page-wallets-converted-desc": "Si vous désirez détenir de grosses sommes, nous vous recommandons d'utiliser un portefeuille matériel car ceux-ci sont les plus sécurisés. Ou un portefeuille comportant des limites de retrait ainsi que des alertes en cas de tentative de fraude.",
  "page-wallets-curious": "Crypto-curieux ?",
  "page-wallets-curious-desc": "Si vous êtes nouveau en cryptomonnaie et que vous voulez juste avoir une première impression, pour cela, nous vous recommandons quelque chose qui vous donnera la possibilité d'explorer des applications Ethereum ou d'acheter votre premier ETH directement depuis le portefeuille.",
  "page-wallets-desc-2": "Vous avez besoin d'un portefeuille pour envoyer des fonds et gérer votre ETH.",
  "page-wallets-desc-2-link": "Plus d'infos sur ETH",
  "page-wallets-desc-3": "Votre portefeuille est seulement un outil pour gérer votre compte Ethereum. Cela signifie que vous pouvez changer de fournisseur de portefeuille à tout moment. Beaucoup de portefeuilles vous permettent également de gérer plusieurs comptes Ethereum depuis une seule application.",
  "page-wallets-desc-4": "C'est parce que les portefeuilles n'ont aucun pouvoir sur vos fonds, seul vous l'avez. Ils ne sont qu'un instrument pour gérer ce qui vous appartient réellement.",
  "page-wallets-description": "Les portefeuilles Ethereum sont des applications qui vous permettent d'interagir avec votre compte Ethereum. Pensez à cela comme une application de services bancaires en ligne – sans la banque. Votre portefeuille vous permet de lire votre solde, d'envoyer des transactions et de vous connecter à des applications.",
  "page-wallets-desktop": "Applications de bureau si vous préférez gérer vos fonds via MacOS, Windows ou Linux",
  "page-wallets-ethereum-wallet": "Un portefeuille",
  "page-wallets-explore": "Explorer Ethereum",
  "page-wallets-features-desc": "Nous pouvons vous aider à choisir votre portefeuille en fonction des fonctionnalités qui sont importantes pour vous.",
  "page-wallets-features-title": "Vous préférez choisir en fonction des fonctionnalités ?",
  "page-wallets-find-wallet-btn": "Trouver un portefeuille",
  "page-wallets-find-wallet-link": "Trouver un portefeuille",
  "page-wallets-get-some": "Obtenir de l'ETH",
  "page-wallets-get-some-alt": "Une illustration d'une main fabriquant un symbole ETH au moyen de briques de lego",
  "page-wallets-get-some-btn": "Obtenir de l'ETH",
  "page-wallets-get-some-desc": "ETH est la cryptomonnaie originaire d'Ethereum. Vous aurez besoin d'ETH dans votre portefeuille pour utiliser des applications Ethereum.",
  "page-wallets-get-wallet": "Obtenir votre portefeuille",
  "page-wallets-get-wallet-desc": "Il existe beaucoup de portefeuilles différents parmi lesquels vous pouvez choisir. Nous voulons vous aider à choisir le meilleur pour vous.",
  "page-wallets-get-wallet-desc-2": "N'oubliez pas : cette décision n'est pas éternelle - votre compte Ethereum n'est pas lié à votre fournisseur de portefeuille.",
  "page-wallets-how-to-store": "Comment stocker des actifs numériques sur Ethereum",
  "page-wallets-keys-to-safety": "Les clés pour garder votre cryptomonnaie en sécurité",
  "page-wallets-manage-funds": "Une application pour gérer vos fonds",
  "page-wallets-manage-funds-desc": "Votre portefeuille montre vos soldes, l'historique des transactions et vous donne un moyen d'envoyer/recevoir des fonds. Certains portefeuilles peuvent en offrir plus.",
  "page-wallets-meta-description": "Ce que vous avez besoin de savoir pour utiliser des portefeuilles Ethereum.",
  "page-wallets-meta-title": "Portefeuilles Ethereum",
  "page-wallets-mobile": "Des applications mobiles qui rendent vos fonds accessibles depuis n'importe où",
  "page-wallets-more-on-dapps-btn": "En savoir plus sur les DApps",
  "page-wallets-most-wallets": "La plupart des produits de portefeuille vous permettront de générer un compte Ethereum. Vous n'en aurez donc pas besoin avant de télécharger un portefeuille.",
  "page-wallets-protecting-yourself": "Protéger vos fonds et vous-même",
  "page-wallets-seed-phrase": "Notez votre phrase de sécurité",
  "page-wallets-seed-phrase-desc": "Les portefeuilles vous donneront souvent une phrase de sécurité que vous devez écrire dans un endroit sûr. C'est la seule façon de récupérer votre portefeuille.",
  "page-wallets-seed-phrase-example": "Voici un exemple :",
  "page-wallets-seed-phrase-snippet": "il y a des courbes de formation de ventilation d'avion produit possible distincte sous lampe à l'esprit (exemple de phrase de sécurité)",
  "page-wallets-seed-phrase-write-down": "Ne la stockez pas sur un ordinateur. Notez-la et gardez-la en sécurité.",
  "page-wallets-slogan": "La clé de votre avenir numérique",
  "page-wallets-stay-safe": "Comment rester en sécurité",
  "page-wallets-stay-safe-desc": "Les portefeuilles sont un nouveau paradigme bancaire. La liberté financière et la capacité d'accéder aux fonds et à les utiliser n'importe où s'accompagnent d'une certaine rigueur et d'une prise de responsabilité. Il n'y a pas d'assistance à la clientèle en cryptomonnaie.",
  "page-wallets-subtitle": "Les portefeuilles donnent accès à vos fonds et applications Ethereum. Seul vous devriez avoir accès à votre portefeuille.",
  "page-wallets-take-responsibility": "Devenir responsable de ses propres fonds",
  "page-wallets-take-responsibility-desc": "Les échanges centralisés relieront votre portefeuille à un nom d'utilisateur et un mot de passe que vous pourrez récupérer de façon traditionnelle. N'oubliez pas que vous faites confiance à cet échange avec la garde de vos fonds. Si cette entreprise est attaquée ou pillée, vos fonds sont à risque.",
  "page-wallets-tips": "Plus de conseils pour rester en sécurité",
  "page-wallets-tips-community": "De la communauté",
  "page-wallets-title": "Portefeuilles Ethereum",
  "page-wallets-triple-check": "Vérifier tout au moins 3 fois",
  "page-wallets-triple-check-desc": "N'oubliez pas que les transactions ne peuvent pas être inversées et que les portefeuilles ne peuvent pas être récupérés facilement, alors prenez garde et soyez responsable.",
  "page-wallets-try-dapps": "Essayer quelques DApps",
  "page-wallets-try-dapps-alt": "Une illustration des membres de la communauté Ethereum travaillant ensemble",
  "page-wallets-try-dapps-desc": "Les DApps sont des applications basées sur Ethereum. Elles sont moins chères, plus justes et moins gourmandes en données que la plupart des applications traditionnelles.",
  "page-wallets-types": "Types de portefeuille",
  "page-wallets-web-browser": "Un portefeuille Web qui vous permet d'interagir avec votre compte via un navigateur Web",
  "page-wallets-whats-a-wallet": "Qu'est-ce qu'un portefeuille Ethereum ?",
  "page-wallets-your-ethereum-account": "Votre compte Ethereum",
  "page-wallets-your-ethereum-account-desc": "Votre portefeuille est votre fenêtre sur votre compte Ethereum - votre solde, l'historique des transactions et bien plus. Mais vous pouvez changer de fournisseur de portefeuille à tout moment.",
  "page-wallets-your-login": "Votre compte pour les applications Ethereum",
  "page-wallets-your-login-desc": "Votre portefeuille vous permet de vous connecter à n'importe quelle application décentralisée à l'aide de votre compte Ethereum. C'est comme une connexion que vous pouvez utiliser sur plusieurs DApps.",
  "page-what-is-ethereum-101": "Le B-A-BA de l'Ethereum",
  "page-what-is-ethereum-101-desc": "Ethereum est une technologie qui vous permet d'envoyer de la cryptomonnaie à n'importe qui moyennant de petits frais. Elle permet également de gérer des applications permanentes et utilisables par tous.",
  "page-what-is-ethereum-101-desc-2": "Ethereum s'appuie sur l'innovation du Bitcoin, avec quelques différences majeures.",
  "page-what-is-ethereum-101-desc-3": "Tous deux vous permettent d'utiliser de l'argent virtuel sans banque ni prestataire de paiement. En revanche, Ethereum est programmable, donc vous pouvez également l'utiliser pour de nombreux actifs numériques différents – même du Bitcoin !",
  "page-what-is-ethereum-101-desc-4": "Cela signifie également qu'Ethereum permet plus que des paiements. Il s'agit d'un marché de services financiers, de jeux et d'applications qui ne peuvent pas vous pister ou vous censurer.",
  "page-what-is-ethereum-101-italic": "la blockchain programmable du monde entier.",
  "page-what-is-ethereum-101-strong": "C'est ",
  "page-what-is-ethereum-accessibility": "Ethereum est ouvert à tous.",
  "page-what-is-ethereum-adventure": "Choisissez votre aventure !",
  "page-what-is-ethereum-alt-img-bazaar": "Illustration d'une personne collaborant à un bazar, destinée à représenter l'Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Une illustration des membres de la communauté Ethereum travaillant ensemble",
  "page-what-is-ethereum-alt-img-lego": "Une illustration d'une main fabriquant un symbole ETH au moyen de briques de lego",
  "page-what-is-ethereum-alt-img-social": "Une illustration de personnages dans un espace social dédié à Ethereum avec un grand logo ETH",
  "page-what-is-ethereum-banking-card": "Services bancaires pour tous",
  "page-what-is-ethereum-banking-card-desc": "Tout le monde n'a pas accès aux services financiers, mais tout ce dont vous avez besoin pour accéder à Ethereum et à ses produits de prêt, d'emprunt et d'épargne est une connexion Internet.",
  "page-what-is-ethereum-build": "Créez avec Ethereum",
  "page-what-is-ethereum-build-desc": "Si vous voulez essayer de développer avec Ethereum, consultez notre documentation, essayez quelques tutoriels, ou jetez un œil aux outils nécessaires pour commencer.",
  "page-what-is-ethereum-censorless-card": "Résistant à la censure",
  "page-what-is-ethereum-censorless-card-desc": "Aucun gouvernement ou entreprise n'a de contrôle sur Ethereum. Cette décentralisation rend pratiquement impossible à quiconque de vous empêcher de recevoir des paiements ou d'utiliser des services sur Ethereum.",
  "page-what-is-ethereum-comm-desc": "Notre communauté comprend des personnes de tous horizons, y compris des artistes, des crypto-anarchistes, des entreprises du Fortune 500, et maintenant vous. Découvrez comment vous pouvez participer dès maintenant.",
  "page-what-is-ethereum-commerce-card": "Garanties de commerce",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum crée un terrain de jeu plus équitable. Les clients ont une garantie sécurisée et intégrée que les fonds ne changeront de main que si vous fournissez ce qui a été convenu. Vous pouvez faire des affaires sans que les grandes entreprises ne s'en mêlent.",
  "page-what-is-ethereum-community": "La communauté Ethereum",
  "page-what-is-ethereum-compatibility-card": "La compatibilité avant tout",
  "page-what-is-ethereum-compatibility-card-desc": "De meilleurs produits et expériences sont créés en permanence grâce à la compatibilité par défaut des produits Ethereum. Les entreprises peuvent donc se baser sur le succès de chacun.",
  "page-what-is-ethereum-dapps-desc": "Des produits et services fonctionnant sur Ethereum. Il existe des DApps pour la finance, les réseaux sociaux, les jeux et bien plus encore – découvrez les applications de l'avenir.",
  "page-what-is-ethereum-dapps-img-alt": "Une illustration d'un doge utilisant une application Ethereum sur un ordinateur",
  "page-what-is-ethereum-dapps-title": "Les DApps Ethereum",
  "page-what-is-ethereum-desc": "La base de notre avenir numérique",
  "page-what-is-ethereum-explore": "Explorer Ethereum",
  "page-what-is-ethereum-get-started": "Le meilleur moyen d'en apprendre davantage est de télécharger un portefeuille, d'obtenir quelques ETH et d'essayer une DApp Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum est un accès ouvert à la monnaie numérique et à des services conviviaux pour tout le monde, qui que vous soyez. C'est une technologie construite par la communauté derrière la cryptomonnaie ether (ETH) et des milliers d'applications que vous pouvez utiliser dès aujourd'hui.",
  "page-what-is-ethereum-internet-card": "Un internet plus respectueux de la vie privée",
  "page-what-is-ethereum-internet-card-desc": "Vous n'avez pas besoin de fournir toutes vos données personnelles pour utiliser une application Ethereum. Ethereum construit une économie basée sur la valeur, pas sur la surveillance.",
  "page-what-is-ethereum-meet-comm": "Rencontrez notre communauté",
  "page-what-is-ethereum-meta-description": "Apprenez-en plus sur Ethereum, ce qu'elle fait et comment l'essayer par vous-même.",
  "page-what-is-ethereum-meta-title": "Qu'est-ce qu'Ethereum ?",
  "page-what-is-ethereum-native-alt": "Le symbole pour l'éther (ETH)",
  "page-what-is-ethereum-native-crypto": "La cryptomonnaie native d'Ethereum et l'équivalent de Bitcoin. Vous pouvez utiliser de l'ETH sur les applications Ethereum ou comme monnaie à envoyer à vos amis et à votre famille.",
  "page-what-is-ethereum-native-img-alt": "Une illustration de robot avec un coffre-fort pour un torse, utilisée pour représenter les portefeuilles Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Un réseau P2P",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum vous permet de transférer de l'argent ou de passer des accords, directement avec quelqu'un d'autre. Vous n'avez pas besoin de passer par des sociétés intermédiaires.",
  "page-what-is-ethereum-singlecard-desc": "Si vous êtes intéressé par la blockchain et le côté technique d'Ethereum, on a ce qu'il vous faut.",
  "page-what-is-ethereum-singlecard-link": "Fonctionnement d'Ethereum",
  "page-what-is-ethereum-singlecard-title": "Fonctionnement d'Ethereum",
  "page-what-is-ethereum-start-building-btn": "Commencez à créer",
  "page-what-is-ethereum-title": "Qu'est-ce qu'Ethereum ?",
  "page-what-is-ethereum-tools-needed": "Tout ce qu'il vous faut pour participer est un portefeuille.",
  "page-what-is-ethereum-try": "Essayer Ethereum",
  "page-what-is-ethereum-tryit": "Entrez dans notre humble bazar et laissez-vous tenter...",
  "page-what-is-ethereum-wallets": "Portefeuilles",
  "page-what-is-ethereum-wallets-desc": "Comment vous gérez votre ETH et votre compte Ethereum. Vous aurez besoin d'un portefeuille pour commencer – on va vous aider à en choisir un.",
  "page-what-is-ethereum-welcome": "Bienvenue sur Ethereum",
  "page-what-is-ethereum-welcome-2": "Nous espérons que vous resterez.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "La mise en jeu est ouverte ! Si vous cherchez à miser vos ETH",
  "banner-staking-2": "confirmez l'adresse du contrat de dépôt",
  "docking": "Arrimage (docking)",
  "page-eth2-vision-security-desc-9": "nous permet d'assigner aléatoirement des validateurs à différents fragments - ce qui rend virtuellement impossible la complicité des validateurs en attaquant un fragment spécifique. La fragmentation n'est pas aussi sécurisée sur une blockchain à preuve de travail, car les mineurs ne peuvent pas être contrôlés par le protocole de cette façon.",
  "page-index-sections-developers-desc": "Découvrez la technologie derrière Ethereum et ses applications afin de l'utiliser pour commencer à construire.",
  "page-index-sections-developers-image-alt": "Illustration d'une main construisant un glyphe Ethereum fait de briques de lego",
  "page-index-sections-developers-link-text": "Commencer à construire",
  "page-index-sections-developers-title": "Développeurs",
  "page-index-sections-enterprise-desc": "Découvrez comment Ethereum peut générer de nouveaux modèles économiques, réduire vos coûts et pérenniser votre activité.",
  "page-index-sections-enterprise-image-alt": "Illustration d'un groupe travaillant sur un projet Ethereum autour d'un ordinateur portable",
  "page-index-sections-enterprise-link-text": "Ethereum pour les entreprises",
  "page-index-sections-enterprise-title": "Entreprises",
  "page-index-sections-individuals-desc": "Découvrez Ethereum, l'Ether, les portefeuilles, les jetons et plus encore, pour pouvoir commencer à utiliser Les applications Ethereum.",
  "page-index-sections-individuals-image-alt": "Illustration d'un chien assis devant un ordinateur",
  "page-index-sections-individuals-link-text": "Débuter avec Ethereum",
  "page-index-sections-individuals-title": "À propos d'Ethereum",
  "page-index-subtitle": "Sur Ethereum, vous pouvez écrire du code qui contrôle un actif numérique, s'exécute exactement comme il a été programmé et est accessible partout dans le monde.",
  "page-find-wallet-authereum-logo-alt": "Logo Authereum",
  "page-find-wallet-description-authereum": "Aucun téléchargement, aucune phrase mnémotechnique. N'importe quel navigateur, à tout moment, mobile ou ordinateur",
  "page-wallets-accounts-addresses-desc-2": "est une entité qui peut envoyer des transactions et qui a un solde.",
  "page-wallets-accounts-has": "Un compte Ethereum dispose d'un",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "Adresse Ethereum",
  "page-wallets-ethereum-addresses-2": ", comme une boîte de réception a une adresse e-mail. Vous pouvez l'utiliser pour envoyer des fonds à un compte.",
  "page-wallets-ethereum-wallet-2": "est un produit qui vous permet de gérer votre compte Ethereum, comme voir le solde de votre compte, envoyer des transactions et plus encore."
}
