{
  "1inch-logo": "Logo da 1 pollice",
  "aave-logo": "Logo Aave",
  "about": "Informazioni",
  "about-ethereum-org": "Informazioni su ethereum.org",
  "about-us": "Chi siamo",
  "alt-eth-blocks": "Figura di blocchi organizzati come simbolo ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Torna all’inizio",
  "banner-page-incomplete": "Questa pagina è incompleta. Se conosci bene l'argomento, modificala e condividi un po' del tuo sapere.",
  "beacon-chain": "La beacon chain",
  "binance-logo": "Logo Binance",
  "bittrex-logo": "Logo Bittrex",
  "brand-assets": "Risorse del marchio",
  "bug-bounty": "Bug bounty",
  "coinbase-logo": "Logo Coinbase",
  "coinmama-logo": "Logo di Coinmama",
  "community": "Community",
  "community-menu": "Community Menu",
  "compound-logo": "Logo Coumpound",
  "cons": "Contro",
  "contact": "Contatti",
  "content-versions": "Content Versions",
  "contributing": "Contributi",
  "contributors": "Hanno contribuito",
  "contributors-thanks": "A tutti coloro che hanno contribuito a questa pagina: grazie!",
  "cookie-policy": "Politica sui cookie",
  "copied": "Copiato",
  "copy": "Copia",
  "dark-mode": "Modalità scura",
  "data-provided-by": "Fornitore contenuti",
  "decentralized-applications-dapps": "Applicazioni decentralizzate (dapps)",
  "deposit-contract": "Contratto di deposito",
  "devcon": "Devcon",
  "developers": "Sviluppatori",
  "developers-home": "Home page sviluppatori",
  "docs": "Documenti",
  "documentation": "Documentazione",
  "dydx-logo": "Logo Dydx",
  "ecosystem": "Ecosistema",
  "edit-page": "Modifica pagina",
  "ef-blog": "Blog della fondazione Ethereum",
  "eips": "Proposte di miglioramento Ethereum",
  "enterprise": "Aziende",
  "enterprise-menu": "Menu Aziende",
  "esp": "Programma di supporto ecosistema",
  "eth-current-price": "Prezzo attuale ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Esplorazione contenuti beacon chain Eth2 open source",
  "eth2-beaconscan-desc": "Esplorazione contenuti beacon chain Eth2 – Etherscan per Eth2",
  "eth2-become-staker": "Diventa uno staker",
  "eth2-become-staker-desc": "Ora è disponibile lo staking! Se vuoi fare staking con i tuoi ETH per contribuire a proteggere la rete, assicurati di essere consapevole dei rischi.",
  "eth2-explore": "Esplora i dati",
  "eth2-no-action-needed": "Non è necessario fare nulla con i ETH che già possiedi. Attenzione ai truffatori che ti chiedono di inviare ETH per scambiarli.",
  "eth2-run-beacon-chain": "Esegui un client beacon",
  "eth2-run-beacon-chain-desc": "Ethereum ha bisogno del maggior numero possibile di client. Contribuisci con questo bene pubblico Ethereum!",
  "eth2-service-announcement": "Comunicazione di servizio Eth2",
  "eth2-what-shipping": "Quando verrà rilasciato?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Risorse del marchio Ethereum",
  "ethereum-community": "Community Ethereum",
  "ethereum-foundation": "Ethereum Foundation",
  "ethereum-foundation-logo": "Logo Ethereum Foundation",
  "ethereum-glossary": "Glossario Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Logo Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Ethereum Studio",
  "ethereum-wallets": "Portafogli Ethereum",
  "ethereum-whitepaper": "Whitepaper Ethereum",
  "example-projects": "Progetti di esempio",
  "find-wallet": "Trova portafoglio",
  "foundation": "Fondazione",
  "gemini-logo": "Logo Gemini",
  "get-eth": "Ottieni ETH",
  "get-involved": "Partecipa",
  "get-started": "Primi passi",
  "gitcoin-logo": "Logo Gitcoin",
  "glossary": "Glossario",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Guide e risorse",
  "history": "Cronologia",
  "history-of-ethereum": "Storia di Ethereum",
  "home": "Home page",
  "how-ethereum-works": "Come funziona Ethereum",
  "image": "immagine",
  "in-this-section": "In questa sezione",
  "individuals": "Utenti privati",
  "individuals-menu": "Menu Utenti privati",
  "jobs": "Jobs",
  "kraken-logo": "Logo Kraken",
  "language-ar": "Arabo",
  "language-bg": "Bulgarian",
  "language-bn": "Bengali",
  "language-ca": "Catalan",
  "language-cs": "Ceco",
  "language-de": "Tedesco",
  "language-el": "Greco",
  "language-en": "Inglese",
  "language-es": "Spagnolo",
  "language-fa": "Farsi",
  "language-fi": "Finlandese",
  "language-fr": "Francese",
  "language-hu": "Ungherese",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonesiano",
  "language-ig": "Igbo",
  "language-it": "Italiano",
  "language-ja": "Giapponese",
  "language-ko": "Coreano",
  "language-lt": "Lituano",
  "language-ml": "Malayalam",
  "language-nb": "Norvegese",
  "language-nl": "Olandese",
  "language-pl": "Polacco",
  "language-pt": "Portoghese",
  "language-pt-br": "Portoghese (Brasile)",
  "language-ro": "Rumeno",
  "language-ru": "Russo",
  "language-se": "Svedese",
  "language-sk": "Slovacco",
  "language-sl": "Sloveno",
  "language-support": "Lingue supportate",
  "language-tr": "Turco",
  "language-uk": "Ucraino",
  "language-vi": "Vietnamita",
  "language-zh": "Cinese semplificato",
  "language-zh-tw": "Cinese tradizionale",
  "languages": "Lingue",
  "last-24-hrs": "Ultime 24 ore",
  "last-edit": "Ultima modifica",
  "learn": "Informazioni",
  "learn-by-coding": "Impara scrivendo codice",
  "learn-menu": "Menu Informazioni",
  "learn-more": "Maggiori informazioni",
  "less": "Meno",
  "light-mode": "Modalità chiara",
  "listing-policy-disclaimer": "Tutti i prodotti elencati in questa pagina non sono approvazioni ufficiali e sono forniti solo a scopo informativo. Per aggiungere un prodotto o fornire feedback sulla politica, apri una segnalazione in GitHub.",
  "listing-policy-raise-issue-link": "Apri segnalazione",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Caricamento in corso...",
  "loading-error": "Errore di caricamento.",
  "loading-error-refresh": "Errore nel caricare i dati. Prova ad aggiornare la pagina.",
  "logo": "logo",
  "loopring-logo": "Logo Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "rete principale Ethereum",
  "makerdao-logo": "Logo MakerDao",
  "matcha-logo": "Logo Matcha",
  "merge": "Merge",
  "more": "Altro",
  "nav-beginners": "Principianti",
  "next": "Avanti",
  "oasis-logo": "Logo Oasis",
  "on-this-page": "Su questa pagina",
  "page-content": "Contenuto della pagina",
  "page-enterprise": "Aziende",
  "page-last-updated": "Ultimo aggiornamento pagina",
  "previous": "Indietro",
  "privacy-policy": "Informativa sulla privacy",
  "private-ethereum": "Ethereum privato",
  "pros": "Pro",
  "read-more": "Ulteriori contenuti",
  "refresh": "Ricarica la pagina.",
  "review-progress": "Avanzamento revisione",
  "search": "Ricerca",
  "search-box-blank-state-text": "Pronti, partenza, cerca!",
  "search-eth-address": "Questo sembrerebbe un indirizzo Ethereum. Non forniamo dati specifici per gli indirizzi. Prova a cercarlo su un Block Explorer come",
  "search-no-results": "Nessun risultato per questa ricerca",
  "security": "Security",
  "see-contributors": "Visualizza chi ha contribuito",
  "set-up-local-env": "Configura l'ambiente locale",
  "shard-chains": "Le shard chain",
  "show-all": "Mostra tutto",
  "show-less": "Mostra meno",
  "site-description": "Ethereum è una piattaforma globale e decentralizzata per denaro e nuovi tipi di applicazioni. Su Ethereum è possibile scrivere codice per controllare il denaro e creare applicazioni accessibili da ogni parte del mondo.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoin",
  "staking": "Staking",
  "summary": "Riepilogo",
  "terms-of-use": "Condizioni d'uso",
  "transaction-fees": "Che cosa sono le commissioni sulle transazioni?",
  "translation-banner-body-new": "Stai visualizzando questa pagina in inglese perché non l'abbiamo ancora tradotta. Aiutaci a tradurre il contenuto.",
  "translation-banner-body-update": "C'è una nuova versione di questa pagina, ma al momento è solo in inglese. Aiutaci a tradurre l'ultima versione.",
  "translation-banner-button-join-translation-program": "Partecipa al programma di traduzione",
  "translation-banner-button-learn-more": "Scopri di più",
  "translation-banner-button-see-english": "Visualizza in inglese",
  "translation-banner-button-translate-page": "Traduci pagina",
  "translation-banner-title-new": "Aiuta a tradurre questa pagina",
  "translation-banner-title-update": "Aiuta ad aggiornare questa pagina",
  "translation-program": "Translation Program",
  "translation-progress": "Avanzamento traduzione",
  "tutorials": "Tutorial",
  "uniswap-logo": "Logo Uniswap",
  "use": "Usa",
  "use-ethereum": "Usare Ethereum",
  "use-ethereum-menu": "Usa menu Ethereum",
  "vision": "Vision",
  "wallets": "Portafogli",
  "website-last-updated": "Ultimo aggiornamento sito web",
  "what-is-ether": "Cos'è Ether (ETH)?",
  "what-is-ethereum": "Cos'è Ethereum?",
  "whitepaper": "Whitepaper",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Richiedi una funzionalità",
  "page-about-h3": "Lavori in corso",
  "page-about-h3-1": "Funzionalità implementate",
  "page-about-h3-2": "Funzionalità previste",
  "page-about-li-1": "in corso",
  "page-about-li-2": "in programma",
  "page-about-li-3": "implementato",
  "page-about-li-4": "implementato",
  "page-about-link-1": "Il codice sorgente di questo repository è concesso in licenza con licenza MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Visualizza l'elenco completo delle attività in corso su GitHub",
  "page-about-link-4": "Unisciti al nostro server Discord",
  "page-about-link-5": "Contattaci su Twitter",
  "page-about-link-6": "Visualizza l'elenco completo delle attività implementate su GitHub",
  "page-about-link-7": "Apri una segnalazione su GitHub",
  "page-about-p-1": "Fin dal lancio di ethereum.org, ci sforziamo di essere trasparenti su come operiamo. Questo è uno dei nostri valori fondamentali perché riteniamo che la trasparenza sia fondamentale per il successo di Ethereum.",
  "page-about-p-2": "Utilizziamo",
  "page-about-p-3": "come strumento primario di gestione dei progetti. Organizziamo le nostre attività in tre categorie:",
  "page-about-p-4": "  Facciamo del nostro meglio per tenere informata la community sullo stato di un'attività specifica.",
  "page-about-p-5": "Attività che stiamo implementando.",
  "page-about-p-6": "Prossime attività in coda da implementare.",
  "page-about-p-7": "Attività completate di recente.",
  "page-about-p-8": "Hai un'idea su come migliorare ethereum.org? Ci piacerebbe collaborare con te!",
  "page-assets-bazaar": "Bazaar Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Unità modulari",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge che usa dapp",
  "page-assets-download-artist": "Artista:",
  "page-assets-download-download": "Scarica",
  "page-assets-enterprise": "Ethereum per le aziende",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "Diamante ETH (colore)",
  "page-assets-eth-diamond-glyph": "Diamante ETH (glifo)",
  "page-assets-eth-diamond-gray": "Diamante ETH (grigio)",
  "page-assets-eth-diamond-purple": "Diamante ETH (viola)",
  "page-assets-eth-diamond-white": "Diamante ETH (bianco)",
  "page-assets-eth-glyph-video-dark": "Video glifo ETH (scuro)",
  "page-assets-eth-glyph-video-light": "Video glifo ETH (chiaro)",
  "page-assets-eth-logo-landscape-gray": "Logo ETH orizzontale (grigio)",
  "page-assets-eth-logo-landscape-purple": "Logo ETH orizzontale (viola)",
  "page-assets-eth-logo-landscape-white": "Logo ETH orizzontale (bianco)",
  "page-assets-eth-logo-portrait-gray": "Logo ETH verticale (grigio)",
  "page-assets-eth-logo-portrait-purple": "Logo ETH verticale (viola)",
  "page-assets-eth-logo-portrait-white": "Logo ETH verticale (bianco)",
  "page-assets-eth-wordmark-gray": "Testo ETH (grigio)",
  "page-assets-eth-wordmark-purple": "Testo ETH (viola)",
  "page-assets-eth-wordmark-white": "Testo ETH (bianco)",
  "page-assets-ethereum-brand-assets": "Risorse del marchio Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "Risorse ethereum.org",
  "page-assets-hero": "Banner inizio pagina ethereum.org",
  "page-assets-hero-particles": "Immagine di particelle ETH",
  "page-assets-historical-artwork": "Grafica storica",
  "page-assets-illustrations": "Illustrazioni",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Esplora e scarica risorse del marchio Ethereum e ethereum.org, illustrazioni e media.",
  "page-assets-meta-title": "Risorse del marchio Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Sfondo solido",
  "page-assets-page-assets-transparent-background": "Sfondo trasparente",
  "page-assets-robot": "Portafoglio robot",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "Logo 1inch",
  "page-dapps-aave-logo-alt": "Logo Aave",
  "page-dapps-add-button": "Suggerisci dapp",
  "page-dapps-add-title": "Aggiungi dapp",
  "page-dapps-audius-logo-alt": "Logo Audius",
  "page-dapps-augur-logo-alt": "Logo Augur",
  "page-dapps-axie-infinity-logo-alt": "Logo Axie Infinity",
  "page-dapps-brave-logo-alt": "Logo Brave",
  "page-dapps-category-arts": "Arte e moda",
  "page-dapps-category-browsers": "Browser",
  "page-dapps-category-collectibles": "Articoli da collezione digitali",
  "page-dapps-category-competitive": "Competizione",
  "page-dapps-category-computing": "Strumenti di sviluppo",
  "page-dapps-category-dex": "Scambio di token",
  "page-dapps-category-investments": "Investimenti",
  "page-dapps-category-lending": "Prestiti",
  "page-dapps-category-lottery": "Crowdfunding",
  "page-dapps-category-marketplaces": "Marketplace",
  "page-dapps-category-music": "Musica",
  "page-dapps-category-payments": "Pagamenti",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Mercati di scambio e previsione",
  "page-dapps-category-utilities": "Utilità",
  "page-dapps-category-worlds": "Mondi virtuali",
  "page-dapps-choose-category": "Scegli una categoria",
  "page-dapps-collectibles-benefits-1-description": "Quando un'opera d'arte viene tokenizzata su Ethereum, la proprietà può essere dimostrata da chiunque. Puoi tracciare il percorso dell'opera dalla creazione all'attuale proprietario. Questo previene le falsificazioni.",
  "page-dapps-collectibles-benefits-1-title": "La proprietà è dimostrabile",
  "page-dapps-collectibles-benefits-2-description": "Pagare per riprodurre musica in streaming o acquistare opere d'arte è decisamente più giusto nei confronti degli artisti. Con Ethereum c'è meno bisogno di intermediari. E se gli intermediari sono necessari, i loro costi non sono così elevati perché le piattaforme non hanno bisogno di pagare per l'infrastruttura della rete.",
  "page-dapps-collectibles-benefits-2-title": "Più giusto nei confronti degli autori",
  "page-dapps-collectibles-benefits-3-description": "Gli articoli da collezione tokenizzati sono legati al tuo indirizzo Ethereum, non alla piattaforma. Quindi puoi vendere articoli, come ad esempio oggetti di un gioco, su qualsiasi marketplace Ethereum e non solo all'interno del gioco.",
  "page-dapps-collectibles-benefits-3-title": "Gli articoli da collezione sono legati a te",
  "page-dapps-collectibles-benefits-4-description": "Gli strumenti e i prodotti che ti permettono di tokenizzare le tue opere d'arte e venderle esistono già! E i tuoi token possono essere venduti su qualsiasi piattaforma di articoli da collezione Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Infrastruttura già esistente",
  "page-dapps-collectibles-benefits-description": "Si tratta di applicazioni incentrate sulla proprietà digitale, che aumentano il potenziale di guadagno per gli autori e inventano nuovi modi per investire nei tuoi autori preferiti e nel loro lavoro.",
  "page-dapps-collectibles-benefits-title": "articoli da collezione decentralizzati e streaming",
  "page-dapps-collectibles-button": "Arte e articoli da collezione",
  "page-dapps-collectibles-description": "Si tratta di applicazioni incentrate sulla proprietà digitale, che aumentano il potenziale di guadagno per gli autori e inventano nuovi modi per investire nei tuoi autori preferiti e nel loro lavoro.",
  "page-dapps-collectibles-title": "Opere d'arte e articoli da collezione decentralizzati",
  "page-dapps-compound-logo-alt": "Logo Compound",
  "page-dapps-cryptopunks-logo-alt": "Logo CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Logo Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Ti aiuta a evitare uno slippage elevato dei prezzi aggregando quelli migliori.",
  "page-dapps-dapp-description-aave": "Presta i tuoi token per ottenere interessi e preleva in qualsiasi momento.",
  "page-dapps-dapp-description-async-art": "Crea, colleziona e scambia #ProgrammableArt. Dipinti digitali divisi in “Livelli” che è puoi usare per influenzare l'immagine generale. Ogni Master e Layer è un token ERC721.",
  "page-dapps-dapp-description-audius": "Piattaforma di streaming decentralizzata. Ascolti = soldi per gli autori, non etichette.",
  "page-dapps-dapp-description-augur": "Scommetti sui risultati di sport, economia e altri eventi mondiali.",
  "page-dapps-dapp-description-axie-infinity": "Scambia e fai combattere creature chiamate Axies. E guadagna mentre giochi. Disponibile su dispositivo mobile",
  "page-dapps-dapp-description-brave": "Guadagna token per navigare sul browser e supporta i tuoi autori preferiti.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Presta i tuoi token per ottenere interessi e preleva in qualsiasi momento.",
  "page-dapps-dapp-description-cryptopunks": "Acquista, fai un'offerta e metti in vendita punk. Uno dei primi token da collezione su Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Crea gallerie d'arte, costruisci negozi e compra terreni. Un mondo virtuale Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Conquista pianeti in un universo infinito, generato proceduralmente e con specifiche crittografiche.",
  "page-dapps-dapp-description-decentraland": "Colleziona, vendi e acquista terre virtuali in un mondo virtuale che puoi esplorare.",
  "page-dapps-dapp-description-dydx": "Apri posizioni corte o con effetto leva fino a 10x. Possibili erogazione e assunzione di prestiti.",
  "page-dapps-dapp-description-ens": "Nomi facili da usare per gli indirizzi Ethereum e siti decentralizzati.",
  "page-dapps-dapp-description-foundation": "Investi in edizioni uniche di opere d'arte digitali e scambiale con altri acquirenti.",
  "page-dapps-dapp-description-gitcoin": "Guadagna criptovalute lavorando con software open-source.",
  "page-dapps-dapp-description-gitcoin-grants": "Crowdfunding per progetti della community Ethereum con contributi amplificati",
  "page-dapps-dapp-description-gods-unchained": "Gioco di carte scambiabili strategico. Guadagna carte giocando e poi vendile nella vita reale.",
  "page-dapps-dapp-description-golem": "Accedi a potenza di calcolo condivisa o noleggia le tue risorse.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Piattaforma di trading peer-to-peer realizzata per essere veloce.",
  "page-dapps-dapp-description-marble-cards": "Crea e scambia carte digitali uniche basate su URL.",
  "page-dapps-dapp-description-matcha": "Cerca più cambi per aiutarti a trovare i prezzi migliori.",
  "page-dapps-dapp-description-nifty-gateway": "Acquista opere on-chain dai migliori artisti, atleti, marchi e autori.",
  "page-dapps-dapp-description-oasis": "Scambia, prendi in prestito e risparmia con Dai, uno stablecoin di Ethereum.",
  "page-dapps-dapp-description-opensea": "Acquista, vendi, scopri e scambia beni in edizione limitata.",
  "page-dapps-dapp-description-opera": "Invia criptovalute dal tuo browser a esercenti, altri utenti e app.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Scommetti sui risultati. Effettua negoziazioni sui mercati dell'informazione.",
  "page-dapps-dapp-description-pooltogether": "Una lotteria dove non puoi perdere. Premi ogni settimana.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Crea, vendi e compra articoli da collezione tokenizzati.",
  "page-dapps-dapp-description-sablier": "Trasmetti denaro in tempo reale.",
  "page-dapps-dapp-description-superrare": "Acquista opere d'arte digitali direttamente dagli artisti o nei mercati secondari.",
  "page-dapps-dapp-description-token-sets": "Strategie di investimento in criptovalute che si riequilibrano automaticamente.",
  "page-dapps-dapp-description-tornado-cash": "Invia transazioni anonime su Ethereum.",
  "page-dapps-dapp-description-uniswap": "Scambia token in modo facile o fornisci token per ricompense %.",
  "page-dapps-dark-forest-logo-alt": "Logo Dark Forest",
  "page-dapps-decentraland-logo-alt": "Logo Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Trova un'applicazione Ethereum da provare.",
  "page-dapps-doge-img-alt": "Figura di un cane che usa un computer",
  "page-dapps-dydx-logo-alt": "Logo dYdX",
  "page-dapps-editors-choice-dark-forest": "Gioca contro altri utenti per conquistare pianeti e prova la tecnologia avanzatissima di scaling/privacy di Ethereum. Forse per coloro che già conoscono Ethereum.",
  "page-dapps-editors-choice-description": "Alcune dapps che il team di ethereum.org adora in questo momento. Esplora più dapps qui sotto.",
  "page-dapps-editors-choice-foundation": "Investi nella cultura. Compra, scambia e vendi opere d'arte digitali uniche e moda da alcuni incredibili artisti, musicisti e marchi.",
  "page-dapps-editors-choice-header": "Scelte da noi",
  "page-dapps-editors-choice-pooltogether": "Acquista un biglietto per la lotteria senza perdita. Ogni settimana, l'interesse generato dall'intera biglietteria viene inviato ad un fortunato vincitore. Riprendi i tuoi soldi quando vuoi.",
  "page-dapps-editors-choice-uniswap": "Scambia i tuoi token con facilità. Uno dei preferiti della community, che ti permette di scambiare token con altri attraverso la rete.",
  "page-dapps-ens-logo-alt": "Logo Ethereum Name Service",
  "page-dapps-explore-dapps-description": "Molte dapp sono ancora in fase sperimentale, e si stanno testando le possibilità delle reti decentralizzate. Ma ci sono già stati successi nelle categorie tecnologia, finanza, giochi e articoli da collezione.",
  "page-dapps-explore-dapps-title": "Esplora le dapp",
  "page-dapps-features-1-description": "Una volta distribuito su Ethereum, il codice di una dapp non può essere rimosso. E chiunque può utilizzare le funzionalità della dapp, anche se il team che le ha sviluppate si è sciolto. Una volta su Ethereum, rimane lì.",
  "page-dapps-features-1-title": "Nessun proprietario",
  "page-dapps-features-2-description": "Nessuno ti può impedire di usare una dapp o di inviare transazioni. Ad esempio, se Twitter fosse su Ethereum, nessuno potrebbe bloccare il tuo account o impedirti di twittare.",
  "page-dapps-features-2-title": "Nessuna censura",
  "page-dapps-features-3-description": "Poiché Ethereum ha ETH, i pagamenti sono nativi di Ethereum. Gli sviluppatori non devono preoccuparsi dell'integrazione con intermediari terzi.",
  "page-dapps-features-3-title": "Pagamenti integrati",
  "page-dapps-features-4-description": "Il codice delle dapp è spesso aperto e compatibile per natura. I team sviluppano regolarmente utilizzando il lavoro di altri. Se vuoi permettere agli utenti di scambiare token nella tua dapp, puoi semplicemente integrare il codice di un'altra dapp.",
  "page-dapps-features-4-title": "Plug and play",
  "page-dapps-features-5-description": "Con la maggior parte delle dapp, non è necessario condividere la tua identità reale. Il tuo account Ethereum è il tuo login e hai solo bisogno di un portafoglio.",
  "page-dapps-features-5-title": "Un solo login anonimo",
  "page-dapps-features-6-description": "La crittografia assicura che un hacker non possa forgiare transazioni e altre interazioni con le dapp per tuo conto. Sei tu che autorizzi azioni dapp con il tuo account Ethereum, di solito tramite il portafoglio, quindi tieni le tue credenziali al sicuro.",
  "page-dapps-features-6-title": "Il potere della crittografia",
  "page-dapps-features-7-description": "Una volta che la dapp è su Ethereum, risulterà non disponibile solo se anche Ethereum non sarà disponibile. Le reti delle dimensioni di Ethereum sono notoriamente difficili da attaccare.",
  "page-dapps-features-7-title": "Addio ai tempi di inattività",
  "page-dapps-finance-benefits-1-description": "I servizi finanziari in esecuzione su Ethereum non hanno requisiti di iscrizione. Se disponi di fondi e di una connessione a Internet, puoi già partire.",
  "page-dapps-finance-benefits-1-title": "Accesso aperto",
  "page-dapps-finance-benefits-2-description": "C'è un intero mondo di token con cui è possibile interagire attraverso questi prodotti finanziari. La gente crea nuovi token su Ethereum in continuazione.",
  "page-dapps-finance-benefits-2-title": "Una nuova economia basata su token",
  "page-dapps-finance-benefits-3-description": "I team hanno creato gli stablecoin, una criptovaluta meno volatile che permette di sperimentare e utilizzare le criptovalute senza i rischi e le incertezze associate.",
  "page-dapps-finance-benefits-3-title": "Stablecoin",
  "page-dapps-finance-benefits-4-description": "I prodotti finanziari nello spazio Ethereum sono tutti modulari e compatibili tra loro. Sul mercato si affacciano nuove configurazioni di questi moduli in continuazione, che aumentano le possibilità offerte dalle criptovalute.",
  "page-dapps-finance-benefits-4-title": "Servizi finanziari interconnessi",
  "page-dapps-finance-benefits-description": "Perché Ethereum permette alle applicazioni finanziarie decentralizzate di prosperare?",
  "page-dapps-finance-benefits-title": "finanza decentralizzata",
  "page-dapps-finance-button": "Finanza",
  "page-dapps-finance-description": "Si tratta di applicazioni incentrate sull'offerta di servizi finanziari tramite criptovalute. Offrono l'equivalente di prestiti, interessi e pagamenti privati, senza richiedere dati personali.",
  "page-dapps-finance-title": "Finanza decentralizzata",
  "page-dapps-foundation-logo-alt": "Logo della Fondazione",
  "page-dapps-gaming-benefits-1-description": "Gli oggetti sono negoziabili sui mercati degli articoli da collezione, che si tratti di terre virtuali o carte scambiabili. Gli oggetti dei tuoi giochi hanno valore reale.",
  "page-dapps-gaming-benefits-1-title": "Gli oggetti dei giochi valgono il doppio come token",
  "page-dapps-gaming-benefits-2-description": "Gli articoli sono tuoi e in alcuni casi anche i tuoi progressi, non delle aziende di gioco. Quindi non perderai nulla se l'azienda che ha creato il gioco viene attaccata, ha un malfunzionamento dei server o fallisce.",
  "page-dapps-gaming-benefits-2-title": "I tuoi salvataggi sono salvi!",
  "page-dapps-gaming-benefits-3-description": "Allo stesso modo i pagamenti di Ethereum possono essere verificati da chiunque, i giochi possono utilizzare questa qualità per garantire equità. In teoria, tutto è verificabile, dal numero di colpi critici alle dimensioni della cassa di guerra di un avversario.",
  "page-dapps-gaming-benefits-3-title": "Correttezza verificabile",
  "page-dapps-gaming-benefits-description": "Perché Ethereum permette alle applicazioni di gaming decentralizzate di prosperare?",
  "page-dapps-gaming-benefits-title": "giochi decentralizzati",
  "page-dapps-gaming-button": "Gaming",
  "page-dapps-gaming-description": "Si tratta di applicazioni che si concentrano sulla creazione di mondi virtuali e sul combattere contro altri giocatori usando articoli da collezione che hanno un valore nel mondo reale.",
  "page-dapps-gaming-title": "Giochi decentralizzati",
  "page-dapps-get-some-eth-description": "Le azioni dapp costano una commissione di transazione",
  "page-dapps-get-started-subtitle": "Per provare una dapp, ti serve un portafoglio e qualche ETH. Un portafoglio ti permetterà di connetterti o di accedere. E avrai bisogno di ETH per pagare tutte le commissioni di transazione.",
  "page-dapps-get-started-title": "Primi passi",
  "page-dapps-gitcoin-grants-logo-alt": "Logo Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Logo Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Logo Gods Unchained",
  "page-dapps-golem-logo-alt": "Logo Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Strumenti e servizi basati su Ethereum",
  "page-dapps-hero-subtitle": "Le dapp sono un movimento crescente di applicazioni che usano Ethereum per interrompere modelli di business o inventarne di nuovi.",
  "page-dapps-how-dapps-work-p1": "Le dapps hanno il proprio codice di backend (smart contract) che viene eseguito su una rete decentralizzata e non su un server centralizzato. Usano la blockchain Ethereum per lo storage dei dati e gli smart contract per la logica delle app.",
  "page-dapps-how-dapps-work-p2": "Uno smart contract è come un insieme di regole presenti on-chain, può essere visto da tutti e funziona in base a tali regole. Immagina un distributore automatico: se inserisci abbastanza monete e selezioni il prodotto corretto, riceverai l'articolo che desideri. E come per i distributori automatici, gli smart contract possono contenere fondi, praticamente come il tuo account Ethereum. Questo permette al codice di mediare accordi e transazioni.",
  "page-dapps-how-dapps-work-p3": "Una volta che le app vengono distribuite sulla rete Ethereum, non è possibile modificarle. Possono essere decentralizzate perché sono controllate dalla logica presente nello smart contract e non da un individuo o da un'azienda.",
  "page-dapps-how-dapps-work-title": "Come funzionano le dapp",
  "page-dapps-learn-callout-button": "Inizia a sviluppare",
  "page-dapps-learn-callout-description": "Il nostro portale degli sviluppatori della community contiene documenti, strumenti e framework per aiutarti a costruire una dapp.",
  "page-dapps-learn-callout-image-alt": "Figura di una mano che costruisce un simbolo ETH da mattoncini di Lego.",
  "page-dapps-learn-callout-title": "Impara a costruire una dapp",
  "page-dapps-loopring-logo-alt": "Logo Loopring",
  "page-dapps-magic-behind-dapps-description": "Le dapp potrebbero sembrare normali app. In realtà, dietro le quinte, hanno alcune qualità speciali, perché ereditano tutti i superpoteri di Ethereum. Questo è ciò che rende le dapp diverse dalle app.",
  "page-dapps-magic-behind-dapps-link": "Cosa rende Ethereum straordinario?",
  "page-dapps-magic-behind-dapps-title": "La magia delle dapp",
  "page-dapps-magic-title-1": "La magia",
  "page-dapps-magic-title-2": "delle",
  "page-dapps-magician-img-alt": "Figura di maghi",
  "page-dapps-marble-cards-logo-alt": "logo marble.cards",
  "page-dapps-matcha-logo-alt": "Logo Matcha",
  "page-dapps-mobile-options-header": "Cerca un'altra categoria",
  "page-dapps-nifty-gateway-logo-alt": "Logo Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Logo Oasis",
  "page-dapps-opensea-logo-alt": "Logo OpenSea",
  "page-dapps-opera-logo-alt": "Logo Opera",
  "page-dapps-polymarket-logo-alt": "Logo Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Logo PoolTogether",
  "page-dapps-rarible-logo-alt": "Logo Rarible",
  "page-dapps-ready-button": "Via!",
  "page-dapps-ready-description": "Scegli una dapp da provare",
  "page-dapps-ready-title": "Pronti?",
  "page-dapps-sablier-logo-alt": "Logo Sablier",
  "page-dapps-set-up-a-wallet-button": "Trova portafoglio",
  "page-dapps-set-up-a-wallet-description": "Un portafoglio è il tuo “login” per una dapp",
  "page-dapps-set-up-a-wallet-title": "Configura un portafoglio",
  "page-dapps-superrare-logo-alt": "Logo SuperRare",
  "page-dapps-technology-button": "Tecnologia",
  "page-dapps-technology-description": "Si tratta di applicazioni incentrate sul decentramento degli strumenti di sviluppo, l'integrazione dei sistemi criptoeconomici nella tecnologia esistente e la creazione di mercati per il lavoro di sviluppo open-source.",
  "page-dapps-technology-title": "Tecnologia decentralizzata",
  "page-dapps-token-sets-logo-alt": "Logo Token Set",
  "page-dapps-tornado-cash-logo-alt": "Logo Tornado Cash",
  "page-dapps-uniswap-logo-alt": "Logo Uniswap",
  "page-dapps-wallet-callout-button": "Trova portafoglio",
  "page-dapps-wallet-callout-description": "I portafogli sono a loro volta delle dapp. Trovane uno basato sulle caratteristiche che più ti aggradano.",
  "page-dapps-wallet-callout-image-alt": "Figura di un robot.",
  "page-dapps-wallet-callout-title": "Visualizza i portafogli",
  "page-dapps-warning-header": "Fai sempre ricerche per verificare",
  "page-dapps-warning-message": "Ethereum è una nuova tecnologia e la maggior parte delle applicazioni sono nuove. Prima di depositare grandi quantità di denaro, assicurati di conoscere i rischi.",
  "page-dapps-what-are-dapps": "Cosa sono le dapp?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Account",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Avanzate",
  "docs-nav-backend-apis": "API backend",
  "docs-nav-block-explorers": "Block Explorer",
  "docs-nav-blocks": "Blocchi",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Compilazione di smart contract",
  "docs-nav-composability": "Componibilità",
  "docs-nav-consensus-mechanisms": "Meccanismi di consenso",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Dati e analisi",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Distribuzione di smart contract",
  "docs-nav-development-frameworks": "Framework di sviluppo",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Reti di sviluppo",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API client Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Stack di Ethereum",
  "docs-nav-evm": "Macchina virtuale Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Argomenti fondamentali",
  "docs-nav-gas": "Carburante",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Ambienti di sviluppo integrati (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Introduzione alle dapp",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Introduzione a Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Introduzione allo stack",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "API JavaScript",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Mining",
  "docs-nav-networks": "Reti",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Nodi e client",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Nodi come servizi",
  "docs-nav-oracles": "Oracoli",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Linguaggi di programmazione",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Proof-of-stake",
  "docs-nav-proof-of-work": "Proof-of-work",
  "docs-nav-python": "Python",
  "docs-nav-readme": "LEGGIMI",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Ampliamento",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Sicurezza",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomia dello smart contract",
  "docs-nav-smart-contract-languages": "Linguaggi dello smart contract",
  "docs-nav-smart-contracts": "Smart contract",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Librerie degli smart contract",
  "docs-nav-standards": "Standard",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Archiviazione",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Testare gli smart contract",
  "docs-nav-token-standards": "Standard per i token",
  "docs-nav-transactions": "Transazioni",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 rispetto a Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Se conosci l'argomento e vuoi contribuire, modifica questa pagina e condividi un po' del tuo sapere.",
  "page-calltocontribute-desc-2": "Il tuo nome verrà aggiunto ai crediti e aiuterai la community di Ethereum!",
  "page-calltocontribute-desc-3": "Usa questo flessibile",
  "page-calltocontribute-desc-4": "Domande? Chiedicelo nel canale #content sul nostro",
  "page-calltocontribute-link": "modello di documentazione",
  "page-calltocontribute-link-2": "server Discord",
  "page-calltocontribute-span": "Modifica pagina",
  "page-calltocontribute-title": "Aiutaci con questa pagina",
  "page-developer-meta-title": "Risorse per sviluppatori Ethereum",
  "page-developers-about": "Informazioni su queste risorse sviluppatore",
  "page-developers-about-desc": "ethereum.org ti aiuta a sviluppare con Ethereum con documentazione sui concetti fondamentali e sullo stack di sviluppo. In più, ti offre tutorial per muovere i primi passi.",
  "page-developers-about-desc-2": "Sull'onda della rete di sviluppatori Mozilla, abbiamo pensato che Ethereum avesse bisogno di un luogo in cui ospitare utili contenuti e risorse per sviluppatori. Come per Mozilla, qui è tutto open-source e disponibile per essere esteso e migliorato da voi.",
  "page-developers-account-desc": "Contratti o utenti sulla rete",
  "page-developers-accounts-link": "Account",
  "page-developers-advanced": "Avanzate",
  "page-developers-api-desc": "Usare le librerie per interagire con gli Smart Contract",
  "page-developers-api-link": "API backend",
  "page-developers-aria-label": "Menu Sviluppatori",
  "page-developers-block-desc": "Batch di transazioni aggiunte alla blockchain",
  "page-developers-block-explorers-desc": "Il portale per i dati Ethereum",
  "page-developers-block-explorers-link": "Block Explorer",
  "page-developers-blocks-link": "Blocchi",
  "page-developers-browse-tutorials": "Scopri i tutorial",
  "page-developers-choose-stack": "Scegli il tuo stack",
  "page-developers-contribute": "Offri il tuo contributo",
  "page-developers-dev-env-desc": "IDE adatti per lo sviluppo dapp",
  "page-developers-dev-env-link": "Ambienti di sviluppo",
  "page-developers-discord": "Unisciti a Discord",
  "page-developers-docs-introductions": "Introduzioni",
  "page-developers-evm-desc": "Il computer che elabora transazioni",
  "page-developers-evm-link": "La virtual machine Ethereum (EVM)",
  "page-developers-explore-documentation": "Consulta la documentazione",
  "page-developers-feedback": "Puoi comunicarci il tuo feedback tramite una segnalazione GitHub o sul nostro server Discord.",
  "page-developers-frameworks-desc": "Strumenti per rendere più rapido lo sviluppo",
  "page-developers-frameworks-link": "Framework di sviluppo",
  "page-developers-fundamentals": "Principi fondamentali",
  "page-developers-gas-desc": "Ether necessari per le transazioni",
  "page-developers-gas-link": "Carburante",
  "page-developers-get-started": "Come vorresti iniziare?",
  "page-developers-improve-ethereum": "Aiutaci a rendere migliore ethereum.org",
  "page-developers-improve-ethereum-desc": "Questi documenti sono il prodotto di un lavoro di gruppo, proprio come ethereum.org. Crea una segnalazione se trovi errori, vuoi proporre miglioramenti o nuove opportunità per aiutare gli sviluppatori Ethereum.",
  "page-developers-into-eth-desc": "Introduzione alla blockchain e a Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Introduzione alle applicazioni decentralizzate",
  "page-developers-intro-dapps-link": "Introduzione alle dapp",
  "page-developers-intro-eth-link": "Introduzione a Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Introduzione allo stack",
  "page-developers-intro-stack-desc": "Introduzione allo stack Ethereum",
  "page-developers-js-libraries-desc": "Usare javascript per interagire con gli Smart Contract",
  "page-developers-js-libraries-link": "Librerie javascript",
  "page-developers-language-desc": "Usare Ethereum con i linguaggi già familiari",
  "page-developers-languages": "Programmare linguaggi",
  "page-developers-learn": "Imparare a sviluppare con Ethereum",
  "page-developers-learn-desc": "Scopri i concetti fondamentali e lo stack Ethereum con i nostri documenti",
  "page-developers-learn-tutorials": "Impara con i tutorial",
  "page-developers-learn-tutorials-cta": "Guarda i tutorial",
  "page-developers-learn-tutorials-desc": "Impara a sviluppare passo-passo con Ethereum da sviluppatori già esperti.",
  "page-developers-meta-desc": "Documentazione, tutorial e strumenti per sviluppatori che usano Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Come cerare nuovi blocchi e raggiungere il consenso",
  "page-developers-mining-link": "Mining",
  "page-developers-networks-desc": "Panoramica su rete principale e le reti di test",
  "page-developers-networks-link": "Reti",
  "page-developers-node-clients-desc": "Come verificare blocchi e transazioni nella rete",
  "page-developers-node-clients-link": " Nodi e client",
  "page-developers-oracle-desc": "Inserire dati off-chain negli Smart Contract",
  "page-developers-oracles-link": "Oracoli",
  "page-developers-play-code": "Prove con il codice",
  "page-developers-read-docs": "Leggi i documenti",
  "page-developers-scaling-desc": "Soluzioni per transazioni più rapide",
  "page-developers-scaling-link": "Ampliamento",
  "page-developers-smart-contract-security-desc": "Misure di sicurezza da tenere a mente durante lo sviluppo",
  "page-developers-smart-contract-security-link": "Sicurezza",
  "page-developers-set-up": "Configurare l'ambiente locale",
  "page-developers-setup-desc": "Preparare lo stack per lo sviluppo configurando un ambiente di sviluppo.",
  "page-developers-smart-contracts-desc": "La logica delle dapp: accordi a esecuzione automatica",
  "page-developers-smart-contracts-link": "Smart Contract",
  "page-developers-stack": "Lo stack",
  "page-developers-start": "Inizia a sperimentare",
  "page-developers-start-desc": "Preferisci prima provare e porre domande in seguito?",
  "page-developers-storage-desc": "Come gestire lo storage dapp",
  "page-developers-storage-link": "Storage",
  "page-developers-subtitle": "Un manuale su Ethereum, scritto da sviluppatori per sviluppatori.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "sviluppatore",
  "page-developers-title-3": "risorse",
  "page-developers-token-standards-desc": "Panoramica degli standard accettati per i token",
  "page-developers-token-standards-link": "Standard per i token",
  "page-developers-transactions-desc": "Come cambia lo stato di Ethereum",
  "page-developers-transactions-link": "Transazioni",
  "page-developers-web3-desc": "Perché il mondo web3 è diverso",
  "page-developers-web3-link": "Web2 rispetto a Web3",
  "page-learning-tools-bootcamps": "Bootcamp per sviluppatori",
  "page-learning-tools-bootcamps-desc": "Corsi online a pagamento per iniziare al meglio, velocemente.",
  "page-learning-tools-browse-docs": "Sfoglia i documenti",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Logo ChainShot",
  "page-learning-tools-coding": "Impara scrivendo codice",
  "page-learning-tools-coding-subtitle": "Questi strumenti ti aiuteranno a sperimentare con Ethereum se preferisci un'esperienza di apprendimento più interattiva.",
  "page-learning-tools-consensys-academy-description": "Bootcamp online per sviluppatori di Ethereum.",
  "page-learning-tools-consensys-academy-logo-alt": "Logo ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Impara a usare Solidity creando un gioco di zombie.",
  "page-learning-tools-cryptozombies-logo-alt": "Logo CryptoZombies",
  "page-learning-tools-documentation": "Impara con la documentazione",
  "page-learning-tools-documentation-desc": "Vuoi saperne di più? Vai alla nostra documentazione per trovare le spiegazioni di cui hai bisogno.",
  "page-learning-tools-eth-dot-build-description": "Un sandbox istruttivo per il web3, che comprende la programmazione drag-and-drop e i blocchi open-source.",
  "page-learning-tools-eth-dot-build-logo-alt": "Logo Eth.build",
  "page-learning-tools-ethernauts-description": "Completa i livelli hackerando smart contract.",
  "page-learning-tools-ethernauts-logo-alt": "Logo Ethernauts",
  "page-learning-tools-game-tutorials": "Tutorial di gioco interattivi",
  "page-learning-tools-game-tutorials-desc": "Impara giocando. Questi tutorial ti insegneranno le basi attraverso il gioco.",
  "page-learning-tools-meta-desc": "Strumenti di programmazione basati sul web ed esperienze di apprendimento interattive per aiutarti a sperimentare lo sviluppo di Ethereum.",
  "page-learning-tools-meta-title": "Strumenti di apprendimento per sviluppatori",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Sviluppa, distribuisci e amministra gli smart contract per Ethereum. Segui i tutorial con il plugin Learneth.",
  "page-learning-tools-remix-description-2": "Remix non è solo una sandbox. Molti sviluppatori scrivono, compilano e distribuiscono smart contract utilizzando Remix.",
  "page-learning-tools-remix-logo-alt": "Logo Remix",
  "page-learning-tools-sandbox": "Sandbox per il codice",
  "page-learning-tools-sandbox-desc": "Queste sandbox ti offriranno uno spazio in cui sperimentare la scrittura di smart contract e comprendere Ethereum.",
  "page-learning-tools-studio-description": "Un IDE basato sul web dove seguire tutorial per creare e testare smart contract, oltre a costruire la loro frontend.",
  "page-learning-tools-vyperfun-description": "Impara a usare Vyper creando il tuo gioco Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Logo Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "108 / 5000\nUn framework di sviluppo e test basato su Python per smart contract per la macchina virtuale Ethereum.",
  "page-local-environment-brownie-logo-alt": "Logo Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "La piattaforma di sviluppo all-in-one per la creazione e la distribuzione di applicazioni decentrate.",
  "page-local-environment-embark-logo-alt": "Logo Embark",
  "page-local-environment-epirus-desc": "Una piattaforma per lo sviluppo, la distribuzione e il monitoraggio delle applicazioni blockchain sulla macchina virtuale di Java",
  "page-local-environment-epirus-logo-alt": "Logo Epirus",
  "page-local-environment-eth-app-desc": "Crea app basate su Ethereum con un comando. Viene fornito con un'ampia offerta di framework di interfaccia utente e modelli DeFi tra cui scegliere.",
  "page-local-environment-eth-app-logo-alt": "Crea il logo dell'app Eth",
  "page-local-environment-framework-feature-1": "Funzionalità per avviare un'istanza blockchain locale.",
  "page-local-environment-framework-feature-2": "Utilità per compilare e testare i tuoi smart contract.",
  "page-local-environment-framework-feature-3": "Componenti aggiuntivi per lo sviluppo su client per creare un'applicazione per gli utenti all'interno dello stesso progetto/repository.",
  "page-local-environment-framework-feature-4": "Configurazione per connettersi alle reti Ethereum e distribuire gli smart contract, sia ad un'istanza in esecuzione localmente che ad una delle reti pubbliche di Ethereum.",
  "page-local-environment-framework-feature-5": "Distribuzione decentralizzata delle app. Integrazioni con opzioni di storage come IPFS.",
  "page-local-environment-framework-features": "Questi framework sono dotati di molte funzionalità disponibili subito, come:",
  "page-local-environment-frameworks-desc": " Ti consigliamo di scegliere un framework, soprattutto se hai appena iniziato. Costruire una dapp completa richiede diversi componenti della tecnologia. I framework includono molte delle funzionalità necessarie o forniscono semplici sistemi plug-in per scegliere gli strumenti desiderati.",
  "page-local-environment-frameworks-title": "Framework e stack preimpostati",
  "page-local-environment-hardhat-desc": "Hardhat è un ambiente di sviluppo Ethereum per professionisti.",
  "page-local-environment-hardhat-logo-alt": "Logo Hardhat",
  "page-local-environment-openZeppelin-desc": "Risparmia tempo di sviluppo compilando, aggiornando, distribuendo smart contract e interagendovi con il nostro CLI.",
  "page-local-environment-openZeppelin-logo-alt": "Logo OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Crea Eth App: tutto ciò di cui hai bisogno per iniziare a creare applicazioni decentralizzate basate su smart contract",
  "page-local-environment-scaffold-eth-logo-alt": "logo scaffold-eth",
  "page-local-environment-setup-meta-desc": "Guida su come scegliere uno stack software per lo sviluppo Ethereum.",
  "page-local-environment-setup-meta-title": "Configurazione di sviluppo locale Ethereum",
  "page-local-environment-setup-subtitle": "Prima di iniziare a creare, è necessario scegliere uno stack.",
  "page-local-environment-setup-subtitle-2": " Ecco gli strumenti e i framework che puoi utilizzare per creare un'applicazione Ethereum.",
  "page-local-environment-setup-title": "Configura il tuo ambiente di sviluppo locale",
  "page-local-environment-solidity-template-desc": "Modello GitHub per una configurazione pre-impostata degli smart contract Solidity. Include una rete locale Hardhat, Waffle per i test, Ethers per l'implementazione del portafoglio e altro ancora.",
  "page-local-environment-solidity-template-logo-alt": "Logo del modello Solidity",
  "page-local-environment-truffle-desc": "Con la Truffle Suite, passare dall'idea alla dapp è comodissimo.",
  "page-local-environment-truffle-logo-alt": "Logo Truffle",
  "page-local-environment-waffle-desc": "La libreria di test più avanzata per smart contract. Da utilizzare da sola o con Scaffold-eth o Hard Hat.",
  "page-local-environment-waffle-logo-alt": "Logo Waffle",
  "comp-tutorial-metadata-minute-read": "minuti letti",
  "page-tutorial-listing-policy-intro": "Prima di inviare un tutorial leggi la nostra politica di inserzione.",
  "comp-tutorial-metadata-tip-author": "Autore suggerimento",
  "page-tutorial-listing-policy": "politica per l'offerta degli articoli",
  "page-tutorial-new-github": "Non conosci GitHub?",
  "page-tutorial-new-github-desc": "Apri una segnalazione: inserisci le informazioni richieste e incolla il tutorial.",
  "page-tutorial-pull-request": "Crea una richiesta di inserimento",
  "page-tutorial-pull-request-btn": "Crea richiesta inserimento",
  "page-tutorial-pull-request-desc-1": "Segui i",
  "page-tutorial-pull-request-desc-2": "tutorial/nome--tutorial/index.md",
  "page-tutorial-pull-request-desc-3": "struttura per l'assegnazione dei nomi.",
  "page-tutorial-raise-issue-btn": "Apri segnalazione",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Invia un tutorial",
  "page-tutorial-submit-tutorial": "Per inviare un tutorial, dovrai usare GitHub. Ti invitiamo ad aprire una segnalazione o una richiesta di inserimento.",
  "page-tutorial-subtitle": "Benvenuti nella nostra lista selezionata di tutorial della community.",
  "page-tutorial-tags-error": "Nessun tutorial ha tutti questi tag",
  "page-tutorial-title": "Tutorial per lo sviluppo su Ethereum",
  "page-tutorials-meta-description": "Sfoglia e filtra i tutorial controllati della community di Ethereum per argomento.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Tutorial per lo sviluppo su Ethereum",
  "page-eth-buy-some": "Vuoi acquistare Ethereum?",
  "page-eth-buy-some-desc": "Spesso si confonde Ethereum con ETH. Ethereum è la blockchain ed ETH è la risorsa principale di Ethereum. ETH è quello che probabilmente stai cercando di acquistare.",
  "page-eth-cat-img-alt": "Grafica del glifo ETH con un caleidoscopio di gatti",
  "page-eth-collectible-tokens": "Token di articoli da collezione",
  "page-eth-collectible-tokens-desc": "Token che rappresentano un articolo da collezione di un gioco, un'opera d'arte digitale o altre risorse uniche. Comunemente noti come token non fungibili (NFTs).",
  "page-eth-cryptography": "Protetto da crittografia",
  "page-eth-cryptography-desc": "Il denaro di Internet può essere una novità, ma è protetto da una crittografia comprovata che rappresenta una protezione per il tuo portafoglio, i tuoi ETH e le tue transazioni. ",
  "page-eth-currency-for-apps": "È la valuta delle app Ethereum.",
  "page-eth-currency-for-future": "Valuta del nostro futuro digitale",
  "page-eth-description": "ETH è una criptovaluta. È denaro digitale che è possibile utilizzare su Internet, analogamente ai Bitcoin. Se non hai esperienza nel campo delle criptovalute, ecco come ETH è diverso dal denaro tradizionale.",
  "page-eth-earn-interest-link": "Ottenere interessi",
  "page-eth-ethhub-caption": "Aggiornamento frequente",
  "page-eth-ethhub-overview": "EthHub ha una panoramica interessante, se desideri",
  "page-eth-flexible-amounts": "Disponibile in quantitativi flessibili",
  "page-eth-flexible-amounts-desc": "ETH è divisibile fino a 18 cifre decimali, in modo da non dover acquistare 1 ETH intero. Puoi acquistare frazioni alla volta, magari solo 0,000000000000000001 ETH se desideri.",
  "page-eth-fuels": "ETH sta alla base di Ethereum e lo mantiene sicuro",
  "page-eth-fuels-desc": "ETH è la linfa vitale di Ethereum. Quando invii ETH o usi un'applicazione Ethereum, paghi una piccola commissione in ETH per utilizzare la rete Ethereum. Questa commissione è un incentivo per un miner che elabora e verifica quello che stai cercando di fare.",
  "page-eth-fuels-desc-2": "I miner possono essere paragonati a guardiani dei record di Ethereum: controllano e dimostrano che nessuno stia imbrogliando. I miner che fanno questo lavoro sono anche ricompensati con piccole quantità di ETH.",
  "page-eth-fuels-desc-3": "I miner mantengono Ethereum sicuro e privo di controllo centralizzato. In altre parole,",
  "page-eth-fuels-more-staking": "Maggiori informazioni sullo staking",
  "page-eth-fuels-staking": "ETH diventerà ancora più importante con lo staking. Se fai staking con i tuoi ETH, contribuisci a mantenere sicuro Ethereum e ottenere ricompense. Con questo sistema, la minaccia di perdere gli ETH disincentiva gli attacchi.",
  "page-eth-get-eth-btn": "Ottieni ETH",
  "page-eth-gov-tokens": "Token di governance",
  "page-eth-gov-tokens-desc": "Token che rappresentano il potere di voto nelle organizzazioni decentrate.",
  "page-eth-has-value": "Perché ETH ha un valore?",
  "page-eth-has-value-desc": "ETH è prezioso in modi diversi per persone diverse.",
  "page-eth-has-value-desc-2": "Per gli utenti di Ethereum, ETH è prezioso perché consente di pagare le commissioni sulle transazioni.",
  "page-eth-has-value-desc-3": "Altri lo considerano un deposito digitale di valore perché la creazione di nuovi ETH rallenta nel tempo.",
  "page-eth-has-value-desc-4": "Più di recente, ETH è diventato prezioso per gli utenti di app finanziarie su Ethereum. Questo perché è possibile utilizzare ETH come garanzia per i prestiti in criptovalute o come sistema di pagamento.",
  "page-eth-has-value-desc-5": "Naturalmente molti lo vedono anche come un investimento, analogamente a Bitcoin o altre criptovalute.",
  "page-eth-how-to-buy": "Come acquistare Ether",
  "page-eth-how-to-buy-caption": "Aggiornamento frequente",
  "page-eth-is-money": "ETH è denaro digitale e globale.",
  "page-eth-last-updated": "Gennaio 2019",
  "page-eth-mining-link": "Maggiori informazioni sul mining",
  "page-eth-monetary-policy": "Politica monetaria di Ethereum",
  "page-eth-more-on-ethereum-link": "Maggiori informazioni su Ethereum",
  "page-eth-no-centralized": "Nessun controllo centralizzato ",
  "page-eth-no-centralized-desc": "ETH è decentralizzato e globale. Non c'è azienda o banca che possa decidere di stampare più ETH o modificare le condizioni di utilizzo.",
  "page-eth-non-fungible-tokens-link": "Token non fungibili",
  "page-eth-not-only-crypto": "ETH non è l'unica criptovaluta su Ethereum",
  "page-eth-not-only-crypto-desc": "Chiunque può creare nuovi tipi di risorse e scambiarle su Ethereum. Sono conosciute come \"'token\". La gente ha tokenizzato le valute tradizionali, la proprietà, l'arte e persino se stessa!",
  "page-eth-not-only-crypto-desc-2": "Ethereum ospita migliaia di token, alcuni più utili e preziosi di altri. Gli sviluppatori stanno costantemente creando nuovi token che sbloccano nuove possibilità e aprono nuovi mercati.",
  "page-eth-not-only-crypto-desc-3": "Se vuoi saperne di più sui token, i nostri amici di EthHub hanno realizzato un paio di interessanti panoramiche:",
  "page-eth-open": "Aperto a chiunque",
  "page-eth-open-desc": "Serve solo una connessione internet e un portafoglio per accettare ETH. Non è necessario avere accesso a un conto bancario per accettare pagamenti. ",
  "page-eth-p2p-payments": "Pagamenti tra pari",
  "page-eth-p2p-payments-desc": "È possibile inviare ETH senza servizi di intermediazione come le banche. È come consegnare denaro contante di persona, ma puoi farlo in modo sicuro con chiunque, ovunque, in qualsiasi momento.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Tipi popolari di token",
  "page-eth-powers-ethereum": "ETH alimenta Ethereum",
  "page-eth-shit-coins": "Monete fasulle",
  "page-eth-shit-coins-desc": "Perché fare nuovi token è facile, chiunque può farlo - anche le persone con cattive o sbagliate intenzioni. Fai sempre la tua ricerca prima di usarli!",
  "page-eth-stablecoins": "Stablecoin",
  "page-eth-stablecoins-desc": "Token che rispecchiano il valore della valuta tradizionale come i dollari. Questo risolve il problema della volatilità di molte criptovalute.",
  "page-eth-stablecoins-link": "Ottieni stablecoin",
  "page-eth-stream-link": "Trasmettere ETH",
  "page-eth-tokens-link": "Token Ethereum",
  "page-eth-trade-link-2": "Scambiare token",
  "page-eth-underpins": "L'ETH è alla base del sistema finanziario Ethereum",
  "page-eth-underpins-desc": "Non soddisfatta dei pagamenti, la community di Ethereum sta creando un intero sistema finanziario tra pari, accessibile a tutti.",
  "page-eth-underpins-desc-2": "È possibile utilizzare ETH come garanzia per generare token di criptovaluta completamente diversi su Ethereum. Inoltre è possibile prendere in prestito, prestare e ottenere interessi su ETH e altri token basati su ETH.",
  "page-eth-uses": "Gli impieghi degli ETH aumentano giorno dopo giorno",
  "page-eth-uses-desc": "Poiché Ethereum è programmabile, gli sviluppatori possono modellarlo in innumerevoli modi.",
  "page-eth-uses-desc-2": "Nel 2015, tutto quello che si poteva fare era inviare ETH da un account Ethereum a un altro. Ecco invece solo alcune delle cose che puoi fare oggi.",
  "page-eth-uses-desc-3": "pagare qualcuno o ricevere fondi in tempo reale.",
  "page-eth-uses-desc-4": "è possibile scambiare ETH con altri token tra cui Bitcoin.",
  "page-eth-uses-desc-5": "su ETH e altri token basati su Ethereum.",
  "page-eth-uses-desc-6": "accedere al mondo delle criptovalute con un valore costante e meno volatile.",
  "page-eth-value": "Perché ether è prezioso",
  "page-eth-video-alt": "Video glifo ETH",
  "page-eth-whats-eth": "Cos'è ether (ETH)?",
  "page-eth-whats-eth-hero-alt": "Figura di un gruppo di persone che si meravigliano del glifo di un ether (ETH)",
  "page-eth-whats-eth-meta-desc": "Cosa devi sapere per capire ETH e il suo posto in Ethereum.",
  "page-eth-whats-eth-meta-title": "Cos'è ether (ETH)?",
  "page-eth-whats-ethereum": "Cos'è Ethereum?",
  "page-eth-whats-ethereum-desc": "Se vuoi saperne di più su Ethereum e sulla tecnologia sottostante, dai un'occhiata alla nostra introduzione.",
  "page-eth-whats-unique": "Cosa rende ETH unico?",
  "page-eth-whats-unique-desc": "Sono presenti molte criptovalute e molti altri token su Ethereum, ma ci sono alcune cose che solo ETH può fare.",
  "page-eth-where-to-buy": "Dove ottenere ETH",
  "page-eth-where-to-buy-desc": "È possibile ottenere ETH da uno scambio o un portafoglio, ma ogni paese ha le sue politiche. Fai alcune ricerche per verificare quali servizi ti permettono di acquistare ETH.",
  "page-eth-yours": "È davvero tuo",
  "page-eth-yours-desc": "Con ETH, la tua banca sei tu. Puoi controllare i tuoi fondi con il portafoglio come prova di proprietà. Non sono necessarie terze parti.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Indirizzo contratto di deposito Eth2",
  "page-eth2-deposit-contract-address-caption": "Abbiamo aggiunto degli spazi in modo da rendere gli indirizzi più facili da leggere",
  "page-eth2-deposit-contract-address-check-btn": "Controlla l'indirizzo del contratto di deposito",
  "page-eth2-deposit-contract-checkbox1": "Ho già usato il launchpad per impostare il mio validatore Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Accetto di dover utilizzare il launchpad per fare staking. Trasferimenti semplici a questo indirizzo non funzioneranno.",
  "page-eth2-deposit-contract-checkbox3": "Controllerò l'indirizzo del contratto di deposito con altre fonti.",
  "page-eth2-deposit-contract-confirm-address": "Conferma per rivelare l'indirizzo",
  "page-eth2-deposit-contract-copied": "Indirizzo copiato",
  "page-eth2-deposit-contract-copy": "Copia indirizzo",
  "page-eth2-deposit-contract-etherscan": "Visualizza contratto su Etherscan",
  "page-eth2-deposit-contract-h2": "Non è qui che puoi fare staking",
  "page-eth2-deposit-contract-launchpad": "Staking usando launchpad",
  "page-eth2-deposit-contract-launchpad-2": "Usa launchpad",
  "page-eth2-deposit-contract-meta-desc": "Verifica l'indirizzo del contratto di deposito per lo staking Eth2.",
  "page-eth2-deposit-contract-meta-title": "Indirizzo contratto del deposito Eth2",
  "page-eth2-deposit-contract-read-aloud": "Leggi l'indirizzo ad alta voce",
  "page-eth2-deposit-contract-reveal-address-btn": "Rivela indirizzo",
  "page-eth2-deposit-contract-staking": " Per fare staking con i tuoi ETH in Eth2 devi utilizzare il prodotto launchpad dedicato e seguire le istruzioni. L'invio di ETH all'indirizzo di questa pagina non ti renderà un staker e produrrà una transazione non riuscita.",
  "page-eth2-deposit-contract-staking-check": "Controlla queste fonti",
  "page-eth2-deposit-contract-staking-check-desc": "È facile imbattersi in truffe e account fasulli. Per sicurezza, confronta l'indirizzo di staking Eth2 che stai utilizzando con l'indirizzo presente su questa pagina. Consigliamo inoltre di verificarlo con altre fonti affidabili.",
  "page-eth2-deposit-contract-staking-more-link": "Maggiori informazioni sullo staking",
  "page-eth2-deposit-contract-stop-reading": "Interrompi lettura",
  "page-eth2-deposit-contract-subtitle": " Questo è l'indirizzo del contratto per lo staking Eth2. Usa questa pagina per confermare che stai inviando fondi all'indirizzo corretto quando fai staking.",
  "page-eth2-deposit-contract-warning": "Controlla attentamente ogni carattere.",
  "page-eth2-deposit-contract-warning-2": "L'invio di fondi a questo indirizzo non funzionerà e non ti renderà uno staker. Devi seguire le istruzioni del launchpad.",
  "page-eth2-bug-bounty-annotated-specs": "specifica annotata",
  "page-eth2-bug-bounty-annotations": "Può essere utile consultare le seguenti annotazioni:",
  "page-eth2-bug-bounty-client-bugs": "Bug client Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "I client eseguiranno la beacon chain quando l'aggiornamento sarà stato distribuito. Dovranno seguire la logica stabilita nella specifica ed essere al sicuro da potenziali attacchi. I bug che vogliamo trovare sono legati all'implementazione del protocollo.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Attualmente i bug Lighthouse, Nimbus, Teku e Prysm sono idonei per questa taglia. È possibile aggiungere più clienti man mano che completano gli audit e diventano pronti per la produzione.",
  "page-eth2-bug-bounty-clients": "Client presenti nei bounty",
  "page-eth2-bug-bounty-clients-type-1": "problemi di non-conformità della specifica.",
  "page-eth2-bug-bounty-clients-type-2": "crash imprevisti o vulnerabilità di tipo denial of service (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "ogni questione che causa una divisione del consenso dal resto della rete.",
  "page-eth2-bug-bounty-docking": "docking",
  "page-eth2-bug-bounty-email-us": "Scrivici:",
  "page-eth2-bug-bounty-help-links": "Link utili",
  "page-eth2-bug-bounty-hunting": "Regole per la ricerca di bug",
  "page-eth2-bug-bounty-hunting-desc": "Il programma bug bounty è un programma di ricompense sperimentale e discrezionale rivolto alla nostra community Ethereum attiva, per incoraggiare e premiare coloro che contribuiscono a migliorare la piattaforma. Non è una gara. È importante sapere che potremmo annullare il programma in qualsiasi momento e le ricompense sono a sola discrezione della commissione di bug bounty della Ethereum Foundation. Inoltre, non possiamo assegnare ricompense a chi è incluso nelle liste di sanzioni o si trova in paesi presenti nelle liste di sanzioni (ad esempio Corea del Nord, Iran ecc.). L'utente è responsabile di tutte le imposte applicabili. Tutte le ricompense sono soggette alla legge applicabile. Infine, il test non deve violare alcuna legge o compromettere dati non di proprietà dell'utente.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Classifica della ricerca di bug",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Trova bug di Eth2 in modo che il tuo nome sia aggiunto a questa classifica",
  "page-eth2-bug-bounty-hunting-li-1": "Problemi già inviati da un altro utente o già noti ai responsabili della manutenzione delle specifiche e dei client non verranno considerati ai fini delle ricompense del bounty.",
  "page-eth2-bug-bounty-hunting-li-2": "La divulgazione al pubblico di una vulnerabilità la rende inammissibile al bounty.",
  "page-eth2-bug-bounty-hunting-li-3": "I ricercatori della Ethereum Foundation e i dipendenti dei team client Eth2 non sono idonei a ricevere ricompense.",
  "page-eth2-bug-bounty-hunting-li-4": "Il programma bounty Ethereum prende in considerazione un certo numero di variabili per determinare le ricompense. Le decisioni in materia di ammissibilità, punteggio e tutti i termini relativi a una ricompensa sono a sola discrezione finale della commissione bug bounty della Ethereum Foundation.",
  "page-eth2-bug-bounty-leaderboard": "Vedi classifica completa",
  "page-eth2-bug-bounty-leaderboard-points": "punti",
  "page-eth2-bug-bounty-ledger-desc": "La specifica della beacon chain illustra nel dettaglio la logica della progettazione e propone modifiche ad Ethereum attraverso l'aggiornamento della beacon chain.",
  "page-eth2-bug-bounty-ledger-title": "I bug delle specifiche della beacon chain",
  "page-eth2-bug-bounty-meta-description": "Una panoramica del programma di ricerca dei bug Eth2: come essere coinvolti e informazioni sulle ricompense.",
  "page-eth2-bug-bounty-meta-title": "Programma bounty per la ricerca dei bug di Eth2",
  "page-eth2-bug-bounty-not-included": "Non incluso",
  "page-eth2-bug-bounty-not-included-desc": "La shard chain e gli aggiornamenti docking sono ancora in fase di sviluppo attivo e quindi non verranno inclusi all'interno di questo programma bounty.",
  "page-eth2-bug-bounty-owasp": "Visualizza metodo OWASP",
  "page-eth2-bug-bounty-points": "La Ethereum Foundation assegnerà inoltre punti in base a:",
  "page-eth2-bug-bounty-points-error": "Errore nel caricamento dei dati... aggiorna.",
  "page-eth2-bug-bounty-points-exchange": "Scambio dei punti",
  "page-eth2-bug-bounty-points-loading": "Caricamento dati in corso...",
  "page-eth2-bug-bounty-points-payout-desc": " La Ethereum Foundation pagherà il valore dei dollari americani in ETH o DAI.",
  "page-eth2-bug-bounty-points-point": "1 punto",
  "page-eth2-bug-bounty-points-rights-desc": "La Ethereum Foundation si riserva il diritto di modificare questa disposizione senza preavviso.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Qualità della descrizione",
  "page-eth2-bug-bounty-quality-desc": ": Le ricompense più elevate sono pagate per le comunicazioni chiare e ben scritte.",
  "page-eth2-bug-bounty-quality-fix": "Qualità della correzione",
  "page-eth2-bug-bounty-quality-fix-desc": ", se incluso: ricompense più elevate sono assegnate per le comunicazioni che includono una descrizione chiara su come risolvere il problema.",
  "page-eth2-bug-bounty-quality-repro": "Qualità della riproducibilità",
  "page-eth2-bug-bounty-quality-repro-desc": ": Si prega di includere il codice di test, script e istruzioni dettagliate. Più facile sarà per noi riprodurre e verificare la vulnerabilità, maggiore sarà la ricompensa.",
  "page-eth2-bug-bounty-questions": "Domande?",
  "page-eth2-bug-bounty-rules": "Leggi le regole",
  "page-eth2-bug-bounty-shard-chains": "shard chain",
  "page-eth2-bug-bounty-slogan": "Bug bounty Eth2",
  "page-eth2-bug-bounty-specs": "Leggi le specifiche complete",
  "page-eth2-bug-bounty-specs-docs": "Documenti di specifica",
  "page-eth2-bug-bounty-submit": "Invia un bug",
  "page-eth2-bug-bounty-submit-desc": "Per ogni bug ti verranno assegnati dei punti. I punti guadagnati dipendono dalla gravità del bug. La Ethereum Foundation (EF) determina la gravità con il metodo OWASP.",
  "page-eth2-bug-bounty-subtitle": "Guadagna fino a $50.000 USD e un posto in classifica trovando bug nel protocollo Eth2 e nei client.",
  "page-eth2-bug-bounty-title": "Aperto alle segnalazioni",
  "page-eth2-bug-bounty-title-1": "beacon chain",
  "page-eth2-bug-bounty-title-2": "Scelta del fork",
  "page-eth2-bug-bounty-title-3": "Contratto di deposito Solidity",
  "page-eth2-bug-bounty-title-4": "Networking peer-to-peer",
  "page-eth2-bug-bounty-type-1": "bug di sicurezza/compromissione della finalità.",
  "page-eth2-bug-bounty-type-2": "vettori denial of service (DOS)",
  "page-eth2-bug-bounty-type-3": "incongruenze nelle ipotesi, come situazioni in cui validatori onesti possono essere espulsi.",
  "page-eth2-bug-bounty-type-4": "incongruenze di calcolo o dei parametri.",
  "page-eth2-bug-bounty-types": "Tipi di bug",
  "page-eth2-bug-bounty-validity": "Bug validi",
  "page-eth2-bug-bounty-validity-desc": "Questo programma di bug bounty si concentra sulla ricerca di bug nelle specifiche principali della beacon chain di Eth2 e nelle implementazioni dei client Prysm, Lighthouse e Teku.",
  "page-eth2-bug-bountycard-critical": "Critico",
  "page-eth2-bug-bountycard-critical-risk": "Invia bug a rischio critico",
  "page-eth2-bug-bountycard-h2": "Medio",
  "page-eth2-bug-bountycard-high": "Alto",
  "page-eth2-bug-bountycard-high-risk": "Invia bug a rischio alto",
  "page-eth2-bug-bountycard-label-1": "Fino a 1.000 punti",
  "page-eth2-bug-bountycard-label-2": "Fino a 2.000 DAI",
  "page-eth2-bug-bountycard-label-3": "Fino a 5.000 punti",
  "page-eth2-bug-bountycard-label-4": "Fino a 10.000 DAI",
  "page-eth2-bug-bountycard-label-5": "Fino a 10.000 punti",
  "page-eth2-bug-bountycard-label-6": "Fino a 20.000 DAI",
  "page-eth2-bug-bountycard-label-7": "Fino a 25.000 punti",
  "page-eth2-bug-bountycard-label-8": "Fino a 50.000 DAI",
  "page-eth2-bug-bountycard-li-1": "Impatto basso, probabilità media",
  "page-eth2-bug-bountycard-li-2": "Impatto medio, probabilità bassa",
  "page-eth2-bug-bountycard-li-3": "Impatto alto, probabilità bassa",
  "page-eth2-bug-bountycard-li-4": "Impatto medio, probabilità media",
  "page-eth2-bug-bountycard-li-5": "Impatto basso, probabilità alta",
  "page-eth2-bug-bountycard-li-6": "Impatto alto, probabilità media",
  "page-eth2-bug-bountycard-li-7": "Impatto medio, probabilità alta",
  "page-eth2-bug-bountycard-li-8": "Impatto alto, probabilità alta",
  "page-eth2-bug-bountycard-low": "Basso",
  "page-eth2-bug-bountycard-low-risk": "Segnala bug a rischio basso",
  "page-eth2-bug-bountycard-medium-risk": "Segnala bug a rischio medio",
  "page-eth2-bug-bountycard-subheader": "Gravità",
  "page-eth2-bug-bountycard-subheader-2": "Esempio",
  "page-eth2-bug-bountycard-text": "Un utente malintenzionato potrebbe impostare un nodo su uno stato che faccia fallire una su cento attestazioni eseguite da un validatore",
  "page-eth2-bug-bountycard-text-1": " Un utente malintenzionato può condurre con successo attacchi eclipse su nodi con Id peer aventi byte a 4 zeri iniziali",
  "page-eth2-bug-bountycard-text-2": "C'è un bug di consenso tra due client, ma è difficile o poco pratico per un utente malintenzionato attivare l'evento.",
  "page-eth2-bug-bountycard-text-3": "  C'è un bug di consenso tra due client ed è molto semplice per un utente malintenzionato attivare l'evento.",
  "page-eth2-get-involved-btn-1": "Vedi client",
  "page-eth2-get-involved-btn-2": "Maggiori informazioni sullo staking",
  "page-eth2-get-involved-btn-3": "Trova bug",
  "page-eth2-get-involved-bug": "Un bug potrebbe essere:",
  "page-eth2-get-involved-bug-hunting": "Alla ricerca di bug",
  "page-eth2-get-involved-bug-hunting-desc": "Trova e segnala bug nelle specifiche di aggiornamento di Eth2 o nei client stessi. È possibile guadagnare fino a $50.000 USD e ottenere un posto in classifica.",
  "page-eth2-get-involved-bug-li": "problemi di non-conformità della specifica",
  "page-eth2-get-involved-bug-li-2": "bug che infrangono la finalità",
  "page-eth2-get-involved-bug-li-3": "vettori denial of service (DOS)",
  "page-eth2-get-involved-bug-li-4": "e altro...",
  "page-eth2-get-involved-date": "Data di chiusura: 23 dicembre 2020",
  "page-eth2-get-involved-desc-1": "Eseguire un client significa essere un partecipante attivo di Ethereum. Il client aiuterà a tenere traccia delle transazioni e controllare nuovi blocchi.",
  "page-eth2-get-involved-desc-2": "Se hai ETH, puoi fare staking per diventare un validatore e contribuire a proteggere la rete. Come validatore puoi ottenere ricompense in ETH.",
  "page-eth2-get-involved-desc-3": "Unisciti al lavoro di test della community! Aiuta a testare gli aggiornamenti Eth2 prima che siano rilasciati, a scoprire i bug e guadagna ricompense.",
  "page-eth2-get-involved-ethresearch-1": "Sharding",
  "page-eth2-get-involved-ethresearch-2": "Transizione da Eth1 a Eth2",
  "page-eth2-get-involved-ethresearch-3": "Shard ed esecuzione dello stato",
  "page-eth2-get-involved-ethresearch-4": "Tutti gli argomenti di ricerca",
  "page-eth2-get-involved-grants": "Sovvenzioni per la community di staking",
  "page-eth2-get-involved-grants-desc": "Aiuta a creare strumenti e contenuti di training per la community di staking",
  "page-eth2-get-involved-how": "Come vuoi partecipare?",
  "page-eth2-get-involved-how-desc": "La community di Ethereum ne beneficerà se un maggior numero di persone esegue i client, fa staking e cerca bug.",
  "page-eth2-get-involved-join": "Unisciti alla ricerca",
  "page-eth2-get-involved-join-desc": " Come la maggior parte di ciò che riguarda Ethereum, un'ampia fetta della ricerca è pubblica. Questo significa che si può partecipare alle discussioni o semplicemente leggere ciò che i ricercatori di Ethereum hanno da dire. ethresearch.ch si concentra prevalentemente su Eth2 anche se tratta molto altro oltre agli aggiornamenti di Eth2.",
  "page-eth2-get-involved-meta-description": "Come partecipare a Eth2: eseguire nodi, fare staking, cercare bug e altro ancora.",
  "page-eth2-get-involved-more": "Maggiori informazioni",
  "page-eth2-get-involved-run-clients": "Eseguire client sulla beacon chain",
  "page-eth2-get-involved-run-clients-desc": "La chiave per la sicurezza a lungo termine di Ethereum è una forte distribuzione dei client. Un client è un software che esegue la blockchain, controlla le transazioni e la creazione di nuovi blocchi. Ogni client ha le sue caratteristiche, pertanto scegline uno in base alle tue esigenze.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Fai staking con i tuoi ETH",
  "page-eth2-get-involved-stake-desc": "Ora puoi fare staking con i tuoi ETH per contribuire a proteggere la beacon chain.",
  "page-eth2-get-involved-stake-eth": "Fai staking con gli ETH",
  "page-eth2-get-involved-subtitle": "Ecco tutti i modi con cui puoi aiutare Ethereum e i futuri sforzi legati all'Eth2.",
  "page-eth2-get-involved-title-1": "Esegui un client",
  "page-eth2-get-involved-title-2": "Fai staking con i tuoi ETH",
  "page-eth2-get-involved-title-3": "Trova bug",
  "page-eth2-get-involved-written-go": "Scritto in Go",
  "page-eth2-get-involved-written-java": "Scritto in Java",
  "page-eth2-get-involved-written-javascript": "Scritto in JavaScript",
  "page-eth2-get-involved-written-net": "Scritto in .NET",
  "page-eth2-get-involved-written-nim": "Scritto in Nim",
  "page-eth2-get-involved-written-python": "Scritto in Python",
  "page-eth2-get-involved-written-rust": "Scritto in Rust",
  "eth2-client-cortex-logo-alt": "Logo Cortex",
  "eth2-client-lighthouse-logo-alt": "Logo Lighthouse",
  "eth2-client-lodestar-logo-alt": "Logo Lodestar",
  "eth2-client-nimbus-logo-alt": "Logo Nimbus",
  "eth2-client-prysm-logo-alt": "Logo Prysm",
  "eth2-client-teku-logo-alt": "Logo Teku",
  "eth2-rhino-img-alt": "Grafica del rinoceronte Eth 2 che corre",
  "page-eth2-answer-1": "Pensa a Eth2 come a una serie di aggiornamenti pensati per migliorare la rete Ethereum rispetto a come la usiamo oggi. Questi aggiornamenti includono la creazione di una nuova catena chiamata beacon chain e fino a 64 catene dette shard.",
  "page-eth2-answer-2": "Sono separate dalla rete principale Ethereum attuale, ma non la sostituiranno. Al contrario, la rete principale si aggancerà o si fonderà a questo sistema parallelo che verrà aggiunto nel corso del tempo.",
  "page-eth2-answer-4": "In altre parole, la rete Ethereum che usiamo oggi alla fine incorporerà tutte le caratteristiche a cui puntiamo nella vision Eth2.",
  "page-eth2-beacon-chain-btn": "Maggiori informazioni sulla beacon chain",
  "page-eth2-beacon-chain-date": "La beacon chain è live a partire dal 1 dicembre 2020.",
  "page-eth2-beacon-chain-desc": "Come prima aggiunta Eth2 all'ecosistema, la beacon chain aggiunge lo staking a Ethereum, pone le basi per aggiornamenti futuri e alla fine coordinerà il nuovo sistema.",
  "page-eth2-beacon-chain-estimate": "La beacon chain è live",
  "page-eth2-beacon-chain-title": "La beacon chain",
  "page-eth2-bug-bounty": "Visualizza il programma bug bounty",
  "page-eth2-clients": "Dai un'occhiata ai client Eth2",
  "page-eth2-deposit-contract-title": "Controlla l'indirizzo del contratto di deposito",
  "page-eth2-diagram-ethereum-mainnet": "La rete principale Ethereum",
  "page-eth2-diagram-h2": "Come si integrano gli aggiornamenti",
  "page-eth2-diagram-link-1": "Maggiori informazioni sulla proof of work",
  "page-eth2-diagram-link-2": "Maggiori informazioni sulle shard chain",
  "page-eth2-diagram-mainnet": "Rete principale",
  "page-eth2-diagram-p": "La rete principale Ethereum continuerà a esistere nella sua forma attuale per un po'. Significa che gli aggiornamenti alla beacon chain e agli shard non ne interromperanno il funzionamento.",
  "page-eth2-diagram-p-1": "La rete principale alla fine si fonderà con il nuovo sistema introdotto dagli aggiornamenti Eth2.",
  "page-eth2-diagram-p-2": "La beacon chain diventerà il sistema di gestione di Ethereum, coordinerà i validatori e imposterà i tempi per la creazione dei blocchi.",
  "page-eth2-diagram-p-3": "All'inizio, esisterà separatamente dalla rete principale e gestirà i validatori. Non avrà nulla a che fare con gli smart contract, le transazioni o gli account.",
  "page-eth2-diagram-p-4": "Gli shard forniranno moltissimi dati aggiuntivi per contribuire ad aumentare la quantità di transazioni che la rete principale può gestire. Saranno coordinati dalla beacon chain.",
  "page-eth2-diagram-p-5": "Ma tutte le transazioni dipenderanno ancora dalla rete principale, che continuerà a esistere come la conosciamo oggi, protetta dalla proof of work e dai miner.",
  "page-eth2-diagram-p-6": "La rete principale si fonderà con il sistema proof of stake, coordinato dalla beacon chain.",
  "page-eth2-diagram-p-8": "Questo trasformerà la rete principale in uno shard all'interno del nuovo sistema. I miner non saranno più necessari in quanto la totalità di Ethereum sarà protetta dai validatori.",
  "page-eth2-diagram-p10": "Eth2 non è una migrazione o una sola entità. Descrive una serie di aggiornamenti in corso di elaborazione che sbloccheranno il vero potenziale di Ethereum. Ecco come si integrano.",
  "page-eth2-diagram-scroll": "Scorri per esplorare. Tocca per ulteriori informazioni.",
  "page-eth2-diagram-shard": "Shard (1)",
  "page-eth2-diagram-shard-1": "Shard (...)",
  "page-eth2-diagram-shard-2": "Shard (2)",
  "page-eth2-diagram-shard-3": "Shard (...)",
  "page-eth2-diagram-validators": "Maggiori informazioni sui validatori",
  "page-eth2-dive": "Dettagli sulla vision",
  "page-eth2-dive-desc": "Come faremo a rendere Ethereum più scalabile, sicura e sostenibile, mantenendo il concetto fondamentale di decentralizzazione?",
  "page-eth2-docking": "Il docking",
  "page-eth2-docking-answer-1": "Il docking avviene quando la rete principale si trasforma in uno shard. Avverrà dopo l'implementazione delle shard chain.",
  "page-eth2-docking-btn": "Maggiori informazioni sul docking",
  "page-eth2-docking-desc": "A un certo punto, la rete principale Ethereum dovrà “ancorarsi” o \"fondersi\" con la beacon chain. Questo permetterà lo staking sull'intera rete e segnerà la fine del mining ad alta intensità energetica.",
  "page-eth2-docking-estimate": "Stima: 2022",
  "page-eth2-docking-mainnet": "Cos'è la rete principale?",
  "page-eth2-docking-mainnet-eth2": "Docking della rete principale in Eth2",
  "page-eth2-eth-blog": "blog ethereum.org",
  "page-eth2-explore-btn": "Esplora gli aggiornamenti",
  "page-eth2-get-involved": "Partecipa a Eth2",
  "page-eth2-get-involved-2": "Partecipa",
  "page-eth2-head-to": "Vai a",
  "page-eth2-help": "Vuoi dare una mano con Eth2?",
  "page-eth2-help-desc": "Ci sono molte opportunità per collaborare agli aggiornamenti Eth2, aiutare nei test e anche guadagnare ricompense.",
  "page-eth2-index-staking": "Lo staking è realtà",
  "page-eth2-index-staking-desc": "Il punto fondamentale degli aggiornamenti Eth2 è l'introduzione dello staking. Se desideri utilizzare i tuoi ETH per proteggere la rete Ethereum, assicurati di seguire questi passaggi.",
  "page-eth2-index-staking-learn": "Scopri di più sullo staking",
  "page-eth2-index-staking-learn-desc": "La beacon chain porterà lo staking nella rete Ethereum. Questo significa che se detieni ETH, puoi fare del bene alla community proteggendo la rete e allo stesso tempo guadagnare più ETH grazie a questo processo.",
  "page-eth2-index-staking-step-1": "1. Preparati a usare il launchpad",
  "page-eth2-index-staking-step-1-btn": "Visita il launchpad per lo staking",
  "page-eth2-index-staking-step-1-desc": "Per fare staking su Eth2 dovrai utilizzare il launchpad. Questo ti guiderà attraverso il processo.",
  "page-eth2-index-staking-step-2": "2. Conferma l'indirizzo di staking",
  "page-eth2-index-staking-step-2-btn": "Conferma l'indirizzo del contratto di deposito",
  "page-eth2-index-staking-step-2-desc": "Prima di fare staking con i tuoi ETH, controlla di avere l'indirizzo corretto. Devi anche sapere usare il launchpad.",
  "page-eth2-index-staking-sustainability": "Più sostenibile",
  "page-eth2-just-docking": "Maggiori informazioni sul docking",
  "page-eth2-meta-desc": "Una panoramica degli aggiornamenti di Ethereum 2.0 e della visione che si spera di realizzare.",
  "page-eth2-meta-title": "Gli aggiornamenti Eth2",
  "page-eth2-miners": "Maggiori informazioni sui miner",
  "page-eth2-more-on-upgrades": "Maggiori informazioni sugli aggiornamenti Eth2",
  "page-eth2-proof-of-stake": "proof of stake",
  "page-eth2-proof-of-work": "proof of work",
  "page-eth2-proof-stake-link": "Maggiori informazioni sul proof of stake",
  "page-eth2-question-1-desc": "Per Eth2 si intende una serie di aggiornamenti distinti, con diverse date di rilascio.",
  "page-eth2-question-1-title": "Quando sarà rilasciato Eth2?",
  "page-eth2-question-2-desc": "Non è esatto immaginare Eth2 come una blockchain separata.",
  "page-eth2-question-2-title": "Eth2 è una blockchain separata?",
  "page-eth2-question-3-answer-2": "Gli aggiornamenti che riguardano la shard chain e il docking possono avere un impatto sugli sviluppatori delle dapp. Le specifiche però non sono ancora state finalizzate, pertanto non è richiesta alcuna azione immediata.",
  "page-eth2-question-3-answer-3": "Parla con il team di ricerca e sviluppo Eth2 su ethresearch.ch.",
  "page-eth2-question-3-answer-3-link": "Visita ethresearch.ch",
  "page-eth2-question-3-desc": "Non è necessario fare nulla in questo momento per prepararsi per Eth2.",
  "page-eth2-question-3-title": "Come posso prepararmi per Eth2?",
  "page-eth2-question-4-answer-1": "Ogni volta che invii una transazione o usi una dapp oggi, stai usando Eth1. È Ethereum protetto dai miner.",
  "page-eth2-question-4-answer-2": "\"Eth1\", la rete principale, continuerà a funzionare normalmente fino al docking.",
  "page-eth2-question-4-answer-3": "Dopo il docking, i validatori proteggeranno l'intera rete tramite il proof of stake.",
  "page-eth2-question-4-answer-6": "Chiunque può diventare validatore facendo staking con i propri ETH.",
  "page-eth2-question-4-answer-7": "Maggiori informazioni sullo staking",
  "page-eth2-question-4-answer-8": "Gli aggiornamenti che riguardano la beacon chain e la shard chain non interromperanno il funzionamento di Eth1, in quanto vengono realizzati separatamente.",
  "page-eth2-question-4-desc": "Per Eth1 si intende la rete principale Ethereum su cui oggi si effettuano le transazioni.",
  "page-eth2-question-4-title": "Cos'è Eth1?",
  "page-eth2-question-5-answer-1": "Per diventare validatore sulla rete a tutti gli effetti, è necessario fare staking con 32 ETH. Se non ne hai così tanti o non vuoi fare staking con una cifra così alta, puoi unirti ai pool di staking, che ti permetteranno di utilizzare una cifra inferiore per lo staking e guadagnare frazioni delle ricompense totali.",
  "page-eth2-question-5-desc": "Dovrai usare il launchpad per lo staking o unirti un pool di staking.",
  "page-eth2-question-5-title": "Come si fa staking?",
  "page-eth2-question-6-answer-1": "Per ora, non è necessario fare niente. Ma ti consigliamo di seguire gli sviluppi sull'aggiornamento delle shard chain e del docking.",
  "page-eth2-question-6-answer-3": "Danny Ryan della Ethereum Foundation tiene la community regolarmente aggiornata:",
  "page-eth2-question-6-answer-4": "Ben Edgington di ConsenSys pubblica una newsletter Eth2 settimanale:",
  "page-eth2-question-6-answer-5": "È inoltre possibile partecipare alla discussione su ricerca e sviluppo di Eth2 su ethresearch.ch.",
  "page-eth2-question-6-desc": "La tua dapp non sarà influenzata da alcun aggiornamento imminente. Ma gli aggiornamenti futuri potrebbero richiedere alcune modifiche.",
  "page-eth2-question-6-title": "Che cosa devo fare con la mia dapp?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(Implementazione .NET)",
  "page-eth2-question-7-desc": "Molti team diversi di tutta la community stanno lavorando sui vari aggiornamenti Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Implementazione rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(implementazione JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Implementazione Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Implementazione Go)",
  "page-eth2-question-7-teams": "I team client Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Implementazione Java)",
  "page-eth2-question-7-title": "Chi sta lavorando a Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Implementazione Python)",
  "page-eth2-question-8-answer-1": "Gli aggiornamenti Eth2 contribuiranno alla scalabilità di Ethereum in modo decentralizzato, mantenendo la sicurezza e aumentando la sostenibilità.",
  "page-eth2-question-8-answer-2": "Forse il problema più evidente è che Ethereum deve essere in grado di gestire più di 15-45 transazioni al secondo. Ma gli aggiornamenti Eth2 affrontano oggi anche altri problemi di Ethereum.",
  "page-eth2-question-8-answer-3": "La domanda sulla rete è così elevata che sta rendendo costoso l'uso di Ethereum. I nodi nella rete faticano per via delle dimensioni di Ethereum e della quantità di dati che i computer devono elaborare. E l'algoritmo sottostante che mantiene Ethereum sicura e decentralizzata è ad alta intensità energetica e deve essere più eco-friendly.",
  "page-eth2-question-8-answer-4": "Molti dei cambiamenti attuali sono sempre stati nella tabella di marcia di Ethereum, sin dal 2015. Ma le condizioni odierne stanno rendendo gli aggiornamenti ancora più necessari.",
  "page-eth2-question-8-answer-6": "Scopri la vision Eth2",
  "page-eth2-question-8-desc": "La rete Ethereum che utilizziamo oggi deve offrire una migliore esperienza agli utenti finali e ai partecipanti.",
  "page-eth2-question-8-title": "Perché è necessario Eth2?",
  "page-eth2-question-9-answer-1": "Il ruolo più attivo che puoi scegliere di avere è lo staking con i tuoi ETH.",
  "page-eth2-question-9-answer-2": "Se lo desideri, puoi anche eseguire un secondo client per contribuire a migliorare la diversità dei client.",
  "page-eth2-question-9-answer-3": "Se hai conoscente tecniche, puoi contribuire a trovare bug nei nuovi client.",
  "page-eth2-question-9-answer-4": "Puoi inoltre partecipare alle discussioni tecniche con i ricercatori Ethereum su ethresearch.ch.",
  "page-eth2-question-9-desc": "Non devi essere un tecnico per contribuire. La community cerca collaborazioni a ogni livello di competenza.",
  "page-eth2-question-9-stake-eth": "Fare staking con gli ETH",
  "page-eth2-question-9-title": "Come posso contribuire a Eth2?",
  "page-eth2-question-10-answer-1": "Siamo riluttanti a parlare troppo di tabella di marcia tecnica perché abbiamo a che fare con software e quindi le cose possono cambiare. Pensiamo che sia più facile capire cosa stia succedendo guardando i risultati.",
  "page-eth2-question-10-answer-1-link": "Visualizza gli aggiornamenti",
  "page-eth2-question-10-answer-2": "Ma se hai seguito le discussioni, ecco come gli aggiornamenti si inseriscono nelle tabelle di marcia tecniche.",
  "page-eth2-question-10-answer-3": "Con fase 0 si intende il lavoro completato per far sì che la beacon chain diventasse realtà.",
  "page-eth2-question-10-answer-5": "La fase 1 delle tabelle di marcia tecniche si concentra sull'implementazione delle shard chain.",
  "page-eth2-question-10-answer-6": "Il docking della rete principale in Eth2 è un'estensione del lavoro svolto per implementare le shard chain e viene definito fase 1.5. Ma è un momento molto importante, perché la rete Ethereum che conosciamo oggi si fonderà con gli altri aggiornamenti Eth2. Inoltre è il punto in cui Ethereum passerà completamente a proof of stake.",
  "page-eth2-question-10-answer-7": "Al momento non è chiaro cosa accadrà nella fase 2. È ancora motivo di intensa ricerca e discussione. Il piano iniziale era quello di aggiungere funzionalità extra alle shard chain, ma potrebbe non essere necessario con una tabella di marcia incentrata sul rollup.",
  "page-eth2-question-10-answer-8": "Ulteriori informazioni su una tabella di marcia incentrata sul rollup",
  "page-eth2-question-10-desc": "Le fasi si riferiscono alle fasi di lavoro e sono incentrate sulla tabella di marcia tecnica Eth2.",
  "page-eth2-question-10-title": "Cosa sono le fasi di Eth2?",
  "page-eth2-question-title": "Domande frequenti",
  "page-eth2-question3-answer-1": "Chi detiene Eth di sicuro non dovrà fare nulla. I tuoi ETH non cambieranno né verranno aggiornati. Quasi certamente circoleranno bufale che affermeranno il contrario, quindi fai attenzione.",
  "page-eth2-scalable": "Più scalabile",
  "page-eth2-scalable-desc": "Ethereum deve supportare migliaia di transazioni al secondo, per rendere le applicazioni più veloci e meno costose da usare.",
  "page-eth2-secure": "Più sicura",
  "page-eth2-secure-desc": "Ethereum deve essere più sicura; man mano che l'adozione di Ethereum aumenta, il protocollo deve essere più protetto da tutte le forme di attacco.",
  "page-eth2-shard-button": "Maggiori informazioni sulle shard chain",
  "page-eth2-shard-date": "L'introduzione delle shard chain, il secondo aggiornamento, è prevista nel corso del 2021.",
  "page-eth2-shard-desc": "Le shard chain espanderanno la capacità di Ethereum di elaborare le transazioni e archiviare i dati. Gli shard stessi otterranno più funzionalità nel tempo, con rollout su più fasi.",
  "page-eth2-shard-estimate": "Stima: 2021",
  "page-eth2-shard-lower": "Maggiori informazioni sulle shard chain",
  "page-eth2-shard-title": "Shard chain",
  "page-eth2-stay-up-to-date": "Segui gli aggiornamenti",
  "page-eth2-stay-up-to-date-desc": "Ricevi le ultime notizie dai ricercatori e dagli sviluppatori che lavorano agli aggiornamenti Eth2.",
  "page-eth2-sustainable-desc": "Ethereum deve essere migliore per l'ambiente; oggi la tecnologia richiede troppa potenza di calcolo ed energia.",
  "page-eth2-take-part": "Partecipa alla ricerca",
  "page-eth2-take-part-desc": "I ricercatori così come gli appassionati di Ethereum si incontrano qui per discutere il lavoro di ricerca, compreso Eth2.",
  "page-eth2-the-upgrades": "Gli aggiornamenti Eth2",
  "page-eth2-the-upgrades-desc": "Con Eth2 si intende una serie di aggiornamenti che migliorano la scalabilità, la sicurezza e la sostenibilità di Ethereum. Anche se si sta lavorando in contemporanea su tutto, ogni aggiornamento ha dipendenze che determinano quando sarà distribuito.",
  "page-eth2-unofficial-roadmap": "Questa non è la tabella di marcia ufficiale, ma quello che sta in effetti succedendo. Si tratta di tecnologia, tutto può cambiare in un attimo, quindi non considerare queste informazioni come un impegno certo.",
  "page-eth2-upgrade-desc": "La rete Ethereum che tutti conosciamo e amiamo, solo più scalabile, più sicura e più sostenibile.",
  "page-eth2-upgrades": "Gli aggiornamenti Eth2",
  "page-eth2-upgrades-aria-label": "Menu aggiornamenti Eth2",
  "page-eth2-upgrades-beacon-chain": "La beacon chain",
  "page-eth2-upgrades-docking": "Docking della rete principale ed Eth2",
  "page-eth2-upgrades-guide": "Guida agli aggiornamenti Eth2",
  "page-eth2-upgrades-shard-chains": "Le shard chain",
  "page-eth2-upgrading": "Aggiornare Ethereum a nuovi livelli",
  "page-eth2-vision": "La vision",
  "page-eth2-vision-btn": "Maggiori informazioni sulla vision Eth2",
  "page-eth2-vision-desc": "Per portare Ethereum nella rete principale e servire tutta l'umanità, dobbiamo rendere Ethereum più scalabile, sicura e sostenibile.",
  "page-eth2-vision-upper": "Vision Eth2",
  "page-eth2-what-to-do": "Che cosa devi fare?",
  "page-eth2-what-to-do-desc": "Se sei utente di una dapp o detieni ETH, non devi fare nulla. Se sei uno sviluppatore o vuoi iniziare a fare staking, oggi ci sono diversi modi per essere coinvolti.",
  "page-eth2-whats-eth2": "Cos'è Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 si riferisce ad una serie di aggiornamenti interconnessi che renderanno Ethereum più scalabile, più sicura e più sostenibile. Questi aggiornamenti sono stati realizzati da più team di tutto l'ecosistema Ethereum.",
  "page-eth2-whats-ethereum": "Ma cos'è Ethereum?",
  "page-eth2-whats-new": "Quali sono le novità di Eth2?",
  "page-eth2-just-staking": "Staking",
  "page-eth2-staking-image-alt": "Immagine del rinoceronte, mascotte del launchpad di eth2.",
  "page-eth2-staking-51-desc-1": "Lo staking rende l'accesso alla rete in qualità di validatore più accessibile, quindi è probabile che ci saranno più validatori rispetto ad oggi. Questo tipo di attacco sarà pertanto ancora più difficile perché il relativo costo aumenterà.",
  "page-eth2-staking-accessibility": "Più accessibile",
  "page-eth2-staking-accessibility-desc": "Con requisiti hardware inferiori e la possibilità di partecipare a pool se non si dispone di 32 ETH, più persone saranno in grado di unirsi alla rete. Questo renderà Ethereum più decentralizzata e sicura, riducendo l'area della superficie di attacco.",
  "page-eth2-staking-at-stake": "Staking con gli ETH",
  "page-eth2-staking-at-stake-desc": "Poiché devi fare staking con i tuoi ETH per convalidare le transazioni e creare nuovi blocchi, li puoi perdere se decidi di provare a raggirare il sistema.",
  "page-eth2-staking-benefits": "Vantaggi dello staking su Ethereum",
  "page-eth2-staking-check-address": "Controlla l'indirizzo del deposito",
  "page-eth2-staking-consensus": "Maggiori informazioni sui meccanismi di consenso",
  "page-eth2-staking-deposit-address": "Controlla l'indirizzo di deposito",
  "page-eth2-staking-deposit-address-desc": "Se hai già seguito le istruzioni di configurazione del launchpad, saprai che è necessario inviare una transazione al contratto di deposito di staking. Ti consigliamo di controllare l'indirizzo con molta attenzione. Puoi trovare l'indirizzo ufficiale su ethereum.org e una serie di altri siti attendibili.",
  "page-eth2-staking-desc-1": "Le ricompense vengono assegnate per le azioni che aiutano la rete a raggiungere un consenso. Otterrai ricompense per aver raggruppato transazioni in un nuovo blocco o per aver controllato il lavoro di altri validatori, perché è ciò che permette il funzionamento sicuro della catena.",
  "page-eth2-staking-desc-2": "È possibile guadagnare ricompense per il lavoro che va a vantaggio della rete, ma si possono anche perdere ETH a causa di azioni dannose, andando offline e se non si viene convalidati.",
  "page-eth2-staking-desc-3": "Servono 32 ETH per diventare a tutti gli effetti validatore o pochi ETH per entrare a far parte di un pool di staking. Dovrai inoltre eseguire un 'Eth1' o client di rete principale. Il launchpad ti guiderà attraverso i requisiti hardware e quelli relativi al processo. In alternativa, puoi usare un'API backend.",
  "page-eth2-staking-description": "Per staking si intende l'atto di depositare 32 ETH per attivare il software di convalida. Come validatore sarai responsabile dell'archiviazione dei dati, dell'elaborazione delle transazioni e dell'aggiunta di nuovi blocchi alla blockchain. Questo manterrà Ethereum sicura per tutti e ti farà guadagnare ulteriori ETH. Questo processo, detto proof of stake, viene introdotto con la beacon chain.",
  "page-eth2-staking-docked": "Maggiori informazioni sul docking",
  "page-eth2-staking-dyor": "Fai ricerche per verificare",
  "page-eth2-staking-dyor-desc": "Nessuno dei servizi di staking elencati è stato approvato ufficialmente. Assicurati di fare le giuste ricerche per capire quale servizio potrebbe essere migliore per te.",
  "page-eth2-staking-header-1": "Fai staking con i tuoi ETH per diventare validatore di Ethereum",
  "page-eth2-staking-how-much": "Quanto sei disponibile a usare per lo staking?",
  "page-eth2-staking-how-to-stake": "Come fare staking",
  "page-eth2-staking-how-to-stake-desc": "Tutto dipende da quanto sei disponibile a utilizzare per lo staking. Per diventare validatore a tutti gli effetti servono 32 ETH, ma è anche possibile utilizzarne meno.",
  "page-eth2-staking-join": "Partecipa",
  "page-eth2-staking-join-community": "Unisciti alla community degli staker",
  "page-eth2-staking-join-community-desc": "r/ethstaker è una community pubblica in cui si può discutere di staking su Ethereum. Partecipa per consigli, supporto e per parlare di tutto ciò che riguarda lo staking.",
  "page-eth2-staking-less-than": "Meno di",
  "page-eth2-staking-link-1": "Guarda le API di backend",
  "page-eth2-staking-meta-description": "Panoramica sullo staking su Ethereum: rischi, ricompense, requisiti e dove farlo.",
  "page-eth2-staking-meta-title": "Staking su Ethereum",
  "page-eth2-staking-more-sharding": "Scopri di più sullo sharding",
  "page-eth2-staking-pool": "Staking in un pool",
  "page-eth2-staking-pool-desc": "Se hai meno di 32 ETH potrai mettere in staking una quantità minore attraverso i pool di staking. Alcune aziende possono occuparsi di tutto, quindi non è necessario rimanere online. Ecco alcune aziende che puoi valutare.",
  "page-eth2-staking-pos-explained": "Spiegazione della proof of stake",
  "page-eth2-staking-pos-explained-desc": "Per diventare validatore in un sistema proof of stake è necessario fare staking. È un meccanismo di consenso che andrà a sostituire il sistema proof of work attualmente in essere. I meccanismi di consenso sono ciò che mantiene le blockchain come Ethereum sicure e decentralizzate.",
  "page-eth2-staking-pos-explained-desc-1": "La proof of stake aiuta a mantenere sicura la rete in diversi modi:",
  "page-eth2-staking-services": "Guarda i servizi di staking",
  "page-eth2-staking-sharding": "Possibilità di sharding",
  "page-eth2-staking-sharding-desc": "Lo sharding è possibile solo in sistemi con meccanismo proof of stake. Lo sharding in un sistema di proof of work diluirebbe la capacità di calcolo necessaria per corrompere la rete, rendendo più facile il lavoro di miner malintenzionati che puntano a controllare gli shard. Questo non succede con staker assegnati causalmente in un meccanismo proof of stake.",
  "page-eth2-staking-solo": "Fai staking in autonomia ed esegui un validatore",
  "page-eth2-staking-solo-desc": "Per iniziare con il processo di staking dovrai usare il launchpad Eth2. Questo ti guiderà attraverso tutta la configurazione. Parte dello staking consiste nell'eseguire un client Eth2, che è una copia locale della blockchain. Potrà essere necessario un po' di tempo per scaricarla sul computer.",
  "page-eth2-staking-start": "Inizia a fare staking",
  "page-eth2-staking-subtitle": "Lo staking è un bene pubblico per l'ecosistema di Ethereum. Puoi contribuire a rendere la rete sicura e guadagnare ricompense durante il processo.",
  "page-eth2-staking-sustainability": "Più duraturo",
  "page-eth2-staking-sustainability-desc": "I validatori non hanno bisogno di computer estremamente potenti per partecipare a un sistema proof of stake: basta un laptop o uno smartphone. In questo modo, Ethereum sarà anche più amico dell'ambiente.",
  "page-eth2-staking-the-beacon-chain": "Maggiori informazioni sulla beacon chain",
  "page-eth2-staking-title-1": "Ricompense",
  "page-eth2-staking-title-2": "Rischi",
  "page-eth2-staking-title-3": "Requisiti",
  "page-eth2-staking-title-4": "Come fare staking con i tuoi ETH",
  "page-eth2-staking-upgrades-li": "La proof of stake è gestita dalla beacon chain.",
  "page-eth2-staking-upgrades-li-2": "Nel prossimo futuro, Ethereum avrà una beacon chain di tipo proof of stake e una rete principale di tipo proof of work. La rete principale corrisponde alla realtà Ethereum che conosciamo da anni.",
  "page-eth2-staking-upgrades-li-3": "Nel frattempo, gli staker aggiungeranno nuovi blocchi alla beacon chain ma non elaboreranno transazioni sulla rete principale.",
  "page-eth2-staking-upgrades-li-4": "Ethereum completerà la transizione a un sistema di tipo proof of stake una volta che la rete principale diventerà uno shard.",
  "page-eth2-staking-upgrades-li-5": "Solo a questo punto potrai prelevare l'importo utilizzato per lo staking.",
  "page-eth2-staking-upgrades-title": "Proof-of-stake e aggiornamenti di Eth2",
  "page-eth2-staking-validators": "Più validatori, più sicurezza",
  "page-eth2-staking-validators-desc": "In una blockchain come Ethereum è possibile che si verifichino episodi di corruzione nel caso in cui si controlli il 51% della rete. Per esempio, con il 51% dei validatori potresti dichiarare che il tuo saldo corrisponde a 1.000.000 di ETH anziché a di 1 ETH. Però, per controllare il 51% dei validatori, dovresti possedere il 51% degli ETH in circolazione: sono tantissimi!",
  "page-eth2-staking-withdrawals": "I prelievi non saranno disponibili da subito",
  "page-eth2-staking-withdrawals-desc": "Non sarai in grado di prelevare l'importo di staking prima della distribuzione degli aggiornamenti futuri. I prelievi saranno disponibili in seguito al docking della rete principale con la beacon chain.",
  "page-eth2-vision-2014": "Leggi un post del 2014 riguardante la proof of stake",
  "page-eth2-vision-beacon-chain": "La beacon chain",
  "page-eth2-vision-beacon-chain-btn": "Maggiori informazioni sulla beacon chain",
  "page-eth2-vision-beacon-chain-date": "La beacon chain è realtà",
  "page-eth2-vision-beacon-chain-desc": "Come prima aggiunta Eth2 all'ecosistema, la beacon chain aggiunge lo staking a Ethereum, pone le basi per aggiornamenti futuri e alla fine coordinerà il nuovo sistema.",
  "page-eth2-vision-beacon-chain-upper": "La beacon chain",
  "page-eth2-vision-desc-1": "Ethereum deve ridurre la congestione della rete e aumentare la velocità per servire meglio una base di utenti globale.",
  "page-eth2-vision-desc-2": "L'esecuzione di un nodo diventa sempre più difficile man mano che la rete cresce. E diventerà ancora più difficile con il lavoro svolto per ampliare la rete.",
  "page-eth2-vision-desc-3": "Ethereum utilizza troppa energia elettrica. La tecnologia che mantiene la rete sicura deve essere più sostenibile.",
  "page-eth2-vision-docking-date": "Stima: 2022",
  "page-eth2-vision-docking-desc": "A un certo punto, la rete principale Ethereum dovrà “agganciarsi” alla beacon chain (docking). Questo consentirà lo staking sull'intera rete e segnerà la fine del mining ad alta intensità energetica.",
  "page-eth2-vision-ethereum-node": "Maggiori informazioni sui nodi",
  "page-eth2-vision-explore-upgrades": "Esplora gli aggiornamenti",
  "page-eth2-vision-future": "Un futuro digitale su scala globale",
  "page-eth2-vision-meta-desc": "Panoramica sull'impatto che gli aggiornamenti di Eth2 avranno su Ethereum e sulle sfide da vincere.",
  "page-eth2-vision-meta-title": "La vision di Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Maggiori informazioni sul mining",
  "page-eth2-vision-problems": "Problemi odierni",
  "page-eth2-vision-scalability": "Scalabilità",
  "page-eth2-vision-scalability-desc": "Ethereum deve essere in grado di gestire una maggiore quantità di transazioni per secondo senza aumentare la dimensione dei nodi nella rete. I nodi sono elementi essenziali della rete, archiviano ed eseguono la blockchain. Aumentare la dimensione dei nodi non è una soluzione pratica perché solo chi dispone di computer costosi e potenti potrebbe farlo.\nPer ampliarsi, Ethereum ha bisogno di gestire un numero maggiore di transazioni al secondo, abbinate a più nodi. Più nodi significa più sicurezza.",
  "page-eth2-vision-scalability-desc-3": "L'aggiornamento delle shard chain suddividerà il carico della rete in 64 nuove catene. Questo darà respiro a Ethereum, riducendone la congestione e aumentando la velocità oltre l'attuale limite di 15-45 transazioni al secondo.",
  "page-eth2-vision-scalability-desc-4": "Anche se ci saranno più catene, sarà in realtà richiesto uno sforzo minore ai validatori, i \"manutentori della rete\", che dovranno eseguire solo il proprio shard e non l'intera catena Ethereum. Questo renderà i nodi più leggeri, permettendo a Ethereum di ampliarsi rimanendo decentralizzata.",
  "page-eth2-vision-security": "Sicurezza",
  "page-eth2-vision-security-desc": "Gli aggiornamenti di Eth2 miglioreranno la sicurezza di Ethereum contro gli attacchi coordinati, come gli attacchi 51%, in cui chi controlla la maggior parte della rete può forzare modifiche fraudolente.",
  "page-eth2-vision-security-desc-3": "La transizione alla proof of stake porta a disincentivare significativamente gli attacchi verso il protocollo Ethereum. Questo perché, utilizzando la proof of stake, i validatori che proteggono la rete devono fare staking nel protocollo con importanti quantità di ETH. Se dovessero provare ad attaccare la rete, il protocollo potrebbe automaticamente distruggere i loro ETH.",
  "page-eth2-vision-security-desc-5": "Questo non è possibile con un sistema di tipo proof of work, dove il massimo che un protocollo possa fare è forzare le entità che proteggono la rete (i miner) a perdere le ricompense di mining che altrimenti avrebbero guadagnato. Per ottenere un effetto simile con il sistema proof of work, il protocollo dovrebbe essere in grado di distruggere tutte le apparecchiature utilizzate dai miner se dovessero provare ad aggirare il sistema.",
  "page-eth2-vision-security-desc-5-link": "Maggiori informazioni sulla proof of work",
  "page-eth2-vision-security-desc-8": "Anche il modello di sicurezza di Ethereum deve cambiare a causa dell'introduzione di",
  "page-eth2-vision-security-desc-10": "Staking significa anche che non è necessario investire in hardware d'èlite per eseguire un nodo Ethereum. Questo dovrebbe incoraggiare più utenti a diventare validatori, aumentando la decentralizzazione della rete e riducendo la superficie di attacco.",
  "page-eth2-vision-security-staking": "Punta ETH",
  "page-eth2-vision-security-validator": "Puoi diventare validatore facendo staking con i tuoi ETH.",
  "page-eth2-vision-shard-chains": "le shard chain",
  "page-eth2-vision-shard-date": "Stima: 2021",
  "page-eth2-vision-shard-desc-4": "Le shard chain suddivideranno il carico della rete in 64 nuove blockchain. Gli shard hanno la capacità di aumentare drasticamente la velocità delle transazioni, portandole fino a 100.000 al secondo.",
  "page-eth2-vision-shard-upgrade": "Maggiori informazioni sulle shard chain",
  "page-eth2-vision-staking-lower": "Maggiori informazioni sullo staking",
  "page-eth2-vision-subtitle": "Far crescere Ethereum fino a che non sarà abbastanza potente per aiutare tutta l'umanità.",
  "page-eth2-vision-sustainability": "Sostenibilità",
  "page-eth2-vision-sustainability-desc-1": "Non è un segreto che Ethereum e altre blockchain come Bitcoin consumino molta energia a causa del mining.",
  "page-eth2-vision-sustainability-desc-2": "Ma Ethereum si sta muovendo per poter essere protetta tramite ETH e non dalla capacità di calcolo, grazie allo staking.",
  "page-eth2-vision-sustainability-desc-3": "Anche se lo staking verrà introdotto con la beacon chain, la rete Ethereum che utilizziamo oggi funzionerà in parallelo per un determinato periodo di tempo, prima di \"fondersi\" o \"agganciarsi\" agli aggiornamenti Eth2. Un sistema è protetto dagli ETH, l'altro dalla capacità di calcolo, questo perché, all'inizio, le shard chain non saranno in grado di gestire entità come gli account o le dapp. Quindi non potremo fare a meno improvvisamente del mining e della rete principale.",
  "page-eth2-vision-sustainability-desc-8": "Una volta che gli aggiornamenti alla beacon chain e alla shard chain saranno completati e funzionanti, inizierà il lavoro di docking della rete principale al nuovo sistema, per trasformare la rete principale in uno shard protetto dagli ETH e molto meno dispendioso dal punto di vista energetico.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum deve essere più amica dell'ambiente.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "La vision Eth2",
  "page-eth2-vision-title-1": "Rete congestionata",
  "page-eth2-vision-title-2": "Spazio su disco",
  "page-eth2-vision-title-3": "Troppa energia",
  "page-eth2-vision-trilemma-cardtext-1": "Gli aggiornamenti a Eth2 renderanno Ethereum scalabile, sicura e decentralizzata. Lo sharding la renderà più scalabile, aumentando il numero di transazioni al secondo e contestualmente diminuendo la quantità di energia necessaria per eseguire un nodo e convalidare la catena. La beacon chain renderà Ethereum sicura, coordinando i validatori dei diversi shard. Lo staking abbatterà le barriere alla partecipazione, creando una rete più grande e più decentralizzata.",
  "page-eth2-vision-trilemma-cardtext-2": "Le reti blockchain sicure e decentralizzate richiedono che ogni nodo verifichi tutte le transazioni elaborate dalla catena. Questa mole di lavoro limita il numero di transazioni che possono essere effettuate in un dato momento. Decentralizzazione e sicurezza corrispondono a quello che è oggi la blockchain di Ethereum.",
  "page-eth2-vision-trilemma-cardtext-3": "Le reti decentralizzate funzionano inviando informazioni sulle transazioni attraverso nodi perché tutta la rete deve essere a conoscenza di ogni cambiamento di stato. Ampliare il numero di transazioni al secondo in una rete decentralizzata crea rischi di sicurezza perché più sono le transazioni, più aumenta il ritardo e quindi più alta è la probabilità di un attacco perpetuato mentre le informazioni sono in viaggio.",
  "page-eth2-vision-trilemma-cardtext-4": "Aumentare la dimensione e la potenza dei nodi di Ethereum potrebbe far aumentare il numero di transazioni al secondo in modo sicuro, ma i requisiti hardware permetterebbero a pochi di partecipare, e questo mina la decentralizzazione. Si spera che lo sharding e la proof of stake permettano ad Ethereum di ampliarsi aumentando il numero dei nodi e non la loro dimensione.",
  "page-eth2-vision-trilemma-h2": "La sfida dell'ampliamento decentralizzato",
  "page-eth2-vision-trilemma-modal-tip": "Tocca i cerchi qua sotto per capire meglio i problemi legati a un ampliamento decentralizzato",
  "page-eth2-vision-trilemma-p": "Un modo semplice per risolvere i problemi di Ethereum potrebbe essere quello di centralizzare maggiormente la rete. Ma la decentralizzazione è troppo importante. È la decentralizzazione infatti che garantisce a Ethereum resistenza alla censura, apertura, privacy dei dati e sicurezza quasi inattaccabile.",
  "page-eth2-vision-trilemma-p-1": "La vision di Ethereum è diventare più scalabile e sicura, rimanendo decentralizzata. Possedere queste tre qualità è un problema noto come \"trilemma della scalabilità\".",
  "page-eth2-vision-trilemma-p-2": "Gli aggiornamenti a Eth2 hanno lo scopo di risolvere il trilemma ma pongono sfide significative.",
  "page-eth2-vision-trilemma-press-button": "Scegli i pulsanti sul triangolo per capire meglio i problemi legati all'ampliamento decentralizzato.",
  "page-eth2-vision-trilemma-text-1": "Decentralizzazione",
  "page-eth2-vision-trilemma-text-2": "Sicurezza",
  "page-eth2-vision-trilemma-text-3": "Scalabilità",
  "page-eth2-vision-trilemma-title-1": "Esplora il trilemma della scalabilità",
  "page-eth2-vision-trilemma-title-2": "Aggiornamenti a Eth2 e ampliamento decentralizzato",
  "page-eth2-vision-trilemma-title-3": "Sicura e decentralizzata",
  "page-eth2-vision-trilemma-title-4": "Decentralizzata e scalabile",
  "page-eth2-vision-trilemma-title-5": "Scalabile e sicura",
  "page-eth2-vision-understanding": "Capire la vision di Eth2",
  "page-eth2-vision-upgrade-needs": "La necessità degli aggiornamenti a Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Il protocollo Ethereum lanciato nel 2015 ha avuto un incredibile successo. La community di Ethereum però ha sempre saputo che sarebbero stati necessari alcuni aggiornamenti chiave per sbloccare il pieno potenziale di Ethereum.",
  "page-eth2-vision-upgrade-needs-desc-2": "La domanda elevata sta facendo aumentare le commissioni sulle transazioni, rendendo Ethereum costosa per l'utente medio. Lo spazio su disco necessario per eseguire un client Ethereum sta crescendo rapidamente. Inoltre, l'algoritmo di consenso proof of work che mantiene Ethereum sicura e decentralizzata ha un forte impatto ambientale.",
  "page-eth2-vision-upgrade-needs-desc-3": "Con Eth2 si intende comunemente una serie di aggiornamenti che puntano a risolvere questi e altri problemi. Il set di aggiornamenti in origine si chiamava \"Serenity\" ed è oggetto di ricerca e sviluppo fin dal 2014.",
  "page-eth2-vision-upgrade-needs-desc-6": " Questo significa che non ci sarà un passaggio netto a Eth2. I miglioramenti verranno rilasciati nel tempo in modo incrementale.",
  "page-eth2-vision-upgrade-needs-serenity": "Leggi un post del 2015 a proposito di \"Serenity\"",
  "page-eth2-vision-uprade-needs-desc-5": "Ora che la tecnologia è pronta, questi aggiornamenti riprogetteranno Ethereum per renderla più scalabile, sicura e sostenibile, con lo scopo di migliorare la vita degli utenti esistenti e di coinvolgerne nuovi. Il tutto mantenendo sempre il valore fondamentale di Ethereum, cioè la decentralizzazione.",
  "page-get-eth-cex": "Borse centralizzate",
  "page-get-eth-cex-desc": "Gli scambi sono aziende che consentono di acquistare criptovalute usando valute tradizionali. Hanno la custodia su tutti gli ETH che acquisti fino a quando li invii a un portafoglio che controlli.",
  "page-get-eth-checkout-dapps-btn": "Scopri le dapp",
  "page-get-eth-community-safety": "Post della community sulla sicurezza",
  "page-get-eth-description": "Ethereum ed ETH non sono controllati da nessun governo o azienda, sono decentralizzati. Significa che gli ETH possono essere utilizzati da tutti.",
  "page-get-eth-dex": "Borse decentralizzate (DEX)",
  "page-get-eth-dex-desc": "Se vuoi più controllo, compra ETH peer-to-peer. Con una DEX puoi acquistare e vendere senza dare a un'azienda centralizzata il controllo dei tuoi fondi.",
  "page-get-eth-dexs": "Borse decentralizzate (DEX)",
  "page-get-eth-dexs-desc": "Le borse decentralizzate sono piazze aperte in cui scambiare ETH e altri token. Collegano direttamente acquirenti e venditori.",
  "page-get-eth-dexs-desc-2": "Anziché utilizzare una terza parte attendibile per salvaguardare i fondi nella transazione, utilizzano il codice. L'ETH del venditore verrà trasferito solo quando il pagamento sarà garantito. Questo tipo di codice è detto smart contract.",
  "page-get-eth-dexs-desc-3": "Tutto questo significa che ci sono meno restrizioni geografiche rispetto alle alternative centralizzate. Se qualcuno vende ciò che cerchi e accetta un metodo di pagamento fornisci, puoi procedere. Con le DEX puoi acquistare ETH con altri token, Paypal o anche contanti di persona.",
  "page-get-eth-do-not-copy": "ESEMPIO: NON COPIARE",
  "page-get-eth-exchanges-disclaimer": "Abbiamo raccolto questa informazione manualmente. Se trovi qualcosa di sbagliato, faccelo sapere:",
  "page-get-eth-exchanges-empty-state-text": "Inserisci il tuo paese di residenza per vedere una lista di portafogli e scambi che puoi usare per acquistare ETH",
  "page-get-eth-exchanges-except": "Eccetto",
  "page-get-eth-exchanges-header": "In che paese vivi?",
  "page-get-eth-exchanges-header-exchanges": "Scambi",
  "page-get-eth-exchanges-header-wallets": "Wallet",
  "page-get-eth-exchanges-intro": "Borse e portafogli hanno restrizioni sul luogo di vendita delle criptovalute.",
  "page-get-eth-exchanges-no-exchanges": "Siamo spiacenti, non conosciamo borse che ti permettano di acquistare ETH da questo paese. Se tu le conosci, faccelo sapere:",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Siamo spiacenti, non conosciamo borse o portafogli che ti permettano di acquistare ETH da questo paese. Se tu li conosci, faccelo sapere:",
  "page-get-eth-exchanges-no-wallets": "Siamo spiacenti, non conosciamo portafogli che ti permettano di acquistare ETH da questo paese. Se tu li conosci, faccelo sapere:",
  "page-get-eth-exchanges-success-exchange": "La registrazione a una borsa può richiedere diversi giorni a causa dei controlli legali da eseguire.",
  "page-get-eth-exchanges-success-wallet-link": "portafogli",
  "page-get-eth-exchanges-success-wallet-paragraph": "Nel paese in cui vivi, puoi acquistare ETH direttamente da questi portafogli. Scopri di più su",
  "page-get-eth-exchanges-usa": "Stati Uniti d'America (USA)",
  "page-get-eth-get-wallet-btn": "Ottieni un portafoglio",
  "page-get-eth-hero-image-alt": "Immagine hero Ottieni ETH",
  "page-get-eth-keep-it-safe": "Mantieni al sicuro i tuoi ETH",
  "page-get-eth-meta-description": "Come acquistare ETH in base al paese in cui vivi. Consigli su come prendersene cura.",
  "page-get-eth-meta-title": "Come acquistare ETH",
  "page-get-eth-need-wallet": "Serve un portafogli per utilizzare una DEX.",
  "page-get-eth-new-to-eth": "Non conosci ETH? Ecco una panoramica per muovere i primi passi.",
  "page-get-eth-other-cryptos": "Acquista con altre criptovalute",
  "page-get-eth-protect-eth-desc": "Se prevedi di acquistare molti ETH, è consigliabile conservarli in un portafoglio sotto il tuo controllo anziché in una borsa. Questo perché una borsa è un bersaglio più facile per gli hacker. Se un hacker ottiene l'accesso, potresti perdere i tuoi fondi. Al contrario, il controllo del tuo portafoglio è solo tuo.",
  "page-get-eth-protect-eth-in-wallet": "Proteggi i tuoi ETH in un portafoglio",
  "page-get-eth-search-by-country": "Cerca per paese",
  "page-get-eth-security": "Questo però significa anche che devi prendere sul serio la sicurezza dei tuoi fondi. Con ETH, non affidi i tuoi soldi a una banca, ci devi pensare tu.",
  "page-get-eth-smart-contract-link": "Maggiori informazioni sugli smart contract",
  "page-get-eth-swapping": "Scambia i tuoi token con ETH di altri. E viceversa.",
  "page-get-eth-traditional-currencies": "Acquista con valute tradizionali",
  "page-get-eth-traditional-payments": "Acquista ETH direttamente dai venditori con metodi di pagamento tradizionali.",
  "page-get-eth-try-dex": "Prova una Dex",
  "page-get-eth-use-your-eth": "Usa i tuoi ETH",
  "page-get-eth-use-your-eth-dapps": "Ora che possiedi qualche ETH, dai un'occhiata ad alcune applicazioni Ethereum (dapp). Ci sono dapp di finanza, social media, videogiochi e molte altre categorie.",
  "page-get-eth-wallet-instructions": "Segui le istruzioni del portafoglio",
  "page-get-eth-wallet-instructions-lost": "Perdere l'accesso al portafoglio significa perderlo ai fondi. Il tuo portafoglio deve darti istruzioni su come evitare questa situazione. Seguile con attenzione. Nella maggior parte dei casi, nessuno potrà aiutarti se perderai l'accesso al tuo portafoglio.",
  "page-get-eth-wallets": "Wallet",
  "page-get-eth-wallets-link": "Maggiori informazioni sui portafogli",
  "page-get-eth-wallets-purchasing": "Alcuni portafogli ti permettono di acquistare criptovalute con carta di debito/credito, bonifico bancario oppure Apple Pay. Si applicano restrizioni geografiche.",
  "page-get-eth-warning": "Queste DEX non sono per principianti, in quanto avrai bisogno di diversi ETH per usarle.",
  "page-get-eth-what-are-DEX's": "Cosa sono le DEX?",
  "page-get-eth-whats-eth-link": "Cos'è l'ETH?",
  "page-get-eth-where-to-buy-desc": "Puoi acquistare ETH direttamente dalle borse o dai portafogli.",
  "page-get-eth-where-to-buy-desc-2": "Controlla quali servizi puoi usare in base al luogo in cui vivi.",
  "page-get-eth-where-to-buy-title": "Dove acquistare ETH",
  "page-get-eth-your-address": "Il tuo indirizzo ETH",
  "page-get-eth-your-address-desc": "Quando scarichi un portafoglio, ti verrà creato un indirizzo ETH pubblico, che si presenta così:",
  "page-get-eth-your-address-desc-3": "Puoi considerarlo come un indirizzo email, su cui invece delle email riceverai ETH. Se vuoi trasferire ETH da una borsa al tuo portafoglio, usa questo indirizzo come destinazione. Controlla sempre due volte prima di confermare l'invio!",
  "page-get-eth-your-address-wallet-link": "Scopri i portafogli",
  "page-index-hero-image-alt": "banner inizio pagina ethereum.org",
  "page-index-meta-description": "Ethereum è una piattaforma globale e decentralizzata per denaro e nuovi tipi di applicazioni. Su Ethereum è possibile scrivere codice per controllare il denaro e creare applicazioni accessibili da ogni parte del mondo.",
  "page-index-meta-title": "Home page",
  "page-index-title": "Ethereum è una piattaforma open source globale per applicazioni decentralizzate.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Lingue supportate",
  "page-languages-interested": "Ti interessa contribuire?",
  "page-languages-learn-more": "Scopri di più sul nostro programma di traduzione",
  "page-languages-meta-desc": "Risorse per tutte le lingue supportate da ethereum.org e modi per contribuire come traduttore.",
  "page-languages-meta-title": "Traduzioni su ethereum.org",
  "page-languages-p1": "Ethereum è un progetto globale, ed è fondamentale che ethereum.org sia accessibile a tutti, indipendentemente dalla lingua e dalla nazionalità. La nostra community lavora sodo per trasformare questa vision in realtà.",
  "page-languages-translations-available": "ethereum.org è disponibile nelle seguenti lingue",
  "page-languages-want-more-header": "Vuoi vedere ethereum.org in un'altra lingua?",
  "page-languages-want-more-link": "Programma di traduzione",
  "page-languages-want-more-paragraph": "i traduttori di ethereum.org stanno sempre traducendo pagine in quante più lingue possibili. Per vedere su cosa stanno lavorando in questo momento o iscriversi per unirsi a loro, leggi sul nostro",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Garanzia per le criptovalute",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Con Ethereum puoi prendere in prestito direttamente da altri utenti senza scambiare i tuoi ETH. Questo può rappresentare un vantaggio: alcuni lo fanno per accumulare più ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Ma poiché il prezzo dell'ETH è instabile, serviranno garanzie extra. Significa che se vuoi prendere in prestito 100 stablecoin avrai probabilmente bisogno di almeno $150 in ETH. Questo sistema protegge il sistema e i prestatori.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Maggiori informazioni su stablecoin supportati da criptovalute",
  "page-stablecoins-accordion-borrow-pill": "Avanzate",
  "page-stablecoins-accordion-borrow-places-intro": "Queste dapp ti permettono di prendere in prestito stablecoin usando criptovalute come garanzia. Alcuni accettano altri token e anche ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Dove prendere in prestito stablecoin",
  "page-stablecoins-accordion-borrow-requirement-1": "Un portafoglio Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Servirà un portafoglio per usare una dapp",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Serviranno ETH come garanzia e/o per le commissioni sulle transazioni",
  "page-stablecoins-accordion-borrow-requirements-description": "Per prendere in prestito stablecoin servirà la dapp giusta. Avrai anche bisogno di un portafoglio e di alcuni ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Se usi ETH come garanzia e il relativo valore cala, la tua garanzia non coprirà gli stablecoin che hai generato. Questo causerà la liquidazione dei tuoi ETH e potresti subire una penale. Quindi se vuoi prendere in prestito stablecoin devi tenere d'occhio il prezzo di ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Ultimo prezzo di ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Rischi",
  "page-stablecoins-accordion-borrow-text-preview": "Puoi prendere in prestito alcuni stablecoin usando criptovalute come garanzia, che dovrai ripagare.",
  "page-stablecoins-accordion-borrow-title": "Prendi in prestito",
  "page-stablecoins-accordion-buy-exchanges-title": "Borse popolari",
  "page-stablecoins-accordion-buy-requirement-1": "Borse e portafogli di criptovalute",
  "page-stablecoins-accordion-buy-requirement-1-description": "Controlla quali servizi puoi usare in base al luogo in cui vivi",
  "page-stablecoins-accordion-buy-requirements-description": "Un account con una borsa o un portafoglio da cui puoi acquistare direttamente criptovalute. Potresti già averne usato uno per ottenere degli ETH. Controlla quali servizi puoi utilizzare nella località in cui vivi.",
  "page-stablecoins-accordion-buy-text-preview": "Molte borse e portafogli ti permettono di acquistare direttamente stablecoin. Potrebbero essere applicate restrizioni geografiche.",
  "page-stablecoins-accordion-buy-title": "Acquista",
  "page-stablecoins-accordion-buy-warning": "Le borse centralizzate potrebbero quotare solo stablecoin ancorati al valore di valute nazionali come USDC, Tether o altre. Potresti non essere in grado di acquistarli direttamente, ma dovresti essere in grado di scambiarli a fronte di ETH o altre criptovalute che puoi acquistare sulla piattaforma.",
  "page-stablecoins-accordion-earn-project-1-description": "Soprattutto lavoro tecnico per il movimento del software open source.",
  "page-stablecoins-accordion-earn-project-2-description": "Tecnologia, contenuti e altri lavori per la community MakerDao (il team che ha creato Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Se davvero sai il fatto tuo, trova bug per guadagnare Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Ricompense Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Premi riguardo i bug Eth2",
  "page-stablecoins-accordion-earn-project-community": "Community di MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Sono piattaforme che ti pagano in stablecoin per il tuo lavoro.",
  "page-stablecoins-accordion-earn-projects-title": "Dove guadagnare stablecoin",
  "page-stablecoins-accordion-earn-requirement-1": "Un portafoglio Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Ti servirà un portafoglio per ricevere gli stablecoin che guadagni",
  "page-stablecoins-accordion-earn-requirements-description": "Gli stablecoin sono un ottimo metodo di pagamento per lavori o servizi, perché il loro valore è stabile. Ma ti servirà un portafoglio per ricevere i pagamenti.",
  "page-stablecoins-accordion-earn-text-preview": "Puoi guadagnare stablecoin lavorando su progetti all'interno dell'ecosistema Ethereum.",
  "page-stablecoins-accordion-earn-title": "Guadagna",
  "page-stablecoins-accordion-less": "Meno",
  "page-stablecoins-accordion-more": "Altro",
  "page-stablecoins-accordion-requirements": "Cosa ti servirà",
  "page-stablecoins-accordion-swap-dapp-intro": "Se già possiedi ETH e un portafoglio, puoi usare queste dapp per scambiare stablecoin.",
  "page-stablecoins-accordion-swap-dapp-link": "Scopri di più sulle borse decentralizzate",
  "page-stablecoins-accordion-swap-dapp-title": "Dapp per scambiare token",
  "page-stablecoins-accordion-swap-editors-tip": "Scelte degli editor",
  "page-stablecoins-accordion-swap-editors-tip-button": "Trova portafogli",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Ottieni un portafoglio che ti permetta di acquistare ETH e scambiarli con token, inclusi gli stablecoin, direttamente.",
  "page-stablecoins-accordion-swap-pill": "Raccomandato",
  "page-stablecoins-accordion-swap-requirement-1": "Un portafoglio Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Ti servirà un portafoglio per autorizzare lo scambio e depositare le tue monete",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Per pagare lo scambio",
  "page-stablecoins-accordion-swap-text-preview": "Puoi trovare la maggior parte degli stablecoin sulle borse decentralizzate. In questo modo, puoi scambiare tutti i tipi di token che hai con lo stablecoin che preferisci.",
  "page-stablecoins-accordion-swap-title": "Scambia",
  "page-stablecoins-algorithmic": "Algoritmico",
  "page-stablecoins-algorithmic-con-1": "Devi fidarti dell'algoritmo (o essere in grado di leggerlo).",
  "page-stablecoins-algorithmic-con-2": "Il saldo delle tue monete cambierà in base alla quantità totale in circolazione.",
  "page-stablecoins-algorithmic-description": "Questi stablecoin non sono supportati da nessun'altra risorsa. Un algoritmo venderà i token se il prezzo dovesse scendere sotto il valore desiderato o fornirà token se il valore dovesse salire oltre l'importo desiderato. Dato che il numero di questi token in circolazione cambia regolarmente, il numero dei token che possiedi cambierà, ma rispecchierà sempre la tua quota.",
  "page-stablecoins-algorithmic-pro-1": "Non servono garanzie.",
  "page-stablecoins-algorithmic-pro-2": "Controllato da un algoritmo pubblico.",
  "page-stablecoins-bank-apy": "0,05%",
  "page-stablecoins-bank-apy-source": "Tasso di interesse medio riconosciuto dalle banche ai titolari di conti correnti base negli USA.",
  "page-stablecoins-bank-apy-source-link": "Fonte",
  "page-stablecoins-bitcoin-pizza": "La famigerata Pizza Bitcoin",
  "page-stablecoins-bitcoin-pizza-body": "Nel 2010, qualcuno ha comprato 2 pizze pagandole 10.000 bitcoin. A quei tempi il valore era pari a circa 41 dollari. Ad oggi, equivarrebbero a milioni di dollari. Ci sono altri esempi di transazioni non proprio vantaggiose nella storia di Ethereum. Gli stablecoin risolvono questo problema, così puoi gustarti una buona pizza senza perdere ETH.",
  "page-stablecoins-coin-price-change": "Variazione del prezzo della moneta (ultimi 30 giorni)",
  "page-stablecoins-crypto-backed": "Basati su criptovalute",
  "page-stablecoins-crypto-backed-con-1": "Meno stabili rispetto agli stablecoin ancorati al valore di valute nazionali.",
  "page-stablecoins-crypto-backed-con-2": "Devi tenere d'occhio il valore della garanzia della criptovaluta.",
  "page-stablecoins-crypto-backed-description": "Questi stablecoin sono legati ad altre criptovalute, come ETH. Il loro prezzo dipende dal valore della risorsa sottostante (o garanzia), che può essere volatile. Siccome il valore di ETH può fluttuare, per questi stablecoin servono garanzie extra, per essere sicuri che il prezzo rimanga il più stabile possibile. Si potrebbe dire che il valore di 1$ di uno stablecoin basato su criptovaluta ha una risorsa criptovaluta sottostante del valore di almeno 2$. Quindi, se il prezzo di ETH dovesse scendere, dovranno essere utilizzati più ETH per sostenere il prezzo dello stablecoin; in caso contrario, lo stablecoin perderebbe il proprio valore.",
  "page-stablecoins-crypto-backed-pro-1": "Trasparenti e completamente decentralizzati.",
  "page-stablecoins-crypto-backed-pro-2": "Trasformabili rapidamente in altre criptovalute.",
  "page-stablecoins-crypto-backed-pro-3": "Nessun custode esterno; tutte le risorse sono controllate da account Ethereum.",
  "page-stablecoins-dai-banner-body": "Il Dai è probabilmente lo stablecoin decentralizzato più famoso. Il suo valore è circa di un dollaro ed è ampiamente accettato nelle dapp.",
  "page-stablecoins-dai-banner-learn-button": "Scopri di più sul Dai",
  "page-stablecoins-dai-banner-swap-button": "Scambia ETH per Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Logo Dai",
  "page-stablecoins-editors-choice": "Scelti da noi",
  "page-stablecoins-editors-choice-intro": "Sono probabilmente gli esempi più noti al momento di stablecoin e delle monete che abbiamo trovato utili per l'uso delle dapp.",
  "page-stablecoins-explore-dapps": "Esplora le dapp",
  "page-stablecoins-fiat-backed": "Ancorato al valore delle valute nazionali",
  "page-stablecoins-fiat-backed-con-1": "Centralizzato: qualcuno deve rilasciare i token.",
  "page-stablecoins-fiat-backed-con-2": "Richiede un controllo per assicurare che l'azienda abbia sufficienti riserve.",
  "page-stablecoins-fiat-backed-description": "Fondamentalmente un \"pagherò\" per una valuta tradizionale (di solito dollari). Usi la tua valuta legale per acquistare un tipo di stablecoin che poi potrai incassare successivamente e riscattare con la tua valuta originale.",
  "page-stablecoins-fiat-backed-pro-1": "Sicuro contro la volatilità tipica delle criptovalute.",
  "page-stablecoins-fiat-backed-pro-2": "Le variazioni di prezzo sono minime.",
  "page-stablecoins-find-stablecoin": "Trova uno stablecoin",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Come ottenere stablecoin",
  "page-stablecoins-find-stablecoin-intro": "Sono disponibili centinaia di stablecoin. Qui te ne mostriamo alcuni per aiutarti a muovere i primi passi. Se non conosci ancora il mondo Ethereum, ti raccomandiamo di fare prima qualche ricerca.",
  "page-stablecoins-find-stablecoin-types-link": "Diversi tipi di stablecoin",
  "page-stablecoins-get-stablecoins": "Come ottenere stablecoin",
  "page-stablecoins-hero-alt": "I tre maggiori stablecoin per capitalizzazione: Dai, USDC e Tether.",
  "page-stablecoins-hero-button": "Ottieni stablecoin",
  "page-stablecoins-hero-header": "Moneta digitale per l'utilizzo quotidiano",
  "page-stablecoins-hero-subtitle": "Gli stablecoin sono token Ethereum progettati per mantenere un valore fisso, anche quando il prezzo di ETH cambia.",
  "page-stablecoins-interest-earning-dapps": "Dapp che permettono di maturare interessi",
  "page-stablecoins-meta-description": "Introduzione agli stablecoin Ethereum: cosa sono, come ottenerli e perché sono importanti.",
  "page-stablecoins-precious-metals": "Metalli preziosi",
  "page-stablecoins-precious-metals-con-1": "Centralizzati: qualcuno deve emettere i token.",
  "page-stablecoins-precious-metals-con-2": "Devi fidarti dell'emittente dei token e delle riserve di metallo prezioso.",
  "page-stablecoins-precious-metals-description": "Come le monete ancorate al valore di valute nazionali, questi stablecoin usano risorse come l'oro per mantenere stabile il proprio valore.",
  "page-stablecoins-precious-metals-pro-1": "Protetti dalla volatilità delle criptovalute.",
  "page-stablecoins-prices": "Prezzi degli stablecoin",
  "page-stablecoins-prices-definition": "Gli stablecoin sono criptovalute non soggette a volatilità. Possiedono molte delle peculiarità di ETH ma il loro valore è stabile, similmente alle valute tradizionali. Quindi hai accesso a moneta stabile che puoi utilizzare su Ethereum. ",
  "page-stablecoins-prices-definition-how": "Come viene garantita la stabilità degli stablecoin",
  "page-stablecoins-research-warning": "Ethereum è una nuova tecnologia e la maggior parte delle applicazioni è nuova. Assicurati di essere consapevole dei rischi e deposita solo somme che puoi permetterti di perdere.",
  "page-stablecoins-research-warning-title": "Fai sempre le tue ricerche di verifica",
  "page-stablecoins-save-stablecoins": "Risparmio con gli stablecoin",
  "page-stablecoins-save-stablecoins-body": "Gli stablecoin hanno solitamente un tasso di interesse più alto della media perché c'è molta domanda per prenderli in prestito. Queste sono dapp che permettono di ottenere interessi sugli stablecoin in tempo reale depositandoli in un pool di prestiti. Proprio come nel mondo bancario, fornisci token a chi li richiede in prestito ma potrai comunque prelevarli, insieme agli interessi, in qualsiasi momento.",
  "page-stablecoins-saving": "Metti a frutto le tue riserve di stablecoin e guadagna interessi. Come sempre nel mondo delle criptovalute, il rendimento annuo percentuale (APY, Annual Percentage Yields) può cambiare giornalmente a seconda della domanda e dell'offerta in tempo reale.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Dai un occhio alle dapp Ethereum. Gli stablecoin sono spesso più utili per le transazioni quotidiane.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Figura di un cane.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Usa i tuoi stablecoin",
  "page-stablecoins-stablecoins-dapp-description-1": "Piazze di scambio per molti stablecoin, tra cui Dai, USDC, TUSD, USDT e altri. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Presta stablecoin e guadagna interessi e $COMP, il token proprietario di Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Piattaforma di trading dove puoi guadagnare interessi sui tuoi Dai e USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "App progettata per risparmiare Dai.",
  "page-stablecoins-stablecoins-feature-1": "Gli stablecoin sono globali e possono essere inviati su internet. Una volta che hai un account Ethereum, sono semplici da ricevere o da inviare.",
  "page-stablecoins-stablecoins-feature-2": "La domanda di stablecoin è alta, quindi puoi guadagnare interessi prestando i tuoi. Assicurati di essere a conoscenza dei rischi prima di procedere al prestito.",
  "page-stablecoins-stablecoins-feature-3": "Gli stablecoin sono scambiabili con ETH e altri token Ethereum. Molte dapp si basano su stablecoin.",
  "page-stablecoins-stablecoins-feature-4": "Gli stablecoin sono protetti dalla crittografia. Nessuno può fare transazioni per tuo conto.",
  "page-stablecoins-stablecoins-meta-description": "Introduzione agli stablecoin Ethereum: cosa sono, come ottenerli e perché sono importanti.",
  "page-stablecoins-stablecoins-table-header-column-1": "Valuta",
  "page-stablecoins-stablecoins-table-header-column-2": "Capitalizzazione di mercato",
  "page-stablecoins-stablecoins-table-header-column-3": "Tipo di garanzia",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Criptovaluta",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Valuta a corso legale",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Metalli preziosi",
  "page-stablecoins-table-error": "Impossibile caricare stablecoin. Prova ad aggiornare la pagina.",
  "page-stablecoins-table-loading": "Caricamento dati stablecoin in corso...",
  "page-stablecoins-title": "Stablecoin",
  "page-stablecoins-top-coins": "Principali stablecoin per capitalizzazione di mercato",
  "page-stablecoins-top-coins-intro": "La capitalizzazione di mercato è",
  "page-stablecoins-top-coins-intro-code": "il numero totale di token esistenti moltiplicato per il valore del token. Questa lista è dinamica e i progetti elencati qui non sono necessariamente approvati dal team di ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Come funzionano: tipi di stablecoin",
  "page-stablecoins-usdc-banner-body": "USDc è probabilmente il più famoso tra gli stablecoin ancorati al valore di valute nazionali. Vale circa un dollaro ed è basato su Circle e Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Scopri di più su USDC",
  "page-stablecoins-usdc-banner-swap-button": "Scambia ETH per USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Logo USDC",
  "page-stablecoins-why-stablecoins": "Perché stablecoin?",
  "page-stablecoins-how-they-work-button": "Come funzionano",
  "page-stablecoins-why-stablecoins-body": "ETH, come Bitcoin, ha un prezzo volatile perché è una nuova tecnologia. Quindi potrebbe essere consigliabile non spenderlo regolarmente. Gli stablecoin rispecchiano il valore delle valute tradizionali per dare accesso a una moneta stabile che puoi usare su Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Se vuoi aggiungere un portafoglio,",
  "page-find-wallet-alpha-logo-alt": "Logo AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Logo Ambo",
  "page-find-wallet-argent-logo-alt": "Logo Argent",
  "page-find-wallet-buy-card": "Acquista criptovalute con una carta",
  "page-find-wallet-buy-card-desc": "Acquista ETH direttamente dal tuo portafoglio con una carta bancaria. Possono essere presenti restrizioni geografiche.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Dai un'occhiata alle dapp",
  "page-find-wallet-clear": "Cancella filtri",
  "page-find-wallet-coinbase-logo-alt": "Logo Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Quindi, scegli il tuo portafoglio in base alle caratteristiche che desideri.",
  "page-find-wallet-description": "I portafogli hanno moltissime funzionalità opzionali che potrebbero piacerti.",
  "page-find-wallet-description-alpha": "Portafoglio Ethereum completamente open source che sfrutta Secure Enclave su dispositivo mobile, offre supporto completo della rete di test e abbraccia lo standard TokenScript.",
  "page-find-wallet-description-ambo": "Passa direttamente agli investimenti e ottieni il tuo primo investimento dopo pochi minuti dal download dell'app",
  "page-find-wallet-description-argent": "Un tocco per guadagnare interessi e investire; prendi in prestito, archivia e invia. La decisione è tua.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "App sicura per archiviare le criptovalute",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma è il tuo portale per DeFi. Addio seed phrase, addio estensioni Chrome.",
  "page-find-wallet-description-enjin": "Impenetrabile, ricco di funzionalità e conveniente. Pensato per trader, gamer e sviluppatori",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "La piattaforma più affidabile per conservare risorse digitali su Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken è un portafoglio digitale semplice e sicuro a cui si sono affidati in milioni",
  "page-find-wallet-description-ledger": "Mantieni i tuoi asset al sicuro con gli standard di sicurezza più elevati",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Inizia a esplorare le applicazioni blockchain in pochi secondi. Scelte da più di un milione di utenti in tutto il mondo.",
  "page-find-wallet-description-monolith": "L'unico portafoglio al mondo con custodia da parte dell'utente, collegato a una carta di debito Visa. Disponibile nel Regno unito e nell'Unione Europea, utilizzabile in tutto il mondo.",
  "page-find-wallet-description-multis": "Multis è un account oer criptovalute pensato per il mondo business. Con Multis le aziende possono archiviare con controllo degli accessi, guadagnare interessi sui propri risparmi e ottimizzare pagamenti e contabilità.",
  "page-find-wallet-description-mycrypto": "MyCripto è un interfaccia per gestire tutti i tuoi account. Scambia, invia e compra criptovalute con portafogli come MetaMask, Ledger, Trezor e altri.",
  "page-find-wallet-description-myetherwallet": "Un'interfaccia gratuita, client-side che ti aiuta a interagire con la blockchain Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Portafoglio senza custodia, di proprietà della community, con la propria rete di pagamenti L2.",
  "page-find-wallet-description-portis": "Il portafoglio senza custodia della blockchain non-custodial che rende le app semplici per chiunque",
  "page-find-wallet-description-rainbow": "Una casa migliore per le tue risorse Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "App di messaggistica sicura, portafoglio per criptovalute e browser Web3 realizzato con tecnologia allo stato dell'arte",
  "page-find-wallet-description-tokenpocket": "TokenPocket：un portafoglio sicuro, comodo e leader a livello mondiale per valute digitali e un portale per le DApp, con supporto per più catene.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Accesso con un clic per il Web 3.0",
  "page-find-wallet-description-trezor": "Il primo portafoglio hardware. L'originale",
  "page-find-wallet-description-trust": "Trust Wallet è un portafoglio decentralizzato per criptovaluta, multi moneta. Acquista criptovalute, esplora le dapp, scambia risorse e fai molto altro, mantenendo il controllo sulle tue chiavi.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo è il primo portafoglio per criptovalute senza chiavikeyless. Con ZenGo non ci sono chiavi private, password o seed phrase da gestire o a rischio smarrimento. Acquista, scambia, guadagna e deposita Ethereum con una semplicità e sicurezza senza precedenti",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Logo Dharma",
  "page-find-wallet-enjin-logo-alt": "Logo Enjin",
  "page-find-wallet-Ethereum-wallets": "Portafogli Ethereum",
  "page-find-wallet-explore-dapps": "Esplora le dapp",
  "page-find-wallet-explore-dapps-desc": "Questi portafogli sono pensati per aiutarti a connetterti alle dapp Ethereum.",
  "page-find-wallet-feature-h2": "Scegli le funzionalità dei portafogli che ti interessano",
  "page-find-wallet-fi-tools": "Accesso a strumenti finanziari",
  "page-find-wallet-fi-tools-desc": "Prendi in prestito, presta e guadagna interessi direttamente dal tuo portafoglio.",
  "page-find-wallet-following-features": "con le seguenti caratteristiche:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Logo Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Trova l'immagine hero del portafoglio",
  "page-find-wallet-imtoken-logo-alt": "logo imToken",
  "page-find-wallet-last-updated": "Ultimo aggiornamento",
  "page-find-wallet-ledger-logo-alt": "Logo Ledger",
  "page-find-wallet-limits": "Protezione tramite limiti",
  "page-find-wallet-limits-desc": "Tieni al sicuro le tue risorse impostando limiti che l'account venga svuotato.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "Regole e linee guida per la quotazione di portafogli sul sito",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Trova e confronta portafogli Ethereum in base alle caratteristiche che cerchi.",
  "page-find-wallet-meta-title": "Trova un portafoglio Ethereum",
  "page-find-wallet-metamask-logo-alt": "Logo MetaMask",
  "page-find-wallet-monolith-logo-alt": "Logo Monolith",
  "page-find-wallet-multis-logo-alt": "Logo Multis",
  "page-find-wallet-multisig": "Account multifirma",
  "page-find-wallet-multisig-desc": "Per aumentare la sicurezza, i portafogli multifirma richiedono che più account autorizzino determinate transazioni.",
  "page-find-wallet-mycrypto-logo-alt": "Logo MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Logo MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Non conosci i portafogli? Ecco una panoramica per iniziare.",
  "page-find-wallet-new-to-wallets-link": "Portafogli di Ethereum",
  "page-find-wallet-not-all-features": "Nessun portafoglio ha tutte queste caratteristiche",
  "page-find-wallet-not-endorsements": "I portafogli elencati in questa pagina non sono approvazioni ufficiali e sono indicati solo a scopo informativo. Le loro descrizioni sono state fornite dalle aziende che li hanno creati. Aggiungiamo questi prodotti a questa pagina basandoci su criteri delle",
  "page-find-wallet-overwhelmed": "portafogli Ethereum. Sono troppi? Prova a filtrare per caratteristiche.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Logo Pillar",
  "page-find-wallet-portis-logo-alt": "Logo Portis",
  "page-find-wallet-rainbow-logo-alt": "Logo Rainbow",
  "page-find-wallet-raise-an-issue": "apri una segnalazione su GitHub",
  "page-find-wallet-search-btn": "Cerca le caratteristiche indicate",
  "page-find-wallet-showing": "Vengono mostrati ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Logo Status",
  "page-find-wallet-swaps": "Scambio di token decentralizzato",
  "page-find-wallet-swaps-desc": "Scambia ETH e altri token direttamente dal tuo portafoglio.",
  "page-find-wallet-title": "Trova un portafoglio",
  "page-find-wallet-tokenpocket-logo-alt": "Logo TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Logo Torus",
  "page-find-wallet-trezor-logo-alt": "Logo Trezor",
  "page-find-wallet-trust-logo-alt": "Logo Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Prova rimuovere una o due funzionalità",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Ora che hai un portafoglio, dai un'occhiata ad alcune applicazioni Ethereum (dapps). Ci sono dapp di finanza, social media, videogiochi e molte altre categorie.",
  "page-find-wallet-use-your-wallet": "Usa il tuo portafoglio",
  "page-find-wallet-voluem-desc": "Se vuoi tenere molti ETH, scegli un portafoglio che ti consenta di acquistare più di $ 2000 ETH alla volta.",
  "page-find-wallet-volume": "Acquisti di volumi elevati",
  "page-find-wallet-we-found": "Abbiamo trovato",
  "page-find-wallet-withdraw": "Prelievo verso la banca",
  "page-find-wallet-withdraw-desc": "Puoi incassare i tuoi ETH direttamente sul tuo conto bancario, senza la necessità di passare attraverso un cambio valuta.",
  "page-find-wallet-yet": "ancora",
  "page-find-wallet-zengo-logo-alt": "Logo ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Punta ETH",
  "page-wallets-accounts-addresses": "Portafogli, account e indirizzi",
  "page-wallets-accounts-addresses-desc": "Vale la pena capire le differenze tra alcuni termini chiave.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Figura di un robot con una cassaforte come corpo che rappresenta un portafoglio Ethereum",
  "page-wallets-ethereum-account": "account Ethereum",
  "page-wallets-blog": "Blog di Coinbase",
  "page-wallets-bookmarking": "Salva il tuo portafoglio tra i preferiti",
  "page-wallets-bookmarking-desc": "Se usi un portafoglio web, mettere il sito tra i preferiti ti aiuta a proteggerti da truffe e phishing.",
  "page-wallets-cd": "Portafogli hardware fisici che ti permettono di tenere le tue criptovalute offline. Molto sicuri",
  "page-wallets-converted": "Usi criptovalute?",
  "page-wallets-converted-desc": "Se cerchi un modo per depositare importi considerevoli, raccomandiamo un portafoglio hardware perché sono quelli più sicuri. Oppure un portafoglio con allerta in caso di frodi e limiti di prelievo.",
  "page-wallets-curious": "Curiosità per il mondo delle criptovalute?",
  "page-wallets-curious-desc": "Se non conosci ancora il mondo delle criptovalute e vuoi solo provare, raccomandiamo qualcosa che ti dia la possibilità di esplorare le applicazioni di Ethereum o di acquistare i tuoi primi ETH direttamente dal portafoglio.",
  "page-wallets-desc-2": "Serve un portafoglio per inviare fondi e gestire i tuoi ETH.",
  "page-wallets-desc-2-link": "Scopri di più su ETH",
  "page-wallets-desc-3": "Il tuo portafoglio è solo uno strumento per gestire il tuo account Ethereum. Questo significa che puoi cambiare provider di portafoglio in qualsiasi momento. Molti portafogli permettono anche di gestire diversi account Ethereum da una sola applicazione.",
  "page-wallets-desc-4": "Questo perché non hanno custodia dei tuoi fondi, quella spetta a te. I portafogli sono solo uno strumento per gestire quello che è tuo.",
  "page-wallets-description": "I portafogli Ethereum sono applicazioni che ti permettono di interagire con il tuo account Ethereum. Considerali una sorta di app di internet banking, ma senza la banca. Il tuo portafoglio ti permette di controllare il saldo, inviare transazioni e connetterti ad applicazioni.",
  "page-wallets-desktop": "Applicazioni desktop, se preferisci gestire i tuoi fondi via macOS, Windows o Linux",
  "page-wallets-ethereum-wallet": "Un portafoglio",
  "page-wallets-explore": "Esplora Ethereum",
  "page-wallets-features-desc": "Possiamo aiutarti a scegliere il tuo portafoglio in base alle caratteristiche che ti interessano.",
  "page-wallets-features-title": "Preferisci scegliere basandoti sulle funzionalità?",
  "page-wallets-find-wallet-btn": "Trova un portafoglio",
  "page-wallets-find-wallet-link": "Trova un portafoglio",
  "page-wallets-get-some": "Ottieni ETH",
  "page-wallets-get-some-alt": "Figura di una mano che crea il logo ETH con mattoncini Lego",
  "page-wallets-get-some-btn": "Ottieni ETH",
  "page-wallets-get-some-desc": "ETH è la criptovaluta nativa di Ethereum. Ti serviranno alcuni ETH nel tuo portafoglio per utilizzare le applicazioni di Ethereum.",
  "page-wallets-get-wallet": "Ottieni un portafoglio",
  "page-wallets-get-wallet-desc": "Ci sono molti portafogli tra cui poter scegliere. Noi vogliamo aiutarti a scegliere il migliore per te.",
  "page-wallets-get-wallet-desc-2": "Ricordati: la decisione che prendi non sarà per sempre. Il tuo account Ethereum non è legato al provider del portafoglio.",
  "page-wallets-how-to-store": "Come conservare risorse digitali su Ethereum",
  "page-wallets-keys-to-safety": "I segreti per tenere al sicuro le tue criptovalute",
  "page-wallets-manage-funds": "Un'app per gestire i tuoi fondi",
  "page-wallets-manage-funds-desc": "Il tuo portafoglio mostra il tuo saldo, la cronologia delle transazioni e ti offre la possibilità di inviare o ricevere fondi. Alcuni portafogli possono offrire funzioni aggiuntive.",
  "page-wallets-meta-description": "Cosa devi sapere per utilizzare portafogli Ethereum.",
  "page-wallets-meta-title": "Portafogli Ethereum",
  "page-wallets-mobile": "Applicazioni mobili che rendono i tuoi fondi accessibili ovunque",
  "page-wallets-more-on-dapps-btn": "Scopri di più sulle dapp",
  "page-wallets-most-wallets": "La maggior parte dei portafogli ti permette di generare un account Ethereum, quindi non ti serve averne uno prima di scaricare un portafoglio.",
  "page-wallets-protecting-yourself": "Proteggi te e i tuoi fondi",
  "page-wallets-seed-phrase": "Prendi nota della tua seed phrase",
  "page-wallets-seed-phrase-desc": "I portafogli spesso ti forniscono una seed phrase che devi scrivere e conservare in un luogo sicuro. Sarà l'unico modo per recuperare il tuo portafoglio.",
  "page-wallets-seed-phrase-example": "Ecco un esempio:",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "Non salvarla su un computer. Scrivila a penna da qualche parte e tienila al sicuro.",
  "page-wallets-slogan": "La chiave per il tuo futuro digitale",
  "page-wallets-stay-safe": "Come rimanere al sicuro",
  "page-wallets-stay-safe-desc": "I portafogli richiedono un piccolo sforzo mentale. La libertà finanziaria e la possibilità di accedere ai propri fondi e utilizzarli ovunque è legata a un po' di responsabilità. Non esiste il supporto clienti per le criptovalute.",
  "page-wallets-subtitle": "I portafogli ti danno accesso ai tuoi fondi e alle applicazioni Ethereum. Solo tu dovresti avere accesso al tuo portafoglio.",
  "page-wallets-take-responsibility": "Prenditi la responsabilità dei tuoi fondi",
  "page-wallets-take-responsibility-desc": "Le borse centralizzate collegano il tuo portafoglio a uno username e una password che puoi recuperare in maniera tradizionale. Ricordati solo che ti stai fidando della borsa affidando i tuoi fondi in custodia. Se l'azienda dovesse venire attaccata o fallire, i tuoi fondi potrebbero essere a rischio.",
  "page-wallets-tips": "Altri consigli su come mantenere la sicurezza",
  "page-wallets-tips-community": "Dalla community",
  "page-wallets-title": "Portafogli Ethereum",
  "page-wallets-triple-check": "Fai un triplo controllo su tutto",
  "page-wallets-triple-check-desc": "Ricordati che le transazioni non possono essere stornate e che non è semplice recuperare un portafoglio, quindi fai attenzione.",
  "page-wallets-try-dapps": "Prova alcune dapp",
  "page-wallets-try-dapps-alt": "Figura dei membri della community Ethereum che lavorano insieme",
  "page-wallets-try-dapps-desc": "Le dapp sono applicazioni costruite su Ethereum. Sono più economiche, eque e attente ai tuoi dati rispetto alla maggior parte delle applicazioni tradizionali.",
  "page-wallets-types": "Tipi di portafogli",
  "page-wallets-web-browser": "I portafogli Web ti permettono di interagire con il tuo account attraverso un browser Web",
  "page-wallets-whats-a-wallet": "Cos'è un portafoglio Ethereum?",
  "page-wallets-your-ethereum-account": "Il tuo account Ethereum",
  "page-wallets-your-ethereum-account-desc": "Il tuo portafoglio è la tua finestra sull'account Ethereum: il saldo, la cronologia delle transazioni e altro. Ma potrai cambiare fornitore del portafoglio ogni volta che vorrai.",
  "page-wallets-your-login": "Il tuo login per le applicazioni Ethereum",
  "page-wallets-your-login-desc": "Il tuo portafoglio ti permette di connetterti a ogni app decentralizzata usando il tuo account Ethereum. È come un login che puoi utilizzare per molte dapp.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum è una tecnologia che ti permette di inviare criptovalute a chiunque, versando solo una piccola commissione. Inoltre sta alla base di applicazioni che chiunque può utilizzare e che nessuno può mandare in tilt.",
  "page-what-is-ethereum-101-desc-2": "Ethereum si basa sull'innovazione di Bitcoin, ma con grandi differenze.",
  "page-what-is-ethereum-101-desc-3": "Entrambe ti permettono di utilizzare moneta digitale senza ricorrere a intermediari o banche. Ma Ethereum è programmabile, quindi la puoi usare per molti tipi diversi di risorse digitali, anche per Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "Questo significa anche che Ethereum non è solo pagamenti. È una piazza di servizi finanziari, giochi e app che non possono rubarti i dati e o censurarti.",
  "page-what-is-ethereum-101-italic": "la blockchain mondiale programmabile.",
  "page-what-is-ethereum-101-strong": "È ",
  "page-what-is-ethereum-accessibility": "Ethereum è aperta a chiunque.",
  "page-what-is-ethereum-adventure": "Scegli la tua avventura!",
  "page-what-is-ethereum-alt-img-bazaar": "Figura di una persona che che sbircia in un bazar, che rappresenta Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Figura dei membri della community di Ethereum che lavorano insieme",
  "page-what-is-ethereum-alt-img-lego": "Figura di una mano che crea il logo ETH formato da mattoncini di Lego",
  "page-what-is-ethereum-alt-img-social": "Figura di personaggi in uno spazio sociale dedicato a Ethereum con un grande logo ETH",
  "page-what-is-ethereum-banking-card": "Attività bancarie per tutti",
  "page-what-is-ethereum-banking-card-desc": "Non tutti hanno accesso ai servizi finanziari. Tutto ciò che ti serve per accedere a Ethereum e al suo sistema di prestiti e prodotti di risparmio è una connessione internet.",
  "page-what-is-ethereum-build": "Fai qualcosa con Ethereum",
  "page-what-is-ethereum-build-desc": "Se vuoi provare a costruire con Ethereum, leggere i nostri documenti, provare alcuni tutorial o dare un'occhiata agli strumenti che ti servono per iniziare.",
  "page-what-is-ethereum-censorless-card": "Anti-censura",
  "page-what-is-ethereum-censorless-card-desc": "Nessun governo o azienda ha il controllo su Ethereum. Questa decentralizzazione rende praticamente impossibile per chiunque impedire di ricevere pagamenti o di utilizzare i servizi basati su Ethereum.",
  "page-what-is-ethereum-comm-desc": "La nostra community include persone con ogni tipo di background, inclusi artisti, cripto-anarchici, aziende Fortune 500 e ora anche te. Scopri come poter partecipare fin da subito.",
  "page-what-is-ethereum-commerce-card": "Garanzie commerciali",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum crea una maggiore parità di condizioni. I clienti hanno la garanzia che i fondi verranno trasferiti solo quando verrà fornito ciò che è stato pattuito. Non ti serve essere un'azienda potente per fare affari.",
  "page-what-is-ethereum-community": "La community Ethereum",
  "page-what-is-ethereum-compatibility-card": "Compatibilità in ottica di successo",
  "page-what-is-ethereum-compatibility-card-desc": "Vengono continuamente realizzati prodotti ed esperienze migliori perché i prodotti Ethereum sono compatibili per natura. Le aziende possono basarsi sul successo reciproco.",
  "page-what-is-ethereum-dapps-desc": "Prodotti e servizi che girano su Ethereum. Ci sono dapp per la finanza, il lavoro, i social media, i videogiochi e altro. Scopri le app per il nostro futuro digitale.",
  "page-what-is-ethereum-dapps-img-alt": "Figura di un cane che usa un'applicazione Ethereum su un computer",
  "page-what-is-ethereum-dapps-title": "Dapp Ethereum",
  "page-what-is-ethereum-desc": "La base per il nostro futuro digitale",
  "page-what-is-ethereum-explore": "Esplora Ethereum",
  "page-what-is-ethereum-get-started": "Il modo migliore per saperne di più è scaricare un portafoglio, ottenere un po' di ETH e provare una dapp Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum offre libero accesso alla moneta digitale e servizi data-friendly aperti a tutti, indipendentemente da background e località geografica. È una tecnologia realizzata da una community, che sta alla base della criptovaluta ether (ETH) e di migliaia di applicazioni che puoi usare già da ora.",
  "page-what-is-ethereum-internet-card": "Un internet più privato",
  "page-what-is-ethereum-internet-card-desc": "Non devi fornire tutte le tue informazioni personali per usare un'app Ethereum. Ethereum crea un'economia basata sul valore, non sulla sorveglianza.",
  "page-what-is-ethereum-meet-comm": "Incontra la community",
  "page-what-is-ethereum-meta-description": "Scopri di più su Ethereum, cosa fa e come puoi provarla.",
  "page-what-is-ethereum-meta-title": "Cos'è Ethereum?",
  "page-what-is-ethereum-native-alt": "Il simbolo dell'ether (ETH)",
  "page-what-is-ethereum-native-crypto": "La criptovaluta nativa di Ethereum e l'equivalente di Bitcoin. Puoi usare ETH nelle applicazioni Ethereum o per inviare valore ad amici e parenti.",
  "page-what-is-ethereum-native-img-alt": "Figura di un robot che ha una cassaforte come corpo, usato per rappresentare i portafogli Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Una rete peer-to-peer",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum ti permette di spostare denaro, o stringere accordi, direttamente con altri. Non è necessario ricorrere a intermediari.",
  "page-what-is-ethereum-singlecard-desc": "Se ti interessa la blockchain e il lato tecnico di Ethereum, abbiamo quello che fa per te.",
  "page-what-is-ethereum-singlecard-link": "Come funziona Ethereum",
  "page-what-is-ethereum-singlecard-title": "Come funziona Ethereum",
  "page-what-is-ethereum-start-building-btn": "Inizia a sviluppare",
  "page-what-is-ethereum-title": "Cos'è Ethereum?",
  "page-what-is-ethereum-tools-needed": "Per farne parte, tutto ciò che ti serve è un portafoglio.",
  "page-what-is-ethereum-try": "Prova Ethereum",
  "page-what-is-ethereum-tryit": "Quindi entra nel bazar e prova...",
  "page-what-is-ethereum-wallets": "Wallet",
  "page-what-is-ethereum-wallets-desc": "Come gestire i tuoi ETH e il tuo account Ethereum. Per iniziare ti serve un portafoglio. Ti aiuteremo a sceglierne uno.",
  "page-what-is-ethereum-welcome": "Ti diamo il benvenuto su Ethereum",
  "page-what-is-ethereum-welcome-2": "Speriamo che tu rimanga.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Ora puoi usare lo staking! Se desideri fare staking con i tuoi ETH",
  "banner-staking-2": "conferma l'indirizzo per il contratto di deposito",
  "docking": "Docking",
  "page-eth2-vision-security-desc-9": "ci permette di assegnare casualmente i validatori a shard differenti. Questo rende virtualmente impossibile per i validatori unirsi per attaccare uno shard specifico. Lo sharding non è un sistema di sicurezza altrettanto valido in una blockchain basata su proof of work, perché i miner non possono essere controllati in questo modo dal protocollo.",
  "page-index-sections-developers-desc": "Scopri la tecnologia che supporta Ethereum e le applicazioni per iniziare a sviluppare.",
  "page-index-sections-developers-image-alt": "Figura di una mano che costruisce un'icona Ethereum composta da mattoncini di Lego",
  "page-index-sections-developers-link-text": "Inizia a sviluppare",
  "page-index-sections-developers-title": "Sviluppatori",
  "page-index-sections-enterprise-desc": "Scopri come Ethereum può aprire la strada a nuovi modelli di business, ridurre i costi e preparare la tua azienda per le sfide del futuro.",
  "page-index-sections-enterprise-image-alt": "Figura di un gruppo di persone che lavorano a un progetto Ethereum attorno a un computer portatile",
  "page-index-sections-enterprise-link-text": "Ethereum per le aziende",
  "page-index-sections-enterprise-title": "Aziende",
  "page-index-sections-individuals-desc": "Scopri Ethereum, gli Ether, i portafogli, i token e altro, per iniziare a utilizzare le applicazioni Ethereum.",
  "page-index-sections-individuals-image-alt": "Figura di un cane seduto a un computer",
  "page-index-sections-individuals-link-text": "Primi passi con Ethereum",
  "page-index-sections-individuals-title": "Informazioni Su Ethereum",
  "page-index-subtitle": "Su Ethereum, è possibile scrivere codice per controllare il valore digitale, che funzioni esattamente come programmato e sia accessibile da ogni parte nel mondo.",
  "page-find-wallet-authereum-logo-alt": "Logo Authereum",
  "page-find-wallet-description-authereum": "Nessun download, nessuna seed phrase. Qualsiasi browser in qualsiasi momento, su dispositivo mobile o desktop",
  "page-wallets-accounts-addresses-desc-2": "è un'entità che può inviare transazioni e che ha un saldo.",
  "page-wallets-accounts-has": "Un account Ethereum ha un",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "indirizzo Ethereum",
  "page-wallets-ethereum-addresses-2": ", proprio come la cartella della posta in arrivo. Puoi usarlo per inviare fondi a un account.",
  "page-wallets-ethereum-wallet-2": "è un prodotto che ti permette di gestire il tuo account Ethereum, ad esempio di vedere il tuo saldo, inviare transazioni e altro."
}
