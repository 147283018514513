{
  "1inch-logo": "1-дюймовый логотип",
  "aave-logo": "Логотип Aave",
  "about": "О",
  "about-ethereum-org": "Об ethereum.org",
  "about-us": "О нас",
  "alt-eth-blocks": "Иллюстрация блоков организуется как символ ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Вернуться вверх",
  "banner-page-incomplete": "Эта страница не завершена. Если вы эксперт по теме, пожалуйста, отредактируйте эту страницу и добавьте в нее известную вам информацию.",
  "beacon-chain": "Beacon Chain",
  "binance-logo": "Логотип Binance",
  "bittrex-logo": "Логотип Bittrex",
  "brand-assets": "Активы бренда",
  "bug-bounty": "Программа вознаграждений за найденные ошибки",
  "coinbase-logo": "Логотип Coinbase",
  "coinmama-logo": "Логотип Coinmama",
  "community": "Сообщество",
  "community-menu": "Community Menu",
  "compound-logo": "Логотип Compound",
  "cons": "Минусы",
  "contact": "Контактные данные",
  "content-versions": "Content Versions",
  "contributing": "Содействие",
  "contributors": "Соавторы",
  "contributors-thanks": "Всем, кто внес свой вклад в эту страницу – спасибо!",
  "cookie-policy": "Политика сookie-файлов",
  "copied": "Скопировано",
  "copy": "Копировать",
  "dark-mode": "Темный режим",
  "data-provided-by": "Данные предоставлены",
  "decentralized-applications-dapps": "Децентрализованные приложения (dapps)",
  "deposit-contract": "Контракт на депозит",
  "devcon": "Девкон",
  "developers": "Разработчикам",
  "developers-home": "Домашняя страница для разработчиков",
  "docs": "Документация",
  "documentation": "Документация",
  "dydx-logo": "Логотип Dydx",
  "ecosystem": "Экосистема",
  "edit-page": "Редактировать страницу",
  "ef-blog": "Блог фонда Ethereum",
  "eips": "Предложения по улучшению Ethereum",
  "enterprise": "Компания",
  "enterprise-menu": "Корпоративное меню",
  "esp": "Программа поддержки экосистемы",
  "eth-current-price": "Текущая цена ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Исследователь Beacon Chain Eth2 с открытым исходным кодом",
  "eth2-beaconscan-desc": "Исследователь Beacon Chain Eth2 – Etherscan для Eth2",
  "eth2-become-staker": "Стать стейкером",
  "eth2-become-staker-desc": "Доступны вложения! Если вы хотите вложить свои ETH, чтобы помочь обезопасить сеть, убедитесь, что вы осознаете риски.",
  "eth2-explore": "Исследовать данные",
  "eth2-no-action-needed": "Вам не нужно ничего делать с ETH, которым вы уже владеете. Остерегайтесь мошенников, просящих отправить ETH для обмена.",
  "eth2-run-beacon-chain": "Запустить клиент Beacon",
  "eth2-run-beacon-chain-desc": "Ethereum нужно как можно больше запущенных клиентов. Помогите с этими общественными благами Ethereum!",
  "eth2-service-announcement": "Объявление сервиса Eth2",
  "eth2-what-shipping": "Когда это запустится?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Активы бренда Ethereum",
  "ethereum-community": "Сообщество Ethereum",
  "ethereum-foundation": "Фонд Ethereum",
  "ethereum-foundation-logo": "Логотип фонда Ethereum",
  "ethereum-glossary": "Глоссарий Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Логотип Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Студия Ethereum",
  "ethereum-wallets": "Кошельки Ethereum",
  "ethereum-whitepaper": "Техническая документация об Ethereum",
  "example-projects": "Примеры проектов",
  "find-wallet": "Найти кошелек",
  "foundation": "Фонд",
  "gemini-logo": "Логотип Gemini",
  "get-eth": "Получить ETH",
  "get-involved": "Участвовать",
  "get-started": "Начать",
  "gitcoin-logo": "Логотип Gitcoin",
  "glossary": "Глоссарий",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Руководства и ресурсы",
  "history": "История",
  "history-of-ethereum": "История Ethereum",
  "home": "Главная",
  "how-ethereum-works": "Как работает Ethereum",
  "image": "изображение",
  "in-this-section": "В этом разделе",
  "individuals": "Физические лица",
  "individuals-menu": "Индивидуальное меню",
  "jobs": "Jobs",
  "kraken-logo": "Логотип Kraken",
  "language-ar": "Арабский",
  "language-bg": "Bulgarian",
  "language-bn": "Бенгальский",
  "language-ca": "Catalan",
  "language-cs": "Чешский",
  "language-de": "Немецкий",
  "language-el": "Греческий",
  "language-en": "Английский",
  "language-es": "Испанский",
  "language-fa": "Фарси",
  "language-fi": "Финский",
  "language-fr": "Французский",
  "language-hu": "Венгерский",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Индонезийский",
  "language-ig": "Igbo",
  "language-it": "Итальянский",
  "language-ja": "Японский",
  "language-ko": "Корейский",
  "language-lt": "Литовский",
  "language-ml": "Malayalam",
  "language-nb": "Норвежский",
  "language-nl": "Голландский",
  "language-pl": "Польский",
  "language-pt": "Португальский",
  "language-pt-br": "Португальский (Бразильский)",
  "language-ro": "Румынский",
  "language-ru": "Русский",
  "language-se": "Шведский",
  "language-sk": "Словацкий",
  "language-sl": "Slovenian",
  "language-support": "Поддержка языков",
  "language-tr": "Турецкий",
  "language-uk": "Украинский",
  "language-vi": "Въетнамский",
  "language-zh": "Chinese Simplified",
  "language-zh-tw": "Chinese Traditional",
  "languages": "Языки",
  "last-24-hrs": "Последние 24 часа",
  "last-edit": "Последнее редактирование",
  "learn": "Обучение",
  "learn-by-coding": "Обучение на программировании",
  "learn-menu": "Меню обучения",
  "learn-more": "Узнать больше",
  "less": "Меньше",
  "light-mode": "Светлый режим",
  "listing-policy-disclaimer": "Все продукты, перечисленные на этой странице, не являются официальными одобрениями и предоставляются только в информационных целях. Если вы хотите добавить продукт или предоставить отзыв о политике, поднимите вопрос в GitHub.",
  "listing-policy-raise-issue-link": "Поднять вопрос",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Загрузка...",
  "loading-error": "Ошибка загрузки.",
  "loading-error-refresh": "Ошибка загрузки данных. Попробуйте обновить страницу.",
  "logo": "логотип",
  "loopring-logo": "Логотип Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Основная сеть Ethereum",
  "makerdao-logo": "Логотип MakerDAO",
  "matcha-logo": "Логотип Matcha",
  "merge": "Merge",
  "more": "Больше",
  "nav-beginners": "Начинающим",
  "next": "Следующий",
  "oasis-logo": "Логотип Oasis",
  "on-this-page": "На этой странице",
  "page-content": "Содержимое страницы",
  "page-enterprise": "Компаниям",
  "page-last-updated": "Последнее обновление страницы",
  "previous": "Предыдущие",
  "privacy-policy": "Политика о конфиденциальности",
  "private-ethereum": "Частный Ethereum",
  "pros": "Преимущества",
  "read-more": "Подробнее",
  "refresh": "Обновите страницу.",
  "review-progress": "Прогресс проверки",
  "search": "Поиск",
  "search-box-blank-state-text": "Искать здесь!",
  "search-eth-address": "Похоже на адрес Ethereum. Мы не предоставляем особые данные для адресов. Попробуйте поискать их в обозревателе блоков, как",
  "search-no-results": "Поиск не дал результатов",
  "security": "Security",
  "see-contributors": "Посмотреть участников",
  "set-up-local-env": "Настройка локальной среды",
  "shard-chains": "Цепочки-осколки",
  "show-all": "Показать все",
  "show-less": "Показать меньше",
  "site-description": "Ethereum – глобальная децентрализованная платформа для применения денег и создания новых видов приложений. На Ethereum можно писать код, который управляет деньгами, и создавать приложения, доступные в любой точке мира.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoins",
  "staking": "Ставки",
  "summary": "Сводка",
  "terms-of-use": "Условия пользования",
  "transaction-fees": "Что такое комиссия за транзакцию?",
  "translation-banner-body-new": "Вы просматриваете эту страницу на английском языке, потому что мы еще не перевели ее. Помогите нам перевести эти материалы.",
  "translation-banner-body-update": "Есть новая версия этой страницы, но пока только на английском языке. Помогите нам перевести последнюю версию.",
  "translation-banner-button-join-translation-program": "Присоединиться к программе перевода",
  "translation-banner-button-learn-more": "Подробнее",
  "translation-banner-button-see-english": "См. английский",
  "translation-banner-button-translate-page": "Перевести страницу",
  "translation-banner-title-new": "Помогите перевести эту страницу",
  "translation-banner-title-update": "Помогите обновить эту страницу",
  "translation-program": "Translation Program",
  "translation-progress": "Прогресс перевода",
  "tutorials": "Учебники",
  "uniswap-logo": "Логотип Uniswap",
  "use": "Использование",
  "use-ethereum": "Пользование Ethereum",
  "use-ethereum-menu": "Использовать меню Ethereum",
  "vision": "Видение",
  "wallets": "Кошельки",
  "website-last-updated": "Последнее обновление страницы",
  "what-is-ether": "Что такое Ether (ETH)?",
  "what-is-ethereum": "Что такое Ethereum?",
  "whitepaper": "Техническое описание",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Предложить возможность",
  "page-about-h3": "Работа в процессе",
  "page-about-h3-1": "Реализованные возможности",
  "page-about-h3-2": "Запланированные возможности",
  "page-about-li-1": "в процессе",
  "page-about-li-2": "запланировано",
  "page-about-li-3": "реализовано",
  "page-about-li-4": "реализовано",
  "page-about-link-1": "Исходный код репозитория распространяется под лицензией MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Просмотреть полный список выполняемых задач на GitHub",
  "page-about-link-4": "Присоединиться к нашему серверу Discord",
  "page-about-link-5": "Связаться с нами в Twitter",
  "page-about-link-6": "Просмотреть полный список реализованных задач на GitHub",
  "page-about-link-7": "Создать запрос на GitHub",
  "page-about-p-1": "С самого запуска ethereum.org мы стремимся работать и действовать открыто. Это одна из наших главных ценностей, так как мы считаем, что прозрачность играет ключевую роль в успехе Ethereum.",
  "page-about-p-2": "Мы используем",
  "page-about-p-3": "в качестве основного средства управления проектами. Мы организуем задачи по трем категориям:",
  "page-about-p-4": "  Мы стараемся держать сообщество в курсе того, в каком статусе находится та или иная задача.",
  "page-about-p-5": "Задачи, которые мы реализуем.",
  "page-about-p-6": "Задачи, которые у нас следующие в очереди на выполнение.",
  "page-about-p-7": "Недавно выполненные задачи.",
  "page-about-p-8": "У вас есть какие-нибудь идеи насчет того, как улучшить ethereum.org? Будем рады сотрудничать с вами!",
  "page-assets-bazaar": "Базар Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Создание блоков",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge использует dapps",
  "page-assets-download-artist": "Исполнитель:",
  "page-assets-download-download": "Загрузить",
  "page-assets-enterprise": "Компания Ethereum",
  "page-assets-eth": "Эфир (ETH)",
  "page-assets-eth-diamond-color": "Бриллиант ETH (цвет)",
  "page-assets-eth-diamond-glyph": "Бриллиант ETH (знак)",
  "page-assets-eth-diamond-gray": "Бриллиант ETH (серый)",
  "page-assets-eth-diamond-purple": "Бриллиант ETH (фиолетовый)",
  "page-assets-eth-diamond-white": "Бриллиант ETH (белый)",
  "page-assets-eth-glyph-video-dark": "Видео с символом ETH (темный)",
  "page-assets-eth-glyph-video-light": "Видео с символом ETH (светлый)",
  "page-assets-eth-logo-landscape-gray": "Пейзаж с логотипом ETH (серый)",
  "page-assets-eth-logo-landscape-purple": "Пейзаж с логотипом ETH (фиолетовый)",
  "page-assets-eth-logo-landscape-white": "Пейзаж с логотипом ETH (белый)",
  "page-assets-eth-logo-portrait-gray": "Портрет с логотипом ETH (серый)",
  "page-assets-eth-logo-portrait-purple": "Портрет с логотипом ETH (фиолетовый)",
  "page-assets-eth-logo-portrait-white": "Портрет с логотипом ETH (белый)",
  "page-assets-eth-wordmark-gray": "Надпись ETH (серый)",
  "page-assets-eth-wordmark-purple": "Надпись ETH (фиолетовый)",
  "page-assets-eth-wordmark-white": "Надпись ETH (белый)",
  "page-assets-ethereum-brand-assets": "Активы бренда Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "активы ethereum.org",
  "page-assets-hero": "герой ethereum.org",
  "page-assets-hero-particles": "Изображение частиц ETH",
  "page-assets-historical-artwork": "Историческое произведение искусства",
  "page-assets-illustrations": "Иллюстрации",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Изучите и загрузите активы, иллюстрации и материалы брендов Ethereum и ethereum.org.",
  "page-assets-meta-title": "Активы бренда Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Сплошной фон",
  "page-assets-page-assets-transparent-background": "Прозрачный фон",
  "page-assets-robot": "Робот-кошелек",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1-дюймовый логотип",
  "page-dapps-aave-logo-alt": "Логотип Aave",
  "page-dapps-add-button": "Предложить децентрализованное приложение",
  "page-dapps-add-title": "Добавить децентрализованное приложение",
  "page-dapps-audius-logo-alt": "Логотип Audius",
  "page-dapps-augur-logo-alt": "Логотип Augur",
  "page-dapps-axie-infinity-logo-alt": "Логотип Axie Infinity",
  "page-dapps-brave-logo-alt": "Логотип Brave",
  "page-dapps-category-arts": "Искусство и мода",
  "page-dapps-category-browsers": "Браузеры",
  "page-dapps-category-collectibles": "Цифровые коллекционные экземпляры",
  "page-dapps-category-competitive": "Конкуренция",
  "page-dapps-category-computing": "Инструменты для разработчиков",
  "page-dapps-category-dex": "Обмен токенами",
  "page-dapps-category-investments": "Инвестиции",
  "page-dapps-category-lending": "Кредитование и заимствование",
  "page-dapps-category-lottery": "Краудфандинг",
  "page-dapps-category-marketplaces": "Торговые площадки",
  "page-dapps-category-music": "Музыка",
  "page-dapps-category-payments": "Платежи",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Рынки торговли и прогнозирования",
  "page-dapps-category-utilities": "Утилиты",
  "page-dapps-category-worlds": "Виртуальные миры",
  "page-dapps-choose-category": "Выберите категорию",
  "page-dapps-collectibles-benefits-1-description": "Когда предмет искусства токенизируется на Ethereum, право собственности может быть доказано и доступно всем для просмотра. Вы можете проследить процесс от создания предмета искусства до его текущего владельца. Это предотвращает подделки.",
  "page-dapps-collectibles-benefits-1-title": "Собственность доказуема",
  "page-dapps-collectibles-benefits-2-description": "Деятелям искусства намного легче оплачивать музыку или покупать произведения искусства. С Ethereum потребность в посредниках меньше. А если посредники необходимы, затраты на них будут не такими высокими, потому что платформам не нужно платить за инфраструктуру сети.",
  "page-dapps-collectibles-benefits-2-title": "Более справедливый для деятелей искусства",
  "page-dapps-collectibles-benefits-3-description": "Токенизированные коллекционные предметы привязываются к вашему адресу Ethereum, а не к платформе. Таким образом, вы можете продавать такие вещи, как внутриигровые предметы, на любом рынке Ethereum, а не только в самой игре.",
  "page-dapps-collectibles-benefits-3-title": "Коллекционные предметы принадлежат вам",
  "page-dapps-collectibles-benefits-4-description": "Уже существуют инструменты, чтобы вы смогли токенизировать и продавать ваши предметы искусства! А ваши токены могут быть проданы на любой платформе коллекционных предметов Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Инфраструктура уже создана",
  "page-dapps-collectibles-benefits-description": "Это приложения, в которых основное внимание уделяется цифровой собственности, увеличивая потенциал заработка деятелей искусства, и изобретению новых способов инвестирования в любимых деятелей искусства и их работы.",
  "page-dapps-collectibles-benefits-title": "децентрализованные коллекционные предметы и стриминг",
  "page-dapps-collectibles-button": "Предметы искусства и коллекционные предметы",
  "page-dapps-collectibles-description": "Это приложения, в которых основное внимание уделяется цифровой собственности, увеличивая потенциал заработка деятелей искусства, и изобретению новых способов инвестирования в любимых деятелей искусства и их работы.",
  "page-dapps-collectibles-title": "Децентрализованные предметы искусства и коллекционные предметы",
  "page-dapps-compound-logo-alt": "Логотип Compound",
  "page-dapps-cryptopunks-logo-alt": "Логотип CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Логотип Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Помогает избежать проскальзывания цен, объединяя лучшие цены.",
  "page-dapps-dapp-description-aave": "Одалживайте токены, чтобы зарабатывать проценты, и выводите средства в любое время.",
  "page-dapps-dapp-description-async-art": "Создавайте, собирайте и продавайте #ProgrammableArt - цифровые картины, разделенные на слои, которые можно использовать для изменения общего изображения. Каждый мастер и слой - это токен ERC721.",
  "page-dapps-dapp-description-audius": "Децентрализованная стриминговая платформа. Прослушивания = доход авторам, а не музыкальным лейблам.",
  "page-dapps-dapp-description-augur": "Делайте ставки на результаты спортивных, экономических и других мировых событий.",
  "page-dapps-dapp-description-axie-infinity": "Обменивайтесь и сражайтесь существами по имени Axies. Зарабатывайте в процессе игры - доступно на мобильных устройствах",
  "page-dapps-dapp-description-brave": "Зарабатывайте токены за просмотры и поддерживайте ваших любимых авторов.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Одалживайте токены, чтобы зарабатывать проценты, и выводите средства в любое время.",
  "page-dapps-dapp-description-cryptopunks": "Покупайте, торгуйте и выставляйте панки на продажу – один из первых коллекционных токенов на Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Создавайте художественные галереи, стройте магазины и покупайте землю – виртуальный мир Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Завоевывайте планеты в бесконечной, случайно сгенерированной, криптографически определенной вселенной.",
  "page-dapps-dapp-description-decentraland": "Собирайте и торгуйте виртуальной землей в виртуальном мире, который можно исследовать.",
  "page-dapps-dapp-description-dydx": "Открывайте короткие или маржинальные позиции с помощью кредитного плеча, которое может достигать десятикратного размера. Также доступны кредитование и займы.",
  "page-dapps-dapp-description-ens": "Удобные имена для адресов Ethereum и децентрализованных площадок.",
  "page-dapps-dapp-description-foundation": "Инвестируйте в уникальные издания цифровых произведений искусства и торгуйте работами с другими покупателями.",
  "page-dapps-dapp-description-gitcoin": "Зарабатывайте криптовалюту, работающую на ПО с открытым исходным кодом.",
  "page-dapps-dapp-description-gitcoin-grants": "Краудфандинг с увеличивающимися взносами для проектов сообщества Ethereum",
  "page-dapps-dapp-description-gods-unchained": "Стратегическая коллекционная карточная игра. Получайте карты, которые вы сможете продать в реальной жизни, просто играя.",
  "page-dapps-dapp-description-golem": "Пользуйтесь общей вычислительной мощностью или сдавайте в аренду ваши собственные ресурсы.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Одноранговая торговая платформа, созданная для скорости.",
  "page-dapps-dapp-description-marble-cards": "Создавайте и торгуйте уникальными цифровыми картами на основе URL-адресов.",
  "page-dapps-dapp-description-matcha": "Проверяет цены на ряде бирж, чтобы помочь вам найти лучший вариант.",
  "page-dapps-dapp-description-nifty-gateway": "Покупайте работы от лучших художников, спортсменов, брендов и создателей непосредственно в блокчейне.",
  "page-dapps-dapp-description-oasis": "Торгуйте, занимайте и экономьте с Dai, стейблкойном Ethereum.",
  "page-dapps-dapp-description-opensea": "Покупайте, продавайте, находите и торгуйте эксклюзивными товарами.",
  "page-dapps-dapp-description-opera": "Отправляйте криптовалюту из своего браузера продавцам, другим пользователям и приложениям.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Ставьте на результат. Торгуйте на информационных рынках.",
  "page-dapps-dapp-description-pooltogether": "Лотерея, в которой невозможно проиграть. Призы каждую неделю.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Создавайте, продавайте и покупайте токенизированные коллекционные предметы.",
  "page-dapps-dapp-description-sablier": "«Транслируйте» деньги в реальном времени.",
  "page-dapps-dapp-description-superrare": "Покупайте цифровые произведения искусства непосредственно от художников или на вторичных рынках.",
  "page-dapps-dapp-description-token-sets": "Стратегии инвестирования, которые автоматически ребалансируют портфель.",
  "page-dapps-dapp-description-tornado-cash": "Отправляйте анонимные транзакции в Ethereum.",
  "page-dapps-dapp-description-uniswap": "Легко обменивайте токены или предоставляйте их для получения наград в процентах.",
  "page-dapps-dark-forest-logo-alt": "Логотип Dark Forest",
  "page-dapps-decentraland-logo-alt": "Логотип Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Выберите и попробуйте приложение Ethereum.",
  "page-dapps-doge-img-alt": "Рисунок доге за компьютером",
  "page-dapps-dydx-logo-alt": "Логотип dYdX",
  "page-dapps-editors-choice-dark-forest": "Играйте против других, чтобы завоевать планеты и опробовать новейшие технологии масштабирования/приватности Ethereum. Подойдет для тех, кто уже знаком с Ethereum.",
  "page-dapps-editors-choice-description": "Несколько децентрализованных приложений, которые понравились команде ethereum.org. Ниже вы найдете еще больше приложений.",
  "page-dapps-editors-choice-foundation": "Инвестируйте в культуру. Покупайте, обменивайте и продавайте уникальные цифровые произведения искусства и моды от невероятных художников, музыкантов и брендов.",
  "page-dapps-editors-choice-header": "Выбор редакции",
  "page-dapps-editors-choice-pooltogether": "Купите билет беспроигрышной лотереи. Каждую неделю проценты, полученные от всего фонда билетов, отправляются одному счастливчику. Вернуть деньги возможно в любое удобное для вас время.",
  "page-dapps-editors-choice-uniswap": "Обменивайте свои токены с легкостью. Любимец сообщества, позволяющий вам торговать токенами с другими людьми по сети.",
  "page-dapps-ens-logo-alt": "Логотип Ethereum Name Service",
  "page-dapps-explore-dapps-description": "Многие децентрализованные приложения все еще являются экспериментальными, испытывающими возможности децентрализованных сетей. Но некоторые первые разработки в сферах технологий, финансов, игровой индустрии и коллекционирования уже стали успешными.",
  "page-dapps-explore-dapps-title": "Просмотреть приложения",
  "page-dapps-features-1-description": "После размещения в Ethereum код централизованных приложений нельзя удалить. Каждый может пользоваться функциями приложений. Даже если команда, создавшая приложение, распалась, вы все равно сможете им пользоваться. Попав на Ethereum, оно остается там.",
  "page-dapps-features-1-title": "Не имеют владельцев",
  "page-dapps-features-2-description": "Вам не могут заблокировать dapp или запретить отправку транзакций. Например, если бы Twitter работал на Ethereum, никто бы не смог заблокировать вам аккаунт или запретить отправлять сообщения на нем.",
  "page-dapps-features-2-title": "Не подвергаются цензуре",
  "page-dapps-features-3-description": "Так как у Ethereum есть ETH, платежи являются собственностью Ethereum. Разработчикам не нужно тратить время на сотрудничество со сторонними поставщиками платежных инструментов.",
  "page-dapps-features-3-title": "Встроенные платежи",
  "page-dapps-features-4-description": "Код Dapp по умолчанию является открытым и совместимым. Команды разработчиков постоянно пользуются плодами работы других программистов. Если вы хотите позволить пользователям меняться токенами в вашем приложении, вы можете просто подключить код другого приложения.",
  "page-dapps-features-4-title": "Подключите и играйте",
  "page-dapps-features-5-description": "С большинством dapp вам не нужно раскрывать вашу настоящую личность. Ваш аккаунт в Ethereum - это ваш логин, и вам нужен только кошелек.",
  "page-dapps-features-5-title": "Одно анонимное имя пользователя",
  "page-dapps-features-6-description": "Криптография гарантирует, что злоумышленники не смогут подделать транзакции и прочие действия в dapp от вашего имени. Вы совершаете действия на dapp через свой аккаунт Ethereum, обычно посредством своего кошелька, поэтому храните свои учетные данные в безопасности.",
  "page-dapps-features-6-title": "Основано на криптографии",
  "page-dapps-features-7-description": "Когда dapp появляется на Ethereum, оно может перестать работать только в случае, если сам Ethereum перестанет работать. Сети такого размера, как Ethereum, как известно, сложно атаковать.",
  "page-dapps-features-7-title": "Никаких простоев",
  "page-dapps-finance-benefits-1-description": "Финансовые услуги, работающие на Эфириуме, не требуют регистрации. Если у вас есть средства и подключение к Интернету, то вы уже готовы.",
  "page-dapps-finance-benefits-1-title": "Открытый доступ",
  "page-dapps-finance-benefits-2-description": "Целый мир токенов, с которыми можно взаимодействовать через эти финансовые продукты. Люди постоянно создают новые токены на основе Ethereum.",
  "page-dapps-finance-benefits-2-title": "Новая экономика токенов",
  "page-dapps-finance-benefits-3-description": "Команды создали стэйблкоины — менее волатильные криптовалюты. Они позволяют вам экспериментировать и использовать криптовалюты без рисков и неопределенности.",
  "page-dapps-finance-benefits-3-title": "Стейблкоины",
  "page-dapps-finance-benefits-4-description": "Все финансовые продукты в Ethereum обладают модульностью и совместимы друг с другом. Новые конфигурации этих модулей постоянно выходят на рынок, тем самым расширяя ваши возможности использования криптовалюты.",
  "page-dapps-finance-benefits-4-title": "Взаимосвязанные финансовые службы",
  "page-dapps-finance-benefits-description": "Что такого в Ethereum, что позволяет процветать децентрализованным финансовым приложениям?",
  "page-dapps-finance-benefits-title": "децентрализованные финансы",
  "page-dapps-finance-button": "Финансы",
  "page-dapps-finance-description": "Ниже представлены приложения, сосредоточенные на построении финансовых служб с использованием криптовалют. Они предлагают аналоги кредитов, займов, получения процентов и личных платежей без предоставления личных данных.",
  "page-dapps-finance-title": "Децентрализованные финансы",
  "page-dapps-foundation-logo-alt": "Логотип фонда",
  "page-dapps-gaming-benefits-1-description": "Независимо от того, идет ли речь о виртуальной земле или о коллекционных карточках, предметы можно продавать на коллекционных рынках. Ваши игровые предметы обладают реальной ценностью.",
  "page-dapps-gaming-benefits-1-title": "Игровые предметы удваиваются как токены",
  "page-dapps-gaming-benefits-2-description": "Не игровые компании, а вы владеете своими предметами, а в некоторых случаях и вашим прогрессом. Таким образом, если игровая компания подвергнется нападению, пострадает от сбоя сервера или будет ликвидирована, вы ничего не потеряете.",
  "page-dapps-gaming-benefits-2-title": "Ваши сбережения в безопасности",
  "page-dapps-gaming-benefits-3-description": "Точно так же как платежи Ethereum доступны любому для проверки, игры могут использовать эту особенность для обеспечения объективности. Теоретически все поддается проверке, начиная от количества критических ударов и заканчивая резервным фондом противника.",
  "page-dapps-gaming-benefits-3-title": "Доказуемая справедливость",
  "page-dapps-gaming-benefits-description": "Что особенного в Ethereum, что позволяет процветать децентрализованным финансовым приложениям?",
  "page-dapps-gaming-benefits-title": "децентрализованные игры",
  "page-dapps-gaming-button": "Игры",
  "page-dapps-gaming-description": "Это приложения, сфокусированные на создании виртуальных миров и борьбе с другими игроками при помощи коллекционных предметов, которые обладают реальной ценностью.",
  "page-dapps-gaming-title": "Децентрализованные игры",
  "page-dapps-get-some-eth-description": "При совершении действий в Dapp оплачивается комиссия за транзакцию",
  "page-dapps-get-started-subtitle": "Чтобы опробовать dapp, вам понадобится кошелек и ETH. Кошелек позволит вам подключиться или войти в систему. И вам понадобится ETH для оплаты любых комиссий за транзакции.",
  "page-dapps-get-started-title": "Начать",
  "page-dapps-gitcoin-grants-logo-alt": "Логотип Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Логотип Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Логотип Gods Unchained",
  "page-dapps-golem-logo-alt": "Логотип Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Инструменты и услуги на базе Ethereum",
  "page-dapps-hero-subtitle": "Dapps - это растущее движение приложений, использующих Ethereum для разрушения старых бизнес-моделей или изобретения новых.",
  "page-dapps-how-dapps-work-p1": "Внутренний код (смарт-контракты) приложений Dapps работает в децентрализованной сети, а не на централизованном сервере. Эти приложения используют блокчейн Ethereum для хранения данных и смарт-контракты для своих алгоритмов.",
  "page-dapps-how-dapps-work-p2": "Смарт-контракт подобен набору правил, которые действуют в сети, чтобы все могли их видеть и работать в точном соответствии с этими правилами. Представьте себе торговый автомат: если вы обеспечите его достаточным количеством средств и сделаете правильный выбор, вы получите желаемый товар. И, подобно торговым автоматам, смарт-контракты могут хранить средства так же, как ваш счет в Ethereum. Это позволяет коду быть посредником в соглашениях и транзакциях.",
  "page-dapps-how-dapps-work-p3": "Как только dapps будут размещены в сети Ethereum, вы не сможете их изменить. Dapps могут быть децентрализованными, потому что они контролируются алгоритмом, записанным в контракте, а не отдельным человеком или компанией.",
  "page-dapps-how-dapps-work-title": "Как работают dapps",
  "page-dapps-learn-callout-button": "Приступить к созданию приложений",
  "page-dapps-learn-callout-description": "На нашем портале для разработчиков есть документы, инструменты и платформы, которые помогут вам начать создавать децентрализованное приложение.",
  "page-dapps-learn-callout-image-alt": "Рисунок руки, создающей логотип ETH из кубиков лего.",
  "page-dapps-learn-callout-title": "Научитесь создавать dapp",
  "page-dapps-loopring-logo-alt": "Логотип Loopring",
  "page-dapps-magic-behind-dapps-description": "Dapps могут показаться обычными приложениями. Но они обладают некоторыми особыми качествами, потому что наследуют все сверхспособности Ethereum. Вот что отличает dapps от приложений.",
  "page-dapps-magic-behind-dapps-link": "В чем причина успеха Ethereum?",
  "page-dapps-magic-behind-dapps-title": "Волшебство, скрывающееся за dapps",
  "page-dapps-magic-title-1": "Волшебство",
  "page-dapps-magic-title-2": "за",
  "page-dapps-magician-img-alt": "Иллюстрация с волшебниками",
  "page-dapps-marble-cards-logo-alt": "логотип marble.cards",
  "page-dapps-matcha-logo-alt": "Логотип Matcha",
  "page-dapps-mobile-options-header": "Просмотреть другую категорию",
  "page-dapps-nifty-gateway-logo-alt": "Логотип Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Логотип Oasis",
  "page-dapps-opensea-logo-alt": "Логотип OpenSea",
  "page-dapps-opera-logo-alt": "Логотип Opera",
  "page-dapps-polymarket-logo-alt": "Логотип Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Логотип PoolTogether",
  "page-dapps-rarible-logo-alt": "Логотип Rarible",
  "page-dapps-ready-button": "Вперед",
  "page-dapps-ready-description": "Выберите dapp для пробы",
  "page-dapps-ready-title": "Готовы?",
  "page-dapps-sablier-logo-alt": "Логотип Sablier",
  "page-dapps-set-up-a-wallet-button": "Найти кошелек",
  "page-dapps-set-up-a-wallet-description": "Кошелек - это ваше имя пользователя для приложения dapp",
  "page-dapps-set-up-a-wallet-title": "Настройте кошелек",
  "page-dapps-superrare-logo-alt": "Логотип SuperRare",
  "page-dapps-technology-button": "Технология",
  "page-dapps-technology-description": "Это приложения, сфокусированные на децентрализованные средства разработчиков, включающие криптоэкономические системы в существующую технологию и создающие рынок для разработок на основе открытого исходного кода.",
  "page-dapps-technology-title": "Децентрализованная технология",
  "page-dapps-token-sets-logo-alt": "Логотип Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Логотип наличных Tornado",
  "page-dapps-uniswap-logo-alt": "Логотип Uniswap",
  "page-dapps-wallet-callout-button": "Найти кошелек",
  "page-dapps-wallet-callout-description": "Кошельки тоже являются приложениями dapp. Выберите кошелек на основе функций, которые подходят вам.",
  "page-dapps-wallet-callout-image-alt": "Иллюстрация робота.",
  "page-dapps-wallet-callout-title": "Просмотреть кошельки",
  "page-dapps-warning-header": "Всегда ищите информацию самостоятельно",
  "page-dapps-warning-message": "Ethereum является новой технологией, и большинство приложений являются новыми. Прежде чем вложить большое количество денег, убедитесь, что вы понимаете риски.",
  "page-dapps-what-are-dapps": "Что такое приложения dapp?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Аккаунты",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Дополнительно",
  "docs-nav-backend-apis": "Внутренние API",
  "docs-nav-block-explorers": "Инструменты изучения блоков",
  "docs-nav-blocks": "Блоки",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Компиляция смарт-контрактов",
  "docs-nav-composability": "Композиционность",
  "docs-nav-consensus-mechanisms": "Механизмы консенсуса",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Данные и аналитика",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Развертывание смарт-контрактов",
  "docs-nav-development-frameworks": "Среды разработки",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Сети разработки",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "Клиентские API Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Стек Ethereum",
  "docs-nav-evm": "Виртуальная машина Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Основные темы",
  "docs-nav-gas": "Газ",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Интегрированные среды разработки (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Введение в приложения dapp",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Введение в Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Введение в стек",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "API JavaScript",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Майнинг",
  "docs-nav-networks": "Сети",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Узлы и клиенты",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Узлы как услуга",
  "docs-nav-oracles": "Оракулы",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Языки программирования",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Доказательство доли владения (PoS)",
  "docs-nav-proof-of-work": "Доказательство выполненной работы (PoW)",
  "docs-nav-python": "Python",
  "docs-nav-readme": "ПРОЧИТАЙ МЕНЯ",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Масштабирование",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Безопасность",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Анатомия смарт-контрактов",
  "docs-nav-smart-contract-languages": "Языки смарт-контрактов",
  "docs-nav-smart-contracts": "Смарт-контракты",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Библиотеки смарт-контрактов",
  "docs-nav-standards": "Стандарты",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Хранилище",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Тестирование смарт-контрактов",
  "docs-nav-token-standards": "Стандарты токенов",
  "docs-nav-transactions": "Транзакции",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 и Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Если вы эксперт по этой теме и хотите внести свой вклад, отредактируйте эту страницу и посыпьте ее своей мудростью.",
  "page-calltocontribute-desc-2": "Вас поблагодарят, и вы поможете сообществу Ethereum!",
  "page-calltocontribute-desc-3": "Используйте эту гибкость",
  "page-calltocontribute-desc-4": "Вопросы? Спросите нас в канале #content на нашем",
  "page-calltocontribute-link": "шаблон документации",
  "page-calltocontribute-link-2": "Сервер Discord",
  "page-calltocontribute-span": "Редактировать страницу",
  "page-calltocontribute-title": "Помогите нам с этой страницей",
  "page-developer-meta-title": "Ресурсы для разработчиков Ethereum",
  "page-developers-about": "Об этих ресурсах для разработчиков",
  "page-developers-about-desc": "ethereum.org поможет вам создавать приложения с Ethereum с помощью документации о базовых понятиях и стеке разработки. Кроме того, есть учебники, которые помогут вам начать работать.",
  "page-developers-about-desc-2": "Нас вдохновила сеть разработчиков Mozilla, и мы решили, что Ethereum необходимо место для размещения полезных материалов и ресурсов для разработчиков. Как и у наших друзей в Mozilla, все это основано на открытом исходном коде и готово к расширению и улучшению с вашей стороны.",
  "page-developers-account-desc": "Контракты или люди в сети",
  "page-developers-accounts-link": "Учетные записи",
  "page-developers-advanced": "Дополнительно",
  "page-developers-api-desc": "Пользование библиотеками для взаимодействия с умными контрактами",
  "page-developers-api-link": "Внутренние API",
  "page-developers-aria-label": "Меню разработчика",
  "page-developers-block-desc": "Пакеты транзакций, добавленных в блокчейн",
  "page-developers-block-explorers-desc": "Ваш портал с данными об Ethereum",
  "page-developers-block-explorers-link": "Инструменты изучения блоков",
  "page-developers-blocks-link": "Блоки",
  "page-developers-browse-tutorials": "Просмотреть учебники",
  "page-developers-choose-stack": "Выбрать свой стек",
  "page-developers-contribute": "Внести свой вклад",
  "page-developers-dev-env-desc": "IDE, подходящие для разработки dapp",
  "page-developers-dev-env-link": "Среды разработки",
  "page-developers-discord": "Присоединиться к Discord",
  "page-developers-docs-introductions": "Введения",
  "page-developers-evm-desc": "Компьютер, который обрабатывает транзакции",
  "page-developers-evm-link": "Виртуальная машина Ethereum (EVM)",
  "page-developers-explore-documentation": "Изучить документацию",
  "page-developers-feedback": "Если вы хотите оставить отзыв, свяжитесь с нами через GitHub Issue или на нашем сервере Discord.",
  "page-developers-frameworks-desc": "Инструменты для ускорения разработки",
  "page-developers-frameworks-link": "Среды разработки",
  "page-developers-fundamentals": "Основы",
  "page-developers-gas-desc": "Ether необходим для выполнения транзакций",
  "page-developers-gas-link": "Газ",
  "page-developers-get-started": "Как вы хотите начать работать?",
  "page-developers-improve-ethereum": "Помогите нам улучшить ethereum.org",
  "page-developers-improve-ethereum-desc": "Как и ethereum.org, эти документы являются плодами совместных трудов сообщества. Создайте PR, если вы увидите ошибки, возможности для улучшения или новые возможности, чтобы помочь разработчикам Ethereum.",
  "page-developers-into-eth-desc": "Введение в блокчейн и Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Введение в децентрализованные приложения",
  "page-developers-intro-dapps-link": "Введение в приложения dapp",
  "page-developers-intro-eth-link": "Введение в Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Введение в стек",
  "page-developers-intro-stack-desc": "Введение в стек Ethereum",
  "page-developers-js-libraries-desc": "Применение JavaScript для взаимодействия с умными контрактами",
  "page-developers-js-libraries-link": "Библиотеки JavaScript",
  "page-developers-language-desc": "Применение Ethereum с знакомыми языками",
  "page-developers-languages": "Языки программирования",
  "page-developers-learn": "Учиться разработке с Ethereum",
  "page-developers-learn-desc": "Узнать о базовых концепциях и стеке Ethereum с нашей документацией",
  "page-developers-learn-tutorials": "Учиться с помощью учебников",
  "page-developers-learn-tutorials-cta": "Смотреть учебники",
  "page-developers-learn-tutorials-desc": "Учитесь разработке с Ethereum шаг за шагом у разработчиков, которые уже научились этому.",
  "page-developers-meta-desc": "Документация, учебники и инструменты для разработчиков на Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Как создаются новые блоки и достигается консенсус",
  "page-developers-mining-link": "Майнинг",
  "page-developers-networks-desc": "Обзор основной сети и тестовых сетей",
  "page-developers-networks-link": "Сети",
  "page-developers-node-clients-desc": "Как проверяют блоки и транзакции в сети",
  "page-developers-node-clients-link": " Узлы и клиенты",
  "page-developers-oracle-desc": "Добавление данных вне цепи в свои умные контракты",
  "page-developers-oracles-link": "Оракулы",
  "page-developers-play-code": "Играть с кодом",
  "page-developers-read-docs": "Читать документацию",
  "page-developers-scaling-desc": "Решения для ускорения транзакций",
  "page-developers-scaling-link": "Масштабирование",
  "page-developers-smart-contract-security-desc": "Рекомендуемые меры безопасности в процессе разработки",
  "page-developers-smart-contract-security-link": "Безопасность",
  "page-developers-set-up": "Настройка локальной среды",
  "page-developers-setup-desc": "Подготовьте свой стек к созданию приложения, настроив среду разработки.",
  "page-developers-smart-contracts-desc": "Логика приложений dapp – немедленно вступающие в силу соглашения",
  "page-developers-smart-contracts-link": "Умные контракты",
  "page-developers-stack": "Стек",
  "page-developers-start": "Начать экспериментировать",
  "page-developers-start-desc": "Хотите сначала поэкспериментировать, а потом задать вопросы?",
  "page-developers-storage-desc": "Как работать с хранилищем dapp",
  "page-developers-storage-link": "Хранилище",
  "page-developers-subtitle": "Руководство разработчика для Ethereum. От разработчиков и для разработчиков.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "разработчик",
  "page-developers-title-3": "ресурсы",
  "page-developers-token-standards-desc": "Обзор принятых стандартов токенов",
  "page-developers-token-standards-link": "Стандарты токенов",
  "page-developers-transactions-desc": "Способ изменения состояния Ethereum",
  "page-developers-transactions-link": "Транзакции",
  "page-developers-web3-desc": "Чем отличается мир разработок web3",
  "page-developers-web3-link": "Web2 против Web3",
  "page-learning-tools-bootcamps": "Учебные лагеря для разработчиков",
  "page-learning-tools-bootcamps-desc": "Платные онлайн-курсы для быстрой прокачки навыков.",
  "page-learning-tools-browse-docs": "Просмотр документов",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Логотип ChainShot",
  "page-learning-tools-coding": "Учитесь на программировании",
  "page-learning-tools-coding-subtitle": "Эти инструменты позволят вам поэкспериментировать с Ethereum, если вы предпочитаете более интерактивное обучение.",
  "page-learning-tools-consensys-academy-description": "Онлайн-лагерь для разработчиков Ethereum.",
  "page-learning-tools-consensys-academy-logo-alt": "Логотип ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Обучение Solidity на примере создания игры про зомби.",
  "page-learning-tools-cryptozombies-logo-alt": "Логотип CryptoZombies",
  "page-learning-tools-documentation": "Учитесь с документацией",
  "page-learning-tools-documentation-desc": "Хотите узнать больше? Прочтите документацию, чтобы найти интересующую вас информацию.",
  "page-learning-tools-eth-dot-build-description": "Обучающая песочница для web3, включающая программирование drag-and-drop и строительные блоки с открытым исходным кодом.",
  "page-learning-tools-eth-dot-build-logo-alt": "Логотип Eth.build",
  "page-learning-tools-ethernauts-description": "Проходите уровни, взламывая смарт-контракты.",
  "page-learning-tools-ethernauts-logo-alt": "Логотип Ethernauts",
  "page-learning-tools-game-tutorials": "Интерактивные игровые руководства",
  "page-learning-tools-game-tutorials-desc": "Учитесь, играя. Эти руководства проведут вас через основы, используя игры.",
  "page-learning-tools-meta-desc": "Онлайн-инструменты программирования и интерактивное обучение, чтобы помочь вам в экспериментах с разработками на Ethereum.",
  "page-learning-tools-meta-title": "Инструменты обучения разработчика",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Разработка, развертывание и администрирование смарт-контрактов для Ethereum. Следуйте инструкциям с плагином Learneth.",
  "page-learning-tools-remix-description-2": "Remix - это не просто песочница. Многие разработчики пишут, компилируют и развертывают свои смарт-контракты с помощью Remix.",
  "page-learning-tools-remix-logo-alt": "Логотип Remix",
  "page-learning-tools-sandbox": "Песочницы для кода",
  "page-learning-tools-sandbox-desc": "Эти песочницы дадут вам пространство для экспериментов с написанием смарт-контрактов и пониманием Ethereum.",
  "page-learning-tools-studio-description": "Интегрированная онлайн-среда разработки, в которой вы можете следовать учебникам для создания и тестирования смарт-контрактов и построить внешний интерфейс для них.",
  "page-learning-tools-vyperfun-description": "Изучите Vyper, построив свою собственную игру Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Логотип Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Основанная на Python платформа для разработки и тестирования смарт-контрактов, нацеленных на Ethereum Virtual Machine.",
  "page-local-environment-brownie-logo-alt": "Логотип Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Комплексная платформа для разработчиков, позволяющая создавать и внедрять децентрализованные приложения.",
  "page-local-environment-embark-logo-alt": "Логотип Embark",
  "page-local-environment-epirus-desc": "Платформа для разработки, применения и отслеживания приложений блокчейн на Java Virtual Machine",
  "page-local-environment-epirus-logo-alt": "Логотип Epirus",
  "page-local-environment-eth-app-desc": "Создавайте приложения на базе Ethereum одной командой. Сопровождается широким выбором фреймворков пользовательского интерфейса и шаблонов DeFi.",
  "page-local-environment-eth-app-logo-alt": "Создать логотип Eth App",
  "page-local-environment-framework-feature-1": "Функции для запуска локального экземпляра блокчейна.",
  "page-local-environment-framework-feature-2": "Утилиты для составления и тестирования ваших смарт-контрактов.",
  "page-local-environment-framework-feature-3": "Клиентские разработки дополнений для создания вашего пользовательского приложения в рамках одного проекта/репозитория.",
  "page-local-environment-framework-feature-4": "Конфигурация для подключения к сетям Ethereum и создания контрактов, будь то локальный запуск или одна из публичных сетей Ethereum.",
  "page-local-environment-framework-feature-5": "Децентрализованное распределение приложений - интеграция с различными способами хранения данных, такими как IPFS.",
  "page-local-environment-framework-features": "Эти фреймворки идут совместно с большим количеством нестандартного функционала, например:",
  "page-local-environment-frameworks-desc": " Мы рекомендуем выбрать фреймворк, особенно если вы только начинаете работу. Создание полноценного приложения требует различных технологий. Фреймворк включает в себя множество необходимых функций или предоставляет простые системы плагинов для выбора нужных вам инструментов.",
  "page-local-environment-frameworks-title": "Фреймворки и готовые стеки",
  "page-local-environment-hardhat-desc": "Hardhat - это среда разработки Ethereum для профессионалов.",
  "page-local-environment-hardhat-logo-alt": "Логотип Hardhat",
  "page-local-environment-openZeppelin-desc": "Сократите время разработки, составляя, совершенствуя и взаимодействуя со смарт-контрактами с нашим CLI.",
  "page-local-environment-openZeppelin-logo-alt": "Логотип OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App: все, что вам нужно, чтобы начать создавать децентрализованные приложения на основе смарт-контрактов",
  "page-local-environment-scaffold-eth-logo-alt": "логотип scaffold-eth",
  "page-local-environment-setup-meta-desc": "Руководство по выбору стека ПО для разработки на Ethereum.",
  "page-local-environment-setup-meta-title": "Настройка локальной разработки на Ethereum",
  "page-local-environment-setup-subtitle": "Если вы готовы приступить к созданию приложения, пришло время выбрать стек.",
  "page-local-environment-setup-subtitle-2": " Вот инструменты и фреймворки, которые вы можете использовать, чтобы создать приложение Ethereum.",
  "page-local-environment-setup-title": "Настройте локальную среду разработки",
  "page-local-environment-solidity-template-desc": "Шаблон GitHub для предварительной установки ваших смарт-контрактов Solidity. Включает в себя локальную сеть Hardhat, Waffle для тестирования, Ethers для применения кошелька и многое другое.",
  "page-local-environment-solidity-template-logo-alt": "Логотип шаблона Solidity",
  "page-local-environment-truffle-desc": "Truffle Suite обеспечивает разработчикам максимальный комфорт в работе от идеи до dapp.",
  "page-local-environment-truffle-logo-alt": "Логотип Truffle",
  "page-local-environment-waffle-desc": "Самая продвинутая тестовая версия для смарт-контрактов. Используйте отдельно или со Scaffold-eth или Hardhat.",
  "page-local-environment-waffle-logo-alt": "Логотип Waffle",
  "comp-tutorial-metadata-minute-read": "минута прочтения",
  "page-tutorial-listing-policy-intro": "Перед отправкой руководства прочтите нашу политику размещения.",
  "comp-tutorial-metadata-tip-author": "Подсказка автора",
  "page-tutorial-listing-policy": "политика размещения статьи",
  "page-tutorial-new-github": "Впервые на GitHub?",
  "page-tutorial-new-github-desc": "Поднять вопрос – просто введите требуемую информацию и вставьте свое руководство.",
  "page-tutorial-pull-request": "Создать запрос на изменение кода",
  "page-tutorial-pull-request-btn": "Создать запрос на изменение кода",
  "page-tutorial-pull-request-desc-1": "Следуйте",
  "page-tutorial-pull-request-desc-2": "tutorials/название-вашего-учебника/index.md",
  "page-tutorial-pull-request-desc-3": "структуре наименования.",
  "page-tutorial-raise-issue-btn": "Поднять вопрос",
  "page-tutorial-read-time": "мин",
  "page-tutorial-submit-btn": "Добавить руководство",
  "page-tutorial-submit-tutorial": "Чтобы отправить руководство, вам нужно использовать GitHub. Мы приглашаем вас создать вопрос или запрос на изменение кода.",
  "page-tutorial-subtitle": "Добро пожаловать в наш курируемый список руководств сообщества.",
  "page-tutorial-tags-error": "Ни одно руководство не содержит всех этих тегов",
  "page-tutorial-title": "Руководства по разработке на Ethereum",
  "page-tutorials-meta-description": "Просматривайте и фильтруйте проверенные руководства сообщества Ethereum по темам.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Руководства по разработке на Ethereum",
  "page-eth-buy-some": "Хотите купить несколько Ethereum?",
  "page-eth-buy-some-desc": "Часто путают Ethereum и ETH. Ethereum - это блокчейн, а ETH - основной актив Ethereum. ETH - это то, что вы, вероятно, хотите купить.",
  "page-eth-cat-img-alt": "Изображение символа ETH с калейдоскопом кошек",
  "page-eth-collectible-tokens": "Коллекционные токены",
  "page-eth-collectible-tokens-desc": "Токены, представляющие собой собираемые игровые предметы, произведения цифрового искусства или иные уникальные активы, общеизвестные как нефункциональные токены (NFT).",
  "page-eth-cryptography": "Защищено криптографией",
  "page-eth-cryptography-desc": "Интернет-деньги могут быть новыми, но они защищены проверенной криптографией. Это защищает ваш кошелек, ваши ETH, а также ваши транзакции. ",
  "page-eth-currency-for-apps": "Это валюта приложений Ethereum.",
  "page-eth-currency-for-future": "Валюта для нашего цифрового будущего",
  "page-eth-description": "ETH – это криптовалюта. Это редкие цифровые деньги, которые можно использовать в Интернете по аналогии с Bitcoin. Если вы новичок в криптовалюте, вот как ETH отличается от традиционных денег.",
  "page-eth-earn-interest-link": "Зарабатывайте проценты",
  "page-eth-ethhub-caption": "Часто обновляется",
  "page-eth-ethhub-overview": "EthHub имеет прекрасный обзор, так сказать",
  "page-eth-flexible-amounts": "Доступно в гибких суммах",
  "page-eth-flexible-amounts-desc": "ETH делится до 18 знаков после запятой, так что вам не нужно покупать 1 целое ETH. Вы можете покупать дробные части за раз – до 0,000000000000000001 ETH, если требуется.",
  "page-eth-fuels": "ETH являются топливом и гарантом Ethereum",
  "page-eth-fuels-desc": "ETH – это кровь жизни Ethereum. Когда вы отправляете ETH или используете Ethereum-приложение, вы платите небольшую сумму в ETH за использование сети Ethereum. Эта комиссия является стимулом для майнера обрабатывать и проверять то, что вы пытаетесь сделать.",
  "page-eth-fuels-desc-2": "Майнеры как регистраторы Ethereum – они проверяют и доказывают, что никто не жульничает. Майнеры, которые делают эту работу, также награждаются небольшими суммами новых выпущенных ETH.",
  "page-eth-fuels-desc-3": "Работающие майнеры обеспечивают безопасность Ethereum и свободу от централизованного контроля. Иными словами,",
  "page-eth-fuels-more-staking": "Подробнее о стейкинге",
  "page-eth-fuels-staking": "ETH станет еще более важным со стейкингом. Когда вы вкладываете ваши ETH, вы сможете помочь защитить Ethereum и получить награды. В этой системе угроза потери ваших ETH сдерживает атаки.",
  "page-eth-get-eth-btn": "Получить ETH",
  "page-eth-gov-tokens": "Управляющие токены",
  "page-eth-gov-tokens-desc": "Токены, представляющие право голоса в децентрализованных организациях.",
  "page-eth-has-value": "Почему ETH имеет ценность?",
  "page-eth-has-value-desc": "ETH ценен по-разному для разных людей.",
  "page-eth-has-value-desc-2": "Для пользователей Ethereum ETH ценен, потому что он позволяет им платить за транзакции.",
  "page-eth-has-value-desc-3": "С другой точки зрения, это цифровое хранилище ценностей, потому что создание новых ETH замедляется с течением времени.",
  "page-eth-has-value-desc-4": "В последнее время ETH стал ценным для пользователей финансовых приложений на Ethereum. Это потому, что они могут использовать ETH в качестве залога для криптовалютных займов или в качестве платежной системы.",
  "page-eth-has-value-desc-5": "Разумеется, многие считают его инвестицией, похожей на биткойны или другие криптовалюты.",
  "page-eth-how-to-buy": "Как купить ETH",
  "page-eth-how-to-buy-caption": "Часто обновляется",
  "page-eth-is-money": "ETH – это цифровые глобальные деньги.",
  "page-eth-last-updated": "Январь 2019 г.",
  "page-eth-mining-link": "Еще о майнинге",
  "page-eth-monetary-policy": "Денежная политика Ethereum",
  "page-eth-more-on-ethereum-link": "Подробнее об Ethereum",
  "page-eth-no-centralized": "Нет централизованного управления ",
  "page-eth-no-centralized-desc": "ETH является децентрализованным и глобальным. Нет ни одной компании или банка, которые могут решить напечатать больше ETH или изменить условия его использования.",
  "page-eth-non-fungible-tokens-link": "Нефункциональные токены",
  "page-eth-not-only-crypto": "ETH - не единственная криптовалюта на Ethereum",
  "page-eth-not-only-crypto-desc": "Каждый может создать новые виды активов и торговать ими на Ethereum. Они известны как токены. Люди токенизировали традиционные валюты, их недвижимость, их искусство и даже самих себя!",
  "page-eth-not-only-crypto-desc-2": "Ethereum является домом для тысяч токенов – некоторые более полезны и ценны, чем другие. Разработчики постоянно строят новые токены, которые открывают новые возможности и новые рынки.",
  "page-eth-not-only-crypto-desc-3": "Если вы хотите узнать больше о токенах, наши друзья на EthHub написали несколько замечательных обзоров:",
  "page-eth-open": "Открыт любому",
  "page-eth-open-desc": "Вам нужно только подключение к Интернету и кошелек для принятия ETH. Вам не нужен доступ к банковскому счету, чтобы принимать платежи. ",
  "page-eth-p2p-payments": "Одноранговые платежи",
  "page-eth-p2p-payments-desc": "Вы можете отправить ETH без посредника, как банк. Это как передача денег лично, но вы можете делать это безопасно с кем угодно, где угодно, в любое время.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Популярные типы токена",
  "page-eth-powers-ethereum": "ETH является силовым двигателем Ethereum",
  "page-eth-shit-coins": "Шиткоины",
  "page-eth-shit-coins-desc": "Так как создание новых токенов просто, любой может сделать это - даже люди с плохими или неправильными намерениями. Всегда исследуйте токены, прежде чем использовать!",
  "page-eth-stablecoins": "Стейблкоины",
  "page-eth-stablecoins-desc": "Токены, отражающие стоимость традиционной валюты, как доллары. Это решает проблему неустойчивости со многими криптовалютами.",
  "page-eth-stablecoins-link": "Получить стейблкоины",
  "page-eth-stream-link": "Транслировать ETH",
  "page-eth-tokens-link": "Токены Ethereum",
  "page-eth-trade-link-2": "Обменять токены",
  "page-eth-underpins": "ETH является основой финансовой системы Ethereum",
  "page-eth-underpins-desc": "Не удовлетворенное платежами сообщество Ethereum создает целую финансовую систему, которая является одноранговой и доступной для всех.",
  "page-eth-underpins-desc-2": "Вы можете использовать ETH как залог для создания совершенно разных криптовалютных токенов на Ethereum. Кроме того, вы можете заимствовать и получать проценты в ETH и в других токенах, основанных на ETH.",
  "page-eth-uses": "Использование ETH растет каждый день",
  "page-eth-uses-desc": "Поскольку Ethereum является программируемым, разработчики могут формировать ETH бесчисленным количеством способов.",
  "page-eth-uses-desc-2": "Еще в 2015 году все вы могли отправить ETH с одного счета Ethereum на другой. Сегодня это лишь малая часть того, что вы можете сделать.",
  "page-eth-uses-desc-3": "платите кому-нибудь или получайте средства в режиме реального времени.",
  "page-eth-uses-desc-4": "вы можете менять ETH на другие токены, включая биткойны.",
  "page-eth-uses-desc-5": "в ETH и других токенах на базе Ethereum.",
  "page-eth-uses-desc-6": "доступ к миру криптовалют с постоянной, мало меняющейся ценностью.",
  "page-eth-value": "Почему ETH имеет ценность",
  "page-eth-video-alt": "Видео с символом ETH",
  "page-eth-whats-eth": "Что такое Ether (ETH)?",
  "page-eth-whats-eth-hero-alt": "Иллюстрация группы людей, восхищающихся начертанию символа эфира (ETH) с трепетом",
  "page-eth-whats-eth-meta-desc": "Что вам нужно знать, чтобы понять ETH и его место в Ethereum.",
  "page-eth-whats-eth-meta-title": "Что такое Ether (ETH)?",
  "page-eth-whats-ethereum": "Что такое Ethereum?",
  "page-eth-whats-ethereum-desc": "Если вы хотите узнать больше о Ethereum и технологиях, стоящих за ETH, посмотрите наше введение.",
  "page-eth-whats-unique": "Что особенного в ETH?",
  "page-eth-whats-unique-desc": "Есть много криптовалют и много других токенов на Ethereum, но некоторые вещи может сделать только ETH.",
  "page-eth-where-to-buy": "Где получить ETH",
  "page-eth-where-to-buy-desc": "Вы можете получить ETH с биржи или кошелька, но в разных странах действуют разные политики. Проверьте, есть ли сервисы, которые позволят вам купить ETH.",
  "page-eth-yours": "По-настоящему ваше",
  "page-eth-yours-desc": "ETH позволяет Вам быть вашим собственным банком. Вы можете управлять своими собственными средствами с помощью вашего кошелька /wallet/ в качестве доказательства владения – нет необходимости в третьих лицах.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Адрес контракта на депозит Eth2",
  "page-eth2-deposit-contract-address-caption": "Мы добавили пробелы, чтобы сделать адрес более удобным для чтения",
  "page-eth2-deposit-contract-address-check-btn": "Проверить адрес контракта на депозит",
  "page-eth2-deposit-contract-checkbox1": "Я уже использовал(-а) панель запуска для настройки своего валидатора Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Я понимаю, что мне нужно использовать панель запуска для вложения своих средств. Простой перевод на этот адрес ни к чему не приведет.",
  "page-eth2-deposit-contract-checkbox3": "Я собираюсь проверить адрес контракта на депозит по другим источникам.",
  "page-eth2-deposit-contract-confirm-address": "Подтвердите для раскрытия адреса",
  "page-eth2-deposit-contract-copied": "Скопированный адрес",
  "page-eth2-deposit-contract-copy": "Копировать адрес",
  "page-eth2-deposit-contract-etherscan": "Просмотреть контракт на Etherscan",
  "page-eth2-deposit-contract-h2": "Это не то место, где вы делаете вклад",
  "page-eth2-deposit-contract-launchpad": "Сделать вклад с помощью панели запуска",
  "page-eth2-deposit-contract-launchpad-2": "Использовать панель запуска",
  "page-eth2-deposit-contract-meta-desc": "Проверьте адрес контакта на депозит для стейкинга Eth2.",
  "page-eth2-deposit-contract-meta-title": "Адрес контракта на депозит Eth2",
  "page-eth2-deposit-contract-read-aloud": "Прочитайте адрес вслух",
  "page-eth2-deposit-contract-reveal-address-btn": "Раскрыть адрес",
  "page-eth2-deposit-contract-staking": " Чтобы вложить свои ETH в Eth2, вы должны использовать отдельный продукт launchpad (панель запуска) и следовать инструкциям. Отправка ETH на адрес этой страницы не сделает вас стейкером и приведет к ошибке транзакции.",
  "page-eth2-deposit-contract-staking-check": "Проверить эти источники",
  "page-eth2-deposit-contract-staking-check-desc": "Мы ожидаем, что там будет много поддельных адресов и мошенничества. Чтобы быть в безопасности, проверьте адрес Eth2, который вы используете на этой странице. Мы рекомендуем также проверить его с другими надежными источниками.",
  "page-eth2-deposit-contract-staking-more-link": "Подробнее о стейкинге",
  "page-eth2-deposit-contract-stop-reading": "Остановить чтение",
  "page-eth2-deposit-contract-subtitle": " Адрес контракта на стейкинг Eth2. Используйте эту страницу, чтобы подтвердить отправку средств на правильный адрес.",
  "page-eth2-deposit-contract-warning": "Проверьте каждый символ внимательно.",
  "page-eth2-deposit-contract-warning-2": "Отправка средств на этот адрес не будет работать и не сделает вас стейкером. Вы должны следовать инструкциям панели запуска.",
  "page-eth2-bug-bounty-annotated-specs": "спецификация с примечаниями",
  "page-eth2-bug-bounty-annotations": "Может быть полезно проверить следующие примечания:",
  "page-eth2-bug-bounty-client-bugs": "Ошибки клиента Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Клиенты будут запускать Beacon Chain после внедрения обновления. Клиентам необходимо следовать логике, указанной в спецификации, и быть защищенными от потенциальных атак. Ошибки, которые мы хотим найти, связаны с реализацией протокола.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "В настоящее время эту награду можно получить только за ошибки в Lighthouse, Nimbus, Teku и Prysm. По мере завершения аудитов и подготовки к работе могут быть добавлены другие клиенты.",
  "page-eth2-bug-bounty-clients": "Клиенты, за которые можно получить награды",
  "page-eth2-bug-bounty-clients-type-1": "вопросы, связанные с несоблюдением спецификации.",
  "page-eth2-bug-bounty-clients-type-2": "непредвиденные сбои или уязвимости, которые могут привести к отказу в обслуживании (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "любые проблемы, вызывающие непоправимые расколы в консенсусе от остальной сети.",
  "page-eth2-bug-bounty-docking": "стыковка",
  "page-eth2-bug-bounty-email-us": "Напишите нам электронное письмо:",
  "page-eth2-bug-bounty-help-links": "Полезные ссылки",
  "page-eth2-bug-bounty-hunting": "Правила поиска ошибок",
  "page-eth2-bug-bounty-hunting-desc": "Программа bug bounty - экспериментальная и общедоступная программа вознаграждений для нашего активного сообщества Ethereum, созданная, чтобы поощрять и награждать тех, кто помогает улучшить платформу. Это не соревнование. Вам следует знать, что мы можем отменить программу в любое время и что вознаграждения присуждаются по собственному усмотрению совета bug bounty компании Ethereum Foundation. Также мы не можем выдавать вознаграждения людям, которые находятся в санкционных списках или в странах, находящихся под санкциями (например, Северная Корея, Иран и т. д.). Вы несете ответственность за уплату всех налогов. Все вознаграждения являются субъектом действующего законодательства. Наконец, ваше тестирование не должно нарушать закон или компрометировать любые персональные данные, кроме ваших.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Список лидеров в поиске ошибок",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Ищите ошибки в Eth2, чтобы попасть в этот список лидеров",
  "page-eth2-bug-bounty-hunting-li-1": "Замечания, которые уже были представлены другим пользователем или уже известны спецификациям и сопровождающим клиента, не имеют права на получение вознаграждений.",
  "page-eth2-bug-bounty-hunting-li-2": "Публичное раскрытие уязвимости лишает вас права на получение награды.",
  "page-eth2-bug-bounty-hunting-li-3": "Исследователи фонда Ethereum и сотрудники команды клиента Eth2 не имеют права на вознаграждение.",
  "page-eth2-bug-bounty-hunting-li-4": "Программа вознаграждения Ethereum рассматривает ряд переменных при определении наград. Установление правил для участия, оценки и всех относящихся к награде условий остается на единоличное и окончательное усмотрение совета bug bounty фонда Ethereum.",
  "page-eth2-bug-bounty-leaderboard": "Просмотреть полный список лидеров",
  "page-eth2-bug-bounty-leaderboard-points": "очки",
  "page-eth2-bug-bounty-ledger-desc": "В спецификации Beacon Chain подробно описаны принципы проектирования и предложенные изменения в Ethereum с помощью обновления Beacon Chain.",
  "page-eth2-bug-bounty-ledger-title": "Ошибки в спецификации Beacon Chain",
  "page-eth2-bug-bounty-meta-description": "Обзор программы для поиска ошибок Eth2: как участвовать в ней и информация о вознаграждении.",
  "page-eth2-bug-bounty-meta-title": "Поощрительная программа поиска ошибок Eth2",
  "page-eth2-bug-bounty-not-included": "Не включено",
  "page-eth2-bug-bounty-not-included-desc": "Обновления с цепочками-осколками и стыковкой все еще находятся в активной разработке и еще не включены в эту программу поощрения.",
  "page-eth2-bug-bounty-owasp": "Просмотреть метод OWASP",
  "page-eth2-bug-bounty-points": "Кроме того, фонд Ethereum начислит баллы на основе:",
  "page-eth2-bug-bounty-points-error": "Ошибка загрузки данных... пожалуйста, обновите страницу.",
  "page-eth2-bug-bounty-points-exchange": "Обмен баллов",
  "page-eth2-bug-bounty-points-loading": "Загрузка данных...",
  "page-eth2-bug-bounty-points-payout-desc": "Фонд Ethereum выплатит сумму в долларах США в ETH или DAI.",
  "page-eth2-bug-bounty-points-point": "1 очко",
  "page-eth2-bug-bounty-points-rights-desc": "Фонд Ethereum оставляет за собой право изменять это предложение без предварительного уведомления.",
  "page-eth2-bug-bounty-points-usd": "2 доллара США",
  "page-eth2-bug-bounty-quality": "Качество описания",
  "page-eth2-bug-bounty-quality-desc": ": более высокое вознаграждение выплачивается за четкие, хорошо подготовленные работы.",
  "page-eth2-bug-bounty-quality-fix": "Качество исправления",
  "page-eth2-bug-bounty-quality-fix-desc": ", если включено: за сообщения с четким описанием того, как исправить ошибку, выплачиваются более высокие награды.",
  "page-eth2-bug-bounty-quality-repro": "Качество воспроизводимости",
  "page-eth2-bug-bounty-quality-repro-desc": ": пожалуйста, добавьте тестовый код, скрипты и подробные инструкции. Чем легче воспроизводить и проверять уязвимости, тем выше награда.",
  "page-eth2-bug-bounty-questions": "Есть вопросы?",
  "page-eth2-bug-bounty-rules": "Читать правила",
  "page-eth2-bug-bounty-shard-chains": "цепочки-осколки",
  "page-eth2-bug-bounty-slogan": "Программы вознаграждения за найденные ошибки Eth2",
  "page-eth2-bug-bounty-specs": "Читать полную спецификацию",
  "page-eth2-bug-bounty-specs-docs": "Документы спецификации",
  "page-eth2-bug-bounty-submit": "Сообщить об ошибке",
  "page-eth2-bug-bounty-submit-desc": "За каждую найденную ошибку вы будете награждены баллами. Количество баллов определяется серьезностью ошибки. Фонд Ethereum определяет серьезность ошибки с помощью метода OWASP.",
  "page-eth2-bug-bounty-subtitle": "Найдите ошибки в протоколе и клиенте Eth2 и заработайте до 50 000 долларов США и место в таблице лидеров.",
  "page-eth2-bug-bounty-title": "Открыто для публикации",
  "page-eth2-bug-bounty-title-1": "Beacon Chain",
  "page-eth2-bug-bounty-title-2": "Выбор ответвления",
  "page-eth2-bug-bounty-title-3": "Контракт на депозит Solidity",
  "page-eth2-bug-bounty-title-4": "Пиринговая сеть",
  "page-eth2-bug-bounty-type-1": "ошибки безопасности/окончания.",
  "page-eth2-bug-bounty-type-2": "векторы отказов в обслуживании (DOS)",
  "page-eth2-bug-bounty-type-3": "несоответствие предположений, например, ситуации, когда честные валидаторы могут быть слэшированы.",
  "page-eth2-bug-bounty-type-4": "вычисление или несовпадение параметров.",
  "page-eth2-bug-bounty-types": "Типы ошибок",
  "page-eth2-bug-bounty-validity": "Действительные ошибки",
  "page-eth2-bug-bounty-validity-desc": "Это программа поощрения за найденные ошибки концентрируется на поиске ошибок в основной спецификации Eth2 Beacon Chain и реализациях клиентов Prysm, Lighthouse и Teku.",
  "page-eth2-bug-bountycard-critical": "Критический",
  "page-eth2-bug-bountycard-critical-risk": "Отправить ошибку с критическим уровнем риска",
  "page-eth2-bug-bountycard-h2": "Средний",
  "page-eth2-bug-bountycard-high": "Высокий",
  "page-eth2-bug-bountycard-high-risk": "Отправить ошибку с высоким уровнем риска",
  "page-eth2-bug-bountycard-label-1": "До 1000 баллов",
  "page-eth2-bug-bountycard-label-2": "До 2000 DAI",
  "page-eth2-bug-bountycard-label-3": "До 5000 баллов",
  "page-eth2-bug-bountycard-label-4": "До 10 000 DAI",
  "page-eth2-bug-bountycard-label-5": "До 10 000 баллов",
  "page-eth2-bug-bountycard-label-6": "До 20 000 DAI",
  "page-eth2-bug-bountycard-label-7": "До 25 000 баллов",
  "page-eth2-bug-bountycard-label-8": "До 50 000 DAI",
  "page-eth2-bug-bountycard-li-1": "Низкое воздействие, средняя вероятность",
  "page-eth2-bug-bountycard-li-2": "Среднее воздействие, низкая вероятность",
  "page-eth2-bug-bountycard-li-3": "Высокое воздействие, низкая вероятность",
  "page-eth2-bug-bountycard-li-4": "Среднее воздействие, средняя вероятность",
  "page-eth2-bug-bountycard-li-5": "Низкое воздействие, высокая вероятность",
  "page-eth2-bug-bountycard-li-6": "Высокое воздействие, средняя вероятность",
  "page-eth2-bug-bountycard-li-7": "Среднее воздействие, высокая вероятность",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Низкий",
  "page-eth2-bug-bountycard-low-risk": "Отправить ошибку с низким уровнем риска",
  "page-eth2-bug-bountycard-medium-risk": "Отправить ошибку со средним уровнем риска",
  "page-eth2-bug-bountycard-subheader": "Серьезность",
  "page-eth2-bug-bountycard-subheader-2": "Пример",
  "page-eth2-bug-bountycard-text": "Иногда злоумышленник может поместить узел в состояние, которое заставит его выбрасывать по одной из каждой сотни аттестаций, сделанных валидатором",
  "page-eth2-bug-bountycard-text-1": "Злоумышленник может успешно провести атаки затмения на узлы с идентификаторами пиров с четырьмя ведущими нулевыми байтами",
  "page-eth2-bug-bountycard-text-2": "Это ошибка консенсуса между двумя клиентами, но для злоумышленника сложно или непрактично инициировать это событие.",
  "page-eth2-bug-bountycard-text-3": "Это ошибка консенсуса между двумя клиентами, и для злоумышленника просто инициировать это событие.",
  "page-eth2-get-involved-btn-1": "Смотреть клиенты",
  "page-eth2-get-involved-btn-2": "Подробнее о стейкинге",
  "page-eth2-get-involved-btn-3": "Найти ошибки",
  "page-eth2-get-involved-bug": "Возможные ошибки:",
  "page-eth2-get-involved-bug-hunting": "Искать ошибки",
  "page-eth2-get-involved-bug-hunting-desc": "Находите и сообщайте об ошибках в спецификации обновления Eth2 или в самих клиентах. Вы можете заработать до 50 000 долларов США и занять место в списке лидеров.",
  "page-eth2-get-involved-bug-li": "вопросы, связанные с несоблюдением спецификации",
  "page-eth2-get-involved-bug-li-2": "ошибки окончания",
  "page-eth2-get-involved-bug-li-3": "векторы отказов в обслуживании (DOS)",
  "page-eth2-get-involved-bug-li-4": "и многое другое...",
  "page-eth2-get-involved-date": "Дата окончания: 23 декабря 2020 года",
  "page-eth2-get-involved-desc-1": "Запуск клиента означает, что вы будете активным участником Ethereum. Ваш клиент будет помогать отслеживать транзакции и проверять новые блоки.",
  "page-eth2-get-involved-desc-2": "Если у вас есть ETH, вы можете вложить их, чтобы стать валидатором и помочь защитить сеть. В качестве валидатора вы cможете зарабатывать награды в ETH.",
  "page-eth2-get-involved-desc-3": "Присоединяйтесь к тестированию! Помогите проверить обновления Eth2, прежде чем они будут запущены, находите ошибки и получайте награды.",
  "page-eth2-get-involved-ethresearch-1": "Шардинг",
  "page-eth2-get-involved-ethresearch-2": "Переход от Eth1 к Eth2",
  "page-eth2-get-involved-ethresearch-3": "Осколки и выполнение состояния",
  "page-eth2-get-involved-ethresearch-4": "Все темы исследований",
  "page-eth2-get-involved-grants": "Гранты для сообщества стейкеров",
  "page-eth2-get-involved-grants-desc": "Помогите создать инструментарий и образовательные материалы для сообщества стейкеров",
  "page-eth2-get-involved-how": "Как вы хотите принять участие?",
  "page-eth2-get-involved-how-desc": "Сообщество Ethereum всегда будет получать выгоду от большего количества людей, запускающих клиенты, занимающихся стейкингом и ищущих ошибки.",
  "page-eth2-get-involved-join": "Присоединиться к исследованию",
  "page-eth2-get-involved-join-desc": " Как и большая часть всего, что связано с Ethereum, многие исследования являются публичными. Это означает, что вы можете принять участие в обсуждениях или просто прочитать то, что исследователи Ethereum могут сказать. ethresear.ch охватывает не только улучшения Eth2, но все же основной контент именно о Eth2.",
  "page-eth2-get-involved-meta-description": "Как участвовать в Eth2: запускать узлы, вкладывать свои средства, искать ошибки и многое другое.",
  "page-eth2-get-involved-more": "Подробнее",
  "page-eth2-get-involved-run-clients": "Запуск клиентов Beacon Chain",
  "page-eth2-get-involved-run-clients-desc": "Ключ к долгосрочной безопасности Ethereum – это умное распределение клиентов. Клиент — это программное обеспечение, которое запускает блокчейн, проверяет транзакции и создает новые блоки. У каждого клиента есть свои особенности, поэтому выберите один из них на основе того, с чем вам удобно работать.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Вкладывайте свои ETH",
  "page-eth2-get-involved-stake-desc": "Теперь вы можете вложить свои ETH, чтобы обеспечить безопасность Beacon Chain.",
  "page-eth2-get-involved-stake-eth": "Вложите ETH",
  "page-eth2-get-involved-subtitle": "Здесь представлены варианты того, как вы можете помочь Ethereum и будущим проектам, связанным с Eth2.",
  "page-eth2-get-involved-title-1": "Запустите клиент",
  "page-eth2-get-involved-title-2": "Вложите свои ETH",
  "page-eth2-get-involved-title-3": "Ищите ошибки",
  "page-eth2-get-involved-written-go": "Написан на Go",
  "page-eth2-get-involved-written-java": "Написан на Java",
  "page-eth2-get-involved-written-javascript": "Написан на JavaScript",
  "page-eth2-get-involved-written-net": "Написан на .NET",
  "page-eth2-get-involved-written-nim": "Написан на Nim",
  "page-eth2-get-involved-written-python": "Написан на Python",
  "page-eth2-get-involved-written-rust": "Написан на Rust",
  "eth2-client-cortex-logo-alt": "Логотип Cortex",
  "eth2-client-lighthouse-logo-alt": "Логотип Lighthouse",
  "eth2-client-lodestar-logo-alt": "Логотип Lodestar",
  "eth2-client-nimbus-logo-alt": "Логотип Nimbus",
  "eth2-client-prysm-logo-alt": "Логотип Prysm",
  "eth2-client-teku-logo-alt": "Логотип Teku",
  "eth2-rhino-img-alt": "Изображение носорога Eth2, нападающего вперед",
  "page-eth2-answer-1": "Думайте об Eth2 как о наборе обновлений, добавляемых для улучшения Ethereum, который мы используем сегодня. Эти обновления включают в себя создание новой цепочки, называемой Beacon Chain, и до 64 цепочек, известных как осколки.",
  "page-eth2-answer-2": "Они отделены от основной сети Ethereum, которую мы используем сегодня, но не заменят ее. Вместо этого основная сеть стыкуется или объединяется с этой параллельной системой, которая добавляется с течением времени.",
  "page-eth2-answer-4": "Другими словами, Ethereum, который мы используем сегодня, в конечном итоге будет воплощать все функции, к которым мы стремимся в концепции Eth2.",
  "page-eth2-beacon-chain-btn": "Подробнее о Beacon Chain",
  "page-eth2-beacon-chain-date": "Beacon Chain была запущена 1 декабря 2020 года.",
  "page-eth2-beacon-chain-desc": "Первое добавление Eth2 к экосистеме. Beacon Chain вводит стейкинг в Ethereum, закладывает основу для будущих обновлений и в конечном итоге будет координировать новую систему.",
  "page-eth2-beacon-chain-estimate": "Beacon Chain активен",
  "page-eth2-beacon-chain-title": "Beacon Chain",
  "page-eth2-bug-bounty": "Посмотреть программу bug bounty",
  "page-eth2-clients": "Ознакомьтесь с клиентами Eth2",
  "page-eth2-deposit-contract-title": "Проверить адрес контакта на депозит",
  "page-eth2-diagram-ethereum-mainnet": "Основная сеть Ethereum",
  "page-eth2-diagram-h2": "Как обновления сочетаются друг с другом",
  "page-eth2-diagram-link-1": "Подробнее о доказательстве работы",
  "page-eth2-diagram-link-2": "Подробнее о цепочках-осколках",
  "page-eth2-diagram-mainnet": "Основная сеть",
  "page-eth2-diagram-p": "Основная сеть Ethereum какое-то время будет существовать в нынешнем виде. Это означает, что обновления Beacon Chain и осколков не нарушат работу сети.",
  "page-eth2-diagram-p-1": "Основная сеть в конечном итоге объединится с новой системой, представленной обновлениями Eth2.",
  "page-eth2-diagram-p-2": "Beacon Chain станет проводником Ethereum, координирующим валидаторов и определяющим темп создания блоков.",
  "page-eth2-diagram-p-3": "Сначала она будет существовать отдельно от основной сети и управлять валидаторами – не будет иметь никакого отношения к смарт-контрактам, сделкам или счетам.",
  "page-eth2-diagram-p-4": "Осколки предоставят много дополнительных данных, которые помогут увеличить количество транзакций в основной сети. Их будет координировать Beacon Chain.",
  "page-eth2-diagram-p-5": "Но все операции все равно будут полагаться на основную сеть, которая будет продолжать существовать в сегодняшнем виде – защищенная проверкой работы и майнерами.",
  "page-eth2-diagram-p-6": "Основная сеть объединится с системой доказательства владения, координируемой Beacon Chain.",
  "page-eth2-diagram-p-8": "Это превратит основную сеть в осколок в рамках новой системы. Майнеры больше не будут нужны, так как весь Ethereum будет защищен валидаторами.",
  "page-eth2-diagram-p10": "Eth2 - это не миграция и не что-то одно. Он описывает набор обновлений, над которыми сейчас работают, чтобы раскрыть истинный потенциал Ethereum. Вот как они все сочетаются друг с другом.",
  "page-eth2-diagram-scroll": "Прокрутите для раскрытия – нажмите для получения дополнительной информации.",
  "page-eth2-diagram-shard": "Осколок (1)",
  "page-eth2-diagram-shard-1": "Осколок (...)",
  "page-eth2-diagram-shard-2": "Осколок (2)",
  "page-eth2-diagram-shard-3": "Осколок (...)",
  "page-eth2-diagram-validators": "Подробнее о валидаторах",
  "page-eth2-dive": "Погрузитесь в видение",
  "page-eth2-dive-desc": "Как мы собираемся сделать Ethereum более масштабируемым, безопасным и устойчивым? И все это при сохранении основного идеала децентрализации Ethereum.",
  "page-eth2-docking": "Стыковка",
  "page-eth2-docking-answer-1": "Стыковка произойдет, когда основная сеть превратится в осколок. Это произойдет после успешного внедрения цепочек-осколков.",
  "page-eth2-docking-btn": "Подробнее про слияние",
  "page-eth2-docking-desc": "В какой-то момент основную сеть Ethereum потребуется состыковать или объединить с Beacon Chain. Это включит стейкинг для всей сети и просигнализирует об окончании энергоемкого майнинга.",
  "page-eth2-docking-estimate": "Приблизительно: в 2022 году",
  "page-eth2-docking-mainnet": "Что такое основная сеть?",
  "page-eth2-docking-mainnet-eth2": "Стыковка основной сети с Eth2",
  "page-eth2-eth-blog": "блог ethereum.org",
  "page-eth2-explore-btn": "Просмотреть обновления",
  "page-eth2-get-involved": "Примите участие в Eth2",
  "page-eth2-get-involved-2": "Присоединитесь",
  "page-eth2-head-to": "Посмотрите",
  "page-eth2-help": "Хотите помочь с Eth2?",
  "page-eth2-help-desc": "Есть много возможностей оценить обновления Eth2, помочь с тестированием и даже получить награды.",
  "page-eth2-index-staking": "Стейкинг здесь",
  "page-eth2-index-staking-desc": "Ключевым обновлением Eth2 является введение стейкинга. Если вы хотите использовать свои ETH для защиты сети Ethereum, обязательно выполните следующие действия.",
  "page-eth2-index-staking-learn": "Узнайте о стейкинге",
  "page-eth2-index-staking-learn-desc": "Beacon Chain вводит стейкинг в Ethereum. Это означает, что если у вас есть ETH, вы можете делать общественное благо, защищая сеть и зарабатывая больше ETH в процессе.",
  "page-eth2-index-staking-step-1": "1. Выполните настройку с помощью панели запуска",
  "page-eth2-index-staking-step-1-btn": "Посетить панель запуска для стейкинга",
  "page-eth2-index-staking-step-1-desc": "Чтобы вложить свои средства в Eth2, вам нужно воспользоваться панелью запуска – это поможет вам пройти этот процесс.",
  "page-eth2-index-staking-step-2": "2. Подтвердите адрес стейкинга",
  "page-eth2-index-staking-step-2-btn": "Подтвердите адрес контакта на депозит",
  "page-eth2-index-staking-step-2-desc": "Перед вложением своих ETH убедитесь, что у вас правильный адрес. Перед этим вы должны были пройти через панель запуска.",
  "page-eth2-index-staking-sustainability": "Более экологичный",
  "page-eth2-just-docking": "Подробнее о стыковке",
  "page-eth2-meta-desc": "Обзор обновлений Ethereum 2.0 и видение, которое они должны воплотить в реальность.",
  "page-eth2-meta-title": "Обновления Eth2",
  "page-eth2-miners": "Подробнее о майнерах",
  "page-eth2-more-on-upgrades": "Подробнее об обновлениях Eth2",
  "page-eth2-proof-of-stake": "доказательство владения",
  "page-eth2-proof-of-work": "доказательство работы",
  "page-eth2-proof-stake-link": "Подробнее о доказательстве владения",
  "page-eth2-question-1-desc": "Eth2 - набор отдельных обновлений с различными датами запуска.",
  "page-eth2-question-1-title": "Когда будет запущен Eth2?",
  "page-eth2-question-2-desc": "Неправильно считать Eth2 отдельным блокчейном.",
  "page-eth2-question-2-title": "Eth2 - это отдельный блокчейн?",
  "page-eth2-question-3-answer-2": "Обновления с цепочками-осколками и стыковкой могут затронуть разработчиков децентрализованных приложений. Но спецификации еще не закончены, поэтому никаких незамедлительных действий не требуется.",
  "page-eth2-question-3-answer-3": "Поговорите с командой исследователей и разработчиков Eth2 на ethresearch.ch.",
  "page-eth2-question-3-answer-3-link": "Посетить ethresear.ch",
  "page-eth2-question-3-desc": "Сейчас вам не нужно ничего делать, чтобы подготовиться к Eth2.",
  "page-eth2-question-3-title": "Как мне подготовиться к Eth2?",
  "page-eth2-question-4-answer-1": "Каждый раз, когда вы отправляете транзакцию или пользуетесь приложением dapp сегодня, вы используете Eth1. Это Ethereum, защищенный майнерами.",
  "page-eth2-question-4-answer-2": "Eth1, или основная сеть, будет работать в обычном режиме до стыковки.",
  "page-eth2-question-4-answer-3": "После стыковки валидаторы будут обеспечивать безопасность всей сети через доказательство доли владения.",
  "page-eth2-question-4-answer-6": "Каждый может стать валидатором, вложив свои ETH.",
  "page-eth2-question-4-answer-7": "Подробнее о стейкинге",
  "page-eth2-question-4-answer-8": "Обновления с Beacon Chain и цепочками-осколками не нарушат Eth1, поскольку они строятся отдельно.",
  "page-eth2-question-4-desc": "Eth1 относится к основной сети Ethereum, в которой вы совершаете транзакции сегодня.",
  "page-eth2-question-4-title": "Что такое Eth1?",
  "page-eth2-question-5-answer-1": "Чтобы стать полноценным валидатором в сети, вам нужно вложить 32 ETH. Если у вас не так много средств или вы не готовы так много вкладывать, вы можете присоединиться к пулу стейкеров. Эти пулы позволят вам вкладывать меньше и зарабатывать доли от общей суммы вознаграждения.",
  "page-eth2-question-5-desc": "Вам нужно будет воспользоваться панелью запуска стейкинга или присоединиться к пулу стейкеров.",
  "page-eth2-question-5-title": "Как мне начать заниматься стейкингом?",
  "page-eth2-question-6-answer-1": "На данный момент никаких действий предпринимать не нужно. Но мы рекомендуем вам быть в курсе событий, связанных с обновлениями с цепочками-осколками и стыковкой.",
  "page-eth2-question-6-answer-3": "Дэнни Райан из фонда Ethereum регулярно обновляет сообщество:",
  "page-eth2-question-6-answer-4": "Бен Эджингтон из ConsenSys выпускает еженедельную информационную новостную рассылку на тему Eth2:",
  "page-eth2-question-6-answer-5": "Вы также можете присоединиться к обсуждению исследований и разработок Eth2 на ethresear.ch.",
  "page-eth2-question-6-desc": "Предстоящие обновления не повлияют на ваше приложение dapp. Однако будущие обновления могут потребовать некоторых изменений.",
  "page-eth2-question-6-title": "Что мне нужно делать с моим децентрализованным приложением?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(реализация на .NET)",
  "page-eth2-question-7-desc": "Много разных команд со всего сообщества работают над различными обновлениями Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(реализация на Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(реализация на JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(реализация на Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(реализация на Go)",
  "page-eth2-question-7-teams": "Команды клиентов Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(реализация на Java)",
  "page-eth2-question-7-title": "Кто создает Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(реализация на Python)",
  "page-eth2-question-8-answer-1": "Обновления Eth2 помогут Ethereum масштабироваться децентрализованно, сохраняя при этом безопасность и повышая устойчивость.",
  "page-eth2-question-8-answer-2": "Возможно, наиболее очевидная проблема заключается в том, что Ethereum должен обрабатывать более 15-45 транзакций в секунду. Но обновления Eth2 также решают некоторые другие проблемы с Ethereum сегодня.",
  "page-eth2-question-8-answer-3": "Сеть пользуется таким высоким спросом, что делает использование Ethereum дорогим. Узлы в сети испытывают трудности с размером Ethereum и объемом данных, которые их компьютеры должны обрабатывать. А базовый алгоритм, обеспечивающий безопасность и децентрализацию Ethereum, энергоемок и должен быть более экологичным.",
  "page-eth2-question-8-answer-4": "Многое из того, что менялось, всегда входило в план развития Ethereum, даже с 2015 года. Но текущие условия делают необходимость обновлений еще больше.",
  "page-eth2-question-8-answer-6": "Изучите концепцию Eth2",
  "page-eth2-question-8-desc": "Сегодняшний Ethereum должен стать более удобным в работе для конечных пользователей и участников сети.",
  "page-eth2-question-8-title": "Зачем нужен Eth2?",
  "page-eth2-question-9-answer-1": "Самая активная роль, которую вы можете сыграть, это вложить свои ETH.",
  "page-eth2-question-9-answer-2": "Вы также можете запустить второй клиент, чтобы повысить разнообразие клиентов.",
  "page-eth2-question-9-answer-3": "Если у вас есть технический опыт, вы можете помочь выявить ошибки в новых клиентах.",
  "page-eth2-question-9-answer-4": "Вы также можете принять участие в технических обсуждениях с исследователями Ethereum на ethresear.ch.",
  "page-eth2-question-9-desc": "Чтобы внести свой вклад, необязательно быть техническим специалистом. Наше cообщество ждет вклада от людей с самыми разными навыками.",
  "page-eth2-question-9-stake-eth": "Вложите ETH",
  "page-eth2-question-9-title": "Как я могу внести свой вклад в Eth2?",
  "page-eth2-question-10-answer-1": "Мы не хотим слишком много говорить о технической дорожной карте, потому что это программное обеспечение: все может измениться. Мы думаем, что легче понять, что происходит, когда вы читаете о результатах.",
  "page-eth2-question-10-answer-1-link": "Просмотреть обновления",
  "page-eth2-question-10-answer-2": "Но если вы следили за обсуждениями, вот как обновления вписываются в технические дорожные карты.",
  "page-eth2-question-10-answer-3": "Фаза 0 описывает работу по запуску Beacon Chain в реальном времени.",
  "page-eth2-question-10-answer-5": "Фаза 1 технических дорожных карт сосредоточена на реализации цепочек-осколков.",
  "page-eth2-question-10-answer-6": "Стыковка основной сети с Eth2 - это расширение работы, проделанной для реализации цепочек-осколков, которая получила название фаза 1.5. Но это важный момент, так как сегодняшний Ethereum сливается с другими обновлениями Eth2. Также в этот момент Ethereum полностью переходит на доказательство владения.",
  "page-eth2-question-10-answer-7": "В настоящее время неясно, что будет происходить вокруг фазы 2. Это все еще предмет интенсивных исследований и дискуссий. Первоначальный план состоял в том, чтобы добавить дополнительную функциональность к цепочкам-осколкам, но это могло не потребоваться с дорожной картой, ориентированной на свертки.",
  "page-eth2-question-10-answer-8": "Подробнее о дорожной карте, ориентированной на свертки",
  "page-eth2-question-10-desc": "Фазы связаны с фазами работы и фокусом технической дорожной карты Eth2.",
  "page-eth2-question-10-title": "Что такое фазы Eth2?",
  "page-eth2-question-title": "Часто задаваемые вопросы",
  "page-eth2-question3-answer-1": "Держателям ETH, конечно, не требуется ничего делать. Вашим ETH не понадобятся изменения или улучшения. Почти наверняка будут мошенники, говорящие прямо противоположное, так что будьте бдительны.",
  "page-eth2-scalable": "Более масштабируемый",
  "page-eth2-scalable-desc": "Ethereum должен поддерживать тысячи транзакций в секунду, чтобы сделать приложения быстрее и дешевле в использовании.",
  "page-eth2-secure": "Более безопасный",
  "page-eth2-secure-desc": "Ethereum должен быть более безопасным. Протокол должен быть более защищенным от всех форм атак, поскольку сферы применения Ethereum расширяются.",
  "page-eth2-shard-button": "Подробнее о цепочках-осколках",
  "page-eth2-shard-date": "Введение цепочек-осколков, второе обновление, ожидается где-то в 2021 году.",
  "page-eth2-shard-desc": "Цепочки-осколки расширят возможности Ethereum для обработки транзакций и хранения данных. Сами осколки со временем получат больше функций, запускаемых в несколько этапов.",
  "page-eth2-shard-estimate": "Приблизительно: в 2021 году",
  "page-eth2-shard-lower": "Подробнее о цепочках-осколках",
  "page-eth2-shard-title": "Цепочки-осколки",
  "page-eth2-stay-up-to-date": "Будьте в курсе",
  "page-eth2-stay-up-to-date-desc": "Получите последнюю информацию от исследователей и разработчиков, работающих над обновлениями Eth2.",
  "page-eth2-sustainable-desc": "Ethereum должен быть лучше для окружающей среды. Сегодняшняя технология требует слишком много вычислительной мощности и энергии.",
  "page-eth2-take-part": "Примите участие в исследовании",
  "page-eth2-take-part-desc": "Здесь встречаются исследователи и энтузиасты Ethereum, чтобы обсудить исследовательские усилия, включая все, что касается Eth2.",
  "page-eth2-the-upgrades": "Обновления Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 - это набор обновлений, которые улучшают масштабируемость, безопасность и устойчивость Ethereum. Хотя над каждым из них работают параллельно с другими, у них есть определенные зависимости, которые определяют, когда они будут развернуты.",
  "page-eth2-unofficial-roadmap": "Это не официальная дорожная карта. Вот как мы видим, что происходит на основе имеющейся в ней информации. Но эта технология может измениться мгновенно. Поэтому не считайте это обязательством.",
  "page-eth2-upgrade-desc": "Ethereum, который мы знаем и любим, просто более масштабируемый, более безопасный и более устойчивый...",
  "page-eth2-upgrades": "Обновления Eth2",
  "page-eth2-upgrades-aria-label": "Меню обновлений Eth2",
  "page-eth2-upgrades-beacon-chain": "Beacon Chain",
  "page-eth2-upgrades-docking": "Стыковка основной сети и Eth2",
  "page-eth2-upgrades-guide": "Руководство по обновлениям Eth2",
  "page-eth2-upgrades-shard-chains": "Цепочки-осколки",
  "page-eth2-upgrading": "Обновление Ethereum до радикально новых высот",
  "page-eth2-vision": "Наша концепция",
  "page-eth2-vision-btn": "Подробнее о концепции Eth2",
  "page-eth2-vision-desc": "Чтобы Ethereum стал популярным и служил всему человечеству, мы должны сделать Ethereum более масштабируемым, безопасным и устойчивым.",
  "page-eth2-vision-upper": "Концепция Eth2",
  "page-eth2-what-to-do": "Что вам нужно сделать?",
  "page-eth2-what-to-do-desc": "Если вы являетесь пользователем приложений dapp или держателем ETH, вам не нужно ничего делать. Если вы разработчик или хотите начать заниматься стейкингом, вы можете принять участие уже сегодня.",
  "page-eth2-whats-eth2": "Что такое Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 - это набор взаимосвязанных обновлений, которые сделают Ethereum более масштабируемым, безопасным и устойчивым. Эти обновления создаются несколькими командами из экосистемы Ethereum.",
  "page-eth2-whats-ethereum": "Погодите, что такое Ethereum?",
  "page-eth2-whats-new": "Что нового в Eth2?",
  "page-eth2-just-staking": "Стейкинг",
  "page-eth2-staking-image-alt": "Изображение талисмана-носорога для панели запуска eth2.",
  "page-eth2-staking-51-desc-1": "Стейкинг делает присоединение к сети в качестве валидатора более доступным, поэтому вполне вероятно, что в сети будет большее количество валидаторов, чем существует сегодня. Это еще больше усложнит атаку такого рода, поскольку стоимость атаки увеличится.",
  "page-eth2-staking-accessibility": "Более доступный",
  "page-eth2-staking-accessibility-desc": "Благодаря более простым требованиям к оборудованию и возможности объединения в пул в случае, если у участника нет 32 ETH, все больше людей смогут присоединиться к сети. Это сделает Ethereum более децентрализованным и безопасным за счет уменьшения площади поверхности атаки.",
  "page-eth2-staking-at-stake": "Ваши ETH на кону",
  "page-eth2-staking-at-stake-desc": "Поскольку вы должны предоставить свои ETH в виде вложения, чтобы подтверждать транзакции и создавать новые блоки, вы можете потерять эти монеты, если попробуете обмануть систему.",
  "page-eth2-staking-benefits": "Преимущества стейкинга в Ethereum",
  "page-eth2-staking-check-address": "Проверить адреса депозита",
  "page-eth2-staking-consensus": "Подробнее о механизмах консенсуса",
  "page-eth2-staking-deposit-address": "Проверить адрес депозита",
  "page-eth2-staking-deposit-address-desc": "Если вы уже выполнили инструкции по настройке на панели запуска, вы знаете, что вам нужно отправить транзакцию на контракт на депозит для стейкинга. Мы рекомендуем внимательно проверять адрес. Вы можете найти официальный адрес на ethereum.org и ряде других надежных сайтов.",
  "page-eth2-staking-desc-1": "Вознаграждение дается за действия, которые помогают сети достичь консенсуса. Вы получите вознаграждение за группирование транзакций в новый блок или проверку работы других валидаторов, потому что это то, что обеспечивает надежную работу цепочки.",
  "page-eth2-staking-desc-2": "Хотя вы можете получать вознаграждения за выполнение работы, которая приносит пользу сети, вы можете потерять ETH из-за злонамеренных действий, выхода из сети и отсутствия проверки.",
  "page-eth2-staking-desc-3": "Вам понадобится 32 ETH, чтобы стать полноценным валидатором, или некоторое количество ETH, чтобы присоединиться к пулу стейкеров. Вам также потребуется запустить Eth1 или клиент основной сети. Панель запуска ознакомит вас с процессом и требованиями к оборудованию. В качестве альтернативы вы можете использовать внутренний API.",
  "page-eth2-staking-description": "Стейкинг - это внесение 32 ETH для активации программного обеспечения валидатора. Как валидатор вы будете отвечать за хранение данных, обработку транзакций и добавление новых блоков в блокчейн. Это обеспечит безопасность Ethereum для всех и принесет вам новые ETH в процессе. Этот процесс, известный как доказательство владения, вводится с Beacon Chain.",
  "page-eth2-staking-docked": "Подробнее о стыковке",
  "page-eth2-staking-dyor": "Ищите информацию самостоятельно",
  "page-eth2-staking-dyor-desc": "Ни одна из перечисленных служб стейкинга не одобрена официально. Обязательно проведите небольшое исследование, чтобы выяснить, какая служба может быть лучше для вас.",
  "page-eth2-staking-header-1": "Вложите свои ETH, чтобы стать валидатором Ethereum",
  "page-eth2-staking-how-much": "Сколько вы готовы вложить?",
  "page-eth2-staking-how-to-stake": "Как заниматься стейкингом",
  "page-eth2-staking-how-to-stake-desc": "Все зависит от того, какое вложение вы хотите сделать. Вам понадобится 32 ETH, чтобы стать полноценным валидатором, но можно вложить меньше.",
  "page-eth2-staking-join": "Присоединиться",
  "page-eth2-staking-join-community": "Вступайте в сообщество стейкеров",
  "page-eth2-staking-join-community-desc": "r/ethstaker - это сообщество, в котором каждый может обсудить стейкинг на Ethereum. Присоединяйтесь, чтобы получить совет, поддержку и обсудить все, что касается стейкинга.",
  "page-eth2-staking-less-than": "Меньше, чем",
  "page-eth2-staking-link-1": "Просмотреть внутренние API",
  "page-eth2-staking-meta-description": "Обзор стейкинга Ethereum: риски, вознаграждения, требования и где это делать.",
  "page-eth2-staking-meta-title": "Стейкинг Ethereum",
  "page-eth2-staking-more-sharding": "Подробнее о шардинге",
  "page-eth2-staking-pool": "Стейкинг в пуле",
  "page-eth2-staking-pool-desc": "Если у вас меньше 32 ETH, вы сможете добавить меньшее количество монет в пулы стейкеров. Некоторые компании могут делать все это от вашего имени, поэтому вам не придется беспокоиться о том, чтобы оставаться в сети. Вот несколько компаний, с которыми стоит ознакомиться.",
  "page-eth2-staking-pos-explained": "Объяснение доказательства владения",
  "page-eth2-staking-pos-explained-desc": "Стейкинг - это то, что вам нужно делать, чтобы быть валидатором в системе доказательства владения. Это механизм консенсуса, который заменит существующую в настоящее время систему доказательства работы. Механизмы консенсуса - это то, что обеспечивает безопасность и децентрализацию таких блокчейнов, как Ethereum.",
  "page-eth2-staking-pos-explained-desc-1": "Доказательство владения помогает защитить сеть несколькими способами:",
  "page-eth2-staking-services": "Ознакомиться со службами стейкинга",
  "page-eth2-staking-sharding": "Разблокирует шардинг",
  "page-eth2-staking-sharding-desc": "Шардинг возможен только с системой доказательства владения. Шардинг в системе доказательства работы сократит количество вычислительных мощностей, необходимых для повреждения сети, что упростит злоумышленникам контроль над осколками. Дело обстоит иначе с выбранными случайным образом стейкерами в системе доказательства владения.",
  "page-eth2-staking-solo": "Занимайтесь стейкингом в одиночку и запускайте валидатор",
  "page-eth2-staking-solo-desc": "Чтобы начать процесс стейкинга, вам нужно будет использовать панель запуска Eth2. Это поможет вам разобраться со всеми настройками. Частью стейкинга является управление клиентом Eth2, локальной копией блокчейна. Загрузка этой копии на ваш компьютер может занять некоторое время.",
  "page-eth2-staking-start": "Начните стейкинг",
  "page-eth2-staking-subtitle": "Стейкинг - это общественное благо экосистемы Ethereum. Вы можете помочь защитить сеть и заработать вознаграждение в процессе.",
  "page-eth2-staking-sustainability": "Более экологичный",
  "page-eth2-staking-sustainability-desc": "Валидаторам не нужны энергоемкие компьютеры для участия в системе доказательство владения - только ноутбук или смартфон. Это делает Ethereum лучше для окружающей среды.",
  "page-eth2-staking-the-beacon-chain": "Подробнее о Beacon Chain",
  "page-eth2-staking-title-1": "Вознаграждения",
  "page-eth2-staking-title-2": "Риски",
  "page-eth2-staking-title-3": "Требования",
  "page-eth2-staking-title-4": "Как вложить свои ETH",
  "page-eth2-staking-upgrades-li": "Доказательством владения управляет Beacon Chain.",
  "page-eth2-staking-upgrades-li-2": "В обозримом будущем Ethereum будет иметь Beacon Chain с доказательством владения и основную сеть с доказательством работы. Основная сеть - это Ethereum, которым мы пользовались все эти годы.",
  "page-eth2-staking-upgrades-li-3": "В течение этого времени стейкеры будут добавлять новые блоки в Beacon Chain, но не обрабатывать транзакции в основной сети.",
  "page-eth2-staking-upgrades-li-4": "Ethereum полностью перейдет на систему доказательства владения, как только основная сеть Ethereum станет осколком.",
  "page-eth2-staking-upgrades-li-5": "Только после этого вы сможете вывести свое вложение.",
  "page-eth2-staking-upgrades-title": "Обновления доказательства владения и Eth2",
  "page-eth2-staking-validators": "Больше валидаторов, выше безопасность",
  "page-eth2-staking-validators-desc": "Такой блокчейн, как Ethereum, можно испортить, если вы контролируете 51 % сети. Например, вы можете заставить 51 % валидаторов подтвердить, что ваш баланс составляет 1 000 000 ETH, а не 1 ETH. Но чтобы контролировать 51 % валидаторов, вам нужно владеть 51 % ETH в системе - это много!",
  "page-eth2-staking-withdrawals": "Вывод средств начнется не сразу",
  "page-eth2-staking-withdrawals-desc": "Вы не сможете вывести свое вложение до тех пор, пока не будут применены следующие обновления. Снятие средств должно быть доступно после стыковки основной сети с системой Beacon Chain.",
  "page-eth2-vision-2014": "Просмотрите сообщение в блоге 2014 года, подробно описывающее доказательство владения",
  "page-eth2-vision-beacon-chain": "Beacon Chain",
  "page-eth2-vision-beacon-chain-btn": "Подробнее о Beacon Chain",
  "page-eth2-vision-beacon-chain-date": "The Beacon Chain активен",
  "page-eth2-vision-beacon-chain-desc": "Первое дополнение Eth2 к экосистеме. Beacon Chain добавляет вложения в Ethereum, закладывает основу для будущих обновлений и в конечном итоге будет координировать новую систему.",
  "page-eth2-vision-beacon-chain-upper": "Beacon Chain",
  "page-eth2-vision-desc-1": "Ethereum необходимо снизить перегруженность сети и повысить скорость, чтобы лучше обслуживать глобальную пользовательскую базу.",
  "page-eth2-vision-desc-2": "С ростом сети управлять узлом становится труднее. Это будет только усложняться усилиями по масштабированию сети.",
  "page-eth2-vision-desc-3": "Ethereum использует слишком много электричества. Технология, обеспечивающая безопасность сети, должна быть более устойчивой.",
  "page-eth2-vision-docking-date": "Приблизительно: в 2022 году",
  "page-eth2-vision-docking-desc": "В какой-то момент основную сеть Ethereum потребуется состыковать с Beacon Chain. Это включит стейкинг для всей сети и просигнализирует об окончании энергоемкого майнинга.",
  "page-eth2-vision-ethereum-node": "Подробнее об узлах",
  "page-eth2-vision-explore-upgrades": "Просмотреть обновления",
  "page-eth2-vision-future": "Цифровое будущее в глобальном масштабе",
  "page-eth2-vision-meta-desc": "Обзор влияния обновлений Eth2 на Ethereum и проблем, которые они должны решить.",
  "page-eth2-vision-meta-title": "Концепция Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Подробнее о майнинге",
  "page-eth2-vision-problems": "Текущие задачи",
  "page-eth2-vision-scalability": "Масштабируемость",
  "page-eth2-vision-scalability-desc": "Ethereum должен иметь возможность обрабатывать больше транзакций в секунду без увеличения размера узлов сети. Узлы - это жизненно важные участники сети, которые сохраняют и запускают блокчейн. Увеличение размера узла не является практичным, потому что это могут сделать только мощные и дорогостоящие компьютеры. Для масштабирования Ethereum требуется больше транзакций в секунду, а также больше узлов. Больше узлов означает большую безопасность.",
  "page-eth2-vision-scalability-desc-3": "Обновление с цепочками-осколками распространит нагрузку сети на 64 новые цепочки. Это облегчит работу Ethereum за счет сокращения перегруженности и повышения скорости, превышающей текущий лимит в 15-45 транзакций в секунду.",
  "page-eth2-vision-scalability-desc-4": "И даже несмотря на то, что будет больше цепочек, это на самом деле потребует меньше работы от валидаторов - сопровождающих сети. Валидаторам придется запускать только свои цепочки-осколки, а не всю цепочку Ethereum. Это сделает узлы более легкими и позволит Ethereum масштабироваться и оставаться децентрализованным.",
  "page-eth2-vision-security": "Безопасность",
  "page-eth2-vision-security-desc": "Обновления Eth2 улучшают защиту Ethereum от скоординированных атак, таких как атака 51 %. Это тип атаки, при которой, если кто-то контролирует большую часть сети, он может принудительно внести мошеннические изменения.",
  "page-eth2-vision-security-desc-3": "Переход на систему доказательства владения означает, что протокол Ethereum имеет больше сдерживающих факторов против атак. Это связано с тем, что в системе доказательства владения валидаторы, которые защищают сеть, должны вкладывать значительные суммы ETH в протокол. Если они попытаются атаковать сеть, протокол может автоматически уничтожить их ETH.",
  "page-eth2-vision-security-desc-5": "Это невозможно в системе доказательства работы, где лучшее, что может сделать протокол, это заставить объекты, которые защищают сеть (майнеры), терять вознаграждение за майнинг, которое они в противном случае заработали бы. Чтобы добиться аналогичного эффекта в системе доказательства работы, протокол должен иметь возможность уничтожить все оборудование майнера, если он попытается жульничать.",
  "page-eth2-vision-security-desc-5-link": "Подробнее о доказательстве работы",
  "page-eth2-vision-security-desc-8": "Модель безопасности Ethereum также нуждается в изменении из-за введения",
  "page-eth2-vision-security-desc-10": "Стейкинг также означает, что вам не нужно вкладывать деньги в элитное оборудование для запуска узла Ethereum. Это должно побудить больше людей становиться валидаторами, увеличивая децентрализацию сети и уменьшая площадь поверхности атаки.",
  "page-eth2-vision-security-staking": "Вложение ETH",
  "page-eth2-vision-security-validator": "Вы можете стать валидатором, вложив свои ETH.",
  "page-eth2-vision-shard-chains": "цепочки-осколки",
  "page-eth2-vision-shard-date": "Приблизительно: в 2021 году",
  "page-eth2-vision-shard-desc-4": "Цепочки-осколки распределяют нагрузку на сеть на 64 новые цепочки. Осколки могут значительно повысить скорость транзакций - до 100 000 в секунду.",
  "page-eth2-vision-shard-upgrade": "Подробнее о цепочках-осколках",
  "page-eth2-vision-staking-lower": "Подробнее о стейкинге",
  "page-eth2-vision-subtitle": "Развивайте Ethereum, пока он не станет достаточно мощным, чтобы помочь всему человечеству.",
  "page-eth2-vision-sustainability": "Устойчивость",
  "page-eth2-vision-sustainability-desc-1": "Ни для кого не секрет, что Ethereum и другие блокчейны, такие как Bitcoin, энергоемки из-за майнинга.",
  "page-eth2-vision-sustainability-desc-2": "Но Ethereum движется к тому, чтобы быть защищенным с помощью ETH, а не вычислительной мощности - через стейкинг.",
  "page-eth2-vision-sustainability-desc-3": "Хотя стейкинг будет введен с Beacon Chain, сегодняшний Ethereum будет работать параллельно в течение определенного периода времени, прежде чем он «сольется» или «состыкуется» с обновлениями Eth2. Одна система защищена ETH, другая - вычислительной мощностью. Это связано с тем, что сначала цепочки-осколки не смогут обрабатывать такие вещи, как наши учетные записи или децентрализованные приложения. Так что мы не можем просто забыть о майнинге и основной сети.",
  "page-eth2-vision-sustainability-desc-8": "Как только будут запущены обновления с Beacon Chain и цепочками-осколками, начнется работа по стыковке основной сети с новой системой. Это превратит основную сеть в осколок, так что она будет защищена ETH и станет менее энергоемкой.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum должен быть экологичнее.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "Концепция Eth2",
  "page-eth2-vision-title-1": "Забитая сеть",
  "page-eth2-vision-title-2": "Дисковое пространство",
  "page-eth2-vision-title-3": "Слишком много энергии",
  "page-eth2-vision-trilemma-cardtext-1": "Обновления Eth2 сделают Ethereum масштабируемым, безопасным и децентрализованным. Шардинг сделает Ethereum более масштабируемым за счет увеличения количества транзакций в секунду при одновременном снижении мощности, необходимой для запуска узла и проверки цепочки. Beacon Chain обеспечит безопасность Ethereum за счет распределения валидаторов по осколкам. А стейкинг снизит барьер для участия, создав большую и более децентрализованную сеть.",
  "page-eth2-vision-trilemma-cardtext-2": "Безопасные и децентрализованные сети блокчейнов требуют, чтобы каждый узел проверял каждую транзакцию, обрабатываемую цепочкой. Этот объем работы ограничивает количество транзакций, которые могут произойти в любой момент времени. Децентрализация и безопасность отражает сегодняшнюю цепочку Ethereum.",
  "page-eth2-vision-trilemma-cardtext-3": "Децентрализованные сети работают, отправляя информацию о транзакциях между узлами - вся сеть должна знать о любом изменении состояния. Масштабирование транзакций в секунду в децентрализованной сети создает риски для безопасности, потому что чем больше транзакций, тем дольше задержка, тем выше вероятность атаки во время передачи информации.",
  "page-eth2-vision-trilemma-cardtext-4": "Увеличение размера и мощности узлов Ethereum может увеличить количество транзакций в секунду безопасным способом, но требования к оборудованию ограничат круг лиц, которые могут это сделать - это угрожает децентрализации. Есть надежда, что шардинг и доказательство доли владения позволят Ethereum масштабироваться за счет увеличения количества узлов, а не их размера.",
  "page-eth2-vision-trilemma-h2": "Проблема децентрализованного масштабирования",
  "page-eth2-vision-trilemma-modal-tip": "Коснитесь кружков ниже, чтобы лучше понять проблемы децентрализованного масштабирования",
  "page-eth2-vision-trilemma-p": "Наивный способ решить проблемы Ethereum - сделать его более централизованным. Но децентрализация слишком важна. Именно децентрализация обеспечивает устойчивость Ethereum к цензуре, открытость, конфиденциальность данных и практически нерушимую безопасность.",
  "page-eth2-vision-trilemma-p-1": "Видение Ethereum - быть более масштабируемым и безопасным, но при этом оставаться децентрализованным. Достижение этих трех качеств - проблема, известная как трилемма масштабируемости.",
  "page-eth2-vision-trilemma-p-2": "Обновления Eth2 направлены на решение этой трилеммы, но при этом возникают серьезные проблемы.",
  "page-eth2-vision-trilemma-press-button": "Нажмите кнопки на треугольнике, чтобы лучше понять проблемы децентрализованного масштабирования.",
  "page-eth2-vision-trilemma-text-1": "Децентрализация",
  "page-eth2-vision-trilemma-text-2": "Безопасность",
  "page-eth2-vision-trilemma-text-3": "Масштабируемость",
  "page-eth2-vision-trilemma-title-1": "Изучите трилемму возможности масштабирования",
  "page-eth2-vision-trilemma-title-2": "Обновления Eth2 и децентрализованное масштабирование",
  "page-eth2-vision-trilemma-title-3": "Безопасный и децентрализованный",
  "page-eth2-vision-trilemma-title-4": "Децентрализованный и масштабируемый",
  "page-eth2-vision-trilemma-title-5": "Масштабируемый и безопасный",
  "page-eth2-vision-understanding": "Понимание концепции Eth2",
  "page-eth2-vision-upgrade-needs": "Необходимость в обновлениях Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Протокол Ethereum, запущенный в 2015 году, имел невероятный успех. Но сообщество Ethereum всегда ожидало, что для полного раскрытия потенциала Ethereum потребуется несколько ключевых обновлений.",
  "page-eth2-vision-upgrade-needs-desc-2": "Высокий спрос повышает комиссию за транзакции, которые делают Ethereum дорогим для обычного пользователя. Дисковое пространство, необходимое для запуска клиента Ethereum, растет быстрыми темпами. А лежащий в основе алгоритм консенсуса, который обеспечивает безопасность и децентрализованность Ethereum, имеет большое воздействие на окружающую среду.",
  "page-eth2-vision-upgrade-needs-desc-3": "То, что обычно называют Eth2, представляет собой набор обновлений, которые решают эти и многие другие проблемы. Этот набор обновлений изначально назывался Serenity, и с 2014 года он являются активной областью исследований и разработок.",
  "page-eth2-vision-upgrade-needs-desc-6": " Это значит, что Eth2 невозможно включить. Улучшения будут появляться постепенно.",
  "page-eth2-vision-upgrade-needs-serenity": "Посмотрите запись в блоге 2015 года о Serenity",
  "page-eth2-vision-uprade-needs-desc-5": "Теперь, когда технология готова к использованию, эти обновления перепроектируют архитектуру программного обеспечения Ethereum, чтобы сделать его более масштабируемым, безопасным и экологичным и улучшить работу для существующих пользователей и привлечь новых. Все это при сохранении основной ценности Ethereum - децентрализации.",
  "page-get-eth-cex": "Централизованные биржи",
  "page-get-eth-cex-desc": "Биржи - это компании, которые позволяют покупать криптовалюту за традиционную валюту. Они обладают контролем над любым купленным вами ETH, пока вы не отправите его на кошелек, контролируемый вами.",
  "page-get-eth-checkout-dapps-btn": "Просмотреть децентрализованные приложения",
  "page-get-eth-community-safety": "Записи сообщества о безопасности",
  "page-get-eth-description": "Ethereum и ETH не контролируются каким-либо правительством или компанией - они децентрализованы. Это означает, что ETH может использовать любой человек.",
  "page-get-eth-dex": "Децентрализованные биржи (DEX)",
  "page-get-eth-dex-desc": "Если вы хотите больше контроля, покупайте ETH у физических лиц. С помощью DEX вы можете торговать, не передавая контроль над своими средствами централизованной компании.",
  "page-get-eth-dexs": "Децентрализованные биржи (DEX)",
  "page-get-eth-dexs-desc": "Децентрализованные биржи - открытые торговые площадки для ETH и других токенов. Они напрямую соединяют покупателей и продавцов.",
  "page-get-eth-dexs-desc-2": "Вместо использования доверенных третьих лиц для защиты средств в сделке они используют код. ETH продавца будут переданы только тогда, когда оплата будет гарантирована. Этот тип кода известен как смарт-контракт.",
  "page-get-eth-dexs-desc-3": "Это означает, что существует меньше географических ограничений, чем при использовании централизованных альтернатив. Если кто-то продает то, что вы хотите, и принимает способ оплаты, который вы можете предоставить, то все готово. DEX может позволить вам купить ETH за другие токены, через PayPal или даже за наличные.",
  "page-get-eth-do-not-copy": "ПРИМЕР: НЕ КОПИРОВАТЬ",
  "page-get-eth-exchanges-disclaimer": "Мы собрали эту информацию вручную. Если вы обнаружите ошибки, дайте нам знать на",
  "page-get-eth-exchanges-empty-state-text": "Введите вашу страну проживания, чтобы увидеть список кошельков и бирж, которые можно использовать для покупки ETH",
  "page-get-eth-exchanges-except": "Кроме",
  "page-get-eth-exchanges-header": "В какой стране вы живете?",
  "page-get-eth-exchanges-header-exchanges": "Биржи",
  "page-get-eth-exchanges-header-wallets": "Кошельки",
  "page-get-eth-exchanges-intro": "Биржи и кошельки имеют ограничение по месту продажи.",
  "page-get-eth-exchanges-no-exchanges": "К сожалению, нам неизвестны биржи, позволяющие купить ETH в этой стране. Если вы знаете, то сообщите нам на",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "К сожалению, нам неизвестны биржи или кошельки, позволяющие купить ETH в этой стране. Если вы знаете, то сообщите нам на",
  "page-get-eth-exchanges-no-wallets": "К сожалению, нам неизвестны кошельки, позволяющие купить ETH в этой стране. Если вы знаете, то сообщите нам на",
  "page-get-eth-exchanges-success-exchange": "Регистрация на бирже может занять несколько дней из-за их юридических проверок.",
  "page-get-eth-exchanges-success-wallet-link": "кошельки",
  "page-get-eth-exchanges-success-wallet-paragraph": "В вашей стране вы можете купить ETH напрямую из этих кошельков. Узнайте больше о",
  "page-get-eth-exchanges-usa": "Соединенные Штаты Америки (США)",
  "page-get-eth-get-wallet-btn": "Создать кошелек",
  "page-get-eth-hero-image-alt": "Получить изображение героя ETH",
  "page-get-eth-keep-it-safe": "Сохранение ваших ETH в безопасности",
  "page-get-eth-meta-description": "Как купить ETH в том месте, где вы живете, и советы, как позаботиться о них.",
  "page-get-eth-meta-title": "Как купить ETH",
  "page-get-eth-need-wallet": "Вам понадобится кошелек, чтобы использовать DEX.",
  "page-get-eth-new-to-eth": "Новичок в ETH? Вот обзор, чтобы помочь вам начать.",
  "page-get-eth-other-cryptos": "Купить за другую криптовалюту",
  "page-get-eth-protect-eth-desc": "Если вы планируете купить много ETH, вам, вероятно, понадобится хранить их в подконтрольном вам кошельке, а не на бирже. Дело в том, что биржи являются главной мишенью для хакеров. Если хакер получит доступ, то вы можете потерять все средства. С другой стороны, доступ к вашему кошельку есть только у вас.",
  "page-get-eth-protect-eth-in-wallet": "Защитите ваши ETH в кошельке",
  "page-get-eth-search-by-country": "Найти по стране",
  "page-get-eth-security": "Но это также означает, что вы должны серьёзно относиться к безопасности своих средств. С ETH вы не доверяете банку смотреть за вашими деньгами – вы доверяете только себе.",
  "page-get-eth-smart-contract-link": "Подробнее о смарт-контрактах",
  "page-get-eth-swapping": "Обменяйте ваши токены на ETH. И наоборот.",
  "page-get-eth-traditional-currencies": "Купить за традиционные валюты",
  "page-get-eth-traditional-payments": "Купите ETH через традиционные средства оплаты непосредственно у продавцов.",
  "page-get-eth-try-dex": "Попробовать DEX",
  "page-get-eth-use-your-eth": "Используйте ваши ETH",
  "page-get-eth-use-your-eth-dapps": "Теперь, когда у вас есть ETH, взгляните на несколько приложений Ethereum (dapps). Существуют приложения для финансов, социальных сетей, игр и многого другого.",
  "page-get-eth-wallet-instructions": "Следуйте инструкциям кошелька",
  "page-get-eth-wallet-instructions-lost": "Если вы потеряете доступ к своему кошельку, вы также потеряете доступ и к своим средствам. Ваш кошелек должен дать вам инструкции, чтобы предупредить подобные ситуации. Удостоверьтесь, что вы внимательно следуете им – в большинстве случаев никто не сможет помочь вам в случае потери доступа к своему кошельку.",
  "page-get-eth-wallets": "Кошельки",
  "page-get-eth-wallets-link": "Подробнее о кошельках",
  "page-get-eth-wallets-purchasing": "Некоторые кошельки позволяют вам купить криптовалюту с помощью дебетовой или кредитной карты, банковского перевода или даже Apple Pay. Имеются географические ограничения.",
  "page-get-eth-warning": "Данные DEX не предназначены для начинающих, поскольку для их использования понадобится некоторое количество ETH.",
  "page-get-eth-what-are-DEX's": "Что такое DEX?",
  "page-get-eth-whats-eth-link": "Что такое ETH?",
  "page-get-eth-where-to-buy-desc": "Вы можете купить ETH на биржах или прямо из кошельков.",
  "page-get-eth-where-to-buy-desc-2": "Проверьте, какими службами вы сможете воспользоваться в вашем месте проживания.",
  "page-get-eth-where-to-buy-title": "Где купить ETH",
  "page-get-eth-your-address": "Ваш адрес ETH",
  "page-get-eth-your-address-desc": "Когда вы загружаете кошелек, он создает для вас общедоступный адрес ETH. Вот пример такого адреса:",
  "page-get-eth-your-address-desc-3": "Думайте об этом, как о вашем адресе электронной почты, но вместо сообщений вы получаете ETH. Если вы хотите перевести ETH из биржи в свой кошелек, используйте ваш адрес в качестве точки назначения. Дважды проверьте его перед отправкой!",
  "page-get-eth-your-address-wallet-link": "Просмотреть кошельки",
  "page-index-hero-image-alt": "изображение героя ethereum.org",
  "page-index-meta-description": "Ethereum – глобальная децентрализованная платформа для применения денег и создания новых видов приложений. На Ethereum можно писать код, который управляет деньгами, и создавать приложения, доступные в любой точке мира.",
  "page-index-meta-title": "Главная",
  "page-index-title": "Ethereum – глобальная платформа с открытым исходным кодом для децентрализованных приложений.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Поддержка языков",
  "page-languages-interested": "Хотите внести свой вклад?",
  "page-languages-learn-more": "Узнайте больше о нашей программе перевода",
  "page-languages-meta-desc": "Ресурсы на все поддерживаемые языки ethereum.org и способы участия в качестве переводчика.",
  "page-languages-meta-title": "перевод ethereum.org на различные языки",
  "page-languages-p1": "Ethereum - это глобальный проект, и очень важно, чтобы ethereum.org был доступен для всех, независимо от их национальности или языка. Наше сообщество прилагает все усилия, чтобы воплотить эту концепцию в жизнь.",
  "page-languages-translations-available": "ethereum.org доступен на следующих языках",
  "page-languages-want-more-header": "Хотите просматривать ethereum.org на другом языке?",
  "page-languages-want-more-link": "Программа перевода",
  "page-languages-want-more-paragraph": "Переводчики ethereum.org всегда переводят страницы на как можно большее количество языков. Чтобы посмотреть, над чем они сейчас работают, или зарегистрироваться, чтобы присоединиться к ним, прочитайте о нашем",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Криптозалог",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "С Ethereum вы можете занимать напрямую у других пользователей, не продавая свои ETH. Это может дать вам кредитное плечо - некоторые так поступают, чтобы попытаться накопить больше ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Но из-за волатильности ETH вам нужно избыточное обеспечение. Это означает, что если вы хотите занять 100 стабильных монет, то вам, скорее всего, понадобится как минимум 150 долларов в эквиваленте ETH. Это защищает систему и кредиторов.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Подробнее о криптообеспеченных стабильных монетах",
  "page-stablecoins-accordion-borrow-pill": "Дополнительно",
  "page-stablecoins-accordion-borrow-places-intro": "Эти децентрализованные приложения позволяют вам занимать стабильные монеты, используя криптовалюту в качестве залога. Некоторые из них принимают другие токены, а также ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Места для заимствования стабильных монет",
  "page-stablecoins-accordion-borrow-requirement-1": "Кошелек Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Для пользования приложением dapp вам нужен кошелек",
  "page-stablecoins-accordion-borrow-requirement-2": "Эфир (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Для обеспечения залога и/или транзакционных комиссий вам нужны ETH",
  "page-stablecoins-accordion-borrow-requirements-description": "Чтобы одолжить стабильные монеты, вы должны использовать правильный dapp. Также вам необходимо иметь кошелек и несколько ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Если вы используете ETH в качестве залога и его цена падает, ваш залог не покрывает сгенерированные вами стабильные монеты. Это может привести к тому, что ваши ETH закончатся и что вы будете оштрафованы. Поэтому если вы займете стабильные монеты, вам придется следить за ценой ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Последняя цена ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Риски",
  "page-stablecoins-accordion-borrow-text-preview": "Вы можете позаимствовать немного стабильных монет, используя криптовалюту в качестве залога, который необходимо будет вернуть обратно.",
  "page-stablecoins-accordion-borrow-title": "Кредит",
  "page-stablecoins-accordion-buy-exchanges-title": "Популярные биржи",
  "page-stablecoins-accordion-buy-requirement-1": "Криптовалютные биржи и кошельки",
  "page-stablecoins-accordion-buy-requirement-1-description": "Проверьте, какими службами вы сможете воспользоваться в вашем месте проживания",
  "page-stablecoins-accordion-buy-requirements-description": "Счет на бирже или кошелек, с которого вы можете купить криптовалюту напрямую. Возможно, вы уже пользовались таким для получения ETH. Проверьте, какими службами вы сможете воспользоваться в вашем месте проживания.",
  "page-stablecoins-accordion-buy-text-preview": "Множество бирж и кошельков позволяют напрямую покупать стабильные монеты. Применяются географические ограничения.",
  "page-stablecoins-accordion-buy-title": "Купить",
  "page-stablecoins-accordion-buy-warning": "Централизованные биржи могут котировать только фиатные стабильные монеты, такие как USDC, Tether и другие. Вы не можете купить их напрямую, но вы можете обменять их на ETH или другие криптовалюты, которые можно купить на этой платформе.",
  "page-stablecoins-accordion-earn-project-1-description": "В основном техническая работа для перехода на программное обеспечение с открытым исходным кодом.",
  "page-stablecoins-accordion-earn-project-2-description": "Технология, материалы и другая работа для сообщества MakerDao (команда, которая дала вам Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Если вы действительно знаете свое дело, найдите ошибки, чтобы заработать Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Награды Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Награды за ошибки Eth2",
  "page-stablecoins-accordion-earn-project-community": "Сообщество MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Это платформы, которые заплатят вам в стабильных монетах за вашу работу.",
  "page-stablecoins-accordion-earn-projects-title": "Где заработать стабильные монеты",
  "page-stablecoins-accordion-earn-requirement-1": "Кошелек Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Вам понадобится кошелек для получения заработанных стабильных монет",
  "page-stablecoins-accordion-earn-requirements-description": "Стабильные монеты - это великолепный способ оплаты работы и услуг, потому что их стоимость стабильна. Но вам понадобится кошелек для получения оплаты.",
  "page-stablecoins-accordion-earn-text-preview": "Вы можете заработать стабильные монеты, работая над проектами в рамках экосистемы Ethereum.",
  "page-stablecoins-accordion-earn-title": "Заработать",
  "page-stablecoins-accordion-less": "Меньше",
  "page-stablecoins-accordion-more": "Больше",
  "page-stablecoins-accordion-requirements": "Что вам потребуется",
  "page-stablecoins-accordion-swap-dapp-intro": "Если у вас уже есть ETH и кошелек, вы можете использовать эти децентрализованные приложения, чтобы обменивать свои средства на стабильные монеты.",
  "page-stablecoins-accordion-swap-dapp-link": "Подробнее о децентрализованных биржах",
  "page-stablecoins-accordion-swap-dapp-title": "Децентрализованные приложения для обмена токенов",
  "page-stablecoins-accordion-swap-editors-tip": "Совет редакции",
  "page-stablecoins-accordion-swap-editors-tip-button": "Найти кошельки",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Заведите себе кошелек, который позволит вам купить ETH и обменять их на токены, в том числе стабильные монеты, напрямую.",
  "page-stablecoins-accordion-swap-pill": "Рекомендовано",
  "page-stablecoins-accordion-swap-requirement-1": "Кошелек Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Вам понадобится кошелек для авторизации обмена и хранения ваших монет",
  "page-stablecoins-accordion-swap-requirement-2": "Эфир (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Заплатить за обмен",
  "page-stablecoins-accordion-swap-text-preview": "Вы можете купить большинство стабильных монет на децентрализованных биржах. Таким образом, вы можете обменять любые свои токены на нужные вам стабильные монеты.",
  "page-stablecoins-accordion-swap-title": "Обменять",
  "page-stablecoins-algorithmic": "Алгоритмический",
  "page-stablecoins-algorithmic-con-1": "Вам нужно доверять (или иметь возможность читать) алгоритм.",
  "page-stablecoins-algorithmic-con-2": "Ваш баланс монет изменится в зависимости от общего предложения.",
  "page-stablecoins-algorithmic-description": "Эти стабильные монеты не обеспечены никакими другими активами. Вместо этого алгоритм будет продавать токены, если цена упадет ниже желаемой стоимости, и покупать токены, если стоимость выйдет за пределы желаемой суммы. Поскольку количество этих токенов в обороте регулярно меняется, количество ваших токенов будет меняться, но всегда будет отражать вашу долю.",
  "page-stablecoins-algorithmic-pro-1": "Залога не требуется.",
  "page-stablecoins-algorithmic-pro-2": "Контролируется публичным алгоритмом.",
  "page-stablecoins-bank-apy": "0,05 %",
  "page-stablecoins-bank-apy-source": "Средняя ставка, выплачиваемая банками на основных застрахованных государством сберегательных счетах в США.",
  "page-stablecoins-bank-apy-source-link": "Источник",
  "page-stablecoins-bitcoin-pizza": "The Infamous Bitcoin Pizza",
  "page-stablecoins-bitcoin-pizza-body": "В 2010 году кто-то купил две пиццы за 10 000 биткоинов. В то время они стоили около 41 доллара. На сегодняшний день это миллионы долларов. В истории Ethereum бывали и другие транзакции, о которых люди жалели. Стабильные монеты решают эту проблему, так что вы можете наслаждаться пиццей и хранить свои ETH.",
  "page-stablecoins-coin-price-change": "Изменение цены монет (за последние 30 дней)",
  "page-stablecoins-crypto-backed": "Обеспечено криптовалютой",
  "page-stablecoins-crypto-backed-con-1": "Менее стабильные, чем фиатные стабильные монеты.",
  "page-stablecoins-crypto-backed-con-2": "Вам нужно следить за стоимостью криптовалютного залога.",
  "page-stablecoins-crypto-backed-description": "Эти стабильные монеты обеспечены другими криптовалютными активами, такими как ETH. Их цена зависит от стоимости лежащего в их основе актива (или залога), который может быть волатильным. Так как цена ETH может колебаться, эти стабильные монеты имеют избыточное обеспечение и гарантируют, что цена останется как можно более стабильной. Таким образом, можно сказать, что стабильная монета, обеспеченная криптовалютой на один доллар США, имеет базовый криптовалютный актив стоимостью не менее двух долларов США. Поэтому если цена ETH падает, то для обеспечения стабильной монеты потребуется больше ETH, иначе она потеряет свою ценность.",
  "page-stablecoins-crypto-backed-pro-1": "Прозрачный и полностью децентрализованный.",
  "page-stablecoins-crypto-backed-pro-2": "Быстро превратить в другие криптовалютные активы.",
  "page-stablecoins-crypto-backed-pro-3": "Никаких внешних хранителей – все активы контролируются счетами Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai - пожалуй, самая популярная децентрализованная стабильная монета. Ее стоимость составляет около доллара, и она принимается повсеместно среди децентрализованных приложений.",
  "page-stablecoins-dai-banner-learn-button": "Подробнее о Dai",
  "page-stablecoins-dai-banner-swap-button": "Обменять ETH на Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Логотип Dai",
  "page-stablecoins-editors-choice": "Выбор редакции",
  "page-stablecoins-editors-choice-intro": "Вероятно, это наиболее известные примеры стабильных монет прямо сейчас и монеты, которые мы посчитали полезными при использовании децентрализованных приложений.",
  "page-stablecoins-explore-dapps": "Просмотреть децентрализованные приложения",
  "page-stablecoins-fiat-backed": "Фиатный",
  "page-stablecoins-fiat-backed-con-1": "Централизованные – кто-то должен выдавать токены.",
  "page-stablecoins-fiat-backed-con-2": "Требует проведения аудита для обеспечения наличия у компании достаточных резервов.",
  "page-stablecoins-fiat-backed-description": "В основном ЯДВ (я должен вам) для традиционной фиатной валюты (обычно долларов). Вы пользуетесь фиатной валютой для покупки стабильной валюты и последующего обмена на свою первоначальную валюту.",
  "page-stablecoins-fiat-backed-pro-1": "Защищена от волатильности криптовалют.",
  "page-stablecoins-fiat-backed-pro-2": "Изменения в цене минимальны.",
  "page-stablecoins-find-stablecoin": "Найти стабильные монеты",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Как получить стабильные монеты",
  "page-stablecoins-find-stablecoin-intro": "Доступны сотни стабильных монет. Вот некоторые из них, которые вам помогут начать. Если вы новичок в Ethereum, мы рекомендуем сначала поискать нужную информацию.",
  "page-stablecoins-find-stablecoin-types-link": "Различные типы стабильных монет",
  "page-stablecoins-get-stablecoins": "Как получить стабильные монеты",
  "page-stablecoins-hero-alt": "Три крупнейшие стабильные монеты по рыночной капитализации: Dai, USDC и Tether.",
  "page-stablecoins-hero-button": "Получить стабильные монеты",
  "page-stablecoins-hero-header": "Цифровые деньги для ежедневного использования",
  "page-stablecoins-hero-subtitle": "Стабильные монеты - это токены Ethereum с постоянной фиксированной стоимостью, даже когда цена ETH меняется.",
  "page-stablecoins-interest-earning-dapps": "Децентрализованные приложения для зарабатывания процентов",
  "page-stablecoins-meta-description": "Введение в стабильные монеты Ethereum: что они представляют собой, как их получить и почему они важны.",
  "page-stablecoins-precious-metals": "Драгоценные металлы",
  "page-stablecoins-precious-metals-con-1": "Централизованные – кто-то должен выдавать токены.",
  "page-stablecoins-precious-metals-con-2": "Вам нужно доверять источнику токенов и резервам драгоценных металлов.",
  "page-stablecoins-precious-metals-description": "Подобно фиатным монетам, эти стабильные монеты используют такие ресурсы, как золото, чтобы сохранить свою ценность.",
  "page-stablecoins-precious-metals-pro-1": "Защищена от волатильности криптовалют.",
  "page-stablecoins-prices": "Цены на стабильные монеты",
  "page-stablecoins-prices-definition": "Стабильные монеты - это криптовалюты без волатильности. Они имеют много тех же сильных сторон, что и ETH, но их стоимость устойчива, как у традиционной валюты. Так что у вас есть доступ к стабильным деньгам, которые вы можете использовать на Ethereum. ",
  "page-stablecoins-prices-definition-how": "Как стабильные монеты получают свою стабильность",
  "page-stablecoins-research-warning": "Ethereum является новой технологией, и большинство приложений являются новыми. Прежде чем вложить большое количество денег, убедитесь, что вы понимаете риски.",
  "page-stablecoins-research-warning-title": "Всегда ищите информацию самостоятельно",
  "page-stablecoins-save-stablecoins": "Копите со стабильными монетами",
  "page-stablecoins-save-stablecoins-body": "Стабильные монеты часто имеют процентную ставку выше среднего, поскольку существует большой спрос на них. Существуют децентрализованные приложения, которые позволяют заработать проценты с вашими стабильными монетами в режиме реального времени, внося их в кредитный фонд. Как и в банковском мире, вы выдаете токены заемщикам, но вы можете снять свои токены и свои проценты в любое время.",
  "page-stablecoins-saving": "Извлеките выгоду из своих накопленных стабильных монет и заработайте проценты. Как и все в криптовалюте, прогнозируемая годовая процентная выручка (APY) может меняться каждый день в зависимости от спроса и предложения в реальном времени.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Ознакомьтесь с децентрализованными приложениями Ethereum – стабильные монеты часто более удобны для ежедневных транзакций.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Иллюстрация собаки.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Используйте ваши стабильные монеты",
  "page-stablecoins-stablecoins-dapp-description-1": "Рынки с большим количеством стабильных монет, включая Dai, USDC, TUSD, USDT и другие. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Одалживайте стабильные монеты и зарабатывайте проценты и $COMP, собственный токен платформы Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Торговая платформа, где вы можете заработать проценты на свои Dai и USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Приложение, разработанное для накопления Dai.",
  "page-stablecoins-stablecoins-feature-1": "Стабильные монеты глобальны, и их можно отправить по Интернету. Их легко получить или отправить, если у вас есть счет Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "Спрос на стабильные монеты высок, поэтому вы можете заработать проценты на выдаче займов. Перед выдачей займов убедитесь, что вы знаете о рисках.",
  "page-stablecoins-stablecoins-feature-3": "Стабильные монеты можно обменять на ETH и другие токены Ethereum. Множество децентрализованных приложений полагаются на стабильные монеты.",
  "page-stablecoins-stablecoins-feature-4": "Стабильные монеты защищены криптографией. Никто не может производить транзакции от вашего имени.",
  "page-stablecoins-stablecoins-meta-description": "Введение в стабильные монеты Ethereum: что они представляют собой, как их получить и почему они важны.",
  "page-stablecoins-stablecoins-table-header-column-1": "Валюта",
  "page-stablecoins-stablecoins-table-header-column-2": "Рыночная капитализация",
  "page-stablecoins-stablecoins-table-header-column-3": "Тип залога",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Крипто",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Фиат",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Драгоценные металлы",
  "page-stablecoins-table-error": "Не удалось загрузить стабильные монеты. Попробуйте обновить страницу.",
  "page-stablecoins-table-loading": "Загрузка данных о стабильных монетах...",
  "page-stablecoins-title": "Стабильные монеты",
  "page-stablecoins-top-coins": "Список стабильных монет с максимальной рыночной капитализацией",
  "page-stablecoins-top-coins-intro": "Рыночная капитализация - это",
  "page-stablecoins-top-coins-intro-code": "общее количество существующих токенов, умноженное на значение на токен. Этот список часто меняется, и перечисленные проекты не обязательно поддерживаются командой ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Как они работают: типы стабильных монет",
  "page-stablecoins-usdc-banner-body": "USDC - пожалуй, самая популярная фиатная стабильная монета. Ее ценность составляет около доллара, и она поддерживается компаниями Circle и Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Узнайте больше о USDC",
  "page-stablecoins-usdc-banner-swap-button": "Обменять ETH на USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Логотип USDC",
  "page-stablecoins-why-stablecoins": "Почему стабильные монеты?",
  "page-stablecoins-how-they-work-button": "Как они работают",
  "page-stablecoins-why-stablecoins-body": "ETH, как и Bitcoin, отличается волатильностью, потому что это новая технология. Поэтому, возможно, вы не станете регулярно тратить их. Стабильные монеты отражают стоимость традиционных валют и предоставляют вам доступ к стабильным деньгам, которыми вы можете пользоваться на Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Если вы хотите, чтобы мы добавили кошелек,",
  "page-find-wallet-alpha-logo-alt": "Логотип AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Логотип Ambo",
  "page-find-wallet-argent-logo-alt": "Логотип Argent",
  "page-find-wallet-buy-card": "Купить криптовалюту с карты",
  "page-find-wallet-buy-card-desc": "Покупайте ETH прямо из кошелька с помощью банковской карты. Могут применяться географические ограничения.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Просмотрите децентрализованные приложения",
  "page-find-wallet-clear": "Очистить фильтры",
  "page-find-wallet-coinbase-logo-alt": "Логотип Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Выберите ваш кошелек, основываясь на функциях, которые вам нужны.",
  "page-find-wallet-description": "Кошельки имеют множество дополнительных функций, которые могут вам понравиться.",
  "page-find-wallet-description-alpha": "Кошелек Ethereum с полностью открытым исходным кодом, который обеспечивает защищенную область на мобильных устройствах, предлагает полную поддержку тестовой сети и включает стандарт TokenScript.",
  "page-find-wallet-description-ambo": "Начинайте инвестировать и сделайте свои первые инвестиции через несколько минут после загрузки приложения",
  "page-find-wallet-description-argent": "Одно касание для того, чтобы зарабатывать проценты и инвестировать; зарабатывать, хранить и отправлять. Владейте своими средствами.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Безопасное приложение для хранения криптовалюты у себя",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma — это ваш портал в DeFi. Больше никаких исходных фраз, больше никаких дополнений для Chrome.",
  "page-find-wallet-description-enjin": "Непроницаемый, функциональный и удобный — создан для трейдеров, геймеров и разработчиков",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Самая надежная платформа для хранения цифровых активов на Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken — это простой и безопасный цифровой кошелек, которому доверяют миллионы людей",
  "page-find-wallet-description-ledger": "Защитите свои активы с наивысшими стандартами безопасности",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Начните изучать приложения для блокчейна за считанные секунды. Нам доверяют более 1 миллиона пользователей по всему миру.",
  "page-find-wallet-description-monolith": "Единственный в мире кошелек для самостоятельного хранения, связанный с дебетовыми картами Visa. Доступен в Великобритании и ЕС и может использоваться во всем мире.",
  "page-find-wallet-description-multis": "Multis — это криптовалютный счет, предназначенный для компаний. С помощью Multis компании могут хранить свои средства с системой контроля доступа, получать проценты по своим сбережениям, а также упорядочивать платежи и бухгалтерский учет.",
  "page-find-wallet-description-mycrypto": "MyCrypto — это интерфейс для управления всеми вашими счетами. Обменивайте, отправляйте и покупайте криптовалюты с кошельками, такими как MetaMask, Ledger, Trezor и многими другими.",
  "page-find-wallet-description-myetherwallet": "Бесплатный клиентский интерфейс, помогающий вам взаимодействовать с блокчейном Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Принадлежащий сообществу кошелек не для хранения средств со своей собственной платежной сетью L2.",
  "page-find-wallet-description-portis": "Кошелек блокчейна не для хранения средств, который упрощает работу с приложениями для всех",
  "page-find-wallet-description-rainbow": "Лучший дом для ваших активов в Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Защищенное приложение для обмена сообщениями, криптовалютный кошелек и браузер Web3 с самой современной технологией",
  "page-find-wallet-description-tokenpocket": "TokenPocket - самый защищенный и удобный в мире кошелек для цифровых валют и портал в децентрализованные приложения с поддержкой нескольких цепочек.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Вход в систему одним щелчком для Web 3.0",
  "page-find-wallet-description-trezor": "Первый и оригинальный аппаратный кошелек",
  "page-find-wallet-description-trust": "Trust Wallet — децентрализованный мультикриптовалютный кошелек. Покупайте криптовалюту, открывайте новые децентрализованные приложения, обменивайте активы и многое другое, при этом контролируя свои ключи.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo — это первый криптовалютный кошелек без ключей. С ZenGo нет приватных ключей, паролей или исходных фраз, которыми нужно управлять и которые можно потерять. Покупайте, продавайте, зарабатывайте и храните ETH с беспрецедентной простотой и безопасностью",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Логотип Dharma",
  "page-find-wallet-enjin-logo-alt": "Логотип Enjin",
  "page-find-wallet-Ethereum-wallets": "Кошельки Ethereum",
  "page-find-wallet-explore-dapps": "Просмотреть децентрализованные приложения",
  "page-find-wallet-explore-dapps-desc": "Эти кошельки предназначены для того, чтобы помочь вам подключиться к децентрализованным приложениям Ethereum.",
  "page-find-wallet-feature-h2": "Выберите интересующие вас функции кошелька",
  "page-find-wallet-fi-tools": "Доступ к финансовым инструментам",
  "page-find-wallet-fi-tools-desc": "Занимайте, кредитуйте и зарабатывайте проценты прямо из вашего кошелька.",
  "page-find-wallet-following-features": "со следующими функциями:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Логотип Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Найти изображение героя кошелька",
  "page-find-wallet-imtoken-logo-alt": "Логотип imToken",
  "page-find-wallet-last-updated": "Последнее обновление",
  "page-find-wallet-ledger-logo-alt": "Логотип Ledger",
  "page-find-wallet-limits": "Защита лимитами",
  "page-find-wallet-limits-desc": "Защитите свои активы, задав лимиты, предотвращающие опустошение вашего счета.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "политика листинга",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Найдите и сравните кошельки Ethereum на основе функций, которые вам нужны.",
  "page-find-wallet-meta-title": "Найти кошелек Ethereum",
  "page-find-wallet-metamask-logo-alt": "Логотип MetaMask",
  "page-find-wallet-monolith-logo-alt": "Логотип Monolith",
  "page-find-wallet-multis-logo-alt": "Логотип Multis",
  "page-find-wallet-multisig": "Счета с несколькими подписями",
  "page-find-wallet-multisig-desc": "Для дополнительной безопасности кошельки с несколькими подписями требуют более одного счета для авторизации определенных транзакций.",
  "page-find-wallet-mycrypto-logo-alt": "Логотип MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Логотип MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Не разбираетесь в кошельках? Вот обзор, чтобы помочь вам начать работать с ними.",
  "page-find-wallet-new-to-wallets-link": "Кошельки Ethereum",
  "page-find-wallet-not-all-features": "Нет кошелька со всеми этими функциями",
  "page-find-wallet-not-endorsements": "Список кошельков, перечисленных на этой странице, не одобрен официально и предоставляется только в информационных целях. Их описания были предоставлены самими компаниями-разработчиками кошельков. Мы добавляем продукты на эту страницу на основе критериев в нашей",
  "page-find-wallet-overwhelmed": "кошельки Ethereum ниже. Слишком много? Попробуйте отфильтровать кошельки по функциям.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Логотип Pillar",
  "page-find-wallet-portis-logo-alt": "Логотип Portis",
  "page-find-wallet-rainbow-logo-alt": "Логотип Rainbow",
  "page-find-wallet-raise-an-issue": "поднять вопрос в GitHub",
  "page-find-wallet-search-btn": "Искать выбранные функции",
  "page-find-wallet-showing": "Показаны ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Логотип Status",
  "page-find-wallet-swaps": "Обмен децентрализованными токенами",
  "page-find-wallet-swaps-desc": "Обменивайте ETH на другие токены и наоборот прямо из своего кошелька.",
  "page-find-wallet-title": "Найти кошелек",
  "page-find-wallet-tokenpocket-logo-alt": "Логотип TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Логотип Torus",
  "page-find-wallet-trezor-logo-alt": "Логотип Trezor",
  "page-find-wallet-trust-logo-alt": "Логотип Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Попробуйте удалить одну или две функции",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Теперь, когда у вас есть кошелек, взгляните на несколько децентрализованных приложений Ethereum. Существуют приложения для финансов, социальных сетей, игр и многого другого.",
  "page-find-wallet-use-your-wallet": "Воспользуйтесь своим кошельком",
  "page-find-wallet-voluem-desc": "Если вы хотите держать много ETH, выберите кошелек, который позволит вам купить более $2000 ETH за один раз.",
  "page-find-wallet-volume": "Крупные покупки",
  "page-find-wallet-we-found": "Мы нашли",
  "page-find-wallet-withdraw": "Вывести средства в банк",
  "page-find-wallet-withdraw-desc": "Вы можете вывести свои ETH прямо на свой банковский счет, не пользуясь услугами биржи.",
  "page-find-wallet-yet": "еще",
  "page-find-wallet-zengo-logo-alt": "Логотип ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Вложить ETH",
  "page-wallets-accounts-addresses": "Кошельки, счета и адреса",
  "page-wallets-accounts-addresses-desc": "Важно понять различия между некоторыми ключевыми терминами.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Иллюстрация робота с хранилищем для тела, представляющего кошелек Ethereum",
  "page-wallets-ethereum-account": "Счет Ethereum",
  "page-wallets-blog": "Блог Coinbase",
  "page-wallets-bookmarking": "Добавьте свой кошелек в закладки",
  "page-wallets-bookmarking-desc": "Если вы пользуетесь веб-кошельком, добавьте этот сайт в закладки, чтобы защитить себя от фишинговых атак.",
  "page-wallets-cd": "Физические аппаратные кошельки, которые позволяют хранить криптовалюту в оффлайн-режиме, обеспечивают высокую безопасность",
  "page-wallets-converted": "Конвертировали средства в криптовалюту?",
  "page-wallets-converted-desc": "Если вы хотите хранить большие средства, мы рекомендуем аппаратный кошелек, так как он наиболее безопасен. Или кошелек с предупреждениями о мошенничестве и ограничениями на снятие средств.",
  "page-wallets-curious": "Интересуетесь криптовалютой?",
  "page-wallets-curious-desc": "Если вы новичок в криптовалюте и просто хотите понять, что это такое, мы рекомендуем что-то, что даст вам возможность изучить приложения Ethereum или купить свои первые ETH прямо из кошелька.",
  "page-wallets-desc-2": "Вам нужен кошелек для отправки средств и управления своими ETH.",
  "page-wallets-desc-2-link": "Подробнее о ETH",
  "page-wallets-desc-3": "Ваш кошелек - это всего лишь инструмент для управления вашим счетом Ethereum. Это означает, что вы можете поменять поставщика кошелька в любое время. Многие кошельки также позволяют управлять несколькими счетами Ethereum из одного приложения.",
  "page-wallets-desc-4": "Это потому, что кошельки не обеспечивают сохранность ваших средств. Этим занимаетесь вы. Они всего лишь инструмент для управления тем, что на самом деле принадлежит вам.",
  "page-wallets-description": "Кошельки Ethereum - это приложения, которые позволяют вам работать с вашим счетом Ethereum. Думайте об этом как о приложении для интернет-банкинга - без банка. Ваш кошелек позволяет вам считывать свой баланс, производить транзакции и подключаться к приложениям.",
  "page-wallets-desktop": "Настольные приложения, если вы предпочитаете управлять своими средствами в MacOS, Windows или Linux",
  "page-wallets-ethereum-wallet": "Кошелек",
  "page-wallets-explore": "Изучите Ethereum",
  "page-wallets-features-desc": "Мы можем помочь вам выбрать кошелек на основе тех функций, которые вам интересны.",
  "page-wallets-features-title": "Предпочитаете выбирать по функциям?",
  "page-wallets-find-wallet-btn": "Найти кошелек",
  "page-wallets-find-wallet-link": "Найдите кошелек",
  "page-wallets-get-some": "Получите ETH",
  "page-wallets-get-some-alt": "Иллюстрация руки, создающей логотип ETH из кубиков Lego",
  "page-wallets-get-some-btn": "Получите ETH",
  "page-wallets-get-some-desc": "ETH - это собственная криптовалюта Ethereum. Для использования приложений Ethereum вам понадобится немного ETH в вашем кошельке.",
  "page-wallets-get-wallet": "Создать кошелек",
  "page-wallets-get-wallet-desc": "Есть много разных кошельков на выбор. Мы хотим помочь вам выбрать лучший вариант.",
  "page-wallets-get-wallet-desc-2": "Помните: это решение не навсегда - ваш счет Ethereum не привязан к поставщику кошелька.",
  "page-wallets-how-to-store": "Как хранить цифровые активы на Ethereum",
  "page-wallets-keys-to-safety": "Ключи для сохранения в безопасности вашей криптовалюты",
  "page-wallets-manage-funds": "Приложение для управления вашими средствами",
  "page-wallets-manage-funds-desc": "Ваш кошелек показывает ваш баланс, историю транзакций и дает вам возможность отправлять/получать средства. Некоторые кошельки могут предлагать больше функций.",
  "page-wallets-meta-description": "Что нужно знать, чтобы пользоваться кошельками Ethereum.",
  "page-wallets-meta-title": "Кошельки Ethereum",
  "page-wallets-mobile": "Мобильные приложения, которые делают ваши средства доступными из любого места",
  "page-wallets-more-on-dapps-btn": "Подробнее о Dapps",
  "page-wallets-most-wallets": "Большинство кошельков позволяют создать счет Ethereum. Так что он вам не понадобится до загрузки кошелька.",
  "page-wallets-protecting-yourself": "Защитите себя и свои средства",
  "page-wallets-seed-phrase": "Запишите свою исходную фразу",
  "page-wallets-seed-phrase-desc": "Кошельки часто дают вам исходную фразу, которую вы должны записать в безопасном месте. Только так вы сможете восстановить свой кошелек.",
  "page-wallets-seed-phrase-example": "Вот пример:",
  "page-wallets-seed-phrase-snippet": "есть форма кривой самолетной вентиляции, возможно другой продукт под духовой лампой",
  "page-wallets-seed-phrase-write-down": "Не храните ее на компьютере. Запишите вручную и держите в безопасном месте.",
  "page-wallets-slogan": "Ключ к вашему цифровому будущему",
  "page-wallets-stay-safe": "Как оставаться в безопасности",
  "page-wallets-stay-safe-desc": "Кошельки немного изменяют мышление. Финансовая свобода и возможность доступа и использования средств в любом месте сопряжены с некоторой ответственностью - в криптографии нет службы поддержки клиентов.",
  "page-wallets-subtitle": "Кошельки предоставляют доступ к вашим средствам и приложениям Ethereum. Только вы должны иметь доступ к вашему кошельку.",
  "page-wallets-take-responsibility": "Возьмите на себя ответственность за собственные средства",
  "page-wallets-take-responsibility-desc": "Централизованные биржи свяжут ваш кошелек с именем пользователя и паролем, которые вы можете восстановить традиционным способом. Просто помните, что вы доверяете этой бирже хранение своих средств. Если эта компания подвергнется нападению или свернется, ваши средства окажутся под угрозой.",
  "page-wallets-tips": "Дополнительные советы по обеспечению безопасности",
  "page-wallets-tips-community": "От сообщества",
  "page-wallets-title": "Кошельки Ethereum",
  "page-wallets-triple-check": "Перепроверяйте все трижды",
  "page-wallets-triple-check-desc": "Помните, что транзакцию нельзя отменить, а восстановить кошелек очень сложно. Будьте осторожны.",
  "page-wallets-try-dapps": "Попробуйте несколько децентрализованных приложений",
  "page-wallets-try-dapps-alt": "Рисунок членов сообщества Ethereum, работающих вместе",
  "page-wallets-try-dapps-desc": "Децентрализованные приложения - это приложения, построенные на Ethereum. Они дешевле, справедливее и удобнее для ваших данных, чем большинство традиционных приложений.",
  "page-wallets-types": "Типы кошельков",
  "page-wallets-web-browser": "Веб-кошельки, позволяющие пользоваться своим счетом через браузер",
  "page-wallets-whats-a-wallet": "Что такое Ethereum-кошелек?",
  "page-wallets-your-ethereum-account": "Ваш счет Ethereum",
  "page-wallets-your-ethereum-account-desc": "Кошелек — это ваше окно в счет Ethereum: в баланс, историю транзакций и многое другое. Но перейти на другой кошелек можно в любой момент.",
  "page-wallets-your-login": "Ваше имя пользователя для приложений Ethereum",
  "page-wallets-your-login-desc": "Ваш кошелек позволяет вам подключаться к любому децентрализованному приложению с помощью вашего счета Ethereum. Это похоже на имя пользователя, которым можно пользоваться во многих децентрализованных приложениях.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum - это технология, которая позволяет вам отправлять криптовалюту абсолютно всем за небольшую комиссию. Она также поддерживает приложения, которые может использовать каждый и которые никто не сможет удалить.",
  "page-what-is-ethereum-101-desc-2": "Ethereum строится на инновациях Bitcoin с некоторыми существенными отличиями.",
  "page-what-is-ethereum-101-desc-3": "Обе технологии позволяют вам использовать цифровые деньги без поставщиков платежных услуг или банков. Но Ethereum является программируемым, так что вы также можете использовать его для множества различных цифровых активов, даже Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "Это также означает, что Ethereum - это больше, чем просто платежи. Это целая торговая площадка, предназначенная для финансовых услуг, игр и приложений, которые не могут украсть ваши данные или подвергнуть вас цензуре.",
  "page-what-is-ethereum-101-italic": "всемирный программируемый блокчейн.",
  "page-what-is-ethereum-101-strong": "Это ",
  "page-what-is-ethereum-accessibility": "Ethereum открыт для каждого.",
  "page-what-is-ethereum-adventure": "Выбирайте свое приключение!",
  "page-what-is-ethereum-alt-img-bazaar": "Изображение человека, всматривающегося в базар, призвано быть олицетворением Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Рисунок членов сообщества Ethereum, работающих вместе",
  "page-what-is-ethereum-alt-img-lego": "Иллюстрация руки, создающей логотип ETH из кубиков Lego",
  "page-what-is-ethereum-alt-img-social": "Изображение персонажей в социальном пространстве, посвященном Ethereum, с большим логотипом ETH",
  "page-what-is-ethereum-banking-card": "Банковское обслуживание для каждого",
  "page-what-is-ethereum-banking-card-desc": "Не у каждого есть доступ к финансовым услугам. Но все, что вам нужно для доступа к Ethereum и его продуктам кредитования, займа и сбережений - это подключение к Интернету.",
  "page-what-is-ethereum-build": "Создавайте с Ethereum",
  "page-what-is-ethereum-build-desc": "Если вы хотите попробовать разрабатывать приложения на Ethereum, прочтите нашу документацию, попробуйте несколько руководств или ознакомьтесь с инструментами, необходимыми для начала работы.",
  "page-what-is-ethereum-censorless-card": "Устойчивость к цензуре",
  "page-what-is-ethereum-censorless-card-desc": "Ни правительство, ни компании не могут контролировать Ethereum. Децентрализация делает практически невозможным запрет на получение вами платежей или использование услуг Ethereum.",
  "page-what-is-ethereum-comm-desc": "В наше сообщество входят люди из всех слоев общества, включая художников, криптоанархистов, компании из списка Fortune 500, а теперь и вас. Узнайте, как вы можете принять участие в этом проекте сегодня.",
  "page-what-is-ethereum-commerce-card": "Коммерческие гарантии",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum создает более равные условия. У клиентов есть неотъемлемая гарантия того, что средства перейдут из рук в руки только в том случае, если вы предоставите свое согласие. Для ведения бизнеса не потребуется влияние крупной компании.",
  "page-what-is-ethereum-community": "Сообщество Ethereum",
  "page-what-is-ethereum-compatibility-card": "Совместимость для победы",
  "page-what-is-ethereum-compatibility-card-desc": "Более качественные продукты и услуги создаются на постоянной основе, так как продукты Ethereum совместимы по умолчанию. Компании могут строиться на успехе друг друга.",
  "page-what-is-ethereum-dapps-desc": "Продукты и услуги, которые работают на Ethereum. Это децентрализованные приложения для финансов, работы, социальных сетей, игр и многого другого - познакомьтесь с приложениями для нашего цифрового будущего.",
  "page-what-is-ethereum-dapps-img-alt": "Изображение песика, пользующегося приложением Ethereum на компьютере",
  "page-what-is-ethereum-dapps-title": "Децентрализованные приложения Ethereum",
  "page-what-is-ethereum-desc": "Фундамент нашего цифрового будущего",
  "page-what-is-ethereum-explore": "Изучите Ethereum",
  "page-what-is-ethereum-get-started": "Лучший способ узнать больше - загрузить кошелек, приобрести немного ETH и попробовать децентрализованное приложение Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum - это открытый доступ к цифровым деньгам и службам предоставления данных для всех, вне зависимости от вашего происхождения и местоположения. Это созданная сообществом технология, лежащая в основе криптовалюты ether (ETH) и тысяч приложений, которыми вы можете пользоваться сегодня.",
  "page-what-is-ethereum-internet-card": "Более частный Интернет",
  "page-what-is-ethereum-internet-card-desc": "Вам не нужно предоставлять все ваши персональные данные, чтобы пользоваться приложением Ethereum. Ethereum строит экономику, основанную на ценности, а не на надзоре.",
  "page-what-is-ethereum-meet-comm": "Познакомьтесь с сообществом",
  "page-what-is-ethereum-meta-description": "Узнайте больше об Ethereum, о его функциях и о том, как попробовать это самим.",
  "page-what-is-ethereum-meta-title": "Что такое Ethereum?",
  "page-what-is-ethereum-native-alt": "Эмблема ether (ETH)",
  "page-what-is-ethereum-native-crypto": "Собственная криптовалюта Ethereum и эквивалент Bitcoin. Вы можете использовать ETH в приложениях Ethereum или для отправки средств друзьям и семье.",
  "page-what-is-ethereum-native-img-alt": "Изображение робота с торсом в виде сейфа, используемое для обозначения кошельков Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Пиринговая сеть",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum позволяет вам переводить деньги или заключать соглашения напрямую с кем-то еще. Вам не нужно проходить через компании-посредники.",
  "page-what-is-ethereum-singlecard-desc": "Если вам интересны блокчейн и техническая сторона Ethereum, мы вам поможем.",
  "page-what-is-ethereum-singlecard-link": "Как работает Ethereum",
  "page-what-is-ethereum-singlecard-title": "Как работает Ethereum",
  "page-what-is-ethereum-start-building-btn": "Приступить к созданию приложений",
  "page-what-is-ethereum-title": "Что такое Ethereum?",
  "page-what-is-ethereum-tools-needed": "Все, что вам нужно для участия в проекте - кошелек.",
  "page-what-is-ethereum-try": "Попробуйте Ethereum",
  "page-what-is-ethereum-tryit": "Так что войдите на базар и осмотритесь вокруг...",
  "page-what-is-ethereum-wallets": "Кошельки",
  "page-what-is-ethereum-wallets-desc": "Как управлять своими ETH и счетом Ethereum. Для начала вам понадобится кошелек. Мы поможем вам выбрать его.",
  "page-what-is-ethereum-welcome": "Добро пожаловать в Ethereum",
  "page-what-is-ethereum-welcome-2": "Надеемся, вам у нас понравится.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Теперь доступны вложения! Если вы хотите вложить свои ETH",
  "banner-staking-2": "подтверждение адреса контракта на депозит",
  "docking": "Стыковка",
  "page-eth2-vision-security-desc-9": "позволяет нам случайным образом назначать валидаторов разным осколкам - это делает практически невозможным сговор валидаторов для атаки определенного осколка. Шардинг не так безопасен в блокчейне с подтверждением работы, потому что протокол не может управлять майнерами таким образом.",
  "page-index-sections-developers-desc": "Узнайте о технологиях, лежащих в основе Ethereum и его приложений, чтобы начать создавать приложения с ним.",
  "page-index-sections-developers-image-alt": "Иллюстрация руки, собирающей символ Ethereum из кирпичиков Lego",
  "page-index-sections-developers-link-text": "Приступить к созданию приложений",
  "page-index-sections-developers-title": "Разработчикам",
  "page-index-sections-enterprise-desc": "Узнайте, как Ethereum может открыть новые бизнес-модели, снизить затраты и обеспечить перспективу для вашего бизнеса.",
  "page-index-sections-enterprise-image-alt": "Рисунок группы, работающей над проектом Ethereum вокруг ноутбука",
  "page-index-sections-enterprise-link-text": "Ethereum для компаний",
  "page-index-sections-enterprise-title": "Компания",
  "page-index-sections-individuals-desc": "Познакомьтесь с Ethereum, Ether, кошельками, токенами и другим, чтобы начать пользоваться приложениями Ethereum.",
  "page-index-sections-individuals-image-alt": "Иллюстрация собаки, сидящей за компьютером",
  "page-index-sections-individuals-link-text": "Начать работать с Ethereum",
  "page-index-sections-individuals-title": "Об Ethereum",
  "page-index-subtitle": "С помощью Ethereum можно писать доступные всему миру программы для управления цифровыми данными, работающие именно так, как задумано.",
  "page-find-wallet-authereum-logo-alt": "Логотип Authereum",
  "page-find-wallet-description-authereum": "Никаких загрузок, никаких исходных фраз. Любой браузер, в любое время, на мобильном телефоне или настольном компьютере",
  "page-wallets-accounts-addresses-desc-2": "это организация, которая может отправлять транзакции и имеет баланс.",
  "page-wallets-accounts-has": "У счета Ethereum есть",
  "page-wallets-an": "Ан",
  "page-wallets-ethereum-addresses": "Адрес Ethereum",
  "page-wallets-ethereum-addresses-2": ", как у почтового ящика есть адрес электронной почты. Вы можете использовать это для отправки средств на счет.",
  "page-wallets-ethereum-wallet-2": "это приложение, которое позволяет вам управлять своим счетом Ethereum: просматривать баланс, совершать транзакции и так далее."
}
