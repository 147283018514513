{
  "1inch-logo": "Logotip de 1inch",
  "aave-logo": "Logotip d'Aave",
  "about": "Quant a",
  "about-ethereum-org": "Quant a ethereum.org",
  "about-us": "Sobre nosaltres",
  "alt-eth-blocks": "Il·lustració d'uns blocs que s’organitzen com un símbol ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Torna a dalt",
  "banner-page-incomplete": "Aquesta pàgina està incompleta. Si ets un expert en el tema, si us plau edita aquesta pàgina i afegeix-hi la teva saviesa.",
  "beacon-chain": "La Cadena de Balisa",
  "binance-logo": "Logotip de Binance",
  "bittrex-logo": "Logotip de Bittrex",
  "brand-assets": "Actius de la marca",
  "bug-bounty": "Recompensa d'error",
  "coinbase-logo": "Logotip de Coinbase",
  "coinmama-logo": "Logotip de Coinmama",
  "community": "Comunitat",
  "community-menu": "Community Menu",
  "compound-logo": "Logotip de Compound",
  "cons": "Contres",
  "contact": "Contacte",
  "content-versions": "Content Versions",
  "contributing": "Contribucions",
  "contributors": "Col·laboradors",
  "contributors-thanks": "A tothom que ha col·laborat amb aquesta pàgina, gràcies!",
  "cookie-policy": "Política de cookies",
  "copied": "Copiat",
  "copy": "Copia",
  "dark-mode": "Mode fosc",
  "data-provided-by": "Dades proporcionades per",
  "decentralized-applications-dapps": "Aplicacions descentralitzades (dapps)",
  "deposit-contract": "Contracte de dipòsit",
  "devcon": "Devcon",
  "developers": "Desenvolupadors",
  "developers-home": "Pàgina d'inici per a desenvolupadors",
  "docs": "Documents",
  "documentation": "Documentació",
  "dydx-logo": "Logotip de Dydx",
  "ecosystem": "Ecosistema",
  "edit-page": "Edita la pàgina",
  "ef-blog": "Blog d'Ethereum Foundation",
  "eips": "Propostes de Millora d'Ethereum",
  "enterprise": "Empresa",
  "enterprise-menu": "Menú empresarial",
  "esp": "Programa de suport de l'ecosistema",
  "eth-current-price": "Preu actual d'ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Explorador de la Cadena de Balisa Eth2 de codi oberta",
  "eth2-beaconscan-desc": "Explorador de la Cadena de Balisa Eth2: Etherscan per a Eth2",
  "eth2-become-staker": "Converteix-te en participant",
  "eth2-become-staker-desc": "Apostes en directe! Si vols apostar el teu ETH per ajudar a protegir la xarxa, assegura't que entens els riscos.",
  "eth2-explore": "Explora les dades",
  "eth2-no-action-needed": "No necessites fer res amb l'ETH que ja tens. Estigues atent a estafadors demanant que enviïs l'ETH per canviar-lo.",
  "eth2-run-beacon-chain": "Executa un client balisa",
  "eth2-run-beacon-chain-desc": "Ethereum necessita el màxim possible de clients actius. Ajuda amb aquest bé públic d’Ethereum!",
  "eth2-service-announcement": "Un anunci del servei d'Eth2",
  "eth2-what-shipping": "Quan es llençarà?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Actius de la marca Ethereum",
  "ethereum-community": "Comunitat Ethereum",
  "ethereum-foundation": "Fundació Ethereum",
  "ethereum-foundation-logo": "Logotip d'Ethereum Foundation",
  "ethereum-glossary": "Glossari d'Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Logotip d'Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Estudi d'Ethereum",
  "ethereum-wallets": "Carteres Ethereum",
  "ethereum-whitepaper": "Informe d'Ethereum",
  "example-projects": "Projectes d'exemple",
  "find-wallet": "Troba una cartera",
  "foundation": "Foundation",
  "gemini-logo": "Logotip de Gemini",
  "get-eth": "Aconsegueix ETH",
  "get-involved": "Participa",
  "get-started": "Comença",
  "gitcoin-logo": "Logotip de Gitcoin",
  "glossary": "Glossari",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Guies i recursos",
  "history": "Historial",
  "history-of-ethereum": "Història d'Ethereum",
  "home": "Inici",
  "how-ethereum-works": "Com funciona Ethereum",
  "image": "imatge",
  "in-this-section": "En aquesta secció",
  "individuals": "Usuaris",
  "individuals-menu": "Menú de l'usuari",
  "jobs": "Jobs",
  "kraken-logo": "Logotip de Kraken",
  "language-ar": "Àrab",
  "language-bg": "Bulgarian",
  "language-bn": "Bengalí",
  "language-ca": "Catalan",
  "language-cs": "Txec",
  "language-de": "German",
  "language-el": "Greek",
  "language-en": "English",
  "language-es": "Spanish",
  "language-fa": "Farsi",
  "language-fi": "Finnish",
  "language-fr": "French",
  "language-hu": "Hungarian",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonesian",
  "language-ig": "Igbo",
  "language-it": "Italian",
  "language-ja": "Japanese",
  "language-ko": "Korean",
  "language-lt": "Lithuanian",
  "language-ml": "Malayalam",
  "language-nb": "Norwegian",
  "language-nl": "Dutch",
  "language-pl": "Polish",
  "language-pt": "Portuguese",
  "language-pt-br": "Portuguese (Brazilian)",
  "language-ro": "Romanian",
  "language-ru": "Russian",
  "language-se": "Swedish",
  "language-sk": "Slovak",
  "language-sl": "Slovenian",
  "language-support": "Suport lingüístic",
  "language-tr": "Turkish",
  "language-uk": "Ukrainian",
  "language-vi": "Vietnamese",
  "language-zh": "Chinese Simplified",
  "language-zh-tw": "Chinese Traditional",
  "languages": "Idiomes",
  "last-24-hrs": "Les darreres 24 hores",
  "last-edit": "Darrera edició",
  "learn": "Aprèn",
  "learn-by-coding": "Aprèn programant",
  "learn-menu": "Menú d'aprenentatge",
  "learn-more": "Més informació",
  "less": "Menys",
  "light-mode": "Mode senzill",
  "listing-policy-disclaimer": "Tots els productes mostrats en aquesta pàgina no estan avalats oficialment i es mostren només amb finalitat informativa. Si voleu afegir un producte o proporcionar suggeriments sobre la política, plantegeu un problema a GitHub.",
  "listing-policy-raise-issue-link": "Planteja tema",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "S'està carregant...",
  "loading-error": "Error de càrrega.",
  "loading-error-refresh": "Error en la càrrega de dades. Intenta actualitzar la pàgina.",
  "logo": "logotip",
  "loopring-logo": "Logotip de Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Xarxa principal d'Ethereum",
  "makerdao-logo": "Logotip de MakerDao",
  "matcha-logo": "Logotip de Matcha",
  "merge": "Merge",
  "more": "Més",
  "nav-beginners": "Principiants",
  "next": "Següent",
  "oasis-logo": "Logotip d'Oasis",
  "on-this-page": "En aquesta pàgina",
  "page-content": "Contingut de la pàgina",
  "page-enterprise": "Empresa",
  "page-last-updated": "Última actualització de la pàgina",
  "previous": "Anterior",
  "privacy-policy": "Política de privadesa",
  "private-ethereum": "Xarxa privada d'Ethereum",
  "pros": "Pros",
  "read-more": "Més informació",
  "refresh": "Actualitza la pàgina.",
  "review-progress": "Revisa el progrés",
  "search": "Cerca",
  "search-box-blank-state-text": "Cerca!",
  "search-eth-address": "Això sembla una adreça d’Ethereum. No proporcionem dades específiques a adreces. Prova de buscar-ho en un explorador de blocs com",
  "search-no-results": "La teva consulta no té resultats",
  "security": "Security",
  "see-contributors": "Mostra col·laboradors",
  "set-up-local-env": "Configura l'entorn local",
  "shard-chains": "Cadenes de fragments",
  "show-all": "Mostra-ho tot",
  "show-less": "Mostra'n menys",
  "site-description": "Ethereum és una plataforma global i descentralitzada de diners i nous tipus d’aplicacions. A Ethereum, pots escriure codi que controli diners i crear aplicacions accessibles a qualsevol part del món.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Monedes estables",
  "staking": "Apostar",
  "summary": "Resum",
  "terms-of-use": "Condicions d'ús",
  "transaction-fees": "Que són les taxes per transacció?",
  "translation-banner-body-new": "Estàs veient aquest pàgina en anglès perquè encara no l'hem traduït. Ajuda'ns a traduir aquest contingut.",
  "translation-banner-body-update": "Hi ha una nova versió d'aquesta pàgina, però actualment només està en anglès. Ajuda'ns a traduir l'última versió.",
  "translation-banner-button-join-translation-program": "Uneix-te al programa de traducció",
  "translation-banner-button-learn-more": "Més informació",
  "translation-banner-button-see-english": "Veure en anglès",
  "translation-banner-button-translate-page": "Tradueix la pàgina",
  "translation-banner-title-new": "Ajuda a traduir aquesta pàgina",
  "translation-banner-title-update": "Ajuda a actualitzar aquesta pàgina",
  "translation-program": "Translation Program",
  "translation-progress": "Progrés de la traducció",
  "tutorials": "Tutorials",
  "uniswap-logo": "Logotip d'Uniswap",
  "use": "Utilitza",
  "use-ethereum": "Utilitza Ethereum",
  "use-ethereum-menu": "Utilitza menú d'Ethereum",
  "vision": "Visió",
  "wallets": "Carteres",
  "website-last-updated": "Última actualització del lloc web",
  "what-is-ether": "Què és l'ether (ETH)?",
  "what-is-ethereum": "Què és Ethereum?",
  "whitepaper": "Informe",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Sol·licita una funció",
  "page-about-h3": "Treball en curs",
  "page-about-h3-1": "Funcions implementades",
  "page-about-h3-2": "Funcions planificades",
  "page-about-li-1": "en curs",
  "page-about-li-2": "planificat",
  "page-about-li-3": "implementat",
  "page-about-li-4": "implementat",
  "page-about-link-1": "El codi font d'aquest dipòsit es troba sota la llicència MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Veure el llistat complet de tasques en curs al GitHub",
  "page-about-link-4": "Uneix-te al nostre servidor Discord",
  "page-about-link-5": "Contacta amb nosaltres al Twitter",
  "page-about-link-6": "Veure el llistat complet de tasques implementades al GitHub",
  "page-about-link-7": "Crea un tema al GitHub",
  "page-about-p-1": "Des del llançament d'ethereum.org, ens hem esforçat per ser transparents en la nostra forma d'operar. Aquest és un dels nostres valors principals perquè creiem que la transparència és crucial per a l'èxit d'Ethereum.",
  "page-about-p-2": "Utilitzem",
  "page-about-p-3": "com a eina principal de gestió de projectes. Organitzem les nostres tasques en 3 categories:",
  "page-about-p-4": "  Fem tot el possible per mantenir la comunitat informada sobre quin és l'estat d'una tasca específica.",
  "page-about-p-5": "Tasques que estem implementant.",
  "page-about-p-6": "Tasques que hem posat a la cua per implementar properament.",
  "page-about-p-7": "Tasques completades recentment.",
  "page-about-p-8": "Tens alguna idea de com millorar ethereum.org? Ens encantaria col·laborar amb tu!",
  "page-assets-bazaar": "Conceptes bàsics d'Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Blocs de construcció",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge utilitzant dapps",
  "page-assets-download-artist": "Artista:",
  "page-assets-download-download": "Baixa",
  "page-assets-enterprise": "Ethereum per a empreses",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "Diamant ETH (color)",
  "page-assets-eth-diamond-glyph": "Diamant ETH (glif)",
  "page-assets-eth-diamond-gray": "Diamant ETH (gris)",
  "page-assets-eth-diamond-purple": "Diamant ETH (morat)",
  "page-assets-eth-diamond-white": "Diamant ETH (blanc)",
  "page-assets-eth-glyph-video-dark": "Vídeo glif ETH (fosc)",
  "page-assets-eth-glyph-video-light": "Vídeo glif ETH (clar)",
  "page-assets-eth-logo-landscape-gray": "Logotip ETH horitzontal (gris)",
  "page-assets-eth-logo-landscape-purple": "Logotip ETH horitzontal (morat)",
  "page-assets-eth-logo-landscape-white": "Logotip ETH horitzontal (blanc)",
  "page-assets-eth-logo-portrait-gray": "Logotip ETH vertical (gris)",
  "page-assets-eth-logo-portrait-purple": "Logotip ETH vertical (morat)",
  "page-assets-eth-logo-portrait-white": "Logotip ETH vertical (blanc)",
  "page-assets-eth-wordmark-gray": "Marca denominativa ETH (gris)",
  "page-assets-eth-wordmark-purple": "Marca denominativa ETH (morat)",
  "page-assets-eth-wordmark-white": "Marca denominativa ETH (blanc)",
  "page-assets-ethereum-brand-assets": "Actius de \"marca\" d'Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "actius d'ethereum.org",
  "page-assets-hero": "protagonista d'ethereum.org",
  "page-assets-hero-particles": "Imatge de partícules ETH",
  "page-assets-historical-artwork": "Obra d'art històrica",
  "page-assets-illustrations": "Il·lustracions",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Explora i baixa els actius de marca, les il·lustracions i els materials d'Ethereum i ethereum.org.",
  "page-assets-meta-title": "Actius de marca d'Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Fons sòlid",
  "page-assets-page-assets-transparent-background": "Fons transparent",
  "page-assets-robot": "Cartera robot",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "Logotip de 1inch",
  "page-dapps-aave-logo-alt": "Logotip d'Aave",
  "page-dapps-add-button": "Suggereix dapp",
  "page-dapps-add-title": "Afegeix dapp",
  "page-dapps-audius-logo-alt": "Logotip d'Audius",
  "page-dapps-augur-logo-alt": "Logotip d'Augur",
  "page-dapps-axie-infinity-logo-alt": "Logotip d'Axie Infitinity",
  "page-dapps-brave-logo-alt": "Logotip de Brave",
  "page-dapps-category-arts": "Art i moda",
  "page-dapps-category-browsers": "Navegadors",
  "page-dapps-category-collectibles": "Col·leccionables digitals",
  "page-dapps-category-competitive": "Concurs",
  "page-dapps-category-computing": "Eines per a desenvolupadors",
  "page-dapps-category-dex": "Intercanvis de tokens",
  "page-dapps-category-investments": "Inversions",
  "page-dapps-category-lending": "Prestar i demanar prestat",
  "page-dapps-category-lottery": "Finançament col·lectiu",
  "page-dapps-category-marketplaces": "Mercats",
  "page-dapps-category-music": "Música",
  "page-dapps-category-payments": "Pagaments",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Mercats predictius i comercials",
  "page-dapps-category-utilities": "Utilitats",
  "page-dapps-category-worlds": "Mons virtuals",
  "page-dapps-choose-category": "Escull una categoria",
  "page-dapps-collectibles-benefits-1-description": "Quan l'art es transforma en token a Ethereum, la propietat es pot demostrar per a tothom. Pots traçar el recorregut de l'obra d'art des de la creació fins al titular actual. Això evita falsificacions.",
  "page-dapps-collectibles-benefits-1-title": "La propietat és demostrable",
  "page-dapps-collectibles-benefits-2-description": "Pagar per transmetre música o comprar obres d'art és molt més just per als artistes. Amb Ethereum hi ha menys necessitat d'intermediaris. I si es necessiten intermediaris, el seu cost no és tant alt ja que les plataformes no necessiten pagar per la infraestructura de la xarxa.",
  "page-dapps-collectibles-benefits-2-title": "Més just per als creadors",
  "page-dapps-collectibles-benefits-3-description": "Els col·leccionables tokenitzats estan lligats a la teva adreça d'Ethereum, no a la plataforma. Per tant, pots vendre articles de joc en qualsevol mercat d'Ethereum, no només dins del joc en sí mateix.",
  "page-dapps-collectibles-benefits-3-title": "Els col·leccionables van amb tu",
  "page-dapps-collectibles-benefits-4-description": "Les eines i els productes ja existeixen per tal que puguis fer tokens del teu art i puguis vendre'ls. I els teus tokens es poden vendre en qualsevol de les plataformes de col·leccionables Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Infraestructura disponible al mateix lloc",
  "page-dapps-collectibles-benefits-description": "Aquestes són aplicacions centrades en la propietat digital, incrementant el potencial de guanys pels creadors i ideant noves formes d'inversió en els teus creadors i el seu treball.",
  "page-dapps-collectibles-benefits-title": "col·leccionables i transmissions descentralitzats",
  "page-dapps-collectibles-button": "Art i col·leccionables",
  "page-dapps-collectibles-description": "Aquestes són aplicacions centrades en la propietat digital, incrementant el potencial de guanys pels creadors i ideant noves formes d'inversió en els teus creadors i el seu treball.",
  "page-dapps-collectibles-title": "Art i col·leccionables descentralitzats",
  "page-dapps-compound-logo-alt": "Logotip de Compound",
  "page-dapps-cryptopunks-logo-alt": "Logotip de CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Logotip de Cryptovoxels",
  "page-dapps-dapp-description-1inch": "T'ajuda a evitar l'elevat desfasament de preus mitjançant l'agregació dels millors preus.",
  "page-dapps-dapp-description-aave": "Presta tokens per guanyar interessos i retirar-los en qualsevol moment.",
  "page-dapps-dapp-description-async-art": "Crea, col·lecciona i comercia #ProgrammableArt, pintures digitals separades en \"capes\" que pots utilitzar per afectar la imatge en conjunt. Cada mestre i capa és un token ERC721.",
  "page-dapps-dapp-description-audius": "Plataforma de transmissió descentralitzada. Escolta = diners per a creadors, no etiquetes.",
  "page-dapps-dapp-description-augur": "Aposta a resultats d'esports, economia i altres esdeveniments mundials.",
  "page-dapps-dapp-description-axie-infinity": "Comercia i lluita amb criatures anomenades Axies. I guanya mentre jugues - disponible per a mòbils",
  "page-dapps-dapp-description-brave": "Guanya tokens per navegar i donar suport als teus creadors favorits amb ells.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Presta tokens per guanyar interessos i retirar-los en qualsevol moment.",
  "page-dapps-dapp-description-cryptopunks": "Compra, licita i ofereix punks a la venda, un dels primers token col·leccionables d'Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Crea galeries d'art, construeix botigues i compra terrenys: un món virtual a Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Conquereix planetes en un univers infinit, criptogràficament específic i generat procedimentalment.",
  "page-dapps-dapp-description-decentraland": "Col·lecciona i comercialitza amb terrenys virtuals en un món virtual que pots explorar.",
  "page-dapps-dapp-description-dydx": "Obre posicions curtes o profitoses fins a 10x. Prestar i demanar prestat també està disponible.",
  "page-dapps-dapp-description-ens": "Noms fàcils d'utilitzar per a adreces Ethereum i llocs web descentralitzats.",
  "page-dapps-dapp-description-foundation": "Inverteix en edicions úniques d'obres d'art digitals i peces comercials amb altres compradors.",
  "page-dapps-dapp-description-gitcoin": "Guanya criptomonedes treballant en un programari de codi obert.",
  "page-dapps-dapp-description-gitcoin-grants": "Finançament de grup per als projectes de la comunitat Ethereum amb contribucions amplificades",
  "page-dapps-dapp-description-gods-unchained": "Joc de cartes estratègic. Guanya cartes jugant a allò que pots vendre en la vida real.",
  "page-dapps-dapp-description-golem": "Accedeix a la potència informàtica compartida o lloga els teus propis recursos.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Plataforma per al comerç d'igual a igual construïda per a la velocitat.",
  "page-dapps-dapp-description-marble-cards": "Crea i comercialitza targetes digitals úniques basades en URL.",
  "page-dapps-dapp-description-matcha": "Cerca intercanvis múltiples per ajudar-te a trobar els millors preus per a tu.",
  "page-dapps-dapp-description-nifty-gateway": "Compra obres en cadena d'artistes famosos, atletes, marques i creadors.",
  "page-dapps-dapp-description-oasis": "Comercia, demana prestat i estalvia amb Dai, una moneda estable d'Ethereum.",
  "page-dapps-dapp-description-opensea": "Compra, ven, descobreix i intercanvia béns d'edició limitada.",
  "page-dapps-dapp-description-opera": "Envia criptomonedes des del teu navegador a altres comerciants o usuaris i aplicacions.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Aposta en resultats. Comercia en mercats d'informació.",
  "page-dapps-dapp-description-pooltogether": "Una loteria que no et pots perdre. Premis cada setmana.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Crea, ven i compra col·leccionables tokenitzats.",
  "page-dapps-dapp-description-sablier": "Envia diners en temps real.",
  "page-dapps-dapp-description-superrare": "Compra obres d'art digitals directament dels artistes o en mercats secundaris.",
  "page-dapps-dapp-description-token-sets": "Estratègies d'inversió criptogràfiques que es reequilibren automàticament.",
  "page-dapps-dapp-description-tornado-cash": "Envia transaccions anònimes dins d'Ethereum.",
  "page-dapps-dapp-description-uniswap": "Intercanvia tokens simplement o proporciona tokens per un percentatge de recompenses.",
  "page-dapps-dark-forest-logo-alt": "Logotip de Dark Forest",
  "page-dapps-decentraland-logo-alt": "Logotip de Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Troba una aplicació Ethereum per provar.",
  "page-dapps-doge-img-alt": "Il·lustració d'un gos utilitzant un ordinador",
  "page-dapps-dydx-logo-alt": "Logotip de dYdX",
  "page-dapps-editors-choice-dark-forest": "Juga contra altres persones per conquerir planetes i prova la tecnologia puntera d'escalada/privacitat d'Ethereum. Potser una amb la qual ja estan familiaritzats amb Ethereum.",
  "page-dapps-editors-choice-description": "Unes quantes dapps que agraden molt a l'equip d'ethereum-org. Explora més dapps a continuació.",
  "page-dapps-editors-choice-foundation": "Inverteix en cultura. Compra, comercia i ven obres d'art digitals i moda úniques, d'artistes, músics i marques increïbles.",
  "page-dapps-editors-choice-header": "Recomanacions dels editors",
  "page-dapps-editors-choice-pooltogether": "Compra un número per a la loteria sense pèrdues. Cada setmana, l'interès generat per tot el conjunt de números s'envia a un afortunat guanyador. Recupera els teus diners quan vulguis.",
  "page-dapps-editors-choice-uniswap": "Intercanvia els teus tokens amb facilitat. Un preferit de la comunitat que et permet intercanviar tokens amb amics arreu de la xarxa.",
  "page-dapps-ens-logo-alt": "Logotip d'Ethereum Name Service",
  "page-dapps-explore-dapps-description": "Encara hi ha un munt de dapps experimentals, provant les possibilitats de les xarxes descentralitzades. Però hi han hagut alguns precursors exitosos en les categories tecnològiques, financeres, de joc i de col·leccionables.",
  "page-dapps-explore-dapps-title": "Explora dapps",
  "page-dapps-features-1-description": "Un cop instal·lat a Ethereum, el codi de la dapp no es pot eliminar. I qualsevol pot utilitzar aquestes característiques de la dapp. Encara que l'equip darrera la dapp es dissolgués, es podria continuar utilitzant. Un cop a Ethereum, s'hi queda per sempre.",
  "page-dapps-features-1-title": "Sense propietaris",
  "page-dapps-features-2-description": "No se't pot bloquejar per utilitzar una dapp o enviar transaccions. Per exemple, si Twitter estigués a Ethereum, ningú podria bloquejar el teu compte o evitar que fessis tweets.",
  "page-dapps-features-2-title": "Sense censura",
  "page-dapps-features-3-description": "Degut a que Ethereum té ETH, els pagaments són nadius d'Ethereum. Els desenvolupadors no necessiten malgastar temps integrant proveïdors de pagaments de tercers.",
  "page-dapps-features-3-title": "Pagaments integrats",
  "page-dapps-features-4-description": "El codi dapp normalment està, per defecte, obert i compatible. Els equips acostumen a construir utilitzant el treball d'altres equips. Si vols permetre que els usuaris canviïn tokens a la teva dapp, només has de connectar un altre codi de dapp.",
  "page-dapps-features-4-title": "Connecta i reprodueix",
  "page-dapps-features-5-description": "Amb la majoria de dapps no necessites compartir la teva identitat real. El teu compte Ethereum es el teu inici de sessió i només necessites una cartera.",
  "page-dapps-features-5-title": "Un inici de sessió anònim",
  "page-dapps-features-6-description": "La criptografia assegura que els atacants no puguin falsificar transaccions i altres interaccions de dapps en nom teu. Tu autoritzes les teves accions dapp amb el teu compte Ethereum, normalment via la teva cartera, per tant mantens les teves credencials segures.",
  "page-dapps-features-6-title": "Recolzat mitjançant criptografia",
  "page-dapps-features-7-description": "Un cop la dapp s'està executant a Ethereum, només deixarà de funcionar si Ethereum cau. La grandària de les xarxes d'Ethereum són molt difícils d'atacar.",
  "page-dapps-features-7-title": "Sense temps d'inactivitat",
  "page-dapps-finance-benefits-1-description": "Els serveis financers que s'executen a Ethereum no tenen requisits d'inscripció. Si tens fons i una connexió a Internet, ja pots començar.",
  "page-dapps-finance-benefits-1-title": "Accés obert",
  "page-dapps-finance-benefits-2-description": "Hi ha tot un món de tokens amb els quals pots interactuar a través d'aquests productes financers. La gent està construint tokens nous a sobre d'Ethereum en tot moment.",
  "page-dapps-finance-benefits-2-title": "Una nova economia de tokens",
  "page-dapps-finance-benefits-3-description": "Els equips han creat les monedes estables, una criptomoneda menys volàtil que et permet experimentar i utilitzar criptomonedes sense riscos ni incerteses.",
  "page-dapps-finance-benefits-3-title": "Monedes estables",
  "page-dapps-finance-benefits-4-description": "Els productes financers dins l'espai Ethereum són tots modulars i compatibles els uns amb els altres. Sovint hi ha noves combinacions d'aquests mòduls que surten al mercat, incrementant el que pots fer amb les teves criptomonedes.",
  "page-dapps-finance-benefits-4-title": "Serveis financers interconnectats",
  "page-dapps-finance-benefits-description": "Què és el que hi ha a Ethereum que permet que les aplicacions de finances descentralitzades prosperin?",
  "page-dapps-finance-benefits-title": "finances descentralitzades",
  "page-dapps-finance-button": "Finances",
  "page-dapps-finance-description": "Són aplicacions que se centren en crear serveis financers utilitzant criptomonedes. Ofereixen la possibilitat de prestar, demanar prestat, guanyar interessos i realitzar pagaments privats, sense que es requereixin dades personals.",
  "page-dapps-finance-title": "Finances descentralitzades",
  "page-dapps-foundation-logo-alt": "Logotip de Foundation",
  "page-dapps-gaming-benefits-1-description": "Tant si es tracta de terrenys virtuals com de cartes, els teus objectes es poden intercanviar als mercats de col·leccionables. Els teus objectes al joc tenen valor en el món real.",
  "page-dapps-gaming-benefits-1-title": "Els objectes del joc es poden utilitzar com a tokens",
  "page-dapps-gaming-benefits-2-description": "Els teus objectes són de la teva propietat, i ens alguns casos els teus progressos, no de les empreses de jocs. Per tant, no perdràs res si l'empresa que hi ha darrera el joc és atacada, pateix un malt funcionament o es dissol.",
  "page-dapps-gaming-benefits-2-title": "Els teus estalvis estan segurs",
  "page-dapps-gaming-benefits-3-description": "De la mateixa forma que els pagaments a Ethereum estan disponibles perquè qualsevol els verifiqui, els jocs poden utilitzar aquesta qualitat per assegurar la imparcialitat. En teoria, tot és verificable: des del nombre de cops crítics fins a la grandària del botí d'un oponent.",
  "page-dapps-gaming-benefits-3-title": "Imparcialitat demostrable",
  "page-dapps-gaming-benefits-description": "Què és el que hi ha a Ethereum que permet que els jocs descentralitzats prosperin?",
  "page-dapps-gaming-benefits-title": "jocs descentralitzats",
  "page-dapps-gaming-button": "Jocs",
  "page-dapps-gaming-description": "Són aplicacions centrades en la creació de mons virtuals i en batalles entre jugadors utilitzant col·leccionables que tenen valor en el món real.",
  "page-dapps-gaming-title": "Jocs descentralitzats",
  "page-dapps-get-some-eth-description": "Les accions de les dapps tenen una comissió de transacció",
  "page-dapps-get-started-subtitle": "Per provar una dapp necessitaràs una cartera i uns quants ETH. Una cartera et permetrà connectar o entrar en el teu perfil. I necessitaràs ETH per pagar qualsevol comissió de transacció.",
  "page-dapps-get-started-title": "Comença",
  "page-dapps-gitcoin-grants-logo-alt": "Logotip de Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Logotip de Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Logotip de Gods Unchained",
  "page-dapps-golem-logo-alt": "Logotip de Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Serveis i eines que funcionen amb Ethereum",
  "page-dapps-hero-subtitle": "Les dapps són un moviment creixent d'aplicacions que utilitzen Ethereum per interrompre els models de negocis i crear-ne de nous.",
  "page-dapps-how-dapps-work-p1": "Les dapps tenen el seu codi de backend (contractes intel·ligents) que s'executa en una xarxa descentralitzada i no en un servidor centralitzat. Utilitzen la cadena de blocs d'Ethereum per emmagatzemar dades i contractes intel·ligents per a la seva lògica d'aplicacions.",
  "page-dapps-how-dapps-work-p2": "Un contracte intel·ligent és com un conjunt de normes que conviuen en cadena a la vista de tothom i s'executen d'acord a aquelles normes. Imagina una màquina de vending: si l'omples amb fons suficients i la selecció correcta, tindràs el que vols. I com les màquines de vending, els contractes intel·ligents poden mantenir fons de la mateixa manera que el teu compte d'Ethereum. Això permet que el codi faci de mitjancer entre acords i transaccions.",
  "page-dapps-how-dapps-work-p3": "Un cop les dapps estan desplegades a la xarxa Ethereum, no pots canviar-les. Les dapps poden estar descentralitzades perquè estan controlades per la lògica escrita al contracte, no per un individu o una empresa.",
  "page-dapps-how-dapps-work-title": "Com funcionen les dapps",
  "page-dapps-learn-callout-button": "Comença a crear",
  "page-dapps-learn-callout-description": "El nostre portal comunitari de desenvolupament conté documents, eines i marcs que t'ajudaran a començar a crear la teva dapp.",
  "page-dapps-learn-callout-image-alt": "Il·lustració d'una mà creant un símbol ETH fet de peces de Lego.",
  "page-dapps-learn-callout-title": "Aprèn a crear una dapp",
  "page-dapps-loopring-logo-alt": "Logotip de Loopring",
  "page-dapps-magic-behind-dapps-description": "Les dapps poden semblar aplicacions normals i corrents. Però tenen algunes qualitats especials, ja que hereten tots els superpoders d'Ethereum. Això és el què fa les dapps diferents de les apps.",
  "page-dapps-magic-behind-dapps-link": "Què fa que Ethereum sigui tan bo?",
  "page-dapps-magic-behind-dapps-title": "La màgia darrere les dapps",
  "page-dapps-magic-title-1": "La màgia",
  "page-dapps-magic-title-2": "darrere",
  "page-dapps-magician-img-alt": "Il·lustració de mags",
  "page-dapps-marble-cards-logo-alt": "Logotip de marble.cards",
  "page-dapps-matcha-logo-alt": "Logotip de Matcha",
  "page-dapps-mobile-options-header": "Cerca una altra categoria",
  "page-dapps-nifty-gateway-logo-alt": "Logotip de Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Logotip d'Oasis",
  "page-dapps-opensea-logo-alt": "Logotip d'OpenSea",
  "page-dapps-opera-logo-alt": "Logotip d'Opera",
  "page-dapps-polymarket-logo-alt": "Logotip de Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Logotip de PoolTogether",
  "page-dapps-rarible-logo-alt": "Logotip de Rarible",
  "page-dapps-ready-button": "Ves",
  "page-dapps-ready-description": "Escull una dapp per provar",
  "page-dapps-ready-title": "Preparat?",
  "page-dapps-sablier-logo-alt": "Logotip de Sablier",
  "page-dapps-set-up-a-wallet-button": "Troba una cartera",
  "page-dapps-set-up-a-wallet-description": "Una cartera és el teu \"inici de sessió\" per a una dapp",
  "page-dapps-set-up-a-wallet-title": "Configura una cartera",
  "page-dapps-superrare-logo-alt": "Logotip de SuperRare",
  "page-dapps-technology-button": "Tecnologia",
  "page-dapps-technology-description": "Aquestes són aplicacions centrades en eines de desenvolupament descentralitzat, la incorporació de sistemes criptoeconòmics a la tecnologia existent i la creació de mercats per al treball de desenvolupament de codi obert.",
  "page-dapps-technology-title": "Tecnologia descentralitzada",
  "page-dapps-token-sets-logo-alt": "Logotip de Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Logotip de Tornado cash",
  "page-dapps-uniswap-logo-alt": "Logotip d'Uniswapd",
  "page-dapps-wallet-callout-button": "Troba una cartera",
  "page-dapps-wallet-callout-description": "Les carteres també són dapps. Troba'n una basada en les característiques que et convinguin.",
  "page-dapps-wallet-callout-image-alt": "Il·lustració d'un robot.",
  "page-dapps-wallet-callout-title": "Visualitza carteres",
  "page-dapps-warning-header": "Fes sempre la teva pròpia recerca",
  "page-dapps-warning-message": "Ethereum és una tecnologia nova i la majoria d'aplicacions són noves. Abans de depositar qualsevol quantitat gran de diners, assegura't de comprendre els riscos.",
  "page-dapps-what-are-dapps": "Què són les dapps?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Comptes",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Avançat",
  "docs-nav-backend-apis": "APIs de Backend",
  "docs-nav-block-explorers": "Explorador de Blocs",
  "docs-nav-blocks": "Blocs",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Compilació de contractes intel·ligents",
  "docs-nav-composability": "Capacitat de composició",
  "docs-nav-consensus-mechanisms": "Mecanismes de consens",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Dades i anàlisis",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Implementació de contractes intel·ligents",
  "docs-nav-development-frameworks": "Frameworks de desenvolupament",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Xarxes de desenvolupament",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "APIs de client Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Pila Ethereum",
  "docs-nav-evm": "Màquina virtual d'Ethereum (MVE)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Temes bàsics",
  "docs-nav-gas": "Gas",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Entorns Integrats de Desenvolupament (EIDs)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Introducció a les dapps",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Introducció a Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Introducció a la pila",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "APIs de JavaScript",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Mineria",
  "docs-nav-networks": "Xarxes",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Nodes i clients",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Nodes com a servei",
  "docs-nav-oracles": "Oracles",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Llenguatges de programació",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Prova de participació",
  "docs-nav-proof-of-work": "Prova de treball",
  "docs-nav-python": "Python",
  "docs-nav-readme": "README",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Escalant",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Seguretat",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomia d'un contracte intel·ligent",
  "docs-nav-smart-contract-languages": "Llenguatges dels contractes intel·ligents",
  "docs-nav-smart-contracts": "Contractes intel·ligents",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Biblioteques dels contractes intel·ligents",
  "docs-nav-standards": "Estàndards",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Emmagatzematge",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Prova de contractes intel·ligents",
  "docs-nav-token-standards": "Estàndards de token",
  "docs-nav-transactions": "Transaccions",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 vs Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Si ets un expert en el tema i vols contribuir, edita aquesta pàgina i escriu tot el que saps.",
  "page-calltocontribute-desc-2": "Seràs reconegut i estaràs ajudant a la comunitat Ethereum!",
  "page-calltocontribute-desc-3": "Utilitza aquesta opció flexible",
  "page-calltocontribute-desc-4": "Dubtes? Pregunta'ns al canal #content al nostre",
  "page-calltocontribute-link": "plantilla de documentació",
  "page-calltocontribute-link-2": "Servidor Discord",
  "page-calltocontribute-span": "Edita la pàgina",
  "page-calltocontribute-title": "Ajuda'ns amb aquesta pàgina",
  "page-developer-meta-title": "Recursos per a desenvolupadors d'Ethereum",
  "page-developers-about": "Sobre aquests recursos per a desenvolupadors",
  "page-developers-about-desc": "L'objectiu d'ethereum.org és ajudar-te a desenvolupar amb Ethereum, amb documentació sobre els conceptes fonamentals, així com la pila de desenvolupament. A més, inclou tutorials per fer-t'ho encara més fàcil.",
  "page-developers-about-desc-2": "Inspirats en la xarxa de desenvolupadors de Mozilla, vam pensar que Ethereum necessitava un lloc on s'agrupessin recursos i continguts de qualitat per a desenvolupadors. Com els companys de Mozilla, tot el que hi ha aquí és de codi obert i disponible perquè ho puguis ampliar i millorar.",
  "page-developers-account-desc": "Contractes o persones a la xarxa",
  "page-developers-accounts-link": "Comptes",
  "page-developers-advanced": "Avançat",
  "page-developers-api-desc": "Ús de biblioteques per interaccionar amb contractes intel·ligents",
  "page-developers-api-link": "APIs de Backend",
  "page-developers-aria-label": "Menú per a desenvolupadors",
  "page-developers-block-desc": "Lots de transaccions afegits a la cadena de blocs",
  "page-developers-block-explorers-desc": "El teu portal a les dades d’Ethereum",
  "page-developers-block-explorers-link": "Exploradors de blocs",
  "page-developers-blocks-link": "Blocs",
  "page-developers-browse-tutorials": "Explora els tutorials",
  "page-developers-choose-stack": "Tria la teva pila",
  "page-developers-contribute": "Contribueix",
  "page-developers-dev-env-desc": "IDE adequats per al desenvolupament de dapps",
  "page-developers-dev-env-link": "Entorns de desenvolupament",
  "page-developers-discord": "Uneix-te a Discord",
  "page-developers-docs-introductions": "Introduccions",
  "page-developers-evm-desc": "L’ordinador que processa les transaccions",
  "page-developers-evm-link": "La màquina virtual d'Ethereum (EVM)",
  "page-developers-explore-documentation": "Consulta la documentació",
  "page-developers-feedback": "Si tens algun comentari, contacta amb nosaltres mitjançant una incidència de GitHub o al nostre servidor Discord.",
  "page-developers-frameworks-desc": "Eines per ajudar a accelerar el desenvolupament",
  "page-developers-frameworks-link": "Frameworks de desenvolupament",
  "page-developers-fundamentals": "Fonaments bàsics",
  "page-developers-gas-desc": "Es necessita Ether per processar les transaccions",
  "page-developers-gas-link": "Gas",
  "page-developers-get-started": "Com t'agradaria començar?",
  "page-developers-improve-ethereum": "Ajuda'ns a millorar ethereum.org",
  "page-developers-improve-ethereum-desc": "Igual que ethereum.org, aquests documents són un esforç comunitari. Crea una PR si hi trobes errors, possibilitats de millora o noves oportunitats per ajudar els desenvolupadors d’Ethereum.",
  "page-developers-into-eth-desc": "Una introducció a les cadenes de blocs i Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Una introducció a les aplicacions descentralitzades",
  "page-developers-intro-dapps-link": "Introducció a les dapps",
  "page-developers-intro-eth-link": "Introducció a Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Introducció a la pila",
  "page-developers-intro-stack-desc": "Una introducció a la pila d'Ethereum",
  "page-developers-js-libraries-desc": "Ús de JavaScript per interaccionar amb contractes intel·ligents",
  "page-developers-js-libraries-link": "Biblioteques JavaScript",
  "page-developers-language-desc": "Ús d'Ethereum amb llenguatges coneguts",
  "page-developers-languages": "Llenguatges de programació",
  "page-developers-learn": "Aprèn a desenvolupar a Ethereum",
  "page-developers-learn-desc": "Obtén informació sobre els conceptes bàsics i la pila d'Ethereum amb la nostra documentació",
  "page-developers-learn-tutorials": "Aprèn mitjançant tutorials",
  "page-developers-learn-tutorials-cta": "Mira els tutorials",
  "page-developers-learn-tutorials-desc": "Aprèn a desenvolupar a Ethereum pas a pas amb l'ajuda d'altres programadors que ja ho han fet.",
  "page-developers-meta-desc": "Documentació, tutorials i eines per a desenvolupadors que utilitzen Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Com es creen nous blocs i s’arriba al consens",
  "page-developers-mining-link": "Mineria",
  "page-developers-networks-desc": "Una visió general de la xarxa principal i de les xarxes de prova",
  "page-developers-networks-link": "Xarxes",
  "page-developers-node-clients-desc": "Com es verifiquen els blocs i les transaccions a la xarxa",
  "page-developers-node-clients-link": " Nodes i clients",
  "page-developers-oracle-desc": "Transferir dades de fora de la cadena als teus contractes intel·ligents",
  "page-developers-oracles-link": "Oracles",
  "page-developers-play-code": "Juga amb codi",
  "page-developers-read-docs": "Llegeix la documentació",
  "page-developers-scaling-desc": "Solucions per a transaccions més ràpides",
  "page-developers-scaling-link": "Escalat",
  "page-developers-smart-contract-security-desc": "Mesures de seguretat a tenir en compte durant el desenvolupament",
  "page-developers-smart-contract-security-link": "Seguretat",
  "page-developers-set-up": "Configura l'entorn local",
  "page-developers-setup-desc": "Prepara la pila per començar a construir configurant un entorn de desenvolupament.",
  "page-developers-smart-contracts-desc": "La lògica darrere les dapps: acords autoexecutables",
  "page-developers-smart-contracts-link": "Contractes intel·ligents",
  "page-developers-stack": "La pila",
  "page-developers-start": "Comença a experimentar",
  "page-developers-start-desc": "Vols experimentar primer i fer preguntes més tard?",
  "page-developers-storage-desc": "Com es gestiona l’emmagatzematge a les dapps",
  "page-developers-storage-link": "Emmagatzematge",
  "page-developers-subtitle": "Manual del desenvolupador per a Ethereum. De desenvolupadors, per a desenvolupadors.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "desenvolupador",
  "page-developers-title-3": "recursos",
  "page-developers-token-standards-desc": "Una visió general dels estàndards acceptats per als tokens",
  "page-developers-token-standards-link": "Estàndards per als tokens",
  "page-developers-transactions-desc": "Com canvia l’estat d’Ethereum",
  "page-developers-transactions-link": "Transaccions",
  "page-developers-web3-desc": "En què és diferent el món del desenvolupament web3",
  "page-developers-web3-link": "Web2 vs Web3",
  "page-learning-tools-bootcamps": "Cursos intensius per a desenvolupadors",
  "page-learning-tools-bootcamps-desc": "Cursos en línia pagats per posar-te al dia ràpidament.",
  "page-learning-tools-browse-docs": "Cerca documents",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Logotip de ChainShot",
  "page-learning-tools-coding": "Aprèn programant",
  "page-learning-tools-coding-subtitle": "Aquestes eines t'ajudaran a experimentar amb Ethereum si et ve de gust una experiència d'aprenentatge més interactiva.",
  "page-learning-tools-consensys-academy-description": "Bootcamp en línia per a desenvolupadors d'Ethereum.",
  "page-learning-tools-consensys-academy-logo-alt": "Logotip de ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Aprèn Solidity construint el teu propi joc de zombis.",
  "page-learning-tools-cryptozombies-logo-alt": "Logotip de CryptoZombies",
  "page-learning-tools-documentation": "Aprèn amb documentació",
  "page-learning-tools-documentation-desc": "Vols aprendre més? Consulta la nostra documentació per trobar les explicacions que necessitis.",
  "page-learning-tools-eth-dot-build-description": "Un entorn aïllat educatiu per Web 3.0, que inclou programació \"arrossegar i deixar anar\" i blocs de construcció de codi obert.",
  "page-learning-tools-eth-dot-build-logo-alt": "Logotip d'Eth.build",
  "page-learning-tools-ethernauts-description": "Pirateja contractes intel·ligents per passar de nivell.",
  "page-learning-tools-ethernauts-logo-alt": "Logotip d'Ethernauts",
  "page-learning-tools-game-tutorials": "Tutorials de joc interactius",
  "page-learning-tools-game-tutorials-desc": "Aprèn mentre jugues. Aquests tutorials t'ajudaran a comprendre els conceptes a través del joc.",
  "page-learning-tools-meta-desc": "Les eines de codificació basades en la web i les experiències d'aprenentatge interactiu t'ajudaran a experimentar amb el desenvolupament d'Ethereum.",
  "page-learning-tools-meta-title": "Eines d'aprenentatge per a desenvolupadors",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Desenvolupa, implementa i administra contractes intel·ligents per a Ethereum. Segueix els tutorials amb el plugin Learneth.",
  "page-learning-tools-remix-description-2": "Remix no és només un entorn segur. Molts desenvolupadors escriuen, compilen i implementen els seus contractes intel·ligents utilitzant Remix.",
  "page-learning-tools-remix-logo-alt": "Logotip de Remix",
  "page-learning-tools-sandbox": "Processos aïllats de codi",
  "page-learning-tools-sandbox-desc": "Aquests processos aïllats et donaran un espai per experimentar amb contractes intel·ligents escrits i entendre Ethereum.",
  "page-learning-tools-studio-description": "Un IDE basat en la web on pots trobar tutorials per crear i provar contractes intel·ligents, i construir per a ells un frontend.",
  "page-learning-tools-vyperfun-description": "Descobreix Vyper mitjançant la creació del teu propi joc de Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Logotip de Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Un marc per a desenvolupament i proves basat en Python per a contractes intel·ligents orientat a la màquina virtual d'Ethereum.",
  "page-local-environment-brownie-logo-alt": "Logotip de Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "La plataforma de desenvolupament tot en un per crear i implementar aplicacions descentralitzades.",
  "page-local-environment-embark-logo-alt": "Logotip d'Embark",
  "page-local-environment-epirus-desc": "Una plataforma per desenvolupar, implementar i supervisar aplicacions de la cadena de blocs a la màquina virtual de Java",
  "page-local-environment-epirus-logo-alt": "Logotip d'Epirus",
  "page-local-environment-eth-app-desc": "Crea aplicacions impulsades per Ethereum amb una ordre. Ve amb una àmplia oferta de marcs d'IU i de plantilles DeFi per escollir.",
  "page-local-environment-eth-app-logo-alt": "Logotip de Create Eth App",
  "page-local-environment-framework-feature-1": "Característiques per activar una instància a la cadena de blocs local.",
  "page-local-environment-framework-feature-2": "Utilitats per compilar i provar els teus contractes intel·ligents.",
  "page-local-environment-framework-feature-3": "Complements per al desenvolupament de clients per crear la teva aplicació d'orientació a l'usuari dins del mateix projecte/repositori.",
  "page-local-environment-framework-feature-4": "Configuració per connectar a les xarxes Ethereum i implementar contractes, tant si es tracta d'una instància d'execució local com una de les xarxes públiques d'Ethereum.",
  "page-local-environment-framework-feature-5": "Aplicació de distribució descentralitzada - integracions amb les opcions d'emmagatzematge com IPFS.",
  "page-local-environment-framework-features": "Aquests marcs venen amb un munt de funcionalitats preparades per utilitzar, com ara:",
  "page-local-environment-frameworks-desc": " Recomanem triar un marc, especialment si estàs començant. Construir una Dapp completa requereix diferents tecnologies. Les estructures inclouen moltes de les característiques necessàries o proporcionen sistemes de connectors senzills perquè tries les eines que vulguis.",
  "page-local-environment-frameworks-title": "Marcs i piles predissenyades",
  "page-local-environment-hardhat-desc": "Hardhat és un entorn de desenvolupament d'Ethereum per a professionals.",
  "page-local-environment-hardhat-logo-alt": "Logotip de Hardhat",
  "page-local-environment-openZeppelin-desc": "Estalvia hores de temps de desenvolupament en compilar, actualitzar, implementar i interactuar amb contractes intel·ligents amb la nostra interfície de línia d'ordres.",
  "page-local-environment-openZeppelin-logo-alt": "Logotip d'OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App: tot el que necessites per començar a crear aplicacions descentralitzades impulsades per contractes intel·ligents",
  "page-local-environment-scaffold-eth-logo-alt": "Logotip de scaffold-eth",
  "page-local-environment-setup-meta-desc": "Guia sobre com triar la pila de programari per al desenvolupament d'Ethereum.",
  "page-local-environment-setup-meta-title": "Configuració per al desenvolupament local d'Ethereum",
  "page-local-environment-setup-subtitle": "Si estàs preparat per començar a crear, és hora d'escollir la teva pila.",
  "page-local-environment-setup-subtitle-2": " Aquí tens les eines i els marcs que pots utilitzar per ajudar-te a crear la teva aplicació Ethereum.",
  "page-local-environment-setup-title": "Configura el teu entorn local de desenvolupament",
  "page-local-environment-solidity-template-desc": "Una plantilla GitHub per a una configuració preestablerta per als teus contractes intel·ligents Solidity. Inclou una xarxa local Hardhat, Waffle per fer proves, Ethers per a la implementació de la cartera i molt més.",
  "page-local-environment-solidity-template-logo-alt": "Logotip de la plantilla de Solidity",
  "page-local-environment-truffle-desc": "Truffle Suite fa que els desenvolupadors passin de la idea a la dapp el més còmodament possible.",
  "page-local-environment-truffle-logo-alt": "Logotip de Truffle",
  "page-local-environment-waffle-desc": "La biblioteca de proves més avançada per a contractes intel·ligents. Fes-la servir sola o amb Scaffold-eth o Hardhat.",
  "page-local-environment-waffle-logo-alt": "Logotip de Waffle",
  "comp-tutorial-metadata-minute-read": "minut llegit",
  "page-tutorial-listing-policy-intro": "Abans d'enviar un tutorial, llegeix la nostra política de publicacions.",
  "comp-tutorial-metadata-tip-author": "Autor del consell",
  "page-tutorial-listing-policy": "política de publicació d'articles",
  "page-tutorial-new-github": "Ets nou al GitHub?",
  "page-tutorial-new-github-desc": "Planteja un tema: completa únicament la informació sol·licitada i enganxa el teu tutorial.",
  "page-tutorial-pull-request": "Crea una sol·licitud d'extracció",
  "page-tutorial-pull-request-btn": "Crea una sol·licitud d'extracció",
  "page-tutorial-pull-request-desc-1": "Segueix",
  "page-tutorial-pull-request-desc-2": "tutorials/your-tutorial-name/index.md",
  "page-tutorial-pull-request-desc-3": "l'estructura de nomenclatura.",
  "page-tutorial-raise-issue-btn": "Planteja tema",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Envia un tutorial",
  "page-tutorial-submit-tutorial": "Per enviar un tutorial, hauràs de fer servir GitHub. Et convidem a crear un assumpte o una sol·licitud d'extracció.",
  "page-tutorial-subtitle": "Benvingut a la llista seleccionada de tutorials de la comunitat.",
  "page-tutorial-tags-error": "Cap tutorial té totes aquestes etiquetes",
  "page-tutorial-title": "Tutorials de desenvolupament Ethereum",
  "page-tutorials-meta-description": "Cerca i explora en els tutorials de la comunitat Ethereum per tema.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Tutorials de desenvolupament d'Ethereum",
  "page-eth-buy-some": "Vols comprar Ethereum?",
  "page-eth-buy-some-desc": "És habitual confondre Ethereum i ETH. Ethereum és la cadena de blocs i ETH és l'actiu principal d'Ethereum. ETH és allò que probablement vols comprar.",
  "page-eth-cat-img-alt": "Gràfic del glif d'ETH amb un calidoscopi de gats",
  "page-eth-collectible-tokens": "Tokens col·leccionables",
  "page-eth-collectible-tokens-desc": "Tokens que representen un article de joc col·leccionable, una obra d'art digital o qualsevol altre actiu únic. Comunament coneguts com a objectes no fungibles (NFT).",
  "page-eth-cryptography": "Protegit per criptografia",
  "page-eth-cryptography-desc": "Els diners a Internet poden semblar nous, però estan assegurats per criptografia provada. Això protegeix la teva cartera, els teus ETH i les teves transaccions. ",
  "page-eth-currency-for-apps": "És la moneda de les aplicacions d'Ethereum.",
  "page-eth-currency-for-future": "La moneda per al nostre futur digital",
  "page-eth-description": "ETH és una criptomoneda. És un tipus de diner digital escàs que pots utilitzar a Internet, similar al Bitcoin. Si ets nou en el món de les criptomonedes, aquí podràs veure en què es diferencia l'ETH dels diners tradicionals.",
  "page-eth-earn-interest-link": "Guanya interessos",
  "page-eth-ethhub-caption": "Actualitzat sovint",
  "page-eth-ethhub-overview": "EthHub té una excel·lent descripció general, si ho vols",
  "page-eth-flexible-amounts": "Disponible en quantitats flexibles",
  "page-eth-flexible-amounts-desc": "ETH és divisible fins a 18 posicions decimals; per tant, no has de comprar 1 ETH complet. Pots comprar una fracció cada vegada, com ara només 0,000000000000000001 ETH si vols.",
  "page-eth-fuels": "ETH impulsa i assegura Ethereum",
  "page-eth-fuels-desc": "ETH és un element vital d'Ethereum. Quan envies ETH o utilitzes una aplicació a Ethereum, pagues una petita comissió en ETH per utilitzar la xarxa d'Ethereum. Aquesta comissió és un incentiu perquè un miner processi i verifiqui el que estàs intentant fer.",
  "page-eth-fuels-desc-2": "Els miners són com els registradors d'Ethereum: comproven i demostren que ningú faci trampes. Els miners que fan aquesta feina també són recompensats amb petites quantitats d'ETH de nova emissió.",
  "page-eth-fuels-desc-3": "La feina dels miners manté Ethereum segura i lliure de control centralitzat. En altres paraules,",
  "page-eth-fuels-more-staking": "Més informació sobre les apostes",
  "page-eth-fuels-staking": "ETH passarà a ser més important amb les apostes. Quan apostis els teus ETH, podràs mantenir Ethereum segura i guanyaràs recompenses. En aquest sistema, l'amenaça de perdre els teus ETH desincentiva els atacs.",
  "page-eth-get-eth-btn": "Aconsegueix ETH",
  "page-eth-gov-tokens": "Tokens de governança",
  "page-eth-gov-tokens-desc": "Tokens que representen el poder del vot en organitzacions descentralitzades.",
  "page-eth-has-value": "Per què ETH té valor?",
  "page-eth-has-value-desc": "ETH té valor de formes diferents per a persones diferents.",
  "page-eth-has-value-desc-2": "Per als usuaris d'Ethereum, ETH té valor perquè els permet pagar taxes per transacció.",
  "page-eth-has-value-desc-3": "D'altres ho veuen com un magatzem digital de valor perquè la creació de nous ETH es ralentitza amb el temps.",
  "page-eth-has-value-desc-4": "Més recentment, ETH s'ha tornat valuós per als usuaris d'aplicacions financeres a Ethereum. Això és perquè pots utilitzar ETH com a substitutiu per demanar préstecs criptogràfics o com a sistema de pagament.",
  "page-eth-has-value-desc-5": "És cert que moltes persones ho veuen com una inversió, similar al Bitcoin o qualsevol altra criptomoneda.",
  "page-eth-how-to-buy": "Com comprar Ether",
  "page-eth-how-to-buy-caption": "Actualitzat sovint",
  "page-eth-is-money": "ETH és diner global i digital.",
  "page-eth-last-updated": "Gener de 2019",
  "page-eth-mining-link": "Més informació sobre la mineria",
  "page-eth-monetary-policy": "Política monetària d'Ethereum",
  "page-eth-more-on-ethereum-link": "Més informació sobre Ethereum",
  "page-eth-no-centralized": "Sense control centralitzat ",
  "page-eth-no-centralized-desc": "ETH és descentralitzat i global. No existeix cap companyia ni banc que pugui decidir imprimir més ETH o canviï els termes d'ús.",
  "page-eth-non-fungible-tokens-link": "Tokens no fungibles",
  "page-eth-not-only-crypto": "ETH no és l'única criptomoneda a Ethereum",
  "page-eth-not-only-crypto-desc": "Qualsevol persona pot crear nous tipus d'actius i intercanviar-los a Ethereum. Es denominen \"tokens\". Les persones han \"tokenitzat\" les monedes tradicionals, els seus béns seents, el seu art i fins i tot ells mateixos!",
  "page-eth-not-only-crypto-desc-2": "Ethereum és la llar de milers d'objectes, alguns més útils i valuosos que d'altres. Els desenvolupadors estan creant constantment nous tokens que obren noves possibilitats i nous mercats.",
  "page-eth-not-only-crypto-desc-3": "Si vols obtenir més informació sobre els tokens, els nostres col·legues han escrit un parell de bons resums a EthHub:",
  "page-eth-open": "Obert a qualsevol",
  "page-eth-open-desc": "Només necessites una connexió a Internet i una cartera per acceptar ETH. No necessites tenir accés a un compte bancari per acceptar pagaments. ",
  "page-eth-p2p-payments": "Pagaments d'igual a igual",
  "page-eth-p2p-payments-desc": "Pots enviar els teus ETH sense un servei intermediari com ara un banc. És com donar diners en efectiu a una persona, però d'una manera segura, en qualsevol lloc i en qualsevol moment.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Classes populars de tokens",
  "page-eth-powers-ethereum": "ETH impulsa Ethereum",
  "page-eth-shit-coins": "Monedes inservibles",
  "page-eth-shit-coins-desc": "Com que fabricar noves monedes és fàcil, qualsevol persona pot fer-ho, fins i tot gent amb males intencions o dolentes. Fes sempre la teva recerca abans d'utilitzar-les!",
  "page-eth-stablecoins": "Monedes estables",
  "page-eth-stablecoins-desc": "Tokens que reflecteixen el valor de monedes tradicionals, com els dòlars. Això soluciona el problema de la volatilitat d'algunes criptomonedes.",
  "page-eth-stablecoins-link": "Aconsegueix monedes estables",
  "page-eth-stream-link": "Flux ETH",
  "page-eth-tokens-link": "Tokens d'Ethereum",
  "page-eth-trade-link-2": "Intercanvia tokens",
  "page-eth-underpins": "ETH aguanta el sistema financer d'Ethereum",
  "page-eth-underpins-desc": "No satisfeta amb els pagaments, la comunitat Ethereum està construint tot un sistema financer que és d'igual a igual i accessible a tothom.",
  "page-eth-underpins-desc-2": "Pots utilitzar ETH com a garantia per generar tokens de criptomonedes completament diferents. A més, pots demanar prestat, prestar i guanyar interessos en ETH i altres tokens recolzats per Ethereum.",
  "page-eth-uses": "Els usos d'ETH creixen cada dia",
  "page-eth-uses-desc": "Degut a que Ethereum és programable, els desenvolupadors poden donar forma a ETH de moltes maneres diferents.",
  "page-eth-uses-desc-2": "L'any 2015 l'únic que podies fer era enviar ETH d'un compte d'Ethereum a un altre compte. Aquí tens algunes de les coses que pots fer avui en dia.",
  "page-eth-uses-desc-3": "pagar algú o rebre fons en temps real.",
  "page-eth-uses-desc-4": "pots intercanviar ETH per altres tokens, també bitcoins.",
  "page-eth-uses-desc-5": "en ETH i altres tokens basats en Ethereum.",
  "page-eth-uses-desc-6": "accedir al món de les criptomonedes amb un valor constant i menys volàtil.",
  "page-eth-value": "Per què ether és valuós",
  "page-eth-video-alt": "Vídeo glif ETH",
  "page-eth-whats-eth": "Què és l'ether (ETH)?",
  "page-eth-whats-eth-hero-alt": "Il·lustració d'un grup de persones meravellades davant d'un glif ether (ETH)",
  "page-eth-whats-eth-meta-desc": "Allò que necessites conèixer per entendre ETH i el seu lloc a Ethereum.",
  "page-eth-whats-eth-meta-title": "Què és l'ether (ETH)?",
  "page-eth-whats-ethereum": "Què és Ethereum?",
  "page-eth-whats-ethereum-desc": "Si vols obtenir més informació sobre Ethereum, la tecnologia que s'amaga darrere d'ETH, fes una ullada a la nostra introducció.",
  "page-eth-whats-unique": "Què té d'únic ETH?",
  "page-eth-whats-unique-desc": "Hi ha diverses criptomonedes i molts altres tokens a Ethereum, però hi ha algunes coses que només ETH pot fer.",
  "page-eth-where-to-buy": "On aconseguir ETH",
  "page-eth-where-to-buy-desc": "Pots aconseguir ETH en un intercanvi o una cartera, però cada país té polítiques diferents. Comprova els serveis que et permeten comprar ETH.",
  "page-eth-yours": "És teu de veritat",
  "page-eth-yours-desc": "ETH et permet ser el teu propi banc. Pots controlar els teus fons amb la teva cartera com a prova de titularitat; no es necessiten terceres parts.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Adreça del contracte de dipòsit Eht2",
  "page-eth2-deposit-contract-address-caption": "Hem afegit espais per fer que l'adreça sigui més fàcil de llegir",
  "page-eth2-deposit-contract-address-check-btn": "Comprova l'adreça del contracte de dipòsit",
  "page-eth2-deposit-contract-checkbox1": "Ja he utilitzat una plataforma de llançament per configurar el meu validador Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Entenc que necessito utilitzar la plataforma per posar diners. Una simple transferència a aquesta adreça no funcionarà.",
  "page-eth2-deposit-contract-checkbox3": "Vaig a comprovar l'adreça del contracte de dipòsit en altres fonts.",
  "page-eth2-deposit-contract-confirm-address": "Confirma per mostrar l'adreça",
  "page-eth2-deposit-contract-copied": "Adreça copiada",
  "page-eth2-deposit-contract-copy": "Copia l'adreça",
  "page-eth2-deposit-contract-etherscan": "Mostra el contracte a Etherscan",
  "page-eth2-deposit-contract-h2": "Aquí no és on has apostat",
  "page-eth2-deposit-contract-launchpad": "Aposta utilitzant una plataforma",
  "page-eth2-deposit-contract-launchpad-2": "Utilitza la plataforma de llançament",
  "page-eth2-deposit-contract-meta-desc": "Verifica l'adreça del contracte de dipòsit per apostar a Eth2.",
  "page-eth2-deposit-contract-meta-title": "Adreça del contracte de dipòsit Eht2",
  "page-eth2-deposit-contract-read-aloud": "Llegeix l'adreça en veu alta",
  "page-eth2-deposit-contract-reveal-address-btn": "Mostra l'adreça",
  "page-eth2-deposit-contract-staking": " Per apostar el teu ETH a Eth2, has de fer servir el producte de la plataforma de llançament dedicat i seguir les instruccions. Enviar ETH a l'adreça pàgina no et convertirà en apostador i donarà lloc a una transacció fallida.",
  "page-eth2-deposit-contract-staking-check": "Comprova aquestes fonts",
  "page-eth2-deposit-contract-staking-check-desc": "Suposem que hi haurà moltes direccions falses i estafes per aquí. Per estar fora de perill, comprova l'adreça d'aposta d'Eth2 que estàs utilitzant amb l'adreça en aquesta pàgina. Recomanem que la comprovis també amb altres fonts de confiança.",
  "page-eth2-deposit-contract-staking-more-link": "Més informació sobre les apostes",
  "page-eth2-deposit-contract-stop-reading": "Deixa de llegir",
  "page-eth2-deposit-contract-subtitle": " Aquesta és l'adreça del contracte de participació Eth2. Utilitza aquesta pàgina per confirmar que estàs enviant fons a l'adreça correcta quan apostes.",
  "page-eth2-deposit-contract-warning": "Comprova cada caràcter amb deteniment.",
  "page-eth2-deposit-contract-warning-2": "Enviar fons a aquesta adreça no funcionarà i no et convertirà en apostador. Has de seguir les instruccions de la plataforma.",
  "page-eth2-bug-bounty-annotated-specs": "especificacions anotades",
  "page-eth2-bug-bounty-annotations": "Podria ser útil comprovar les següents anotacions:",
  "page-eth2-bug-bounty-client-bugs": "Errors de client Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Els clients executaran la cadena de balisa un cop que l'actualització s'hagi implementat. Els clients necessitaran seguir la lògica configurada a l'especificació i estaran protegits davant d'atacs potencials. Els errors que volem trobar estan relacionats amb la implementació del protocol.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Només els errors Prysm, Lighthouse i Teku són actualment aptes per a aquest tipus de recompensa. S'afegiran més clients a mesura que completin auditories i estiguin a punt de producció.",
  "page-eth2-bug-bounty-clients": "Clients destacats a les recompenses",
  "page-eth2-bug-bounty-clients-type-1": "problemes d'incompliment d'especificacions.",
  "page-eth2-bug-bounty-clients-type-2": "errors inesperats o vulnerabilitats de denegació de servei (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "qualsevol problema que causi divisions irreparables en el consens pel que fa a la resta de la xarxa.",
  "page-eth2-bug-bounty-docking": "acoblament",
  "page-eth2-bug-bounty-email-us": "Envia'ns un correu:",
  "page-eth2-bug-bounty-help-links": "Enllaços útils",
  "page-eth2-bug-bounty-hunting": "Normes per a la recerca d'errors",
  "page-eth2-bug-bounty-hunting-desc": "El programa de recompenses per errors és un programa de recompenses experimental i discrecional per a la nostra activa comunitat Ethereum, amb el qual encoratjar i recompensar aquells que contribueixen a millorar la plataforma. No és una competició. Has de saber que podem cancel·lar el programa en qualsevol moment i que els premis queden a la total discreció del panell de recompenses d'errors de la Fundació Ethereum. A més, no podem atorgar premis a persones que figurin en llistes de sancions o que estiguin en països que figurin en llistes de sancions (per exemple, Corea del Nord, Iran, etc.). Ets responsable de tots els impostos. Tots els premis estan subjectes a la llei aplicable. Finalment, la teva prova no ha d'infringir cap llei ni comprometre cap dada que no sigui teva.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Taula de classificació de recerca d'errors",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Troba errors d'Eth2 perquè t'afegeixin a aquesta taula de classificació",
  "page-eth2-bug-bounty-hunting-li-1": "Les incidències que ja hagi enviat un altre usuari o que ja siguin conegudes per als que mantenen les especificacions i els clients no generaran recompenses.",
  "page-eth2-bug-bounty-hunting-li-2": "La revelació pública d'una vulnerabilitat la fa inelegible per a una recompensa.",
  "page-eth2-bug-bounty-hunting-li-3": "Els investigadors de la Fundació Ethereum i els empleats dels equips de clients d'Eth2 no poden rebre recompenses.",
  "page-eth2-bug-bounty-hunting-li-4": "El programa de recompenses d'Ethereum té en compte nombroses variables a l'hora de determinar les recompenses. Les definicions d'elegibilitat, puntuació i tots els termes relacionats amb una recompensa resten a la discreció exclusiva del panell de recompenses per errors de la Fundació Ethereum.",
  "page-eth2-bug-bounty-leaderboard": "Mostra tota la taula de classificació",
  "page-eth2-bug-bounty-leaderboard-points": "punts",
  "page-eth2-bug-bounty-ledger-desc": "L'especificació de la cadena de balisa detalla el disseny racional i els canvis proposats a la xarxa Ethereum mitjançant una actualització de la cadena de balisa.",
  "page-eth2-bug-bounty-ledger-title": "Els errors d'especificació de la cadena de balisa",
  "page-eth2-bug-bounty-meta-description": "Una descripció general del programa de recerca d'errors d'Eth2: com involucrar-se i informació sobre les recompenses.",
  "page-eth2-bug-bounty-meta-title": "Programa de recompenses per recerca d'errors d'Eth2",
  "page-eth2-bug-bounty-not-included": "No inclòs",
  "page-eth2-bug-bounty-not-included-desc": "La cadena de fragments i les millores d'acoblament encara estan en desenvolupament actiu, i per tant, no formen part del programa de recompenses.",
  "page-eth2-bug-bounty-owasp": "Mostra el mètode OWASP",
  "page-eth2-bug-bounty-points": "La Fundació Ethereum també concedirà punts basats en:",
  "page-eth2-bug-bounty-points-error": "Error en la càrrega de dades... actualitza.",
  "page-eth2-bug-bounty-points-exchange": "Intercanvi de punts",
  "page-eth2-bug-bounty-points-loading": "S'estan carregant les dades...",
  "page-eth2-bug-bounty-points-payout-desc": "La Fundació Ethereum abonarà l'import en dòlars en ETH o DAI.",
  "page-eth2-bug-bounty-points-point": "1 punt",
  "page-eth2-bug-bounty-points-rights-desc": "La Fundació Ethereum es reserva el dret de canviar-ho sense previ avís.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Qualitat de la descripció",
  "page-eth2-bug-bounty-quality-desc": ": es paguen recompenses més altes per informes clars i ben escrits.",
  "page-eth2-bug-bounty-quality-fix": "Qualitat de la correcció",
  "page-eth2-bug-bounty-quality-fix-desc": ", si s'inclou: es paguen recompenses més altes per informes que descriguin amb claredat com arreglar el problema.",
  "page-eth2-bug-bounty-quality-repro": "Qualitat de la reproducció",
  "page-eth2-bug-bounty-quality-repro-desc": ": inclou codi de prova, scripts i instruccions detallades. Com més fàcil sigui reproduir i verificar la vulnerabilitat, major serà la recompensa.",
  "page-eth2-bug-bounty-questions": "Preguntes?",
  "page-eth2-bug-bounty-rules": "Llegeix les normes",
  "page-eth2-bug-bounty-shard-chains": "cadenes de fragments",
  "page-eth2-bug-bounty-slogan": "Recompenses per errors d'Eth2",
  "page-eth2-bug-bounty-specs": "Llegeix totes les especificacions",
  "page-eth2-bug-bounty-specs-docs": "Documents d'especificacions",
  "page-eth2-bug-bounty-submit": "Envia un error",
  "page-eth2-bug-bounty-submit-desc": "Per cada error que trobis obtindràs una recompensa en forma de punts. Els punts que guanyis depenen de la gravetat de l'error. La Fundació Ethereum determina la gravetat mitjançant el mètode OWASP.",
  "page-eth2-bug-bounty-subtitle": "Guanya fins a 50.000 dòlars i un lloc a la taula de classificació per trobar errors del protocol Eth2 i del client.",
  "page-eth2-bug-bounty-title": "Obert per a l'enviament",
  "page-eth2-bug-bounty-title-1": "La Cadena de Balisa",
  "page-eth2-bug-bounty-title-2": "Opció de bifurcació",
  "page-eth2-bug-bounty-title-3": "Contracte de dipòsit de Solidity",
  "page-eth2-bug-bounty-title-4": "Xarxa entre iguals",
  "page-eth2-bug-bounty-type-1": "errors de seguretat o errors d'infracció de la finalitat.",
  "page-eth2-bug-bounty-type-2": "vectors de denegació de servei (DOS)",
  "page-eth2-bug-bounty-type-3": "inconsistències en supòsits, com situacions en què els validadors honestos poden ser sancionats.",
  "page-eth2-bug-bounty-type-4": "inconsistències de càlculs o paràmetres.",
  "page-eth2-bug-bounty-types": "Tipus d'error",
  "page-eth2-bug-bounty-validity": "Errors vàlids",
  "page-eth2-bug-bounty-validity-desc": "Aquest programa de recompenses d'errors està pensat per trobar errors en l'especificació de la cadena de balisa Eth2 i en les implementacions de clients Prysm, Lighthouse i Teku.",
  "page-eth2-bug-bountycard-critical": "Crític",
  "page-eth2-bug-bountycard-critical-risk": "Envia un error de risc crític",
  "page-eth2-bug-bountycard-h2": "Mitjà",
  "page-eth2-bug-bountycard-high": "Alt",
  "page-eth2-bug-bountycard-high-risk": "Envia un error de risc alt",
  "page-eth2-bug-bountycard-label-1": "Fins a 1.000 punts",
  "page-eth2-bug-bountycard-label-2": "Fins a 2.000 DAI",
  "page-eth2-bug-bountycard-label-3": "Fins a 5.000 punts",
  "page-eth2-bug-bountycard-label-4": "Fins a 10.000 DAI",
  "page-eth2-bug-bountycard-label-5": "Fins a 10.000 punts",
  "page-eth2-bug-bountycard-label-6": "Fins a 20.000 DAI",
  "page-eth2-bug-bountycard-label-7": "Fins a 25.000 punts",
  "page-eth2-bug-bountycard-label-8": "Fins a 50.000 DAI",
  "page-eth2-bug-bountycard-li-1": "Impacte baix, probabilitat mitjana",
  "page-eth2-bug-bountycard-li-2": "Impacte mitjà, probabilitat baixa",
  "page-eth2-bug-bountycard-li-3": "Impacte alt, probabilitat baixa",
  "page-eth2-bug-bountycard-li-4": "Impacte mitjà, probabilitat mitjana",
  "page-eth2-bug-bountycard-li-5": "Impacte baix, probabilitat alta",
  "page-eth2-bug-bountycard-li-6": "Impacte alt, probabilitat mitjana",
  "page-eth2-bug-bountycard-li-7": "Impacte mitjà, probabilitat alta",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Baix",
  "page-eth2-bug-bountycard-low-risk": "Envia un error de risc baix",
  "page-eth2-bug-bountycard-medium-risk": "Envia un error de risc mitjà",
  "page-eth2-bug-bountycard-subheader": "Gravetat",
  "page-eth2-bug-bountycard-subheader-2": "Exemple",
  "page-eth2-bug-bountycard-text": "L'atacant de vegades pot posar un node en un estat que fa que invalidi un de cada cent certificats fets per un validador",
  "page-eth2-bug-bountycard-text-1": "Un atacant pot dur a terme amb èxit atacs d'eclipsi en nodes amb identificacions paritàries amb 4 bytes zero inicials",
  "page-eth2-bug-bountycard-text-2": "Hi ha un error de consens entre dos clients, però és difícil o poc pràctic per a l'atacant activar l'esdeveniment.",
  "page-eth2-bug-bountycard-text-3": "  Hi ha un error de consens entre dos clients i és trivial per a un atacant activar l'esdeveniment.",
  "page-eth2-get-involved-btn-1": "Veure els clients",
  "page-eth2-get-involved-btn-2": "Més informació sobre les apostes",
  "page-eth2-get-involved-btn-3": "Cerca errors",
  "page-eth2-get-involved-bug": "Un error pot ser:",
  "page-eth2-get-involved-bug-hunting": "A la recerca d'errors",
  "page-eth2-get-involved-bug-hunting-desc": "Cerca i informa d'errors a les especificacions de millora d'Eth2 o en els mateixos clients. Pots guanyar fins a 50.000 USD i un lloc a la taula de classificació.",
  "page-eth2-get-involved-bug-li": "problemes d'incompliment d'especificacions",
  "page-eth2-get-involved-bug-li-2": "errors d'infracció de la finalitat",
  "page-eth2-get-involved-bug-li-3": "vectors de denegació de servei (DOS)",
  "page-eth2-get-involved-bug-li-4": "i més...",
  "page-eth2-get-involved-date": "Data final: 23 de desembre de 2020",
  "page-eth2-get-involved-desc-1": "Executar un client significa que seràs un actiu participant a Ethereum. El teu client ajudarà a fer un seguiment de les transaccions i comprovar nous blocs.",
  "page-eth2-get-involved-desc-2": "Si tens ETH, pots depositar-los per fer-te validador i ajudar a mantenir la xarxa segura. Com a validador pots guanyar recompenses en ETH.",
  "page-eth2-get-involved-desc-3": "Apunta't a l'esforç comunitari de comprovació! Ajuda a comprovar les millores d'Eth2 abans del seu llançament, troba errors i guanya recompenses.",
  "page-eth2-get-involved-ethresearch-1": "Fragmentació",
  "page-eth2-get-involved-ethresearch-2": "Transició d'Eth1 a Eth2",
  "page-eth2-get-involved-ethresearch-3": "Fragments i execució d'estat",
  "page-eth2-get-involved-ethresearch-4": "Tots els temes de recerca",
  "page-eth2-get-involved-grants": "Concessions per a la comunitat de participació",
  "page-eth2-get-involved-grants-desc": "Ajuda a crear eines i contingut educatiu per a la comunitat participant",
  "page-eth2-get-involved-how": "Com vols participar?",
  "page-eth2-get-involved-how-desc": "La comunitat d'Ethereum sempre es beneficiarà com més gent executi clients, aposti i busqui errors.",
  "page-eth2-get-involved-join": "Uneix-te a la recerca",
  "page-eth2-get-involved-join-desc": " Com la majoria de les coses amb Ethereum, gran part de la recerca és pública. Això vol dir que pots participar en les discussions o simplement llegir el que els investigadors d'Ethereum han de dir. Ethresearch.ch cobreix més que les actualitzacions d'Eth2, però hi ha molta atenció posada en Eth2.",
  "page-eth2-get-involved-meta-description": "Com participar en Eth2: executa nodes, fes apostes, troba errors i més.",
  "page-eth2-get-involved-more": "Més informació",
  "page-eth2-get-involved-run-clients": "Executa clients de la cadena de balisa",
  "page-eth2-get-involved-run-clients-desc": "La clau per a la seguretat a llarg termini d'Ethereum és una forta distribució de clients. Un client és un programari que executa la cadena de blocs, comprova transaccions i crea nous blocs. Cada client té les seves pròpies característiques, així que tria el client amb el qual et sentis còmode.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Aposta el teu ETH",
  "page-eth2-get-involved-stake-desc": "Pots apostar el teu ETH per ajudar a mantenir protegida la cadena de balisa.",
  "page-eth2-get-involved-stake-eth": "Aposta ETH",
  "page-eth2-get-involved-subtitle": "Aquestes són totes les maneres que hi ha per ajudar amb Ethereum i els futurs esforços relacionats amb Eth2.",
  "page-eth2-get-involved-title-1": "Executa un client",
  "page-eth2-get-involved-title-2": "Aposta el teu ETH",
  "page-eth2-get-involved-title-3": "Cerca errors",
  "page-eth2-get-involved-written-go": "Escrit en Go",
  "page-eth2-get-involved-written-java": "Escrit en Java",
  "page-eth2-get-involved-written-javascript": "Escrit en JavaScript",
  "page-eth2-get-involved-written-net": "Escrit en .NET",
  "page-eth2-get-involved-written-nim": "Escrit en Nim",
  "page-eth2-get-involved-written-python": "Escrit en Python",
  "page-eth2-get-involved-written-rust": "Escrit en Rust",
  "eth2-client-cortex-logo-alt": "Logotip de Cortex",
  "eth2-client-lighthouse-logo-alt": "Logotip de Lighthouse",
  "eth2-client-lodestar-logo-alt": "Logotip de Lodestar",
  "eth2-client-nimbus-logo-alt": "Logotip de Nimbus",
  "eth2-client-prysm-logo-alt": "Logotip de Prysm",
  "eth2-client-teku-logo-alt": "Logotip de Teku",
  "eth2-rhino-img-alt": "Gràfic d'Eth 2 Rhino carregant per davant",
  "page-eth2-answer-1": "Pensa en Eth2 com un conjunt d’actualitzacions que s’afegeixen per millorar l’Ethereum que fem servir avui. Aquestes actualitzacions inclouen la creació d’una nova cadena anomenada Cadena de Balisa i fins a 64 cadenes conegudes com a fragments.",
  "page-eth2-answer-2": "Aquestes són independents de la xarxa principal Ethereum que fem servir avui, però no la substituiran. En lloc d'això, la xarxa principal s'acoblarà o \"fusionarà\" amb aquest sistema paral·lel que s'afegirà amb el pas del temps.",
  "page-eth2-answer-4": "Dit d’una altra manera, l’Ethereum que fem servir avui acabarà incorporant totes les funcions cap a les quals estem avançant amb la visió d'Eth2.",
  "page-eth2-beacon-chain-btn": "Més informació sobre la Cadena de Balisa",
  "page-eth2-beacon-chain-date": "La Cadena de Balisa es va publicar l’1 de desembre de 2020.",
  "page-eth2-beacon-chain-desc": "La primera incorporació d’Eth2 a l’ecosistema. La Cadena de Balisa aporta la participació a Ethereum, posa les bases per a futures actualitzacions i, finalment, coordinarà el nou sistema.",
  "page-eth2-beacon-chain-estimate": "La Cadena de Balisa està en funcionament",
  "page-eth2-beacon-chain-title": "La Cadena de Balisa",
  "page-eth2-bug-bounty": "Veure el programa de recompenses per errors",
  "page-eth2-clients": "Consulta els clients d'Eth2",
  "page-eth2-deposit-contract-title": "Consulta l'adreça del contracte de dipòsit",
  "page-eth2-diagram-ethereum-mainnet": " Xarxa principal d'Ethereum",
  "page-eth2-diagram-h2": "Com s’integren les actualitzacions",
  "page-eth2-diagram-link-1": "Més informació sobre la prova de treball",
  "page-eth2-diagram-link-2": "Més informació sobre les cadenes de fragments",
  "page-eth2-diagram-mainnet": "Xarxa principal",
  "page-eth2-diagram-p": "La xarxa principal d'Ethereum continuarà existint en la seva forma actual durant un temps. Això significa que la Cadena de Balisa i les actualitzacions de fragments no interrompran la xarxa.",
  "page-eth2-diagram-p-1": "La xarxa principal s'integrarà finalment amb el nou sistema introduït per les actualitzacions d'Eth2.",
  "page-eth2-diagram-p-2": "La Cadena de Balisa es convertirà en el conductor d’Ethereum, coordinant els validadors i establint el ritme per a la creació de blocs.",
  "page-eth2-diagram-p-3": "Al principi, existirà per separat de la xarxa principal i gestionarà els validadors – no tindrà res a veure amb contractes, transaccions o comptes intel·ligents.",
  "page-eth2-diagram-p-4": "Els fragments proporcionaran moltes dades addicionals per ajudar a augmentar la quantitat de transaccions que la xarxa principal pot gestionar. La Cadena de Balisa coordinarà aquestes dades.",
  "page-eth2-diagram-p-5": "Però totes les transaccions confiaran en la xarxa principal, que continuarà existint tal i com la coneixem avui en dia - protegida per la prova de treball i els miners.",
  "page-eth2-diagram-p-6": "La xarxa principal es fusionarà amb el sistema de prova de treball, coordinat per la Cadena de Balisa.",
  "page-eth2-diagram-p-8": "Això farà que la xarxa principal es transformi en un fragment dins del nou sistema. Els miners ja seran necessaris, ja que tot Ethereum estarà protegit pels validadors.",
  "page-eth2-diagram-p10": "Eth2 no és una migració ni quelcom únic. Es descriu com una sèrie de millores per treballar en l'apertura del veritable potencial d' Ethereum. Així és com encaixa tot.",
  "page-eth2-diagram-scroll": "Desplaça't per explorar: toca per obtenir més informació.",
  "page-eth2-diagram-shard": "Fragment (1)",
  "page-eth2-diagram-shard-1": "Fragment (...)",
  "page-eth2-diagram-shard-2": "Fragment (2)",
  "page-eth2-diagram-shard-3": "Fragment (...)",
  "page-eth2-diagram-validators": "Més informació sobre els validadors",
  "page-eth2-dive": "Submergeix-te en la visió",
  "page-eth2-dive-desc": "Com farem Ethereum més escalable, segur i sostenible? Tot al mateix temps que es manté la base de la descentralització d'Ethereum.",
  "page-eth2-docking": "L'acoblament",
  "page-eth2-docking-answer-1": "L'acoblament és quan la xarxa principal es torna un fragment. Això succeirà després de l'exitosa implementació de les cadenes de fragments.",
  "page-eth2-docking-btn": "Més informació sobre l'acoblament",
  "page-eth2-docking-desc": "La xarxa principal d'Ethereum necessitarà \"acoblar-se\" o \"fusionar-se\" amb la Cadena de Balisa en algun punt. Això permetrà apostar a tota la xarxa i assenyalarà el final de la mineria d'energia intensa.",
  "page-eth2-docking-estimate": "Estimació: 2022",
  "page-eth2-docking-mainnet": "Què és la xarxa principal?",
  "page-eth2-docking-mainnet-eth2": "Acoblament de la xarxa principal amb Eth2",
  "page-eth2-eth-blog": "Blog d'ethereum.org",
  "page-eth2-explore-btn": "Explora les millores",
  "page-eth2-get-involved": "Involucra't a l'Eth2",
  "page-eth2-get-involved-2": "Involucra't",
  "page-eth2-head-to": "Cap a",
  "page-eth2-help": "Vols ajuda amb Eth2?",
  "page-eth2-help-desc": "Hi ha moltes oportunitats per contribuir en les millores d'Eth2, ajudar amb les proves i inclús guanyar recompenses.",
  "page-eth2-index-staking": "Ja han arribat les apostes",
  "page-eth2-index-staking-desc": "La clau per a les millores d'Eth2 està en la introducció de les apostes. Si vols utilitzar els teus ETH per ajudar a mantenir segura la xarxa Ethereum, assegura't de seguir els següents passos.",
  "page-eth2-index-staking-learn": "Més informació sobre les apostes",
  "page-eth2-index-staking-learn-desc": "La cadena de balisa portarà les apostes a Ethereum. Això significa que si tens ETH pots fer-ne un bé públic assegurant la xarxa i guanyant més ETH durant el procés.",
  "page-eth2-index-staking-step-1": "1. Configura'l amb la plataforma de llançament",
  "page-eth2-index-staking-step-1-btn": "Visita la plataforma de llançament de les apostes",
  "page-eth2-index-staking-step-1-desc": "Per apostar a Eth2 necessitaràs utilitzar la plataforma de llançament, això t'ajudarà a seguir els passos durant el procés.",
  "page-eth2-index-staking-step-2": "2. Confirma l'adreça d'apostes",
  "page-eth2-index-staking-step-2-btn": "Confirma l'adreça del contracte de dipòsit",
  "page-eth2-index-staking-step-2-desc": "Abans d'apostar els teus ETH, assegura't de comprovar que tens l'adreça correcta. Hauràs d'haver passat per la plataforma abans de fer-ho.",
  "page-eth2-index-staking-sustainability": "Més sostenible",
  "page-eth2-just-docking": "Més informació sobre l'acoblament",
  "page-eth2-meta-desc": "Un resum de les millores d'Ethereum 2.0 i la visió que esperen fer realitat.",
  "page-eth2-meta-title": "Les millores d'Eth2",
  "page-eth2-miners": "Més informació sobre els miners",
  "page-eth2-more-on-upgrades": "Més informació sobre les millores d'Eth2",
  "page-eth2-proof-of-stake": "prova de participació",
  "page-eth2-proof-of-work": "prova de treball",
  "page-eth2-proof-stake-link": "Més informació sobre la prova de participació",
  "page-eth2-question-1-desc": "Eth2 és un conjunt de diverses millores amb diferents dates d'enviament.",
  "page-eth2-question-1-title": "Quan s'enviarà Eth2?",
  "page-eth2-question-2-desc": "No és correcte pensar en Eth2 com una cadena de blocs independent.",
  "page-eth2-question-2-title": "És Eth2 una cadena de blocs independent?",
  "page-eth2-question-3-answer-2": "La cadena de fragments i les millores d'acoblament poden impactar els desenvolupadors de dapp. Però les especificacions encara no han estat finalitzades, per tant no es requereix una acció immediata.",
  "page-eth2-question-3-answer-3": "Parla amb l'equip de recerca i desenvolupament d'Eth2 a ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Visita ethresear.ch",
  "page-eth2-question-3-desc": "Ara mateix no has de fer res per preparar-te per a Eth2.",
  "page-eth2-question-3-title": "Com em preparo per a Eth2?",
  "page-eth2-question-4-answer-1": "Cada cop que envies una transacció o utilitzes una dapp avui en dia, estàs utilitzant Eth1. Aquest és l'Ethereum que està protegit pels miners.",
  "page-eth2-question-4-answer-2": "\"Eth1\", o xarxa principal, continuarà funcionant amb normalitat fins a l'acoblament,.",
  "page-eth2-question-4-answer-3": "Després de l'acoblament, els validadors asseguraran tota la xarxa mitjançant la prova de participació.",
  "page-eth2-question-4-answer-6": "Qualsevol persona pot esdevenir un validador apostant els seus ETH.",
  "page-eth2-question-4-answer-7": "Més informació sobre les apostes",
  "page-eth2-question-4-answer-8": "La millores a la cadena de balisa i a la cadena de fragments no interrompran Eth1, ja que es desenvolupen per separat.",
  "page-eth2-question-4-desc": "Eth1 fa referència a la xarxa principal d'Ethereum on fas transaccions avui en dia.",
  "page-eth2-question-4-title": "Què és Eth1?",
  "page-eth2-question-5-answer-1": "Per esdevenir un validador total de la xarxa, necessitaràs apostar 32 ETH. Si no tens aquesta quantitat, o no estàs disposat a apostar tant, pot unir-te als grups d'apostes. Aquests grups et permetran apostar menys quantitat i guanyar fraccions de les recompenses totals.",
  "page-eth2-question-5-desc": "Necessitaràs fer servir la plataforma de llançament de les apostes o unir-te a un grup d'apostes.",
  "page-eth2-question-5-title": "Com puc participar?",
  "page-eth2-question-6-answer-1": "De moment, no has de fer res. Però et recomanem estar al dia del desenvolupament de les cadenes de fragments i les millores d'acoblament.",
  "page-eth2-question-6-answer-3": "Danny Ryan, de la fundació Ethereum, actualitza regularment la comunitat:",
  "page-eth2-question-6-answer-4": "Ben Edgington, de ConsenSys, té un butlletí informatiu setmanal d'Eth2:",
  "page-eth2-question-6-answer-5": "També pots unir-te als debats del grup de recerca i desenvolupament d'Eth2 a ethresear.ch.",
  "page-eth2-question-6-desc": "La teva dapp no es veurà afectada per cap millora imminent. No obstant, futures millores poden requerir alguns canvis.",
  "page-eth2-question-6-title": "Què he de fer amb la meva dapp?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(Implementació del .NET)",
  "page-eth2-question-7-desc": "Diversos equips de tota la comunitat estan treballant en les diferents millores d'Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Implementació del Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(Implementació del JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Implementació del Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Implementació del Go)",
  "page-eth2-question-7-teams": "Els equips del client d'Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Implementació del Java)",
  "page-eth2-question-7-title": "Qui construeix Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Implementació del Python)",
  "page-eth2-question-8-answer-1": "Les millores d'Eth2 ajudaran al creixement d'Ethereum d'un mode descentralitzat, mentre es manté la seguretat i s'incrementa la sostenibilitat.",
  "page-eth2-question-8-answer-2": "Potser el problema més obvi és que Ethereum necessita poder gestionar més de 15-45 transaccions per segon. Però les millores d'Eth2 també solucionen alguns altres problemes amb l'Ethereum actual.",
  "page-eth2-question-8-answer-3": "La xarxa té tanta demanda que fa que Ethereum sigui car d'utilitzar. Els nodes a la xarxa estan tenint problemes amb la mida d'Ethereum i la quantitat de dades que els ordinadors han de processar. I l'algoritme subjacent que manté Ethereum segur i descentralitzat consumeix molta energia i necessita ser més ecològic.",
  "page-eth2-question-8-answer-4": "Moltes de les coses que estan canviant sempre han estat al full de ruta d'Ethereum, inclús des de 2015. Però les condicions actuals fan que la necessitat de millores sigui encara molt més gran.",
  "page-eth2-question-8-answer-6": "Explora la visió d'Eth2",
  "page-eth2-question-8-desc": "L'Ethereum que utilitzem avui en dia necessita oferir una millor experiència als usuaris finals i als participants a la xarxa.",
  "page-eth2-question-8-title": "Per què és necessari Eth2?",
  "page-eth2-question-9-answer-1": "El paper més actiu que pots jugar és apostar els teus ETH.",
  "page-eth2-question-9-answer-2": "També pot ser que vulguis executar un segon client per ajudar a millorar la diversitat dels clients.",
  "page-eth2-question-9-answer-3": "Si ets més tècnic, pots ajudar a trobar errors en els nous clients.",
  "page-eth2-question-9-answer-4": "També pots prendre part en les discussions tècniques amb els investigadors d'Ethereum a ethresear.ch.",
  "page-eth2-question-9-desc": "No has de ser tècnic per contribuir. La comunitat busca contribucions de gent amb tot tipus d'habilitats.",
  "page-eth2-question-9-stake-eth": "Aposta ETH",
  "page-eth2-question-9-title": "Com puc contribuir a Eth2?",
  "page-eth2-question-10-answer-1": "Som reticents a parlar massa en termes d'un full de ruta tècnic perquè això és programari: les coses poden canviar. Creiem que és més fàcil comprendre què succeeix quan es llegeixen els resultats.",
  "page-eth2-question-10-answer-1-link": "Visualitza les millores",
  "page-eth2-question-10-answer-2": "Però si has estat seguint els debats, així és com les millores encaixen en els fulls de ruta tècnics.",
  "page-eth2-question-10-answer-3": "La fase 0 descriu el treball per activar la Cadena de Balisa.",
  "page-eth2-question-10-answer-5": "La fase 1 dels fulls de ruta tècnics se centren en la implementació de les cadenes de fragments.",
  "page-eth2-question-10-answer-6": "L'acoblament de la xarxa principal a Eth2 és una extensió del treball fet per implementar les cadenes de fragments i s'ha denominat fase 1.5. Però és un moment decisiu, ja que l'Ethereum que coneixem actualment es fusiona amb les altres millores d'Eth2. A més, és quan Ethereum passa totalment a la prova de participació.",
  "page-eth2-question-10-answer-7": "No està del tot clar encara què succeirà a la fase 2. Encara és un punt d'intensa recerca i discussió. El pla inicial era afegir una funcionalitat extra a les cadenes de fragments però podria no ser necessari amb un full de ruta centrat en l'acumulació.",
  "page-eth2-question-10-answer-8": "Més informació sobre el full de ruta centrat en l'acumulació",
  "page-eth2-question-10-desc": "Les fases descriuen les fases de treball i se centren en el full de ruta tècnic d'Eth2.",
  "page-eth2-question-10-title": "Quines són les fases d'Eth2?",
  "page-eth2-question-title": "Preguntes freqüents",
  "page-eth2-question3-answer-1": "En realitat, els propietaris d'Eth no han de fer res. Els teus ETH no han de canviar o millorar. És molt probable que hi hagi estafadors dient-te el contrari, així que estigues atent.",
  "page-eth2-scalable": "Més escalable",
  "page-eth2-scalable-desc": "Ethereum ha d'admetre milers de transaccions per segon per fer que les aplicacions siguin més ràpides i barates d'utilitzar.",
  "page-eth2-secure": "Més segura",
  "page-eth2-secure-desc": "Ethereum necessita ser més segura. Mentre l'adopció d'Ethereum creix, el protocol necessita ser més segur contra qualsevol forma d'atac.",
  "page-eth2-shard-button": "Més informació sobre les cadenes de fragments",
  "page-eth2-shard-date": "La introducció de les cadenes de fragments, la segona millora, s'espera en algun moment de 2021.",
  "page-eth2-shard-desc": "Les cadenes de fragments expandiran la capacitat d'Ethereum de processar transaccions i emmagatzemar dades. Les mateixes cadenes guanyaran més atributs amb el temps, desplegades en diverses fases.",
  "page-eth2-shard-estimate": "Estimació: 2021",
  "page-eth2-shard-lower": "Més informació sobre les cadenes de fragments",
  "page-eth2-shard-title": "Cadenes de fragments",
  "page-eth2-stay-up-to-date": "Mantingue't al dia",
  "page-eth2-stay-up-to-date-desc": "Obtén les últimes notícies sobre els investigadors i desenvolupadors que treballen en les millores d'Eth2.",
  "page-eth2-sustainable-desc": "Ethereum necessita ser millor per al medi ambient. La tecnologia d'avui en dia requereix molta potencia de computació i energia.",
  "page-eth2-take-part": "Participa en la recerca",
  "page-eth2-take-part-desc": "Els investigadors i els entusiastes d'Ethereum es reuneixen aquí per discutir sobre els esforços de recerca, inclòs cada detall d'Eth2.",
  "page-eth2-the-upgrades": "Les millores d'Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 és un conjunt d'actualitzacions que milloren l'escalabilitat, la seguretat i la sostenibilitat d'Ethereum. Encara que cadascuna es treballa per separat, tenen certes dependències que determinen quan s'implementaran.",
  "page-eth2-unofficial-roadmap": "Aquest no és el full de ruta oficial. Així és com nosaltres veiem què està succeïnt basant-nos en la informació que tenim. Però així és la tecnologia, les coses poden canviar en un instant. Per tant, no llegeixis això com un compromís.",
  "page-eth2-upgrade-desc": "L' Ethereum que coneixem i estimem, una mica més escalable, més segura i més sostenible...",
  "page-eth2-upgrades": "Les millores d'Eth2",
  "page-eth2-upgrades-aria-label": "Menú de millores d'Eth2",
  "page-eth2-upgrades-beacon-chain": "La Cadena de Balisa",
  "page-eth2-upgrades-docking": "Acoblament de la xarxa principal amb Eth2",
  "page-eth2-upgrades-guide": "Guia de les millores d'Eth2",
  "page-eth2-upgrades-shard-chains": "Les cadenes de fragments",
  "page-eth2-upgrading": "Millorar Ethereum a nivells radicalment nous",
  "page-eth2-vision": "La visió",
  "page-eth2-vision-btn": "Més informació sobre la visió d'Eth2",
  "page-eth2-vision-desc": "Per incorporar Ethereum a la societat i donar servei a tota la humanitat, hem d'aconseguir que sigui més escalable, segura i sostenible.",
  "page-eth2-vision-upper": "Visió d'Eth2",
  "page-eth2-what-to-do": "Què has de fer?",
  "page-eth2-what-to-do-desc": "Si ets un usuari de dapp o un propietari d'ETH, no has de fer res. Si ets un desenvolupador o vols començar a apostar, hi ha diferents maneres d'implicar-se actualment.",
  "page-eth2-whats-eth2": "Què és Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 es refereix a un conjunt de millores interconnectades que farà Ethereum més escalable, més segura i més sostenible. Aquestes millores estan essent dissenyades per diversos equips de tot l'ecosistema d'Ethereum.",
  "page-eth2-whats-ethereum": "Espera, què és Ethereum?",
  "page-eth2-whats-new": "Què hi ha de nou a Eth2?",
  "page-eth2-just-staking": "Participació",
  "page-eth2-staking-image-alt": "Imatge de la mascota Rhino per a la plataforma d'Eth2.",
  "page-eth2-staking-51-desc-1": "La participació fa que unir-se a la xarxa com a validador sigui més accessible, de manera que és probable que hi hagi més validadors a la xarxa dels que hi ha actualment. Això farà que aquest tipus d'atac sigui encara més difícil a mesura que el cost d'un atac augmenti.",
  "page-eth2-staking-accessibility": "Més accesible",
  "page-eth2-staking-accessibility-desc": "Amb requeriments de maquinari més fàcils i amb l'oportunitat de participar en grup si no disposes de 32 ETH, més persones podran unir-se a la xarxa. Això farà que Ethereum sigui més descentralitzada i segura en disminuir la superfície d'atac.",
  "page-eth2-staking-at-stake": "El teu ETH està en joc",
  "page-eth2-staking-at-stake-desc": "Degut a que has de participar amb els teus ETH per poder validar transaccions i crear nous blocs, pots perdre'l si decideixes provar d'enganyar el sistema.",
  "page-eth2-staking-benefits": "Beneficis de participació a Ethereum",
  "page-eth2-staking-check-address": "Comprova l'adreça de dipòsit",
  "page-eth2-staking-consensus": "Més informació sobre els mecanismes de consens",
  "page-eth2-staking-deposit-address": "Comprova l'adreça de dipòsit",
  "page-eth2-staking-deposit-address-desc": "Si has seguit les instruccions de configuració a la plataforma, sabràs que has de fer una transacció al contracte de dipòsit de participacions. Et recomanem que comprovis l'adreça amb molta cura. Pots trobar l'adreça oficial a ethereum.org i a un nombre d'altres llocs web de confiança.",
  "page-eth2-staking-desc-1": "S'atorguen recompenses per accions que ajudin a la xarxa a assolir el consens. Obtindràs recompenses per agrupar transaccions en un nou bloc o comprovar el treball d'altres validadors, perquè això és el que manté la cadena funcionant de forma segura.",
  "page-eth2-staking-desc-2": "Tot i que pots guanyar recompenses per fer feines que beneficiïn la xarxa, pots perdre ETH per actes malintencionats, desconnectar-te i no validar.",
  "page-eth2-staking-desc-3": "Necessitaràs 32 ETH per esdevenir un validador complet o alguns ETH per unir-te a un grup de participació. També necessitaràs executar un client d'Eth1 o de la xarxa principal. La plataforma et mostrarà el procès i els requeriments de maquinari. Alternativament, pots utilitzar una API de backend.",
  "page-eth2-staking-description": "La participació és l'acte de depositar 32 ETH per activar el programari de validació. Com a validador, seràs responsable d'emmagatzemar informació, processar transaccions i afegir nous blocs a la cadena de blocs. Això mantindrà Ethereum segura per a tothom i guanyaràs nous ETH en el procés. Aquest procés, conegut com a prova de participació, és introduït per la Cadena de Balisa.",
  "page-eth2-staking-docked": "Més informació sobre l'acoblament",
  "page-eth2-staking-dyor": "Fes la teva pròpia recerca",
  "page-eth2-staking-dyor-desc": "Cap dels serveis de participació enumerats tenen un recolzament oficial. Assegura't de fer una mica de recerca per esbrinar quin és el millor servei per a tu.",
  "page-eth2-staking-header-1": "Aposta el teu ETH per esdevenir un validador d'Ethereum",
  "page-eth2-staking-how-much": "Quant estàs disposat a apostar?",
  "page-eth2-staking-how-to-stake": "Com apostar",
  "page-eth2-staking-how-to-stake-desc": "Tot depèn de quant estiguis disposat a apostar. Necessitaràs 32 ETH per convertir-te en un validador complet, però és possible apostar menys ETH.",
  "page-eth2-staking-join": "Uneix-te",
  "page-eth2-staking-join-community": "Uneix-te a la comunitat de participació",
  "page-eth2-staking-join-community-desc": "r/ethstaker és una comunitat perquè tothom pugui debatre sobre la participació a Ethereum. Uneix-te per obtenir consells, suport i per parlar de tot el que està en joc.",
  "page-eth2-staking-less-than": "Menys de",
  "page-eth2-staking-link-1": "Mostra les API de backend",
  "page-eth2-staking-meta-description": "Un resum sobre les participacions a Ethereum: els riscos, les recompenses, els requeriments i on fer-ho.",
  "page-eth2-staking-meta-title": "Participació a Ethereum",
  "page-eth2-staking-more-sharding": "Més informació sobre la fragmentació",
  "page-eth2-staking-pool": "Aposta en un grup",
  "page-eth2-staking-pool-desc": "Si tens menys de 32 ETH, podràs afegir petites apostes en grups d'apostes. Algunes empreses poden fer-ho tot en el teu nom, així que no hauràs de preocupar de mantenir-te connectat. A continuació, s'indiquen algunes empreses perquè hi facis una ullada.",
  "page-eth2-staking-pos-explained": "Prova de participació explicada",
  "page-eth2-staking-pos-explained-desc": "Apostar és el que has de fer per convertir-te en un validador en el sistema de prova de participació. Aquest és un mecanisme de consens que va substituir el sistema de prova de treball que està actualment en vigor. Els mecanismes de consens són els que mantenen les cadenes de blocs com Ethereum segures i descentralitzades.",
  "page-eth2-staking-pos-explained-desc-1": "La prova de participació ajuda a protegir la xarxa de diverses maneres:",
  "page-eth2-staking-services": "Veure els serveis de participació",
  "page-eth2-staking-sharding": "Desbloqueja la fragmentació",
  "page-eth2-staking-sharding-desc": "La fragmentació només és possible amb el sistema de prova de participació. Fragmentar un sistema de prova de treball dissoldria la quantitat de potència informàtica necessària per a corrompre la xarxa, el que facilitaria que miners maliciosos controlessin els fragments. Aquest no és el cas dels apostadors assignats de forma aleatòria a la prova de participació.",
  "page-eth2-staking-solo": "Apostar en solitari i executar un validador",
  "page-eth2-staking-solo-desc": "Per iniciar el procés de participació, et caldrà utilitzar la plataforma de llançament d'Eth2. Aquesta et guiarà a través de tota la configuració. Part de la participació consisteix en executar un client Eth2, que és una còpia local de la cadena de blocs. Això pot trigar una mica a descarregar-se en l'ordinador.",
  "page-eth2-staking-start": "Comença a participar",
  "page-eth2-staking-subtitle": "El staking és un bé públic per a l'ecosistema d'Ethereum. Pots ajudar a protegir la xarxa i guanyar recompenses en el procés.",
  "page-eth2-staking-sustainability": "Més sostenible",
  "page-eth2-staking-sustainability-desc": "Els validadors no requereixen ordinadors gaire potents per participar en el sistema de prova de participació, únicament un portàtil o un telèfon. Això farà a Ethereum millor per al medi ambient.",
  "page-eth2-staking-the-beacon-chain": "Més informació sobre la Cadena de Balisa",
  "page-eth2-staking-title-1": "Recompenses",
  "page-eth2-staking-title-2": "Riscos",
  "page-eth2-staking-title-3": "Requisits",
  "page-eth2-staking-title-4": "Com apostar el teu ETH",
  "page-eth2-staking-upgrades-li": "La Cadena de Balisa gestiona la prova de participació.",
  "page-eth2-staking-upgrades-li-2": "Ethereum tindrà una Cadena de Balisa de prova de participació i una xarxa principal de prova de treball per a un futur previsible. La xarxa principal és l'Ethereum que hem estat utilitzant durant anys.",
  "page-eth2-staking-upgrades-li-3": "Durant aquest temps, els participants podran afegir nous blocs a la Cadena de Balisa, però no processar transaccions a la xarxa principal.",
  "page-eth2-staking-upgrades-li-4": "Ethereum evolucionarà totalment al sistema de prova de participació una vegada que la xarxa principal d'Ethereum es converteixi en un fragment.",
  "page-eth2-staking-upgrades-li-5": "Només llavors podràs retirar la teva aposta.",
  "page-eth2-staking-upgrades-title": "Millores de prova de participació i Eth2",
  "page-eth2-staking-validators": "Més validadors, més seguretat",
  "page-eth2-staking-validators-desc": "És possible corrompre una cadena de blocs com Ethereum si es controla el 51 % de la xarxa. Per exemple, podries fer que el 51 % dels validadors indiquin que el teu saldo és de 1.000.000 ETH i no d'1 ETH. Però, per controlar el 51 % dels validadors, hauries de posseir el 51 % d'ETH al sistema. Això és molt!",
  "page-eth2-staking-withdrawals": "Les retirades no estaran disponibles al moment",
  "page-eth2-staking-withdrawals-desc": "No podràs retirar la teva aposta fins que les futures actualitzacions s'hagin implementat. Les retirades estaran disponibles una vegada que la xarxa principal s'hagi acoblat al sistema de la Cadena de Balisa.",
  "page-eth2-vision-2014": "Mostra una entrada al blog de 2014 sobre la prova de participació",
  "page-eth2-vision-beacon-chain": "La Cadena de Balisa",
  "page-eth2-vision-beacon-chain-btn": "Més informació sobre la Cadena de Balisa",
  "page-eth2-vision-beacon-chain-date": "La Cadena de Balisa està en funcionament",
  "page-eth2-vision-beacon-chain-desc": "La primera incorporació d’Eth2 a l’ecosistema. La Cadena de Balisa aporta la participació a Ethereum, posa les bases per a futures actualitzacions i, finalment, coordinarà el nou sistema.",
  "page-eth2-vision-beacon-chain-upper": "La Cadena de Balisa",
  "page-eth2-vision-desc-1": "Ethereum necessita reduir la congestió a la xarxa i millorar les velocitats per servir millor a una base d'usuaris global.",
  "page-eth2-vision-desc-2": "Executar un node es fa cada vegada més dificil a mida que la xarxa creix. Això només es farà més dificil amb esforços per escalar la xarxa.",
  "page-eth2-vision-desc-3": "Ethereum utilitza massa energia. La tecnologia que manté la xarxa protegida necessita ser més sostenible.",
  "page-eth2-vision-docking-date": "Estimació: 2022",
  "page-eth2-vision-docking-desc": "La xarxa principal d'Ethereum necessitarà \"acoblar-se\" amb la Cadena de Balisa en algun punt. Això permetrà apostar a tota la xarxa i assenyalarà el final de la mineria d'energia intensa.",
  "page-eth2-vision-ethereum-node": "Més informació sobre els nodes",
  "page-eth2-vision-explore-upgrades": "Explora les millores",
  "page-eth2-vision-future": "Un futur digital a una escala global",
  "page-eth2-vision-meta-desc": "Un resum de l'impacte que tindran les millores d'Eth2 a Ethereum i els desafiaments que han de superar.",
  "page-eth2-vision-meta-title": "La visió d'Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Més informació sobre la mineria",
  "page-eth2-vision-problems": "Els problemes actuals",
  "page-eth2-vision-scalability": "Escalabilitat",
  "page-eth2-vision-scalability-desc": "Ethereum necessita ser capaç de processar més transaccions per segon sense incrementar el tamany dels nodes a la xarxa. Els nodes són participants vitals de la xarxa que emmagatzemen dades i executen la cadena de blocs. Incrementar el tamany dels nodes no és pràctic perquè només aquells que tinguin ordinadors cars i potents podrien fer-ho. Per escalar, Ethereum necessita més transaccions per segon, juntament amb més nodes. Més nodes significa més protecció.",
  "page-eth2-vision-scalability-desc-3": "La millora de les cadenes de fragments estendrà la càrdega de la xarxa amb 64 noves cadenes. Això aportarà a Ethereum espai per respirar, reduint la congestió i millorant les velocitats més enllà del límit de les actuals 15-45 transaccions per segon.",
  "page-eth2-vision-scalability-desc-4": "I encara que hi hagi més cadenes, en realitat requerirà menys treball per part dels validadors, els que mantenen la xarxa. Els validadors només necessitaran \"executar\" el seu fragment i no tota la cadena Ethereum. Això fa que els nodes siguin més lleugers, permetent Ethereum escalar i mantenir-se descentralitzat.",
  "page-eth2-vision-security": "Seguretat",
  "page-eth2-vision-security-desc": "Les millores d'Eth2 fan que Ethereum es mantingui protegida contra els atacs coordinats, com un atac del 51 %. Aquest és un tipus d'atac en el qual si algú controla la major part de la xarxa, pot forçar canvis fraudulents.",
  "page-eth2-vision-security-desc-3": "La transició a la prova de participació significa que el protocol Ethereum te més desincentivadors contra els atacs. Això és perquè a la prova de participació, els validadors que protegeixen la xarxa han de participar amb quantitats significatives d'ETH dins el protocol. Si intenten atacar la xarxa, el protocol pot destruir automàticament el seu ETH.",
  "page-eth2-vision-security-desc-5": "Això és impossible a la prova de treball, on el millor que pot fer el protocol és forçar les entitats que protegeixen la xarxa (el miners) a perdre recompenses mineres que altrament haguéssin guanyat. Per aconseguir l'efecte equivalent a la prova de treball, el protocol hauria de ser capaç de destruir tot l'equipament d'un miner si tractés de fer trampa.",
  "page-eth2-vision-security-desc-5-link": "Més informació sobre la prova de treball",
  "page-eth2-vision-security-desc-8": "El model de seguretat d'Ethereum també necessita canviar degut a la introducció de",
  "page-eth2-vision-security-desc-10": "La participació també significa que no has d'invertir en maquinari d'alt nivell per \"executar\" un node d'Ethereum. Això hauria d'encoratjar a més persones a convertir-se en validador, incrementant la descentralització de la xarxa i disminuint la superfície d'atac.",
  "page-eth2-vision-security-staking": "Aposta ETH",
  "page-eth2-vision-security-validator": "Pots esdevenir un validador si apostes el teu ETH.",
  "page-eth2-vision-shard-chains": "cadenes de fragments",
  "page-eth2-vision-shard-date": "Estimació: 2021",
  "page-eth2-vision-shard-desc-4": "Les cadenes de fragments estendran la càrrega de la xarxa a 64 noves cadenes de blocs. Els fragments tenen el potencial de millorar dràsticament la velocitat de transacció, fins a 100.000 per segon.",
  "page-eth2-vision-shard-upgrade": "Més informació sobre les cadenes de fragments",
  "page-eth2-vision-staking-lower": "Més informació sobre les apostes",
  "page-eth2-vision-subtitle": "Fes crèixer Ethereum fins que sigui suficientment poderós per ajudar a tota la humanitat.",
  "page-eth2-vision-sustainability": "Sostenibilitat",
  "page-eth2-vision-sustainability-desc-1": "No és cap secret que Ethereum i altres cadenes de blocs com Bitcoin necessiten molta energia degut a la mineria.",
  "page-eth2-vision-sustainability-desc-2": "Però Ethereum s'està movent cap a una protecció amb ETH, no mitjançant el poder de la informàtica, sinó mitjançant la participació.",
  "page-eth2-vision-sustainability-desc-3": "Tot i que la Cadena de Balisa introduirà les apostes, l'Ethereum que utilitzem avui en dia s'executarà en paral·lel durant un temps abans de \"fusionar-se\" o \"acoblar-se\" amb les actualitzacions d'Eth2. Un sistema protegit per ETH i l'altre pel poder de la informàtica. Això és degut al fet que, al principi, les cadenes de fragments no eren capaces de manejar coses com els nostres comptes o les nostres dapps. Així doncs, no podem oblidar la mineria i la xarxa principal.",
  "page-eth2-vision-sustainability-desc-8": "Una vegada que la Cadena de Balisa i les actualitzacions de la cadena de fragments s'estiguin executant, el treball començarà amb l'acoblament de la xarxa principal i el nou sistema. Això convertirà la xarxa principal en un fragment perquè així estigui protegida per ETH i requereixi menys consum d'energia.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum necessita ser més ecològic.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "La visió d'Eth2",
  "page-eth2-vision-title-1": "Xarxa congestionada",
  "page-eth2-vision-title-2": "Espai en disc",
  "page-eth2-vision-title-3": "Massa energia",
  "page-eth2-vision-trilemma-cardtext-1": "Les millores d'Eth2 faran que Ethereum sigui escalable, segura i descentralitzada. La fragmentació farà que Ethereum sigui més escalable en augmentar les transaccions per segon mentre disminueix el poder necessari per executar un node i validar la cadena. La Cadena de Balisa farà que Ethereum sigui segura mitjançant la coordinació de validadors a través de fragments. I apostar reduirà la barrera de la participació i crearà una xarxa més gran, més descentralitzada.",
  "page-eth2-vision-trilemma-cardtext-2": "Les xarxes de cadena de blocs segures i descentralitzades requereixen que cada node verifiqui cada transacció processada per la cadena. Aquesta quantitat de treball limita el nombre de transaccions que poden succeïr en un moment donat. Descentralitzada i segura, aixó és la cadena d'Ethereum avui en dia.",
  "page-eth2-vision-trilemma-cardtext-3": "Les xarxes descentralitzades treballen enviant informació de transaccions a través dels nodes. Tota la xarxa necessita conèixer qualsevol canvi d'estat. Escalar transaccions per segon a través d'una xarxa descentralitzada posa en risc la seguretat, perquè com més transaccions, més llarg serà el retard i, per tant, més alta la probabilitat d'atac mentre la informació estigui circulant.",
  "page-eth2-vision-trilemma-cardtext-4": "Augmentar la mida i la potència dels nodes d’Ethereum podria augmentar les transaccions per segon d’una manera segura, però els requeriments de maquinari restringiria qui ho podria fer; això amenaça la descentralització. S'espera que la fragmentació i la prova de participació permetin a Ethereum créixer augmentant la quantitat de nodes, no la mida dels nodes.",
  "page-eth2-vision-trilemma-h2": "El repte del tamany descentralitzat",
  "page-eth2-vision-trilemma-modal-tip": "Toca els cercles de la part inferior per entendre millor els problemes de l'escalada descentralitzada",
  "page-eth2-vision-trilemma-p": "Una manera ingènua de solucionar els problemes d'Ethereum seria fer-lo més centralitzat. Però la descentralització és massa important. És la descentralització el que dona a Ethereum resistència contra la censura, obertura, privacitat de les dades i una seguretat pràcticament indestructible.",
  "page-eth2-vision-trilemma-p-1": "La visió d' Ethereum és esdevenir més escalable i segura, però també mantenir-se descentralitzada. Aconseguir aquestes 3 qualitats és un problema conegut com a trilema d'escalabilitat.",
  "page-eth2-vision-trilemma-p-2": "L'objectiu de les millores d'Eth2 és solucionar el trilema, però hi ha reptes importants.",
  "page-eth2-vision-trilemma-press-button": "Prem els botons del triangle per entendre millor els problemes de l'escalada descentralitzada.",
  "page-eth2-vision-trilemma-text-1": "Descentralització",
  "page-eth2-vision-trilemma-text-2": "Seguretat",
  "page-eth2-vision-trilemma-text-3": "Escalabilitat",
  "page-eth2-vision-trilemma-title-1": "Explora el trilema d'escalabilitat",
  "page-eth2-vision-trilemma-title-2": "Millores d'Eth2 i escalada descentralitzada",
  "page-eth2-vision-trilemma-title-3": "Segura i descentralitzada",
  "page-eth2-vision-trilemma-title-4": "Descentralitzada i escalable",
  "page-eth2-vision-trilemma-title-5": "Escalable i segura",
  "page-eth2-vision-understanding": "Comprendre la visió d'Eth2",
  "page-eth2-vision-upgrade-needs": "La necessitat de millores d'Eth2",
  "page-eth2-vision-upgrade-needs-desc": "El protocol d'Ethereum que es va llançar el 2015 ha tingut un èxit increïble. Però la comunitat d'Ethereum sempre ha contemplat que serien necessàries algunes millores clau per desbloquejar tot el potencial d'Ethereum.",
  "page-eth2-vision-upgrade-needs-desc-2": "L'alta demanda està fent pujar les taxes per transacció que fan que Ethereum sigui cara per a l'usuari mitjà. L'espai en disc necessari per a executar un client d'Ethereum creix cada cop més. I l'algoritme de consens de prova de treball subjacent que manté Ethereum segura i descentralitzada té un gran impacte ambiental.",
  "page-eth2-vision-upgrade-needs-desc-3": "Normalment, Eth2 es refereix a un conjunt d'actualitzacions que aborden aquests problemes, entre d'altres. Aquest conjunt d'actualitzacions es va denominar originalment \"Serenity\", i ha estat un àrea activa de recerca i desenvolupament des de 2014.",
  "page-eth2-vision-upgrade-needs-desc-6": " Això vol dir que no hi ha cap canvi per a Eth2. Les millores s'enviaran gradualment amb el pas del temps.",
  "page-eth2-vision-upgrade-needs-serenity": "Mostra una entrada al blog de 2015 sobre \"Serenity\"",
  "page-eth2-vision-uprade-needs-desc-5": "Ara que la tecnologia està preparada, aquestes millores reestructuraran Ethereum per fer-la més escalable, segura i sostenible i millorar la vida dels usuaris actuals i atraure'n de nous. Tot això mentre es preserva el valor central de descentralització d'Ethereum.",
  "page-get-eth-cex": "Cases de canvi centralitzades",
  "page-get-eth-cex-desc": "Les cases de canvi són negocis que et permeten comprar criptomonedes utilitzant moneda tradicional. Tenen la custòdia sobre qualsevol ETH que compris fins que ho enviïs a la teva cartera.",
  "page-get-eth-checkout-dapps-btn": "Comprova dapps",
  "page-get-eth-community-safety": "Missatges de la comunitat sobre seguretat",
  "page-get-eth-description": "Ethereum i ETH no estan sota el control governamental ni corporatiu. Estan descentralitzats. Això significa que ETH està obert a tothom.",
  "page-get-eth-dex": "Cases de canvi descentralitzades (DEXs)",
  "page-get-eth-dex-desc": "Si vols més control, compra ETH punt a punt. Amb una DEX pots comerciar sense donar el control dels teus fons a una corporació centralitzada.",
  "page-get-eth-dexs": "Cases de canvi descentralitzades (DEXs)",
  "page-get-eth-dexs-desc": "Les cases de canvi descentralitzats estan obertes als mercats per a ETH i altres monedes. Connecten compradors i venedors directament.",
  "page-get-eth-dexs-desc-2": "En comptes d’utilitzar una tercera entitat de confiança per guardar els fons durant la transacció, s’utilitza un codi. Els ETH del venedor només els transferirà quan un pagament estigui garantitzat. Aquest tipus de codi es coneix com a Contracte intel·ligent.",
  "page-get-eth-dexs-desc-3": "Això significa que hi ha menys restriccions geogràfiques comparades amb alternatives centralitzades. Si algú ven el que vols i accepta el mètode de pagament que pots aportar, endavant. Les DEXs et deixen comprar ETH amb altres tokens, PayPal o diners en efectiu.",
  "page-get-eth-do-not-copy": "EXEMPLE: NO COPIAR",
  "page-get-eth-exchanges-disclaimer": "Recopilem aquesta informació manualment. Si detectes quelcom erroni, fes-nos-ho saber a",
  "page-get-eth-exchanges-empty-state-text": "Introdueix el teu país de residència per a veure la llista de carteres i cases de canvi que pots utilitzar per comprar ETH",
  "page-get-eth-exchanges-except": "Excepte",
  "page-get-eth-exchanges-header": "A quin país vius?",
  "page-get-eth-exchanges-header-exchanges": "Cases de canvi",
  "page-get-eth-exchanges-header-wallets": "Carteres",
  "page-get-eth-exchanges-intro": "Les cases de canvi i les carteres tenen restriccions sobre on es poden vendre criptomonedes.",
  "page-get-eth-exchanges-no-exchanges": "Disculpa, no coneixem cap casa de canvi que et permeti comprar ETH en aquest país. Si tu en coneixes alguna, avisa’ns a",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Disculpa, no coneixem cap casa de canvi o cartera que et permeti comprar ETH en aquest país. Si tu en coneixes alguna, avisa’ns a",
  "page-get-eth-exchanges-no-wallets": "Disculpa, no coneixem cap cartera que et permeti comprar ETH en aquest país. Si tu en coneixes alguna, avisa’ns a",
  "page-get-eth-exchanges-success-exchange": "Registrar-te a una casa de canvi pot trigar diversos dies degut a les comprovacions legals.",
  "page-get-eth-exchanges-success-wallet-link": "carteres",
  "page-get-eth-exchanges-success-wallet-paragraph": "On vius pots comprar ETH directament des d’aquestes carteres. Obtén més informació sobre",
  "page-get-eth-exchanges-usa": "Estats Units d'Amèrica (EUA)",
  "page-get-eth-get-wallet-btn": "Aconsegueix una cartera",
  "page-get-eth-hero-image-alt": "Aconsegueix la imatge de protagonista d'ETH",
  "page-get-eth-keep-it-safe": "Mantenir segur el teu ETH",
  "page-get-eth-meta-description": "Com comprar ETH segons el lloc on vius i consells sobre com cuidar-lo.",
  "page-get-eth-meta-title": "Com comprar ETH",
  "page-get-eth-need-wallet": "Necessitaràs una cartera per utilitzar DEX.",
  "page-get-eth-new-to-eth": "Nou amb l'ETH? Aquí tens una descripció general per començar.",
  "page-get-eth-other-cryptos": "Compra amb altres criptomonedes",
  "page-get-eth-protect-eth-desc": "Si planeges comprar molts ETH, pot ser que desitgis mantenir-los en una cartera que controlis, no en una casa de canvi. El motiu és que una casa de canvi és un objectiu probable per als pirates informàtics. Si un pirata informàtic aconsegueix accedir-hi, podries perdre els teus fons. Alternativament, només tu tens el control de la cartera.",
  "page-get-eth-protect-eth-in-wallet": "Protegeix el teu ETH en una cartera",
  "page-get-eth-search-by-country": "Cerca per país",
  "page-get-eth-security": "Tanmateix, això vol dir que necessites prendre't seriosament la seguretat dels teus fons. Amb ETH, no estàs confiant la cura dels teus diners a un banc, sinó que ho fas tu mateix.",
  "page-get-eth-smart-contract-link": "Més informació sobre els contractes intel·ligents",
  "page-get-eth-swapping": "Intercanvia els teus tokens amb ETH d’altra gent i viceversa.",
  "page-get-eth-traditional-currencies": "Compra amb monedes tradicionals",
  "page-get-eth-traditional-payments": "Compra ETH amb mètodes de pagament tradicionals directament dels venedors.",
  "page-get-eth-try-dex": "Prova un Dex",
  "page-get-eth-use-your-eth": "Utilitza el teu ETH",
  "page-get-eth-use-your-eth-dapps": "Ara que tens ETH, revisa algunes aplicacions descentralitzades d'Ethereum (dapps). Hi dapps per a finances, xarxes socials, jocs i moltes més categories.",
  "page-get-eth-wallet-instructions": "Segueix les instruccions de la cartera",
  "page-get-eth-wallet-instructions-lost": "Si perds a l'accés a la cartera, perdràs l'accés als teus fons. La cartera ha de donar-te instruccions sobre com protegir-te d'això. Assegura't de seguir-les amb atenció. En la majoria dels casos, ningú podrà ajudar-te si perds l'accés a la cartera.",
  "page-get-eth-wallets": "Carteres",
  "page-get-eth-wallets-link": "Més informació sobre les carteres",
  "page-get-eth-wallets-purchasing": "Algunes carteres et permeten comprar criptomonedes amb una targeta de dèbit o crèdit, transferència bancària o fins i tot Apple Pay. S'apliquen restriccions geogràfiques.",
  "page-get-eth-warning": "Aquestes cases de canvi no són per a principiants ja que necessites alguns ETH per utilitzar-les.",
  "page-get-eth-what-are-DEX's": "Què són les DEXs?",
  "page-get-eth-whats-eth-link": "Què és ETH?",
  "page-get-eth-where-to-buy-desc": "Pots comprar ETH des de cases de canvi o carteres directament.",
  "page-get-eth-where-to-buy-desc-2": "Comprova quins serveis pots utilitzar basant-te en el lloc on vius.",
  "page-get-eth-where-to-buy-title": "On comprar ETH",
  "page-get-eth-your-address": "La teva adreça ETH",
  "page-get-eth-your-address-desc": "Quan descarregues una cartera es crea una adreça ETH pública per a tu. Un exemple:",
  "page-get-eth-your-address-desc-3": "Pensa en això com si fos la teva adreça de correu electrònic, però en lloc de correu reps ETH. Si vols transferir ETH d'una casa de canvi a la cartera, utilitza la teva adreça com a destinació. Assegura't de revisar-ho sempre amb cura abans d'enviar!",
  "page-get-eth-your-address-wallet-link": "Comprova les teves carteres",
  "page-index-hero-image-alt": "imatge destacada d'ethereum.org",
  "page-index-meta-description": "Ethereum és una plataforma global i descentralitzada per a diners i nous tipus d’aplicacions. A Ethereum, pots escriure codi que controli diners i crear aplicacions accessibles des de qualsevol lloc del món.",
  "page-index-meta-title": "Inici",
  "page-index-title": "Ethereum és una plataforma global de codi obert per a aplicacions descentralitzades.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Suport lingüístic",
  "page-languages-interested": "Interessat en contribuir?",
  "page-languages-learn-more": "Obtén més informació sobre el nostre programa de traducció",
  "page-languages-meta-desc": "Recursos per a tots els idiomes admesos per ethereum.org i maneres d’involucrar-te com a traductor.",
  "page-languages-meta-title": "Traduccions d'idiomes d'ethereum.org",
  "page-languages-p1": "Ethereum és un projecte global, i és fonamental que ethereum.org sigui accesible per a tothom, independentment de la nacionalitat o l'idioma. La nostra comunitat ha treballat molt per fer d'aquesta visió una realitat.",
  "page-languages-translations-available": "ethereum.org està disponible en els idiomes següents",
  "page-languages-want-more-header": "Vols veure ethereum.org en un idioma diferent?",
  "page-languages-want-more-link": "Programa de traducció",
  "page-languages-want-more-paragraph": "Els traductors d'ethereum.org sempre tradueixen pàgines a tants idiomes com sigui possible. Per veure en què treballen ara mateix o per registrar-te per unir-te a ells, llegeix sobre el nostre",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Garantia criptogràfica",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Amb Ethereum, pots demanar prestat directament a altres usuaris sense haver de fer operacions amb el teu ETH. Això et pot donar apalancament: algunes persones ho fan per intentar acumular més ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Però com que el preu de l'ETH és volàtil, hauràs d'aportar una sobregarantia. Això vol dir que si vols demanar prestades 100 monedes estables, probablement necessitaràs almenys 150 $ en ETH. Això protegeix el sistema i els prestadors.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Més informació sobre les monedes estables recolzades per criptomonedes",
  "page-stablecoins-accordion-borrow-pill": "Avançat",
  "page-stablecoins-accordion-borrow-places-intro": "Aquestes dapps et permeten demanar prestades monedes estables mitjançant criptomonedes com a garantia. Algunes accepten altres tokens, així com ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Llocs per demanar prestades monedes estables",
  "page-stablecoins-accordion-borrow-requirement-1": "Una cartera d'Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Per utilitzar una dapp, necessitaràs una cartera",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Necessitaràs ETH per a les taxes per transacció o garantia",
  "page-stablecoins-accordion-borrow-requirements-description": "Per demanar prestades monedes estables, hauràs d'utilitzar la dapp correcta. També necessitaràs una cartera i alguns ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Si fas servir ETH com a garantia i el seu valor cau, la garantia no cobrirà les monedes estables que has generat. Això provocarà la liquidació del teu ETH i és probable que hagis de fer front a una penalització. Així que si demanes prestades monedes estables, hauràs de vigilar el preu de l'ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Últim preu d'ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Riscos",
  "page-stablecoins-accordion-borrow-text-preview": "Potes demanar prestades algunes monedes estables amb criptomonedes com a garantia, que hauràs de retornar.",
  "page-stablecoins-accordion-borrow-title": "Demanar prestat",
  "page-stablecoins-accordion-buy-exchanges-title": "Intercanvis populars",
  "page-stablecoins-accordion-buy-requirement-1": "Intercanvis de criptomonedes i carteres",
  "page-stablecoins-accordion-buy-requirement-1-description": "Consulta els serveis que pots utilitzar al teu lloc de residència",
  "page-stablecoins-accordion-buy-requirements-description": "Un compte amb un intercanvi o una cartera amb la que pots comprar criptomonedes directament. És probable que ja n'hagis utilitzat una per obtenir ETH. Comprova els serveis que pots utilitzar al teu lloc de residència.",
  "page-stablecoins-accordion-buy-text-preview": "Molts intercanvis i carteres et permeten comprar monedes estables directament. S'aplicaran restriccions geogràfiques.",
  "page-stablecoins-accordion-buy-title": "Comprar",
  "page-stablecoins-accordion-buy-warning": "Les cases de canvi centralitzades només poden enumerar monedes estables de tipus \"fiat-backed\", és a dir, recolzades per diner fiduciari (FIAT), com la moneda estable digital vinculada al dòlar dels Estats Units (USDC), Tether i altres. És possible que no les puguis comprar directament, però hauries de poder intercanviar-les des d'ETH o altres criptomonedes que puguis comprar a la plataforma.",
  "page-stablecoins-accordion-earn-project-1-description": "La major part del treball tècnic és per al moviment de software de codi obert.",
  "page-stablecoins-accordion-earn-project-2-description": "Tecnologia, contingut i altres treballs per a la comunitat MakerDao (l'equip que et va portar Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Quan coneguis realment totes les teves coses, cerca errors per guanyar Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Recompenses de gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Recompenses per errors d'Eth2",
  "page-stablecoins-accordion-earn-project-community": "Comunitat MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Són plataformes que et pagaran en monedes estables per la teva feina.",
  "page-stablecoins-accordion-earn-projects-title": "On guanyar monedes estables",
  "page-stablecoins-accordion-earn-requirement-1": "Una cartera d'Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Necessitaràs una cartera per rebre les monedes estables que guanyis",
  "page-stablecoins-accordion-earn-requirements-description": "Les monedes estables són un gran mètode de pagament per la feina feta i els serveis oferts, perquè el valor és estable. Però necessitaràs una cartera per al pagament.",
  "page-stablecoins-accordion-earn-text-preview": "Pots guanyar monedes estables si treballes en projectes dins de l'ecosistema d'Ethereum.",
  "page-stablecoins-accordion-earn-title": "Guanyar",
  "page-stablecoins-accordion-less": "Menys",
  "page-stablecoins-accordion-more": "Més",
  "page-stablecoins-accordion-requirements": "Què necessitaràs?",
  "page-stablecoins-accordion-swap-dapp-intro": "Si ja tens ETH i una cartera, pots fer servir dapps per intercanviar monedes estables.",
  "page-stablecoins-accordion-swap-dapp-link": "Més informació sobre els intercanvis descentralitzats",
  "page-stablecoins-accordion-swap-dapp-title": "Dapps per intercanviar tokens",
  "page-stablecoins-accordion-swap-editors-tip": "Consell dels editors",
  "page-stablecoins-accordion-swap-editors-tip-button": "Cerca carteres",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Aconsegueix una cartera que et permetrà comprar ETH i intercanviar-los per tokens directament, monedes estables incloses.",
  "page-stablecoins-accordion-swap-pill": "Recomanat",
  "page-stablecoins-accordion-swap-requirement-1": "Una cartera d'Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Necessitaràs una cartera per autoritzar l'intercanvi i emmagatzemar les teves monedes",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Pagar per l'intercanvi",
  "page-stablecoins-accordion-swap-text-preview": "Pots recollir la majoria de monedes estables d'intercanvis descentralitzats. Així que pots intercanviar qualsevol dels teus tokens per una de les monedes estables que vulguis.",
  "page-stablecoins-accordion-swap-title": "Intercanviar",
  "page-stablecoins-algorithmic": "Algorítmic",
  "page-stablecoins-algorithmic-con-1": "Has de confiar en l'algoritme (o ser capaç de llegir-lo).",
  "page-stablecoins-algorithmic-con-2": "El teu saldo de monedes canviarà en funció de l'oferta total.",
  "page-stablecoins-algorithmic-description": "Aquestes monedes estables no estan recolzades per cap altre actiu. En el seu lloc, un algoritme vendrà tokens si el preu cau per sota del valor desitjat i els proporcionarà si el valor supera la quantitat desitjada. Com que el nombre de tokens en circulació canvia amb freqüència, el nombre de tokens que tinguis canviarà, però sempre reflectirà la teva quota.",
  "page-stablecoins-algorithmic-pro-1": "No es necessita cap garantia.",
  "page-stablecoins-algorithmic-pro-2": "Controlat per un algoritme públic.",
  "page-stablecoins-bank-apy": "0,05 %",
  "page-stablecoins-bank-apy-source": "El tipus de canvi mitjà pagat pels bancs en comptes d'estalvi bàsiques i assegurades federalment als Estats Units.",
  "page-stablecoins-bank-apy-source-link": "Font",
  "page-stablecoins-bitcoin-pizza": "La pizza infame de Bitcoin",
  "page-stablecoins-bitcoin-pizza-body": "El 2010, algú va comprar 2 pizzes amb 10.000 bitcoins. En aquest moment, equivalien a uns 41 dòlars. En el mercat d'avui en dia, valen milions de dòlars. Hi ha diverses transaccions similars lamentables a la història d'Ethereum. Les monedes estables resolen aquest problema, així que pots gaudir de la pizza i conservar els teus ETH.",
  "page-stablecoins-coin-price-change": "Canvi del preu de la moneda (últims 30 dies)",
  "page-stablecoins-crypto-backed": "Recolzat per criptomoneda",
  "page-stablecoins-crypto-backed-con-1": "Menys estable que les monedes estables recolzades per diner fiduciari.",
  "page-stablecoins-crypto-backed-con-2": "Cal vigilar el valor de la garantia criptogràfica.",
  "page-stablecoins-crypto-backed-description": "Aquestes monedes estables estan recolzades per altres actius criptogràfics, como l’ETH. Els preus depenen del valor de l’actiu subjacent (o valor garantit), que pot ser volàtil. Com que el valor d’ETH pot fluctuar, aquestes monedes estables estan sobregarantides per garantir que el preu es mantingui el més estable possible. Això significa en poques paraules que una criptomoneda valorada en 1 $ recolzada per una moneda estable té una criptomoneda subjacent amb un valor d’almenys 2 $. Així, si el preu d’ETH cau, caldrà utilitzar més ETH per recolzar la moneda estable; en cas contrari, les monedes estables perdran el seu valor.",
  "page-stablecoins-crypto-backed-pro-1": "Transparent i totalmente descentralitzat.",
  "page-stablecoins-crypto-backed-pro-2": "Ràpid per convertir-se en altres actius criptogràfics.",
  "page-stablecoins-crypto-backed-pro-3": "No hi ha amortidors externs: tots els actius estan controlats per comptes d'Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai és probablement la moneda estable descentralitzada més famosa. El seu valor és aproximadament d'1 dòlar i és àmpliament acceptada a les dapps.",
  "page-stablecoins-dai-banner-learn-button": "Més informació sobre Dai",
  "page-stablecoins-dai-banner-swap-button": "Intercanvia ETH per Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "El logotip de Dai",
  "page-stablecoins-editors-choice": "Recomanacions de l'editor",
  "page-stablecoins-editors-choice-intro": "Aquests són probablement els exemples més coneguts de monedes estables en aquest moment i les monedes que hem trobat útils a l'hora d'utilitzar dapps.",
  "page-stablecoins-explore-dapps": "Explora dapps",
  "page-stablecoins-fiat-backed": "Recolzat per diner fiduciari",
  "page-stablecoins-fiat-backed-con-1": "Centralitzat: algú ha d'emetre els tokens.",
  "page-stablecoins-fiat-backed-con-2": "Requereix auditoria per garantir que l'empresa tingui prou reserves.",
  "page-stablecoins-fiat-backed-description": "És bàsicament la promesa de pagament d'un deute per diner fiduciari tradicional (normalment, dòlars). Utilitzes els teus diners fiduciaris per comprar una moneda estable que després pots canviar en efectiu per la teva moneda original.",
  "page-stablecoins-fiat-backed-pro-1": "Assegurança contra la volatilitat de les criptomonedes.",
  "page-stablecoins-fiat-backed-pro-2": "Els canvis en el preu són mínims.",
  "page-stablecoins-find-stablecoin": "Cerca una moneda estable",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Com obtenir monedes estables",
  "page-stablecoins-find-stablecoin-intro": "Hi ha centenars de monedes estables disponibles. Aquí en tens algunes que t'ajudaran a començar. Si ets nou a Ethereum, et recomanem que primer facis una mica de recerca.",
  "page-stablecoins-find-stablecoin-types-link": "Diferents tipus de monedes estables",
  "page-stablecoins-get-stablecoins": "Com obtenir monedes estables",
  "page-stablecoins-hero-alt": "Les tres monedes estables més grans per capitalització de mercat són: Dai, USDC i Tether.",
  "page-stablecoins-hero-button": "Aconsegueix monedes estables",
  "page-stablecoins-hero-header": "Diners digitals per a l'ús diari",
  "page-stablecoins-hero-subtitle": "Les monedes estables són tokens d'Ethereum dissenyades per mantenir un valor fix, fins i tot quan el valor d'ETH canvia.",
  "page-stablecoins-interest-earning-dapps": "Dapps de guanys d'interessos",
  "page-stablecoins-meta-description": "Una introducció a les monedes estables d'Ethereum: què són, com obtenir-les i per què són tan importants.",
  "page-stablecoins-precious-metals": "Metalls preciosos",
  "page-stablecoins-precious-metals-con-1": "Centralitzat: algú ha d'emitir els tokens.",
  "page-stablecoins-precious-metals-con-2": "Has de confiar en l'emissor de tokens i en les reserves de metalls preciosos.",
  "page-stablecoins-precious-metals-description": "De la mateixa manera que les monedes recolzades pels diners fiduciaris (fiat), aquestes monedes estables utilitzen recursos com l'or per mantenir el seu valor.",
  "page-stablecoins-precious-metals-pro-1": "Assegurança contra la volatilitat de les criptomonedes.",
  "page-stablecoins-prices": "Preus de les monedes estables",
  "page-stablecoins-prices-definition": "Les monedes estables són criptomonedes sense volatilitat. Comparteixen moltes de les capacitats d'ETH, però el seu valor és estable, més com una moneda tradicional. Per tant, tens accés a diners estables que pots utilitzar a Ethereum. ",
  "page-stablecoins-prices-definition-how": "Com obtenen les monedes estables la seva estabilitat",
  "page-stablecoins-research-warning": "Ethereum és una nova tecnologia i la majoria de les aplicacions són noves. Assegura't que ets conscient dels riscos i que només diposites el que pots permetre't perdre.",
  "page-stablecoins-research-warning-title": "Fes sempre la teva pròpia recerca",
  "page-stablecoins-save-stablecoins": "Estalvia amb monedes estables",
  "page-stablecoins-save-stablecoins-body": "Les monedes estables sovint tenen una taxa d'interès mitjà superior pel fet que hi ha molta demanda d'endeutament. Hi ha dapps que et permeten obtenir interès de les teves monedes estables en temps real si les diposites en un fons de préstec. Com passa en l'àmbit bancari, estàs subministrant tokens per als prestataris, però pots retirar els tokens i els interessos en qualsevol moment.",
  "page-stablecoins-saving": "Fes un bon ús dels teus estalvis en monedes estables i guanya una mica d'interès. Com tot en criptografia, els rendiments percentuals anuals previstos (APY) poden canviar diàriament en funció de l'oferta i la demanda en temps real.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Revisa les dapps d'Ethereum. Les monedes estables sovint són més útils per a les transaccions quotidianes.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Il·lustració d'un gos.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Utilitza les teves monedes estables",
  "page-stablecoins-stablecoins-dapp-description-1": "Mercats per a moltes monedes estables, incloses Dai, USDC, TUSD, USDT i més. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Presta monedes estables i guanya interessos i $COMP, el token propi de Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Una plataforma comercial on pots guanyar interessos amb els teus Dai i USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Una app disenyada per estalviar Dai.",
  "page-stablecoins-stablecoins-feature-1": "Les monedes estables són globals i poden enviar-se a través d'Internet. Són fàcils d'enviar o rebre un cop que es disposa d'un compte Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "La demanda de monedes estables és alta, de manera que pots guanyar interessos per prestar les teves. Assegura't que ets conscient dels riscos abans de prestar-les.",
  "page-stablecoins-stablecoins-feature-3": "Les monedes estables són intercanviables per ETH i altres tokens d'Ethereum. Moltes aplicacions depenen de les monedes estables.",
  "page-stablecoins-stablecoins-feature-4": "Les monedes estables estan protegides per criptografia. Ningú pot falsificar transaccions en nom teu.",
  "page-stablecoins-stablecoins-meta-description": "Una introducció a les monedes estables d'Ethereum: què són, com obtenir-les i per què són tan importants.",
  "page-stablecoins-stablecoins-table-header-column-1": "Moneda",
  "page-stablecoins-stablecoins-table-header-column-2": "Capitalització de mercat",
  "page-stablecoins-stablecoins-table-header-column-3": "Classe de garantia",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Criptomoneda",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Fiat",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Metalls preciosos",
  "page-stablecoins-table-error": "No es poden carregar les monedes estables. Prova d'actualitzar la pàgina.",
  "page-stablecoins-table-loading": "S'estan carregant dades de la moneda estable...",
  "page-stablecoins-title": "Monedes estables",
  "page-stablecoins-top-coins": "Millors monedes estables per capitalització de mercat",
  "page-stablecoins-top-coins-intro": "La capitalització del mercat és",
  "page-stablecoins-top-coins-intro-code": "el nombre total de tokens que hi ha multiplicat pel valor de cada símbol. La llista és dinàmica i els projectes enumerats aquí no estan necessàriament recolzats per l'equip d'ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Com funcionen: tipus de monedes estables",
  "page-stablecoins-usdc-banner-body": "L'USDc és probablement la moneda estable més famosa recolzada per diners fiduciari. El seu valor és aproximadament d'un dòlar i està recolzada per Circle i Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Més informació sobre USDC",
  "page-stablecoins-usdc-banner-swap-button": "Intercanvia ETH per USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "El logotip d'USDC",
  "page-stablecoins-why-stablecoins": "Per què monedes estables?",
  "page-stablecoins-how-they-work-button": "Com funcionen",
  "page-stablecoins-why-stablecoins-body": "L'ETH, com el Bitcoin, té un preu volàtil perquè és una tecnologia nova, així que és possible que no vulguis gastar-la regularment. Les monedes estables reflecteixen el valor de les monedes tradicionals per donar-te accés a diners estables que pots utilitzar a Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Si vols que afegim una cartera,",
  "page-find-wallet-alpha-logo-alt": "Logotip d'AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Logotip d'Ambo",
  "page-find-wallet-argent-logo-alt": "Logotip d'Argent",
  "page-find-wallet-buy-card": "Compra criptomoneda amb una targeta",
  "page-find-wallet-buy-card-desc": "Compra ETH directament de la teva cartera amb una targeta bancària. Es poden aplicar restriccions geogràfiques.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Comprova dapps",
  "page-find-wallet-clear": "Esborra els filtres",
  "page-find-wallet-coinbase-logo-alt": "Logotip de Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Així que tria la cartera d'acord amb les funcions que desitgis.",
  "page-find-wallet-description": "Les carteres tenen moltes funcions opcionals que et poden agradar.",
  "page-find-wallet-description-alpha": "Cartera de codi obert d'Ethereum que aprofita l'enclavament segur en dispositius mòbils, ofereix suport total a la xarxa de proves i s'adapta a l'estàndard TokenScript.",
  "page-find-wallet-description-ambo": "Inverteix directament i aconsegueix la teva primera inversió només uns minuts després de baixar l'aplicació",
  "page-find-wallet-description-argent": "Un toc per guanyar interessos i invertir. Presta, emmagatzema i envia. Aconsegueix-lo.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "L'aplicació segura per emmagatzemar criptomonedes tu mateix",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma és el teu portal a DeFi. No més frases llavor, no més extensions de Chrome.",
  "page-find-wallet-description-enjin": "Impenetrable, amb característiques precises i convenient; creat per a inversors, jugadors i desenvolupadors",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "La plataforma més fiable per desar actius digitals a Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken és una cartera digital fàcil i segura en la qual confien milions de persones",
  "page-find-wallet-description-ledger": "Mantingues els actius segurs amb els més alts estàndards de seguretat",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Comença a explorar en segons aplicacions blockchain en les quals confien més d'1 milió d'usuaris a tot el món.",
  "page-find-wallet-description-monolith": "L'única cartera autocustodiada del món, aparellada amb una targeta de dèbit Visa. Disponible al Regne Unit i la UE i que pot utilitzar-se en tot el món.",
  "page-find-wallet-description-multis": "Multis és un compte de criptomoneda dissenyada per als negocis. Amb Multis, les empreses poden emmagatzemar amb controls d'accés, guanyar interessos sobre els seus estalvis i agilitar els pagaments i la comptabilitat.",
  "page-find-wallet-description-mycrypto": "MyCrypto és una interfície per administrar tots els teus comptes. Intercanvia, envia i compra criptomonedes amb carteres com MetaMask, Ledger, Trezor i altres.",
  "page-find-wallet-description-myetherwallet": "Una interfície gratuïta de la banda del client que t'ajuda a interactuar amb el blockchain d'Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Cartera de propietat comunitària, sense custòdia, amb la seva pròpia xarxa de pagaments L2.",
  "page-find-wallet-description-portis": "La cartera de blockchain sense custòdia que fa que les aplicacions siguin senzilles per a tothom",
  "page-find-wallet-description-rainbow": "Una llar millor per als teus actius d'Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Aplicació de missatgeria segura, cartera de criptomonedes i un navegador web 3.0 creat amb tecnologia d'avantguarda",
  "page-find-wallet-description-tokenpocket": "TokenPocket：una cartera de moneda digital líder al món, segura i convenient, i un portal a Dapps, amb suport de cadena múltiple.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Inici de sessió amb un clic per al Web 3.0",
  "page-find-wallet-description-trezor": "La primera i original cartera de hardware",
  "page-find-wallet-description-trust": "Trust Wallet és una criptomoneda descentralitzada de múltiples monedes. Compra criptomonedes, explora dapps, intercanvia actius, etc., i mantingues el control de les teves claus.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo és la primera cartera de criptomonedes sense clau. Amb ZenGo, no hi ha claus privades, contrasenyes o frases llavor per gestionar o perdre. Compra, comercia, guanya i emmagatzema amb una senzillesa i seguretat sense precedents",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Logotip de Dharma",
  "page-find-wallet-enjin-logo-alt": "Logotip d'Enjin",
  "page-find-wallet-Ethereum-wallets": "Carteres Ethereum",
  "page-find-wallet-explore-dapps": "Explora dapps",
  "page-find-wallet-explore-dapps-desc": "Aquestes carteres estan dissenyades per ajudar-te a connectar a dapps d'Ethereum.",
  "page-find-wallet-feature-h2": "Tria les funcions de la cartera que t'interessa",
  "page-find-wallet-fi-tools": "Accés a eines financeres",
  "page-find-wallet-fi-tools-desc": "Demana prestat, presta i guanya interessos directament des de la teva cartera.",
  "page-find-wallet-following-features": "amb les funcions següents:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Logotip de Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Troba imatge destacada de la cartera",
  "page-find-wallet-imtoken-logo-alt": "Logotip d'imToken",
  "page-find-wallet-last-updated": "Última actualització",
  "page-find-wallet-ledger-logo-alt": "Logotip de Ledger",
  "page-find-wallet-limits": "Protecció de límits",
  "page-find-wallet-limits-desc": "Salvaguarda teus actius configurant els límits per prevenir que el teu compte s'esgoti.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "política de publicació",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Troba i compara les carteres d'Ethereum segons les funcions que vulguis.",
  "page-find-wallet-meta-title": "Cerca una cartera d'Ethereum",
  "page-find-wallet-metamask-logo-alt": "Logotip de MetaMask",
  "page-find-wallet-monolith-logo-alt": "Logotip de Monolith",
  "page-find-wallet-multis-logo-alt": "Logotip de Multis",
  "page-find-wallet-multisig": "Comptes de diverses signatures",
  "page-find-wallet-multisig-desc": "Per a més seguretat, les carteres de diverses signatures requereixen més d'un compte per autoritzar determinades transaccions.",
  "page-find-wallet-mycrypto-logo-alt": "Logotip de MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Logotip de MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Nou amb les carteres? Aquí tens una visió general per començar.",
  "page-find-wallet-new-to-wallets-link": "Carteres d'Ethereum",
  "page-find-wallet-not-all-features": "Cap cartera té totes aquestes funcions",
  "page-find-wallet-not-endorsements": "Les carteres que apareixen en aquesta pàgina no estan homologades de manera oficial i es proporcionen només amb finalitats informatives. Les descripcions les han facilitat les pròpies empreses de les carteres. Afegim productes a aquesta pàgina d'acord amb els criteris de les nostres carteres",
  "page-find-wallet-overwhelmed": "d'Ethereum a continuació. Estàs aclaparat? Intenta filtrar per funcions.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Logotip de Pillar",
  "page-find-wallet-portis-logo-alt": "Logotip de Portis",
  "page-find-wallet-rainbow-logo-alt": "Logotip de Rainbow",
  "page-find-wallet-raise-an-issue": "planteja un tema al GitHub",
  "page-find-wallet-search-btn": "Cerca característiques seleccionades",
  "page-find-wallet-showing": "S'està mostrant ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Logotip de Status",
  "page-find-wallet-swaps": "Intercanvi de tokens descentralitzats",
  "page-find-wallet-swaps-desc": "Comercia entre ETH i altres tokens directament des de la teva cartera.",
  "page-find-wallet-title": "Troba una cartera",
  "page-find-wallet-tokenpocket-logo-alt": "Logotip de TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Logotip de Torus",
  "page-find-wallet-trezor-logo-alt": "Logotip de Trezor",
  "page-find-wallet-trust-logo-alt": "Logotip de Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Prova de suprimir una o dues funcions",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Ara que tens una cartera, comprova algunes aplicacions d'Ethereum (dapps). Hi ha dapps financeres, per a xarxes socials, jocs i moltes altres categories.",
  "page-find-wallet-use-your-wallet": "Utilitza la teva cartera",
  "page-find-wallet-voluem-desc": "Si vols tenir un munt d'ETH, tria una cartera que et permeti comprar més de 2.000 $ d'ETH alhora.",
  "page-find-wallet-volume": "Compres de gran volum",
  "page-find-wallet-we-found": "Hem trobat",
  "page-find-wallet-withdraw": "Retira al banc",
  "page-find-wallet-withdraw-desc": "Pots retirar els teus ETH directament al teu compte bancari sense passar per un intercanvi.",
  "page-find-wallet-yet": "encara",
  "page-find-wallet-zengo-logo-alt": "Logotip de ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Aposta ETH",
  "page-wallets-accounts-addresses": "Carteres, comptes i adreces",
  "page-wallets-accounts-addresses-desc": "Val la pena comprendre les diferències entre alguns termes clau.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Imatge d'un robot amb una caixa forta com a cos, que representa una cartera d'Ethereum",
  "page-wallets-ethereum-account": "Compte d'Ethereum",
  "page-wallets-blog": "Blog de Coinbase",
  "page-wallets-bookmarking": "Marca la teva cartera",
  "page-wallets-bookmarking-desc": "Si utilitzes una cartera web, marca el lloc per protegir-te contra estafes de pesca.",
  "page-wallets-cd": "Carteres físiques de maquinari que et permeten mantenir les teves criptomonedes sense connexió (molt segures)",
  "page-wallets-converted": "Convertit en criptomoneda?",
  "page-wallets-converted-desc": "Si busques tenir un valor seriós, et recomanem una cartera de maquinari, ja que aquestes són més segures. O una cartera amb alertes de frau i límits de retirada.",
  "page-wallets-curious": "Curiositat per les criptomonedes?",
  "page-wallets-curious-desc": "Si ets nou en les criptomonedes i vols tenir-ne una idea, et recomanem una cosa que et donarà l'oportunitat d'explorar aplicacions d'Ethereum o comprar directament el teu primer ETH des de la cartera.",
  "page-wallets-desc-2": "Necessites una cartera per enviar fons i gestionar el teu ETH.",
  "page-wallets-desc-2-link": "Més informació sobre ETH",
  "page-wallets-desc-3": "La teva cartera és només una eina per gestionar el teu compte d'Ethereum. Això vol dir que pots intercanviar proveïdors de carteres en qualsevol moment. Algunes carteres també et permeten gestionar diversos comptes d'Ethereum des d'una aplicació.",
  "page-wallets-desc-4": "Això és degut al fet que les carteres no tenen la custòdia dels teus fons; la tens tu. Les carteres són només una eina per gestionar el que realment és teu.",
  "page-wallets-description": "Les carteres d'Ethereum són aplicacions que et permeten interactuar amb el teu compte d'Ethereum. Pensa que són com una aplicació bancària d'Internet: són un banc. La teva cartera et permet llegir el teu saldo, enviar transaccions i connectar-te a aplicacions.",
  "page-wallets-desktop": "Aplicacions d'escriptori en cas que prefereixis gestionar els teus fons des de Windows, MacOS o Linux",
  "page-wallets-ethereum-wallet": "Una cartera",
  "page-wallets-explore": "Explora Ethereum",
  "page-wallets-features-desc": "Podem ajudar-te a triar la teva cartera en funció de les característiques que t'interessin.",
  "page-wallets-features-title": "Prefereixes triar en funció de les característiques?",
  "page-wallets-find-wallet-btn": "Troba una cartera",
  "page-wallets-find-wallet-link": "Troba una cartera",
  "page-wallets-get-some": "Aconsegueix ETH",
  "page-wallets-get-some-alt": "Una il·lustració d'una mà creant un logotip d'ETH fet de peces de lego",
  "page-wallets-get-some-btn": "Aconsegueix ETH",
  "page-wallets-get-some-desc": "ETH és la criptomoneda nativa d'Ethereum. Necessitaràs una mica d'ETH a la cartera per utilitzar les aplicacions d'Ethereum.",
  "page-wallets-get-wallet": "Aconsegueix una cartera",
  "page-wallets-get-wallet-desc": "Hi ha moltes carteres diferents per triar. Volem ajudar-te a seleccionar la millor per a tu.",
  "page-wallets-get-wallet-desc-2": "Recorda: aquesta decisió no és per sempre. El teu compte d'Ethereum no està vinculada al teu proveïdor de cartera.",
  "page-wallets-how-to-store": "Com emmagatzemar actius digitals a Ethereum",
  "page-wallets-keys-to-safety": "Les claus per mantenir segura la teva criptomoneda",
  "page-wallets-manage-funds": "Una aplicació per gestionar els teus fons",
  "page-wallets-manage-funds-desc": "La teva cartera mostra els teus saldos i l'historial de transaccions, i et dona la possibilitat d'enviar o rebre fons. Algunes carteres poden oferir més funcions.",
  "page-wallets-meta-description": "Què cal saber per utilitzar carteres d'Ethereum",
  "page-wallets-meta-title": "Carteres d'Ethereum",
  "page-wallets-mobile": "Aplicacions mòbils que fan que els teus fons siguin accessibles des de qualsevol lloc",
  "page-wallets-more-on-dapps-btn": "Més informació sobre les dapps",
  "page-wallets-most-wallets": "La majoria dels productes de cartera et permetran generar un compte d'Ethereum. Així que no en necessites un abans de descarregar una cartera.",
  "page-wallets-protecting-yourself": "Protecció per a tu i els teus fons",
  "page-wallets-seed-phrase": "Escriu la teva frase llavor",
  "page-wallets-seed-phrase-desc": "Les carteres sovint et proporcionaran una frase llavor que hauràs d'escriure en algun lloc segur. Aquesta és l'única manera de poder recuperar la teva cartera.",
  "page-wallets-seed-phrase-example": "Aquí tens un exemple:",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "No la desis en un ordinador. Escriu-la i mantingues-la fora de perill.",
  "page-wallets-slogan": "La clau del teu futur digital",
  "page-wallets-stay-safe": "Com mantenir-se segur",
  "page-wallets-stay-safe-desc": "Les carteres suposen un petit canvi de pensament. La llibertat financera i la capacitat d'accedir i utilitzar els fons en qualsevol lloc comporta una mica de responsabilitat: no hi ha suport tècnic en matèria de criptomoneda.",
  "page-wallets-subtitle": "Les carteres et donen accés als teus fons i aplicacions d'Ethereum. Només tu tens accés a la teva cartera.",
  "page-wallets-take-responsibility": "Assumeix la responsabilitat dels teus propis fons",
  "page-wallets-take-responsibility-desc": "Els intercanvis centralitzats enllaçaran la cartera a un usuari i una contrasenya que podràs recuperar de manera tradicional. Només recorda que estàs confiant en aquest intercanvi amb la custòdia dels teus fons. Si aquesta empresa pateix un atac o una fallida, els teus fons estan en risc.",
  "page-wallets-tips": "Més consells sobre com mantenir-se segur",
  "page-wallets-tips-community": "De la comunitat",
  "page-wallets-title": "Carteres d'Ethereum",
  "page-wallets-triple-check": "Comprova-ho tot tres vegades",
  "page-wallets-triple-check-desc": "Recorda que les transaccions no es poden anul·lar i que les carteres no es poden recuperar fàcilment, així que vés amb compte.",
  "page-wallets-try-dapps": "Prova algunes dapps",
  "page-wallets-try-dapps-alt": "Una il·lustració de membres de la comunitat d'Ethereum treballant junts",
  "page-wallets-try-dapps-desc": "Les dapps són aplicacions creades a Ethereum. Són més barates i més justes i tracten millor les teves dades que les aplicacions tradicionals.",
  "page-wallets-types": "Tipus de carteres",
  "page-wallets-web-browser": "Les carteres web et permeten interactuar amb el teu compte mitjançant un navegador web",
  "page-wallets-whats-a-wallet": "Què és una cartera d'Ethereum?",
  "page-wallets-your-ethereum-account": "El teu compte d'Ethereum",
  "page-wallets-your-ethereum-account-desc": "La teva cartera és la finestra al teu compte d'Ethereum: al teu saldo, l'historial de transaccions i més. Però pots intercanviar proveïdors de cartera en qualsevol moment.",
  "page-wallets-your-login": "El teu inici de sessió per a aplicacions d'Ethereum",
  "page-wallets-your-login-desc": "La teva cartera et permet connectar-te a qualsevol aplicació descentralitzada mitjançant el teu compte d'Ethereum. És com un inici de sessió que pots utilitzar en moltes dapps.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum és una tecnologia que et permet enviar criptomonedes a qualsevol persona a canvi d'una petita taxa. També potencia aplicacions que tothom pot utilitzar i ningú no pot tombar.",
  "page-what-is-ethereum-101-desc-2": "Ethereum es construeix sobre la innovació de Bitcoin, amb algunes grans diferències.",
  "page-what-is-ethereum-101-desc-3": "Les dues et permeten utilitzar diners digitals sense haver de pagar proveïdors o bancs, però Ethereum és programable, així que també es pot utilitzar per a molts actius diferents, fins i tot Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "Això també significa que Ethereum serveix més que pels pagaments. És un mercat de serveis financers, jocs i aplicacions que no poden robar les teves dades o censurar-te.",
  "page-what-is-ethereum-101-italic": "la cadena de blocs programable mundial.",
  "page-what-is-ethereum-101-strong": "És ",
  "page-what-is-ethereum-accessibility": "Ethereum és obert per a tothom.",
  "page-what-is-ethereum-adventure": "Escull la teva aventura!",
  "page-what-is-ethereum-alt-img-bazaar": "Il·lustració d'una persona que entra en un basar, que pretén representar Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Una il·lustració de membres de la comunitat d'Ethereum treballant junts",
  "page-what-is-ethereum-alt-img-lego": "Una il·lustració d'una mà creant un logotip d'ETH fet de peces de lego",
  "page-what-is-ethereum-alt-img-social": "Una il·lustració de personatges en un espai social dedicat a Ethereum amb un gran logotip d'ETH",
  "page-what-is-ethereum-banking-card": "Banca per a tothom",
  "page-what-is-ethereum-banking-card-desc": "No tothom té accés a serveis financers; però tot el que necessites per accedir a Ethereum i els seus productes de préstec i estalvi és una connexió a Internet.",
  "page-what-is-ethereum-build": "Fes alguna cosa amb Ethereum",
  "page-what-is-ethereum-build-desc": "Si vols provar de construir amb Ethereum, llegeix els nostres documents, prova algun tutorial o descobreix les eines que necessites per començar.",
  "page-what-is-ethereum-censorless-card": "Resistent a la censura",
  "page-what-is-ethereum-censorless-card-desc": "Cap govern o empresa té control sobre Ethereum. Aquesta descentralització fa gairebé impossible que algú t'impedeixi rebre pagaments o utilitzar serveis a Ethereum.",
  "page-what-is-ethereum-comm-desc": "La nostra comunitat acull persones de tots els orígens, inclosos artistes, criptoanarquistes, empreses de la llista Fortune 500 i ara tu. Descobreix com pots involucrar-te avui mateix.",
  "page-what-is-ethereum-commerce-card": "Garanties comercials",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum crea un camp de joc més equilibrat. Els clients tenen una assegurança, una garantia integrada per la qual els fons només canviaran de mans si proporciones el que es va acordar. No necessites una gran influència empresarial per fer negocis.",
  "page-what-is-ethereum-community": "La comunitat d'Ethereum",
  "page-what-is-ethereum-compatibility-card": "Compatibilitat per a la victòria",
  "page-what-is-ethereum-compatibility-card-desc": "S'estan creant contínuament millors productes i experiències, perquè els productes Ethereum són compatibles per defecte. Les empreses poden basar-se en l'èxit dels altres.",
  "page-what-is-ethereum-dapps-desc": "Productes i serveis que funcionen a Ethereum. Hi ha dapps per a finances, feina, xarxes socials, jocs d'atzar i molt més: descobreix les aplicacions del nostre futur digital.",
  "page-what-is-ethereum-dapps-img-alt": "Una il·lustració d'un gos que fa servir una aplicació d'Ethereum en un ordinador",
  "page-what-is-ethereum-dapps-title": "Dapps d'Ethereum",
  "page-what-is-ethereum-desc": "La base del nostre futur digital",
  "page-what-is-ethereum-explore": "Explora Ethereum",
  "page-what-is-ethereum-get-started": "La millor manera d'aprendre més és descarregar una cartera, aconseguir ETH i provar una DAPP d'Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum és un accés obert als diners digitals i als serveis d'informació per a tots, independentment de l'origen o ubicació. És una tecnologia creada per la comunitat després de la criptomoneda ether (ETH) i milers d'aplicacions que pots fer servir avui.",
  "page-what-is-ethereum-internet-card": "Una Internet més privada",
  "page-what-is-ethereum-internet-card-desc": "No has de proporcionar totes les teves dades personals per fer servir una app d'Ethereum. Ethereum està construint una economia basada en el valor, no en la vigilància.",
  "page-what-is-ethereum-meet-comm": "Coneix la comunitat",
  "page-what-is-ethereum-meta-description": "Obtén informació sobre Ethereum, què fa i com provar-ho per tu mateix.",
  "page-what-is-ethereum-meta-title": "Què és Ethereum?",
  "page-what-is-ethereum-native-alt": "El símbol de l'ether (ETH)",
  "page-what-is-ethereum-native-crypto": "Criptomoneda nativa d'Ethereum i equivalent a Bitcoin. Pots fer servir ETH en aplicacions d'Ethereum o per enviar valors a amics i família.",
  "page-what-is-ethereum-native-img-alt": "Una il·lustració d'un robot amb una caixa forta com a tors, utilitzada per representar les carteres d'Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Una xarxa de parells",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum et permet moure diners o arribar a acords directament amb una altra persona. No necessites passar per empreses intermediàries.",
  "page-what-is-ethereum-singlecard-desc": "Si estàs interessat en la cadena de blocs i la part tècnica d'Ethereum, t'hem trobat.",
  "page-what-is-ethereum-singlecard-link": "Com funciona Ethereum",
  "page-what-is-ethereum-singlecard-title": "Com funciona Ethereum",
  "page-what-is-ethereum-start-building-btn": "Comença a crear",
  "page-what-is-ethereum-title": "Què és Ethereum?",
  "page-what-is-ethereum-tools-needed": "Tot el que necessites per participar és una cartera.",
  "page-what-is-ethereum-try": "Prova Ethereum",
  "page-what-is-ethereum-tryit": "Així que entra al basar i prova-ho...",
  "page-what-is-ethereum-wallets": "Carteres",
  "page-what-is-ethereum-wallets-desc": "Com pots administrar els teus ETH i teu compte d'Ethereum? Necessitaràs una cartera per començar. T'ajudarem a triar-ne una.",
  "page-what-is-ethereum-welcome": "Benvingut a Ethereum",
  "page-what-is-ethereum-welcome-2": "Esperem que et quedis.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Han arribat les apostes! Si el que vols és apostar el teu ETH",
  "banner-staking-2": "confirma l'adreça del contracte de dipòsit",
  "docking": "Acoblament",
  "page-eth2-vision-security-desc-9": "ens permet assignar validadors aleatòriament a diferents fragments. Això fa que sigui virtualment impossible per als validadors tenir connivència per atacar un fragment específic. La fragmentació no és segura en una cadena de blocs de prova de treball perquè el protocol no pot controlar els miners d'aquesta manera.",
  "page-index-sections-developers-desc": "Obtén informació sobre la tecnologia que hi ha al darrere d’Ethereum i les seves aplicacions per poder començar a desenvolupar.",
  "page-index-sections-developers-image-alt": "Il·lustració d'una mà construint un glif d'Ethereum fet amb peces de Lego",
  "page-index-sections-developers-link-text": "Comença a desenvolupar",
  "page-index-sections-developers-title": "Desenvolupadors",
  "page-index-sections-enterprise-desc": "Descobreix com Ethereum pot ajudar la teva empresa a trobar nous models de negoci, a reduir els costos i a afrontar el futur.",
  "page-index-sections-enterprise-image-alt": "Il·lustració d'un grup que treballa en un projecte d'Ethereum al voltant d'un ordinador portàtil",
  "page-index-sections-enterprise-link-text": "Ethereum per a l'empresa",
  "page-index-sections-enterprise-title": "Empresa",
  "page-index-sections-individuals-desc": "Coneix Ethereum, Ether, les carteres, els tokens i molt més per començar a utilitzar les aplicacions d'Ethereum.",
  "page-index-sections-individuals-image-alt": "Il·lustració d'un gos assegut davant d'un ordinador",
  "page-index-sections-individuals-link-text": "Comença a utilitzar Ethereum",
  "page-index-sections-individuals-title": "Sobre Ethereum",
  "page-index-subtitle": "Amb Ethereum pots escriure un codi que controli un valor digital, s'executi exactament tal com ha estat programat i sigui accessible des de qualsevol lloc del món.",
  "page-find-wallet-authereum-logo-alt": "Logotip d'Authereum",
  "page-find-wallet-description-authereum": "Sense descàrregues, sense frases llavor. Qualsevol navegador, en qualsevol moment, amb qualsevol dispositiu mòbil o d'escriptori",
  "page-wallets-accounts-addresses-desc-2": "és una entitat que pot enviar transaccions i tenir un saldo.",
  "page-wallets-accounts-has": "Un compte d'Ethereum té una",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "Adreça d'Ethereum",
  "page-wallets-ethereum-addresses-2": ", com una safata d'entrada que té una adreça electrònica. Pots utilitzar l'adreça per enviar fons a un compte.",
  "page-wallets-ethereum-wallet-2": "és un producte que et permet gestionar el teu compte d'Ethereum, com ara veure el saldo del teu compte, enviar transaccions i més coses."
}
