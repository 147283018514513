{
  "1inch-logo": "1inch ロゴ",
  "aave-logo": "Aave ロゴ",
  "about": "詳細",
  "about-ethereum-org": "Ethereum.orgについて",
  "about-us": "私たちについて",
  "alt-eth-blocks": "ETHの記号のように構成されているブロックの図",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "トップに戻る",
  "banner-page-incomplete": "このページは未完成です。もしこちらのトピックについてご存じの場合は、ページの編集にご協力をお願いいたします。",
  "beacon-chain": "ビーコンチェーン",
  "binance-logo": "Binance ロゴ",
  "bittrex-logo": "Bittrex ロゴ",
  "brand-assets": "ブランドアセット",
  "bug-bounty": "バグバウンティ",
  "coinbase-logo": "Coinbaseロゴ",
  "coinmama-logo": "Coinmama ロゴ",
  "community": "コミュニティ",
  "community-menu": "Community Menu",
  "compound-logo": "Compound ロゴ",
  "cons": "欠点",
  "contact": "お問い合わせ",
  "content-versions": "Content Versions",
  "contributing": "貢献",
  "contributors": "貢献者",
  "contributors-thanks": "このページを編集して下さった方々へ感謝いたします。",
  "cookie-policy": "クッキーポリシー",
  "copied": "コピー済み",
  "copy": "コピー",
  "dark-mode": "ダークモード",
  "data-provided-by": "データ提供元:",
  "decentralized-applications-dapps": "分散アプリケーション (dapps)",
  "deposit-contract": "デポジットコントラクト",
  "devcon": "Devcon",
  "developers": "開発者向け",
  "developers-home": "開発者のホーム",
  "docs": "ドキュメント",
  "documentation": "ドキュメント",
  "dydx-logo": "Dydx ロゴ",
  "ecosystem": "エコシステム",
  "edit-page": "ページ編集",
  "ef-blog": "イーサリアム財団ブログ",
  "eips": "イーサリアム改善の提案",
  "enterprise": "事業活用",
  "enterprise-menu": "企業向けメニュー",
  "esp": "エコシステムサポートプログラム",
  "eth-current-price": "現在のETH価格 (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "オープンソースのEth2ビーコンチェーンエクスプローラー",
  "eth2-beaconscan-desc": "Eth2のビーコンチェーンエクスプローラー – Eth2のEtherscan",
  "eth2-become-staker": "ステーカーになる",
  "eth2-become-staker-desc": "ステーキングは有効です！ネットワークの安全性を高めるためにご自身のETHをステーキングしたい場合、そのリスクをよく理解してください。",
  "eth2-explore": "データを探索する",
  "eth2-no-action-needed": "既に保有しているETHについては、何もする必要はありません。ETHを送付して交換するように求めてくる詐欺師にはご注意ください。",
  "eth2-run-beacon-chain": "ビーコンクライアントの実行",
  "eth2-run-beacon-chain-desc": "イーサリアムでは可能な限り多くのクライアントが稼働している必要があります。イーサリアムの公益的な活動にご協力ください。",
  "eth2-service-announcement": "Eth2 サービスのお知らせ",
  "eth2-what-shipping": "導入はいつですか？",
  "ethereum": "イーサリアム",
  "ethereum-2-0": "イーサリウム2.0",
  "ethereum-brand-assets": "イーサリアムブランドアセット",
  "ethereum-community": "イーサリアムコミュニティ",
  "ethereum-foundation": "イーサリアム財団",
  "ethereum-foundation-logo": "イーサリアム財団ロゴ",
  "ethereum-glossary": "イーサリアム用語集",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Ethereum ロゴ",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "イーサリアムスタジオ",
  "ethereum-wallets": "イーサリアムウォレット",
  "ethereum-whitepaper": "イーサリアムのホワイトペーパー",
  "example-projects": "プロジェクト例",
  "find-wallet": "ウォレットを探す",
  "foundation": "基礎",
  "gemini-logo": "Gemini",
  "get-eth": "ETHを入手",
  "get-involved": "参加しましょう",
  "get-started": "始める",
  "gitcoin-logo": "Gitcoin",
  "glossary": "用語集",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "ガイドとリソース",
  "history": "歴史",
  "history-of-ethereum": "イーサリアムの歴史",
  "home": "ホーム",
  "how-ethereum-works": "イーサリアムの仕組み",
  "image": "画像",
  "in-this-section": "このセクション",
  "individuals": "一般",
  "individuals-menu": "一般向けメニュー",
  "jobs": "Jobs",
  "kraken-logo": "Kraken ロゴ",
  "language-ar": "Arabic",
  "language-bg": "Bulgarian",
  "language-bn": "Bengali",
  "language-ca": "Catalan",
  "language-cs": "Czech",
  "language-de": "German",
  "language-el": "Greek",
  "language-en": "English",
  "language-es": "Spanish",
  "language-fa": "Farsi",
  "language-fi": "Finnish",
  "language-fr": "French",
  "language-hu": "Hungarian",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonesian",
  "language-ig": "Igbo",
  "language-it": "Italian",
  "language-ja": "Japanese",
  "language-ko": "Korean",
  "language-lt": "Lithuanian",
  "language-ml": "Malayalam",
  "language-nb": "Norwegian",
  "language-nl": "Dutch",
  "language-pl": "Polish",
  "language-pt": "Portuguese",
  "language-pt-br": "Portuguese (Brazilian)",
  "language-ro": "Romanian",
  "language-ru": "Russian",
  "language-se": "Swedish",
  "language-sk": "Slovak",
  "language-sl": "Slovenian",
  "language-support": "言語サポート",
  "language-tr": "Turkish",
  "language-uk": "Ukrainian",
  "language-vi": "Vietnamese",
  "language-zh": "Chinese Simplified",
  "language-zh-tw": "Chinese Traditional",
  "languages": "言語",
  "last-24-hrs": "24時間以内",
  "last-edit": "最終編集者",
  "learn": "知る",
  "learn-by-coding": "コーディングで学ぶ",
  "learn-menu": "メニューを学ぶ",
  "learn-more": "もっと詳しく",
  "less": "より少なく",
  "light-mode": "ライトモード",
  "listing-policy-disclaimer": "このページに掲載されている全てのプロダクトは、公式に推奨するものではなく、情報提供のみを目的としています。プロダクトの追加やポリシーに関するフィードバックをご希望の場合は、GitHubで問題を提起してください。",
  "listing-policy-raise-issue-link": "問題を報告する",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "読み込み中",
  "loading-error": "読み込みエラー",
  "loading-error-refresh": "読み込みに失敗しました。ページの更新を試行してください。",
  "logo": "ロゴ",
  "loopring-logo": "Loopring ロゴ",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "メインネット イーサリアム",
  "makerdao-logo": "MakerDao ロゴ",
  "matcha-logo": "Matcha ロゴ",
  "merge": "Merge",
  "more": "もっと見る",
  "nav-beginners": "初心者",
  "next": "次へ",
  "oasis-logo": "Oasis",
  "on-this-page": "このページ",
  "page-content": "ページコンテンツ",
  "page-enterprise": "エンタープライズ",
  "page-last-updated": "ページの最終更新日時",
  "previous": "前へ",
  "privacy-policy": "プライバシーポリシー",
  "private-ethereum": "プライベートイーサリアム",
  "pros": "利点",
  "read-more": "続きを読む",
  "refresh": "ページを更新してください。",
  "review-progress": "レビューの進捗",
  "search": "検索",
  "search-box-blank-state-text": "検索！",
  "search-eth-address": "これはイーサリアムのアドレスのようですが、アドレスに固有のデータは提供していません。etherscanのようなブロックエクスプローラーで検索してみてください。",
  "search-no-results": "検索結果はありません。",
  "security": "Security",
  "see-contributors": "貢献者を表示",
  "set-up-local-env": "ローカル環境の設定",
  "shard-chains": "シャードチェーン",
  "show-all": "すべて表示",
  "show-less": "少なく表示",
  "site-description": "イーサリウムは、お金と新しい種類のアプリケーションのためのグローバルな分散型プラットフォームです。 イーサリウムでは、お金を制御するコードを書くことができ、世界中のどこにいてもアクセス可能なアプリケーションを構築できます。",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "ステーブルコイン",
  "staking": "ステーキング",
  "summary": "概要",
  "terms-of-use": "利用規約",
  "transaction-fees": "取引手数料とは？",
  "translation-banner-body-new": "このページはまだ翻訳されていないため、英語で表示されています。このコンテンツの翻訳にご協力ください。",
  "translation-banner-body-update": "このページの新しいバージョンがありますが、現在は英語のみです。最新バージョンの翻訳にご協力ください。",
  "translation-banner-button-join-translation-program": "翻訳プロジェクトへの参加",
  "translation-banner-button-learn-more": "もっと詳しく",
  "translation-banner-button-see-english": "英語を見る",
  "translation-banner-button-translate-page": "ページを翻訳する",
  "translation-banner-title-new": "このページの翻訳を行う",
  "translation-banner-title-update": "このページの翻訳を行う",
  "translation-program": "Translation Program",
  "translation-progress": "翻訳の進捗",
  "tutorials": "チュートリアル",
  "uniswap-logo": "Uniswap ロゴ",
  "use": "使う",
  "use-ethereum": "イーサリアムを使う",
  "use-ethereum-menu": "イーサリアムのメニューを使用",
  "vision": "ビジョン",
  "wallets": "ウォレット",
  "website-last-updated": "ウェブサイトの最終更新日時",
  "what-is-ether": "イーサ(ETH) とは？",
  "what-is-ethereum": "イーサリアムとは？",
  "whitepaper": "ホワイトペーパー",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "利用方法",
  "page-about-h2": "機能を提案",
  "page-about-h3": "処理中です",
  "page-about-h3-1": "実装済み機能",
  "page-about-h3-2": "予定されている機能",
  "page-about-li-1": "進行中",
  "page-about-li-2": "計画済み",
  "page-about-li-3": "実装済み",
  "page-about-li-4": "実装済み",
  "page-about-link-1": "このリポジトリのソースコードは、MIT ライセンスの下でライセンスされています",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "GitHub で進行中のタスクの全リストを表示する",
  "page-about-link-4": "Discord サーバーに参加する",
  "page-about-link-5": "Twitterでご連絡ください",
  "page-about-link-6": "GitHub で実装されたタスクの一覧を表示する",
  "page-about-link-7": "GitHubでイシューを作成する",
  "page-about-p-1": "ethereum.org の立ち上げ以来、私たちはどのように事業を運営しているかを透明にするよう努めています。透明性が Ethereum の成功に不可欠であると信じているため、私たちのコア価値の1つです。",
  "page-about-p-2": "私たちは使用します",
  "page-about-p-3": "主要なプロジェクト管理ツールとして、タスクを3つのカテゴリーに分類しています。",
  "page-about-p-4": "  私たちは、特定のタスクの状況をコミュニティに知らせ続けるよう最善を尽くします。",
  "page-about-p-5": "実装中のタスク。",
  "page-about-p-6": "次に実装するためにキューに入れたタスク。",
  "page-about-p-7": "最近完了したタスク。",
  "page-about-p-8": "ethereum.org を改善するためのアイデアがありますか？ ぜひコラボレーションしていきたいです！",
  "page-assets-bazaar": "イーサリアムの基礎",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "ビルディング・ブロック",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Dogeはdappsを使っている",
  "page-assets-download-artist": "アーティスト:",
  "page-assets-download-download": "ダウンロード",
  "page-assets-enterprise": "エンタープライズイーサリアム",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "ETHダイヤモンド(色)",
  "page-assets-eth-diamond-glyph": "ETHダイヤモンド(グリフ)",
  "page-assets-eth-diamond-gray": "ETHダイヤモンド(グレー)",
  "page-assets-eth-diamond-purple": "ETHダイヤモンド(紫)",
  "page-assets-eth-diamond-white": "ETHダイヤモンド(白)",
  "page-assets-eth-glyph-video-dark": "ETHグリフビデオ (ダーク)",
  "page-assets-eth-glyph-video-light": "ETHグリフビデオ (ライト)",
  "page-assets-eth-logo-landscape-gray": "ETHロゴの横向き(グレー)",
  "page-assets-eth-logo-landscape-purple": "ETHロゴの横向き(紫)",
  "page-assets-eth-logo-landscape-white": "ETHロゴの横向き(白)",
  "page-assets-eth-logo-portrait-gray": "ETHロゴの縦向き(グレー)",
  "page-assets-eth-logo-portrait-purple": "ETHロゴの縦向き(紫)",
  "page-assets-eth-logo-portrait-white": "ETHロゴの縦向き(白)",
  "page-assets-eth-wordmark-gray": "ETHワードマーク (グレー)",
  "page-assets-eth-wordmark-purple": "ETHワードマーク (紫)",
  "page-assets-eth-wordmark-white": "ETHワードマーク (ホワイト)",
  "page-assets-ethereum-brand-assets": "イーサリアムブランドアセット",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "ethereum.org assets",
  "page-assets-hero": "ethereum.orgのヒーロー",
  "page-assets-hero-particles": "ETHパーティクル画像",
  "page-assets-historical-artwork": "過去の作品",
  "page-assets-illustrations": "イラスト",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "EthereumとEthereum.orgブランド資産、イラスト、メディアを探索してダウンロードします。",
  "page-assets-meta-title": "イーサリアムブランドのアセット",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "ソリッド背景",
  "page-assets-page-assets-transparent-background": "透明な背景",
  "page-assets-robot": "ロボットウォレット",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1inch ロゴ",
  "page-dapps-aave-logo-alt": "Aave ロゴ",
  "page-dapps-add-button": "Dappを提案",
  "page-dapps-add-title": "Dappを追加",
  "page-dapps-audius-logo-alt": "Audius ロゴ",
  "page-dapps-augur-logo-alt": "Augur ロゴ",
  "page-dapps-axie-infinity-logo-alt": "Axie Infinity ロゴ",
  "page-dapps-brave-logo-alt": "Brave ロゴ",
  "page-dapps-category-arts": "アートとファッション",
  "page-dapps-category-browsers": "ブラウザ",
  "page-dapps-category-collectibles": "デジタル収集品",
  "page-dapps-category-competitive": "競争",
  "page-dapps-category-computing": "開発者ツール",
  "page-dapps-category-dex": "トークンスワップ",
  "page-dapps-category-investments": "投資",
  "page-dapps-category-lending": "貸借",
  "page-dapps-category-lottery": "クラウドファンディング",
  "page-dapps-category-marketplaces": "マーケットプレイス",
  "page-dapps-category-music": "音楽",
  "page-dapps-category-payments": "お支払い",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "取引と予測市場",
  "page-dapps-category-utilities": "ユーティリティ",
  "page-dapps-category-worlds": "仮想世界",
  "page-dapps-choose-category": "カテゴリの選択",
  "page-dapps-collectibles-benefits-1-description": "アートがイーサリアム上でトークン化されると、所有権が誰の目にも明らかになります。作品の制作から現在の所有者までを追跡できるため、偽造を防ぐことができます。",
  "page-dapps-collectibles-benefits-1-title": "所有権が証明可能",
  "page-dapps-collectibles-benefits-2-description": "音楽のストリーミングやアート作品の購入にお金を払うことは、アーティストにとって公平です。イーサリアムでは、仲介者が必要ありません。また、仲介者が必要な場合でも、プラットフォームがネットワークのインフラにお金を払う必要がないため、そのコストはそれほど高くありません。",
  "page-dapps-collectibles-benefits-2-title": "クリエイターのための公平性",
  "page-dapps-collectibles-benefits-3-description": "トークン化された収集品は、プラットフォームではなく、あなたのイーサリアムアドレスに結びついています。そのため、ゲーム内のアイテムの様なものは、そのゲーム内だけでなく、あらゆるイーサリアムマーケットプレイスで販売する事ができます。",
  "page-dapps-collectibles-benefits-3-title": "収集可能品はあなたの元にあります",
  "page-dapps-collectibles-benefits-4-description": "アートをトークン化して販売するためのツールやプロダクトは既に存在しています！そして、そのトークンはあらゆるイーサリアムの収集プラットフォームで販売する事ができます。",
  "page-dapps-collectibles-benefits-4-title": "インフラは既に整備されています",
  "page-dapps-collectibles-benefits-description": "これらのアプリケーションは、デジタルなオーナーシップに焦点を当て、クリエイターの収益性を高め、お気に入りのクリエイターとその作品に投資するための新しい方法を生み出すものです。",
  "page-dapps-collectibles-benefits-title": "分散型の収集品とストリーミング",
  "page-dapps-collectibles-button": "アートと収集品",
  "page-dapps-collectibles-description": "これらのアプリケーションは、デジタルオーナーシップに焦点を当て、クリエイターの収益性を高め、お気に入りのクリエイターとその作品に投資するための新しい方法を生み出すものです。",
  "page-dapps-collectibles-title": "分散アートと収集品",
  "page-dapps-compound-logo-alt": "Compound ロゴ",
  "page-dapps-cryptopunks-logo-alt": "CryptoPunks ロゴ",
  "page-dapps-cryptovoxels-logo-alt": "Cryptovoxels ロゴ",
  "page-dapps-dapp-description-1inch": "最善の値をまとめることにより、高価格のslippageを避けるのに役立ちます。",
  "page-dapps-dapp-description-aave": "トークンを貸して利子を獲得し、いつでも引き出すことができます。",
  "page-dapps-dapp-description-async-art": "#ProgrammableArt の作成、収集、および取引 - デジタルペインティングは、全体的なイメージに影響を与えるために使用できる「レイヤー」に分割されます。 各マスターとレイヤーはERC721トークンです。",
  "page-dapps-dapp-description-audius": "分散型ストリーミングプラットフォーム。Listens =ラベルではなくクリエイターのためのお金。",
  "page-dapps-dapp-description-augur": "スポーツ、経済、およびより多くの世界のイベントの結果に賭けます。",
  "page-dapps-dapp-description-axie-infinity": "Axiesと呼ばれる取引およびバトルクリーチャー。プレイしながら収入が得られます - モバイルで利用可能です",
  "page-dapps-dapp-description-brave": "お気に入りのクリエイターを閲覧してサポートするためのトークンを獲得しましょう。",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "トークンを貸して利子を獲得し, いつでも引き出しできます。",
  "page-dapps-dapp-description-cryptopunks": "購入、入札、セール中のパンクを提供する 、イーサリウムで最初のトークン収集品の1つ。",
  "page-dapps-dapp-description-cryptovoxels": "アートギャラリーを作成し、店舗を構築し、土地を購入する - Ethereum仮想世界。",
  "page-dapps-dapp-description-dark-forest": "無限の、手続き的に生成された、暗号学的に指定された宇宙で惑星を征服します。",
  "page-dapps-dapp-description-decentraland": "探索することができる仮想世界で仮想土地を収集、取引。",
  "page-dapps-dapp-description-dydx": "10倍までのレバレッジでショートまたはレバレッジポジションを開きます。",
  "page-dapps-dapp-description-ens": "イーサリウムアドレスと分散サイトのユーザーフレンドリーな名前。",
  "page-dapps-dapp-description-foundation": "デジタルアートワークのユニークなエディションに投資し、他のバイヤーと作品を取引します。",
  "page-dapps-dapp-description-gitcoin": "オープンソースソフトウェアで作業する暗号を獲得します。",
  "page-dapps-dapp-description-gitcoin-grants": "貢献が増幅されるイーサリウムコミュニティプロジェクトのクラウドファンディング",
  "page-dapps-dapp-description-gods-unchained": "戦略的なトレーディングカードゲーム。実際に売れるようにすることでカードを稼ぐことができます。",
  "page-dapps-dapp-description-golem": "共有コンピューティングパワーにアクセスしたり、自分のリソースを借りたりします。",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "ピアツーピア取引プラットフォームは、スピードのために構築されています。",
  "page-dapps-dapp-description-marble-cards": "URLに基づいてユニークなデジタルカードを作成し、取引します。",
  "page-dapps-dapp-description-matcha": "複数の取引所を検索して、最善の価格を見つけることができます。",
  "page-dapps-dapp-description-nifty-gateway": "トップアーティスト、アスリート、ブランド、クリエイターからチェーン上で作品を購入。",
  "page-dapps-dapp-description-oasis": "イーサリウムのステーブルコインであるDaiで取引、借入、保存します。",
  "page-dapps-dapp-description-opensea": "限定品の購入、販売、発見、取引。",
  "page-dapps-dapp-description-opera": "ブラウザからマーチャント、他のユーザーやアプリに暗号を送信します。",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "結果に賭けてください。情報市場で取引します。",
  "page-dapps-dapp-description-pooltogether": "負けることがない宝くじ。毎週賞金が当たります。",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "トークン化されたグッズを作成、販売、購入します。",
  "page-dapps-dapp-description-sablier": "リアルタイムでお金をストリーミングします。",
  "page-dapps-dapp-description-superrare": "アーティストまたは二次市場から直接デジタルアート作品を購入。",
  "page-dapps-dapp-description-token-sets": "自動的にバランスを取る暗号投資戦略。",
  "page-dapps-dapp-description-tornado-cash": "イーサリウムで匿名のトランザクションを送信します。",
  "page-dapps-dapp-description-uniswap": "トークンを交換するか、トークンを%の報酬で提供します。",
  "page-dapps-dark-forest-logo-alt": "Dark Forest ロゴ",
  "page-dapps-decentraland-logo-alt": "Decentraland ロゴ",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "試してみたいイーサリアムのアプリケーションを見つけましょう。",
  "page-dapps-doge-img-alt": "コンピューターを使用しているドージのイラスト",
  "page-dapps-dydx-logo-alt": "dydx ロゴ",
  "page-dapps-editors-choice-dark-forest": "惑星を征服し、最先端のEthereumスケーリング/プライバシー技術を試してみるために他の人と対戦します。",
  "page-dapps-editors-choice-description": "Ethereum.orgチームが今気に入っている分散アプリケーション（dapps）がいくつかあります。以下でdappsを探してください。",
  "page-dapps-editors-choice-foundation": "文化に投資しましょう。素晴らしいアーティスト、ミュージシャン、ブランドからユニークなデジタルアートワークやファッションを購入、取引、販売してください。",
  "page-dapps-editors-choice-header": "編集者のチョイス",
  "page-dapps-editors-choice-pooltogether": "ロスなし宝くじのチケットを購入します。毎週、チケットプール全体から生成された利息は、1人の幸運な勝者に送信されます。 好きな時にお金を取り戻しましょう。",
  "page-dapps-editors-choice-uniswap": "簡単にトークンを交換します。ネットワーク全体の人々とトークンを取引できるコミュニティのお気に入りです。",
  "page-dapps-ens-logo-alt": "Ethereum Name Service ロゴ",
  "page-dapps-explore-dapps-description": "多くの分散アプリケーション（dapps）はまだ実験的であり、分散ネットワークの可能性をテストしています。 しかし、テクノロジー、金融、ゲーム、グッズのカテゴリで初期の動きが成功しました。",
  "page-dapps-explore-dapps-title": "dapps を探す",
  "page-dapps-features-1-description": "イーサリウムにデプロイすると、分散アプリケーション（Dapp）コードは削除できなくなります。dappの機能は誰でも使用できます。 dappの背後にあるチームが解散したとしても、それをまだ使用することができました。一度イーサリウムに導入されると、ずっと使えるようになります。",
  "page-dapps-features-1-title": "所有者なし",
  "page-dapps-features-2-description": "Dappの使用やトランザクションの送信をブロックすることはできません。 たとえば、Twitterがイーサリウムにある場合、誰もあなたのアカウントをブロックしたり、あなたがツイートするのを止めることはできません。",
  "page-dapps-features-2-title": "検閲なし",
  "page-dapps-features-3-description": "イーサリウムにはETHがあるため、支払いはイーサリウム固有の方法です。開発者はサードパーティの支払いプロバイダーと統合する時間を費やす必要はありません。",
  "page-dapps-features-3-title": "組み込みの支払い",
  "page-dapps-features-4-description": "Dappコードは、デフォルトでオープンで互換性があります。 チームは定期的に他のチームの作業を使用してビルドします。 ユーザーにdappでトークンを交換させたい場合は、別のdappのコードを入力します。",
  "page-dapps-features-4-title": "プラグアンドプレイ",
  "page-dapps-features-5-description": "ほとんどのdappsでは、実際のIDを共有する必要はありません。あなたのEthereumアカウントはあなたのログインであり、ウォレットが必要です。",
  "page-dapps-features-5-title": "1つの匿名ログイン",
  "page-dapps-features-6-description": "暗号化により、攻撃者がトランザクションやその他のdappのやり取りを偽造することはできません。 通常はウォレットを介してイーサリアムアカウントを使用してdappアクションの認証を行うため、認証情報を安全に保つことができます。",
  "page-dapps-features-6-title": "暗号化によって保護されています",
  "page-dapps-features-7-description": "Dappがイーサリウム上でライブになると、イーサリウム自体がダウンした場合にのみダウンします。 イーサリウムの規模のネットワークは、攻撃が難しいことで知られています。",
  "page-dapps-features-7-title": "ダウンタイムなし",
  "page-dapps-finance-benefits-1-description": "イーサリウム上で動作する金融サービスにはサインアップの要件はありません。資金とインターネット接続がある場合は、準備が整っています。",
  "page-dapps-finance-benefits-1-title": "オープンアクセス",
  "page-dapps-finance-benefits-2-description": "これらの金融商品間でやり取りできるトークンの全世界があります。 人々はイーサリウムの上に常に新しいトークンを構築しています。",
  "page-dapps-finance-benefits-2-title": "新しいトークンエコノミー",
  "page-dapps-finance-benefits-3-description": "チームは変動性の低い暗号通貨であるステーブルコインを開発しました。これにより、リスクや不確実性なしに暗号を実験して使用できるようになります。",
  "page-dapps-finance-benefits-3-title": "ステーブルコイン",
  "page-dapps-finance-benefits-4-description": "イーサリウム市場の金融商品はすべてモジュール型で互いに互換性があります。 これらのモジュールの新しい構成は常に市場に出回っており、暗号を使用してできることが増加しています。",
  "page-dapps-finance-benefits-4-title": "相互接続された金融サービス",
  "page-dapps-finance-benefits-description": "分散型の金融アプリケーションの成功を可能とするイーサリウムの特長は何ですか?",
  "page-dapps-finance-benefits-title": "分散型金融",
  "page-dapps-finance-button": "ファイナンス",
  "page-dapps-finance-description": "これらは、暗号通貨を使用した金融サービスの構築に焦点を当てたアプリケーションです。 貸出、借入、利子の収入、個人的な支払いなどを提供します。個人情報は必要ありません。",
  "page-dapps-finance-title": "分散型金融",
  "page-dapps-foundation-logo-alt": "Foundation ロゴ",
  "page-dapps-gaming-benefits-1-description": "仮想の土地やトレーディングカードであっても、あなたのアイテムはグッズ市場で取引可能です。ゲーム内アイテムは実際の価値を持っています。",
  "page-dapps-gaming-benefits-1-title": "ゲームアイテムをトークンとして2倍にする",
  "page-dapps-gaming-benefits-2-description": "あなたは自分のアイテムを所有しており、進捗状況を所有している場合もあります。ゲーム会社が所有しているのではありません。 したがって、ゲームの背後にある会社が攻撃され、サーバーの故障、または解散が発生した場合、何も失うことはありません。",
  "page-dapps-gaming-benefits-2-title": "セーブデータは安全です",
  "page-dapps-gaming-benefits-3-description": "Ethereumの支払いは誰でも確認できるのと同じように、ゲームはこの品質を使用して公平性を保証することができます。 理論的には、クリティカルヒット数から相手のバトルチェストのサイズまですべてが検証可能です。",
  "page-dapps-gaming-benefits-3-title": "証明可能な公平性",
  "page-dapps-gaming-benefits-description": "分散型の金融アプリケーションの成功を可能とするイーサリウムの特長は何ですか?",
  "page-dapps-gaming-benefits-title": "分散ゲーミング",
  "page-dapps-gaming-button": "ゲーム",
  "page-dapps-gaming-description": "これらは、仮想世界の作成に焦点を当て、現実の価値を保持するグッズを使用して他のプレイヤーと戦うアプリケーションです。",
  "page-dapps-gaming-title": "分散型ゲーム",
  "page-dapps-get-some-eth-description": "Dappアクションは手数料がかかります",
  "page-dapps-get-started-subtitle": "分散アプリケーション（Dapp）を試すにはウォレットとETHが必要です。ウォレットは接続やログインを許可します。 また、取引手数料を支払うにはETHが必要です。",
  "page-dapps-get-started-title": "始める",
  "page-dapps-gitcoin-grants-logo-alt": "Gitcoin Grants ロゴ",
  "page-dapps-gitcoin-logo-alt": "Gitcoin ロゴ",
  "page-dapps-gods-unchained-logo-alt": "Gods Unchained ロゴ",
  "page-dapps-golem-logo-alt": "Golem ロゴ",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "イーサリアム搭載のツールとサービス",
  "page-dapps-hero-subtitle": "分散アプリケーション（dapps）は、イーサリウムを使用してビジネスモデルを革新したり新しいビジネスモデルを発明する、拡大中のアプリケーションです。",
  "page-dapps-how-dapps-work-p1": "Dapps には、分散型ネットワーク上で動作するバックエンドコード (スマートコントラクト) があり、集中型サーバーではありません。 アプリロジックのデータストレージとスマートコントラクトにイーサリウムブロックチェーンを使用します。",
  "page-dapps-how-dapps-work-p2": "スマートコントラクトは、すべての人がルールに従って正確に見て実行できるように、チェーンの一連のルールのようなものです。 自販機を想像してみてください十分な資金と適切な選択をすれば 欲しい商品が手に入ります そして自動販売機のように、スマートコントラクトはあなたのEthereumアカウントと同様に資金を保持することができます。これにより、コードは契約や取引を仲介することができます。",
  "page-dapps-how-dapps-work-p3": "Dappsがイーサリウムネットワークにいったんデプロイされると、変更することはできません。 Dappsは、個人や企業ではなく、コントラクトに記述されたロジックによって制御されるため、分散化することができます。",
  "page-dapps-how-dapps-work-title": "Dappsの仕組み",
  "page-dapps-learn-callout-button": "開発を始める",
  "page-dapps-learn-callout-description": "コミュニティ開発者ポータルには、dappを構築するのに役立つドキュメント、ツール、フレームワークがあります。",
  "page-dapps-learn-callout-image-alt": "レゴブロックでETHロゴを作成している手のイラスト",
  "page-dapps-learn-callout-title": "Dappの構築方を学ぶ",
  "page-dapps-loopring-logo-alt": "Loopring ロゴ",
  "page-dapps-magic-behind-dapps-description": "Dappsは通常のアプリのように感じるかもしれません。 しかし、舞台裏ではイーサリウムの超能力を全て受け継いでいるため、彼らにはいくつかの特別な資質があります。 アプリの違いは以下の通りです。",
  "page-dapps-magic-behind-dapps-link": "イーサリウムが素晴らしい理由は何ですか?",
  "page-dapps-magic-behind-dapps-title": "Dappsの背後にある魔法",
  "page-dapps-magic-title-1": "魔法",
  "page-dapps-magic-title-2": "後ろに",
  "page-dapps-magician-img-alt": "魔術師の実例",
  "page-dapps-marble-cards-logo-alt": "marble.cards ロゴ",
  "page-dapps-matcha-logo-alt": "Matcha ロゴ",
  "page-dapps-mobile-options-header": "他のカテゴリを参照",
  "page-dapps-nifty-gateway-logo-alt": "Nifty Gateway ロゴ",
  "page-dapps-oasis-logo-alt": "Oasis ロゴ",
  "page-dapps-opensea-logo-alt": "OpenSea ロゴ",
  "page-dapps-opera-logo-alt": "Opera ロゴ",
  "page-dapps-polymarket-logo-alt": "Polymarket ロゴ",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "PoolTogether ロゴ",
  "page-dapps-rarible-logo-alt": "Rarible ロゴ",
  "page-dapps-ready-button": "次へ",
  "page-dapps-ready-description": "Dappを選択して試してください。",
  "page-dapps-ready-title": "準備はできましたか？",
  "page-dapps-sablier-logo-alt": "Sablier ロゴ",
  "page-dapps-set-up-a-wallet-button": "ウォレットを探す",
  "page-dapps-set-up-a-wallet-description": "ウォレットはdappの「ログイン」です。",
  "page-dapps-set-up-a-wallet-title": "ウォレットの設定",
  "page-dapps-superrare-logo-alt": "SuperRare ロゴ",
  "page-dapps-technology-button": "テクノロジー",
  "page-dapps-technology-description": "これらのアプリケーションは、開発者ツールを分散化させ、暗号資産経済システムを既存の技術に統合し、オープンソース開発作業のための市場を作成します。",
  "page-dapps-technology-title": "分散型テクノロジー",
  "page-dapps-token-sets-logo-alt": "Token Sets ロゴ",
  "page-dapps-tornado-cash-logo-alt": "Tornado cash ロゴ",
  "page-dapps-uniswap-logo-alt": "Uniswap ロゴ",
  "page-dapps-wallet-callout-button": "ウォレットを探す",
  "page-dapps-wallet-callout-description": "ウォレットもdappsです。 自分に合った特徴に基づいて探してください。",
  "page-dapps-wallet-callout-image-alt": "ロボットのイラスト。",
  "page-dapps-wallet-callout-title": "ウォレットを表示する",
  "page-dapps-warning-header": "常に自分でリサーチしよう",
  "page-dapps-warning-message": "イーサリウムは新しい技術であり、ほとんどのアプリケーションは新しいものです。 大量のお金を預ける前に、リスクを理解してください。",
  "page-dapps-what-are-dapps": "Dappsとは?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "アカウント",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "詳細",
  "docs-nav-backend-apis": "バックエンドAPI",
  "docs-nav-block-explorers": "ブロックエクスプローラー",
  "docs-nav-blocks": "ブロック",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "スマートコントラクトのコンパイル",
  "docs-nav-composability": "コンポーザビリティ",
  "docs-nav-consensus-mechanisms": "コンセンサスメカニズム",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "データと分析",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "スマートコントラクトの導入",
  "docs-nav-development-frameworks": "開発フレームワーク",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "開発フレームワーク",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "イーサリアムクライアントAPI",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "イーサリアムスタック",
  "docs-nav-evm": "イーサリアム仮想マシン(EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "基礎的なトピック",
  "docs-nav-gas": "Gas",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "統合開発環境 (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "dapps入門",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "イーサリアム入門",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "スタック入門",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "JavaScript APIs",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "マイニング",
  "docs-nav-networks": "ネットワーク",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "ノードとクライアント",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "サービスとしてのノード",
  "docs-nav-oracles": "オラクル",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "プログラミング言語",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "プルーフオブステーク",
  "docs-nav-proof-of-work": "プルーフ・オブ・ワーク",
  "docs-nav-python": "Python",
  "docs-nav-readme": "README",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "拡大縮小",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "セキュリティ",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "スマートコントラクト機構",
  "docs-nav-smart-contract-languages": "スマートコントラクト言語",
  "docs-nav-smart-contracts": "スマートコントラクト",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "スマートコントラクトライブラリ",
  "docs-nav-standards": "スタンダード",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "ストレージ",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "スマートコントラクトのテスト",
  "docs-nav-token-standards": "トークンの基準",
  "docs-nav-transactions": "トランザクション",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 vs Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "あなたがトピックの専門家であり、貢献したい場合は、知識を使ってこのページを編集してください。",
  "page-calltocontribute-desc-2": "貢献者として名前が記載され、イーサリウムコミュニティを助けることになります!",
  "page-calltocontribute-desc-3": "この柔軟性を使用する",
  "page-calltocontribute-desc-4": "質問がありますか？こちらの #content チャンネルでお問い合わせください",
  "page-calltocontribute-link": "ドキュメントテンプレート",
  "page-calltocontribute-link-2": "Discord サーバー",
  "page-calltocontribute-span": "ページを編集",
  "page-calltocontribute-title": "このページについてご協力ください。",
  "page-developer-meta-title": "イーサリアム開発者リソース",
  "page-developers-about": "これらの開発リソースについて",
  "page-developers-about-desc": "ethereum.orgは、基本的な概念と開発スタックに関するドキュメントでイーサリアムでの構築を支援しています。 さらに、使い始めて実行するためのチュートリアルがあります。",
  "page-developers-about-desc-2": "Mozilla Developer Networkを見て、私たちは優れた開発者向けコンテンツとリソースを格納する場所が必要だと考えました。 Mozillaの利用者と同じように、ここにあるすべてのものはオープンソースであり、拡張し改善する準備ができています。",
  "page-developers-account-desc": "ネットワーク上のコントラクトまたはユーザー",
  "page-developers-accounts-link": "アカウント",
  "page-developers-advanced": "詳細",
  "page-developers-api-desc": "ライブラリを使用してスマートコントラクトとやり取りする",
  "page-developers-api-link": "バックエンドAPI",
  "page-developers-aria-label": "開発者向けメニュー",
  "page-developers-block-desc": "ブロックチェーンに追加されたトランザクションのバッチ数",
  "page-developers-block-explorers-desc": "イーサリアムデータへのポータル",
  "page-developers-block-explorers-link": "ブロックエクスプローラー",
  "page-developers-blocks-link": "ブロック",
  "page-developers-browse-tutorials": "チュートリアルを参照",
  "page-developers-choose-stack": "スタックを選択",
  "page-developers-contribute": "コントリビューション",
  "page-developers-dev-env-desc": "dapp開発に適したIDE",
  "page-developers-dev-env-link": "開発環境",
  "page-developers-discord": "Discord に参加する",
  "page-developers-docs-introductions": "はじめに",
  "page-developers-evm-desc": "トランザクションを処理するコンピューター",
  "page-developers-evm-link": "イーサリアム仮想マシン(EVM)",
  "page-developers-explore-documentation": "ドキュメントを探す",
  "page-developers-feedback": "フィードバックがあれば、GitHubの問題経由またはDiscordサーバーでご連絡ください。",
  "page-developers-frameworks-desc": "開発スピードを上げるためのツール",
  "page-developers-frameworks-link": "開発フレームワーク",
  "page-developers-fundamentals": "基本",
  "page-developers-gas-desc": "イーサはトランザクションに電力を供給する必要がありました",
  "page-developers-gas-link": "Gas",
  "page-developers-get-started": "どの方法で開始しますか？",
  "page-developers-improve-ethereum": "Ethereum.orgをより良くするためにご協力ください",
  "page-developers-improve-ethereum-desc": "ethereum.orgのように、これらのドキュメントはコミュニティの努力です。間違い、改善の余地、イーサリアム開発者を助ける新しい機会があればPRを作成してください。",
  "page-developers-into-eth-desc": "ブロックチェーンとイーサリアムの入門",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "分散型アプリケーションの入門",
  "page-developers-intro-dapps-link": "Dapps入門",
  "page-developers-intro-eth-link": "イーサリアム入門",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "スタック入門",
  "page-developers-intro-stack-desc": "イーサリアムスタックの紹介",
  "page-developers-js-libraries-desc": "javascriptを使用してスマートコントラクトとやり取りする",
  "page-developers-js-libraries-link": "Javascriptライブラリ",
  "page-developers-language-desc": "使い慣れた言語でイーサリアムを使用する",
  "page-developers-languages": "プログラミング言語",
  "page-developers-learn": "イーサリアムの開発を学ぶ",
  "page-developers-learn-desc": "私たちのドキュメントでコアコンセプトとイーサリアムスタックについて読む",
  "page-developers-learn-tutorials": "チュートリアルで学ぶ",
  "page-developers-learn-tutorials-cta": "チュートリアルを見る",
  "page-developers-learn-tutorials-desc": "すでに実行しているビルダーから段階的にイーサリアムの開発を学びましょう。",
  "page-developers-meta-desc": "イーサリアム上でアプリケーションを開発するためのガイドやリソース、開発ツールなどを紹介します。",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "新しいブロックが作成され、コンセンサスに達する方法",
  "page-developers-mining-link": "マイニング",
  "page-developers-networks-desc": "メインネットとテストネットワークの概要",
  "page-developers-networks-link": "ネットワーク",
  "page-developers-node-clients-desc": "ネットワークでブロックとトランザクションがどのように検証されるか",
  "page-developers-node-clients-link": " ノードとクライアント",
  "page-developers-oracle-desc": "スマートコントラクトにオフチェーンデータを取得する",
  "page-developers-oracles-link": "オラクル",
  "page-developers-play-code": "コードを使ってプレイ",
  "page-developers-read-docs": "ドキュメントを読む",
  "page-developers-scaling-desc": "より高速なトランザクションのためのソリューション",
  "page-developers-scaling-link": "スケーリング",
  "page-developers-smart-contract-security-desc": "開発中に考慮すべきセキュリティ",
  "page-developers-smart-contract-security-link": "セキュリティ",
  "page-developers-set-up": "ローカル環境の設定",
  "page-developers-setup-desc": "開発環境を構成してスタックを構築する準備をしましょう。",
  "page-developers-smart-contracts-desc": "Dappsの背後にあるロジック-自己実行契約",
  "page-developers-smart-contracts-link": "スマートコントラクト",
  "page-developers-stack": "スタック",
  "page-developers-start": "試験を開始",
  "page-developers-start-desc": "最初に試験してから質問しますか?",
  "page-developers-storage-desc": "Dappストレージを処理する方法",
  "page-developers-storage-link": "ストレージ",
  "page-developers-subtitle": "ビルダーによるビルダーのためのイーサリアムのビルダーマニュアル。",
  "page-developers-title-1": "イーサリアム",
  "page-developers-title-2": "開発者",
  "page-developers-title-3": "リソース",
  "page-developers-token-standards-desc": "受け入れられたトークン標準の概要",
  "page-developers-token-standards-link": "トークンの基準",
  "page-developers-transactions-desc": "イーサリアムの状態の変化",
  "page-developers-transactions-link": "トランザクション",
  "page-developers-web3-desc": "Web3 の開発の世界が異なる方法",
  "page-developers-web3-link": "Web2 vs Web3",
  "page-learning-tools-bootcamps": "デベロッパーブートキャンプ",
  "page-learning-tools-bootcamps-desc": "有料のオンラインコースを利用して、効率的に学習しましょう。",
  "page-learning-tools-browse-docs": "ドキュメントを参照",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "ChainShot ロゴ",
  "page-learning-tools-coding": "コーディングで学ぶ",
  "page-learning-tools-coding-subtitle": "よりインタラクティブな学習体験を希望される場合、これらのツールはイーサリアムの実験に役立ちます。",
  "page-learning-tools-consensys-academy-description": "オンラインのイーサリアム開発者ブートキャンプ",
  "page-learning-tools-consensys-academy-logo-alt": "ConsenSys Academy ロゴ",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Solidityを学んであなた自身のゾンビゲームを開発しましょう。",
  "page-learning-tools-cryptozombies-logo-alt": "CryptoZombies ロゴ",
  "page-learning-tools-documentation": "ドキュメントで学ぶ",
  "page-learning-tools-documentation-desc": "詳細については、こちらのドキュメントをご参照ください。",
  "page-learning-tools-eth-dot-build-description": "ドラッグ＆ドロップ式のプログラミングやオープンソースのビルディングブロック等の、web3用の教育用サンドボックス",
  "page-learning-tools-eth-dot-build-logo-alt": "Eth.build ロゴ",
  "page-learning-tools-ethernauts-description": "スマートコントラクトをハッキングして全てのレベルをクリアしましょう。",
  "page-learning-tools-ethernauts-logo-alt": "Ethernauts ロゴ",
  "page-learning-tools-game-tutorials": "インタラクティブなゲームチュートリアル",
  "page-learning-tools-game-tutorials-desc": "遊びながら学びましょう。これらのチュートリアルでは、ゲームをプレイしながら基本的な知識を身につけることができます。",
  "page-learning-tools-meta-desc": "Webベースのコーディングツールとインタラクティブな学習体験により、イーサリアムの開発を実験することができます。",
  "page-learning-tools-meta-title": "開発者向けの学習ツール",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "イーサリアム用のスマートコントラクトを開発、デプロイ、管理します。Learnethプラグインのチュートリアルに従ってください。",
  "page-learning-tools-remix-description-2": "Remixは単なるサンドボックスではありません。多くの開発者がRemixを使用してスマートコントラクトを書き、コンパイルし、デプロイしています。",
  "page-learning-tools-remix-logo-alt": "Remix ロゴ",
  "page-learning-tools-sandbox": "コードサンドボックス",
  "page-learning-tools-sandbox-desc": "これらのサンドボックスは、スマートコントラクトを書いたり、イーサリアムを理解したりするための実験の場となります。",
  "page-learning-tools-studio-description": "WebベースのIDEでは、チュートリアルに従ってスマートコントラクトを作成してテストし、それらのフロントエンドを構築することができます。",
  "page-learning-tools-vyperfun-description": "Vyperを学んでご自身のポケモンゲームを作りましょう。",
  "page-learning-tools-vyperfun-logo-alt": "Vyper.fun ロゴ",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "イーサリアム仮想マシンをターゲットとしたスマートコントラクトのためのPythonベースの開発とテストフレームワーク。",
  "page-local-environment-brownie-logo-alt": "Brownie ロゴ",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "分散型アプリケーションの構築と展開のためのオールインワン開発プラットフォーム。",
  "page-local-environment-embark-logo-alt": "Embarkロゴ",
  "page-local-environment-epirus-desc": "Java仮想マシン上でブロックチェーンアプリケーションを開発、デプロイ、監視するためのプラットフォーム",
  "page-local-environment-epirus-logo-alt": "Epirus ロゴ",
  "page-local-environment-eth-app-desc": "1つのコマンドでEthereumを搭載したアプリを作成します。UIフレームワークとDeFiテンプレートから選択できます。",
  "page-local-environment-eth-app-logo-alt": "Ethアプリのロゴを作成",
  "page-local-environment-framework-feature-1": "ローカルのブロックチェーンインスタンスを起動する機能。",
  "page-local-environment-framework-feature-2": "スマートコントラクトをコンパイルしてテストするユーティリティ。",
  "page-local-environment-framework-feature-3": "クライアント開発アドオンは、同じプロジェクト/リポジトリ内でユーザーが利用するアプリケーションを構築します。",
  "page-local-environment-framework-feature-4": "Ethereumネットワークに接続し、ローカルで実行されているインスタンス、またはEthereumのパブリックネットワークのいずれかにかかわらず、コントラクトを展開するための設定。",
  "page-local-environment-framework-feature-5": "分散型アプリ配信 - IPFSのようなストレージオプションとの統合。",
  "page-local-environment-framework-features": "これらのフレームワークには、すぐに使える機能がたくさんあります。",
  "page-local-environment-frameworks-desc": " 使用を開始したばかりの場合は特に、フレームワークを選択することをお勧めします。 本格的なdappを構築するには、さまざまな技術が必要です。フレームワークには、必要な機能の多くが含まれているか、ユーザーが望むツールを選択するための簡単なプラグインシステムが提供されています。",
  "page-local-environment-frameworks-title": "フレームワークと事前作成されたスタック",
  "page-local-environment-hardhat-desc": "Hardhatは、プロのためのイーサリウム開発環境です。",
  "page-local-environment-hardhat-logo-alt": "Hardhatロゴ",
  "page-local-environment-openZeppelin-desc": "CLIとのスマートコントラクトをコンパイル、アップグレード、デプロイ、および相互作用することで、開発時間を短縮できます。",
  "page-local-environment-openZeppelin-logo-alt": "OpenZeppelinロゴ",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App: スマートコントラクトを搭載した分散型アプリケーションの構築を始めるためにすべての必要なもの",
  "page-local-environment-scaffold-eth-logo-alt": "scaffold-eth ロゴ",
  "page-local-environment-setup-meta-desc": "イーサリウム開発用のソフトウェアスタックを選択する方法についてのガイド。",
  "page-local-environment-setup-meta-title": "イーサリアムのローカル開発のセットアップ",
  "page-local-environment-setup-subtitle": "構築を開始する準備ができたら、スタックを選択する時間です。",
  "page-local-environment-setup-subtitle-2": " ここでは、Ethereumアプリケーションの構築に役立つツールとフレームワークを紹介します。",
  "page-local-environment-setup-title": "ローカル開発環境をセットアップする",
  "page-local-environment-solidity-template-desc": "Solidityスマートコントラクト用のビルド済みセットアップ用のGitHubテンプレート。 Hardhatローカルネットワーク、テストのためのワッフル、財布の実装のためのEtherなどが含まれています。",
  "page-local-environment-solidity-template-logo-alt": "Solidityテンプレートのロゴ",
  "page-local-environment-truffle-desc": "Truffle Suiteは、開発者がアイデアからdappにできるだけ快適にアクセスできるようにします。",
  "page-local-environment-truffle-logo-alt": "Truffle ロゴ",
  "page-local-environment-waffle-desc": "スマートコントラクトのための最も高度なテストリブ。単独またはScaffoldethまたはHardhatで使用してください。",
  "page-local-environment-waffle-logo-alt": "Waffleロゴ",
  "comp-tutorial-metadata-minute-read": "分の読書",
  "page-tutorial-listing-policy-intro": "チュートリアルを提出する前に、リストポリシーをお読みください。",
  "comp-tutorial-metadata-tip-author": "著者にチップを渡す",
  "page-tutorial-listing-policy": "記事一覧ポリシー",
  "page-tutorial-new-github": "GitHubは初めてですか？",
  "page-tutorial-new-github-desc": "問題を提起する - 必要な情報を記入し、チュートリアルを貼り付けます。",
  "page-tutorial-pull-request": "プルリクエストを作る",
  "page-tutorial-pull-request-btn": "プルリクエストを作る",
  "page-tutorial-pull-request-desc-1": "に従ってください",
  "page-tutorial-pull-request-desc-2": "tutorials/your-tutorial-name/index.md",
  "page-tutorial-pull-request-desc-3": "命名構造。",
  "page-tutorial-raise-issue-btn": "問題を提起する",
  "page-tutorial-read-time": "分",
  "page-tutorial-submit-btn": "チュートリアルを提出する",
  "page-tutorial-submit-tutorial": "チュートリアルを提出するために、あなたはGitHubを使用する必要があります。私たちはあなたが問題またはプルリクエストを作成ことを歓迎します。",
  "page-tutorial-subtitle": "キュレーションされたコミュニティチュートリアルリストへようこそ。",
  "page-tutorial-tags-error": "これらのタグが全て付いているチュートリアルはありません",
  "page-tutorial-title": "イーサリウムの開発チュートリアル",
  "page-tutorials-meta-description": "検証されたイーサリウムコミュニティチュートリアルをトピック別に参照し、フィルタリングします。",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "イーサリウムの開発チュートリアル",
  "page-eth-buy-some": "イーサリウムを購入したいですか？",
  "page-eth-buy-some-desc": "イーサリウムとETHを混同するのはよくあることです。 イーサリウムはブロックチェーンであり、ETHはイーサリウムの主要資産です。 おそらくあなたが購入したいのはETHです。",
  "page-eth-cat-img-alt": "猫の万華鏡を備えたETHグリフのグラフィック",
  "page-eth-collectible-tokens": "収集可能なトークン",
  "page-eth-collectible-tokens-desc": "収集可能なゲームアイテム、デジタルアートの一部、またはその他のユニークな資産を表すトークン。通常は、ノンファンジブルトークン(NFT) として知られています。",
  "page-eth-cryptography": "暗号化によって保護されています",
  "page-eth-cryptography-desc": "インターネットマネーは新しいものかもしれませんが、証明された暗号化によって保護されています。これにより、ウォレット、ETH、およびトランザクションが保護されます。 ",
  "page-eth-currency-for-apps": "それはイーサリウムアプリの通貨です。",
  "page-eth-currency-for-future": "デジタル未来のための通貨",
  "page-eth-description": "ETHは暗号通貨です。ビットコインと同様に、インターネット上で使用できるデジタルマネーは不足しています。 暗号を初めて使う場合は、ETHと従来のお金の違いをご覧ください。",
  "page-eth-earn-interest-link": "利息を得る",
  "page-eth-ethhub-caption": "頻繁に更新",
  "page-eth-ethhub-overview": "EthHubには素晴らしい概要があります",
  "page-eth-flexible-amounts": "柔軟な金額で利用可能",
  "page-eth-flexible-amounts-desc": "ETHは小数点以下18桁まで割れるので、ETH全体を1つ購入する必要はありません。 必要に応じて、最低0000000000000 001 ETHを一度に購入することができます 。",
  "page-eth-fuels": "ETH燃料とイーサリアムの確保",
  "page-eth-fuels-desc": "ETHはイーサリウムの生命線です。 ETHを送信するか、イーサリウムアプリケーションを使用すると、イーサリウムネットワークを使用するためにETHで少額の料金を支払うことになります。 この手数料は、あなたが何をしようとしているかをマイナーが処理して検証するためのインセンティブです。",
  "page-eth-fuels-desc-2": "マイナーはイーサリウムの記録保持者のようなものです 。 彼らは誰も不正行為をしていないことをチェックして証明します。 この作業を行うマイナーは、少量の新しく発行されたETHでも報酬を受けます。",
  "page-eth-fuels-desc-3": "作業を行うマイナーはイーサリウムを安全かつ集中管理の対象とならないようにします。言い換えれば、",
  "page-eth-fuels-more-staking": "ステークの詳細",
  "page-eth-fuels-staking": "ETHはステーキングによってさらに重要になります。ETHをステーキングすると、イーサリウムの安全を確保し、報酬を得ることができます。 このシステムでは、ETHを失う脅威は、攻撃を阻止します。",
  "page-eth-get-eth-btn": "ETHを入手",
  "page-eth-gov-tokens": "ガバナンストークン",
  "page-eth-gov-tokens-desc": "分散型組織の投票力を表すトークン。",
  "page-eth-has-value": "ETHに価値があるのはなぜですか?",
  "page-eth-has-value-desc": "ETHはさまざまな方法で異なる人々に価値があります。",
  "page-eth-has-value-desc-2": "Ethereumのユーザーにとって、ETHは取引手数料を支払うことができるため、価値があります。",
  "page-eth-has-value-desc-3": "新しいETHの作成が時間の経過とともに遅くなるため、それを価値のデジタルストアとみなす人もいます。",
  "page-eth-has-value-desc-4": "最近では、ETHはイーサリウム上の金融アプリのユーザーにとって価値があります。 これは、ETHを暗号ローンの担保として、または支払いシステムとして使用できるためです。",
  "page-eth-has-value-desc-5": "もちろん、多くの人がそれをビットコインや他の暗号通貨と同様の投資と見なしています。",
  "page-eth-how-to-buy": "イーサの購入方法",
  "page-eth-how-to-buy-caption": "頻繁に更新",
  "page-eth-is-money": "ETHはデジタルでグローバルな資金です",
  "page-eth-last-updated": "2019 年 1 月",
  "page-eth-mining-link": "ステーキングの詳細",
  "page-eth-monetary-policy": "イーサリアムの金融政策",
  "page-eth-more-on-ethereum-link": "イーサリアムの詳細",
  "page-eth-no-centralized": "集中管理なし ",
  "page-eth-no-centralized-desc": "ETHは分散型でグローバルです。ETHを印刷する量を増やしたり、利用規約を変更したりできる会社や銀行はありません。",
  "page-eth-non-fungible-tokens-link": "ノンファンジブルトークン",
  "page-eth-not-only-crypto": "ETHはイーサリウムの唯一の暗号ではありません",
  "page-eth-not-only-crypto-desc": "誰でも新しい種類の資産を作成し、イーサリウムで取引することができます。これらは「トークン」として知られています。 人々は従来の通貨、不動産、アート、さらには自分自身をトークン化してきました!",
  "page-eth-not-only-crypto-desc-2": "Ethereumには何千ものトークンがあります。他のトークンよりも有用で価値があります。 開発者は常に新しい可能性を開き、新しい市場を開く新しいトークンを構築しています。",
  "page-eth-not-only-crypto-desc-3": "トークンについてもっと知りたい場合は、EthHubの友人がいくつかの素晴らしい概要を書いています。",
  "page-eth-open": "誰にでも公開",
  "page-eth-open-desc": "ETHを受け入れるにはインターネット接続とウォレットのみが必要です。支払いを受け入れるには銀行口座へのアクセスは必要ありません。 ",
  "page-eth-p2p-payments": "ピアツーピアでの支払い",
  "page-eth-p2p-payments-desc": "銀行などの仲介サービスなしでETHを送ることができます。 現金を直接渡すようなものですが、誰でも、どこでも、いつでも安全に行うことができます。",
  "page-eth-period": "。",
  "page-eth-popular-tokens": "人気のトークンの種類",
  "page-eth-powers-ethereum": "ETHがイーサリウムをサポート",
  "page-eth-shit-coins": "Sh*t Coin",
  "page-eth-shit-coins-desc": "新しいトークンを作成するのは簡単なので、悪意を持つ人でも誰でもそれを行うことができます。必ず使用する前に調査してください。",
  "page-eth-stablecoins": "ステーブルコイン",
  "page-eth-stablecoins-desc": "ドルのような伝統的な通貨の価値を反映するトークン これは、多くの暗号通貨の変動性に関する問題を解決します。",
  "page-eth-stablecoins-link": "ステーブルコインを入手する",
  "page-eth-stream-link": "ストリーム ETH",
  "page-eth-tokens-link": "イーサリウムトークン",
  "page-eth-trade-link-2": "トークンの入れ替え",
  "page-eth-underpins": "ETHはイーサリウムの金融システムを支えています",
  "page-eth-underpins-desc": "支払いに満足していないイーサリウムコミュニティが、ピアツーピアで誰もがアクセスできる金融システム全体を構築しています。",
  "page-eth-underpins-desc-2": "ETHを担保として使用して、イーサリウム上で全く異なる暗号通貨トークンを生成することができます。 加えて、ETHやその他のETHが支援するトークンを借りたり、貸したり、利益を得たりすることができます。",
  "page-eth-uses": "ETHの使用量は日々増加します",
  "page-eth-uses-desc": "イーサリウムはプログラム可能であるため、開発者は無数の方法でETHを形作ることができます。",
  "page-eth-uses-desc-2": "2015年には、あるイーサリウムアカウントから別のアカウントにETHを送るだけでした。 今日できることをいくつか紹介します。",
  "page-eth-uses-desc-3": "リアルタイムで誰かに資金を支払うか受け取ります。",
  "page-eth-uses-desc-4": "ビットコインを含む他のトークンとETHを取引できます。",
  "page-eth-uses-desc-5": "ETHやその他のイーサリアムベースのトークンで。",
  "page-eth-uses-desc-6": "安定した変動性の低い価値で暗号通貨の世界にアクセスできます。",
  "page-eth-value": "イーサが貴重な理由",
  "page-eth-video-alt": "ETHグリフビデオ",
  "page-eth-whats-eth": "イーサ(ETH) とは？",
  "page-eth-whats-eth-hero-alt": "イーサ(ETH) グリフを見て驚嘆する人々のグループの図",
  "page-eth-whats-eth-meta-desc": "イーサリウムでETHとその場所を理解するために知っておく必要があること。",
  "page-eth-whats-eth-meta-title": "イーサ(ETH) とは？",
  "page-eth-whats-ethereum": "イーサリアムとは？",
  "page-eth-whats-ethereum-desc": "ETHの背後にある技術であるイーサリウムについてもっと知りたい場合は、概要をご覧ください。",
  "page-eth-whats-unique": "ETHのユニークな点は？",
  "page-eth-whats-unique-desc": "イーサリウムには多くの暗号通貨と他のトークンがありますが、ETHだけができることがいくつかあります。",
  "page-eth-where-to-buy": "ETHの取得先",
  "page-eth-where-to-buy-desc": "取引所やウォレットからETHを取得することができますが、異なる国には異なるポリシーがあります。 ETHを購入できるようにするサービスを確認してください。",
  "page-eth-yours": "本当にあなたのものです",
  "page-eth-yours-desc": "ETHはあなた自身の銀行になることができます。あなたは所有権の証明として自分のウォレットで自分の資金を管理することができます 。第三者は必要ありません。",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Eth2デポジットコントラクトアドレス",
  "page-eth2-deposit-contract-address-caption": "アドレスを読みやすくするためにスペースを追加しました",
  "page-eth2-deposit-contract-address-check-btn": "デポジットコントラクトアドレスを確認",
  "page-eth2-deposit-contract-checkbox1": "Eth2 validatorをセットアップするために、すでにランチパッドを使用しました。",
  "page-eth2-deposit-contract-checkbox2": "ランチパッドを使ってステークする必要があることを理解しています。このアドレスへの簡単な送金はできません。",
  "page-eth2-deposit-contract-checkbox3": "私は他のソースとデポジットコントラクトのアドレスをチェックします.",
  "page-eth2-deposit-contract-confirm-address": "アドレスを明らかにすることを確認",
  "page-eth2-deposit-contract-copied": "コピーされたアドレス",
  "page-eth2-deposit-contract-copy": "アドレスをコピーする",
  "page-eth2-deposit-contract-etherscan": "Etherscan でコントラクトを表示",
  "page-eth2-deposit-contract-h2": "これはあなたがステーキングする場所ではありません",
  "page-eth2-deposit-contract-launchpad": "ランチパッドを使用してステークする",
  "page-eth2-deposit-contract-launchpad-2": "ランチパッドを使用",
  "page-eth2-deposit-contract-meta-desc": "Eth2ステークのデポジットコントラクトアドレスを確認してください。",
  "page-eth2-deposit-contract-meta-title": "デポジットコントラクトアドレスの確認",
  "page-eth2-deposit-contract-read-aloud": "アドレスを読み上げてください",
  "page-eth2-deposit-contract-reveal-address-btn": "アドレスを表示する",
  "page-eth2-deposit-contract-staking": " ETHをEth2にステークするには、専用のランチパッド製品を使用し、指示に従う必要があります。 このページのアドレスにETHを送信することは安全ではなく、トランザクションが失敗します。",
  "page-eth2-deposit-contract-staking-check": "これらのソースを確認",
  "page-eth2-deposit-contract-staking-check-desc": "そこに多くの偽のアドレスや詐欺があることが予想されます。 安全のために、このページのアドレスに対して使用しているEth2ステーキングアドレスを確認してください。他の信頼できる情報源も確認することをお勧めします。",
  "page-eth2-deposit-contract-staking-more-link": "ステークの詳細",
  "page-eth2-deposit-contract-stop-reading": "読み取りを停止",
  "page-eth2-deposit-contract-subtitle": " これがEth2ステーキング契約のアドレスです。ステーキング時に正しいアドレスに資金を送っていることをこのページで確認してください。",
  "page-eth2-deposit-contract-warning": "各文字を注意深くチェックしてください。",
  "page-eth2-deposit-contract-warning-2": "このアドレスに資金を送金することはできず、あなたをステーカーにすることはできません。ランチパッドの指示に従ってください。",
  "page-eth2-bug-bounty-annotated-specs": "注釈付き仕様",
  "page-eth2-bug-bounty-annotations": "以下の注釈を確認すると便利かもしれません:",
  "page-eth2-bug-bounty-client-bugs": "Eth2クライアントのバグ",
  "page-eth2-bug-bounty-client-bugs-desc": "クライアントは、アップグレードがデプロイされると、ビーコンチェーンを実行します。クライアントは仕様で定められたロジックに従う必要があり、潜在的な攻撃に対してセキュアである必要があります。私たちが見つけたいバグは、プロトコルの実装に関連しています。",
  "page-eth2-bug-bounty-client-bugs-desc-2": "現在、この報奨金の対象となるのは、Prysm、Lighthouse、Tekuのバグのみです。監査を完了し、製品の準備が整い次第、さらに多くのクライアントが追加されます。",
  "page-eth2-bug-bounty-clients": "報奨金があるクライアント",
  "page-eth2-bug-bounty-clients-type-1": "仕様不適合の問題",
  "page-eth2-bug-bounty-clients-type-2": "予期しないクラッシュまたはサービス拒否(DOS) の脆弱性。",
  "page-eth2-bug-bounty-clients-type-3": "ネットワークの残りの部分から取り返しのつかないコンセンサスの分裂を引き起こす問題。",
  "page-eth2-bug-bounty-docking": "ドッキング",
  "page-eth2-bug-bounty-email-us": "メールでのお問い合わせ：",
  "page-eth2-bug-bounty-help-links": "役立つリンク",
  "page-eth2-bug-bounty-hunting": "バグハンティングルール",
  "page-eth2-bug-bounty-hunting-desc": "バグ報奨金プログラムは、私たちのアクティブなイーサリウムコミュニティのための実験的で裁量的な報酬プログラムで、プラットフォームの改善を支援している人々を奨励し、報酬を与えます。 競争ではありません。 いつでもプログラムをキャンセルすることができ、賞はイーサリアム財団バグ報奨金パネルの単独の裁量で決められます。 加えて、制裁対象者または制裁対象国にいる個人に対して賞を発行することはできません(e. 北朝鮮、イランなど） あなたはすべての税金を負担します。すべての賞は適用される法律に従います。 最後に、あなたのテストは、いかなる法律に違反することも、自分のものではないデータを侵害することもできません。",
  "page-eth2-bug-bounty-hunting-leaderboard": "バグ狩りのリーダーボード",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Eth2のバグを見つけてこのリーダーボードに名前を載せる",
  "page-eth2-bug-bounty-hunting-li-1": "すでに他のユーザによって提出されている問題、またはすでに仕様書やクライアント管理者に知られている問題は報奨金の報酬の対象ではありません。",
  "page-eth2-bug-bounty-hunting-li-2": "脆弱性を公開すると、報奨金の対象になりません。",
  "page-eth2-bug-bounty-hunting-li-3": "イーサリアム財団の研究者およびEth2クライアントチームの従業員は報酬を受け取ることはできません。",
  "page-eth2-bug-bounty-hunting-li-4": "イーサリウム報奨金プログラムは、報酬を決定する際にいくつかの変数を考慮します。 資格、スコア、賞に関連するすべての条件の決定は、イーサリアム財団バグ報奨金パネルの唯一かつ最終的な裁量に委ねられています。",
  "page-eth2-bug-bounty-leaderboard": "リーダーボードをすべて表示する",
  "page-eth2-bug-bounty-leaderboard-points": "ポイント",
  "page-eth2-bug-bounty-ledger-desc": "ビーコンチェーンの仕様では、ビーコンチェーンのアップグレードによるイーサリウムの設計の合理性と提案されている変更点が詳細に説明されています。",
  "page-eth2-bug-bounty-ledger-title": "ビーコンチェーン仕様のバグ",
  "page-eth2-bug-bounty-meta-description": "Eth2バグハンティングプログラムの概要: 参加方法と報酬情報。",
  "page-eth2-bug-bounty-meta-title": "Eth2バグハンティング報奨金プログラム",
  "page-eth2-bug-bounty-not-included": "含まれないもの",
  "page-eth2-bug-bounty-not-included-desc": "シャードチェーンとドッキングのアップグレードはまだ開発中のため、この賞金プログラムにはまだ含まれていません。",
  "page-eth2-bug-bounty-owasp": "OWASP メソッドを表示",
  "page-eth2-bug-bounty-points": "また、EFは以下に基づいてポイントを付与します:",
  "page-eth2-bug-bounty-points-error": "データの読み込み中にエラーが発生しました。更新してください。",
  "page-eth2-bug-bounty-points-exchange": "ポイント交換",
  "page-eth2-bug-bounty-points-loading": "データ読込中…",
  "page-eth2-bug-bounty-points-payout-desc": " Ethereum Foundation は、ETHまたはDAIで米ドル分の価値を支払います。",
  "page-eth2-bug-bounty-points-point": "1 ポイント",
  "page-eth2-bug-bounty-points-rights-desc": "イーサリアム財団は、これを予告なく変更する権利を有します。",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "記述の質",
  "page-eth2-bug-bounty-quality-desc": ": 明瞭で良く書かれた投稿には、より高い報酬が支払われます。",
  "page-eth2-bug-bounty-quality-fix": "修正の質",
  "page-eth2-bug-bounty-quality-fix-desc": "修正を含む場合: 問題の修正方法が明確に記載された投稿には、より高い報酬が支払われます。",
  "page-eth2-bug-bounty-quality-repro": "再現性の質",
  "page-eth2-bug-bounty-quality-repro-desc": ": テストコード、スクリプト、詳細な手順を含めてください。再現と脆弱性の検証が容易であるほど、報酬は高くなります。",
  "page-eth2-bug-bounty-questions": "質問がありますか？",
  "page-eth2-bug-bounty-rules": "ルールを読む",
  "page-eth2-bug-bounty-shard-chains": "シャードチェーン",
  "page-eth2-bug-bounty-slogan": "Eth2 バグ報奨金",
  "page-eth2-bug-bounty-specs": "全仕様を読む",
  "page-eth2-bug-bounty-specs-docs": "仕様ドキュメント",
  "page-eth2-bug-bounty-submit": "バグを投稿する",
  "page-eth2-bug-bounty-submit-desc": "バグを見つけるたびにポイントがもらえます。獲得できるポイントは、バグの重大度によって異なります。イーサリアム財団(EF) は OWASP メソッドを使用して重大度を決定します。",
  "page-eth2-bug-bounty-subtitle": "Eth2プロトコルとクライアントのバグを見つけることで、リーダーボードへ入賞し、最大で$50,000ドルを稼ごう。",
  "page-eth2-bug-bounty-title": "提案を受け付けています",
  "page-eth2-bug-bounty-title-1": "ビーコンチェーン",
  "page-eth2-bug-bounty-title-2": "フォークの選択",
  "page-eth2-bug-bounty-title-3": "Solidityデポジットコントラクト",
  "page-eth2-bug-bounty-title-4": "ピアツーピアネットワーキング",
  "page-eth2-bug-bounty-type-1": "safety/finality-breaking バグ",
  "page-eth2-bug-bounty-type-2": "サービス拒否 (DOS) ベクトル",
  "page-eth2-bug-bounty-type-3": "正直なバリデータがスラッシングされる状況などの、仮定における矛盾。",
  "page-eth2-bug-bounty-type-4": "計算またはパラメータの不一致。",
  "page-eth2-bug-bounty-types": "バグの種類",
  "page-eth2-bug-bounty-validity": "有効なバグ",
  "page-eth2-bug-bounty-validity-desc": "このバグ報奨プログラムは、コアとなる Eth2ビーコンチェーン仕様 と Prysm, Lighthouse, Teku クライアント実装のバグを発見することに重点を置いています。",
  "page-eth2-bug-bountycard-critical": "重大",
  "page-eth2-bug-bountycard-critical-risk": "重大なリスクバグを送信",
  "page-eth2-bug-bountycard-h2": "中",
  "page-eth2-bug-bountycard-high": "高",
  "page-eth2-bug-bountycard-high-risk": "リスクの高いバグを送信",
  "page-eth2-bug-bountycard-label-1": "最大1,000ポイント",
  "page-eth2-bug-bountycard-label-2": "最大 2,000 DAI",
  "page-eth2-bug-bountycard-label-3": "最大5,000ポイント",
  "page-eth2-bug-bountycard-label-4": "最大 10,000 DAI",
  "page-eth2-bug-bountycard-label-5": "最大10,000ポイント",
  "page-eth2-bug-bountycard-label-6": "最大 20,000 DAI",
  "page-eth2-bug-bountycard-label-7": "最大25,000ポイント",
  "page-eth2-bug-bountycard-label-8": "最大 50,000 DAI",
  "page-eth2-bug-bountycard-li-1": "低い影響、中程度の可能性",
  "page-eth2-bug-bountycard-li-2": "中程度の影響、低い可能性",
  "page-eth2-bug-bountycard-li-3": "高い影響、低い可能性",
  "page-eth2-bug-bountycard-li-4": "中程度の影響、中程度の可能性",
  "page-eth2-bug-bountycard-li-5": "低い影響、高い可能性",
  "page-eth2-bug-bountycard-li-6": "高い影響、中程度の可能性",
  "page-eth2-bug-bountycard-li-7": "中程度の影響、高い可能性",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "低",
  "page-eth2-bug-bountycard-low-risk": "低リスクのバグを送信",
  "page-eth2-bug-bountycard-medium-risk": "中程度のリスクのバグを送信",
  "page-eth2-bug-bountycard-subheader": "重大度",
  "page-eth2-bug-bountycard-subheader-2": "例",
  "page-eth2-bug-bountycard-text": "攻撃者は時々、バリデータによる100件の認証ごとに1つドロップされる状態にノードを置くことができます。",
  "page-eth2-bug-bountycard-text-1": "攻撃者は4バイト先頭のゼロを持つピアIDを持つノードに対してエクリプス攻撃を成功させることができます",
  "page-eth2-bug-bountycard-text-2": "2つのクライアント間に合意されたバグがありますが、攻撃者がイベントを発生させるのは難しいか、非現実的です。",
  "page-eth2-bug-bountycard-text-3": "2つのクライアント間に合意されたバグがあり、攻撃者がイベントを発生させることは問題になりません。",
  "page-eth2-get-involved-btn-1": "クライアントを参照する",
  "page-eth2-get-involved-btn-2": "ステーキングの詳細",
  "page-eth2-get-involved-btn-3": "バグを見つける",
  "page-eth2-get-involved-bug": "バグは以下のようなものです:",
  "page-eth2-get-involved-bug-hunting": "バグハンティングに行こう",
  "page-eth2-get-involved-bug-hunting-desc": "Eth2のアップグレード仕様またはクライアント自身のバグを見つけて報告しましょう。最大50,000ドルの賞金を獲得し、リーダーボードへ入賞することができます。",
  "page-eth2-get-involved-bug-li": "仕様不適合の問題",
  "page-eth2-get-involved-bug-li-2": "finality breaking バグ",
  "page-eth2-get-involved-bug-li-3": "サービス拒否 (DOS) ベクトル",
  "page-eth2-get-involved-bug-li-4": "その他...",
  "page-eth2-get-involved-date": "締切日: 2020年12月23日",
  "page-eth2-get-involved-desc-1": "クライアントを実行するということは、イーサリアムに積極的に参加することを意味します。クライアントは、トランザクションの追跡と新しいブロックのチェックに役立ちます。",
  "page-eth2-get-involved-desc-2": "ETHをお持ちの方は、バリデータになりネットワークのセキュリティを確保するために、ETHをステーキングすることができます。バリデータとして、ETHの報酬を得ることができます。",
  "page-eth2-get-involved-desc-3": "コミュニティのテストに参加してください！リリース前のEth2アップグレードのテストに協力し、バグを見つけ、報酬を得ましょう。",
  "page-eth2-get-involved-ethresearch-1": "シャーディング",
  "page-eth2-get-involved-ethresearch-2": "Eth1からEth2への移行",
  "page-eth2-get-involved-ethresearch-3": "シャードとステートの実行",
  "page-eth2-get-involved-ethresearch-4": "すべての研究トピック",
  "page-eth2-get-involved-grants": "ステーキングコミュニティへの寄付",
  "page-eth2-get-involved-grants-desc": "ステーキングコミュニティのためのツールと教育コンテンツの作成に協力してください。",
  "page-eth2-get-involved-how": "どのように参加したいですか？",
  "page-eth2-get-involved-how-desc": "イーサリアムコミュニティは、クライアント、ステーキング、バグハンティングを行う人が増えることで、常に恩恵を受けます。",
  "page-eth2-get-involved-join": "研究に参加する",
  "page-eth2-get-involved-join-desc": " イーサリアムのほとんどのものと同様に、研究の多くは公開されています。つまり、話し合いに参加することもできますが、イーサリアムの研究者の発言に目を通すだけでもいいということです。ethresear.chはEth2のアップグレードだけではなく、Eth2にも力を入れています。",
  "page-eth2-get-involved-meta-description": "Eth2への参加方法：ノードの実行、ステーキング、バグハントなど。",
  "page-eth2-get-involved-more": "詳細",
  "page-eth2-get-involved-run-clients": "ビーコンチェーンクライアントを実行しよう",
  "page-eth2-get-involved-run-clients-desc": "イーサリアムの長期的な安全性の鍵は、クライアントの強力な分散にあります。クライアントとは、ブロックチェーンを動かすソフトウェアのことで、取引のチェックと新しいブロックの作成を行います。各クライアントはそれぞれ独自の機能を持っているので、自分にとっての使いやすさを基準にして選びましょう。",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "ETHをステーキングする",
  "page-eth2-get-involved-stake-desc": "ビーコンチェーンのセキュリティを確保するために、ETHをステーキングすることができるようになりました。",
  "page-eth2-get-involved-stake-eth": "ETHをステーキングする",
  "page-eth2-get-involved-subtitle": "あなたがイーサリアムと今後のEth2関連の取り組みに協力できる全ての方法は次のとおりです。",
  "page-eth2-get-involved-title-1": "クライアントを実行する",
  "page-eth2-get-involved-title-2": "ETHをステーキングする",
  "page-eth2-get-involved-title-3": "バグを見つける",
  "page-eth2-get-involved-written-go": "Goで書かれている",
  "page-eth2-get-involved-written-java": "Javaで書かれている",
  "page-eth2-get-involved-written-javascript": "JavaScriptで書かれている",
  "page-eth2-get-involved-written-net": ".NETで書かれている",
  "page-eth2-get-involved-written-nim": "Nimで書かれている",
  "page-eth2-get-involved-written-python": "Pythonで書かれている",
  "page-eth2-get-involved-written-rust": "Rustで書かれている",
  "eth2-client-cortex-logo-alt": "Cortex ロゴ",
  "eth2-client-lighthouse-logo-alt": "Lighthouse ロゴ",
  "eth2-client-lodestar-logo-alt": "Lodestar ロゴ",
  "eth2-client-nimbus-logo-alt": "Nimbus ロゴ",
  "eth2-client-prysm-logo-alt": "Prysm ロゴ",
  "eth2-client-teku-logo-alt": "Teku ロゴ",
  "eth2-rhino-img-alt": "先に充電するEth 2 Rhinoのグラフィック",
  "page-eth2-answer-1": "Eth2は、今日私達が使用しているイーサリアムを改善するために追加されたアップグレードだと考えてください。これらのアップグレードには、ビーコンチェーンと呼ばれる新しいチェーンと、シャードと呼ばれる最大64チェーンの作成が含まれています。",
  "page-eth2-answer-2": "これらは今日使用されているイーサリアムのメインネットとは別物ですが、メインネットに取って代わるものではありません。その代わりに、メインネットは時間をかけて、追加されているこの並列システムと、ドッキングまたは「マージ」を行います。",
  "page-eth2-answer-4": "言い換えると、今日私達が使っているイーサリアムは、最終的にはEth2のビジョンで目指している全ての機能を具現化したものになります。",
  "page-eth2-beacon-chain-btn": "ビーコンチェーン の詳細",
  "page-eth2-beacon-chain-date": "ビーコンチェーンは2020年12月1日に稼働しました。",
  "page-eth2-beacon-chain-desc": "エコシステムへの最初のEth2の追加。ビーコンチェーンはイーサリアムにステーキングをもたらし、将来のアップグレードのための基礎を築き、最終的には新しいシステムを調整するでしょう。",
  "page-eth2-beacon-chain-estimate": "ビーコンチェーンは稼働しています",
  "page-eth2-beacon-chain-title": "ビーコンチェーン",
  "page-eth2-bug-bounty": "バグ報奨金プログラムを見る",
  "page-eth2-clients": "Eth2クライアントをチェックする",
  "page-eth2-deposit-contract-title": "デポジットコントラクトアドレスを確認する",
  "page-eth2-diagram-ethereum-mainnet": "イーサリアムメインネット",
  "page-eth2-diagram-h2": "アップグレードをどのように合わせるか",
  "page-eth2-diagram-link-1": "プルーフ・オブ・ワークの詳細",
  "page-eth2-diagram-link-2": "シャードチェーンの詳細",
  "page-eth2-diagram-mainnet": "メインネット",
  "page-eth2-diagram-p": "イーサリアムのメインネットは、しばらくの間、現在の形で存在し続けます。これは、ビーコンチェーンとシャードのアップグレードがネットワークを破壊しないことを意味します。",
  "page-eth2-diagram-p-1": "メインネットは最終的にEth2のアップグレードによって導入された新しいシステムとマージされます。",
  "page-eth2-diagram-p-2": "ビーコンチェーンはイーサリアムの指揮者になり、バリデータを調整し、ブロック作成のペースを設定します。",
  "page-eth2-diagram-p-3": "最初は、メインネットとは別に存在し、バリデータを管理します - スマートコントラクト、トランザクション、アカウントとは何の関係もありません。",
  "page-eth2-diagram-p-4": "複数のシャードは、メインネットが処理できるトランザクションの量を増やすのに役立つ多くの追加データを処理してくれます。複数のシャードはビーコンチェーンによって調整されます。",
  "page-eth2-diagram-p-5": "しかし、すべての取引は依然としてメインネットに依存しており、プルーフ・オブ・ワークとマイナーによって保護されて、今日 知られているのと同じ方法で存在し続けます。",
  "page-eth2-diagram-p-6": "メインネットは、ビーコンチェーンによって調整されたプルーフ・オブ・ステークスシステムとマージされます。",
  "page-eth2-diagram-p-8": "これにより、メインネットは新システム内のシャードになります。すべてのイーサリアムはバリデータによって保護されるので、マイナーはもはや必要とされません。",
  "page-eth2-diagram-p10": "Eth2は移行または単一のものではありません。イーサリアムの真のポテンシャルを解き放つために取り組んでいるアップグレードを記述しています。次のようにまとめられます。",
  "page-eth2-diagram-scroll": "スクロールして探索する - 詳細についてはタップしてください。",
  "page-eth2-diagram-shard": "シャード(1)",
  "page-eth2-diagram-shard-1": "シャード(...)",
  "page-eth2-diagram-shard-2": "シャード(2)",
  "page-eth2-diagram-shard-3": "シャード(...)",
  "page-eth2-diagram-validators": "バリデータの詳細",
  "page-eth2-dive": "ビジョンに飛び込もう",
  "page-eth2-dive-desc": "分散化という Ethereumのコアとなる精神を維持しながら、 Ethereumをよりスケーラブルでセキュアで持続可能なものにしていくにはどうすればよいのでしょうか？",
  "page-eth2-docking": "ドッキング",
  "page-eth2-docking-answer-1": "ドッキングは、メインネットがシャードに変わったときに行われます。これは、シャードチェーンの実装に成功した後に行われます。",
  "page-eth2-docking-btn": "ドッキングの詳細",
  "page-eth2-docking-desc": "メインネットイーサリウムはどこかの時点でビーコンチェーンと「ドッキング」または「マージ」する必要があります。これにより、ネットワーク全体のステーキングが可能になり、エネルギー集約的なマイニングの終わりを告げることになるでしょう。",
  "page-eth2-docking-estimate": "予定: 2022年",
  "page-eth2-docking-mainnet": "メインネットとは？",
  "page-eth2-docking-mainnet-eth2": "Eth2 にメインネットをドッキング",
  "page-eth2-eth-blog": "ethereum.org ブログ",
  "page-eth2-explore-btn": "アップグレードを探る",
  "page-eth2-get-involved": "Eth2 に参加する",
  "page-eth2-get-involved-2": "参加しましょう",
  "page-eth2-head-to": "向かう",
  "page-eth2-help": "Eth2を支援したいですか？",
  "page-eth2-help-desc": "Eth2のアップグレードを重視したり、テストを手伝ったり、報酬を獲得したりする機会がたくさんあります。",
  "page-eth2-index-staking": "ステーキングはこちら",
  "page-eth2-index-staking-desc": "Eth2のアップグレードの鍵となるのは、ステーキングの導入です。Ethereumネットワークのセキュリティ確保のためにETHを使用したい場合は、以下の手順に従ってください。",
  "page-eth2-index-staking-learn": "ステーキングについて学ぶ",
  "page-eth2-index-staking-learn-desc": "ビーコンチェーンは、イーサリアムにステーキングをもたらします。これは、ETHを持つことが、ネットワークのセキュリティを確保することで公益となり、その過程でより多くのETHを稼ぐことができることを意味します。",
  "page-eth2-index-staking-step-1": "1. launchpad のセットアップ",
  "page-eth2-index-staking-step-1-btn": "ステーキングローンチパッドへアクセスする",
  "page-eth2-index-staking-step-1-desc": "Eth2をステーキングするには、ローンチパッドを使用する必要があります。 - これにより、１つずつ段階的にプロセスを進められます。",
  "page-eth2-index-staking-step-2": "2. ステーキングアドレスの確認",
  "page-eth2-index-staking-step-2-btn": "デポジットコントラクトアドレスの確認",
  "page-eth2-index-staking-step-2-desc": "ETHをステーキングする前に、正しいアドレスであることを確認してください。これをする前に ローンチパッドを通過しなければなりません。",
  "page-eth2-index-staking-sustainability": "より持続可能に",
  "page-eth2-just-docking": "ドッキングの詳細",
  "page-eth2-meta-desc": "イーサリアム 2.0のアップグレードの概要 と 実現したいビジョン。",
  "page-eth2-meta-title": "Eth2のアップグレード",
  "page-eth2-miners": "マイナーの詳細",
  "page-eth2-more-on-upgrades": "Eth2アップグレードの詳細",
  "page-eth2-proof-of-stake": "プルーフ・オブ・ステーク",
  "page-eth2-proof-of-work": "プルーフ・オブ・ワーク",
  "page-eth2-proof-stake-link": "プルーフ・オブ・ステークの詳細",
  "page-eth2-question-1-desc": "Eth2はリリース日が異なる、別個のアップグレードの集まりです。",
  "page-eth2-question-1-title": "Eth2はいつ始動しますか？",
  "page-eth2-question-2-desc": "Eth2を独立したブロックチェーンとして考えるのは正確ではありません。",
  "page-eth2-question-2-title": "Eth2は独立したブロックチェーンですか？",
  "page-eth2-question-3-answer-2": "シャードチェーンとドッキングのアップグレードは、dapp 開発者に影響を与えるかもしれません。しかし、仕様はまだ確定していないため、すぐに対応する必要はありません。",
  "page-eth2-question-3-answer-3": "ethresear.chのEth2研究開発チームに相談してください。",
  "page-eth2-question-3-answer-3-link": "ethresear.ch を訪れる",
  "page-eth2-question-3-desc": "Eth2に備えるために今すぐ何もする必要はありません。",
  "page-eth2-question-3-title": "Eth2の準備はどうすればいいですか？",
  "page-eth2-question-4-answer-1": "今日は、取引を送ったり、dappを使ったりするたびに、Eth1を使っています。これはマイナーによりセキュリティが確保されているイーサリウムです。",
  "page-eth2-question-4-answer-2": "「Eth1」またはメインネットは、ドッキングするまで通常通り動作し続けます。",
  "page-eth2-question-4-answer-3": "ドッキング後、検証者はプルーフ・オブ・ステイクによってネットワーク全体を保護します。",
  "page-eth2-question-4-answer-6": "ETHをステーキングすることで誰でも検証者になることができます。",
  "page-eth2-question-4-answer-7": "ステーキングの詳細",
  "page-eth2-question-4-answer-8": "ビーコンチェーンとシャードチェーンのアップグレードは別々に構築されているため、Eth1を混乱させることはありません。",
  "page-eth2-question-4-desc": "Eth1は、今日取引しているイーサリアムメインネットを指します。",
  "page-eth2-question-4-title": "Eth1とは？",
  "page-eth2-question-5-answer-1": "ネットワーク上でフルバリデータになるためには、32ETHをステーキングする必要があります。それほど多くの資金を持っていない場合 または それほど多くの額をステーキングしたくない場合は、ステークキングプールに参加することができます。これらのプールに参加することで、より少ない額をステーキングして、報酬総額の一部を稼ぐことができます。",
  "page-eth2-question-5-desc": "ステーキングlaunchpadを使用するか、ステーキングプールに参加する必要があります。",
  "page-eth2-question-5-title": "どのようにステーキングすればよいですか？",
  "page-eth2-question-6-answer-1": "今のところ、取るべきアクションはありません。しかし、シャードチェーンとドッキングのアップグレードについては、最新の情報を入手することをお勧めします。",
  "page-eth2-question-6-answer-3": "イーサリアム財団の Danny Ryan はコミュニティを定期的に更新します:",
  "page-eth2-question-6-answer-4": "ConsenSys の Ben Edginton が Eth2のニュースレターを毎週配信しています。",
  "page-eth2-question-6-answer-5": "ethresear.ch で Eth2 の研究開発に関する議論に参加することもできます。",
  "page-eth2-question-6-desc": "あなたの dapp は、差し迫ったアップグレードの影響を受けることはありません。しかし、将来のアップグレードにはいくつかの変更が必要になるかもしれません。",
  "page-eth2-question-6-title": "私はdappで何をする必要がありますか？",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(.NET 実装)",
  "page-eth2-question-7-desc": "コミュニティ全体の多くの異なるチームが、様々な Eth2 アップグレードに取り組んでいます。",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Rust 実装)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(JavaScript 実装)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Nim 実装)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Go 実装)",
  "page-eth2-question-7-teams": "Eth2 クライアント チーム:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Java 実装)",
  "page-eth2-question-7-title": "誰が Eth2 を構築しているのですか？",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Python 実装)",
  "page-eth2-question-8-answer-1": "Eth2のアップグレードは、セキュリティを維持し、持続可能性を向上させながら、分散化した方法でイーサリアムがスケーリングするのに役立ちます。",
  "page-eth2-question-8-answer-2": "おそらく最も明白な問題は、イーサリウムが1秒間に15～45件以上のトランザクションを処理できるようにする必要があるということです。しかし、Eth2のアップグレードは、今日のイーサリウムの他のいくつかの問題にも対処します。",
  "page-eth2-question-8-answer-3": "ネットワークの需要は非常に高く、イーサリウムの使用は高価なものになっています。ネットワークのノードは、イーサリウムのサイズとコンピュータが処理しなければならないデータの量に苦労しています。そして、イーサリウムのセキュリティと分散化を維持する基盤となるアルゴリズムは、エネルギー集約型であり、より環境に優しいものにする必要があります。",
  "page-eth2-question-8-answer-4": "変更の多くは、2015年からずっとEthereumのロードマップに載っていました。しかし、現在の状況では、アップグレードの必要性がさらに大きくなっています。",
  "page-eth2-question-8-answer-6": "Eth2のビジョンを探索する",
  "page-eth2-question-8-desc": "今日私達が使用しているイーサリアムは、エンドユーザーとネットワーク参加者へより良いエクスペリエンスを提供する必要があります。",
  "page-eth2-question-8-title": "なぜEth2が必要なのですか？",
  "page-eth2-question-9-answer-1": "もっとも活躍するには、あなたのETHをステーキングすることです。",
  "page-eth2-question-9-answer-2": "また、クライアントの多様性を向上させるために、２つ目のクライアントを実行することもできます。",
  "page-eth2-question-9-answer-3": "より技術力が高い方は、新規クライアントのバグを見つけるのを手伝うことができます。",
  "page-eth2-question-9-answer-4": "ethresear.chでイーサリアムの研究者との技術的な議論に重きを置くこともできます。",
  "page-eth2-question-9-desc": "貢献するために技術的な知識が必要なわけではありません。コミュニティでは、あらゆる種類のスキルセットからの貢献を募集しています。",
  "page-eth2-question-9-stake-eth": "ETHのステーキング",
  "page-eth2-question-9-title": "どうすれば Eth2 に貢献できますか？",
  "page-eth2-question-10-answer-1": "私たちは、技術的なロードマップという観点からあまり話をしたくはありません。なぜなら、これはソフトウェアであり、物事は変化する可能性があるからです。結果を読んだほうが何が起こっているのかを理解しやすいと考えています。",
  "page-eth2-question-10-answer-1-link": "アップグレードを見る",
  "page-eth2-question-10-answer-2": "しかし、もしあなたが議論を続けているのであれば、ここではアップグレードが技術的なロードマップにどのように適合するかを説明します。",
  "page-eth2-question-10-answer-3": "フェーズ0では、ビーコンチェーンを稼働するための作業を説明しています。",
  "page-eth2-question-10-answer-5": "技術的なロードマップのフェーズ1では、シャードチェーンの実装に焦点を当てています。",
  "page-eth2-question-10-answer-6": "メインネットをEth2にドッキングすることは、シャードチェーンを実装するために行われた作業の延長線上にあり、フェーズ1.5と呼ばれています。しかし、今日私たちが知っているイーサリアムが他のEth2のアップグレードとマージする重要な瞬間です。さらに、それはイーサリアムが完全にプルーフ・オブ・ステークへ移行する時でもあります。",
  "page-eth2-question-10-answer-7": "フェーズ2の周辺で何が起こるかは、現在のところ不明です。まだ熱心に研究や議論が行われているポイントです。当初の計画ではシャードチェーンに追加機能を加える予定でしたが、rollup-centric ロードマップでは必要ないかもしれません。",
  "page-eth2-question-10-answer-8": "rollup-centricロードマップの詳細",
  "page-eth2-question-10-desc": "フェーズは、Eth2テクニカルロードマップにおける作業フェーズとフォーカスに関わっています。",
  "page-eth2-question-10-title": "Eth2フェーズとは何ですか？",
  "page-eth2-question-title": "よくある質問（FAQ）",
  "page-eth2-question3-answer-1": "ETHの保有者は確かに何もする必要はありません。ETHを変更したり、アップグレードしたりする必要はありません。そうでないと言われた場合はほぼ確実に詐欺なので、注意してください。",
  "page-eth2-scalable": "よりスケーラブルに",
  "page-eth2-scalable-desc": "イーサリウムは、アプリケーションをより速く、より安く利用できるようにするために、1秒間に1000件のトランザクションをサポートする必要があります。",
  "page-eth2-secure": "よりセキュアに",
  "page-eth2-secure-desc": "イーサリウムはセキュリティを高める必要があります。イーサリウムの利用が増えるにつれて、プロトコルはあらゆる形態の攻撃に対してよりセキュアになる必要があります。",
  "page-eth2-shard-button": "シャードチェーンの詳細",
  "page-eth2-shard-date": "2回目のアップグレードであるシャードチェーンの導入は、2021年のある時期になると予想されています。",
  "page-eth2-shard-desc": "シャードチェーンは、取引を処理したりデータを保存したりするためのイーサリアムの容量を拡大します。シャード自体は、複数のフェーズに分けてロールアウトされ、時間の経過とともにより多くの機能を獲得していくことになります。",
  "page-eth2-shard-estimate": "予定: 2021年",
  "page-eth2-shard-lower": "シャードチェーンの詳細",
  "page-eth2-shard-title": "シャードチェーン",
  "page-eth2-stay-up-to-date": "最新情報を確認する",
  "page-eth2-stay-up-to-date-desc": "Eth2 のアップグレードに取り組んでいる研究者や開発者の最新情報を入手できます。",
  "page-eth2-sustainable-desc": "イーサリアムは環境のためにより良いものである必要があります。今日の技術は、あまりにも多くの計算能力とエネルギーを必要とします。",
  "page-eth2-take-part": "研究に参加する",
  "page-eth2-take-part-desc": "イーサリウムの研究者とファンがここで一堂に会し、Eth2のすべてを含む研究活動について議論します。",
  "page-eth2-the-upgrades": "Eth2のアップグレード",
  "page-eth2-the-upgrades-desc": "Eth2は、イーサリアムのスケーラビリティ、セキュリティ、持続可能性を向上させるアップグレードです。それぞれが並行して作業されていますが、いつデプロイされるかを決める特定の依存関係があります。",
  "page-eth2-unofficial-roadmap": "これは公式のロードマップではありません。これは、世の中の情報を基にした私たちの見解です。しかし、これはテクノロジーであるため、一瞬で変わる可能性があります。そのため、これをコミットメントとして見なさないでください。",
  "page-eth2-upgrade-desc": "私たちが大好きなイーサリアムが、よりスケーラブルで、より安全で、より持続可能に...",
  "page-eth2-upgrades": "Eth2のアップグレード",
  "page-eth2-upgrades-aria-label": "Eth2アップグレードメニュー",
  "page-eth2-upgrades-beacon-chain": "ビーコンチェーン",
  "page-eth2-upgrades-docking": "メインネットとEth2のドッキング",
  "page-eth2-upgrades-guide": "Eth2 アップグレードガイド",
  "page-eth2-upgrades-shard-chains": "シャードチェーン",
  "page-eth2-upgrading": "イーサリアムを急進的な新しい高みへアップグレード",
  "page-eth2-vision": "ビジョン",
  "page-eth2-vision-btn": "Eth2のビジョンの詳細",
  "page-eth2-vision-desc": "イーサリウムを主流にして全人類に役立つようにするには、イーサリウムをよりスケーラブルで安全で持続可能なものにしなければなりません。",
  "page-eth2-vision-upper": "Eth2 ビジョン",
  "page-eth2-what-to-do": "何をする必要がありますか？",
  "page-eth2-what-to-do-desc": "dappユーザーやETHホルダーであれば、何もする必要はありません。もしあなたが開発者であったり、ステーキングを始めたいのであれば、今日から参加できる方法があります。",
  "page-eth2-whats-eth2": "Eth2とは？",
  "page-eth2-whats-eth2-desc": "Eth2とは、イーサリウムをよりスケーラブルで、より安全で、より持続可能なものにするための相互に接続されたアップグレードのことを指します。これらのアップグレードは、イーサリウムエコシステム全体に渡って複数のチームによって構築されています。",
  "page-eth2-whats-ethereum": "待って、イーサリウムって何？",
  "page-eth2-whats-new": "Eth2 新着情報",
  "page-eth2-just-staking": "ステーキング",
  "page-eth2-staking-image-alt": "eth2 launchpad用Rhinoマスコットの画像。",
  "page-eth2-staking-51-desc-1": "ステーキングを行うことで、バリデータとしてネットワークに参加しやすくなるため、現在よりも多くのバリデータがネットワークに存在する可能性が高くなります。これにより、この種の攻撃はさらに困難になり、攻撃のコストが増加することになります。",
  "page-eth2-staking-accessibility": "よりアクセスしやすい",
  "page-eth2-staking-accessibility-desc": "ハードウェア要件がより簡単になり、32ETHを持っていなければプールできるようになったことで、より多くの人がネットワークに参加できるようになります。これにより、攻撃面積が減ることで、イーサリアムはより分散化され、安全性が高くなります。",
  "page-eth2-staking-at-stake": "ETHのステーキング",
  "page-eth2-staking-at-stake-desc": "トランザクションを検証し、新しいブロックを作成するには、ETHをステーキングしなければならないため、システムを騙そうとすると損失することがあります。",
  "page-eth2-staking-benefits": "イーサリアムのステーキングに参加する利点",
  "page-eth2-staking-check-address": "デポジット用のアドレスを確認する",
  "page-eth2-staking-consensus": "コンセンサスメカニズムの詳細",
  "page-eth2-staking-deposit-address": "デポジット用のアドレスを確認する",
  "page-eth2-staking-deposit-address-desc": "すでに ローンチパッドのセットアップ手順を踏んでいる方は、ステーキングデポジットコントラクトへトランザクションを送信する必要があります。とても慎重にそのアドレスを確認することをお勧めします。あなたは ethereum.org と 他の信頼できるサイトで 公式のアドレスを見つけることができます。",
  "page-eth2-staking-desc-1": "ネットワークがコンセンサスに達するのを助ける行動に対して報酬が与えられます。あなたが新しいブロックへトランザクションをバッチ処理したり、チェーンの安全な実行を維持するために他のバリデータの作業をチェックしたりすることで、報酬が得られます。",
  "page-eth2-staking-desc-2": "あなたはネットワークに利益をもたらす作業をすることで報酬を得ることができますが、悪意のある行動、オフラインにする、検証の失敗 によりETHを失う可能性があります。",
  "page-eth2-staking-desc-3": "フルバリデータになるためには32ETHが必要です。または、ステーキングプールに参加するためにはいくつかのETHが必要です。また、「Eth1」またはメインネットクライアントを実行する必要があります。launchpad は、プロセスとハードウェア要件を1つずつ段階的に説明してくれます。あるいは、バックエンドAPIを使用することもできます。",
  "page-eth2-staking-description": "ステーキングとは、バリデータソフトウェアを有効化するために32ETHをデポジットする行為です。バリデータとして、あなたはデータの保存、トランザクションの処理、ブロックチェーンへの新しいブロックの追加 を担当することになります。これにより、誰にとってもイーサリウムの安全性が保たれ、その過程であなたは新たなETHを獲得することができます。このプロセスはプルーフ・オブ・ステークと呼ばれ、ビーコンチェーンによって導入されています。",
  "page-eth2-staking-docked": "ドッキングの詳細",
  "page-eth2-staking-dyor": "自分で調査する",
  "page-eth2-staking-dyor-desc": "記載されているステーキングサービスは、いずれも公式の推薦ではありません。どのサービスがあなたに最適なのかを把握するために、調査するようにしてください。",
  "page-eth2-staking-header-1": "イーサリアムのバリデータになるためにETHをステーキングする",
  "page-eth2-staking-how-much": "いくらステーキングしますか？",
  "page-eth2-staking-how-to-stake": "ステーキングする方法",
  "page-eth2-staking-how-to-stake-desc": "全てはどのくらいの額をステーキングするかにかかっています。フルバリデータになるには 32 必要ですが、それよりも少ない額をステーキングすることもできます。",
  "page-eth2-staking-join": "参加する",
  "page-eth2-staking-join-community": "ステーキングのコミュニティに参加しよう",
  "page-eth2-staking-join-community-desc": "r/ethstakerは誰もがイーサリアムのステーキングについて議論するコミュニティです 。アドバイス、サポート、そしてステーキングについて話し合うために参加してください。",
  "page-eth2-staking-less-than": "未満",
  "page-eth2-staking-link-1": "バックエンドAPIを見る",
  "page-eth2-staking-meta-description": "イーサリアムステーキングの概要: リスク、報酬、要件、およびそれを行う場所。",
  "page-eth2-staking-meta-title": "イーサリアムステーキング",
  "page-eth2-staking-more-sharding": "シャーディングの詳細",
  "page-eth2-staking-pool": "プール内ステーキング",
  "page-eth2-staking-pool-desc": "あなたが持っているETHが32ETH未満の場合、より小さいステーキングをステーキングプールへ追加することができます。いくつかの企業が代行してくれるので、あなたはオンラインにし続ける事を心配する必要はありません。チェックすべき会社をいくつかご紹介します。",
  "page-eth2-staking-pos-explained": "プルーフ・オブ・ステーク の説明",
  "page-eth2-staking-pos-explained-desc": "ステーキングは、プルーフ・オブ・ステークにおいてバリデータになるために必要なことです。これは、現在行われているプルーフ・オブ・ワークシステムに代わるコンセンサスメカニズムです。コンセンサスメカニズムは、イーサリアムのようなブロックチェーンの安全性と分散性を維持するものです。",
  "page-eth2-staking-pos-explained-desc-1": "プルーフ・オブ・ステーク は、さまざまな方法でネットワークを保護するのに役立ちます:",
  "page-eth2-staking-services": "ステーキングサービスを見る",
  "page-eth2-staking-sharding": "シャーディングのロック解除",
  "page-eth2-staking-sharding-desc": "シャーディングはプルーフ・オブ・ステークシステムでのみ可能です。プルーフ・オブ・ワークシステムをシャーディングすると、ネットワークを破壊するのに必要な計算能力が希釈され、悪意のあるマイナーがシャードを制御するのが容易になります。プルーフ・オブ・ステークのランダムに割り当てられたステーカーの場合はそうではありません。",
  "page-eth2-staking-solo": "単独でステーキングして検証を実行する",
  "page-eth2-staking-solo-desc": "ステーキング処理を開始するには、Eth2 launchpad を使用する必要があります。これはすべてのセットアップを1つずつ段階的に説明してくれます。ステーキングの一部は、ブロックチェーンのローカルコピーであるEth2クライアントを実行することです。これをあなたのコンピュータにダウンロードするには時間がかかることがあります。",
  "page-eth2-staking-start": "ステーキングを開始する",
  "page-eth2-staking-subtitle": "ステーキングは、イーサリアムエコシステムの公共財です。あなたはネットワークの安全確保に貢献し、その過程で報酬を得ることができます。",
  "page-eth2-staking-sustainability": "より持続可能",
  "page-eth2-staking-sustainability-desc": "バリデーターは、プルーフ・オブ・ステークスシステムに参加するためにエネルギーを消費するコンピューターを必要としません - ノートパソコンやスマートフォンだけです。これにより、イーサリアムは、より環境に優しいものになります。",
  "page-eth2-staking-the-beacon-chain": "ビーコンチェーン の詳細",
  "page-eth2-staking-title-1": "報酬",
  "page-eth2-staking-title-2": "リスク",
  "page-eth2-staking-title-3": "必要条件",
  "page-eth2-staking-title-4": "あなたのETHをステーキングする方法",
  "page-eth2-staking-upgrades-li": "プルーフ・オブ・ステーク はビーコンチェーンで管理されています。",
  "page-eth2-staking-upgrades-li-2": "イーサリウムにはプルーフ・オブ・ステークのビーコンチェーンとプルーフ・オブ・ワークのメインネットが将来的に存在することになります。メインネットは私たちが何年も使ってきたイーサリアムです。",
  "page-eth2-staking-upgrades-li-3": "この間、ステーカーはビーコンチェーンに新しいブロックを追加しますが、メインネット取引は処理しません。",
  "page-eth2-staking-upgrades-li-4": "イーサリウムメインネットがシャードになると、イーサリアムはプルーフオブステイクシステムに完全に移行します。",
  "page-eth2-staking-upgrades-li-5": "その時になって初めて、自分のステーキングを引き出すことができます。",
  "page-eth2-staking-upgrades-title": "プルーフ・オブ・ステーク と Eth2 のアップグレード",
  "page-eth2-staking-validators": "より多くのバリデータ、セキュリティの向上",
  "page-eth2-staking-validators-desc": "イーサリアムのようなブロックチェーンでは、ネットワークの51%を制御していれば、破壊することが可能です。例えば、バリデータの51%を抑えることで、あなたの残高を1ETHではなく1,000,000,000ETH にできます。しかし、バリデータの51%を抑えるには、システム内にあるETHの51%を所有している必要があります。これはかなり大きい量です。",
  "page-eth2-staking-withdrawals": "すぐに引き出すことはできません",
  "page-eth2-staking-withdrawals-desc": "将来のアップグレードが行われるまで、あなたはステーキングを引き出すことはできません。メインネットがビーコンチェーンシステムとドッキングした後、出金が可能になります。",
  "page-eth2-vision-2014": "2014年のプルーフオブステークを説明するブログ記事を見る",
  "page-eth2-vision-beacon-chain": "ビーコンチェーン",
  "page-eth2-vision-beacon-chain-btn": "ビーコンチェーン の詳細",
  "page-eth2-vision-beacon-chain-date": "ビーコンチェーンは生きています",
  "page-eth2-vision-beacon-chain-desc": "エコシステムへの最初のEth2の追加。ビーコンチェーンはイーサリアムにステーキングをもたらし、将来のアップグレードのための基礎を築き、最終的には新しいシステムを調整するでしょう。",
  "page-eth2-vision-beacon-chain-upper": "ビーコンチェーン",
  "page-eth2-vision-desc-1": "イーサリウムは、ネットワークの混雑を減らし、スピードを上げてグローバルなユーザーベースのサービスを改善する必要があります。",
  "page-eth2-vision-desc-2": "ネットワークが成長するにつれて、ノードの実行は難しくなります。ネットワークを拡張する努力をすればするほど、これは難しくなるでしょう。",
  "page-eth2-vision-desc-3": "イーサリアムはあまりにも多く電気を使いすぎています。ネットワークを安全に保つ技術は、より持続可能なものにする必要があります。",
  "page-eth2-vision-docking-date": "予定: 2022年",
  "page-eth2-vision-docking-desc": "メインネットイーサリアムはどこかの時点でビーコンチェーンと「ドッキング」する必要があります。これにより、ネットワーク全体のステーキングが可能になり、エネルギー集約的なマイニングが終了することになります。",
  "page-eth2-vision-ethereum-node": "ノードの詳細",
  "page-eth2-vision-explore-upgrades": "アップグレードを探ろう",
  "page-eth2-vision-future": "グローバルに広がるデジタルな未来",
  "page-eth2-vision-meta-desc": "Eth2アップグレードがイーサリアムに与える影響と、イーサリアムが克服しなければならない課題の概要。",
  "page-eth2-vision-meta-title": "イーサリアム2.0(Eth2) のビジョン",
  "page-eth2-vision-mining": "マイニングの詳細",
  "page-eth2-vision-problems": "今日の問題",
  "page-eth2-vision-scalability": "スケーラビリティ",
  "page-eth2-vision-scalability-desc": "Ethereumは、ネットワーク内のノードのサイズを増やすことなく、1秒間あたりにより多くのトランザクションを処理できる必要があります。ノードはブロックチェーンを保存し、実行する重要なネットワーク参加者です。強力で高価なコンピューターだけがそれを行うことができるので、ノードのサイズを増やすことは現実的ではありません。規模を拡大するためには、イーサリウムは1秒あたりのトランザクション数を増やし、より多くのノード数と結合する必要があります。ノードが増えるほど、セキュリティは強化されます。",
  "page-eth2-vision-scalability-desc-3": "シャードチェーンのアップグレードにより、ネットワークの負荷が64の新しいチェーンへ分散されます。これにより、混雑が緩和され、現在の1秒あたり15～45トランザクションの制限を超えて速度が向上することで、イーサリアムに余裕ができます。",
  "page-eth2-vision-scalability-desc-4": "そして、チェーンの数が増えても、実際にはネットワークのメンテナである検証者の作業は少なくて済むでしょう。バリデータは自分のシャードを「実行」するだけで、イーサリウムチェーン全体を実行する必要はありません。これにより、ノードがより軽量化され、イーサリウムの拡張性と分散性を維持することが可能になります。",
  "page-eth2-vision-security": "セキュリティ",
  "page-eth2-vision-security-desc": "Eth2のアップグレードは、51％攻撃のような協調攻撃に対するイーサリウムのセキュリティを改善します。これは、誰かがネットワークの大部分を支配している場合、不正な変更を強制的に行うことができる攻撃の一種です。",
  "page-eth2-vision-security-desc-3": "プルーフ・オブ・ステークへの移行は、イーサリウムプロトコルの攻撃に対する阻害要因が大きくなることを意味します。これは、プルーフ・オブ・ステークでは、ネットワークを保護するバリデータがプロトコルに多額のETHを投資しなければならないからです。もし彼らがネットワークを攻撃しようとすると、プロトコルは自動的に彼らのETHを破壊することができます。",
  "page-eth2-vision-security-desc-5": "これはプルーフ・オブ・ワークでは不可能であり、プロトコルができる最善の方法は、ネットワークを保護するエンティティ(マイナー) に、そうでなければ得られるはずのマイニング報酬を強制的に失わせることです。プルーフ・オブ・ワークで同等の効果を得るためには、マイナーが不正行為をしようとした場合に、プロトコルはマイナーの機器をすべて破壊できなければなりません。",
  "page-eth2-vision-security-desc-5-link": "プルーフ・オブ・ワークの詳細",
  "page-eth2-vision-security-desc-8": "イーサリウムのセキュリティモデルも下記の導入のために変更する必要があります",
  "page-eth2-vision-security-desc-10": "ステーキングは、イーサリアムノードを「実行」するために高性能なハードウェアに投資する必要がないことも意味します。 これにより、より多くの人々が検証者になることを奨励し、ネットワークの分散化を増やし、攻撃エリアを減らすことができます。",
  "page-eth2-vision-security-staking": "ETHをステーキングする",
  "page-eth2-vision-security-validator": "あなたのETHをステーキングすることでバリデータになることができます。",
  "page-eth2-vision-shard-chains": "シャードチェーン",
  "page-eth2-vision-shard-date": "予定: 2021年",
  "page-eth2-vision-shard-desc-4": "シャードチェーンはネットワークの負荷を64の新しいブロックチェーンに広げます。 シャードはトランザクションのスピードを大幅に向上させる可能性を秘めており、毎秒最大100,000まで向上させます。",
  "page-eth2-vision-shard-upgrade": "シャードチェーンの詳細",
  "page-eth2-vision-staking-lower": "ステーキングの詳細",
  "page-eth2-vision-subtitle": "全人類を助けられるほど十分に強力になるまで イーサリウムを育てよう。",
  "page-eth2-vision-sustainability": "サステナビリティ",
  "page-eth2-vision-sustainability-desc-1": "イーサリウムやBitcoinのような他のブロックチェーンがマイニングのためにエネルギーを消費していることは周知の事実です。",
  "page-eth2-vision-sustainability-desc-2": "しかし、イーサリアムはコンピューティングパワーではなくステーキングを介して、ETHによって保護される方向に向かっています。",
  "page-eth2-vision-sustainability-desc-3": "ステーキングは ビーコンチェーン によって導入されますが、現在使用しているEthereumは一定期間並行して動作します。Eth2のアップグレードと「マージ」または「ドック」される前に ETHによって保護されたシステムと、コンピューティングパワーによって保護されたシステムがあります。これは、最初のうちは、シャードチェーンがアカウントやdappsのようなものを扱えないからです。そのため、私たちはマイニングとメインネットのことを忘れることはできません。",
  "page-eth2-vision-sustainability-desc-8": "ビーコンチェーンとシャードチェーンのアップグレードが起動して実行されると、メインネットを新しいシステムにドッキングさせる作業を開始します。これにより、メインネットをシャードにして、ETHによるセキュリティを確保し、エネルギー消費量を大幅に削減することができます。",
  "page-eth2-vision-sustainability-subtitle": "イーサリアムはもっと環境に優しくなる必要があります。",
  "page-eth2-vision-The": " ",
  "page-eth2-vision-title": "Eth2のビジョン",
  "page-eth2-vision-title-1": "詰まったネットワーク",
  "page-eth2-vision-title-2": "ディスク容量",
  "page-eth2-vision-title-3": "過剰なエネルギー",
  "page-eth2-vision-trilemma-cardtext-1": "Eth2のアップグレードにより、Ethereumは拡張性、安全性、分散化が可能になります。シャーディングは、ノードの実行とチェーンの検証に必要な電力を減少させる一方で、1秒あたりのトランザクションを増加させることで、イーサリウムをよりスケーラブルなものにします。ビーコンチェーンは、シャード間でバリデータを調整することで、イーサリウムの安全性を高めます。また、ステーキングは参加への障壁を下げ、より大きな、より分散化されたネットワークを構築します。",
  "page-eth2-vision-trilemma-cardtext-2": "安全で分散化されたブロックチェーンネットワークは、すべてのノードがチェーンによって処理されたすべてのトランザクションを検証する必要があります。 この作業量は、任意の時間に発生するトランザクションの数を制限します。分散化とセキュリティは、今日、イーサリアムチェーンへ影響を与えています。",
  "page-eth2-vision-trilemma-cardtext-3": "分散型ネットワークは、ノード間でトランザクションに関する情報を送信することで機能します。ネットワーク全体が状態の変化を知る必要があります。分散型ネットワーク上で秒単位でトランザクションをスケーリングすると、トランザクションの数が多くなるほど遅延が長くなり、情報が飛んでいる間に攻撃を受ける確率が高くなるため、セキュリティ上のリスクが発生します。",
  "page-eth2-vision-trilemma-cardtext-4": "イーサリウムのノードのサイズとパワーを増すことで、安全な方法で1秒あたりのトランザクションを増やすことができるが、ハードウェア要件によってそれができる人が制限されることになり、分散化を脅かすことになります。シャーディングとプルーフオブステークにより、ノードのサイズではなく、ノードの量を増やすことでイーサリアムのスケーリングが可能になることが期待されています。",
  "page-eth2-vision-trilemma-h2": "分散型スケーリングの課題",
  "page-eth2-vision-trilemma-modal-tip": "以下の円をタップして、分散スケーリングの問題をより深く理解しましょう。",
  "page-eth2-vision-trilemma-p": "イーサリアムの問題を解決するための単純な方法は、もっと中央集権化することでしょう。しかし、分散化は非常に重要です。イーサリアムの検閲耐性、オープン性、データのプライバシー、そして侵害されないセキュリティを実現するのは分散化なのです。",
  "page-eth2-vision-trilemma-p-1": "イーサリアムのビジョンは、よりスケーラビリティと安全性を高めつつ、分散化を維持することです。この3つの品質を達成することは、スケーラビリティ・トリレンマとして知られている問題です。",
  "page-eth2-vision-trilemma-p-2": "Eth2のアップグレードはトリレンマの解決を目指していますが、重大な課題があります。",
  "page-eth2-vision-trilemma-press-button": "三角形のボタンを押して、分散スケーリングの問題をより深く理解しましょう。",
  "page-eth2-vision-trilemma-text-1": "分散化",
  "page-eth2-vision-trilemma-text-2": "セキュリティ",
  "page-eth2-vision-trilemma-text-3": "スケーラビリティ",
  "page-eth2-vision-trilemma-title-1": "スケーラビリティ・トリレンマについて探る",
  "page-eth2-vision-trilemma-title-2": "Eth2のアップグレードと分散スケーリング",
  "page-eth2-vision-trilemma-title-3": "安全性と分散化",
  "page-eth2-vision-trilemma-title-4": "分散化とスケーラビリティ",
  "page-eth2-vision-trilemma-title-5": "スケーラビリティと安全性",
  "page-eth2-vision-understanding": "Eth2のビジョンの理解",
  "page-eth2-vision-upgrade-needs": "Eth2アップグレードの必要性",
  "page-eth2-vision-upgrade-needs-desc": "2015年にローンチしたイーサリウムプロトコルは、信じられないほどの成功を収めました。しかし、イーサリウムコミュニティは、イーサリウムのポテンシャルを完全に解き放つためには、いくつかの重要なアップグレードが必要であると常に期待していました。",
  "page-eth2-vision-upgrade-needs-desc-2": "需要の高さが取引手数料の高騰を招き、イーサリウムは平均的なユーザーにとって高価なものになっています。イーサリウムクライアントを実行するために必要なディスク容量は、急速に増加しています。そして、イーサリウムの安全性と分散化を維持するための基礎となるプルーフ・オブ・ワーク・コンセンサスアルゴリズムは、環境に大きな影響を与えています。",
  "page-eth2-vision-upgrade-needs-desc-3": "通称「Eth2」と呼ばれているものは、これらの問題点などを解決するアップグレードです。このアップグレードは元々「セレニティ」と呼ばれていたもので、2014年から研究開発が盛んに行われています。",
  "page-eth2-vision-upgrade-needs-desc-6": " これは、Eth2のオンスイッチがないことを意味します。改良版は時間の経過とともに徐々に発表されていきます。",
  "page-eth2-vision-upgrade-needs-serenity": "2015年のブログ記事「セレニティ」の論議 を見てください",
  "page-eth2-vision-uprade-needs-desc-5": "技術の準備が整った今、これらのアップグレードは、イーサリウムをよりスケーラブルで、安全で、持続可能なものにするために再構築されます。 イーサリウムのコアバリューである分散化を維持しながら、既存のユーザーの生活をより良くし、新しいユーザーを誘います。",
  "page-get-eth-cex": "中央集権型取引所",
  "page-get-eth-cex-desc": "取引所は、伝統的な通貨を使用して暗号資産を購入できる企業です。 彼らはあなたが管理するウォレットへそれを送るまで、あなたが購入したETHを保管しています。",
  "page-get-eth-checkout-dapps-btn": "dappsをチェックする",
  "page-get-eth-community-safety": "セキュリティに関するコミュニティの投稿",
  "page-get-eth-description": "イーサリウムとETHは政府や企業によって管理されていません - それらは分散化されています。これはETHが誰にでも使用できることを意味します。",
  "page-get-eth-dex": "分散型取引所(DEXs)",
  "page-get-eth-dex-desc": "より管理したい場合は、ETHピアツーピアを購入してください。DEXを使用すると、中央集権型の会社にあなたの資金を管理させることなく取引できます。",
  "page-get-eth-dexs": "分散型取引所(DEXs)",
  "page-get-eth-dexs-desc": "分散型取引所は、ETHとその他のトークンのためのオープンマーケットプレイスであり、買い手と売り手を直接繋ぎます。",
  "page-get-eth-dexs-desc-2": "トランザクション内の資金を保護するために信頼できる第三者を使用する代わりに、コードを使用します。 売り手のETHは支払いが保証されている場合にのみ送金されます。このタイプのコードはスマートコントラクトとして知られています。",
  "page-get-eth-dexs-desc-3": "これは、中央集権的な代替手段よりも地理的な制限が少ないということです。 誰かがあなたが欲しいものを売っていて、あなたが提供できる支払い方法を受け入れている場合は、準備万端です。 DEXは、他のトークン、PayPal、あるいは対面で直接現金配達でETHを購入することができます。",
  "page-get-eth-do-not-copy": "例: コピーしてはいけません",
  "page-get-eth-exchanges-disclaimer": "手動でこの情報を収集しました。何か間違いを見つけた場合は、こちらでお知らせください",
  "page-get-eth-exchanges-empty-state-text": "お住まいの国を入力すると、ETHの購入に使用できるウォレットと取引所のリストが表示されます",
  "page-get-eth-exchanges-except": "除外する",
  "page-get-eth-exchanges-header": "あなたはどこの国に住んでいますか？",
  "page-get-eth-exchanges-header-exchanges": "取引所",
  "page-get-eth-exchanges-header-wallets": "ウォレット",
  "page-get-eth-exchanges-intro": "交換所とウォレットには暗号資産をどこで売れるか制限があります。",
  "page-get-eth-exchanges-no-exchanges": "申し訳ありませんが、この国からETHを購入できる取引所はありません。もしご存知でしたら、こちらから教えてください",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "申し訳ありませんが、この国からETHを購入できる取引所またはウォレットはありません。もしご存知でしたら、こちらから教えてください",
  "page-get-eth-exchanges-no-wallets": "申し訳ありませんが、この国からETHを購入できるウォレットはありません。もしご存知でしたら、こちらから教えてください",
  "page-get-eth-exchanges-success-exchange": "法的チェックのため、取引所に登録するには数日かかることがあります。",
  "page-get-eth-exchanges-success-wallet-link": "ウォレット",
  "page-get-eth-exchanges-success-wallet-paragraph": "あなたが住んでいる場所では、これらのウォレットから直接ETHを購入することができます。詳細はこちら",
  "page-get-eth-exchanges-usa": "アメリカ合衆国(USA)",
  "page-get-eth-get-wallet-btn": "ウォレットを手に入れよう",
  "page-get-eth-hero-image-alt": "ETHヒーロー画像を取得する",
  "page-get-eth-keep-it-safe": "あなたのETHを安全に保持",
  "page-get-eth-meta-description": "住んでいる場所に基づいてETHを購入する方法とその後のアドバイス。",
  "page-get-eth-meta-title": "ETHの購入方法",
  "page-get-eth-need-wallet": "DEXを使用するにはウォレットが必要です。",
  "page-get-eth-new-to-eth": "ETHは初めてですか？始めるための概要はこちらになります。",
  "page-get-eth-other-cryptos": "他の暗号資産を使って購入する",
  "page-get-eth-protect-eth-desc": "ETHをたくさん購入する予定がある場合は、取引所ではなく自分で管理するウォレットに保管したいと思うかもしれません。 それは、取引所がハッカーの標的になる可能性があるからです。 ハッカーがアクセスできた場合、あなたの資金を失う可能性があります。自分で管理するウォレットでは、管理できるのはあなただけです。",
  "page-get-eth-protect-eth-in-wallet": "ウォレット内のETHを保護する",
  "page-get-eth-search-by-country": "国で検索する",
  "page-get-eth-security": "しかし、これはまた、資金の安全性を真剣に考える必要があることを意味します。ETHでは、銀行にお金を預けるのではなく、あなた自身を信頼することになります。",
  "page-get-eth-smart-contract-link": "スマートコントラクトの詳細",
  "page-get-eth-swapping": "あなたのトークンを他の人のETHに交換します。その逆も同様です。",
  "page-get-eth-traditional-currencies": "従来の通貨で購入",
  "page-get-eth-traditional-payments": "売り手から直接、従来の支払い方法でETHを購入してください。",
  "page-get-eth-try-dex": "Dexを試す",
  "page-get-eth-use-your-eth": "あなたのETHを使う",
  "page-get-eth-use-your-eth-dapps": "さて、ETHを手に入れたので、いくつかのイーサリアムアプリケーション(dapps) をチェックしてみましょう。金融、ソーシャルメディア、ゲーム、その他多くのカテゴリのdappsがあります。",
  "page-get-eth-wallet-instructions": "ウォレットの指示に従う",
  "page-get-eth-wallet-instructions-lost": "ウォレットにアクセスできなくなると、資金にアクセスできなくなります。ウォレットには、このような事態を防ぐための手順が記載されているはずです。慎重にそれらに従うようにしてください - ほとんどの場合、ウォレットにアクセスできなくなっても誰も助けてくれません。",
  "page-get-eth-wallets": "ウォレット",
  "page-get-eth-wallets-link": "ウォレットの詳細",
  "page-get-eth-wallets-purchasing": "いくつかのウォレットでは、デビット/クレジットカード、銀行振込、あるいはApple Payで暗号資産を購入することができます。地理的な制限があります。",
  "page-get-eth-warning": "これらのDEXは、使用するにはETHが必要になるので、初心者向けではありません。",
  "page-get-eth-what-are-DEX's": "DEXとは？",
  "page-get-eth-whats-eth-link": "ETHとは？",
  "page-get-eth-where-to-buy-desc": "取引所またはウォレットからETHを直接購入できます。",
  "page-get-eth-where-to-buy-desc-2": "住んでいる場所を基準に、どのサービスを利用できるか確認してください。",
  "page-get-eth-where-to-buy-title": "ETHを購入する場所",
  "page-get-eth-your-address": "あなたのETHアドレス",
  "page-get-eth-your-address-desc": "ウォレットをダウンロードすると、パブリックETHアドレスが作成されます。以下のようになります：",
  "page-get-eth-your-address-desc-3": "これはあなたのメールアドレスのようなものと考えてください。しかし、メールの代わりにETHを受け取ることができます。取引所からウォレットへETHを送金したい場合は、送金先としてあなたのアドレスを使用してください。送る前に必ず再確認しておきましょう！",
  "page-get-eth-your-address-wallet-link": "ウォレットをチェックする",
  "page-index-hero-image-alt": "hereum.orgヒーロー画像",
  "page-index-meta-description": "Ethereumは、お金と新しい種類のアプリケーションのためのグローバルな分散型プラットフォームです。 Ethereumでは、お金を制御するコードを書くことができ、世界中のどこにいてもアクセス可能なアプリケーションを構築できます。",
  "page-index-meta-title": "ホーム",
  "page-index-title": "イーサリアムは、分散型アプリケーションのためのグローバルでオープンソースなプラットフォームです。",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "言語サポート",
  "page-languages-interested": "貢献することに興味がありますか？",
  "page-languages-learn-more": "翻訳プログラムの詳細はこちら",
  "page-languages-meta-desc": "Ethereum.orgのすべてのサポートされている言語のリソースと翻訳者として参加する方法。",
  "page-languages-meta-title": "etherum.org 言語翻訳",
  "page-languages-p1": "Ethereumはグローバルなプロジェクトであり、国籍や言語に関係なく誰もがアクセスできる ethereum.org は重要です。私たちのコミュニティは、このビジョンを実現するために懸命に取り組んできました。",
  "page-languages-translations-available": "ethereum.org は以下の言語で利用可能です。",
  "page-languages-want-more-header": "他の言語でethereum.orgを見たいですか？",
  "page-languages-want-more-link": "翻訳プログラム",
  "page-languages-want-more-paragraph": "ethereum.orgの翻訳者は常に可能な限り多くの言語でページを翻訳しています。彼らが現在何に取り組んでいるか、またはそれらに参加するためにサインアップするには、以下を読んでください。",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "暗号資産の担保",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "イーサリウムでは、あなたのETHを取引せずに他のユーザーから直接借りることができます。これによりレバレッジをかけることができます。より多くのETHをためるためにこのようなことをする人もいます。",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "しかしETHの価格は変動しますので、あなたは過剰担保が必要になります。 100ステーブルコインを借りたい場合は、少なくとも150ドル相当のETHが必要になるでしょう。これによりシステムと貸し手が保護されます。",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "仮想通貨の裏付けがあるステーブルコインの詳細",
  "page-stablecoins-accordion-borrow-pill": "上級者向け",
  "page-stablecoins-accordion-borrow-places-intro": "これらのdappsでは、暗号資産を担保として使用してステーブルコインを借りることができます。いくつかはETHと同様に他のトークンを受け入れます。",
  "page-stablecoins-accordion-borrow-places-title": "ステーブルコインを借りる場所",
  "page-stablecoins-accordion-borrow-requirement-1": "イーサリアムウォレット",
  "page-stablecoins-accordion-borrow-requirement-1-description": "dappsを利用するにはウォレットが必要になります。",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "担保 かつ/または 取引手数料にETHが必要になります",
  "page-stablecoins-accordion-borrow-requirements-description": "ステーブルコインを借りるには、正しいdappsを使用する必要があります。ウォレットといくらかのETHも必要です。",
  "page-stablecoins-accordion-borrow-risks-copy": "ETHを担保として使用し、その価値が低下した場合、その担保はあなたが生成したステーブルコインをカバーすることはできません。そうなると、あなたのETHは清算されてしまい、ペナルティを受ける可能性があります。そのため、ステーブルコインを借りる場合はETHの価格に注意が必要です。",
  "page-stablecoins-accordion-borrow-risks-link": "最新のETH価格",
  "page-stablecoins-accordion-borrow-risks-title": "リスク",
  "page-stablecoins-accordion-borrow-text-preview": "あなたが返済しなければならない担保として暗号資産を使用することで、いくつかのステーブルコインを借りることができます。",
  "page-stablecoins-accordion-borrow-title": "借りる",
  "page-stablecoins-accordion-buy-exchanges-title": "人気の取引所",
  "page-stablecoins-accordion-buy-requirement-1": "暗号資産交換とウォレット",
  "page-stablecoins-accordion-buy-requirement-1-description": "住んでいる場所で利用できるサービスを確認してください",
  "page-stablecoins-accordion-buy-requirements-description": "取引所またはウォレットを持つアカウントでは、直接、暗号資産を購入できます。 あなたは既にいくつかのETHを取得するために使用しているかもしれません。あなたが住んでいる場所でどのサービスを使用できるか確認してください。",
  "page-stablecoins-accordion-buy-text-preview": "多くの取引所とウォレットは、ステーブルコインを直接購入することができます。 地理的な制限が適用されます。",
  "page-stablecoins-accordion-buy-title": "購入",
  "page-stablecoins-accordion-buy-warning": "中央集権型の取引所は、USDC、Tetherなどの不換紙幣担保型のステーブルコインのみが上場している場合があります。あなたはそれらを直接購入することができない場合があります。 しかし、プラットフォーム上で購入できるETHや他の暗号資産からそれらへ交換できるはずです。",
  "page-stablecoins-accordion-earn-project-1-description": "主にオープンソースソフトウェアムーブメントの技術的な仕事です。",
  "page-stablecoins-accordion-earn-project-2-description": "MakerDaoコミュニティ(あなたにダイをもたらしたチーム) のためのテクノロジー、コンテンツ、その他の仕事。",
  "page-stablecoins-accordion-earn-project-3-description": "あなたの専門分野において本当に何でも知っているときは、Daiを稼ぐためにバグを見つけてください。",
  "page-stablecoins-accordion-earn-project-bounties": "Gitcoin報奨金",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Eth2 バグ報奨金",
  "page-stablecoins-accordion-earn-project-community": "MakerDao コミュニティ",
  "page-stablecoins-accordion-earn-projects-copy": "これらはあなたの仕事のためにステーブルコインであなたへ支払うプラットフォームです。",
  "page-stablecoins-accordion-earn-projects-title": "どこでステーブルコインを獲得するか",
  "page-stablecoins-accordion-earn-requirement-1": "イーサリアムウォレット",
  "page-stablecoins-accordion-earn-requirement-1-description": "稼いだステーブルコインを受け取るにはウォレットが必要です。",
  "page-stablecoins-accordion-earn-requirements-description": "ステーブルコインは価値が安定しているため、仕事やサービスの支払いに最適な方法です。しかし、支払いにはウォレットが必要です。",
  "page-stablecoins-accordion-earn-text-preview": "イーサリアムエコシステム内のプロジェクトに取り組むことで、ステーブルコインを獲得できます。",
  "page-stablecoins-accordion-earn-title": "獲得する",
  "page-stablecoins-accordion-less": "少なく表示",
  "page-stablecoins-accordion-more": "さらに表示",
  "page-stablecoins-accordion-requirements": "必要なもの",
  "page-stablecoins-accordion-swap-dapp-intro": "ETHとウォレットをすでに手に入れている場合は、これらのdappを使用してステーブルコインと交換できます。",
  "page-stablecoins-accordion-swap-dapp-link": "分散型取引所の詳細",
  "page-stablecoins-accordion-swap-dapp-title": "トークンを交換するためのDapps",
  "page-stablecoins-accordion-swap-editors-tip": "編集者からのヒント",
  "page-stablecoins-accordion-swap-editors-tip-button": "ウォレットを探す",
  "page-stablecoins-accordion-swap-editors-tip-copy": "ETHを購入し、ステーブルコインを含むトークンと直接交換できるウォレットを手に入れましょう。",
  "page-stablecoins-accordion-swap-pill": "推奨",
  "page-stablecoins-accordion-swap-requirement-1": "イーサリアムウォレット",
  "page-stablecoins-accordion-swap-requirement-1-description": "スワップを承認してコインを保管するにはウォレットが必要です",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "スワップの支払いに必要です",
  "page-stablecoins-accordion-swap-text-preview": "分散型取引所でほとんどのステーブルコインを入手することができます。したがって、あなたが持っているトークンを、必要なステーブルコインと交換することができます。",
  "page-stablecoins-accordion-swap-title": "スワップ",
  "page-stablecoins-algorithmic": "アルゴリズム",
  "page-stablecoins-algorithmic-con-1": "アルゴリズムを信頼する(または読むことができる) 必要があります。",
  "page-stablecoins-algorithmic-con-2": "合計供給量に基づいてあなたのコインの残高が変わります。",
  "page-stablecoins-algorithmic-description": "これらのステーブルコインは他の資産に裏付けられていません。その代わりに、価格が希望の金額を下回るとアルゴリズムがトークンを売却し、希望の金額を超えるとトークンを供給します。流通しているトークンの数は定期的に変化するため、あなたが所有するトークンの数は変化しますが、あなたの取り分は常に反映されます。",
  "page-stablecoins-algorithmic-pro-1": "担保は必要ありません。",
  "page-stablecoins-algorithmic-pro-2": "公開アルゴリズムによって制御されます。",
  "page-stablecoins-bank-apy": "0.05%",
  "page-stablecoins-bank-apy-source": "USAで、基本的に銀行によって支払われる、連邦政府による保険付き預金に支払う平均利率。",
  "page-stablecoins-bank-apy-source-link": "情報源",
  "page-stablecoins-bitcoin-pizza": "悪名高いBitcoinピザ",
  "page-stablecoins-bitcoin-pizza-body": "2010年、誰かが10,000ビットコインで2枚のピザを購入しました。当時、これらは～41ドルの価値がありました。今日の市場では数百万ドルです。Ethereumの歴史の中には、似たような残念な取引が多くあります。ステーブルコインはこの問題を解決してくれるので、あなたはピザを楽しみながらETHを保持することができるのです。",
  "page-stablecoins-coin-price-change": "コインの価格変更(直近30日間)",
  "page-stablecoins-crypto-backed": "暗号資産の裏付け",
  "page-stablecoins-crypto-backed-con-1": "不換紙幣担保型のステーブルコインよりも低い安定性。",
  "page-stablecoins-crypto-backed-con-2": "あなたは暗号資産による担保の価値に注目し続ける必要があります。",
  "page-stablecoins-crypto-backed-description": "これらのステーブルコインは、ETHのような他の暗号資産によって支えられています。これらの価格は、原資産(または担保) の価値に依存しており、変動する可能性があります。ETHの価値は変動する可能性があるため、これらのステーブルコインは、価格が可能な限り安定していることを保証するために過剰に担保されています。つまり、1ドルのクリプトを裏付けとしたステーブルコインには、少なくとも2ドルの価値がある原資産があるということに近いです。 そのため、ETHの価格が下落した場合は、より多くのETHを使用してステーブルコインを裏付けなければならず、そうでなければ、ステーブルコインの価値が失われます。",
  "page-stablecoins-crypto-backed-pro-1": "透明性と完全な分散型。",
  "page-stablecoins-crypto-backed-pro-2": "迅速に他の暗号資産に交換できる。",
  "page-stablecoins-crypto-backed-pro-3": "外部の管理者がいない - すべての資産はイーサリアムアカウントで管理されています。",
  "page-stablecoins-dai-banner-body": "Daiはおそらく最も有名な分散型ステーブルコインです。その価値はおよそ1ドルで、dapps全体で広く受け入れられています。",
  "page-stablecoins-dai-banner-learn-button": "Daiについて学ぶ",
  "page-stablecoins-dai-banner-swap-button": "ETHをDaiに交換する",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Dai ロゴ",
  "page-stablecoins-editors-choice": "編集者のチョイス",
  "page-stablecoins-editors-choice-intro": "これらは、おそらく今最もよく知られているステーブルコインの例であり、dappsを使うときに便利だと感じたコインです。",
  "page-stablecoins-explore-dapps": "dapps を探す",
  "page-stablecoins-fiat-backed": "不換紙幣担保型",
  "page-stablecoins-fiat-backed-con-1": "中央集権的 – 誰かがトークンを発行する必要があります。",
  "page-stablecoins-fiat-backed-con-2": "会社が十分な準備金を持っていることを確認するために監査が必要です。",
  "page-stablecoins-fiat-backed-description": "基本的には、従来の不換紙幣(通常はドル) の IOU(I owe you, 借用証書) です。不換紙幣を使用してステーブルコインを購入し、後で現金化して元の通貨に交換することができます。",
  "page-stablecoins-fiat-backed-pro-1": "暗号資産の変動性に対して安全です。",
  "page-stablecoins-fiat-backed-pro-2": "価格の変動は最小限に抑えられます。",
  "page-stablecoins-find-stablecoin": "ステーブルコインを探す",
  "page-stablecoins-find-stablecoin-how-to-get-them": "ステーブルコインを取得する方法",
  "page-stablecoins-find-stablecoin-intro": "利用可能な何百ものステーブルコインがあります。ここでは、始めるのに役立つものをいくつかご紹介します。イーサリウムを初めて利用する場合は、最初に調査をすることをお勧めします。",
  "page-stablecoins-find-stablecoin-types-link": "様々なステーブルコインの種類",
  "page-stablecoins-get-stablecoins": "ステーブルコインを取得する方法",
  "page-stablecoins-hero-alt": "時価総額による３大ステーブルコイン: Dau、USDC、Tether。",
  "page-stablecoins-hero-button": "ステーブルコインを入手する",
  "page-stablecoins-hero-header": "日常的に使えるデジタルマネー",
  "page-stablecoins-hero-subtitle": "ステーブルコインは、ETHの価格が変更された場合でも、固定価格にとどまるように設計されたイーサリアムトークンです。",
  "page-stablecoins-interest-earning-dapps": "利子で稼ぐdapps",
  "page-stablecoins-meta-description": "イーサリアムステーブルコインの紹介：イーサリアムステーブルコインとは何か、どのようにして入手するのか、なぜ重要なのか。",
  "page-stablecoins-precious-metals": "貴金属",
  "page-stablecoins-precious-metals-con-1": "中央集権的 – 誰かがトークンを発行する必要があります。",
  "page-stablecoins-precious-metals-con-2": "トークン発行者と貴金属の備蓄を信頼する必要があります。",
  "page-stablecoins-precious-metals-description": "不換紙幣担保型のコインのように、代わりにこれらのステーブルコインは価値を維持するために金のような資源を使用します。",
  "page-stablecoins-precious-metals-pro-1": "暗号資産の変動性に対して安全です。",
  "page-stablecoins-prices": "ステーブルコインの価格",
  "page-stablecoins-prices-definition": "ステーブルコインは変動しない暗号通貨です。ステーブルコイン はETHと同じ力を多く持っていますが、その価値は従来の通貨のように安定しています。そのため、あなたはイーサリアムで使用できる安定したお金にアクセスできます。 ",
  "page-stablecoins-prices-definition-how": "ステーブルコインがどのように安定性を得るのか",
  "page-stablecoins-research-warning": "イーサリウムは新しい技術であり、ほとんどのアプリケーションは新しいものです。リスクをしっかりと認識して、損をしてもいい分だけ入金するようにしましょう。",
  "page-stablecoins-research-warning-title": "常に自分で調査する",
  "page-stablecoins-save-stablecoins": "ステーブルコインで貯める",
  "page-stablecoins-save-stablecoins-body": "ステーブルコインを借りる需要が多いため、ステーブルコインには多くの場合平均以上の金利が付きます。 あなたが貸出プールにステーブルコインを預けることによってあなたのステーブルコインでリアルタイムに利益を得ることを可能にするdappsがあります。 ちょうど銀行の世界でのように、あなたは借り手のためのトークンを供給していますが、いつでもあなたのトークンと利子を引き出すことができます。",
  "page-stablecoins-saving": "ステーブルコインの貯蓄を有効に活用して、いくらかの利子を稼いでください。あらゆる暗号資産と同様に、予測された年率利回り(APY) は、リアルタイムの需要/供給により日々変化する可能性があります。",
  "page-stablecoins-stablecoins-dapp-callout-description": "イーサリウムのdappsをチェックする - 日常のトランザクションでは多くの場合、ステーブルコインのほうが便利です。",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "鳩のイラスト。",
  "page-stablecoins-stablecoins-dapp-callout-title": "ステーブルコインを使おう",
  "page-stablecoins-stablecoins-dapp-description-1": "Dai、USDC、TUSD、USDT など、多くのステーブルコイン市場。 ",
  "page-stablecoins-stablecoins-dapp-description-2": "ステーブルコインを貸し出して、利子と$COMP、複利の独自のトークンを獲得しましょう。",
  "page-stablecoins-stablecoins-dapp-description-3": "DaiとUSDCで利息を稼ぐことができる取引プラットフォーム。",
  "page-stablecoins-stablecoins-dapp-description-4": "Daiを貯めるために設計されたアプリです。",
  "page-stablecoins-stablecoins-feature-1": "ステーブルコインはグローバルであり、インターネット経由で送金できます。イーサリアムアカウントを持っていれば、簡単に着金または送金できます。",
  "page-stablecoins-stablecoins-feature-2": "ステーブルコインは需要が高いので、自分のものを貸すことで利息を得ることができます。貸す前にリスクを確認しておきましょう。",
  "page-stablecoins-stablecoins-feature-3": "ステーブルコインはETHや他のイーサリアムトークンと交換可能です。多くのdappsはステーブルコインを信頼しています。",
  "page-stablecoins-stablecoins-feature-4": "ステーブルコインは暗号化によって保護されています。誰もあなたに代わって取引を偽造することはできません。",
  "page-stablecoins-stablecoins-meta-description": "イーサリアムステーブルコインの紹介：イーサリアムステーブルコインとは何か、どのようにして入手するのか、なぜ重要なのか。",
  "page-stablecoins-stablecoins-table-header-column-1": "通貨",
  "page-stablecoins-stablecoins-table-header-column-2": "時価総額",
  "page-stablecoins-stablecoins-table-header-column-3": "担保の種類",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "暗号資産",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "通貨",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "貴金属",
  "page-stablecoins-table-error": "ステーブルコインをロードできませんでした。ページを更新してみてください。",
  "page-stablecoins-table-loading": "ステーブルコインのデータを読み込んでいます...",
  "page-stablecoins-title": "ステーブルコイン",
  "page-stablecoins-top-coins": "時価総額別の上位ステーブルコイン",
  "page-stablecoins-top-coins-intro": "時価総額は",
  "page-stablecoins-top-coins-intro-code": "存在するトークンの合計数にトークンあたりの価値を掛けたものです。この一覧は動的で、ここにリストされているプロジェクトは必ずしもethereum.orgチームによって承認されていません。",
  "page-stablecoins-types-of-stablecoin": "動作の仕組み: ステーブルコインの種類",
  "page-stablecoins-usdc-banner-body": "USDcはおそらく最も有名な不換紙幣担保型のステーブルコインです。その価値はおよそ1ドルで、Circle と Coinbaseによって裏付けされています。",
  "page-stablecoins-usdc-banner-learn-button": "USDCについて学ぶ",
  "page-stablecoins-usdc-banner-swap-button": "ETHをUSDCに交換する",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "USDC ロゴ",
  "page-stablecoins-why-stablecoins": "なぜステーブルコインなのか？",
  "page-stablecoins-how-they-work-button": "動作の仕組み",
  "page-stablecoins-why-stablecoins-body": "ETHはビットコインと同じく、新しい技術なので価格が変動します。そのため、頻繁に使うのは控えた方がいいかもしれません。ステーブルコインは、従来の通貨の価値を反映して、イーサリウムで使える安定したお金へのアクセスを提供します。",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": "。ウォレットを追加したい場合、",
  "page-find-wallet-alpha-logo-alt": "AlphaWalletロゴ",
  "page-find-wallet-ambo-logo-alt": "Amboロゴ",
  "page-find-wallet-argent-logo-alt": "Argentロゴ",
  "page-find-wallet-buy-card": "カードで暗号資産を購入する",
  "page-find-wallet-buy-card-desc": "銀行のカードで直接ウォレットからETHを購入することができます。地理的な制限が適用される場合があります。",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "dappsをチェックする",
  "page-find-wallet-clear": "フィルタをクリア",
  "page-find-wallet-coinbase-logo-alt": "Coinbaseロゴ",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "必要な機能に基づいてウォレットを選びましょう。",
  "page-find-wallet-description": "ウォレットには多くのオプション機能があります。",
  "page-find-wallet-description-alpha": "モバイルで安全なエンクレーブを活用する完全オープンソースのイーサリアムウォレットは、フルテストネットサポートを提供し、TokenScript標準を採用しています。",
  "page-find-wallet-description-ambo": "すぐに投資できます。アプリをダウンロードしてから数分以内に、初めての投資を行うことができます。",
  "page-find-wallet-description-argent": "ワンタップで利息と投資を得ることができます。借りて保管し、送金し、所有してください。",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "暗号資産をあなた自身で保存するセキュアなアプリ",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "DharmaはDeFiへのポータルです。もうシードフレーズもChrome拡張機能も不要です。",
  "page-find-wallet-description-enjin": "頑強で機能満載で便利です。トレーダー、ゲーマー、開発者のために作られたものです。",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "デジタル資産をイーサリアムに保存するのに最も信頼できるプラットフォーム",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imTokenは何百万人もの人に信頼されている簡単でセキュアなデジタルウォレットです",
  "page-find-wallet-description-ledger": "最高のセキュリティ基準であなたの資産を安全に保管しましょう。",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "ブロックチェーンアプリケーションの検討を数秒で開始します。世界中の100万人以上のユーザーに信頼されています。",
  "page-find-wallet-description-monolith": "世界で唯一、自己保管ウォレットとVISAデビットカードの2つを一組にしました。UK&EUで入手でき、世界中で使用できます。",
  "page-find-wallet-description-multis": "Multisは、ビジネス向けに設計された暗号通貨アカウントです。 Multisを使用すると、企業はアクセス制御を保存し、貯蓄で利子を獲得し、支払いと会計を合理化することができます。",
  "page-find-wallet-description-mycrypto": "MyCryptoはあなたの全てのアカウントを管理するためのインターフェイスです。MetaMask、Ledger、Trezorなどのウォレットで暗号資産を交換、送信、購入します。",
  "page-find-wallet-description-myetherwallet": "イーサリアムブロックチェーンとのやり取りを支援する無料のクライアントサイドインターフェイス",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "独自のL2ペイメントネットワークを持つ、ノンカストディアル（ユーザーが資産を自己管理可能）なコミュニティ所有のウォレット。",
  "page-find-wallet-description-portis": "アプリを誰にとっても簡単にするノンカストディアル（ユーザーが資産を自己管理可能）なブロックチェーンウォレット",
  "page-find-wallet-description-rainbow": "イーサリアム資産のためのより良いホーム",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "セキュアなメッセージングアプリ、暗号ウォレット、最先端の技術で構築されたWeb3ブラウザ",
  "page-find-wallet-description-tokenpocket": "TokenPocket：マルチチェーンをサポートした、セキュアで便利な世界をリードするデジタル通貨ウォレットとDAppsポータル。",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Web 3.0用のワンクリックログイン",
  "page-find-wallet-description-trezor": "元祖ハードウェアウォレット",
  "page-find-wallet-description-trust": "Trust Walletは分散型のマルチコイン暗号通貨ウォレットです。鍵を管理しながら、暗号資産を購入したり、dappsを探したり、資産をスワップすることができます。",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGoは、最初のキーレス暗号ウォレットです。ZenGoでは、管理する必要があるか、なくしてしまうかもしれない 秘密鍵、パスワード、またはシードフレーズはありません。前例のないシンプルさと安全性で、イーサリアムを購入、取引、獲得、保管することができます。",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Dharma ロゴ",
  "page-find-wallet-enjin-logo-alt": "Enjin ロゴ",
  "page-find-wallet-Ethereum-wallets": "イーサリアムウォレット",
  "page-find-wallet-explore-dapps": "dapps を探す",
  "page-find-wallet-explore-dapps-desc": "これらのウォレットは、イーサリアムのdappsへ接続できるように設計されています。",
  "page-find-wallet-feature-h2": "ウォレットの気になる機能を選んでください",
  "page-find-wallet-fi-tools": "金融ツールへのアクセス",
  "page-find-wallet-fi-tools-desc": "あなたのウォレットから直接借りたり、貸したり、利息を得たりすることができます。",
  "page-find-wallet-following-features": "以下の機能で：",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Gnosis Safe ロゴ",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "ウォレットヒーローの画像を探す",
  "page-find-wallet-imtoken-logo-alt": "imToken ロゴ",
  "page-find-wallet-last-updated": "最終更新日",
  "page-find-wallet-ledger-logo-alt": "Ledger ロゴ",
  "page-find-wallet-limits": "制限による保護",
  "page-find-wallet-limits-desc": "アカウントが削除されないように制限を設定して資産を保護します。",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "リストポリシー",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "あなたの欲しい機能に基づいてイーサリアムウォレットを探して比較しましょう。",
  "page-find-wallet-meta-title": "イーサリアムウォレットを探しましょう",
  "page-find-wallet-metamask-logo-alt": "MetaMask ロゴ",
  "page-find-wallet-monolith-logo-alt": "Monolith ロゴ",
  "page-find-wallet-multis-logo-alt": "Multis ロゴ",
  "page-find-wallet-multisig": "複数署名アカウント",
  "page-find-wallet-multisig-desc": "セキュリティを強化するために、複数署名ウォレットは特定の取引を許可するには複数のアカウントを必要とします。",
  "page-find-wallet-mycrypto-logo-alt": "MyCrypto ロゴ",
  "page-find-wallet-myetherwallet-logo-alt": "MyEtherWallet ロゴ",
  "page-find-wallet-new-to-wallets": "ウォレットは初めてですか？始めるための概要はこちらになります。",
  "page-find-wallet-new-to-wallets-link": "イーサリアムウォレット",
  "page-find-wallet-not-all-features": "これらの機能をすべて備えたウォレットはありません",
  "page-find-wallet-not-endorsements": "このページに記載されているウォレットは公式の推奨ではなく、情報提供のみを目的として提供されています。 これらの説明はウォレット会社自身によって提供されています。私たちは、私たちの基準に基づいて、このページにプロダクトを追加します。",
  "page-find-wallet-overwhelmed": "イーサリアムウォレットは以下のとおりです。その数の多さに圧倒されていますか？機能でフィルタリングしてみてください。",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Pillar ロゴ",
  "page-find-wallet-portis-logo-alt": "Portis ロゴ",
  "page-find-wallet-rainbow-logo-alt": "Rainbow ロゴ",
  "page-find-wallet-raise-an-issue": "GitHubでイシューを作成してください",
  "page-find-wallet-search-btn": "選択した機能を検索",
  "page-find-wallet-showing": "表示 ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Status ロゴ",
  "page-find-wallet-swaps": "分散型トークンスワップ",
  "page-find-wallet-swaps-desc": "ETHと他のトークンをあなたのウォレットから直接取引します。",
  "page-find-wallet-title": "ウォレットを探す",
  "page-find-wallet-tokenpocket-logo-alt": "TokenPocket ロゴ",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Torus ロゴ",
  "page-find-wallet-trezor-logo-alt": "Trezor ロゴ",
  "page-find-wallet-trust-logo-alt": "Trust ロゴ",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "機能を１つまたは２つ削除してみてください",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "さて、ウォレットを手に入れたので、いくつかのイーサリアムアプリケーション(dapps) をチェックしてみましょう。金融、ソーシャルメディア、ゲーム、その他多くのカテゴリのdappsがあります。",
  "page-find-wallet-use-your-wallet": "あなたのウォレットを使ってみる",
  "page-find-wallet-voluem-desc": "ETHをたくさん保持したい場合は、一度に$ 2000 ETH以上を購入することができるウォレットを選びましょう。",
  "page-find-wallet-volume": "大量購入",
  "page-find-wallet-we-found": "見つかりました",
  "page-find-wallet-withdraw": "銀行に引き出す",
  "page-find-wallet-withdraw-desc": "取引所を経由せずに、ETHを直接銀行口座に出金できます。",
  "page-find-wallet-yet": "まだ",
  "page-find-wallet-zengo-logo-alt": "ZenGo ロゴ",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "ETHのステーキング",
  "page-wallets-accounts-addresses": "ウォレット、アカウント、アドレス",
  "page-wallets-accounts-addresses-desc": "いくつかのキーワードの違いを理解しておくと良いでしょう。",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "イーサリアムのウォレットを表現した、胴体が金庫になっているロボットのイラスト",
  "page-wallets-ethereum-account": "イーサリアムアカウント",
  "page-wallets-blog": "Coinbaseブログ",
  "page-wallets-bookmarking": "あなたのウォレットをブックマークする",
  "page-wallets-bookmarking-desc": "ウェブウォレットを使用する場合は、フィッシング詐欺から身を守るためにサイトをブックマークしてください。",
  "page-wallets-cd": "オフラインで暗号を保持できる物理的なハードウェアウォレット - 非常に安全です",
  "page-wallets-converted": "暗号を変換しましたか？",
  "page-wallets-converted-desc": "高価な価値ある物をお持ちの方には、最も安全性の高いハードウェアウォレットをお勧めします。また、不正行為の警告と引き出し限度額が設定されているウォレットもお勧めです。",
  "page-wallets-curious": "暗号に興味がありますか？",
  "page-wallets-curious-desc": "仮想通貨に慣れておらず、それに対する感触を得たい場合は、イーサリアムアプリケーションを探すか、ウォレットから直接、最初のETHを購入する機会を利用することをお勧めします。",
  "page-wallets-desc-2": "資金の送金とETHの管理にはウォレットが必要です。",
  "page-wallets-desc-2-link": "ETHの詳細",
  "page-wallets-desc-3": "ウォレットはイーサリアムアカウントを管理するためのツールにすぎません。つまり、いつでもウォレットプロバイダーを交換できます。また、多くのウォレットでは、1つのアプリケーションから複数のイーサリアムアカウントを管理することもできます。",
  "page-wallets-desc-4": "それは、ウォレットがあなたの資金を管理しているのではなく、あなたが管理しているからです。本当にあなたのものを管理するためのツールに過ぎないのです。",
  "page-wallets-description": "イーサリアムウォレットは、イーサリアムアカウントを操作するためのアプリケーションです。銀行を介さないインターネットバンキングアプリのようなものと考えてください。ウォレットを使えば、残高を読み取ったり、取引を送信したり、アプリケーションに接続したりすることができます。",
  "page-wallets-desktop": "MacOS または Windows または Linux により資金を管理したい場合のデスクトップアプリケーション",
  "page-wallets-ethereum-wallet": "ウォレット",
  "page-wallets-explore": "イーサリアムの探索",
  "page-wallets-features-desc": "あなたが重要と思う機能に基づいて、ウォレット選びのお手伝いをさせていただきます。",
  "page-wallets-features-title": "機能に基づいて選びたいですか？",
  "page-wallets-find-wallet-btn": "ウォレットを探す",
  "page-wallets-find-wallet-link": "ウォレットを探す",
  "page-wallets-get-some": "ETHを取得する",
  "page-wallets-get-some-alt": "レゴブロックで作られたETHロゴを作成する手のイラスト",
  "page-wallets-get-some-btn": "ETHを取得する",
  "page-wallets-get-some-desc": "ETHはイーサリアムのネイティブ暗号資産です。イーサリアムアプリケーションを使用するには、ウォレットの中にETHが必要です。",
  "page-wallets-get-wallet": "ウォレットを手に入れよう",
  "page-wallets-get-wallet-desc": "ウォレットには様々な種類があります。私たちは最適なウォレットを選ぶお手伝いをしたいと思っています。",
  "page-wallets-get-wallet-desc-2": "覚えておいてください: この決定は永遠ではありません 。あなたのイーサリアムアカウントはあなたのウォレットプロバイダーと結びついていません。",
  "page-wallets-how-to-store": "デジタル資産をイーサリアムに保存する方法",
  "page-wallets-keys-to-safety": "あなたの暗号資産を安全に保持するための鍵",
  "page-wallets-manage-funds": "資金を管理するためのアプリ",
  "page-wallets-manage-funds-desc": "あなたのウォレットは残高、取引履歴を表示し、資金を送信/受信する方法を提供します。ウォレットによってはより多くの機能を提供する場合もあります。",
  "page-wallets-meta-description": "イーサリアムウォレットを使用する上で知っておきたいこと。",
  "page-wallets-meta-title": "イーサリアムウォレット",
  "page-wallets-mobile": "どこからでもあなたの資金にアクセスできるようにするモバイルアプリケーション",
  "page-wallets-more-on-dapps-btn": "Dappsの詳細",
  "page-wallets-most-wallets": "ほとんどのウォレットプロダクトはイーサリアムアカウントを作ることができます。そのため、ウォレットをダウンロードする前にアカウントを作る必要はありません。",
  "page-wallets-protecting-yourself": "あなた自身とあなたの資金を守るために",
  "page-wallets-seed-phrase": "シードフレーズを書き留めてください",
  "page-wallets-seed-phrase-desc": "ウォレットは、多くの場合安全な場所に書き留めなければならないシードフレーズを提供します。 これはウォレットを復元できる唯一の方法です。",
  "page-wallets-seed-phrase-example": "次に例を示します：",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "コンピューターに保存しないでください。書き留めて安全に保管してください。",
  "page-wallets-slogan": "あなたのデジタル未来への鍵",
  "page-wallets-stay-safe": "安全に維持する方法",
  "page-wallets-stay-safe-desc": "ウォレットは、考え方が少し変わります。金融の自由 と どこでも資金にアクセスして使用する能力 には、少し責任を伴います。 暗号資産のカスタマーサポートはありません。",
  "page-wallets-subtitle": "ウォレットはあなたの資金とイーサリアムアプリケーションにアクセスできます。ウォレットにアクセスできるのはあなただけです。",
  "page-wallets-take-responsibility": "ご自身の資金に責任を持ってください",
  "page-wallets-take-responsibility-desc": "中央集権型の取引所では、あなたのウォレットを従来の方法であなたが復元できるユーザー名とパスワードにリンクします。ただ、あなたの資金を保管している取引所を信頼していることを肝に銘じてください。その会社が攻撃されたり閉鎖したりすると、あなたの資金は危険にさらされます。",
  "page-wallets-tips": "安全を保つためのその他のヒント",
  "page-wallets-tips-community": "コミュニティより",
  "page-wallets-title": "イーサリアムウォレット",
  "page-wallets-triple-check": "すべてを3回確認しましょう",
  "page-wallets-triple-check-desc": "取引を元に戻すことはできず、ウォレットは簡単には復元できないので、気を付けてください。",
  "page-wallets-try-dapps": "いくつかのdappsをお試しください",
  "page-wallets-try-dapps-alt": "イーサリアムコミュニティのメンバーが一緒に作業しているイラスト",
  "page-wallets-try-dapps-desc": "Dappsはイーサリアム上に構築されたアプリケーションです。ほとんどの従来のアプリケーションよりも安く、より公平で、データにより親切なアプリケーションです。",
  "page-wallets-types": "ウォレットの種類",
  "page-wallets-web-browser": "Webブラウザ経由であなたのアカウントとやり取りできるWebウォレット",
  "page-wallets-whats-a-wallet": "イーサリアムウォレットとは？",
  "page-wallets-your-ethereum-account": "あなたのイーサリアムアカウント",
  "page-wallets-your-ethereum-account-desc": "ウォレットはあなたのイーサリアムアカウントの窓口で、残高、取引履歴などを扱えます。ただし、いつでもウォレットプロバイダを交換できます。",
  "page-wallets-your-login": "イーサリアムアプリケーションへのログイン",
  "page-wallets-your-login-desc": "あなたのウォレットはイーサリアムアカウントを使用して任意の分散型アプリケーションに接続することができます。これは多くのdappsをまたがって使用できるログインのようなものです。",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "イーサリウムは、少額の手数料で誰にでも暗号通貨を送ることができる技術です。また、誰もが使用でき、誰もがダウンできないアプリケーションも供給しています。",
  "page-what-is-ethereum-101-desc-2": "イーサリウムはBitcoinのイノベーションに基づいていますが、いくつかの大きな違いがあります。",
  "page-what-is-ethereum-101-desc-3": "どちらも、支払業者や銀行を介さずにデジタルマネーを利用することができます。しかし、イーサリウムはプログラム可能なので、Bitcoinを含むさまざまなデジタル資産も使用できます。",
  "page-what-is-ethereum-101-desc-4": "これは、イーサリウムが支払い以上のものであることも意味しています。それは金融サービスやゲーム、アプリのマーケットプレイスであり、あなたのデータを盗んだりあなたを検閲したりすることはできません。",
  "page-what-is-ethereum-101-italic": "世界のプログラム可能なブロックチェーンです。",
  "page-what-is-ethereum-101-strong": "それは ",
  "page-what-is-ethereum-accessibility": "Ethereumは誰にでも開かれています。",
  "page-what-is-ethereum-adventure": "あなたの冒険を選んでください！",
  "page-what-is-ethereum-alt-img-bazaar": "市場を覗き込んでいる人のイラストは、イーサリウムを表しています。",
  "page-what-is-ethereum-alt-img-comm": "イーサリウムコミュニティのメンバーが一緒に作業しているイラスト",
  "page-what-is-ethereum-alt-img-lego": "レゴブロックで作られたETHロゴを作成する手のイラスト",
  "page-what-is-ethereum-alt-img-social": "大きなETHロゴが描かれたイーサリウム専用のソーシャルスペースにいる人々のイラスト",
  "page-what-is-ethereum-banking-card": "全ての人のための銀行",
  "page-what-is-ethereum-banking-card-desc": "誰もが金融サービスにアクセスできるわけではありません。しかし、イーサリウムとその貸し出し、借り入れ、貯蓄商品にアクセスするために必要なのは、インターネット接続だけです。",
  "page-what-is-ethereum-build": "イーサリウムで何かを作る",
  "page-what-is-ethereum-build-desc": "イーサリウムでビルドを試してみたい場合は、ドキュメントを読んだり、チュートリアルを試してみたり、始めるのに必要なツールをチェックしてみてください。",
  "page-what-is-ethereum-censorless-card": "検閲を受けない",
  "page-what-is-ethereum-censorless-card-desc": "政府や企業がイーサリアムを管理しているわけではありません。この分散化により、イーサリアム上で支払いを受けたり、サービスを利用したりすることを誰かに止められることは、ほぼ不可能になります。",
  "page-what-is-ethereum-comm-desc": "私たちのコミュニティには、アーティスト、クリプトアナーキスト、フォーチュン500企業、そして今ではあなたを含む、あらゆるバックグラウンドを持つ人々が参加しています。今すぐ参加できる方法を見つけてください。",
  "page-what-is-ethereum-commerce-card": "商取引の保証",
  "page-what-is-ethereum-commerce-card-desc": "イーサリアムは、より公平な競争の場を作ります。顧客は、合意されたものを提供した場合にのみ資金が手に渡るというセキュアで組み込まれた保証を得ることができます。ビジネスを行うために大企業の力は必要ありません。",
  "page-what-is-ethereum-community": "イーサリアムコミュニティ",
  "page-what-is-ethereum-compatibility-card": "勝利のための互換性",
  "page-what-is-ethereum-compatibility-card-desc": "イーサリアムプロダクトはデフォルトで互換性があるため、より良いプロダクトと経験が構築されているところです。企業はお互いの成功に基づいて構築することができます。",
  "page-what-is-ethereum-dapps-desc": "イーサリアム上で実行されるプロダクトとサービスには、金融、仕事、ソーシャルメディア、ゲーム向けのdappsがあります。デジタルな未来のためのアプリに出会えます。",
  "page-what-is-ethereum-dapps-img-alt": "コンピュータ上でイーサリアムアプリケーションを使用している犬のイラスト",
  "page-what-is-ethereum-dapps-title": "イーサリアム dapps",
  "page-what-is-ethereum-desc": "デジタル未来のための基盤",
  "page-what-is-ethereum-explore": "イーサリアムの探索",
  "page-what-is-ethereum-get-started": "さらに学ぶための最良の方法は、ウォレットをダウンロードし、いくらかETHを入手してイーサリアムのdappを試してみることです。",
  "page-what-is-ethereum-in-depth-description": "イーサリアムは、バックグラウンドや場所を問わず、誰もがデジタルマネーとデータフレンドリーなサービスにオープンアクセスできるようになっています。イーサリウムは、暗号通貨であるether(ETH) と 今日使用できる数千ものアプリケーション の背後にあるコミュニティビルドのテクノロジーです。",
  "page-what-is-ethereum-internet-card": "よりプライベートなインターネット",
  "page-what-is-ethereum-internet-card-desc": "イーサリアムアプリを使用するために、個人情報を提供する必要はありません。イーサリウムは監視ではなく、価値に基づいた経済を構築しています。",
  "page-what-is-ethereum-meet-comm": "コミュニティの紹介",
  "page-what-is-ethereum-meta-description": "イーサリアムについて、それが何をするのか、どうやってそれを試すのかについて学びましょう。",
  "page-what-is-ethereum-meta-title": "イーサリアムとは？",
  "page-what-is-ethereum-native-alt": "イーサ(ETH) のシンボル",
  "page-what-is-ethereum-native-crypto": "イーサリアムのネイティブ暗号通貨で、Bitcoinに相当します。あなたはイーサリウムアプリケーションでETHを使用したり、友人や家族に送金したりできます。",
  "page-what-is-ethereum-native-img-alt": "イーサリアムのウォレットを表現するために使用される胴体に金庫を備えたロボットのイラスト",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "ピアツーピアネットワーク",
  "page-what-is-ethereum-p2p-card-desc": "イーサリアムを使えば直接お金を動かしたり、契約を結んだりすることができます。仲介業者を通す必要はありません。",
  "page-what-is-ethereum-singlecard-desc": "ブロックチェーンとイーサリアムの技術に興味がある方を支援することができます。",
  "page-what-is-ethereum-singlecard-link": "イーサリアムの仕組み",
  "page-what-is-ethereum-singlecard-title": "イーサリアムの仕組み",
  "page-what-is-ethereum-start-building-btn": "開発を始める",
  "page-what-is-ethereum-title": "イーサリアムとは？",
  "page-what-is-ethereum-tools-needed": "必要なのは参加するためのウォレットだけです。",
  "page-what-is-ethereum-try": "イーサリアムを試す",
  "page-what-is-ethereum-tryit": "市場に足を踏み入れて、試してみてください。",
  "page-what-is-ethereum-wallets": "ウォレット",
  "page-what-is-ethereum-wallets-desc": "ETHとイーサリアムアカウントをどのように管理していますか。始めるにはウォレットが必要になりますので、ウォレット選びをお手伝いします。",
  "page-what-is-ethereum-welcome": "イーサリアムへようこそ",
  "page-what-is-ethereum-welcome-2": "長期のご利用をお待ちしております。",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "ETHを投資しようとしている場合、ステーキングが到着しました！",
  "banner-staking-2": "デポジットコントラクトアドレスの確認",
  "docking": "ドッキング",
  "website_last_updated": "本コンテンツの最終更新",
  "update_datetime": "{Updated,date} {Updated,time}",
  "page-eth2-vision-security-desc-9": "バリデータランダムに異なるシャードに割り当てることができます。 これにより、バリデータが特定のシャードを攻撃して共謀することは事実上不可能になります。シャーディングはプルーフ・オブ・ワーク・ブロックチェーン上ではセキュアではありません。なぜならこの方法ではプロトコルによりマイナーを制御することはできないからです。",
  "page-index-sections-developers-desc": "イーサリウムとそのアプリケーションの背後にある技術について学び、それで構築を開始できます。",
  "page-index-sections-developers-image-alt": "レゴブロックで作られたイーサリアム像を構築する手のイラスト",
  "page-index-sections-developers-link-text": "開発を始める",
  "page-index-sections-developers-title": "開発者",
  "page-index-sections-enterprise-desc": "イーサリウムが新たなビジネスモデルをどのように開放し、コストを削減し、ビジネスを将来を見据えることができるかをご覧ください。",
  "page-index-sections-enterprise-image-alt": "ラップトップの周りでイーサリアムプロジェクトに作業するグループの図",
  "page-index-sections-enterprise-link-text": "エンタープライズのためのイーサリアム",
  "page-index-sections-enterprise-title": "エンタープライズ",
  "page-index-sections-individuals-desc": "イーサリアム、イーサ、ウォレット、トークンなどを知って、イーサリウムアプリケーションを使い始めることができます。",
  "page-index-sections-individuals-image-alt": "コンピューターに座っている犬の図",
  "page-index-sections-individuals-link-text": "イーサリアムを始めましょう",
  "page-index-sections-individuals-title": "イーサリアムについて",
  "page-index-subtitle": "イーサリアム上で書かれたコードは、世界中のどこからでもアクセスが可能であり、プログラムした通りに正確に動作し、デジタルな価値をコントロールすることを可能にします。",
  "page-find-wallet-authereum-logo-alt": "Authereumロゴ",
  "page-find-wallet-description-authereum": "ダウンロードとシードフレーズは不要です。どんなブラウザでも、いつでも、モバイルまたはデスクトップで。",
  "page-wallets-accounts-addresses-desc-2": "トランザクションを送ることができ、残高を持つエンティティです。",
  "page-wallets-accounts-has": "イーサリアムアカウントには以下が含まれます",
  "page-wallets-an": " ",
  "page-wallets-ethereum-addresses": "イーサリアムアドレス",
  "page-wallets-ethereum-addresses-2": "、受信トレイがメールアドレスがあるように。これを使用してアカウントへ資金を送ることができます。",
  "page-wallets-ethereum-wallet-2": "は、アカウントの残高の確認、トランザクションの送信等、イーサリアムアカウントを管理できるプロダクトです。"
}
