{
  "1inch-logo": "1inch logo",
  "aave-logo": "Aave logo",
  "about": "Про",
  "about-ethereum-org": "Про ethereum.org",
  "about-us": "Про нас",
  "alt-eth-blocks": "Ілюстрація блоків, організованих як символ ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Угору",
  "banner-page-incomplete": "Інформація на цій сторінці неповна. Якщо ви розумієтеся на темі, відредагуйте цю сторінку й поділіться з нами своїми знаннями.",
  "beacon-chain": "Beacon Chain",
  "binance-logo": "Binance logo",
  "bittrex-logo": "Bittrex logo",
  "brand-assets": "Елементи бренду",
  "bug-bounty": "Винагороди за виявлення багів",
  "coinbase-logo": "Coinbase logo",
  "coinmama-logo": "Coinmama logo",
  "community": "Спільнота",
  "community-menu": "Community Menu",
  "compound-logo": "Compound logo",
  "cons": "Недоліки",
  "contact": "Контакти",
  "content-versions": "Content Versions",
  "contributing": "Участь у проекті",
  "contributors": "Учасники проекту",
  "contributors-thanks": "Дякуємо всім, хто додавав інформацію на цю сторінку!",
  "cookie-policy": "Правила щодо файлів cookie",
  "copied": "Скопійовано",
  "copy": "Копіювати",
  "dark-mode": "Темний режим",
  "data-provided-by": "Джерело даних:",
  "decentralized-applications-dapps": "Децентралізовані програми (dapps)",
  "deposit-contract": "Контракт депозиту",
  "devcon": "Devcon",
  "developers": "Розробникам",
  "developers-home": "Головна сторінка для розробників",
  "docs": "Документи",
  "documentation": "Документація",
  "dydx-logo": "Dydx logo",
  "ecosystem": "Екосистема",
  "edit-page": "Змінити сторінку",
  "ef-blog": "Блог Ethereum Foundation",
  "eips": "Пропозиції покращення Ethereum",
  "enterprise": "Корпоративний сектор",
  "enterprise-menu": "Меню корпоративного сектора",
  "esp": "Програма підтримки екосистеми",
  "eth-current-price": "Поточна ціна ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Відкрите довідкове джерело з інформацією про оновлення Beacon Chain Eth2",
  "eth2-beaconscan-desc": "Довідкове джерело з інформацією про оновлення Beacon Chain Eth2 – Etherscan для Eth2",
  "eth2-become-staker": "Станьте стейкером",
  "eth2-become-staker-desc": "Стейкінг уже тут! Якщо ви хочете отримати частку ETH і допомогти захистити мережу, ознайомтеся з можливими ризиками.",
  "eth2-explore": "Дізнайтеся більше",
  "eth2-no-action-needed": "Вам нічого не потрібно робити з наявними токенами ETH. Остерігайтеся шахраїв, які для обміну просять переказати токени ETH.",
  "eth2-run-beacon-chain": "Запустіть клієнт Beacon",
  "eth2-run-beacon-chain-desc": "Що більше запущених клієнтів, то захищеніша мережа Ethereum. Допоможіть покращити Ethereum!",
  "eth2-service-announcement": "Оголошення щодо Eth2",
  "eth2-what-shipping": "Коли буде запущено оновлення?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Елементи бренду Ethereum",
  "ethereum-community": "Спільнота Ethereum",
  "ethereum-foundation": "Ethereum Foundation",
  "ethereum-foundation-logo": "Ethereum Foundation logo",
  "ethereum-glossary": "Глосарій Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Ethereum logo",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Студія Ethereum",
  "ethereum-wallets": "Гаманці Ethereum",
  "ethereum-whitepaper": "Основне про Ethereum",
  "example-projects": "Приклади проектів",
  "find-wallet": "Знайти гаманець",
  "foundation": "Foundation",
  "gemini-logo": "Gemini logo",
  "get-eth": "Як отримати ETH",
  "get-involved": "Долучитися",
  "get-started": "Почати",
  "gitcoin-logo": "Gitcoin logo",
  "glossary": "Глосарій",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Посібники та ресурси",
  "history": "Історія",
  "history-of-ethereum": "Історія Ethereum",
  "home": "Головна",
  "how-ethereum-works": "Як працює Ethereum",
  "image": "зображення",
  "in-this-section": "У цьому розділі",
  "individuals": "Фізичні особи",
  "individuals-menu": "Меню фізичної особи",
  "jobs": "Jobs",
  "kraken-logo": "Kraken logo",
  "language-ar": "Арабська",
  "language-bg": "Bulgarian",
  "language-bn": "Бенгальська",
  "language-ca": "Catalan",
  "language-cs": "Чеська",
  "language-de": "Німецька",
  "language-el": "Грецька",
  "language-en": "Англійська",
  "language-es": "Іспанська",
  "language-fa": "Перська",
  "language-fi": "Фінська",
  "language-fr": "Французька",
  "language-hu": "Угорська",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Індонезійська",
  "language-ig": "Ігбо",
  "language-it": "Італійська",
  "language-ja": "Японська",
  "language-ko": "Корейська",
  "language-lt": "Литовська",
  "language-ml": "Малайялам",
  "language-nb": "Норвезька",
  "language-nl": "Нідерландська",
  "language-pl": "Польська",
  "language-pt": "Португальська",
  "language-pt-br": "Португальська (Бразилія)",
  "language-ro": "Румунська",
  "language-ru": "Російська",
  "language-se": "Шведська",
  "language-sk": "Словацька",
  "language-sl": "Словенська",
  "language-support": "Доступні мови",
  "language-tr": "Турецька",
  "language-uk": "Українська",
  "language-vi": "В’єтнамська",
  "language-zh": "Китайська (спрощена)",
  "language-zh-tw": "Китайська (традиційна)",
  "languages": "Мови",
  "last-24-hrs": "за останні 24 години",
  "last-edit": "Остання зміна",
  "learn": "Довідка",
  "learn-by-coding": "Кодуй і навчайся",
  "learn-menu": "Меню довідки",
  "learn-more": "Докладніше",
  "less": "Менше",
  "light-mode": "Світлий режим",
  "listing-policy-disclaimer": "Усі продукти, перелічені на цій сторінці, не є офіційними рекомендаціями й наведені тут лише в ознайомлювальних цілях. Щоб додати продукт або надіслати відгук щодо правил, повідомте про проблему в GitHub.",
  "listing-policy-raise-issue-link": "Повідомити про проблему",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Завантаження...",
  "loading-error": "Помилка завантаження.",
  "loading-error-refresh": "Помилка завантаження даних. Спробуйте оновити сторінку.",
  "logo": "logo",
  "loopring-logo": "Loopring logo",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Основна мережа Ethereum",
  "makerdao-logo": "MakerDao logo",
  "matcha-logo": "Matcha logo",
  "merge": "Merge",
  "more": "Більше",
  "nav-beginners": "Початківці",
  "next": "Далі",
  "oasis-logo": "Oasis logo",
  "on-this-page": "На цій сторінці",
  "page-content": "Вміст сторінки",
  "page-enterprise": "Корпоративному сектору",
  "page-last-updated": "Останнє оновлення сторінки",
  "previous": "Назад",
  "privacy-policy": "Політика конфіденційності",
  "private-ethereum": "Приватна мережа Ethereum",
  "pros": "Переваги",
  "read-more": "Читати далі",
  "refresh": "Оновіть сторінку.",
  "review-progress": "Перевірено",
  "search": "Пошук",
  "search-box-blank-state-text": "Введіть пошуковий запит.",
  "search-eth-address": "Схоже, це адреса Ethereum. Ми не надаємо дані про адреси. Спробуйте знайти їх у посібник із блоків, наприклад",
  "search-no-results": "Пошук не дав результатів",
  "security": "Security",
  "see-contributors": "Переглянути учасників",
  "set-up-local-env": "Налаштування локального середовища",
  "shard-chains": "Ланцюги сегментів даних",
  "show-all": "Показати все",
  "show-less": "Показати менше",
  "site-description": "Ethereum — це глобальна децентралізована платформа для заробітку грошей і створення нових програм. На ній можна написати код, який контролюватиме обмін коштами, і розробити програми, доступні звідусіль.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoins",
  "staking": "Стейкінг",
  "summary": "Огляд",
  "terms-of-use": "Умови використання",
  "transaction-fees": "Що таке комісія за транзакції?",
  "translation-banner-body-new": "Ця сторінка відображається англійською мовою, оскільки ми ще не переклали її. Допоможіть нам у цьому.",
  "translation-banner-body-update": "Ми оновили контент на цій сторінці, але наразі нова версія доступна лише англійською мовою. Допоможіть нам її перекласти.",
  "translation-banner-button-join-translation-program": "Приєднатися до програми перекладів",
  "translation-banner-button-learn-more": "Докладніше",
  "translation-banner-button-see-english": "Читати англійською",
  "translation-banner-button-translate-page": "Перекласти сторінку",
  "translation-banner-title-new": "Допоможіть перекласти цю сторінку",
  "translation-banner-title-update": "Допоможіть оновити цю сторінку",
  "translation-program": "Translation Program",
  "translation-progress": "Перекладено",
  "tutorials": "Навчальні посібники",
  "uniswap-logo": "Uniswap logo",
  "use": "Використання",
  "use-ethereum": "Використання Ethereum",
  "use-ethereum-menu": "Використання меню Ethereum",
  "vision": "Бачення",
  "wallets": "Гаманці",
  "website-last-updated": "Останнє оновлення веб-сайту",
  "what-is-ether": "Що таке Ether (ETH)?",
  "what-is-ethereum": "Що таке Ethereum?",
  "whitepaper": "Основне",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Надіслати запит на доступ до функції",
  "page-about-h3": "Робота в процесі",
  "page-about-h3-1": "Реалізовані функції",
  "page-about-h3-2": "Заплановані функції",
  "page-about-li-1": "у процесі",
  "page-about-li-2": "заплановано",
  "page-about-li-3": "реалізовано",
  "page-about-li-4": "реалізовано",
  "page-about-link-1": "Вихідний код цього репозиторію має ліцензію MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Переглянути повний список завдань, що виконуються, на GitHub",
  "page-about-link-4": "Приєднуйтеся до нашого сервера Discord",
  "page-about-link-5": "Зв’яжіться з нами у Твіттері",
  "page-about-link-6": "Переглянути повний список виконаних завдань на GitHub",
  "page-about-link-7": "Повідомити про проблему на GitHub",
  "page-about-p-1": "З моменту запуску ethereum.org ми прагнемо забезпечувати прозорість нашої роботи. Це одна з наших основних цінностей, адже ми віримо, що прозорість має вирішальне значення для успіху Ethereum.",
  "page-about-p-2": "Ми використовуємо",
  "page-about-p-3": "як основний інструмент керування. Ми розділили завдання на 3 категорії:",
  "page-about-p-4": "  Ми робимо все можливе, щоб спільнота завжди була в курсі статусу того чи іншого завдання.",
  "page-about-p-5": "Завдання, які ми виконуємо.",
  "page-about-p-6": "Завдання на черзі.",
  "page-about-p-7": "Нещодавно завершені завдання.",
  "page-about-p-8": "Маєте ідею, як покращити ethereum.org? Ми залюбки з вами співпрацюватимемо!",
  "page-assets-bazaar": "Ethereum Bazaar",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Створення блоків",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "DOGE у децентралізованих програмах",
  "page-assets-download-artist": "Виконавець:",
  "page-assets-download-download": "Завантажити",
  "page-assets-enterprise": "Ethereum для корпоративного сектора",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "Diamond ETH (колір)",
  "page-assets-eth-diamond-glyph": "Diamond ETH (гліф)",
  "page-assets-eth-diamond-gray": "Diamond ETH (сірий)",
  "page-assets-eth-diamond-purple": "Diamond ETH (фіолетовий)",
  "page-assets-eth-diamond-white": "Diamond ETH (білий)",
  "page-assets-eth-glyph-video-dark": "Відео гліфів ETH (темний)",
  "page-assets-eth-glyph-video-light": "Відео гліфів ETH (світлий)",
  "page-assets-eth-logo-landscape-gray": "Логотип ETH в альбомному форматі (сірий)",
  "page-assets-eth-logo-landscape-purple": "Логотип ETH в альбомному форматі (фіолетовий)",
  "page-assets-eth-logo-landscape-white": "Логотип ETH в альбомному форматі (білий)",
  "page-assets-eth-logo-portrait-gray": "Логотип ETH у книжному форматі (сірий)",
  "page-assets-eth-logo-portrait-purple": "Логотип ETH у книжному форматі (фіолетовий)",
  "page-assets-eth-logo-portrait-white": "Логотип ETH у книжному форматі (білий)",
  "page-assets-eth-wordmark-gray": "Графічний знак ETH (сірий)",
  "page-assets-eth-wordmark-purple": "Графічний знак ETH (фіолетовий)",
  "page-assets-eth-wordmark-white": "Графічний знак ETH (білий)",
  "page-assets-ethereum-brand-assets": "Елементи бренду Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "Елементи ethereum.org",
  "page-assets-hero": "Герой ethereum.org",
  "page-assets-hero-particles": "Зображення часток ETH",
  "page-assets-historical-artwork": "Історичний витвір",
  "page-assets-illustrations": "Ілюстрації",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Перегляньте й завантажте елементи бренду, ілюстрації та медіафайли Ethereum і ethereum.org.",
  "page-assets-meta-title": "Елементи бренду Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Суцільний фон",
  "page-assets-page-assets-transparent-background": "Прозорий фон",
  "page-assets-robot": "Гаманець робота",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1inch logo",
  "page-dapps-aave-logo-alt": "Aave logo",
  "page-dapps-add-button": "Запропонувати додаток",
  "page-dapps-add-title": "Додати додаток",
  "page-dapps-audius-logo-alt": "Audius logo",
  "page-dapps-augur-logo-alt": "Augur logo",
  "page-dapps-axie-infinity-logo-alt": "Axie Infinity logo",
  "page-dapps-brave-logo-alt": "Brave logo",
  "page-dapps-category-arts": "Мистецтво й мода",
  "page-dapps-category-browsers": "Веб-переглядачі",
  "page-dapps-category-collectibles": "Цифрові колекції",
  "page-dapps-category-competitive": "Змагання",
  "page-dapps-category-computing": "Інструменти для розробників",
  "page-dapps-category-dex": "Обмін токенів",
  "page-dapps-category-investments": "Інвестиції",
  "page-dapps-category-lending": "Кредитування та позики",
  "page-dapps-category-lottery": "Краудфандінг",
  "page-dapps-category-marketplaces": "Торговельні майданчики",
  "page-dapps-category-music": "Музика",
  "page-dapps-category-payments": "Платежі",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Торгівля та прогнозування ринків",
  "page-dapps-category-utilities": "Утиліти",
  "page-dapps-category-worlds": "Віртуальні світи",
  "page-dapps-choose-category": "Оберіть категорію",
  "page-dapps-collectibles-benefits-1-description": "Коли витвори мистецтва токенізуються в мережі Ethereum, ви можете підтвердити своє право власності на них. Шлях витворів мистецтва від створення до потрапляння в руки поточного власника можна відстежити. Це запобігає підробкам.",
  "page-dapps-collectibles-benefits-1-title": "Право власності можна довести",
  "page-dapps-collectibles-benefits-2-description": "Можливість сплачувати за трансляцію музики або купувати твори мистецтва корисна для митців. Завдяки мережу Ethereum потреба в посередниках зменшується. І навіть якщо вони потрібні, витрати на відповідні послуги не такі високі, оскільки платформам не потрібно сплачувати за інфраструктуру мережі.",
  "page-dapps-collectibles-benefits-2-title": "Більше користі для авторів",
  "page-dapps-collectibles-benefits-3-description": "Токенізовані колекції прив’язуються до адреси Ethereum, а не до платформи. Тому ви можете продавати такі речі, як ігрові елементи на будь-якому торговельному майданчику Ethereum, а не лише в грі.",
  "page-dapps-collectibles-benefits-3-title": "Колекції залишаються з вами",
  "page-dapps-collectibles-benefits-4-description": "Ви можете токенізувати свої витвори мистецтва й продавати їх за допомогою вже створених інструментів і продуктів! Токени можна продавати на будь-якій колекційній платформі Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Готова інфраструктура",
  "page-dapps-collectibles-benefits-description": "Це програми, направлені на роботу з цифровим правом власності. Завдяки цьому потенціал авторів зростає й з’являються нові шляхи для інвестицій у роботи улюблених авторів.",
  "page-dapps-collectibles-benefits-title": "децентралізовані колекції та трансляції",
  "page-dapps-collectibles-button": "Витвори мистецтва й колекції",
  "page-dapps-collectibles-description": "Це програми, направлені на роботу з цифровим правом власності. Завдяки цьому потенціал авторів зростає й з’являються нові шляхи для інвестицій у роботи улюблених авторів.",
  "page-dapps-collectibles-title": "Децентралізовані витвори мистецтва й колекції",
  "page-dapps-compound-logo-alt": "Compound logo",
  "page-dapps-cryptopunks-logo-alt": "CryptoPunks logo",
  "page-dapps-cryptovoxels-logo-alt": "Cryptovoxels logo",
  "page-dapps-dapp-description-1inch": "Допомагає уникнути різкого спаду цін, агрегуючи найкращі з них.",
  "page-dapps-dapp-description-aave": "Позичте свої токени, щоб заробити відсотки й вивести їх будь-коли.",
  "page-dapps-dapp-description-async-art": "#ProgrammableArt: створюйте, збирайте й продавайте цифрові картини, розділені на компоненти, які можна використовувати для впливу на все зображення. Кожен витвір мистецтва й кожен його компонент є токеном ERC721.",
  "page-dapps-dapp-description-audius": "Децентралізована платформа для трансляцій. Прослуховування приносять гроші авторам, а не лейблам.",
  "page-dapps-dapp-description-augur": "Робіть ставки на результати спортивних матчів, подій у сфері економіки та світових подій.",
  "page-dapps-dapp-description-axie-infinity": "Торгуйте войовничими створіннями Аксі. Заробляйте гроші, граючи в ігри (доступно на мобільних пристроях)",
  "page-dapps-dapp-description-brave": "Заробляйте токени за дії в Інтернеті й підтримуйте улюблених авторів.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Позичте свої токени, щоб заробити відсотки й вивести їх будь-коли.",
  "page-dapps-dapp-description-cryptopunks": "Купуйте, робіть ставки й продавайте CryptoPunks – одні з перших колекційних екземплярів у мережі Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Створюйте мистецькі галереї, відкривайте магазини й купуйте земельні ділянки у віртуальному світі Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Завойовуйте планети в безмежному криптографічному всесвіті, для створення якого кожна деталь бралася до уваги.",
  "page-dapps-dapp-description-decentraland": "Збирайте й продавайте земельні ділянки в цікавому віртуальному світі.",
  "page-dapps-dapp-description-dydx": "Відкривайте короткі позиції або позиції за кредитні кошти й отримуйте в 10 разів більше. Доступні також кредитування й позики.",
  "page-dapps-dapp-description-ens": "Зручні імена для адрес Ethereum і децентралізовані сайти.",
  "page-dapps-dapp-description-foundation": "Інвестуйте в унікальні цифрові витвори мистецтва й торгуйте ними з іншими покупцями.",
  "page-dapps-dapp-description-gitcoin": "Заробляйте криптовалюту за допомогою програмного забезпечення з відкритим кодом.",
  "page-dapps-dapp-description-gitcoin-grants": "Краудфандинг для проектів спільноти Ethereum із посиленими внесками",
  "page-dapps-dapp-description-gods-unchained": "Стратегічна торгова карткова гра. Заробляйте карти, граючи в продажі так, як ви продавали б у реальному житті.",
  "page-dapps-dapp-description-golem": "Отримайте доступ до спільних обчислювальних машин або орендуйте власні ресурси.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Торговельна платформа P2P для швидкої роботи.",
  "page-dapps-dapp-description-marble-cards": "Створюйте унікальні цифрові картки на основі посилань і торгуйте ними.",
  "page-dapps-dapp-description-matcha": "Перевіряє кілька бірж, щоб виявляти найкращі ціни.",
  "page-dapps-dapp-description-nifty-gateway": "Купуйте роботи найпопулярніших митців, спортсменів, брендів і авторів прямо в мережі.",
  "page-dapps-dapp-description-oasis": "Торгуйте, позичайте й зберігайте за допомогою Dai – стейблкойну Ethereum.",
  "page-dapps-dapp-description-opensea": "Купуйте, продавайте, знаходьте та торгуйте товарами, кількість яких обмежено.",
  "page-dapps-dapp-description-opera": "Надсилайте криптовалюту зі свого веб-переглядача продавцям, іншим користувачам і додаткам.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Робіть ставки на результати. Торгуйте на інформаційних ринках.",
  "page-dapps-dapp-description-pooltogether": "Лотерея, у якій неможливо програти. Призи щотижня.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Створюйте, продавайте й купуйте токенізовані колекційні предмети.",
  "page-dapps-dapp-description-sablier": "Переказуйте гроші в режимі реального часу.",
  "page-dapps-dapp-description-superrare": "Купуйте цифрові витвори мистецтва безпосередньо в митців або на вторинних ринках.",
  "page-dapps-dapp-description-token-sets": "Криптографічні інвестиційні стратегії можна автоматично заново збалансувати.",
  "page-dapps-dapp-description-tornado-cash": "Надсилайте анонімні транзакції в мережі Ethereum.",
  "page-dapps-dapp-description-uniswap": "Легко обмінюйте токени або надавайте їх за винагороди у відсотках.",
  "page-dapps-dark-forest-logo-alt": "Dark Forest logo",
  "page-dapps-decentraland-logo-alt": "Decentraland logo",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Знайдіть потрібну програму Ethereum.",
  "page-dapps-doge-img-alt": "Ілюстрація собаки за комп’ютером",
  "page-dapps-dydx-logo-alt": "dYdX logo",
  "page-dapps-editors-choice-dark-forest": "Змагайтеся з іншими, завойовуйте планети та випробуйте найсучаснішу технологію масштабування та забезпечення конфіденційності Ethereum. Можливо, вона підійде для тих, хто вже працював із мережею Ethereum.",
  "page-dapps-editors-choice-description": "Кілька улюблених децентралізованих програм команди ethereum.org. Нижче можна дізнатися про інші.",
  "page-dapps-editors-choice-foundation": "Інвестуйте в культуру. Купуйте й продавайте унікальні цифрові витвори мистецтва й елементи моди від талановитих митців, музикантів і брендів.",
  "page-dapps-editors-choice-header": "Вибір редакції",
  "page-dapps-editors-choice-pooltogether": "Купіть квиток на лотерею, у якій неможливо програти. Щотижня відсотки, що генеруються з усього пулу квитків, надсилаються одному щасливому переможцю. Повертайте гроші, коли вам завгодно.",
  "page-dapps-editors-choice-uniswap": "Легко обмінюйте токени. Це улюблена програма спільноти, оскільки в ній можна торгувати токенами з користувачами мережі.",
  "page-dapps-ens-logo-alt": "Ethereum Name Service logo",
  "page-dapps-explore-dapps-description": "Багато програм поки що експериментальні й перебувають на етапі тестування можливостей децентралізованих мереж. Однак у галузях технологій, фінансів, ігор та колекціонування вже є приклади їх успішного використання.",
  "page-dapps-explore-dapps-title": "Огляд програм",
  "page-dapps-features-1-description": "Код децентралізованої програми неможливо вилучити з мережі Ethereum, а її функції доступні всім користувачам. Навіть якщо команду, яка створила децентралізовану програму, розформовано, ви й далі можете нею користуватися. Щойно її код буде додано в мережу Ethereum, скасувати зміни не вдасться.",
  "page-dapps-features-1-title": "Немає власників",
  "page-dapps-features-2-description": "Ви не можете втратити право використовувати децентралізовану програму або надсилати транзакції. Наприклад, якби Твіттер був у мережі Ethereum, ніхто б не зміг заблокувати ваш акаунт або заборонити вам розміщувати твіти.",
  "page-dapps-features-2-title": "Ніякої цензури",
  "page-dapps-features-3-description": "Оскільки Ethereum має ETH, платежі здійснюються безпосередньо в мережі Ethereum. Розробникам не потрібно витрачати час на інтеграцію зі сторонніми постачальниками платіжних послуг.",
  "page-dapps-features-3-title": "Вбудовані платежі",
  "page-dapps-features-4-description": "Код децентралізованих програм часто відкритий і сумісний за умовчанням. Команди часто використовують у ньому роботи інших команд. Якщо ви хочете дозволити користувачам обмінювати токени у своїй децентралізованій програмі, то можете просто використати код іншої програми.",
  "page-dapps-features-4-title": "Підключайтеся й грайте",
  "page-dapps-features-5-description": "У більшості децентралізованих програм ви не маєте розкривати справжню особистість. Для доступу до акаунту Ethereum потрібен лише логін і гаманець.",
  "page-dapps-features-5-title": "Один анонімний логін",
  "page-dapps-features-6-description": "Криптографія гарантує, що зловмисники не зможуть виконувати транзакції й інші дії з децентралізованою програмою від вашого імені. Ви самі підтверджуєте дії в акаунті Ethereum (як правило, через гаманець), тому радимо тримати свої облікові дані в надійному місці.",
  "page-dapps-features-6-title": "Захищено криптографією",
  "page-dapps-features-7-description": "Децентралізована програма, запущена в мережі Ethereum, перестане працювати, лише якщо перестати працювати сама мережа. Глобальні мережі на кшталт Ethereum надзвичайно важко атакувати.",
  "page-dapps-features-7-title": "Безкінечна робота",
  "page-dapps-finance-benefits-1-description": "Фінансові сервіси, що працюють у мережі Ethereum, не мають вимог щодо реєстрації. Якщо у вас є кошти й з’єднання з Інтернетом, ви можете починати роботу.",
  "page-dapps-finance-benefits-1-title": "Відкритий доступ",
  "page-dapps-finance-benefits-2-description": "Існує дуже велика кількість токенів, з якими ви можете взаємодіяти в цих фінансових продуктах. Крім того, користувачі постійно створюють нові токени в мережі Ethereum.",
  "page-dapps-finance-benefits-2-title": "Нова економіка з використанням токенів",
  "page-dapps-finance-benefits-3-description": "Команди створили \"стабільні монети\" – стабільнішу криптовалюту. Вони дають змогу експериментувати й використовувати криптовалюту без ризиків і невизначеності.",
  "page-dapps-finance-benefits-3-title": "Стабільні монети",
  "page-dapps-finance-benefits-4-description": "Усі фінансові продукти в мережі Ethereum модульні й сумісні між собою. Нові конфігурації цих модулів весь час з’являються на ринку, розширюючи коло можливостей взаємодії з криптовалютою.",
  "page-dapps-finance-benefits-4-title": "Взаємозв’язані фінансові сервіси",
  "page-dapps-finance-benefits-description": "Що саме в мережі Ethereum дає змогу децентралізованим фінансовим програмам процвітати?",
  "page-dapps-finance-benefits-title": "децентралізовані фінанси",
  "page-dapps-finance-button": "Фінанси",
  "page-dapps-finance-description": "Це програми, націлені на надання фінансових послуг за допомогою криптовалют. Вони мають переваги кредитування, позик, отримання відсотків і використання конфіденційних платежів, оскільки для цього не потрібні особисті дані.",
  "page-dapps-finance-title": "Децентралізовані фінанси",
  "page-dapps-foundation-logo-alt": "Foundation logo",
  "page-dapps-gaming-benefits-1-description": "Не важливо, що це – віртуальні земельні ділянки чи торгові картки. Ваші предмети можна продавати на колекційних ринках. Ігрові предмети мають реальну цінність.",
  "page-dapps-gaming-benefits-1-title": "Цінність ігрових предметів подвоюється, якщо використовувати їх як токени",
  "page-dapps-gaming-benefits-2-description": "Ви володієте ігровими предметами (іноді – прогресом), але не компаніями з розробки ігор. Сам тому ви нічого не втратите, якщо гру такої компанії атакують, перестане працювати сервер або сама компанія розпадеться.",
  "page-dapps-gaming-benefits-2-title": "Ваші заощадження в безпеці",
  "page-dapps-gaming-benefits-3-description": "Таким же чином платежі в мережі Ethereum доступні всім для перевірки. Ігри можуть використовувати цю функцію для забезпечення справедливості. Теоретично все можна перевірити – від кількості критичних ударів до розміру воєнної скрині противника.",
  "page-dapps-gaming-benefits-3-title": "Доказова справедливість",
  "page-dapps-gaming-benefits-description": "Що саме в мережі Ethereum дає змогу децентралізованим іграм процвітати?",
  "page-dapps-gaming-benefits-title": "децентралізовані ігри",
  "page-dapps-gaming-button": "Ігри",
  "page-dapps-gaming-description": "Це програми, націлені на створення віртуальних світів і змагання з іншими гравцями за допомогою колекційних предметів, що мають реальну цінність.",
  "page-dapps-gaming-title": "Децентралізовані ігри",
  "page-dapps-get-some-eth-description": "За дії в децентралізованих програмах потрібно сплачувати комісію за транзакції",
  "page-dapps-get-started-subtitle": "Щоб скористатися децентралізованою програмою, потрібно мати гаманець і певну кількість ETH. Гаманець дасть змогу підключити програму або ввійти в неї, а ETH допоможуть сплатити комісії за транзакції.",
  "page-dapps-get-started-title": "Початок роботи",
  "page-dapps-gitcoin-grants-logo-alt": "Gitcoin Grants logo",
  "page-dapps-gitcoin-logo-alt": "Gitcoin logo",
  "page-dapps-gods-unchained-logo-alt": "Gods Unchained logo",
  "page-dapps-golem-logo-alt": "Golem logo",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Інструменти й сервіси Ethereum",
  "page-dapps-hero-subtitle": "Децентралізовані програми – це програми на основі технологій Ethereum, що допомагають руйнувати старі бізнес-моделі й створювати нові.",
  "page-dapps-how-dapps-work-p1": "Децентралізовані програми мають власний внутрішній код (розумні контракти) і працюють у децентралізованій мережі, а не на централізованому сервері. Вони використовують блокчейн Ethereum для зберігання даних і розумних контрактів.",
  "page-dapps-how-dapps-work-p2": "Розумний контракт - це ніби набір загальнодоступних правил у мережі, яка функціонує відповідно до цих правил. Уявіть торговий автомат. Якщо мати достатньо коштів і робити правильний вибір, ви обов’язково отримаєте потрібний предмет. Так само, як і торгові автомати, розумні контракти можуть містити кошти (як ваш акаунт Ethereum). Це дає змогу коду керувати угодами й транзакціями.",
  "page-dapps-how-dapps-work-p3": "У децентралізовані програми, запущені в мережі Ethereum, неможливо внести зміни. Такі програми децентралізовані, оскільки працюють за принципом, прописаним у контракті, а не створеним людиною чи компанією.",
  "page-dapps-how-dapps-work-title": "Як працюють децентралізовані програми",
  "page-dapps-learn-callout-button": "Почати",
  "page-dapps-learn-callout-description": "Наш спільний портал для розробників містить документи, інструменти й фреймворки, які допоможуть вам створити децентралізовану програму.",
  "page-dapps-learn-callout-image-alt": "Ілюстрація руки, що будує символ ETH із цеглинок Lego.",
  "page-dapps-learn-callout-title": "Як створити децентралізовану програму",
  "page-dapps-loopring-logo-alt": "Loopring logo",
  "page-dapps-magic-behind-dapps-description": "Децентралізовані програми схожі на звичайні. Однак вони мають особливі функції завдяки перевагам мережі Ethereum. Ось що відрізняє децентралізовані програми від звичайних.",
  "page-dapps-magic-behind-dapps-link": "У чому переваги мережі Ethereum?",
  "page-dapps-magic-behind-dapps-title": "Особливі переваги децентралізованих додатків",
  "page-dapps-magic-title-1": "Особливі",
  "page-dapps-magic-title-2": "переваги",
  "page-dapps-magician-img-alt": "Ілюстрація магів",
  "page-dapps-marble-cards-logo-alt": "marble.cards logo",
  "page-dapps-matcha-logo-alt": "Matcha logo",
  "page-dapps-mobile-options-header": "Пошукайте в іншій категорії",
  "page-dapps-nifty-gateway-logo-alt": "Nifty Gateway logo",
  "page-dapps-oasis-logo-alt": "Oasis logo",
  "page-dapps-opensea-logo-alt": "OpenSea logo",
  "page-dapps-opera-logo-alt": "Opera logo",
  "page-dapps-polymarket-logo-alt": "Polymarket logo",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "PoolTogether logo",
  "page-dapps-rarible-logo-alt": "Rarible logo",
  "page-dapps-ready-button": "ОК",
  "page-dapps-ready-description": "Виберіть потрібну децентралізовану програму",
  "page-dapps-ready-title": "Готові?",
  "page-dapps-sablier-logo-alt": "Sablier logo",
  "page-dapps-set-up-a-wallet-button": "Знайти гаманець",
  "page-dapps-set-up-a-wallet-description": "Гаманець - це ваш логін для входу в децентралізовану програму",
  "page-dapps-set-up-a-wallet-title": "Налаштуйте гаманець",
  "page-dapps-superrare-logo-alt": "SuperRare logo",
  "page-dapps-technology-button": "Технології",
  "page-dapps-technology-description": "Це програми, націлені на децентралізацію інструментів розробників, включення криптоекономічних систем в існуючі технології та створення торговельних майданчиків для роботи з відкритим вихідним кодом.",
  "page-dapps-technology-title": "Децентралізована технологія",
  "page-dapps-token-sets-logo-alt": "Token Sets logo",
  "page-dapps-tornado-cash-logo-alt": "Tornado cash logo",
  "page-dapps-uniswap-logo-alt": "Uniswap logo",
  "page-dapps-wallet-callout-button": "Знайти гаманець",
  "page-dapps-wallet-callout-description": "Гаманці – це також децентралізовані програми. Знайдіть його на основі функцій, які вас цікавлять.",
  "page-dapps-wallet-callout-image-alt": "Ілюстрація робота.",
  "page-dapps-wallet-callout-title": "Перегляньте гаманці",
  "page-dapps-warning-header": "Завжди власноруч перевіряйте інформацію",
  "page-dapps-warning-message": "Ethereum – це нова технологія, тому більшість програм є новими. Перш ніж вкласти велику суму грошей, переконайтеся, що ви розумієте ризики.",
  "page-dapps-what-are-dapps": "Що таке децентралізовані програми?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Акаунти",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Для досвідчених розробників",
  "docs-nav-backend-apis": "API серверної частини",
  "docs-nav-block-explorers": "Посібники щодо блоків",
  "docs-nav-blocks": "Блоки",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Компільовані розумні контракти",
  "docs-nav-composability": "Компонованість",
  "docs-nav-consensus-mechanisms": "Механізми консенсусу",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Дані й аналітика",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Упровадження розумних контрактів",
  "docs-nav-development-frameworks": "Платформи для розробки",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Мережі для розробки",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API клієнта Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Стековий список Ethereum",
  "docs-nav-evm": "Віртуальна машина Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Основоположні теми",
  "docs-nav-gas": "Gas",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Інтегровані середовища розробки (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Про децентралізовані програми",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Про Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Про стек",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "JavaScript API",
  "docs-nav-javascript": "JavaScript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Майнінг",
  "docs-nav-networks": "Мережі",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Вузли й клієнти",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Вузли як сервіс",
  "docs-nav-oracles": "Оракули",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Мови програмування",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Доказ частки",
  "docs-nav-proof-of-work": "Доказ виконаної роботи",
  "docs-nav-python": "Python",
  "docs-nav-readme": "ІНСТРУКЦІЯ",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Масштабування",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Безпека",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Будова розумних контрактів",
  "docs-nav-smart-contract-languages": "Мови розумних контрактів",
  "docs-nav-smart-contracts": "Розумні контракти",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Бібліотеки розумних контрактів",
  "docs-nav-standards": "Стандарти",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Сховище",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Тестування розумних контрактів",
  "docs-nav-token-standards": "Стандарти для токенів",
  "docs-nav-transactions": "Транзакції",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Різниця між Web2 та Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Якщо ви добре розумієтеся на темі й хочете зробити внесок, відредагуйте цю сторінку й поділіться своїми знаннями.",
  "page-calltocontribute-desc-2": "Ми будемо вас вдячні від імені всієї спільноти Ethereum!",
  "page-calltocontribute-desc-3": "Використайте цей гнучкий",
  "page-calltocontribute-desc-4": "Виникли запитання? Зв’яжіться з нами в каналі #контенту на нашому",
  "page-calltocontribute-link": "шаблон для документів",
  "page-calltocontribute-link-2": "сервері Discord",
  "page-calltocontribute-span": "Виправити сторінку",
  "page-calltocontribute-title": "Допоможіть нам покращити цю сторінку",
  "page-developer-meta-title": "Ресурси для розробників у мережі Ethereum",
  "page-developers-about": "Про ці ресурси для розробників",
  "page-developers-about-desc": "Сайт ethereum.org містить основну документацію та стек технологій для розробки, які допоможуть вам програмувати з Ethereum. Для початку можете переглянути наші навчальні посібники.",
  "page-developers-about-desc-2": "Узявши за приклад центр веб-документації Mozilla, ми створили для Ethereum сайт із корисними ресурсами й контентом для розробників. Усі наші технології мають відкритий код, тож їх можна сміливо доповнювати й покращувати.",
  "page-developers-account-desc": "Контракти або люди в мережі",
  "page-developers-accounts-link": "Облікові записи",
  "page-developers-advanced": "Для досвідчених розробників",
  "page-developers-api-desc": "Як взаємодіяти зі смарт-контрактами за допомогою бібліотек",
  "page-developers-api-link": "API серверної частини",
  "page-developers-aria-label": "Меню розробника",
  "page-developers-block-desc": "Пакети транзакцій, додані до блокчейну",
  "page-developers-block-explorers-desc": "Ваш доступ до даних Ethereum",
  "page-developers-block-explorers-link": "Переглядач блоків",
  "page-developers-blocks-link": "Блоки",
  "page-developers-browse-tutorials": "Переглянути навчальні посібники",
  "page-developers-choose-stack": "Виберіть стек",
  "page-developers-contribute": "Зробити внесок",
  "page-developers-dev-env-desc": "Інтегровані середовища, які підходять для розробки децентралізованих програм",
  "page-developers-dev-env-link": "Середовища розробки",
  "page-developers-discord": "Приєднатися до Discord",
  "page-developers-docs-introductions": "Вступ",
  "page-developers-evm-desc": "Комп'ютер, який обробляє операції",
  "page-developers-evm-link": "Віртуальна машина Ethereum (EVM)",
  "page-developers-explore-documentation": "Переглянути документацію",
  "page-developers-feedback": "Щоб залишити відгук, зв'яжіться з нами через GitHub або сервер Discord.",
  "page-developers-frameworks-desc": "Інструменти для прискорення розробки",
  "page-developers-frameworks-link": "Платформи для розробки",
  "page-developers-fundamentals": "Основи",
  "page-developers-gas-desc": "Для обробки транзакцій потрібен Ether",
  "page-developers-gas-link": "Gas",
  "page-developers-get-started": "Як ви хочете почати роботу?",
  "page-developers-improve-ethereum": "Допоможіть нам покращувати сайт ethereum.org",
  "page-developers-improve-ethereum-desc": "Як і контент на ethereum.org, ці документи створюють учасники нашої спільноти. Якщо ви бачите помилку, можливість щось покращити або допомогти розробникам Ethereum, створіть pull request.",
  "page-developers-into-eth-desc": "Про блокчейн і Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Про децентралізовані програми",
  "page-developers-intro-dapps-link": "Про децентралізовані програми",
  "page-developers-intro-eth-link": "Про Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Про стек",
  "page-developers-intro-stack-desc": "Про стек Ethereum",
  "page-developers-js-libraries-desc": "Як взаємодіяти зі смарт-контрактами за допомогою JavaScript",
  "page-developers-js-libraries-link": "Бібліотеки JavaScript",
  "page-developers-language-desc": "Як використовувати Ethereum зі знайомими мовами",
  "page-developers-languages": "Мови програмування",
  "page-developers-learn": "Учіться програмувати з Ethereum",
  "page-developers-learn-desc": "Перегляньте наші документи, щоб розібратися з основними поняттями та стеком Ethereum",
  "page-developers-learn-tutorials": "Навчайтесь за допомогою посібників",
  "page-developers-learn-tutorials-cta": "Переглянути посібники",
  "page-developers-learn-tutorials-desc": "Покроково вчіться програмувати з Ethereum у досвідчених розробників.",
  "page-developers-meta-desc": "Документація, навчальні посібники та інструменти для розробки з Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Як створюються нові блоки й досягається консенсус",
  "page-developers-mining-link": "Майнінг",
  "page-developers-networks-desc": "Огляд основної й тестової мереж",
  "page-developers-networks-link": "Мережі",
  "page-developers-node-clients-desc": "Як блоки й транзакції перевіряються в мережі",
  "page-developers-node-clients-link": " Вузли й клієнти",
  "page-developers-oracle-desc": "Як отримувати дані офчейн у смарт-контракти",
  "page-developers-oracles-link": "Оракули",
  "page-developers-play-code": "Грати з кодом",
  "page-developers-read-docs": "Читати документи",
  "page-developers-scaling-desc": "Рішення для швидших транзакцій",
  "page-developers-scaling-link": "Масштабування",
  "page-developers-smart-contract-security-desc": "Заходи безпеки, яких потрібно вживати під час розробки",
  "page-developers-smart-contract-security-link": "Безпека",
  "page-developers-set-up": "Налаштування локального середовища",
  "page-developers-setup-desc": "Налаштуйте середовище розробки, щоб підготувати стек до програмування.",
  "page-developers-smart-contracts-desc": "В основі децентралізованих програм — угоди, які виконуються самостійно",
  "page-developers-smart-contracts-link": "Смарт-контракти",
  "page-developers-stack": "Стек",
  "page-developers-start": "Поекспериментуйте",
  "page-developers-start-desc": "Хочете спершу поекспериментувати, а потім ставити запитання?",
  "page-developers-storage-desc": "Як керувати сховищем децентралізованої програми",
  "page-developers-storage-link": "Сховище",
  "page-developers-subtitle": "Посібник із програмування на Ethereum. Створено розробниками для розробників.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "розробник",
  "page-developers-title-3": "ресурси",
  "page-developers-token-standards-desc": "Огляд прийнятих стандартів для токенів",
  "page-developers-token-standards-link": "Стандарти для токенів",
  "page-developers-transactions-desc": "Як змінюється стан Ethereum",
  "page-developers-transactions-link": "Транзакції",
  "page-developers-web3-desc": "Чим відрізняються інструменти для розробки web3",
  "page-developers-web3-link": "Різниця між Web2 та Web3",
  "page-learning-tools-bootcamps": "Посібники для розробників",
  "page-learning-tools-bootcamps-desc": "Платні онлайн-курси, які швидко підготують вас до роботи.",
  "page-learning-tools-browse-docs": "Переглянути документи",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "ChainShot logo",
  "page-learning-tools-coding": "Кодуй і навчайся",
  "page-learning-tools-coding-subtitle": "Ці інструменти дадуть змогу експериментувати з мережею Ethereum, якщо ви віддаєте перевагу більш інтерактивному навчанню.",
  "page-learning-tools-consensys-academy-description": "Онлайн-посібник для розробників Ethereum.",
  "page-learning-tools-consensys-academy-logo-alt": "ConsenSys Academy logo",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Вивчайте Solidity на прикладі створення гри про зомбі.",
  "page-learning-tools-cryptozombies-logo-alt": "CryptoZombies logo",
  "page-learning-tools-documentation": "Навчайтеся за допомогою документів",
  "page-learning-tools-documentation-desc": "Хочете дізнатися більше? Перегляньте наші документи, щоб знайти потрібні пояснення.",
  "page-learning-tools-eth-dot-build-description": "Навчальна пісочниця для Web3, що включає програмування перетягуванням і блоки з відкритим кодом.",
  "page-learning-tools-eth-dot-build-logo-alt": "Eth.build logo",
  "page-learning-tools-ethernauts-description": "Проходьте рівні, зламуючи розумні контракти.",
  "page-learning-tools-ethernauts-logo-alt": "Ethernauts logo",
  "page-learning-tools-game-tutorials": "Інтерактивні ігрові посібники",
  "page-learning-tools-game-tutorials-desc": "Грайте й навчайтеся. Ці посібники допоможуть вам зрозуміти основи під час гри.",
  "page-learning-tools-meta-desc": "Веб-інструменти кодування та інтерактивне навчання, які допоможуть вам експериментувати з розробкою мережі Ethereum.",
  "page-learning-tools-meta-title": "Навчальні інструменти для розробників",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Розробляйте й упроваджуйте розумні контракти для мережі Ethereum, а також керуйте ними. Скористайтеся посібниками за допомогою плагіна Learneth.",
  "page-learning-tools-remix-description-2": "Remix – це не просто пісочниця. Багато розробників пишуть, компілюють і впроваджують розумні контракти за допомогою інструмента Remix.",
  "page-learning-tools-remix-logo-alt": "Remix logo",
  "page-learning-tools-sandbox": "Пісочниці для кодування",
  "page-learning-tools-sandbox-desc": "Ці пісочниці дозволять вам експериментувати з написанням розумних контрактів і розумінням мережі Ethereum.",
  "page-learning-tools-studio-description": "Інтегроване веб-середовище розробки, де можна знайти посібники зі створення та тестування розумних контрактів, а також створити для користувацький інтерфейс.",
  "page-learning-tools-vyperfun-description": "Вивчайте Vyper на прикладі створення власної гри Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Vyper.fun logo",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Фреймворк для розробки й тестування на основі Python для розумних контрактів, націлених на Віртуальну машину Ethereum.",
  "page-local-environment-brownie-logo-alt": "Brownie logo",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Універсальна платформа для розробників для створення та впровадження децентралізованих програм.",
  "page-local-environment-embark-logo-alt": "Embark logo",
  "page-local-environment-epirus-desc": "Платформа для розробки, впровадження та відстеження блокчейн-програм у Віртуальній машині Java",
  "page-local-environment-epirus-logo-alt": "Epirus logo",
  "page-local-environment-eth-app-desc": "Створюйте програми на основі Ethereum за допомогою однієї команди. Містить велику кількість фреймворків інтерфейсу та шаблонів DeFi.",
  "page-local-environment-eth-app-logo-alt": "Create Eth App logo",
  "page-local-environment-framework-feature-1": "Функції для створення локальної системи блокчейнів.",
  "page-local-environment-framework-feature-2": "Утиліти для компіляції і тестування розумних контрактів.",
  "page-local-environment-framework-feature-3": "Додатки до розробки клієнта для створення користувацьких програм у межах одного проекту/репозиторію.",
  "page-local-environment-framework-feature-4": "Конфігурація для підключення до мереж Ethereum і впровадження контрактів для локально запущеного блокчейну або для загальнодоступних мереж Ethereum.",
  "page-local-environment-framework-feature-5": "Розподіл децентралізованих програм – інтеграція з параметрами сховища, такими як IPFS.",
  "page-local-environment-framework-features": "Ці фреймворки мають багато незвичайних функцій (приклади наведено нижче).",
  "page-local-environment-frameworks-desc": " Радимо обрати фреймворк, особливо якщо ви тільки починаєте. Створення повноцінної децентралізованої програми потребує різних технологій. Фреймворки містять багато корисних функцій або надають прості системи плагінів, де можна вибрати потрібні інструменти.",
  "page-local-environment-frameworks-title": "Фреймворки й заготовлені стеки",
  "page-local-environment-hardhat-desc": "Hardhat – це середовище розробки Ethereum для професіоналів.",
  "page-local-environment-hardhat-logo-alt": "Hardhat logo",
  "page-local-environment-openZeppelin-desc": "Заощаджуйте час, який витрачається на розробку, шляхом компіляції, оновлення та впровадження розумних контрактів, а також взаємодії з ними за допомогою CLI.",
  "page-local-environment-openZeppelin-logo-alt": "OpenZeppelin logo",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App – це все, що вам потрібно, щоб почати створювати децентралізовані програми, які працюють на основі розумних контрактів",
  "page-local-environment-scaffold-eth-logo-alt": "scaffold-eth logo",
  "page-local-environment-setup-meta-desc": "Дізнайтесь, як обрати програмний стек для розробки Ethereum.",
  "page-local-environment-setup-meta-title": "Налаштування локальної розробки Ethereum",
  "page-local-environment-setup-subtitle": "Якщо ви готові почати, оберіть стек.",
  "page-local-environment-setup-subtitle-2": " Ось інструменти та фреймворки, які ви можете використовувати для створення власної програми в мережі Ethereum.",
  "page-local-environment-setup-title": "Налаштування локального середовища розробки",
  "page-local-environment-solidity-template-desc": "Шаблон GitHub для попередньо вбудованих налаштувань розумних контрактів мовою Solidity. Включає локальну мережу Hardhat, Waffle для тестування, Ether для запуску гаманця тощо.",
  "page-local-environment-solidity-template-logo-alt": "Solidity template logo",
  "page-local-environment-truffle-desc": "Набір інструментів Truffle Suite допомагає розробникам легко втілити ідею в реальну децентралізовану програму.",
  "page-local-environment-truffle-logo-alt": "Truffle logo",
  "page-local-environment-waffle-desc": "Найдосконаліша тестова бібліотека для розумних контрактів. Використовуйте окремо або разом із Scaffold-eth або Hardhat.",
  "page-local-environment-waffle-logo-alt": "Waffle logo",
  "comp-tutorial-metadata-minute-read": "читається за хвилину",
  "page-tutorial-listing-policy-intro": "Перш ніж надсилати посібник, прочитайте наші правила розміщення статей.",
  "comp-tutorial-metadata-tip-author": "Порада від автора",
  "page-tutorial-listing-policy": "правила розміщення статей",
  "page-tutorial-new-github": "Ще не користувалися GitHub?",
  "page-tutorial-new-github-desc": "Щоб повідомити про проблему, просто заповніть потрібну інформацію та вставте посібник.",
  "page-tutorial-pull-request": "Створіть запит на внесення змін",
  "page-tutorial-pull-request-btn": "Створити запит на внесення змін",
  "page-tutorial-pull-request-desc-1": "Дотримуйтеся вимог",
  "page-tutorial-pull-request-desc-2": "tutorials/your-tutorial-name/index.md",
  "page-tutorial-pull-request-desc-3": "(структура найменування).",
  "page-tutorial-raise-issue-btn": "Повідомити про проблему",
  "page-tutorial-read-time": "хв",
  "page-tutorial-submit-btn": "Надіслати посібник",
  "page-tutorial-submit-tutorial": "Щоб надіслати посібник, потрібно скористатися GitHub. Радимо повідомити про проблему або надіслати запит на внесення змін.",
  "page-tutorial-subtitle": "Ласкаво просимо до нашого спеціального списку посібників спільноти.",
  "page-tutorial-tags-error": "Жоден посібник не містить усіх цих міток",
  "page-tutorial-title": "Посібник із розробки Ethereum",
  "page-tutorials-meta-description": "Перегляньте й відфільтруйте за темою перевірені посібники спільноти Ethereum.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Посібники з розробки Ethereum",
  "page-eth-buy-some": "Хочете купити Ethereum?",
  "page-eth-buy-some-desc": "Ethereum і ETH часто плутають. Ethereum – це блокчейн, а ETH – основний об’єкт блокчейну Ethereum. Скоріш за все, ви хочете придбати ETH.",
  "page-eth-cat-img-alt": "Графік гліфа ETH з калейдоскопом котів",
  "page-eth-collectible-tokens": "Колекційні токени",
  "page-eth-collectible-tokens-desc": "Токени, які представляють собою колекційний ігровий предмет, витвір цифрового мистецтва або інші унікальні об’єкти. Найчастіше вони відомі як незамінні токени (NFT).",
  "page-eth-cryptography": "Захищено криптографією",
  "page-eth-cryptography-desc": "Цифрові гроші – це нове явище, однак їх захищено надійною криптографією. Це дає змогу захистити ваш гаманець, ETH і транзакції. ",
  "page-eth-currency-for-apps": "Це валюта в програмах Ethereum.",
  "page-eth-currency-for-future": "Валюта для нашого цифрового майбутнього",
  "page-eth-description": "ETH – це криптовалюта. Це дефіцитні цифрові гроші, якими можна користуватися в Інтернеті (схожі на Bitcoin). Якщо ви тільки почали працювати з криптовалютами, перегляньте наведену нижче інформацію про те, чим ETH відрізняється від традиційних грошей.",
  "page-eth-earn-interest-link": "Отримуйте відсотки",
  "page-eth-ethhub-caption": "Часто оновлюються",
  "page-eth-ethhub-overview": "На EthHub наведено чудовий огляд. Радимо за бажання його переглянути.",
  "page-eth-flexible-amounts": "Доступно в гнучких сумах",
  "page-eth-flexible-amounts-desc": "ETH можна поділити на 18 десяткових чисел, тому вам не потрібно купувати 1 цілий ETH. За бажання ви можете купити фракції за один раз. Їх мінімальний розмір може становити 0.00000000000001 ETH.",
  "page-eth-fuels": "ETH забезпечує роботу мережі Ethereum і захищає її",
  "page-eth-fuels-desc": "ETH – це основа Ethereum. Коли ви надсилаєте ETH або використовуєте програму Ethereum, то сплачуєте невелику комісію в ETH за використання мережі Ethereum. Ця комісія заохочує майнерів до обробки й перевірки того, що ви намагаєтеся зробити.",
  "page-eth-fuels-desc-2": "Майнери – це реєстратори Ethereum. Вони проводять перевірки й попереджають випадки шахрайства. Майнери, які цим займаються, також отримують нові ETH у невеликій кількості як винагороду.",
  "page-eth-fuels-desc-3": "Завдяки майнерам мережа Ethereum залишається безпечною та вільною від централізованого контролю. Іншими словами,",
  "page-eth-fuels-more-staking": "Докладніше про стейкінг",
  "page-eth-fuels-staking": "ETH стане ще більш важливим елементом після впровадження стейкінгу. Отримавши свою частку ETH, ви зможете допомогти нам захистити Ethereum і отримаєте винагороди. У цій системі загроза втрати ETH запобігає атакам.",
  "page-eth-get-eth-btn": "Отримати ETH",
  "page-eth-gov-tokens": "Токени управління",
  "page-eth-gov-tokens-desc": "Токени, що представляють силу голосування в децентралізованих організаціях.",
  "page-eth-has-value": "Чому ETH має цінність?",
  "page-eth-has-value-desc": "Користувачі по-різному визначають цінність ETH.",
  "page-eth-has-value-desc-2": "Для користувачів Ethereum цінність ETH полягає в тому, що цією валютою можна оплачувати комісії за транзакції.",
  "page-eth-has-value-desc-3": "Інші користувачі бачать цінність у тому, що створення нових одиниць ETH уповільнюється з часом.",
  "page-eth-has-value-desc-4": "Зовсім нещодавно ETH став цінним для користувачів фінансових програм Ethereum. Це тому, що ви можете використовувати ETH як заставу для криптовалют або як платіжну систему.",
  "page-eth-has-value-desc-5": "Звісно, багато хто також розглядає це як інвестицію, подібну до Bitcoin чи інших криптовалют.",
  "page-eth-how-to-buy": "Як купити Ether",
  "page-eth-how-to-buy-caption": "Часто оновлюються",
  "page-eth-is-money": "ETH – це цифрові глобальні гроші.",
  "page-eth-last-updated": "Січень 2019 р.",
  "page-eth-mining-link": "Докладніше про майнінг",
  "page-eth-monetary-policy": "Валютна політика Ethereum",
  "page-eth-more-on-ethereum-link": "Докладніше про Ethereum",
  "page-eth-no-centralized": "Немає централізованого контролю ",
  "page-eth-no-centralized-desc": "ETH – це децентралізована й глобальна валюта. Не існує компаній або банків, які можуть випустити більше одиниць ETH або змінити умови використання цієї валюти.",
  "page-eth-non-fungible-tokens-link": "Незамінні токени",
  "page-eth-not-only-crypto": "ETH – не єдина криптовалюта в Ethereum",
  "page-eth-not-only-crypto-desc": "Будь-хто може створювати нові види об’єктів і продавати їх у мережі Ethereum. Такі об’єкти називаються токенами. Люди токенізують традиційні валюти, нерухомість, витвори мистецтва та навіть самих себе!",
  "page-eth-not-only-crypto-desc-2": "Мережа Ethereum містить тисячі токенів. Деякі з них цінніші за інші. Розробники постійно створюють нові токени, що відкривають нові можливості й ринки.",
  "page-eth-not-only-crypto-desc-3": "Якщо ви хочете дізнатися більше про токени, перегляньте кілька статей, написаних нашими друзям на EthHub.",
  "page-eth-open": "Доступно для всіх",
  "page-eth-open-desc": "Щоб отримувати ETH, потрібно мати лише підключення до Інтернету та гаманець. Доступ до банківського рахунку не потрібен для прийняття платежів. ",
  "page-eth-p2p-payments": "Однорангові платежі",
  "page-eth-p2p-payments-desc": "Ви можете надсилати ETH, не використовуючи посередницькі сервіси (як банк). Це як передача готівки особисто, яку можна виконувати безпечно з будь-ким, будь-коли й будь-де.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Популярні типи токенів",
  "page-eth-powers-ethereum": "ETH забезпечує роботу мережі Ethereum",
  "page-eth-shit-coins": "Неякісні монети",
  "page-eth-shit-coins-desc": "Оскільки нові токени можна легко створити, це може зробити кожен, навіть люди зі злочинними намірами. Завжди власноруч перевіряйте їх, перш ніж використовувати!",
  "page-eth-stablecoins": "Стабільні монети",
  "page-eth-stablecoins-desc": "Токени, які відображають вартість традиційної валюти, наприклад доларів. Це вирішує проблему нестабільності, що є актуальною для багатьох криптовалют.",
  "page-eth-stablecoins-link": "Отримати стабільні монети",
  "page-eth-stream-link": "Передавання ETH",
  "page-eth-tokens-link": "Токени Ethereum",
  "page-eth-trade-link-2": "Обмін токенами",
  "page-eth-underpins": "ETH лежить в основі фінансової системи Ethereum",
  "page-eth-underpins-desc": "Оскільки спільнота Ethereum незадоволена платежами, її учасники наразі розробляють абсолютно нову фінансову систему, що має бути одноранговою та загальнодоступною.",
  "page-eth-underpins-desc-2": "Ви можете використовувати ETH в якості застави для генерації різних токенів криптовалюти на Ethereum. Ви можете позичати, давати кредити й отримувати відсотки за ETH та інші альтернативні токени.",
  "page-eth-uses": "Популярність ETH зростає щодня",
  "page-eth-uses-desc": "Оскільки мережа Ethereum підлягає програмуванню, розробники можуть експериментувати з ETH безліччю способів.",
  "page-eth-uses-desc-2": "У 2015 році ETH можна було лише надсилати з одного акаунту Ethereum в інший. Нижче наведено деякі можливості, доступні сьогодні.",
  "page-eth-uses-desc-3": "надсилайте кошти іншим користувачам і отримуйте їх у реальному часі.",
  "page-eth-uses-desc-4": "ви можете торгувати ETH та іншими токенами, зокрема токенами Bitcoin.",
  "page-eth-uses-desc-5": "за ETH та інші токени на основі Ethereum.",
  "page-eth-uses-desc-6": "отримайте доступ до світу криптовалют зі стабільною цінністю.",
  "page-eth-value": "Чому Ether цінний",
  "page-eth-video-alt": "Відео гліфів ETH",
  "page-eth-whats-eth": "Що таке Ether (ETH)?",
  "page-eth-whats-eth-hero-alt": "Ілюстрація групи людей, що в захваті дивляться на гліф Ether (ETH)",
  "page-eth-whats-eth-meta-desc": "Що треба знати, щоб розуміти ETH і місце цієї валюти в мережі Ethereum.",
  "page-eth-whats-eth-meta-title": "Що таке Ether (ETH)?",
  "page-eth-whats-ethereum": "Що таке Ethereum?",
  "page-eth-whats-ethereum-desc": "Щоб дізнатися більше про Ethereum, технології в основі ETH, перегляньте інформацію у вступі.",
  "page-eth-whats-unique": "Що унікального в ETH?",
  "page-eth-whats-unique-desc": "У мережі Ethereum існує багато криптовалют та інших токенів, однак є речі, які вирізняють ETH серед інших.",
  "page-eth-where-to-buy": "Де отримати ETH",
  "page-eth-where-to-buy-desc": "ETH можна отримати в результаті обміну або через гаманець, однак різні країни мають різні правила. Перевірте, на яких сервісах ви можете придбати ETH.",
  "page-eth-yours": "Ваші кошти належать вам",
  "page-eth-yours-desc": "Завдяки ETH ви можете бути самі собі банком. Ви можете контролювати власні кошти в гаманці як доказ права власності. Сторонні сервіси не потрібні.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Адреса контакту, за яким здійснювалося поповнення (Eth2)",
  "page-eth2-deposit-contract-address-caption": "Ми додали пробіли, щоб зробити адресe було зручніше читати",
  "page-eth2-deposit-contract-address-check-btn": "Перевірити адресу контакту, за яким здійснювалося поповнення",
  "page-eth2-deposit-contract-checkbox1": "Я не вперше використовую панель запуску для налаштування валідатора Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Я розумію, що панель запуску потрібно використовувати для стейкінгу. Прості транзакції на цю адресу не підтримуються.",
  "page-eth2-deposit-contract-checkbox3": "Я звірю адресу контакту, за яким здійснювалося поповнення, з іншими джерелами.",
  "page-eth2-deposit-contract-confirm-address": "Поставте прапорці нижче, щоб переглянути адресу",
  "page-eth2-deposit-contract-copied": "Скопійована адреса",
  "page-eth2-deposit-contract-copy": "Копіювати адресу",
  "page-eth2-deposit-contract-etherscan": "Переглянути контракт на Etherscan",
  "page-eth2-deposit-contract-h2": "Тут не можна отримати частку",
  "page-eth2-deposit-contract-launchpad": "Отримати частку на панелі запуску",
  "page-eth2-deposit-contract-launchpad-2": "Використати панель запуску",
  "page-eth2-deposit-contract-meta-desc": "Підтвердьте адресу контакту, за яким здійснювалося поповнення для стейкінгу Eth2.",
  "page-eth2-deposit-contract-meta-title": "Адреса контакту, за яким здійснювалося поповнення (Eth2)",
  "page-eth2-deposit-contract-read-aloud": "Читати адресу вголос",
  "page-eth2-deposit-contract-reveal-address-btn": "Показати адресу",
  "page-eth2-deposit-contract-staking": " Щоб отримати частку ETH у рамках оновлень Eth2, потрібно скористатися відповідною панеллю запуску та виконати вказівки. Якщо надіслати ETH на адресу на цій сторінці, ви не стаєте стейкером, а здійснити транзакцію не вдасться.",
  "page-eth2-deposit-contract-staking-check": "Перегляньте ці джерела",
  "page-eth2-deposit-contract-staking-check-desc": "Ми передбачаємо появу великої кількості фальшивих адрес і шахрайських схем. Щоб захистити себе, порівняйте адресу, яку ви використовуєте, з адресою на цій сторінці. Ми також радимо порівняти її з іншими надійними джерелами.",
  "page-eth2-deposit-contract-staking-more-link": "Докладніше про стейкінг",
  "page-eth2-deposit-contract-stop-reading": "Припинити читання",
  "page-eth2-deposit-contract-subtitle": " Це адреса контракту Eth2. Скористайтеся цією сторінкою, щоб підтвердити, що під час стейкінгу ви надсилаєте кошти на правильну адресу.",
  "page-eth2-deposit-contract-warning": "Уважно перевірте кожен символ.",
  "page-eth2-deposit-contract-warning-2": "Якщо надіслати кошти на цю адресу, транзакцію буде відхилено, і ви не станете стейкером. Дотримуйтеся вказівок на панелі запуску.",
  "page-eth2-bug-bounty-annotated-specs": "анотаційна специфікація",
  "page-eth2-bug-bounty-annotations": "Було б корисно оформити такі анотації:",
  "page-eth2-bug-bounty-client-bugs": "Помилки з клієнтами Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Клієнти запустять Beacon Chain відразу після впровадження оновлення. Клієнти мають відповідати вимогам у специфікації та захищати вас від потенційних атак. Помилки, які потрібно знайти, пов’язані з протоколом налаштувань.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Цю винагороду можна отримати за виявлення таких помилок, як Lighthouse, Nimbus, Teku та Prysm. Після завершення аудиту та готовності до експлуатації можна додати більше клієнтів.",
  "page-eth2-bug-bounty-clients": "Клієнти, що беруть участь у винагородах",
  "page-eth2-bug-bounty-clients-type-1": "невідповідності специфікації;",
  "page-eth2-bug-bounty-clients-type-2": "неочікувані збої чи перешкоди в роботі DOS;",
  "page-eth2-bug-bounty-clients-type-3": "будь-які проблеми, що ведуть до незворотного від’єднання від решти мережі;",
  "page-eth2-bug-bounty-docking": "злиття.",
  "page-eth2-bug-bounty-email-us": "Email us:",
  "page-eth2-bug-bounty-help-links": "Корисні посилання",
  "page-eth2-bug-bounty-hunting": "Правила пошуку помилок",
  "page-eth2-bug-bounty-hunting-desc": "Програма виявлення помилок – це експериментальна й самостійна програма винагород для нашої активної спільноти Ethereum, покликана заохочувати користувачів покращувати платформу за винагороди. Це не змагання. Пам’ятайте, що ми можемо будь-коли скасувати програму, а також що винагородами керує організація Ethereum Foundation. Крім того, ми не надаємо винагороди особам, яких внесено в санкційні списки (наприклад, КНДР, Іран тощо). Ви несете відповідальність за сплату всіх податків. Усі винагороди повинні відповідати вимогам чинного законодавства. І останнє: ваше тестування не має порушувати закон або компрометувати дані, які вам не належать.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Користувачі, які виявили найбільше помилок",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Щоб потрапити в цей список, знайдіть помилки в оновленнях Eth2",
  "page-eth2-bug-bounty-hunting-li-1": "Проблеми, які вже були представлені іншим користувачем або вже відомі специфікаціям, а супроводжуючі клієнтів не мають права на винагороду.",
  "page-eth2-bug-bounty-hunting-li-2": "Публічне розкриття вразливості робить неможливим отримання винагороди.",
  "page-eth2-bug-bounty-hunting-li-3": "Дослідники й працівники організації Ethereum Foundation, що є учасниками команди з розробки оновлень Eth2, не можуть отримувати винагороди.",
  "page-eth2-bug-bounty-hunting-li-4": "Програма винагород Ethereum зважає на ряд змінних у визначенні нагород. Придатність, оцінки та всі умови, пов’язані з винагородою, остаточно визначаються комітетом Ethereum Foundation.",
  "page-eth2-bug-bounty-leaderboard": "Переглянути повну таблицю лідерів",
  "page-eth2-bug-bounty-leaderboard-points": "бали",
  "page-eth2-bug-bounty-ledger-desc": "У специфікації Beacon Chain чітко описано дизайн і зміни для мережі Ethereum, запропоновані в рамках оновлення Beacon Chain.",
  "page-eth2-bug-bounty-ledger-title": "Помилки в специфікації Beacon Chain",
  "page-eth2-bug-bounty-meta-description": "Огляд програми з пошуку помилок в оновленнях Eth2: як зробити внесок і отримати винагороду?",
  "page-eth2-bug-bounty-meta-title": "Програма винагород за пошук помилок в оновленнях Eth2",
  "page-eth2-bug-bounty-not-included": "Не включено",
  "page-eth2-bug-bounty-not-included-desc": "Ланцюги сегментів даних і злиття все ще активно розробляються та поки що не беруть участь у бонусній програмі.",
  "page-eth2-bug-bounty-owasp": "Перегляньте метод OWASP",
  "page-eth2-bug-bounty-points": "Ethereum Foundation також надаватиме бали на основі таких факторів:",
  "page-eth2-bug-bounty-points-error": "Помилка завантаження даних... Оновіть сторінку.",
  "page-eth2-bug-bounty-points-exchange": "Обмін балами",
  "page-eth2-bug-bounty-points-loading": "Завантаження даних...",
  "page-eth2-bug-bounty-points-payout-desc": "Ethereum Foundation виплатить USD у ETH або DAI.",
  "page-eth2-bug-bounty-points-point": "1 бал",
  "page-eth2-bug-bounty-points-rights-desc": "Організація Ethereum Foundation залишає за собою право змінювати цю інформацію без попередження.",
  "page-eth2-bug-bounty-points-usd": "2 дол. США",
  "page-eth2-bug-bounty-quality": "Якість опису",
  "page-eth2-bug-bounty-quality-desc": ": Що чіткіше й добре написано інформацію, то кращу винагороду ви отримаєте.",
  "page-eth2-bug-bounty-quality-fix": "Якість виправлення",
  "page-eth2-bug-bounty-quality-fix-desc": ", (якщо є) вищі винагороди надаються за інформацію з чітким описом способу вирішення проблеми.",
  "page-eth2-bug-bounty-quality-repro": "Якість відтворюваності",
  "page-eth2-bug-bounty-quality-repro-desc": ": Додайте код тесту, скрипти та детальні інструкції. Що простіше нам створювати й перевіряти вразливість, то вища нагорода.",
  "page-eth2-bug-bounty-questions": "Запитання?",
  "page-eth2-bug-bounty-rules": "Читати правила",
  "page-eth2-bug-bounty-shard-chains": "ланцюги сегментів даних",
  "page-eth2-bug-bounty-slogan": "Винагороди за знайдення помилок в оновленні Eth2",
  "page-eth2-bug-bounty-specs": "Читати всю спеціфікацію",
  "page-eth2-bug-bounty-specs-docs": "Документи специфікацій",
  "page-eth2-bug-bounty-submit": "Повідомити про помилку",
  "page-eth2-bug-bounty-submit-desc": "За кожну виявлену помилку ви отримуватимете бали. Їх кількість залежатиме від того, наскільки серйозною є помилка. Організація Ethereum Foundation визначає рівень помилки за допомогою методу OWASP.",
  "page-eth2-bug-bounty-subtitle": "Заробляйте до 50 000 дол. США й увійдіть у список лідерів, знаходячи помилки в протоколі й клієнті Eth2.",
  "page-eth2-bug-bounty-title": "Заявки ще приймаються",
  "page-eth2-bug-bounty-title-1": "Beacon Chain",
  "page-eth2-bug-bounty-title-2": "Вибір оновлення",
  "page-eth2-bug-bounty-title-3": "Контракт на депозит мовою Solidity",
  "page-eth2-bug-bounty-title-4": "Одноранговий нетворкінг",
  "page-eth2-bug-bounty-type-1": "помилки, що перешкоджають завершенню блоку або пов’язані з безпекою;",
  "page-eth2-bug-bounty-type-2": "DOS-вектори;",
  "page-eth2-bug-bounty-type-3": "невідповідності в припущеннях, зокрема в ситуаціях, коли чесного валідатора визнано зловмисним;",
  "page-eth2-bug-bounty-type-4": "невідповідності в розрахунках або параметрах.",
  "page-eth2-bug-bounty-types": "Типи помилок",
  "page-eth2-bug-bounty-validity": "Дійсні помилки",
  "page-eth2-bug-bounty-validity-desc": "Ця програма винагород націлена на пошук помилок у специфікації оновлення Beacon Chain Eth2, а також у запуску клієнтів Prysm, Lighthouse і Teku.",
  "page-eth2-bug-bountycard-critical": "Критична",
  "page-eth2-bug-bountycard-critical-risk": "Повідомити про критичну помилку",
  "page-eth2-bug-bountycard-h2": "Середня",
  "page-eth2-bug-bountycard-high": "Серйозна",
  "page-eth2-bug-bountycard-high-risk": "Повідомити про серйозну помилку",
  "page-eth2-bug-bountycard-label-1": "До 1000 балів",
  "page-eth2-bug-bountycard-label-2": "До 2000 DAI",
  "page-eth2-bug-bountycard-label-3": "До 5000 балів",
  "page-eth2-bug-bountycard-label-4": "До 10 000 DAI",
  "page-eth2-bug-bountycard-label-5": "До 10 000 балів",
  "page-eth2-bug-bountycard-label-6": "До 20 000 DAI",
  "page-eth2-bug-bountycard-label-7": "До 25 000 балів",
  "page-eth2-bug-bountycard-label-8": "До 50 000 DAI",
  "page-eth2-bug-bountycard-li-1": "Незначний вплив, середня ймовірність",
  "page-eth2-bug-bountycard-li-2": "Середній вплив, низька ймовірність",
  "page-eth2-bug-bountycard-li-3": "Серйозний вплив, низька ймовірність",
  "page-eth2-bug-bountycard-li-4": "Середній вплив, середня ймовірність",
  "page-eth2-bug-bountycard-li-5": "Незначний вплив, висока ймовірність",
  "page-eth2-bug-bountycard-li-6": "Серйозний вплив, середня ймовірність",
  "page-eth2-bug-bountycard-li-7": "Середній вплив, висока ймовірність",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Незначна",
  "page-eth2-bug-bountycard-low-risk": "Повідомити про незначну помилку",
  "page-eth2-bug-bountycard-medium-risk": "Повідомити про помірну помилку",
  "page-eth2-bug-bountycard-subheader": "Значимість",
  "page-eth2-bug-bountycard-subheader-2": "Приклад",
  "page-eth2-bug-bountycard-text": "Іноді зловмисник може завести вузол у стан, що спричиняє видалення по одній атестації зі ста наявних, зроблених валідатором",
  "page-eth2-bug-bountycard-text-1": "Зловмисник може успішно атакувати вузли з одноранговими ідентифікаторами з 4 провідними нульовими байтами",
  "page-eth2-bug-bountycard-text-2": "Існує консенсусна помилка між двома клієнтами, але зловмиснику важко (майже неможливо) запустити подію.",
  "page-eth2-bug-bountycard-text-3": "Існує консенсусна помилка між двома клієнтами, але зловмисник може легко запустити подію.",
  "page-eth2-get-involved-btn-1": "Переглянути клієнти",
  "page-eth2-get-involved-btn-2": "Докладніше про стейкінг",
  "page-eth2-get-involved-btn-3": "Знайти помилки",
  "page-eth2-get-involved-bug": "До помилок належать:",
  "page-eth2-get-involved-bug-hunting": "Виловлюйте помилки",
  "page-eth2-get-involved-bug-hunting-desc": "Знаходьте помилки в оновлених специфікаціях або клієнтах Eth2 і повідомляйте про них. Ви можете заробити до 50 000 дол. США й потрапити до списку лідерів.",
  "page-eth2-get-involved-bug-li": "невідповідності специфікації;",
  "page-eth2-get-involved-bug-li-2": "помилки, що перешкоджають завершенню блоку;",
  "page-eth2-get-involved-bug-li-3": "DOS-вектори;",
  "page-eth2-get-involved-bug-li-4": "інші помилки...",
  "page-eth2-get-involved-date": "Дата закриття: 23 грудня 2020 року",
  "page-eth2-get-involved-desc-1": "Якщо ви запустите клієнт, то станете активним учасником мережі Ethereum. Ваш клієнт допомагатиме відстежувати транзакції та перевіряти нові блоки.",
  "page-eth2-get-involved-desc-2": "Якщо у вас є ETH, ви можете скористатися цією валютою, щоб стати валідатором і допомагати захищати мережу. Як валідатор ви отримуватимете винагороди ETH.",
  "page-eth2-get-involved-desc-3": "Приєднуйтеся до тестувань спільноти! Допоможіть протестувати оновлення Eth2 до того, як їх буде запущено. Виявляйте помилки та заробляйте винагороди.",
  "page-eth2-get-involved-ethresearch-1": "Сегментування",
  "page-eth2-get-involved-ethresearch-2": "Перехід від Еth1 до Еth2",
  "page-eth2-get-involved-ethresearch-3": "Сегменти даних і впровадження стану",
  "page-eth2-get-involved-ethresearch-4": "Усі дослідницькі теми",
  "page-eth2-get-involved-grants": "Стейкінг грантів спільноти",
  "page-eth2-get-involved-grants-desc": "Допоможіть створити інструменти та навчальні матеріали для спільноти стейкерів",
  "page-eth2-get-involved-how": "Який внесок ви хочете зробити?",
  "page-eth2-get-involved-how-desc": "Що більше людей запускатимуть клієнти, ставатимуть стейкерами й шукатимуть помилки, то досконалішою ставатиме мережа Ethereum.",
  "page-eth2-get-involved-join": "Приєднатися до дослідження",
  "page-eth2-get-involved-join-desc": " Як і багато що інше в мережі Ethereum, велика частина дослідження є доступною для всіх. Це означає, що ви можете брати участь в обговореннях або просто читати думки інших дослідників Ethereum. На сторінці ethresear.ch наведено відомості не лише про оновлення Eth2, але й багато інформації про мету Eth2.",
  "page-eth2-get-involved-meta-description": "Як взяти участь в оновленнях Eth2: запускати вузли, отримувати частки, знаходити помилки тощо.",
  "page-eth2-get-involved-more": "Докладніше",
  "page-eth2-get-involved-run-clients": "Запустіть клієнти Beacon Chain",
  "page-eth2-get-involved-run-clients-desc": "Ключовою умовою довготривалої захищеності мережі Ethereum є сильний розподіл клієнтів. Клієнт – це програмне забезпечення, на основі якого працює блокчейн. Він перевіряє транзакції та створює нові блоки. Кожен клієнт має власні функції, тому радимо вибирати ті, що вам підходять.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Як отримати частку ETH",
  "page-eth2-get-involved-stake-desc": "Тепер ви можете отримати частку ETH і допомогти посилити захист Beacon Chain.",
  "page-eth2-get-involved-stake-eth": "Частка ETH",
  "page-eth2-get-involved-subtitle": "Ось усі поради, які знадобляться вам для роботи з Ethereum і покращення Eth2.",
  "page-eth2-get-involved-title-1": "Запустіть клієнт",
  "page-eth2-get-involved-title-2": "Як отримати частку ETH",
  "page-eth2-get-involved-title-3": "Знайти помилки",
  "page-eth2-get-involved-written-go": "Написано мовою кодування Go",
  "page-eth2-get-involved-written-java": "Написано мовою кодування Java",
  "page-eth2-get-involved-written-javascript": "Написано мовою кодування JavaScript",
  "page-eth2-get-involved-written-net": "Написано мовою кодування .NET",
  "page-eth2-get-involved-written-nim": "Написано мовою кодування Nim",
  "page-eth2-get-involved-written-python": "Написано мовою кодування Python",
  "page-eth2-get-involved-written-rust": "Написано мовою кодування Rust",
  "eth2-client-cortex-logo-alt": "Cortex logo",
  "eth2-client-lighthouse-logo-alt": "Lighthouse logo",
  "eth2-client-lodestar-logo-alt": "Lodestar logo",
  "eth2-client-nimbus-logo-alt": "Nimbus logo",
  "eth2-client-prysm-logo-alt": "Prysm logo",
  "eth2-client-teku-logo-alt": "Teku logo",
  "eth2-rhino-img-alt": "Графічне зображення носорога, що біжить уперед (оновлення Eth 2)",
  "page-eth2-answer-1": "Вважайте Eth2 набором оновлень, покликаних покращити відомий нам сьогодні Ethereum. У рамках цих оновлень буде створено новий ланцюг Beacon Chain і до 64 сегментів даних.",
  "page-eth2-answer-2": "Ці оновлення не стосуватимуться основної мережі Ethereum, що наразі використовується, однак і не замінять її. Натомість основну мережу буде з’єднано з цією паралельною системою, яку ми додамо згодом.",
  "page-eth2-answer-4": "Іншими словами, відома нам мережа Ethereum зрештою матиме всі функції, які ми збираємося додати в рамках оновлень Eth2.",
  "page-eth2-beacon-chain-btn": "Докладніше про Beacon Chain",
  "page-eth2-beacon-chain-date": "Оновлення Beacon Chain було запущено 1 грудня 2020 року.",
  "page-eth2-beacon-chain-desc": "Перше оновлення Eth2 в екосистемі. У рамках Beacon Chain у мережу Ethereum додано стейкінг. Це основа для майбутніх оновлень, яка зрештою керуватиме новою системою.",
  "page-eth2-beacon-chain-estimate": "Beacon Chain запущено",
  "page-eth2-beacon-chain-title": "Beacon Chain",
  "page-eth2-bug-bounty": "Переглянути програму винагород",
  "page-eth2-clients": "Переглянути клієнтів Eth2",
  "page-eth2-deposit-contract-title": "Перевірте адресу контакту, за яким здійснювалося поповнення",
  "page-eth2-diagram-ethereum-mainnet": "Основна мережа Ethereum",
  "page-eth2-diagram-h2": "Як поєднуються оновлення",
  "page-eth2-diagram-link-1": "Докладніше про доказ виконаної роботи",
  "page-eth2-diagram-link-2": "Докладніше про ланцюги сегментів даних",
  "page-eth2-diagram-mainnet": "Основна мережа",
  "page-eth2-diagram-p": "Основна мережа Ethereum продовжить існувати в поточному вигляді деякий час. Оновлення Beacon Chain і сегментування даних не впливатиме на роботу мережі.",
  "page-eth2-diagram-p-1": "Зрештою основну мережу буде з’єднано з новою системою, упровадженою в рамках оновлень Eth2.",
  "page-eth2-diagram-p-2": "Beacon Chain почне керувати мережею Ethereum і валідаторами, а також установлюватиме швидкість створення блоків.",
  "page-eth2-diagram-p-3": "Спочатку це оновлення функціонуватиме окремо від основної мережі й контролюватиме роботу валідаторів. Воно ніяк не стосується розумних контрактів, транзакцій і акаунтів.",
  "page-eth2-diagram-p-4": "Завдяки сегментуванню з’являться додаткові дані, що допоможе збільшити кількість транзакцій в основній мережі. Ними керуватиме Beacon Chain.",
  "page-eth2-diagram-p-5": "Однак усі транзакції, як і раніше, покладатимуться на основну мережу, що й надалі функціонуватиме у відомому нам вигляді. Її безпеку гарантують докази виконаної роботи та майнери.",
  "page-eth2-diagram-p-6": "Основна мережа з’єднається із системою доказу частки, що координується оновленням Beacon Chain.",
  "page-eth2-diagram-p-8": "Після цього основна мережа стане сегментом даних у новій системі. Майнери більше не будуть потрібні, оскільки мережу Ethereum захищатимуть валідатори.",
  "page-eth2-diagram-p10": "Eth2 – це не перехід і не єдине оновлення. Це набір оновлень, націлених на розкриття справжнього потенціалу Ethereum. Так вони всі й поєднуються.",
  "page-eth2-diagram-scroll": "Прокрутіть для огляду й натисніть, щоб дізнатися більше.",
  "page-eth2-diagram-shard": "Сегмент даних (1)",
  "page-eth2-diagram-shard-1": "Сегмент даних (...)",
  "page-eth2-diagram-shard-2": "Сегмент даних (2)",
  "page-eth2-diagram-shard-3": "Сегмент даних (...)",
  "page-eth2-diagram-validators": "Докладніше про валідаторів",
  "page-eth2-dive": "Дізнайтеся більше про оновлення",
  "page-eth2-dive-desc": "Як ми збираємося масштабувати мережу Ethereum, посилити її захист і надійність? Усе ще шляхом забезпечення децентралізації Ethereum.",
  "page-eth2-docking": "Злиття",
  "page-eth2-docking-answer-1": "Злиття – це перетворення основної мережі на сегмент даних. Це відбудеться після успішного впровадження ланцюгів сегментів даних.",
  "page-eth2-docking-btn": "Докладніше про злиття",
  "page-eth2-docking-desc": "Основна мережа Ethereum зрештою з’єднається із системою Beacon Chain. Це дозволить увімкнути стейкінг у всій мережі й свідчитиме про кінець єнергоємного майнінгу.",
  "page-eth2-docking-estimate": "Очікується: 2022",
  "page-eth2-docking-mainnet": "Що таке основна мережа?",
  "page-eth2-docking-mainnet-eth2": "Злиття основної мережі з оновленням Eth2",
  "page-eth2-eth-blog": "Блог на сайті ethereum.org",
  "page-eth2-explore-btn": "Переглянути оновлення",
  "page-eth2-get-involved": "Долучитися до оновлень Eth2",
  "page-eth2-get-involved-2": "Долучитися",
  "page-eth2-head-to": "Перейти до",
  "page-eth2-help": "Хочете допомогти з оновленнями Eth2?",
  "page-eth2-help-desc": "Існує багато способів допомогти нам із упровадженням оновлень Eth2 і тестуванням, а також отримати винагороди.",
  "page-eth2-index-staking": "Стейкінг уже тут",
  "page-eth2-index-staking-desc": "Упровадження стейкінгу має ключове значення для оновлень Eth2. Якщо ви хочете допомогти нам захистити мережу Ethereum за допомогою ETH, виконайте ці вказівки.",
  "page-eth2-index-staking-learn": "Дізнайтеся, що таке стейкінг",
  "page-eth2-index-staking-learn-desc": "У рамках оновлення Beacon Chain у мережі Ethereum буде запущено стейкінг. Якщо у вас є ETH, ви можете допомогти нам захистити мережу й разом із тим заробити більше ETH.",
  "page-eth2-index-staking-step-1": "1. Виберіть налаштування на панелі запуску",
  "page-eth2-index-staking-step-1-btn": "Перейти на панель запуску",
  "page-eth2-index-staking-step-1-desc": "Щоб долучитися до оновлень Eth2, потрібно скористатися панеллю запуску. На ній ви знайдете потрібні вказівки.",
  "page-eth2-index-staking-step-2": "2. Підтвердьте адресу для стейкінгу",
  "page-eth2-index-staking-step-2-btn": "Підтвердити адресу",
  "page-eth2-index-staking-step-2-desc": "Перш ніж отримати ETH, введіть правильну адресу та виконайте вказівки на панелі запуску.",
  "page-eth2-index-staking-sustainability": "Надійність",
  "page-eth2-just-docking": "Докладніше про злиття",
  "page-eth2-meta-desc": "Огляд оновлень Ethereum 2.0 та ідей, які ми плануємо реалізувати.",
  "page-eth2-meta-title": "Оновлення Eth2",
  "page-eth2-miners": "Докладніше про майнерів",
  "page-eth2-more-on-upgrades": "Докладніше про оновлення Eth2",
  "page-eth2-proof-of-stake": "доказ частки",
  "page-eth2-proof-of-work": "доказ виконаної роботи",
  "page-eth2-proof-stake-link": "Докладніше про доказ частки",
  "page-eth2-question-1-desc": "Eth2 – це набір важливих оновлень, які будуть запущені в різний час.",
  "page-eth2-question-1-title": "Коли убуде запущено оновлення Eth2?",
  "page-eth2-question-2-desc": "Eth2 – це не окремий блокчейн.",
  "page-eth2-question-2-title": "Eth2 – це окремий блокчейн?",
  "page-eth2-question-3-answer-2": "Оновлення, пов’язані з ланцюгами сегментів даних і злиттям, можуть вплинути на роботу розробників децентралізованих програм. Однак нараз вам не потрібно виконувати жодних дій, оскільки специфікації ще в розробці.",
  "page-eth2-question-3-answer-3": "Зв’яжіться з командою розробки оновлень Eth2 на сторінці ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Відвідати ethresear.ch",
  "page-eth2-question-3-desc": "Наразі вам не потрібно нічого робити, щоб підготуватися до запуску оновлень Eth2.",
  "page-eth2-question-3-title": "Мені потрібно готуватися до Eth2?",
  "page-eth2-question-4-answer-1": "Надсилаючи транзакції та користуючись децентралізованими програмами, ви використовуєте Eth1. Це мережа Ethereum. яку захищають майнери.",
  "page-eth2-question-4-answer-2": "Eth1 (основна мережа) і далі працюватиме звичайним чином аж до моменту злиття.",
  "page-eth2-question-4-answer-3": "Після злиття валідатори почнуть захищати всю систему за допомогою доказів частки.",
  "page-eth2-question-4-answer-6": "Валідаторами можуть стати всі, хто мають ETH.",
  "page-eth2-question-4-answer-7": "Докладніше про стейкінг",
  "page-eth2-question-4-answer-8": "Оновлення Beacon Chain і ланцюги сегментів даних не вплинуть на роботу Eth1, оскільки їх запущено окремо.",
  "page-eth2-question-4-desc": "Eth1 – це основна мережа Ethereum, у якій ви наразі здійснюєте транзакції.",
  "page-eth2-question-4-title": "Що таке Eth1?",
  "page-eth2-question-5-answer-1": "Щоб стати повноцінним валідатором в мережі, вам потрібно буде отримати 32 ETH. Якщо ж у вас їх немає або ви не хочете стільки купувати, то можете приєднатися до стейк-пулів. Так ви купуватимете менше й отримуватимете фракції загальних нагород.",
  "page-eth2-question-5-desc": "Вам потрібно буде скористатися панеллю запуску стейкінгу або підключити стейк-пул.",
  "page-eth2-question-5-title": "Як отримати частку?",
  "page-eth2-question-6-answer-1": "Наразі вам нічого не потрібно робити. Однак радимо стежити за новинами про ланцюги сегментів даних і злиття.",
  "page-eth2-question-6-answer-3": "Дені Райан з Ethereum Foundation регулярно оновлює спільноту:",
  "page-eth2-question-6-answer-4": "Бен Едгінгтон із ConsenSys щотижня випускає інформаційний лист, присвячений оновленням Eth2:",
  "page-eth2-question-6-answer-5": "Ви також можете приєднатися до обговорень щодо розробки Eth2 на сторінці ethresear.ch.",
  "page-eth2-question-6-desc": "Майбутні оновлення не вплинуть на вашу децентралізовану програму, однак імовірно, що для їх упровадження потрібно буде внести деякі зміни.",
  "page-eth2-question-6-title": "Що мені робити з моєю децентралізованою програмою?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(.NET)",
  "page-eth2-question-7-desc": "Багато різних команд з усієї спільноти працюють над різними оновленнями Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Go)",
  "page-eth2-question-7-teams": "Клієнтські команди Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Java)",
  "page-eth2-question-7-title": "Хто розробляє оновлення Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Python)",
  "page-eth2-question-8-answer-1": "Оновлення Eth2 допоможуть масштабувати мережу Ethereum шляхом децентралізації, забезпечуючи її безпеку й надійність.",
  "page-eth2-question-8-answer-2": "Найбільш очевидною проблемою, імовірно, є те, що мережа Ethereum повинна обробляти 15–45 транзакцій за секунду. Однак оновлення Eth2 також націлені на вирішення інших проблем, що зараз наявні в мережі Ethereum.",
  "page-eth2-question-8-answer-3": "Мережа Ethereum настільки популярна, що користуватися нею стало дорого. Вузли в мережі потерпають від розмірів Ethereum і обсягу даних, які повинні обробляти комп’ютери. Крім того, внутрішній алгоритм захисту й децентралізації Ethereum став надто енергоємним.",
  "page-eth2-question-8-answer-4": "Багато впроваджуваних змін були частиною плану з розвитку мережі Ethereum ще з 2015 року. Однак поточний стан мережі потребує оновлень навіть більше.",
  "page-eth2-question-8-answer-6": "Огляд оновлень Eth2",
  "page-eth2-question-8-desc": "Відома нам мережа Ethereum повинна стати зручнішою для кінцевих користувачів і учасників мережі.",
  "page-eth2-question-8-title": "Навіщо потрібні оновлення Eth2?",
  "page-eth2-question-9-answer-1": "Найбільш значуща дія, яку ви можете виконати, – це придбати ETH.",
  "page-eth2-question-9-answer-2": "Також радимо запустити другий клієнт, щоб допомогти покращити їх різноманітність.",
  "page-eth2-question-9-answer-3": "Якщо вам більше цікавить технічна сторона, ви можете зайнятися пошуком помилок у нових клієнтах.",
  "page-eth2-question-9-answer-4": "Крім того, ви можете поділитися своєю думкою в технічних обговореннях із розробниками Ethereum на сторінці ethresear.ch.",
  "page-eth2-question-9-desc": "Щоб долучитися, не потрібно бути технічно підкованим. Спільнота буде вдячна за будь-який внесок.",
  "page-eth2-question-9-stake-eth": "Частка ETH",
  "page-eth2-question-9-title": "Як зробити внесок в оновлення Eth2?",
  "page-eth2-question-10-answer-1": "Нам не хочеться багато говорити про технічну карту, оскільки це програмне забезпечення, яке може змінюватися. Ми вважаємо, що користувачам легше зрозуміти, що відбувається тоді, коли вони читають про результати.",
  "page-eth2-question-10-answer-1-link": "Переглянути оновлення",
  "page-eth2-question-10-answer-2": "Однак якщо ви стежили за обговореннями, ось як оновлення відповідають технічним картам.",
  "page-eth2-question-10-answer-3": "Фаза 0 – це перелік дій, потрібних для запуску оновлення Beacon Chain.",
  "page-eth2-question-10-answer-5": "Фаза 1 на технічних картах містить інформацію про запуск ланцюгів сегментів даних.",
  "page-eth2-question-10-answer-6": "Злиття основної мережі в рамках Eth2 – це продовження вже виконаних дій для запуску ланцюгів сегментів даних, позначене як Фаза 1.5. Це важливий момент, оскільки відома нам мережа Ethereum з’єднається з іншими оновленнями Eth2. Крім того, після цього мережа Ethereum повністю перейде на технологію доказу частки.",
  "page-eth2-question-10-answer-7": "Наразі незрозуміло, що відбуватиметься у Фазі 2. Вона ще перебуває на етапі розробки. Спочатку ми планували додати інші функції для ланцюгів сегментів даних, однак вони можуть виявитися непотрібними після злиття.",
  "page-eth2-question-10-answer-8": "Докладніше про карту, орієнтовану на об’єднання",
  "page-eth2-question-10-desc": "Фази відповідають етапам роботи й націлені на виконання дій у технічній карті Eth2.",
  "page-eth2-question-10-title": "Що таке фази Eth2?",
  "page-eth2-question-title": "Поширені запитання",
  "page-eth2-question3-answer-1": "Власникам ETH точно не потрібно нічого робити. Ваші одиниці ETH не потрібно буде змінювати або оновлювати. Однак остерігайтеся шахраїв, які наполягатимуть на протилежному, і будьте обережні.",
  "page-eth2-scalable": "Масштабування",
  "page-eth2-scalable-desc": "Мережа Ethereum повинна виконувати тисячі транзакцій за секунду, щоб вам було легше й дешевше користуватися програмами.",
  "page-eth2-secure": "Безпека",
  "page-eth2-secure-desc": "Мережа Ethereum має бути більш захищеною. Оскільки вона стає все більш популярною, протокол потрібно краще захистити від усіх видів атак.",
  "page-eth2-shard-button": "Докладніше про ланцюги сегментів даних",
  "page-eth2-shard-date": "Про ланцюги сегментів даних – друге оновлення, запуск якого заплановано на 2021 рік.",
  "page-eth2-shard-desc": "Ланцюги сегментів даних збільшать ємність мережі Ethereum: вона зможе обробляти більше транзакцій і зберігати більше даних. З часом до сегментування поетапно додаватимуться нові функції.",
  "page-eth2-shard-estimate": "Очікується: 2021",
  "page-eth2-shard-lower": "Докладніше про ланцюги сегментів даних",
  "page-eth2-shard-title": "Ланцюги сегментів даних",
  "page-eth2-stay-up-to-date": "Будьте в курсі подій",
  "page-eth2-stay-up-to-date-desc": "Стежте за новинами від дослідників і розробників, які працюють над оновленнями Eth2.",
  "page-eth2-sustainable-desc": "Мережа Ethereum має бути екологічнішою. Поточні технології потребують забагато комп’ютерів і енергії.",
  "page-eth2-take-part": "Візьміть участь у дослідженні",
  "page-eth2-take-part-desc": "Розробники Ethereum і просто зацікавлені користувачі обговорюють тут новини, пов’язані з Eth2.",
  "page-eth2-the-upgrades": "Оновлення Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 – це набір оновлень, націлених на масштабування мережі Ethereum, а також покращення її захисту й надійності. Хоча оновлення розробляються окремо, вони містять елементи, що їх поєднують і визначають, коли буде запущено наступне.",
  "page-eth2-unofficial-roadmap": "Це не офіційна технічна карта. Це наше бачення, створене на основі спостережень. Однак коли мови йде про технології, ситуація може змінитися в будь-який момент. Тож це вважайте це офіційною заявою.",
  "page-eth2-upgrade-desc": "Відома нам масштабована мережа Ethereum з покращеним захистом і надійністю...",
  "page-eth2-upgrades": "Оновлення Eth2",
  "page-eth2-upgrades-aria-label": "Меню оновлень Eth2",
  "page-eth2-upgrades-beacon-chain": "Beacon Chain",
  "page-eth2-upgrades-docking": "Злиття основної мережі й оновлення Eth2",
  "page-eth2-upgrades-guide": "Посібник з оновлень Eth2",
  "page-eth2-upgrades-shard-chains": "Ланцюги сегментів даних",
  "page-eth2-upgrading": "Радикальне оновлення Ethereum",
  "page-eth2-vision": "Наше бачення",
  "page-eth2-vision-btn": "Докладніше про призначення Eth2",
  "page-eth2-vision-desc": "Щоб зробити мережу Ethereum ще більш популярною та зручною для всіх, її потрібно масштабувати, краще захистити й зробити надійнішою.",
  "page-eth2-vision-upper": "Призначення Eth2",
  "page-eth2-what-to-do": "Що вам потрібно зробити?",
  "page-eth2-what-to-do-desc": "Якщо ви користувач децентралізованої програми або власник ETH вам не потрібно нічого робити. Якщо ви розробник або хочете почати займатися стейкінгом, існує безліч способів долучитися.",
  "page-eth2-whats-eth2": "Що таке Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 – це набір взаємопов’язаних оновлень, покликаних масштабувати мережу Ethereum, покращити її захист і надійність. Над розробкою цих оновлень працюють різні команди в екосистемі Ethereum.",
  "page-eth2-whats-ethereum": "Чекайте, що таке Ethereum?",
  "page-eth2-whats-new": "Що нового в Eth2?",
  "page-eth2-just-staking": "Стейкінг",
  "page-eth2-staking-image-alt": "Зображення носорога, що репрезентує панель запуску Eth2.",
  "page-eth2-staking-51-desc-1": "Завдяки стейкінгу приєднуватися до мережі як валідатор легше, тому ми очікуємо збільшення кількості валідаторів. Так атакувати мережу буде складніше, оскільки це коштуватиме дорожче.",
  "page-eth2-staking-accessibility": "Доступ",
  "page-eth2-staking-accessibility-desc": "Оскільки вимог до технічних засобів стане менше й з’явиться можливість приєднуватися до груп за відсутності 32 ETH, до мережі приєднуватиметься більше кількість користувачів. Це зробить мережу більш децентралізованою Ethereum і посилить її захист за рахунок зменшення ймовірності атак.",
  "page-eth2-staking-at-stake": "Ви можете втратити ETH",
  "page-eth2-staking-at-stake-desc": "Оскільки для підтвердження транзакцій і створення нових блоків потрібно мати ETH, ви можете втратити свої кошти, якщо спробуєте обійти систему.",
  "page-eth2-staking-benefits": "Переваги приєднання до Ethereum",
  "page-eth2-staking-check-address": "Перевірте адресу",
  "page-eth2-staking-consensus": "Докладніше про механізм консенсусу",
  "page-eth2-staking-deposit-address": "Перевірте адресу поповнення",
  "page-eth2-staking-deposit-address-desc": "Якщо ви вже виконали вказівки з налаштування на панелі запуску, то знаєте, що маєте здійснити транзакцію на контакт стейкінгу. Радимо уважно перевіряти адресу. Офіційну адресу можна знайти на сайті ethereum.org і кількох інших надійних сайтах.",
  "page-eth2-staking-desc-1": "Ви отримуєте винагороди за дії, які допомагають мережі досягти консенсусу, зокрема за транзакції в нових блоках або перевірку роботи інших валідаторів. Це забезпечує безпечну роботу мережі.",
  "page-eth2-staking-desc-2": "Незважаючи на те, що ви отримуєте винагороди за дії, що приносять користь мережі, ви все одно можете втратити ETH за зловмисні дії, перехід у автономний режим і невдалу перевірку.",
  "page-eth2-staking-desc-3": "Щоб стати повноправним валідатором, потрібно мати 32 ETH або достатню кількість ETH для приєднання до групи стейкінгу. Ви також повинні керувати клієнтом Eth1 або основної мережі. На панелі запуску ви знайдете вказівки й вимоги щодо технічного забезпечення. Крім того, ви можете скористатися API серверної частини.",
  "page-eth2-staking-description": "Стейкінг – це поповнення в розмірі 32 ETH програмного забезпечення активного валідатора для його активації. Як валідатор ви відповідаєте за збереження даних, обробку транзакцій і додавання нових блоків у блокчейн. Це допоможе зробити мережу Ethereum безпечною для всіх, а ви заробите ETH у процесі під назвою \"доказ частки\", упроваджений у рамках оновлення Beacon Chain.",
  "page-eth2-staking-docked": "Докладніше про злиття",
  "page-eth2-staking-dyor": "Перевіряйте інформацію власноруч",
  "page-eth2-staking-dyor-desc": "Жоден із перелічених сервісів для стейкінгу не є офіційним. Перевірте інформацію про них, щоб вибрати найкращий.",
  "page-eth2-staking-header-1": "Отримайте ETH, щоб стати валідатором Ethereum",
  "page-eth2-staking-how-much": "Скільки ви хочете вкласти?",
  "page-eth2-staking-how-to-stake": "Як вкласти частку",
  "page-eth2-staking-how-to-stake-desc": "Це залежить від того, скільки ви готові вкласти. Щоб стати повноправним валідатором, потрібно вкласти 32 ETH, однак ви можете вкласти менше.",
  "page-eth2-staking-join": "Приєднатися",
  "page-eth2-staking-join-community": "Приєднуйтеся до спільноти стейкерів",
  "page-eth2-staking-join-community-desc": "r/ethstaker – це загальнодоступна спільнота для обговорення стейкінгу в мережі Ethereum. Приєднуйтеся, щоб отримувати поради й підтримку та спілкуватися.",
  "page-eth2-staking-less-than": "Менше ніж",
  "page-eth2-staking-link-1": "Переглянути API серверної частини",
  "page-eth2-staking-meta-description": "Огляд стейкінгу в мережі Ethereum: ризики, винагороди, вимоги й сервіси.",
  "page-eth2-staking-meta-title": "Стейкінг у мережі Ethereum",
  "page-eth2-staking-more-sharding": "Докладніше про сегментування",
  "page-eth2-staking-pool": "Стейкінг у групі",
  "page-eth2-staking-pool-desc": "Якщо у вас менше ніж 32 ETH, ви можете внести меншу частку в групу стейкінгу. Так роблять деякі компанії, щоб ви не хвилювалися про підключення до Інтернету. Ось кілька таких компаній.",
  "page-eth2-staking-pos-explained": "Про доказ частки",
  "page-eth2-staking-pos-explained-desc": "Стейкінг – це те, чим потрібно займатися, щоб стати валідатором у систему доказів частки. Це механізм консенсусу, який згодом замінить поточну систему на основі доказів виконаної роботи. Механізми консенсусу забезпечують захист і децентралізованість блокчейнів на кшталт Ethereum.",
  "page-eth2-staking-pos-explained-desc-1": "Доказ частки дає змогу захистити мережу різними способами:",
  "page-eth2-staking-services": "Переглянути сервіси для стейкінгу",
  "page-eth2-staking-sharding": "Розблоковує сегментування",
  "page-eth2-staking-sharding-desc": "Сегментування даних можливе лише в системі на основі доказів частки. Якщо застосувати такий підхід у системі на основі доказів виконаної роботи, це послабить роботу комп’ютерів, які захищають мережу. Так зловмисним майнерам було б легше контролювати сегменти. Однак цього не станеться за умови рандомно призначених стейкерів у системі доказів частки.",
  "page-eth2-staking-solo": "Зробіть вклад і запустіть валідатор",
  "page-eth2-staking-solo-desc": "Щоб почати процес стейкінгу, потрібно скористатися панеллю запуску Eth2. На ній наведено вказівки з налаштування. Ви також маєте керувати клієнтом Eth2 – локальною копією блокчейну. Для завантаження на комп’ютер можна знадобитися деякий час.",
  "page-eth2-staking-start": "Зробити вклад",
  "page-eth2-staking-subtitle": "Стейкінг корисний для екосистеми Ethereum. Ви можете допомогти нам захистити мережу й разом із тим отримати винагороди.",
  "page-eth2-staking-sustainability": "Надійність",
  "page-eth2-staking-sustainability-desc": "Валідаторам не потрібні енергоємні комп’ютери для приєднання до системи доказів частки. Вистачить ноутбука або смартфона. Це зробить мережу Ethereum екологічнішою.",
  "page-eth2-staking-the-beacon-chain": "Докладніше про Beacon Chain",
  "page-eth2-staking-title-1": "Винагороди",
  "page-eth2-staking-title-2": "Ризики",
  "page-eth2-staking-title-3": "Вимоги",
  "page-eth2-staking-title-4": "Як вкласти ETH",
  "page-eth2-staking-upgrades-li": "Доказ частки керується оновленням Beacon Chain.",
  "page-eth2-staking-upgrades-li-2": "У майбутньому в мережі Ethereum функціонуватиме Beacon Chain (доказ частки) й основна мережа (доказ виконаної роботи). Остання використовується вже багато років.",
  "page-eth2-staking-upgrades-li-3": "Протягом цього часу власники часток додаватимуть нові блоки в Beacon Chain, але не оброблятимуть транзакції в основній мережі.",
  "page-eth2-staking-upgrades-li-4": "Мережа Ethereum повністю перейде на систему доказів частки, коли основна мережа Ethereum стане сегментом даних.",
  "page-eth2-staking-upgrades-li-5": "Тільки після цього ви зможете вивести свою частку.",
  "page-eth2-staking-upgrades-title": "Доказ частки й оновлення Eth2",
  "page-eth2-staking-validators": "Що більше валідаторів, то кращий захист",
  "page-eth2-staking-validators-desc": "Зламати блокчейн на зразок Ethereum можливо, лише якщо володіти 51% мережі. Наприклад, ви можете попросити 51% валідаторів підтвердити, що на вашому балансі 1 000 000 ETH, а не 1 ETH. Однак щоб контролювати 51% валідаторів, потрібно володіти 51% ETH у системі, а це багато!",
  "page-eth2-staking-withdrawals": "Виведення часток стане доступним згодом",
  "page-eth2-staking-withdrawals-desc": "Ви не зможете вивести свою частку, доки ми не запустимо майбутні оновлення. Це відбудеться після злиття основної мережі із системою Beacon Chain.",
  "page-eth2-vision-2014": "Переглянути допис у блозі від 2014 року з інформацією про доказ частки",
  "page-eth2-vision-beacon-chain": "Beacon Chain",
  "page-eth2-vision-beacon-chain-btn": "Докладніше про Beacon Chain",
  "page-eth2-vision-beacon-chain-date": "Beacon Chain запущено",
  "page-eth2-vision-beacon-chain-desc": "Перше оновлення Eth2 в екосистемі. У рамках Beacon Chain в мережу Ethereum впроваджено стейкінг і закладено основи для майбутній оновлень. Згодом це оновлення координуватиме нову систему.",
  "page-eth2-vision-beacon-chain-upper": "Beacon Chain",
  "page-eth2-vision-desc-1": "Мережа Ethereum повинна зменшити перевантаження мережі та покращити швидкість, щоб краще обслуговувати глобальну базу користувачів.",
  "page-eth2-vision-desc-2": "Оскільки мережа розширюється, запускати вузли стає все складніше. Якщо намагатися масштабувати мережу, це все тільки більше ускладнить.",
  "page-eth2-vision-desc-3": "Мережа Ethereum використовує надто багато електроенергії. Технології, які забезпечують захист мережі, мають бути екологічнішими.",
  "page-eth2-vision-docking-date": "Очікується: 2022",
  "page-eth2-vision-docking-desc": "Основна мережа Ethereum зрештою з’єднається із системою Beacon Chain. Це дозволить увімкнути стейкінг у всій мережі й свідчитиме про кінець єнергоємного майнінгу.",
  "page-eth2-vision-ethereum-node": "Докладніше про вузли",
  "page-eth2-vision-explore-upgrades": "Огляд оновлень",
  "page-eth2-vision-future": "Цифрове майбутнє в глобальному масштабі",
  "page-eth2-vision-meta-desc": "Огляд впливу оновлень Eth2 на мережу Ethereum і виклики, які потрібно подолати.",
  "page-eth2-vision-meta-title": "Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Докладніше про майнінг",
  "page-eth2-vision-problems": "Поточні проблеми",
  "page-eth2-vision-scalability": "Масштабованість",
  "page-eth2-vision-scalability-desc": "Мережа Ethereum повинна виконувати більше транзакцій за секунду, не збільшуючи розмір вузлів у мережі. Вузли – це життєво важливі компоненти мережі, які зберігають дан і керують блокчейном. Збільшення розміру вузла було б непрактичним рішенням, оскільки це можуть робити лише потужні й дорогі комп’ютери. Щоб масштабувати мережу, Ethereum має здійснювати транзакцій за секунду та мати більше вузлів. Це забезпечить надійніший захист.",
  "page-eth2-vision-scalability-desc-3": "Оновлення, пов’язане з ланцюгами сегментів даних, перенаправить навантаження мережі в 64 нові вузли. Так мережа Ethereum менше зависатиме й швидше оброблятиме транзакції, кількість яких буде більшою ніж 15–45 за секунду.",
  "page-eth2-vision-scalability-desc-4": "Хоч ланцюгів і стане більше, обсяг робити валідаторів (майнерів мережі) зменшиться. Їм потрібно буде лише керувати призначеними сегментами даних, а не всією мережею Ethereum. Це спростить вузли й дасть мережі Ethereum змогу масштабуватися, залишаючись децентралізованою.",
  "page-eth2-vision-security": "Безпека",
  "page-eth2-vision-security-desc": "Завдяки оновленням Eth2 мережу Ethereum краще захищено від координованих атак, зокрема таких, коли хтось володіє 51% мережі й може внести зловмисні зміни.",
  "page-eth2-vision-security-desc-3": "Перехід на систему доказів частки означає, що протокол Ethereum краще захищено від атак, оскільки в цій системі валідатори, що відповідають за безпеку мережі, мають вносити значну суму ETH у протокол. Якщо вони спробують атакувати мережу, протокол автоматично знищить їхні частки ETH.",
  "page-eth2-vision-security-desc-5": "У системі доказів виконаної роботи це неможливо, оскільки в разі порушень майнери, які захищають мережу, щонайбільше втратять отримані винагороди. Щоб досягти такого ж ефекту в цій системі, протокол повинен мати змогу знищити все обладнання майнерів, які намагаються обійти чи зламати систему.",
  "page-eth2-vision-security-desc-5-link": "Докладніше про доказ виконаної роботи",
  "page-eth2-vision-security-desc-8": "Модель захисту Ethereum також повинна змінитися через впровадження",
  "page-eth2-vision-security-desc-10": "Стейкінг також означає, що вам не потрібно інвестувати в придбання дорогих технічних засобів, щоб запустити вузол Ethereum. Ми сподіваємося, що це заохотить більше користувачів ставати валідаторами, покращить децентралізацію мережі й зменшить імовірність атак.",
  "page-eth2-vision-security-staking": "Частка ETH",
  "page-eth2-vision-security-validator": "Ви можете стати валідатором, якщо вкладете ETH.",
  "page-eth2-vision-shard-chains": "ланцюги сегментів даних",
  "page-eth2-vision-shard-date": "Очікується: 2021",
  "page-eth2-vision-shard-desc-4": "Ланцюги сегментів даних розподілятимуть навантаження мережі між 64 новими блокчейнами. Завдяки сегментам швидкість обробки транзакцій може неймовірно зрости – до 100 000 за секунду.",
  "page-eth2-vision-shard-upgrade": "Докладніше про ланцюги сегментів даних",
  "page-eth2-vision-staking-lower": "Докладніше про стейкінг",
  "page-eth2-vision-subtitle": "Допомагайте покращувати мережу Ethereum, доки вона не стане корисною для всього людства.",
  "page-eth2-vision-sustainability": "Стійкість",
  "page-eth2-vision-sustainability-desc-1": "Ні для кого не секрет, що Ethereum та інші блокчейни, такі як Bitcoin, витрачають багато енергії через майнінг.",
  "page-eth2-vision-sustainability-desc-2": "Однак ми прагнемо захищати Ethereum за допомогою ETH, а не комп’ютерної енергії. Для цього й потрібен стейкінг.",
  "page-eth2-vision-sustainability-desc-3": "Хоча стейкінг буде впроваджено в рамках оновлення Beacon Chain, мережа Ethereum, до якої ми звикли, працюватиме паралельно звичним чином аж до моменту її злиття і оновленнями Eth2. Одна система захищена ETH, інша – комп’ютерною енергією. Це відбувається тому, що ланцюги сегментів даних не витримали б наших акаунтів і децентралізованих програм. Ось чому ми не можемо просто забути про майнінг і основну мережу.",
  "page-eth2-vision-sustainability-desc-8": "Щойно буде запущено Beacon Chain і ланцюги сегментів даних, ми почнемо працювати над злиттям основної мережі з новою системою. Це перетворить основну мережу на сегмент даних і буде захищено ETH. Вона споживатиме набагато менше енергії.",
  "page-eth2-vision-sustainability-subtitle": "Мережа Ethereum має бути екологічнішою.",
  "page-eth2-vision-The": ".",
  "page-eth2-vision-title": "Бачення Eth2",
  "page-eth2-vision-title-1": "Перенавантажена мережа",
  "page-eth2-vision-title-2": "Місце на диску",
  "page-eth2-vision-title-3": "Забагато енергії",
  "page-eth2-vision-trilemma-cardtext-1": "Оновлення Eth2 допоможуть масштабувати мережу Ethereum і зроблять її надійнішою та децентралізованішою. Завдяки сегментуванню Ethereum оброблятиме більше транзакцій за секунду, а обсяг енергії, що витрачається на запуск вузла й підтвердження ланцюга, зменшиться. Оновлення Beacon Chain покращить захист Ethereum і координуватиме валідаторів у сегментах даних. Стейкінг привабить більшу кількість користувачів, мережа розшириться та стане більш децентралізованою.",
  "page-eth2-vision-trilemma-cardtext-2": "Безпечні й децентралізовані блокчейн-мережі вимагають перевірки кожної транзакції кожним вузлом. Ця кількість роботи обмежує кількість транзакцій, які можуть здійснюватися в певний час. Децентралізована та надійна – такою наразі є мережа Ethereum.",
  "page-eth2-vision-trilemma-cardtext-3": "Децентралізовані мережі працюють шляхом надсилання інформації про транзакції через вузли - вся мережа повинна знати про будь-які зміни стану. Масштабування транзакцій за секунду через децентралізовану мережу створює ризики для безпеки, оскільки що більше транзакцій, то довше затримка й вища ймовірність атаки під час передавання інформації.",
  "page-eth2-vision-trilemma-cardtext-4": "Збільшення розміру та потужності вузлів Ethereum може збільшити кількість транзакцій за секунду безпечним способом, але вимоги до технічного забезпечення обмежують коло осіб, хто міг би це зробити, і це загрожує децентралізації. Ми сподіваємося, що сегментування даних і доказ частки допоможуть Ethereum масштабуватися за рахунок збільшення кількості вузлів, а не їх розмірів.",
  "page-eth2-vision-trilemma-h2": "Проблема децентралізованого масштабування",
  "page-eth2-vision-trilemma-modal-tip": "Торкніться кіл нижче, щоб краще зрозуміти проблеми децентралізованого масштабування",
  "page-eth2-vision-trilemma-p": "Наївним способом вирішення проблем мережі Ethereum було б зробити її більш централізованою. Однак децентралізація є надто важливою. Саме вона дає змогу уникати цензури в мережі Ethereum, забезпечує відкритість, конфіденційність і захист, який майже неможливо зламати.",
  "page-eth2-vision-trilemma-p-1": "Мережа Ethereum має бути більш масштабною та захищеною, але при цьому залишатися децентралізованою. Досягти всіх трьох цілей важко. Це – трилема масштабованості.",
  "page-eth2-vision-trilemma-p-2": "Оновлення Eth2 націлені на вирішення цієї трилеми, однак існують деякі труднощі.",
  "page-eth2-vision-trilemma-press-button": "Натисніть кнопки в трикутнику, щоб краще зрозуміти проблеми децентралізованого масштабування.",
  "page-eth2-vision-trilemma-text-1": "Децентралізація",
  "page-eth2-vision-trilemma-text-2": "Безпека",
  "page-eth2-vision-trilemma-text-3": "Масштабованість",
  "page-eth2-vision-trilemma-title-1": "Дослідіть трилему масштабованості",
  "page-eth2-vision-trilemma-title-2": "Оновлення Eth2 і децентралізоване масштабування",
  "page-eth2-vision-trilemma-title-3": "Захищена й децентралізована",
  "page-eth2-vision-trilemma-title-4": "Децентралізована й масштабована",
  "page-eth2-vision-trilemma-title-5": "Масштабована й захищена",
  "page-eth2-vision-understanding": "Бачення Eth2",
  "page-eth2-vision-upgrade-needs": "Навіщо потрібні оновлення Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Протокол Ethereum, запущений у 2015 році, мав неймовірний успіх. Однак спільнота мережі Ethereum наполягає на внесенні деяких важливих змін, які допоможуть розкрити весь потенціал Ethereum.",
  "page-eth2-vision-upgrade-needs-desc-2": "Високий попит – це стимулювання плати за транзакцію, що робить мережу Ethereum дорогою для середньостатистичного користувача. Місце на диску, необхідне для запуску клієнта Ethereum збільшується все швидше. А алгоритм консенсусу в основі системи доказів виконаної роботи, який забезпечує безпеку й децентралізацію Ethereum, має великий вплив на навколишнє середовище.",
  "page-eth2-vision-upgrade-needs-desc-3": "Найчастіше Eth2 називають набір оновлень, націлених на вирішення цих проблем. Цей набір оновлень називався Serenity і був важливим предметом досліджень і обговорень із 2014 року.",
  "page-eth2-vision-upgrade-needs-desc-6": " Це означає, що різкий перехід на Eth2 неможливий. Оновлення запускатимуться поступово.",
  "page-eth2-vision-upgrade-needs-serenity": "Переглянути допис у блозі від 2015 року з інформацією про Serenity",
  "page-eth2-vision-uprade-needs-desc-5": "Тепер, коли технологія готова, ці оновлення будуть реконструювати мережу Ethereum, щоб зробити її більш масштабною, безпечною, надійною та зручною для наявних і нових користувачів, зберігаючи основну цінність Ethereum – децентралізованість.",
  "page-get-eth-cex": "Централізовані обмінники",
  "page-get-eth-cex-desc": "Обмінники – це компанії, які дають вам змогу купувати криптовалюту за традиційні гроші. Їм належать куплені вами ETH, доки ви не надішлете їх у гаманець, яким керуєте.",
  "page-get-eth-checkout-dapps-btn": "Переглянути додатки",
  "page-get-eth-community-safety": "Дописи в спільноті на тему безпеки",
  "page-get-eth-description": "Ethereum і ETH не контролюються жодним урядовим органом або компанією, вони децентралізовані. Це означає, що користуватися ETH можуть усі.",
  "page-get-eth-dex": "Децентралізовані обмінники (DEX)",
  "page-get-eth-dex-desc": "Щоб мати більше контролю, купуйте однорангові ETH. Завдяки децентралізованим обмінникам ви можете торгувати, не передаючи керування коштами централізованій компанії.",
  "page-get-eth-dexs": "Децентралізовані обмінники (DEX)",
  "page-get-eth-dexs-desc": "Децентралізовані обмінники – це відкриті торговельні майданчики, де можна продати й купити ETH та інші токени. У них продавці й покупці взаємодіють без посередників.",
  "page-get-eth-dexs-desc-2": "Замість надійних сторонніх компаній, відповідальних за захист коштів під час здійснення транзакції, такі обмінники використовують кодування. ETH продавця буде переказано, лише якщо платіж гарантовано. Цей тип кодування відомий як \"розумний контракт\".",
  "page-get-eth-dexs-desc-3": "Це означає меншу кількість географічних обмежень у порівнянні з централізованими альтернативами. Якщо хтось продає те, що вас цікавить, і приймає запропонований вами спосіб оплати, дійте. Децентралізовані обмінники дають змогу купувати ETH за інші токени, кошти на балансі PayPal і навіть шляхом особистої передачі готівки.",
  "page-get-eth-do-not-copy": "ПРИКЛАД: НЕ КОПІЮВАТИ",
  "page-get-eth-exchanges-disclaimer": "Ми зібрали цю інформацію вручну. Якщо ви помітили помилку, повідомте нас про це за адресою",
  "page-get-eth-exchanges-empty-state-text": "Введіть свою країну проживання, щоб переглянути список гаманців і обмінників, які ви можете використовувати для придбання ETH",
  "page-get-eth-exchanges-except": "За винятком",
  "page-get-eth-exchanges-header": "У якій країні ви мешкаєте?",
  "page-get-eth-exchanges-header-exchanges": "Обмінники",
  "page-get-eth-exchanges-header-wallets": "Гаманці",
  "page-get-eth-exchanges-intro": "Обмінники й гаманці можуть продавати криптовалюту не в усіх країнах.",
  "page-get-eth-exchanges-no-exchanges": "На жаль, у цій країні немає обмінників, у яких ви могли б придбати ETH. Якщо такі є, повідомте нас за адресою",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "На жаль, у цій країні немає обмінників або гаманців, у яких ви могли б придбати ETH. Якщо такі є, повідомте нас за адресою",
  "page-get-eth-exchanges-no-wallets": "На жаль, у цій країні немає гаманців, у яких ви могли б придбати ETH. Якщо такі є, повідомте нас за адресою",
  "page-get-eth-exchanges-success-exchange": "Реєстрація в обміннику може тривати кілька днів у зв’язку з юридичними перевірками.",
  "page-get-eth-exchanges-success-wallet-link": "гаманці",
  "page-get-eth-exchanges-success-wallet-paragraph": "У цій країні можна придбати ETH прямо в наведених тут гаманцях. Докладніше про",
  "page-get-eth-exchanges-usa": "Сполучені Штати Америки (США)",
  "page-get-eth-get-wallet-btn": "Створити гаманець",
  "page-get-eth-hero-image-alt": "Отримати зображення героя ETH",
  "page-get-eth-keep-it-safe": "Захист ваших ETH",
  "page-get-eth-meta-description": "Як придбати валюту ETH залежно від країни проживання й отримати поради щодо її зберігання.",
  "page-get-eth-meta-title": "Як купити ETH",
  "page-get-eth-need-wallet": "Щоб використовувати децентралізовані обмінники, потрібно мати гаманець.",
  "page-get-eth-new-to-eth": "Ще не користувалися ETH? Нижче наведено поради для початку роботи.",
  "page-get-eth-other-cryptos": "Купуйте за іншу криптовалюту",
  "page-get-eth-protect-eth-desc": "Якщо ви плануєте купити багато ETH, радимо тримати їх у гаманці, який ви контролюєте, а не в обміннику, який із великою ймовірністю може стати ціллю для хакерів. Якщо хакер отримає доступ, ви можете втратити свої кошти. Крім того, тільки ви керуєте своїм гаманцем.",
  "page-get-eth-protect-eth-in-wallet": "Як захистити ETH у гаманці",
  "page-get-eth-search-by-country": "Шукати за країною",
  "page-get-eth-security": "Однак це також означає, що до безпеки коштів слід ставитися серйозно. Використовуючи ETH, ви покладаєтеся не на банк, а лише на себе.",
  "page-get-eth-smart-contract-link": "Докладніше про розумні контракти",
  "page-get-eth-swapping": "Обмінюйте свої токени на ETH інших користувачів і навпаки.",
  "page-get-eth-traditional-currencies": "Купуйте за традиційні гроші",
  "page-get-eth-traditional-payments": "Купуйте ETH безпосередньо в продавців за допомогою традиційних платіжних систем.",
  "page-get-eth-try-dex": "Знайти обмінник",
  "page-get-eth-use-your-eth": "Як використовувати ETH",
  "page-get-eth-use-your-eth-dapps": "Оскільки тепер у вас є ETH, радимо переглянути деякі децентралізовані програми Ethereum у категоріях фінансів, соціальних мереж, ігор тощо.",
  "page-get-eth-wallet-instructions": "Дотримуйтеся вказівок у гаманці",
  "page-get-eth-wallet-instructions-lost": "Якщо ви втратите доступ до гаманця, то втратите доступ і до своїх коштів. У гаманці можна знайти вказівки щодо того, як цьому запобігти. Чітко дотримуйтеся їх, адже якщо ви втратите доступ до гаманця, скоріш за все, вам ніхто не зможе допомогти.",
  "page-get-eth-wallets": "Гаманці",
  "page-get-eth-wallets-link": "Докладніше про гаманці",
  "page-get-eth-wallets-purchasing": "У деяких гаманцях криптовалюту можна придбати за допомогою кредитної чи дебетової картки та навіть Apple Pay. Застосовуються географічні обмеження.",
  "page-get-eth-warning": "Ці децентралізовані обмінники не для початківців, оскільки щоб користуватися ними, потрібно мати достатню кількість ETH.",
  "page-get-eth-what-are-DEX's": "Що таке децентралізовані обмінники?",
  "page-get-eth-whats-eth-link": "Що таке ETH?",
  "page-get-eth-where-to-buy-desc": "Ви можете придбати ETH прямо в обміннику або гаманці.",
  "page-get-eth-where-to-buy-desc-2": "Перевірте, якими сервісами можна скористатися у вашій країні.",
  "page-get-eth-where-to-buy-title": "Де купити ETH",
  "page-get-eth-your-address": "Ваша адреса ETH",
  "page-get-eth-your-address-desc": "Під час завантаження гаманця для вас буде створено публічну адресу ETH. Ось як вона виглядає:",
  "page-get-eth-your-address-desc-3": "Вона схожа на адресу електронної пошти, однак замість листів на неї надходять ETH. Щоб переказати ETH з обмінника в гаманець, використовуйте адресу як цільовий сервіс. Не забувайте перевіряти всю інформацію ще раз, перш ніж надсилати кошти!",
  "page-get-eth-your-address-wallet-link": "Переглянути гаманці",
  "page-index-hero-image-alt": "банер на ethereum.org",
  "page-index-meta-description": "Ethereum — це глобальна децентралізована платформа для заробітку грошей і створення нових програм. На ній можна написати код, який контролюватиме обмін коштами, і розробити програми, доступні звідусіль.",
  "page-index-meta-title": "Головна",
  "page-index-title": "Ethereum — це глобальна платформа з відкритим вихідним кодом для децентралізованих програм.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Доступні мови",
  "page-languages-interested": "Хочете зробити внесок?",
  "page-languages-learn-more": "Дізнайтеся більше про нашу програму перекладів",
  "page-languages-meta-desc": "Ресурси для всіх підтримуваних мов на ethereum.org і способи зробити внесок у переклади.",
  "page-languages-meta-title": "Переклад сайту ethereum.org на різні мови",
  "page-languages-p1": "Ethereum - це глобальний проект, і критично важливо, щоб ethereum.org був доступний всім, незалежно від національності чи мови. Наша спільнота прагне втілити цю ідею в реальність.",
  "page-languages-translations-available": "Сайт ethereum.org доступний наведеними нижче мовами.",
  "page-languages-want-more-header": "Хочете перекласти сайт ethereum.org іншою мовою?",
  "page-languages-want-more-link": "програму перекладів",
  "page-languages-want-more-paragraph": "Перекладачі прагнуть перекласти сайт ethereum.org на максимально можливу кількість мов. Щоб побачити, над чим вони зараз працюють, або приєднатися до команди, дізнайтеся більше про нашу",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Застави в криптовалюті",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Завдяки Ethereum ви можете позичати кошти безпосередньо в інших користувачів, не продаючи власні ETH. Це надає вам перевагу. Дехто так робить, щоб накопичувати більше ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Але оскільки ціна ETH є мінливою, вам потрібно буде внести більшу заставу. Це означає, що якщо ви хочете позичити 100 стабільних монет, у вас мають бути ETH вартістю щонайменше 150 дол. США. Це захищає систему та кредиторів.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Докладніше про стабільні монети в криптовалюті",
  "page-stablecoins-accordion-borrow-pill": "Для досвідчених користувачів",
  "page-stablecoins-accordion-borrow-places-intro": "Ці децентралізовані програми дають змогу позичати стабільні монети, використовуючи криптовалюту як заставу. Деякі програми також приймають інші токени й ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Де можна позичити стабільні монети",
  "page-stablecoins-accordion-borrow-requirement-1": "Гаманець Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Щоб користуватися децентралізованою програмою, потрібен гаманець",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Вам потрібні будуть ETH для сплати застави та/або комісій за транзакцію",
  "page-stablecoins-accordion-borrow-requirements-description": "Щоб позичити стабільні монети, потрібно користуватися правильною децентралізованою програмою. Вам також знадобиться гаманець і ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Якщо ви використовуєте ETH як заставу, а цінність цієї валюти впаде, то застава не покриє кількість створених стабільних монет. Це призведе до того, що ваші ETH буде ліквідовано, а ви отримаєте штраф. Тому позичаючи стабільні монети, звертайте увагу на ціну ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Остання ціна ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Ризики",
  "page-stablecoins-accordion-borrow-text-preview": "Ви можете позичати стабільні монети, використовуючи криптовалюту як заставу, яку потрібно повернути.",
  "page-stablecoins-accordion-borrow-title": "Позичайте",
  "page-stablecoins-accordion-buy-exchanges-title": "Популярні обмінники",
  "page-stablecoins-accordion-buy-requirement-1": "Обмінники криптовалют і гаманці",
  "page-stablecoins-accordion-buy-requirement-1-description": "Перевірте, якими сервісами можна скористатися у вашій країні",
  "page-stablecoins-accordion-buy-requirements-description": "Акаунт із обмінником або гаманцем, у яких можна придбати криптовалюту. Можливо, ви вже використовували один із них, що отримати ETH. Перевірте, якими сервісами можна скористатися у вашій країні.",
  "page-stablecoins-accordion-buy-text-preview": "У багатьох обмінниках і гаманцях можна купити стабільні монети. Застосовуватимуться географічні обмеження.",
  "page-stablecoins-accordion-buy-title": "Купуйте",
  "page-stablecoins-accordion-buy-warning": "У централізованих обмінниках можна придбати лише кредитно-паперові стабільні монети, зокрема USDC, Tether тощо. Можливо, ви не купите їх безпосередньо, однак зможете обміняти на них ETH або інші криптовалюти, які можна придбати на платформі.",
  "page-stablecoins-accordion-earn-project-1-description": "Переважно технічні роботи в рамках руху до створення програмного забезпечення з відкритим кодом.",
  "page-stablecoins-accordion-earn-project-2-description": "Технології, контент та інші роботи для спільноти MakerDao (команда, що розробила Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Якщо ви дійсно розумієтеся на темі, знаходьте помилки й отримуйте Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Винагороди Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Винагороди за знайдення помилок в оновленні Eth2",
  "page-stablecoins-accordion-earn-project-community": "Спільнота MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Це платформи, які заплатять вам стабільними монетами за вашу роботу.",
  "page-stablecoins-accordion-earn-projects-title": "Де заробити стабільні монети",
  "page-stablecoins-accordion-earn-requirement-1": "Гаманець Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Щоб отримати зароблені стабільні монети, вам знадобиться гаманець",
  "page-stablecoins-accordion-earn-requirements-description": "Стабільні монети – це чудовий спосіб оплати праці та послуг завдяки їх стабільній вартості. Однак щоб отримати такі монети, вам знадобиться гаманець.",
  "page-stablecoins-accordion-earn-text-preview": "Ви можете заробляти стабільні монети, працюючи над проектами в екосистемі Ethereum.",
  "page-stablecoins-accordion-earn-title": "Заробляйте",
  "page-stablecoins-accordion-less": "Менше",
  "page-stablecoins-accordion-more": "Більше",
  "page-stablecoins-accordion-requirements": "Що вам знадобиться",
  "page-stablecoins-accordion-swap-dapp-intro": "Якщо у вас вже є ETH і гаманець, ви можете використати обмінювати стабільні монети в децентралізованих програмах.",
  "page-stablecoins-accordion-swap-dapp-link": "Детальніше про децентралізовані обмінники",
  "page-stablecoins-accordion-swap-dapp-title": "Децентралізовані програми для обміну токенів",
  "page-stablecoins-accordion-swap-editors-tip": "Порада від редактора",
  "page-stablecoins-accordion-swap-editors-tip-button": "Знайти гаманці",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Знайдіть гаманець, який дасть вам змогу купувати валюту ETH та обмінювати її на токени, такі як стабільні монети, і навпаки.",
  "page-stablecoins-accordion-swap-pill": "Рекомендовано",
  "page-stablecoins-accordion-swap-requirement-1": "Гаманець Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Вам буде потрібен гаманець для авторизації обмінів і зберігання монет",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Щоб сплачувати за обмін",
  "page-stablecoins-accordion-swap-text-preview": "Стабільні монети можна придбати в децентралізованих обмінниках. У них ви можете обміняти будь-які токени на потрібні стабільні монети.",
  "page-stablecoins-accordion-swap-title": "Oбмін",
  "page-stablecoins-algorithmic": "Захищено алгоритмом",
  "page-stablecoins-algorithmic-con-1": "Ви повинні довіряти алгоритму або вміти його читати.",
  "page-stablecoins-algorithmic-con-2": "Баланс монет змінюватиметься залежно від загальної суми.",
  "page-stablecoins-algorithmic-description": "Ці стабільні монети не підкріплені іншими об’єктами. Натомість алгоритм продаватиме токени, якщо ціна падатиме нижче бажаного значення, і купуватиме їх, якщо значення перевищуватиме бажану суму. Оскільки кількість цих токенів в обігу змінюється регулярно, кількість токенів, якими ви володієте, завжди змінюватиметься. Показуватиметься ваша частка.",
  "page-stablecoins-algorithmic-pro-1": "Застава не потрібна.",
  "page-stablecoins-algorithmic-pro-2": "Керується публічним алгоритмом.",
  "page-stablecoins-bank-apy": "0.05%",
  "page-stablecoins-bank-apy-source": "Середня ставка, що виплачується банками на основних федерально застрахованих ощадних рахунках у США.",
  "page-stablecoins-bank-apy-source-link": "Джерело",
  "page-stablecoins-bitcoin-pizza": "Сумнозвісна піца Bitcoin",
  "page-stablecoins-bitcoin-pizza-body": "У 2010 році хтось купив 2 піци за 10 000 BTC. На той час вони коштували ~41 долар США. Сьогодні на ринку це мільйони доларів. Історія Ethereum налічує багато аналогічних помилкових транзакцій. Стабільні монети вирішують цю проблему, тож ви можете насолоджуватися піцою, не використовуючи ETH.",
  "page-stablecoins-coin-price-change": "Зміна ціни монети (за останні 30 днів)",
  "page-stablecoins-crypto-backed": "Захищено криптовалютою",
  "page-stablecoins-crypto-backed-con-1": "Менш стабільні, ніж кредитно-паперові стабільні монети.",
  "page-stablecoins-crypto-backed-con-2": "Вам потрібно слідкувати за вартістю застави в криптовалюті.",
  "page-stablecoins-crypto-backed-description": "Ці стабільні монети підкріплені іншими криптовалютами, як-от ETH. Їх ціна залежить від вартості основного об’єкта (або застави), яка може змінюватися. Оскільки цінність ETH може коливатися, кількість стабільних монет завжди має бути більшою, щоб гарантувати стабільність цін. Іншими словами, 1 стабільна монета еквівалентом в 1 дол. США має основний об’єкт вартістю щонайменше 2 дол. США. Тому якщо ціна ETH падає, для підтримки стабільної монети треба використовувати більше ETH, інакше такі монети втратять свою цінність.",
  "page-stablecoins-crypto-backed-pro-1": "Прозора й повністю децентралізована.",
  "page-stablecoins-crypto-backed-pro-2": "Швидко перетворити на інші криптовалюти.",
  "page-stablecoins-crypto-backed-pro-3": "Немає зовнішніх зберігачів – усі активи контролюються акаунтами Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai – це, мабуть, найвідоміша децентралізована стабільна монета. ЇЇ цінність становить приблизно 1 дол. США. Ця монета приймається багатьма децентралізованими програмами.",
  "page-stablecoins-dai-banner-learn-button": "Докладніше про Dai",
  "page-stablecoins-dai-banner-swap-button": "Обміняти ETH на Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "The Dai logo",
  "page-stablecoins-editors-choice": "Вибір редакції",
  "page-stablecoins-editors-choice-intro": "Це, мабуть, найвідоміші приклади стабільних монет і монети, які можуть бути корисними при використанні додатків.",
  "page-stablecoins-explore-dapps": "Переглянути програми",
  "page-stablecoins-fiat-backed": "Захищено кредитно-паперовими об’єктами",
  "page-stablecoins-fiat-backed-con-1": "Централізовано – хтось повинен видавати токени.",
  "page-stablecoins-fiat-backed-con-2": "Потрібен аудит, щоб переконатися, що компанія має достатні резерви.",
  "page-stablecoins-fiat-backed-description": "Зазвичай боргове зобов’язання за традиційну валюту (зазвичай долари). Ви використовуєте свою валюту для придбання стабільних монет, які пізніше можна буде перевести в готівку й обміняти на валюту країни.",
  "page-stablecoins-fiat-backed-pro-1": "Захищено від мінливості криптовалюти.",
  "page-stablecoins-fiat-backed-pro-2": "Зміни в ціні мінімальні.",
  "page-stablecoins-find-stablecoin": "Як знайти стабільну монету",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Як отримати стабільні монети",
  "page-stablecoins-find-stablecoin-intro": "Існують сотні стабільних монет. Ось деякі з них, щоб допомогти вам розпочати роботу. Якщо ви ще не використовували Ethereum, радимо спершу ознайомитися з деякими матеріалами.",
  "page-stablecoins-find-stablecoin-types-link": "Різні типи стабільних монет",
  "page-stablecoins-get-stablecoins": "Як отримати стабільні монети",
  "page-stablecoins-hero-alt": "Три найбільші компанії зі створення стабільних монет (за ринковою вартістю капіталу): Dai, USDC та Tether.",
  "page-stablecoins-hero-button": "Отримати стабільні монети",
  "page-stablecoins-hero-header": "Цифрові гроші для повсякденного використання",
  "page-stablecoins-hero-subtitle": "Стабільні монети – це токени Ethereum, створені, щоб зберігати фіксоване значення, навіть якщо ціна ETH змінюється.",
  "page-stablecoins-interest-earning-dapps": "Децентралізовані додатки для отримання відсотків",
  "page-stablecoins-meta-description": "Про стабільні монети Ethereum: що це, як їх отримати та навіщо вони потрібні.",
  "page-stablecoins-precious-metals": "Дорогоцінні метали",
  "page-stablecoins-precious-metals-con-1": "Централізовано – хтось повинен видавати токени.",
  "page-stablecoins-precious-metals-con-2": "Вам потрібно довіряти емітенту токена і постачальникам дорогоцінних металів.",
  "page-stablecoins-precious-metals-description": "Як і у випадку з кредитно-паперовими монетами, використовуйте замість цих стабільних монет інші ресурси, як-от золото. Це забезпечить збереження їх вартості.",
  "page-stablecoins-precious-metals-pro-1": "Захищено від мінливості криптовалюти.",
  "page-stablecoins-prices": "Ціни на стабільні монети",
  "page-stablecoins-prices-definition": "Стабільні монети — це криптовалюти, вартість яких не змінюється. Вони мають багато функцій ETH, але їх цінність полягає в стабільній валюті, схожій на традиційну. Ви маєте доступ до стабільних грошей, які можна використовувати в мережі Ethereum. ",
  "page-stablecoins-prices-definition-how": "Як монети стають стабільними",
  "page-stablecoins-research-warning": "Ethereum – це нова технологія, і більшість програм є новими. Переконайся, що ви усвідомлюєте ризики й вкладаєте кошти, лише якщо можете дозволити собі програти.",
  "page-stablecoins-research-warning-title": "Завжди власноруч перевіряйте інформацію",
  "page-stablecoins-save-stablecoins": "Заощаджуйте зі стабільними монетами",
  "page-stablecoins-save-stablecoins-body": "Стабільні монети часто мають середню процентну ставку, тому що їх багато хто позичає. Є децентралізовані програми, які дають змогу отримувати відсотки від стабільних монет в режимі реального часу. Так само, як у банківській справі, ви постачаєте токени позичальникам, але можете будь-коли вивести свої токени та відсотки.",
  "page-stablecoins-saving": "Раціонально використовуйте стабільні монети, щоб заробляти відсотки. Як і все в криптовалюті, прогнозоване значення щорічної процентної дохідності може щодня змінюватися залежно від попиту й пропозиції в реальному часі.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Перегляньте децентралізовані програми Ethereum – часто стабільні монети приносять більше користі, якщо їх використовувати для повсякденних транзакцій.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Ілюстрація собаки.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Як використовувати стабільні монети",
  "page-stablecoins-stablecoins-dapp-description-1": "Ринок багатьох стабільних монет, зокрема Dai, USDC, TUSD, USDT тощо. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Позичайте стабільні монети, заробляйте відсотки й отримуйте токени $COMP, Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Торгова платформа, де ви можете заробити відсотки на Dai і USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Застосунок призначений для заощаджень на Dai.",
  "page-stablecoins-stablecoins-feature-1": "Стабільні монети глобальні, ними можна обмінюватися в Інтернеті. Їх також можна легко отримати або надіслати, якщо у вас є обліковий запис Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "Попит на стабільні монети високий, тому ви можете заробити відсотки, якщо позичатимете їх. Перш ніж це робити, переконайтеся, що ви усвідомлюєте ризики.",
  "page-stablecoins-stablecoins-feature-3": "Стабільні монети можна обміняти за ETH та інші токени Ethereum. Багато децентралізованих програм покладаються на такі монети.",
  "page-stablecoins-stablecoins-feature-4": "Стабільні монети захищені криптографією. Ніхто не може здійснювати транзакції від вашого імені.",
  "page-stablecoins-stablecoins-meta-description": "Про стабільні монети Ethereum: що це, як їх отримати та навіщо вони потрібні.",
  "page-stablecoins-stablecoins-table-header-column-1": "Валюта",
  "page-stablecoins-stablecoins-table-header-column-2": "Ринкова капіталізація",
  "page-stablecoins-stablecoins-table-header-column-3": "Тип застави",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Криптовалюта",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Кредитно-паперові гроші",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Дорогоцінні метали",
  "page-stablecoins-table-error": "Не вдалося завантажити стабільні монети. Спробуйте оновити сторінку.",
  "page-stablecoins-table-loading": "Завантаження даних про стабільні монети...",
  "page-stablecoins-title": "Стабільні монети",
  "page-stablecoins-top-coins": "Найкращі стабільні монети за ринковою ціною капіталу",
  "page-stablecoins-top-coins-intro": "Ринкова капіталізація – це",
  "page-stablecoins-top-coins-intro-code": "загальна кількість наявних токенів, помножена на вартість кожного токена. Цей список є динамічним, тому перераховані тут проекти не обов’язково втілюються командою ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Як це працює: типи стабільних монет",
  "page-stablecoins-usdc-banner-body": "USDC – це, мабуть, найвідоміша кредитно-паперова стабільна монета. Її цінність становить приблизно 1 дол. США. Ця монета приймається такими програмами, як Circle і Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Докладніше про USDC",
  "page-stablecoins-usdc-banner-swap-button": "Обміняти ETH на USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "The USDC logo",
  "page-stablecoins-why-stablecoins": "Чому стабільні монети?",
  "page-stablecoins-how-they-work-button": "Як вони працюють",
  "page-stablecoins-why-stablecoins-body": "Ціна ETH, як і Bitcoin, змінюється, оскільки це нова технологія. Тому не радимо регулярно витрачати ці гроші. Стабільні монети відображають вартість традиційних валют, щоб надати вам доступ до стабільних грошей, які можна використовувати в мережі Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Якщо ви хочете, щоб ми додали гаманець,",
  "page-find-wallet-alpha-logo-alt": "AlphaWallet logo",
  "page-find-wallet-ambo-logo-alt": "Ambo logo",
  "page-find-wallet-argent-logo-alt": "Argent logo",
  "page-find-wallet-buy-card": "Купіть криптовалюту за допомогою картки",
  "page-find-wallet-buy-card-desc": "Купіть ETH прямо через гаманець, до якого прив’язано банківську картку. Можуть застосовуватися географічні обмеження.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Перегляньте децентралізовані додатки",
  "page-find-wallet-clear": "Очистити фільтри",
  "page-find-wallet-coinbase-logo-alt": "Coinbase logo",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Виберіть гаманець на основі функцій, що вас цікавлять.",
  "page-find-wallet-description": "Гаманці мають багато додаткових функцій, які можуть вам сподобатися.",
  "page-find-wallet-description-alpha": "Гаманець Ethereum із повністю відкритим кодом який використовує захищений анклав на мобільних пристроях, пропонує повну підтримку тестової мережі і відповідає стандарту TokenScript.",
  "page-find-wallet-description-ambo": "Переходьте відразу до інвестицій і здійсніть першу за кілька хвилин після завантаження програми",
  "page-find-wallet-description-argent": "Отримуйте відсотки, інвестуйте, позичайте, зберігайте й надсилайте кошти за один клік. Отримайте повний контроль.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Захищений додаток для зберігання криптовалюти",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma – ваш портал у DeFi. Фрази-паролі й розширення Chrome більше не потрібні.",
  "page-find-wallet-description-enjin": "Захищена й зручна програма з безліччю функцій, розроблена для трейдерів, геймерів і розробників",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Найнадійніша платформа для зберігання цифрових об’єктів у мережі Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken – це захищений і легкий у користуванні гаманець, якому довіряють мільйони користувачів",
  "page-find-wallet-description-ledger": "Захистіть об’єкти за допомогою найвищих стандартів безпеки",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Почніть досліджувати блокчейн-програми за секунди. Ними користується понад 1 мільйон користувачів з усього світу.",
  "page-find-wallet-description-monolith": "Єдиний у світі гаманець із власною системою захисту й підтримкою дебетних карток Visa. Доступний у Великій Британії та Європі, використовується в усьому світі.",
  "page-find-wallet-description-multis": "Multis – це акаунт криптовалют для компаній. Завдяки Multis компанії можуть зберігати об’єкти, керувати ними, отримувати відсотки від заощаджень, керувати платежами й вести облік.",
  "page-find-wallet-description-mycrypto": "MyCrypto – програма для керування всіма акаунтами. Обмінюйте, надсилайте й купуйте криптовалюту за допомогою таких гаманців, як MetaMask, Ledger, Trezor тощо.",
  "page-find-wallet-description-myetherwallet": "Безкоштовний клієнтський інтерфейс, який допомагає взаємодіяти з блокчейном Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Самостійний гаманець, що належить спільноті, з власною платіжною мережею L2.",
  "page-find-wallet-description-portis": "Самостійний блокчейн-гаманець, який робить програми простими для всіх",
  "page-find-wallet-description-rainbow": "Краще місце для зберігання ваших об’єктів Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Безпечна програма для обміну повідомленнями, крипто-гаманець і веб-переглядач Web3, створений за використання найсучасніших технологій",
  "page-find-wallet-description-tokenpocket": "TokenPocket – безпечний і зручний цифровий валютний гаманець та портал для децентралізованих програм із підтримкою багатоканальної мережі. Цей гаманець є провідним у світі.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Вхід у Web 3.0 за один клік",
  "page-find-wallet-description-trezor": "Перший і оригінальний апаратний гаманець",
  "page-find-wallet-description-trust": "Trust Wallet – децентралізований мультимонетний крипто-гаманець. Купуйте криптовалюту, переглядайте децентралізовані програми й обмінюйте об’єкти, контролюючи ключі.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo – це перший крипто-гаманець без ключів. Завдяки ZenGo вам не потрібно зберігати й контролювати приватні ключі, паролі або фрази-паролі. Купуйте, продавайте, заробляйте й зберігайте Ethereum легко й безпечно",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Dharma logo",
  "page-find-wallet-enjin-logo-alt": "Enjin logo",
  "page-find-wallet-Ethereum-wallets": "Гаманці Ethereum",
  "page-find-wallet-explore-dapps": "Огляд децентралізованих програм",
  "page-find-wallet-explore-dapps-desc": "Ці гаманці створено, щоб ви могли підключати децентралізовані програми Ethereum.",
  "page-find-wallet-feature-h2": "Виберіть функції гаманців, що вас цікавлять",
  "page-find-wallet-fi-tools": "Доступ до фінансових інструментів",
  "page-find-wallet-fi-tools-desc": "Позичайте кошти й отримуйте відсотки прямо в гаманці.",
  "page-find-wallet-following-features": "з такими функціями:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Gnosis Safe logo",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Зображення героя для пошуку гаманця",
  "page-find-wallet-imtoken-logo-alt": "imToken logo",
  "page-find-wallet-last-updated": "Останнє оновлення",
  "page-find-wallet-ledger-logo-alt": "Ledger logo",
  "page-find-wallet-limits": "Захист лімітів",
  "page-find-wallet-limits-desc": "Захистіть свої об’єкти, встановивши ліміти, що запобігатимуть викраденню коштів із вашого акаунта.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "правила розміщення",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Знайдіть і порівняйте гаманці Ethereum на основі функцій, які вас цікавлять.",
  "page-find-wallet-meta-title": "Як знайти гаманець Ethereum",
  "page-find-wallet-metamask-logo-alt": "MetaMask logo",
  "page-find-wallet-monolith-logo-alt": "Monolith logo",
  "page-find-wallet-multis-logo-alt": "Multis logo",
  "page-find-wallet-multisig": "Акаунти з кількома підписами",
  "page-find-wallet-multisig-desc": "З міркувань посилення захисту для гаманців із кількома підписами потрібно мати більше ніж один акаунт для підтвердження деяких транзакцій.",
  "page-find-wallet-mycrypto-logo-alt": "MyCrypto logo",
  "page-find-wallet-myetherwallet-logo-alt": "MyEtherWallet logo",
  "page-find-wallet-new-to-wallets": "Ще не користувалися гаманцями? Нижче наведено поради для початку роботи.",
  "page-find-wallet-new-to-wallets-link": "Гаманці Ethereum",
  "page-find-wallet-not-all-features": "Жоден гаманець не має усіх цих функцій",
  "page-find-wallet-not-endorsements": "Гаманці, перераховані на цій сторінці, не є офіційними схваленнями, і надаються тільки в інформаційних цілях. Їхні описи надано компаніями з розробки гаманців. Ми додаємо продукти на цю сторінку за критеріями наших",
  "page-find-wallet-overwhelmed": "гаманців Ethereum (їх наведено нижче). Зацікавились? Спробуйте відфільтрувати їх.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Pillar logo",
  "page-find-wallet-portis-logo-alt": "Portis logo",
  "page-find-wallet-rainbow-logo-alt": "Rainbow logo",
  "page-find-wallet-raise-an-issue": "повідомте про проблему на GitHub",
  "page-find-wallet-search-btn": "Шукати вибрані функції",
  "page-find-wallet-showing": "Показано ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Status logo",
  "page-find-wallet-swaps": "Децентралізований обмін токенів",
  "page-find-wallet-swaps-desc": "Торгуйте ETH та іншими токенами прямо в гаманці.",
  "page-find-wallet-title": "Знайти гаманець",
  "page-find-wallet-tokenpocket-logo-alt": "TokenPocket logo",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Torus logo",
  "page-find-wallet-trezor-logo-alt": "Trezor logo",
  "page-find-wallet-trust-logo-alt": "Trust logo",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Спробуйте вилучити одну або дві функції",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Оскільки тепер у вас є гаманець, радимо переглянути деякі децентралізовані програми Ethereum у категоріях фінансів, соціальних мереж, ігор тощо.",
  "page-find-wallet-use-your-wallet": "Скористайтеся своїм гаманцем",
  "page-find-wallet-voluem-desc": "Щоб зберігати багато одиниць ETH, виберіть гаманець, який дасть вам змогу придбати понад 2000 ETH за раз.",
  "page-find-wallet-volume": "Покупки з великим обсягом",
  "page-find-wallet-we-found": "Ми знайшли",
  "page-find-wallet-withdraw": "Вивести на банківський рахунок",
  "page-find-wallet-withdraw-desc": "Ви можете виводити ETH прямо на банківський рахунок. Для цього не потрібно використовувати обмінник.",
  "page-find-wallet-yet": "ще",
  "page-find-wallet-zengo-logo-alt": "ZenGo logo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Частка ETH",
  "page-wallets-accounts-addresses": "Гаманці, акаунти й адреси",
  "page-wallets-accounts-addresses-desc": "Важливо розуміти різницю між деякими ключовими термінами.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Ілюстрація робота в тілі, що репрезентує гаманець Ethereum",
  "page-wallets-ethereum-account": "Акаунт Ethereum",
  "page-wallets-blog": "Блог Coinbase",
  "page-wallets-bookmarking": "Додайте гаманець у закладки",
  "page-wallets-bookmarking-desc": "Якщо ви використовуєте веб-гаманець, додайте в закладки сайт, щоб захистити себе від спроб фішингу.",
  "page-wallets-cd": "Звичайні гаманці, які дають змогу зберігати криптовалюту офлайн (дуже надійний спосіб)",
  "page-wallets-converted": "Конвертація криптовалюти",
  "page-wallets-converted-desc": "Якщо ви хочете, щоб кошти були у вас на руках, радимо використовувати звичайний гаманець, оскільки він безпечніший за інші. Або гаманець із попередженнями про шахрайство й ліміт на виведення.",
  "page-wallets-curious": "Цікавитеся криптовалютами?",
  "page-wallets-curious-desc": "Якщо ви новачок у криптографії і просто хочете зрозуміти, як усе працює, радимо дослідити програми Ethereum або придбати перші одиниці ETH безпосередньо з гаманця.",
  "page-wallets-desc-2": "Щоб надсилати кошти й керувати ETH, потрібно мати гаманець.",
  "page-wallets-desc-2-link": "Докладніше про ETH",
  "page-wallets-desc-3": "Ваш гаманець – це лише інструмент для керування акаунтом Ethereum. Це означає, що ви можете будь-коли змінити постачальників гаманців. За допомогою кількох гаманців можна керувати кількома акаунтами Ethereum з однієї програми.",
  "page-wallets-desc-4": "Це тому, що гаманці не мають права зберігати ваші кошти, а ви маєте. Гаманці – це просто інструмент керування тим, що дійсно належить вам.",
  "page-wallets-description": "Гаманці Ethereum – це програми, завдяки яким можна взаємодіяти з акаунтом Ethereum. Вважайте їх програмами онлайн-банкінгу, не пов’язаними власне з банками. Гаманець дає змогу переглядати баланс, надсилати транзакції та підключати програми.",
  "page-wallets-desktop": "Комп’ютерні програми на випадок, якщо ви віддаєте перевагу керуванню коштами через MacOS, Windows або Linux",
  "page-wallets-ethereum-wallet": "Гаманець",
  "page-wallets-explore": "Огляд Ethereum",
  "page-wallets-features-desc": "Ми можемо допомогти вам обрати ваш гаманець на основі функцій, які вас цікавлять.",
  "page-wallets-features-title": "Вибрати на основі функцій?",
  "page-wallets-find-wallet-btn": "Знайти гаманець",
  "page-wallets-find-wallet-link": "Знайти гаманець",
  "page-wallets-get-some": "Отримайте ETH",
  "page-wallets-get-some-alt": "Ілюстрація руки, що створює логотип ETH із цеглинок Lego",
  "page-wallets-get-some-btn": "Отримайте ETH",
  "page-wallets-get-some-desc": "ETH – це власна криптовалюта мережі Ethereum. Щоб почати користуватися програмами Ethereum, потрібно мати ETH у гаманці.",
  "page-wallets-get-wallet": "Створити гаманець",
  "page-wallets-get-wallet-desc": "Існує безліч різних гаманців. Ми хочемо допомогти вам вибрати найкращий.",
  "page-wallets-get-wallet-desc-2": "Зверніть увагу: ви вибираєте його не назавжди, оскільки акаунт Ethereum не прив’язується до постачальника гаманця.",
  "page-wallets-how-to-store": "Як зберігати цифрові об’єкти в мережі Ethereum",
  "page-wallets-keys-to-safety": "Ключові умови безпеки криптовалюти",
  "page-wallets-manage-funds": "Програма для керування коштами",
  "page-wallets-manage-funds-desc": "Гаманець містить дані про ваші баланси й історію транзакцій, а також дає змогу надсилати та отримувати кошти. Деякі гаманці мають більше функцій.",
  "page-wallets-meta-description": "Що треба знати, щоб користуватися гаманцями Ethereum.",
  "page-wallets-meta-title": "Гаманці Ethereum",
  "page-wallets-mobile": "Мобільні програми, завдяки яким керувати коштами можна звідусіль",
  "page-wallets-more-on-dapps-btn": "Докладніше про децентралізовані додатки",
  "page-wallets-most-wallets": "Більшість гаманців дозволять вам створити акаунт Ethereum, тому вам не потрібно створювати його до завантаження гаманця.",
  "page-wallets-protecting-yourself": "Як захистити себе й свої кошти",
  "page-wallets-seed-phrase": "Запишіть фразу-пароль",
  "page-wallets-seed-phrase-desc": "Гаманці часто створюють фрази-паролі, які слід записати й зберігати в надійному місці. Це єдиний спосіб за потреби відновити гаманець.",
  "page-wallets-seed-phrase-example": "Ось приклад:",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "Не зберігайте фрази-паролі на комп’ютері. Записуйте їх і зберігайте в надійному місці.",
  "page-wallets-slogan": "Ключ до вашого цифрового майбутнього",
  "page-wallets-stay-safe": "Як захистити себе",
  "page-wallets-stay-safe-desc": "Гаманці ведуть до змін у способі мислення. Фінансова свобода й здатність отримувати доступ до коштів і використовувати їх будь-де потребують відповідальності, оскільки в криптовалюті немає служби підтримки клієнтів.",
  "page-wallets-subtitle": "Гаманці надають вам доступ до ваших коштів і до програм Ethereum. Доступ до вашого гаманця має бути лише у вас.",
  "page-wallets-take-responsibility": "Візьміть на себе відповідальність за власні кошти",
  "page-wallets-take-responsibility-desc": "Децентралізовані обмінники зв’яжуть ваш гаманець з іменем користувача й паролем, який можна відновити традиційним способом. Однак пам’ятайте, що ви надаєте цьому обміннику доступ до своїх коштів. Якщо компанію атакують або вона закриється, ви ризикуєте залишитися без коштів.",
  "page-wallets-tips": "Інші поради щодо безпеки",
  "page-wallets-tips-community": "Від спільноти",
  "page-wallets-title": "Гаманці Ethereum",
  "page-wallets-triple-check": "Перевіряйте все тричі",
  "page-wallets-triple-check-desc": "Пам’ятайте, що транзакції неможливо скасувати, а відновити гаманці не легко. Будьте обережні.",
  "page-wallets-try-dapps": "Спробуйте децентралізовані програми",
  "page-wallets-try-dapps-alt": "Ілюстрація спільної роботи учасників спільноти Ethereum",
  "page-wallets-try-dapps-desc": "Децентралізовані програми – це програми, створені в мережі Ethereum. Коли мова йде про керування вашими даними, вони дешевші, надійніші й ефективніші, ніж інші програми.",
  "page-wallets-types": "Типи гаманців",
  "page-wallets-web-browser": "Веб-гаманці, які дають змогу взаємодіяти з вашим акаунтом через веб-браузер",
  "page-wallets-whats-a-wallet": "Що таке гаманець Ethereum?",
  "page-wallets-your-ethereum-account": "Ваш акаунт Ethereum",
  "page-wallets-your-ethereum-account-desc": "Гаманець – це вікно у ваш акаунт Ethereum, де міститься баланс, історія транзакцій тощо. Змінити постачальника гаманця можна будь-коли.",
  "page-wallets-your-login": "Ваш логін для програм Ethereum",
  "page-wallets-your-login-desc": "Ваш гаманець дає змогу підключатися до будь-якої децентралізованої програми за допомогою акаунту Ethereum. Це як логін, який можна використовувати в багатьох програмах.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum — це технологія, яка за невелику плату дає змогу надсилати криптовалюту будь-кому. На ній також базуються додатки, якими може користуватися кожен і які ніхто не може видалити.",
  "page-what-is-ethereum-101-desc-2": "В основі Ethereum лежать інновації Bitcoin, але ці технології значно відрізняються.",
  "page-what-is-ethereum-101-desc-3": "Вони обидві дають вам змогу використовувати цифрові гроші без платіжних провайдерів чи банків. Але Ethereum можна запрограмувати, тому ви можете також використовувати його для багатьох інших цифрових активів – навіть Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "Це також означає, що Ethereum призначений не лише для платежів. Це ринок фінансових послуг, ігор та додатків, який не може викрасти ваші дані або цензурувати вас.",
  "page-what-is-ethereum-101-italic": "світовий програмований блокчейн.",
  "page-what-is-ethereum-101-strong": "Це ",
  "page-what-is-ethereum-accessibility": "Ethereum відкритий для всіх.",
  "page-what-is-ethereum-adventure": "Виберіть, що ви спробуєте!",
  "page-what-is-ethereum-alt-img-bazaar": "Зображення людини, яка зазирає в торговий зал, уособлюючи Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Зображення учасників спільноти Ethereum за спільною роботою",
  "page-what-is-ethereum-alt-img-lego": "Зображення руки, що збирає логотип ETH із кубиків лего",
  "page-what-is-ethereum-alt-img-social": "Зображення персонажів у соціальному просторі, присвяченому Ethereum, з великим логотипом ETH",
  "page-what-is-ethereum-banking-card": "Банківські послуги для всіх",
  "page-what-is-ethereum-banking-card-desc": "Не кожен має доступ до фінансових послуг. Але щоб отримати доступ до Ethereum і сервісів кредитування, позики та заощаджень, потрібне лише підключення до Інтернету.",
  "page-what-is-ethereum-build": "Створіть щось за допомогою Ethereum",
  "page-what-is-ethereum-build-desc": "Якщо ви хочете розробити додаток за допомогою Ethereum, прочитайте нашу документацію, перегляньте кілька навчальних посібників або ознайомтеся з інструментами, необхідними для початку роботи.",
  "page-what-is-ethereum-censorless-card": "Жодної цензури",
  "page-what-is-ethereum-censorless-card-desc": "Жоден уряд чи компанія не контролюють Ethereum. Завдяки цьому ніхто не перешкоджатиме вам отримувати платежі чи користуватися послугами Ethereum.",
  "page-what-is-ethereum-comm-desc": "Наша спільнота включає людей різного походження, зокрема митців, криптоанархістів, компанії з Fortune 500, а тепер і вас. Дізнайтеся, що ви можете зробити для спільноти вже сьогодні.",
  "page-what-is-ethereum-commerce-card": "Комерційні гарантії",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum створює рівніші умови гри. Клієнти мають безпечну вбудовану гарантію, що кошти змінюватимуть лише тоді, коли ви надаєте згоду. Для ведення бізнесу вам не потрібна велика компанія.",
  "page-what-is-ethereum-community": "Спільнота Ethereum",
  "page-what-is-ethereum-compatibility-card": "Корисна сумісність",
  "page-what-is-ethereum-compatibility-card-desc": "Кращі продукти й програми постійно вбудовуються, оскільки продукти Ethereum сумісні за умовчанням. Компанії можуть розвиватися завдяки успіху одна одної.",
  "page-what-is-ethereum-dapps-desc": "Продукти й сервіси, що працюють на Ethereum. Існують додатки для фінансів, роботи, соціальних мереж, ігор і багато іншого. Це – програми нашого цифрового майбутнього.",
  "page-what-is-ethereum-dapps-img-alt": "Ілюстрація собаки, що використовує програму Ethereum на комп’ютері",
  "page-what-is-ethereum-dapps-title": "Децентралізовані програми Ethereum",
  "page-what-is-ethereum-desc": "Основа нашого цифрового майбутнього",
  "page-what-is-ethereum-explore": "Огляд Ethereum",
  "page-what-is-ethereum-get-started": "Найкращий спосіб дізнатись більше - це завантажити гаманець, отримати ETH і спробувати децентралізовану програму Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum - це відкритий доступ до цифрових грошей і сервісів, доступних для всіх незалежно від походження чи місцезнаходження. Це створена спільнотою технологія, що лежить в основі криптовалюти Ether (ETH) і тисяч програм, які ви можете використовувати сьогодні.",
  "page-what-is-ethereum-internet-card": "Конфіденційніше використання Інтернету",
  "page-what-is-ethereum-internet-card-desc": "Вам не потрібно надавати всі ваші особисті дані, щоб використовувати програму Ethereum. Ethereum створює економіку на основі цінності, а не стеження.",
  "page-what-is-ethereum-meet-comm": "Приєднатися до спільноти",
  "page-what-is-ethereum-meta-description": "Дізнайтеся більше про мережу Ethereum, а також те, як вона працює та як до неї приєднатися.",
  "page-what-is-ethereum-meta-title": "Що таке Ethereum?",
  "page-what-is-ethereum-native-alt": "Символ валюти Ether (ETH)",
  "page-what-is-ethereum-native-crypto": "Власна криптовалюта Ethereum і альтернатива Bitcoin. ETH можна використовувати в програмах Ethereum і надсилати друзям і сім’ї.",
  "page-what-is-ethereum-native-img-alt": "Ілюстрація робота із сейфом замість тулуба, яка репрезентує гаманці Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Однорангова мережа",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum дає змогу переміщувати гроші або укладати договори безпосередньо з іншими користувачами. Вам не потрібні послуги посередницьких компаній.",
  "page-what-is-ethereum-singlecard-desc": "Якщо вас цікавить блокчейн або технічна сторона Ethereum, ми знаємо, що вам робити.",
  "page-what-is-ethereum-singlecard-link": "Як працює Ethereum",
  "page-what-is-ethereum-singlecard-title": "Як працює Ethereum",
  "page-what-is-ethereum-start-building-btn": "Розпочати програмування",
  "page-what-is-ethereum-title": "Що таке Ethereum?",
  "page-what-is-ethereum-tools-needed": "Для початку роботи вам потрібен лише гаманець.",
  "page-what-is-ethereum-try": "Приєднуйтеся до Ethereum",
  "page-what-is-ethereum-tryit": "Наважуйтеся й приєднуйтеся...",
  "page-what-is-ethereum-wallets": "Гаманці",
  "page-what-is-ethereum-wallets-desc": "Спосіб керування валютою ETH і акаунтом Ethereum. Для початку роботи потрібен буде гаманець. Ми допоможемо вам вибрати потрібний.",
  "page-what-is-ethereum-welcome": "Ласкаво просимо до Ethereum",
  "page-what-is-ethereum-welcome-2": "Ми сподіваємося, що ви залишитеся.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Стейкінг уже тут! Щоб отримати свою частку валюти Ethereum,",
  "banner-staking-2": "підтвердьте адресу контакту, за яким здійснювалося поповнення.",
  "docking": "Злиття",
  "page-eth2-vision-security-desc-9": "дає нам змогу призначати випадкових валідаторів для різних сегментів даних. Це робить практично неможливою змову валідаторів і атакування ними певного сегмента даних. Сегментування не таке захищене в блокчейні доказів виконаної роботи, оскільки майнери неможливо контролювати таким же чином за допомогою протоколу.",
  "page-index-sections-developers-desc": "Ознайомтеся з технологіями, на яких базуються Ethereum та її програми, і починайте програмувати.",
  "page-index-sections-developers-image-alt": "Зображення руки, яка складає символ Ethereum із кубиків Lego",
  "page-index-sections-developers-link-text": "Розпочати програмування",
  "page-index-sections-developers-title": "Розробникам",
  "page-index-sections-enterprise-desc": "Дізнайтесь, як Ethereum може ставати основою для нових бізнес-моделей, зменшувати витрати й відкривати нові перспективи для вашої компанії.",
  "page-index-sections-enterprise-image-alt": "Зображення групи людей за ноутбуком, які працюють над проектом Ethereum",
  "page-index-sections-enterprise-link-text": "Ethereum для корпоративного сектора",
  "page-index-sections-enterprise-title": "Корпоративний сектор",
  "page-index-sections-individuals-desc": "Дізнайтеся більше про Ethereum, Ether, гаманці, токени тощо та почніть використовувати програми на основі Ethereum.",
  "page-index-sections-individuals-image-alt": "Зображення собаки шіба-іну за комп'ютером",
  "page-index-sections-individuals-link-text": "Почати роботу з Ethereum",
  "page-index-sections-individuals-title": "Про Ethereum",
  "page-index-subtitle": "Використовуючи Ethereum, ви можете розробляти доступні всьому світу програми для керування цифровими активами, які виконуються відповідно до написаного коду.",
  "page-find-wallet-authereum-logo-alt": "Authereum logo",
  "page-find-wallet-description-authereum": "Жодних завантажень і фраз-паролів. Будь-який веб-переглядач у будь-який час на мобільному пристрої або комп’ютері",
  "page-wallets-accounts-addresses-desc-2": "це об’єкт, який може здійснювати транзакції та має баланс.",
  "page-wallets-accounts-has": "Акаунт Ethereum має",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "Адреса Ethereum",
  "page-wallets-ethereum-addresses-2": ", так само як папка \"Вхідні\" має електронну адресу. Завдяки цьому ви можете надсилати кошти в акаунт.",
  "page-wallets-ethereum-wallet-2": "– продукт, що дає змогу керувати акаунтом Ethereum, зокрема переглядати на ньому баланс, надсилати транзакції тощо."
}
