{
  "1inch-logo": "Logo 1inch",
  "aave-logo": "Logo Aave",
  "about": "Despre",
  "about-ethereum-org": "Despre ethereum.org",
  "about-us": "Despre noi",
  "alt-eth-blocks": "Ilustrație de blocuri organizate ca un simbol ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Înapoi sus",
  "banner-page-incomplete": "Această pagină este incompletă. Dacă ești expert în acest subiect, te rugăm să editezi această pagină și s-o presari cu înțelepciune.",
  "beacon-chain": "Lanțul Beacon",
  "binance-logo": "Logo Binance",
  "bittrex-logo": "Logo Bittrex",
  "brand-assets": "Materiale de branding",
  "bug-bounty": "Recompensă de eroare",
  "coinbase-logo": "Logo Coinbase",
  "coinmama-logo": "Logo Coinmama",
  "community": "Comunitate",
  "community-menu": "Community Menu",
  "compound-logo": "Logo Compound",
  "cons": "Contra",
  "contact": "Contact",
  "content-versions": "Content Versions",
  "contributing": "Participanți la proiect",
  "contributors": "Colaboratori",
  "contributors-thanks": "Tuturor care au contribuit la această pagină – vă mulțumesc!",
  "cookie-policy": "Politica de cookie-uri",
  "copied": "Copiat",
  "copy": "Copiere",
  "dark-mode": "Mod întunecat",
  "data-provided-by": "Date furnizate de",
  "decentralized-applications-dapps": "Aplicații descentralizate (dapps)",
  "deposit-contract": "Contract de depozit",
  "devcon": "Devcon",
  "developers": "Programatori",
  "developers-home": "Pagina de pornire pentru programatori",
  "docs": "Documente",
  "documentation": "Documentație",
  "dydx-logo": "Logo dYdX",
  "ecosystem": "Ecosistem",
  "edit-page": "Editare pagină",
  "ef-blog": "Blogul Fundației Ethereum",
  "eips": "Propuneri de îmbunătățire Ethereum",
  "enterprise": "Întreprindere",
  "enterprise-menu": "Meniu Întreprindere",
  "esp": "Program de sprijin pentru ecosistem",
  "eth-current-price": "Prețul curent al ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Explorator de lanțuri Beacon Eth2 open source",
  "eth2-beaconscan-desc": "Explorator de lanțuri Beacon Eth2 – Etherscan pentru Eth2",
  "eth2-become-staker": "Devino staker",
  "eth2-become-staker-desc": "Mizarea este aici! Dacă vrei să mizezi ETH pentru a ajuta la securizarea rețelei, asigură-te că ești conștient de riscuri.",
  "eth2-explore": "Explorare de date",
  "eth2-no-action-needed": "Nu trebuie să faci nimic cu ETH-urile pe care le deții deja. Ferește-te de escrocii care îți cer să trimiți ETH pentru a-l schimba.",
  "eth2-run-beacon-chain": "Rulează un client Beacon",
  "eth2-run-beacon-chain-desc": "Ethereum are nevoie de cât mai mulți clienți posibil care să ruleze. Ajută cu aceasta spre binele public al Ethereum!",
  "eth2-service-announcement": "Un anunț de serviciu Eth2",
  "eth2-what-shipping": "Când va fi lansat?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Materiale de branding Ethereum",
  "ethereum-community": "Comunitatea Ethereum",
  "ethereum-foundation": "Fundația Ethereum",
  "ethereum-foundation-logo": "Logo Fundație Ethereum",
  "ethereum-glossary": "Glosar Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Logo Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Studio Ethereum",
  "ethereum-wallets": "Portofele Ethereum",
  "ethereum-whitepaper": "Ethereum Whitepaper",
  "example-projects": "Exemple de proiecte",
  "find-wallet": "Găsește un portofel",
  "foundation": "Fundație",
  "gemini-logo": "Logo Gemini",
  "get-eth": "Obține ETH",
  "get-involved": "Implică-te",
  "get-started": "Începe aici",
  "gitcoin-logo": "Logo Gitcoin",
  "glossary": "Glosar",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Ghiduri și resurse",
  "history": "Istoric",
  "history-of-ethereum": "Istoricul Ethereum",
  "home": "Pagină de pornire",
  "how-ethereum-works": "Cum funcționează Ethereum",
  "image": "imagine",
  "in-this-section": "În aceasta secțiune",
  "individuals": "Persoane fizice",
  "individuals-menu": "Meniu pentru persoane fizice",
  "jobs": "Jobs",
  "kraken-logo": "Logo Kraken",
  "language-ar": "Arabă",
  "language-bg": "Bulgarian",
  "language-bn": "Bengalezã",
  "language-ca": "Catalan",
  "language-cs": "Cehă",
  "language-de": "Germană",
  "language-el": "Greacă",
  "language-en": "Engleză",
  "language-es": "Spaniolă",
  "language-fa": "Persană",
  "language-fi": "Finlandeză",
  "language-fr": "Franceză",
  "language-hu": "Maghiară",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indoneziană",
  "language-ig": "Igbo",
  "language-it": "Italiană",
  "language-ja": "Japoneză",
  "language-ko": "Coreeană",
  "language-lt": "Lituaniană",
  "language-ml": "Malayalam",
  "language-nb": "Norvegiană",
  "language-nl": "Neerlandeză",
  "language-pl": "Poloneză",
  "language-pt": "Portugheză",
  "language-pt-br": "Portugheză (Braziliană)",
  "language-ro": "Română",
  "language-ru": "Rusă",
  "language-se": "Suedeză",
  "language-sk": "Slovacă",
  "language-sl": "Slovenă",
  "language-support": "Suport lingvistic",
  "language-tr": "Turcă",
  "language-uk": "Ucraineană",
  "language-vi": "Vietnameză",
  "language-zh": "Chineză simplificată",
  "language-zh-tw": "Chineză tradițională",
  "languages": "Limbi",
  "last-24-hrs": "Ultimele 24 ore",
  "last-edit": "Ultima editare",
  "learn": "Învață",
  "learn-by-coding": "Învață prin codificare",
  "learn-menu": "Meniul Învață",
  "learn-more": "Află mai multe",
  "less": "Mai puțin",
  "light-mode": "Mod luminos",
  "listing-policy-disclaimer": "Produsele enumerate pe această pagină nu sunt aprobate oficial și sunt furnizate numai în scop informativ. Dacă vrei să adaugi un produs sau să oferi feedback cu privire la politică, ridică o problemă pe GitHub.",
  "listing-policy-raise-issue-link": "Ridică o problema",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Se încarcă...",
  "loading-error": "Eroare la încărcare.",
  "loading-error-refresh": "Eroare la încărcarea datelor. Încearcă să reîmprospătezi pagina.",
  "logo": "logo",
  "loopring-logo": "Logo Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Rețeaua principală Ethereum",
  "makerdao-logo": "Logo MakerDao",
  "matcha-logo": "Logo Matcha",
  "merge": "Merge",
  "more": "Mai multe",
  "nav-beginners": "Începători",
  "next": "Următor",
  "oasis-logo": "Logo Oasis",
  "on-this-page": "Pe această pagină",
  "page-content": "Conținutul paginii",
  "page-enterprise": "Întreprindere",
  "page-last-updated": "Pagina a fost actualizată pe",
  "previous": "Anterior",
  "privacy-policy": "Politica de confidențialitate",
  "private-ethereum": "Ethereum privat",
  "pros": "Pro",
  "read-more": "Informații suplimentare",
  "refresh": "Reîmprospătează pagina.",
  "review-progress": "Vizualizare progres",
  "search": "Caută",
  "search-box-blank-state-text": "Începe căutarea!",
  "search-eth-address": "Aceasta arată ca o adresă Ethereum. Nu oferim date specifice adreselor. Încearcă să o cauți într-un explorator de blocuri, cum ar fi",
  "search-no-results": "Nu a fost găsit niciun rezultat pentru căutarea ta",
  "security": "Security",
  "see-contributors": "Vezi colaboratorii",
  "set-up-local-env": "Configurează mediul local",
  "shard-chains": "Lanțuri de fragmente",
  "show-all": "Afișează tot",
  "show-less": "Afișează mai puțin",
  "site-description": "Ethereum este o platformă globală, descentralizată pentru bani și noi tipuri de aplicații. Pe Ethereum, poți scrie cod care controlează banii și poți construi aplicații accesibile oriunde în lume.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoin-uri",
  "staking": "Mizarea",
  "summary": "Rezumat",
  "terms-of-use": "Condiții de utilizare",
  "transaction-fees": "Ce sunt taxele de tranzacție?",
  "translation-banner-body-new": "Vizualizezi această pagină în limba engleză, deoarece nu am tradus-o încă. Ajută-ne să traducem acest conținut.",
  "translation-banner-body-update": "Există o nouă versiune a acestei pagini, dar acum este doar în engleză. Ajută-ne să traducem cea mai recentă versiune.",
  "translation-banner-button-join-translation-program": "Alătură-te programului de traducere",
  "translation-banner-button-learn-more": "Informații suplimentare",
  "translation-banner-button-see-english": "Vezi în engleză",
  "translation-banner-button-translate-page": "Tradu pagina",
  "translation-banner-title-new": "Ajută la traducerea acestei pagini",
  "translation-banner-title-update": "Ajută la actualizarea acestei pagini",
  "translation-program": "Translation Program",
  "translation-progress": "Progresul traducerii",
  "tutorials": "Tutoriale",
  "uniswap-logo": "Logo Uniswap",
  "use": "Utilizează",
  "use-ethereum": "Folosește Ethereum",
  "use-ethereum-menu": "Folosește meniul Ethereum",
  "vision": "Viziune",
  "wallets": "Portofele",
  "website-last-updated": "Site-ul a fost actualizat pe",
  "what-is-ether": "Ce este eter (ETH)?",
  "what-is-ethereum": "Ce este Ethereum?",
  "whitepaper": "Whitepaper",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Solicitare funcție",
  "page-about-h3": "Lucrări în curs",
  "page-about-h3-1": "Funcții implementate",
  "page-about-h3-2": "Funcții planificate",
  "page-about-li-1": "în desfășurare",
  "page-about-li-2": "planificat",
  "page-about-li-3": "implementat",
  "page-about-li-4": "implementat",
  "page-about-link-1": "Codul sursă al acestui depozit este licențiat sub licența MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Vezi lista completă a sarcinilor în desfășurare pe GitHub",
  "page-about-link-4": "Alătură-te serverului nostru Discord",
  "page-about-link-5": "Contactează-ne pe Twitter",
  "page-about-link-6": "Vezi lista completă a sarcinilor implementate pe GitHub",
  "page-about-link-7": "Creează o problemă pe GitHub",
  "page-about-p-1": "Încă de la lansarea ethereum.org, ne străduim să fim transparenți cu privire la modul în care operăm. Aceasta este una dintre valorile noastre de bază, deoarece credem că transparența este crucială pentru succesul Ethereum.",
  "page-about-p-2": "Folosim",
  "page-about-p-3": "ca instrument principal de gestionare a proiectelor noastre. Ne organizăm sarcinile în 3 categorii:",
  "page-about-p-4": "  Facem tot posibilul pentru a ține comunitatea la curent cu starea unei anumite sarcini.",
  "page-about-p-5": "Sarcini pe care le implementăm.",
  "page-about-p-6": "Sarcini puse în coadă pentru a le implementa în continuare.",
  "page-about-p-7": "Sarcini finalizate recent.",
  "page-about-p-8": "Ai o idee despre cum să îmbunătățești ethereum.org? Ne-ar plăcea să colaborăm cu tine!",
  "page-assets-bazaar": "Bazarul Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Blocuri de construcție",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Câine folosind aplicații dapps",
  "page-assets-download-artist": "Artist:",
  "page-assets-download-download": "Descărcare",
  "page-assets-enterprise": "Ethereum Enterprise",
  "page-assets-eth": "Eter (ETH)",
  "page-assets-eth-diamond-color": "Diamant ETH (culoare)",
  "page-assets-eth-diamond-glyph": "Diamant ETH (glif)",
  "page-assets-eth-diamond-gray": "Diamant ETH (gri)",
  "page-assets-eth-diamond-purple": "Diamant ETH (violet)",
  "page-assets-eth-diamond-white": "Diamant ETH (alb)",
  "page-assets-eth-glyph-video-dark": "Video glif ETH (întunecat)",
  "page-assets-eth-glyph-video-light": "ETH glif video (luminos)",
  "page-assets-eth-logo-landscape-gray": "Logo ETH peisaj (gri)",
  "page-assets-eth-logo-landscape-purple": "Logo ETH peisaj (violet)",
  "page-assets-eth-logo-landscape-white": "Logo ETH peisaj (alb)",
  "page-assets-eth-logo-portrait-gray": "Portret logo ETH (gri)",
  "page-assets-eth-logo-portrait-purple": "Portret logo-ul ETH (violet)",
  "page-assets-eth-logo-portrait-white": "Portret logo ETH (alb)",
  "page-assets-eth-wordmark-gray": "Semn ETH (gri)",
  "page-assets-eth-wordmark-purple": "Semn ETH (violet)",
  "page-assets-eth-wordmark-white": "Semn ETH (alb)",
  "page-assets-ethereum-brand-assets": "Active „marca” Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "active ethereum.org",
  "page-assets-hero": "erou ethereum.org",
  "page-assets-hero-particles": "Imagine a particulelor ETH",
  "page-assets-historical-artwork": "Opere de artă istorice",
  "page-assets-illustrations": "Ilustrații",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Explorează și descarcă active, materiale de branding, ilustrații și fișiere media ale Ethereum și ethereum.org.",
  "page-assets-meta-title": "Materiale de branding Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Fundal solid",
  "page-assets-page-assets-transparent-background": "Fundal transparent",
  "page-assets-robot": "Portofel Robot",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "Logo 1inch",
  "page-dapps-aave-logo-alt": "Logo Aave",
  "page-dapps-add-button": "Sugerează dapp",
  "page-dapps-add-title": "Adaugă dapp",
  "page-dapps-audius-logo-alt": "Logo Audius",
  "page-dapps-augur-logo-alt": "Logo Augur",
  "page-dapps-axie-infinity-logo-alt": "Logo Axie Infinity",
  "page-dapps-brave-logo-alt": "Logo Brave",
  "page-dapps-category-arts": "Artă și modă",
  "page-dapps-category-browsers": "Browsere",
  "page-dapps-category-collectibles": "Colecții digitale",
  "page-dapps-category-competitive": "Competiție",
  "page-dapps-category-computing": "Instrumente pentru programatori",
  "page-dapps-category-dex": "Schimburi de tokenuri",
  "page-dapps-category-investments": "Investiții",
  "page-dapps-category-lending": "Acordare și acceptare de credite",
  "page-dapps-category-lottery": "Finanțarea participativă",
  "page-dapps-category-marketplaces": "Piețe",
  "page-dapps-category-music": "Muzică",
  "page-dapps-category-payments": "Plăți",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Piețe de tranzacționare și predicție",
  "page-dapps-category-utilities": "Utilități",
  "page-dapps-category-worlds": "Lumi virtuale",
  "page-dapps-choose-category": "Alege o categorie",
  "page-dapps-collectibles-benefits-1-description": "Când arta este tokenizată pe Ethereum, proprietatea poate fi dovedită de către toți. Poți urmări călătoria operei de artă de la creație la actualul titular. Acest lucru previne falsurile.",
  "page-dapps-collectibles-benefits-1-title": "Proprietatea este demonstrabilă",
  "page-dapps-collectibles-benefits-2-description": "Plata pentru a asculta muzică sau pentru a cumpăra opere de artă este mult mai echitabilă pentru artiști. Cu Ethereum, nevoia de intermediari este mai mică. Și dacă sunt necesari intermediari, costurile lor nu sunt la fel de mari, deoarece platformele nu trebuie să plătească pentru infrastructura rețelei.",
  "page-dapps-collectibles-benefits-2-title": "Mai echitabil pentru creatori",
  "page-dapps-collectibles-benefits-3-description": "Obiectele de colecție tokenizate sunt legate de adresa ta Ethereum, nu de platformă. Așadar, poți vinde lucruri precum articole din joc pe orice piață Ethereum, nu doar în joc.",
  "page-dapps-collectibles-benefits-3-title": "Obiectele de colecție merg cu tine",
  "page-dapps-collectibles-benefits-4-description": "Instrumentele și produsele există deja pentru a-ți tokeniza arta și a o vinde! Și tokenurile tale pot fi vândute pe orice platformă de colecție Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Infrastructură deja existentă",
  "page-dapps-collectibles-benefits-description": "Acestea sunt aplicații care se concentrează pe proprietatea digitală, sporind potențialul de câștig pentru creatori și inventând noi moduri de a investi în creatorii tăi preferați și munca lor.",
  "page-dapps-collectibles-benefits-title": "colecții descentralizate și streaming",
  "page-dapps-collectibles-button": "Arte și obiecte de colecție",
  "page-dapps-collectibles-description": "Acestea sunt aplicații care se concentrează pe proprietatea digitală, sporind potențialul de câștig pentru creatori și inventând noi moduri de a investi în creatorii tăi preferați și munca lor.",
  "page-dapps-collectibles-title": "Arte și colecții descentralizate",
  "page-dapps-compound-logo-alt": "Logo Compound",
  "page-dapps-cryptopunks-logo-alt": "Logo CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Logo Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Te ajută să eviți deraparea spre prețuri ridicate prin agregarea celor mai bune prețuri.",
  "page-dapps-dapp-description-aave": "Creditează tokenuri cu dobândă și retrage-le oricând.",
  "page-dapps-dapp-description-async-art": "Creează, colectează și tranzacționează #ProgrammableArt - picturi digitale împărțite în „Straturi” pe care le poți folosi pentru a afecta imaginea generală. Fiecare Maestru și Strat este un token ERC721.",
  "page-dapps-dapp-description-audius": "Platformă de streaming descentralizată. Ascultări = bani pentru creatori, nu pentru casele de producție.",
  "page-dapps-dapp-description-augur": "Pariază pe rezultatele sportului, economiei și a mai multor evenimente mondiale.",
  "page-dapps-dapp-description-axie-infinity": "Tranzacționează și luptă cu creaturi numite Axies. Și câștigă pe măsură ce te joci – disponibile pe mobil",
  "page-dapps-dapp-description-brave": "Câștigă tokenuri pentru navigare și sprijină creatorii preferați cu acestea.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Creditează tokenuri pentru a câștiga dobândă și retrage-le oricând.",
  "page-dapps-dapp-description-cryptopunks": "Cumpără, licitează și oferă caractere \"punk de vânzare\" – una dintre primele colecții de token-uri de pe Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Creează galerii de artă, construiește magazine și cumpără terenuri – o lume virtuală Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Cucerește planetele într-un univers infinit, generat procedural, specificat criptografic.",
  "page-dapps-dapp-description-decentraland": "Colectează, comercializează terenuri virtuale într-o lume virtuală pe care o poți explora.",
  "page-dapps-dapp-description-dydx": "Deschide poziții de vânzare (short) sau cu efect de levier de până la 10x. Poți de asemenea accepta sau acorda credite.",
  "page-dapps-dapp-description-ens": "Nume ușor de folosit pentru adresele Ethereum și site-uri descentralizate.",
  "page-dapps-dapp-description-foundation": "Investește în ediții unice de opere de artă digitală și tranzacționează piese cu alți cumpărători.",
  "page-dapps-dapp-description-gitcoin": "Câștigă cripto lucrând pe software open-source.",
  "page-dapps-dapp-description-gitcoin-grants": "Finanțarea participativă pentru proiecte comunitare Ethereum cu contribuții amplificate",
  "page-dapps-dapp-description-gods-unchained": "Joc strategic de cărți de tranzacționare. Jucând, câștigi cărți pe care le poți vinde, la fel ca în viața reală.",
  "page-dapps-dapp-description-golem": "Accesează puterea de calcul partajată sau închiriază propriile resurse.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Platformă de tranzacționare peer-to-peer, construită pentru viteză.",
  "page-dapps-dapp-description-marble-cards": "Creează și tranzacționează carduri digitale unice pe baza URL-urilor.",
  "page-dapps-dapp-description-matcha": "Caută mai multe schimburi valutare pentru a te ajuta să găsești cele mai bune prețuri.",
  "page-dapps-dapp-description-nifty-gateway": "Cumpără lucrări „on-chain” artiști, sportivi, mărci și creatori de top.",
  "page-dapps-dapp-description-oasis": "Tranzacționează, ia credite și salvează cu Dai, un stablecoin Ethereum.",
  "page-dapps-dapp-description-opensea": "Cumpără, vinde, descoperă și comercializează bunuri în ediție limitată.",
  "page-dapps-dapp-description-opera": "Trimite cripto direct din browser la comercianți, altor utilizatori și aplicații.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Pariază pe rezultate. Tranzacționează pe piețele de informații.",
  "page-dapps-dapp-description-pooltogether": "O loterie în care nu poți pierde. Premii în fiecare săptămână.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Creează, vinde și cumpără piese de colecție tokenizate.",
  "page-dapps-dapp-description-sablier": "Trimite bani în timp real.",
  "page-dapps-dapp-description-superrare": "Cumpără opere de artă digitală direct de la artiști sau de pe piețele secundare.",
  "page-dapps-dapp-description-token-sets": "Strategii de investiții cripto care se reechilibrează automat.",
  "page-dapps-dapp-description-tornado-cash": "Trimite tranzacții anonime pe Ethereum.",
  "page-dapps-dapp-description-uniswap": "Schimbă tokenuri sau oferă tokenuri pentru recompense de %.",
  "page-dapps-dark-forest-logo-alt": "Logo Dark Forest",
  "page-dapps-decentraland-logo-alt": "Logo Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Găsește o aplicație Ethereum pentru a încerca.",
  "page-dapps-doge-img-alt": "Imaginea unui câine folosind un calculator",
  "page-dapps-dydx-logo-alt": "Logo dYdX",
  "page-dapps-editors-choice-dark-forest": "Joacă împotriva altora pentru a cuceri planete și încearcă tehnologia cea mai avansată de scalare/confidențialitate Ethereum. Poate pentru cei care cunosc deja Ethereum.",
  "page-dapps-editors-choice-description": "Câteva aplicații dapp pe care echipa ethereum.org le adoră acum. Explorează mai multe dapp-uri mai jos.",
  "page-dapps-editors-choice-foundation": "Investește în cultură. Cumpără, tranzacționează și vinde opere de artă digitale unice și modă de la artiști, muzicieni și mărci incredibile.",
  "page-dapps-editors-choice-header": "Alegerile editorilor",
  "page-dapps-editors-choice-pooltogether": "Cumpără un bilet la loteria fără pierderi. În fiecare săptămână, dobânda generată de întregul grup de bilete este trimisă unui câștigător norocos. Obții banii înapoi oricând dorești.",
  "page-dapps-editors-choice-uniswap": "Schimbă tokenuri cu ușurință. O funcție preferată de comunitate, care îți permite să tranzacționezi tokenuri cu oameni din întreaga rețea.",
  "page-dapps-ens-logo-alt": "Logo Serviciu de Nume Ethereum",
  "page-dapps-explore-dapps-description": "Multe aplicații dapp sunt încă experimentale, testând posibilitățile rețelelor descentralizate. Dar au existat câțiva jucători timpurii de succes în categoriile tehnologie, financiar, jocuri și colecții.",
  "page-dapps-explore-dapps-title": "Explorează dapps",
  "page-dapps-features-1-description": "Odată implementat în Ethereum, codul aplicației dapp nu poate fi eliminat. Și oricine poate folosi caracteristicile aplicației. Chiar dacă echipa din spatele aplicației dapp s-a desființat, îl poți folosi în continuare. Odată pe Ethereum, rămâne pe Ethereum.",
  "page-dapps-features-1-title": "Fără proprietari",
  "page-dapps-features-2-description": "Nu poți fi împiedicat să utilizezi o aplicație dapp sau să trimiți tranzacții. De exemplu, dacă Twitter era pe Ethereum, nimeni nu ar fi putut să îți blocheze contul sau să te oprească să trimiți tweet-uri.",
  "page-dapps-features-2-title": "Fără cenzură",
  "page-dapps-features-3-description": "Deoarece Ethereum are ETH, plățile sunt originare din Ethereum. Programatorii nu trebuie să-și petreacă timpul integrându-se cu furnizorii de plăți terți.",
  "page-dapps-features-3-title": "Plăți încorporate",
  "page-dapps-features-4-description": "Codul aplicației dapp este adesea deschis și compatibil în mod implicit. Echipele construiesc în mod regulat folosind munca altor echipe. Dacă dorești să permiți utilizatorilor să schimbe tokenuri în aplicația ta dapp, poți să conectezi codul unui alt dapp.",
  "page-dapps-features-4-title": "Plug and play",
  "page-dapps-features-5-description": "Cu majoritatea aplicațiilor dapp, nu este nevoie să-ți împărtășești identitatea din lumea reală. Contul tău Ethereum este autentificarea ta și nu ai nevoie decât de un portofel.",
  "page-dapps-features-5-title": "O autentificare anonimă",
  "page-dapps-features-6-description": "Criptografia asigură faptul că atacatorii nu pot falsifica tranzacții și alte interacțiuni cu aplicații dapp în numele tău. Autorizezi acțiunile aplicațiilor dapp cu ajutorul contului tău Ethereum, de obicei prin portofel, de aceea, păstrează-ți acreditările în siguranță.",
  "page-dapps-features-6-title": "Susținut de criptografie",
  "page-dapps-features-7-description": "Odată ce aplicația dapp este activă pe Ethereum, acesta va cădea numai dacă Ethereum însuși cade. Rețelele de dimensiunea Ethereum sunt notoriu greu de atacat.",
  "page-dapps-features-7-title": "Fără timpi morți",
  "page-dapps-finance-benefits-1-description": "Serviciile financiare care rulează pe Ethereum nu au cerințe de înscriere. Dacă ai fonduri și o conexiune la internet, ești gata să începi.",
  "page-dapps-finance-benefits-1-title": "Acces deschis",
  "page-dapps-finance-benefits-2-description": "Există o întreagă lume de tokenuri cu care poți interacționa în aceste produse financiare. Oamenii construiesc totdeauna noi tokenuri pe Ethereum.",
  "page-dapps-finance-benefits-2-title": "O nouă economie bazată pe token",
  "page-dapps-finance-benefits-3-description": "Echipele au construit monede stabile – o cripto-monedă mai puțin volatilă. Acestea îți permit să experimentezi și să utilizezi cripto fără risc și incertitudine.",
  "page-dapps-finance-benefits-3-title": "Monede stabile",
  "page-dapps-finance-benefits-4-description": "Produsele financiare din spațiul Ethereum sunt toate modulare și compatibile între ele. Noile configurații ale acestor module ajung pe piață tot timpul, sporind ceea ce poți face cu cripto.",
  "page-dapps-finance-benefits-4-title": "Servicii financiare interconectate",
  "page-dapps-finance-benefits-description": "De ce permite Ethereum să prospere aplicațiile de finanțare descentralizate?",
  "page-dapps-finance-benefits-title": "finanțe descentralizate",
  "page-dapps-finance-button": "Finanțe",
  "page-dapps-finance-description": "Acestea sunt aplicații care se concentrează pe dezvoltarea serviciilor financiare folosind cripto-monede. Ele oferă creditare, acceptare de credite, câștigarea de dobânzi și plăți private – nu sunt necesare date personale.",
  "page-dapps-finance-title": "Finanțe descentralizate",
  "page-dapps-foundation-logo-alt": "Logo Fundație",
  "page-dapps-gaming-benefits-1-description": "Fie că este vorba de teren virtual sau de cărți de tranzacționare, articolele tale pot fi tranzacționate pe piețele de colecție. Articolele tale din joc au valoarea din lumea reală.",
  "page-dapps-gaming-benefits-1-title": "Elementele jocului se dublează ca tokenuri",
  "page-dapps-gaming-benefits-2-description": "Deții articolele tale și, în unele cazuri, progresul tău și nu companiile de jocuri. Deci, nu vei pierde nimic în cazul în care compania din spatele jocul este atacată, suferă o defecțiune a serverului sau se desființează.",
  "page-dapps-gaming-benefits-2-title": "Salvările tale sunt sigure",
  "page-dapps-gaming-benefits-3-description": "În același mod în care plățile Ethereum sunt disponibile oricui pentru verificare, jocurile pot folosi această calitate pentru a asigura corectitudinea. În teorie, totul este verificabil de la numărul de lovituri critice până la dimensiunea pieptului adversarului de război.",
  "page-dapps-gaming-benefits-3-title": "Onestitate demonstrabilă",
  "page-dapps-gaming-benefits-description": "De ce permite Ethereum să prospere aplicațiile de jocuri descentralizate?",
  "page-dapps-gaming-benefits-title": "jocuri descentralizate",
  "page-dapps-gaming-button": "Gaming",
  "page-dapps-gaming-description": "Acestea sunt aplicații care se concentrează pe crearea de lumi virtuale și lupta cu alți jucători, folosind colecții care dețin o valoare reală.",
  "page-dapps-gaming-title": "Jocuri descentralizate",
  "page-dapps-get-some-eth-description": "Acțiunile aplicației dapp costă o taxă de tranzacție",
  "page-dapps-get-started-subtitle": "Pentru a încerca o aplicație dapp, ai nevoie de un portofel și ceva ETH. Un portofel îți va permite să te conectezi sau să te autentifici. Și vei avea nevoie de ETH pentru a plăti orice taxe de tranzacție.",
  "page-dapps-get-started-title": "Începe aici",
  "page-dapps-gitcoin-grants-logo-alt": "Logo Subvenții Gitcoin",
  "page-dapps-gitcoin-logo-alt": "Logo Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Logo Gods Unchained",
  "page-dapps-golem-logo-alt": "Logo Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Instrumente și servicii bazate pe Ethereum",
  "page-dapps-hero-subtitle": "Dapps sunt o mișcare de aplicații în creștere care utilizează Ethereum pentru a perturba modelele de afaceri sau pentru a inventa altele noi.",
  "page-dapps-how-dapps-work-p1": "Aplicațiile dapp au codul lor back-end (contracte inteligente) care rulează pe o rețea descentralizată și nu pe un server centralizat. Ele folosesc blockchain-ul Ethereum pentru stocarea datelor și contracte inteligente pentru logica aplicației lor.",
  "page-dapps-how-dapps-work-p2": "Un contract inteligent este ca un set de reguli care trăiesc în lanț pentru ca toți să vadă și să ruleze exact în conformitate cu aceste reguli. Imaginează-ți un distribuitor automat: dacă îl furnizezi cu fonduri suficiente și o selecție corectă, vei obține elementul dorit. Și, la fel cu distribuitoarele automate, contractele inteligente pot deține fonduri, ca și contul tău. Ethereum. Aceasta permite codului să medieze acorduri și tranzacții.",
  "page-dapps-how-dapps-work-p3": "Odată implementate în rețeaua Ethereum, aplicațiile dapp nu pot fi modificate. Dapps pot fi descentralizate, deoarece sunt controlate de logica scrisă în contract, nu de o persoană fizică sau de o companie.",
  "page-dapps-how-dapps-work-title": "Cum funcționează dapps",
  "page-dapps-learn-callout-button": "Începe să dezvolți",
  "page-dapps-learn-callout-description": "Portalul nostru pentru comunitatea de programatori are documente, instrumente și cadre pentru a te ajuta să începi să construiești o aplicație dapp.",
  "page-dapps-learn-callout-image-alt": "Ilustrația unei mâini care construiește un simbol ETH din cărămizi lego.",
  "page-dapps-learn-callout-title": "Învață să construiești o aplicație dapp",
  "page-dapps-loopring-logo-alt": "Logo Loopring",
  "page-dapps-magic-behind-dapps-description": "Dapps pot părea aplicații obișnuite. Dar, în culise, au niște calități speciale, deoarece moștenesc toate superputerile Ethereum. Iată de ce aplicațiile dapps sunt diferite de aplicațiile obișnuite.",
  "page-dapps-magic-behind-dapps-link": "Ce face Ethereum grozav?",
  "page-dapps-magic-behind-dapps-title": "Magia din spatele aplicațiilor dapps",
  "page-dapps-magic-title-1": "Magia",
  "page-dapps-magic-title-2": "din spatele",
  "page-dapps-magician-img-alt": "Ilustrație cu magicieni",
  "page-dapps-marble-cards-logo-alt": "Logo Marble.Cards",
  "page-dapps-matcha-logo-alt": "Logo Matcha",
  "page-dapps-mobile-options-header": "Vizualizează o altă categorie",
  "page-dapps-nifty-gateway-logo-alt": "Logo Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Logo Oasis",
  "page-dapps-opensea-logo-alt": "Logo OpenSea",
  "page-dapps-opera-logo-alt": "Logo Opera",
  "page-dapps-polymarket-logo-alt": "Logo Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Logo PoolTogether",
  "page-dapps-rarible-logo-alt": "Logo Rarible",
  "page-dapps-ready-button": "Începe",
  "page-dapps-ready-description": "Alege o aplicație dapp pentru a încerca",
  "page-dapps-ready-title": "Gata?",
  "page-dapps-sablier-logo-alt": "Logo Sablier",
  "page-dapps-set-up-a-wallet-button": "Găsește un portofel",
  "page-dapps-set-up-a-wallet-description": "Un portofel este „autentificarea” ta pentru o aplicație dapp",
  "page-dapps-set-up-a-wallet-title": "Configurează un portofel",
  "page-dapps-superrare-logo-alt": "Logo SuperRare",
  "page-dapps-technology-button": "Tehnologie",
  "page-dapps-technology-description": "Acestea sunt aplicații care se concentrează pe descentralizarea instrumentelor pentru programatori, încorporarea sistemelor cripto-economice în tehnologia existentă și crearea de piețe pentru munca de dezvoltare open-source.",
  "page-dapps-technology-title": "Tehnologie descentralizată",
  "page-dapps-token-sets-logo-alt": "Logo Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Logo Tornado Cash",
  "page-dapps-uniswap-logo-alt": "Logo Uniswap",
  "page-dapps-wallet-callout-button": "Găsește un portofel",
  "page-dapps-wallet-callout-description": "Portofelele sunt și ele aplicații dapp. Găsește unul bazat pe caracteristicile care ți se potrivesc.",
  "page-dapps-wallet-callout-image-alt": "Ilustrarea unui robot.",
  "page-dapps-wallet-callout-title": "Vizualizare portofele",
  "page-dapps-warning-header": "Fă-ți întotdeauna propria cercetare",
  "page-dapps-warning-message": "Ethereum este o tehnologie nouă și majoritatea aplicațiilor sunt noi. Înainte de a depune cantități mari de bani, asigură-te că înțelegi riscurile.",
  "page-dapps-what-are-dapps": "Ce sunt aplicațiile dapps?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Conturi",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Avansat",
  "docs-nav-backend-apis": "API backend",
  "docs-nav-block-explorers": "Exploratori de blocuri",
  "docs-nav-blocks": "Blocuri",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Compilarea contractelor inteligente",
  "docs-nav-composability": "Combinabilitatea",
  "docs-nav-consensus-mechanisms": "Mecanisme de consens",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Date și analize",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Implementarea contractelor inteligente",
  "docs-nav-development-frameworks": "Cadre de dezvoltare",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Rețele de dezvoltare",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API-uri client Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Stiva Ethereum",
  "docs-nav-evm": "Mașină virtuală Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Subiecte fundamentale",
  "docs-nav-gas": "Gaz",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Medii de dezvoltare integrate (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Introducere în dapps",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Introducere în Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Introducere în stivă",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "API-uri JavaScript",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Minarea",
  "docs-nav-networks": "Rețele",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Noduri și clienți",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Noduri ca serviciu",
  "docs-nav-oracles": "Oracole",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Limbaje de programare",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Dovada mizei",
  "docs-nav-proof-of-work": "Dovada muncii",
  "docs-nav-python": "Python",
  "docs-nav-readme": "CITEȘTE-MĂ",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Scalare",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Securitate",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomie contracte inteligente",
  "docs-nav-smart-contract-languages": "Limbaje contracte inteligente",
  "docs-nav-smart-contracts": "Contracte inteligente",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Biblioteci de contracte inteligente",
  "docs-nav-standards": "Standarde",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Stocare",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Testarea contractelor inteligente",
  "docs-nav-token-standards": "Standarde token",
  "docs-nav-transactions": "Tranzacții",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 vs Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Dacă ești expert pe această temă și dorești să contribui, editează această pagină și presar-o cu înțelepciunea ta.",
  "page-calltocontribute-desc-2": "Vei fi creditat și vei ajuta comunitatea Ethereum!",
  "page-calltocontribute-desc-3": "Utilizează acest șablon",
  "page-calltocontribute-desc-4": "Întrebări? Întreabă-ne pe canalul #content de pe",
  "page-calltocontribute-link": "de documentație flexibil",
  "page-calltocontribute-link-2": "serverul nostru Discord",
  "page-calltocontribute-span": "Editare pagină",
  "page-calltocontribute-title": "Ajută-ne cu această pagină",
  "page-developer-meta-title": "Resurse pentru dezvoltatori Ethereum",
  "page-developers-about": "Despre aceste resurse de dezvoltator",
  "page-developers-about-desc": "ethereum.org te ajută să dezvolți cu Ethereum folosind documentația despre concepte fundamentale, precum și stiva de dezvoltare. În plus, există tutoriale care te ajută să începi.",
  "page-developers-about-desc-2": "Inspirat de rețeaua dezvoltatorilor Mozilla, ne-am gândit că Ethereum are nevoie de un loc pentru a găzdui conținut și resurse pentru dezvoltatori. Ca și prietenii noștri de la Mozilla, totul este open-source și pregătit pentru ca tu să extinzi și să îmbunătățești.",
  "page-developers-account-desc": "Contracte sau persoane din rețea",
  "page-developers-accounts-link": "Conturi",
  "page-developers-advanced": "Avansat",
  "page-developers-api-desc": "Utilizarea bibliotecilor pentru a interacționa cu contractele inteligente",
  "page-developers-api-link": "API backend",
  "page-developers-aria-label": "Meniu pentru programatori",
  "page-developers-block-desc": "Loturi de tranzacții adăugate la blockchain",
  "page-developers-block-explorers-desc": "Portalul tău către datele Ethereum",
  "page-developers-block-explorers-link": "Exploratoare de blocuri",
  "page-developers-blocks-link": "Blocuri",
  "page-developers-browse-tutorials": "Parcurge tutoriale",
  "page-developers-choose-stack": "Alege stiva ta",
  "page-developers-contribute": "Contribuie",
  "page-developers-dev-env-desc": "IDE-uri adecvate pentru dezvoltarea aplicațiilor dapp",
  "page-developers-dev-env-link": "Medii de dezvoltare",
  "page-developers-discord": "Intră pe Discord",
  "page-developers-docs-introductions": "Introduceri",
  "page-developers-evm-desc": "Computerul care procesează tranzacțiile",
  "page-developers-evm-link": "Mașina virtuală Ethereum (EVM)",
  "page-developers-explore-documentation": "Explorează documentația",
  "page-developers-feedback": "Dacă ai feedback, contactează-ne printr-o problemă GitHub sau pe serverul nostru Discord.",
  "page-developers-frameworks-desc": "Instrumente care ajută la accelerarea dezvoltării",
  "page-developers-frameworks-link": "Cadre de dezvoltare",
  "page-developers-fundamentals": "Principii de bază",
  "page-developers-gas-desc": "Eter necesar pentru alimentarea tranzacțiilor",
  "page-developers-gas-link": "Gaz",
  "page-developers-get-started": "Cum dorești să începi?",
  "page-developers-improve-ethereum": "Ajută-ne să îmbunătățim ethereum.org",
  "page-developers-improve-ethereum-desc": "Precum ethereum.org, aceste documente sunt un efort al comunității. Creează un PR dacă observi greșeli, posibilități de îmbunătățire sau noi oportunități pentru a ajuta dezvoltatorii Ethereum.",
  "page-developers-into-eth-desc": "Introducere în blockchain și Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Introducere în aplicațiile descentralizate",
  "page-developers-intro-dapps-link": "Introducere în dapps",
  "page-developers-intro-eth-link": "Introducere în Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Introducere în stivă",
  "page-developers-intro-stack-desc": "Introducere în stiva Ethereum",
  "page-developers-js-libraries-desc": "Folosirea javascript pentru a interacționa cu contractele inteligente",
  "page-developers-js-libraries-link": "Biblioteci JavaScript",
  "page-developers-language-desc": "Utilizarea Ethereum cu limbaje familiare",
  "page-developers-languages": "Limbaje de programare",
  "page-developers-learn": "Învață dezvoltarea Ethereum",
  "page-developers-learn-desc": "Citește despre conceptele de bază și stiva Ethereum cu documentele noastre",
  "page-developers-learn-tutorials": "Învață prin tutoriale",
  "page-developers-learn-tutorials-cta": "Vezi tutoriale",
  "page-developers-learn-tutorials-desc": "Învață dezvoltarea Ethereum pas cu pas de la dezvoltatorii care au făcut deja acest lucru.",
  "page-developers-meta-desc": "Documentație, tutoriale și instrumente pentru programatorii care dezvoltă pe Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Cum sunt create noile blocuri și cum se ajunge la consens",
  "page-developers-mining-link": "Extragere",
  "page-developers-networks-desc": "O prezentare generală a rețelei principale și a rețelei de testare",
  "page-developers-networks-link": "Rețele",
  "page-developers-node-clients-desc": "Cum sunt verificate blocurile și tranzacțiile în rețea",
  "page-developers-node-clients-link": " Noduri și clienți",
  "page-developers-oracle-desc": "Includerea datelor off-chain în contractele inteligente",
  "page-developers-oracles-link": "Oracole",
  "page-developers-play-code": "Joacă-te cu codul",
  "page-developers-read-docs": "Citește documentația",
  "page-developers-scaling-desc": "Soluții pentru tranzacții mai rapide",
  "page-developers-scaling-link": "Scalare",
  "page-developers-smart-contract-security-desc": "Măsurile de securitate de luat în considerare în cursul dezvoltării",
  "page-developers-smart-contract-security-link": "Securitate",
  "page-developers-set-up": "Configurarea mediului local",
  "page-developers-setup-desc": "Pregătește-ți stiva pentru dezvoltare prin configurarea unui mediu de dezvoltare.",
  "page-developers-smart-contracts-desc": "Logica din spatele aplicațiilor dapps - acorduri de auto-executare",
  "page-developers-smart-contracts-link": "Contracte inteligente",
  "page-developers-stack": "Stiva",
  "page-developers-start": "Începe să experimentezi",
  "page-developers-start-desc": "Vrei să experimentezi mai întâi și să pui întrebări mai târziu?",
  "page-developers-storage-desc": "Cum să gestionezi stocarea dapp",
  "page-developers-storage-link": "Stocare",
  "page-developers-subtitle": "Manualul dezvoltatorilor pentru Ethereum. De la dezvoltatori, pentru dezvoltatori.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "programator",
  "page-developers-title-3": "resurse",
  "page-developers-token-standards-desc": "Prezentare generală a standardelor token acceptate",
  "page-developers-token-standards-link": "Standarde token",
  "page-developers-transactions-desc": "Modul în care se schimbă starea Ethereum",
  "page-developers-transactions-link": "Tranzacții",
  "page-developers-web3-desc": "Modul în care lumea dezvoltării web3 este diferit",
  "page-developers-web3-link": "Web2 vs Web3",
  "page-learning-tools-bootcamps": "Cursuri intensive pentru programatori",
  "page-learning-tools-bootcamps-desc": "Cursuri online contra cost pentru a progresa rapid.",
  "page-learning-tools-browse-docs": "Răsfoiește documente",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Logo ChainShot",
  "page-learning-tools-coding": "Învață prin codificare",
  "page-learning-tools-coding-subtitle": "Aceste instrumente te vor ajuta să experimentezi cu Ethereum dacă preferi o experiență de învățare mai interactivă.",
  "page-learning-tools-consensys-academy-description": "Curs intensiv online pentru programatori Ethereum.",
  "page-learning-tools-consensys-academy-logo-alt": "Logo ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Învață Solidity în timp ce îți construiești propriul joc cu zombie.",
  "page-learning-tools-cryptozombies-logo-alt": "Logo CryptoZombies",
  "page-learning-tools-documentation": "Învață cu documentație",
  "page-learning-tools-documentation-desc": "Dorești să afli mai multe? Accesează documentația noastră pentru a găsi explicațiile de care ai nevoie.",
  "page-learning-tools-eth-dot-build-description": "Sandbox educațional pentru web3, care include programare drag-and-drop și blocuri de construcții open-source.",
  "page-learning-tools-eth-dot-build-logo-alt": "Eth.build logo",
  "page-learning-tools-ethernauts-description": "Parcurge nivelurile piratând contracte inteligente.",
  "page-learning-tools-ethernauts-logo-alt": "Logo Ethernauts",
  "page-learning-tools-game-tutorials": "Tutoriale de joc interactive",
  "page-learning-tools-game-tutorials-desc": "Învață în timp ce te joci. Aceste tutoriale te ajută să treci prin elementele de bază folosind modul gameplay.",
  "page-learning-tools-meta-desc": "Instrumente de codificare bazate pe web și experiențe interactive de învățare pentru a te ajuta să experimentezi cu dezvoltarea Ethereum.",
  "page-learning-tools-meta-title": "Instrumente de învățare pentru programatori",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Dezvoltă, implementează și administrează contracte inteligente pentru Ethereum. Urmează tutoriale cu plugin-ul Learneth.",
  "page-learning-tools-remix-description-2": "Remix nu este doar un sandbox. Mulți programatori își scriu, compilează și implementează contractele inteligente folosind Remix.",
  "page-learning-tools-remix-logo-alt": "Logo Remix",
  "page-learning-tools-sandbox": "Sandbox-uri de cod",
  "page-learning-tools-sandbox-desc": "Aceste sandbox-uri îți vor oferi un spațiu pentru a experimenta scrierea de contracte inteligente și pentru a înțelege Ethereum.",
  "page-learning-tools-studio-description": "Un IDE bazat pe web în care poți urmări tutoriale pentru a crea și testa contracte inteligente și pentru a crea un front-end pentru acestea.",
  "page-learning-tools-vyperfun-description": "Învață Vyper construindu-ți propriul joc Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Logo Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Un cadru de dezvoltare și testare bazat pe Python pentru contracte inteligente care vizează mașina virtuală Ethereum.",
  "page-local-environment-brownie-logo-alt": "Logo Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "O platformă all-in-one pentru programatori pentru construirea și implementarea aplicațiilor descentralizate.",
  "page-local-environment-embark-logo-alt": "Logo Embark",
  "page-local-environment-epirus-desc": "O platformă pentru dezvoltarea, implementarea și monitorizarea aplicațiilor blockchain pe mașina virtuală Java",
  "page-local-environment-epirus-logo-alt": "Logo Epirus",
  "page-local-environment-eth-app-desc": "Creează aplicații bazate pe Ethereum cu o singură comandă. Vine cu o gamă largă de oferte de cadre UI și șabloane DeFi din care să alegi.",
  "page-local-environment-eth-app-logo-alt": "Creează logoul aplicației Eth",
  "page-local-environment-framework-feature-1": "Funcții pentru a crea o instanță blockchain locală.",
  "page-local-environment-framework-feature-2": "Utilități pentru a compila și testa contractele inteligente.",
  "page-local-environment-framework-feature-3": "Suplimente de dezvoltare client pentru a-ți crea aplicația orientată către utilizator în același proiect/depozit.",
  "page-local-environment-framework-feature-4": "Configurare pentru conectarea la rețelele Ethereum și implementarea contractelor, fie la o instanță care rulează local, fie la una dintre rețelele publice Ethereum.",
  "page-local-environment-framework-feature-5": "Distribuție descentralizată a aplicațiilor - integrări cu opțiuni de stocare precum IPFS.",
  "page-local-environment-framework-features": "Aceste cadre vin cu o mulțime de funcționalități ieșite din comun, cum ar fi:",
  "page-local-environment-frameworks-desc": " Îți recomandăm să alegi un cadru, mai ales dacă abia începi. Construirea unei aplicații dapp complet dezvoltate necesită diferite piese de tehnologie. Cadrele includ multe dintre funcțiile necesare sau oferă sisteme simple de plugin-uri pentru a alege instrumentele dorite.",
  "page-local-environment-frameworks-title": "Cadre și stive prefabricate",
  "page-local-environment-hardhat-desc": "Hardhat este un mediu de dezvoltare Ethereum pentru profesioniști.",
  "page-local-environment-hardhat-logo-alt": "Logo Hardhat",
  "page-local-environment-openZeppelin-desc": "Economisește ore de dezvoltare compilând, făcând upgrade, implementând și interacționând cu contracte inteligente cu CLI-ul nostru.",
  "page-local-environment-openZeppelin-logo-alt": "Logo OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Creează aplicații Eth: tot ce ai nevoie pentru a începe să creezi aplicații descentralizate alimentate de contracte inteligente",
  "page-local-environment-scaffold-eth-logo-alt": "Logo scaffold-eth",
  "page-local-environment-setup-meta-desc": "Ghid despre cum să alegi stiva de software pentru dezvoltarea Ethereum.",
  "page-local-environment-setup-meta-title": "Configurare de dezvoltare locală Ethereum",
  "page-local-environment-setup-subtitle": "Dacă ești gata să începi construirea, este timpul să-ți alegi stiva.",
  "page-local-environment-setup-subtitle-2": " Iată instrumentele și cadrele pe care le poți utiliza pentru a te ajuta să-ți creezi aplicația Ethereum.",
  "page-local-environment-setup-title": "Configurează-ți mediul de dezvoltare locală",
  "page-local-environment-solidity-template-desc": "Un șablon GitHub pentru o configurare pre-construită pentru contractele inteligente Solidity. Include o rețea locală Hardhat, Waffle pentru testare, Ethers pentru implementarea portofelului și multe altele.",
  "page-local-environment-solidity-template-logo-alt": "Logo Solidity template",
  "page-local-environment-truffle-desc": "Suita Truffle aduce programatorii de la idee la aplicația dapp cât mai confortabil posibil.",
  "page-local-environment-truffle-logo-alt": "Logo Truffle",
  "page-local-environment-waffle-desc": "Cea mai avansată bibliotecă de testare pentru contracte inteligente. Se utilizează singură sau împreună cu Scafold-eth sau Hardhat.",
  "page-local-environment-waffle-logo-alt": "Logo Waffle",
  "comp-tutorial-metadata-minute-read": "minute de citit",
  "page-tutorial-listing-policy-intro": "Înainte de a trimite un tutorial, te rugăm să citești politica noastră de listare.",
  "comp-tutorial-metadata-tip-author": "Sfatul autorului",
  "page-tutorial-listing-policy": "politica de listare a articolelor",
  "page-tutorial-new-github": "Nou pe GitHub?",
  "page-tutorial-new-github-desc": "Ridică o problemă – completează informațiile solicitate și lipește tutorialul.",
  "page-tutorial-pull-request": "Creează o cerere de integrare",
  "page-tutorial-pull-request-btn": "Creează cererea de integrare",
  "page-tutorial-pull-request-desc-1": "Folosește",
  "page-tutorial-pull-request-desc-2": "tutoriale/numele-tutorialului-tău.md",
  "page-tutorial-pull-request-desc-3": "ca structură de denumire.",
  "page-tutorial-raise-issue-btn": "Ridică problema",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Trimite un tutorial",
  "page-tutorial-submit-tutorial": "Pentru a trimite un tutorial, trebuie să folosești GitHub. Te invităm să creezi o problemă sau o cerere de integrare.",
  "page-tutorial-subtitle": "Bine ai venit la lista de tutoriale a comunității.",
  "page-tutorial-tags-error": "Niciun tutorial nu are toate aceste etichete",
  "page-tutorial-title": "Tutoriale de dezvoltare Ethereum",
  "page-tutorials-meta-description": "Navighează și filtrează tutorialele comunității Ethereum după subiect.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Tutoriale de dezvoltare Ethereum",
  "page-eth-buy-some": "Dorești să cumperi niște Ethereum?",
  "page-eth-buy-some-desc": "Este normal să confunzi Ethereum cu ETH. Ethereum este blockchain-ul și ETH este activul principal al Ethereum. ETH este ceea ce probabil cauți să cumperi.",
  "page-eth-cat-img-alt": "Grafic al glif-ului ETH cu un caleidoscop de pisici",
  "page-eth-collectible-tokens": "Tokenuri de colecție",
  "page-eth-collectible-tokens-desc": "Tokenuri care reprezintă un obiect de joc de colecție, o piesă de artă digitală sau alte active unice. Cunoscute în mod obișnuit ca tokenuri non-fungibile (NFT).",
  "page-eth-cryptography": "Securizat prin criptografie",
  "page-eth-cryptography-desc": "Banii de pe internet pot fi noi, dar sunt asigurați prin criptografie dovedită. Acest lucru îți protejează portofelul, ETH-ul și tranzacțiile. ",
  "page-eth-currency-for-apps": "Este moneda aplicațiilor Ethereum.",
  "page-eth-currency-for-future": "Moneda pentru viitorul nostru digital",
  "page-eth-description": "ETH este o cripto-monedă. Sunt bani digitali în cantitate limitată pe care îi poți folosi pe internet – similar cu Bitcoin. Dacă ești nou în cripto, iată care sunt diferențele dintre ETH și banii tradiționali.",
  "page-eth-earn-interest-link": "Câștigi dobândă",
  "page-eth-ethhub-caption": "Este actualizat frecvent",
  "page-eth-ethhub-overview": "Dacă vrei, EthHub are o imagine de ansamblu excelentă",
  "page-eth-flexible-amounts": "Disponibil în cantități flexibile",
  "page-eth-flexible-amounts-desc": "ETH este divizibil până la 18 zecimale, deci nu trebuie să cumperi 1 ETH întreg. Poți cumpăra fracții la un moment dat - doar 0,000000000000000001 ETH dacă dorești.",
  "page-eth-fuels": "ETH alimentează și asigură Ethereum",
  "page-eth-fuels-desc": "ETH este sângele Ethereum. Când trimiți ETH sau utilizezi o aplicație Ethereum, vei plăti o mică taxă în ETH pentru a utiliza rețeaua Ethereum. Această taxă este un stimulent pentru un miner să proceseze și să verifice ceea ce încerci să faci.",
  "page-eth-fuels-desc-2": "Minerii sunt precum păstrătorii de recorduri ai Ethereum – verifică și dovedesc că nimeni nu înșală. Minerii care fac această muncă sunt, de asemenea, recompensați cu cantități mici de ETH nou-emis.",
  "page-eth-fuels-desc-3": "Munca pe care o fac minerii păstrează Ethereum în siguranță și fără control centralizat. Cu alte cuvinte,",
  "page-eth-fuels-more-staking": "Mai multe despre mizare",
  "page-eth-fuels-staking": "ETH va deveni și mai important odată cu mizarea. Atunci când mizezi ETH, vei putea ajuta la securizarea Ethereum și vei câștiga recompense. În acest sistem, amenințarea de a-ți pierde ETH-ul, descurajează atacurile.",
  "page-eth-get-eth-btn": "Obține ETH",
  "page-eth-gov-tokens": "Tokenuri de guvernanță",
  "page-eth-gov-tokens-desc": "Tokenuri care reprezintă puterea de vot în organizațiile descentralizate.",
  "page-eth-has-value": "De ce ETH are valoare?",
  "page-eth-has-value-desc": "ETH este valoros în moduri diferite pentru diferite persoane.",
  "page-eth-has-value-desc-2": "Pentru utilizatorii Ethereum, ETH este valoros, deoarece îți permite să plătești taxe de tranzacție.",
  "page-eth-has-value-desc-3": "Alții îl văd ca pe un magazin digital de valoare, deoarece crearea de nou ETH încetinește în timp.",
  "page-eth-has-value-desc-4": "Mai recent, ETH a devenit valoros pentru utilizatorii de aplicații financiare de pe Ethereum, deoarece pot utiliza ETH ca garanție pentru împrumuturi criptografice sau ca sistem de plăți.",
  "page-eth-has-value-desc-5": "Desigur, mulți o văd și ca o investiție, similară cu Bitcoin sau alte cripto-monede.",
  "page-eth-how-to-buy": "Cum să cumperi Eter",
  "page-eth-how-to-buy-caption": "Este actualizat frecvent",
  "page-eth-is-money": "ETH este o monedă digitală, globală.",
  "page-eth-last-updated": "Ianuarie 2019",
  "page-eth-mining-link": "Mai multe despre minerit",
  "page-eth-monetary-policy": "Politica monetară a Ethereum",
  "page-eth-more-on-ethereum-link": "Mai multe despre Ethereum",
  "page-eth-no-centralized": "Fără control centralizat ",
  "page-eth-no-centralized-desc": "ETH este descentralizat și global. Nicio companie sau bancă nu poate decide să imprime mai mult ETH sau să modifice condițiile de utilizare.",
  "page-eth-non-fungible-tokens-link": "Tokenuri non-fungibile",
  "page-eth-not-only-crypto": "ETH nu este singura cripto pe Ethereum",
  "page-eth-not-only-crypto-desc": "Oricine poate crea noi tipuri de active și le poate tranzacționa pe Ethereum. Acestea sunt cunoscute sub numele de tokenuri. Oamenii au „tokenizat” monedele tradiționale, proprietățile lor imobiliare, arta lor și chiar pe ei înșiși!",
  "page-eth-not-only-crypto-desc-2": "Ethereum găzduiește mii de tokenuri – unele mai utile și mai valoroase decât altele. Programatorii construiesc în mod constant noi tokenuri care deblochează noi posibilități și deschid noi piețe.",
  "page-eth-not-only-crypto-desc-3": "Dacă dorești să afli mai multe despre tokenuri, prietenii noștri de la EthHub au scris câteva prezentări superbe:",
  "page-eth-open": "Este deschis oricui",
  "page-eth-open-desc": "Ai nevoie doar de o conexiune la internet și de un portofel pentru a accepta ETH. Nu ai nevoie de acces la un cont bancar pentru a accepta plăți. ",
  "page-eth-p2p-payments": "Plăți peer-to-peer",
  "page-eth-p2p-payments-desc": "Poți trimite ETH fără niciun serviciu intermediar, cum ar fi o bancă. Este ca și cum ai preda bani în persoană, dar o poți face în siguranță cu oricine, oriunde, oricând.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Tipuri populare de tokenuri",
  "page-eth-powers-ethereum": "ETH alimentează Ethereum",
  "page-eth-shit-coins": "Monede Sh*t",
  "page-eth-shit-coins-desc": "Deoarece realizarea de tokenuri noi este ușoară, oricine o poate face – chiar și persoanele cu intenții rele sau greșite. Întotdeauna efectuează cercetările înainte de a le folosi!",
  "page-eth-stablecoins": "Monede stabile",
  "page-eth-stablecoins-desc": "Tokenuri care reflectă valoarea monedei tradiționale precum dolarii. Acest lucru rezolvă problema volatilității cu multe criptomonede.",
  "page-eth-stablecoins-link": "Obține monede stabile",
  "page-eth-stream-link": "Tranzacționează ETH",
  "page-eth-tokens-link": "Tokenuri Ethereum",
  "page-eth-trade-link-2": "Schimbă tokenuri",
  "page-eth-underpins": "ETH stă la baza sistemului financiar Ethereum",
  "page-eth-underpins-desc": "Nu ești mulțumit de plăți, comunitatea Ethereum construiește un întreg sistem financiar peer-to -peer și accesibil tuturor.",
  "page-eth-underpins-desc-2": "Poți folosi ETH ca garanție pentru a genera tokenuri criptovalutare complet diferite pe Ethereum. În plus poți să accepți credite sau să creditezi și să câștigi dobândă în ETH și în alte tokenuri sprijinite de ETH.",
  "page-eth-uses": "Utilizările pentru ETH cresc în fiecare zi",
  "page-eth-uses-desc": "Deoarece Ethereum este programabil, programatorii pot modela ETH în nenumărate moduri.",
  "page-eth-uses-desc-2": "În 2015, tot ce puteai face era să trimiți ETH dintr-un cont Ethereum în altul. Iată doar câteva dintre lucrurile pe care le poți face astăzi.",
  "page-eth-uses-desc-3": "poți plăti pe cineva sau primi fonduri în timp real.",
  "page-eth-uses-desc-4": "poți tranzacționa ETH cu alte tokenuri, inclusiv Bitcoin.",
  "page-eth-uses-desc-5": "pe ETH și alte tokenuri bazate pe Ethereum.",
  "page-eth-uses-desc-6": "accesa lumea criptomonedelor cu o valoare constantă, mai puțin volatilă.",
  "page-eth-value": "De ce eterul (ETH) este valoros",
  "page-eth-video-alt": "Video glif ETH",
  "page-eth-whats-eth": "Ce este eter (ETH)?",
  "page-eth-whats-eth-hero-alt": "Ilustrație a unui grup de persoane care se minunează de un glif eter (ETH)",
  "page-eth-whats-eth-meta-desc": "Ce trebuie să știi pentru a înțelege ETH și locul acestuia în Ethereum.",
  "page-eth-whats-eth-meta-title": "Ce este eter (ETH)?",
  "page-eth-whats-ethereum": "Ce este Ethereum?",
  "page-eth-whats-ethereum-desc": "Dacă dorești să afli mai multe despre Ethereum, tehnologia din spatele ETH, consultă introducerea noastră.",
  "page-eth-whats-unique": "Ce este unic la ETH?",
  "page-eth-whats-unique-desc": "Există multe criptomonede și multe alte tokenuri pe Ethereum, dar sunt unele lucruri pe care numai ETH le poate face.",
  "page-eth-where-to-buy": "De unde poți să obții ETH",
  "page-eth-where-to-buy-desc": "Poți să obții ETH printr-un schimb sau dintr-un portofel, dar diferite țări au politici diferite. Verifică pentru a vedea serviciile care îți permit să cumperi ETH.",
  "page-eth-yours": "Este cu adevărat al tău",
  "page-eth-yours-desc": "ETH îți permite să fii propria ta bancă. Poți să-ți controlezi propriile fonduri cu portofelul, ca dovadă a proprietății – nu sunt necesare terțe părți.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Adresa contractului de depozit Eth2",
  "page-eth2-deposit-contract-address-caption": "Am adăugat spații pentru a ușura citirea adresei",
  "page-eth2-deposit-contract-address-check-btn": "Verifică adresa contractului de depunere",
  "page-eth2-deposit-contract-checkbox1": "Am folosit deja launchpad-ul pentru a-mi configura validatorul Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Înțeleg că trebuie să folosesc launchpad-ul pentru a miza. Transferurile simple către această adresă nu vor funcționa.",
  "page-eth2-deposit-contract-checkbox3": "Voi verifica adresa contractului de depunere cu alte surse.",
  "page-eth2-deposit-contract-confirm-address": "Confirmă pentru a dezvălui adresa",
  "page-eth2-deposit-contract-copied": "Adresa s-a copiat",
  "page-eth2-deposit-contract-copy": "Copiază adresa",
  "page-eth2-deposit-contract-etherscan": "Vezi contractul pe Etherscan",
  "page-eth2-deposit-contract-h2": "Aici nu este locul în care mizezi",
  "page-eth2-deposit-contract-launchpad": "Mizează folosind launchpad-ul",
  "page-eth2-deposit-contract-launchpad-2": "Utilizează launchpad-ul",
  "page-eth2-deposit-contract-meta-desc": "Verifică adresa contractului de depozit pentru miza Eth2.",
  "page-eth2-deposit-contract-meta-title": "Adresa contractului de depozit Eth2",
  "page-eth2-deposit-contract-read-aloud": "Citește adresa cu voce tare",
  "page-eth2-deposit-contract-reveal-address-btn": "Dezvăluie adresa",
  "page-eth2-deposit-contract-staking": " Pentru a miza ETH în Eth2 trebuie să utilizezi produsul launchpad dedicat și să urmezi instrucțiunile. Trimiterea de ETH la adresa de pe această pagină nu te va face un staker și va rezulta într-o tranzacție eșuată.",
  "page-eth2-deposit-contract-staking-check": "Verifică aceste surse",
  "page-eth2-deposit-contract-staking-check-desc": "Ne așteptăm să existe o mulțime de adrese și escrocherii acolo. Pentru a fi în siguranță, verifică adresa de mizare Eth2 pe care o utilizezi față de adresa de pe această pagină. Recomandăm verificarea ei și cu alte surse de încredere.",
  "page-eth2-deposit-contract-staking-more-link": "Mai multe despre mizare",
  "page-eth2-deposit-contract-stop-reading": "Nu mai citi",
  "page-eth2-deposit-contract-subtitle": " Aceasta este adresa contractului de mizare Eth2. Folosește această pagină pentru a confirma că trimiți fonduri la adresa corectă atunci când mizezi.",
  "page-eth2-deposit-contract-warning": "Verifică foarte atent fiecare caracter.",
  "page-eth2-deposit-contract-warning-2": "Trimiterea de fonduri la această adresă nu va funcționa și nu te va face un staker. Trebuie să urmezi instrucțiunile launchpad-ului.",
  "page-eth2-bug-bounty-annotated-specs": "specificații adnotate",
  "page-eth2-bug-bounty-annotations": "Ar putea fi util să verifici următoarele adnotări:",
  "page-eth2-bug-bounty-client-bugs": "Erori client Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Clienții vor rula lanțul Beacon după implementarea upgrade-ului. Clienții vor trebui să urmeze logica stabilită în specificații și să fie protejați împotriva atacurilor potențiale. Erorile pe care vrem să le găsim sunt legate de implementarea protocolului.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Doar erorile Lighthouse, Nimbus, Teku și Prysm sunt eligibile în prezent pentru această recompensă. Mai mulți clienți vor fi adăugați pe măsură ce finalizează auditul și devin gata de producție.",
  "page-eth2-bug-bounty-clients": "Clienți incluși în recompense",
  "page-eth2-bug-bounty-clients-type-1": "probleme de neconformitate cu specificațiile.",
  "page-eth2-bug-bounty-clients-type-2": "blocări neașteptate sau vulnerabilități de refuz al serviciului (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "orice probleme care provoacă diviziuni ireparabile a consensului de restul rețelei.",
  "page-eth2-bug-bounty-docking": "andocare",
  "page-eth2-bug-bounty-email-us": "Trimite-ne un e-mail:",
  "page-eth2-bug-bounty-help-links": "Linkuri utile",
  "page-eth2-bug-bounty-hunting": "Reguli de vânătoare de erori",
  "page-eth2-bug-bounty-hunting-desc": "Programul de recompense pentru erori este un program de recompense experimental și discreționar pentru comunitatea noastră activă Ethereum, pentru a încuraja și recompensa pe cei care ajută la îmbunătățirea platformei. Nu este o competiție. Trebuie să știi că putem anula programul în orice moment, iar premiile sunt la exclusiva latitudine a panoului de recompense pentru erori al Fundației Ethereum. În plus, nu putem emite premii persoanelor care se află pe listele de sancțiuni sau care se află în țările aflate pe listele de sancțiuni (de exemplu, Coreea de Nord, Iran etc.). Ești responsabil pentru toate impozitele. Toate premiile se supun legilor aplicabile. În cele din urmă, testarea ta nu trebuie să încalce nicio lege sau să compromită nicio dată care nu-ți aparține.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Clasament de vânătoare de erori",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Găsește erori Eth2 pentru a fi adăugat la acest clasament",
  "page-eth2-bug-bounty-hunting-li-1": "Problemele care au fost deja raportate de alt utilizator sau care sunt deja cunoscute de cei care întrețin specificațiile și clienții nu sunt eligibile pentru recompense.",
  "page-eth2-bug-bounty-hunting-li-2": "Dezvăluirea publică a unei vulnerabilități o face neeligibilă pentru recompensă.",
  "page-eth2-bug-bounty-hunting-li-3": "Cercetătorii Fundației Ethereum și angajații echipelor clientului Eth2 nu sunt eligibili pentru recompense.",
  "page-eth2-bug-bounty-hunting-li-4": "Programul de recompense Ethereum ia în considerare o serie de variabile la determinarea recompenselor. Determinările de eligibilitate, punctajul și toți termenii legați de un premiu sunt la discreția unică și finală a panoului de recompense pentru erori al Fundației Ethereum.",
  "page-eth2-bug-bounty-leaderboard": "Vezi clasamentul complet",
  "page-eth2-bug-bounty-leaderboard-points": "puncte",
  "page-eth2-bug-bounty-ledger-desc": "Specificația lanțului Beacon detaliază raționamentul de proiectare și modificările propuse la Ethereum prin upgrade-ul lanțului Beacon.",
  "page-eth2-bug-bounty-ledger-title": "Erorile specificațiilor lanțului Beacon",
  "page-eth2-bug-bounty-meta-description": "O prezentare generală a programului de vânătoare de erori Eth2: cum să te implici și să fi recompensat pentru informații.",
  "page-eth2-bug-bounty-meta-title": "Programul de recompensă de vânătoare de erori Eth2",
  "page-eth2-bug-bounty-not-included": "Nu sunt incluse",
  "page-eth2-bug-bounty-not-included-desc": "Lanțul de fragmente și upgrade-urile de andocare sunt încă în dezvoltare activă și prin urmare, nu sunt încă incluse ca parte a acestui program de recompense.",
  "page-eth2-bug-bounty-owasp": "Vezi metoda OWASP",
  "page-eth2-bug-bounty-points": "EF va acorda, de asemenea, puncte bazate pe:",
  "page-eth2-bug-bounty-points-error": "Eroare la încărcarea datelor... reîmprospătează.",
  "page-eth2-bug-bounty-points-exchange": "Schimb de puncte",
  "page-eth2-bug-bounty-points-loading": "Se încarcă datele...",
  "page-eth2-bug-bounty-points-payout-desc": " Fundația Ethereum va plăti valoarea USD în ETH sau DAI.",
  "page-eth2-bug-bounty-points-point": "1 punct",
  "page-eth2-bug-bounty-points-rights-desc": "Fundația Ethereum își rezervă dreptul de a modifica aceasta fără o notificare prealabilă.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Calitatea descrierii",
  "page-eth2-bug-bounty-quality-desc": ": Recompense mai mari sunt plătite pentru rapoarte clare, bine scrise.",
  "page-eth2-bug-bounty-quality-fix": "Calitatea remedierii",
  "page-eth2-bug-bounty-quality-fix-desc": ", dacă este inclus: recompense mai mari sunt plătite pentru rapoarte cu o descriere clară a modului de soluționare a problemei.",
  "page-eth2-bug-bounty-quality-repro": "Calitatea reproductibilității",
  "page-eth2-bug-bounty-quality-repro-desc": ": Te rugăm să incluzi codul de testare, scripturile și instrucțiunile detaliate. Cu cât ne este mai ușor să reproducem și să verificăm vulnerabilitatea, cu atât este mai mare recompensa.",
  "page-eth2-bug-bounty-questions": "Ai întrebări?",
  "page-eth2-bug-bounty-rules": "Citește regulile",
  "page-eth2-bug-bounty-shard-chains": "lanțuri de fragmente",
  "page-eth2-bug-bounty-slogan": "Recompense de erori Eth2",
  "page-eth2-bug-bounty-specs": "Citește specificațiile complete",
  "page-eth2-bug-bounty-specs-docs": "Documente de specificație",
  "page-eth2-bug-bounty-submit": "Trimite o eroare",
  "page-eth2-bug-bounty-submit-desc": "Pentru fiecare eroare găsită, vei fi recompensat cu puncte. Punctele câștigate depind de severitatea erorii. Fundația Ethereum (EF) determină severitatea utilizând metoda OWASP.",
  "page-eth2-bug-bounty-subtitle": "Câștigă până la 50.000 USD și un loc în clasament, prin găsirea de erori ale protocolului Eth2 și ale clientului.",
  "page-eth2-bug-bounty-title": "Deschis pentru trimiteri",
  "page-eth2-bug-bounty-title-1": "Lanțul Beacon",
  "page-eth2-bug-bounty-title-2": "Opțiunea furculiței",
  "page-eth2-bug-bounty-title-3": "Contract de depozit Solidity",
  "page-eth2-bug-bounty-title-4": "Rețea peer-to-peer",
  "page-eth2-bug-bounty-type-1": "erori de securitate/spargere a finalității.",
  "page-eth2-bug-bounty-type-2": "vectori de negare a serviciului (DOS)",
  "page-eth2-bug-bounty-type-3": "inconsecvențe în prezumții, cum ar fi situații în care validatorii cinstiți pot fi penalizați financiar.",
  "page-eth2-bug-bounty-type-4": "inconsecvențe de calcul sau parametri.",
  "page-eth2-bug-bounty-types": "Tipuri de erori",
  "page-eth2-bug-bounty-validity": "Erori valide",
  "page-eth2-bug-bounty-validity-desc": "Programul de recompensă pentru erori se axează pe găsirea erorilor în specificații de bază Eth2 de pe lanțul Beacon și pe implementările clienților Prysm, Lighthouse și Teku.",
  "page-eth2-bug-bountycard-critical": "Critic",
  "page-eth2-bug-bountycard-critical-risk": "Trimite eroarea de risc critic",
  "page-eth2-bug-bountycard-h2": "Mediu",
  "page-eth2-bug-bountycard-high": "Ridicat",
  "page-eth2-bug-bountycard-high-risk": "Trimite eroare cu risc ridicat",
  "page-eth2-bug-bountycard-label-1": "Până la 1.000 de puncte",
  "page-eth2-bug-bountycard-label-2": "Până la 2.000 DAI",
  "page-eth2-bug-bountycard-label-3": "Până la 5.000 de puncte",
  "page-eth2-bug-bountycard-label-4": "Până la 10.000 DAI",
  "page-eth2-bug-bountycard-label-5": "Până la 10.000 de puncte",
  "page-eth2-bug-bountycard-label-6": "Până la 20.000 DAI",
  "page-eth2-bug-bountycard-label-7": "Până la 25.000 de puncte",
  "page-eth2-bug-bountycard-label-8": "Până la 50.000 DAI",
  "page-eth2-bug-bountycard-li-1": "Impact redus, probabilitate medie",
  "page-eth2-bug-bountycard-li-2": "Impact mediu, probabilitate mică",
  "page-eth2-bug-bountycard-li-3": "Impact mare, probabilitate mică",
  "page-eth2-bug-bountycard-li-4": "Impact mediu, probabilitate medie",
  "page-eth2-bug-bountycard-li-5": "Impact redus, probabilitate mare",
  "page-eth2-bug-bountycard-li-6": "Impact mare, probabilitate medie",
  "page-eth2-bug-bountycard-li-7": "Impact mediu, probabilitate mare",
  "page-eth2-bug-bountycard-li-8": "Impact mare, probabilitate mare",
  "page-eth2-bug-bountycard-low": "Mic",
  "page-eth2-bug-bountycard-low-risk": "Trimite eroare cu risc mic",
  "page-eth2-bug-bountycard-medium-risk": "Trimite eroare de risc mediu",
  "page-eth2-bug-bountycard-subheader": "Severitate",
  "page-eth2-bug-bountycard-subheader-2": "Exemplu",
  "page-eth2-bug-bountycard-text": "Atacatorul poate pune uneori un nod într-o stare care îl determină să invalideze una din fiecare o sută de atestări făcute de un validator",
  "page-eth2-bug-bountycard-text-1": " Atacatorul poate efectua cu succes atacuri eclipsă asupra nodurilor cu peer-id-uri cu 4 octeți de bază zero",
  "page-eth2-bug-bountycard-text-2": "Există o eroare de consens între doi clienți, dar este dificil sau nepractic pentru atacator să declanșeze evenimentul.",
  "page-eth2-bug-bountycard-text-3": "  Există o eroare de consens între doi clienți și este fără însemnătate pentru un atacator să declanșeze evenimentul.",
  "page-eth2-get-involved-btn-1": "Vezi clienții",
  "page-eth2-get-involved-btn-2": "Mai multe despre mizare",
  "page-eth2-get-involved-btn-3": "Găsește erori",
  "page-eth2-get-involved-bug": "O eroare ar putea fi:",
  "page-eth2-get-involved-bug-hunting": "Mergi la vânătoare de erori",
  "page-eth2-get-involved-bug-hunting-desc": "Găsește și raportează erori în specificațiile de upgrade-uri Eth2 sau clienții înșiși. Poți câștiga până la 50.000 USD și poți câștiga un loc în clasament.",
  "page-eth2-get-involved-bug-li": "probleme de neconformitate a specificației",
  "page-eth2-get-involved-bug-li-2": "erori de spargere a finalității",
  "page-eth2-get-involved-bug-li-3": "vectori de negare a serviciului (DOS)",
  "page-eth2-get-involved-bug-li-4": "și mai multe...",
  "page-eth2-get-involved-date": "Dată de închidere: 23 decembrie 2020",
  "page-eth2-get-involved-desc-1": "Rularea unui client înseamnă că vei fi un participant activ în Ethereum. Clientul tău va contribui la urmărirea tranzacțiilor și va verifica noile blocuri.",
  "page-eth2-get-involved-desc-2": "Dacă ai ETH, îl poți miza pentru a deveni validator și pentru a ajuta la securizarea rețelei. Ca validator, poți câștiga recompense ETH.",
  "page-eth2-get-involved-desc-3": "Alătură-te efortului de testare a comunității! Ajută la testarea de upgrade-uri Eth2 înaintea lansării acestora, găsește erori și câștigă recompense.",
  "page-eth2-get-involved-ethresearch-1": "Fragmentare",
  "page-eth2-get-involved-ethresearch-2": "Tranziția Eth1 la Eth2",
  "page-eth2-get-involved-ethresearch-3": "Fragmente și executarea stării",
  "page-eth2-get-involved-ethresearch-4": "Toate subiectele de cercetare",
  "page-eth2-get-involved-grants": "Mizare - subvenții comunitare",
  "page-eth2-get-involved-grants-desc": "Ajută la crearea de instrumente și conținut educațional pentru comunitatea de mize",
  "page-eth2-get-involved-how": "Cum vrei să te implici?",
  "page-eth2-get-involved-how-desc": "Comunitatea Ethereum va beneficia întotdeauna de pe urma unui număr mai mare de persoane care administrează clienți, mizează și vânează erori.",
  "page-eth2-get-involved-join": "Alătură-te cercetării",
  "page-eth2-get-involved-join-desc": " La fel ca majoritatea lucrurilor cu Ethereum, o mare parte din cercetare este publică. Acest lucru înseamnă că poți lua parte la discuții sau poți citi ceea ce au de spus cercetătorii Ethereum. „ethresear.ch” acoperă mai mult decât doar upgrade-urile Eth2, dar se pune mai mult accentul pe Eth2.",
  "page-eth2-get-involved-meta-description": "Cum să participi la Eth2: rulezi noduri, mizezi, vânezi erori și multe altele.",
  "page-eth2-get-involved-more": "Mai multe detalii",
  "page-eth2-get-involved-run-clients": "Rularea clienților lanțului Beacon",
  "page-eth2-get-involved-run-clients-desc": "Cheia securității pe termen lung a Ethereum este o distribuție puternică a clienților. Un client este un software care rulează blockchain-ul, verificând tranzacțiile și crearea de blocuri noi. Fiecare client are propriile sale caracteristici, așa că alege unul cu care te simți mai confortabil.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Mizează propriul ETH",
  "page-eth2-get-involved-stake-desc": "Acum poți să mizezi ETH pentru a ajuta la securizarea lanțului Beacon.",
  "page-eth2-get-involved-stake-eth": "Mizează ETH",
  "page-eth2-get-involved-subtitle": "Iată toate modurile prin care poți ajuta la eforturile legate de Ethereum și viitorul Eth2.",
  "page-eth2-get-involved-title-1": "Rulează un client",
  "page-eth2-get-involved-title-2": "Mizează propriul ETH",
  "page-eth2-get-involved-title-3": "Găsește erori",
  "page-eth2-get-involved-written-go": "Scris în Go",
  "page-eth2-get-involved-written-java": "Scris în Java",
  "page-eth2-get-involved-written-javascript": "Scris în JavaScript",
  "page-eth2-get-involved-written-net": "Scris în .NET",
  "page-eth2-get-involved-written-nim": "Scris în Nim",
  "page-eth2-get-involved-written-python": "Scris în Python",
  "page-eth2-get-involved-written-rust": "Scris în Rust",
  "eth2-client-cortex-logo-alt": "Logo Cortex",
  "eth2-client-lighthouse-logo-alt": "Logo Lighthouse",
  "eth2-client-lodestar-logo-alt": "Logo Lodestar",
  "eth2-client-nimbus-logo-alt": "Logo Nimbus",
  "eth2-client-prysm-logo-alt": "Logo Prysm",
  "eth2-client-teku-logo-alt": "Logo Teku",
  "eth2-rhino-img-alt": "Graficul evoluției Eth2 Rhino",
  "page-eth2-answer-1": "Gândește-te la Eth2 ca la un set de upgrade-uri adăugate pentru a îmbunătăți Ethereum-ul pe care îl folosim astăzi. Aceste upgrade-uri includ crearea unui nou lanț numit lanțul Beacon și până la 64 de lanțuri cunoscute sub numele de fragmente",
  "page-eth2-answer-2": "Acestea sunt separate de rețeaua principală Ethereum pe care o folosim astăzi, dar nu o vor înlocui. În schimb, rețeaua principală va andoca sau „fuziona” cu acest sistem paralel care este adăugat în timp.",
  "page-eth2-answer-4": "Cu alte cuvinte, Ethereum pe care îl folosim astăzi va întruchipa în cele din urmă toate caracteristicile pe care le căutăm în viziunea Eth2.",
  "page-eth2-beacon-chain-btn": "Mai multe despre lanțul Beacon",
  "page-eth2-beacon-chain-date": "Lanțul Beacon a fost introdus pe 1 decembrie 2020.",
  "page-eth2-beacon-chain-desc": "Prima adăugare Eth2 la ecosistem. Lanțul Beacon aduce mizarea în Ethereum, pune bazele upgrade-urilor viitoare și va coordona în cele din urmă noul sistem.",
  "page-eth2-beacon-chain-estimate": "Lanțul Beacon este activ",
  "page-eth2-beacon-chain-title": "Lanțul Beacon",
  "page-eth2-bug-bounty": "Vezi programul de recompensă pentru erori",
  "page-eth2-clients": "Consultă clienții Eth2",
  "page-eth2-deposit-contract-title": "Verifică adresa contractului de depozit",
  "page-eth2-diagram-ethereum-mainnet": " Rețeaua principală Ethereum",
  "page-eth2-diagram-h2": "Cum se potrivesc upgrade-urile împreună",
  "page-eth2-diagram-link-1": "Mai multe despre dovada mizei",
  "page-eth2-diagram-link-2": "Mai multe despre lanțurile de fragmente",
  "page-eth2-diagram-mainnet": "Rețea principală",
  "page-eth2-diagram-p": "Rețeaua principală Ethereum va exista în continuare în forma actuală, pentru o vreme. Acest lucru înseamnă că actualizarea lanțului Beacon și de fragmente nu va perturba rețeaua.",
  "page-eth2-diagram-p-1": "Rețeaua principală va fuziona în cele din urmă cu noul sistem introdus de upgrade-urile Eth2.",
  "page-eth2-diagram-p-2": "Lanțul Beacon va deveni dirijorul Ethereum, coordonând validatorii și stabilind ritmul pentru crearea blocurilor.",
  "page-eth2-diagram-p-3": "La început, va exista separat de rețeaua principală și va gestiona validatorii – nu va avea nimic de-a face cu contractele, tranzacțiile sau conturile inteligente.",
  "page-eth2-diagram-p-4": "Fragmentele vor furniza o mulțime de date suplimentare pentru a ajuta la creșterea numărului de tranzacții care pot fi gestionate de rețeaua principală. Ele vor fi coordonate de lanțul Beacon.",
  "page-eth2-diagram-p-5": "Dar toate tranzacțiile se vor baza în continuare pe rețeaua principală, care va continua să existe așa cum o știm astăzi – asigurată de dovada muncii și de mineri.",
  "page-eth2-diagram-p-6": "Rețeaua principală va fuziona cu sistemul de dovadă a mizei, coordonat de lanțul Beacon.",
  "page-eth2-diagram-p-8": "Acest lucru va transforma rețeaua principală într-un fragment al noului sistem. Minerii nu vor mai fi necesari, întrucât întregul Ethereum va fi asigurat de validatori.",
  "page-eth2-diagram-p10": "Eth2 nu este o migrație sau un singur lucru. Acesta descrie un set de upgrade-uri la care se lucrează pentru a debloca adevăratul potențial al Ethereum. Astfel se potrivesc toate acestea împreună.",
  "page-eth2-diagram-scroll": "Derulează pentru a explora – atinge pentru mai multe informații.",
  "page-eth2-diagram-shard": "Fragment (1)",
  "page-eth2-diagram-shard-1": "Fragment (...)",
  "page-eth2-diagram-shard-2": "Fragment (2)",
  "page-eth2-diagram-shard-3": "Fragment (...)",
  "page-eth2-diagram-validators": "Mai multe despre validatori",
  "page-eth2-dive": "Aprofundează viziunea",
  "page-eth2-dive-desc": "Cum vom face Ethereum mai scalabil, mai sigur și mai durabil? Totul păstrând etosul central al descentralizării Ethereum.",
  "page-eth2-docking": "Combinarea",
  "page-eth2-docking-answer-1": "Andocarea se produce atunci când rețeaua principală se transformă într-un fragment. Acest lucru va veni după o implementare cu succes a lanțurilor de fragmente.",
  "page-eth2-docking-btn": "Mai multe despre andocare",
  "page-eth2-docking-desc": "Rețeaua principală Ethereum va trebui să „andocheze” sau „fuzioneze” cu lanțul Beacon la un moment dat. Acest lucru va permite mizarea pentru întreaga rețea și va semnala sfârșitul mineritului cu consum intensiv de energie.",
  "page-eth2-docking-estimate": "Estimare: 2022",
  "page-eth2-docking-mainnet": "Ce este rețeaua principală?",
  "page-eth2-docking-mainnet-eth2": "Andocarea rețelei principale cu Eth2",
  "page-eth2-eth-blog": "blogul ethereum.org",
  "page-eth2-explore-btn": "Explorează upgrade-urile",
  "page-eth2-get-involved": "Implică-te în Eth2",
  "page-eth2-get-involved-2": "Implică-te",
  "page-eth2-head-to": "Mergi la",
  "page-eth2-help": "Vrei să ajuți cu Eth2?",
  "page-eth2-help-desc": "Există numeroase oportunități de a evalua upgrade-urile Eth2, de a ajuta la testare și chiar de a câștiga recompense.",
  "page-eth2-index-staking": "Mizarea este aici",
  "page-eth2-index-staking-desc": "Cheia upgrade-urilor Eth2 este introducerea mizării. Dacă dorești să utilizezi ETH pentru a asigura securitatea rețelei Ethereum, asigură-te că urmezi acești pași.",
  "page-eth2-index-staking-learn": "Află mai multe despre mizare",
  "page-eth2-index-staking-learn-desc": "Lanțul Beacon va aduce mizarea în Ethereum. Aceasta înseamnă că, dacă ai ETH, poți face un bine public securizând rețeaua și câștigând mai mult ETH în acest proces.",
  "page-eth2-index-staking-step-1": "1. Configurează cu launchpad-ul",
  "page-eth2-index-staking-step-1-btn": "Vizitează launchpad-ul de mizare",
  "page-eth2-index-staking-step-1-desc": "Pentru a participa la Eth2, va trebui să utilizezi launchpad-ul, iar acesta te va îndruma prin proces.",
  "page-eth2-index-staking-step-2": "2. Confirmă adresa mizei",
  "page-eth2-index-staking-step-2-btn": "Confirmă adresa contractului de depunere",
  "page-eth2-index-staking-step-2-desc": "Înainte de a miza ETH, asigură-te că ai verificat primirea adresei corecte. Trebuie să fi trecut prin launchpad înainte de a face acest lucru.",
  "page-eth2-index-staking-sustainability": "Mai durabil",
  "page-eth2-just-docking": "Mai multe despre andocare",
  "page-eth2-meta-desc": "O prezentare generală a upgrade-urilor Ethereum 2.0 și a viziunii pe care ele speră să le facă realitate.",
  "page-eth2-meta-title": "Upgrade-urile Eth2",
  "page-eth2-miners": "Mai multe despre mineri",
  "page-eth2-more-on-upgrades": "Mai multe despre upgrade-urile Eth2",
  "page-eth2-proof-of-stake": "dovada-mizei",
  "page-eth2-proof-of-work": "dovada-muncii",
  "page-eth2-proof-stake-link": "Mai multe despre dovada mizei",
  "page-eth2-question-1-desc": "Eth2 este un set de upgrade-uri distincte cu date de livrare diferite.",
  "page-eth2-question-1-title": "Când se va lansa Eth2?",
  "page-eth2-question-2-desc": "Nu este corect să ne gândim la Eth2 ca la un blockchain separat.",
  "page-eth2-question-2-title": "Este Eth2 un blockchain separat?",
  "page-eth2-question-3-answer-2": "Lanțul de fragmente și upgrade-urile de andocare pot avea impact asupra programatorilor de aplicații dapp. Specificațiile nu au fost încă finalizate, deci nu este necesară nicio acțiune imediată.",
  "page-eth2-question-3-answer-3": "Discută cu echipa de cercetare și dezvoltare Eth2 la ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Vizitează ethresear.ch",
  "page-eth2-question-3-desc": "Pentru moment nu trebuie să faci nimic pentru a te pregăti pentru Eth2.",
  "page-eth2-question-3-title": "Cum să mă pregătesc pentru Eth2?",
  "page-eth2-question-4-answer-1": "Ori de câte ori trimiți o tranzacție sau utilizezi o aplicație dapp astăzi, utilizezi Eth1. Acesta este Ethereum care este asigurat de mineri.",
  "page-eth2-question-4-answer-2": "„Eth1”, sau rețeaua principală, va continua să ruleze normal până la andocare.",
  "page-eth2-question-4-answer-3": "După andocare, validatorii vor securiza întreaga rețea prin dovada mizei (PoS).",
  "page-eth2-question-4-answer-6": "Oricine poate deveni un validator prin mizarea propriului ETH.",
  "page-eth2-question-4-answer-7": "Mai multe informații despre mizare",
  "page-eth2-question-4-answer-8": "Upgrade-urile la lanțul Beacon și a lanțul de fragmente nu vor perturba Eth1, deoarece acestea sunt construite separat.",
  "page-eth2-question-4-desc": "Eth1 se referă la rețeaua principală Ethereum pe care tranzacționezi astăzi.",
  "page-eth2-question-4-title": "Ce este Eth1?",
  "page-eth2-question-5-answer-1": "Pentru a deveni un validator complet în rețea, va trebui să mizezi 32 ETH. Dacă nu ai atât de mult sau nu ești dispus să mizezi atât de mult, poți să te alături grupurilor de mizare. Aceste grupuri îți vor permite să mizezi mai puțin și să câștigați fracțiuni din recompensele totale.",
  "page-eth2-question-5-desc": "Va trebui să utilizezi launchpad-ul de mizare sau să te alături unui grup de mizare.",
  "page-eth2-question-5-title": "Cum pot să mizez?",
  "page-eth2-question-6-answer-1": "Deocamdată nu există acțiuni de întreprins. Dar îți recomandăm să fii la curent cu evoluțiile legate de lanțurile de fragmente și de upgrade-urile de andocare.",
  "page-eth2-question-6-answer-3": "Danny Ryan de la Fundația Ethereum actualizează periodic comunitatea:",
  "page-eth2-question-6-answer-4": "Ben Edginton de la ConsenSys are un buletin informativ Eth2 săptămânal:",
  "page-eth2-question-6-answer-5": "De asemenea, te poți alătura discuției despre cercetarea și dezvoltarea Eth2 la ethresear.ch.",
  "page-eth2-question-6-desc": "Aplicația ta dapp nu va fi afectată de niciun upgrade iminent. Totuși, viitoarele upgrade-uri pot necesita unele modificări.",
  "page-eth2-question-6-title": "Ce trebuie să fac cu aplicația mea dapp?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(implementare .NET)",
  "page-eth2-question-7-desc": "Multe echipe diferite din întreaga comunitate lucrează la diferite upgrade-uri Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(implementare Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(Implementare JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(implementare Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Implementare Go)",
  "page-eth2-question-7-teams": "Echipele de client ale Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Implementare Java)",
  "page-eth2-question-7-title": "Cine construiește Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Implementare Python)",
  "page-eth2-question-8-answer-1": "Upgrade-urile Eth2 vor ajuta Ethereum să se extindă într-un mod descentralizat, menținând în același timp securitatea și sporind durabilitatea.",
  "page-eth2-question-8-answer-2": "Poate că cea mai evidentă problemă este că Ethereum trebuie să poată gestiona mai mult de 15-45 de tranzacții pe secundă. Dar upgrade-urile Eth2 abordează și alte probleme cu Ethereum de astăzi.",
  "page-eth2-question-8-answer-3": "Rețeaua are o cerere atât de mare încât face Ethereum scump de utilizat. Nodurile din rețea se luptă sub dimensiunea redusă a Ethereum-ului și cantitatea mare de date pe care computerele lor trebuie să le proceseze. În plus, algoritmul de bază care menține Ethereum în siguranță și descentralizat, consumă multă energie și trebuie să fie mai ecologic.",
  "page-eth2-question-8-answer-4": "O mare parte din ceea ce s-a schimbat a fost întotdeauna pe foaia de parcurs Ethereum, chiar din 2015. Dar condițiile actuale fac ca nevoia de upgrade-uri să fie și mai mare.",
  "page-eth2-question-8-answer-6": "Explorează viziunea Eth2",
  "page-eth2-question-8-desc": "Ethereum pe care îl folosim astăzi trebuie să ofere o experiență mai bună utilizatorilor finali și participanților la rețea.",
  "page-eth2-question-8-title": "De ce este nevoie de Eth2?",
  "page-eth2-question-9-answer-1": "Rolul cel mai activ pe care îl poți juca este să-ți mizezi propriul ETH.",
  "page-eth2-question-9-answer-2": "Poate dorești să rulezi un al doilea client pentru a ajuta la îmbunătățirea diversității clientului.",
  "page-eth2-question-9-answer-3": "Dacă ești mai tehnic, poți ajuta la detectarea de erori la noii clienți.",
  "page-eth2-question-9-answer-4": "Poți, de asemenea, să participi la discuțiile tehnice cu cercetătorii Ethereum la ethresear.ch.",
  "page-eth2-question-9-desc": "Nu trebuie să fii tehnic pentru a contribui. Comunitatea caută contribuții de tot felul de abilități.",
  "page-eth2-question-9-stake-eth": "Mizează ETH",
  "page-eth2-question-9-title": "Cum pot să contribui la Eth2?",
  "page-eth2-question-10-answer-1": "Suntem reticenți să vorbim prea mult despre o foaie de parcurs tehnică, deoarece acesta este un software: lucrurile se pot schimba. Credem că este mai ușor să înțelegi ce se întâmplă atunci când citești despre rezultate.",
  "page-eth2-question-10-answer-1-link": "Vezi upgrade-urile",
  "page-eth2-question-10-answer-2": "Dar dacă ai urmărit discuțiile, iată cum se încadrează upgrade-urile în foile de parcurs tehnice.",
  "page-eth2-question-10-answer-3": "Etapa 0 descrie munca pentru a aduce la viață lanțul Beacon.",
  "page-eth2-question-10-answer-5": "Etapa 1 a foilor de parcurs tehnice se axează pe implementarea lanțurilor de fragmente.",
  "page-eth2-question-10-answer-6": "Andocarea rețelei principale în Eth2 este o extensie a muncii realizate pentru implementarea lanțurilor de fragmente și a fost denumită Etapa 1.5. Dar este un moment semnificativ, deoarece Ethereum pe care îl știm astăzi va fuziona cu celelalte upgrade-uri Eth2. Și în plus, este momentul când Ethereum face tranziția completă la dovada mizei.",
  "page-eth2-question-10-answer-7": "În prezent nu este clar ce se va întâmpla în jurul Etapei 2. Este încă un punct de cercetare și discuții intense. Planul inițial a fost de a adăuga funcționalități suplimentare lanțurilor de fragmente, dar s-ar putea să nu mai fie necesare cu o foaie de parcurs axată pe dezvoltare.",
  "page-eth2-question-10-answer-8": "Mai mult despre foaia de parcurs axată pe dezvoltare",
  "page-eth2-question-10-desc": "Etapele se referă la etapele de lucru și se concentrează pe foaia de parcurs tehnică Eth2.",
  "page-eth2-question-10-title": "Care sunt etapele Eth2?",
  "page-eth2-question-title": "Întrebări frecvente",
  "page-eth2-question3-answer-1": "Deținătorii de Eth nu trebuie să facă nimic. ETH-ul tău nu va necesita schimbări sau upgrade-uri. Cu siguranță există escroci care îți spun altceva, așa că fii atent.",
  "page-eth2-scalable": "Mai scalabil",
  "page-eth2-scalable-desc": "Ethereum trebuie să suporte mii de tranzacții pe secundă, pentru a face aplicațiile mai rapide și mai ieftin de utilizat.",
  "page-eth2-secure": "Mai protejat",
  "page-eth2-secure-desc": "Ethereum trebuie să fie mai securizat. Pe măsură ce adoptarea Ethereum crește, protocolul trebuie să devină mai securizat împotriva tuturor formelor de atac.",
  "page-eth2-shard-button": "Mai multe despre lanțurile de fragmente",
  "page-eth2-shard-date": "Introducerea lanțurilor de fragmente, al doilea upgrade, este așteptată în 2021.",
  "page-eth2-shard-desc": "Lanțurile de fragmente vor extinde capacitatea Ethereum de a procesa tranzacții și stoca date. Fragmentele în sine vor câștiga mai multe caracteristici în timp, lansate în mai multe faze.",
  "page-eth2-shard-estimate": "Estimare: 2021",
  "page-eth2-shard-lower": "Mai multe despre lanțurile de fragmente",
  "page-eth2-shard-title": "Lanțuri de fragmente",
  "page-eth2-stay-up-to-date": "Rămâi la curent",
  "page-eth2-stay-up-to-date-desc": "Obține cele mai recente informații de la cercetătorii și programatorii care lucrează la upgrade-urile Eth2.",
  "page-eth2-sustainable-desc": "Ethereum trebuie să fie mai bun pentru mediu. Tehnologia de azi necesită prea multă putere de calcul și energie.",
  "page-eth2-take-part": "Ia parte la cercetare",
  "page-eth2-take-part-desc": "Cercetătorii și entuziaștii Ethereum se întâlnesc aici pentru a discuta despre eforturile de cercetare, inclusiv orice are legătură cu Eth2.",
  "page-eth2-the-upgrades": "Upgrade-urile Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 este un set de upgrade-uri care îmbunătățesc scalabilitatea, securitatea și sustenabilitatea Ethereum. Deși fiecare este lucrat în paralel, acestea au anumite dependențe care determină când vor fi implementate.",
  "page-eth2-unofficial-roadmap": "Aceasta nu este foaia de parcurs oficială. Acesta este modul în care vedem ce se întâmplă pe baza informațiilor care există. Dar aceasta este tehnologie, lucrurile se pot schimba într-o clipă. Așadar, te rugăm să nu citești acest lucru ca pe un angajament.",
  "page-eth2-upgrade-desc": "Ethereum pe care îl cunoaștem și îl iubim, doar mai scalabil, mai protejat și mai durabil...",
  "page-eth2-upgrades": "Upgrade-urile Eth2",
  "page-eth2-upgrades-aria-label": "Meniu de upgrade-uri Eth2",
  "page-eth2-upgrades-beacon-chain": "Lanțul Beacon",
  "page-eth2-upgrades-docking": "Andocarea rețelei principale și Eth2",
  "page-eth2-upgrades-guide": "Ghid pentru upgrade-urile Eth2",
  "page-eth2-upgrades-shard-chains": "Lanțurile de fragmente",
  "page-eth2-upgrading": "Îmbunătățind Ethereum pe noi culmi radicale",
  "page-eth2-vision": "Viziunea",
  "page-eth2-vision-btn": "Mai multe despre viziunea Eth2",
  "page-eth2-vision-desc": "Pentru a aduce Ethereum pe primul plan și a servi întregii umanități, trebuie să-l facem mai scalabil, mai protejat și mai durabil.",
  "page-eth2-vision-upper": "Viziunea Eth2",
  "page-eth2-what-to-do": "Ce trebuie să faci?",
  "page-eth2-what-to-do-desc": "Dacă utilizezi o aplicație dapp sau deții ETH, nu este nevoie să faci nimic. Dacă ești un programator sau vrei să începi să mizezi, există modalități prin care te poți implica chiar astăzi.",
  "page-eth2-whats-eth2": "Ce este Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 se referă la un set de upgrade-uri interconectate care vor face Ethereum mai scalabil, mai sigur și mai sustenabil. Aceste îmbunătățiri sunt construite de mai multe echipe din ecosistemul Ethereum.",
  "page-eth2-whats-ethereum": "Ce este Ethereum?",
  "page-eth2-whats-new": "Ce este nou în Eth2?",
  "page-eth2-just-staking": "Mizarea",
  "page-eth2-staking-image-alt": "Imaginea mascotei Rhino pentru launchpad-ul eth2.",
  "page-eth2-staking-51-desc-1": "Mizarea face ca accesul la rețea ca validator să fie mai accesibil, deci este posibil că în rețea vor exista probabil mai mulți validatori decât sunt astăzi. Aceasta va face acest tip de atac și mai dificil deoarece costul unui atac va crește.",
  "page-eth2-staking-accessibility": "Mai accesibil",
  "page-eth2-staking-accessibility-desc": "Cu cerințe hardware mai ușoare și posibilitatea de a pune în comun mize dacă nu ai 32 ETH, mai mulți oameni vor putea să se alăture rețelei. Aceasta va face Ethereum mai descentralizat și mai sigur prin scăderea suprafeței de atac.",
  "page-eth2-staking-at-stake": "Propriul tău ETH în joc",
  "page-eth2-staking-at-stake-desc": "Deoarece trebuie să-ți mizezi propriul ETH pentru a valida tranzacțiile și a crea noi blocuri, îl poți pierde dacă decizi să încerci să înșeli sistemul.",
  "page-eth2-staking-benefits": "Avantajele mizării pe Ethereum",
  "page-eth2-staking-check-address": "Verifică adresa depozitului",
  "page-eth2-staking-consensus": "Mai multe despre mecanismele de consens",
  "page-eth2-staking-deposit-address": "Verifică adresa depozitului",
  "page-eth2-staking-deposit-address-desc": "Dacă ai urmat deja instrucțiunile de configurare de pe launchpad, vei ști că trebuie să trimiți o tranzacție la contractul de depunere de miză. Îți recomandăm să verifici foarte atent adresa. Poți găsi adresa oficială pe ethereum.org și o serie de alte site-uri de încredere.",
  "page-eth2-staking-desc-1": "Se acordă recompense pentru acțiunile care ajută rețeaua să ajungă la un consens. Vei obține recompense pentru gruparea tranzacțiilor într-un bloc nou sau pentru verificarea activității altor validatori, deoarece acest lucru menține lanțul în siguranță.",
  "page-eth2-staking-desc-2": "Deși poți câștiga recompense pentru a face ceva care aduce beneficii rețelei, poți pierde ETH pentru acțiuni rău intenționate, deconectarea și nereușita validării.",
  "page-eth2-staking-desc-3": "Vei avea nevoie de 32 ETH pentru a deveni un validator complet sau de ceva ETH pentru a te alătura unui grup de mizare. De asemenea, va trebui să rulezi un client „Eth1” sau client rețea principală. Launchpad-ul te va ghida prin proces și cerințele hardware. Alternativ, poți utiliza un API back-end.",
  "page-eth2-staking-description": "Mizarea este actul de a depune 32 ETH pentru a activa un software de validare. În calitate de validator, vei fi responsabil pentru stocarea datelor, procesarea tranzacțiilor și adăugarea de blocuri noi în blockchain. Aceasta va menține Ethereum în siguranță pentru toată lumea și te va face să câștigi ETH nou în acest proces. Acest proces, cunoscut sub numele de dovada mizei, este introdus de lanțul Beacon.",
  "page-eth2-staking-docked": "Mai multe despre andocare",
  "page-eth2-staking-dyor": "Fă propriile tale cercetări",
  "page-eth2-staking-dyor-desc": "Niciunul dintre serviciile de mizare listate nu reprezintă o aprobare oficială. Efectuează propriile cercetări pentru a afla ce serviciu ți se potrivește cel mai bine.",
  "page-eth2-staking-header-1": "Mizează ETH pentru a deveni un validator Ethereum",
  "page-eth2-staking-how-much": "Cât ești dispus să mizezi?",
  "page-eth2-staking-how-to-stake": "Cum să mizez",
  "page-eth2-staking-how-to-stake-desc": "Totul depinde de cât de mult ești dispus să mizezi. Vei avea nevoie de 32 ETH pentru a deveni un validator complet, dar este posibil să mizezi mai puțin.",
  "page-eth2-staking-join": "Alătură-te",
  "page-eth2-staking-join-community": "Alătură-te comunității de mizare",
  "page-eth2-staking-join-community-desc": "r/ethstaker este o comunitate pentru toți pentru a discuta mizarea pe Ethereum – alătură-te pentru sfaturi, asistență și pentru a vorbi orice despre mizare.",
  "page-eth2-staking-less-than": "Mai puțin de",
  "page-eth2-staking-link-1": "Vezi API-urile back-end",
  "page-eth2-staking-meta-description": "O prezentare generală a mizării în Ethereum: riscurile, recompensele, cerințele și unde să o faci.",
  "page-eth2-staking-meta-title": "Mizarea în Ethereum",
  "page-eth2-staking-more-sharding": "Mai multe despre fragmentare",
  "page-eth2-staking-pool": "Mizarea în grup",
  "page-eth2-staking-pool-desc": "Dacă ai mai puțin de 32 ETH, vei putea adăuga o miză mai mică la un grup de mizare. Unele companii pot face totul în numele tău, astfel încât să nu-ți faci griji despre rămânerea online. Iată câteva companii de verificat.",
  "page-eth2-staking-pos-explained": "Dovada mizei explicată",
  "page-eth2-staking-pos-explained-desc": "Mizarea este ceea ce trebuie să faci pentru a deveni validator într-un sistem de dovadă a mizei. Acesta este un mecanism de consens care va înlocui sistemul dovezii muncii în vigoare în prezent. Mecanismele de consens sunt cele care păstrează blockchain-uri precum Ethereum securizate și descentralizate.",
  "page-eth2-staking-pos-explained-desc-1": "Dovada mizei ajută la securizarea rețelei în mai multe moduri:",
  "page-eth2-staking-services": "Vezi serviciile de mizare",
  "page-eth2-staking-sharding": "Deblochează fragmentarea",
  "page-eth2-staking-sharding-desc": "Fragmentarea este posibilă numai cu un sistem de dovadă a mizei. Fragmentarea unui sistem de dovadă a muncii ar dilua cantitatea de putere de calcul necesară pentru a corupe rețeaua, facilitând controlul fragmentelor de către minerii rău intenționați. Nu este cazul cu stakeri repartizați aleatoriu în dovada mizei.",
  "page-eth2-staking-solo": "Mizează singur și rulează un validator",
  "page-eth2-staking-solo-desc": "Pentru a începe procesul de mizare, va trebui să utilizezi launchpad-ul Eth2. Aceasta te va conduce prin toate setările. O parte a mizării este executarea unui client Eth2, care este o copie locală a blockchain-ului. Descărcarea pe calculator poate dura ceva timp.",
  "page-eth2-staking-start": "Începe să mizezi",
  "page-eth2-staking-subtitle": "Mizarea este un bun public pentru ecosistemul Ethereum. Poți ajuta la securizarea rețelei și poți câștiga recompense în acest proces.",
  "page-eth2-staking-sustainability": "Mai sustenabil",
  "page-eth2-staking-sustainability-desc": "Validatorii nu au nevoie de computere cu consum mare de energie pentru a participa la un sistem de dovadă a mizei – doar un laptop sau un telefon inteligent. Aceasta va face Ethereum mai bun pentru mediu.",
  "page-eth2-staking-the-beacon-chain": "Mai multe despre lanțul Beacon",
  "page-eth2-staking-title-1": "Recompense",
  "page-eth2-staking-title-2": "Riscuri",
  "page-eth2-staking-title-3": "Cerințe",
  "page-eth2-staking-title-4": "Cum să-ți mizezi propriul ETH",
  "page-eth2-staking-upgrades-li": "Dovada mizei este gestionată de lanțul Beacon.",
  "page-eth2-staking-upgrades-li-2": "Ethereum va avea un lanț Beacon bazat pe dovada mizei și o rețea principală bazată pe dovada muncii pentru viitorul apropiat. Rețeaua principală este Ethereum-ul pe care îl folosim de mulți ani.",
  "page-eth2-staking-upgrades-li-3": "În acest timp, stakerii vor adăuga noi blocuri lanțului Beacon, dar nu vor procesa tranzacțiile din rețeaua principală.",
  "page-eth2-staking-upgrades-li-4": "Ethereum va trece pe deplin la un sistem de dovadă a mizei odată ce rețeaua principală Ethereum devine un fragment al lanțului Beacon.",
  "page-eth2-staking-upgrades-li-5": "Numai atunci îți vei putea retrage miza.",
  "page-eth2-staking-upgrades-title": "Upgrade-uri ale dovezii mizei și Eth2",
  "page-eth2-staking-validators": "Mai mulți validatori, mai multă securitate",
  "page-eth2-staking-validators-desc": "Un blockchain precum Ethereum poate fi corupt când controlezi 51% din rețea. De exemplu, ai putea obține 51% din validatori care să afirme că soldul tău este 1.000.000 ETH și nu 1 ETH. Dar, pentru a controla 51% din validatori, ar trebui să deții 51% din ETH-ul din sistem – ceea ce este foarte mult!",
  "page-eth2-staking-withdrawals": "Retragerile nu vor fi activate imediat",
  "page-eth2-staking-withdrawals-desc": "Nu-ți vei putea retrage miza până când nu vor fi implementate viitoarele upgrade-uri. Retragerile ar trebui să fie disponibile numai după andocarea rețelei principale cu sistemul lanțului Beacon.",
  "page-eth2-vision-2014": "Vizualizează o postare de blog din 2014 care detaliază dovada mizei",
  "page-eth2-vision-beacon-chain": "Lanțul Beacon",
  "page-eth2-vision-beacon-chain-btn": "Mai multe despre lanțul Beacon",
  "page-eth2-vision-beacon-chain-date": "Lanțul Beacon este activ",
  "page-eth2-vision-beacon-chain-desc": "Prima adăugare Eth2 la ecosistem. Lanțul Beacon aduce mizarea în Ethereum, pune bazele upgrade-urilor viitoare și va coordona în cele din urmă noul sistem.",
  "page-eth2-vision-beacon-chain-upper": "Lanțul Beacon",
  "page-eth2-vision-desc-1": "Ethereum trebuie să micșoreze congestia rețelei și să îmbunătățească viteza pentru a deservi mai bine o bază globală de utilizatori.",
  "page-eth2-vision-desc-2": "Rularea unui nod este din ce în ce mai dificilă pe măsură ce rețeaua crește. Acest lucru va fi mai greu doar cu eforturile de a scala rețeaua.",
  "page-eth2-vision-desc-3": "Ethereum folosește prea multă energie electrică. Tehnologia care menține rețeaua în securitate trebuie să fie mai durabilă.",
  "page-eth2-vision-docking-date": "Estimare: 2022",
  "page-eth2-vision-docking-desc": "Rețeaua principală Ethereum va trebui să „andocheze” cu lanțul Beacon la un moment dat. Acest lucru va permite tuturor în rețea să poată miza și va semnala sfârșitul mineritului cu consum intensiv de energie.",
  "page-eth2-vision-ethereum-node": "Mai multe despre noduri",
  "page-eth2-vision-explore-upgrades": "Explorează upgrade-urile",
  "page-eth2-vision-future": "Un viitor digital la scară globală",
  "page-eth2-vision-meta-desc": "O prezentare generală a impactului pe care îl va avea actualizarea Eth2 asupra Ethereum și provocările pe care trebuie să le depășească.",
  "page-eth2-vision-meta-title": "Viziunea Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Mai multe despre minerit",
  "page-eth2-vision-problems": "Problemele de astăzi",
  "page-eth2-vision-scalability": "Scalabilitatea",
  "page-eth2-vision-scalability-desc": "Ethereum trebuie să poată gestiona mai multe tranzacții pe secundă fără a crește dimensiunea nodurilor din rețea. Nodurile sunt participanți esențiali la rețea care stochează și rulează blockchain-ul. Creșterea dimensiunii nodurilor nu este practică, deoarece doar cei cu computere puternice și scumpe ar putea să o facă. Pentru a scala, Ethereum are nevoie de mai multe tranzacții pe secundă, împreună cu mai multe noduri. Mai multe noduri înseamnă mai multă securitate.",
  "page-eth2-vision-scalability-desc-3": "Upgrade-ul lanțurilor de fragmente va distribui sarcina rețelei în 64 de lanțuri noi. Acest lucru va oferi Ethereum spațiu de respirație prin reducerea congestiei și îmbunătățirea vitezei peste limita actuală de 15-45 de tranzacții pe secundă.",
  "page-eth2-vision-scalability-desc-4": "Și chiar dacă vor exista mai multe lanțuri, acest lucru va necesita de fapt mai puțină muncă de la validatori „întreținătorii rețelei”. Validatorii vor trebui doar să-și „ruleze” fragmentul și nu întregul lanț Ethereum. Acest lucru face ca nodurile să fie mai ușoare, permițând Ethereum să se extindă și să rămână descentralizat.",
  "page-eth2-vision-security": "Securitate",
  "page-eth2-vision-security-desc": "Upgrade-urile Eth2 îmbunătățesc securitatea Ethereum împotriva atacurilor coordonate, cum ar fi un atac de 51%. Acesta este un tip de atac în care, dacă cineva controlează majoritatea rețelei, poate forța prin modificări frauduloase.",
  "page-eth2-vision-security-desc-3": "Trecerea la dovada mizei înseamnă că protocolul Ethereum are mai multe măsuri de descurajare a atacurilor. Acest lucru se datorează faptului că în dovada mizei, validatorii care securizează rețeaua trebuie să pună în protocol cantități semnificative de ETH. Dacă încearcă și atacă rețeaua, protocolul le poate distruge automat ETH-ul lor propriu.",
  "page-eth2-vision-security-desc-5": "Acest lucru nu este posibil în dovada muncii, unde cel mai bun lucru pe care îl poate face un protocol este să forțeze entitățile care securizează rețeaua (minerii) să piardă recompensele miniere pe care le-ar fi câștigat altfel. Pentru a obține efectul echivalent în dovada muncii, protocolul ar trebui să poată distruge tot echipamentul unui miner dacă încearcă să trișeze.",
  "page-eth2-vision-security-desc-5-link": "Mai multe despre dovada muncii",
  "page-eth2-vision-security-desc-8": "Modelul de securitate al Ethereum trebuie, de asemenea, să se schimbe din cauza introducerii",
  "page-eth2-vision-security-desc-10": "Mizarea înseamnă de asemenea, că nu este nevoie să investești în hardware de elită pentru a „rula” un nod Ethereum. Acest lucru ar trebui să încurajeze mai mulți oameni să devină validatori, crescând descentralizarea rețelei și scăzând suprafața de atac.",
  "page-eth2-vision-security-staking": "Mizează ETH",
  "page-eth2-vision-security-validator": "Poți deveni validator mizând propriul ETH.",
  "page-eth2-vision-shard-chains": "lanțuri de fragmente",
  "page-eth2-vision-shard-date": "Estimare: 2021",
  "page-eth2-vision-shard-desc-4": "Lanțurile de fragmente vor distribui sarcina rețelei în 64 de blockchain-uri noi. Fragmentele au potențialul de a îmbunătăți drastic viteza tranzacției - până la 100.000 pe secundă.",
  "page-eth2-vision-shard-upgrade": "Mai multe despre lanțurile de fragmente",
  "page-eth2-vision-staking-lower": "Mai multe despre mizare",
  "page-eth2-vision-subtitle": "Crește Ethereum până când e suficient de puternic pentru a ajuta întreaga umanitate.",
  "page-eth2-vision-sustainability": "Durabilitate",
  "page-eth2-vision-sustainability-desc-1": "Nu este un secret că Ethereum și alte blockchain-uri precum Bitcoin sunt consumatoare de energie din cauza mineritului.",
  "page-eth2-vision-sustainability-desc-2": "Dar Ethereum se îndreaptă spre obținerea securității prin ETH, nu prin puterea de calcul – prin mizare.",
  "page-eth2-vision-sustainability-desc-3": "Deși mizarea va fi introdusă de lanțul Beacon, Ethereum pe care îl folosim astăzi va rula în paralel pentru o perioadă de timp, înainte de a „fuziona” sau „andoca” cu upgrade-urile Eth2. Un sistem securizat de ETH, celălalt prin putere de calcul. Acest lucru se datorează faptului că, la început, lanțurile de fragmente nu vor putea gestiona lucruri precum conturile sau aplicațiile noastre dapp. Deci, nu putem uita doar de minerit și de rețeaua principală.",
  "page-eth2-vision-sustainability-desc-8": "Odată ce upgrade-urile lanțului Beacon și ale lanțului de fragmente sunt funcționale, vor începe lucrările de andocare a rețelei principale cu noul sistem. Acest lucru va transforma rețeaua principală într-un fragment securizat de ETH și mult mai puțin energofag.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum trebuie să fie mai ecologic.",
  "page-eth2-vision-The": ". ",
  "page-eth2-vision-title": "Viziunea Eth2",
  "page-eth2-vision-title-1": "Rețea congestionată",
  "page-eth2-vision-title-2": "Spațiu pe disc",
  "page-eth2-vision-title-3": "Prea multă energie",
  "page-eth2-vision-trilemma-cardtext-1": "Upgrade-urile Eth2 vor face Ethereum scalabil, sigur și descentralizat. Fragmentarea va face Ethereum mai scalabil prin creșterea numărului de tranzacții pe secundă, reducând în același timp energia necesară pentru a rula un nod și pentru a valida lanțul. Lanțul Beacon va face Ethereum sigur prin coordonarea validatorilor între fragmente. Și mizarea va reduce bariera în calea participării, creând o rețea – mai descentralizată – mai mare.",
  "page-eth2-vision-trilemma-cardtext-2": "Rețelele blockchain securizate și descentralizate necesită ca fiecare nod să verifice fiecare tranzacție procesată de lanț. Acest volum de lucru limitează numărul de tranzacții care pot avea loc la un moment dat. Descentralizat și securizat, reflectă lanțul Ethereum de astăzi.",
  "page-eth2-vision-trilemma-cardtext-3": "Rețelele descentralizate funcționează prin trimiterea de informații despre tranzacții între noduri – întreaga rețea trebuie să știe despre orice schimbare de stare. Scalarea tranzacțiilor pe secundă într-o rețea descentralizată prezintă riscuri de securitate, deoarece cu cât sunt mai multe tranzacții, cu atât este mai mare întârzierea, cu atât este mai mare probabilitatea de atac în timp ce informațiile sunt în zbor.",
  "page-eth2-vision-trilemma-cardtext-4": "Creșterea dimensiunii și puterii nodurilor Ethereum ar putea crește numărul de tranzacțiile pe secundă în mod sigur, dar cerința hardware ar restricționa cine ar putea face acest lucru – amenințând astfel descentralizarea. Se speră că fragmentarea și dovada mizei vor permite Ethereum să se extindă prin creșterea numărului de noduri, nu a dimensiunii nodului.",
  "page-eth2-vision-trilemma-h2": "Provocarea scalării descentralizate",
  "page-eth2-vision-trilemma-modal-tip": "Atinge cercurile de mai jos pentru a înțelege mai bine problemele scalării descentralizate",
  "page-eth2-vision-trilemma-p": "Un mod naiv de a rezolva problemele Ethereum ar fi să-l facem mai centralizat. Dar descentralizarea este prea importantă. Descentralizarea oferă Ethereum rezistență împotriva cenzurii, deschidere, confidențialitatea datelor și o securitate aproape de neînvins.",
  "page-eth2-vision-trilemma-p-1": "Viziunea Ethereum este să fie mai scalabil și mai securizat, dar de asemenea, să rămână descentralizat. Realizarea acestor 3 deziderate este o problemă cunoscută sub numele de trilema scalabilității.",
  "page-eth2-vision-trilemma-p-2": "Upgrade-urile Eth2 își propun să rezolve trilema, dar există provocări semnificative.",
  "page-eth2-vision-trilemma-press-button": "Apasă butoanele de pe triunghi pentru a înțelege mai bine problemele scalării descentralizate.",
  "page-eth2-vision-trilemma-text-1": "Descentralizarea",
  "page-eth2-vision-trilemma-text-2": "Securitate",
  "page-eth2-vision-trilemma-text-3": "Scalabilitatea",
  "page-eth2-vision-trilemma-title-1": "Explorează trilema scalabilității",
  "page-eth2-vision-trilemma-title-2": "Upgrade-urile Eth2 și scalarea descentralizată",
  "page-eth2-vision-trilemma-title-3": "Securizat și descentralizat",
  "page-eth2-vision-trilemma-title-4": "Descentralizat și scalabil",
  "page-eth2-vision-trilemma-title-5": "Scalabil și securizat",
  "page-eth2-vision-understanding": "Înțelegerea viziunii Eth2",
  "page-eth2-vision-upgrade-needs": "Nevoia de upgrade-uri Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Protocolul Ethereum lansat în 2015 a avut un succes incredibil. Dar comunitatea Ethereum se așteaptă întotdeauna ca unele upgrade-uri cheie să fie necesare pentru a debloca întregul potențial al Ethereum.",
  "page-eth2-vision-upgrade-needs-desc-2": "Cererea ridicată crește taxele pe tranzacții, ceea ce face Ethereum scump pentru utilizatorul mediu. Spațiul pe disc necesar pentru a rula un client Ethereum crește într-un ritm rapid. Iar algoritmul de bază al consensului prin dovada muncii, care menține Ethereum în siguranță și descentralizat, are un mare impact asupra mediului.",
  "page-eth2-vision-upgrade-needs-desc-3": "Ceea ce este denumit în mod obișnuit Eth2 este un set de upgrade-uri care abordează aceste probleme și multe altele. Acest set de upgrade-uri a fost numit inițial „Serenity” și a reprezentat un domeniu activ de cercetare și dezvoltare încă din 2014.",
  "page-eth2-vision-upgrade-needs-desc-6": " Acest lucru înseamnă că nu există un comutator de pornire pentru Eth2. Îmbunătățirile vor fi livrate în mod progresiv în timp.",
  "page-eth2-vision-upgrade-needs-serenity": "Vizualizează o postare de blog din 2015 despre „Serenity”",
  "page-eth2-vision-uprade-needs-desc-5": "Acum, când tehnologia este gata, aceste upgrade-uri vor reproiecta Ethereum pentru a-l face mai scalabil, mai sigur și mai durabil – pentru a îmbunătăți viața utilizatorilor existenți și a atrage pe alții noi. Totul păstrând valoarea centrală a descentralizării Ethereum.",
  "page-get-eth-cex": "Schimburi centralizate",
  "page-get-eth-cex-desc": "Schimburile sunt companii care îți permit să cumperi cripto folosind monede tradiționale. Ele au custodia oricărui ETH pe care îl cumperi până când îl trimiți într-un portofel pe care îl controlezi.",
  "page-get-eth-checkout-dapps-btn": "Verifică aplicațiile dapp",
  "page-get-eth-community-safety": "Postările comunității în materie de securitate",
  "page-get-eth-description": "Ethereum și ETH nu sunt controlate de niciun guvern sau companie – sunt descentralizate. Aceasta înseamnă că ETH-ul este deschis tuturor pentru utilizare.",
  "page-get-eth-dex": "Schimburi descentralizate (DEX-uri)",
  "page-get-eth-dex-desc": "Dacă dorești mai mult control, cumpără ETH peer-to-peer. Cu un DEX poți tranzacționa fără a da controlul fondurilor tale unei companii centralizate.",
  "page-get-eth-dexs": "Schimburi descentralizate (DEX-uri)",
  "page-get-eth-dexs-desc": "Schimburile descentralizate sunt piețe deschise pentru ETH și alte tokenuri. Acestea conectează direct cumpărătorii și vânzătorii.",
  "page-get-eth-dexs-desc-2": "În loc să folosească o terță parte de încredere pentru a proteja fondurile în tranzacție, ei folosesc codul. ETH-ul vânzătorului va fi transferat numai atunci când plata este garantată. Acest tip de cod este cunoscut sub numele de contract inteligent.",
  "page-get-eth-dexs-desc-3": "Aceasta înseamnă că sunt mai puține restricții geografice decât în ​​cazul alternativelor centralizate. Dacă cineva vinde ceea ce dorești și acceptă o metodă de plată pe care o poți furniza, ești gata de drum. DEX-urile îți pot permite să cumperi ETH cu alte tokenuri, PayPal sau chiar livrări de numerar în persoană.",
  "page-get-eth-do-not-copy": "EXEMPLU: NU COPIA",
  "page-get-eth-exchanges-disclaimer": "Am colectat aceste informații manual. Dacă observi ceva greșit, anunță-ne la",
  "page-get-eth-exchanges-empty-state-text": "Introdu țara ta de reședință pentru a vedea o listă de portofele și schimburi pe care le poți utiliza pentru a cumpăra ETH",
  "page-get-eth-exchanges-except": "Cu excepția",
  "page-get-eth-exchanges-header": "În ce țară locuiești?",
  "page-get-eth-exchanges-header-exchanges": "Schimburi",
  "page-get-eth-exchanges-header-wallets": "Portofele",
  "page-get-eth-exchanges-intro": "Schimburile și portofelele au restricții cu privire la locul unde pot vinde cripto.",
  "page-get-eth-exchanges-no-exchanges": "Ne pare rău, nu cunoaștem schimburi care să-ți permită să cumperi ETH din această țară. Dacă tu cunoști, spune-ne la",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Ne pare rău, nu cunoaștem schimburi sau portofele care să-ți permită să cumperi ETH din această țară. Dacă tu cunoști, spune-ne la",
  "page-get-eth-exchanges-no-wallets": "Ne pare rău, nu cunoaștem niciun portofel care să-ți permită să cumperi ETH din această țară. Dacă tu cunoști, spune-ne la",
  "page-get-eth-exchanges-success-exchange": "Poate dura câteva zile pentru a te înregistra la un schimb din cauza verificărilor lor legale.",
  "page-get-eth-exchanges-success-wallet-link": "portofele",
  "page-get-eth-exchanges-success-wallet-paragraph": "Unde locuiești, poți cumpăra ETH direct din aceste portofele. Află mai multe despre",
  "page-get-eth-exchanges-usa": "Statele Unite ale Americii (SUA)",
  "page-get-eth-get-wallet-btn": "Obține un portofel",
  "page-get-eth-hero-image-alt": "Obține imaginea eroului ETH",
  "page-get-eth-keep-it-safe": "Menținerea siguranței ETH",
  "page-get-eth-meta-description": "Cum să cumperi ETH în funcție de zona în care locuiești și sfaturi despre cum să ai grijă de el.",
  "page-get-eth-meta-title": "Cum să cumperi ETH",
  "page-get-eth-need-wallet": "Vei avea nevoie de un portofel pentru a utiliza un DEX.",
  "page-get-eth-new-to-eth": "Ești nou în ETH? Iată o prezentare generală pentru a începe.",
  "page-get-eth-other-cryptos": "Cumpără cu altă cripto",
  "page-get-eth-protect-eth-desc": "Dacă intenționezi să cumperi mult ETH, îți recomandăm să-l păstrezi într-un portofel pe care îl controlezi, nu într-un schimb. Aceasta deoarece un schimb este o țintă probabilă pentru hackeri. Dacă un hacker obține acces, poți pierde fondurile. Alternativ, doar tu deții controlul portofelului.",
  "page-get-eth-protect-eth-in-wallet": "Protejează-ți ETH-ul într-un portofel",
  "page-get-eth-search-by-country": "Caută după țară",
  "page-get-eth-security": "Dar acest lucru înseamnă, de asemenea, că trebuie să iei în serios securitatea fondurilor. Cu ETH, nu ai încredere într-o instituție bancară care să-ți îngrijească banii, ci ai încredere în tine.",
  "page-get-eth-smart-contract-link": "Mai multe despre contractele inteligente",
  "page-get-eth-swapping": "Schimbă tokenuri pentru ETH cu alte persoane. Și invers.",
  "page-get-eth-traditional-currencies": "Cumpără cu monede tradiționale",
  "page-get-eth-traditional-payments": "Cumpără ETH cu tipuri tradiționale de plată direct de la vânzători.",
  "page-get-eth-try-dex": "Încearcă un Dex",
  "page-get-eth-use-your-eth": "Folosește-ți ETH-ul",
  "page-get-eth-use-your-eth-dapps": "Acum, odată ce deții ceva ETH, uită-te la câteva aplicații Ethereum (dapps). Există aplicații pentru finanțe, rețele sociale, jocuri și multe alte categorii.",
  "page-get-eth-wallet-instructions": "Urmează instrucțiunile portofelului",
  "page-get-eth-wallet-instructions-lost": "Dacă pierzi accesul la portofel, vei pierde accesul la fondurile tale. Portofelul tău ar trebui să-ți ofere instrucțiuni privind protecția împotriva acestui lucru. Asigură-te că le urmezi cu atenție – în majoritatea cazurilor, nimeni nu te poate ajuta dacă pierzi accesul la portofel.",
  "page-get-eth-wallets": "Portofele",
  "page-get-eth-wallets-link": "Mai multe despre portofele",
  "page-get-eth-wallets-purchasing": "Unele portofele îți permit să cumperi cripto cu un card de debit/credit, transfer bancar sau chiar Apple Pay. Se aplică restricții geografice.",
  "page-get-eth-warning": "Aceste DEX-uri nu sunt pentru începători, deoarece vei avea nevoie de ETH pentru a le utiliza.",
  "page-get-eth-what-are-DEX's": "Ce sunt DEX-urile?",
  "page-get-eth-whats-eth-link": "Ce este ETH?",
  "page-get-eth-where-to-buy-desc": "Poți cumpăra ETH din schimburi sau din portofele direct.",
  "page-get-eth-where-to-buy-desc-2": "Verifică ce servicii poți utiliza în funcție de zona în care locuiești.",
  "page-get-eth-where-to-buy-title": "De unde să cumperi ETH",
  "page-get-eth-your-address": "Adresa ta ETH",
  "page-get-eth-your-address-desc": "Când descarci un portofel, acesta va crea o adresă publică ETH pentru tine. Iată cum arată una:",
  "page-get-eth-your-address-desc-3": "Gândește-te la aceasta ca la adresa ta de e-mail, dar în loc de e-mail poate primi ETH. Dacă dorești să transferi ETH dintr-un schimb în portofel, utilizează-ți adresa ca destinație. Asigură-te că verifici de două ori întotdeauna înainte de a trimite!",
  "page-get-eth-your-address-wallet-link": "Verifică portofelele",
  "page-index-hero-image-alt": "imagine erou ethereum.org",
  "page-index-meta-description": "Ethereum este o platformă globală, descentralizată pentru bani și noi tipuri de aplicații. Pe Ethereum, poți scrie cod care controlează banii și poți crea aplicații accesibile oriunde în lume.",
  "page-index-meta-title": "Pagină de pornire",
  "page-index-title": "Ethereum este o platformă globală, open-source pentru aplicații descentralizate.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Suport lingvistic",
  "page-languages-interested": "Ești interesat să contribui?",
  "page-languages-learn-more": "Află mai multe despre programul nostru de traducere",
  "page-languages-meta-desc": "Resursele pentru toate limbile acceptate de ethereum.org și modalități de implicare în calitate de traducător.",
  "page-languages-meta-title": "Traduceri lingvistice ethereum.org",
  "page-languages-p1": "Ethereum este un proiect global și este esențial ca ethereum.org să fie accesibil tuturor, indiferent de naționalitate sau limbă. Comunitatea noastră a lucrat din greu pentru ca această viziune să devină realitate.",
  "page-languages-translations-available": "ethereum.org este disponibil în următoarele limbi",
  "page-languages-want-more-header": "Vrei să vezi ethereum.org într-o altă limbă?",
  "page-languages-want-more-link": "Program de traducere",
  "page-languages-want-more-paragraph": "Traducătorii ethereum.org traduc întotdeauna paginile în cât mai multe limbi posibil. Pentru a vedea la ce lucrează acum sau pentru a te înscrie pentru a te alătura acestora, citește despre",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Garanție cripto",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Cu Ethereum poți împrumuta direct de la alți utilizatori fără a tranzacționa ETH. Acest lucru te poate face mai influent - unii fac acest lucru numai pentru a încerca să acumuleze mai mult ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Dar, deoarece prețul ETH este volatil, va trebui supra-asigurat cu garanții. Aceasta înseamnă că atunci când vrei să împrumuți 100 de monede stabile, probabil vei avea nevoie de ETH în valoare de cel puțin 150 USD. Acest lucru protejează sistemul și creditorii.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Mai multe despre monedele stabile susținute de cripto",
  "page-stablecoins-accordion-borrow-pill": "Avansat",
  "page-stablecoins-accordion-borrow-places-intro": "Aceste aplicații dapp îți permit să împrumuți monede stabile folosind cripto ca garanție. Unii acceptă și alte tokenuri, precum și ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Locuri de unde poți împrumuta monede stabile",
  "page-stablecoins-accordion-borrow-requirement-1": "Un portofel Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Vei avea nevoie de un portofel pentru a utiliza o aplicație dapp",
  "page-stablecoins-accordion-borrow-requirement-2": "Eter (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Vei avea nevoie de ETH pentru garanții și/sau comisioane de tranzacție",
  "page-stablecoins-accordion-borrow-requirements-description": "Pentru a împrumuta monede stabile, va trebui să utilizezi aplicația dapp potrivită. De asemenea, vei avea nevoie de un portofel și ceva ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Dacă utilizezi ETH ca garanție și valoarea acestuia scade, garanția ta nu va acoperi monedele stabile pe care le-ai generat. Acest lucru va face ca ETH-ul tău să se lichideze și este posibil să te confrunți cu o penalizare. Deci, dacă împrumuți monede stabile, va trebui să urmărești prețul ETH-ului.",
  "page-stablecoins-accordion-borrow-risks-link": "Cel mai recent preț al ETH-ului",
  "page-stablecoins-accordion-borrow-risks-title": "Riscuri",
  "page-stablecoins-accordion-borrow-text-preview": "Poți împrumuta niște monede stabile folosind cripto ca garanție, pe care trebuie să le rambursezi.",
  "page-stablecoins-accordion-borrow-title": "Împrumut",
  "page-stablecoins-accordion-buy-exchanges-title": "Schimburi populare",
  "page-stablecoins-accordion-buy-requirement-1": "Schimburi și portofele criptografice",
  "page-stablecoins-accordion-buy-requirement-1-description": "Verifică serviciile pe care le poți folosi acolo unde locuiești",
  "page-stablecoins-accordion-buy-requirements-description": "Un cont cu un schimb sau un portofel din care poți cumpăra cripto direct. Este posibil să fi folosit deja unul pentru a obține ceva ETH. Verifică pentru a vedea ce servicii poți utiliza acolo unde locuiești.",
  "page-stablecoins-accordion-buy-text-preview": "O mulțime de schimburi și portofele îți permit să cumperi direct monede stabile. Se vor aplica restricții geografice.",
  "page-stablecoins-accordion-buy-title": "Cumpără",
  "page-stablecoins-accordion-buy-warning": "Schimburile centralizate pot enumera numai monede stabile susținute de fiat, cum ar fi USDC, Tether și altele. Este posibil să nu le poți cumpăra direct, dar ar trebui să le poți schimba cu ETH sau cu alte criptomonede pe care le poți cumpăra de pe platformă.",
  "page-stablecoins-accordion-earn-project-1-description": "Lucrări mai ales tehnice pentru mișcarea software open-source.",
  "page-stablecoins-accordion-earn-project-2-description": "Tehnologie, conținut și alte lucrări pentru comunitatea MakerDao (echipa care ți-a adus Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Dacă ești cu adevărat priceput, găsește erori pentru a câștiga Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Recompense Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Recompense de erori Eth2",
  "page-stablecoins-accordion-earn-project-community": "Comunitatea MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Acestea sunt platforme care te vor plăti în monede stabile pentru munca ta.",
  "page-stablecoins-accordion-earn-projects-title": "Unde să câștigi monede stabile",
  "page-stablecoins-accordion-earn-requirement-1": "Un portofel Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Vei avea nevoie de un portofel pentru a primi monedele stabile câștigate",
  "page-stablecoins-accordion-earn-requirements-description": "Monedele stabile sunt o metodă excelentă de plată pentru muncă și servicii, deoarece valoarea este stabilă. Dar vei avea nevoie de un portofel pentru a fi plătit.",
  "page-stablecoins-accordion-earn-text-preview": "Poți câștiga monede stabile lucrând la proiecte din ecosistemul Ethereum.",
  "page-stablecoins-accordion-earn-title": "Câștigă",
  "page-stablecoins-accordion-less": "Mai puțin",
  "page-stablecoins-accordion-more": "Mai mult",
  "page-stablecoins-accordion-requirements": "Cerințe",
  "page-stablecoins-accordion-swap-dapp-intro": "Dacă ai deja ETH și un portofel, poți utiliza aceste aplicații dapp pentru a schimba monede stabile.",
  "page-stablecoins-accordion-swap-dapp-link": "Mai multe despre schimburile descentralizate",
  "page-stablecoins-accordion-swap-dapp-title": "Aplicații dapp pentru schimbul de tokenuri",
  "page-stablecoins-accordion-swap-editors-tip": "Sfaturile editorilor",
  "page-stablecoins-accordion-swap-editors-tip-button": "Găsește portofele",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Obține un portofel care îți va permite să cumperi ETH și să îl schimbi direct pentru tokenuri, inclusiv monede stabile.",
  "page-stablecoins-accordion-swap-pill": "Recomandat",
  "page-stablecoins-accordion-swap-requirement-1": "Un portofel Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Vei avea nevoie de un portofel pentru a autoriza schimbul și a stoca monedele",
  "page-stablecoins-accordion-swap-requirement-2": "Eter (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Pentru a plăti schimbul",
  "page-stablecoins-accordion-swap-text-preview": "Poți alege dintr-o varietate de monede stabile la schimburile descentralizate. Deci, poți schimba orice token ai pentru moneda stabilă dorită.",
  "page-stablecoins-accordion-swap-title": "Schimbă",
  "page-stablecoins-algorithmic": "Algoritmic",
  "page-stablecoins-algorithmic-con-1": "Trebuie să ai încredere (sau să poți citi) algoritmul.",
  "page-stablecoins-algorithmic-con-2": "Soldul tău de monede se va modifica în funcție de oferta totală.",
  "page-stablecoins-algorithmic-description": "Aceste monede stabile nu sunt susținute de niciun alt activ. În schimb, un algoritm va vinde tokenuri dacă prețul scade sub valoarea dorită și va furniza tokenuri dacă valoarea depășește suma dorită. Deoarece numărul acestor tokenuri aflate în circulație se modifică în mod regulat, numărul de tokenuri pe care le deții se va schimba, dar va reflecta întotdeauna partea ta.",
  "page-stablecoins-algorithmic-pro-1": "Nu este necesară nicio garanție.",
  "page-stablecoins-algorithmic-pro-2": "Controlat de un algoritm public.",
  "page-stablecoins-bank-apy": "0,05%",
  "page-stablecoins-bank-apy-source": "Rata medie plătită de bănci în conturile de economii de bază, asigurate la nivel federal, în SUA.",
  "page-stablecoins-bank-apy-source-link": "Sursă",
  "page-stablecoins-bitcoin-pizza": "Infamul Bitcoin pizza",
  "page-stablecoins-bitcoin-pizza-body": "În 2010, cineva a cumpărat 2 pizza pentru 10.000 bitcoin. La vremea respectivă, acestea valorau ~ 41 USD. Pe piața de astăzi valorează milioane de dolari. Există multe tranzacții regretabile similare în istoria Ethereum. Monedele stabile rezolvă această problemă, astfel încât să te poți bucura de pizza și să-ți păstrezi ETH.",
  "page-stablecoins-coin-price-change": "Modificarea prețului monedei (ultimele 30 de zile)",
  "page-stablecoins-crypto-backed": "Susținute de cripto",
  "page-stablecoins-crypto-backed-con-1": "Mai puțin stabile decât monedele stabile susținute de fiat.",
  "page-stablecoins-crypto-backed-con-2": "Trebuie să urmărești valoarea garanției criptografice.",
  "page-stablecoins-crypto-backed-description": "Aceste monede stabile sunt susținute de alte active criptografice, cum ar fi ETH. Prețul acestora depinde de valoarea activului suportat (sau a garanției), care poate fi volatilă. Deoarece valoarea ETH poate fluctua, aceste monede stabile sunt supragarantate pentru a se asigura că prețul rămâne cât mai stabil posibil. Acest lucru înseamnă că este mai aproape să spunem că o monedă stabilă de 1 USD susținută de cripto are un activ criptografic subiacent în valoare de cel puțin 2 USD. Deci, dacă prețul ETH scade, trebuie utilizat mai mult ETH pentru a sprijini moneda stabilă, altfel monedele stabile își vor pierde valoarea.",
  "page-stablecoins-crypto-backed-pro-1": "Transparent și complet descentralizat.",
  "page-stablecoins-crypto-backed-pro-2": "Se transformă rapid în alte active cripto.",
  "page-stablecoins-crypto-backed-pro-3": "Fără custozi externi - toate activele sunt controlate de conturi Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai este probabil cea mai faimoasă monedă stabilă descentralizată. Valoarea sa este de aproximativ un dolar și este acceptată pe scară largă în toate domeniile.",
  "page-stablecoins-dai-banner-learn-button": "Află mai multe despre Dai",
  "page-stablecoins-dai-banner-swap-button": "Schimbă ETH pentru Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Logo Dai",
  "page-stablecoins-editors-choice": "Alegerile editorilor",
  "page-stablecoins-editors-choice-intro": "Acestea sunt probabil cele mai cunoscute exemple de monede stabile și monedele pe care le-am găsit utile atunci când folosim aplicații dapp.",
  "page-stablecoins-explore-dapps": "Explorează aplicații dapp",
  "page-stablecoins-fiat-backed": "Susținute de fiat",
  "page-stablecoins-fiat-backed-con-1": "Centralizat – cineva trebuie să emită tokenuri.",
  "page-stablecoins-fiat-backed-con-2": "Necesită audit pentru a se asigura că rezervele companiei sunt suficiente.",
  "page-stablecoins-fiat-backed-description": "Practic un IOU (I owe you - îți datorez) pentru o monedă tradițională fiat (de obicei, dolari). Îți utilizezi monedă fiat pentru a achiziționa o monedă stabilă pe care ulterior o poți încasa și schimba pentru a-ți recupera moneda originală.",
  "page-stablecoins-fiat-backed-pro-1": "Sigură împotriva volatilității cripto.",
  "page-stablecoins-fiat-backed-pro-2": "Modificările de preț sunt minime.",
  "page-stablecoins-find-stablecoin": "Găsește o monedă stabilă",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Cum să obții monede stabile",
  "page-stablecoins-find-stablecoin-intro": "Există sute de monede stabile disponibile. Iată câteva pentru a te ajuta să începi. Dacă ești nou în Ethereum, îți recomandăm să faci mai întâi unele cercetări.",
  "page-stablecoins-find-stablecoin-types-link": "Diferite tipuri de monede stabile",
  "page-stablecoins-get-stablecoins": "Cum să obții monede stabile",
  "page-stablecoins-hero-alt": "Trei dintre cele mai mari monede stabile în funcție de capitalizarea pieței: Dai, USDC și Tether.",
  "page-stablecoins-hero-button": "Obține monede stabile",
  "page-stablecoins-hero-header": "Banii digitali pentru uz zilnic",
  "page-stablecoins-hero-subtitle": "Monedele stabile sunt tokenuri Ethereum concepute pentru a rămâne la o valoare fixă, chiar și atunci când prețul ETH se schimbă.",
  "page-stablecoins-interest-earning-dapps": "Aplicații dapp care câștigă dobândă",
  "page-stablecoins-meta-description": "O introducere în monedele stabile Ethereum: ce sunt, cum să le obții și de ce sunt importante.",
  "page-stablecoins-precious-metals": "Metale prețioase",
  "page-stablecoins-precious-metals-con-1": "Centralizat – cineva trebuie să emită tokenuri.",
  "page-stablecoins-precious-metals-con-2": "Trebuie să ai încredere în emitentul de tokenuri și în rezervele de metal prețios.",
  "page-stablecoins-precious-metals-description": "Asemenea monedelor susținute de fiat, în schimb, aceste monede stabile folosesc resurse precum aurul pentru a-și menține valoarea.",
  "page-stablecoins-precious-metals-pro-1": "Sigură împotriva volatilității cripto.",
  "page-stablecoins-prices": "Prețuri de monede stabile",
  "page-stablecoins-prices-definition": "Monedele stabile sunt criptomonede fără volatilitate. Acestea partajează multe din puterile ETH, dar valoarea lor este constantă, mai degrabă ca o monedă tradițională. Așadar, ai acces la bani stabili pe care îi poți folosi pe Ethereum. ",
  "page-stablecoins-prices-definition-how": "Cum își obțin stabilitatea monedele stabile",
  "page-stablecoins-research-warning": "Ethereum este o tehnologie nouă și majoritatea aplicațiilor sunt noi. Asigură-te că ești conștient de risc și depune doar ceea ce îți poți permite să pierzi.",
  "page-stablecoins-research-warning-title": "Fă-ți întotdeauna propria cercetare",
  "page-stablecoins-save-stablecoins": "Economisește cu monede stabile",
  "page-stablecoins-save-stablecoins-body": "Monedele stabile au adesea o rată a dobânzii peste medie, deoarece există o mare cerere pentru a le împrumuta. Există aplicații dapp care îți permit să câștigi dobânzi pentru monedele tale stabile în timp real, depunându-le într-un fond de creditare. La fel ca în lumea bancară, furnizezi tokenuri pentru împrumuturi, dar îți poți retrage tokenurile și dobânzile în orice moment.",
  "page-stablecoins-saving": "Folosește-ți bine economiile de monede stabile și câștigă dobândă. Ca totul în cripto, Randamentele Anuale Anticipate (APY) se pot schimba de la o zi la alta, în funcție de cerere/ofertă în timp real.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Consultă aplicațiile dapp Ethereum - monedele stabile sunt adesea mai utile pentru tranzacțiile de zi cu zi.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Ilustrație meme Doge Shiba Inu.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Folosește-ți monedele stabile",
  "page-stablecoins-stablecoins-dapp-description-1": "Piețe pentru o mulțime de monede stabile, inclusiv Dai, USDC, TUSD, USDT și multe altele. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Împrumută monede stabile și câștigă dobândă și $COMP, propriul token al Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "O platformă de tranzacționare în care poți câștiga interes pentru Dai și USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "O aplicație concepută pentru a salva Dai.",
  "page-stablecoins-stablecoins-feature-1": "Monedele stabile sunt globale și pot fi trimise prin internet. Sunt ușor de primit sau de trimis odată ce ai un cont Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "Cererea de monede stabile este mare, de aceea poți câștiga dobânzi pentru împrumutul tău. Asigură-te că ești conștient de riscuri înainte de a împrumuta.",
  "page-stablecoins-stablecoins-feature-3": "Monedele stabile sunt interschimbabile pentru ETH și alte tokenuri Ethereum. Numeroase aplicații dapp se bazează pe monedele stabile.",
  "page-stablecoins-stablecoins-feature-4": "Monedele stabile sunt securizate prin criptografie. Nimeni nu poate falsifica tranzacții în numele tău.",
  "page-stablecoins-stablecoins-meta-description": "Prezentarea monedelor stabile Ethereum: ce sunt, cum să le obții și de ce sunt importante.",
  "page-stablecoins-stablecoins-table-header-column-1": "Monedă",
  "page-stablecoins-stablecoins-table-header-column-2": "Capitalizare de piață",
  "page-stablecoins-stablecoins-table-header-column-3": "Tip de colateral",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Cripto",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Fiat",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Metale prețioase",
  "page-stablecoins-table-error": "Nu s-au putut încărca monede stabile. Încearcă să reîmprospătezi pagina.",
  "page-stablecoins-table-loading": "Se încarcă date pentru monede stabile...",
  "page-stablecoins-title": "Monede stabile",
  "page-stablecoins-top-coins": "Monede stabile de top după capitalizarea pieței",
  "page-stablecoins-top-coins-intro": "Capitalizarea de piață reprezintă",
  "page-stablecoins-top-coins-intro-code": "numărul total de tokenuri existente, înmulțit cu valoarea tokenului. Această listă este dinamică, iar proiectele enumerate aici nu sunt neapărat aprobate de echipa ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Cum funcționează: tipuri de monede stabile",
  "page-stablecoins-usdc-banner-body": "USDc este probabil cea mai faimoasă monedă stabilă susținută de fiat. Valoarea sa este de aproximativ un dolar și este susținută de Circle și Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Informații despre USDC",
  "page-stablecoins-usdc-banner-swap-button": "Schimbă ETH pentru USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Logo USDC",
  "page-stablecoins-why-stablecoins": "De ce monede stabile?",
  "page-stablecoins-how-they-work-button": "Cum funcționează",
  "page-stablecoins-why-stablecoins-body": "ETH, la fel ca Bitcoin, are un preț volatil, deoarece este o tehnologie nouă. Așadar, este posibil să nu vrei să-l cheltui regulat. Monedele stabile reflectă valoarea monedelor tradiționale pentru a-ți oferi acces la bani stabili pe care îi poți folosi pe Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Dacă dorești să adăugăm un portofel,",
  "page-find-wallet-alpha-logo-alt": "Logo AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Logo Ambo",
  "page-find-wallet-argent-logo-alt": "Logo Argent",
  "page-find-wallet-buy-card": "Cumpără cripto cu un card",
  "page-find-wallet-buy-card-desc": "Cumpără ETH direct din portofel cu un card bancar. Pot fi aplicate restricții geografice.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Verifică aplicațiile dapp",
  "page-find-wallet-clear": "Șterge filtrele",
  "page-find-wallet-coinbase-logo-alt": "Logo Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Așa că alege-ți un portofel în funcție de caracteristicile dorite.",
  "page-find-wallet-description": "Portofelele au o mulțime de funcții opționale care ți-ar putea plăcea.",
  "page-find-wallet-description-alpha": "Portofel Ethereum complet open-source, care valorifică „enclava sigură” pe mobil, oferă suport complet pentru rețeaua de testare și adoptă standardul TokenScript.",
  "page-find-wallet-description-ambo": "Treci direct la investiții și obține prima investiție în câteva minute de la descărcarea aplicației",
  "page-find-wallet-description-argent": "Cu o atingere câștigi dobânzi și investești; împrumuți, stochezi și trimiți. Deține-l.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Aplicație securizată pentru a-ți stoca în securitate cripto",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma este portalul tău către DeFi. Nu mai ai nevoie de fraze de securitate și nici de extensii Chrome.",
  "page-find-wallet-description-enjin": "Impenetrabil, plin de caracteristici și convenabil — construit pentru comercianți, jucători și programatori",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Cea mai de încredere platformă de stocare a activelor digitale pe Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken este un portofel digital ușor și securizat în care milioane de oameni au încredere",
  "page-find-wallet-description-ledger": "Păstrează-ți activele în siguranță cu cele mai înalte standarde de securitate",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Începe să explorezi aplicațiile blockchain în câteva secunde. Se bucură de încrederea a peste 1 milion de utilizatori din întreaga lume.",
  "page-find-wallet-description-monolith": "Singurul portofel cu autocustodie din lume asociat cu cardul de debit Visa. Disponibil în Marea Britanie și UE și utilizabil la nivel global.",
  "page-find-wallet-description-multis": "Multis este un cont de criptomonede conceput pentru companii. Cu Multis, companiile pot să stocheze cu controale de acces, pot câștiga dobânzi pentru economiile lor și pot simplifica plățile și contabilitatea.",
  "page-find-wallet-description-mycrypto": "MyCrypto este o interfață pentru gestionarea tuturor conturilor tale. Schimbă, trimite și cumpără cripto cu portofele precum MetaMask, Ledger, Trezor și multe altele.",
  "page-find-wallet-description-myetherwallet": "O interfață gratuită din partea clientului, care te ajută să interacționezi cu blockchain-ul Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Portofel cu autocustodie, deținut de comunitate, cu propria rețea de plăți L2.",
  "page-find-wallet-description-portis": "Portofel blockchain fără custodie care face aplicațiile simple pentru toată lumea",
  "page-find-wallet-description-rainbow": "O casă mai bună pentru activele tale Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Aplicație de mesagerie securizată, portofel criptografic și browser Web3 construite cu tehnologie de ultimă generație",
  "page-find-wallet-description-tokenpocket": "TokenPocket：Un portofel de monedă digitală sigur și convenabil la nivel mondial și un portal către DApps, cu suport pentru multi-lanț acceptat",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Autentificare cu un singur clic pentru Web 3.0",
  "page-find-wallet-description-trezor": "Primul și originalul portofel hardware",
  "page-find-wallet-description-trust": "Trust Wallet este un portofel de criptomonede multi-monedă descentralizat. Cumpără cripto, explorează aplicații dapp, schimbă active și multe altele, păstrând în același timp controlul cheilor.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo este primul portofel criptografic fără cheie. Cu ZenGo, nu există chei private, parole sau fraze de securitate de gestionat sau pierdut. Cumpără, tranzacționează, câștigă și stochează Ethereum cu o simplitate și siguranță fără precedent",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Logo Dharma",
  "page-find-wallet-enjin-logo-alt": "Logo Enjin",
  "page-find-wallet-Ethereum-wallets": "Portofele Ethereum",
  "page-find-wallet-explore-dapps": "Explorează aplicații dapp",
  "page-find-wallet-explore-dapps-desc": "Aceste portofele sunt concepute pentru a te ajuta să te conectezi la aplicații dapp Ethereum.",
  "page-find-wallet-feature-h2": "Alege funcțiile portofelului care te interesează",
  "page-find-wallet-fi-tools": "Acces la instrumente financiare",
  "page-find-wallet-fi-tools-desc": "Acceptă credit, acordă credit și câștigă dobânzi direct din portofel.",
  "page-find-wallet-following-features": "cu următoarele caracteristici:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Logo Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Găsește imaginea erou a portofelului",
  "page-find-wallet-imtoken-logo-alt": "Logo imToken",
  "page-find-wallet-last-updated": "Ultima actualizare",
  "page-find-wallet-ledger-logo-alt": "Logo Ledger",
  "page-find-wallet-limits": "Limite de protecție",
  "page-find-wallet-limits-desc": "Protejează-ți activele stabilind limite care împiedică golirea contului tău.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "politica de listare",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Găsește și compară portofele Ethereum pe baza caracteristicilor dorite.",
  "page-find-wallet-meta-title": "Găsește un portofel Ethereum",
  "page-find-wallet-metamask-logo-alt": "Logo MetaMask",
  "page-find-wallet-monolith-logo-alt": "Logo Monolith",
  "page-find-wallet-multis-logo-alt": "Logo Multis",
  "page-find-wallet-multisig": "Conturi cu mai multe semnături",
  "page-find-wallet-multisig-desc": "Pentru o securitate suplimentară, portofelele cu mai multe semnături necesită ca mai mult de un cont să autorizeze anumite tranzacții.",
  "page-find-wallet-mycrypto-logo-alt": "Logo MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Logo MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Ești nou în portofele? Iată o prezentare generală pentru a începe.",
  "page-find-wallet-new-to-wallets-link": "Portofele Ethereum",
  "page-find-wallet-not-all-features": "Niciun portofel nu are toate aceste caracteristici",
  "page-find-wallet-not-endorsements": "Portofelele enumerate pe această pagină nu sunt aprobări oficiale și sunt furnizate numai în scop informativ. Descrierile lor au fost furnizate chiar de companiile de portofele. Adăugăm produse la această pagină pe baza criteriilor noastre",
  "page-find-wallet-overwhelmed": "de portofele Ethereum mai jos. Impresionat? Încearcă să filtrezi după caracteristici.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Logo Pillar",
  "page-find-wallet-portis-logo-alt": "Logo Portis",
  "page-find-wallet-rainbow-logo-alt": "Logo Rainbow",
  "page-find-wallet-raise-an-issue": "ridică o problemă pe GitHub",
  "page-find-wallet-search-btn": "Caută caracteristicile selectate",
  "page-find-wallet-showing": "Se afișează ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Logo Status",
  "page-find-wallet-swaps": "Schimburi de tokenuri descentralizate",
  "page-find-wallet-swaps-desc": "Tranzacționează între ETH și alte tokenuri direct din portofel.",
  "page-find-wallet-title": "Găsește un portofel",
  "page-find-wallet-tokenpocket-logo-alt": "Logo TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Logo Torus",
  "page-find-wallet-trezor-logo-alt": "Logo Trezor",
  "page-find-wallet-trust-logo-alt": "Logo Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Încearcă să elimini o caracteristică sau două",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Acum, odată ce ai un portofel, uită-te la câteva aplicații Ethereum (dapps). Există aplicații dapp pentru finanțe, rețele sociale, jocuri și multe alte categorii.",
  "page-find-wallet-use-your-wallet": "Folosește-ți portofelul",
  "page-find-wallet-voluem-desc": "Dacă dorești să deții mult ETH, alege un portofel care îți permite să cumperi ETH în valoare de mai mult de 2.000 de dolari odată.",
  "page-find-wallet-volume": "Achiziții cu volum mare",
  "page-find-wallet-we-found": "Am găsit",
  "page-find-wallet-withdraw": "Retragere prin transfer bancar",
  "page-find-wallet-withdraw-desc": "Poți retrage ETH direct în contul tău bancar fără a trece printr-un schimb.",
  "page-find-wallet-yet": "încă",
  "page-find-wallet-zengo-logo-alt": "Logo ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Mizează ETH",
  "page-wallets-accounts-addresses": "Portofele, conturi și adrese",
  "page-wallets-accounts-addresses-desc": "Merită să înțelegem diferențele dintre unii termeni cheie.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Ilustrarea unui robot cu un seif pentru corp, reprezentând un portofel Ethereum",
  "page-wallets-ethereum-account": "Cont Ethereum",
  "page-wallets-blog": "Blogul Coinbase",
  "page-wallets-bookmarking": "Marchează-ți portofelul",
  "page-wallets-bookmarking-desc": "Dacă utilizezi un portofel web, marchează site-ul pentru a te proteja împotriva escrocheriilor de phishing.",
  "page-wallets-cd": "Portofele hardware fizice care îți permit să-ți păstrezi cripto off-line – foarte securizate",
  "page-wallets-converted": "Cripto convertit?",
  "page-wallets-converted-desc": "Dacă dorești să deții o valoare serioasă, îți recomandăm portofelele hardware, deoarece acestea sunt cele mai sigure. Sau un portofel cu alerte de fraudă și limite de retragere.",
  "page-wallets-curious": "Cripto curios?",
  "page-wallets-curious-desc": "Dacă ești nou în cripto și dorești doar să vezi cum te simți cu el, îți recomandăm ceva care îți va oferi posibilitatea de a explora aplicațiile Ethereum sau de a cumpăra primul ETH direct din portofel.",
  "page-wallets-desc-2": "Ai nevoie de un portofel pentru a trimite fonduri și a gestiona ETH.",
  "page-wallets-desc-2-link": "Mai multe despre ETH",
  "page-wallets-desc-3": "Portofelul este doar un instrument de gestiune al contului Ethereum. De aceea poți schimba furnizorii de portofele oricând. Multe portofele permit și gestionarea centralizată a mai multor conturi Ethereum dintr-o singură aplicație.",
  "page-wallets-desc-4": "Aceasta deoarece portofelele nu au custodia fondurilor tale, tu o ai Ele sunt doar un instrument de gestiune a ceea ce este cu adevărat al tău.",
  "page-wallets-description": "Portofelele Ethereum sunt aplicații care îți permit să interacționezi cu contul Ethereum. Gândește la portofel ca la o aplicație bancară pe internet – fără bancă. Portofelul îți permite să citești soldul, să trimiți tranzacții și să te conectezi la aplicații.",
  "page-wallets-desktop": "Aplicații desktop, dacă preferi să-ți gestionezi fondurile prin MacOS, Windows sau Linux",
  "page-wallets-ethereum-wallet": "Un portofel",
  "page-wallets-explore": "Explorează Ethereum",
  "page-wallets-features-desc": "Te putem ajuta să-ți alegeți portofelul pe baza caracteristicilor care te interesează.",
  "page-wallets-features-title": "Preferi să alegi în funcție de caracteristici?",
  "page-wallets-find-wallet-btn": "Găsește un portofel",
  "page-wallets-find-wallet-link": "Găsește un portofel",
  "page-wallets-get-some": "Obține niște ETH",
  "page-wallets-get-some-alt": "Ilustrația unei mâini care creează un logo Ethereum din cărămizi lego",
  "page-wallets-get-some-btn": "Obține niște ETH",
  "page-wallets-get-some-desc": "ETH este cripto nativ al Ethereum. Vei avea nevoie de ceva ETH în portofel pentru a utiliza aplicațiile Ethereum.",
  "page-wallets-get-wallet": "Obține un portofel",
  "page-wallets-get-wallet-desc": "Există multe portofele diferite din care poți alege. Vrem să vă ajutăm să îl selectezi pe cel mai bun pentru tine.",
  "page-wallets-get-wallet-desc-2": "Amintește-ți: această decizie nu este permanentă – contul tău Ethereum nu este legat de furnizorul portofelului.",
  "page-wallets-how-to-store": "Cum să stochezi active digitale pe Ethereum",
  "page-wallets-keys-to-safety": "Cheile pentru păstrarea criptomonedei în siguranță",
  "page-wallets-manage-funds": "O aplicație pentru gestionarea fondurilor",
  "page-wallets-manage-funds-desc": "Portofelul tău arată soldurile, istoricul tranzacțiilor și îți oferă o modalitate de a trimite/primi fonduri. Unele portofele pot oferi mai multe.",
  "page-wallets-meta-description": "Ce trebuie să știi pentru a utiliza portofelele Ethereum.",
  "page-wallets-meta-title": "Portofele Ethereum",
  "page-wallets-mobile": "Aplicații mobile care îți fac fondurile accesibile de oriunde",
  "page-wallets-more-on-dapps-btn": "Mai mult despre Dapps",
  "page-wallets-most-wallets": "Majoritatea produselor de portofel îți vor permite să generezi un cont Ethereum. Deci, nu ai nevoie de unul înainte de a descărca un portofel.",
  "page-wallets-protecting-yourself": "Protecția ta și a fondurilor tale",
  "page-wallets-seed-phrase": "Notează fraza ta de securitate",
  "page-wallets-seed-phrase-desc": "Portofelele îți vor oferi adesea o frază de securitate pe care trebuie să o notezi undeva în siguranță. Aceasta este singura modalitate prin care îți vei putea recupera portofelul.",
  "page-wallets-seed-phrase-example": "Iată un exemplu:",
  "page-wallets-seed-phrase-snippet": "acolo avion curbă aerisire formație doge posibil product distinct sub spirit lampă",
  "page-wallets-seed-phrase-write-down": "Nu o stoca pe computer. Scrie-o undeva și ține-o în siguranță.",
  "page-wallets-slogan": "Cheia viitorului tău digital",
  "page-wallets-stay-safe": "Cum să stai în siguranță",
  "page-wallets-stay-safe-desc": "Portofelele reprezintă o mică schimbare în gândire. Libertatea financiară și capacitatea de a accesa și utiliza fonduri oriunde vine cu puțină responsabilitate – nu există asistență pentru clienți în cripto.",
  "page-wallets-subtitle": "Portofelele oferă acces la fondurile tale și la aplicațiile Ethereum. Doar tu ar trebui să ai acces la portofel.",
  "page-wallets-take-responsibility": "Asumă-ți responsabilitatea pentru propriile tale fonduri",
  "page-wallets-take-responsibility-desc": "Schimburile centralizate îți vor lega portofelul la un nume de utilizator și o parolă pe care o poți recupera într-un mod tradițional. Amintește-ți că te încrezi în schimb pentru custodia asupra fondurilor tale. Dacă acea companie este atacată sau dă faliment, fondurile tale sunt în pericol.",
  "page-wallets-tips": "Alte sfaturi pentru a rămâne în siguranță",
  "page-wallets-tips-community": "De la comunitate",
  "page-wallets-title": "Portofele Ethereum",
  "page-wallets-triple-check": "Verifică triplu totul",
  "page-wallets-triple-check-desc": "Nu uitați că tranzacțiile nu pot fi anulate, iar portofelele nu pot fi recuperate cu ușurință, așa că ai grijă.",
  "page-wallets-try-dapps": "Încearcă niște aplicații dapp",
  "page-wallets-try-dapps-alt": "O ilustrare a membrilor comunității Ethereum care lucrează împreună",
  "page-wallets-try-dapps-desc": "Dapps sunt aplicații construite pe Ethereum. Sunt mai ieftine, mai corecte, mai amabile cu datele tale decât majoritatea aplicațiilor tradiționale.",
  "page-wallets-types": "Tipuri de portofele",
  "page-wallets-web-browser": "Portofele web care îți permit să interacționezi cu contul tău printr-un browser web",
  "page-wallets-whats-a-wallet": "Ce este un portofel Ethereum?",
  "page-wallets-your-ethereum-account": "Contul tău Ethereum",
  "page-wallets-your-ethereum-account-desc": "Portofelul tău este fereastra contului tău Ethereum – soldul tău, istoricul tranzacțiilor și multe altele. Dar poți schimba furnizorii de portofele în orice moment.",
  "page-wallets-your-login": "Autentificarea ta pentru aplicațiile Ethereum",
  "page-wallets-your-login-desc": "Portofelul tău îți permite să te conectezi la orice aplicație descentralizată utilizându-ți contul Ethereum. Este ca o autentificare pe care o poți utiliza în mai multe aplicații dapp.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum este o tehnologie care îți permite să trimiți oricui criptomonede contra unei taxe mici. De asemenea, alimentează aplicațiile pe care toată lumea le poate folosi și nimeni nu le poate elimina.",
  "page-what-is-ethereum-101-desc-2": "Ethereum se bazează pe inovația Bitcoin, cu câteva mari diferențe.",
  "page-what-is-ethereum-101-desc-3": "Ambele îți permit să utilizezi bani digitali fără furnizori de plăți sau bănci. Dar Ethereum este programabil, deci îl poți utiliza și pentru o mulțime de active digitale diferite – chiar și Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "Aceasta înseamnă și că Ethereum este mai mult decât plăți. Este o piață de servicii financiare, jocuri și aplicații care nu îți pot fura datele sau nu te pot cenzura.",
  "page-what-is-ethereum-101-italic": "blockchain-ul programabil al lumii.",
  "page-what-is-ethereum-101-strong": "Este ",
  "page-what-is-ethereum-accessibility": "Ethereum este deschis tuturor.",
  "page-what-is-ethereum-adventure": "Alege-ți aventura!",
  "page-what-is-ethereum-alt-img-bazaar": "Ilustrația unei persoane care privește într-un bazar, menit să reprezinte Ethereum",
  "page-what-is-ethereum-alt-img-comm": "O ilustrare a membrilor comunității Ethereum care lucrează împreună",
  "page-what-is-ethereum-alt-img-lego": "Ilustrația unei mâini care construiește un logo ETH făcut din cărămizi Lego",
  "page-what-is-ethereum-alt-img-social": "O ilustrare a personajelor într-un spațiu social dedicat Ethereum cu un logo ETH mare",
  "page-what-is-ethereum-banking-card": "Servicii bancare pentru toată lumea",
  "page-what-is-ethereum-banking-card-desc": "Nu toată lumea are acces la servicii financiare. Dar tot ce ai nevoie pentru a accesa Ethereum și produsele sale de creditare, împrumut și economii este o conexiune la internet.",
  "page-what-is-ethereum-build": "Creează ceva cu Ethereum",
  "page-what-is-ethereum-build-desc": "Dacă dorești să încerci să construiești cu Ethereum, citește documentele noastre, încearcă unele tutoriale sau consultă instrumentele de care ai nevoie pentru a începe.",
  "page-what-is-ethereum-censorless-card": "Rezistent la cenzură",
  "page-what-is-ethereum-censorless-card-desc": "Niciun guvern sau companie nu are controlul asupra Ethereum. Această descentralizare face aproape imposibil pentru cineva să te împiedice să primești plăți sau să utilizezi servicii pe Ethereum.",
  "page-what-is-ethereum-comm-desc": "Comunitatea noastră include oameni din toate mediile, inclusiv artiști, cripto-anarhiști, fortune 500 și acum tu. Află cum te poți implica chiar astăzi.",
  "page-what-is-ethereum-commerce-card": "Garanții comerciale",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum creează un mediu concurențial mai echitabil. Clienții au o garanție sigură, încorporată că fondurile vor trece de la un proprietar la altul doar dacă furnizezi ceea ce s-a convenit. Nu ai nevoie de influența unei companii mari pentru a face afaceri.",
  "page-what-is-ethereum-community": "Comunitatea Ethereum",
  "page-what-is-ethereum-compatibility-card": "Compatibilitate pentru succes",
  "page-what-is-ethereum-compatibility-card-desc": "Produse și experiențe mai bune sunt construite tot timpul, deoarece produsele Ethereum sunt compatibile în mod implicit. Companiile se pot baza reciproc pe succesul celeilalte.",
  "page-what-is-ethereum-dapps-desc": "Produse și servicii care rulează pe Ethereum. Există aplicații dapp pentru finanțe, locul de muncă, rețele sociale, jocuri de noroc și multe altele – fă cunoștință cu aplicațiile viitorului nostru digital.",
  "page-what-is-ethereum-dapps-img-alt": "O ilustrare a unui câine folosind o aplicație Ethereum pe un calculator",
  "page-what-is-ethereum-dapps-title": "Aplicații dapp Ethereum",
  "page-what-is-ethereum-desc": "Fundamentul viitorului nostru digital",
  "page-what-is-ethereum-explore": "Explorează Ethereum",
  "page-what-is-ethereum-get-started": "Cea mai bună modalitate de a afla mai multe este să descarci un portofel, să obții niște ETH și să încerci o aplicație dapp Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum este accesul liber la bani digitali și servicii accesibile tuturor, indiferent de mediu sau locație. Este o tehnologie construită de comunitate, în spatele criptomonedei eter (ETH) și a miilor de aplicații pe care le poți utiliza astăzi.",
  "page-what-is-ethereum-internet-card": "Un internet mai personal",
  "page-what-is-ethereum-internet-card-desc": "Nu trebuie să furnizezi toate datele tale personale pentru a utiliza o aplicație Ethereum. Ethereum construiește o economie bazată pe valoare, nu pe supraveghere.",
  "page-what-is-ethereum-meet-comm": "Întâlnește comunitatea",
  "page-what-is-ethereum-meta-description": "Află mai multe despre Ethereum, ce face și cum să-l încerci pentru tine.",
  "page-what-is-ethereum-meta-title": "Ce este Ethereum?",
  "page-what-is-ethereum-native-alt": "Simbolul pentru eter (ETH)",
  "page-what-is-ethereum-native-crypto": "Criptomonedă nativă a Ethereum și echivalentă cu Bitcoin. Poți utiliza ETH pe aplicații Ethereum sau pentru a trimite valoare la prieteni și familie.",
  "page-what-is-ethereum-native-img-alt": "O ilustrare a unui robot cu un seif pentru trunchi, folosit pentru a reprezenta portofelele Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "O rețea peer-to-peer",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum îți permite să transferi bani sau să închei acorduri direct cu altcineva. Nu este nevoie să treci prin companii intermediare.",
  "page-what-is-ethereum-singlecard-desc": "Dacă ești interesat de blockchain și partea tehnică a Ethereum, te acoperim.",
  "page-what-is-ethereum-singlecard-link": "Cum funcționează Ethereum",
  "page-what-is-ethereum-singlecard-title": "Cum funcționează Ethereum",
  "page-what-is-ethereum-start-building-btn": "Începe să dezvolți",
  "page-what-is-ethereum-title": "Ce este Ethereum?",
  "page-what-is-ethereum-tools-needed": "Tot ce ai nevoie pentru a participa este un portofel.",
  "page-what-is-ethereum-try": "Încearcă Ethereum",
  "page-what-is-ethereum-tryit": "Deci pășește în bazar și încearcă...",
  "page-what-is-ethereum-wallets": "Portofele",
  "page-what-is-ethereum-wallets-desc": "Cum îți gestionezi ETH-ul și contul Ethereum. Vei avea nevoie de un portofel pentru a începe – te vom ajuta să alegi unul.",
  "page-what-is-ethereum-welcome": "Bun venit în Ethereum",
  "page-what-is-ethereum-welcome-2": "Sperăm să rămâi.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Miza a sosit! Dacă dorești să mizezi ETH",
  "banner-staking-2": "confirmă adresa contractului de depozit",
  "docking": "Andocare",
  "page-eth2-vision-security-desc-9": "ne permite să alocăm aleatoriu validatori diferitelor fragmente - acest lucru face practic imposibil ca validatorii să colaboreze vreodată atacând un fragment specific. Fragmentarea nu este la fel de sigură pe un blockchain de dovada muncii, deoarece minerii nu pot fi controlați prin protocol în acest fel.",
  "page-index-sections-developers-desc": "Află informații despre tehnologia din spatele Ethereum și aplicațiilor sale, astfel încât să poți începe să dezvolți cu ea.",
  "page-index-sections-developers-image-alt": "Ilustrația unei mâini care construiește un simbol Ethereum făcut din cărămizi Lego",
  "page-index-sections-developers-link-text": "Începe să dezvolți",
  "page-index-sections-developers-title": "Programatori",
  "page-index-sections-enterprise-desc": "Află cum Ethereum poate deschide noi modele de afaceri, reduce costurile și asigura viabilitatea în viitor a businessului.",
  "page-index-sections-enterprise-image-alt": "Imaginea unui grup lucrând la un proiect Ethereum în jurul un laptop",
  "page-index-sections-enterprise-link-text": "Ethereum pentru întreprinderi",
  "page-index-sections-enterprise-title": "Întreprindere",
  "page-index-sections-individuals-desc": "Învață despre Ethereum, Ether, portofele, tokenuri și multe altele, astfel încât să poți începe să utilizezi aplicațiile Ethereum.",
  "page-index-sections-individuals-image-alt": "Câine așezat la computer",
  "page-index-sections-individuals-link-text": "Să începem cu Ethereum",
  "page-index-sections-individuals-title": "Despre Ethereum",
  "page-index-subtitle": "Pe Ethereum poți scrie cod care controlează valori digitale, rulează exact așa cum a fost programat și este accesibil oriunde în lume.",
  "page-wallets-accounts-addresses-desc-2": "este o entitate care poate trimite tranzacții și are un sold.",
  "page-wallets-accounts-has": "Un cont Ethereum are un",
  "page-wallets-an": "Un",
  "page-wallets-ethereum-addresses": "Adresă Ethereum",
  "page-wallets-ethereum-addresses-2": ", ca o căsuță poștală internet care are o adresă de e-mail. O poți utiliza pentru a trimite fonduri către un cont.",
  "page-wallets-ethereum-wallet-2": "este un produs care îți permite să-ți gestionezi contul Ethereum, să vezi soldului contului, să trimiți tranzacții și multe altele."
}
