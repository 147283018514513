{
  "1inch-logo": "1inch logosu",
  "aave-logo": "Aave logosu",
  "about": "Hakkında",
  "about-ethereum-org": "ethereum.org hakkında",
  "about-us": "Hakkımızda",
  "alt-eth-blocks": "Illustration of blocks being organized like an ETH symbol",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Başa dön",
  "banner-page-incomplete": "Bu sayfa tamamlanmadı. Konuyla ilgili bir uzmansanız, lütfen bu sayfayı düzenleyin ve bilgilerinizle doldurunuz.",
  "beacon-chain": "İşaret Zinciri",
  "binance-logo": "Binance logosu",
  "bittrex-logo": "Bittrex logosu",
  "brand-assets": "Marka varlıkları",
  "bug-bounty": "Hata ödülü",
  "coinbase-logo": "Coinbase logosu",
  "coinmama-logo": "Coinmama logosu",
  "community": "Topluluk",
  "community-menu": "Community Menu",
  "compound-logo": "Compund logosu",
  "cons": "Eksileri",
  "contact": "İletişim",
  "content-versions": "Content Versions",
  "contributing": "Katkılar",
  "contributors": "Katkıda Bulunanlar",
  "contributors-thanks": "\nBu sayfaya katkıda bulunan herkese - teşekkür ederiz!",
  "cookie-policy": "Çerez Politikası",
  "copied": "Kopyalandı",
  "copy": "Kopyala",
  "dark-mode": "Gece modu",
  "data-provided-by": "Veri sağlayıcısı",
  "decentralized-applications-dapps": "Merkezi olmayan uygulamalar (dapps)",
  "deposit-contract": "Mevduat sözleşmeleri",
  "devcon": "Devcon",
  "developers": "Geliştiriciler",
  "developers-home": "Geliştirici Ana Sayfası",
  "docs": "Belgeler",
  "documentation": "Dokümanlar",
  "dydx-logo": "Dydx logosu",
  "ecosystem": "Ekosistem\n",
  "edit-page": "Sayfayı düzenle",
  "ef-blog": "Ethereum Foundation Blogu",
  "eips": "Ethereum İyileştirme Önerileri",
  "enterprise": "İşletmeler",
  "enterprise-menu": "Kurum Menüsü",
  "esp": "Ekosistem Destek Programı",
  "eth-current-price": "Güncel ETH fiyatı (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Açık kaynak Eth2 Beacon Chain explorer",
  "eth2-beaconscan-desc": "Eth2 Beacon Chain explorer - Eth2 için Etherscan",
  "eth2-become-staker": "Staker ol",
  "eth2-become-staker-desc": "Staking artık yayında! Ağın güvenliğini sağlamak için ETH'nizi stake etmek istiyorsanız, risklerin farkında olduğunuzdan emin olun.",
  "eth2-explore": "Verileri keşfedin",
  "eth2-no-action-needed": "Elinizde tuttuğunuz herhangi bir ETH ile herhangi bir şey yapmanıza gerek yoktur. Takas için ETH göndermenizi isteyen dolandırıcılara dikkat edin.",
  "eth2-run-beacon-chain": "Bir işaret istemcisi çalıştırın",
  "eth2-run-beacon-chain-desc": "Ethereum'un mümkün olduğunca çok sayıda müşteriye ihtiyacı var. Bu Ethereum kamu yararına yardım edin!",
  "eth2-service-announcement": "Eth2 hizmet duyurusu",
  "eth2-what-shipping": "Ne zaman gönderiliyor?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Ethereum Marka Varlıkları",
  "ethereum-community": "Ethereum Topluluğu",
  "ethereum-foundation": "Ethereum'un Geleceği",
  "ethereum-foundation-logo": "Ethereum Vakfı logosu",
  "ethereum-glossary": "Ethereum Sözlük",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Ethereum logosu",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Ethrereum Stüdyosu",
  "ethereum-wallets": "Ethereum Cüzdanlar",
  "ethereum-whitepaper": "Ethereum Teknik Raporu",
  "example-projects": "Örnek projeler",
  "find-wallet": "Cüzdan bul",
  "foundation": "Kurum",
  "gemini-logo": "Gemini logosu",
  "get-eth": "ETH edin",
  "get-involved": "Dahil olun",
  "get-started": "Başlayın",
  "gitcoin-logo": "Gitcoin logosu",
  "glossary": "Sözlük",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Kılavuzlar ve kaynaklar",
  "history": "Tarihçe",
  "history-of-ethereum": "Ethereum'un Geçmişi",
  "home": "Ana sayfa",
  "how-ethereum-works": "Ethereum nasıl çalışır?\n",
  "image": "Fotoğraf",
  "in-this-section": "Bu bölümde",
  "individuals": "Bireyler",
  "individuals-menu": "Birey Menüsü",
  "jobs": "Jobs",
  "kraken-logo": "Kraken logosu",
  "language-ar": "Arabic",
  "language-bg": "Bulgarian",
  "language-bn": "Bengali",
  "language-ca": "Catalan",
  "language-cs": "Czech",
  "language-de": "German",
  "language-el": "Greek",
  "language-en": "English",
  "language-es": "Spanish",
  "language-fa": "Farsi",
  "language-fi": "Finnish",
  "language-fr": "French",
  "language-hu": "Hungarian",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonesian",
  "language-ig": "Igbo",
  "language-it": "Italian",
  "language-ja": "Japanese",
  "language-ko": "Korean",
  "language-lt": "Lithuanian",
  "language-ml": "Malayalam",
  "language-nb": "Norwegian",
  "language-nl": "Dutch",
  "language-pl": "Polish",
  "language-pt": "Portuguese",
  "language-pt-br": "Portuguese (Brazilian)",
  "language-ro": "Romanian",
  "language-ru": "Russian",
  "language-se": "Swedish",
  "language-sk": "Slovak",
  "language-sl": "Slovenian",
  "language-support": "Dil Desteği",
  "language-tr": "Turkish",
  "language-uk": "Ukrainian",
  "language-vi": "Vietnamese",
  "language-zh": "Chinese Simplified",
  "language-zh-tw": "Chinese Traditional",
  "languages": "Diller",
  "last-24-hrs": "Son 24 saat",
  "last-edit": "Son düzenleme",
  "learn": "Öğren",
  "learn-by-coding": "Kodlayarak öğren",
  "learn-menu": "Öğren menüsü",
  "learn-more": "Daha fazla bilgi edinin",
  "less": "Daha az",
  "light-mode": "Açık tema",
  "listing-policy-disclaimer": "Bu sayfada yer alan bilgiler genel nitelikte olup ve yalnızca bilgi amaçlı sağlanmıştır. Bir ürün eklemek veya politika hakkında geri bildirim sağlamak istiyorsanız GitHub'da bir sorun oluşturun.",
  "listing-policy-raise-issue-link": "Sorun bildir",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Yükleniyor...",
  "loading-error": "Yükleme hatası.",
  "loading-error-refresh": "Veri yüklenirken hata oluştu. Sayfayı yenilemeyi deneyin.",
  "logo": "logo",
  "loopring-logo": "Loopring logosu",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Mainnet Ethereum",
  "makerdao-logo": "MakerDao logosu",
  "matcha-logo": "Matcha logosu",
  "merge": "Merge",
  "more": "Daha fazlası",
  "nav-beginners": "Yeni başlayanlar",
  "next": "Sonraki",
  "oasis-logo": "Oasis logosu",
  "on-this-page": "Bu sayfada",
  "page-content": "Sayfa içeriği",
  "page-enterprise": "Kurumsal",
  "page-last-updated": "Sayfanın son güncellenmesi",
  "previous": "Önceki",
  "privacy-policy": "Gizlilik politikası",
  "private-ethereum": "Özel Ethereum",
  "pros": "Artıları",
  "read-more": "Devamını Oku",
  "refresh": "Lütfen sayfayı yenileyiniz.",
  "review-progress": "İnceleme süreci",
  "search": "Arama",
  "search-box-blank-state-text": "Uzakta ara!",
  "search-eth-address": "Bu bir Ethereum adresine benziyor. Adreslere özel veriler sağlamıyoruz. Şunun gibi bir blok gezgininde aramayı deneyin",
  "search-no-results": "Aramanız için sonuç yok",
  "security": "Security",
  "see-contributors": "Katkıda bulunanları göster",
  "set-up-local-env": "Yerel ortamı kur",
  "shard-chains": "Shard zincirleri",
  "show-all": "Tümünü göster",
  "show-less": "Daha az göster",
  "site-description": "Ethereum, bir para ve yeni tür uygulamalar için evrensel, merkezi olmayan bir platformdur. Ethereum'da parayı kontrol eden kod yazabilir ve dünyanın her yerinden erişilebilir uygulamalar oluşturabilirsiniz.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Sabit coinler",
  "staking": "Stake etme",
  "summary": "Özet",
  "terms-of-use": "Kullanım Koşulları",
  "transaction-fees": "İşlem ücretleri nedir?",
  "translation-banner-body-new": "Henüz çevirmediğimiz için bu sayfayı İngilizce olarak görüntülüyorsunuz. Bu içeriği çevirmemize yardım edin.",
  "translation-banner-body-update": "Bu sayfanın yeni bir sürümü vardır ancak şu anda yalnızca İngilizce'dir. Son sürümü çevirmemize yardımcı ol.",
  "translation-banner-button-join-translation-program": "Çeviri programına katıl",
  "translation-banner-button-learn-more": "Daha fazla bilgi edin",
  "translation-banner-button-see-english": "İngilizce'yi gör",
  "translation-banner-button-translate-page": "Sayfayı çevir",
  "translation-banner-title-new": "Bu sayfanın çevirisine yardım edin",
  "translation-banner-title-update": "Bu sayfanın güncellenmesine yardım edin",
  "translation-program": "Translation Program",
  "translation-progress": "Çeviri ilerlemesi",
  "tutorials": "Sunumlar",
  "uniswap-logo": "Uniswap logosu",
  "use": "Kullanım",
  "use-ethereum": "Ethereum'u Kullan",
  "use-ethereum-menu": "Ethereum menüsünü kullanın",
  "vision": "Vizyon",
  "wallets": "Cüzdanlar",
  "website-last-updated": "Sayfa son güncelleme",
  "what-is-ether": "Ether (ETH) nedir?",
  "what-is-ethereum": "Ethereum nedir?",
  "whitepaper": "Tanıtım",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Bir özellik iste",
  "page-about-h3": "Yapım aşamasında",
  "page-about-h3-1": "Uygulanan özellikler",
  "page-about-h3-2": "Planlanan özellikler",
  "page-about-li-1": "devam ediyor",
  "page-about-li-2": "planlanmış",
  "page-about-li-3": "uygulandı",
  "page-about-li-4": "uygulandı",
  "page-about-link-1": "Bu deponun kaynak kodu MIT lisansı altında lisanslıdır",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "GitHub ilerleyişindeki görevlerin tam listesini görüntüleyin",
  "page-about-link-4": "Discord sunucumuza katılın",
  "page-about-link-5": "Twitter'da bize ulaşın",
  "page-about-link-6": "GitHub'da uygulanan görevlerin tam listesini görün",
  "page-about-link-7": "GitHub'da bir konu oluşturun",
  "page-about-p-1": "Ethereum.org'un lansmanından bu yana çalışma şeklimiz konusunda şeffaf olmaya çalışıyoruz. Bu esas değerlerimizden biridir zira şeffaflığın Ethereum'un başarısı için çok önemli olduğuna inanıyoruz.",
  "page-about-p-2": "Birincil proje yönetim aracımız olarak",
  "page-about-p-3": "kullanırız. Görevlerimizi üç kategoriye ayırırız:",
  "page-about-p-4": "  Belirli bir görevin durumunun ne olduğu konusunda topluluğu bilgilendirmek için elimizden geleni yaparız.",
  "page-about-p-5": "Uyguladığımız görevler.",
  "page-about-p-6": "Sıradaki uygulayacağımız görevler.",
  "page-about-p-7": "En son tamamlanan görevler.",
  "page-about-p-8": "Ethereum.org'un nasıl geliştirilebileceği konusunda fikirleriniz mi var? Sizinle işbirliği yapmaktan mutluluk duyarız!",
  "page-assets-bazaar": "Ethereum pazarı",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Yapı taşları",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge, dApps kullanıyor",
  "page-assets-download-artist": "Sanatçı:\n",
  "page-assets-download-download": "İndir",
  "page-assets-enterprise": "Enterprise Ethereum",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "ETH elmas (renk)",
  "page-assets-eth-diamond-glyph": "ETH elmas (glif)",
  "page-assets-eth-diamond-gray": "ETH elmas (gri)",
  "page-assets-eth-diamond-purple": "ETH elmas (mor)",
  "page-assets-eth-diamond-white": "ETH elmas (beyaz)",
  "page-assets-eth-glyph-video-dark": "ETH glif videosu (koyu)",
  "page-assets-eth-glyph-video-light": "ETH glif videosu (açık)",
  "page-assets-eth-logo-landscape-gray": "ETH logosu yatay (gri)",
  "page-assets-eth-logo-landscape-purple": "ETH logosu yatay (mor)",
  "page-assets-eth-logo-landscape-white": "ETH logosu yatay (beyaz)",
  "page-assets-eth-logo-portrait-gray": "ETH logosu dikey (gri)",
  "page-assets-eth-logo-portrait-purple": "ETH logo portresi (mor)",
  "page-assets-eth-logo-portrait-white": "ETH logo portresi (beyaz)",
  "page-assets-eth-wordmark-gray": "ETH kelime işareti (gri)",
  "page-assets-eth-wordmark-purple": "ETH kelime işareti (mor)",
  "page-assets-eth-wordmark-white": "ETH kelime işareti (beyaz)",
  "page-assets-ethereum-brand-assets": "Ethereum \"marka\" varlıkları",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "ethereum.org varlıkları",
  "page-assets-hero": "ethereum.org kahramanı",
  "page-assets-hero-particles": "ETH parçacık görüntüsü",
  "page-assets-historical-artwork": "Tarihi sanat",
  "page-assets-illustrations": "Medya",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Ethereum ve ethereum.org marka varlıklarını, çizimleri ve medyayı keşfedin ve indirin.",
  "page-assets-meta-title": "Ethereum marka varlıkları",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Düz arka plan",
  "page-assets-page-assets-transparent-background": "Saydam arkaplan",
  "page-assets-robot": "Robot cüzdan",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1inch logosu",
  "page-dapps-aave-logo-alt": "Aave logosu",
  "page-dapps-add-button": "dApp öner",
  "page-dapps-add-title": "dApp ekle",
  "page-dapps-audius-logo-alt": "Audius logosu",
  "page-dapps-augur-logo-alt": "Augur logosu",
  "page-dapps-axie-infinity-logo-alt": "Axie Infinity logosu",
  "page-dapps-brave-logo-alt": "Brave logosu",
  "page-dapps-category-arts": "Sanat ve moda",
  "page-dapps-category-browsers": "Tarayıcılar",
  "page-dapps-category-collectibles": "Dijital koleksiyonlar",
  "page-dapps-category-competitive": "Rekabet",
  "page-dapps-category-computing": "Geliştirici araçları",
  "page-dapps-category-dex": "Token takasları",
  "page-dapps-category-investments": "Yatırımlar",
  "page-dapps-category-lending": "Borç verme ve borçlanma",
  "page-dapps-category-lottery": "Kitle fonlama",
  "page-dapps-category-marketplaces": "Pazar yerleri",
  "page-dapps-category-music": "Müzik",
  "page-dapps-category-payments": "Ödemeler",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Trade ve tahmin piyasaları",
  "page-dapps-category-utilities": "Yardımcı araçlar",
  "page-dapps-category-worlds": "Sanal dünyalar",
  "page-dapps-choose-category": "Kategori seçimi",
  "page-dapps-collectibles-benefits-1-description": "Bir sanat eseri Etherum blok zincirinde belirtildiğinde sahipliğini yaratılışından şu anki sahibine kadar takip edebilirsin. Bu sahteciliği engeller.",
  "page-dapps-collectibles-benefits-1-title": "Sahiplik kanıtlanabilir",
  "page-dapps-collectibles-benefits-2-description": "Müzik dinlemek veya sanat eseri satın almak için ödeme yapmak sanatçılar için çok daha adil. Ethereum ile aracılara daha az ihtiyaç var. Aracılara ihtiyaç duyulursa, maliyetleri o kadar yüksek olmaz çünkü platformların ağ altyapısı için ödeme yapması gerekmez.",
  "page-dapps-collectibles-benefits-2-title": "İçerik oluşturucular için daha adil",
  "page-dapps-collectibles-benefits-3-description": "Tokenize edilmiş koleksiyonlar, platforma değil, Ethereum adresinize bağlıdır. Böylece oyun içi öğeler gibi şeyleri yalnızca oyunun kendisinde değil, herhangi bir Ethereum pazarında satabilirsiniz.",
  "page-dapps-collectibles-benefits-3-title": "Koleksiyon parçaları sizinle birlikte gelir",
  "page-dapps-collectibles-benefits-4-description": "Sanatınızı tokenize etmeniz ve satmanız için araçlar ve ürünler zaten var! Ve tokenleriniz herhangi bir platformda ve tüm Ethereum koleksiyon platformlarında satılabilir.",
  "page-dapps-collectibles-benefits-4-title": "Altyapı zaten yerinde",
  "page-dapps-collectibles-benefits-description": "Bunlar, dijital sahipliğe odaklanan, içerik oluşturucular için kazanç potansiyelini artıran ve en sevdiğiniz içerik oluşturuculara ve onların çalışmalarına yatırım yapmak için yeni yollar icat eden uygulamalardır.",
  "page-dapps-collectibles-benefits-title": "merkezi olmayan koleksiyon ve akış",
  "page-dapps-collectibles-button": "Sanat ve koleksiyon",
  "page-dapps-collectibles-description": "Bunlar, dijital sahipliğe odaklanan, içerik oluşturucular için kazanç potansiyelini artıran ve en sevdiğiniz içerik oluşturuculara ve onların çalışmalarına yatırım yapmak için yeni yollar icat eden uygulamalardır.",
  "page-dapps-collectibles-title": "Merkezi olmayan sanatlar ve koleksiyonlar",
  "page-dapps-compound-logo-alt": "Compound logosu",
  "page-dapps-cryptopunks-logo-alt": "CryptoPunks logosu",
  "page-dapps-cryptovoxels-logo-alt": "Cryptovoxels logosu",
  "page-dapps-dapp-description-1inch": "En iyi fiyatları toplayarak yüksek fiyat düşüşlerinden kaçınmanıza yardımcı olur.",
  "page-dapps-dapp-description-aave": "Faiz kazanmak ve istediğiniz zaman para çekmek için token'larınızı ödünç verin.",
  "page-dapps-dapp-description-async-art": "#ProgrammableArt - genel görüntüyü etkilemek için kullanabileceğiniz \"Katmanlara\" bölünmüş dijital resimler oluşturun, toplayın ve ticaretini yapın. Her Ana ve Katman bir ERC721 belirtecidir.",
  "page-dapps-dapp-description-audius": "Merkezi olmayan akış platformu = yaratıcılar için para, etiketler için değil.",
  "page-dapps-dapp-description-augur": "Spor, ekonomi ve diğer dünya olaylarının sonuçlarına bahse girin.",
  "page-dapps-dapp-description-axie-infinity": "Axies denen yaratıklarla ticaret yapın ve savaşın. Ve oynadıkça kazanın - mobil cihazlarda kullanılabilir",
  "page-dapps-dapp-description-brave": "Göz atmak için token kazanın ve en sevdiğiniz içerik oluşturucularını onlarla destekleyin.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Faiz kazanmak ve istediğiniz zaman para çekmek için token'larınızı ödünç verin.",
  "page-dapps-dapp-description-cryptopunks": "Ethereum'daki ilk token koleksiyon parçalarından biri olan punkları satın alın, teklif verin ve satışa sunun.",
  "page-dapps-dapp-description-cryptovoxels": "Sanat galerileri oluşturun, mağazalar inşa edin ve arazi satın alın - bir Ethereum sanal dünyası.",
  "page-dapps-dapp-description-dark-forest": "Sonsuz, prosedürel olarak oluşturulmuş, kriptografik olarak belirlenmiş bir evrende gezegenleri fethedin.",
  "page-dapps-dapp-description-decentraland": "Keşfedebileceğiniz sanal bir dünyada sanal arazi toplayın, ticaretini yapın.",
  "page-dapps-dapp-description-dydx": "10 kata kadar kaldıraçla kısa veya kaldıraçlı pozisyonlar açın. Borç verme ve borçlanma da mevcuttur.",
  "page-dapps-dapp-description-ens": "Ethereum adresleri için kullanıcı dostu isimler ve merkezi olmayan siteler.",
  "page-dapps-dapp-description-foundation": "Dijital sanat eserlerinin benzersiz baskılarına yatırım yapın ve diğer alıcılarla ticaret yapın.",
  "page-dapps-dapp-description-gitcoin": "Açık kaynaklı yazılım üzerinde çalışarak kripto kazanın.",
  "page-dapps-dapp-description-gitcoin-grants": "Güçlendirilmiş katkılarla Ethereum topluluk projeleri için kitle fonlaması",
  "page-dapps-dapp-description-gods-unchained": "Stratejik ticaret kart oyunu. Gerçek hayatta satabileceğiniz kartları oynayarak kazanın.",
  "page-dapps-dapp-description-golem": "Paylaşılan bilgi işlem gücüne erişin veya kendi kaynaklarınızı kiralayın.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Eşler arası ticaret platformu hız için inşa edilmiştir.",
  "page-dapps-dapp-description-marble-cards": "URL' lere dayalı benzersiz dijital kartlar oluşturun ve ticaret yapın.",
  "page-dapps-dapp-description-matcha": "Size en iyi fiyatları bulmanıza yardımcı olacak şekilde birden fazla borsada arama yapın.",
  "page-dapps-dapp-description-nifty-gateway": "Zincirdeki sanatçılar, sporcular, markalar, ve yaratıcıların eserleri satın alın.",
  "page-dapps-dapp-description-oasis": "Bir Ethereum sabit coin olan Dai ile ticaret yapın, ödünç alın ve tasarruf edin.",
  "page-dapps-dapp-description-opensea": "Sınırlı sayıda mal satın alın, satın, keşfedin ve ticaret yapın.",
  "page-dapps-dapp-description-opera": "Tarayıcınızdan satıcılara, diğer kullanıcılara ve uygulamalara kripto gönderin.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Sonuçlara bahse girin. Bilgi piyasalarında ticaret yapın.",
  "page-dapps-dapp-description-pooltogether": "Kaybedemeyeceğiniz bir piyango. Her hafta ödüller.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Token'li koleksiyonlar oluşturun, satın ve satın alın.",
  "page-dapps-dapp-description-sablier": "Gerçek zamanlı olarak para akışı.",
  "page-dapps-dapp-description-superrare": "Dijital sanat eserlerini doğrudan sanatçılardan veya ikincil pazarlardan satın alın.",
  "page-dapps-dapp-description-token-sets": "Otomatik olarak yeniden dengeleyen kripto yatırım stratejileri.",
  "page-dapps-dapp-description-tornado-cash": "Ethereum'da anonim işlemler gönderin.",
  "page-dapps-dapp-description-uniswap": "Token'leri basitçe değiştirin veya % ödüller için token'ler sağlayın.",
  "page-dapps-dark-forest-logo-alt": "Dark Forest logosu",
  "page-dapps-decentraland-logo-alt": "Decentraland logosu",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Denemek için bir Ethereum uygulaması bulun.",
  "page-dapps-doge-img-alt": "Bilgisayar kullanan bir köpek görseli",
  "page-dapps-dydx-logo-alt": "dYdX logosu",
  "page-dapps-editors-choice-dark-forest": "Gezegenleri fethetmek ve en son Ethereum ölçekleme/gizlilik teknolojisini denemek için başkalarına karşı oynayın. Ethereum' a zaten aşına olanlar için örneklerden bir tanesi.",
  "page-dapps-editors-choice-description": "Ethereum.org ekibinin şu anda sevdiği birkaç merkezi olmayan uygulama (dapp). Aşağıdan daha fazla uygulama keşfedin.",
  "page-dapps-editors-choice-foundation": "Kültüre yatırım yapın. İnanılmaz sanatçı, müzisyen ve markadan benzersiz dijital sanat eserleri ve moda ürünleri satın alın, ticaret yapın ve satın.",
  "page-dapps-editors-choice-header": "Editörlerin seçimi",
  "page-dapps-editors-choice-pooltogether": "Kaybetmediğiniz piyango için bir bilet satın alın. Her hafta, tüm bilet havuzundan elde edilen faiz şanslı bir kazanana gönderilir. İstediğiniz zaman paranızı geri alın.",
  "page-dapps-editors-choice-uniswap": "Tokenlerinizi kolaylıkla değiştirin. Ağ genelinde insanlarla token ticareti yapmanızı sağlayan bir topluluk favorisi.",
  "page-dapps-ens-logo-alt": "Ethereum Name Service logosu",
  "page-dapps-explore-dapps-description": "Merkezi olmayan ağların olanaklarını test eden birçok uygulama hala deneyseldir. Ancak teknoloji, finans, oyun ve koleksiyon kategorilerinde bazı başarılı erken hareket edenler olmuştur.",
  "page-dapps-explore-dapps-title": "Dapps'i keşfedin",
  "page-dapps-features-1-description": "Ethereum' a dağıtıldıktan sonra, merkezi olmayan uygulama (dapp) kodu kaldırılamaz ve herkes merkezi olmayan uygulama özelliklerini kullanabilir. Merkezi olmayan uygulamaların arkasındaki ekip dağılsa bile, yine de kullanabilirsiniz. Ethereum'da bir kez var olan, orada daima kalır.",
  "page-dapps-features-1-title": "Sahip yok",
  "page-dapps-features-2-description": "Merkezi olmayan bir uygulama (dapp) kullanmaktan veya İşlem göndermekten engellenemezsiniz. Örneğin, Twitter Ethereum' daysa, hiç kimse hesabınızı engelleyemez veya tweet atmanızı engelleyemez.",
  "page-dapps-features-2-title": "Sansürsüz",
  "page-dapps-features-3-description": "Ethereum' un ETH' ye sahip olması nedeniyle, ödemeler Ethereum' a özgüdür. Geliştiricilerin üçüncü taraf ödeme sağlayıcılarıyla entegrasyon için zaman harcamasına gerek yoktur.",
  "page-dapps-features-3-title": "Yerleşik ödemeler",
  "page-dapps-features-4-description": "Merkezi olmayan uygulama (dapp) kodu genellikle açık ve varsayılan olarak uyumludur. Takımlar düzenli olarak diğer takımların çalışmalarını kullanarak inşa eder. Kullanıcıların merkezi olmayan uygulamanızdaki belirteçleri değiştirmesine izin vermek istiyorsanız, başka bir merkezi olmayan uygulamanın kodunu takmanız yeterlidir.",
  "page-dapps-features-4-title": "Tak ve çalıştır",
  "page-dapps-features-5-description": "Çoğu merkezi olmayan uygulama ile gerçek kimliğinizi paylaşmanıza gerek yoktur. Ethereum hesabınız girişinizdir ve sadece bir cüzdana ihtiyacınız vardır.",
  "page-dapps-features-5-title": "Anonim bir giriş",
  "page-dapps-features-6-description": "Kriptografi, saldırganların sizin adınıza işlemleri ve diğer merkezi olmayan uygulama etkileşimlerini taklit etmemesini sağlar. Yetkili uygulama işlemleriniz Ethereum hesabınızla, genellikle cüzdanınız aracılığıyla yapılır, bu nedenle kimlik bilgilerinizi güvende tutun.",
  "page-dapps-features-6-title": "Kriptografi ile desteklenmektedir",
  "page-dapps-features-7-description": "Merkezi olmayan uygulama Ethereum'da yayınlandıktan sonra, yalnızca Ethereum' un kendisi çökerse, çökecektir. Ethereum büyüklüğündeki ağlara saldırmak çok zordur.",
  "page-dapps-features-7-title": "Kesinti yok",
  "page-dapps-finance-benefits-1-description": "Ethereum' da çalışan finansal hizmetlerin kayıt gereksinimleri yoktur. Paranız ve internet bağlantınız varsa başlamaya hazırsınız.",
  "page-dapps-finance-benefits-1-title": "Açık erişim",
  "page-dapps-finance-benefits-2-description": "Bu finansal ürünler arasında etkileşimde bulunabileceğiniz bir dizi token mevcut. İnsanlar her zaman Ethereum' un üstünde yeni token'lar inşa ediyorlar.",
  "page-dapps-finance-benefits-2-title": "Yeni bir token ekonomisi",
  "page-dapps-finance-benefits-3-description": "Ekipler daha az uçucu bir kripto para birimi olan stabil coin'leri inşa ettiler. Bunlar, risk ve belirsizlik olmadan kriptoyu denemenize ve kullanmanıza izin verir.",
  "page-dapps-finance-benefits-3-title": "Sabit coin'ler",
  "page-dapps-finance-benefits-4-description": "Ethereum alanındaki tüm finansal ürünler modülerdir ve birbirleriyle uyumludur. Bu modüllerin yeni konfigürasyonları her zaman piyasaya çıkıyor ve kripto ile yapabileceklerinizi artırıyor.",
  "page-dapps-finance-benefits-4-title": "Birbirine bağlı finansal hizmetler",
  "page-dapps-finance-benefits-description": "Merkezi olmayan finans uygulamalarının gelişmesine izin veren Ethereum hakkında ne var?",
  "page-dapps-finance-benefits-title": "merkezi olmayan finans",
  "page-dapps-finance-button": "Finans",
  "page-dapps-finance-description": "Bu uygulamalar, kripto para birimleri kullanarak finansal hizmetler oluşturmaya odaklanan uygulamalardır. Borç verme, borçlanma, faiz kazanma ve özel ödemeler gibi şeyler sunarlar – kişisel veri gerektirmezler.",
  "page-dapps-finance-title": "Merkezi olmayan finans",
  "page-dapps-foundation-logo-alt": "Foundation logosu",
  "page-dapps-gaming-benefits-1-description": "Sanal arazi veya ticaret kartları olsun, öğeleriniz koleksiyon pazarlarında satılabilir. Oyun içi öğeleriniz gerçek dünya değerine sahiptir.",
  "page-dapps-gaming-benefits-1-title": "Oyun öğeleri token olarak ikiye katlanır",
  "page-dapps-gaming-benefits-2-description": "Eşyalarınıza ve bazı durumlarda oyun şirketlerine değil ilerlemenize sahipsiniz. Bu şekilde, oyunun arkasındaki şirket saldırıya uğrarsa, sunucu arızasından muzdaripse veya dağılırsa hiçbir şey kaybetmezsiniz.",
  "page-dapps-gaming-benefits-2-title": "Tasarruflarınız güvende",
  "page-dapps-gaming-benefits-3-description": "Ethereum ödemelerinin doğrulanmasının herkes tarafından yapılabilmesi gibi, oyunlar da teyit amacıyla bu kaliteden yararlanabilir. Teoride, kritik vuruşların sayısından rakibin savaş sandığının boyutuna kadar her şey doğrulanabilir.",
  "page-dapps-gaming-benefits-3-title": "Kanıtlanabilir adalet",
  "page-dapps-gaming-benefits-description": "Merkezi olmayan finans uygulamalarının gelişmesine izin veren Ethereum hakkında ne var?",
  "page-dapps-gaming-benefits-title": "merkezi olmayan oyun",
  "page-dapps-gaming-button": "Oyun",
  "page-dapps-gaming-description": "Bunlar, sanal dünyaların yaratılmasına ve gerçek dünya değerine sahip koleksiyonları kullanarak diğer oyuncularla savaşmaya odaklanan uygulamalardır.",
  "page-dapps-gaming-title": "Merkezi olmayan oyun",
  "page-dapps-get-some-eth-description": "Merkezi olmayan uygulama eylemleri bir işlem ücretine mal olur",
  "page-dapps-get-started-subtitle": "Bir merkezi olmayan uygulama denemek için bir cüzdana ve biraz ETH' ye ihtiyacınız olacak. Bir cüzdan bağlanmanıza veya oturum açmanıza olanak sağlar. İşlem ücretlerini ödemek içinse ETH' ye ihtiyacınız vardır.",
  "page-dapps-get-started-title": "Başlayın",
  "page-dapps-gitcoin-grants-logo-alt": "Gitcoin Grants logosu",
  "page-dapps-gitcoin-logo-alt": "Gitcoin logosu",
  "page-dapps-gods-unchained-logo-alt": "Gods Unchained logosu",
  "page-dapps-golem-logo-alt": "Golem logosu",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Ethereum destekli araçlar ve hizmetler",
  "page-dapps-hero-subtitle": "Merkezi olmayan uygulamalar (dapps), iş modellerini bozmak veya yenilerini icat etmek için Ethereum kullanarak gelişen bir uygulama hareketidir.",
  "page-dapps-how-dapps-work-p1": "Merkezi olmayan uygulamaların arka uç kodları (akıllı sözleşmeler) merkezi olmayan bir ağda çalışır ve merkezi bir sunucuda çalışmaz. Veri depolama için Ethereum blok zincirini, uygulama mantıkları için akıllı sözleşmeleri kullanırlar.",
  "page-dapps-how-dapps-work-p2": "Akıllı bir sözleşme, herkesin görmesi ve tam olarak bu kurallara göre çalışması için zincir üzerinde yaşayan bir dizi kural gibidir. Bir otomat düşünün: yeterli para ve doğru seçim ile tedarik ederseniz, istediğiniz öğeyi alırsınız. Otomatlar gibi, akıllı sözleşmeler de Ethereum hesabınız gibi para tutabilir. Bu, kodun anlaşmalara ve işlemlere aracılık etmesine izin verir.",
  "page-dapps-how-dapps-work-p3": "Merkezi olmayan uygulamalar Ethereum ağına dağıtıldıktan sonra bunları değiştiremezsiniz. Uygulamalar merkezi olmayan olabilir çünkü bir birey veya şirket değil, sözleşmeye yazılan mantık tarafından kontrol edilirler.",
  "page-dapps-how-dapps-work-title": "Merkezi olmayan uygulamalar nasıl çalışır",
  "page-dapps-learn-callout-button": "Geliştirmeye başla",
  "page-dapps-learn-callout-description": "Topluluk geliştirici portalı, mümkün olan en kısa sürede merkezi olmayan uygulama oluşturmaya başlamanıza yardımcı olacak belgelere, araçlara ve çerçevelere sahiptir.",
  "page-dapps-learn-callout-image-alt": "Lego tuğlalarından ETH sembolü inşa eden bir elin görseli.",
  "page-dapps-learn-callout-title": "Bir merkezi olmayan uygulama (dapp) inşa etmeyi öğrenin",
  "page-dapps-loopring-logo-alt": "Loopring logosu",
  "page-dapps-magic-behind-dapps-description": "Merkezi olmayan uygulamalar normal uygulamalar gibi hissettirebilir. Ancak sahnelerin arkasında bazı özel nitelikler var çünkü Ethereum' un tüm süper güçlerini miras alırlar. Merkezi olmayan uygulamaları, uygulamalardan farklı kılan şey budur.",
  "page-dapps-magic-behind-dapps-link": "Ethereum' u harika yapan nedir?",
  "page-dapps-magic-behind-dapps-title": "Merkezi olmayan uygulamaların arkasındaki sihir",
  "page-dapps-magic-title-1": "Sihir",
  "page-dapps-magic-title-2": "arkasında",
  "page-dapps-magician-img-alt": "Sihirbazlar görseli",
  "page-dapps-marble-cards-logo-alt": "marble.cards logosu",
  "page-dapps-matcha-logo-alt": "Matcha logosu",
  "page-dapps-mobile-options-header": "Başka bir kategoriye göz atın",
  "page-dapps-nifty-gateway-logo-alt": "Nifty Gateway logosu",
  "page-dapps-oasis-logo-alt": "Oasis logosu",
  "page-dapps-opensea-logo-alt": "Oasis logosu",
  "page-dapps-opera-logo-alt": "Opera logosu",
  "page-dapps-polymarket-logo-alt": "Polymarket logosu",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "PoolTogether logosu",
  "page-dapps-rarible-logo-alt": "Rarible logosu",
  "page-dapps-ready-button": "Başla",
  "page-dapps-ready-description": "Denemek için merkezi olmayan bir uygulama seçin",
  "page-dapps-ready-title": "Hazır mısın?",
  "page-dapps-sablier-logo-alt": "Sablier logosu",
  "page-dapps-set-up-a-wallet-button": "Cüzdan bul",
  "page-dapps-set-up-a-wallet-description": "Bir cüzdan bir merkezi olmayan uygulama için \"girişiniz\" demektir",
  "page-dapps-set-up-a-wallet-title": "Bir cüzdan oluşturun",
  "page-dapps-superrare-logo-alt": "SuperRare logosu",
  "page-dapps-technology-button": "Teknoloji",
  "page-dapps-technology-description": "Bunlar, geliştirici araçlarını Ademi merkezileştirmeye, kripto-ekonomik sistemleri mevcut teknolojiye dahil etmeye ve açık kaynak geliştirme çalışmaları için pazarlar oluşturmaya odaklanan uygulamalardır.",
  "page-dapps-technology-title": "Merkezi olmayan teknoloji",
  "page-dapps-token-sets-logo-alt": "Token Sets logosu",
  "page-dapps-tornado-cash-logo-alt": "Tornado cash logosu",
  "page-dapps-uniswap-logo-alt": "Uniswap logosu",
  "page-dapps-wallet-callout-button": "Cüzdan bulun",
  "page-dapps-wallet-callout-description": "Cüzdanlar da merkezi olmayan uygulamalardır. Size uygun özelliklere göre bir tane bulun.",
  "page-dapps-wallet-callout-image-alt": "Bir robot görseli.",
  "page-dapps-wallet-callout-title": "Cüzdanları görüntüleyin",
  "page-dapps-warning-header": "Her zaman kendi araştırmanızı yapın",
  "page-dapps-warning-message": "Ethereum yeni bir teknolojidir ve çoğu uygulama yenidir. Herhangi bir büyük miktarda para yatırmadan önce, riskleri anladığınızdan emin olun.",
  "page-dapps-what-are-dapps": "Merkezi olmayan uygulamalar nelerdir?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Hesaplar",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Gelişmiş",
  "docs-nav-backend-apis": "Arka Uç API'ları",
  "docs-nav-block-explorers": "Blok Arayıcıları",
  "docs-nav-blocks": "Bloklar",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Akıllı kontratlar derlemek",
  "docs-nav-composability": "Birleştirilebilirlik",
  "docs-nav-consensus-mechanisms": "Konsensus mekanizmaları",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Veri ve analizler",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Akıllı kontratlar dağıtmak",
  "docs-nav-development-frameworks": "Geliştirici çerçeveleri",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Geliştirme ağları",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "Ethereum client API'leri",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Ethereum yığını",
  "docs-nav-evm": "Ethereum Sanal Makinesi (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Temel konular",
  "docs-nav-gas": "Gaz",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Tümleşik Geliştirme Ortamları (IDE'ler)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Dapps'a giriş",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Ethereum'a giriş",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Yığına giriş",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "JavaScript API'ları",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Madencilik",
  "docs-nav-networks": "Ağlar",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Düğümler ve istemciler",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Hizmet olarak node'lar",
  "docs-nav-oracles": "Oracles",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Dilleri programlama",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Hisse ispatı",
  "docs-nav-proof-of-work": "İş ispatı",
  "docs-nav-python": "Python",
  "docs-nav-readme": "BENİOKU",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Ölçeklendirme",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Güvenlik",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Akıllı sözleşme anatomisi",
  "docs-nav-smart-contract-languages": "Akıllı sözleşme dilleri",
  "docs-nav-smart-contracts": "Akıllı sözleşmeler",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Akıllı sözleşme kitaplıkları",
  "docs-nav-standards": "Standartlar",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Depolama",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Akıllı sözleşmeleri test etmek",
  "docs-nav-token-standards": "Token standartları",
  "docs-nav-transactions": "İşlemler",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 ve Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Konuyla ilgili bir uzmansanız ve katkıda bulunmak istiyorsanız, bu sayfayı düzenleyin ve bilgeliğinizi herkesle paylaşın.",
  "page-calltocontribute-desc-2": "İtibar kazanacak ve Ethereum topluluğuna yardım etmiş olacaksınız!",
  "page-calltocontribute-desc-3": "Bu değiştirilebilir dokümantasyon şablonunu kullanın",
  "page-calltocontribute-desc-4": "Sorularınızı Discord sunucumuzdaki #content kanalından bize sorabilirsiniz",
  "page-calltocontribute-link": "dokümantasyon şablonu",
  "page-calltocontribute-link-2": "Discord sunucusu",
  "page-calltocontribute-span": "Sayfayı düzenle",
  "page-calltocontribute-title": "Bu sayfada bize yardım edin",
  "page-developer-meta-title": "Ethereum Geliştirici Kaynakları",
  "page-developers-about": "Geliştirici kaynakları hakkında",
  "page-developers-about-desc": "ethereum.org, Ethereum ile temel kavramların yanı sıra geliştirme yığını hakkındaki dokümantasyonu oluşturmanıza yardımcı olmaya hazırdır. Ayrıca, başlamanızı sağlayacak öğreticiler de bulunmaktadır.",
  "page-developers-about-desc-2": "Mozilla Geliştirici Ağından esinlenerek Ethereum'un harika geliştirici içeriği ve kaynaklarını barındıracak bir yere ihtiyacı olduğunu düşündük. Mozilla'daki arkadaşlarımız gibi buradaki her şey açık kaynaklıdır ve genişletmeniz ve geliştirmeniz için size sunulmuştur.",
  "page-developers-account-desc": "Sözleşmeliler veya ağdaki kişiler",
  "page-developers-accounts-link": "Hesaplar",
  "page-developers-advanced": "Gelişmiş",
  "page-developers-api-desc": "Akıllı sözleşmelerle etkileşim kurmak için kütüphanelerin kullanılması",
  "page-developers-api-link": "Arka Uç API'ları",
  "page-developers-aria-label": "Geliştirici Menüsü",
  "page-developers-block-desc": "Blok zincirine eklenmiş işlem yığınları",
  "page-developers-block-explorers-desc": "Ethereum veri portalınız",
  "page-developers-block-explorers-link": "Blok Arayıcıları",
  "page-developers-blocks-link": "Bloklar",
  "page-developers-browse-tutorials": "Eğitimleri gör",
  "page-developers-choose-stack": "Yığınınızı seçin",
  "page-developers-contribute": "Katkıda bulun",
  "page-developers-dev-env-desc": "IDE'ler dapp geliştirme için uygundur",
  "page-developers-dev-env-link": "Geliştirme ortamları",
  "page-developers-discord": "Discord'a Katıl!",
  "page-developers-docs-introductions": "Girişler",
  "page-developers-evm-desc": "İşlemleri yapan bilgisayar",
  "page-developers-evm-link": "Ethereum Sanal Makinesi (EVM)",
  "page-developers-explore-documentation": "Belgeyi görüntüle",
  "page-developers-feedback": "GitHub veya Discord üzerinden geri bildirimde bulunabilirsiniz.",
  "page-developers-frameworks-desc": "Yardımcı olabilecek araçlar",
  "page-developers-frameworks-link": "Geliştirici çerçeveleri",
  "page-developers-fundamentals": "Temeller",
  "page-developers-gas-desc": "İşlemleri güçlendirmek için gereken ether",
  "page-developers-gas-link": "Gaz",
  "page-developers-get-started": "Nasıl başlamak istersin?",
  "page-developers-improve-ethereum": "Ethereum.org'u geliştirmemizde bize yardımcı olun",
  "page-developers-improve-ethereum-desc": "Ethereum.org gibi, bu dokümanlar da topluluğun gayretiyle oluşturulmuştur. Ethereum geliştiricilerine yardımcı olmak üzere hatalar, iyileştirme alanı veya yeni fırsatlar görürseniz bir PR oluşturun.",
  "page-developers-into-eth-desc": "Blok zinciri ve Ethereum'a giriş",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Merkeziyetsiz uygulamalara giriş",
  "page-developers-intro-dapps-link": "Dapps'a giriş",
  "page-developers-intro-eth-link": "Ethereum'a giriş",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Yığına giriş",
  "page-developers-intro-stack-desc": "Ethereum yığınına giriş",
  "page-developers-js-libraries-desc": "Akıllı sözleşmelerle etkileşim için javascript kullanımı",
  "page-developers-js-libraries-link": "Javascript kütüphaneleri",
  "page-developers-language-desc": "Bilinen dillerle Ethereum kullanımı",
  "page-developers-languages": "Dilleri programlama",
  "page-developers-learn": "Ethereum geliştiriciliğini öğren",
  "page-developers-learn-desc": "Belgelerimizle ana kavramları ve Ethereum yığını hakkında bilgi edinin",
  "page-developers-learn-tutorials": "Öğreticiler ile öğren",
  "page-developers-learn-tutorials-cta": "Öğreticileri gör",
  "page-developers-learn-tutorials-desc": "Ethereum geliştirmeyi zaten gerçekleştirmiş uygulayıcılardan adım adım öğrenin.",
  "page-developers-meta-desc": "Ethereum üzerinde çalışan geliştiriciler için belgeler, eğitimler ve araçlar.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Yeni bloklar nasıl oluşturulur ve fikir birliğine varılır",
  "page-developers-mining-link": "Madencilik",
  "page-developers-networks-desc": "Ana ağ ve test ağlarının özeti",
  "page-developers-networks-link": "Ağlar",
  "page-developers-node-clients-desc": "Ağda bloklar ve işlemler nasıl doğrulanır",
  "page-developers-node-clients-link": " Düğümler ve İstemciler",
  "page-developers-oracle-desc": "Zincirde olmayan verileri sözleşmelerinize katma",
  "page-developers-oracles-link": "Oracles",
  "page-developers-play-code": "Kodla oynayın",
  "page-developers-read-docs": "Belgeleri okuyun",
  "page-developers-scaling-desc": "Daha hızlı işlemler için çözümler",
  "page-developers-scaling-link": "Ölçeklendirme",
  "page-developers-smart-contract-security-desc": "Geliştirme sırasında dikkate alınacak güvenlik tedbirleri",
  "page-developers-smart-contract-security-link": "Güvenlik",
  "page-developers-set-up": "Yerel ortamı ayarla",
  "page-developers-setup-desc": "Bir geliştirme ortamı yapılandırarak oluşturmak için yığınınızı hazır edin.",
  "page-developers-smart-contracts-desc": "Dapps ardındaki mantık - kendi kendine yürütülen sözleşmeler",
  "page-developers-smart-contracts-link": "Akıllı sözleşmeler",
  "page-developers-stack": "Yığın",
  "page-developers-start": "Deneyimlemeye başla",
  "page-developers-start-desc": "Önce deneyimlemek mi istersin, sonra soru sormak mı?",
  "page-developers-storage-desc": "Dapp depolama nasıl yapılır",
  "page-developers-storage-link": "Depolama",
  "page-developers-subtitle": "Ethereum için geliştirici kılavuzu. Geliştiriciler için, geliştirenler tarafından.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "geliştirici",
  "page-developers-title-3": "kaynaklar",
  "page-developers-token-standards-desc": "Kabul edilen token standartlarının özeti",
  "page-developers-token-standards-link": "Token standartları",
  "page-developers-transactions-desc": "Ethereum durum değişikliği yolu",
  "page-developers-transactions-link": "İşlemler",
  "page-developers-web3-desc": "Web3 geliştirme dünyasının farkı",
  "page-developers-web3-link": "Web2 ve Web3",
  "page-learning-tools-bootcamps": "Geliştirici bootcampleri",
  "page-learning-tools-bootcamps-desc": "Öğreniminizi hızlandırmak için ücretli kurslar alın.",
  "page-learning-tools-browse-docs": "Dokümanlara göz atın",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "ChainShot logo",
  "page-learning-tools-coding": "Kodlayarak öğrenin",
  "page-learning-tools-coding-subtitle": "Etkileşimli bir öğrenim modelini tercih ediyorsanız, bu araçlar Ethereum' u denemenizde yardımcı olacaktır.",
  "page-learning-tools-consensys-academy-description": "Online Ethereum geliştirici eğitim programı.",
  "page-learning-tools-consensys-academy-logo-alt": "ConsenSys Academy logosu",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Kendi Zombi oyunununuzu inşa ederek Solidity öğrenin.",
  "page-learning-tools-cryptozombies-logo-alt": "CryptoZombies logosu",
  "page-learning-tools-documentation": "Dokümantasyon ile öğrenin",
  "page-learning-tools-documentation-desc": "Daha fazla bilgi edinmek ister misiniz? İhtiyacınız olan açıklamaları bulmak için dokümantasyona göz atın.",
  "page-learning-tools-eth-dot-build-description": "Sürükle ve bırak programlama ve açık kaynaklı yapı taşları da dahil olmak üzere web3 için bir sandbox.",
  "page-learning-tools-eth-dot-build-logo-alt": "Eth.build logosu",
  "page-learning-tools-ethernauts-description": "Akıllı kontratları hackleyerek bölümleri tamamlayın.",
  "page-learning-tools-ethernauts-logo-alt": "Ethernauts logosu",
  "page-learning-tools-game-tutorials": "İnteraktif oyun öğreticiler",
  "page-learning-tools-game-tutorials-desc": "Oynarken öğrenin. Bu öğreticiler, oyunun temellerini anlamanıza yardımcı olacaktır.",
  "page-learning-tools-meta-desc": "Ethereum ile denemeler ve geliştirmeler yapmanıza yardımcı olmak için Web tabanlı kodlama araçları ve etkileşimli öğrenme deneyimleri.",
  "page-learning-tools-meta-title": "Geliştirici öğrenme araçları",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Ethereum için akıllı sözleşmeler geliştirin, dağıtın ve yönetin. Learneth eklentisi ile öğreticilere göz atın.",
  "page-learning-tools-remix-description-2": "Remix sadece bir sandbox değildir. Birçok geliştirici, Remix kullanarak akıllı sözleşmelerini yazar, derler ve dağıtır.",
  "page-learning-tools-remix-logo-alt": "Remix logosu",
  "page-learning-tools-sandbox": "Sandboxların kodu",
  "page-learning-tools-sandbox-desc": "Bu sandboxlar, akıllı sözleşmeler yazmayı ve Ethereum'u anlamayı denemek için size bir alan sağlayacaktır.",
  "page-learning-tools-studio-description": "Akıllı sözleşmeler oluşturmak ve test etmek, onlar için bir ön uç oluşturmak için öğreticileri takip edebileceğiniz web tabanlı bir IDE.",
  "page-learning-tools-vyperfun-description": "Kendi Pokémon oyununuzu oluşturarak Vyper öğrenin.",
  "page-learning-tools-vyperfun-logo-alt": "Vyper.fun logosu",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Ethereum Sanal Makinesi'ni hedefleyen akıllı sözleşmeler için Python bazlı bir geliştirme ve yazılım iskeleti.",
  "page-local-environment-brownie-logo-alt": "Brownie logosu",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Merkezi olmayan uygulamalar oluşturmak ve dağıtmak için hepsi bir arada geliştirici platformu.",
  "page-local-environment-embark-logo-alt": "Embark logosu",
  "page-local-environment-epirus-desc": "Java sanal makinesinde blockchain uygulamalarını geliştirmek, dağıtmak ve izlemek için bir platform",
  "page-local-environment-epirus-logo-alt": "Epirus logosu",
  "page-local-environment-eth-app-desc": "Tek bir komutla Ethereum destekli uygulamalar oluşturun. Seçebileceğiniz birbirinden farklı arayüz yapıları ve DeFi şablonları da bulunur.",
  "page-local-environment-eth-app-logo-alt": "Create Eth App logosu",
  "page-local-environment-framework-feature-1": "Yerel bir blok-zincir örneğini döndürmek için özellikler.",
  "page-local-environment-framework-feature-2": "Akıllı sözleşmelerinizi derlemek ve test etmek için yardımcı programlar.",
  "page-local-environment-framework-feature-3": "Kullanıcı odaklı uygulamanızı aynı projede/depoda oluşturmak için istemci geliştirme eklentileri.",
  "page-local-environment-framework-feature-4": "Yerel olarak çalışan bir örneğe veya Ethereum' un genel ağlarından birine olsun, Ethereum ağlarına bağlanmak ve sözleşmeleri dağıtmak için yapılandırma.",
  "page-local-environment-framework-feature-5": "Merkezi olmayan uygulama dağıtımı için IPFS gibi depolama seçenekleriyle entegrasyonlar.",
  "page-local-environment-framework-features": "Bu frameworkler, bir sürü kutu dışı işlevsellik ile birlikte gelir:",
  "page-local-environment-frameworks-desc": "Özellikle yeni başlıyorsanız, bir framework seçmenizi öneririz. Tam teşekküllü bir uygulama oluşturmak, farklı teknoloji parçaları gerektirir. Frameworkler, gerekli özelliklerin çoğunu içerir veya istediğiniz araçları seçmek için basit eklenti sistemleri sağlar.",
  "page-local-environment-frameworks-title": "Framework'ler ve önceden yapılmış yığınlar",
  "page-local-environment-hardhat-desc": "Hardhat, profesyoneller için bir Ethereum geliştirme ortamıdır.",
  "page-local-environment-hardhat-logo-alt": "Hardhat logosu",
  "page-local-environment-openZeppelin-desc": "CLI ile akıllı sözleşmeleri derleyerek, yükselterek, dağıtarak ve etkileşime girerek geliştirme süresinden saatlerce tasarruf edin.",
  "page-local-environment-openZeppelin-logo-alt": "OpenZeppelin logosu",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Create Eth App: Akıllı sözleşmelerle desteklenen merkezi olmayan uygulamalar oluşturmaya başlamak için ihtiyacınız olan her şey",
  "page-local-environment-scaffold-eth-logo-alt": "scaffold-eth logosu",
  "page-local-environment-setup-meta-desc": "Ethereum geliştirmek için yazılım yığınınızı nasıl seçeceğinize dair bir rehber.",
  "page-local-environment-setup-meta-title": "Ethereum yerel geliştirme kurulumu",
  "page-local-environment-setup-subtitle": "İnşa etmeye hazırsanız, yığınınızı seçmenin zamanı geldi.",
  "page-local-environment-setup-subtitle-2": " Ethereum uygulamanızı oluşturmanıza yardımcı olmak için kullanabileceğiniz araçlar ve framework'ler.",
  "page-local-environment-setup-title": "Sanal geliştirme ortamınızı oluşturun",
  "page-local-environment-solidity-template-desc": "Solidity akıllı sözleşmeleriniz için önceden oluşturulmuş bir GitHub şablonu. Hardhat yerel ağı, testler için Waffle, cüzdan uygulaması için Etherler ve daha fazlasını içerir.",
  "page-local-environment-solidity-template-logo-alt": "Solidity template logosu",
  "page-local-environment-truffle-desc": "Truffle Suite, geliştiricileri olabildiğince rahat bir şekilde fikirden merkezi olmayan uygulamaya geçirir.",
  "page-local-environment-truffle-logo-alt": "Truffle logosu",
  "page-local-environment-waffle-desc": "Akıllı sözleşmeler için en gelişmiş test laboratuvarı. Tek başına veya Scaffold-eth veya Hardhat ile kullanın.",
  "page-local-environment-waffle-logo-alt": "Waffle logosu",
  "comp-tutorial-metadata-minute-read": "dakikalık okuma",
  "page-tutorial-listing-policy-intro": "Eğitime başlamadan önce listeleme politikalarımızı okuyun.",
  "comp-tutorial-metadata-tip-author": "İpucu yazarı",
  "page-tutorial-listing-policy": "makale listeleme politikası",
  "page-tutorial-new-github": "GitHub'da yeni misiniz?",
  "page-tutorial-new-github-desc": "Bir sorunu ortaya çıkarın, sadece istenen bilgileri doldurun ve öğreticinizi yapıştırın.",
  "page-tutorial-pull-request": "Bir çekme isteği oluşturun",
  "page-tutorial-pull-request-btn": "Çekme isteği oluşturun",
  "page-tutorial-pull-request-desc-1": "Lütfen takip edin",
  "page-tutorial-pull-request-desc-2": "öğreticiler/öğretici-adınız/index.md",
  "page-tutorial-pull-request-desc-3": "adlandırma yapısı.",
  "page-tutorial-raise-issue-btn": "Sorun bildir",
  "page-tutorial-read-time": "dk",
  "page-tutorial-submit-btn": "Bir eğitimi iletin",
  "page-tutorial-submit-tutorial": "Bir eğitimi iletmek için GitHub kullanmanız gerekir. Bir sorun veya bir çekme talebi oluşturmanızı memnuniyetle karşılıyoruz.",
  "page-tutorial-subtitle": "Düzenlenmiş topluluk eğitimleri listemize hoş geldiniz.",
  "page-tutorial-tags-error": "Bu etiketlerin hiçbirinde eğitici yok",
  "page-tutorial-title": "Ethereum Geliştirme Sunumları",
  "page-tutorials-meta-description": "İncelenen Ethereum topluluğu eğitimlerine konuya göre göz atın ve filtreleyin.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Ethereum Geliştirme Öğreticileri",
  "page-eth-buy-some": "Ethereum satın almak ister misiniz?",
  "page-eth-buy-some-desc": "Ethereum ve ETH birbiriyle karıştırılıyor. Ethereum, blok zinciridir ve ETH, Ethereum' un birincil varlığıdır. Muhtemelen ETH satın almak istiyorsunuz.",
  "page-eth-cat-img-alt": "Kedi kaleydoskopu ile ETH glifinin grafiği",
  "page-eth-collectible-tokens": "Koleksiyon token'ları",
  "page-eth-collectible-tokens-desc": "Koleksiyonluk bir oyun öğesini, dijital sanat eserini veya diğer benzersiz varlıkları temsil eden token'lar. Yaygın olarak değiştirilebilir olmayan belirteçler (NFT'ler) olarak bilinir.",
  "page-eth-cryptography": "Kriptografi ile güvence altında",
  "page-eth-cryptography-desc": "İnternet parası yeni olabilir, ancak kanıtlanmış kriptografi ile güvence altına alınmıştır. Bu, cüzdanınızı, ETH'nizi ve işlemlerinizi korur. ",
  "page-eth-currency-for-apps": "Ethereum uygulamalarının para birimi.",
  "page-eth-currency-for-future": "Dijital geleceğimiz için para birimi",
  "page-eth-description": "ETH bir kripto para birimidir. İnternette kullanabileceğiniz sınırlı dijital paradır - Bitcoin'e benzer. Kriptoda yeniyseniz, işte ETH'nin geleneksel paradan farkı.",
  "page-eth-earn-interest-link": "Faiz kazanın",
  "page-eth-ethhub-caption": "Sık güncellendi",
  "page-eth-ethhub-overview": "İsterseniz EthHub harika bir genel bakışa sahip",
  "page-eth-flexible-amounts": "Esnek miktarlarda mevcuttur",
  "page-eth-flexible-amounts-desc": "ETH, 18 ondalık basamağa bölünebilir, bu nedenle 1 tam ETH satın almanıza gerek kalmaz. Tek seferde azar azar satın alabilirsiniz - isterseniz 0.000000000000000001 ETH bile alabilirsiniz.",
  "page-eth-fuels": "ETH, Ethereum'u besler ve güvence altına alır",
  "page-eth-fuels-desc": "ETH, Ethereum'un can damarıdır. ETH gönderdiğinizde veya bir Ethereum uygulaması kullandığınızda, Ethereum ağını kullanmak için ETH'de küçük bir komisyon ödersiniz. Bu ücret, bir madencinin ne yapmaya çalıştığınızı işlemesi ve doğrulaması için bir teşviktir.",
  "page-eth-fuels-desc-2": "Madenciler, Ethereum'un rekor sahipleri gibidir - kimsenin hile yapmadığını kontrol eder ve kanıtlarlar. Bu işi yapan madenciler ayrıca küçük miktarlarda yeni çıkarılan ETH ile ödüllendiriliyor.",
  "page-eth-fuels-desc-3": "Madencilerin yaptığı iş, Ethereum'u güvende ve merkezi kontrolden uzak tutar. Diğer bir deyişle,",
  "page-eth-fuels-more-staking": "Stake etme hakkında daha fazla bilgi",
  "page-eth-fuels-staking": "ETH, stake etme ile daha da önemli hale gelecektir. ETH'nizi stake ettiğinizde, Ethereum'u güvence altına almaya ve ödüller kazanmaya yardımcı olacaksınız. Bu sistemde, ETH'nizi kaybetme tehdidi, saldırıları caydırır.",
  "page-eth-get-eth-btn": "ETH edinin",
  "page-eth-gov-tokens": "Yönetim Token'ları",
  "page-eth-gov-tokens-desc": "Merkezi olmayan kuruluşlarda oylama gücünü temsil eden token'lar.",
  "page-eth-has-value": "ETH'nin neden değeri var?",
  "page-eth-has-value-desc": "ETH, farklı insanlar için farklı şekillerde değerlidir.",
  "page-eth-has-value-desc-2": "Ethereum kullanıcıları için ETH, işlem ücretlerini ödemenizi sağladığı için değerlidir.",
  "page-eth-has-value-desc-3": "Diğerleri bunu dijital bir değer deposu olarak görüyor çünkü yeni ETH'nin yaratılması zamanla yavaşlıyor.",
  "page-eth-has-value-desc-4": "Yakın zamanda ETH, Ethereum'daki finansal uygulamaların kullanıcıları için değerli hale geldi. Bunun nedeni, ETH'yi kripto kredileri için teminat olarak veya bir ödeme sistemi olarak kullanabilmenizdir.",
  "page-eth-has-value-desc-5": "Elbette çoğu kişi bunu Bitcoin veya diğer kripto para birimlerine benzer bir yatırım olarak görüyor.",
  "page-eth-how-to-buy": "Ether nasıl alınır",
  "page-eth-how-to-buy-caption": "Sık güncellendi",
  "page-eth-is-money": "ETH dijital, evrensel paradır.",
  "page-eth-last-updated": "Ocak 2019",
  "page-eth-mining-link": "Madencilik hakkında daha fazla bilgi",
  "page-eth-monetary-policy": "Ethereum'un para politikası",
  "page-eth-more-on-ethereum-link": "Ethereum hakkında daha fazla bilgi",
  "page-eth-no-centralized": "Merkezi kontrol yok ",
  "page-eth-no-centralized-desc": "ETH, merkezi değildir ve evrenseldir. Daha fazla ETH basmaya veya kullanım şartlarını değiştirmeye karar verebilecek hiçbir şirket veya banka yoktur.",
  "page-eth-non-fungible-tokens-link": "Değiştirilemez tokenler",
  "page-eth-not-only-crypto": "ETH, Ethereum'daki tek kripto değildir",
  "page-eth-not-only-crypto-desc": "Herkes yeni tür varlıklar oluşturabilir ve bunları Ethereum'da takas edebilir. Bunlar 'token'lar' olarak bilinir. İnsanlar token'lı geleneksel para birimlerine, gayrimenkullere, sanatlara ve hatta kendilerine bile sahiptirler!",
  "page-eth-not-only-crypto-desc-2": "Ethereum, bazıları diğerlerinden daha kullanışlı ve değerli olan binlerce token'a ev sahipliği yapmaktadır. Geliştiriciler, sürekli olarak yeni olasılıkların kilidini açan ve yeni pazarlar açan yeni token'lar oluşturuyorlar.",
  "page-eth-not-only-crypto-desc-3": "Token'lar hakkında daha fazla bilgi edinmek isterseniz, EthHub'daki arkadaşlarımız birkaç harika makale yazdı:",
  "page-eth-open": "Herkese açık",
  "page-eth-open-desc": "ETH'yi kabul etmek için yalnızca bir internet bağlantısına ve bir cüzdana ihtiyacınız var. Ödemeleri kabul etmek için bir banka hesabına erişmeniz gerekmez. ",
  "page-eth-p2p-payments": "Eşten eşe ödemeler",
  "page-eth-p2p-payments-desc": "ETH'nizi banka gibi herhangi bir aracılık hizmeti olmadan gönderebilirsiniz. Bu, şahsen nakit vermek gibidir, ancak bunu herhangi bir yerde, herhangi bir zamanda, herhangi biriyle güvenli bir şekilde yapabilirsiniz.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Popüler token türleri",
  "page-eth-powers-ethereum": "ETH, Ethereum'a güç veriyor",
  "page-eth-shit-coins": "Değersiz para",
  "page-eth-shit-coins-desc": "Yeni token'ları yapmak kolay olduğu için, bunu herkes yapabilir - kötü veya yanlış niyetli kişiler bile. Kullanmadan önce mutlaka araştırmanızı yapın!",
  "page-eth-stablecoins": "Sabit coin'ler",
  "page-eth-stablecoins-desc": "Dolar gibi geleneksel para biriminin değerini yansıtan token'lar. Bu, birçok kripto para birimindeki dalgalanma sorununu çözer.",
  "page-eth-stablecoins-link": "Sabit coin alın",
  "page-eth-stream-link": "ETH Akışı",
  "page-eth-tokens-link": "Ethereum token'ları",
  "page-eth-trade-link-2": "Token'ları takas edin",
  "page-eth-underpins": "ETH, Ethereum finansal sisteminin temelini oluşturuyor",
  "page-eth-underpins-desc": "Ödemelerden memnun olmayan Ethereum topluluğu, eşler arası ve herkes tarafından erişilebilir olan bütün bir finansal sistem inşa ediyor.",
  "page-eth-underpins-desc-2": "Ethereum'da tamamen farklı kripto para belirteçleri oluşturmak için ETH'yi teminat olarak kullanabilirsiniz. Ayrıca, ETH ve diğer ETH destekli jetonları ödünç alabilir, ödünç verebilir ve faiz kazanabilirsiniz.",
  "page-eth-uses": "ETH kullanımları her gün artıyor",
  "page-eth-uses-desc": "Ethereum programlanabilir olduğundan, geliştiriciler ETH'yi sayısız şekilde şekillendirebilir.",
  "page-eth-uses-desc-2": "2015 yılında tek yapabileceğiniz bir Ethereum hesabından diğerine ETH göndermekti. İşte bugün yapabileceğiniz bazı şeyler.",
  "page-eth-uses-desc-3": "birisine ödeme yapın veya gerçek zamanlı olarak para alın.",
  "page-eth-uses-desc-4": "ETH'yi Bitcoin dahil diğer token'larla takas edebilirsiniz.",
  "page-eth-uses-desc-5": "ETH ve diğer Ethereum tabanlı token'larda.",
  "page-eth-uses-desc-6": "sabit, daha az değişken bir değere sahip kripto para dünyasına erişin.",
  "page-eth-value": "Eter neden değerlidir",
  "page-eth-video-alt": "ETH glif videosu",
  "page-eth-whats-eth": "Ether (ETH) nedir?",
  "page-eth-whats-eth-hero-alt": "Büyülenmiş biçimde bir eter (ETH) glifine hayranlık duyan bir grup insan görseli",
  "page-eth-whats-eth-meta-desc": "ETH'yi ve Ethereum'daki yerini anlamak için bilmeniz gerekenler.",
  "page-eth-whats-eth-meta-title": "Ether (ETH) nedir?",
  "page-eth-whats-ethereum": "Ethereum nedir?",
  "page-eth-whats-ethereum-desc": "ETH'nin arkasındaki teknoloji olan Ethereum hakkında daha fazla bilgi edinmek isterseniz, tanıtımımıza göz atın.",
  "page-eth-whats-unique": "ETH hakkında benzersiz olan nedir?",
  "page-eth-whats-unique-desc": "Ethereum'da birçok kripto para birimi ve çok sayıda başka token var, ancak yalnızca ETH'nin yapabileceği bazı şeyler var.",
  "page-eth-where-to-buy": "ETH nereden alınır",
  "page-eth-where-to-buy-desc": "ETH'yi bir borsadan veya cüzdandan alabilirsiniz, ancak farklı ülkelerin farklı politikaları vardır. ETH satın almanıza izin verecek hizmetleri görmek için kontrol edin.",
  "page-eth-yours": "Gerçekten senin",
  "page-eth-yours-desc": "ETH, kendi bankanız olmanıza izin verir. Sahiplik kanıtı olarak cüzdanınızla kendi paranızı kontrol edebilirsiniz - üçüncü şahıslara gerek yoktur.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Eth2 para yatırma sözleşmesi adresi",
  "page-eth2-deposit-contract-address-caption": "Adresin okunmasını kolaylaştırmak için boşluklar ekledik",
  "page-eth2-deposit-contract-address-check-btn": "Para yatırma sözleşmesi adresini kontrol edin",
  "page-eth2-deposit-contract-checkbox1": "Eth2 doğrulayıcımı kurmak için zaten launchpadi kullandım.",
  "page-eth2-deposit-contract-checkbox2": "Stake yapmak için launchpad'i kullanmam gerektiğini anlıyorum. Bu adreste basit transferler çalışmaz.",
  "page-eth2-deposit-contract-checkbox3": "Para yatırma sözleşmesi adresini diğer kaynaklarla kontrol edeceğim.",
  "page-eth2-deposit-contract-confirm-address": "Adresi göstermek için onaylayın",
  "page-eth2-deposit-contract-copied": "Adres kopyalandı",
  "page-eth2-deposit-contract-copy": "Adresi kopyala",
  "page-eth2-deposit-contract-etherscan": "Etherscan' de sözleşmeyi görüntüle",
  "page-eth2-deposit-contract-h2": "Burası stake ettiğin yer değil",
  "page-eth2-deposit-contract-launchpad": "Launchpad' i kullanarak stake edin",
  "page-eth2-deposit-contract-launchpad-2": "Launchpad kullanın",
  "page-eth2-deposit-contract-meta-desc": "Eth2 stake etmek için para yatırma sözleşmesi adresini doğrulayın.",
  "page-eth2-deposit-contract-meta-title": "Eth2 para yatırma sözleşmesi adresi",
  "page-eth2-deposit-contract-read-aloud": "Adresi yüksek sesle okuyun",
  "page-eth2-deposit-contract-reveal-address-btn": "Adresi gösterin",
  "page-eth2-deposit-contract-staking": " ETH'nizi Eth2'ye yatırmak için özel launchpad ürününü kullanmalı ve talimatları izlemelisiniz. Bu sayfadaki adrese ETH göndermek sizi staker yapmaz ve işlemin başarısız olmasına neden olur.",
  "page-eth2-deposit-contract-staking-check": "Bu kaynakları kontrol edin",
  "page-eth2-deposit-contract-staking-check-desc": "Çok sayıda sahte adres ve dolandırıcılık olacağını tahmin ediyoruz. Güvende olmak için, kullandığınız Eth2 staking adresini bu sayfadaki adresle karşılaştırarak kontrol edin. Diğer güvenilir kaynaklardan da kontrol etmenizi öneririz.",
  "page-eth2-deposit-contract-staking-more-link": "Stake etme hakkında daha fazlası",
  "page-eth2-deposit-contract-stop-reading": "Okumayı durdurun",
  "page-eth2-deposit-contract-subtitle": " Bu, Eth2 stake sözleşmesinin adresidir. Bahis yatırdığınızda doğru adrese para gönderdiğinizden emin olmak için bu sayfayı kullanın.",
  "page-eth2-deposit-contract-warning": "Her bir karakteri dikkatlice kontrol edin.",
  "page-eth2-deposit-contract-warning-2": "Bu adrese para göndermek işe yaramayacak ve sizi staker yapmayacaktır. Launchpad talimatlarını takip etmelisiniz.",
  "page-eth2-bug-bounty-annotated-specs": "açıklamalı spesifikasyon",
  "page-eth2-bug-bounty-annotations": "Aşağıdaki ek açıklamaları kontrol etmek faydalı olabilir:",
  "page-eth2-bug-bounty-client-bugs": "Eth2 istemci hataları",
  "page-eth2-bug-bounty-client-bugs-desc": "Yükseltme uygulandıktan sonra istemciler işaret zincirini çalıştıracaktır. İstemcilerin, şartnamede belirtilen mantığı izlemesi ve olası saldırılara karşı güvende olması gerekir. Bulmak istediğimiz hatalar, protokolün uygulanmasıyla ilgilidir.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Şu anda Lighthouse, Nimbus, Teku ve Prysm hataları şu anda bu ödül için uygundur. Denetimleri tamamladıkça ve üretime hazır hale geldikçe daha fazla istemci eklenebilir.",
  "page-eth2-bug-bounty-clients": "Ödüllerde yer alan istemciler",
  "page-eth2-bug-bounty-clients-type-1": "uyumsuzluk sorunlarını belirtmek.",
  "page-eth2-bug-bounty-clients-type-2": "beklenmeyen çökmeler veya hizmet reddi (DOS) güvenlik açıkları.",
  "page-eth2-bug-bounty-clients-type-3": "onarılamaz fikir birliğine neden olan herhangi bir sorun, ağın geri kalanından ayrılır.",
  "page-eth2-bug-bounty-docking": "kenetlenme",
  "page-eth2-bug-bounty-email-us": "Bize e-posta gönderin:",
  "page-eth2-bug-bounty-help-links": "Yardımcı bağlantılar",
  "page-eth2-bug-bounty-hunting": "Hata yakalama kuralları",
  "page-eth2-bug-bounty-hunting-desc": "Hata ödül programı, aktif Ethereum topluluğumuzun platformu iyileştirmeye yardımcı olanları teşvik etmek ve ödüllendirmek için deneysel ve isteğe bağlı bir ödül programıdır. Bu bir yarışma değil. Programı istediğimiz zaman iptal edebileceğimizi ve ödüllerin tamamen Ethereum Vakfı hata ödül panelinin takdirine bağlı olduğunu bilmelisiniz. Ayrıca, yaptırım listelerinde bulunan veya yaptırım listelerinde yer alan ülkelerde bulunan kişilere (örneğin Kuzey Kore, İran vb.) ödül veremiyoruz. Tüm vergilerden siz sorumlusunuz. Tüm ödüller geçerli yasaya tabidir. Son olarak, testiniz herhangi bir yasayı ihlal etmemeli veya size ait olmayan hiçbir veriyi tehlikeye atmamalıdır.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Hata yakalama skor tablosu",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Skor tablosuna eklenmek için Eth2 hatalarını bulun",
  "page-eth2-bug-bounty-hunting-li-1": "Halihazırda başka bir kullanıcı tarafından gönderilmiş olan veya teknik özellikleri ve müşteri geliştiricileri tarafından zaten bilinen sorunlar prim ödülleri için uygun değildir.",
  "page-eth2-bug-bounty-hunting-li-2": "Bir güvenlik açığının kamuya açıklanması, onu prim için uygun hale getirmez.",
  "page-eth2-bug-bounty-hunting-li-3": "Ethereum Vakfı araştırmacıları ve Eth2 müşteri ekiplerinin çalışanları ödül almaya uygun değildir.",
  "page-eth2-bug-bounty-hunting-li-4": "Ethereum prim programı, ödüllerin belirlenmesinde bir dizi değişkeni dikkate alır. Uygunluk, puan ve bir ödülle ilgili tüm şartların belirlenmesi, Ethereum Vakfı hata prim panelinin yegane ve nihai takdirine bağlıdır.",
  "page-eth2-bug-bounty-leaderboard": "Skor tablosunun tamamını görün",
  "page-eth2-bug-bounty-leaderboard-points": "puanlar",
  "page-eth2-bug-bounty-ledger-desc": "İşaret zinciri spesifikasyonu, tasarım mantığını ve işaret zinciri yükseltmesi aracılığıyla Ethereum'a önerilen değişiklikleri ayrıntılarıyla açıklar.",
  "page-eth2-bug-bounty-ledger-title": "İşaret zinciri spesifikasyon hataları",
  "page-eth2-bug-bounty-meta-description": "Eth2 hata yakalama programına genel bir bakış: nasıl dahil olunur ve ödül bilgisi.",
  "page-eth2-bug-bounty-meta-title": "Eth2 hata yakalama prim programı",
  "page-eth2-bug-bounty-not-included": "Dahil değil",
  "page-eth2-bug-bounty-not-included-desc": "Parça zinciri ve yerleştirme yükseltmeleri hala aktif geliştirme aşamasındadır ve bu nedenle bu prim programının bir parçası olarak henüz dahil edilmemiştir.",
  "page-eth2-bug-bounty-owasp": "OWASP yöntemini görüntüle",
  "page-eth2-bug-bounty-points": "Ethereum Vakfı ayrıca aşağıdakilere dayalı olarak puanlar verecektir:",
  "page-eth2-bug-bounty-points-error": "Veriler yüklenirken hata oluştu... lütfen yenileyin.",
  "page-eth2-bug-bounty-points-exchange": "Puan Takası",
  "page-eth2-bug-bounty-points-loading": "Veri yükleniyor...",
  "page-eth2-bug-bounty-points-payout-desc": "Ethereum Vakfı, USD'nin değerini ETH veya DAI olarak ödeyecektir.",
  "page-eth2-bug-bounty-points-point": "1 puan",
  "page-eth2-bug-bounty-points-rights-desc": "Ethereum Vakfı bunu önceden haber vermeksizin değiştirme hakkını saklı tutar.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Açıklama kalitesi",
  "page-eth2-bug-bounty-quality-desc": "Açık, iyi yazılmış gönderimler için daha yüksek ödüller ödenir.",
  "page-eth2-bug-bounty-quality-fix": "Düzeltme kalitesi",
  "page-eth2-bug-bounty-quality-fix-desc": ", dahilse: Sorunun nasıl çözüleceğine dair net bir açıklama içeren gönderimler için daha yüksek ödüller ödenir.",
  "page-eth2-bug-bounty-quality-repro": "Tekrarlanabilirlik kalitesi",
  "page-eth2-bug-bounty-quality-repro-desc": ": Lütfen test kodunu, komut dosyalarını ve ayrıntılı talimatları ekleyin. Güvenlik açığını yeniden üretmek ve doğrulamak bizim için ne kadar kolay olursa, ödül o kadar yüksek olur.",
  "page-eth2-bug-bounty-questions": "Sorular?",
  "page-eth2-bug-bounty-rules": "Kuralları okuyun",
  "page-eth2-bug-bounty-shard-chains": "parça zincirleri (shard chains)",
  "page-eth2-bug-bounty-slogan": "Eth2 hata primleri",
  "page-eth2-bug-bounty-specs": "Şartnamenin tamamını okuyun",
  "page-eth2-bug-bounty-specs-docs": "Şartname belgeleri",
  "page-eth2-bug-bounty-submit": "Hata bildir",
  "page-eth2-bug-bounty-submit-desc": "Her hata için puan kazanırsınız. Kazandığınız puanlar, hatanın ciddiyetine bağlıdır. Ethereum Vakfı (EF), OWASP yöntemini kullanarak ciddiyeti belirler.",
  "page-eth2-bug-bounty-subtitle": "Eth2 protokolünü ve istemci hatalarını bularak 50.000 $ 'a kadar USD ve skor tablosunda bir yer kazanın.",
  "page-eth2-bug-bounty-title": "Gönderilere açık",
  "page-eth2-bug-bounty-title-1": "İşaret Zinciri",
  "page-eth2-bug-bounty-title-2": "Fork seçimi",
  "page-eth2-bug-bounty-title-3": "Solidity depozito sözleşmesi",
  "page-eth2-bug-bounty-title-4": "Eşler arası ağ (P2P) iletişimi",
  "page-eth2-bug-bounty-type-1": "güvenlik/kesinliği bozan hatalar.",
  "page-eth2-bug-bounty-type-2": "hizmet reddi(DOS) vektörleri",
  "page-eth2-bug-bounty-type-3": "varsayımlardaki tutarsızlıklar, dürüst doğrulayıcıların kesilebileceği durumlar gibi.",
  "page-eth2-bug-bounty-type-4": "hesaplama veya parametre tutarsızlıkları.",
  "page-eth2-bug-bounty-types": "Hata türleri",
  "page-eth2-bug-bounty-validity": "Geçerli hatalar",
  "page-eth2-bug-bounty-validity-desc": "Bu hata prim programı, temel Eth2 Beacon Chain spesifikasyonunda ve Prysm, Lighthouse ve Teku istemci uygulamalarında hataları bulmaya odaklanmıştır.",
  "page-eth2-bug-bountycard-critical": "Kritik",
  "page-eth2-bug-bountycard-critical-risk": "Kritik risk hatası bildirin",
  "page-eth2-bug-bountycard-h2": "Orta",
  "page-eth2-bug-bountycard-high": "Yüksek",
  "page-eth2-bug-bountycard-high-risk": "Yüksek risk hatası bildirin",
  "page-eth2-bug-bountycard-label-1": "1,000 puana kadar",
  "page-eth2-bug-bountycard-label-2": "2,000 DAI'ya kadar",
  "page-eth2-bug-bountycard-label-3": "5,000 puana kadar",
  "page-eth2-bug-bountycard-label-4": "10,000 DAI'ya kadar",
  "page-eth2-bug-bountycard-label-5": "10,000 puana kadar",
  "page-eth2-bug-bountycard-label-6": "20,000 DAI'ya kadar",
  "page-eth2-bug-bountycard-label-7": "25,000 puana kadar",
  "page-eth2-bug-bountycard-label-8": "50,000 DAI'ya kadar",
  "page-eth2-bug-bountycard-li-1": "Düşük etki, orta olasılık",
  "page-eth2-bug-bountycard-li-2": "Orta etki, düşük olasılık",
  "page-eth2-bug-bountycard-li-3": "Yüksek etki, düşük olasılık",
  "page-eth2-bug-bountycard-li-4": "Orta etki, orta olasılık",
  "page-eth2-bug-bountycard-li-5": "Düşük etki, yüksek olasılık",
  "page-eth2-bug-bountycard-li-6": "Yüksek etki, orta olasılık",
  "page-eth2-bug-bountycard-li-7": "Orta etki, yüksek olasılık",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Düşük",
  "page-eth2-bug-bountycard-low-risk": "Düşük risk hatası bildirin",
  "page-eth2-bug-bountycard-medium-risk": "Orta risk hatası bildirin",
  "page-eth2-bug-bountycard-subheader": "Önem Derecesi",
  "page-eth2-bug-bountycard-subheader-2": "Örnek",
  "page-eth2-bug-bountycard-text": "Saldırgan bazen bir düğümü, doğrulayıcı tarafından yapılan her yüz onaydan birini bırakmasına neden olacak bir duruma getirebilir",
  "page-eth2-bug-bountycard-text-1": "Saldırgan, önde gelen 4 sıfır bayt ile eş kimlikli düğümlerde tutulma saldırılarını başarıyla gerçekleştirebilir",
  "page-eth2-bug-bountycard-text-2": "İki istemci arasında bir fikir birliği hatası vardır, ancak saldırganın olayı tetiklemesi zor veya pratik olmayan bir şey değildir.",
  "page-eth2-bug-bountycard-text-3": "İki istemci arasında bir fikir birliği hatası vardır ve bir saldırganın olayı tetiklemesi önemsizdir.",
  "page-eth2-get-involved-btn-1": "İstemcilere göz atın",
  "page-eth2-get-involved-btn-2": "Stake etme hakkında daha fazlası",
  "page-eth2-get-involved-btn-3": "Hataları bulun",
  "page-eth2-get-involved-bug": "Bir hata şöyle olabilir:",
  "page-eth2-get-involved-bug-hunting": "Hata avına çıkın",
  "page-eth2-get-involved-bug-hunting-desc": "Eth2 yükseltme özelliklerinde veya istemcilerin kendilerindeki hataları bulun ve bildirin. 50.000 USD'ye kadar para kazanabilir ve liderlik tablosunda bir yer kazanabilirsiniz.",
  "page-eth2-get-involved-bug-li": "şartname uyumsuzluk sorunları",
  "page-eth2-get-involved-bug-li-2": "kesinliği bozan hatalar",
  "page-eth2-get-involved-bug-li-3": "hizmet reddi(DOS) vektörleri",
  "page-eth2-get-involved-bug-li-4": "ve daha fazlası...",
  "page-eth2-get-involved-date": "Kapanış tarihi: 23 Aralık 2020",
  "page-eth2-get-involved-desc-1": "Bir istemci çalıştırmak, Ethereum'da aktif bir katılımcı olacağınız anlamına gelir. İstemciniz, işlemleri takip etmenize ve yeni blokları kontrol etmenize yardımcı olacaktır.",
  "page-eth2-get-involved-desc-2": "Eğer ETH'niz varsa, bunu bir doğrulayıcı olmak ve ağın güvenliğini sağlamak için riske atabilirsiniz. Bir doğrulayıcı olarak ETH ödülleri kazanabilirsiniz.",
  "page-eth2-get-involved-desc-3": "Topluluğun test çabasına katılın! Gönderilmeden önce Eth2 yükseltmelerini test etmeye yardımcı olun, hataları bulun ve ödüller kazanın.",
  "page-eth2-get-involved-ethresearch-1": "Sharding",
  "page-eth2-get-involved-ethresearch-2": "Eth1'den Eth2'ye geçiş",
  "page-eth2-get-involved-ethresearch-3": "Parçalar ve toplu işlem yürütme",
  "page-eth2-get-involved-ethresearch-4": "Tüm araştırma konuları",
  "page-eth2-get-involved-grants": "Stake topluluk hibeleri",
  "page-eth2-get-involved-grants-desc": "Stake topluluğu için araçlar ve eğitim içeriği oluşturmaya yardımcı olun",
  "page-eth2-get-involved-how": "Nasıl dahil olmak istersiniz?",
  "page-eth2-get-involved-how-desc": "Ethereum topluluğu, her zaman istemci çalıştıran, stake eden ve hata avcılığından faydalanan daha fazla kişiden yararlanacaktır.",
  "page-eth2-get-involved-join": "Araştırmaya katılın",
  "page-eth2-get-involved-join-desc": " Ethereum'daki çoğu şey gibi, araştırmaların çoğu halka açıktır.Bu, tartışmalara katılabileceğiniz veya adece Ethereum araştırmacılarının söylediklerini okuyabileceğiniz anlamına gelir.Ethresear.ch, Eth2 yükseltmelerinden daha fazlasını kapsar, ama büyük bir Eth2 odağı vardır.",
  "page-eth2-get-involved-meta-description": "Eth2'ye nasıl katılırım: düğümleri çalıştırın, stake edin, hata avlayın ve daha fazlası.",
  "page-eth2-get-involved-more": "Daha fazla bilgi",
  "page-eth2-get-involved-run-clients": "İşaret zinciri istemcilerini çalıştırın",
  "page-eth2-get-involved-run-clients-desc": "Ethereum'un uzun vadeli güvenliğinin anahtarı, güçlü bir istemci dağıtımıdır. İstemci, blok zincirini çalıştıran, işlemleri kontrol eden ve yeni blokların oluşturulmasını sağlayan bir yazılımdır. Her istemcinin kendi özellikleri vardır, bu yüzden içinize sinen birini seçin.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "EHT'nizi stake edin",
  "page-eth2-get-involved-stake-desc": "İşaret zincirinin güvenliğini sağlamak için artık ETH'nizi stake edebilirsiniz.",
  "page-eth2-get-involved-stake-eth": "ETH stake etme",
  "page-eth2-get-involved-subtitle": "Ethereum ve gelecekteki Eth2 ile ilgili çabalara yardımcı olabileceğiniz tüm yollar burada.",
  "page-eth2-get-involved-title-1": "İstemci çalıştır",
  "page-eth2-get-involved-title-2": "EHT'nizi stake edin",
  "page-eth2-get-involved-title-3": "Hataları bulun",
  "page-eth2-get-involved-written-go": "Go'da yazıldı",
  "page-eth2-get-involved-written-java": "Java'da yazıldı",
  "page-eth2-get-involved-written-javascript": "JavaScript'te yazıldı",
  "page-eth2-get-involved-written-net": ".NET'te yazıldı",
  "page-eth2-get-involved-written-nim": "Nim'de yazıldı",
  "page-eth2-get-involved-written-python": "Python'da yazıldı",
  "page-eth2-get-involved-written-rust": "Rust'ta yazıldı",
  "eth2-client-cortex-logo-alt": "Cortex logosu",
  "eth2-client-lighthouse-logo-alt": "Lighthouse logosu",
  "eth2-client-lodestar-logo-alt": "Lodestar logo",
  "eth2-client-nimbus-logo-alt": "Nimbus logosu",
  "eth2-client-prysm-logo-alt": "Prysm logosu",
  "eth2-client-teku-logo-alt": "Teku logosu",
  "eth2-rhino-img-alt": "İleride lazım olacak Eth2 çalışma grafiği",
  "page-eth2-answer-1": "Eth2' yi bugün kullanmış olduğumuz Ethereum' un bir dizi yükseltmesi olarak düşünebiliriz. Bu yükseltmeler beacon chain adı verilen yeni bir chain ve shards diye bilinen 64'e kadar chain oluşturmayı kapsar.",
  "page-eth2-answer-2": "Bunlar, bugün kullandığımız Ethereum ana ağından ayrıdır ve onun yerini almaz.  Bunun yerine ana ağ, zamanla eklenen bu paralel sistemle dock edecek veya \"birleşecektir\".",
  "page-eth2-answer-4": "Başka bir deyişle, bugün kullandığımız Ethereum, eninde sonunda Eth2 vizyonunda hedeflediğimiz tüm özellikleri bünyesinde barındıracaktır.",
  "page-eth2-beacon-chain-btn": "Beacon Zinciri hakkında daha fazla bilgi",
  "page-eth2-beacon-chain-date": "Beacon Zinciri 1 Aralık 2020'de yayına girdi.",
  "page-eth2-beacon-chain-desc": "Ilk Eth2 ekosisteme ekleniyor. Beacon Zinciri, Ethereum'a destek getiriyor, gelecekteki yükseltmeler için zemin hazırlıyor ve sonunda yeni sistemi koordine edecek.",
  "page-eth2-beacon-chain-estimate": "Beacon Zinciri aktif",
  "page-eth2-beacon-chain-title": "İşaret (Beacon) Zinciri",
  "page-eth2-bug-bounty": "Bug bounty programını görüntüle",
  "page-eth2-clients": "Eth2 müşterilerine göz at",
  "page-eth2-deposit-contract-title": "Para yatırma sözleşmesi adresini kontrol edin",
  "page-eth2-diagram-ethereum-mainnet": " Ethereum mainnet",
  "page-eth2-diagram-h2": "Geliştirmeler birbirine nasıl uyuyor",
  "page-eth2-diagram-link-1": "Çalışma prensibi hakkında daha fazla bilgi",
  "page-eth2-diagram-link-2": "Zincir parçaları hakkında daha fazla bilgi",
  "page-eth2-diagram-mainnet": "Mainnet",
  "page-eth2-diagram-p": "Ethereum ana ağı bir süre daha mevcut haliyle var olmaya devam edecek. Bu, Beacon Zinciri ve parça yükseltmelerinin ağı kesintiye uğratmayacağı anlamına gelir.",
  "page-eth2-diagram-p-1": "Ana Ağ, sonunda Eth2 yükseltmelerinin getirdiği yeni sistemle birleşecek.",
  "page-eth2-diagram-p-2": "Beacon Zinciri, Ethereum'un rehberi olacak, doğrulayıcıları koordine edecek ve blok oluşturma hızını belirleyecek.",
  "page-eth2-diagram-p-3": "İlk başta, ana ağdan ayrı olarak var olacak ve doğrulayıcıları yönetecek - akıllı sözleşmeler, işlemler veya hesaplarla hiçbir ilgisi olmayacak.",
  "page-eth2-diagram-p-4": "Parçalar, ana ağın üstesinden gelebileceği işlemlerin miktarını artırmaya yardımcı olmak için birçok ekstra veri sağlayacaktır. Beacon Zinciri tarafından koordine edilecekler.",
  "page-eth2-diagram-p-5": "Ancak tüm işlemler, yapılan işin kanıtı ve madenciler tarafından güvence altına alınan, bugün bildiğimiz haliyle var olmaya devam edecek olan ana ağa bağlı olacak.",
  "page-eth2-diagram-p-6": "Ana ağ, Beacon Zinciri tarafından koordine edilen hisse-kanıtı sistemiyle birleşecek.",
  "page-eth2-diagram-p-8": "Ana ağ, yeni sistem kapsamında  bir parçaya(shard) dönüşecektir. Tüm Ethereum doğrulayıcılar tarafından güvence altına alınacağından artık madencilere ihtiyaç duyulmayacak.",
  "page-eth2-diagram-p10": "Eth2 kötü bir değişim olarak gözükmemelidir. Buradaki temel amaç, Ethereum'un gerçek potansiyelini ortaya çıkarmak için üzerinde çalışılan bir dizi yükseltmeyi sağlamaktır.",
  "page-eth2-diagram-scroll": "İncelemek için kaydırın - daha fazla bilgi için tıklayın.",
  "page-eth2-diagram-shard": "Parçacık (1)",
  "page-eth2-diagram-shard-1": "Parçacık (...)",
  "page-eth2-diagram-shard-2": "Parçacık (2)",
  "page-eth2-diagram-shard-3": "Parçacık (...)",
  "page-eth2-diagram-validators": "Doğrulayıcılarla ilgili daha fazla bilgi",
  "page-eth2-dive": "Vizyona dalın",
  "page-eth2-dive-desc": "Ethereum'u nasıl daha ölçülebilir, güvenli ve sürdürülebilir yapabiliriz? Hem de Ethereum'un temel değeri olan merkeziyetsizleştirmeyi koruyarak.",
  "page-eth2-docking": "Kenetleme",
  "page-eth2-docking-answer-1": "Kenetlenme ana ağ parçaya dönüştüğünde gerçekleşir. İşaret zincirleri başarıyla uygulandıktan sonra olur.",
  "page-eth2-docking-btn": "Kenetlenme hakkında daha fazla bilgi",
  "page-eth2-docking-desc": "Mainnet Ethereum bir noktada İşaret Zinciri ile \"kenetlenecek\" veya \"birleşecektir\". Bu işlem ana ağın tamamı için stake etmeyi sağlar ve enerjisi yoğun madenciliğin sonuna işaret eder.",
  "page-eth2-docking-estimate": "Tahmin: 2022",
  "page-eth2-docking-mainnet": "Mainnet nedir?",
  "page-eth2-docking-mainnet-eth2": "Ana ağı Eth2'ye kenetlemek",
  "page-eth2-eth-blog": "ethereum.org blog",
  "page-eth2-explore-btn": "Geliştirmeleri keşfet",
  "page-eth2-get-involved": "Eth2'ye dahil olun",
  "page-eth2-get-involved-2": "Dahil olun",
  "page-eth2-head-to": "Git",
  "page-eth2-help": "Eth2 konusunda yardımcı olmak ister misin?",
  "page-eth2-help-desc": "Eth2 yükseltmelerine ağırlık vermek, testlere yardımcı olmak ve hatta ödüller kazanmak için birçok fırsat var.",
  "page-eth2-index-staking": "Stake etme (kilitleme) burada",
  "page-eth2-index-staking-desc": "Eth2 yükseltmelerinin anahtarı, stake etmenin tanıtılmasıdır. Ethereum ağının güvenliğini sağlamaya yardımcı olmak için ETH'nizi kullanmak istiyorsanız, aşağıdaki adımları uyguladığınızdan emin olun.",
  "page-eth2-index-staking-learn": "Stake etme hakkında bilgi edinin",
  "page-eth2-index-staking-learn-desc": "İşaret zinciri, Ethereum'a staking getirir. Bu sayede ETH'niz varsa, ağı güvence altına alarak herkese fayda sağlayabilir ve bu süreçte daha fazla ETH kazanabilirsiniz.",
  "page-eth2-index-staking-step-1": "1. Başlama noktasını kurun",
  "page-eth2-index-staking-step-1-btn": "Stake etme başlama noktasını ziyaret edin",
  "page-eth2-index-staking-step-1-desc": "Eth2'ye dahil olmak için başlama noktasını kullanmanız gerekir - bu işlem boyunca size yol gösterecektir.",
  "page-eth2-index-staking-step-2": "2. Stake etme adresini onaylayın",
  "page-eth2-index-staking-step-2-btn": "Para yatırma sözleşme adresini onaylayın",
  "page-eth2-index-staking-step-2-desc": "ETH'nizi riske atmadan önce, doğru adrese sahip olup olmadığınızı kontrol ettiğinizden emin olun. Bunu yapmadan önce başlatma panelinden geçmiş olmalısınız.",
  "page-eth2-index-staking-sustainability": "Daha sürdürülebilir",
  "page-eth2-just-docking": "Kenetlenme hakkında daha fazla bilgi",
  "page-eth2-meta-desc": "Ethereum 2.0 yükseltmelerine ve gerçeğe dönüştürmeyi umdukları vizyona genel bir bakış.",
  "page-eth2-meta-title": "Eth2 yükseltmeleri",
  "page-eth2-miners": "Madenciler hakkında daha fazla bilgi",
  "page-eth2-more-on-upgrades": "Eth2 yükseltmeleri hakkında daha fazla bilgi",
  "page-eth2-proof-of-stake": "hisse kanıtı",
  "page-eth2-proof-of-work": "iş kanıtı",
  "page-eth2-proof-stake-link": "Hisse kanıtı hakkında daha fazla bilgi",
  "page-eth2-question-1-desc": "Eth2, farklı alım tarihlerine sahip bir dizi farklı yükseltmedir.",
  "page-eth2-question-1-title": "Eth2 ne zaman gönderilecek?",
  "page-eth2-question-2-desc": "Eth2'yi ayrı bir blok zinciri olarak düşünmek doğru değildir.",
  "page-eth2-question-2-title": "Eth2 ayrı bir blockchain mi?",
  "page-eth2-question-3-answer-2": "Parça zinciri ve kenetleme yükseltmeleri, dapp geliştiricilerini etkileyebilir. Ancak şartnameler henüz kesinleşmedi, bu nedenle acil bir işlem yapılması gerekmiyor.",
  "page-eth2-question-3-answer-3": "ethresear.ch adresinden Eth2 araştırma ve geliştirme ekibiyle konuşun.",
  "page-eth2-question-3-answer-3-link": "Ethresear.ch ziyaret edin",
  "page-eth2-question-3-desc": "Eth2'ye hazırlanmak için şu anda herhangi bir şey yapmanız gerekmiyor.",
  "page-eth2-question-3-title": "Eth2'ye nasıl hazırlanırım?",
  "page-eth2-question-4-answer-1": "Bugün bir işlem gönderdiğinizde veya bir dapp kullandığınızda Eth1 kullanırsınız. Bu, madenciler tarafından güvence altına alınan Ethereum'dur.",
  "page-eth2-question-4-answer-2": "'Eth1' veya mainnet, kenetlenmeye kadar normal çalışmayı sürdürecektir.",
  "page-eth2-question-4-answer-3": "Kenetlemeden sonra doğrulayıcılar hisse ispatı yoluyla ağın tamamının güvenliğini sağlayacaktır.",
  "page-eth2-question-4-answer-6": "ETH'sini stake eden herkes doğrulayıcı olabilir.",
  "page-eth2-question-4-answer-7": "Stake etme hakkında daha fazla bilgi",
  "page-eth2-question-4-answer-8": "İşaret Zinciri ve parça zinciri yükseltmeleri ayrı olarak oluşturuldukları için Eth1'i bozmaz.",
  "page-eth2-question-4-desc": "Eth1 bugün üzerinde işlem yaptığınız Ethereum ana ağını ifade eder.",
  "page-eth2-question-4-title": "Eth1 nedir?",
  "page-eth2-question-5-answer-1": "Ağda tam bir doğrulayıcı olmak için 32 ETH stake etmeniz gerekir. O kadar paranız yoksa veya o kadar stake etmek istemiyorsanız, staking havuzlarına katılabilirsiniz. Bu havuzlar, daha az stake etmenizi ve toplam ödüllerin bir kısmını kazanmanızı sağlar.",
  "page-eth2-question-5-desc": "Stake başlatma panelini kullanmanız veya bir staking havuzuna katılmanız gerekecektir.",
  "page-eth2-question-5-title": "Nasıl stake ederim?",
  "page-eth2-question-6-answer-1": "Şu an için yapılacak bir işlem yoktur. Ancak, parça zincirleri ve kenetleme yükseltmeleriyle ilgili gelişmelerden haberdar olmanızı öneririz.",
  "page-eth2-question-6-answer-3": "Ethereum foundation'dan Danny Ryan, topluluğu düzenli olarak güncelliyor:",
  "page-eth2-question-6-answer-4": "ConsenSys'den Ben Edgington'ın haftalık bir Eth2 bülteni var:",
  "page-eth2-question-6-answer-5": "Ayrıca ethresear.ch adresindeki Eth2 araştırma ve geliştirme tartışmasına da katılabilirsiniz.",
  "page-eth2-question-6-desc": "Dapp'ınız yakın zamanda yapılacak herhangi bir yükseltmeden etkilenmeyecektir. Ancak gelecekteki yükseltmeler bazı değişiklikler gerektirebilir.",
  "page-eth2-question-6-title": "Dapp'ımla ne yapmam gerekiyor?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(.NET uygulaması)",
  "page-eth2-question-7-desc": "Topluluğun her yerinden birçok farklı ekip, çeşitli Eth2 yükseltmeleri üzerinde çalışıyor.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Rust uygulaması)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(JavaScript uygulaması)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Nim uygulaması)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Go uygulaması)",
  "page-eth2-question-7-teams": "Eth2 müşteri ekipleri:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Java uygulaması)",
  "page-eth2-question-7-title": "Eth2'yi kim inşa ediyor?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Python uygulaması)",
  "page-eth2-question-8-answer-1": "Eth2 yükseltmeleri, güvenliği korurken ve sürdürülebilirliği artırırken Ethereum'un merkezi olmayan bir şekilde ölçeklenmesine yardımcı olur.",
  "page-eth2-question-8-answer-2": "Muhtemelen en belirgin sorun, Ethereum'un saniyede 15-45'ten fazla işlemi gerçekleştirebilmesi gerektiğidir. Ancak Eth2 yükseltmeleri, bugün Ethereum ile ilgili diğer bazı sorunları da ele alıyor.",
  "page-eth2-question-8-answer-3": "Ağ o kadar yüksek talep görüyor ki, bu durum Ethereum'u kullanmayı pahalı hale getiriyor. Ağdaki düğümler, Ethereum'un boyutu ve bilgisayarlarının işlemek zorunda olduğu veri miktarı altında zorlanıyor. Üstelik Ethereum'u güvenli ve merkezi olmayan tutan temeldeki algoritma, enerji olarak yoğundur ve daha çevreci olması gerekir.",
  "page-eth2-question-8-answer-4": "2015'ten beri bile değişen şeylerin çoğu Ethereum yol haritasında yer almıştır. Ancak mevcut koşullar yükseltme ihtiyacını daha da artırıyor.",
  "page-eth2-question-8-answer-6": "Eth2 vizyonunu keşfet",
  "page-eth2-question-8-desc": "Bugün kullandığımız Ethereum'un son kullanıcılara ve ağ katılımcılarına daha iyi bir deneyim sunması gerekiyor.",
  "page-eth2-question-8-title": "Eth2'ye neden ihtiyaç var?",
  "page-eth2-question-9-answer-1": "Oynayabileceğiniz en aktif rol ETH'nizi stake etmektir.",
  "page-eth2-question-9-answer-2": "İstemci çeşitliliğini geliştirmeye yardımcı olması için ikinci bir istemci çalıştırmak da isteyebilirsiniz.",
  "page-eth2-question-9-answer-3": "Daha teknik biriyseniz, yeni istemcilerdeki hataları yakalamaya yardımcı olabilirsiniz.",
  "page-eth2-question-9-answer-4": "Ayrıca ethresear.ch adresindeki Ethereum araştırmacılarıyla teknik tartışmalara katılabilirsiniz.",
  "page-eth2-question-9-desc": "Katkıda bulunmak için teknik bir kişi olmanıza gerek yok. Topluluk için her türlü yeteneğin katkısı önemlidir.",
  "page-eth2-question-9-stake-eth": "ETH stake etme",
  "page-eth2-question-9-title": "Eth2'ye nasıl katkıda bulunabilirim?",
  "page-eth2-question-10-answer-1": "Teknik bir yol haritası açısından çok fazla konuşmak istemiyoruz çünkü bu bir yazılım: işler değişebilir. Sonuçları okuduğunuzda neler olduğunu anlamanın daha kolay olduğunu düşünüyoruz.",
  "page-eth2-question-10-answer-1-link": "Yükseltmeleri görüntüleyin",
  "page-eth2-question-10-answer-2": "Ancak tartışmaları takip ettiyseniz, yükseltmelerin teknik yol haritalarına nasıl uyduğunu burada görebilirsiniz.",
  "page-eth2-question-10-answer-3": "Faz 0, İşaret Zinciri'ni yayına almak için yapılan işi açıklar.",
  "page-eth2-question-10-answer-5": "Teknik yol haritalarının 1. Aşaması parça zincirlerinin uygulanmasına odaklanır.",
  "page-eth2-question-10-answer-6": "Ana ağı Eth2'ye kenetlemek, parça zincirlerini uygulamak için yapılan çalışmanın bir uzantısıdır ve Aşama 1.5 olarak anılır. Ancak bugün bildiğimiz Ethereum diğer Eth2 yükseltmeleriyle birleştiği için bu önemli bir andır. Üstelik Ethereum tamamen hisse ispatına geçtiğinde gerçekleşir.",
  "page-eth2-question-10-answer-7": "Şu anda 2. Aşamada ne olacağı belli değildir. Bu hala yoğun bir araştırma ve tartışma noktasıdır. İlk plan, parça zincirlerine ekstra işlevsellik eklemektir, ancak rollup merkezli bir yol haritası ile gerekli olmayabilir.",
  "page-eth2-question-10-answer-8": "Rollup merkezli bir yol haritası hakkında daha fazla bilgi",
  "page-eth2-question-10-desc": "Aşamalar, işin aşamalarıyla ilgilidir ve Eth2 teknik yol haritasına odaklanır.",
  "page-eth2-question-10-title": "Eth2 aşamaları nelerdir?",
  "page-eth2-question-title": "Sıkça sorulan sorular",
  "page-eth2-question3-answer-1": "Eth sahiplerinin kesinlikle bir şey yapmasına gerek yoktur. ETH'nizin değiştirilmesi veya yükseltilmesi gerekmeyecektir. Size aksini söyleyen dolandırıcılar büyük olasılıkla ortaya çıkabilir, bu yüzden dikkatli olun.",
  "page-eth2-scalable": "Daha ölçeklenebilir",
  "page-eth2-scalable-desc": "Uygulamaları daha hızlı ve daha ucuz hale getirmek için Ethereum'un saniyede 1000'lerce işlemi desteklemesi gerekir.",
  "page-eth2-secure": "Daha güvenli",
  "page-eth2-secure-desc": "Ethereum'un daha güvenli olmaya ihtiyacı var. Ethereum daha fazla benimsendikçe protokol her türlü saldırıya karşı daha güvenli hale gelmelidir.",
  "page-eth2-shard-button": "Parçacık zincirleri hakkında daha fazla bilgi",
  "page-eth2-shard-date": "İkinci yükseltme olan parça zincirlerinin sunumunun 2021 içinde yapılması bekleniyor.",
  "page-eth2-shard-desc": "Parça zincirleri, Ethereum'un işlemleri işleme ve veri depolama kapasitesini genişletecektir. Parçalar, zamanla birden fazla aşamada kullanıma sunulan daha fazla özellik kazanacaktır.",
  "page-eth2-shard-estimate": "Tahmin: 2021",
  "page-eth2-shard-lower": "Zincir parçaları hakkında daha fazla bilgi",
  "page-eth2-shard-title": "Parça zincirleri  (Shard chains)",
  "page-eth2-stay-up-to-date": "Güncel kalın",
  "page-eth2-stay-up-to-date-desc": "Eth2 yükseltmeleri üzerinde çalışan araştırmacılardan ve geliştiricilerden en son haberleri alın.",
  "page-eth2-sustainable-desc": "Ethereum daha çevreci bir teknolojiye dönüşmek zorundadır. Günümüz teknolojisi çok fazla güç ve enerji tüketiyor",
  "page-eth2-take-part": "Araştırmaya katılın",
  "page-eth2-take-part-desc": "Ethereum araştırmacıları ve meraklıları, Eth2 ile ilgili her şeyi kapsayan araştırma çalışmalarını tartışmak için burada buluşuyor.",
  "page-eth2-the-upgrades": "Eth2 yükseltmeleri",
  "page-eth2-the-upgrades-desc": "Eth2, Ethereum'un ölçeklenebilirliğini, güvenliğini ve sürdürülebilirliğini artıran bir dizi yükseltmedir. Her biri üzerinde paralel olarak çalışılsa da, ne zaman dağıtılacaklarını belirleyen beli bağımlılıkları vardır.",
  "page-eth2-unofficial-roadmap": "Bu resmi yol haritası değildir. Oradaki bilgilere dayanarak neler olup bittiğini bu şekilde görürüz. Ama bu teknolojidir ve işler bir anda değişebilir. Bu yüzden lütfen bunu bir taahhüt olarak ele almayın.",
  "page-eth2-upgrade-desc": "Bildiğimiz ve sevdiğimiz Ethereum, sadece daha ölçeklenebilir, daha güvenli ve daha sürdürülebilir..",
  "page-eth2-upgrades": "Eth2 yükseltmeleri",
  "page-eth2-upgrades-aria-label": "Eth2 yükseltme menüsü",
  "page-eth2-upgrades-beacon-chain": "İşaret (Beacon) Zinciri",
  "page-eth2-upgrades-docking": "Ana ağ ve Eth2 kenetleme",
  "page-eth2-upgrades-guide": "Eth2 yükseltme kılavuzu",
  "page-eth2-upgrades-shard-chains": "Parçacık zincirleri",
  "page-eth2-upgrading": "Ethereum'u radikal yeni zirvelere yükseltmek",
  "page-eth2-vision": "Vizyon",
  "page-eth2-vision-btn": "Eth2 vizyonu hakkında daha fazla bilgi",
  "page-eth2-vision-desc": "Ethereum'u ana akım haline getirmek ve tüm insanlığa hizmet etmek için Ethereum'u daha ölçeklenebilir, güvenli ve sürdürülebilir hale getirmeliyiz.",
  "page-eth2-vision-upper": "Eth2 vizyonu",
  "page-eth2-what-to-do": "Ne yapmanız gerekiyor?",
  "page-eth2-what-to-do-desc": "Bir dapp kullanıcısı veya ETH sahibiyseniz, hiçbir şey yapmanıza gerek yoktur. Bir geliştiriciyseniz veya stake etmeye başlamak istiyorsanız, bugün dahil olmanın yolları var.",
  "page-eth2-whats-eth2": "Eth2 nedir?",
  "page-eth2-whats-eth2-desc": "Eth2, Ethereum'u daha ölçeklenebilir, daha güvenli ve daha sürdürülebilir hale getirecek bir dizi birbirine bağlı yükseltmeyi ifade eder. Bu yükseltmeler, Ethereum ekosistemindeki birden fazla ekip tarafından oluşturuluyor.",
  "page-eth2-whats-ethereum": "Bekle, Ethereum nedir?",
  "page-eth2-whats-new": "Eth2'deki yenilikler neler?",
  "page-eth2-just-staking": "Stake etme",
  "page-eth2-staking-image-alt": "Eth2 için başlatma noktası Rhino maskot imajı.",
  "page-eth2-staking-51-desc-1": "Stake etme bir doğrulayıcı olarak ağa katılmayı daha erişilebilir hale getirir, bu nedenle ağda bugün olduğundan daha fazla doğrulayıcı olması muhtemeldir. Bu sayede bir saldırının maliyeti artacağından, bu tür bir saldırı daha da zorlaşacaktır.",
  "page-eth2-staking-accessibility": "Daha erişilebilir",
  "page-eth2-staking-accessibility-desc": "Daha kolay donanım gereksinimleri ve 32ETH'niz yoksa havuz oluşturma fırsatı ile ağa daha fazla insan katılabilir. Bu, saldırı yüzey alanını azaltarak Ethereum'u daha merkezi olmayan ve güvenli bir hale getirir.",
  "page-eth2-staking-at-stake": "ETH'niz stake'te",
  "page-eth2-staking-at-stake-desc": "İşlemleri doğrulamak ve yeni bloklar oluşturmak için ETH'nizi stake etmeniz gerektiğinden, sistemi deneyip yanıltmaya karar verdiğinizde kaybedebilirsiniz.",
  "page-eth2-staking-benefits": "Ethereum'a staking yapmanın faydaları",
  "page-eth2-staking-check-address": "Para yatırma adresini kontrol edin",
  "page-eth2-staking-consensus": "Konsensüs mekanizmaları hakkında daha fazla bilgi",
  "page-eth2-staking-deposit-address": "Para yatırma adresini kontrol edin",
  "page-eth2-staking-deposit-address-desc": "Başlatma panelindeki kurulum talimatlarını zaten takip ettiyseniz, staking depozito sözleşmesine bir işlem göndermeniz gerektiğini bilirsiniz. Adresi çok dikkatli kontrol etmenizi öneririz. Resmi adresi ethereum.org'da ve bir dizi başka güvenilir sitede bulabilirsiniz.",
  "page-eth2-staking-desc-1": "Ağın konsensusa varmasına yardımcı olan eylemler için ödüller verilir. İşlemleri yeni bir blokta toplamak veya diğer doğrulayıcıların çalışmalarını kontrol etmek için ödüller alacaksınız, çünkü zincirin güvenli bir şekilde çalışmasını sağlayan şey budur.",
  "page-eth2-staking-desc-2": "Ağa fayda sağlayan işler için ödüller kazanabilseniz de, kötü niyetli eylemler, çevrimdışı olma ve doğrulamada başarısız olma nedeniyle ETH kaybedebilirsiniz.",
  "page-eth2-staking-desc-3": "Tam doğrulayıcı olmak için 32 ETH'ye veya bir stake havuzuna katılmak için bir miktar ETH'ye ihtiyacınız olacaktır. Ayrıca bir 'Eth1' veya ana ağ istemcisi çalıştırmanız gerekecektir. Başlatma paneli, süreç ve donanım gereksinimleri konusunda size yol gösterecektir. Alternatif olarak, bir arka uç API'si kullanabilirsiniz.",
  "page-eth2-staking-description": "Stake, doğrulama yazılımını etkinleştirmek için 32 ETH yatırma işlemidir. Doğrulayıcı olarak, verileri depolamaktan, işlemleri işlemekten ve blok zincirine yeni bloklar eklemekten siz sorumlu olursunuz. Bu, Ethereum'u herkes için güvende tutar ve bu süreçte size yeni ETH kazandırır. Proof-of-stake olarak bilinen bu süreç, İşaret Zinciri tarafından tanıtılıyor.",
  "page-eth2-staking-docked": "Kenetlenme hakkında daha fazla bilgi",
  "page-eth2-staking-dyor": "Kendi Araştırmanızı Kendiniz Yapın",
  "page-eth2-staking-dyor-desc": "Listelenen staking hizmetlerinin hiçbiri resmi onay değildir. Hangi hizmetin sizin için en iyi olabileceğini bulmak için biraz araştırma yaptığınızdan emin olun.",
  "page-eth2-staking-header-1": "Ethereum doğrulayıcısı olmak için ETH'nizi stake edin",
  "page-eth2-staking-how-much": "Ne kadar stake etmek istiyorsunuz?",
  "page-eth2-staking-how-to-stake": "Nasıl stake ederim",
  "page-eth2-staking-how-to-stake-desc": "Her şey ne kadar stake etmek istediğinize bağlı. Tam bir doğrulayıcı olmak için 32'ye ihtiyacınız olacak, ancak daha az stake etmek de mümkündür.",
  "page-eth2-staking-join": "Katılın",
  "page-eth2-staking-join-community": "Staker topluluğuna katılın",
  "page-eth2-staking-join-community-desc": "r/ethstaker, herkesin Ethereum'da staking'i tartışması için bir topluluktur - tavsiye, destek için katılın ve stake etme ile ilgili her şeyi konuşmak için katılın.",
  "page-eth2-staking-less-than": "Daha az",
  "page-eth2-staking-link-1": "Arka uç API'larını görüntüleyin",
  "page-eth2-staking-meta-description": "Ethereum staking'e genel bir bakış: riskler, ödüller, gereksinimler ve nerede yapılacağı.",
  "page-eth2-staking-meta-title": "Ethereum staking",
  "page-eth2-staking-more-sharding": "Parçalama hakkında her şey",
  "page-eth2-staking-pool": "Havuzdaki stake",
  "page-eth2-staking-pool-desc": "32'den az ETH'niz varsa, stake havuzlarına daha küçük bir stake ekleyebilirsiniz. Bazı şirketler her şeyi sizin adınıza yapabilir, böylece çevrimiçi kalma konusunda endişelenmenize gerek kalmaz. İşte kontrol etmeniz gereken bazı şirketler.",
  "page-eth2-staking-pos-explained": "Proof-of-stake açıklandı",
  "page-eth2-staking-pos-explained-desc": "Staking (kilitleme) bir Hisse İspatı sisteminde doğrulayıcı olmak için yapmanız gereken şeydir. Bu, şu anda yürürlükte olan iş kanıtı sisteminin yerini alacak bir fikir birliği mekanizmasıdır. Konsensüs mekanizmaları, Ethereum gibi blok zincirlerini güvenli ve merkeziyetsiz tutan şeydir.",
  "page-eth2-staking-pos-explained-desc-1": "Hisse ispatı ağın güvenliğini çeşitli şekillerde sağlamaya yardımcı olur:",
  "page-eth2-staking-services": "Staking (kilitleme) hizmetlerini görün",
  "page-eth2-staking-sharding": "Sharding'in kilidini açar",
  "page-eth2-staking-sharding-desc": "Sharding yalnızca bir proof-of-stake sistemiyle mümkündür. İş ispatı sisteminin parçalanması, ağı bozmak için gereken bilgi işlem gücü miktarını azaltarak kötü niyetli madencilerin parçaları kontrol etmesini kolaylaştırır. proof-of-stake olarak rastgele atanan staker'larda durum böyle değildir.",
  "page-eth2-staking-solo": "Tek başına kilitleyin ve bir doğrulayıcı çalıştırın",
  "page-eth2-staking-solo-desc": "Stake sürecine başlamak için Eth2 launchpad'i kullanmanız gerekecektir. Bu, tüm kurulum boyunca size yol gösterecektir. Stake etmenin bir kısmı, blok zincirinin yerel bir kopyası olan bir Eth2 istemcisi çalıştırır. Bunun bilgisayarınıza indirilmesi biraz zaman alabilir.",
  "page-eth2-staking-start": "Staking'i başlat",
  "page-eth2-staking-subtitle": "Staking, Ethereum ekosistemi için kamu yararına olan bir üründür. Ağın güvenliğini sağlamaya ve bu süreçte ödüller kazanmaya yardımcı olabilirsiniz.",
  "page-eth2-staking-sustainability": "Daha sürdürülebilir",
  "page-eth2-staking-sustainability-desc": "Doğrulayıcıların, bir proof-of-stake sistemine katılmak için enerji yoğun bilgisayarlara ihtiyacı yoktur - sadece bir dizüstü bilgisayar veya akıllı telefon yeterlidir. Bu, Ethereum'u çevre için daha iyi hale getirecektir.",
  "page-eth2-staking-the-beacon-chain": "Beacon Zinciri hakkında daha fazla bilgi",
  "page-eth2-staking-title-1": "Ödüller",
  "page-eth2-staking-title-2": "Riskler",
  "page-eth2-staking-title-3": "Gereksinimler",
  "page-eth2-staking-title-4": "ETH'nizi kilitleme",
  "page-eth2-staking-upgrades-li": "Proof-of-stake İşaret Zinciri tarafından yönetilir.",
  "page-eth2-staking-upgrades-li-2": "Ethereum, öngörülebilir bir gelecek için bir proof-of-stake İşaret Zinciri ve proof-of-work ana ağına sahip olacaktır. Mainnet, yıllardır kullandığımız Ethereum'dur.",
  "page-eth2-staking-upgrades-li-3": "Bu süre boyunca, staker'lar İşaret Zinciri'ne yeni bloklar ekleyecek ancak ana ağ işlemlerini işlemeyecektir.",
  "page-eth2-staking-upgrades-li-4": "Ethereum ana ağı bir parça haline geldiğinde, Ethereum tamamen bir hisse ispatı sistemine geçecektir.",
  "page-eth2-staking-upgrades-li-5": "Ancak o zaman stake'inizi çekebilirsiniz.",
  "page-eth2-staking-upgrades-title": "Hisse ispatı ve Eth2 yükseltmeleri",
  "page-eth2-staking-validators": "Daha fazla doğrulayıcı, daha fazla güvenlik",
  "page-eth2-staking-validators-desc": "Ethereum gibi bir blok zincirinde, ağın %51'ini kontrol ederseniz onu bozmak mümkündür. Örneğin, doğrulayıcıların %51'inin bakiyenizin 1 ETH değil 1.000.000 ETH okuduğunu beyan etmelerini sağlayabilirsiniz. Ancak doğrulayıcıların %51'ini kontrol etmek için sistemdeki ETH'nin %51'ine sahip olmanız gerekir – bu çok fazla!",
  "page-eth2-staking-withdrawals": "Çekimler hemen aktif olmayacaktır",
  "page-eth2-staking-withdrawals-desc": "Gelecekteki yükseltmeler dağıtılana kadar stake'inizi çekemezsiniz. Ana ağ, İşaret Zinciri sistemine bağlandıktan sonra para çekme işlemleri yapılabilir.",
  "page-eth2-vision-2014": "Hisse Kanıtını detaylandıran 2014 blog paylaşımını görüntüle",
  "page-eth2-vision-beacon-chain": "İşaret (Beacon) zinciri",
  "page-eth2-vision-beacon-chain-btn": "Beacon Zinciri hakkında daha fazla bilgi",
  "page-eth2-vision-beacon-chain-date": "Beacon Zinciri aktif",
  "page-eth2-vision-beacon-chain-desc": "Ekosisteme ilk Eth2 ilavesi. İşaret Zinciri, Ethereum'a staking'i getiriyor, gelecekteki yükseltmeler için zemin hazırlıyor ve sonunda yeni sistemi koordine edecek.",
  "page-eth2-vision-beacon-chain-upper": "İşaret (Beacon) Zinciri",
  "page-eth2-vision-desc-1": "Ethereum'un küresel bir kullanıcı tabanına daha iyi hizmet vermek için ağ tıkanıklığını azaltması ve hızları iyileştirmesi gerekiyor.",
  "page-eth2-vision-desc-2": "Ağ büyüdükçe bir düğümü çalıştırmak zorlaşır. Bu, yalnızca ağı ölçeklendirme çabalarıyla daha da zorlaşacaktır.",
  "page-eth2-vision-desc-3": "Ethereum çok fazla elektrik kullanır. Ağı güvenli tutan teknolojinin daha sürdürülebilir olması gerekir.",
  "page-eth2-vision-docking-date": "Tahmin: 2022",
  "page-eth2-vision-docking-desc": "Mainnet Ethereum'un bir noktada İşaret Zinciri ile \"bağlanması\" gerekecek. Bu, tüm ağ için stake etmeyi mümkün kılacak ve enerji yoğun madenciliğin sona erdiğinin sinyalini verecektir.",
  "page-eth2-vision-ethereum-node": "Düğümler hakkında daha fazla bilgi",
  "page-eth2-vision-explore-upgrades": "Yükseltmeleri keşfet",
  "page-eth2-vision-future": "Küresel ölçekte dijital bir gelecek",
  "page-eth2-vision-meta-desc": "Eth2 yükseltmelerinin Ethereum üzerindeki etkisine ve üstesinden gelinmesi gereken zorluklara genel bir bakış.",
  "page-eth2-vision-meta-title": "Ethereum 2.0 (Eth2) vizyonu",
  "page-eth2-vision-mining": "Madencilik hakkında daha fazlası",
  "page-eth2-vision-problems": "Günümüzün sorunları",
  "page-eth2-vision-scalability": "Ölçeklenebilirlik",
  "page-eth2-vision-scalability-desc": "Ethereum, ağdaki düğümlerin boyutunu artırmadan saniyede daha fazla işlem gerçekleştirebilmelidir. Düğümler, blok zincirini depolayan ve çalıştıran hayati ağ katılımcılarıdır. Düğüm boyutunu artırmak pratik değildir çünkü yalnızca güçlü ve pahalı bilgisayarlara sahip olanlar bunu yapabilir. Ethereum ölçeklendirme için daha fazla düğümle birlikte saniyede daha fazla işleme ihtiyaç duyar. Daha fazla düğüm, daha fazla güvenlik anlamına gelir.",
  "page-eth2-vision-scalability-desc-3": "Parça zincirleri yükseltmesi, ağın yükünü 64 yeni zincire yayacaktır. Bu, tıkanıklığı azaltarak ve mevcut saniyede 15-45 işlem sınırının ötesinde hızları artırarak Ethereum'a nefes aldıracak.",
  "page-eth2-vision-scalability-desc-4": "Üstelik daha fazla zincir olsa dahi, bu aslında doğrulayıcıların - ağın bakımını yapanların - daha az çalışmasını gerektirecektir. Doğrulayıcıların tüm Ethereum zincirini değil, yalnızca parçalarını 'çalıştırmaları' gerekir. Bu işlem düğümleri daha hafif hale getirerek Ethereum'un ölçeklenmesine ve merkeziyetsiz kalmasına izin verir.",
  "page-eth2-vision-security": "Güvenlik",
  "page-eth2-vision-security-desc": "Eth2 yükseltmeleri bir %51 saldırısı gibi koordineli saldırılara karşı Ethereum'un güvenliğini artırır. Bu saldırı bir kişinin ağın çoğunluğunu kontrol etmesi durumunda hileli değişiklikler yapmaya zorlanabileceği bir saldırı türüdür.",
  "page-eth2-vision-security-desc-3": "Proof-of-stake'e geçiş, Ethereum protokolünün saldırılara karşı daha fazla caydırıcı olduğu anlamına gelir. Bunun nedeni, hisse ispatında, ağı güvence altına alan doğrulayıcıların protokole önemli miktarda ETH kilitlemesinin gerekmesidir. Ağa saldırmaya çalışırlarsa, protokol ETH'lerini otomatik olarak yok edebilir.",
  "page-eth2-vision-security-desc-5": "Bir protokolün yapabileceği en iyi şeyin, ağı güvence altına alan varlıkları (madenciler) aksi halde kazanacakları madencilik ödüllerini kaybetmeye zorlamak olduğu iş ispatında bunu yapmak mümkün değildir. İş ispatında eşdeğer etkiyi elde etmek için protokolün, bir madencinin hile yapmaya çalışması durumunda tüm ekipmanını imha edebilmesi gerekir.",
  "page-eth2-vision-security-desc-5-link": "İş kanıtı hakkında daha fazla bilgi",
  "page-eth2-vision-security-desc-8": "Ethereum'un güvenlik modelinin de değişmesi gerekiyor zira",
  "page-eth2-vision-security-desc-10": "Staking ayrıca bir Ethereum düğümünü 'çalıştırmak' için elit donanıma yatırım yapmanız gerekmediği anlamına gelir. Bu durum, ağın merkezden uzaklaşmasını artırarak ve saldırı yüzey alanını azaltarak daha fazla insanı doğrulayıcı olmaya teşvik etmelidir.",
  "page-eth2-vision-security-staking": "ETH stake etme",
  "page-eth2-vision-security-validator": "ETH'nizi stake ederek doğrulayıcı olabilirsiniz.",
  "page-eth2-vision-shard-chains": "shard zincirleri",
  "page-eth2-vision-shard-date": "Tahmin: 2021",
  "page-eth2-vision-shard-desc-4": "Parça zincirleri, ağın yükünü 64 yeni blok zincirine yayacak. Parçalar, işlem hızını önemli ölçüde artırma potansiyeline sahiptir - saniyede 100.000'e kadar.",
  "page-eth2-vision-shard-upgrade": "Zincir parçaları hakkında daha fazla bilgi",
  "page-eth2-vision-staking-lower": "Stake etme hakkında daha fazla bilgi",
  "page-eth2-vision-subtitle": "Tüm insanlığa yardımcı olacak kadar güçlü olana dek Ethereum'u büyütün.",
  "page-eth2-vision-sustainability": "Sürdürülebilirlik",
  "page-eth2-vision-sustainability-desc-1": "Ethereum ve Bitcoin gibi diğer blok zincirlerinin madencilik nedeniyle enerji yoğun olduğu bir sır değildir.",
  "page-eth2-vision-sustainability-desc-2": "Ancak Ethereum bilgi işlem gücüyle değil, stake yoluyla ETH tarafından güvence altına alınmaya doğru ilerliyor.",
  "page-eth2-vision-sustainability-desc-3": "Staking İşaret Zinciri tarafından sunulacak olsa da, bugün kullandığımız Ethereum, Eth2 yükseltmeleriyle 'birleşmeden' veya 'kenetlenmeden' önce bir süre paralel olarak çalışacaktır. Bir sistem ETH, diğeri bilgi işlem gücü ile güvence altına alındı. Bunun nedeni, ilk başta, parça zincirlerinin hesaplarımız veya dapp'lerimiz gibi şeyleri idare edemeyecek olmasıdır. Yani madenciliği ve ana ağı öylece unutamayız.",
  "page-eth2-vision-sustainability-desc-8": "İşaret zinciri ve parça zinciri yükseltmeleri çalışmaya başladığında, ana ağı yeni sistemle kenetleme çalışmaları başlayacak. Bu, ana ağı bir parçaya dönüştürecek, böylece ETH ile güvence altına alınacak ve çok daha az enerji tüketecek.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum daha yeşil olmalıdır.",
  "page-eth2-vision-The": ".",
  "page-eth2-vision-title": "Eth2 Vizyonu",
  "page-eth2-vision-title-1": "Tıkanmış ağ",
  "page-eth2-vision-title-2": "Disk alanı",
  "page-eth2-vision-title-3": "Çok fazla enerji",
  "page-eth2-vision-trilemma-cardtext-1": "Eth2 yükseltmeleri, Ethereum'u ölçeklenebilir, güvenli ve merkeziyetsiz hale getirecek. Sharding, bir düğümü çalıştırmak ve zinciri doğrulamak için gereken gücü azaltırken, saniye başı işlemleri artırarak Ethereum'u daha ölçeklenebilir hale getirecektir. İşaret zinciri, doğrulayıcıları parçalar arasında koordine ederek Ethereum'u güvenli hale getirecektir. Staking, katılımın önündeki engeli azaltacak ve daha büyük, daha merkezi olmayan bir ağ yaratacaktır.",
  "page-eth2-vision-trilemma-cardtext-2": "Güvenli ve merkezi olmayan blok zinciri ağları, her düğümün zincir tarafından işlenen her işlemi doğrulamasını gerektirir. Bu iş miktarı, herhangi bir zamanda gerçekleşebilecek işlem sayısını sınırlar. Merkezi olmayan ve güvenli sıfatları, bugün Ethereum zincirini yansıtıyor.",
  "page-eth2-vision-trilemma-cardtext-3": "Merkezi olmayan ağlar, düğümler arasındaki işlemler hakkında bilgi göndererek çalışır - tüm ağın herhangi bir durum değişikliğini bilmesi gerekir. Merkezi olmayan bir ağda işlemleri saniye başına ölçeklendirmek güvenlik riskleri doğurur çünkü işlemler ne kadar fazlaysa, gecikme o kadar uzun ve bilgi uçuş halindeyken saldırı olasılığı o kadar yüksek olur.",
  "page-eth2-vision-trilemma-cardtext-4": "Ethereum'un düğümlerinin boyutunu ve gücünü artırmak, saniye başına işlemleri güvenli bir şekilde artırabilir, ancak donanım gereksinimi bunu kimin yapabileceğini kısıtlayabilir - bu, merkeziyetsizliği tehdit eder. Sharding ve hisse ispatının, düğüm boyutunu değil, düğüm miktarını artırarak Ethereum'un ölçeklenmesine izin vereceği umulmaktadır.",
  "page-eth2-vision-trilemma-h2": "Merkeziyetsiz ölçülebilirliğin zorluğu",
  "page-eth2-vision-trilemma-modal-tip": "Merkezi olmayan ölçeklendirmenin sorunlarını daha iyi anlamak için aşağıdaki dairelere dokunun",
  "page-eth2-vision-trilemma-p": "Ethereum'un sorunlarını çözmenin naif bir yolu, onu daha merkezi hale getirmek olacaktır. Ancak merkeziyetsizlik çok önemlidir. Ethereum sansürüne direnç, açıklık, veri gizliliği ve neredeyse aşılmaz bir güvenlik sağlayan merkeziyetsizliktir.",
  "page-eth2-vision-trilemma-p-1": "Ethereum'un vizyonu, daha ölçeklenebilir ve güvenli olmak, aynı zamanda merkeziyetsiz kalmaktır. Bu 3 niteliğin elde edilmesi, ölçeklenebilirlik üçlemi olarak bilinen bir problemdir.",
  "page-eth2-vision-trilemma-p-2": "Eth2 yükseltmeleri, üçlemeyi çözmeyi amaçlıyor ancak önemli zorluklar var.",
  "page-eth2-vision-trilemma-press-button": "Merkezi olmayan ölçeklemenin sorunlarını daha iyi anlamak için üçgen üzerindeki düğmelere basın.",
  "page-eth2-vision-trilemma-text-1": "Merkeziyetsizlik",
  "page-eth2-vision-trilemma-text-2": "Güvenlik",
  "page-eth2-vision-trilemma-text-3": "Ölçeklenebilirlik",
  "page-eth2-vision-trilemma-title-1": "Ölçeklenebilirlik üçlemini keşfetme",
  "page-eth2-vision-trilemma-title-2": "Eth2 yükseltmeleri ve merkezi olmayan ölçeklendirme",
  "page-eth2-vision-trilemma-title-3": "Güvenli ve merkeziyetsiz",
  "page-eth2-vision-trilemma-title-4": "Merkeziyetsiz ve ölçeklenebilir",
  "page-eth2-vision-trilemma-title-5": "Ölçeklenebilir ve güvenli",
  "page-eth2-vision-understanding": "Eth2 vizyonunu anlamak",
  "page-eth2-vision-upgrade-needs": "Eth2 yükseltmeleri ihtiyacı",
  "page-eth2-vision-upgrade-needs-desc": "2015 yılında başlatılan Ethereum protokolü inanılmaz bir başarı elde etti. Ancak Ethereum topluluğu, Ethereum'un tüm potansiyelini ortaya çıkarmak için her zaman birkaç önemli yükseltmenin gerekli olacağını umuyordu.",
  "page-eth2-vision-upgrade-needs-desc-2": "Yüksek talep, Ethereum'u ortalama bir kullanıcı için pahalı hale getiren işlem ücretlerini artırıyor. Bir Ethereum istemcisini çalıştırmak için gereken disk alanı hızla büyüyor. Ethereum'u güvenli ve merkezi olmayan tutan temel çalışma kanıtı konsensüs algoritmasının büyük bir çevresel etkisi vardır.",
  "page-eth2-vision-upgrade-needs-desc-3": "Bu sorunları ve daha fazlasını ele alan bir dizi yükseltme genellikle Eth2 olarak adlandırılır. Bu yükseltme seti başlangıçta 'Serenity' olarak adlandırıldı ve 2014'ten bu yana aktif bir araştırma ve geliştirme alanı oldu.",
  "page-eth2-vision-upgrade-needs-desc-6": " Bu, Eth2 için açık anahtar olmadığı anlamına gelir. İyileştirmeler zaman içinde aşamalı olarak yapılacaktır.",
  "page-eth2-vision-upgrade-needs-serenity": "'Serenity'yi tartışan bir 2015 blog gönderisini görüntüleyin",
  "page-eth2-vision-uprade-needs-desc-5": "Artık teknoloji hazır olduğuna göre, bu yükseltmeler Ethereum'u daha ölçeklenebilir, güvenli ve sürdürülebilir kılmak için yeniden yapılandıracak - mevcut kullanıcılar için hayatı güzelleştirmek ve yeni kullanıcıları cezbetmek için. Tüm bunlarla birlikte Ethereum'un temel merkezden uzak değeri korunur.",
  "page-get-eth-cex": "Merkezi borsalar",
  "page-get-eth-cex-desc": "Borsalar, geleneksel para birimleri kullanarak kripto varlık satın almanızı sağlayan işletmelerdir. Satın aldığınız her ETH üzerinde kontrol ettiğiniz bir cüzdana gönderene kadar velayet sahibidirler.",
  "page-get-eth-checkout-dapps-btn": "Dappsa göz atın",
  "page-get-eth-community-safety": "Güvenlik üzerine topluluk gönderileri",
  "page-get-eth-description": "Ethereum ve ETH herhangi bir hükümet veyahut şirket tarafından kontrol edilmemektedir, tamamen bağımsızdır. Bu ETH'nin herkesin kullanımına açık olduğu anlamına gelir.",
  "page-get-eth-dex": "Merkeziyetsiz borsalar (DEXs)",
  "page-get-eth-dex-desc": "Eğer daha fazla kontrol isterseniz eşler arası ETH satın alabilirsiniz. Bir DEX ile birlikte fonunuzun kontrolünü herhangi bir merkezi şirkete vermeden ticaret yapabilirsiniz.",
  "page-get-eth-dexs": "Merkeziyetsiz borsalar (DEXs)",
  "page-get-eth-dexs-desc": "Merkezi olmayan borsalar, ETH ve diğer tokenler için açık pazar yerleridir. Alıcıları ve satıcıları doğrudan birbirine bağlarlar.",
  "page-get-eth-dexs-desc-2": "İşlemdeki fonları korumak için güvenilir bir üçüncü taraf kullanmak yerine kod kullanırlar. Satıcının ETH'si yalnızca ödeme garanti edildiğinde aktarılacaktır. Bu kod türü akıllı sözleşme olarak bilinir.",
  "page-get-eth-dexs-desc-3": "Merkezi alternatiflere göre daha az coğrafi kısıtlama vardır. Bir kişi istediğinizi satıyorsa ve sağlayabileceğiniz bir ödeme yöntemini kabul ediyorsa, başlamaya hazırsınız. DEX'ler, diğer tokenler, PayPal ve hatta şahsen nakit ödemelerle ETH satın almanıza izin verebilir.",
  "page-get-eth-do-not-copy": "ÖRNEK: KOPYALAMAYIN",
  "page-get-eth-exchanges-disclaimer": "Bu bilgileri manuel olarak topladık. Yanlış bir şey tespit ederseniz, bize buradan bildirin",
  "page-get-eth-exchanges-empty-state-text": "ETH satın almak için kullanabileceğiniz cüzdan ve borsaların listesini görmek için ikamet ettiğiniz ülkeyi girin",
  "page-get-eth-exchanges-except": "Dışında",
  "page-get-eth-exchanges-header": "Hangi ülkede yaşamaktasınız?",
  "page-get-eth-exchanges-header-exchanges": "Borsalar",
  "page-get-eth-exchanges-header-wallets": "Cüzdanlar",
  "page-get-eth-exchanges-intro": "Borsalar ve cüzdanlar, kripto satabilecekleri yerler konusunda kısıtlamalara sahiptir.",
  "page-get-eth-exchanges-no-exchanges": "Üzgünüz, bu ülkeden ETH satın almanıza izin veren herhangi bir borsadan haberimiz yok. Bunu yaparsanız, bize bildirin",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Üzgünüz, bu ülkeden ETH satın almanıza izin veren herhangi bir borsadan haberimiz yok. Bunu yaparsanız, bize bildirin",
  "page-get-eth-exchanges-no-wallets": "Üzgünüz, bu ülkeden ETH satın almanıza izin veren herhangi bir borsadan haberimiz yok. Bunu yaparsanız, bize bildirin",
  "page-get-eth-exchanges-success-exchange": "Yasal kontrolleri nedeniyle bir borsaya kaydolmak birkaç gün sürebilir.",
  "page-get-eth-exchanges-success-wallet-link": "cüzdanlar",
  "page-get-eth-exchanges-success-wallet-paragraph": "Yaşadığınız yerde, doğrudan bu cüzdanlardan ETH satın alabilirsiniz. Hakkında daha fazla bilgi alın",
  "page-get-eth-exchanges-usa": "Amerika Birleşik Devletleri (ABD)",
  "page-get-eth-get-wallet-btn": "Bir cüzdan edin",
  "page-get-eth-hero-image-alt": "ETH hero görsel edin",
  "page-get-eth-keep-it-safe": "ETH'nizi güvende tutmak",
  "page-get-eth-meta-description": "Yaşadığınız yere göre ETH nasıl satın alınır ve bununla nasıl ilgileneceğinize dair tavsiyeler.",
  "page-get-eth-meta-title": "ETH nasıl alınır",
  "page-get-eth-need-wallet": "DEX kullanmak için cüzdanınız olmalıdır.",
  "page-get-eth-new-to-eth": "ETH'de yeni misiniz? İşte başlamanıza yardımcı olacak bir genel bakış.",
  "page-get-eth-other-cryptos": "Başka bir kripto ile satın alın",
  "page-get-eth-protect-eth-desc": "Çok fazla ETH satın almayı planlıyorsanız, bunu bir borsada değil, kontrol ettiğiniz bir cüzdanda tutmak isteyebilirsiniz. Bunun nedeni borsanın bilgisayar korsanları için olası bir hedef olmasıdır. Bilgisayar korsanı erişim kazanırsa, paranızı kaybedebilirsiniz. Alternatif olarak, cüzdanınızı yalnızca siz kontrol edersiniz.",
  "page-get-eth-protect-eth-in-wallet": "ETH'nizi bir cüzdanda koruyun",
  "page-get-eth-search-by-country": "Ülkeye göre ara",
  "page-get-eth-security": "Ancak bu aynı zamanda fonlarınızın güvenliğini ciddiye almanız gerektiği anlamına gelir. ETH ile paranızla ilgilenecek bir bankaya değil, kendinize güvenirsiniz.",
  "page-get-eth-smart-contract-link": "Akıllı kontratlar hakkında daha fazla bilgi edin",
  "page-get-eth-swapping": "Token'larınızı başkalarının ETH'siyle değiştirin. Ya da tam tersini yapın.",
  "page-get-eth-traditional-currencies": "Geleneksel para birimleriyle satın alın",
  "page-get-eth-traditional-payments": "Geleneksel ödeme türleriyle doğrudan satıcılardan ETH satın alın.",
  "page-get-eth-try-dex": "Dex deneyin",
  "page-get-eth-use-your-eth": "ETH'nizi kullanın",
  "page-get-eth-use-your-eth-dapps": "Artık bir miktar ETH'ye sahip olduğunuza göre, bazı Ethereum uygulamalarına (dapps) göz atın. Finans, sosyal medya, oyun ve diğer birçok kategori için uygulamalar var.",
  "page-get-eth-wallet-instructions": "Cüzdan talimatlarını takip edin",
  "page-get-eth-wallet-instructions-lost": "Cüzdanınıza erişiminizi kaybederseniz, fonlarınıza erişimi de kaybedersiniz. Cüzdanınız size buna karşı korunma talimatları sunmalıdır. Bunları dikkatli bir şekilde takip ettiğinizden emin olun - çoğu durumda cüzdanınıza erişiminizi kaybederseniz size kimse yardımcı olamaz.",
  "page-get-eth-wallets": "Cüzdanlar",
  "page-get-eth-wallets-link": "Cüzdanlar hakkında daha fazlası",
  "page-get-eth-wallets-purchasing": "Bazı cüzdanlar banka/kredi kartı, banka havalesi ve hatta Apple Pay ile kripto satın almanıza izin verir. Coğrafi kısıtlamalar geçerlidir.",
  "page-get-eth-warning": "Bu DEX'ler yeni başlayanlar için değil çünkü bunları kullanmak için bir miktar ETH'ye ihtiyacınız olacak.",
  "page-get-eth-what-are-DEX's": "DEX'ler nedir?",
  "page-get-eth-whats-eth-link": "ETH nedir?",
  "page-get-eth-where-to-buy-desc": "Borsalardan ya da cüzdanlardan doğrudan ETH satın alabilirsiniz.",
  "page-get-eth-where-to-buy-desc-2": "Nerede yaşadığınıza bağlı olarak kullanabileceğiniz hizmetlere bakın.",
  "page-get-eth-where-to-buy-title": "ETH nereden alınır",
  "page-get-eth-your-address": "ETH adresiniz",
  "page-get-eth-your-address-desc": "Bir cüzdan indirdiğinizde, sizin için genel bir ETH adresi oluşturacaktır. Şu şekilde görünür:",
  "page-get-eth-your-address-desc-3": "Bunu e-posta adresiniz gibi düşünün, ancak posta yerine ETH alabilir. ETH'yi bir borsadan cüzdanınıza aktarmak istiyorsanız, hedef olarak adresinizi kullanın. Göndermeden önce her zaman iki kez kontrol ettiğinizden emin olun!",
  "page-get-eth-your-address-wallet-link": "Cüzdanları kontrol edin",
  "page-index-hero-image-alt": "ethereum.org hero görsel",
  "page-index-meta-description": "Ethereum yenilikçi çalışmalar için bağımsız ve küresel bir platformdur. Ethereum ile parayı kontrol eden bir yazılım veya her yerden erişilebilen bir uygulama yaratabilirsiniz.",
  "page-index-meta-title": "Anasayfa",
  "page-index-title": "Ethereum, merkezi olmayan uygulamalar için küresel, açık kaynaklı bir platformdur.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Dil Desteği",
  "page-languages-interested": "Katkıda bulunmak ister misiniz?",
  "page-languages-learn-more": "Çeviri Programımız hakkında daha fazla bilgi edinin",
  "page-languages-meta-desc": "Ethereum.org'un desteklenen tüm dillerine yönelik kaynaklar ve çevirmen olarak katılmanın yolları.",
  "page-languages-meta-title": "ethereum.org Dil Çevirileri",
  "page-languages-p1": "Ethereum küresel bir projedir ve ethereum.org'un milliyeti veya dili ne olursa olsun herkes tarafından erişilebilir olması çok önemlidir. Topluluğumuz bu vizyonu gerçeğe dönüştürmek için sıkı çalışıyor.",
  "page-languages-translations-available": "ethereum.org aşağıdaki dillerde mevcuttur",
  "page-languages-want-more-header": "Ethereum.org'u farklı bir dilde görmek ister misiniz?",
  "page-languages-want-more-link": "Çeviri Programı",
  "page-languages-want-more-paragraph": "ethereum.org çevirmenleri sayfaları her zaman mümkün olduğu kadar çok dilde çevirir. Şu anda ne üzerinde çalıştıklarını görmek veya onlara katılmak üzere kaydolmak için ve hakkımızda okumak için",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Kripto yardımcı",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Ethereum ile, ETH'nizi takas etmeden doğrudan diğer kullanıcılardan ödünç alabilirsiniz. Bu size olanak sağlayabilir – kullanıcılar bunu daha fazla ETH biriktirmeye çalışmak için yapar.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Ancak ETH'nin fiyatı değişken olduğu için fazla teminat vermeniz gerekecektir. Dolayısıyla 100 sabit para ödünç almak istiyorsanız, muhtemelen en az 150 dolar değerinde ETH'ye ihtiyacınız olacaktır. Bu, sistemi ve borç verenleri korur.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Kripto destekli sabit coinler hakkında daha fazlası",
  "page-stablecoins-accordion-borrow-pill": "Gelişmiş",
  "page-stablecoins-accordion-borrow-places-intro": "Bu merkezi olmaya uygulamalar, sabit paraları teminat olarak kullanarak sabit para ödünç almanıza izin verir. Bazıları ETH'nin yanı sıra diğer token'ları da kabul eder.",
  "page-stablecoins-accordion-borrow-places-title": "Sabit para ödünç alabileceğiniz yerler",
  "page-stablecoins-accordion-borrow-requirement-1": "Ethereum cüzdanı",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Merkezi olmayan bir uygulama kullanmak için bir cüzdana ihtiyacınız olacaktır",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Teminat ve/veya işlem ücretleri için ETH'ye ihtiyacınız olacaktır",
  "page-stablecoins-accordion-borrow-requirements-description": "Sabit paraları ödünç almak için doğru merkezi olmayan uygulamayı kullanmanız gerekir. Ayrıca bir cüzdana ve bir miktar ETH'ye de ihtiyacınız olacaktır.",
  "page-stablecoins-accordion-borrow-risks-copy": "ETH'yi teminat olarak kullanırsanız ve değeri düşerse, teminatınız oluşturduğunuz sabit paraları kapsamaz. Bu, ETH'nizin tasfiye edilmesine neden olur ve bir ceza ile karşı karşıya kalabilirsiniz. Dolayısıyla, sabit para ödünç alırsanız ETH fiyatını izlemeniz gerekir.",
  "page-stablecoins-accordion-borrow-risks-link": "Son ETH fiyatı",
  "page-stablecoins-accordion-borrow-risks-title": "Riskler",
  "page-stablecoins-accordion-borrow-text-preview": "Teminat olarak kripto kullanarak geri ödemeniz gereken bazı sabit paraları ödünç alabilirsiniz.",
  "page-stablecoins-accordion-borrow-title": "Ödünç Al",
  "page-stablecoins-accordion-buy-exchanges-title": "Popüler borsalar",
  "page-stablecoins-accordion-buy-requirement-1": "Kripto borsaları ve cüzdanları",
  "page-stablecoins-accordion-buy-requirement-1-description": "Nerede yaşadığınıza bağlı olarak kullanabileceğiniz hizmetlere bakın",
  "page-stablecoins-accordion-buy-requirements-description": "Doğrudan kripto satın alabileceğiniz bir takas borsası veya cüzdan içeren bir hesap. Bunu bir miktar ETH almak için zaten kullanmış olabilirsiniz. Yaşadığınız yerde hangi hizmetleri kullanabileceğinizi kontrol edin.",
  "page-stablecoins-accordion-buy-text-preview": "Birçok borsa ve cüzdan, doğrudan sabit para satın almanıza izin verir. Coğrafi kısıtlamalar geçerli olacaktır.",
  "page-stablecoins-accordion-buy-title": "Satın Al",
  "page-stablecoins-accordion-buy-warning": "Merkezi borsalar yalnızca USDC, Tether ve diğerleri gibi fiat destekli sabit paraları listeleyebilir. Bunları doğrudan satın alamayabilirsiniz, ancak bunları ETH veya platformda satın alabileceğiniz diğer kripto para birimleriyle takas edebilmelisiniz.",
  "page-stablecoins-accordion-earn-project-1-description": "Açık kaynaklı yazılım hareketi için çoğunlukla teknik çalışma.",
  "page-stablecoins-accordion-earn-project-2-description": "MakerDao topluluğu (size Dai'yi getiren ekip) için teknoloji, içerik ve diğer çalışmalar.",
  "page-stablecoins-accordion-earn-project-3-description": "Konunuza gerçekten hakim olduğunuzda Dai kazanmak için hataları bulun.",
  "page-stablecoins-accordion-earn-project-bounties": "Gitcoin ödülleri",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Eth2 hata ödülleri",
  "page-stablecoins-accordion-earn-project-community": "MakerDao topluluğu",
  "page-stablecoins-accordion-earn-projects-copy": "Bunlar, çalışmanız karşılığında size sabit para olarak ödeme yapacak platformlardır.",
  "page-stablecoins-accordion-earn-projects-title": "Sabit paralar nereden kazanılır",
  "page-stablecoins-accordion-earn-requirement-1": "Ethereum cüzdanı",
  "page-stablecoins-accordion-earn-requirement-1-description": "Kazandığınız sabit paraları almak için bir cüzdana ihtiyacınız olacak",
  "page-stablecoins-accordion-earn-requirements-description": "Sabit paralar, değeri sabit olduğu için iş ve hizmetler için harika bir ödeme yöntemidir. Ancak ödeme yapmak için bir cüzdana ihtiyacınız olacak.",
  "page-stablecoins-accordion-earn-text-preview": "Ethereum ekosistemindeki projeler üzerinde çalışarak sabit para kazanabilirsiniz.",
  "page-stablecoins-accordion-earn-title": "Kazan",
  "page-stablecoins-accordion-less": "Daha az",
  "page-stablecoins-accordion-more": "Daha fazla",
  "page-stablecoins-accordion-requirements": "İhtiyacınız olanlar",
  "page-stablecoins-accordion-swap-dapp-intro": "Halihazırda ETH'niz ve bir cüzdanınız varsa, sabit paraları takas etmek için merkezi olmayan bu uygulamaları kullanabilirsiniz.",
  "page-stablecoins-accordion-swap-dapp-link": "Merkeziyetsiz borsalar hakkında daha fazlası",
  "page-stablecoins-accordion-swap-dapp-title": "Token takas etmek için merkezi olmayan uygulamalar",
  "page-stablecoins-accordion-swap-editors-tip": "Düzenleyici ipuçları",
  "page-stablecoins-accordion-swap-editors-tip-button": "Cüzdan bulun",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Kendinize ETH satın almanıza ve onu sabit paralar da dahil olmak üzere token'larla doğrudan değiştirmenize izin verecek bir cüzdan alın.",
  "page-stablecoins-accordion-swap-pill": "Önerilen",
  "page-stablecoins-accordion-swap-requirement-1": "Ethereum Cüzdanı",
  "page-stablecoins-accordion-swap-requirement-1-description": "Takas işlemine izin vermek ve paralarınızı saklamak için bir cüzdana ihtiyacınız olacak",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Takas ödemesi yapmak için",
  "page-stablecoins-accordion-swap-text-preview": "Çoğu sabit parayı merkezi olmayan borsalardan alabilirsiniz. Böylece sahip olabileceğiniz herhangi bir token'ı istediğiniz bir sabit para ile değiştirebilirsiniz.",
  "page-stablecoins-accordion-swap-title": "Takas Et",
  "page-stablecoins-algorithmic": "Algoritmik",
  "page-stablecoins-algorithmic-con-1": "Algoritmaya güvenmeniz (veya onu okuyabilmeniz) gerekir.",
  "page-stablecoins-algorithmic-con-2": "Para bakiyeniz toplam arza göre değişecektir.",
  "page-stablecoins-algorithmic-description": "Bu sabit paralar başka bir varlık tarafından desteklenmez. Bunun yerine bir algoritma, fiyat istenen değerin altına düşerse token satacak ve değer istenen miktarın üzerine çıkarsa token tedarik edecektir. Dolaşımdaki bu token'ların sayısı düzenli olarak değiştiği için sahip olduğunuz token sayısı değişecek ancak her zaman payınızı yansıtacaktır.",
  "page-stablecoins-algorithmic-pro-1": "Teminata gerek yoktur.",
  "page-stablecoins-algorithmic-pro-2": "Halka açık bir algoritmayla kontrol edilir.",
  "page-stablecoins-bank-apy": "%0.05",
  "page-stablecoins-bank-apy-source": "Bankalar tarafından temel, federal olarak sigortalı tasarruf hesaplarında ödenen ortalama oran, ABD.",
  "page-stablecoins-bank-apy-source-link": "Kaynak",
  "page-stablecoins-bitcoin-pizza": "Kötü şöhretli Bitcoin pizzası",
  "page-stablecoins-bitcoin-pizza-body": "2010 yılında bir kişi 10.000 bitcoin karşılığında 2 pizza satın aldı. O zamanlar yaklaşık değeri 41 USD değerindeydi. Bugünün piyasasında bu milyonlarca dolar. Ethereum'un tarihinde buna benzer birçok pişmanlık verici işlem var. Sabit paralar bu sorunu çözer, böylece pizzanızın tadını çıkarabilir ve ETH'nizi elinizde tutabilirsiniz.",
  "page-stablecoins-coin-price-change": "Para fiyat değişikliği (son 30 gün)",
  "page-stablecoins-crypto-backed": "Kripto destekli",
  "page-stablecoins-crypto-backed-con-1": "Fiat-destekli sabit paralardan daha az stabil.",
  "page-stablecoins-crypto-backed-con-2": "Kripto teminatının değerine dikkat etmeniz gerekir.",
  "page-stablecoins-crypto-backed-description": "Bu sabit paralar ETH gibi diğer kripto varlıkları tarafından desteklenmektedir. Fiyatları, değişken olabilen dayanak varlığın (veya teminatın) değerine bağlıdır. ETH'nin değeri dalgalanabileceğinden, bu sabit paralar, fiyatın mümkün olduğunca sabit kalmasını sağlamak için aşırı teminatlandırılmıştır. Bu, 1 dolarlık kripto destekli bir sabit paranın en az 2 dolarlık bir altta yatan kripto varlığına sahip olduğunu söylemeye yakın olduğumuz anlamına gelir. Bu nedenle, ETH fiyatı düşerse, sabit parayı desteklemek için daha fazla ETH kullanılmalıdır, aksi takdirde sabit paralar değerlerini kaybeder.",
  "page-stablecoins-crypto-backed-pro-1": "Şeffaf ve tamamen merkeziyetsiz.",
  "page-stablecoins-crypto-backed-pro-2": "Diğer kripto varlıklarına kolayca çevrilebilir.",
  "page-stablecoins-crypto-backed-pro-3": "Harici emanetçi yok - tüm varlıklar Ethereum hesapları tarafından kontrol edilir.",
  "page-stablecoins-dai-banner-body": "Dai muhtemelen en ünlü merkezi olmayan sabit paradır. Değeri kabaca bir dolardır ve merkezi olmayan uygulamalarda yaygın olarak kabul edilir.",
  "page-stablecoins-dai-banner-learn-button": "Dai hakkında bilgi alın",
  "page-stablecoins-dai-banner-swap-button": "Dai karşılığında ETH takas edin",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Dai logosu",
  "page-stablecoins-editors-choice": "Editörlerin seçimi",
  "page-stablecoins-editors-choice-intro": "Bunlar muhtemelen şu anda en iyi bilinen sabit para örnekleridir ve merkezi olmayan uygulamalar kullanırken faydalı bulduğumuz paralardır.",
  "page-stablecoins-explore-dapps": "Dapps'i keşfedin",
  "page-stablecoins-fiat-backed": "Fiat destekli",
  "page-stablecoins-fiat-backed-con-1": "Merkezi - birileri token çıkarmalıdır.",
  "page-stablecoins-fiat-backed-con-2": "Şirketin yeterli rezervlere sahip olduğundan emin olmak için denetim gerektirir.",
  "page-stablecoins-fiat-backed-description": "Basit olarak, geleneksel itibari para birimi (genellikle dolar) için bir borç senedi (size borçluyum). İtibari para biriminizi, daha sonra bozdurabileceğiniz ve orijinal para biriminiz için kullanabileceğiniz bir sabit para satın almak için kullanırsınız.",
  "page-stablecoins-fiat-backed-pro-1": "Kripto volatilitesine karşı güvenli.",
  "page-stablecoins-fiat-backed-pro-2": "Fiyat değişiklikleri minimum seviyededir.",
  "page-stablecoins-find-stablecoin": "Bir sabit para bulun",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Sabit para nasıl alınır",
  "page-stablecoins-find-stablecoin-intro": "Yüzlerce sabit para vardır. İşte başlamanıza yardımcı olacak bazı bilgiler. Ethereum'da yeniyseniz, önce biraz araştırma yapmanızı öneririz.",
  "page-stablecoins-find-stablecoin-types-link": "Farklı sabit para türleri",
  "page-stablecoins-get-stablecoins": "Sabit para nasıl alınır",
  "page-stablecoins-hero-alt": "Piyasa değerine göre en büyük üç sabit para: Dai, USDC ve Tether.",
  "page-stablecoins-hero-button": "Sabit para alın",
  "page-stablecoins-hero-header": "Günlük kullanım için dijital para",
  "page-stablecoins-hero-subtitle": "Sabit paralar ETH fiyatı değişse bile sabit bir değerde kalacak şekilde tasarlanmış Ethereum token'larıdır.",
  "page-stablecoins-interest-earning-dapps": "Faiz kazandıran merkezi olmayan uygulamalar",
  "page-stablecoins-meta-description": "Ethereum sabit paralarına giriş: ne oldukları, nasıl elde edilecekleri ve neden önemli oldukları.",
  "page-stablecoins-precious-metals": "Değerli madenler",
  "page-stablecoins-precious-metals-con-1": "Merkezi - birileri token'ları üretmelidir.",
  "page-stablecoins-precious-metals-con-2": "Token üreten kuruluşa ve değerli maden rezervlerine güvenmeniz gerekir.",
  "page-stablecoins-precious-metals-description": "Fiat destekli paralar gibi, bu sabit paralar da değerlerini korumak için altın gibi kaynakları kullanır.",
  "page-stablecoins-precious-metals-pro-1": "Kripto volatilitesine karşı güvenli.",
  "page-stablecoins-prices": "Sabit para fiyatları",
  "page-stablecoins-prices-definition": "Sabit paralar volatilitesi olmayan kripto para birimleridir. ETH ile aynı güçlerin çoğunu paylaşırlar, ancak değerleri sabit, daha çok geleneksel bir para birimi gibidir. Böylece Ethereum'da kullanabileceğiniz sabit paraya erişiminiz olur. ",
  "page-stablecoins-prices-definition-how": "Sabit paralar istikrarlarını nasıl sağlar",
  "page-stablecoins-research-warning": "Ethereum yeni bir teknolojidir ve çoğu uygulama yenidir. Riskin farkında olduğunuzdan emin olun ve yalnızca kaybetmeyi göze alabileceğiniz kadar para yatırın.",
  "page-stablecoins-research-warning-title": "Her zaman kendi araştırmanızı yapın",
  "page-stablecoins-save-stablecoins": "Sabit paralarla kaydedin",
  "page-stablecoins-save-stablecoins-body": "Sabit paralar genellikle ortalamanın üzerinde bir faiz oranına sahiptir çünkü onları ödünç almak için çok fazla talep vardır. Sabit paralarınızı bir borç verme havuzuna yatırmak suretiyle gerçek zamanlı olarak faiz kazanmanızı sağlayan uygulamalar vardır. Bankacılık dünyasında olduğu gibi, ödünç alanlar için token sağlarsınız, ancak token ve faizinizi istediğiniz zaman çekebilirsiniz.",
  "page-stablecoins-saving": "Sabit para tasarruflarınızı iyi bir şekilde kullanın ve bir miktar faiz kazanın. Kriptodaki her şey gibi, tahmini Yıllık Yüzde Getiriler (APY), gerçek zamanlı arz/talebe bağlı olarak günden güne değişebilir.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Ethereum'un merkezi olmayan uygulamalarına göz atın – sabit paralar genellikle günlük işlemler için daha kullanışlıdır.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Doge görseli.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Sabit paralarınızı kullanın",
  "page-stablecoins-stablecoins-dapp-description-1": "Dai, USDC, TUSD, USDT ve daha fazlası dahil olmak üzere birçok sabit para için piyasalar. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Sabit para ödünç verin, faiz ve Compound'un kendi tokeni olan $COMP kazanın.",
  "page-stablecoins-stablecoins-dapp-description-3": "Dai ve USDC'nizden faiz kazanabileceğiniz bir ticaret platformu.",
  "page-stablecoins-stablecoins-dapp-description-4": "Dai'yi kurtarmak için tasarlanmış bir uygulama.",
  "page-stablecoins-stablecoins-feature-1": "Sabit paralar globaldir ve internet üzerinden gönderilebilir. Bir Ethereum hesabınız olduğunda bunları almak veya göndermek kolaydır.",
  "page-stablecoins-stablecoins-feature-2": "Sabit paralara olan talep yüksektir, bu nedenle kendinizinkini ödünç vererek faiz kazanabilirsiniz. Kredi vermeden önce risklerin farkında olduğunuzdan emin olun.",
  "page-stablecoins-stablecoins-feature-3": "Sabit paralar, ETH ve diğer Ethereum tokenleri ile değiştirilebilir. Pek çok merkezi olmayan uygulama sabit paralara güveniyor.",
  "page-stablecoins-stablecoins-feature-4": "Sabit paralar kriptografi ile güvence altına alınır. Hiç kimse sizin adınıza sahte işlem yapamaz.",
  "page-stablecoins-stablecoins-meta-description": "Ethereum sabit paralara giriş: ne oldukları, nasıl elde edilecekleri ve neden önemli oldukları.",
  "page-stablecoins-stablecoins-table-header-column-1": "Para Birimi",
  "page-stablecoins-stablecoins-table-header-column-2": "Piyasa değeri",
  "page-stablecoins-stablecoins-table-header-column-3": "Teminat türü",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Kripto",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Resmi Para",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Değerli madenler",
  "page-stablecoins-table-error": "Sabit paralar yüklenemedi. Sayfayı yenilemeyi deneyin.",
  "page-stablecoins-table-loading": "Sabit para verileri yükleniyor...",
  "page-stablecoins-title": "Sabit paralar",
  "page-stablecoins-top-coins": "Piyasa değerine göre en iyi sabit paralar",
  "page-stablecoins-top-coins-intro": "Piyasa değeri",
  "page-stablecoins-top-coins-intro-code": "var olan toplam token sayısının token başına değerle çarpılmasıdır. Bu liste dinamiktir ve burada listelenen projeler ethereum.org ekibi tarafından mutlak olarak desteklenmemektedir.",
  "page-stablecoins-types-of-stablecoin": "Nasıl çalışır: sabit para türleri",
  "page-stablecoins-usdc-banner-body": "USDc muhtemelen fiat destekli en ünlü sabit paradır. Değeri kabaca bir dolardır ve Circle ve Coinbase tarafından desteklenir.",
  "page-stablecoins-usdc-banner-learn-button": "USDC hakkında bilgi alın",
  "page-stablecoins-usdc-banner-swap-button": "USDC karşılığında ETH takas edin",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "USDC logosu",
  "page-stablecoins-why-stablecoins": "Neden sabit paralar?",
  "page-stablecoins-how-they-work-button": "Nasıl çalışırlar",
  "page-stablecoins-why-stablecoins-body": "ETH, Bitcoin gibi, yeni bir teknoloji olduğu için değişken bir fiyata sahiptir. Bu yüzden düzenli olarak harcamak istemeyebilirsiniz. Sabit paralar, Ethereum'da kullanabileceğiniz istikrarlı paraya erişmenizi sağlamak için geleneksel para birimlerinin değerini yansıtır.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": "Eğer bir cüzdan eklememizi isterseniz,",
  "page-find-wallet-alpha-logo-alt": "AlphaWallet logosu",
  "page-find-wallet-ambo-logo-alt": "Ambo logosu",
  "page-find-wallet-argent-logo-alt": "Argent logosu",
  "page-find-wallet-buy-card": "Bir kartla kripto satın alın",
  "page-find-wallet-buy-card-desc": "ETH'yi doğrudan cüzdanınıza bir banka kartı ile satın alın. Coğrafi kısıtlamalar geçerli olabilir.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Merkezi olmayan uygulamalara göz atın",
  "page-find-wallet-clear": "Filtreleri temizle",
  "page-find-wallet-coinbase-logo-alt": "Coinbase logosu",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Bu yüzden istediğiniz özelliklere göre cüzdanınızı seçin.",
  "page-find-wallet-description": "Cüzdanlar, beğenebileceğiniz birçok ek özelliğe sahiptir.",
  "page-find-wallet-description-alpha": "Mobil cihazlarda güvenli bir yerleşim bölgesinden yararlanan tamamen açık kaynaklı Ethereum cüzdanı, tam testnet desteği sunar ve TokenScript standardını benimser.",
  "page-find-wallet-description-ambo": "Doğrudan yatırım yapmaya devam edin ve uygulamayı indirdikten sonra birkaç dakika içinde ilk yatırımınızı alın",
  "page-find-wallet-description-argent": "Pay kazanmak ve yatırım yapmak için tek dokunuşla ödünç alın, saklayın ve gönderin. Sahip olun.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Kriptoyu kendiniz depolamak için güvenli uygulama",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma, DeFi'ye açılan portalınızdır. Artık tohum verileri yok, Chrome uzantıları yok.",
  "page-find-wallet-description-enjin": "Geçilmez, özelliklerle dolu ve kullanışlı; tüccarlar, oyuncular ve geliştiriciler için tasarlandı",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Ethereum dijital varlıkları depolamak için en güvenilir platform",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken, milyonlarca insan tarafından güvenilen basit ve güvenli bir dijital cüzdandır",
  "page-find-wallet-description-ledger": "Varlıklarınızı en yüksek güvenlik standartlarıyla güvende tutun",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Blockchain uygulamalarını saniyeler içinde keşfetmeye başlayın. Dünya çapında 1 milyondan fazla kullanıcı güveniyor.",
  "page-find-wallet-description-monolith": "Dünyanın tek VISA Bankamatik Kartı ile eşleştirilmiş kendi kendine saklama cüzdanı. İngiltere ve AB'de mevcuttur ve dünya çapında kullanılabilir.",
  "page-find-wallet-description-multis": "Multis, işletmeler için tasarlanmış bir kripto para birimi hesabıdır. Multis ile şirketler erişim kontrolleri ile depolayabilir, tasarruflarına pay kazandırabilir ve ödemeleri ve muhasebeyi kolaylaştırabilir.",
  "page-find-wallet-description-mycrypto": "MyCrypto, tüm hesaplarınızı yönetmek için bir arayüzdür. Metamask, Ledger, Trezor ve daha fazlası gibi cüzdanlarla kriptoyu değiştirin, gönderin ve satın alın.",
  "page-find-wallet-description-myetherwallet": "Ethereum blockchain ile etkileşime girmenize yardımcı olan ücretsiz, istemci taraflı arayüzü",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Kendi L2 Ödeme Ağı'na sahip, gözetimsiz, topluluğa ait cüzdan.",
  "page-find-wallet-description-portis": "Uygulamaları herkes için basitleştiren gözetimsiz blok zinciri cüzdanı",
  "page-find-wallet-description-rainbow": "Ethereum varlıklarınız için daha iyi bir yer",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "En son teknoloji ile inşa edilmiş güvenli mesajlaşma uygulaması, kripto cüzdanı ve Web3 tarayıcısı",
  "page-find-wallet-description-tokenpocket": "TokenPocket: güvenli ve kullanışlı bir dünya lideri dijital para cüzdanı ve çoklu zincir destekli merkezi olmayan uygulamalar için bir portal.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Web 3.0 için tek tıklamayla oturum açma",
  "page-find-wallet-description-trezor": "İlk ve orijinal donanım cüzdanı",
  "page-find-wallet-description-trust": "Trust Wallet, merkezi olmayan çok paralı bir kripto para cüzdanıdır. Anahtarlarınızı kontrol ederken kripto satın alın, uygulamaları keşfedin, varlıkları değiştirin ve daha fazlasını yapın.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo ilk anahtarsız kripto cüzdanıdır. ZenGo ile, yönetmek veya kaybetmek için özel anahtarlar, şifreler veya tohum cümleleri yoktur. Ethereum'u benzeri görülmemiş basitlik ve güvenlik ile satın alın, ticaret yapın, kazanın ve saklayın",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Dharma logosu",
  "page-find-wallet-enjin-logo-alt": "Enjin logosu",
  "page-find-wallet-Ethereum-wallets": "Ethereum Cüzdanlar",
  "page-find-wallet-explore-dapps": "Merkezi olmayan uygulamaları keşfedin",
  "page-find-wallet-explore-dapps-desc": "Bu cüzdanlar, ethereum dapps'e bağlanmanıza yardımcı olmak için tasarlanmıştır.",
  "page-find-wallet-feature-h2": "İlgilendiğiniz cüzdan özelliklerini seçin",
  "page-find-wallet-fi-tools": "Finansal araçlara erişim",
  "page-find-wallet-fi-tools-desc": "Doğrudan cüzdanınızdan ödünç alın, ödünç verin ve pay kazanın.",
  "page-find-wallet-following-features": "aşağıdaki özelliklere sahip:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Gnosis Safe logosu",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Cüzdan resminizi bulun",
  "page-find-wallet-imtoken-logo-alt": "imToken logosu",
  "page-find-wallet-last-updated": "Son güncelleme",
  "page-find-wallet-ledger-logo-alt": "Ledger logosu",
  "page-find-wallet-limits": "Koruma sınırları",
  "page-find-wallet-limits-desc": "Hesabınızın boşaltılmasını engelleyen sınırlar belirleyerek varlıklarınızı koruyun.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "listeleme politikası",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "İstediğiniz özelliklere göre Ethereum cüzdanlarını bulun ve karşılaştırın.",
  "page-find-wallet-meta-title": "Bir Ethereum cüzdanı bulun",
  "page-find-wallet-metamask-logo-alt": "MetaMask logosu",
  "page-find-wallet-monolith-logo-alt": "Monolith logosu",
  "page-find-wallet-multis-logo-alt": "Multis logosu",
  "page-find-wallet-multisig": "Çoklu imza hesapları",
  "page-find-wallet-multisig-desc": "Ekstra güvenlik için, çoklu imza cüzdanları belirli işlemleri yetkilendirmek için birden fazla hesap gerektirir.",
  "page-find-wallet-mycrypto-logo-alt": "MyCrypto logosu",
  "page-find-wallet-myetherwallet-logo-alt": "MyEtherWallet logosu",
  "page-find-wallet-new-to-wallets": "Cüzdanlarda yeni misiniz? İşte başlamanıza yardımcı olacak bir genel bakış.",
  "page-find-wallet-new-to-wallets-link": "Ethereum cüzdanları",
  "page-find-wallet-not-all-features": "Hiçbir cüzdan bu özelliklerin tümüne sahip değildir",
  "page-find-wallet-not-endorsements": "Bu sayfada listelenen cüzdanlar resmi onaylar değildir ve yalnızca bilgilendirme amaçlıdır. Açıklamaları, cüzdan şirketlerinin kendileri tarafından sağlanmıştır. Şuradaki kriterlere göre bu sayfaya ürünler ekliyoruz",
  "page-find-wallet-overwhelmed": "Aşağıdaki Ethereum cüzdanları. Bunaldınız mı? Özelliklere göre filtrelemeyi deneyin.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Pillar logosu",
  "page-find-wallet-portis-logo-alt": "Portis logosu",
  "page-find-wallet-rainbow-logo-alt": "Rainbow logosu",
  "page-find-wallet-raise-an-issue": "GitHub'da bir sorun oluşturun",
  "page-find-wallet-search-btn": "Seçili özellikleri arayın",
  "page-find-wallet-showing": "Gösteriliyor ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Status logosu",
  "page-find-wallet-swaps": "Merkezi olmayan token takasları",
  "page-find-wallet-swaps-desc": "ETH ve diğer tokenler arasında doğrudan cüzdanınızdan işlem yapın.",
  "page-find-wallet-title": "Cüzdan bul",
  "page-find-wallet-tokenpocket-logo-alt": "TokenPocket logosu",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Torus logosu",
  "page-find-wallet-trezor-logo-alt": "Trezor logosu",
  "page-find-wallet-trust-logo-alt": "Trust logosu",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Bir veya iki özelliği kaldırmayı deneyin",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Artık bir cüzdanınız olduğuna göre, bazı Ethereum uygulamalarına (dapps) göz atın. Finans, sosyal medya, oyun ve diğer birçok kategori için uygulamalar var.",
  "page-find-wallet-use-your-wallet": "Cüzdanınızı kullanın",
  "page-find-wallet-voluem-desc": "Çok fazla ETH tutmak istiyorsanız, bir seferde 2000 dolardan fazla ETH satın almanıza izin veren bir cüzdan seçin.",
  "page-find-wallet-volume": "Yüksek hacimli satın alımlar",
  "page-find-wallet-we-found": "Bulduk",
  "page-find-wallet-withdraw": "Bankadan para çekme",
  "page-find-wallet-withdraw-desc": "ETH'nizi bir borsadan geçmeden doğrudan banka hesabınıza nakde çevirebilirsiniz.",
  "page-find-wallet-yet": "henüz",
  "page-find-wallet-zengo-logo-alt": "ZenGo logosu",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "ETH stake etme",
  "page-wallets-accounts-addresses": "Cüzdanlar, hesaplar ve adresler",
  "page-wallets-accounts-addresses-desc": "Bazı temel terimler arasındaki farkları anlamak önemlidir.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Ethereum cüzdanını temsil eden, gövdesi yerinde kasası olan bir robot görseli",
  "page-wallets-ethereum-account": "Ethereum hesabı",
  "page-wallets-blog": "Coinbase blogu",
  "page-wallets-bookmarking": "Cüzdanınızı işaretleyin",
  "page-wallets-bookmarking-desc": "Bir web cüzdanı kullanıyorsanız, kimlik avı dolandırıcılıklarına karşı kendinizi korumak için siteyi yer imlerine ekleyin.",
  "page-wallets-cd": "Kriptolarınızı çevrimdışı tutmanıza izin veren fiziksel donanım cüzdanları - çok güvenli",
  "page-wallets-converted": "Kriptoya çevirmek mi?",
  "page-wallets-converted-desc": "Ciddi bir değer elde etmek istiyorsanız, en güveniliri olan bir donanım cüzdanı öneririz. Veya dolandırıcılık uyarıları ve para çekme limitleri olan bir cüzdan.",
  "page-wallets-curious": "Kriptoya meraklı mısınız?",
  "page-wallets-curious-desc": "Kripto konusunda yeniyseniz ve sadece bir fikir edinmek istiyorsanız, size Ethereum uygulamalarını keşfetme veya ilk ETH'nizi doğrudan cüzdandan satın alma fırsatı verecek bir şey öneriyoruz.",
  "page-wallets-desc-2": "Para göndermek ve ETH'nizi yönetmek için bir cüzdana ihtiyacınız var.",
  "page-wallets-desc-2-link": "ETH hakkında daha fazlası",
  "page-wallets-desc-3": "Cüzdanınız yalnızca Ethereum hesabınızı yönetmek için bir araçtır. Bu, cüzdan sağlayıcılarını istediğiniz zaman değiştirebileceğiniz anlamına gelir. Birçok cüzdan aynı zamanda birkaç Ethereum hesabını tek bir uygulamadan yönetmenize izin verir.",
  "page-wallets-desc-4": "Bunun nedeni, cüzdanların paranızın gözetimine sahip olmamasıdır, siz yaparsınız. Onlar sadece gerçekten size ait olanı yönetmek için bir araçtır.",
  "page-wallets-description": "Ethereum cüzdanları, Ethereum hesabınızla etkileşim kurmanıza izin veren uygulamalardır. Banka olmadan bir internet bankacılığı uygulaması gibi düşünün. Cüzdanınız bakiyenizi okumanıza, işlem göndermenize ve uygulamalara bağlanmanıza olanak tanır.",
  "page-wallets-desktop": "Fonlarınızı MacOS, Windows veya linux üzerinden yönetmeyi tercih ediyorsanız masaüstü uygulamaları",
  "page-wallets-ethereum-wallet": "Bir cüzdan",
  "page-wallets-explore": "Ethereum'u Keşfedin",
  "page-wallets-features-desc": "Önem verdiğiniz özelliklere göre cüzdanınızı seçmenize yardımcı olabiliriz.",
  "page-wallets-features-title": "Özelliklere göre tercih yapmak ister misiniz?",
  "page-wallets-find-wallet-btn": "Bir cüzdan bul",
  "page-wallets-find-wallet-link": "Bir cüzdan bul",
  "page-wallets-get-some": "Bir miktar ETH edin",
  "page-wallets-get-some-alt": "Lego tuğlalarından ETH sembolü inşa eden bir elin görseli",
  "page-wallets-get-some-btn": "Bir miktar ETH edin",
  "page-wallets-get-some-desc": "ETH, Ethereum'un yerel kriptosudur. Ethereum uygulamalarını kullanmak için cüzdanınızda bir miktar ETH'ye ihtiyacınız olacak.",
  "page-wallets-get-wallet": "Bir cüzdan edin",
  "page-wallets-get-wallet-desc": "Aralarından seçim yapabileceğiniz birçok farklı cüzdan vardır. Sizin için en iyisini seçmenize yardımcı olmak istiyoruz.",
  "page-wallets-get-wallet-desc-2": "Unutmayın: bu karar sonsuza kadar geçerli değildir – Ethereum hesabınız cüzdan sağlayıcınıza bağlı değildir.",
  "page-wallets-how-to-store": "Dijital varlıklar Ethereum'da nasıl saklanır",
  "page-wallets-keys-to-safety": "Kriptonuzu güvende tutmanın anahtarları",
  "page-wallets-manage-funds": "Fonlarınızı yönetmek için bir uygulama",
  "page-wallets-manage-funds-desc": "Cüzdanınız bakiyelerinizi, işlem geçmişinizi gösterir ve size para göndermek/almak için bir yol sunar. Bazı cüzdanlar daha fazlasını sunabilir.",
  "page-wallets-meta-description": "Ethereum cüzdanlarını kullanmak için bilmeniz gerekenler.",
  "page-wallets-meta-title": "Ethereum cüzdanları",
  "page-wallets-mobile": "Paranızın her yerden erişilebilir olmasını sağlayan mobil uygulamalar",
  "page-wallets-more-on-dapps-btn": "Merkezi olmayan uygulamalar hakkında daha fazlası",
  "page-wallets-most-wallets": "Çoğu cüzdan ürünü, bir Ethereum hesabı oluşturmanıza izin verir. Yani bir cüzdan indirmeden önce birine ihtiyacınız yok.",
  "page-wallets-protecting-yourself": "Kendinizi ve paranızı korumak",
  "page-wallets-seed-phrase": "Çekirdek sözcük grubunu yaz",
  "page-wallets-seed-phrase-desc": "Cüzdanlar genellikle size güvenli bir yere yazmanız gereken bir çekirdek cümlesi verir. Cüzdanınızı kurtarmanın tek yolu budur.",
  "page-wallets-seed-phrase-example": "İşte bir örnek:",
  "page-wallets-seed-phrase-snippet": "orada uçak eğrisi havalandırma oluşumu doge olası ürün ispirto lambasının altında farklı",
  "page-wallets-seed-phrase-write-down": "Bir bilgisayarda saklamayın. Bir yere yazın ve güvende tutun.",
  "page-wallets-slogan": "Dijital geleceğinizin anahtarı",
  "page-wallets-stay-safe": "Nasıl güvende kalınır",
  "page-wallets-stay-safe-desc": "Cüzdanlar düşüncede biraz değişikliktir. Finansal özgürlük ve fonlara her yerden erişme ve kullanma yeteneği biraz sorumluluk gerektirir – kriptoda müşteri desteği yoktur.",
  "page-wallets-subtitle": "Cüzdanlar varlıklarınıza ve Ethereum uygulamalarına erişiminizi sağlar. Cüzdanınıza yalnızca sizin erişebiliyor olmanız gerekir.",
  "page-wallets-take-responsibility": "Varlıklarınızın sorumluluğunu alın",
  "page-wallets-take-responsibility-desc": "Merkezileşmiş borsalar cüzdanınızı geleneksel yolla kurtarabileceğiniz bir kullanıcı adı ve şifreye bağlar. Varlıklarınızı bu borsalara kendinizin güvenip emanet ettiğini unutmayın. Şirkete gerçekleştirilen bir saldırı veya şirketin iflas etmesi varlıklarınızı riske eder.",
  "page-wallets-tips": "Güvende kalmak için daha fazla öneri",
  "page-wallets-tips-community": "Topluluktan gelenler",
  "page-wallets-title": "Ethereum cüzdanları",
  "page-wallets-triple-check": "Herşeyi üç kere kontrol edin",
  "page-wallets-triple-check-desc": "Unutmayın cüzdanlar kolay bir şekilde kurtarılamaz ve yapılan işlemler geri alınamaz bu yüzden dikkatli olun.",
  "page-wallets-try-dapps": "Dapps uygulamalarını deneyin",
  "page-wallets-try-dapps-alt": "Ethereum topluluğu üyelerinin birlikte çalışmasını gösteren bir görsel",
  "page-wallets-try-dapps-desc": "Dapps uygulamaları Ethereum üzerine oluşturulmuştur. Verilerinize çoğu geleneksel uygulamalara göre daha tutumlu, adil ve iyi davranırlar.",
  "page-wallets-types": "Cüzdan türleri",
  "page-wallets-web-browser": "Web tarayıcınızla etkileşime geçebileceğiniz web cüzdanları",
  "page-wallets-whats-a-wallet": "Ethereum cüzdanı nedir?",
  "page-wallets-your-ethereum-account": "Ethereum hesabınız",
  "page-wallets-your-ethereum-account-desc": "Cüzdanınız Ethereum hesabınıza, bakiyenize, işlem geçmişinize ve daha fazlasına açılan pencerenizdir. Cüzdan sağlayıcınızı istediğiniz zaman değiştirebilirsiniz.",
  "page-wallets-your-login": "Ethereum uygulamaları için oturum açma aracınız",
  "page-wallets-your-login-desc": "Cüzdanınız Ethereum hesabınızı kullanarak sizi merkezi olmayan uygulamalara bağlar. Pek çok dapps uygulamasında kullanabileceğiniz bir üyelik gibi.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum, küçük bir ücret karşılığında herkese kripto para göndermenize izin veren bir teknolojidir. Ayrıca herkesin kullanabileceği ve kimsenin alt edemeyeceği uygulamalara da güç verir.",
  "page-what-is-ethereum-101-desc-2": "Ethereum, bazı büyük farklılıklarla birlikte Bitcoin inovasyonu üzerine inşa edilmiştir.",
  "page-what-is-ethereum-101-desc-3": "Her ikisi de dijital parayı ödeme sağlayıcıları veya bankalar olmadan kullanmanıza izin verir. Ancak Ethereum programlanabilir, bu yüzden onu birçok farklı dijital varlık için de kullanabilirsiniz – Bitcoin için bile!",
  "page-what-is-ethereum-101-desc-4": "Bu aynı zamanda Ethereum'un ödemelerden daha fazlası olduğu anlamına gelir. Verilerinizi çalamayan veya sizi sansürleyemeyen finansal hizmetler, oyunlar ve uygulamalardan oluşan bir pazardır.",
  "page-what-is-ethereum-101-italic": "dünyanın programlanabilir blok zinciridir.",
  "page-what-is-ethereum-101-strong": "Budur ",
  "page-what-is-ethereum-accessibility": "Ethereum herkese açıktır.",
  "page-what-is-ethereum-adventure": "Maceranızı seçin!",
  "page-what-is-ethereum-alt-img-bazaar": "Ethereum'u temsil etmesi amaçlanan bir pazara bakan bir kişinin görseli",
  "page-what-is-ethereum-alt-img-comm": "Ethereum topluluğu üyelerinin birlikte çalışmasını gösteren bir görsel",
  "page-what-is-ethereum-alt-img-lego": "Lego tuğlalarından ETH sembolü inşa eden bir elin görseli",
  "page-what-is-ethereum-alt-img-social": "Büyük bir ETH logolu Ethereum'a adanmış bir sosyal alandaki karakterlerin bir görseli",
  "page-what-is-ethereum-banking-card": "Herkes için bankacılık",
  "page-what-is-ethereum-banking-card-desc": "Herkesin finansal hizmetlere erişimi yoktur. Ancak Ethereum'a ve onun ödünç verme, ödünç alma ve tasarruf ürünlerine erişmek için ihtiyacınız olan tek şey bir internet bağlantısıdır.",
  "page-what-is-ethereum-build": "Ethereum ile bir şey yapın",
  "page-what-is-ethereum-build-desc": "Ethereum ile inşa etmeyi denemek istiyorsanız, belgelerimizi okuyun, bazı öğreticileri deneyin veya başlamak için ihtiyacınız olan araçlara göz atın.",
  "page-what-is-ethereum-censorless-card": "Sansüre dirençli",
  "page-what-is-ethereum-censorless-card-desc": "Hiçbir hükümet veya şirketin Ethereum üzerinde kontrolü yoktur. Bu merkezi olmayan yapılanma, Ethereum'da ödeme almanızın veya hizmetleri kullanmanızın engellemesini neredeyse imkansız hale getirir.",
  "page-what-is-ethereum-comm-desc": "Topluluğumuz, sanatçılar, kripto-anarşistler, en zengin 500 şirket ve şimdi siz de dahil olmak üzere farklı geçmişleri olan tüm insanları içerir. Bugün nasıl dahil olabileceğinizi öğrenin.",
  "page-what-is-ethereum-commerce-card": "Garantilerin ticaretini yapın",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum daha seviyeli bir oyun alanı yaratır. Müşteriler, fonların yalnızca siz üzerinde anlaşılanı sağlamanız durumunda el değiştireceğine dair güvenli, yerleşik bir garantiye sahiptir. İş yapmak için büyük bir şirket nüfuzuna ihtiyacınız yoktur.",
  "page-what-is-ethereum-community": "Ethereum topluluğu",
  "page-what-is-ethereum-compatibility-card": "Kazanma uyumluluğu",
  "page-what-is-ethereum-compatibility-card-desc": "Ethereum ürünleri varsayılan olarak uyumlu olduğu için her zaman daha iyi ürün ve deneyimler oluşturulur. Şirketler birbirlerinin başarıları üzerine inşa edebilirler.",
  "page-what-is-ethereum-dapps-desc": "Ethereum üzerinde çalışan ürünler ve hizmetler. Finans, iş, sosyal medya, oyun ve daha fazlası için uygulamalar var - dijital geleceğimiz için uygulamalarla tanışın.",
  "page-what-is-ethereum-dapps-img-alt": "Bilgisayarda Ethereum uygulamasını kullanan bir doge resmi",
  "page-what-is-ethereum-dapps-title": "Ethereum temelleri",
  "page-what-is-ethereum-desc": "Dijital geleceğimizin temeli",
  "page-what-is-ethereum-explore": "Ethereum'u Keşfedin",
  "page-what-is-ethereum-get-started": "Daha fazlasını öğrenmenin en iyi yolu bir cüzdan indirmek, biraz ETH almak ve bir Ethereum dapp'i denemektir.",
  "page-what-is-ethereum-in-depth-description": "Ethereum, geçmişiniz veya konumunuz ne olursa olsun, herkes için dijital paraya ve veri dostu hizmetlere açık erişimdir. Kripto para birimi eterinin (ETH) ve bugün kullanabileceğiniz binlerce uygulamanın arkasında topluluk tarafından oluşturulmuş bir teknolojidir.",
  "page-what-is-ethereum-internet-card": "Daha özel bir internet",
  "page-what-is-ethereum-internet-card-desc": "Bir Ethereum uygulamasını kullanmak için tüm kişisel bilgilerinizi vermeniz gerekmez. Ethereum, gözetime değil, değere dayalı bir ekonomi inşa eder.",
  "page-what-is-ethereum-meet-comm": "Toplulukla tanış",
  "page-what-is-ethereum-meta-description": "Ethereum'u, ne yaptığını ve kendiniz için nasıl deneyeceğinizi öğrenin.",
  "page-what-is-ethereum-meta-title": "Ethereum nedir?",
  "page-what-is-ethereum-native-alt": "Ether (ETH) sembolü",
  "page-what-is-ethereum-native-crypto": "Ethereum'un yerel kripto para birimidir ve Bitcoin'e eşdeğerdir. ETH'yi Ethereum uygulamalarında veya arkadaşlarınıza ve ailenize değer göndermek için kullanabilirsiniz.",
  "page-what-is-ethereum-native-img-alt": "Ethereum cüzdanlarını temsil etmek için kullanılan, gövdesinde kasası olan bir robot resmi",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Eşler arası ağ",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum, doğrudan başka biriyle para taşımanıza veya anlaşmalar yapmanıza olanak tanır. Aracı firmalardan geçmenize gerek yoktur.",
  "page-what-is-ethereum-singlecard-desc": "Blok zincir ve Ethereum'un teknik yönü ile ilgileniyorsanız, size hitap ederiz.",
  "page-what-is-ethereum-singlecard-link": "Ethereum nasıl çalışır?\n",
  "page-what-is-ethereum-singlecard-title": "Ethereum nasıl çalışır?\n",
  "page-what-is-ethereum-start-building-btn": "Geliştirmeye başla",
  "page-what-is-ethereum-title": "Ethereum nedir?",
  "page-what-is-ethereum-tools-needed": "Rol almak için tek ihtiyacınız olan bir cüzdan.",
  "page-what-is-ethereum-try": "Ethereum'u Deneyin",
  "page-what-is-ethereum-tryit": "Pazara adım atın ve deneyin...",
  "page-what-is-ethereum-wallets": "Cüzdanlar",
  "page-what-is-ethereum-wallets-desc": "ETH'nizi ve Ethereum hesabınızı nasıl yönetiyorsunuz. Başlamak için bir cüzdana ihtiyacınız olacak - birini seçmenize yardımcı olacağız.",
  "page-what-is-ethereum-welcome": "Ethereum'a hoş geldiniz",
  "page-what-is-ethereum-welcome-2": "Umarız kalırsınız.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Staking geldi! Stake yani cüzdanınızda ETH tutma işlemi yapmak istiyorsanız",
  "banner-staking-2": "para yatırma sözleşmesi adresini onaylayın",
  "docking": "Kenetlenme",
  "page-eth2-vision-security-desc-9": "farklı parçalara rastgele doğrulayıcılar atamamıza izin verir - bu durum doğrulayıcıların belirli bir parçaya saldırarak gizlice işbirliği yapmasını neredeyse imkansız hale getirir. Sharding, iş kanıtı blok zincirinde o kadar da güvenli değildir, çünkü madenciler bu şekilde protokol tarafından kontrol edilemez.",
  "page-index-sections-developers-desc": "Ethereum ile oluşturmaya başlamak için ardındaki teknoloji ve uygulamaları hakkında bilgi edinin.",
  "page-index-sections-developers-image-alt": "Lego tuğlalarından yapılmış Ethereum kabartmasının elle yapılma görseli",
  "page-index-sections-developers-link-text": "Geliştirmeye başla",
  "page-index-sections-developers-title": "Geliştiriciler",
  "page-index-sections-enterprise-desc": "Ethereum'un yeni iş modellerine nasıl uyumlanabildiğini, maliyetlerinizi nasıl düşürdüğünü ve işinizi nasıl geleceğe dönük bir hale getirdiğini görün.",
  "page-index-sections-enterprise-image-alt": "Bir dizüstü etrafında Ethereum projesi üzerinde çalışan bir grubun görseli",
  "page-index-sections-enterprise-link-text": "İşletmeler için Ethereum\n",
  "page-index-sections-enterprise-title": "İşletmeler",
  "page-index-sections-individuals-desc": "Ethereum uygulamalarını kullanmaya başlayabilmek için, Ethereum, Ether, cüzdanlar, token'lar ve daha fazlası hakkında daha bilgi edinin.",
  "page-index-sections-individuals-image-alt": "Bilgisayar başında oturan köpek görseli",
  "page-index-sections-individuals-link-text": "Ethereum kullanmaya başlayın",
  "page-index-sections-individuals-title": "Ethereum Hakkında",
  "page-index-subtitle": "Ethereum ile dijital değeri kontrol eden, tam olarak programlandığı gibi çalışan ve dünyanın her yerinden erişilebilen bir kod yazabilirsiniz.",
  "page-find-wallet-authereum-logo-alt": "Authereum logosu",
  "page-find-wallet-description-authereum": "İndirme yok, tohum verileri yok. Herhangi bir tarayıcı, herhangi bir zaman, mobil veya masaüstü",
  "page-wallets-accounts-addresses-desc-2": "bir varlıktır, bakiyeye sahiptir ve ödeme alıp gönderebilir.",
  "page-wallets-accounts-has": "Ethereum hesabında şunlar vardır",
  "page-wallets-an": "Bir",
  "page-wallets-ethereum-addresses": "Ethereum adresi",
  "page-wallets-ethereum-addresses-2": ", bir gelen kutusunun bir e-posta adresi olması gibi. Bir hesaba para göndermek için bunu kullanabilirsiniz.",
  "page-wallets-ethereum-wallet-2": "hesap bakiyenizi görüntüleme, işlem gönderme ve daha fazlası gibi Ethereum hesabınızı yönetmenize izin veren bir üründür."
}
