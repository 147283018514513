{
  "1inch-logo": "Λογότυπο 1inch",
  "aave-logo": "Λογότυπο Aave",
  "about": "Σχετικά με",
  "about-ethereum-org": "Σχετικά με το ethereum.org",
  "about-us": "Σχετικά με εμάς",
  "alt-eth-blocks": "Απεικόνιση των μπλοκ που οργανώνονται σαν ένα σύμβολο ETH",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Επιστροφή στην κορυφή",
  "banner-page-incomplete": "Αυτή η σελίδα είναι ελλιπής. Αν γνωρίζετε σχετικά με το θέμα, παρακαλούμε επεξεργαστείτε τη σελίδα και προσφέρετε τη γνώση σας.",
  "beacon-chain": "Η κύρια αλυσίδα (Beacon Chain)",
  "binance-logo": "Λογότυπο Binance",
  "bittrex-logo": "Λογότυπο Bittrex",
  "brand-assets": "Ψηφιακά στοιχεία επωνυμίας",
  "bug-bounty": "Αμοιβή σφάλματος",
  "coinbase-logo": "Λογότυπο Coinbase",
  "coinmama-logo": "Λογότυπο Coinmama",
  "community": "Κοινότητα",
  "community-menu": "Community Menu",
  "compound-logo": "Σύνθεση λογότυπου",
  "cons": "Μειονεκτήματα",
  "contact": "Επικοινωνία",
  "content-versions": "Content Versions",
  "contributing": "Συνεισφορές",
  "contributors": "Συνεισφέροντες",
  "contributors-thanks": "Όλοι όσοι συνέβαλαν σε αυτή τη σελίδα – σας ευχαριστούμε!",
  "cookie-policy": "Πολιτική περί cookies",
  "copied": "Αντιγράφηκε",
  "copy": "Αντιγραφή",
  "dark-mode": "Σκοτεινή λειτουργία",
  "data-provided-by": "Τα δεδομένα παρέχονται από",
  "decentralized-applications-dapps": "Αποκεντρωμένες εφαρμογές (dapps)",
  "deposit-contract": "Σύμβαση κατάθεσης",
  "devcon": "Devcon",
  "developers": "Προγραμματιστές",
  "developers-home": "Αρχική προγραμματιστών",
  "docs": "Έγγραφα",
  "documentation": "Τεκμηρίωση",
  "dydx-logo": "Λογότυπο Dydx",
  "ecosystem": "Οικοσύστημα",
  "edit-page": "Επεξεργασία σελίδας",
  "ef-blog": "Ιστολόγιο ιδρύματος Ethereum",
  "eips": "Προτάσεις βελτίωσης Ethereum",
  "enterprise": "Επιχείρηση",
  "enterprise-menu": "Μενού επιχειρήσεων",
  "esp": "Πρόγραμμα υποστήριξης οικοσυστήματος",
  "eth-current-price": "Τρέχουσα τιμή ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Εξερευνητής ανοικτού κώδικα Beacon Chain Eth2",
  "eth2-beaconscan-desc": "Εξερευνητής Eth2 Beacon Chain – Etherscan για Eth2",
  "eth2-become-staker": "Γίνετε staker",
  "eth2-become-staker-desc": "Το Staking υπάρχει! Αν θέλετε να αποθηκεύσετε (stake) το ETH σας για να βοηθήσετε στην ασφάλεια του δικτύου, βεβαιωθείτε ότι γνωρίζετε τους κινδύνους.",
  "eth2-explore": "Εξερευνήστε τα δεδομένα",
  "eth2-no-action-needed": "Δεν χρειάζεται να κάνετε τίποτα με τα ETH που έχετε. Προσοχή στους κακόβουλους που ζητούν να στείλετε ETH για ανταλλαγή.",
  "eth2-run-beacon-chain": "Εκτέλεση ενός beacon",
  "eth2-run-beacon-chain-desc": "Το Ethereum χρειάζεται όσο το δυνατόν περισσότερους πελάτες. Βοηθήστε με αυτό το δημόσιο αγαθό Ethereum!",
  "eth2-service-announcement": "Ανακοίνωση υπηρεσίας Eth2",
  "eth2-what-shipping": "Πότε ξεκινά;",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Ψηφιακά στοιχεία επωνυμίας Ethereum",
  "ethereum-community": "Κοινότητα Ethereum",
  "ethereum-foundation": "Ίδρυμα Ethereum",
  "ethereum-foundation-logo": "Λογότυπο ιδρύματος Ethereum",
  "ethereum-glossary": "Γλωσσάρι Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Λογότυπο Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Στούντιο Ethereum",
  "ethereum-wallets": "Πορτοφόλια Ethereum",
  "ethereum-whitepaper": "Καταστατικό Ethereum",
  "example-projects": "Παραδείγματα έργων",
  "find-wallet": "Επιλογή πορτοφολιού",
  "foundation": "Ίδρυμα",
  "gemini-logo": "Λογότυπο Gemini",
  "get-eth": "Λήψη ETH",
  "get-involved": "Συμμετέχετε",
  "get-started": "Ας αρχίσουμε",
  "gitcoin-logo": "Λογότυπο Gitcoin",
  "glossary": "Λεξικό",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Οδηγοί και βοηθήματα",
  "history": "Ιστορικό",
  "history-of-ethereum": "Ιστορία του Ethereum",
  "home": "Αρχική",
  "how-ethereum-works": "Πώς λειτουργεί το Ethereum",
  "image": "εικόνα",
  "in-this-section": "Σε αυτή την ενότητα",
  "individuals": "Ατομικά",
  "individuals-menu": "Ατομικά μενού",
  "jobs": "Jobs",
  "kraken-logo": "Λογότυπο Kraken",
  "language-ar": "Αραβικά",
  "language-bg": "Bulgarian",
  "language-bn": "Μπενγκάλι",
  "language-ca": "Catalan",
  "language-cs": "Τσεχικά",
  "language-de": "Γερμανικά",
  "language-el": "Ελληνικά",
  "language-en": "Αγγλικά",
  "language-es": "Ισπανικά",
  "language-fa": "Φαρσί",
  "language-fi": "Φινλανδικά",
  "language-fr": "Γαλλικά",
  "language-hu": "Ουγγαρικά",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Ινδονησιακά",
  "language-ig": "Ίγκμπο",
  "language-it": "Ιταλικά",
  "language-ja": "Ιαπωνικά",
  "language-ko": "Κορεάτικα",
  "language-lt": "Λιθουανικά",
  "language-ml": "Μαλαγιαλαμικά",
  "language-nb": "Νορβηγικά",
  "language-nl": "Ολλανδικά",
  "language-pl": "Πολωνικά",
  "language-pt": "Πορτογαλικά",
  "language-pt-br": "Πορτογαλικά (Βραζιλίας)",
  "language-ro": "Ρουμανικά",
  "language-ru": "Ρωσικά",
  "language-se": "Σουηδικά",
  "language-sk": "Σλοβακικά",
  "language-sl": "Σλοβενικά",
  "language-support": "Υποστήριξη γλώσσας",
  "language-tr": "Τουρκικά",
  "language-uk": "Ουκρανικά",
  "language-vi": "Βιετναμικά",
  "language-zh": "Κινέζικα Απλοποιημένα",
  "language-zh-tw": "Κινεζικά παραδοσιακά",
  "languages": "Γλώσσες",
  "last-24-hrs": "Τελευταίο 24ωρο",
  "last-edit": "Τελευταία επεξεργασία",
  "learn": "Μάθηση",
  "learn-by-coding": "Μάθετε με κωδικοποίηση",
  "learn-menu": "Εκμάθηση μενού",
  "learn-more": "Μάθετε περισσότερα",
  "less": "Λιγότερα",
  "light-mode": "Φωτεινή λειτουργία",
  "listing-policy-disclaimer": "Όλα τα προϊόντα που παρατίθενται σε αυτή τη σελίδα δεν είναι επίσημες θέσεις και παρέχονται μόνο για ενημερωτικούς σκοπούς. Αν θέλετε να προσθέσετε ένα προϊόν ή να παράσχετε σχόλια σχετικά με την πολιτική αναφέρετε το ζήτημα στο GitHub.",
  "listing-policy-raise-issue-link": "Αναφορά σφάλματος",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Φόρτωση...",
  "loading-error": "Σφάλμα φόρτωσης.",
  "loading-error-refresh": "Σφάλμα φόρτωσης δεδομένων. Δοκιμάστε να ανανεώσετε τη σελίδα.",
  "logo": "λογότυπο",
  "loopring-logo": "Λογότυπο Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Βασικό δίκτυο του Ethereum",
  "makerdao-logo": "Λογότυπο MakerDao",
  "matcha-logo": "Λογότυπο Matcha",
  "merge": "Merge",
  "more": "Περισσότερα",
  "nav-beginners": "Αρχάριοι",
  "next": "Επόμενο",
  "oasis-logo": "Λογότυπο Oasis",
  "on-this-page": "Σε αυτή τη σελίδα",
  "page-content": "Περιεχόμενο σελίδας",
  "page-enterprise": "Επιχείρηση",
  "page-last-updated": "Τελευταία ενημέρωση της σελίδας",
  "previous": "Προηγούμενο",
  "privacy-policy": "Πολιτική απορρήτου",
  "private-ethereum": "Ιδιωτικό Ethereum",
  "pros": "Πλεονεκτήματα",
  "read-more": "Διαβάστε περισσότερα",
  "refresh": "Παρακαλούμε ανανεώστε τη σελίδα.",
  "review-progress": "Πρόοδος αναθεώρησης",
  "search": "Αναζήτηση",
  "search-box-blank-state-text": "Αναζήτηση!",
  "search-eth-address": "Μοιάζει με διεύθυνση Ethereum. Δεν παρέχουμε δεδομένα ειδικά για διευθύνσεις. Δοκιμάστε να την αναζητήσετε σε έναν εξερευνητή μπλοκ όπως",
  "search-no-results": "Δεν υπάρχουν αποτελέσματα για την αναζήτηση σας",
  "security": "Security",
  "see-contributors": "Δείτε τους συνεισφέροντες",
  "set-up-local-env": "Ρύθμιση τοπικού περιβάλλοντος",
  "shard-chains": "Οι Shard chains",
  "show-all": "Εμφάνιση όλων",
  "show-less": "Εμφάνιση λιγότερων",
  "site-description": "Το Ethereum είναι μια παγκόσμια, αποκεντρωμένη πλατφόρμα για χρήματα και νέα είδη εφαρμογών. Στο Ethereum, μπορείτε να γράψετε κώδικα που ελέγχει χρήματα και να δημιουργήσετε εφαρμογές προσβάσιμες οπουδήποτε στον κόσμο.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoins",
  "staking": "Staking (αποθήκευση)",
  "summary": "Σύνοψη",
  "terms-of-use": "Όροι Χρήσης",
  "transaction-fees": "Ποιες είναι οι χρεώσεις συναλλαγής;",
  "translation-banner-body-new": "Βλέπετε αυτήν τη σελίδα στα Αγγλικά επειδή δεν την έχουμε μεταφράσει ακόμα. Βοηθήστε μας να μεταφράσουμε αυτό το περιεχόμενο.",
  "translation-banner-body-update": "Υπάρχει μια νέα έκδοση αυτής της σελίδας, αλλά αυτήν τη στιγμή είναι μόνο στα Αγγλικά. Βοηθήστε μας να μεταφράσουμε την τελευταία έκδοση.",
  "translation-banner-button-join-translation-program": "Εγγραφείτε στο πρόγραμμα μετάφρασης",
  "translation-banner-button-learn-more": "Μάθετε περισσότερα",
  "translation-banner-button-see-english": "Δείτε στα Αγγλικά",
  "translation-banner-button-translate-page": "Μετάφραση σελίδας",
  "translation-banner-title-new": "Βοηθήστε στη μετάφραση αυτής της σελίδας",
  "translation-banner-title-update": "Βοηθήστε την ενημέρωση αυτής της σελίδας",
  "translation-program": "Translation Program",
  "translation-progress": "Πρόοδος μετάφρασης",
  "tutorials": "Σεμινάρια",
  "uniswap-logo": "Λογότυπο Uniswap",
  "use": "Χρήση",
  "use-ethereum": "Χρήση του Ethereum",
  "use-ethereum-menu": "Χρήση του μενού Ethereum",
  "vision": "Όραμα",
  "wallets": "Πορτοφόλια",
  "website-last-updated": "Τελευταία ενημέρωση της ιστοσελίδας",
  "what-is-ether": "Τι είναι το ether (ETH);",
  "what-is-ethereum": "Τι είναι το Ethereum;",
  "whitepaper": "Καταστατικό (Whitepaper)",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Αίτηση για νέα δυνατότητα",
  "page-about-h3": "Εργασία σε εξέλιξη",
  "page-about-h3-1": "Ολοκληρωμένες δυνατότητες",
  "page-about-h3-2": "Μελλοντικές δυνατότητες",
  "page-about-li-1": "σε εξέλιξη",
  "page-about-li-2": "προγραμματισμένο",
  "page-about-li-3": "υλοποιήθηκε",
  "page-about-li-4": "υλοποιήθηκε",
  "page-about-link-1": "Ο πηγαίος κώδικας του αποθετηρίου έχει αδειοδοτηθεί με την άδεια MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Δείτε την πλήρη λίστα των εργασιών σε εξέλιξη στο GitHub",
  "page-about-link-4": "Εγγραφείτε στον διακομιστή μας Discord",
  "page-about-link-5": "Συνομιλήστε μαζί μας στο Twitter",
  "page-about-link-6": "Δείτε την πλήρη λίστα των τρεχουσών εργασιών μας στο GitHub",
  "page-about-link-7": "Δημιουργία αναφοράς σφάλματος στο GitHub",
  "page-about-p-1": "Από την έναρξη του ethereum.org, προσπαθούμε να είμαστε διαφανείς με τον τρόπο λειτουργίας μας. Είναι μία από τις βασικές μας αξίες, διότι πιστεύουμε ότι η διαφάνεια είναι κρίσιμη για την επιτυχία του Ethereum.",
  "page-about-p-2": "Χρησιμοποιούμε",
  "page-about-p-3": "ως το κύριο εργαλείο διαχείρισης έργου. Οργανώνουμε τις εργασίες μας σε 3 κατηγορίες:",
  "page-about-p-4": "  Κάνουμε ό, τι καλύτερο μπορούμε για να ενημερώνουμε την κοινότητα σχετικά με την κατάσταση ενός συγκεκριμένου έργου.",
  "page-about-p-5": "Εργασίες που υλοποιούμε.",
  "page-about-p-6": "Μελλοντικές προγραμματισμένες εργασίες.",
  "page-about-p-7": "Πρόσφατα ολοκληρωμένες εργασίες.",
  "page-about-p-8": "Έχετε κάποια πρόταση βελτίωσης του ethereum.org; Θα θέλαμε να συνεργαστούμε μαζί σας!",
  "page-assets-bazaar": "Ethereum αγορά",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Κατασκευή δομής",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Ο Doge χρησιμοποιεί dapps",
  "page-assets-download-artist": "Καλλιτέχνης:",
  "page-assets-download-download": "Λήψη",
  "page-assets-enterprise": "Ethereum για επιχειρήσεις",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "ETH ρόμβος (χρωματιστό)",
  "page-assets-eth-diamond-glyph": "ETH ρόμβος (ανάγλυφο)",
  "page-assets-eth-diamond-gray": "ETH ρόμβος (γκρί)",
  "page-assets-eth-diamond-purple": "ETH ρόμβος (μώβ)",
  "page-assets-eth-diamond-white": "ETH ρόμβος (λευκό)",
  "page-assets-eth-glyph-video-dark": "ETH ανάγλυφο video (σκοτεινό)",
  "page-assets-eth-glyph-video-light": "ETH ανάγλυφο video (φωτεινό)",
  "page-assets-eth-logo-landscape-gray": "ETH εικονίδιο τοπίο (γκρί)",
  "page-assets-eth-logo-landscape-purple": "ETH εικονίδιο τοπίο (μώβ)",
  "page-assets-eth-logo-landscape-white": "ETH εικονίδιο τοπίο (λευκό)",
  "page-assets-eth-logo-portrait-gray": "ETH λογότυπο πορτρέτο (γκρί)",
  "page-assets-eth-logo-portrait-purple": "ETH λογότυπο πορτρέτο (μώβ)",
  "page-assets-eth-logo-portrait-white": "ETH λογότυπο πορτρέτο (λευκό)",
  "page-assets-eth-wordmark-gray": "ETH επωνυμία (γκρί)",
  "page-assets-eth-wordmark-purple": "ETH επωνυμία (μώβ)",
  "page-assets-eth-wordmark-white": "ETH επωνυμία (λευκό)",
  "page-assets-ethereum-brand-assets": "Ψηφιακά στοιχεία επωνυμίας Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "ethereum.org ψηφιακά στοιχεία ",
  "page-assets-hero": "ethereum.org ήρωας",
  "page-assets-hero-particles": "ETH απεικόνιση σωματιδίων",
  "page-assets-historical-artwork": "Ιστορικά εξώφυλλα",
  "page-assets-illustrations": "Εικονογραφήσεις",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Εξερευνήστε και κατεβάστε τα ψηφιακά στοιχεία επωνυμίας του Ethereum και του ethereum.org, εικόνες και πολυμέσα.",
  "page-assets-meta-title": "Ψηφιακά στοιχεία επωνυμίας Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Συμπαγές φόντο",
  "page-assets-page-assets-transparent-background": "Διαφανές φόντο",
  "page-assets-robot": "Το Ρομπότ πορτοφόλι",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "Λογότυπο 1inch",
  "page-dapps-aave-logo-alt": "Λογότυπο Aave",
  "page-dapps-add-button": "Προτείνετε dapp",
  "page-dapps-add-title": "Προσθήκη dapp",
  "page-dapps-audius-logo-alt": "Λογότυπο Audius",
  "page-dapps-augur-logo-alt": "Λογότυπο Augur",
  "page-dapps-axie-infinity-logo-alt": "Λογότυπο Axie Infinity",
  "page-dapps-brave-logo-alt": "Λογότυπο Brave",
  "page-dapps-category-arts": "Τέχνη και μόδα",
  "page-dapps-category-browsers": "Προγράμματα περιήγησης",
  "page-dapps-category-collectibles": "Ψηφιακή συλλογή",
  "page-dapps-category-competitive": "Ανταγωνισμός",
  "page-dapps-category-computing": "Εργαλεία προγραμματιστών",
  "page-dapps-category-dex": "Ανταλλαγή token",
  "page-dapps-category-investments": "Επενδύσεις",
  "page-dapps-category-lending": "Δάνεια και δανειοληψία",
  "page-dapps-category-lottery": "Ανεύρεση πόρων",
  "page-dapps-category-marketplaces": "Αγορές",
  "page-dapps-category-music": "Μουσική",
  "page-dapps-category-payments": "Πληρωμές",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Αγορές συναλλαγών και προβλέψεων",
  "page-dapps-category-utilities": "Βοηθήματα",
  "page-dapps-category-worlds": "Εικονικοί κόσμοι",
  "page-dapps-choose-category": "Επιλέξτε κατηγορία",
  "page-dapps-collectibles-benefits-1-description": "Όταν η τέχνη συμβολίζεται στο Ethereum, η ιδιοκτησία μπορεί να αποδειχθεί για όλους. Μπορείτε να εντοπίσετε το ταξίδι του έργου τέχνης από τη δημιουργία μέχρι τον τρέχοντα κάτοχό του. Αυτό αποτρέπει τα πλαστογραφήματα.",
  "page-dapps-collectibles-benefits-1-title": "Η κυριότητα αποδεικνύεται",
  "page-dapps-collectibles-benefits-2-description": "Με το Ethereum υπάρχει λιγότερη ανάγκη για μεσάζοντες. Και αν οι μεσάζοντες χρειάζονται, το κόστος τους δεν είναι τόσο υψηλό γιατί οι πλατφόρμες δεν χρειάζεται να πληρώσουν για την υποδομή του δικτύου.",
  "page-dapps-collectibles-benefits-2-title": "Δικαιότερο για τους δημιουργούς",
  "page-dapps-collectibles-benefits-3-description": "Οι ομαδοποιημένες μονάδες είναι συνδεδεμένες με τη διεύθυνση Ethereum σας, όχι την πλατφόρμα. Έτσι, μπορείτε να πουλήσετε πράγματα όπως μέσα στο παιχνίδι σε οποιαδήποτε αγορά Ethereum, όχι μόνο στο ίδιο το παιχνίδι.",
  "page-dapps-collectibles-benefits-3-title": "Τα συγκεντρωμένα νομίσματα σας ακολουθούν",
  "page-dapps-collectibles-benefits-4-description": "Τα εργαλεία και τα προϊόντα υπάρχουν ήδη για να \"μετατρέψετε\" την τέχνη σας και να την πουλήσετε! Και μάρκες σας μπορούν να πωληθούν σε οποιαδήποτε και όλες Ethereum πλατφόρμα συλλεκτικών.",
  "page-dapps-collectibles-benefits-4-title": "Ισχύουσα υποδομή",
  "page-dapps-collectibles-benefits-description": "Αυτές είναι εφαρμογές που εστιάζουν στην ψηφιακή ιδιοκτησία, αυξάνοντας την προοπτική κέρδους για τους δημιουργούς, και εφευρίσκοντας νέους τρόπους για να επενδύσουν στους αγαπημένους σας δημιουργούς και τη δουλειά τους.",
  "page-dapps-collectibles-benefits-title": "αποκεντρωμένες συλλογές \"νομισμάτων\" και ροή",
  "page-dapps-collectibles-button": "Τέχνες και συλλογές",
  "page-dapps-collectibles-description": "Αυτές είναι εφαρμογές που εστιάζουν στην ψηφιακή ιδιοκτησία, αυξάνοντας την προοπτική κέρδους για τους δημιουργούς, και εφευρίσκοντας νέους τρόπους για να επενδύσουν στους αγαπημένους σας δημιουργούς και τη δουλειά τους.",
  "page-dapps-collectibles-title": "Αποκεντρωμένες τέχνες και συλλογές",
  "page-dapps-compound-logo-alt": "Λογότυπο Compound",
  "page-dapps-cryptopunks-logo-alt": "Λογότυπο CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Λογότυπο Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Σας βοηθά στην αποφυγή διακυμάνσεων υψηλών τιμών συγκεντρώνοντας τις καλύτερες τιμές.",
  "page-dapps-dapp-description-aave": "Δανείστε τα νομίσματά σας και κερδίστε από τους τόκους, διαθέσιμα πάντοτε για ανάληψη.",
  "page-dapps-dapp-description-async-art": "Δημιουργήστε, συλλέξτε και ανταλλάξτε #ProgrammableArt - ψηφιακά έργα ζωγραφικής χωρισμένα σε “Layers” που μπορείτε να χρησιμοποιήσετε επηρεάζοντας τη συνολική εικόνα. Κάθε Master και Layer είναι νόμισμα ERC72.",
  "page-dapps-dapp-description-audius": "Αποκεντρωμένη πλατφόρμα μετάδοσης. Ακροατές = χρήματα για τους δημιουργούς, όχι ετικέτες.",
  "page-dapps-dapp-description-augur": "Στοιχηματίστε σε αποτελέσματα αγώνων, οικονομίας και περισσότερων παγκόσμιων γεγονότων.",
  "page-dapps-dapp-description-axie-infinity": "Ανταλλάξτε και πολεμήστε με πλάσματα που ονομάζονται Axies. Κερδίστε ενώ παίζετε – διαθέσιμο για κινητό",
  "page-dapps-dapp-description-brave": "Κερδίστε κρυπτονομίσματα κατά την περιήγηση και υποστηρίξτε τους αγαπημένους σας δημιουργούς.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Δανείστε τα κρυπτονομίσματά σας και κερδίστε από τους τόκους, διαθέσιμα πάντοτε για ανάληψη.",
  "page-dapps-dapp-description-cryptopunks": "Αγοράστε, συμμετέχετε και πουλήστε punks – ένα από τα πρώτα συλλεκτικά token στο Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Δημιουργήστε γκαλερί τέχνης, καταστήματα και αγοράστε γη - ένας εικονικός κόσμος στο Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Κατακτήστε πλανήτες σε ένα άπειρο, δημιουργικό, κρυπτογραφικά καθορισμένο σύμπαν.",
  "page-dapps-dapp-description-decentraland": "Συλλέξτε και ανταλλάξτε εικονική γη σε έναν εικονικό κόσμο που μπορείτε να εξερευνήσετε.",
  "page-dapps-dapp-description-dydx": "Ανοικτές σύντομες ή θέσεις με μόχλευση έως και 10x. Μπορείτε επίσης να δανείσετε ή να δανειστείτε.",
  "page-dapps-dapp-description-ens": "Φιλικά προς το χρήστη ονόματα διευθύνσεων Ethereum και αποκεντρωμένες ιστοσελίδες.",
  "page-dapps-dapp-description-foundation": "Επενδύστε σε μοναδικές εκδόσεις ψηφιακών έργων τέχνης και κομματιών με εμπορική αξία με άλλους αγοραστές.",
  "page-dapps-dapp-description-gitcoin": "Κερδίστε κρυπτονομίσματα συμμετέχοντας σε λογισμικό ανοιχτού κώδικα.",
  "page-dapps-dapp-description-gitcoin-grants": "Χρηματοδότηση για έργα της κοινότητας του Ethereum από διάφορους συνεισφέροντες",
  "page-dapps-dapp-description-gods-unchained": "Παιχνίδι στρατηγικής ανταλλαγής καρτών. Κερδίστε κάρτες παίζοντας τις οποίες μπορείτε να πουλήσετε στην πραγματική ζωή.",
  "page-dapps-dapp-description-golem": "Πρόσβαση στην κοινόχρηστη υπολογιστική δύναμη ή ενοικίαση δικών σας πόρων.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Πλατφόρμα συναλλαγών peer-to-peer για περισσότερη ταχύτητα.",
  "page-dapps-dapp-description-marble-cards": "Δημιουργήστε και ανταλλάξτε μοναδικές ψηφιακές κάρτες με βάση τις διευθύνσεις URL.",
  "page-dapps-dapp-description-matcha": "Αναζήτηση σε διάφορα ανταλλακτήρια για να σας βοηθήσει να βρείτε τις καλύτερες τιμές.",
  "page-dapps-dapp-description-nifty-gateway": "Αγοράστε έργα on-chain από κορυφαίους καλλιτέχνες, αθλητές, μάρκες και δημιουργούς.",
  "page-dapps-dapp-description-oasis": "Ανταλλάξτε, δανειστείτε και κερδίστε με το Dai, ένα νόμισμα σταθερής αξίας του Ethereum.",
  "page-dapps-dapp-description-opensea": "Αγοράστε, πουλήστε και ανταλλάξτε αγαθά περιορισμένης έκδοσης.",
  "page-dapps-dapp-description-opera": "Στείλτε κρυπτονομίσματα από το πρόγραμμα περιήγησής σας σε εμπόρους, σε άλλους χρήστες αλλά και σε εφαρμογές.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Στοιχηματίστε στα αποτελέσματα. Ένα εμπόριο στην αγορά πληροφοριών.",
  "page-dapps-dapp-description-pooltogether": "Μια λοταρία που δεν μπορείτε να χάσετε. Βραβεία κάθε εβδομάδα.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Δημιουργήστε, πουλήστε και αγοράστε tokenised συλλεκτικά.",
  "page-dapps-dapp-description-sablier": "Μεταδώστε χρήματα σε πραγματικό χρόνο.",
  "page-dapps-dapp-description-superrare": "Αγοράστε ψηφιακά έργα τέχνης απευθείας από τους καλλιτέχνες ή από δευτερογενείς αγορές.",
  "page-dapps-dapp-description-token-sets": "Επενδυτικές στρατηγικές κρυπτογράφησης που επανισορροπούν αυτόματα.",
  "page-dapps-dapp-description-tornado-cash": "Αποστολή ανώνυμων συναλλαγών στο Ethereum.",
  "page-dapps-dapp-description-uniswap": "Μετατρέψτε εύκολα τα κρυπτονομίσματα ή παρέχετε μερικά για % ανταμοιβές.",
  "page-dapps-dark-forest-logo-alt": "Λογότυπο Dark Forest",
  "page-dapps-decentraland-logo-alt": "Λογότυπο Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Βρείτε και δοκιμάστε μια εφαρμογή του Ethereum.",
  "page-dapps-doge-img-alt": "Απεικόνιση ενός σκύλου που χρησιμοποιεί υπολογιστή",
  "page-dapps-dydx-logo-alt": "Λογότυπο Dydx",
  "page-dapps-editors-choice-dark-forest": "Παίξτε εναντίον άλλων για να κατακτήσετε τους πλανήτες και να δοκιμάσετε την bleeding-edge τεχνολογία κλιμάκωσης/απορρήτου του Ethereum. Ίσως κάποια για όσους είναι ήδη εξοικειωμένοι με το Ethereum.",
  "page-dapps-editors-choice-description": "Μερικές αγαπημένες εφαρμογές της ομάδας του ethereum.org. Εξερευνήστε περισσότερα παρακάτω.",
  "page-dapps-editors-choice-foundation": "Επενδύστε στον πολιτισμό. Αγοράστε, ανταλλάξτε και πουλήστε μοναδικά ψηφιακά έργα τέχνης και μόδας από μερικούς καταπληκτικούς καλλιτέχνες, μουσικούς αλλά και επώνυμα εμπορικά σήματα.",
  "page-dapps-editors-choice-header": "Επιλογές συντακτών",
  "page-dapps-editors-choice-pooltogether": "Αγοράστε ένα εισιτήριο για την λοταρία που δεν έχει χαμένους. Κάθε εβδομάδα, οι τόκοι που δημιουργούνται από ολόκληρο το σύνολο εισιτηρίων αποστέλλονται σε έναν τυχερό νικητή. Παίρνετε τα χρήματά σας όποτε θέλετε.",
  "page-dapps-editors-choice-uniswap": "Ανταλλάξτε τα tokens σας με ευκολία. Ένα αγαπημένο κομμάτι της κοινότητας που σας επιτρέπει να ανταλλάζετε tokens με χρήστες σε όλο το δίκτυο.",
  "page-dapps-ens-logo-alt": "Λογότυπο υπηρεσίας ονομάτων Ethereum",
  "page-dapps-explore-dapps-description": "Πολλά dapp είναι ακόμα πειραματικά, δοκιμάζουν τις δυνατότητες αποκεντρωμένων δικτύων. Αλλά έχουν υπάρξει μερικές επιτυχημένες πρώιμες κινήσεις στις κατηγορίες τεχνολογίας, οικονομικών, τυχερών παιχνιδιών και στις συλλεκτικές κατηγορίες.",
  "page-dapps-explore-dapps-title": "Εξερευνήστε τα dapps",
  "page-dapps-features-1-description": "Μόλις αναπτυχθεί στο Ethereum, ο κώδικας dapp δεν μπορεί να καταργηθεί. Και ο καθένας μπορεί να χρησιμοποιήσει τις λειτουργίες του dapp. Ακόμα και αν η ομάδα πίσω από το dapp διαλυθεί, θα μπορείτε να το χρησιμοποιείτε. Ό,τι μπαίνει στο Ethereum, παραμένει εκεί.",
  "page-dapps-features-1-title": "Χωρίς κάτοχο",
  "page-dapps-features-2-description": "Δεν μπορείτε να αποκλειστείτε από τη χρήση μιας αποκεντρωμένης εφαρμογής ή από την υποβολή συναλλαγών. Για παράδειγμα, εάν το Twitter λειτουργούσε μέσα στο Ethereum, δεν θα μπορούσε κανείς να αποκλείσει τον λογαριασμό ή τα λεγόμενά σας.",
  "page-dapps-features-2-title": "Χωρίς λογοκρισία",
  "page-dapps-features-3-description": "Επειδή το Ethereum έχει ETH, οι πληρωμές είναι εγγενείς στο Ethereum. Οι προγραμματιστές δεν χρειάζεται να δαπανήσουν χρόνο για ενσωμάτωση με τρίτους παρόχους πληρωμών.",
  "page-dapps-features-3-title": "Ενσωματωμένες πληρωμές",
  "page-dapps-features-4-description": "Ο κώδικας Dapp είναι συχνά ανοικτός και συμβατός από προεπιλογή. Οι ομάδες χτίζουν τακτικά χρησιμοποιώντας την εργασία άλλων ομάδων. Αν θέλετε να επιτρέψετε στους χρήστες να ανταλλάξουν tokens στο dapp, μπορείτε απλά να συνδέσετε έναν άλλο κώδικα dapp.",
  "page-dapps-features-4-title": "Σύνδεση και αναπαραγωγή",
  "page-dapps-features-5-description": "Με τα περισσότερα dapp, δεν χρειάζεται να μοιράζεστε την πραγματική σας ταυτότητα. Ο λογαριασμός σας στο Ethereum είναι τα στοιχεία σύνδεσής σας και απλά χρειάζεστε ένα πορτοφόλι.",
  "page-dapps-features-5-title": "Μία ανώνυμη σύνδεση",
  "page-dapps-features-6-description": "Η κρυπτογραφία εξασφαλίζει ότι οι εισβολείς δεν μπορούν να πλαστογραφήσουν συναλλαγές και άλλες αλληλεπιδράσεις dapp για λογαριασμό σας. Εγκρίνετε ενέργειες dapp με τον λογαριασμό Ethereum σας, συνήθως μέσω του πορτοφολιού σας, οπότε διατηρήστε τα διαπιστευτήρια σας ασφαλή.",
  "page-dapps-features-6-title": "Ασφάλεια μέσω κρυπτογράφησης",
  "page-dapps-features-7-description": "Μόλις το dapp είναι ζωντανά στο Ethereum, θα πέσει μόνο αν πέσει το ίδιο το Ethereum. Σε δίκτυα μεγέθους του Ethereum είναι εμφανώς πιο δύσκολη η επίθεση.",
  "page-dapps-features-7-title": "Χωρίς χρόνο λήξης",
  "page-dapps-finance-benefits-1-description": "Οι οικονομικές υπηρεσίες που εκτελούνται στο Ethereum δεν έχουν απαιτήσεις εγγραφής. Εάν έχετε κεφάλαια και σύνδεση στο διαδίκτυο, είστε έτοιμοι.",
  "page-dapps-finance-benefits-1-title": "Ανοιχτή πρόσβαση",
  "page-dapps-finance-benefits-2-description": "Υπάρχει ένας ολόκληρος κόσμος token με τον οποίο μπορείτε να αλληλεπιδράσετε με αυτά τα χρηματοπιστωτικά προϊόντα. Οι άνθρωποι χτίζουν νέα token στο Ethereum συνέχεια.",
  "page-dapps-finance-benefits-2-title": "Μια νέα οικονομία κρυπτονομισμάτων",
  "page-dapps-finance-benefits-3-description": "Οι ομάδες έχουν δημιουργήσει κρυπτονομίσματα σταθερής αξίας – λιγότερο ασταθή κρυπτονομίσματα. Αυτά σας επιτρέπουν να πειραματιστείτε και να χρησιμοποιείτε κρυπτονομίσματα χωρίς κίνδυνο και αβεβαιότητα.",
  "page-dapps-finance-benefits-3-title": "Stablecoins",
  "page-dapps-finance-benefits-4-description": "Τα οικονομικά προϊόντα στον χώρο του Ethereum είναι όλα σπονδυλωτά και συμβατά μεταξύ τους. Νέες διαμορφώσεις από αυτές τις ενότητες χτυπούν την αγορά συνεχώς, αυξάνοντας το τι μπορείτε να κάνετε με τα κρυπτονομίσματα σας.",
  "page-dapps-finance-benefits-4-title": "Συναφείς χρηματοοικονομικές υπηρεσίες",
  "page-dapps-finance-benefits-description": "Τι είναι αυτό στο Ethereum που επιτρέπει αποκεντρωμένες χρηματοπιστωτικές εφαρμογές να ευδοκιμήσουν;",
  "page-dapps-finance-benefits-title": "αποκεντρωμένη τραπεζική",
  "page-dapps-finance-button": "Οικονομικά",
  "page-dapps-finance-description": "Αυτές είναι εφαρμογές που επικεντρώνονται στη δημιουργία χρηματοπιστωτικών υπηρεσιών χρησιμοποιώντας κρυπτονομίσματα. Προσφέρουν τις αρετές του να δανείζεις, να δανείζεσαι, να κερδίζεις τόκους και να κάνεις ιδιωτικές πληρωμές – δεν απαιτούνται προσωπικά δεδομένα.",
  "page-dapps-finance-title": "Αποκεντρωμένη τραπεζική",
  "page-dapps-foundation-logo-alt": "Λογότυπο ιδρύματος",
  "page-dapps-gaming-benefits-1-description": "Είτε πρόκειται για εικονική γη είτε για κάρτες συναλλαγών, τα αντικείμενα σας είναι διαπραγματεύσιμα σε αγορές συλλεκτών. Τα στοιχεία σας στο παιχνίδι έχουν πραγματική αξία.",
  "page-dapps-gaming-benefits-1-title": "Αντικείμενα παιχνιδιού διπλά ως token",
  "page-dapps-gaming-benefits-2-description": "Μπορείτε να έχετε εσείς τα αντικείμενα σας, και σε ορισμένες περιπτώσεις την πρόοδό σας, και όχι οι εταιρείες παιχνιδιού. Έτσι, δεν θα χάσετε τίποτα, αν η εταιρεία πίσω από το παιχνίδι δέχεται επίθεση, πάσχει από δυσλειτουργία του διακομιστή ή διαλύεται.",
  "page-dapps-gaming-benefits-2-title": "Οι αποταμιεύσεις σας είναι ασφαλείς",
  "page-dapps-gaming-benefits-3-description": "Με τον ίδιο τρόπο που οι πληρωμές Ethereum είναι διαθέσιμες σε οποιονδήποτε για επαλήθευση, τα παιχνίδια μπορούν να χρησιμοποιήσουν αυτή την ποιότητα για να εξασφαλίσουν δικαιοσύνη. Θεωρητικά, τα πάντα είναι επαληθεύσιμα από τον αριθμό των κρίσιμων χτυπημάτων στο μέγεθος του αποθέματος ενός αντιπάλου.",
  "page-dapps-gaming-benefits-3-title": "Αποδεδειγμένη αμεροληψία",
  "page-dapps-gaming-benefits-description": "Τι είναι αυτό για το Ethereum που επιτρέπει την ανάπτυξη αποκεντρωμένων τυχερών παιχνιδιών;",
  "page-dapps-gaming-benefits-title": "αποκεντρωμένα παιχνίδια",
  "page-dapps-gaming-button": "Παιχνίδια",
  "page-dapps-gaming-description": "Αυτές είναι εφαρμογές που επικεντρώνονται στη δημιουργία εικονικών κόσμων και μάχονται άλλους παίκτες χρησιμοποιώντας συλλεκτικά στοιχεία που έχουν πραγματική αξία.",
  "page-dapps-gaming-title": "Αποκεντρωμένα παιχνίδια",
  "page-dapps-get-some-eth-description": "Οι συναλλαγές μέσω των εφαρμογών έχουν κρατήσεις",
  "page-dapps-get-started-subtitle": "Για να δοκιμάσετε μια εφαρμογή χρειάζεστε ένα πορτοφόλι και μερικά ΕΤΗ. Με το πορτοφόλι μπορείτε να συνδεθείτε και με τα ΕΤΗ θα πληρώσετε τυχόν κρατήσεις.",
  "page-dapps-get-started-title": "Ας αρχίσουμε",
  "page-dapps-gitcoin-grants-logo-alt": "Λογότυπο Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Λογότυπο Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Λογότυπο Gods Unchained",
  "page-dapps-golem-logo-alt": "Λογότυπο Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Ethereum - δυναμικά εργαλεία και υπηρεσίες",
  "page-dapps-hero-subtitle": "Τα Dapps είναι μια αυξανόμενη κίνηση εφαρμογών που χρησιμοποιούν το Ethereum για να διαταράξουν τα επιχειρηματικά μοντέλα ή να εφεύρουν νέα.",
  "page-dapps-how-dapps-work-p1": "Τα Dapps έχουν τον κωδικό backend τους (έξυπνα συμβόλαια) σε αποκεντρωμένο δίκτυο και όχι σε κεντρικό διακομιστή. Χρησιμοποιούν το blockchain του Ethereum για αποθήκευση δεδομένων και έξυπνα συμβόλαια για τη λογική της εφαρμογής τους.",
  "page-dapps-how-dapps-work-p2": "Ένα έξυπνο συμβόλαιο είναι σαν ένα σύνολο κανόνων που υπάρχει on-chain για να το βλέπουν όλοι και να τρέχουν ακριβώς σύμφωνα με αυτούς τους κανόνες. Φανταστείτε μια μηχανή αυτόματης πώλησης: αν την εφοδιάσετε με αρκετά κεφάλαια και τη σωστή επιλογή, θα πάρετε το αντικείμενο που θέλετε. Και όπως οι μηχανές αυτόματης πώλησης, τα έξυπνα συμβόλαια μπορούν να κρατάνε κεφάλαια όπως ο λογαριασμός σας στο Ethereum. Αυτό επιτρέπει στον κώδικα να μεσολαβεί σε συμφωνίες και συναλλαγές.",
  "page-dapps-how-dapps-work-p3": "Μόλις οι τα dapps εγκατασταθούν στο δίκτυο Ethereum δεν μπορείτε να τα αλλάξετε. Τα Dapps μπορούν να αποκεντρωθούν επειδή ελέγχονται από τη λογική που γράφεται στο συμβόλαιο, όχι σε ένα άτομο ή μια εταιρεία.",
  "page-dapps-how-dapps-work-title": "Πώς λειτουργούν τα dapps",
  "page-dapps-learn-callout-button": "Ξεκινήστε να δημιουργείτε",
  "page-dapps-learn-callout-description": "Η πύλη προγραμματιστών της κοινότητάς μας έχει έγγραφα, εργαλεία και πλαίσια για να σας βοηθήσει να αρχίσετε να χτίζετε ένα dapp.",
  "page-dapps-learn-callout-image-alt": "Απεικόνιση ενός χεριού που δημιουργεί το σύμβολο του ETH από lego τουβλάκια.",
  "page-dapps-learn-callout-title": "Μάθετε να δημιουργείτε μια εφαρμογή",
  "page-dapps-loopring-logo-alt": "Λογότυπο Loopring",
  "page-dapps-magic-behind-dapps-description": "Τα Dapps μπορεί να μοιάζουν με κανονικές εφαρμογές. Αλλά στα παρασκήνια έχουν κάποιες ειδικές ιδιότητες, επειδή κληρονομούν όλες τις υπερδυνάμεις του Ethereum. Αυτό κάνει τα dapps διαφορετικά από τις εφαρμογές.",
  "page-dapps-magic-behind-dapps-link": "Τι κάνει το Ethereum μοναδικό;",
  "page-dapps-magic-behind-dapps-title": "Η μαγεία πίσω από τα dapps",
  "page-dapps-magic-title-1": "Η μαγεία",
  "page-dapps-magic-title-2": "πίσω από",
  "page-dapps-magician-img-alt": "Απεικόνιση μάγων",
  "page-dapps-marble-cards-logo-alt": "Λογότυπο marble.cards",
  "page-dapps-matcha-logo-alt": "Λογότυπο Matcha",
  "page-dapps-mobile-options-header": "Περιήγηση σε άλλη κατηγορία",
  "page-dapps-nifty-gateway-logo-alt": "Λογότυπο Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Λογότυπο Oasis",
  "page-dapps-opensea-logo-alt": "Λογότυπο OpenSea",
  "page-dapps-opera-logo-alt": "Λογότυπο Opera",
  "page-dapps-polymarket-logo-alt": "Λογότυπο Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Λογότυπο PoolTogether",
  "page-dapps-rarible-logo-alt": "Λογότυπο Rarible",
  "page-dapps-ready-button": "Go",
  "page-dapps-ready-description": "Επιλέξτε μια dapp να τη δοκιμάσετε",
  "page-dapps-ready-title": "Έτοιμοι;",
  "page-dapps-sablier-logo-alt": "Λογότυπο Sablier",
  "page-dapps-set-up-a-wallet-button": "Επιλογή πορτοφολιού",
  "page-dapps-set-up-a-wallet-description": "Ένα πορτοφόλι είναι η “σύνδεση” σας σε μια dapp",
  "page-dapps-set-up-a-wallet-title": "Ρυθμίστε ένα πορτοφόλι",
  "page-dapps-superrare-logo-alt": "Λογότυπο SuperRare",
  "page-dapps-technology-button": "Τεχνολογία",
  "page-dapps-technology-description": "Πρόκειται για εφαρμογές που επικεντρώνονται στην αποκέντρωση των εργαλείων ανάπτυξης, ενσωματώνοντας κρυπτοοικονομικά συστήματα στην υπάρχουσα τεχνολογία και δημιουργώντας αγορές για εργασίες ανάπτυξης ανοιχτού κώδικα.",
  "page-dapps-technology-title": "Αποκεντρωμένη τεχνολογία",
  "page-dapps-token-sets-logo-alt": "Λογότυπο Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Λογότυπο Tornado cash",
  "page-dapps-uniswap-logo-alt": "Λογότυπο Uniswap",
  "page-dapps-wallet-callout-button": "Επιλογή πορτοφολιού",
  "page-dapps-wallet-callout-description": "Τα πορτοφόλια είναι και αυτά αποκεντρωμένες εφαρμογές. Βρείτε ένα που να ταιριάζει σε εσάς.",
  "page-dapps-wallet-callout-image-alt": "Απεικόνιση ενός ρομπότ.",
  "page-dapps-wallet-callout-title": "Προβολή πορτοφολιών",
  "page-dapps-warning-header": "Να κάνετε πάντα τη δική σας έρευνα",
  "page-dapps-warning-message": "Το Ethereum είναι μια νέα τεχνολογία και οι περισσότερες εφαρμογές είναι καινούργιες. Πριν από την κατάθεση μεγάλων ποσών, βεβαιωθείτε πως έχετε καταλάβει πλήρως το ρίσκο.",
  "page-dapps-what-are-dapps": "Τι είναι τα dapps;",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Λογαριασμοί",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Για προχωρημένους",
  "docs-nav-backend-apis": "Backend APIs",
  "docs-nav-block-explorers": "Εξερευνητές Block",
  "docs-nav-blocks": "Μπλοκ",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Μετατροπή Smart Contract",
  "docs-nav-composability": "Συνθετικότητα",
  "docs-nav-consensus-mechanisms": "Μηχανισμοί συναινέσεως",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Δεδομένα και αναλύσεις",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Ανάπτυξη Smart Contract",
  "docs-nav-development-frameworks": "Πλαίσια ανάπτυξης",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Δίκτυα ανάπτυξης",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "APIs για εφαρμογή πελάτη Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Ethereum stack",
  "docs-nav-evm": "Εικονική μηχανή Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Βασικά θέματα",
  "docs-nav-gas": "Καύσιμα",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Ολοκληρωμένα περιβάλλοντα ανάπτυξης (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Εισαγωγή στις dapps",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Εισαγωγή στο Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Εισαγωγή στο stack",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "JavaScript APIs",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Εξόρυξη",
  "docs-nav-networks": "Δίκτυα",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Κόμβοι και πελάτες",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Κόμβοι ως υπηρεσία",
  "docs-nav-oracles": "Oracle",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Γλώσσες προγραμματισμού",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Απόδειξη των μετοχών (proof-of-stake)",
  "docs-nav-proof-of-work": "Απόδειξη της εργασίας (proof-of-work)",
  "docs-nav-python": "Python",
  "docs-nav-readme": "ΔΙΑΒΑΣΤΕ",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Κλίμακα",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Ασφάλεια",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Ανατομία Smart Contract",
  "docs-nav-smart-contract-languages": "Γλώσσες Smart Contract",
  "docs-nav-smart-contracts": "Smart contracts",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Βιβλιοθήκες Smart Contract",
  "docs-nav-standards": "Πρότυπα",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Αποθηκευτικός χώρος",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Δοκιμές Smart Contract",
  "docs-nav-token-standards": "Πρότυπα token",
  "docs-nav-transactions": "Συναλλαγές",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 vs Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Αν είστε ειδικός στο θέμα και θέλετε να συνεισφέρετε, επεξεργαστείτε αυτή τη σελίδα και προσφέρετε με τη σοφία σας.",
  "page-calltocontribute-desc-2": "Θα πιστωθεί σε εσάς και θα βοηθήσετε την κοινότητα του Ethereum!",
  "page-calltocontribute-desc-3": "Χρησιμοποιήστε αυτό το ευέλικτο",
  "page-calltocontribute-desc-4": "Έχετε απορίες; Ρωτήστε μας στο κανάλι #content στον",
  "page-calltocontribute-link": "πρότυπο τεκμηρίωσης",
  "page-calltocontribute-link-2": "Διακομιστής Discord",
  "page-calltocontribute-span": "Επεξεργασία σελίδας",
  "page-calltocontribute-title": "Βοηθήστε μας με αυτή τη σελίδα",
  "page-developer-meta-title": "Βοηθήματα προγραμματιστών Ethereum",
  "page-developers-about": "Σχετικά με αυτά τα βοηθήματα προγραμματιστών",
  "page-developers-about-desc": "Το ethereum.org είναι εδώ για να σας βοηθήσει να δημιουργήσετε με το Ethereum με τεκμηρίωση σχετικά με τις θεμελιώδεις έννοιες, καθώς και τη στοίβα ανάπτυξης. Επιπλέον, υπάρχουν σεμινάρια για να ξεκινήσετε.",
  "page-developers-about-desc-2": "Εμπνευσμένοι από το δίκτυο προγραμματιστή του Mozilla, πιστεύαμε ότι το Ethereum χρειαζόταν ένα μέρος για να φιλοξενήσει εξαιρετικό περιεχόμενο και βοηθήματα προγραμματιστών. Όπως και με τους φίλους μας στο Mozilla, όλα εδώ είναι ανοιχτού κώδικα και έτοιμα για επέκταση και βελτίωση.",
  "page-developers-account-desc": "Contracts ή άτομα στο δίκτυο",
  "page-developers-accounts-link": "Λογαριασμοί",
  "page-developers-advanced": "Για προχωρημένους",
  "page-developers-api-desc": "Χρήση βιβλιοθηκών για αλληλεπίδραση με smart contracts",
  "page-developers-api-link": "Backend APIs",
  "page-developers-aria-label": "Μενού προγραμματιστών",
  "page-developers-block-desc": "Μαζικές συναλλαγές προστέθηκαν στο blockchain",
  "page-developers-block-explorers-desc": "Η πύλη σας στα δεδομένα Ethereum",
  "page-developers-block-explorers-link": "Εξερευνητές μπλοκ",
  "page-developers-blocks-link": "Μπλοκ",
  "page-developers-browse-tutorials": "Αναζητήστε σεμινάρια",
  "page-developers-choose-stack": "Επιλέξτε το stack σας",
  "page-developers-contribute": "Συμβολή",
  "page-developers-dev-env-desc": "IDE κατάλληλα για ανάπτυξη dapp",
  "page-developers-dev-env-link": "Περιβάλλοντα ανάπτυξης",
  "page-developers-discord": "Μπείτε στο Discord",
  "page-developers-docs-introductions": "Εισαγωγές",
  "page-developers-evm-desc": "Ο υπολογιστής που επεξεργάζεται συναλλαγές",
  "page-developers-evm-link": "Η εικονική μηχανή του Ethereum (EVM)",
  "page-developers-explore-documentation": "Εξερευνήστε την τεκμηρίωση",
  "page-developers-feedback": "Εάν έχετε σχόλια, επικοινωνήστε μαζί μας μέσω ενός θέματος GitHub ή μέσω του διακομιστή Discord.",
  "page-developers-frameworks-desc": "Εργαλεία για την επιτάχυνση της ανάπτυξης",
  "page-developers-frameworks-link": "Πλαίσια ανάπτυξης",
  "page-developers-fundamentals": "Βασικές αρχές",
  "page-developers-gas-desc": "Απαιτούμενο Ether για την τροφοδοσία συναλλαγών",
  "page-developers-gas-link": "Καύσιμα",
  "page-developers-get-started": "Πώς θα θέλατε να ξεκινήσουμε;",
  "page-developers-improve-ethereum": "Βοηθήστε μας να βελτιώσουμε το ethereum.org",
  "page-developers-improve-ethereum-desc": "Όπως το ethereum.org, αυτά τα έγγραφα είναι μια κοινοτική προσπάθεια. Δημιουργήστε ένα PR εάν δείτε λάθη, περιθώρια βελτίωσης ή νέες ευκαιρίες για να βοηθήσετε τους προγραμματιστές του Ethereum.",
  "page-developers-into-eth-desc": "Εισαγωγή στο blockchain και το Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Εισαγωγή στις αποκεντρωμένες εφαρμογές",
  "page-developers-intro-dapps-link": "Εισαγωγή στις dapps",
  "page-developers-intro-eth-link": "Εισαγωγή στο Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Εισαγωγή στο stack",
  "page-developers-intro-stack-desc": "Εισαγωγή στο Ethereum stack",
  "page-developers-js-libraries-desc": "Χρήση javascript για αλληλεπίδραση με smart contracts",
  "page-developers-js-libraries-link": "Βιβλιοθήκες Javascript",
  "page-developers-language-desc": "Χρήση Ethereum με γνωστές γλώσσες",
  "page-developers-languages": "Γλώσσες προγραμματισμού",
  "page-developers-learn": "Μάθετε την ανάπτυξη του Ethereum",
  "page-developers-learn-desc": "Διαβάστε για τις βασικές έννοιες και το Ethereum stack με τα έγγραφά μας",
  "page-developers-learn-tutorials": "Μάθετε μέσω σεμιναρίων",
  "page-developers-learn-tutorials-cta": "Προβολή σεμιναρίων",
  "page-developers-learn-tutorials-desc": "Μάθετε βήμα-βήμα την ανάπτυξη του Ethereum από κατασκευαστές που το έχουν ήδη κάνει.",
  "page-developers-meta-desc": "Τεκμηρίωση, σεμινάρια και εργαλεία για προγραμματιστές που \"χτίζουν\" πάνω στην τεχνολογία Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Πώς δημιουργούνται νέα μπλοκ και επιτυγχάνεται συναίνεση",
  "page-developers-mining-link": "Εξόρυξη",
  "page-developers-networks-desc": "Μια επισκόπηση του βασικού δικτύου και των δοκιμαστικών δικτύων",
  "page-developers-networks-link": "Δίκτυα",
  "page-developers-node-clients-desc": "Πώς επαληθεύονται τα μπλοκ και οι συναλλαγές στο δίκτυο",
  "page-developers-node-clients-link": " Κόμβοι και πελάτες",
  "page-developers-oracle-desc": "Συγκέντρωση δεδομένων εκτός αλυσίδας στα smart contracts σας",
  "page-developers-oracles-link": "Oracle",
  "page-developers-play-code": "Παιχνίδι με κώδικα",
  "page-developers-read-docs": "Διαβάστε τα έγγραφα",
  "page-developers-scaling-desc": "Λύσεις για ταχύτερες συναλλαγές",
  "page-developers-scaling-link": "Κλίμακα",
  "page-developers-smart-contract-security-desc": "Μέτρα ασφαλείας που πρέπει να ληφθούν υπόψη κατά την ανάπτυξη",
  "page-developers-smart-contract-security-link": "Ασφάλεια",
  "page-developers-set-up": "Ρύθμιση τοπικού περιβάλλοντος",
  "page-developers-setup-desc": "Ετοιμάστε το stack σας για δημιουργία διαμορφώνοντας ένα περιβάλλον ανάπτυξης.",
  "page-developers-smart-contracts-desc": "Η λογική πίσω από τις dapps - συμφωνίες άμεσης εφαρμογής",
  "page-developers-smart-contracts-link": "Smart contracts",
  "page-developers-stack": "Το stack",
  "page-developers-start": "Ξεκινήστε να πειραματίζεστε",
  "page-developers-start-desc": "Θέλετε πρώτα να πειραματιστείτε, και να κάνετε ερωτήσεις αργότερα;",
  "page-developers-storage-desc": "Πώς να χειριστείτε τον αποθηκευτικό χώρο dapp",
  "page-developers-storage-link": "Αποθηκευτικός χώρος",
  "page-developers-subtitle": "Εγχειρίδιο κατασκευαστών για το Ethereum. Από κατασκευαστές, για κατασκευαστές.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "προγραμματιστής",
  "page-developers-title-3": "πηγές",
  "page-developers-token-standards-desc": "Μια επισκόπηση των αποδεκτών προτύπων token",
  "page-developers-token-standards-link": "Πρότυπα token",
  "page-developers-transactions-desc": "Ο τρόπος με τον οποίο αλλάζει η κατάσταση του Ethereum",
  "page-developers-transactions-link": "Συναλλαγές",
  "page-developers-web3-desc": "Πώς ο κόσμος ανάπτυξης του web3 είναι διαφορετικός",
  "page-developers-web3-link": "Web2 vs Web3",
  "page-learning-tools-bootcamps": "Μαθήματα προγραμματισμού",
  "page-learning-tools-bootcamps-desc": "Δικτυακά μαθήματα επί πληρωμή για να σας κρατάνε ενήμερους, γρήγορα.",
  "page-learning-tools-browse-docs": "Περιήγηση στα έγγραφα",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "ChainShot λογότυπο",
  "page-learning-tools-coding": "Μάθετε προγραμματίζοντας",
  "page-learning-tools-coding-subtitle": "Αυτά τα εργαλεία θα σας βοηθήσουν να πειραματιστείτε με το Ethereum αν προτιμάτε μια πιο διαδραστική εμπειρία μάθησης.",
  "page-learning-tools-consensys-academy-description": "Ζωντανή συνάντηση προγραμματισμού Ethereum.",
  "page-learning-tools-consensys-academy-logo-alt": "Λογότυπο ακαδημίας ConsenSys",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Μάθετε το Solidity δημιουργώντας το δικό σας παιχνίδι Zombie.",
  "page-learning-tools-cryptozombies-logo-alt": "Λογότυπο CryptoZombies",
  "page-learning-tools-documentation": "Μάθετε με τεκμηρίωση",
  "page-learning-tools-documentation-desc": "Θέλετε να μάθετε περισσότερα; Μεταβείτε στην τεκμηρίωση μας για να βρείτε τις εξηγήσεις που χρειάζεστε.",
  "page-learning-tools-eth-dot-build-description": "Ένας εκπαιδευτικός προσωρινός χώρος για web3, συμπεριλαμβανομένου του προγραμματισμού με drag-and-drop και των δομικών μπλοκ ανοιχτού κώδικα.",
  "page-learning-tools-eth-dot-build-logo-alt": "Λογότυπο Eth.build",
  "page-learning-tools-ethernauts-description": "Ολοκληρώστε επίπεδα χάραξης κάνοντας hack smart contract.",
  "page-learning-tools-ethernauts-logo-alt": "Λογότυπο Ethereum",
  "page-learning-tools-game-tutorials": "Διαδραστικά μαθήματα με παιχνίδια",
  "page-learning-tools-game-tutorials-desc": "Μάθετε ενώ παίζετε. Αυτά τα σεμινάρια σας μαθαίνουν τα βασικά με τη χρήση παιχνιδιών.",
  "page-learning-tools-meta-desc": "Εργαλεία προγραμματισμού μέσω διαδικτύου και διαδραστικές εμπειρίες μάθησης για να σας βοηθήσουν να πειραματιστείτε με τον προγραμματισμό του Ethereum.",
  "page-learning-tools-meta-title": "Εργαλεία εκμάθησης προγραμματισμού",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Αναπτύξετε, διαμοιράστε και διαχειριστείτε Smart Contract για το Ethereum. Ακολουθήστε τους οδηγούς με το πρόσθετο Learneth.",
  "page-learning-tools-remix-description-2": "Το Remix δεν είναι απλά ένας ασφαλής χώρος (sandbox). Πολλοί προγραμματιστές γράφουν, μετατρέπουν και αναπτύσσουν τα Smart Contract τους, χρησιμοποιώντας το Remix.",
  "page-learning-tools-remix-logo-alt": "Λογότυπο Remix",
  "page-learning-tools-sandbox": "Προγραμματίστε σε ασφαλή περιβάλλον",
  "page-learning-tools-sandbox-desc": "Αυτά τα λογισμικά ασφαλούς περιβάλλοντος (sandbox), σας παρέχουν ένα χώρο για να πειραματιστείτε με τη συγγραφή των Smart Contract και την καλύτερη κατανόηση του Ethereum.",
  "page-learning-tools-studio-description": "Ένα IDE με χρήση μέσω περιηγητή, όπου μπορείτε να ακολουθήσετε τα μαθήματα για να δημιουργήσετε και να δοκιμάσετε Smart Contract και να δημιουργήσετε ένα frontend γι 'αυτά.",
  "page-learning-tools-vyperfun-description": "Μάθετε το Vyper δημιουργώντας το δικό σας παιχνίδι Pokemon.",
  "page-learning-tools-vyperfun-logo-alt": "Λογότυπο Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Ένα πλαίσιο ανάπτυξης και δοκιμών με βάση την Python για smart contracts που στοχεύουν την εικονική μηχανή Ethereum.",
  "page-local-environment-brownie-logo-alt": "Λογότυπο Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Η πλήρη πλατφόρμα προγραμματισμού για την οικοδόμηση και την ανάπτυξη αποκεντρωμένων εφαρμογών.",
  "page-local-environment-embark-logo-alt": "Λογότυπο Embark",
  "page-local-environment-epirus-desc": "Μια πλατφόρμα ανάπτυξης, ελέγχου και παρακολούθησης εφαρμογών blockchain στην Java Virtual Machine",
  "page-local-environment-epirus-logo-alt": "Λογότυπο Epirus",
  "page-local-environment-eth-app-desc": "Δημιουργήστε δυναμικές εφαρμογές Ethereum με μία εντολή. Έρχεται με ένα ευρύ φάσμα προτύπων για UI και DeFi για να επιλέξετε.",
  "page-local-environment-eth-app-logo-alt": "Λογότυπο δημιουργία Eth App",
  "page-local-environment-framework-feature-1": "Χαρακτηριστικά για να εφαρμόσετε μια τοπική λειτουργία κεντρικής αλυσίδας.",
  "page-local-environment-framework-feature-2": "Βοηθήματα για την συγκρότηση και δοκιμή των smart contracts σας.",
  "page-local-environment-framework-feature-3": "Πρόσθετα ανάπτυξης πελατών για την κατασκευή της εφαρμογής που χρησιμοποιεί ο χρήστης στο ίδιο έργο/αποθετήριο.",
  "page-local-environment-framework-feature-4": "Ρύθμιση παραμέτρων για τη σύνδεση με το δίκτυο του Ethereum και την ανάπτυξη συμβάσεων, είτε σε μια τοπική εγκατάσταση ή σε ένα από τα δημόσια δίκτυα του Ethereum.",
  "page-local-environment-framework-feature-5": "Αποκεντρωμένη διανομή εφαρμογών - ενοποιήσεων με επιλογές αποθήκευσης όπως το IPFS.",
  "page-local-environment-framework-features": "Αυτά τα πλαίσια παρέχονται με διάφορες λειτουργίες όπως:",
  "page-local-environment-frameworks-desc": " Σας συνιστούμε να επιλέξετε ένα πλαίσιο, ειδικά αν μόλις ξεκινήσατε. Η δημιουργία μιας ολοκληρωμένης αποκεντρωμένης εφαρμογής απαιτεί διαφορετικά κομμάτια τεχνολογίας. Τα πλαίσια περιλαμβάνουν πολλά από τα απαραίτητα χαρακτηριστικά ή παρέχουν εύκολα συστήματα πρόσθετων για να επιλέξετε τα εργαλεία που επιθυμείτε.",
  "page-local-environment-frameworks-title": "Πλαίσια και προρυθμισμένες δομές λογισμικού",
  "page-local-environment-hardhat-desc": "Το Hardhat είναι ένα περιβάλλον ανάπτυξης Ethereum για επαγγελματίες.",
  "page-local-environment-hardhat-logo-alt": "Λογότυπο Hardhat",
  "page-local-environment-openZeppelin-desc": "Εξοικονομήστε χρόνο προγραμματισμού για τη μεταγλώττιση, αναβάθμιση, εγκατάσταση και αλληλεπίδραση με έξυπνα συμβόλαια με τη γραμμή εντολών μας.",
  "page-local-environment-openZeppelin-logo-alt": "Λογότυπο OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Εφαρμογές Hardhat και Create Eth: όλα όσα χρειάζεστε για να ξεκινήσετε τη δημιουργία αποκεντρωμένων εφαρμογών για λειτουργία από έξυπνα συμβόλαια",
  "page-local-environment-scaffold-eth-logo-alt": "Λογότυπο scaffold-eth",
  "page-local-environment-setup-meta-desc": "Ένας οδηγός για να επιλέξετε τη στοίβα λογισμικού για ανάπτυξη στο Ethereum.",
  "page-local-environment-setup-meta-title": "Εγκατάσταση τοπικής ανάπτυξης Ethereum",
  "page-local-environment-setup-subtitle": "Αν είστε έτοιμοι να ξεκινήσετε τη δημιουργία, ήρθε η ώρα να επιλέξετε τη δομή δεδομένων σας.",
  "page-local-environment-setup-subtitle-2": " Δείτε παρακάτω τα εργαλεία και τα πλαίσια που μπορείτε να χρησιμοποιήσετε για να σας βοηθήσουν να δημιουργήσετε την Ethereum εφαρμογή σας.",
  "page-local-environment-setup-title": "Εγκαταστήστε το τοπικό σας περιβάλλον ανάπτυξης",
  "page-local-environment-solidity-template-desc": "Ένα πρότυπο του GitHub με προρυθμισμένη εγκατάσταση έξυπνου συμβολαίου Solidity. Περιλαμβάνει ένα τοπικό δίκτυο Hardhat, Waffle για δοκιμές, Ethers για εφαρμογή πορτοφολιού και πολλά άλλα.",
  "page-local-environment-solidity-template-logo-alt": "Λογότυπο προτύπου Solidity",
  "page-local-environment-truffle-desc": "Η σουίτα Truffle μεταφέρει την ιδέα των προγραμματιστών σε αποκεντρωμένη εφαρμογή όσο το δυνατόν πιο εύκολα.",
  "page-local-environment-truffle-logo-alt": "Λογότυπο Truffle",
  "page-local-environment-waffle-desc": "Η πιο προηγμένη βιβλιοθήκη δοκιμών για έξυπνα συμβόλαια. Χρησιμοποιείστε το μόνο του ή με τα Scaffold-eth ή Hardhat.",
  "page-local-environment-waffle-logo-alt": "Λογότυπο Waffle",
  "comp-tutorial-metadata-minute-read": "λεπτά ανάγνωσης",
  "page-tutorial-listing-policy-intro": "Πριν υποβάλετε έναν οδηγό παρακαλούμε διαβάστε την πολιτική δημοσίευσής μας.",
  "comp-tutorial-metadata-tip-author": "Συντάκτης",
  "page-tutorial-listing-policy": "πολιτική καταχώρησης άρθρων",
  "page-tutorial-new-github": "Νέος στο GitHub;",
  "page-tutorial-new-github-desc": "Αναφορά σφάλματος - απλά συμπληρώστε τις πληροφορίες που απαιτούνται και επικολλήστε τον οδηγό σας.",
  "page-tutorial-pull-request": "Δημιουργήστε ένα αίτημα ελέγχου αλλαγών (pull request)",
  "page-tutorial-pull-request-btn": "Δημιουργήστε αίτημα ελέγχου αλλαγών (pull request)",
  "page-tutorial-pull-request-desc-1": "Παρακαλούμε ακολουθήστε τη",
  "page-tutorial-pull-request-desc-2": "tutorials/your-tutorial-name/index.md",
  "page-tutorial-pull-request-desc-3": "δομή ονομασίας.",
  "page-tutorial-raise-issue-btn": "Αναφορά σφάλματος",
  "page-tutorial-read-time": "λεπτά",
  "page-tutorial-submit-btn": "Υποβολή οδηγού",
  "page-tutorial-submit-tutorial": "Για να υποβάλετε έναν οδηγό, θα πρέπει να χρησιμοποιήσετε το GitHub. Σας προτρέπουμε να δημιουργήσετε ένα ζήτημα ή ένα αίτημα αλλαγών (pull request).",
  "page-tutorial-subtitle": "Καλώς ήρθατε στην επιμελημένη λίστα των εκπαιδευτικών σεμιναρίων της κοινότητας μας.",
  "page-tutorial-tags-error": "Δεν υπάρχουν οδηγοί με όλες αυτές τις ετικέτες",
  "page-tutorial-title": "Μαθήματα προγραμματισμού Ethereum",
  "page-tutorials-meta-description": "Περιήγηση και φιλτράρισμα των ελεγμένων μαθημάτων της κοινότητας Ethereum ανά θέμα.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Μαθήματα προγραμματισμού Ethereum",
  "page-eth-buy-some": "Θέλετε να αγοράσετε μερικά Ethereum;",
  "page-eth-buy-some-desc": "Είναι συνηθισμένη η παρερμηνεία μεταξύ των εννοιών Ethereum και ETH. Το Ethereum είναι η αλυσίδα των μπλοκ (blockchain) και το ETH είναι το κύριο κρυπτονόμισμα του Ethereum και το οποίο είναι αυτό που πιθανώς ψάχνετε να αγοράσετε.",
  "page-eth-cat-img-alt": "Γραφικό του glyph ETH με ένα καλειδοσκόπιο γατών",
  "page-eth-collectible-tokens": "Συλλεκτικά νομίσματα",
  "page-eth-collectible-tokens-desc": "Νομίσματα με παραστάσεις όπως ένα συλλεκτικό αντικείμενο παιχνιδιού, ένα κομμάτι ψηφιακής τέχνης ή άλλα μοναδικά περιουσιακά στοιχεία. Συνήθως είναι γνωστά και ως μη ανταλλάξιμα νομίσματα (NFTs).",
  "page-eth-cryptography": "Ασφαλισμένο με κρυπτογράφηση",
  "page-eth-cryptography-desc": "Τα χρήματα στο Internet μπορεί να είναι καινούρια στο χώρο, αλλά είναι ασφαλή μέσω της αποδεδειγμένης κρυπτογράφησης. Έτσι προστατεύεται το πορτοφόλι σας, τα ETH και οι συναλλαγές σας. ",
  "page-eth-currency-for-apps": "Είναι το νόμισμα των εφαρμογών του Ethereum.",
  "page-eth-currency-for-future": "Ένα νόμισμα για το ψηφιακό μας μέλλον",
  "page-eth-description": "Το ETH είναι ένα κρυπτονόμισμα. Είναι σπάνιο ψηφιακό χρήμα που μπορείτε να χρησιμοποιήσετε στο διαδίκτυο - παρόμοιο με το Bitcoin. Αν είστε καινούριοι στα κρυπτονομίσματα, δείτε εδώ πως το ETH είναι διαφορετικό από τα παραδοσιακά χρήματα.",
  "page-eth-earn-interest-link": "Κερδίστε τόκους",
  "page-eth-ethhub-caption": "Συχνή ενημέρωση",
  "page-eth-ethhub-overview": "Το EthHub έχει μια εξαιρετική επισκόπηση, αν θέλετε",
  "page-eth-flexible-amounts": "Διαθέσιμο σε διάφορες ποσότητες",
  "page-eth-flexible-amounts-desc": "Το ETH διαιρείται μέχρι και σε 18 δεκαδικά ψηφία, ώστε να μην χρειάζεται να αγοράσετε 1 ολόκληρο ETH. Μπορείτε να αγοράσετε κλάσματα - τόσο μικρά όσο 0,000000000000000001 ETH αν θέλετε.",
  "page-eth-fuels": "Το ΕΤΗ τροφοδοτεί και ασφαλίζει το Ethereum",
  "page-eth-fuels-desc": "Το ETH είναι το αίμα στις φλέβες του Ethereum. Όταν στέλνετε ETH ή χρησιμοποιείτε μια εφαρμογή Ethereum, θα πληρώσετε ένα μικρό τέλος σε ETH για να χρησιμοποιήσετε το δίκτυο του Ethereum. Αυτό το τέλος είναι ένα κίνητρο για έναν εξορύκτη να επεξεργαστεί και να επαληθεύσει αυτό που προσπαθείτε να κάνετε.",
  "page-eth-fuels-desc-2": "Οι εξορύκτες είναι κάτι σαν τους καταγραφείς-φύλακες του Ethereum - ελέγχουν και αποδεικνύουν ότι κανείς δεν εξαπατεί. Οι εξορύκτες, που κάνουν αυτό το έργο, ανταμείβονται επίσης με μικρές ποσότητες νεώτερης έκδοσης ETH.ανθρακωρύχος",
  "page-eth-fuels-desc-3": "Οι εξορύκτες εργασίας κρατούν το Ethereum ασφαλές και απαλλαγμένο από τον κεντρικό έλεγχο. Με άλλα λόγια,",
  "page-eth-fuels-more-staking": "Περισσότερα για το staking",
  "page-eth-fuels-staking": "Το ETH θα γίνει ακόμα πιο σημαντικό με την αποθήκευση (staking). Όταν αποθηκεύετε τα ETH σας βοηθάτε στην ασφάλεια του Ethereum και κερδίζετε ανταμοιβές. Με αυτό το σύστημα, η απειλή της απώλειας των ETH αποθαρρύνει τις επιθέσεις.",
  "page-eth-get-eth-btn": "Αγορά ETH",
  "page-eth-gov-tokens": "Στοιχεία διακυβέρνησης",
  "page-eth-gov-tokens-desc": "Στοιχεία που αντιπροσωπεύουν την δύναμη συμμετοχής σε αποκεντρωμένες οργανώσεις.",
  "page-eth-has-value": "Γιατί το ETH έχει αξία;",
  "page-eth-has-value-desc": "Το ETH, είναι πολύτιμο με διαφορετικούς τρόπους για διαφορετικούς ανθρώπους.",
  "page-eth-has-value-desc-2": "Για τους χρήστες του Ethereum, το ETH είναι πολύτιμο επειδή επιτρέπει να πληρώνετε τα τέλη συναλλαγών.",
  "page-eth-has-value-desc-3": "Άλλοι το βλέπουν ως ένα ψηφιακό κατάστημα αξίας, επειδή η δημιουργία ενός νέου ETH επιβραδύνεται με την πάροδο του χρόνου.",
  "page-eth-has-value-desc-4": "Πιο πρόσφατα, το ETH έχει γίνει πολύτιμο για τους χρήστες οικονομικών εφαρμογών στο Ethereum. Αυτό συμβαίνει επειδή μπορείτε να χρησιμοποιήσετε το ETH ως ασφάλεια για τα δάνεια κρυπτονομισμάτων ή ως σύστημα πληρωμών.",
  "page-eth-has-value-desc-5": "Φυσικά, πολλοί το βλέπουν επίσης ως επένδυση, παρόμοια με του Bitcoin ή άλλων κρυπτονομισμάτων.",
  "page-eth-how-to-buy": "Πώς να αγοράσετε Ether",
  "page-eth-how-to-buy-caption": "Συχνή ενημέρωση",
  "page-eth-is-money": "Το ETH είναι ψηφιακό, παγκόσμιο χρήμα.",
  "page-eth-last-updated": "Ιανουάριος 2019",
  "page-eth-mining-link": "Περισσότερα για την εξόρυξη",
  "page-eth-monetary-policy": "Νομισματική πολιτική του Ethereum",
  "page-eth-more-on-ethereum-link": "Περισσότερα για το Ethereum",
  "page-eth-no-centralized": "Χωρίς κεντρικό έλεγχο ",
  "page-eth-no-centralized-desc": "Το ETH είναι αποκεντρωμένο και παγκόσμιο. Δεν υπάρχει καμιά εταιρεία ή τράπεζα που να μπορεί να αποφασίσει να τυπώσει περισσότερα ETH, ούτε να αλλάξει τους όρους χρήσης.",
  "page-eth-non-fungible-tokens-link": "Μη ανταλλάξιμα νομίσματα",
  "page-eth-not-only-crypto": "Το ETH δεν είναι το μόνο κρυπτονόμισμα στο Ethereum",
  "page-eth-not-only-crypto-desc": "Ο καθένας μπορεί να δημιουργήσει νέα είδη περιουσιακών στοιχείων και να τα ανταλλάξει στο Ethereum. Αυτά είναι γνωστά ως \"tokens\". Κάποιοι έχουν δημιουργήσει κρυπτονομίσματα με εικόνες των παραδοσιακών νομισμάτων τους, της ακίνητης περιουσία τους, της τέχνης τους, ακόμη και του εαυτού τους!",
  "page-eth-not-only-crypto-desc-2": "Το Ethereum είναι το σπιτικό χιλιάδων νομισμάτων - μερικά πιο χρήσιμα και ποιο πολύτιμα από άλλα. Οι προγραμματιστές δημιουργούν συνεχώς νέα νομίσματα που ξεκλειδώνουν νέες δυνατότητες ανοίγοντας νέες αγορές.",
  "page-eth-not-only-crypto-desc-3": "Αν θέλετε να μάθετε περισσότερα για τα νομίσματα, οι φίλοι μας στο EthHub έχουν συντάξει μερικά σημαντικά άρθρα:",
  "page-eth-open": "Ανοιχτό για όλους",
  "page-eth-open-desc": "Το μόνο που χρειάζεστε είναι μια σύνδεση στο διαδίκτυο και ένα πορτοφόλι για να δεχτείτε ETH. Δεν χρειάζεστε πρόσβαση σε τραπεζικό λογαριασμό για την αποδοχή πληρωμών. ",
  "page-eth-p2p-payments": "Πληρωμές peer-to-peer",
  "page-eth-p2p-payments-desc": "Μπορείτε να στείλετε τα ETH σας χωρίς καμία ενδιάμεση υπηρεσία όπως μια τράπεζα. Είναι σαν να δίνετε μετρητά σε κάποιον, αλλά μπορείτε να το κάνετε με ασφάλεια, οπουδήποτε και οποτεδήποτε.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Δημοφιλείς τύποι token",
  "page-eth-powers-ethereum": "το ETH είναι η δύναμη του Ethereum",
  "page-eth-shit-coins": "Sh*t coins",
  "page-eth-shit-coins-desc": "Επειδή η δημιουργία νέων tokens είναι εύκολη, ο καθένας μπορεί να το κάνει - ακόμη και άτομα με κακές ή κακόβουλες προθέσεις. Κάνετε πάντα την έρευνά σας πριν τα χρησιμοποιήσετε!",
  "page-eth-stablecoins": "Stablecoins",
  "page-eth-stablecoins-desc": "Τα tokens αντικατοπτρίζουν την αξία του παραδοσιακού νομίσματος όπως το δολάριο. Αυτό λύνει το πρόβλημα μεταβλητότητας με πολλά κρυπτονομίσματα.",
  "page-eth-stablecoins-link": "Αγοράστε stablecoins",
  "page-eth-stream-link": "Stream ETH",
  "page-eth-tokens-link": "Ethereum tokens",
  "page-eth-trade-link-2": "Ανταλλαγή tokens",
  "page-eth-underpins": "Το ETH στηρίζει το χρηματοπιστωτικό σύστημα του Ethereum",
  "page-eth-underpins-desc": "Καθώς δεν είναι ικανοποιημένη με τις πληρωμές, η κοινότητα του Ethereum χτίζει ένα ολόκληρο οικονομικό σύστημα που είναι μεταξύ των χρηστών του και προσβάσιμο σε όλους.",
  "page-eth-underpins-desc-2": "Μπορείτε να χρησιμοποιήσετε το ETH ως ασφάλεια για να δημιουργήσετε εντελώς διαφορετικά κρυπτονομίσματα στο Ethereum. Πλέον μπορείτε να δανειστείτε, να δανείσετε και να κερδίσετε τόκους σε ETH και άλλα νομίσματα που υποστηρίζονται από το ETH.",
  "page-eth-uses": "Οι χρήσεις του ETH εμπλουτίζονται καθημερινά",
  "page-eth-uses-desc": "Επειδή το Ethereum βασίζεται στον προγραμματισμό, οι προγραμματιστές μπορούν να διαμορφώσουν το ETH με αμέτρητους τρόπους.",
  "page-eth-uses-desc-2": "Πίσω στο 2015, το μόνο που θα μπορούσατε να κάνετε ήταν να στείλετε ETH από έναν λογαριασμό Ethereum σε έναν άλλο. Δείτε παρακάτω μερικά μόνο από αυτά που μπορείτε να κάνετε σήμερα.",
  "page-eth-uses-desc-3": "πληρώστε ή λάβετε χρήματα σε πραγματικό χρόνο.",
  "page-eth-uses-desc-4": "μπορείτε να ανταλλάξετε ETH με άλλα νομίσματα συμπεριλαμβανομένου του Bitcoin.",
  "page-eth-uses-desc-5": "σε ETH και άλλα με βάση το Ethereum.",
  "page-eth-uses-desc-6": "μπείτε στον κόσμο των κρυπτονομισμάτων με πιο σταθερή αξία.",
  "page-eth-value": "Γιατί ο αιθέρας είναι πολύτιμος",
  "page-eth-video-alt": "Βίντεο ανάγλυφου ETH",
  "page-eth-whats-eth": "Τι είναι το ether (ETH);",
  "page-eth-whats-eth-hero-alt": "Εικονογράφηση μιας ομάδας ανθρώπων που θαυμάζουν ένα ether (ETH) με δέος",
  "page-eth-whats-eth-meta-desc": "Τι πρέπει να γνωρίζετε και να κατανοήσετε για το ETH και τη θέση του μέσα στο Ethereum.",
  "page-eth-whats-eth-meta-title": "Τι είναι το ether (ETH);",
  "page-eth-whats-ethereum": "Τι είναι το Ethereum;",
  "page-eth-whats-ethereum-desc": "Αν θέλετε να μάθετε περισσότερα σχετικά με το Ethereum και την τεχνολογία πίσω από το ETH, δείτε την παρουσίασή μας.",
  "page-eth-whats-unique": "Ποια είναι η καινοτομία του ΕΤΗ;",
  "page-eth-whats-unique-desc": "Υπάρχουν πολλά κρυπτονομίσματα και πολλά άλλα στοιχεία (tokens) στο Ethereum, αλλά υπάρχουν κάποια πράγματα που μόνο το ETH μπορεί να κάνει.",
  "page-eth-where-to-buy": "Από πού να αγοράσετε ETH",
  "page-eth-where-to-buy-desc": "Μπορείτε να πάρετε ETH από ένα ανταλλακτήριο ή ένα πορτοφόλι. Υπάρχουν όμως διαφορετικές πολιτικές ανάλογα τη χώρα. Δείτε τις υπηρεσίες που μπορείτε να αγοράσετε ETH.",
  "page-eth-yours": "Είναι πραγματικά δικό σας",
  "page-eth-yours-desc": "Το ΕΤΗ σας επιτρέπει να διατηρείτε τη δική σας τράπεζα. Μπορείτε να ελέγχετε τα κεφάλαια σας μέσω του πορτοφολιού σας ως απόδειξη της ιδιοκτησίας - κανένα τρίτο μέρος δεν είναι απαραίτητο.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Διεύθυνση κατάθεσης κεφαλαίου Eth2",
  "page-eth2-deposit-contract-address-caption": "Προσθέσαμε κενά για να διευκολύνουμε την ανάγνωση της διεύθυνσης",
  "page-eth2-deposit-contract-address-check-btn": "Έλεγχος της διεύθυνσης κατάθεσης του κεφαλαίου",
  "page-eth2-deposit-contract-checkbox1": "Έχω ήδη χρησιμοποιήσει την πλατφόρμα εκκίνησης για τη ρύθμιση του Eth2 επικυρωτή μου.",
  "page-eth2-deposit-contract-checkbox2": "Καταλαβαίνω ότι πρέπει να χρησιμοποιήσω την πλατφόρμα εκκίνησης για την αποθήκευση (stake). Απλές συναλλαγές σε αυτή τη διεύθυνση δεν λειτουργούν.",
  "page-eth2-deposit-contract-checkbox3": "Θα ελέγξω τη διεύθυνση κατάθεσης κεφαλαίου με άλλες πηγές.",
  "page-eth2-deposit-contract-confirm-address": "Επιβεβαίωση αποκάλυψης της διεύθυνσης",
  "page-eth2-deposit-contract-copied": "Η διεύθυνση αντιγράφηκε",
  "page-eth2-deposit-contract-copy": "Αντιγραφή διεύθυνσης",
  "page-eth2-deposit-contract-etherscan": "Προβολή σύμβασης στην Etherscan",
  "page-eth2-deposit-contract-h2": "Εδώ δεν κάνετε stake",
  "page-eth2-deposit-contract-launchpad": "Πλατφόρμα έναρξης Stake",
  "page-eth2-deposit-contract-launchpad-2": "Χρήση της πλατφόρμας εκκίνησης",
  "page-eth2-deposit-contract-meta-desc": "Επαληθεύστε τη διεύθυνση κατάθεσης για το staking σε Eth2.",
  "page-eth2-deposit-contract-meta-title": "Διεύθυνση κατάθεσης κεφαλαίου Eth2",
  "page-eth2-deposit-contract-read-aloud": "Ανάγνωση δυνατά της διεύθυνσης",
  "page-eth2-deposit-contract-reveal-address-btn": "Αποκάλυψη της διεύθυνσης",
  "page-eth2-deposit-contract-staking": " Για να κάνετε stake τα ETH σας σε Eth2 θα πρέπει να χρησιμοποιήσετε την πλατφόρμα εκκίνησης ακολουθώντας τις οδηγίες. Η αποστολή ETH σε αυτή τη διεύθυνση δεν σας καθιστά staker και θα οδηγήσει σε μια αποτυχημένη συναλλαγή.",
  "page-eth2-deposit-contract-staking-check": "Ελέγξτε αυτές τις πηγές",
  "page-eth2-deposit-contract-staking-check-desc": "Αναμένουμε να υπάρχουν πολλές ψεύτικες διευθύνσεις και κακόβουλες ενέργειες. Για να είστε ασφαλείς, ελέγξτε τη διεύθυνση staking Eth2 που χρησιμοποιείτε με τη διεύθυνση σε αυτή τη σελίδα. Σας συνιστούμε να την ελέγξετε και με άλλες αξιόπιστες πηγές.",
  "page-eth2-deposit-contract-staking-more-link": "Περισσότερα για το staking",
  "page-eth2-deposit-contract-stop-reading": "Διακοπή ανάγνωσης",
  "page-eth2-deposit-contract-subtitle": " Αυτή είναι η διεύθυνση της σύμβασης staking Eth2. Χρησιμοποιήστε αυτή τη σελίδα για να επιβεβαιώσετε ότι στέλνετε κεφάλαια στη σωστή διεύθυνση όταν κάνετε stake.",
  "page-eth2-deposit-contract-warning": "Ελέγξτε προσεκτικά κάθε χαρακτήρα.",
  "page-eth2-deposit-contract-warning-2": "Η αποστολή κεφαλαίων σε αυτή τη διεύθυνση δεν θα λειτουργήσει και δεν θα σας κάνει staker. Πρέπει να ακολουθήσετε τις οδηγίες στην πλατφόρμα εκκίνησης.",
  "page-eth2-bug-bounty-annotated-specs": "σχολιασμός προδιαγραφών",
  "page-eth2-bug-bounty-annotations": "Θα σας φανεί χρήσιμο να ελέγξετε τις παρακάτω σημειώσεις:",
  "page-eth2-bug-bounty-client-bugs": "Σφάλματα πελατών Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Οι εφαρμογές πελάτη θα εκτελέσουν την κεντρική αλυσίδα με τη δημοσίευση της αναβάθμισης. Θα πρέπει να ακολουθήσουν τη λογική λειτουργίας όπως ορίζεται στις προδιαγραφές και να είναι ασφαλές ενάντια σε πιθανές επιθέσεις. Τα σφάλματα που θέλουμε να εντοπίσουμε σχετίζονται κυρίως με την εφαρμογή του πρωτοκόλλου.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Μόνο τα σφάλματα από τα Lighthouse, Nimbus, Teku και Prysm γίνονται αποδεκτά μέχρι στιγμής. Περισσότερες εφαρμογές πελάτη θα προστεθούν με την ολοκλήρωση των ελέγχων τους και τη διαθεσιμότητά τους για πλήρη λειτουργία.",
  "page-eth2-bug-bounty-clients": "Χαρακτηριστικά πελατών του προγράμματος ελέγχου σφαλμάτων",
  "page-eth2-bug-bounty-clients-type-1": "ζητήματα μη συμμόρφωσης προδιαγραφών.",
  "page-eth2-bug-bounty-clients-type-2": "μη αναμενόμενες καταρρεύσεις ή ευπάθειες σε άρνηση υπηρεσίας (denial of service (DOS)).",
  "page-eth2-bug-bounty-clients-type-3": "τυχόν ζητήματα που προκαλούν ανεπανόρθωτο διαχωρισμό από το υπόλοιπο δίκτυο.",
  "page-eth2-bug-bounty-docking": "ενσωμάτωση",
  "page-eth2-bug-bounty-email-us": "Στείλτε μας email:",
  "page-eth2-bug-bounty-help-links": "Χρήσιμοι σύνδεσμοι",
  "page-eth2-bug-bounty-hunting": "Κανόνες αναζήτησης σφαλμάτων",
  "page-eth2-bug-bounty-hunting-desc": "Το πρόγραμμα αναζήτησης σφαλμάτων είναι ένα πειραματικό και διακριτικό πρόγραμμα ανταμοιβών για την ενεργή κοινότητα του Ethereum για να ενθαρρύνει και να ανταμείψει όσους βοηθούν στη βελτίωση της πλατφόρμας. Δεν πρόκειται για ανταγωνισμό. Θα πρέπει να γνωρίζετε ότι μπορούμε να ακυρώσουμε το πρόγραμμα ανά πάσα στιγμή και τα βραβεία είναι στη διακριτική ευχέρεια του Ιδρύματος Ethereum. Επιπλέον, δεν είμαστε σε θέση να χορηγήσουμε βραβεία σε άτομα που βρίσκονται σε καταλόγους κυρώσεων ή που βρίσκονται σε χώρες που βρίσκονται σε καταλόγους κυρώσεων (π. χ. Βόρεια Κορέα, Ιράν κ.λπ.). Είστε υπεύθυνοι για την απόδοση τυχόν φόρων. Όλα τα βραβεία υπόκεινται στην ισχύουσα νομοθεσία. Τέλος, οι ενέργειές σας δεν θα πρέπει να παραβιάζουν κανένα νόμο ή να θέτουν σε κίνδυνο δεδομένα που δεν σας ανήκουν.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Πίνακας κατάταξης στον εντοπισμό σφαλμάτων",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Εντοπίστε σφάλματα του Eth2 και κατακτήστε μια θέση στον πίνακα κατάταξης",
  "page-eth2-bug-bounty-hunting-li-1": "Τα σφάλματα που έχουν ήδη αναφερθεί από άλλους χρήστες ή είναι ήδη γνωστά στην ομάδα ελέγχου και συντήρησης δεν θα λαμβάνουν ανταμοιβή.",
  "page-eth2-bug-bounty-hunting-li-2": "Η δημοσιοποίηση μιας ευπάθειας την καθιστά εκτός της διαδικασίας εντοπισμού.",
  "page-eth2-bug-bounty-hunting-li-3": "Οι ερευνητές και το προσωπικό των ομάδων των πελατών Eth2 του Ιδρύματος Ethereum δεν μπορούν να συμμετέχουν στο πρόγραμμα ανταμοιβών.",
  "page-eth2-bug-bounty-hunting-li-4": "Το πρόγραμμα ανταμοιβών του Ethereum εξετάζει μια σειρά μεταβλητών για τον καθορισμό των ανταμοιβών. Ο προσδιορισμός των κανόνων, της βαθμολογίας και των όρων που σχετίζονται με ένα βραβείο είναι κατά την αποκλειστική και διακριτική ευχέρεια του Ιδρύματος Ethereum.",
  "page-eth2-bug-bounty-leaderboard": "Δείτε την πλήρη κατάταξη",
  "page-eth2-bug-bounty-leaderboard-points": "πόντοι",
  "page-eth2-bug-bounty-ledger-desc": "Οι προδιαγραφές της κεντρικής αλυσίδας περιγράφουν λεπτομερώς το σκεπτικό του σχεδιασμού και τις προτεινόμενες αλλαγές στο Ethereum μέσω των αναβαθμίσεων της κεντρικής αλυσίδας.",
  "page-eth2-bug-bounty-ledger-title": "Οι προδιαγραφές σφαλμάτων της κεντρικής αλυσίδας",
  "page-eth2-bug-bounty-meta-description": "Μια επισκόπηση του προγράμματος αναζήτησης σφάλματος Eth2: πώς να συμμετέχετε και να ανταμειφθείτε για τις πληροφορίες.",
  "page-eth2-bug-bounty-meta-title": "Πρόγραμμα αναζήτησης σφαλμάτων Eth2 με αμοιβή",
  "page-eth2-bug-bounty-not-included": "Δεν συμπεριλαμβάνεται",
  "page-eth2-bug-bounty-not-included-desc": "Η αλυσίδα τομέα και οι αναβαθμίσεις ενσωμάτωσης είναι ακόμα υπό ανάπτυξη και έτσι δεν συμμετέχουν ακόμη στο πρόγραμμα αναζήτησης σφαλμάτων.",
  "page-eth2-bug-bounty-owasp": "Προβολή μεθόδου OWASP",
  "page-eth2-bug-bounty-points": "Το EF θα απονέμει επίσης πόντους με βάση τα εξής:",
  "page-eth2-bug-bounty-points-error": "Σφάλμα φόρτωσης δεδομένων... παρακαλούμε ανανεώστε.",
  "page-eth2-bug-bounty-points-exchange": "Ανταλλαγή Πόντων",
  "page-eth2-bug-bounty-points-loading": "Φόρτωση δεδομένων...",
  "page-eth2-bug-bounty-points-payout-desc": " Το Ίδρυμα Ethereum θα καταβάλει την αξία των δολαρίων ΗΠΑ σε ETH ή DAI.",
  "page-eth2-bug-bounty-points-point": "1 πόντος",
  "page-eth2-bug-bounty-points-rights-desc": "Το Ίδρυμα Ethereum διατηρεί το δικαίωμα να αλλάξει τους όρους χωρίς προειδοποίηση.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Ποιότητα περιγραφής",
  "page-eth2-bug-bounty-quality-desc": ": Δίδονται υψηλότερες ανταμοιβές για σαφείς και καλογραμμένες περιγραφές.",
  "page-eth2-bug-bounty-quality-fix": "Ποιότητα διόρθωσης",
  "page-eth2-bug-bounty-quality-fix-desc": ", εάν περιλαμβάνεται: δίδονται υψηλότερες ανταμοιβές για τα κείμενα με σαφή περιγραφή του τρόπου διόρθωσης του σφάλματος.",
  "page-eth2-bug-bounty-quality-repro": "Ποιότητα αναπαραγωγιμότητας",
  "page-eth2-bug-bounty-quality-repro-desc": ": Παρακαλούμε να συμπεριλάβετε τον δοκιμαστικό κώδικα, τα σενάρια και τις λεπτομερείς οδηγίες. Όσο πιο εύκολο είναι για εμάς να αναπαράγουμε και να επαληθεύουμε την ευπάθεια που περιγράφετε, τόσο μεγαλύτερη θα είναι η ανταμοιβή σας.",
  "page-eth2-bug-bounty-questions": "Ερωτήσεις;",
  "page-eth2-bug-bounty-rules": "Όροι συμμετοχής",
  "page-eth2-bug-bounty-shard-chains": "οι αλυσίδες τομέα (shard chains)",
  "page-eth2-bug-bounty-slogan": "Αναζήτηση σφαλμάτων Eth2",
  "page-eth2-bug-bounty-specs": "Διαβάστε τις πλήρεις προδιαγραφές",
  "page-eth2-bug-bounty-specs-docs": "Έγγραφα προδιαγραφών",
  "page-eth2-bug-bounty-submit": "Αναφορά σφάλματος",
  "page-eth2-bug-bounty-submit-desc": "Για κάθε σφάλμα που εντοπίζετε ανταμείβεστε με πόντους. Οι πόντοι που κερδίζετε εξαρτώνται από τη σοβαρότητα του σφάλματος. Το Ίδρυμα Ethereum (ΙE) καθορίζει τη σοβαρότητα χρησιμοποιώντας τη μέθοδο OWASP.",
  "page-eth2-bug-bounty-subtitle": "Κερδίστε έως και $50.000 USD και μια θέση στον πίνακα κατάταξης με την εύρεση του πρωτοκόλλου Eth2 και των σφαλμάτων των εφαρμογών πελάτη.",
  "page-eth2-bug-bounty-title": "Διαθέσιμο για προτάσεις",
  "page-eth2-bug-bounty-title-1": "Η κύρια αλυσίδα",
  "page-eth2-bug-bounty-title-2": "Fork choice",
  "page-eth2-bug-bounty-title-3": "Συμβόλαιο κατάθεσης Solidity",
  "page-eth2-bug-bounty-title-4": "Δίκτυο peer-to-peer",
  "page-eth2-bug-bounty-type-1": "σφάλματα παραβίασης ασφάλειας/ολοκλήρωσης.",
  "page-eth2-bug-bounty-type-2": "φορείς denial of service (DOS)",
  "page-eth2-bug-bounty-type-3": "ασυνέπειες σε παραδοχές, όπως καταστάσεις όπου μπορούν να μειωθούν οι επιβεβαιωμένοι επικυρωτές.",
  "page-eth2-bug-bounty-type-4": "ασυνέπειες υπολογισμού ή παραμέτρων.",
  "page-eth2-bug-bounty-types": "Τύποι σφαλμάτων",
  "page-eth2-bug-bounty-validity": "Αποδεκτά σφάλματα",
  "page-eth2-bug-bounty-validity-desc": "Το πρόγραμμα αναζήτησης σφαλμάτων επικεντρώνεται στην εύρεση σφαλμάτων στον πυρήνα των προδιαγραφών της Κύριας αλυσίδας του Eth2 και των εφαρμογών πελάτη Prysm, Lighthouse, Teku.",
  "page-eth2-bug-bountycard-critical": "Κρίσιμο",
  "page-eth2-bug-bountycard-critical-risk": "Αναφορά κρίσιμου σφάλματος",
  "page-eth2-bug-bountycard-h2": "Μεσαίο",
  "page-eth2-bug-bountycard-high": "Υψηλό",
  "page-eth2-bug-bountycard-high-risk": "Αναφορά υψηλού σφάλματος",
  "page-eth2-bug-bountycard-label-1": "Έως 1.000 πόντους",
  "page-eth2-bug-bountycard-label-2": "Έως 2.000 DAI",
  "page-eth2-bug-bountycard-label-3": "Έως 5.000 πόντους",
  "page-eth2-bug-bountycard-label-4": "Έως 10.000 DAI",
  "page-eth2-bug-bountycard-label-5": "Έως 10.000 πόντους",
  "page-eth2-bug-bountycard-label-6": "Έως 20.000 DAI",
  "page-eth2-bug-bountycard-label-7": "Έως 25.000 πόντους",
  "page-eth2-bug-bountycard-label-8": "Έως 50.000 DAI",
  "page-eth2-bug-bountycard-li-1": "Χαμηλού επηρεασμού, μεσαία πιθανότητα",
  "page-eth2-bug-bountycard-li-2": "Μεσαίου επηρεασμού, χαμηλή πιθανότητα",
  "page-eth2-bug-bountycard-li-3": "Υψηλού επηρεασμού, χαμηλή πιθανότητα",
  "page-eth2-bug-bountycard-li-4": "Μεσαίου επηρεασμού, μεσαία πιθανότητα",
  "page-eth2-bug-bountycard-li-5": "Χαμηλού επηρεασμού, υψηλή πιθανότητα",
  "page-eth2-bug-bountycard-li-6": "Υψηλού επηρεασμού, μεσαία πιθανότητα",
  "page-eth2-bug-bountycard-li-7": "Μεσαίου επηρεασμού, υψηλή πιθανότητα",
  "page-eth2-bug-bountycard-li-8": "Υψηλού επηρεασμού, υψηλή πιθανότητα",
  "page-eth2-bug-bountycard-low": "Χαμηλό",
  "page-eth2-bug-bountycard-low-risk": "Αναφορά σφάλματος χαμηλού κινδύνου",
  "page-eth2-bug-bountycard-medium-risk": "Αναφορά σφάλματος μεσαίου κινδύνου",
  "page-eth2-bug-bountycard-subheader": "Επικινδυνότητα",
  "page-eth2-bug-bountycard-subheader-2": "Παράδειγμα",
  "page-eth2-bug-bountycard-text": "Ο κακόβουλος χρήστης μπορεί μερικές φορές να θέσει έναν κόμβο σε κατάσταση που θα τον αναγκάζει να καταρρεύσει, κάθε εκατό επιβεβαιώσεις που έλαβε από ένα επικυρωτή",
  "page-eth2-bug-bountycard-text-1": " Ένας κακόβουλος μπορεί να διεξάγει με επιτυχία επιθέσεις έκλειψης σε κόμβους με χρήση των 4 ομότιμων κορυφαίων μηδενικών byte",
  "page-eth2-bug-bountycard-text-2": "Υπάρχει ένα συναινετικό σφάλμα μεταξύ δύο εφαρμογών πελάτη και είναι δύσκολο ή μη πρακτικό για τον εισβολέα να ενεργοποιήσει το συμβάν.",
  "page-eth2-bug-bountycard-text-3": "  Υπάρχει ένα συναινετικό σφάλμα μεταξύ των δύο εφαρμογών πελάτη και είναι ασήμαντο για τον εισβολέα να ενεργοποιήσει το συμβάν.",
  "page-eth2-get-involved-btn-1": "Δείτε τις εφαρμογές πελάτη",
  "page-eth2-get-involved-btn-2": "Περισσότερα για το staking",
  "page-eth2-get-involved-btn-3": "Βρείτε σφάλματα",
  "page-eth2-get-involved-bug": "Ένα σφάλμα μπορεί να είναι:",
  "page-eth2-get-involved-bug-hunting": "Ξεκινήστε το κυνήγι σφαλμάτων",
  "page-eth2-get-involved-bug-hunting-desc": "Εντοπίστε και αναφέρετε σφάλματα στις προδιαγραφές αναβάθμισης Eth2 ή για τις εφαρμογές πελάτη. Μπορείτε να κερδίσετε έως και $50.000 USD και μια θέση στον πίνακα κατάταξης.",
  "page-eth2-get-involved-bug-li": "ζητήματα μη συμμόρφωσης προδιαγραφών",
  "page-eth2-get-involved-bug-li-2": "σφάλματα κατά την ολοκλήρωση",
  "page-eth2-get-involved-bug-li-3": "φορείς denial of service (DOS)",
  "page-eth2-get-involved-bug-li-4": "και πολλά άλλα...",
  "page-eth2-get-involved-date": "Ημερομηνία λήξης: 23 Δεκεμβρίου 2020",
  "page-eth2-get-involved-desc-1": "Η εκτέλεση ενός πελάτη σημαίνει ότι θα είστε ένας ενεργός χρήστης στο Ethereum. Το λογισμικό πελάτη σας, θα σας βοηθήσει να παρακολουθείτε τις συναλλαγές και να ελέγχετε τα νέα μπλοκ.",
  "page-eth2-get-involved-desc-2": "Εάν έχετε ETH, μπορείτε να τα αποθηκεύσετε (stake) για να γίνετε επικυρωτής και να βοηθήσετε στην ασφάλεια του δικτύου. Ως επικυρωτής μπορείτε να κερδίσετε ανταμοιβές σε ETH.",
  "page-eth2-get-involved-desc-3": "Γίνετε μέλος της κοινότητας και βοηθήστε σε ελέγχους! Δοκιμάστε τις αναβαθμίσεις Eth2 πριν αποσταλούν, βρείτε σφάλματα και κερδίστε ανταμοιβές.",
  "page-eth2-get-involved-ethresearch-1": "Sharding",
  "page-eth2-get-involved-ethresearch-2": "Μετάβαση του Eth1 σε Eth2",
  "page-eth2-get-involved-ethresearch-3": "Πρόοδος κατάστασης και Shards",
  "page-eth2-get-involved-ethresearch-4": "Όλα τα θέματα έρευνας",
  "page-eth2-get-involved-grants": "Προσφορά επιχορηγήσεων στην κοινότητα",
  "page-eth2-get-involved-grants-desc": "Βοηθήστε στη δημιουργία εργαλείων και εκπαιδευτικού περιεχομένου για την κοινότητα staking",
  "page-eth2-get-involved-how": "Πώς θέλετε να συμμετέχετε;",
  "page-eth2-get-involved-how-desc": "Η κοινότητα του Ethereum θα ωφελείται πάντοτε από όσο περισσότερους χρήστες εκτελούν ένα λογισμικό πελάτη, αποθήκευσης ή με την εύρεση και αναφορά σφαλμάτων.",
  "page-eth2-get-involved-join": "Συμμετέχετε στην έρευνα",
  "page-eth2-get-involved-join-desc": " Όπως τα περισσότερα πράγματα με το Ethereum, έτσι πολλά από την έρευνα είναι δημόσια. Αυτό σημαίνει ότι μπορείτε να συμμετάσχετε στις συζητήσεις ή απλά να διαβάσετε τι έχουν να πουν οι ερευνητές του Ethereum στο κανάλι say.ethresear.ch όπου δεν καλύπτει μόνο θέματα από τις αναβαθμίσεις Eth2, αλλά υπάρχει και μεγάλη εστίαση γενικά για το Eth2.",
  "page-eth2-get-involved-meta-description": "Πώς να συμμετάσχετε στο Eth2: εκτελέστε κόμβους, αποθηκεύστε, εντοπίστε σφάλματα και πολλά άλλα.",
  "page-eth2-get-involved-more": "Περισσότερες πληροφορίες",
  "page-eth2-get-involved-run-clients": "Εκτέλεση πελατών κύριας αλυσίδας",
  "page-eth2-get-involved-run-clients-desc": "Το κλειδί για τη μακροπρόθεσμη ασφάλεια του Ethereum είναι μια ισχυρή διανομή πελατών. Ένας πελάτης είναι λογισμικό που τρέχει την κύρια αλυσίδα, ελέγχει τις συναλλαγές και τη δημιουργία των νέων μπλοκ. Μπορείτε να επιλέξετε λογισμικό πελάτη σύμφωνα με τα χαρακτηριστικά που επιθυμείτε.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Αποθηκεύστε τα ΕΤΗ σας",
  "page-eth2-get-involved-stake-desc": "Μπορείτε να αποθηκεύσετε τα ETH σας για να βοηθήσετε στην ασφάλεια της αλυσίδας αναφοράς (beacon chain).",
  "page-eth2-get-involved-stake-eth": "Αποθήκευση ΕΤΗ",
  "page-eth2-get-involved-subtitle": "Δείτε παρακάτω τους τρόπους που μπορείτε να βοηθήσετε το Ethereum και τις μελλοντικές προσπάθειες που σχετίζονται με το Eth2.",
  "page-eth2-get-involved-title-1": "Εκτελέστε μια εφαρμογή πελάτη",
  "page-eth2-get-involved-title-2": "Αποθηκεύστε τα ΕΤΗ σας",
  "page-eth2-get-involved-title-3": "Βρείτε σφάλματα",
  "page-eth2-get-involved-written-go": "Γράφτηκε στην Go",
  "page-eth2-get-involved-written-java": "Γράφτηκε στην Java",
  "page-eth2-get-involved-written-javascript": "Γράφτηκε στη JavaScript",
  "page-eth2-get-involved-written-net": "Γράφτηκε στη .NET",
  "page-eth2-get-involved-written-nim": "Γράφτηκε στη Nim",
  "page-eth2-get-involved-written-python": "Γράφτηκε στην Python",
  "page-eth2-get-involved-written-rust": "Γράφτηκε στη Rust",
  "eth2-client-cortex-logo-alt": "Λογότυπο Cortex",
  "eth2-client-lighthouse-logo-alt": "Λογότυπο Lighthouse",
  "eth2-client-lodestar-logo-alt": "Λογότυπο Lodestar",
  "eth2-client-nimbus-logo-alt": "Λογότυπο Nimbus",
  "eth2-client-prysm-logo-alt": "Λογότυπο Prysm",
  "eth2-client-teku-logo-alt": "Λογότυπο Teku",
  "eth2-rhino-img-alt": "Γραφική παράσταση ενός ρινόκερου με το Eth 2",
  "page-eth2-answer-1": "Σκεφτείτε το Eth2 ως ένα σύνολο αναβαθμίσεων που προστίθενται για τη βελτίωση του Ethereum που χρησιμοποιούμε σήμερα. Αυτές οι αναβαθμίσεις περιλαμβάνουν τη δημιουργία μιας νέας αλυσίδας που ονομάζεται κύρια αλυσίδα (beacon chain) και μέχρι 64 αλυσίδες γνωστές ως shards.",
  "page-eth2-answer-2": "Αυτά είναι ξεχωριστά από το κεντρικό δίκτυο του Ethereum που χρησιμοποιούμε σήμερα, αλλά δεν θα το αντικαταστήσουν. Αντ 'αυτού το κεντρικό δίκτυο θα προσδεθεί ή θα «συγχωνευθεί» με αυτό το παράλληλο σύστημα όπου θα ενσωματώνεται με την πάροδο του χρόνου.",
  "page-eth2-answer-4": "Με άλλα λόγια, το Ethereum που χρησιμοποιούμε σήμερα θα ενσαρκώσει τελικά όλα τα χαρακτηριστικά που οραματιζόμαστε για το Eth2.",
  "page-eth2-beacon-chain-btn": "Περισσότερα για την κύρια αλυσίδα",
  "page-eth2-beacon-chain-date": "Η κύρια αλυσίδα (Beacon Chain) ανακοινώθηκε στις 1 Δεκεμβρίου 2020.",
  "page-eth2-beacon-chain-desc": "Η πρώτη προσθήκη στο οικοσύστημα σχετικά με το Eth2. Η κύρια αλυσίδα προσθέτει και τη λειτουργία της αποθήκευσης στο Ethereum, θέτοντας τα θεμέλια για μελλοντικές αναβαθμίσεις όπου και τελικά θα συντονίσει το νέο σύστημα.",
  "page-eth2-beacon-chain-estimate": "Η κύρια αλυσίδα είναι πραγματικότητα",
  "page-eth2-beacon-chain-title": "Η Beacon Chain",
  "page-eth2-bug-bounty": "Δείτε το πρόγραμμα εύρεσης σφαλμάτων",
  "page-eth2-clients": "Δείτε τις εφαρμογές πελάτη Eth2",
  "page-eth2-deposit-contract-title": "Ελέγξτε τη διεύθυνση κατάθεσης του κεφαλαίου",
  "page-eth2-diagram-ethereum-mainnet": "Κεντρικό δίκτυο Ethereum",
  "page-eth2-diagram-h2": "Με ποιο τρόπο θα ενσωματωθούν οι αναβαθμίσεις",
  "page-eth2-diagram-link-1": "Περισσότερα για απόδειξη της εργασίας (proof of work)",
  "page-eth2-diagram-link-2": "Περισσότερα για τις αλυσίδες τομέα (shard chains)",
  "page-eth2-diagram-mainnet": "Κεντρικό δίκτυο",
  "page-eth2-diagram-p": "Το κεντρικό δίκτυο του Ethereum θα συνεχίσει να υπάρχει στην τρέχουσα μορφή του για λίγο διάστημα. Αυτό σημαίνει ότι η κύρια αλυσίδα και οι αναβαθμίσεις των shard δεν θα επηρεάσουν το δίκτυο.",
  "page-eth2-diagram-p-1": "Το κεντρικό δίκτυο θα συγχωνευθεί τελικά με το νέο σύστημα καθοδηγούμενο από τις αναβαθμίσεις Eth2.",
  "page-eth2-diagram-p-2": "Η κύρια αλυσίδα θα καταστεί το κεντρικό σύστημα του Ethereum, υπεύθυνο για τον συντονισμό των επικυρωτών και καθορίζοντας το ρυθμό δημιουργίας των μπλοκ.",
  "page-eth2-diagram-p-3": "Αρχικά, θα λειτουργεί ξεχωριστά από το κεντρικό δίκτυο και θα διαχειρίζεται τους επικυρωτές – δεν θα έχει καμία σχέση με τις έξυπνες συμβάσεις, τις συναλλαγές ή τους λογαριασμούς.",
  "page-eth2-diagram-p-4": "Τα shards θα παρέχουν περισσότερα δεδομένα ώστε να βοηθήσουν στην αύξηση του αριθμού των συναλλαγών του κεντρικού δικτύου που μπορεί να διαχειριστεί. Θα συντονίζονται από την κύρια αλυσίδα.",
  "page-eth2-diagram-p-5": "Όλες οι συναλλαγές θα εξακολουθούν να βασίζονται στο κεντρικό δίκτυο, το οποίο θα συνεχίσει να υπάρχει όπως το γνωρίζουμε έως σήμερα – ασφαλίζοντας το μέσω της απόδειξης εργασίας (proof of work) και των ανθρακωρύχων.",
  "page-eth2-diagram-p-6": "Το κεντρικό δίκτυο θα συγχωνευθεί με το σύστημα της απόδειξης-συμμετοχής, το οποίο συντονίζεται από την κύρια αλυσίδα.",
  "page-eth2-diagram-p-8": "Έτσι το κεντρικό δίκτυο θα μετατραπεί σε shard (τομέας) μέσα στο νέο σύστημα. Οι εξορυκτές ή κρυπτορύχοι δεν θα είναι πλέον απαραίτητοι, καθώς το σύνολο της ασφάλειας του Ethereum θα παρέχεται από τους επικυρωτές.",
  "page-eth2-diagram-p10": "Η Eth2 δεν είναι μια απλή μετάβαση ή κάτι απλό. Περιγράφει ένα σύνολο αναβαθμίσεων που εργάζονται παράλληλα με σκοπό να ξεκλειδώσουν τις πραγματικές δυνατότητες του Ethereum. Δείτε παρακάτω πώς θα λειτουργήσουν όλοι μαζί.",
  "page-eth2-diagram-scroll": "Κύλιση για εξερεύνηση – πατήστε για περισσότερες πληροφορίες.",
  "page-eth2-diagram-shard": "Τομέας (1)",
  "page-eth2-diagram-shard-1": "Τομέας (...)",
  "page-eth2-diagram-shard-2": "Τομέας (2)",
  "page-eth2-diagram-shard-3": "Τομέας (...)",
  "page-eth2-diagram-validators": "Περισσότερα για τους επικυρωτές",
  "page-eth2-dive": "Βουτήξτε στο όραμα",
  "page-eth2-dive-desc": "Με ποιο τρόπο θα κάνουμε το Ethereum πιο επεκτάσιμο, ασφαλές και βιώσιμο; Όλα αυτά διατηρώντας το βασικό ήθος της αποκέντρωσης του Ethereum.",
  "page-eth2-docking": "Η ενσωμάτωση",
  "page-eth2-docking-answer-1": "Η ενσωμάτωση πραγματοποιείται όταν το κεντρικό δίκτυο μετατρέπεται σε shard. Αυτό θα γίνει μετά από την επιτυχημένη εφαρμογή του σχεδιασμού των αλυσίδων shard.",
  "page-eth2-docking-btn": "Περισσότερα για την ενσωμάτωση",
  "page-eth2-docking-desc": "Το κεντρικό δίκτυο του Ethereum θα πρέπει να «ενσωματωθεί» ή να «συγχωνευθεί» με την κύρια αλυσίδα κάποια στιγμή. Αυτό θα δώσει τη δυνατότητα συμμετοχής με τη μέθοδο της αποθήκευσης (staking) σε ολόκληρο το δίκτυο και θα σηματοδοτήσει το τέλος της υψηλής ενεργειακής απαίτησης για λόγους εξόρυξης.",
  "page-eth2-docking-estimate": "Αναμένεται: 2022",
  "page-eth2-docking-mainnet": "Τι είναι το κεντρικό δίκτυο;",
  "page-eth2-docking-mainnet-eth2": "Ενσωμάτωση του κεντρικού δικτύου με το Eth2",
  "page-eth2-eth-blog": "ιστολόγιο ethereum.org",
  "page-eth2-explore-btn": "Εξερευνήστε τις αναβαθμίσεις",
  "page-eth2-get-involved": "Συμμετέχετε στο Eth2",
  "page-eth2-get-involved-2": "Συμμετέχετε",
  "page-eth2-head-to": "Μετάβαση στο",
  "page-eth2-help": "Θέλετε να βοηθήσετε με το Eth2;",
  "page-eth2-help-desc": "Υπάρχουν πάρα πολλές ευκαιρίες με τις αναβαθμίσεις Eth2, βοηθήστε με τις δοκιμές και κερδίστε ανταμοιβές.",
  "page-eth2-index-staking": "Το staking (αποθήκευση) είναι πλέον γεγονός",
  "page-eth2-index-staking-desc": "Το κλειδί με την αναβάθμιση στο Eth2 είναι η εισαγωγή του staking (αποθήκευση). Μπορείτε, αν θέλετε, να χρησιμοποιήσετε τα ETH σας για να βοηθήσετε στην ασφάλεια του δικτύου Ethereum, ακολουθώντας τα παρακάτω βήματα.",
  "page-eth2-index-staking-learn": "Μάθετε για το staking (αποθήκευση)",
  "page-eth2-index-staking-learn-desc": "Με την κύρια αλυσίδα θα εφαρμοστεί το staking (αποθήκευση) στο Ethereum. Αυτό σημαίνει ότι αν έχετε ETH, μπορείτε να το δεσμεύσετε για το καλό όλων αλλά κυρίως για την ασφάλεια του δικτύου και να κερδίσετε έτσι περισσότερα ETH από τη διαδικασία.",
  "page-eth2-index-staking-step-1": "1. Ρυθμίστε με την πλατφόρμα εκκίνησης",
  "page-eth2-index-staking-step-1-btn": "Μετάβαση στην πλατφόρμα staking",
  "page-eth2-index-staking-step-1-desc": "Για να συμμετέχετε στο stake - αποθήκευση του Eth2 χρησιμοποιήστε την πλατφόρμα εκκίνησης – θα σας καθοδηγήσει στη διαδικασία.",
  "page-eth2-index-staking-step-2": "2. Επιβεβαιώστε τη διεύθυνση staking",
  "page-eth2-index-staking-step-2-btn": "Επιβεβαιώστε τη διεύθυνση κατάθεσης του κεφαλαίου",
  "page-eth2-index-staking-step-2-desc": "Πριν αποθηκεύσετε τα ETH σας, φροντίστε να ελέγξετε ότι έχετε τη σωστή διεύθυνση. Αυτό πρέπει να έχετε κάνει μέσω της πλατφόρμας εκκίνησης.",
  "page-eth2-index-staking-sustainability": "Πιο βιώσιμο",
  "page-eth2-just-docking": "Περισσότερα για την ενσωμάτωση",
  "page-eth2-meta-desc": "Μια επισκόπηση των αναβαθμίσεων του Ethereum 2.0 και του οράματος που ελπίζουν να υλοποιηθούν.",
  "page-eth2-meta-title": "Οι αναβαθμίσεις Eth2",
  "page-eth2-miners": "Περισσότερα για την εξόρυξη",
  "page-eth2-more-on-upgrades": "Περισσότερα για τις αναβαθμίσεις Eth2",
  "page-eth2-proof-of-stake": "proof-of-stake",
  "page-eth2-proof-of-work": "proof-of-work",
  "page-eth2-proof-stake-link": "Περισσότερα για το proof of stake",
  "page-eth2-question-1-desc": "Το Eth2 είναι ένα σύνολο ξεχωριστών αναβαθμίσεων με διαφορετικές ημερομηνίες εφαρμογής.",
  "page-eth2-question-1-title": "Πότε θα κυκλοφορήσει το Eth2;",
  "page-eth2-question-2-desc": "Δεν είναι τόσο ακριβές να θεωρούμε το Eth2 ως ένα ξεχωριστό blockchain.",
  "page-eth2-question-2-title": "Το Eth2 είναι ένα ξεχωριστό blockchain;",
  "page-eth2-question-3-answer-2": "Η shard chain και οι αναβαθμίσεις ενσωμάτωσης μπορεί να επηρεάσουν τους προγραμματιστές των dapp. Αλλά οι προδιαγραφές δεν έχουν ακόμη οριστικοποιηθεί, οπότε δεν απαιτείται κάποια ενέργεια προς το παρόν.",
  "page-eth2-question-3-answer-3": "Συζητήστε με την ομάδα του Eth2 έρευνας και ανάπτυξης στο ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Επισκεφθείτε το ethresear.ch",
  "page-eth2-question-3-desc": "Δεν χρειάζεται να κάνετε τίποτα αυτή τη στιγμή για να προετοιμαστείτε για το Eth2.",
  "page-eth2-question-3-title": "Πώς μπορώ να προετοιμαστώ για το Eth2;",
  "page-eth2-question-4-answer-1": "Κάθε φορά που εκτελείται μια συναλλαγή ή χρησιμοποιείτε μια εφαρμογή dapp, κάνετε χρήση του Eth1. Αυτό είναι το Ethereum που παρέχεται από αυτούς που κάνουν εξόρυξη.",
  "page-eth2-question-4-answer-2": "Το 'Eth1' ή κεντρικό δίκτυο, θα συνεχίσει να λειτουργεί κανονικά μέχρι την ενσωμάτωση του.",
  "page-eth2-question-4-answer-3": "Μετά την ενσωμάτωση, οι επικυρωτές θα ασφαλίσουν ολόκληρο το δίκτυο μέσω του proof-of-stake.",
  "page-eth2-question-4-answer-6": "Όλοι μπορούν να γίνει επικυρωτές δεσμεύοντας (staking) τα ETH τους.",
  "page-eth2-question-4-answer-7": "Περισσότερα για το staking",
  "page-eth2-question-4-answer-8": "Οι αναβαθμίσεις των αλυσίδας Beacon Chain και shard chain δεν θα διαταράξουν το Eth1 καθώς δημιουργούνται ξεχωριστά.",
  "page-eth2-question-4-desc": "Το Eth1 αναφέρεται στο κεντρικό δίκτυο του Ethereum στο οποίο συναλλάσσεστε σήμερα.",
  "page-eth2-question-4-title": "Τι είναι το Eth1;",
  "page-eth2-question-5-answer-1": "Για να γίνετε πλήρης επικυρωτής στο δίκτυο, θα πρέπει να δεσμεύσετε (stake) 32 ETH σας. Αν δεν έχετε τόσα, ή δεν είστε πρόθυμοι να δεσμεύσετε (stake) τόσο πολλά, μπορείτε να συμμετάσχετε σε ομαδικές δεσμεύσεις (staking pools). Αυτές θα σας επιτρέψουν να συμμετέχετε με λιγότερα κρυπτονομίσματα και να κερδίσετε ανάλογα με το ύψος συμμετοχής σας.",
  "page-eth2-question-5-desc": "Θα χρειαστεί να χρησιμοποιήσετε την πλατφόρμα έναρξης (launchpad) ή να συμμετάσχετε σε μια ομάδα (staking pool).",
  "page-eth2-question-5-title": "Πώς μπορώ να αποθηκεύσω-δεσμεύσω (stake);",
  "page-eth2-question-6-answer-1": "Προς το παρόν, δεν χρειάζεται να κάνετε κάτι. Αλλά σας συνιστούμε να ενημερώνεστε με τις εξελίξεις στις shard chains και τις αναβαθμίσεις της ενσωμάτωσης.",
  "page-eth2-question-6-answer-3": "Ο Danny Ryan του ιδρύματος Ethereum ενημερώνει τακτικά την κοινότητα:",
  "page-eth2-question-6-answer-4": "Ο Ben Edginton του ConsenSys εκδίδει εβδομαδιαίο ενημερωτικό δελτίο:",
  "page-eth2-question-6-answer-5": "Μπορείτε να συμμετάσχετε στη συζήτηση για την έρευνα και ανάπτυξη του Eth2 στο ethresear.ch.",
  "page-eth2-question-6-desc": "Οι εφαρμογές σας dapp δεν θα επηρεαστούν από τις επικείμενες αναβαθμίσεις. Ωστόσο, οι μελλοντικές αναβαθμίσεις μπορεί να απαιτούν κάποιες αλλαγές.",
  "page-eth2-question-6-title": "Τι χρειάζεται να κάνω με την εφαρμογή μου dapp;",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(υλοποίηση με .NET)",
  "page-eth2-question-7-desc": "Αρκετές διαφορετικές ομάδες από όλη την κοινότητα εργάζονται σε διάφορες αναβαθμίσεις του Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(υλοποίηση με Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(υλοποίηση με JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(υλοποίηση με Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(υλοποίηση με Go)",
  "page-eth2-question-7-teams": "Οι ομάδες πελατών Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(υλοποίηση με Java)",
  "page-eth2-question-7-title": "Ποιος δημιουργεί το Eth2;",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(υλοποίηση με Python)",
  "page-eth2-question-8-answer-1": "Οι αναβαθμίσεις Eth2 θα βοηθήσουν το Ethereum με αποκεντρωμένο τρόπο, διατηρώντας παράλληλα την ασφάλεια και αυξάνοντας την βιωσιμότητα.",
  "page-eth2-question-8-answer-2": "Ίσως το πιο κοινό πρόβλημα είναι ότι το Ethereum πρέπει να είναι σε θέση να διαχειριστεί περισσότερες από 15-45 συναλλαγές ανά δευτερόλεπτο. Αλλά με τις αναβαθμίσεις στο Eth2 αντιμετωπίζονται περισσότερα προβλήματα του σημερινού Ethereum.",
  "page-eth2-question-8-answer-3": "Η υψηλή ζήτηση του δικτύου Ethereum το καθιστά ακριβό στη χρήση. Οι κόμβοι του δικτύου διαχειρίζονται το μεγάλο μέγεθος του Ethereum και τον όγκο των δεδομένων που πρέπει να επεξεργάζονται οι υπολογιστές τους. Ο υποκείμενος αλγόριθμος που κρατά το Ethereum ασφαλές και αποκεντρωμένο καταναλώνει αρκετή ενέργεια και θα πρέπει να είναι πιο πράσινος.",
  "page-eth2-question-8-answer-4": "Πολλές από τις αλλαγές ήταν πάντοτε στο χάρτη πορείας του Ethereum, ακόμη και από το 2015. Αλλά οι τρέχουσες συνθήκες καθιστούν την ανάγκη για τις αναβαθμίσεις ακόμη μεγαλύτερη.",
  "page-eth2-question-8-answer-6": "Εξερευνήστε το όραμα για το Eth2",
  "page-eth2-question-8-desc": "Το Ethereum που χρησιμοποιούμε σήμερα πρέπει να προσφέρει μια καλύτερη εμπειρία στους τελικούς χρήστες και τους συμμετέχοντες στο δίκτυο.",
  "page-eth2-question-8-title": "Γιατί χρειάζεται το Eth2;",
  "page-eth2-question-9-answer-1": "Ο πιο ενεργός ρόλος με τον οποίο μπορείτε να συμμετέχετε είναι να αποθηκεύσετε τα ETH σας.",
  "page-eth2-question-9-answer-2": "Μπορεί επίσης να θέλετε να εκτελέσετε ένα δεύτερο πελάτη για να βοηθήσει στη βελτίωση της ποικιλομορφίας των πελατών.",
  "page-eth2-question-9-answer-3": "Αν είστε περισσότερο της τεχνολογίας, μπορείτε να βοηθήσετε στον εντοπισμό σφαλμάτων των νέων πελατών.",
  "page-eth2-question-9-answer-4": "Μπορείτε επίσης να συμμετέχετε σε συζητήσεις για τεχνικά θέματα με τους ερευνητές του Ethereum στο ethresear.ch.",
  "page-eth2-question-9-desc": "Δεν χρειάζεται να είστε τεχνικός για να συνεισφέρετε. Η κοινότητα αναζητά εθελοντές με κάθε είδους δεξιότητες.",
  "page-eth2-question-9-stake-eth": "Αποθήκευση ΕΤΗ",
  "page-eth2-question-9-title": "Πώς μπορώ να συνεισφέρω στο Eth2;",
  "page-eth2-question-10-answer-1": "Δεν μπορούμε να συζητούμε σύμφωνα με ένα τεχνικό οδικό χάρτη, επειδή είναι λογισμικό: τα δεδομένα μπορούν πάντοτε αλλάξουν. Πιστεύουμε ότι είναι πιο εύκολο να καταλάβουμε τι συμβαίνει ερμηνεύοντας καλύτερα τα αποτελέσματα.",
  "page-eth2-question-10-answer-1-link": "Προβολή των αναβαθμίσεων",
  "page-eth2-question-10-answer-2": "Αλλά αν έχετε παρακολουθήσει τις συζητήσεις, θα δείτε πώς οι αναβαθμίσεις συμβαδίζουν με τους τεχνικούς οδικούς χάρτες.",
  "page-eth2-question-10-answer-3": "Η Φάση 0 περιγράφει τις απαραίτητες εργασίες ενεργοποίησης της Κύριας Αλυσίδας.",
  "page-eth2-question-10-answer-5": "Η Φάση 1 του τεχνικού οδικού χάρτη επικεντρώνεται στην εφαρμογή των shard chains.",
  "page-eth2-question-10-answer-6": "Η συγχώνευση του κεντρικού δικτύου στο Eth2 αποτελεί επέκταση του έργου που επιτελείται για την εφαρμογή των shard chains και αναφέρεται ως Φάση 1.5. Είναι μια σημαντική στιγμή καθώς το Ethereum που γνωρίζουμε σήμερα συγχωνεύεται με τις άλλες αναβαθμίσεις Eth2. Θα σηματοδοτεί την πλήρη μετάβαση του Ethereum στην απόδειξη συμμετοχής.",
  "page-eth2-question-10-answer-7": "Είναι προς το παρόν ασαφές τι θα συμβεί γύρω από τη Φάση 2. Είναι ακόμα ένα σημείο έντονης έρευνας και συζήτησης. Το αρχικό σχέδιο ήταν να προσθέσει επιπλέον λειτουργικότητα στις shard chains, αλλά μπορεί να μην είναι απαραίτητο με μια αναθεώρηση του οδικού χάρτη.",
  "page-eth2-question-10-answer-8": "Περισσότερα για το rollup-centric roadmap",
  "page-eth2-question-10-desc": "Οι φάσεις σχετίζονται με τις εργασίες που απαιτούνται κάθε φορά να ολοκληρωθούν και την εστίαση στον τεχνικό οδικό χάρτη του Eth2.",
  "page-eth2-question-10-title": "Τι είναι οι φάσεις του Eth2;",
  "page-eth2-question-title": "Συχνές ερωτήσεις",
  "page-eth2-question3-answer-1": "Οι κάτοχοι Eth σίγουρα δεν χρειάζεται να κάνουν κάτι. Τα ETH σας δεν θα χρειαστούν καμία αλλαγή ή αναβάθμιση. Είναι σχεδόν σίγουρο ότι θα υπάρξουν κακόβουλοι λέγοντάς σας κάτι διαφορετικό, οπότε να είστε προσεκτικοί.",
  "page-eth2-scalable": "Πιο επεκτάσιμο",
  "page-eth2-scalable-desc": "Το Ethereum θα πρέπει να υποστηρίζει 1000 συναλλαγές ανά δευτερόλεπτο, για να μπορέσει να κάνει τις εφαρμογές ταχύτερες και φθηνότερες στη χρήση.",
  "page-eth2-secure": "Ασφαλέστερο",
  "page-eth2-secure-desc": "Το Ethereum πρέπει να είναι πιο ασφαλές, καθώς αυξάνεται η χρήση του Ethereum, το πρωτόκολλο πρέπει να γίνει ασφαλέστερο έναντι όλων των μορφών επίθεσης.",
  "page-eth2-shard-button": "Περισσότερα για τις αλυσίδες τομέα",
  "page-eth2-shard-date": "Η εισαγωγή αλυσίδων τομέα, η δεύτερη αναβάθμιση, αναμένεται μέσα στο 2021.",
  "page-eth2-shard-desc": "Οι αλυσίδες τομέα θα επεκτείνουν την ικανότητα του Ethereum να επεξεργάζεται περισσότερες συναλλαγές και να αποθηκεύει δεδομένα. Οι ίδιοι οι τομείς θα αποκτήσουν περισσότερα χαρακτηριστικά με την πάροδο του χρόνου, υλοποιώντας το σε πολλαπλές φάσεις.",
  "page-eth2-shard-estimate": "Αναμένεται: 2021",
  "page-eth2-shard-lower": "Περισσότερα για τις αλυσίδες τομέα (shard chains)",
  "page-eth2-shard-title": "Shard chains",
  "page-eth2-stay-up-to-date": "Μείνετε ενημερωμένοι",
  "page-eth2-stay-up-to-date-desc": "Μάθετε περισσότερα από του ερευνητές και τους προγραμματιστές που εργάζονται με τις αναβαθμίσεις του Eth2.",
  "page-eth2-sustainable-desc": "Το Ethereum πρέπει να γίνει πιο φιλικό για το περιβάλλον. Η σημερινή τεχνολογία απαιτεί υπερβολική υπολογιστική ισχύ και ενέργεια.",
  "page-eth2-take-part": "Πάρτε μέρος στην έρευνα",
  "page-eth2-take-part-desc": "Οι ερευνητές του Ethereum και οι ενθουσιώδεις χρήστες μπορούν να συμμετέχουν και να συζητούν για τις ερευνητικές προσπάθειες, συμπεριλαμβανομένων των διαφόρων θεμάτων γύρω από το Eth2.",
  "page-eth2-the-upgrades": "Οι αναβαθμίσεις Eth2",
  "page-eth2-the-upgrades-desc": "Το Eth2 είναι ένα σύνολο αναβαθμίσεων με σκοπό τη βελτίωση της επεκτασιμότητας, της ασφάλειας και της βιωσιμότητας του Ethereum. Όλα εργάζονται παράλληλα με τον ίδιο σκοπό, έχοντας ορισμένες ιδιαιτερότητες καθορίζοντας τον χρόνο αποδέσμευσης.",
  "page-eth2-unofficial-roadmap": "Δεν είναι επίσημη τοποθέτηση. Είναι ο τρόπος που βλέπουμε τα πράγματα σύμφωνα πάντα με τις πληροφορίες που προέρχονται από εκεί έξω. Αλλά έτσι είναι η τεχνολογία, μπορεί να αλλάξει κάθετα και ραγδαία. Οπότε τίποτε δεν μπορεί να θεωρηθεί ως δεδομένο, ως δέσμευση.",
  "page-eth2-upgrade-desc": "Το Ethereum που όλοι γνωρίζουμε και αγαπάμε, απλά πιο επεκτάσιμο, πιο ασφαλές και πιο βιώσιμο...",
  "page-eth2-upgrades": "Οι αναβαθμίσεις Eth2",
  "page-eth2-upgrades-aria-label": "Μενού αναβαθμίσεων Eth2",
  "page-eth2-upgrades-beacon-chain": "Το Beacon Chain",
  "page-eth2-upgrades-docking": "Ενσωμάτωση του κεντρικού δικτύου και Eth2",
  "page-eth2-upgrades-guide": "Οδηγός για τις αναβαθμίσεις Eth2",
  "page-eth2-upgrades-shard-chains": "Οι αλυσίδες τομέα (shard chains)",
  "page-eth2-upgrading": "Αναβαθμίζοντας το Ethereum σε ριζοσπαστική νέα λειτουργία",
  "page-eth2-vision": "Το όραμα",
  "page-eth2-vision-btn": "Περισσότερα για το όραμα Eth2",
  "page-eth2-vision-desc": "Για να φέρουμε το Ethereum στο κύριο σύστημα στην υπηρεσία όλης της ανθρωπότητας, θα πρέπει να κάνουμε το Ethereum πιο επεκτάσιμο, ασφαλές και βιώσιμο.",
  "page-eth2-vision-upper": "Tο όραμα Eth2",
  "page-eth2-what-to-do": "Τι χρειάζεται να κάνετε;",
  "page-eth2-what-to-do-desc": "Αν είστε χρήστης dapp ή κάτοχος ETH, δεν χρειάζεται να κάνετε τίποτα. Αν είστε προγραμματιστής ή θέλετε να ξεκινήσετε με το staking (αποθήκευση), υπάρχουν τρόποι με τους οποίους μπορείτε να συμμετάσχετε σήμερα.",
  "page-eth2-whats-eth2": "Τι είναι το Eth2;",
  "page-eth2-whats-eth2-desc": "Το Eth2 είναι ένα σύνολο αναβαθμίσεων που θα κάνουν το Ethereum πιο επεκτάσιμο, πιο ασφαλές και πιο βιώσιμο. Αυτές οι αναβαθμίσεις δημιουργούνται από πολλές διαφορετικές ομάδες από ολόκληρο το οικοσύστημα του Ethereum.",
  "page-eth2-whats-ethereum": "Περιμένετε, τι είναι το Ethereum;",
  "page-eth2-whats-new": "Τι νέο υπάρχει στο Eth2;",
  "page-eth2-just-staking": "Staking (αποθήκευση)",
  "page-eth2-staking-image-alt": "Εικόνα της μασκότ Rhino για την eth2 πλατφόρμα.",
  "page-eth2-staking-51-desc-1": "Το ποντάρισμα κάνει την ένταξη στο δίκτυο ως επικυρωτής, πιο προσβάσιμη, έτσι είναι πιθανό ότι θα υπάρχουν περισσότεροι επικυρωτές στο δίκτυο από ό,τι υπάρχουν σήμερα. Αυτό θα δυσχεράνει ακόμη περισσότερο αυτού του είδους την επίθεση, καθώς το κόστος μιας επίθεσης θα αυξηθεί.",
  "page-eth2-staking-accessibility": "Πιο προσβάσιμο",
  "page-eth2-staking-accessibility-desc": "Με λιγότερες απαιτήσεις σε υλικό και με τη δυνατότητα συμμετοχής σε κοινή δεξαμενή αποθήκευσης, αν δεν έχετε 32ETH, θα μπορούν περισσότεροι να συμμετέχουν στο δίκτυο. Αυτό θα καταστήσει το Ethereum πιο αποκεντρωμένο και ασφαλές μειώνοντας τη δυνατότητα επίθεσης.",
  "page-eth2-staking-at-stake": "Τα ETH σας κατά την αποθήκευση",
  "page-eth2-staking-at-stake-desc": "Επειδή πρέπει να αποθηκεύσετε τα ETH σας για να μπορείτε να επικυρώνετε συναλλαγές και να δημιουργήσετε νέα μπλοκ, μπορεί και να τα χάσετε σε περίπτωση που αποφασίσετε να εξαπατήσετε το σύστημα.",
  "page-eth2-staking-benefits": "Πλεονεκτήματα της αποθήκευσης στο Ethereum",
  "page-eth2-staking-check-address": "Έλεγχος διεύθυνσης κατάθεσης",
  "page-eth2-staking-consensus": "Περισσότερα για τους μηχανισμούς συναίνεσης",
  "page-eth2-staking-deposit-address": "Ελέγξτε τη διεύθυνση καταθέσεων",
  "page-eth2-staking-deposit-address-desc": "Αν έχετε ήδη ακολουθήσει τις οδηγίες εγκατάστασης στην πλατφόρμα εκκίνησης, θα γνωρίζετε ότι πρέπει να στείλετε μια συναλλαγή στο συμβόλαιο κατάθεσης για την συμμετοχή σας. Σας προτείνουμε να ελέγξετε αυτή τη διεύθυνση πολύ προσεκτικά. Μπορείτε να βρείτε την επίσημη διεύθυνση στο ethereum.org και σε μια σειρά από άλλες αξιόπιστες ιστοσελίδες.",
  "page-eth2-staking-desc-1": "Οι ανταμοιβές δίνονται για ενέργειες που βοηθούν το δίκτυο να επιτύχει τη συναίνεση. Θα λάβετε ανταμοιβές από την ομαδοποίηση των συναλλαγών σε ένα νέο μπλοκ ή τον έλεγχο των εργασιών άλλων επαληθευτών, επειδή αυτό είναι που κρατά την αλυσίδα να λειτουργεί με ασφάλεια.",
  "page-eth2-staking-desc-2": "Μπορείτε να κερδίσετε ανταμοιβές για την εργασία σας που ωφελεί το δίκτυο, μπορείτε όμως να χάσετε ETH από κακόβουλες ενέργειες, για λειτουργία εκτός σύνδεσης και αποτυχία στην επαλήθευση.",
  "page-eth2-staking-desc-3": "Για να γίνετε πλήρης επαληθευτής θα χρειαστείτε 32 ETH ή με μερικά ETH μπορείτε να συμμετάσχετε σε μια δεξαμενή αποθήκευσης. Θα χρειαστεί επίσης να εκτελέσετε ένα πρόγραμμα πελάτη του 'Eth1' ή του κεντρικού δικτύου. Η πλατφόρμα εκκίνησης θα σας ξεναγήσει μέσα από τη διαδικασία και τις απαιτήσεις σε υλικό. Εναλλακτικά, μπορείτε να χρησιμοποιήσετε ένα backend API.",
  "page-eth2-staking-description": "Η αποθήκευση ή staking, είναι η πράξη της κατάθεσης 32 ETH για να μπορεί να τεθεί σε λειτουργία το λογισμικό επικύρωσης. Ως επαληθευτής θα είστε υπεύθυνος για την αποθήκευση δεδομένων, την επεξεργασία συναλλαγών και την προσθήκη νέων μπλοκ στην κεντρική αλυσίδα. Έτσι θα παραμείνει το Ethereum ασφαλές για όλους και εσείς κερδίσετε νέα ETH από τη διαδικασία. Αυτή η διαδικασία, γνωστή και ως απόδειξη συμμετοχής, εισάγεται από την Κεντρική Αλυσίδα.",
  "page-eth2-staking-docked": "Περισσότερα για την ενσωμάτωση",
  "page-eth2-staking-dyor": "Κάντε τη δική σας έρευνα",
  "page-eth2-staking-dyor-desc": "Καμία από τις καταχωρημένες υπηρεσίες αποθήκευσης δεν είναι επίσημα εγκεκριμένες. Να κάνετε πάντοτε μια έρευνα και να βεβαιώνεστε ποια υπηρεσία είναι η καλύτερη επιλογή για εσάς.",
  "page-eth2-staking-header-1": "Αποθηκεύστε τα ETH σας, για να γίνετε επαληθευτής στο Ethereum",
  "page-eth2-staking-how-much": "Με τι ποσό είστε πρόθυμοι να συμμετάσχετε;",
  "page-eth2-staking-how-to-stake": "Πώς να αποθηκεύσετε",
  "page-eth2-staking-how-to-stake-desc": "Όλα εξαρτώνται από την ποσότητα που είστε πρόθυμοι να αποθηκεύσετε. Θα χρειαστείτε 32 για να γίνετε ένας πλήρης επαληθευτής, αλλά είναι δυνατόν να συμμετέχετε με λιγότερα.",
  "page-eth2-staking-join": "Συνδεθείτε",
  "page-eth2-staking-join-community": "Συνδεθείτε με την κοινότητα αποθήκευσης",
  "page-eth2-staking-join-community-desc": "Η κοινότητα r/ethstaker είναι για όσους επιθυμούν να συζητήσουν για την αποθήκευση στο Ethereum - να συμμετέχουν για να λάβουν συμβουλές, υποστήριξη και να μιλήσουν σχετικά με το θέμα.",
  "page-eth2-staking-less-than": "Λιγότερα από",
  "page-eth2-staking-link-1": "Προβολή backend APIs",
  "page-eth2-staking-meta-description": "Μια επισκόπηση της αποθήκευσης Ethereum: οι κίνδυνοι, οι ανταμοιβές και οι απαιτήσεις καθώς και πού μπορούν να υλοποιηθούν.",
  "page-eth2-staking-meta-title": "Αποθήκευση Ethereum",
  "page-eth2-staking-more-sharding": "Περισσότερα για το sharding",
  "page-eth2-staking-pool": "Αποθήκευση σε κοινή δεξαμενή",
  "page-eth2-staking-pool-desc": "Αν έχετε λιγότερα από 32 ETH, μπορείτε να συμμετέχετε με μικρότερο ποσό αποθήκευσης σε δεξαμενές αποθήκευσης με άλλους. Ορισμένες εταιρείες μπορούν να τα κάνουν όλα εκ μέρους σας, ώστε να μην ανησυχείτε σε περίπτωση διακοπής του Διαδικτύου.",
  "page-eth2-staking-pos-explained": "Περιγραφή της απόδειξης συμμετοχής",
  "page-eth2-staking-pos-explained-desc": "Αυτό που πρέπει να κάνετε είναι να αποθηκεύσετε κρυπτονομίσματα για να μπορέσετε να γίνετε επαληθευτής, σε ένα σύστημα με απόδειξη συμμετοχής. Πρόκειται για έναν μηχανισμό συναίνεσης που θα αντικαταστήσει το σύστημα απόδειξης της εργασίας που εφαρμόζεται μέχρι σήμερα. Οι μηχανισμοί συναίνεσης είναι αυτοί που κρατούν τις Κεντρικές αλυσίδες όπως το Ethereum ασφαλή και αποκεντρωμένα.",
  "page-eth2-staking-pos-explained-desc-1": "Η απόδειξη συμμετοχής βοηθά στην ασφάλεια του δικτύου με διάφορους τρόπους:",
  "page-eth2-staking-services": "Δείτε τις υπηρεσίες αποθήκευσης",
  "page-eth2-staking-sharding": "Ξεκλειδώνει το sharding",
  "page-eth2-staking-sharding-desc": "Το sharding είναι δυνατό μόνο με ένα σύστημα με απόδειξη συμμετοχής. Σε ένα σύστημα με απόδειξη εργασίας το sharding θα μειώσει το ποσό της υπολογιστικής ενέργειας που απαιτείται για να διαφθείρει το δίκτυο, καθιστώντας ευκολότερο για κακόβουλους εξορύκτες να ελέγχουν τα θραύσματα. Δεν αφορά την περίπτωση με τυχαία ανάθεση αποθήκευσης στην απόδειξη συμμετοχής.",
  "page-eth2-staking-solo": "Αποθηκεύστε μόνοι σας και εκτελέστε ένα επαληθευτή",
  "page-eth2-staking-solo-desc": "Για να ξεκινήσετε τη διαδικασία αποθήκευσης, θα πρέπει να το κάνετε μέσα από την πλατφόρμα εκκίνησης Eth2, όπου θα σας καθοδηγήσει σε όλες τις ρυθμίσεις που απαιτούνται. Ένα μέρος της αποθήκευσης εκτελεί έναν πελάτη Eth2, ο οποίος είναι ένα τοπικό αντίγραφο της κεντρικής αλυσίδας. Χρειάζεται λίγος χρόνος για να την κατεβάσετε στον υπολογιστή σας.",
  "page-eth2-staking-start": "Ξεκινήστε την αποθήκευση",
  "page-eth2-staking-subtitle": "Η αποθήκευση ευνοεί το οικοσύστημα του Ethereum. Μπορείτε να βοηθήσετε στην ασφάλεια του δικτύου και να κερδίσετε ανταμοιβές από τη διαδικασία.",
  "page-eth2-staking-sustainability": "Πιο βιώσιμο",
  "page-eth2-staking-sustainability-desc": "Οι επαληθευτές δεν χρειάζεται να καταναλώνουν αρκετή ενέργεια με τον υπολογιστή τους για να συμμετέχουν σε ένα σύστημα με απόδειξη συμμετοχής – μπορούν απλά με ένα φορητό υπολογιστή ή ένα έξυπνο τηλέφωνο. Αυτό θα κάνει το Ethereum ποιο φιλικό στο περιβάλλον.",
  "page-eth2-staking-the-beacon-chain": "Περισσότερα για την κύρια αλυσίδα",
  "page-eth2-staking-title-1": "Ανταμοιβές",
  "page-eth2-staking-title-2": "Κίνδυνοι",
  "page-eth2-staking-title-3": "Προϋποθέσεις",
  "page-eth2-staking-title-4": "Πώς να αποθηκεύσετε τα ETH σας",
  "page-eth2-staking-upgrades-li": "Η απόδειξη συμμετοχής διαχειρίζεται από την Κεντρική Αλυσίδα.",
  "page-eth2-staking-upgrades-li-2": "Το Ethereum θα έχει την απόδειξη συμμετοχής της Κεντρικής αλυσίδας και την απόδειξη εργασίας του κεντρικού δικτύου για το προσεχές μέλλον. Το κεντρικό δίκτυο είναι το Ethereum όπως το χρησιμοποιούμε έως σήμερα.",
  "page-eth2-staking-upgrades-li-3": "Κατά τη διάρκεια αυτής της περιόδου, οι συμμετέχοντες θα προσθέτουν νέα μπλοκ στην Κύρια Αλυσίδα αλλά δεν θα επεξεργάζονται συναλλαγές του κεντρικού δικτύου.",
  "page-eth2-staking-upgrades-li-4": "Το Ethereum θα μεταβεί πλήρως στο σύστημα με απόδειξη συμμετοχής μόλις το κεντρικό δίκτυο του Ethereum γίνει κομμάτι του.",
  "page-eth2-staking-upgrades-li-5": "Μόνο τότε θα μπορείτε να αποσύρετε τη συμμετοχή σας.",
  "page-eth2-staking-upgrades-title": "Η απόδειξη συμμετοχής και αναβαθμίσεις του Eth2",
  "page-eth2-staking-validators": "Περισσότεροι επαληθευτές, περισσότερη ασφάλεια",
  "page-eth2-staking-validators-desc": "Σε μια κύρια αλυσίδα όπως αυτή του Ethereum είναι δυνατόν να επηρεαστεί, εάν ελέγχετε το 51% του δικτύου. Για παράδειγμα, θα μπορούσατε να κατέχετε το 51% των επαληθευτών για να δηλώσετε ότι το υπόλοιπό σας είναι 1.000.000 ETH και όχι 1 ETH. Αλλά, για να ελέγχετε το 51% των επαληθευτών, θα πρέπει να κατέχετε το 51% του ETH στο σύστημα - αυτό είναι πάρα πολύ!",
  "page-eth2-staking-withdrawals": "Οι αναλήψεις δεν θα είναι άμεσα διαθέσιμες",
  "page-eth2-staking-withdrawals-desc": "Δεν θα μπορείτε να αποσύρετε τη συμμετοχή σας μέχρι να πραγματοποιηθούν οι μελλοντικές αναβαθμίσεις. Οι αναλήψεις θα είναι διαθέσιμες μόλις το Κεντρικό Δίκτυο θα συγχωνευθεί με το σύστημα της Κεντρικής Αλυσίδας.",
  "page-eth2-vision-2014": "Δείτε ένα άρθρο του 2014 σχετικά με την απόδειξη συμμετοχής",
  "page-eth2-vision-beacon-chain": "Η κύρια αλυσίδα",
  "page-eth2-vision-beacon-chain-btn": "Περισσότερα για την κύρια αλυσίδα",
  "page-eth2-vision-beacon-chain-date": "Η κύρια αλυσίδα είναι πραγματικότητα",
  "page-eth2-vision-beacon-chain-desc": "Η πρώτη προσθήκη στο οικοσύστημα σχετικά με το Eth2. Η κύρια αλυσίδα προσθέτει και τη λειτουργία της αποθήκευσης στο Ethereum, θέτοντας τα θεμέλια για μελλοντικές αναβαθμίσεις όπου και τελικά θα συντονίσει το νέο σύστημα.",
  "page-eth2-vision-beacon-chain-upper": "Η κύρια αλυσίδα (Beacon Chain)",
  "page-eth2-vision-desc-1": "Το Ethereum χρειάζεται να αποσυμφορήσει το δίκτυο και να βελτιώσει την ταχύτητα των υπηρεσιών του για το σύνολο των χρηστών του.",
  "page-eth2-vision-desc-2": "Η εκτέλεση ενός κόμβου γίνεται όλο και πιο δύσκολη καθώς το δίκτυο αναπτύσσεται. Γίνεται δυσκολότερο κατά την ιεράρχηση και τον υπολογισμό του δικτύου.",
  "page-eth2-vision-desc-3": "Το Ethereum χρησιμοποιεί υπερβολική ηλεκτρική ενέργεια. Η τεχνολογία που διατηρεί το δίκτυο ασφαλές θα πρέπει να είναι πιο βιώσιμη.",
  "page-eth2-vision-docking-date": "Αναμένεται: 2022",
  "page-eth2-vision-docking-desc": "Το κεντρικό δίκτυο του Ethereum θα πρέπει να «ενσωματωθεί» με την Κύρια Αλυσίδα κάποια στιγμή. Αυτό θα δώσει τη δυνατότητα συμμετοχής με τη μέθοδο της αποθήκευσης (staking) σε ολόκληρο το δίκτυο και θα σηματοδοτήσει το τέλος της υψηλής ενεργειακής απαίτησης για λόγους εξόρυξης.",
  "page-eth2-vision-ethereum-node": "Περισσότερα για τους κόμβους",
  "page-eth2-vision-explore-upgrades": "Εξερευνήστε τις αναβαθμίσεις",
  "page-eth2-vision-future": "Ένα ψηφιακό μέλλον παγκόσμιας κλίμακας",
  "page-eth2-vision-meta-desc": "Μια επισκόπηση του αντίκτυπου των αναβαθμίσεων του Eth2 στο Ethereum και των προκλήσεων που πρέπει να ξεπεράσουν.",
  "page-eth2-vision-meta-title": "Το όραμα του Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Περισσότερα για την εξόρυξη",
  "page-eth2-vision-problems": "Σημερινά προβλήματα",
  "page-eth2-vision-scalability": "Κλιμάκωση",
  "page-eth2-vision-scalability-desc": "Το Ethereum χρειάζεται να μπορεί να διαχειριστεί περισσότερες συναλλαγές ανά δευτερόλεπτο χωρίς να αυξήσει τους κόμβους του δικτύου. Οι κόμβοι είναι σημαντικής σημασίας στο δίκτυο όπου αποθηκεύουν και εκτελούν την κεντρική αλυσίδα. Δεν είναι πρακτικό να αυξηθεί το μέγεθος του κόμβου καθώς θα απαιτούνται ακριβοί και αρκετά ισχυροί υπολογιστές. Με την προσθήκη μερικών κόμβων θα εξισορροπηθεί η ταχύτητα του Ethereum ολοκληρώνοντας περισσότερες συναλλαγές ανά δευτερόλεπτο. Περισσότεροι κόμβοι σημαίνει μεγαλύτερη ασφάλεια.",
  "page-eth2-vision-scalability-desc-3": "Η αναβάθμιση των αλυσίδων shard θα εξαπλώσει το φορτίο του δικτύου σε 64 νέες αλυσίδες. Αυτό θα δώσει χώρο στο Ethereum μειώνοντας τη συμφόρηση και βελτιώνοντας τις ταχύτητες πέρα από τις τρέχουσες 15-45 συναλλαγές ανά δευτερόλεπτο.",
  "page-eth2-vision-scalability-desc-4": "Και παρόλο που θα υπάρχουν περισσότερες αλυσίδες, στην πραγματικότητα αυτό απαιτεί λιγότερη δουλειά από τους επικυρωτές - τους συντηρητές του δικτύου'. Οι επικυρωτές θα πρέπει μόνο να «τρέξουν» το shard τους και όχι ολόκληρη την αλυσίδα του Ethereum. Αυτό κάνει τους κόμβους πιο ελαφρείς, επιτρέποντας στο Ethereum να κλιμακωθεί και να παραμείνει αποκεντρωμένο.",
  "page-eth2-vision-security": "Ασφάλεια",
  "page-eth2-vision-security-desc": "Οι αναβαθμίσεις του Eth2 βελτιώνουν την ασφάλεια του Ethereum ενάντια σε συντονισμένες επιθέσεις, όπως μια επίθεση 51% attack. Ένα είδος επίθεσης όπου αν κάποιος ελέγχει την πλειοψηφία του όγκου δεδομένων του δικτύου μπορεί να επιβάλει κακόβουλες αλλαγές.",
  "page-eth2-vision-security-desc-3": "Η μετάβαση στην απόδειξη συμμετοχής σημαίνει ότι το πρωτόκολλο του Ethereum έχει μεγαλύτερα αντικίνητρα κατά των επιθέσεων. Αυτό οφείλεται στο γεγονός ότι στην απόδειξη του στοιχήματος, οι επικυρωτές που εξασφαλίζουν το δίκτυο πρέπει να αναφέρουν σημαντικές ποσότητες ETH στο πρωτόκολλο. Αν προσπαθήσουν να επιτεθούν στο δίκτυο, το πρωτόκολλο μπορεί να καταστρέψει αυτόματα το ETH.",
  "page-eth2-vision-security-desc-5": "Αυτό δεν είναι δυνατό σε απόδειξη εργασίας, όπου το καλύτερο που μπορεί να κάνει ένα πρωτόκολλο είναι να αναγκάσει τις οντότητες που εξασφαλίζουν το δίκτυο (τους εξορυκτές) να χάσουν τις ανταμοιβές εξόρυξης που θα είχαν κατά τα άλλα κερδίσει. Για να επιτευχθεί το ισοδύναμο αποτέλεσμα στην απόδειξη εργασίας, το πρωτόκολλο θα πρέπει να είναι σε θέση να καταστρέψει όλο τον εξοπλισμό ενός εξορυκτή σε περίπτωση προσπάθειας εξαπάτησης.",
  "page-eth2-vision-security-desc-5-link": "Περισσότερα για απόδειξη της εργασίας (proof of work)",
  "page-eth2-vision-security-desc-8": "Το μοντέλο ασφαλείας του Ethereum πρέπει επίσης να αλλάξει λόγω της εισαγωγής του",
  "page-eth2-vision-security-desc-10": "Η αποθήκευση σημαίνει επίσης ότι δεν χρειάζεται να επενδύσετε σε επίλεκτο υλικό για να «τρέξει» έναν κόμβο Ethereum. Αυτό θα πρέπει να ενθαρρύνει περισσότερους ανθρώπους να γίνουν επικυρωτές, αυξάνοντας την αποκέντρωση του δικτύου και μειώνοντας την επιφάνεια επίθεσης.",
  "page-eth2-vision-security-staking": "Αποθήκευση ΕΤΗ",
  "page-eth2-vision-security-validator": "Μπορείτε να γίνετε επικυρωτές δεσμεύοντας (staking) τα ETH σας.",
  "page-eth2-vision-shard-chains": "shard chains",
  "page-eth2-vision-shard-date": "Αναμένεται: 2021",
  "page-eth2-vision-shard-desc-4": "Οι αλυσίδες Shard θα εξαπλώσουν το φορτίο του δικτύου σε 64 νέα blockchain. Τα Shards έχουν τη δυνατότητα να βελτιώσουν δραστικά την ταχύτητα των συναλλαγών – μέχρι 100.000 ανά δευτερόλεπτο.",
  "page-eth2-vision-shard-upgrade": "Περισσότερα για τις αλυσίδες τομέα (shard chains)",
  "page-eth2-vision-staking-lower": "Περισσότερα για το staking",
  "page-eth2-vision-subtitle": "Αύξηση του Ethereum μέχρι να είναι αρκετά ισχυρό για να βοηθήσει όλη την ανθρωπότητα.",
  "page-eth2-vision-sustainability": "Βιωσιμότητα",
  "page-eth2-vision-sustainability-desc-1": "Δεν είναι μυστικό ότι το Ethereum και άλλα blockchain, όπως το Bitcoin είναι υψηλής ενεργειακής έντασης λόγω εξόρυξης.",
  "page-eth2-vision-sustainability-desc-2": "Αλλά το Ethereum κινείται προς την κατεύθυνση της εξασφάλισης από το ETH, όχι της υπολογιστικής ισχύος – μέσω της αποθήκευσης.",
  "page-eth2-vision-sustainability-desc-3": "Αν και το στοίχημα θα εισαχθεί από την αλυσίδα Beacon, το Ethereum που χρησιμοποιούμε σήμερα θα τρέξει παράλληλα για ένα χρονικό διάστημα, πριν «συγχωνευτεί» ή «ενσωματωθεί» με τις αναβαθμίσεις Eth2. Ένα σύστημα που ασφαλίζεται από το ETH, το άλλο με την υπολογιστική ενέργεια. Αυτό συμβαίνει επειδή, στην αρχή, οι αλυσίδες shard δεν θα είναι σε θέση να χειριστούν πράγματα όπως οι λογαριασμοί ή τα dapps μας. Έτσι δεν μπορούμε απλά να ξεχάσουμε την εξόρυξη και το κεντρικό δίκτυο.",
  "page-eth2-vision-sustainability-desc-8": "Μόλις η κύρια αλυσίδα και οι αναβαθμίσεις της αλυσίδας shard είναι έτοιμες και λειτουργούν, οι εργασίες θα ξεκινήσουν με το δίκτυο σύνδεσης με το κεντρικό σύστημα. Αυτό θα μετατρέψει το κεντρικό σύστημα σε shard έτσι ώστε να είναι ασφαλισμένο από ETH και πολύ λιγότερης ενεργειακής έντασης.",
  "page-eth2-vision-sustainability-subtitle": "Το Ethereum πρέπει να είναι πιο φιλικό στο περιβάλλον.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "Το όραμα για το Eth2",
  "page-eth2-vision-title-1": "Συμφόρηση δικτύου",
  "page-eth2-vision-title-2": "Αποθηκευτικός χώρος",
  "page-eth2-vision-title-3": "Πάρα πολύ ενέργεια",
  "page-eth2-vision-trilemma-cardtext-1": "Οι αναβαθμίσεις της Eth2 θα καταστήσουν το Ethereum επεκτάσιμο, ασφαλές και αποκεντρωμένο. Η αποθήκευση θα κάνει το Ethereum πιο κλιμακούμενο αυξάνοντας τις συναλλαγές ανά δευτερόλεπτο, μειώνοντας παράλληλα την απαιτούμενη ισχύ για να τρέξει έναν κόμβο και να επικυρώσει την αλυσίδα. Η κύρια αλυσίδα θα καταστήσει το Ethereum ασφαλές συντονίζοντας τους επικυρωτές σε όλα τα shard. Και το sharding θα μειώσει το εμπόδιο στη συμμετοχή, δημιουργώντας ένα μεγαλύτερο – πιο αποκεντρωμένο – δίκτυο.",
  "page-eth2-vision-trilemma-cardtext-2": "Ασφαλή και αποκεντρωμένα δίκτυα blockchain απαιτούν κάθε κόμβο να επαληθεύει κάθε συναλλαγή που επεξεργάζεται η αλυσίδα. Αυτός ο όγκος εργασίας περιορίζει τον αριθμό των συναλλαγών που μπορεί να συμβεί σε κάθε δεδομένη στιγμή. Η αποκέντρωση και η ασφάλεια αντικατοπτρίζει την αλυσίδα Ethereum σήμερα.",
  "page-eth2-vision-trilemma-cardtext-3": "Τα αποκεντρωμένα δίκτυα λειτουργούν στέλνοντας πληροφορίες σχετικά με τις συναλλαγές σε όλους τους κόμβους – όλο το δίκτυο πρέπει να γνωρίζει για οποιαδήποτε αλλαγή της κατάστασης. Η κλιμάκωση των συναλλαγών ανά δευτερόλεπτο σε ένα αποκεντρωμένο δίκτυο ενέχει κινδύνους για την ασφάλεια, επειδή οι περισσότερες συναλλαγές, όσο μεγαλύτερη είναι η καθυστέρηση, τόσο μεγαλύτερη είναι η πιθανότητα επίθεσης ενώ οι πληροφορίες βρίσκονται στον αέρα.",
  "page-eth2-vision-trilemma-cardtext-4": "Η αύξηση του μεγέθους και της ισχύος των κόμβων του Ethereum θα μπορούσε να αυξήσει τις συναλλαγές ανά δευτερόλεπτο με ασφαλή τρόπο, αλλά η απαίτηση υλικού θα περιορίσει ποιος θα μπορούσε να το κάνει – αυτό απειλεί την αποκέντρωση. Ελπίζουμε ότι το sharding και το proof-of-stake θα επιτρέψει στο Ethereum να κλιμακωθεί, αυξάνοντας το ποσό των κόμβων, όχι το μέγεθος του κόμβου.",
  "page-eth2-vision-trilemma-h2": "Η πρόκληση της αποκεντρωμένης κλιμάκωσης",
  "page-eth2-vision-trilemma-modal-tip": "Πατήστε τους παρακάτω κύκλους για να κατανοήσετε καλύτερα τα προβλήματα της αποκεντρωμένης κλιμάκωσης",
  "page-eth2-vision-trilemma-p": "Ένας αφελής τρόπος για την επίλυση των προβλημάτων του Ethereum θα ήταν να το κάνουμε πιο συγκεντρωτικό. Όμως η αποκέντρωση είναι πολύ σημαντική. Η αποκέντρωση είναι που προκαλεί την αντίσταση στη λογοκρισία του Ethereum, το άνοιγμα, το απόρρητο των δεδομένων και τη σχεδόν άθραυστη ασφάλεια.",
  "page-eth2-vision-trilemma-p-1": "Το όραμα του Ethereum είναι να είναι πιο κλιμακούμενο και ασφαλές, αλλά και να παραμένει αποκεντρωμένο. Η επίτευξη αυτών των 3 ιδιοτήτων είναι ένα πρόβλημα γνωστό ως το τρίλιμμα της κλιμάκωσης.",
  "page-eth2-vision-trilemma-p-2": "Οι αναβαθμίσεις Eth2 στοχεύουν στην επίλυση του τριλήμματος, αλλά υπάρχουν σημαντικές προκλήσεις.",
  "page-eth2-vision-trilemma-press-button": "Πατήστε τους παρακάτω κύκλους για να κατανοήσετε καλύτερα τα προβλήματα της αποκεντρωμένης κλιμάκωσης.",
  "page-eth2-vision-trilemma-text-1": "Αποκέντρωση",
  "page-eth2-vision-trilemma-text-2": "Ασφάλεια",
  "page-eth2-vision-trilemma-text-3": "Κλιμάκωση",
  "page-eth2-vision-trilemma-title-1": "Εξερευνήστε το τρίλιμμα κλιμάκωσης",
  "page-eth2-vision-trilemma-title-2": "Αναβαθμίσεις και αποκεντρωμένη κλιμάκωση Eth2",
  "page-eth2-vision-trilemma-title-3": "Ασφαλές και αποκεντρωμένο",
  "page-eth2-vision-trilemma-title-4": "Αποκεντρωμένη και κλιμακούμενη",
  "page-eth2-vision-trilemma-title-5": "Κλιμακωτή και ασφαλής",
  "page-eth2-vision-understanding": "Κατανοώντας το όραμα του Eth2",
  "page-eth2-vision-upgrade-needs": "Η αναγκαιότητα για αναβάθμιση σε Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Το πρωτόκολλο του Ethereum που ξεκίνησε το 2015 είχε απίστευτη επιτυχία. Αλλά η κοινότητα του Ethereum ανέμενε πάντα ότι μερικές αναβαθμίσεις κλειδιά θα ήταν απαραίτητες για να ξεκλειδώσετε το πλήρες δυναμικό του Ethereum.",
  "page-eth2-vision-upgrade-needs-desc-2": "Η υψηλή ζήτηση είναι η αύξηση των προμηθειών συναλλαγών που καθιστούν το Ethereum ακριβό για τον μέσο χρήστη. Ο χώρος στο δίσκο που απαιτείται για να τρέξει έναν πελάτη Ethereum αυξάνεται με γρήγορο ρυθμό. Και ο υποκείμενος αλγόριθμος απόδειξης συμμετοχής που κρατά το Ethereum ασφαλές και αποκεντρωμένο έχει μεγάλες περιβαλλοντικές επιπτώσεις.",
  "page-eth2-vision-upgrade-needs-desc-3": "Αυτό που συνήθως αναφέρεται ως Eth2 είναι ένα σύνολο αναβαθμίσεων που αντιμετωπίζουν αυτά τα προβλήματα και πολλά άλλα. Αυτό το σύνολο αναβαθμίσεων ονομάστηκε αρχικά 'Serenity', και είναι ένας ενεργός τομέας έρευνας και ανάπτυξης από το 2014.",
  "page-eth2-vision-upgrade-needs-desc-6": " Αυτό σημαίνει ότι δεν υπάρχει διακόπτης ενεργοποίησης για Eth2. Βελτιώσεις θα αποστέλλονται σταδιακά με την πάροδο του χρόνου.",
  "page-eth2-vision-upgrade-needs-serenity": "Δείτε ένα άρθρο του 2015 που συζητά για το 'Serenity'",
  "page-eth2-vision-uprade-needs-desc-5": "Τώρα που η τεχνολογία είναι έτοιμη, αυτές οι αναβαθμίσεις θα καταστρώσουν εκ νέου το Ethereum για να το κάνουν πιο επεκτάσιμο, ασφαλές, και βιώσιμο – να κάνει τη ζωή καλύτερη για τους υπάρχοντες χρήστες και να δελεάσει νέους. Διαφυλάσσοντας την κεντρική αξία της αποκέντρωσης του Ethereum.",
  "page-get-eth-cex": "Επίσημα ανταλλακτήρια",
  "page-get-eth-cex-desc": "Τα ανταλλακτικά είναι επιχειρήσεις που σας επιτρέπουν να αγοράσετε κρυπτονομίσματα χρησιμοποιώντας τα παραδοσιακά νομίσματα. Έχουν την επιμέλεια πάνω από οποιοδήποτε ETH που αγοράζετε μέχρι να το στείλετε σε ένα δικό σας πορτοφόλι.",
  "page-get-eth-checkout-dapps-btn": "Δείτε τις εφαρμογές dapps",
  "page-get-eth-community-safety": "Δημοσιεύσεις της κοινότητας για την ασφάλεια",
  "page-get-eth-description": "Το Ethereum και το ETH δεν ελέγχονται από καμία κυβέρνηση ή εταιρεία - είναι αποκεντρωμένα. Αυτό σημαίνει ότι το ETH είναι διαθέσιμο για όλους.",
  "page-get-eth-dex": "Αποκεντρωμένα ανταλλακτήρια (DEXs)",
  "page-get-eth-dex-desc": "Αν θέλετε περισσότερο έλεγχο, αγοράστε ETH peer-to-peer. Μέσω DEX μπορείτε να κάνετε συναλλαγές χωρίς να δώσετε τον έλεγχο των κεφαλαίων σας σε μια κεντρική εταιρεία.",
  "page-get-eth-dexs": "Αποκεντρωμένα ανταλλακτήρια (DEXs)",
  "page-get-eth-dexs-desc": "Τα αποκεντρωμένα ανταλλακτήρια είναι ανοικτές αγορές για το ETH και άλλα κρυπτονομίσματα. Συνδέουν άμεσα τους αγοραστές με τους πωλητές.",
  "page-get-eth-dexs-desc-2": "Αντί τη χρήση ενός αξιόπιστου τρίτου μέρους για την προστασία των κεφαλαίων κατά τη συναλλαγή, χρησιμοποιούν έναν κώδικα. Το ETH του πωλητή θα μεταφερθεί μόνο όταν υπάρχει εγγύηση της πληρωμής. Αυτός ο τύπος κώδικα είναι γνωστός και ως έξυπνο συμβόλαιο.",
  "page-get-eth-dexs-desc-3": "Αυτό σημαίνει ότι υπάρχουν λιγότεροι γεωγραφικοί περιορισμοί από ότι με τα κεντρικά ανταλλακτήρια. Αν κάποιος πουλάει αυτό που θέλετε και αποδέχεται τη μέθοδο πληρωμής που παρέχετε, είστε έτοιμοι. Τα DEX επιτρέπουν την αγορά ETH με άλλα κρυπτονομίσματα, το PayPal ή ακόμα και μετρητά.",
  "page-get-eth-do-not-copy": "ΠΑΡΑΔΕΙΓΜΑ: ΜΗΝ ΑΝΤΙΓΡΑΦΕΤΕ",
  "page-get-eth-exchanges-disclaimer": "Συλλέξαμε τις πληροφορίες με μη αυτόματο τρόπο. Αν εντοπίσετε κάτι λάθος, ενημερώστε μας στο",
  "page-get-eth-exchanges-empty-state-text": "Εισαγάγετε τη χώρα σας για να δείτε τη λίστα πορτοφολιών και ανταλλακτηρίων που μπορείτε να χρησιμοποιήσετε για να αγοράσετε ETH",
  "page-get-eth-exchanges-except": "Εκτός",
  "page-get-eth-exchanges-header": "Σε ποια χώρα είσαστε;",
  "page-get-eth-exchanges-header-exchanges": "Ανταλλακτήρια",
  "page-get-eth-exchanges-header-wallets": "Πορτοφόλια",
  "page-get-eth-exchanges-intro": "Τα ανταλλακτήρια και τα πορτοφόλια έχουν περιορισμούς με την πώληση κρυπτονομισμάτων.",
  "page-get-eth-exchanges-no-exchanges": "Λυπούμαστε, δεν γνωρίζουμε κάποιο ανταλλακτήριο για αγορά ETH από αυτή τη χώρα. Εάν βρείτε κάποιο ενημερώστε μας στο",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Λυπούμαστε, δεν γνωρίζουμε κάποιο ανταλλακτήριο ή πορτοφόλι για αγορά ETH από αυτή τη χώρα. Εάν βρείτε κάποιο ενημερώστε μας στο",
  "page-get-eth-exchanges-no-wallets": "Λυπούμαστε, δεν γνωρίζουμε κάποιο πορτοφόλι για αγορά ETH από αυτή τη χώρα. Εάν βρείτε κάποιο ενημερώστε μας στο",
  "page-get-eth-exchanges-success-exchange": "Μπορεί να χρειαστούν αρκετές ημέρες για να εγγραφείτε σε ένα ανταλλακτήριο ανάλογα με τους ελέγχους που απαιτούνται.",
  "page-get-eth-exchanges-success-wallet-link": "πορτοφόλια",
  "page-get-eth-exchanges-success-wallet-paragraph": "Από όπου βρίσκεστε, μπορείτε να αγοράσετε ETH απευθείας από αυτά τα πορτοφόλια. Μάθετε περισσότερα σχετικά",
  "page-get-eth-exchanges-usa": "Ηνωμένες Πολιτείες της Αμερικής (ΗΠΑ)",
  "page-get-eth-get-wallet-btn": "Επιλέξτε ένα πορτοφόλι",
  "page-get-eth-hero-image-alt": "Εικόνα ήρωα, αγορά ΕΤΗ",
  "page-get-eth-keep-it-safe": "Κρατήστε τα ETH σας ασφαλή",
  "page-get-eth-meta-description": "Πώς να αγοράσετε ETH με βάση την τοποθεσία σας, καθώς και επιπλέον συμβουλές.",
  "page-get-eth-meta-title": "Πώς να αγοράσετε ETH",
  "page-get-eth-need-wallet": "Θα χρειαστείτε ένα πορτοφόλι για να χρησιμοποιήσετε ένα DEX.",
  "page-get-eth-new-to-eth": "Είστε καινούργιοι στο ΕΤΗ; Ενημερωθείτε παρακάτω για να ξεκινήσετε.",
  "page-get-eth-other-cryptos": "Αγοράστε με άλλα κρυπτονομίσματα",
  "page-get-eth-protect-eth-desc": "Αν σκοπεύετε να αγοράσετε πολλά ETH, αποθηκεύστε τα σε ένα δικό σας πορτοφόλι και όχι στο ανταλλακτήριο. Ο λόγος είναι γιατί τα ανταλλακτήρια είναι ένας πιθανός στόχος των χάκερ. Αν ένας χάκερ αποκτήσει πρόσβαση, θα μπορούσατε να χάσετε τα χρήματά σας. Εναλλακτικά με το πορτοφόλι, μόνο εσείς έχετε τον έλεγχο του.",
  "page-get-eth-protect-eth-in-wallet": "Προστατέψτε τα ETH σας με ένα πορτοφόλι",
  "page-get-eth-search-by-country": "Αναζήτηση ανά χώρα",
  "page-get-eth-security": "Αλλά αυτό σημαίνει επίσης ότι θα πρέπει να πάρετε σοβαρά την ασφάλεια των κεφαλαίων σας. Με το ETH, δεν εμπιστεύεστε μια τράπεζα για την προστασία των χρημάτων σας, εμπιστεύεστε τον εαυτό σας.",
  "page-get-eth-smart-contract-link": "Περισσότερα για τα έξυπνα συμβόλαια",
  "page-get-eth-swapping": "Κάντε ανταλλαγή μεταξύ κρυπτονομισμάτων - ΕΤΗ και αντίστροφα.",
  "page-get-eth-traditional-currencies": "Αγορά με παραδοσιακά νομίσματα",
  "page-get-eth-traditional-payments": "Αγοράστε ETH απευθείας από τους πωλητές, με τον παραδοσιακό τρόπο πληρωμών.",
  "page-get-eth-try-dex": "Δοκιμάστε ένα Dex",
  "page-get-eth-use-your-eth": "Χρησιμοποιήστε τα ETH σας",
  "page-get-eth-use-your-eth-dapps": "Τώρα που έχετε μερικά ΕΤΗ, δείτε μερικές εφαρμογές του Ethereum (dapps). Υπάρχουν dapps για την οικονομία, τα κοινωνικά μέσα, παιχνίδια και πολλές άλλες κατηγορίες.",
  "page-get-eth-wallet-instructions": "Ακολουθήστε τις οδηγίες του πορτοφολιού",
  "page-get-eth-wallet-instructions-lost": "Αν χάσετε την πρόσβαση στο πορτοφόλι σας, θα χάσετε την πρόσβαση στα χρήματά σας. Το πορτοφόλι σας θα πρέπει να σας δώσει σχετικές οδηγίες προστασίας σε περίπτωση απώλειας. Φροντίστε να τις ακολουθήσετε προσεκτικά – στις περισσότερες περιπτώσεις, κανείς δεν μπορεί να σας βοηθήσει αν χάσετε την πρόσβαση στο πορτοφόλι σας.",
  "page-get-eth-wallets": "Πορτοφόλια",
  "page-get-eth-wallets-link": "Περισσότερα για τα πορτοφόλια",
  "page-get-eth-wallets-purchasing": "Ορισμένα πορτοφόλια σας επιτρέπουν να αγοράσετε κρυπτονομίσματα με χρεωστική/πιστωτική κάρτα, τραπεζικό έμβασμα ή ακόμα και Apple Pay. Υπάρχουν γεωγραφικοί περιορισμοί.",
  "page-get-eth-warning": "Αυτά τα DEX δεν είναι για αρχάριους, καθώς θα χρειαστείτε κάποιο ETH για να τα χρησιμοποιήσετε.",
  "page-get-eth-what-are-DEX's": "Τι είναι τα DEXs;",
  "page-get-eth-whats-eth-link": "Τι είναι το ETH;",
  "page-get-eth-where-to-buy-desc": "Μπορείτε να αγοράσετε ETH απευθείας από ανταλλακτήρια ή πορτοφόλια.",
  "page-get-eth-where-to-buy-desc-2": "Ελέγξτε ποιες υπηρεσίες μπορείτε να χρησιμοποιήσετε ανάλογα με τη χώρα σας.",
  "page-get-eth-where-to-buy-title": "Πού να αγοράσετε ETH",
  "page-get-eth-your-address": "Η διεύθυνση ETH σας",
  "page-get-eth-your-address-desc": "Όταν αποκτήσετε ένα πορτοφόλι δημιουργείται η δική σας δημόσια διεύθυνση ETH:",
  "page-get-eth-your-address-desc-3": "Σκεφτείτε το όπως το email σας, αλλά αντί της αλληλογραφίας μπορεί να λάβει ETH. Αν θέλετε να μεταφέρετε ETH από ένα ανταλλακτήριο στο πορτοφόλι σας, χρησιμοποιήστε τη διεύθυνσή σας ως προορισμό. Κάντε πάντοτε διπλό έλεγχο της διεύθυνσης πριν από την αποστολή!",
  "page-get-eth-your-address-wallet-link": "Δείτε τα πορτοφόλια",
  "page-index-hero-image-alt": "μεγάλη εικόνα ethereum.org",
  "page-index-meta-description": "Το Ethereum είναι μια παγκόσμια, αποκεντρωμένη πλατφόρμα για χρήματα και νέα είδη εφαρμογών. Στο Ethereum, μπορείτε να γράψετε κώδικα που ελέγχει χρήματα και να δημιουργήσετε εφαρμογές προσβάσιμες οπουδήποτε στον κόσμο.",
  "page-index-meta-title": "Αρχική",
  "page-index-title": "Το Ethereum είναι μια παγκόσμια πλατφόρμα ανοικτού κώδικα για αποκεντρωμένες εφαρμογές.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Υποστήριξη γλώσσας",
  "page-languages-interested": "Ενδιαφέρεστε να συνεισφέρετε;",
  "page-languages-learn-more": "Μάθετε περισσότερα για το πρόγραμμα μεταφράσεων",
  "page-languages-meta-desc": "Πόροι σε όλες τις υποστηριζόμενες γλώσσες του ethereum.org και τρόποι συμμετοχής ως μεταφραστής.",
  "page-languages-meta-title": "Μεταφράσεις του ethereum.org",
  "page-languages-p1": "Το Ethereum είναι ένα παγκόσμιο έργο και είναι κρίσιμο η ιστοσελίδα ethereum.org να είναι προσβάσιμη σε όλους, ανεξάρτητα από την εθνικότητα ή τη γλώσσα. Η κοινότητά μας εργάζεται σκληρά για να κάνει αυτό το όραμα πραγματικότητα.",
  "page-languages-translations-available": "Το ethereum.org είναι διαθέσιμο στις ακόλουθες γλώσσες",
  "page-languages-want-more-header": "Θέλετε να δείτε το ethereum.org σε μια διαφορετική γλώσσα;",
  "page-languages-want-more-link": "Πρόγραμμα μετάφρασης",
  "page-languages-want-more-paragraph": "Οι μεταφραστές του ethereum.org μεταφράζουν πάντα σελίδες σε όσο το δυνατόν περισσότερες γλώσσες. Για να δείτε την τρέχουσα κατάσταση ή να εγγραφείτε για να συμμετάσχετε, διαβάστε",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Εγγύηση κρυπτονομισμάτων",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Με το Ethereum μπορείτε να δανειστείτε απευθείας από άλλους χρήστες χωρίς να ανταλλάξετε τα ETH σας. Αυτό μπορεί να σας δώσει μόχλευση - μερικοί το κάνουν αυτό για να αποκτήσουν περισσότερα ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Αλλά επειδή η τιμή του ETΗ είναι ευμετάβλητη, θα χρειαστεί να να παρέχετε περισσότερη εγγύηση. Αυτό σημαίνει ότι αν θέλετε να δανειστείτε $100 σε νομίσματα σταθερής τιμής, θα χρειαστείτε τουλάχιστον $150 σε ETH. Έτσι προστατεύεται το σύστημα και οι δανειστές.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Περισσότερα για την υποστήριξη των νομισμάτων σταθερής τιμής",
  "page-stablecoins-accordion-borrow-pill": "Για προχωρημένους",
  "page-stablecoins-accordion-borrow-places-intro": "Με τα παρακάτω dapps μπορείτε να δανειστείτε κρυπτονομίσματα σταθερής αξίας χρησιμοποιώντας κρυπτονομίσματα ως εγγύηση. Κάποια δέχονται και άλλα νομίσματα, καθώς και ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Τοποθεσίες δανεισμού νομισμάτων σταθερής αξίας",
  "page-stablecoins-accordion-borrow-requirement-1": "Ένα πορτοφόλι Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Θα χρειαστείτε ένα πορτοφόλι για να χρησιμοποιήσετε ένα dapp",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Θα χρειαστείτε μερικά ETH για την εγγύηση ή/και τις κρατήσεις συναλλαγής",
  "page-stablecoins-accordion-borrow-requirements-description": "Για να δανειστείτε κρυπτονομίσματα σταθερής αξίας χρειάζεται να χρησιμοποιήσετε το σωστό dapp, και επίσης ένα πορτοφόλι και μερικά ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Εάν χρησιμοποιήσετε ETH ως εγγύηση και η αξία του μειωθεί, δεν θα μπορεί να καλύψει την αξία του ποσού που δανειστήκατε. Αυτό θα αναγκάσει το ETH σας να ρευστοποιηθεί και μπορεί να αντιμετωπίσετε κάποια ποινή. Έτσι, αν δανειστείτε κρυπτονομίσματα σταθερής αξίας θα πρέπει να παρακολουθήσετε την τιμή του ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Τελευταία τιμή ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Κίνδυνοι",
  "page-stablecoins-accordion-borrow-text-preview": "Μπορείτε να δανειστείτε κρυπτονομίσματα σταθερής αξίας με τη χρήση κρυπτονομισμάτων ως εγγύηση, τα οποία θα πρέπει να επιστρέψετε.",
  "page-stablecoins-accordion-borrow-title": "Δανεισμός",
  "page-stablecoins-accordion-buy-exchanges-title": "Δημοφιλείς ανταλλαγές",
  "page-stablecoins-accordion-buy-requirement-1": "Ανταλλαγές κρυπτονομισμάτων και πορτοφολιών",
  "page-stablecoins-accordion-buy-requirement-1-description": "Ελέγξτε τις υπηρεσίες που μπορείτε να χρησιμοποιείσετε ανάλογα με τον τόπο κατοικίας σας",
  "page-stablecoins-accordion-buy-requirements-description": "Με ένα λογαριασμό με δυνατότητα ανταλλαγής ή με ένα πορτοφόλι μπορείτε να αγοράσετε άμεσα κρυπτονομίσμτα.",
  "page-stablecoins-accordion-buy-text-preview": "Πολλά ανταλλακτήρια και πορτοφόλια επιτρέπουν την άμεση αγορά κρυπτονομισμάτων σταθερής αξίας. Πιθανώς να ισχύουν γεωγραφικοί περιορισμοί.",
  "page-stablecoins-accordion-buy-title": "Αγορά",
  "page-stablecoins-accordion-buy-warning": "Τα κεντρικά ανταλλακτήρια μπορεί να διαθέτουν μόνο κρυπτονομίσματα σταθερής αξίας που υποστηρίζονται από τα fiat (κανονικά) χρήματα, όπως τα USDC, Tether και άλλα. Δεν μπορείτε να τα αγοράσετε άμεσα αλλά μπορείτε να τα ανταλλάξετε από ΕΤΗ ή άλλα κρυπτονομίσματα που έχετε στην κατοχή σας από τις διάφορες πλατφόρμες.",
  "page-stablecoins-accordion-earn-project-1-description": "Κυρίως η τεχνική εργασία που απαιτείται για τη δρομολόγηση του ανοιχτού λογισμικού.",
  "page-stablecoins-accordion-earn-project-2-description": "Τεχνολογία, περιεχόμενο και άλλες εργασίες για την κοινότητα του MakerDao (την ομάδα που δημιούργησε το Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Από τη στιγμή που έχετε βαθιά γνώση των πραγμάτων, εντοπίστε σφάλματα και κερδίστε Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Ανταμοιβές του Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Ανταμοιβή εύρεσης σφαλμάτων Eth2",
  "page-stablecoins-accordion-earn-project-community": "Κοινότητα MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Παρακάτω οι πλατφόρμες που θα σας πληρώσουν σε stablecoins για την εργασία σας.",
  "page-stablecoins-accordion-earn-projects-title": "Πού να κερδίσετε stablecoins",
  "page-stablecoins-accordion-earn-requirement-1": "Πορτοφόλι Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Θα χρειαστείτε ένα πορτοφόλι για να λάβετε τα stablecoin που κερδίζετε",
  "page-stablecoins-accordion-earn-requirements-description": "Τα κρυπτονομίσματα σταθερής αξίας είναι μια μέθοδος πληρωμής για εργασίες και υπηρεσίες εξαιτίας της σταθερής τιμής τους. Αλλά βέβαια χρειάζεστε ένα πορτοφόλι για να πληρωθείτε.",
  "page-stablecoins-accordion-earn-text-preview": "Μπορείτε να κερδίσετε stablecoins συμμετέχοντας σε έργα εντός του οικοσυστήματος του Ethereum.",
  "page-stablecoins-accordion-earn-title": "Κερδίστε",
  "page-stablecoins-accordion-less": "Λιγότερα",
  "page-stablecoins-accordion-more": "Περισσότερα",
  "page-stablecoins-accordion-requirements": "Τι θα χρειαστείτε",
  "page-stablecoins-accordion-swap-dapp-intro": "Εάν έχετε ήδη ETH και πορτοφόλι, μπορείτε να χρησιμοποιήσετε αυτές τις αποκεντρωμένες εφαρμογές για να ανταλλάξετε με νομίσματα σταθερής αξίας.",
  "page-stablecoins-accordion-swap-dapp-link": "Περισσότερα για τα αποκεντρωμένα ανταλλακτήρια",
  "page-stablecoins-accordion-swap-dapp-title": "Εφαρμογές για μετατροπή κρυπτονομισμάτων",
  "page-stablecoins-accordion-swap-editors-tip": "Συμβουλή συντάκτη",
  "page-stablecoins-accordion-swap-editors-tip-button": "Επιλογή πορτοφολιού",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Αποκτήστε ένα πορτοφόλι που θα σας επιτρέπει να αγοράζετε ΕΤΗ και να μπορείτε να τα μετατρέπετε ακόμη και με νομίσματα σταθερής αξίας, άμεσα.",
  "page-stablecoins-accordion-swap-pill": "Προτεινόμενα",
  "page-stablecoins-accordion-swap-requirement-1": "Ένα πορτοφόλι Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Θα χρειαστείτε ένα πορτοφόλι για να εγκρίνετε την ανταλλαγή και να αποθηκεύσετε τα νομίσματα σας",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Για να πληρώσετε την ανταλλαγή",
  "page-stablecoins-accordion-swap-text-preview": "Μπορείτε να επιλέξετε τα περισσότερα κρυπτονομίσματα στα αποκεντρωμένα ανταλλακτήρια. Έτσι μπορείτε να τα μετατρέψετε σε όποια θέλετε.",
  "page-stablecoins-accordion-swap-title": "Μετατροπή",
  "page-stablecoins-algorithmic": "Αλγόριθμος",
  "page-stablecoins-algorithmic-con-1": "Πρέπει να εμπιστεύεστε (ή να είστε σε θέση να διαβάσετε) τον αλγόριθμο.",
  "page-stablecoins-algorithmic-con-2": "Το υπόλοιπο των νομισμάτων σας θα διαμορφωθεί σύμφωνα με την τελική προσφορά.",
  "page-stablecoins-algorithmic-description": "Αυτά τα κρυπτονομίσματα δεν υποστηρίζονται από κάποιο άλλο περιουσιακό στοιχείο. Αντιθέτως ένας αλγόριθμος θα προβεί σε πώληση εάν η τιμή πέσει κάτω από την επιθυμητή ή θα θέσει σε προσφορά σε περίπτωση αύξησης της καθορισμένης τιμής. Επειδή αυτός ο αριθμός αλλάζει συνεχώς, ο αριθμός των νομισμάτων που είναι στην κατοχή σας αλλάζει συνεχώς αλλά πάντοτε θα αντιπροσωπεύει το μερίδιό σας.",
  "page-stablecoins-algorithmic-pro-1": "Δεν απαιτείται εγγύηση.",
  "page-stablecoins-algorithmic-pro-2": "Διαχειρίζεται από ένα δημόσιο αλγόριθμο.",
  "page-stablecoins-bank-apy": "0,05%",
  "page-stablecoins-bank-apy-source": "Το μέσο επιτόκιο που καταβάλλουν οι τράπεζες σε βασικούς, ομοσπονδιακά ασφαλισμένους λογαριασμούς ταμιευτηρίου, στις ΗΠΑ.",
  "page-stablecoins-bank-apy-source-link": "Πηγή",
  "page-stablecoins-bitcoin-pizza": "Η περίφημη πίτσα Bitcoin",
  "page-stablecoins-bitcoin-pizza-body": "Το 2010, κάποιος αγόρασε 2 πίτσες στην τιμή των 10.000 bitcoin. Εκείνη τη στιγμή η αξία τους ήταν περίπου ~$41 USD, ενώ σήμερα αξίζουν μερικά εκατομμύρια. Υπάρχουν βέβαια και άλλες παρόμοιες λυπηρές συναλλαγές στην ιστορία του Ethereum. Τα κρυπτονομίσματα σταθερής αξίας έχουν επιλύσει αυτό το πρόβλημα, οπότε μπορείτε να απολαύσετε την πίτσα σας και να κρατήσετε τα ΕΤΗ σας.",
  "page-stablecoins-coin-price-change": "Μεταβολή τιμής νομίσματος (τελευταίες 30 ημέρες)",
  "page-stablecoins-crypto-backed": "Υποστήριξη κρυπτονομισμάτων",
  "page-stablecoins-crypto-backed-con-1": "Λιγότερο σταθερό από τα υποστηριζόμενα με fiat κρυπτονομίσματα σταθερής αξίας.",
  "page-stablecoins-crypto-backed-con-2": "Θα πρέπει να παρακολουθείτε την αξία της εγγύησης των κρυπτονομισμάτων.",
  "page-stablecoins-crypto-backed-description": "Τα παρακάτω κρυπτονομίσματα σταθερής αξίας υποστηρίζονται από άλλα κρυπτονομίσματα όπως το ΕΤΗ. Η τιμή τους εξαρτάται από την τιμή του υποκείμενου περιουσιακού στοιχείου (ή της εγγύησης) η οποία μπορεί να είναι μεταβλητή. Επειδή η αξία του ΕΤΗ δεν είναι σταθερή αυτά τα κρυπτονομίσματα σταθερής αξίας ενισχύονται επιπλέον, για λόγους σταθερότητας της τιμής τους. Αυτό σημαίνει ότι ένα κρυπτονόμισμα σταθερής αξίας του 1$ υποστηρίζεται από περιουσιακό στοιχείο αξίας 2$. Έτσι εάν υπάρξει μείωση της αξίας του ΕΤΗ να μπορούν να χρησιμοποιηθούν περισσότερα κρυπτονομίσματα για την υποστήριξη της τιμής των σταθερών κρυπτονομισμάτων ώστε να μην χάσουν την σταθερή αξία τους.",
  "page-stablecoins-crypto-backed-pro-1": "Διαφανές και πλήρως αποκεντρωμένο.",
  "page-stablecoins-crypto-backed-pro-2": "Γρήγορη μετατροπή σε άλλα κρυπτονομίσματα.",
  "page-stablecoins-crypto-backed-pro-3": "Χωρίς εξωτερικούς θεματοφύλακες – όλα τα περιουσιακά στοιχεία ελέγχονται από λογαριασμούς στο Ethereum.",
  "page-stablecoins-dai-banner-body": "Το Dai είναι ίσως το πιο διαδεδομένο αποκεντρωμένο κρυπτονόμισμα σταθερής αξίας. Η αξία του είναι ίση με του δολαρίου και είναι αποδεκτό μεταξύ όλων των αποκεντρωμένων εφαρμογών.",
  "page-stablecoins-dai-banner-learn-button": "Μάθετε για το Dai",
  "page-stablecoins-dai-banner-swap-button": "Ανταλλαγή ETH με Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Το λογότυπο του Dai",
  "page-stablecoins-editors-choice": "Επιλογές συντακτών",
  "page-stablecoins-editors-choice-intro": "Παρακάτω είναι ίσως τα ποιο γνωστά κρυπτονομίσματα σταθερής αξίας με χρήση μεταξύ των αποκεντρωμένων εφαρμογών.",
  "page-stablecoins-explore-dapps": "Εξερευνήστε τα dapps",
  "page-stablecoins-fiat-backed": "Υποστήριξη Fiat",
  "page-stablecoins-fiat-backed-con-1": "Κεντρικά - θα πρέπει κάποιος να εκδώσει τα νομίσματα.",
  "page-stablecoins-fiat-backed-con-2": "Απαιτείται έλεγχος για την εξασφάλιση της επάρκειας του αποθεματικού.",
  "page-stablecoins-fiat-backed-description": "Βασικά ένα IOU (I owe you) για ένα παραδοσιακό fiat νόμισμα (συνήθως δολάρια). Χρησιμοποιήστε τα fiat νομίσματά σας για να αγοράσετε κρυπτονομίσματα σταθερής αξίας, που μπορείτε αργότερα να εξαργυρώσετε με το αρχικό σας νόμισμα.",
  "page-stablecoins-fiat-backed-pro-1": "Ασφαλής ενάντια στην μεταβλητότητα των κρυπτονομισμάτων.",
  "page-stablecoins-fiat-backed-pro-2": "Οι αλλαγές στην τιμή είναι ελάχιστες.",
  "page-stablecoins-find-stablecoin": "Βρείτε ένα stablecoin",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Πώς να πάρετε stablecoin",
  "page-stablecoins-find-stablecoin-intro": "Υπάρχουν εκατοντάδες stablecoin διαθέσιμα. Δείτε μερικά παρακάτω για να σας βοηθήσουν να ξεκινήσετε. Αν είστε νέοι στο Ethereum, σας συνιστούμε να κάνετε κάποια έρευνα πρώτα.",
  "page-stablecoins-find-stablecoin-types-link": "Διαφορετικοί τύποι stablecoin",
  "page-stablecoins-get-stablecoins": "Πώς να πάρετε stablecoin",
  "page-stablecoins-hero-alt": "Τα τρία μεγαλύτερα κρυπτονομίσματα σταθερής αξίας: Dai, USDC και Tether.",
  "page-stablecoins-hero-button": "Αγοράστε stablecoins",
  "page-stablecoins-hero-header": "Ψηφιακά χρήματα για καθημερινή χρήση",
  "page-stablecoins-hero-subtitle": "Τα κρυπτονομίσματα σταθερής αξίας (stablecoins) είναι νομίσματα Ethereum σχεδιασμένα να παραμένουν σε σταθερή τιμή ακόμη και εάν η τιμή του ΕΤΗ μεταβάλλεται.",
  "page-stablecoins-interest-earning-dapps": "Dapps που κερδίζουν τόκους",
  "page-stablecoins-meta-description": "Μια εισαγωγή στα κρυπτονομίσματα του Ethereum σταθερής αξίας: τι είναι, πώς θα τα αποκτήσετε και γιατί είναι σημαντικά.",
  "page-stablecoins-precious-metals": "Πολύτιμα μέταλλα",
  "page-stablecoins-precious-metals-con-1": "Κεντρικά - θα πρέπει κάποιος να εκδώσει τα νομίσματα.",
  "page-stablecoins-precious-metals-con-2": "Θα πρέπει να εμπιστευτείτε τον εκδότη των νομισμάτων και το απόθεμα σε πολύτιμα μέταλλα.",
  "page-stablecoins-precious-metals-description": "Όπως τα νομίσματα που υποστηρίζονται από fiat χρήματα, αυτά τα κρυπτονομίσματα σταθερής αξίας χρησιμοποιούν πηγές όπως τον χρυσό για προσδιορισμό της τιμής τους.",
  "page-stablecoins-precious-metals-pro-1": "Ασφαλή ενάντια στην μεταβλητότητα των κρυπτονομισμάτων.",
  "page-stablecoins-prices": "Τιμές Stablecoin",
  "page-stablecoins-prices-definition": "Τα κρυπτονομίσματα σταθερής αξίας είναι κρυπτονομίσματα χωρίς τη μεταβλητότητα της αξίας τους. Χρησιμοποιούν την ίδια δυναμική με το ΕΤΗ αλλά η τιμή τους παραμένει σταθερή όπως ένα παραδοσιακό νόμισμα. Έτσι έχετε πρόσβαση σε σταθερά χρήματα που μπορείτε να χρησιμοποιήσετε μέσα στο Ethereum. ",
  "page-stablecoins-prices-definition-how": "Πώς τα κρυπτονομίσματα σταθερής αξίας διατηρούν τη σταθερότητά τους",
  "page-stablecoins-research-warning": "Το Ethereum είναι μια νέα τεχνολογία και οι περισσότερες εφαρμογές είναι καινούργιες. Σιγουρευτείτε ότι έχετε κατανοήσει τον κίνδυνο και συμμετέχετε μόνο με αυτά που είστε διατεθειμένοι που πιθανώς να χάσετε.",
  "page-stablecoins-research-warning-title": "Να κάνετε πάντα τη δική σας έρευνα",
  "page-stablecoins-save-stablecoins": "Κερδίστε από τα κρυπτονομίσματα σταθερής αξίας",
  "page-stablecoins-save-stablecoins-body": "Τα νομίσματα σταθερής αξίας έχουν συχνά ένα επιτόκιο άνω του μέσου όρου εξαιτίας της ζήτησης για δανεισμό τους. Υπάρχουν αποκεντρωμένες εφαρμογές που σας αποδίδουν κέρδος με την αποθήκευση των νομισμάτων σας σε δεξαμενές δανεισμού. Όπως στο τραπεζικό σύστημα, καταθέτονται χρήματα τα οποία είναι διαθέσιμα για δανειστές αλλά και άμεσα διαθέσιμα για ανάληψη.",
  "page-stablecoins-saving": "Τοποθετήστε τα κρυπτονομίσματά σας στο σωστό μέρος για να αποδώσουν καλύτερα. Όπως τα πάντα στα κρυπτονομίσματα, το Επιτόκιο αποδόσεως [Annual Percentage Yields (APY)] μπορεί να αλλάξει καθημερινά ανάλογα με την προσφορά/ζήτηση.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Ελέγξτε τις αποκεντρωμένες εφαρμογές του Ethereum - τα κρυπτονομίσματα σταθερής αξίας είναι χρήσιμα για τις καθημερινές συναλλαγές.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Απεικόνιση ενός σκύλου.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Χρήση των κρυπτονομισμάτων σταθερής αξίας",
  "page-stablecoins-stablecoins-dapp-description-1": "Αγορές πολλών κρυπτονομισμάτων σταθερής αξίας όπως τα Dai, USDC, TUSD, USDT κ.ά. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Δανείστε κρυπτονομίσματα σταθερής αξίας και κερδίστε τόκους και $COMP, νόμισμα του Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Μια πλατφόρμα συναλλαγών όπου μπορείτε να κερδίσετε τόκους για τα Dai και USDC σας.",
  "page-stablecoins-stablecoins-dapp-description-4": "Μια εφαρμογή σχεδιασμένη για τη διαχείριση Dai.",
  "page-stablecoins-stablecoins-feature-1": "Τα κρυπτονομίσματα είναι παγκόσμια και μπορούν να σταλούν μέσω του Διαδικτύου. Μπορείτε εύκολα να στείλετε ή να λάβετε εφόσον έχετε ένα λογαριασμό Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "Η ζήτηση σε κρυπτονομίσματα σταθερής αξία είναι μεγάλη, οπότε μπορείτε να κερδίσετε δανείζοντας τα δικά σας. Σιγουρευτείτε πως έχετε κατανοήσει πλήρως το ρίσκο του δανεισμού.",
  "page-stablecoins-stablecoins-feature-3": "Τα κρυπτονομίσματα σταθερής αξίας είναι ανταλλάξιμα με ΕΤΗ και άλλα νομίσματα Ethereum. Αρκετές εφαρμογές παραπέμπουν σε κρυπτονομίσματα σταθερής αξίας.",
  "page-stablecoins-stablecoins-feature-4": "Τα κρυπτονομίσματα είναι ασφαλισμένα με κρυπτογράφηση. Κανείς δεν μπορεί να πλαστογραφήσει τις συναλλαγές σας.",
  "page-stablecoins-stablecoins-meta-description": "Μια εισαγωγή στα κρυπτονομίσματα σταθερής αξίας του Ethereum: τι είναι, πώς θα τα αποκτήσετε και γιατί είναι σημαντικά.",
  "page-stablecoins-stablecoins-table-header-column-1": "Νόμισμα",
  "page-stablecoins-stablecoins-table-header-column-2": "Κεφαλαιοποίηση αγοράς",
  "page-stablecoins-stablecoins-table-header-column-3": "Τύπος νομίσματος",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Κρυπτονόμισμα",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Fiat",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Πολύτιμα μέταλλα",
  "page-stablecoins-table-error": "Δεν ήταν δυνατή η φόρτωση των κρυπτονομισμάτων σταθερής αξίας. Δοκιμάστε να ανανεώσετε τη σελίδα.",
  "page-stablecoins-table-loading": "Φόρτωση δεδομένων κρυπτονομισμάτων σταθερής αξίας...",
  "page-stablecoins-title": "Κρυτπονομίσματα σταθερής αξίας (Stablecoins)",
  "page-stablecoins-top-coins": "Κορυφαία κρυπτονομίσματα σταθερής αξίας ανά κεφαλαιοποίηση αγοράς",
  "page-stablecoins-top-coins-intro": "Η κεφαλαιοποίηση αγοράς είναι",
  "page-stablecoins-top-coins-intro-code": "ο συνολικός αριθμός των νομισμάτων πολλαπλασιασμένος επί την τιμή ανά νόμισμα. Αυτή η λίστα είναι δυναμική και η κατάσταση των έργων που αναφέρονται δεν είναι απαραίτητα επικυρωμένα από την ομάδα του ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Πώς λειτουργούν: τύποι κρυπτονομισμάτων σταθερής αξίας",
  "page-stablecoins-usdc-banner-body": "Το USDc είναι ίσως το πιο διαδεδομένο υποστηριζόμενο με fiat κρυπτονόμισμα σταθερής αξίας. Η αξία του είναι ίση με αυτή του δολαρίου και υποστηρίζεται από τα Circle και Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Μάθετε για το USDC",
  "page-stablecoins-usdc-banner-swap-button": "Ανταλλαγή ETH για USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Το λογότυπο του USDC",
  "page-stablecoins-why-stablecoins": "Γιατί κρυπτονομίσματα σταθερής αξίας;",
  "page-stablecoins-how-they-work-button": "Πώς λειτουργούν",
  "page-stablecoins-why-stablecoins-body": "Το ΕΤΗ όπως το Bitcoin, έχουν μια ευμετάβλητη τιμή εξαιτίας του νεαρού της τεχνολογίας τους. Οπότε μπορεί να μην θέλετε να τα δαπανάτε τακτικά. Τα κρυπτονομίσματα σταθερής αξίας αντικατοπτρίζουν την αξία των παραδοσιακών νομισμάτων και σας παρέχουν πρόσβαση σε σταθερής αξίας χρήματα που μπορείτε να χρησιμοποιήσετε στο Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Εάν θέλετε να προσθέσουμε ένα πορτοφόλι,",
  "page-find-wallet-alpha-logo-alt": "Λογότυπο AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Λογότυπο Ambo",
  "page-find-wallet-argent-logo-alt": "Λογότυπο Argent",
  "page-find-wallet-buy-card": "Αγορά κρυπτονομισμάτων με κάρτα",
  "page-find-wallet-buy-card-desc": "Αγοράστε ETH απευθείας από το πορτοφόλι σας με τραπεζική κάρτα. Ενδέχεται να ισχύουν γεωγραφικοί περιορισμοί.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Δείτε τις εφαρμογές dapps",
  "page-find-wallet-clear": "Κατάργηση φίλτρων",
  "page-find-wallet-coinbase-logo-alt": "Λογότυπο Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Επιλέξτε το πορτοφόλι σας με βάση τα χαρακτηριστικά που ταιριάζουν σε εσάς.",
  "page-find-wallet-description": "Τα πορτοφόλια έχουν πολλές προαιρετικές δυνατότητες που μπορεί να σας αρέσουν.",
  "page-find-wallet-description-alpha": "Πλήρως ανοικτού κώδικα για Ethereum που αξιοποιεί το ασφαλές περιβάλλον στο κινητό, προσφέρει πλήρη υποστήριξη testnet και υιοθετεί το πρότυπο TokenScript.",
  "page-find-wallet-description-ambo": "Σχεδόν άμεση χρήση των επενδύσεών σας μέσα σε λίγα λεπτά από τη λήψη της εφαρμογής",
  "page-find-wallet-description-argent": "Με ένα πάτημα κερδίστε τόκους & επενδύσεις. Δανειστείτε, αποθηκεύστε και στείλτε. Κάντε το δικό σας.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Η ασφαλής εφαρμογή για να αποθηκεύσετε τα κρυπτονομίσματά σας",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Το Dharma είναι η πύλη πρόσβασης σε DeFi. Όχι άλλοι διαμοιρασμοί ή επεκτάσεις Chrome.",
  "page-find-wallet-description-enjin": "Αδιαπέραστο, με αρκετές λειτουργίες και κατασκευασμένο ειδικά για εμπόρους, προγραμματιστές και όσους ασχολούνται με παιχνίδια",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Η πιο αξιόπιστη πλατφόρμα για την αποθήκευση των Ethereum assets σας",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "το imToken είναι ένα εύκολο και ασφαλές ψηφιακό πορτοφόλι που εμπιστεύονται εκατομμύρια χρήστες",
  "page-find-wallet-description-ledger": "Κρατήστε τα περιουσιακά σας στοιχεία ασφαλή με τα υψηλότερα πρότυπα ασφαλείας",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Ξεκινήστε την εξερεύνηση εφαρμογών blockchain σε δευτερόλεπτα. Το εμπιστεύονται πάνω από 1 εκατομμύριο χρήστες παγκοσμίως.",
  "page-find-wallet-description-monolith": "Το μοναδικό πορτοφόλι στον κόσμο που συνδέεται με χρεωστική κάρτα Visa. Διατίθεται σε Ηνωμένο Βασίλειο και ΕΕ και μπορεί να χρησιμοποιηθεί παγκοσμίως.",
  "page-find-wallet-description-multis": "Το Multis είναι ένας λογαριασμός κρυπτονομισμάτων σχεδιασμένος για επιχειρήσεις. Με το Multis, οι εταιρείες μπορούν να αποθηκεύσουν με έλεγχο πρόσβασης, να κερδίσουν τόκους από την αποταμίευση τους και να διαχειριστούν τις πληρωμές και τη λογιστική.",
  "page-find-wallet-description-mycrypto": "Το MyCrypto είναι μια διεπαφή για τη διαχείριση όλων των λογαριασμών σας. Εναλλάσσετε, στείλτε και αγοράστε κρυπτονομίσματα με πορτοφόλια όπως τα MetaMask, Ledger, Trezor και άλλα.",
  "page-find-wallet-description-myetherwallet": "Ένα ελεύθερο, περιβάλλον πελάτη, που σας βοηθά να αλληλεπιδράσετε με το Ethereum blockchain",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Ένα κοινοτικό πορτοφόλι, χωρίς επίβλεψη, με το δικό του δίκτυο πληρωμών L2.",
  "page-find-wallet-description-portis": "Το blockchain πορτοφόλι χωρίς επίβλεψη, που κάνει τις εφαρμογές απλές για όλους",
  "page-find-wallet-description-rainbow": "Ένα καλύτερο σπιτικό για τα Ethereum assets σας",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Ασφαλής εφαρμογή μηνυμάτων, πορτοφόλι κρυπτονομισμάτων και πρόγραμμα περιήγησης Web3, χτισμένο με τεχνολογία αιχμής",
  "page-find-wallet-description-tokenpocket": "TokenPocket：Ένα ασφαλές και παγκοσμίως κορυφαίο ψηφιακό πορτοφόλι νομισμάτων και μια πύλη για DApps, με υποστήριξη Multi-chain.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Σύνδεση με ένα κλικ σε Web 3.0",
  "page-find-wallet-description-trezor": "Η πρώτη επίσημη συσκευή πορτοφολιού",
  "page-find-wallet-description-trust": "Το Trust Wallet, είναι ένα αποκεντρωμένο πορτοφόλι κρυπτονομισμάτων. Αγοράστε κρυπτονομίσματα, εξερευνήστε τις εφαρμογές, τα swap assets και άλλα, διατηρώντας τον έλεγχο των κλειδιών σας.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "Το ZenGo είναι το πρώτο πορτοφόλι κρυπτονομισμάτων χωρίς κλειδί. Με το ZenGo, δεν υπάρχουν ιδιωτικά κλειδιά, κωδικοί πρόσβασης ή φράσεις για να διαχειριστείτε ή να χάσετε. Αγοράστε, ανταλλάξτε, κερδίστε και αποθηκεύστε το Ethereum με πρωτοφανή απλότητα και ασφάλεια",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Λογότυπο Dharma",
  "page-find-wallet-enjin-logo-alt": "Λογότυπο Enjin",
  "page-find-wallet-Ethereum-wallets": "Πορτοφόλια Ethereum",
  "page-find-wallet-explore-dapps": "Εξερευνήστε τα dapps",
  "page-find-wallet-explore-dapps-desc": "Αυτά τα πορτοφόλια σχεδιάστηκαν για να σας βοηθήσουν να συνδεθείτε με τις εφαρμογές Ethereum.",
  "page-find-wallet-feature-h2": "Επιλέξτε τα χαρακτηριστικά του πορτοφολιού που σας ενδιαφέρουν",
  "page-find-wallet-fi-tools": "Αποκτήστε πρόσβαση σε χρηματοοικονομικά εργαλεία",
  "page-find-wallet-fi-tools-desc": "Δανείστε, δανειστείτε και κερδίστε τόκους απευθείας από το πορτοφόλι σας.",
  "page-find-wallet-following-features": "με τα ακόλουθα χαρακτηριστικά:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Λογότυπο Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Βρείτε εικόνα ήρωα πορτοφολιού",
  "page-find-wallet-imtoken-logo-alt": "Λογότυπο imToken",
  "page-find-wallet-last-updated": "Τελευταία ενημέρωση",
  "page-find-wallet-ledger-logo-alt": "Λογότυπο Ledger",
  "page-find-wallet-limits": "Όρια προστασίας",
  "page-find-wallet-limits-desc": "Διασφαλίστε το κεφάλαιό σας ορίζοντας το μέγιστο όριο ανάληψης ή μεταφοράς.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "πολιτική καταχώρησης",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Βρείτε και συγκρίνετε πορτοφόλια Ethereum με βάση τα χαρακτηριστικά που χρειάζεστε.",
  "page-find-wallet-meta-title": "Βρείτε ένα πορτοφόλι Ethereum",
  "page-find-wallet-metamask-logo-alt": "Λογότυπο MetaMask",
  "page-find-wallet-monolith-logo-alt": "Λογότυπο Monolith",
  "page-find-wallet-multis-logo-alt": "Λογότυπο Multis",
  "page-find-wallet-multisig": "Λογαριασμοί πολλαπλής ασφαλείας",
  "page-find-wallet-multisig-desc": "Για επιπλέον ασφάλεια, τα πορτοφόλια πολλαπλής ασφάλειας απαιτούν περισσότερους από έναν κωδικούς για να εγκρίνουν ορισμένες συναλλαγές.",
  "page-find-wallet-mycrypto-logo-alt": "Λογότυπο MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Λογότυπο MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Είστε καινούργιοι στα πορτοφόλια; Ενημερωθείτε παρακάτω πριν ξεκινήσετε.",
  "page-find-wallet-new-to-wallets-link": "Πορτοφόλια Ethereum",
  "page-find-wallet-not-all-features": "Κανένα πορτοφόλι δεν διαθέτει όλα αυτά τα χαρακτηριστικά",
  "page-find-wallet-not-endorsements": "Τα πορτοφόλια που παρατίθενται σε αυτή τη σελίδα δεν είναι επίσημα εγκεκριμένα και παρέχονται μόνο για ενημερωτικούς σκοπούς. Οι περιγραφές έχουν παραχωρηθεί από τις αντίστοιχες εταιρείες. Προσθέτουμε προϊόντα σε αυτή τη σελίδα με βάση τα κριτήρια μας",
  "page-find-wallet-overwhelmed": "στα Ethereum πορτοφόλια παρακάτω. Πάρα πολλά; Εφαρμόστε τα παρακάτω φίλτρα.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Λογότυπο Pillar",
  "page-find-wallet-portis-logo-alt": "Λογότυπο Portis",
  "page-find-wallet-rainbow-logo-alt": "Λογότυπο Rainbow",
  "page-find-wallet-raise-an-issue": "αναφέρετε ένα σφάλμα στο GitHub",
  "page-find-wallet-search-btn": "Αναζήτηση επιλεγμένων χαρακτηριστικών",
  "page-find-wallet-showing": "Εμφανίζονται ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Λογότυπο Status",
  "page-find-wallet-swaps": "Μετατροπή με αποκεντρωμένα στοιχεία",
  "page-find-wallet-swaps-desc": "Ανταλλαγή μεταξύ ETH και άλλων νομισμάτων απευθείας από το πορτοφόλι σας.",
  "page-find-wallet-title": "Επιλογή πορτοφολιού",
  "page-find-wallet-tokenpocket-logo-alt": "Λογότυπο TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Λογότυπο Torus",
  "page-find-wallet-trezor-logo-alt": "Λογότυπο Trezor",
  "page-find-wallet-trust-logo-alt": "Λογότυπο Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Δοκιμάστε να αφαιρέσετε ένα ή δύο χαρακτηριστικά",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Τώρα που έχετε πορτοφόλι, δείτε μερικές εφαρμογές του Ethereum (dapps). Υπάρχουν dapps για την οικονομία, τα κοινωνικά μέσα, παιχνίδια και πολλές άλλες κατηγορίες.",
  "page-find-wallet-use-your-wallet": "Χρησιμοποιήστε το πορτοφόλι σας",
  "page-find-wallet-voluem-desc": "Αν θέλετε να κατέχετε αρκετά ETH, επιλέξτε ένα πορτοφόλι που σας επιτρέπει να αγοράσετε περισσότερα από $2000 ETH κάθε φορά.",
  "page-find-wallet-volume": "Αγορές υψηλού όγκου",
  "page-find-wallet-we-found": "Βρέθηκαν",
  "page-find-wallet-withdraw": "Ανάληψη σε τράπεζα",
  "page-find-wallet-withdraw-desc": "Μπορείτε να εξαργυρώσετε τα ETH σας κατευθείαν στον τραπεζικό σας λογαριασμό χωρίς να κάνετε ανταλλαγή.",
  "page-find-wallet-yet": "ακόμη",
  "page-find-wallet-zengo-logo-alt": "Λογότυπο ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Αποθήκευση ΕΤΗ",
  "page-wallets-accounts-addresses": "Πορτοφόλια, λογαριασμοί και διευθύνσεις",
  "page-wallets-accounts-addresses-desc": "Αξίζει να κατανοήσουμε τις διαφορές μεταξύ ορισμένων βασικών όρων.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Απεικόνιση ενός ρομπότ με ένα θησαυροφυλάκιο στο σώμα του, που αντιπροσωπεύει ένα πορτοφόλι Ethereum",
  "page-wallets-ethereum-account": "Λογαριασμός Ethereum",
  "page-wallets-blog": "Ιστολόγιο Coinbase",
  "page-wallets-bookmarking": "Αποθηκεύστε το πορτοφόλι σας στους σελιδοδείκτες",
  "page-wallets-bookmarking-desc": "Αν χρησιμοποιείτε ένα πορτοφόλι ιστού, προσθέστε τη σελίδα στους σελιδοδείκτες σας, για να προστατευθείτε από απάτες.",
  "page-wallets-cd": "Συσκευές πορτοφολιού που σας επιτρέπουν να κρατάτε τα κρυπτονομίσματά σας εκτός σύνδεσης – πολύ ασφαλή",
  "page-wallets-converted": "Επένδυση σε κρυπτονομίσματα;",
  "page-wallets-converted-desc": "Αν θέλετε να διατηρήσετε σοβαρή αξία κρυπτονομισμάτων, σας συνιστούμε να το κάνετε με συσκευή πορτοφολιού, καθώς είναι τα πιο ασφαλή. Ή ένα πορτοφόλι με ειδοποιήσεις παραβίασης και όριο ανάληψης.",
  "page-wallets-curious": "Περιέργεια για τα κρυπτονομίσματα;",
  "page-wallets-curious-desc": "Αν είστε καινούργιοι στα κρυπτονομίσματα και απλά θέλετε να πάρετε μια γεύση, σας συνιστούμε κάτι που θα σας δώσει την ευκαιρία να εξερευνήσετε τις εφαρμογές Ethereum ή να αγοράσετε το πρώτο σας ETH, απευθείας από το πορτοφόλι σας.",
  "page-wallets-desc-2": "Χρειάζεστε ένα πορτοφόλι για να στείλετε κεφάλαια και να διαχειριστείτε ETH.",
  "page-wallets-desc-2-link": "Περισσότερα για το ETH",
  "page-wallets-desc-3": "Το πορτοφόλι σας είναι μόνο το εργαλείο διαχείρισης του λογαριασμού σας Ethereum. Αυτό σημαίνει ότι μπορείτε να ανταλλάξετε παρόχους πορτοφολιού ανά πάσα στιγμή. Πολλά πορτοφόλια, σας επιτρέπουν επίσης να διαχειριστείτε πολλούς λογαριασμούς Ethereum από μία εφαρμογή.",
  "page-wallets-desc-4": "Αυτό συμβαίνει επειδή τα πορτοφόλια δεν έχουν την κυριότητα των κεφαλαίων σας, αλλά εσείς. Είναι απλά το εργαλείο για τη διαχείριση της περιουσίας σας.",
  "page-wallets-description": "Τα πορτοφόλια Ethereum είναι εφαρμογές που σας επιτρέπουν να διαχειριστείτε τον λογαριασμό. Σκεφτείτε το σαν μια εφαρμογή δικτυακής τραπεζικής – χωρίς την τράπεζα. Το πορτοφόλι σας επιτρέπει να ελέγξετε το υπόλοιπό σας, να κάνετε συναλλαγές και να συνδεθείτε σε εφαρμογές.",
  "page-wallets-desktop": "Εφαρμογές για υπολογιστή, αν προτιμάτε να διαχειριστείτε τα κεφάλαιά σας μέσω MacOS, windows ή linux",
  "page-wallets-ethereum-wallet": "Ένα πορτοφόλι",
  "page-wallets-explore": "Εξερευνήστε το Ethereum",
  "page-wallets-features-desc": "Μπορούμε να σας βοηθήσουμε να επιλέξετε το πορτοφόλι σας με βάση τα χαρακτηριστικά που σας ενδιαφέρουν.",
  "page-wallets-features-title": "Θα επιλέξετε με βάση τις δυνατότητες;",
  "page-wallets-find-wallet-btn": "Επιλογή πορτοφολιού",
  "page-wallets-find-wallet-link": "Επιλογή πορτοφολιού",
  "page-wallets-get-some": "Αποκτήστε ETH",
  "page-wallets-get-some-alt": "Μια απεικόνιση ενός χεριού που δημιουργεί το λογότυπο του ETH από lego τουβλάκια",
  "page-wallets-get-some-btn": "Αποκτήστε ETH",
  "page-wallets-get-some-desc": "Το ETH είναι το αρχικό κρυπτονόμισμα του Ethereum. Θα χρειαστείτε νομίσματα ETH στο πορτοφόλι σας για να χρησιμοποιήσετε τις εφαρμογές του Ethereum.",
  "page-wallets-get-wallet": "Αποκτήστε ένα πορτοφόλι",
  "page-wallets-get-wallet-desc": "Υπάρχουν πολλά διαφορετικά πορτοφόλια για να διαλέξετε. Θέλουμε να σας βοηθήσουμε να επιλέξετε το καλύτερο που ταιριάζει σε εσάς.",
  "page-wallets-get-wallet-desc-2": "Θυμηθείτε: δεν είναι δεσμευτική επιλογή – ο λογαριασμός σας στο Ethereum δεν συνδέεται με τον πάροχο του πορτοφολιού.",
  "page-wallets-how-to-store": "Πώς να αποθηκεύσετε τα ψηφιακά κρυπτονομίσματά σας στο Ethereum",
  "page-wallets-keys-to-safety": "Τα κλειδιά για την ασφάλεια των κρυπτονομισμάτων σας",
  "page-wallets-manage-funds": "Μια εφαρμογή για τη διαχείριση των κεφαλαίων σας",
  "page-wallets-manage-funds-desc": "Ένα πορτοφόλι δείχνει το υπόλοιπο, το ιστορικό των συναλλαγών σας και σας παρέχει έναν τρόπο για να στέλνετε/λαμβάνετε κεφάλαια. Κάποια πορτοφόλια μπορεί να προσφέρουν και περισσότερα.",
  "page-wallets-meta-description": "Τι πρέπει να γνωρίζετε για να χρησιμοποιήσετε τα πορτοφόλια Ethereum.",
  "page-wallets-meta-title": "Πορτοφόλια Ethereum",
  "page-wallets-mobile": "Εφαρμογές για το κινητό σας, για να έχετε πρόσβαση στο κεφάλαιό σας από οπουδήποτε",
  "page-wallets-more-on-dapps-btn": "Περισσότερα για τα Dapps",
  "page-wallets-most-wallets": "Τα περισσότερα προϊόντα πορτοφολιού θα σας επιτρέψουν να δημιουργήσετε έναν λογαριασμό Ethereum. Έτσι, δεν χρειάζεστε ένα πριν κατεβάσετε ένα πορτοφόλι.",
  "page-wallets-protecting-yourself": "Προστατέψτε τον εαυτό σας και το κεφάλαιό σας",
  "page-wallets-seed-phrase": "Σημειώστε τις λέξεις ασφαλείας σας",
  "page-wallets-seed-phrase-desc": "Τα πορτοφόλια σας παρέχουν μια σειρά λέξεων που πρέπει να αποθηκεύσετε κάπου ασφαλή. Είναι ο μόνος τρόπος για να μπορέσετε να ανακτήσετε το πορτοφόλι σας.",
  "page-wallets-seed-phrase-example": "Δείτε ένα παράδειγμα:",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "Μην τις αποθηκεύετε στον υπολογιστή. Γράψτε τες και κρατήστε τις ασφαλείς.",
  "page-wallets-slogan": "Το κλειδί του ψηφιακού σας μέλλοντος",
  "page-wallets-stay-safe": "Πώς να μείνετε ασφαλείς",
  "page-wallets-stay-safe-desc": "Τα πορτοφόλια χρειάζονται λίγο διαφορετικό τρόπο σκέψης. Η οικονομική ελευθερία και η δυνατότητα πρόσβασης και χρήσης των χρημάτων σας από οπουδήποτε, έρχεται με μια μικρή ευθύνη - δεν υπάρχει υποστήριξη πελατών στα κρυπτονομίσματα.",
  "page-wallets-subtitle": "Τα πορτοφόλια σας παρέχουν πρόσβαση στα χρήματά σας και στις εφαρμογές του Ethereum. Μόνο εσείς θα πρέπει να έχετε πρόσβαση στο πορτοφόλι σας.",
  "page-wallets-take-responsibility": "Αναλάβετε την ευθύνη των κεφαλαίων σας",
  "page-wallets-take-responsibility-desc": "Τα κεντρικά ανταλλακτήρια θα συνδέσουν το πορτοφόλι σας με ένα όνομα χρήστη και ένα κωδικό πρόσβασης που μπορείτε να τον ανακτήσετε με τον παραδοσιακό τρόπο. Απλά θυμηθείτε ότι εμπιστεύεστε τα χρήματά σας σε αυτά τα ανταλλακτήρια. Εάν αυτή η εταιρεία δεχθεί επίθεση ή τερματίσει τη λειτουργία της, τα χρήματά σας διατρέχουν κίνδυνο.",
  "page-wallets-tips": "Περισσότερες συμβουλές για να παραμείνετε ασφαλείς",
  "page-wallets-tips-community": "Από την κοινότητα",
  "page-wallets-title": "Πορτοφόλια Ethereum",
  "page-wallets-triple-check": "Κάντε τριπλό έλεγχο σε όλα",
  "page-wallets-triple-check-desc": "Θυμηθείτε ότι οι συναλλαγές δεν μπορούν να επιστραφούν και τα πορτοφόλια δεν μπορούν εύκολα να ανακτηθούν, οπότε προσέχετε πάντοτε.",
  "page-wallets-try-dapps": "Δοκιμάστε μερικές αποκεντρωμένες εφαρμογές",
  "page-wallets-try-dapps-alt": "Μια απεικόνιση μελών της κοινότητας Ethereum που συνεργάζονται",
  "page-wallets-try-dapps-desc": "Οι αποκεντρωμένες εφαρμογές ή Dapps, είναι εφαρμογές που χτίστηκαν στο Ethereum. Είναι φθηνότερες, δικαιότερες και πιο ευγενικές με τα δεδομένα σας από τις περισσότερες παραδοσιακές.",
  "page-wallets-types": "Τύποι πορτοφολιού",
  "page-wallets-web-browser": "Πορτοφόλια ιστού που σας επιτρέπουν να διαχειρίζεστε το λογαριασμό σας μέσω του προγράμματος περιήγησης",
  "page-wallets-whats-a-wallet": "Τι είναι το πορτοφόλι Ethereum;",
  "page-wallets-your-ethereum-account": "Ο λογαριασμός σας Ethereum",
  "page-wallets-your-ethereum-account-desc": "Το πορτοφόλι σας είναι ο τρόπος διαχείρισης του λογαριασμού σας Ethereum – το υπόλοιπό σας, το ιστορικό συναλλαγών σας και πολλά άλλα. Μπορείτε να αλλάξετε παρόχους πορτοφολιού ανά πάσα στιγμή.",
  "page-wallets-your-login": "Σύνδεση στις εφαρμογές Ethereum",
  "page-wallets-your-login-desc": "Το πορτοφόλι, σας επιτρέπει να συνδεθείτε σε οποιαδήποτε αποκεντρωμένη εφαρμογή χρησιμοποιώντας τον λογαριασμό σας Ethereum. Είναι σαν τα στοιχεία σύνδεσης και μπορείτε να τα χρησιμοποιήσετε διάφορες εφαρμογές.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Το Ethereum είναι η τεχνολογία που σας επιτρέπει να στέλνετε κρυπτονομίσματα σε οποιονδήποτε με μια μικρή χρέωση. Σε αυτό επίσης βασίζονται διάφορες εφαρμογές, διαθέσιμες για όλους, που κανείς δεν μπορεί να απενεργοποιήσει.",
  "page-what-is-ethereum-101-desc-2": "Το Ethereum βασίζεται στην καινοτομία του Bitcoin, με μερικές μεγάλες διαφορές.",
  "page-what-is-ethereum-101-desc-3": "Και τα δύο σας επιτρέπουν να χρησιμοποιήσετε ψηφιακά χρήματα χωρίς παρόχους πληρωμών ή τράπεζες. Αλλά το Ethereum είναι προγραμματιζόμενο, ώστε να μπορείτε επίσης να το χρησιμοποιήσετε και για άλλα διαφορετικά ψηφιακά κρυπτονομίσματα – ακόμα και Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "Αυτό σημαίνει επίσης ότι το Ethereum είναι κάτι περισσότερο από ένα σύστημα πληρωμών. Είναι μια αγορά χρηματοπιστωτικών υπηρεσιών, παιχνιδιών και εφαρμογών, όπου δεν μπορούν να κλέψουν τα δεδομένα σας ή να σας λογοκρίνουν.",
  "page-what-is-ethereum-101-italic": "η παγκόσμια προγραμματιζόμενη κύρια αλυσίδα.",
  "page-what-is-ethereum-101-strong": "Είναι ",
  "page-what-is-ethereum-accessibility": "Το Ethereum είναι διαθέσιμο για όλους.",
  "page-what-is-ethereum-adventure": "Επιλέξτε τη δική σας περιπέτεια!",
  "page-what-is-ethereum-alt-img-bazaar": "Εικονογράφηση ατόμου που κοιτά στο παζάρι, που προορίζεται να εκπροσωπήσει το Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Μια απεικόνιση μελών της κοινότητας Ethereum που συνεργάζονται",
  "page-what-is-ethereum-alt-img-lego": "Μια απεικόνιση ενός χεριού που δημιουργεί το λογότυπο του ETH από lego τουβλάκια",
  "page-what-is-ethereum-alt-img-social": "Απεικόνιση χαρακτήρων σε κοινωνικό χώρο αφιερωμένο στο Ethereum με ένα μεγάλο λογότυπο ETH",
  "page-what-is-ethereum-banking-card": "Τραπεζικές υπηρεσίες για όλους",
  "page-what-is-ethereum-banking-card-desc": "Δεν έχουν όλοι πρόσβαση σε χρηματοοικονομικές υπηρεσίες. Το μόνο που χρειάζεστε για να έχετε πρόσβαση στο Ethereum, σε προϊόντα δανεισμού και αποταμίευσης, είναι μια σύνδεση στο Διαδίκτυο.",
  "page-what-is-ethereum-build": "Δημιουργήστε με το Ethereum",
  "page-what-is-ethereum-build-desc": "Αν θέλετε να δοκιμάσετε να χτίζετε με το Ethereum, διαβάστε τα σχετικά έγγραφα, ενημερωθείτε από τα σεμινάρια ή ελέγξτε τα εργαλεία που χρειάζεστε για να ξεκινήσετε.",
  "page-what-is-ethereum-censorless-card": "Ανθεκτικό στη Λογοκρισία",
  "page-what-is-ethereum-censorless-card-desc": "Καμία κυβέρνηση ή επιχείρηση δεν έχει τον έλεγχο επί του Ethereum. Η αποκέντρωση καθιστά σχεδόν αδύνατο για οποιονδήποτε να σας σταματήσει από το να λαμβάνετε πληρωμές ή να χρησιμοποιείτε τις υπηρεσίες του Ethereum.",
  "page-what-is-ethereum-comm-desc": "Η κοινότητά μας περιλαμβάνει ανθρώπους από όλα τα κοινωνικά στρώματα, συμπεριλαμβανομένων καλλιτεχνών, κρυπτο-αναρχικών, τις εταιρείες του fortune 500 και τώρα εσάς. Μάθετε πώς μπορείτε να συμμετέχετε σήμερα.",
  "page-what-is-ethereum-commerce-card": "Εμπορικές εγγυήσεις",
  "page-what-is-ethereum-commerce-card-desc": "Το Ethereum δημιουργεί πιο ίσους όρους ανταγωνισμού. Οι πελάτες απολαμβάνουν την ασφάλεια και την εγγύηση ότι τα κεφάλαιά τους θα αλλάξουν χέρια μόνο όταν υπάρξει συμφωνία μεταξύ τους. Δεν χρειάζεστε την εγγύηση μια μεγάλης εταιρείας για να έρθετε σε συνεργασία.",
  "page-what-is-ethereum-community": "Η κοινότητα του Ethereum",
  "page-what-is-ethereum-compatibility-card": "Συμβατότητα για την επιτυχία",
  "page-what-is-ethereum-compatibility-card-desc": "Καλύτερα προϊόντα και επενδύσεις δημιουργούνται συνεχώς, επειδή τα προϊόντα Ethereum είναι συμβατά από προεπιλογή. Οι εταιρείες μπορούν να επενδύσουν στην επιτυχία του άλλου.",
  "page-what-is-ethereum-dapps-desc": "Προϊόντα και υπηρεσίες που λειτουργούν στο Ethereum. Υπάρχουν αποκεντρωμένες εφαρμογές τα λεγόμενα dapps, για τα οικονομικά, την εργασία, τα μέσα κοινωνικής δικτύωσης, τα παιχνίδια και πολλά άλλα. Γνωρίστε τις εφαρμογές του ψηφιακού μας μέλλοντος.",
  "page-what-is-ethereum-dapps-img-alt": "Απεικόνιση ενός σκύλου που χρησιμοποιεί μια εφαρμογή Ethereum σε υπολογιστή",
  "page-what-is-ethereum-dapps-title": "Ethereum dapps",
  "page-what-is-ethereum-desc": "Το θεμέλιο του ψηφιακού μας μέλλοντος",
  "page-what-is-ethereum-explore": "Εξερευνήστε το Ethereum",
  "page-what-is-ethereum-get-started": "Ο καλύτερος τρόπος για να μάθετε περισσότερα είναι να κατεβάσετε ένα πορτοφόλι, να λάβετε μερικά ETH και να δοκιμάσετε μια εφαρμογή Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Το Ethereum παρέχει ανοικτή πρόσβαση σε ψηφιακά χρήματα και σε φιλικές υπηρεσίες προς τα δεδομένα για όλους – ανεξάρτητα από το ποιος είστε ή την τοποθεσία σας. Είναι μια τεχνολογία χτισμένη για την κοινότητα με βασικό συστατικό το κρυπτονόμισμα ether (ETH) και χιλιάδες άλλες εφαρμογές που μπορείτε να χρησιμοποιήσετε σήμερα.",
  "page-what-is-ethereum-internet-card": "Πιο ιδιωτικό Διαδίκτυο",
  "page-what-is-ethereum-internet-card-desc": "Δεν χρειάζεται να παρέχετε όλα τα προσωπικά σας δεδομένα για να χρησιμοποιήσετε μια εφαρμογή Ethereum. Το Ethereum δημιουργεί την οικονομία βασισμένο στην αξία και όχι την επιτήρηση.",
  "page-what-is-ethereum-meet-comm": "Γνωρίστε την κοινότητα",
  "page-what-is-ethereum-meta-description": "Μάθετε για το Ethereum, τι κάνει και πώς να το δοκιμάσετε.",
  "page-what-is-ethereum-meta-title": "Τι είναι το Ethereum;",
  "page-what-is-ethereum-native-alt": "Το σύμβολο για το ether (ETH)",
  "page-what-is-ethereum-native-crypto": "Το Ethereum είναι ένα εγγενές κρυπτονόμισμα και ισοδύναμο με το Bitcoin. Μπορείτε να χρησιμοποιήσετε το ETH σε εφαρμογές Ethereum ή για την αποστολή νομισμάτων σε φίλους και οικογένεια.",
  "page-what-is-ethereum-native-img-alt": "Απεικόνιση ενός ρομπότ με ένα θησαυροφυλάκιο για κορμό, χρησιμοποιείται για να αναπαριστά τα πορτοφόλια του Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Ένα δίκτυο peer-to-peer",
  "page-what-is-ethereum-p2p-card-desc": "Το Ethereum σας επιτρέπει να μεταφέρετε χρήματα ή να συνάπτετε συμφωνίες, απευθείας με κάποιον άλλο. Δεν χρειάζεται να χρησιμοποιήσετε ενδιάμεσες εταιρείες.",
  "page-what-is-ethereum-singlecard-desc": "Αν ενδιαφέρεστε για τις τεχνικές λεπτομέρειες της κύριας αλυσίδας ή γενικά για το Ethereum, σας έχουμε καλυμμένους.",
  "page-what-is-ethereum-singlecard-link": "Πώς λειτουργεί το Ethereum",
  "page-what-is-ethereum-singlecard-title": "Πώς λειτουργεί το Ethereum",
  "page-what-is-ethereum-start-building-btn": "Ξεκινήστε να δημιουργείτε",
  "page-what-is-ethereum-title": "Τι είναι το Ethereum;",
  "page-what-is-ethereum-tools-needed": "Το μόνο που χρειάζεστε για να συμμετέχετε είναι ένα πορτοφόλι.",
  "page-what-is-ethereum-try": "Δοκιμάστε το Ethereum",
  "page-what-is-ethereum-tryit": "Κάντε ένα βήμα στην αγορά και δοκιμάστε το...",
  "page-what-is-ethereum-wallets": "Πορτοφόλια",
  "page-what-is-ethereum-wallets-desc": "Πώς διαχειρίζεστε τα ETH σας και τον λογαριασμό σας στο Ethereum. Θα χρειαστείτε ένα πορτοφόλι για να ξεκινήσετε – θα σας βοηθήσουμε να επιλέξετε ένα.",
  "page-what-is-ethereum-welcome": "Καλώς ήλθατε στο Ethereum",
  "page-what-is-ethereum-welcome-2": "Ελπίζουμε να παραμείνετε.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Το Staking έφτασε! Αν θέλετε να κάνετε stake τα ETH σας",
  "banner-staking-2": "επιβεβαιώστε τη διεύθυνση κατάθεσης",
  "docking": "Ενσωμάτωση",
  "page-eth2-vision-security-desc-9": "μας επιτρέπει να αντιστοιχίσουμε τυχαία τους επικυρωτές σε διαφορετικά shards - αυτό καθιστά σχεδόν αδύνατο για τους επικυρωτές να συμπράξουν με την επίθεση σε ένα συγκεκριμένο shard. Η αποθήκευση δεν είναι τόσο ασφαλής σε blockchain, επειδή οι εξορυκτές δεν μπορούν να ελεγχθούν από το πρωτόκολλο με αυτόν τον τρόπο.",
  "page-index-sections-developers-desc": "Μάθετε για την τεχνολογία πίσω από το Ethereum και τις εφαρμογές του, ώστε να μπορείτε να ξεκινήσετε να δημιουργείτε με αυτό.",
  "page-index-sections-developers-image-alt": "Απεικόνιση ενός χεριού που κατασκευάζει ένα σύμβολο Ethereum φτιαγμένο από τούβλα lego",
  "page-index-sections-developers-link-text": "Ξεκινήστε να δημιουργείτε",
  "page-index-sections-developers-title": "Προγραμματιστές",
  "page-index-sections-enterprise-desc": "Δείτε πώς το Ethereum μπορεί να ανοίξει νέα επιχειρηματικά μοντέλα, να μειώσει το κόστος σας και να εξασφαλίσει τη μελλοντική εξέλιξη της επιχείρησής σας.",
  "page-index-sections-enterprise-image-alt": "Απεικόνιση μιας ομάδας που εργάζεται σε ένα πρόγραμμα Ethereum γύρω από έναν φορητό υπολογιστή",
  "page-index-sections-enterprise-link-text": "Ethereum για Επιχείρηση",
  "page-index-sections-enterprise-title": "Επιχείρηση",
  "page-index-sections-individuals-desc": "Γνωρίστε τα Ethereum, Ether, πορτοφόλια, token και πολλά άλλα, ώστε να μπορείτε να αρχίσετε να χρησιμοποιείτε τις εφαρμογές του Ethereum.",
  "page-index-sections-individuals-image-alt": "Απεικόνιση ενός σκύλου doge που κάθεται σε έναν υπολογιστή",
  "page-index-sections-individuals-link-text": "Ξεκινήστε με το Ethereum",
  "page-index-sections-individuals-title": "Σχετικά με το Ethereum",
  "page-index-subtitle": "Στο Ethereum, μπορείτε να γράψετε κώδικα ο οποίος ελέγχει ψηφιακές αξίες, τρέχει ακριβώς όπως έχει προγραμματιστεί και είναι προσβάσιμος οπουδήποτε στον κόσμο.",
  "page-find-wallet-authereum-logo-alt": "Λογότυπο Authereum",
  "page-find-wallet-description-authereum": "Χωρίς λήψη, χωρίς διαμοιρασμό. Από οποιοδήποτε πρόγραμμα περιήγησης, οποιαδήποτε στιγμή, από το κινητό ή τον υπολογιστή σας",
  "page-wallets-accounts-addresses-desc-2": "είναι μια οικονομική οντότητα που μπορεί να κάνει συναλλαγές και έχει ένα υπόλοιπο.",
  "page-wallets-accounts-has": "Ένας λογαριασμός Ethereum έχει μια",
  "page-wallets-an": "Ένα",
  "page-wallets-ethereum-addresses": "Διεύθυνση Ethereum",
  "page-wallets-ethereum-addresses-2": ", όπως και τα εισερχόμενα σας έχουν μια διεύθυνση email. Μπορείτε να τη χρησιμοποιήσετε για να στείλετε χρήματα σε έναν λογαριασμό.",
  "page-wallets-ethereum-wallet-2": "σας επιτρέπει τη διαχείριση του λογαριασμού σας Ethereum, όπως να δείτε το υπόλοιπο του λογαριασμού σας, να κάνετε συναλλαγές και πολλά άλλα."
}
