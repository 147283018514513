{
  "1inch-logo": "1inch logo",
  "aave-logo": "Aave logo",
  "about": "O aplikaciji",
  "about-ethereum-org": "O stranici ethereum.org",
  "about-us": "O nama",
  "alt-eth-blocks": "Ilustracija blokova posloženih kao simbol ETH-a",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Povratak na vrh",
  "banner-page-incomplete": "Ova je stranica nepotpuna. Ako ste stručnjak za tu temu, uredite ovu stranicu i obasjajte je svojom mudrošću.",
  "beacon-chain": "Beacon Chain",
  "binance-logo": "Binance logo",
  "bittrex-logo": "Bittrex logo",
  "brand-assets": "Imovina robne marke",
  "bug-bounty": "Nagrada za pogrešku",
  "coinbase-logo": "Coinbase blog",
  "coinmama-logo": "Coinmama logo",
  "community": "Zajednica",
  "community-menu": "Community Menu",
  "compound-logo": "Compound logo",
  "cons": "Nedostatci",
  "contact": "Kontakt",
  "content-versions": "Content Versions",
  "contributing": "Doprinos",
  "contributors": "Suradnici",
  "contributors-thanks": "Svima koji su dali svoj doprinos ovoj stranici – hvala!",
  "cookie-policy": "Pravila o kolačićima",
  "copied": "Kopirano",
  "copy": "Kopiraj",
  "dark-mode": "Tamni način",
  "data-provided-by": "Podatke pruža",
  "decentralized-applications-dapps": "Decentralizirane aplikacije (dapp-ovi)",
  "deposit-contract": "Ugovor o depozitu",
  "devcon": "Devcon",
  "developers": "Programeri",
  "developers-home": "Početna stranica za programere",
  "docs": "Dokumenti",
  "documentation": "Dokumentacija",
  "dydx-logo": "Dydx logo",
  "ecosystem": "Ekosustav",
  "edit-page": "Uredi stranicu",
  "ef-blog": "Blog zaklade Ethereum",
  "eips": "Prijedlozi za unapređenje Ethereuma",
  "enterprise": "Poduzeće",
  "enterprise-menu": "Izbornik poduzeća",
  "esp": "Program potpore ekosustavu",
  "eth-current-price": "Trenutačna cijena ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Istraživač otvorenog koda za Eth2 Beacon Chain",
  "eth2-beaconscan-desc": "Istraživač za Eth2 Beacon Chain – Etherscan za Eth2",
  "eth2-become-staker": "Postanite ulagač",
  "eth2-become-staker-desc": "Ulaganje radi! Ako želite uložiti svoj ETH za unapređenje sigurnosti mreže, budite svjesni rizika.",
  "eth2-explore": "Istražite podatke",
  "eth2-no-action-needed": "Ne morate ništa raditi s bilo kojim ETH-om koji već imate. Čuvajte se prevaranata koji traže da pošaljete ETH na zamjenu.",
  "eth2-run-beacon-chain": "Pokretanje Beaconovog klijenta",
  "eth2-run-beacon-chain-desc": "Ethereumu treba što više klijenata u pogonu. Pomozite za javno dobro Ethereuma!",
  "eth2-service-announcement": "Najava usluge Eth2",
  "eth2-what-shipping": "Kada kreće?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Imovina marke Ethereum",
  "ethereum-community": "Ethereumova zajednica",
  "ethereum-foundation": "Zaklada Ethereum",
  "ethereum-foundation-logo": "Ethereum Foundation logo",
  "ethereum-glossary": "Ethereumov pojmovnik",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Ethereum logo",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Studio Ethereum",
  "ethereum-wallets": "Novčanici Ethereum",
  "ethereum-whitepaper": "Ethereumova tehnička dokumentacija",
  "example-projects": "Primjeri projekata",
  "find-wallet": "Pronađi novčanik",
  "foundation": "Zaklada",
  "gemini-logo": "Gemini logo",
  "get-eth": "Nabavite ETH",
  "get-involved": "Uključite se",
  "get-started": "Prvi koraci",
  "gitcoin-logo": "Gitcoin logo",
  "glossary": "Pojmovnik",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Vodiči i resursi",
  "history": "Povijest",
  "history-of-ethereum": "Povijest Ethereuma",
  "home": "Dom",
  "how-ethereum-works": "Kako Ethereum radi",
  "image": "slika",
  "in-this-section": "U ovom odjeljku",
  "individuals": "Individualno",
  "individuals-menu": "Izbornik za pojedince",
  "jobs": "Jobs",
  "kraken-logo": "Kraken logo",
  "language-ar": "arapski",
  "language-bg": "Bulgarian",
  "language-bn": "bengalski",
  "language-ca": "Catalan",
  "language-cs": "češki",
  "language-de": "njemački",
  "language-el": "grčki",
  "language-en": "engleski",
  "language-es": "španjolski",
  "language-fa": "farsi",
  "language-fi": "finski",
  "language-fr": "francuski",
  "language-hu": "mađarski",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "indonezijski",
  "language-ig": "igbo",
  "language-it": "talijanski",
  "language-ja": "japanski",
  "language-ko": "korejski",
  "language-lt": "litavski",
  "language-ml": "malajalamski",
  "language-nb": "norveški",
  "language-nl": "nizozemski",
  "language-pl": "poljski",
  "language-pt": "portugalski",
  "language-pt-br": "portugalski (brazilski)",
  "language-ro": "rumunjski",
  "language-ru": "ruski",
  "language-se": "švedski",
  "language-sk": "slovački",
  "language-sl": "slovenski",
  "language-support": "Jezična podrška",
  "language-tr": "turski",
  "language-uk": "ukrajinski",
  "language-vi": "vijetnamski",
  "language-zh": "kineski pojednostavljen",
  "language-zh-tw": "kineski tradicionalan",
  "languages": "Jezici",
  "last-24-hrs": "Posljednja 24 sata",
  "last-edit": "Posljednje uređivanje",
  "learn": "Učenje",
  "learn-by-coding": "Učite kodirajući",
  "learn-menu": "Izbornik za učenje",
  "learn-more": "Saznajte više",
  "less": "Manje",
  "light-mode": "Svijetli način",
  "listing-policy-disclaimer": "Proizvodi navedeni na ovoj stranici preporučuju se samo u informativne svrhe. Ako želite dodati proizvod ili pružiti povratne informacije o pravilima, obratite se GitHubu.",
  "listing-policy-raise-issue-link": "Pokrenite zahtjev za rješavanje problema",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Učitavanje...",
  "loading-error": "Pogreška pri učitavanju.",
  "loading-error-refresh": "Pogreška pri učitavanju podataka. Pokušajte osvježiti stranicu.",
  "logo": "logotip",
  "loopring-logo": "Loopring logo",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Glavna mreža Ethereum",
  "makerdao-logo": "MakerDao logo",
  "matcha-logo": "Matcha logo",
  "merge": "Merge",
  "more": "Više",
  "nav-beginners": "Početnici",
  "next": "Sljedeće",
  "oasis-logo": "Oasis logo",
  "on-this-page": "Na ovoj stranici",
  "page-content": "Sadržaj stranice",
  "page-enterprise": "Poduzeće",
  "page-last-updated": "Stranica je posljednji put ažurirana",
  "previous": "Prethodno",
  "privacy-policy": "Pravila privatnosti",
  "private-ethereum": "Privatni Ethereum",
  "pros": "Prednosti",
  "read-more": "Pročitaj više",
  "refresh": "Osvježite stranicu.",
  "review-progress": "Napredovanje provjere",
  "search": "Pretraži",
  "search-box-blank-state-text": "Pretražuj do mile volje!",
  "search-eth-address": "Ovo izgleda kao Ethereumova adresa. Ne pružamo podatke specifične za adrese. Pokušajte ga potražiti u istraživaču blokova",
  "search-no-results": "Nije pronađen rezultat za Vašu pretragu",
  "security": "Security",
  "see-contributors": "Pogledajte suradnike",
  "set-up-local-env": "Postavi lokalna mjesta",
  "shard-chains": "Lanci djelića",
  "show-all": "Prikaži sve",
  "show-less": "Prikaži manje",
  "site-description": "Ethereum je globalna, decentralizirana platforma za novac i nove vrste aplikacija. Na Ethereumu možete napisati kod koji kontrolira novac i izgraditi a.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoins",
  "staking": "Ulaganje",
  "summary": "Sažetak",
  "terms-of-use": "Uvjeti korištenja",
  "transaction-fees": "Što su naknade za transakcije?",
  "translation-banner-body-new": "Ovu stranicu pregledavate na engleskom jer je još nismo preveli. Pomozite nam prevesti ovaj sadržaj.",
  "translation-banner-body-update": "Postoji nova verzija ove stranice, ali trenutno samo na engleskom. Pomozite nam prevesti najnoviju verziju.",
  "translation-banner-button-join-translation-program": "Pridružite se projektu prevođenja",
  "translation-banner-button-learn-more": "Saznajte više",
  "translation-banner-button-see-english": "Pogledajte engleski",
  "translation-banner-button-translate-page": "Prevedi stranicu",
  "translation-banner-title-new": "Pomozite prevesti ovu stranicu",
  "translation-banner-title-update": "Pomozite ažurirati ovu stranicu",
  "translation-program": "Translation Program",
  "translation-progress": "Napredovanje prevođenja",
  "tutorials": "Praktični vodič",
  "uniswap-logo": "Uniswap logo",
  "use": "Upotrijebi",
  "use-ethereum": "Upotrebljavaj Ethereum",
  "use-ethereum-menu": "Upotrijebi Ethereumov izbornik",
  "vision": "Vizija",
  "wallets": "Novčanici",
  "website-last-updated": "Stranica je posljednji put ažurirana",
  "what-is-ether": "Što je ether (ETH)?",
  "what-is-ethereum": "Što je Ethereum?",
  "whitepaper": "Tehnička dokumentacija",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Zatraži značajku",
  "page-about-h3": "Rad u tijeku",
  "page-about-h3-1": "Implementirane značajke",
  "page-about-h3-2": "Planirane značajke",
  "page-about-li-1": "u tijeku",
  "page-about-li-2": "planirano",
  "page-about-li-3": "implementirano",
  "page-about-li-4": "implementirano",
  "page-about-link-1": "Izvorni kod ovog repozitorija licenciran je pod licencom MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Pogledajte cjelovit popis zadataka koji su u tijeku na Githubu",
  "page-about-link-4": "Pridruži se našem poslužitelju Discord",
  "page-about-link-5": "Obratite nam se na Twitteru",
  "page-about-link-6": "Pogledajte cjelovit popis implementiranih zadataka na Githubu",
  "page-about-link-7": "Prijavite problem na Githubu",
  "page-about-p-1": "Od pokretanja stranice ethereum.org nastojimo biti transparentni u pogledu svog poslovanja. To je jedna od naših temeljnih vrijednosti jer vjerujemo da je transparentnost presudna za uspjeh Ethereuma.",
  "page-about-p-2": "Upotrebljavamo",
  "page-about-p-3": "kao primarni alat za upravljanje projektima. Zadatke organiziramo u 3 kategorije:",
  "page-about-p-4": "  Dajemo sve od sebe da zajednicu informiramo o statusu određenog zadatka.",
  "page-about-p-5": "Zadaci koje implementiramo.",
  "page-about-p-6": "Zadaci koje smo stavili u red za implementiranje.",
  "page-about-p-7": "Nedavno dovršeni zadaci.",
  "page-about-p-8": "Imate li ideju kako poboljšati ethereum.org? Voljeli bismo surađivati s vama!",
  "page-assets-bazaar": "Ethereumov bazar",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Građevni blokovi",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Doge upotrebljava dapps-ove",
  "page-assets-download-artist": "Umjetnik:",
  "page-assets-download-download": "Preuzmi",
  "page-assets-enterprise": "Poduzeće Ethereum",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "ETH dijamant (boja)",
  "page-assets-eth-diamond-glyph": "ETH dijamant (glif)",
  "page-assets-eth-diamond-gray": "ETH dijamant (sivi)",
  "page-assets-eth-diamond-purple": "ETH dijamant (ljubičast)",
  "page-assets-eth-diamond-white": "ETH dijamant (bijeli)",
  "page-assets-eth-glyph-video-dark": "ETH dijamant (taman)",
  "page-assets-eth-glyph-video-light": "ETH dijamant (svijetao)",
  "page-assets-eth-logo-landscape-gray": "ETH logotip položen (siv)",
  "page-assets-eth-logo-landscape-purple": "ETH logotip položen (ljubičast)",
  "page-assets-eth-logo-landscape-white": "ETH logotip položen (bijel)",
  "page-assets-eth-logo-portrait-gray": "ETH logotip portret (siv)",
  "page-assets-eth-logo-portrait-purple": "ETH logo portret (ljubičast)",
  "page-assets-eth-logo-portrait-white": "ETH logo portet (bijel)",
  "page-assets-eth-wordmark-gray": "ETH vodeni žig (siv)",
  "page-assets-eth-wordmark-purple": "ETH vodeni žig (ljubičast)",
  "page-assets-eth-wordmark-white": "ETH vodeni žig (bijel)",
  "page-assets-ethereum-brand-assets": "Imovina marke Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "imovina marke ethereum.org",
  "page-assets-hero": "ethereum.org heroj",
  "page-assets-hero-particles": "Slika ETH čestica",
  "page-assets-historical-artwork": "Povijesni dizajni",
  "page-assets-illustrations": "Ilustracije",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Istražite i preuzmite ilustracije, imovinu i medije marki Ethereum i ethereum.org.",
  "page-assets-meta-title": "Imovina marke Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Jednobojna pozadina",
  "page-assets-page-assets-transparent-background": "Transparentna pozadina",
  "page-assets-robot": "Novčanik robot",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1inch logo",
  "page-dapps-aave-logo-alt": "Aave logo",
  "page-dapps-add-button": "Predloži dapp",
  "page-dapps-add-title": "Dodaj dapp",
  "page-dapps-audius-logo-alt": "Audius logo",
  "page-dapps-augur-logo-alt": "Augur logo",
  "page-dapps-axie-infinity-logo-alt": "Axie Infinity logo",
  "page-dapps-brave-logo-alt": "Brave logo",
  "page-dapps-category-arts": "Umjetnost i moda",
  "page-dapps-category-browsers": "Preglednici",
  "page-dapps-category-collectibles": "Digitalni kolekcionarski proizvodi",
  "page-dapps-category-competitive": "Natjecanje",
  "page-dapps-category-computing": "Razvojni alati",
  "page-dapps-category-dex": "Zamjene žetona",
  "page-dapps-category-investments": "Investicije",
  "page-dapps-category-lending": "Iznajmljivanje i posuđivanje",
  "page-dapps-category-lottery": "Grupno financiranje",
  "page-dapps-category-marketplaces": "Tržišta",
  "page-dapps-category-music": "Glazba",
  "page-dapps-category-payments": "Plaćanja",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Tržišta trgovanja i pradviđenja",
  "page-dapps-category-utilities": "Sredstva",
  "page-dapps-category-worlds": "Virtualni svjetovi",
  "page-dapps-choose-category": "Odaberi kategoriju",
  "page-dapps-collectibles-benefits-1-description": "Kada se umjetnost tokenizira na Ethereumu, vlasništvo se može dokazati da svi vide. Možete pratiti putovanje umjetničkog djela od stvaranja do trenutnog vlasnika. To sprječava krivotvorenje.",
  "page-dapps-collectibles-benefits-1-title": "Vlasništvo je dokazivo",
  "page-dapps-collectibles-benefits-2-description": "Plaćanje prijenosa glazbe ili kupnje umjetničkih djela daleko je poštenije prema umjetnicima. S Ethereumom manja je potreba za posrednicima. A ako su i potrebni, njihovi troškovi nisu toliko visoki jer platforme ne plaćaju infrastrukturu mreže.",
  "page-dapps-collectibles-benefits-2-title": "Pravednije za stvaraoce",
  "page-dapps-collectibles-benefits-3-description": "Tokenizirani kolekcionarski proizvodi vezani su uz vašu adresu na Ethereumu, a ne za platformu. Tako možete prodavati stvari poput stavki za igru na bilo kojem Ethereumovom tržištu, ne samo u samoj igri.",
  "page-dapps-collectibles-benefits-3-title": "Kolekcionarski proizvodi idu s vama",
  "page-dapps-collectibles-benefits-4-description": "Alati i proizvodi već postoje kako biste tokenizirali svoju umjetnost i prodali je! A vaši se tokeni mogu prodati na bilo kojoj kolekcionarskoj platformi Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Postojeća infrastruktura",
  "page-dapps-collectibles-benefits-description": "To su aplikacije koje su usredotočene na digitalno vlasništvo, povećavajući potencijal za zaradu stvaratelja i izmišljajući nove načine ulaganja u svoje omiljene stvaraoce i njihov rad.",
  "page-dapps-collectibles-benefits-title": "decentralizirano kolekcionarstvo i streaming",
  "page-dapps-collectibles-button": "Umjetnost i kolekcionarstvo",
  "page-dapps-collectibles-description": "To su aplikacije koje su usredotočene na digitalno vlasništvo, povećavajući potencijal za zaradu stvaratelja i smišljaju nove načine ulaganja u vaše omiljene stvaraoce i njihov rad.",
  "page-dapps-collectibles-title": "Decentralizirana umjetnost i kolekcionarstvo",
  "page-dapps-compound-logo-alt": "Compound logo",
  "page-dapps-cryptopunks-logo-alt": "CryptoPunks logo",
  "page-dapps-cryptovoxels-logo-alt": "Cryptovoxels logo",
  "page-dapps-dapp-description-1inch": "Uspoređivanje cijena pomaže vam u izbjegavanju visokih cijena.",
  "page-dapps-dapp-description-aave": "Posudite svoje tokene kako biste zaradili kamatu i povukli ih u bilo kojem trenutku.",
  "page-dapps-dapp-description-async-art": "Stvorite, sakupljajte i prodajte #ProgrammableArt – digitalne slike podijeljene u \"slojeve\" pomoću kojih možete utjecati na izgled slike. Svaki master i sloj je token ERC721.",
  "page-dapps-dapp-description-audius": "Decentralizirana platforma za streaming. Slušanje = novac za autore, a ne za izdavače.",
  "page-dapps-dapp-description-augur": "Kladite se na ishode sporta, ekonomije i drugih svjetskih događaja.",
  "page-dapps-dapp-description-axie-infinity": "Trgujte i borite se sa stvorenjima zvanim Axies. I zarađujte dok igrate – dostupno na mobitelu",
  "page-dapps-dapp-description-brave": "Zaradite tokene za pregledavanje i podržite njima svoje omiljene stvaraoce.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Posudite svoje tokene kako biste zaradili kamatu i povukli ih u bilo kojem trenutku.",
  "page-dapps-dapp-description-cryptopunks": "Kupujte, licitirajte i nudite punkove na prodaju – jedan od prvih kolekcionarskih tokena na Ethereumu.",
  "page-dapps-dapp-description-cryptovoxels": "Stvarajte umjetničke galerije, gradite trgovine i kupujte zemlju – virtualni svijet Ethereuma.",
  "page-dapps-dapp-description-dark-forest": "Osvajajte planete u beskonačnom, proceduralno generiranom, kriptografski specificiranom svemiru.",
  "page-dapps-dapp-description-decentraland": "Skupljajte, trgujte virtualnom zemljom u virtualnom svijetu koji možete istraživati.",
  "page-dapps-dapp-description-dydx": "Otvorite kratke ili polužne položaje do 10x. Moguće je i posuđivanje i iznajmljivanje.",
  "page-dapps-dapp-description-ens": "Nazivi za Ethereumove adrese i decentralizirane web stranice jednostavni za korištenje.",
  "page-dapps-dapp-description-foundation": "Uložite u jedinstvena izdanja digitalnih umjetničkih djela i trgujte s drugim kupcima.",
  "page-dapps-dapp-description-gitcoin": "Zaradite kripto radeći na softveru otvorenog koda.",
  "page-dapps-dapp-description-gitcoin-grants": "Skupno financiranje za projekte zajednice Ethereum s pojačanim doprinosima",
  "page-dapps-dapp-description-gods-unchained": "Strateška igra s kartama za razmjenu. Igrajući zaradite karte koje možete prodati u stvarnom životu.",
  "page-dapps-dapp-description-golem": "Pristupite zajedničkoj računarskoj moći ili iznajmite vlastite resurse.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Ravnopravna platforma za trgovanje, izgrađena za brzinu.",
  "page-dapps-dapp-description-marble-cards": "Stvorite jedinstvene digitalne karte i trgujte njima na temelju URL adresa.",
  "page-dapps-dapp-description-matcha": "Pretražuje više burzi kako bi vam pomogao pronaći najbolje cijene.",
  "page-dapps-dapp-description-nifty-gateway": "Kupite radove u lancu od vrhunskih umjetnika, sportaša, brendova i stvaralaca.",
  "page-dapps-dapp-description-oasis": "Trgujte, posuđujte i štedite pomoću Dai-a, Ethereumovog stabilnog novčića.",
  "page-dapps-dapp-description-opensea": "Kupujte, prodajte, otkrivajte predmete ograničene naklade i trgujte njima.",
  "page-dapps-dapp-description-opera": "Pošaljite kripto iz svog preglednika trgovcima, drugim korisnicima i aplikacijama.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Kladite se na ishode. Trgujte na informacijskim tržištima.",
  "page-dapps-dapp-description-pooltogether": "Lutrija na kojoj ne možete izgubiti. Nagrade svaki tjedan.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Stvorite, prodajte i kupite tokenizirane kolekcionarstke proizvode.",
  "page-dapps-dapp-description-sablier": "Prosljeđujte novac u stvarnom vremenu.",
  "page-dapps-dapp-description-superrare": "Digitalna umjetnička djela kupujte izravno od umjetnika ili na sekundarnim tržištima.",
  "page-dapps-dapp-description-token-sets": "Kripto strategije ulaganja koje se automatski uravnotežuju.",
  "page-dapps-dapp-description-tornado-cash": "Šaljite anonimne transakcije na Ethereum.",
  "page-dapps-dapp-description-uniswap": "Zamijenite tokene jednostavno ili osigurajte tokene za % nagrada.",
  "page-dapps-dark-forest-logo-alt": "Dark Forest logo",
  "page-dapps-decentraland-logo-alt": "Decentraland logo",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Pronađite aplikaciju Ethereum za isprobavanje.",
  "page-dapps-doge-img-alt": "Ilustracija psa Doge-a koji upotrebljava računalo",
  "page-dapps-dydx-logo-alt": "dYdX logo",
  "page-dapps-editors-choice-dark-forest": "Igrajte protiv drugih da biste osvojili planete i isprobajte najbolju tehnologiju skaliranja/privatnosti Ethereuma. Možda onih koji su već upoznati s Ethereumom.",
  "page-dapps-editors-choice-description": "Nekoliko dapp-ova koje tim ethereum.org trenutno voli. Istražite još dapp-ova u nastavku.",
  "page-dapps-editors-choice-foundation": "Ulažite u kulturu. Kupujte, trgujte i prodajte jedinstvena digitalna umjetnička djela i modu od nevjerojatnih umjetnika, glazbenika i brendova.",
  "page-dapps-editors-choice-header": "Urednikov izbor",
  "page-dapps-editors-choice-pooltogether": "Kupite srećku za lutriju bez gubitaka. Svaki tjedan kamata generirana iz cijelog fonda karata šalje se jednom sretnom dobitniku. Preuzmite svoj novac kad god želite.",
  "page-dapps-editors-choice-uniswap": "Zamijenite svoje tokene s lakoćom. Omiljena opcija zajednice koja vam omogućuje da trgujete tokenima s ljudima preko mreže.",
  "page-dapps-ens-logo-alt": "Ethereum Name Service logo",
  "page-dapps-explore-dapps-description": "Mnogo je dapps-ova još uvijek eksperimentalno, testiranje mogućnosti decentraliziranih mreža. No, bilo je nekoliko uspješnih ranih pokretača u kategorijama tehnologije, financija, igara na sreću i kolekcionarstva.",
  "page-dapps-explore-dapps-title": "Istražite dapps-ove",
  "page-dapps-features-1-description": "Jednom plasiran u Ethereum, kod dapp ne može se ukloniti. I svatko može se može koristiti dapp-ovim značajkama. Čak i ako se tim iza dapp-a raspusti, i dalje se možete njime koristiti. Kad je jednom na Ethereumu, tamo i ostaje.",
  "page-dapps-features-1-title": "Nema vlasnika",
  "page-dapps-features-2-description": "Ne može vam biti blokirano korištenje dapp-a ili slanje transakcija. Primjerice, da je Twitter Ethereumu, nitko ne bi mogao blokirati vaš račun ili vas spriječiti u tweetovima.",
  "page-dapps-features-2-title": "Bez cenzure",
  "page-dapps-features-3-description": "Budući da Ethereum ima ETH, plaćanja su izvorna za Ethereum. Programeri ne trebaju trošiti vrijeme na integraciju s nezavisnim pružateljima usluga plaćanja.",
  "page-dapps-features-3-title": "Ugrađena plaćanja",
  "page-dapps-features-4-description": "Dapp kôd često je otvoren i kompatibilan je prema zadanim postavkama. Timovi redovito grade koristeći rad drugih timova. Ako želite dopustiti korisnicima da zamijene tokene u vašem dapp-u, možete jednostavno priključiti drugi dapp-ov kôd.",
  "page-dapps-features-4-title": "Uključi se i igraj",
  "page-dapps-features-5-description": "S većinom dapps-ova ne trebate dijeliti svoj stvarni identitet. Vaš je Ethereum račun vaša prijava i trebate samo novčanik.",
  "page-dapps-features-5-title": "Jedna anonimna prijava",
  "page-dapps-features-6-description": "Kriptografija osigurava da napadači ne mogu krivotvoriti transakcije i druge dapp interakcije u vaše ime. Ovlašćujete dapp radnje sa svojim Ethereum računom, obično putem novčanika, zato čuvajte svoje vjerodajnice.",
  "page-dapps-features-6-title": "Potkrijepljeno kriptografijom",
  "page-dapps-features-7-description": "Jednom kada se dapp aktivira na Ethereumu, on će se pasti samo ako Ethereum sam padne. Mrežu veličine Ethereuma notorno je teško srušiti.",
  "page-dapps-features-7-title": "Nema vremena nedjelovanja",
  "page-dapps-finance-benefits-1-description": "Zinancijske usluge koje rade na Ethereumu nije obavezna registracija. Ako imate sredstva i internetsku vezu, možete krenuti.",
  "page-dapps-finance-benefits-1-title": "Otvoreni pristup",
  "page-dapps-finance-benefits-2-description": "Postoji čitav svijet tokena s kojima možete komunicirati putem tih financijskih proizvoda. Ljudi cijelo vrijeme grade nove tokene povrh Ethereuma.",
  "page-dapps-finance-benefits-2-title": "Nova ekonomija tokena",
  "page-dapps-finance-benefits-3-description": "Timovi su izgradili stabilne novčiće kovanice – manje nestabilnu kripto valutu. Oni vam omogućuju eksperimentiranje i upotrebu kriptografije bez rizika i nesigurnosti.",
  "page-dapps-finance-benefits-3-title": "Stabilni novčići",
  "page-dapps-finance-benefits-4-description": "Financijski proizvodi u prostoru Ethereuma svi su modularni i međusobno kompatibilni. Nove konfiguracije tih modula cijelo vrijeme izlaze na tržište, povećavajući mogućnosti upotrebe kripta.",
  "page-dapps-finance-benefits-4-title": "Međusobno povezane financijske usluge",
  "page-dapps-finance-benefits-description": "Što je to kod Ethereuma što omogućuje napredovanje decentraliziranih financijskih aplikacija?",
  "page-dapps-finance-benefits-title": "decentralizirane financije",
  "page-dapps-finance-button": "Financije",
  "page-dapps-finance-description": "To su aplikacije koje su usredotočene na izgradnju financijskih usluga pomoću kriptovaluta. One nude zajmove, posuđivanje, zaradu kamata i privatna plaćanja – nisu potrebni osobni podaci.",
  "page-dapps-finance-title": "Decentralizirane financije",
  "page-dapps-foundation-logo-alt": "Logo zaklade",
  "page-dapps-gaming-benefits-1-description": "Bilo da se radi o virtualnom zemljištu ili karticama za trgovanje, vašim se stavkama mogže trgovati na tržištima kolekcionarskih proizvoda. Vaši stavke iz igre imaju stvarnu vrijednost.",
  "page-dapps-gaming-benefits-1-title": "Predmeti u igri se udvostručuju kao tokeni",
  "page-dapps-gaming-benefits-2-description": "Vi ste vlasnik svojih stavki, a u nekim slučajevima i svog napretka, a ne tvrtke za igre. Dakle, nećete izgubiti ništa ako je tvrtka koja stoji iza igre napadnuta, pati od kvara na poslužitelju ili se rasformira.",
  "page-dapps-gaming-benefits-2-title": "Vaša spremanja su sigurna",
  "page-dapps-gaming-benefits-3-description": "Na isti način na koji su Ethereumova plaćanja dostupna svima za provjeru, igrese mogu koristiti tom kvalitetu kako bi osigurale pravičnost. U teoriji je sve provjerljivo, od broja kritičnih pogodaka do veličine ratničke škrinje protivnika.",
  "page-dapps-gaming-benefits-3-title": "Dokaziva pravičnost",
  "page-dapps-gaming-benefits-description": "Što je to što Ethereumu omogućuje da decentralizirano igranje napreduje?",
  "page-dapps-gaming-benefits-title": "decentralizirano igranje",
  "page-dapps-gaming-button": "Igranje Igara",
  "page-dapps-gaming-description": "To su aplikacije koje su usredotočene na stvaranje virtualnih svjetova i borbu s drugim igračima pomoću kolekcionarskih proizvoda koji imaju stvarnu vrijednost.",
  "page-dapps-gaming-title": "Decentralizirano igranje",
  "page-dapps-get-some-eth-description": "Za radnje u vezi s dapp-om naplaćuje se naknada za transakciju",
  "page-dapps-get-started-subtitle": "Da biste isprobali dapp, trebat će vam novčanik i malo ETH-ova. Novčanik će vam omogućiti povezivanje ili prijavu. A ETH će vam trebati za plaćanje bilo kakvih naknada za transakcije.",
  "page-dapps-get-started-title": "Prvi koraci",
  "page-dapps-gitcoin-grants-logo-alt": "Gitcoin Grants logo",
  "page-dapps-gitcoin-logo-alt": "Gitcoin logo",
  "page-dapps-gods-unchained-logo-alt": "Gods Unchained logo",
  "page-dapps-golem-logo-alt": "Golem logo",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Alati i usluge koje pokreće Ethereum",
  "page-dapps-hero-subtitle": "Dapps je grupa aplikacija u rastu koje upotrebljavaju Ethereum da poremete postojeće poslovne modele ili izmisle nove.",
  "page-dapps-how-dapps-work-p1": "Dapps ima svoj pozadinski kod (pametni ugovori) koji se izvodi na decentraliziranoj mreži, a ne na centraliziranom poslužitelju. Za pohranu podataka koriste se Ethereumovim lnacem blokova, a za logiku aplikacija pametnim ugovorima.",
  "page-dapps-how-dapps-work-p2": "Pametni ugovor je poput skupa pravila koja žive na lancu kako bi svi mogli vidjeti i raditi točno u skladu s tim pravilima. Zamislite automat: ako ga opskrbite s dovoljno sredstava i pravilnim odabirom, dobit ćete željeni predmet. I poput automata, pametni ugovori mogu držati sredstva slična vašem Ethereum računu. To omogućuje kodu da posreduje u sporazumima i transakcijama.",
  "page-dapps-how-dapps-work-p3": "Jednom kada su dapps-ovi postavljeni na Ethereumovoj mreži, ne mogu se mijenjati. Dapps se mogu decentralizirati jer se njima kontrolira logika zapisana u ugovoru, a ne pojedinac ili tvrtka.",
  "page-dapps-how-dapps-work-title": "Kako rade dapps-ovi",
  "page-dapps-learn-callout-button": "Počnite graditi",
  "page-dapps-learn-callout-description": "Naš portal za zajednicu programera ima dokumente, alate i okvire koji će vam pomoći da započnete graditi dapp.",
  "page-dapps-learn-callout-image-alt": "Ilustracija ruke koja gradi ETH simbol od lego kockica.",
  "page-dapps-learn-callout-title": "Naučite graditi dapp",
  "page-dapps-loopring-logo-alt": "Loopring logo",
  "page-dapps-magic-behind-dapps-description": "Dapps mogu izgledati kao uobičajene aplikacije. Ali iza kulisa imaju neke posebne kvalitete jer nasljeđuju sve velesile Ethereuma. Evo po čemu se dapps razlikuju od aplikacija.",
  "page-dapps-magic-behind-dapps-link": "Što Ethereum čini izvrsnim?",
  "page-dapps-magic-behind-dapps-title": "Čarolija iza dapps-ova",
  "page-dapps-magic-title-1": "Čarolija",
  "page-dapps-magic-title-2": "iza",
  "page-dapps-magician-img-alt": "Ilustracija čarobnjaka",
  "page-dapps-marble-cards-logo-alt": "marble.cards logo",
  "page-dapps-matcha-logo-alt": "Matcha logo",
  "page-dapps-mobile-options-header": "Pregledajte drugu kategoriju",
  "page-dapps-nifty-gateway-logo-alt": "Nifty Gateway logo",
  "page-dapps-oasis-logo-alt": "Oasis logo",
  "page-dapps-opensea-logo-alt": "OpenSea logo",
  "page-dapps-opera-logo-alt": "Opera logo",
  "page-dapps-polymarket-logo-alt": "Polymarket logo",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "PoolTogether logo",
  "page-dapps-rarible-logo-alt": "Rarible logo",
  "page-dapps-ready-button": "Kreni",
  "page-dapps-ready-description": "Odaberite dapp za isprobavanje",
  "page-dapps-ready-title": "Spremni?",
  "page-dapps-sablier-logo-alt": "Sablier logo",
  "page-dapps-set-up-a-wallet-button": "Pronađi novčanik",
  "page-dapps-set-up-a-wallet-description": "Novčanik je vaša „prijava” za dapp",
  "page-dapps-set-up-a-wallet-title": "Postavite svoj novčanik",
  "page-dapps-superrare-logo-alt": "SuperRare logo",
  "page-dapps-technology-button": "Tehnologija",
  "page-dapps-technology-description": "To su aplikacije koje su usredotočene na decentraliziranje razvojnih alata, uključivanje kriptoekonomskih sustava u postojeću tehnologiju i stvaranje tržišta za razvojni rad s otvorenim kodom.",
  "page-dapps-technology-title": "Decentralizirana tehnologija",
  "page-dapps-token-sets-logo-alt": "Token Sets logo",
  "page-dapps-tornado-cash-logo-alt": "Tornado cash logo",
  "page-dapps-uniswap-logo-alt": "Uniswap logo",
  "page-dapps-wallet-callout-button": "Pronađi novčanik",
  "page-dapps-wallet-callout-description": "Novčanici su također dapps-i. Pronađite jedan na temelju značajki koje vam odgovaraju.",
  "page-dapps-wallet-callout-image-alt": "Ilustracija robota.",
  "page-dapps-wallet-callout-title": "Pogledajte novčanike",
  "page-dapps-warning-header": "Uvijek provedite vlastita istraživanja",
  "page-dapps-warning-message": "Ethereum je nova tehnologija i većina aplikacija je nova. Prije polaganja velike količine novca, provjerite razumijete li rizike.",
  "page-dapps-what-are-dapps": "Što su dapps-ovi?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Računi",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Napredno",
  "docs-nav-backend-apis": "Pozadinski API-ji",
  "docs-nav-block-explorers": "Istraživanje bloka",
  "docs-nav-blocks": "Blokovi",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Sastavljanje pametnih ugovora",
  "docs-nav-composability": "Sastavljivost",
  "docs-nav-consensus-mechanisms": "Konsenzusni mehanizmi",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Podaci i analitika",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Uvođenje pametnih ugovora",
  "docs-nav-development-frameworks": "Okviri za razvoj",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Razvojne mreže",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API-ji klijenata Ethereuma",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Ethereumovi stogovi",
  "docs-nav-evm": "Ethereumova virtualna mašina (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Temeljne teme",
  "docs-nav-gas": "Gorivo",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Integrirana razvojna okruženja (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Uvod u Dapps",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Uvod u Eterijum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Uvod u knjižnicu",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "JavaScript APIs",
  "docs-nav-javascript": "Javascript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Rudarenje",
  "docs-nav-networks": "Mreže",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Čvorovi i klijenti",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Čvorovi kao usluga",
  "docs-nav-oracles": "Oracle",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Programski jezici",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Proof-of-stake",
  "docs-nav-proof-of-work": "Proof-of-work",
  "docs-nav-python": "Python",
  "docs-nav-readme": "Pročitaj me",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Skaliranje",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Sigurnost",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomija pametnog ugovora",
  "docs-nav-smart-contract-languages": "Jezici pametnih ugovora",
  "docs-nav-smart-contracts": "Pametni ugovori",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Knjižnice pametnih ugovora",
  "docs-nav-standards": "Standardi",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Pohrana",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Testiranje pametnih ugovora",
  "docs-nav-token-standards": "Standardi za tokene",
  "docs-nav-transactions": "Transakcije",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 u usporedbi sa Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Ako ste stručnjak za tu temu i želite dati svoj doprinos, uredite ovu stranicu i obasjajte je svojom mudrošću.",
  "page-calltocontribute-desc-2": "Bit ćete nagrađeni i pomoći ćete Ethereumovoj zajednici!",
  "page-calltocontribute-desc-3": "Upotrebljavajte to fleksibilno",
  "page-calltocontribute-desc-4": "Pitanja? Pitajte nas na kanalu #content na našem",
  "page-calltocontribute-link": "predložak dokumentacije",
  "page-calltocontribute-link-2": "Poslužitelj Discord",
  "page-calltocontribute-span": "Uredi stranicu",
  "page-calltocontribute-title": "Pomozite nam s ovom stranicom",
  "page-developer-meta-title": "Resursi za Ethereum programere",
  "page-developers-about": "Opisi resursa za programere",
  "page-developers-about-desc": "Ethereum.org je ovdje kako bi ti pomogao graditi na Ethereumu, dolazi sa dokumentacijom o osnovnim koncepcijama kao i programerskim obrascima. A tu su također i lekcije da bi ti pomogle na početku.",
  "page-developers-about-desc-2": "Ispirirani Mozzillom za programere, smislili smo mjesto gdje se nalaze resursi i sadržaji za programere. Kao i naši prijatelji u Mozilli, sve ovdje je otvoreno i spremno za vas da možete dalje stvarati i usavršavati.",
  "page-developers-account-desc": "Ugovori ili ljudi na mreži",
  "page-developers-accounts-link": "Računi",
  "page-developers-advanced": "Napredno",
  "page-developers-api-desc": "Interakcija s pametnim ugovorima kroz korištenje knjižnica",
  "page-developers-api-link": "Pozadinski API-ji",
  "page-developers-aria-label": "Programerski izbornik",
  "page-developers-block-desc": "Skup transakcija dodanih u lanac blokova",
  "page-developers-block-explorers-desc": "Vaš ulaz u Ethereummove podatke",
  "page-developers-block-explorers-link": "Istraživači blokova",
  "page-developers-blocks-link": "Blokovi",
  "page-developers-browse-tutorials": "Pregledavaj lekcije",
  "page-developers-choose-stack": "Izaberi svoj stog",
  "page-developers-contribute": "Doprinesite",
  "page-developers-dev-env-desc": "IDE-ovi koji su prikladni za razvijanje dappova",
  "page-developers-dev-env-link": "Razvojna okolina",
  "page-developers-discord": "Pridružite Discordu",
  "page-developers-docs-introductions": "Uvod",
  "page-developers-evm-desc": "Računalo koji obrađuje transakcije",
  "page-developers-evm-link": "Ethereumova virtualna mašina (EVM)",
  "page-developers-explore-documentation": "Istražite dokumentaciju",
  "page-developers-feedback": "Ako imate bilo kakvu povratnu informaciju, javite nam se kroz naš GitHub ili poslužitelj Discord.",
  "page-developers-frameworks-desc": "Alati koji pomažu pri ubrzavanju razvoja",
  "page-developers-frameworks-link": "Okviri za razvoj",
  "page-developers-fundamentals": "Osnove",
  "page-developers-gas-desc": "Ether je potreban da bi se obavile transakcije",
  "page-developers-gas-link": "Gorivo",
  "page-developers-get-started": "Kako biste vi voljeli započeti?",
  "page-developers-improve-ethereum": "Pomozite nam poboljšati ethereum.org",
  "page-developers-improve-ethereum-desc": "Baš kao ethereum.org ovi dokumenti su rezultat truda zajednice. Stvori PR ako vidiš grešku, prostor za napredak ili nove mogućnosti kako bi se pomoglo Eterijum programerima.",
  "page-developers-into-eth-desc": "Uvod u lance blokova i Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Uvod u decentralizirane aplikacije",
  "page-developers-intro-dapps-link": "Uvod u dapps",
  "page-developers-intro-eth-link": "Uvod u Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Uvod u stog",
  "page-developers-intro-stack-desc": "Uvod u Ethereumov stog",
  "page-developers-js-libraries-desc": "Interakcija sa pametnim ugovorima upotrebom javaskripta",
  "page-developers-js-libraries-link": "Knjižnice Javascripta",
  "page-developers-language-desc": "Upotreba Ethereuma na poznatim jezicima",
  "page-developers-languages": "Programerski jezici",
  "page-developers-learn": "Nauči razvijati Ethereum",
  "page-developers-learn-desc": "Istražite glavne ideje i Ethereumov stog kroz našu dokumentaciju",
  "page-developers-learn-tutorials": "Uči uz pomoć vodiča",
  "page-developers-learn-tutorials-cta": "Vidi vodiče",
  "page-developers-learn-tutorials-desc": "Učite razvoj Ethereuma korak po korak od već iskusnih graditelja.",
  "page-developers-meta-desc": "Dokumentacija, vodiči i alati za programere koji grade na Ethereumu.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Kako su novi blokovi kreirani i kako se dolazi do suglasnosti",
  "page-developers-mining-link": "Rudarenje",
  "page-developers-networks-desc": "Pregled glavne mreže i testnih mreža",
  "page-developers-networks-link": "Mreže",
  "page-developers-node-clients-desc": "Kako se potvrđuju blokovi i transakcije na mreži",
  "page-developers-node-clients-link": " Čvorovi i klijenti",
  "page-developers-oracle-desc": "Ugrađivanje vanlančanih informacija u pametne ugovore",
  "page-developers-oracles-link": "Oracle",
  "page-developers-play-code": "Igranje s kodom",
  "page-developers-read-docs": "Pročitajte dokumentaciju",
  "page-developers-scaling-desc": "Rješenja za brže transakcije",
  "page-developers-scaling-link": "Skaliranje",
  "page-developers-smart-contract-security-desc": "Sigurnosne mjere koje treba uzeti u obzir tijekom programiranja",
  "page-developers-smart-contract-security-link": "Sigurnost",
  "page-developers-set-up": "Postavi lokalno okruženje",
  "page-developers-setup-desc": "Pripremite stog za gradnju tako što ćete podesiti okolinu za programiranje.",
  "page-developers-smart-contracts-desc": "Logika Dapp-ova – samoizvršni dogovori",
  "page-developers-smart-contracts-link": "Pametni ugovori",
  "page-developers-stack": "Stog",
  "page-developers-start": "Počnite eksperimentirati",
  "page-developers-start-desc": "Želiš li prvo eksperimentirati, a pitanja postavljati poslije?",
  "page-developers-storage-desc": "Kako se nositi sa spremanjem podataka na dapp",
  "page-developers-storage-link": "Pohrana",
  "page-developers-subtitle": "Graditeljski priručnik za Ethereum. Od graditelja, za graditelje.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "Programer",
  "page-developers-title-3": "Resursi",
  "page-developers-token-standards-desc": "Pregled prihvaćenih standarda za tokene",
  "page-developers-token-standards-link": "Standardi za tokene",
  "page-developers-transactions-desc": "Kako se mijenja stanje Ethereuma",
  "page-developers-transactions-link": "Transakcije",
  "page-developers-web3-desc": "Kako je svijet razvijanja sa web3 drugačiji",
  "page-developers-web3-link": "Web2 ili Web3",
  "page-learning-tools-bootcamps": "Kampovi za napredovanje programera",
  "page-learning-tools-bootcamps-desc": "Plaćeni internetski tečajevi koji će vam pomoći.",
  "page-learning-tools-browse-docs": "Pregledavajte dokumente",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "ChainShot logo",
  "page-learning-tools-coding": "Učite kodirajući",
  "page-learning-tools-coding-subtitle": "Ovi alati pomoći će vam da eksperimentirate s Ethereumom ako više volite interaktivno iskustvo učenja.",
  "page-learning-tools-consensys-academy-description": "Mrežni kampovi za Ethereum programere.",
  "page-learning-tools-consensys-academy-logo-alt": "ConsenSys Academy logo",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Naučite Solidity gradeći svoju vlastitu igru sa zombijima.",
  "page-learning-tools-cryptozombies-logo-alt": "CryptoZombies logo",
  "page-learning-tools-documentation": "Učite s dokumentacijom",
  "page-learning-tools-documentation-desc": "Želite saznati više? Idite u našu dokumentaciju kako biste pronašli potrebna objašnjenja.",
  "page-learning-tools-eth-dot-build-description": "Obrazovni pješčanik za web3, uključujući povuci i ispusti programiranje, kao i blokove za gradnju otvorenog koda.",
  "page-learning-tools-eth-dot-build-logo-alt": "Eth.build logo",
  "page-learning-tools-ethernauts-description": "Završite nivoe hakiranjem pametnih ugovora.",
  "page-learning-tools-ethernauts-logo-alt": "Ethernauts logo",
  "page-learning-tools-game-tutorials": "Interaktivni vodiči za igre",
  "page-learning-tools-game-tutorials-desc": "Učite dok igrate. Ovi vodiči vode vas kroz osnove igranja.",
  "page-learning-tools-meta-desc": "Alati za kodiranje na mreži i interaktivna iskustva učenja koja će vam pomoći da eksperimentirate s Ethereumovim razvojem.",
  "page-learning-tools-meta-title": "Alati za učenje programiranja",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Razvoj, implementacija i administriranje pametnih ugovora za Ethereum. Slijedite upute s dodatkom Learneth.",
  "page-learning-tools-remix-description-2": "Remix nije samo pješčanik. Mnogi programeri pišu, kompajliraju i primenjuju svoje pametne ugovore upotrebljavajući Remix.",
  "page-learning-tools-remix-logo-alt": "Remix logo",
  "page-learning-tools-sandbox": "Pješčanici za kodove",
  "page-learning-tools-sandbox-desc": "Ti će vam pješčanici pružiti prostor za eksperimentiranje s pisanjem pametnih ugovora i razumijevanjem Ethereuma.",
  "page-learning-tools-studio-description": "Internetski IDE na kojem možete slijediti upute za stvaranje i testiranje pametnih ugovora te za njih izraditi sučelje.",
  "page-learning-tools-vyperfun-description": "Naučite Vyper gradeći vlastitu igru Pokémon.",
  "page-learning-tools-vyperfun-logo-alt": "Vyper.fun logo",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Okvir za razvoj i testiranje zasnovan na Pythonu za pametne ugovore usmjerene na Ethereumovu virtualnu mašinu.",
  "page-local-environment-brownie-logo-alt": "Brownie logo",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Razvojna platforma sve u jednom, za izgradnju i primjenu decentraliziranih aplikacija.",
  "page-local-environment-embark-logo-alt": "Embark logo",
  "page-local-environment-epirus-desc": "Platforma za razvoj, plasiranje i nadgledanje aplikacija lanaca blokova na Javinoj virtualnoj mašini",
  "page-local-environment-epirus-logo-alt": "Epirus logo",
  "page-local-environment-eth-app-desc": "Izradite aplikacije na osnovi Ethereuma jednom naredbom. Isporučuje se s širokom ponudom okvira UI i predložaka DeFi koje možete odabrati.",
  "page-local-environment-eth-app-logo-alt": "Stvaranje logotipa Eth App",
  "page-local-environment-framework-feature-1": "Značajke za okretanje lokalne instance lanca blokova.",
  "page-local-environment-framework-feature-2": "Uslužni programi za sastavljanje \"kompajliranje\" i testiranje vaših pametnih ugovora.",
  "page-local-environment-framework-feature-3": "Programi za razvoj klijenta za izgradnju vaše aplikacije usmjerene prema korisnicima unutar istog projekta/spremišta.",
  "page-local-environment-framework-feature-4": "Konfiguracija za povezivanje s Ethereumovim mrežama i implementiranje ugovora, bilo da se radi o lokalno pokrenutoj instanci ili nekoj od javnih mreža Ethereuma.",
  "page-local-environment-framework-feature-5": "Decentralizirana distribucija aplikacija – integracije s opcijama pohrane poput IPFS-a.",
  "page-local-environment-framework-features": "Ti okviri dolaze s puno unaprijed pripremljenih funkcionalnosti, poput:",
  "page-local-environment-frameworks-desc": " Preporučujemo odabir okvira, posebno ako tek započinjete. Izgradnja punopravnog dapp-a zahtijeva različite dijelove tehnologije. Okviri uključuju mnoge potrebne značajke ili pružaju jednostavne sustave dodataka za odabir alata koje želite.",
  "page-local-environment-frameworks-title": "Okviri i gotovi stogovi",
  "page-local-environment-hardhat-desc": "Hardhat je Ethereumovo razvojno okruženje za profesionalce.",
  "page-local-environment-hardhat-logo-alt": "Hardhat logo",
  "page-local-environment-openZeppelin-desc": "Uštedite sate razvojnog vremena sastavljanjem, nadogradnjom, implementacijom i interakcijom s pametnim ugovorima pomoću našeg CLI-ja.",
  "page-local-environment-openZeppelin-logo-alt": "OpenZeppelin logo",
  "page-local-environment-scaffold-eth-desc": "Hardhat + aplikacija Create Eth: sve što je potrebno za početak izrade decentraliziranih aplikacija pokrenutih pametnim ugovorima",
  "page-local-environment-scaffold-eth-logo-alt": "scaffold-eth logo",
  "page-local-environment-setup-meta-desc": "Vodič za odabir softverskog stoga za razvoj Ethereuma.",
  "page-local-environment-setup-meta-title": "Postavljanje lokalnog razvojnog okruženja Ethereuma",
  "page-local-environment-setup-subtitle": "Ako ste spremni započeti gradnju, vrijeme je da odaberete svoj stog.",
  "page-local-environment-setup-subtitle-2": " Evo alata i okvira pomoću kojih možete izgraditi svoju aplikaciju Ethereum.",
  "page-local-environment-setup-title": "Postavite svoje lokalno razvojno okruženje",
  "page-local-environment-solidity-template-desc": "Predložak GitHub sa unaprijed zadanim postavkama za vaše pametne ugovore Solidity. Uključuje lokalnu mrežu Hardhat, Waffle za testove, Ethere za implementaciju novčanika i još mnogo toga.",
  "page-local-environment-solidity-template-logo-alt": "Solidity template logo",
  "page-local-environment-truffle-desc": "Komplet Truffle vodi programere od ideje do dapp-a što je udobnije moguće.",
  "page-local-environment-truffle-logo-alt": "Truffle logo",
  "page-local-environment-waffle-desc": "Najnaprednija knjižnica za testiranje pametnih ugovora. Upotrebljavajte samostalno ili sa Scaffold-ethom ili s Hardhatom.",
  "page-local-environment-waffle-logo-alt": "Waffle logo",
  "comp-tutorial-metadata-minute-read": "minuta čitanja",
  "page-tutorial-listing-policy-intro": "Prije nego što pošaljete vodič, pročitajte pravila o objavi.",
  "comp-tutorial-metadata-tip-author": "Napojnica za autora",
  "page-tutorial-listing-policy": "pravila objave članaka",
  "page-tutorial-new-github": "Novi ste na GitHubu?",
  "page-tutorial-new-github-desc": "Pokrenite zahtjev za rješavanje problema – samo ispunite tražene podatke i zalijepite svoj vodič.",
  "page-tutorial-pull-request": "Stvorite zahtjev za povlačenjem",
  "page-tutorial-pull-request-btn": "Stvorite zahtjev za povlačenjem",
  "page-tutorial-pull-request-desc-1": "Slijedite",
  "page-tutorial-pull-request-desc-2": "tutorials/your-tutorial-name/index.md",
  "page-tutorial-pull-request-desc-3": "strukturu imenovanja.",
  "page-tutorial-raise-issue-btn": "Pokrenite zahtjev za rješavanje problema",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Pošaljite vodič",
  "page-tutorial-submit-tutorial": "Da biste poslali vodič, morate koristiti GitHub. Pozivamo vas da stvorite zahtjev za rješavanje ili zahtjev za povlačenje.",
  "page-tutorial-subtitle": "Dobrodošli na odabrani popis vodiča za zajednicu.",
  "page-tutorial-tags-error": "Nijedan vodič nema sve ove oznake",
  "page-tutorial-title": "Vodiči za Ethereumov razvoj",
  "page-tutorials-meta-description": "Pregledajte i filtrirajte provjerene vodiče za zajednicu Ethereum po temama.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Vodiči za Ethereumov razvoj",
  "page-eth-buy-some": "Želite li kupiti koji Ethereum?",
  "page-eth-buy-some-desc": "Uobičajeno je miješati Ethereum i ETH. Ethereum je lanac blokova, a ETH je primarna imovina Ethereuma. ETH je ono što vjerojatno želite kupiti.",
  "page-eth-cat-img-alt": "Grafika glifa ETH s kaleidoskopom mačaka",
  "page-eth-collectible-tokens": "Kolekcionarski tokeni",
  "page-eth-collectible-tokens-desc": "Žetoni koji predstavljaju kolekcionarski predmet igre, digitalnu umjetnost ili drugu jedinstvenu imovinu. Uobičajeno poznati kao nezamjenjivi tokeni (NFT).",
  "page-eth-cryptography": "Osigurano kriptografijom",
  "page-eth-cryptography-desc": "Internetski novac može biti nov, ali osiguran je provjerenom kriptografijom. To štiti vaš novčanik, vaš ETH i vaše transakcije. ",
  "page-eth-currency-for-apps": "To je valuta Ethereum aplikacija.",
  "page-eth-currency-for-future": "Valuta za našu digitalnu budućnost",
  "page-eth-description": "ETH je kriptovaluta. Rijedak digitalni novac koji možete koristiti na internetu sličan Bitcoinu. Ako ste novi u kriptu, evo kako se ETH razlikuje od tradicionalnog novca.",
  "page-eth-earn-interest-link": "Zaradite kamate",
  "page-eth-ethhub-caption": "Često se ažurira",
  "page-eth-ethhub-overview": "EthHub ima sjajan pregled ako želite",
  "page-eth-flexible-amounts": "Dostupno u fleksibilnim količinama",
  "page-eth-flexible-amounts-desc": "ETH je djeljiv do 18 decimalnih mjesta, tako da ne morate kupiti 1 cijeli ETH. Možete kupiti frakcije – samo 0,000000000000000001 ETH ako želite.",
  "page-eth-fuels": "ETH je gorivo Ethereuma i osigurava ga",
  "page-eth-fuels-desc": "ETH je krvotok Ethereuma. Kada šaljete ETH ili se koristite aplikacijom Ethereum, platit ćete malu naknadu u ETH za upotrebu mreže Ethereum. Ova naknada poticaj je rudaru da obradi i provjeri što pokušavate učiniti.",
  "page-eth-fuels-desc-2": "Rudari su poput zapisničara Ethereuma - provjeravaju i dokazuju da nitko ne vara. Rudari koji rade ovaj posao također su nagrađeni malim količinama novoizdanih ETH kovanica.",
  "page-eth-fuels-desc-3": "Posao koji rudari rade održava Ethereum sigurnim i bez centralizirane kontrole. Drugim riječima,",
  "page-eth-fuels-more-staking": "Više o ulaganju",
  "page-eth-fuels-staking": "ETH će postati još važniji ulaganjem. Kad uložite svoj ETH, moći ćete osigurati Ethereum i zaraditi nagrade. U tom sustavu prijetnja gubitka vašeg ETH-a destimulira napade.",
  "page-eth-get-eth-btn": "Nabavite ETH",
  "page-eth-gov-tokens": "Žetoni uprave",
  "page-eth-gov-tokens-desc": "Žetoni koji predstavljaju prava glasanja u decentraliziranim organizacijama.",
  "page-eth-has-value": "Zašto ETH ima vrijednost?",
  "page-eth-has-value-desc": "ETH je vrijedan na različite načine za različite ljude.",
  "page-eth-has-value-desc-2": "Korisnicima Ethereuma ETH je vrijedan jer omogućuje plaćanje naknada za transakcije.",
  "page-eth-has-value-desc-3": "Drugi ga vide kao digitalnu zalihu vrijednosti jer se stvaranje novih ETH-a s vremenom usporava.",
  "page-eth-has-value-desc-4": "U novije vrijeme ETH je postao vrijedan za korisnike financijskih aplikacija na Ethereumu. To je zato što ETH možete koristiti kao polog za kripto kredite ili kao sustav plaćanja.",
  "page-eth-has-value-desc-5": "Naravno, mnogi to vide i kao investiciju, slično Bitcoin-u ili drugim kriptovalutama.",
  "page-eth-how-to-buy": "Kako kupiti Ether",
  "page-eth-how-to-buy-caption": "Često se ažurira",
  "page-eth-is-money": "ETH je digitalni, globalni novac.",
  "page-eth-last-updated": "siječanj 2019.",
  "page-eth-mining-link": "Više o rudarenju",
  "page-eth-monetary-policy": "Monetarna politika Ethereuma",
  "page-eth-more-on-ethereum-link": "Više o Ethereumu",
  "page-eth-no-centralized": "Nema centralizirane kontrole ",
  "page-eth-no-centralized-desc": "ETH je decentraliziran i globalan. Ne postoji tvrtka ili banka koja se može odlučiti za izdavanje više ETH-a ili promjenu uvjeta korištenja.",
  "page-eth-non-fungible-tokens-link": "Nezamjenjivi tokeni",
  "page-eth-not-only-crypto": "ETH nije jedini kripto na Ethereumu",
  "page-eth-not-only-crypto-desc": "Svatko može stvoriti nove vrste imovine i trgovati njima na Ethereumu. To je poznato kao tokeni. Ljudi su tokenirali tradicionalne valute, svoje nekretnine, svoju umjetnost, pa čak i sebe!",
  "page-eth-not-only-crypto-desc-2": "Ethereum je dom za tisuće tokena – neki korisniji i vrijedniji od drugih. Programeri neprestano grade nove tokene koji otključavaju nove mogućnosti i otvaraju nova tržišta.",
  "page-eth-not-only-crypto-desc-3": "Ako želite saznati više o tokenima, naši prijatelji iz EthHub-a napisali su nekoliko sjajnih pregleda:",
  "page-eth-open": "Otvoren za sve",
  "page-eth-open-desc": "Za prihvaćanje ETH-a trebaju vam samo internetska veza i novčanik. Za primanja uplate ne treba vam pristup bankovnom računu. ",
  "page-eth-p2p-payments": "Ravnopravna plaćanja",
  "page-eth-p2p-payments-desc": "Svoj ETH možete poslati bez posredničke usluge kao što je banka. To je poput osobne predaje gotovine, ali to možete učiniti sigurno s bilo kime, bilo gdje i bilo kada.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Popularne vrste tokena",
  "page-eth-powers-ethereum": "ETH pokreće Ethereum",
  "page-eth-shit-coins": "Shitcoins",
  "page-eth-shit-coins-desc": "Budući da je stvaranje novih tokena jednostavno, to može učiniti svatko – čak i ljudi s lošim ili pogrešnim namjerama. Uvijek istražite prije nego što ih upotrijebite!",
  "page-eth-stablecoins": "Stabilni novčići",
  "page-eth-stablecoins-desc": "Tokeni koji odražavaju vrijednost tradicionalne valute poput dolara. Ovo rješava problem volatilnosti kod mnogih kriptovaluta.",
  "page-eth-stablecoins-link": "Nabavite stabilne novčiće",
  "page-eth-stream-link": "Tijek ETH",
  "page-eth-tokens-link": "Ethereumovi tokeni",
  "page-eth-trade-link-2": "Zamijenite tokene",
  "page-eth-underpins": "ETH podupire financijski sustav Ethereuma",
  "page-eth-underpins-desc": "Nezadovoljna postojećim plaćanjima, zajednica Ethereum gradi čitav financijski sustav koji je ravnopravan i dostupan svima.",
  "page-eth-underpins-desc-2": "Možete ETH upotrebljavati kao polog za generiranje potpuno drugačijih tokena kriptovaluta na Ethereumu. Osim toga, možete iznajmiti, posuditi i zaraditi kamate na ETH-u i drugim ETH tokenima.",
  "page-eth-uses": "Upotrebljavajte za rast ETH-a svaki dan",
  "page-eth-uses-desc": "Budući da je Ethereum moguće programirati, programeri mogu oblikovati ETH na bezbroj načina.",
  "page-eth-uses-desc-2": "Još 2015. godine sve što ste mogli je poslati ETH s jednog računa Ethereuma na drugi. Ovdje je samo nekoliko stvari koje danas možete učiniti:",
  "page-eth-uses-desc-3": "platiti nekome ili dobiti sredstva u stvarnom vremenu",
  "page-eth-uses-desc-4": "možete mjenjati ETH-e s drugim tokenima, uključujući Bitcoin.",
  "page-eth-uses-desc-5": "Na ETH i drugim tokenima temeljenim na Ethereumu",
  "page-eth-uses-desc-6": "pristupite svijetu kriptovaluta sa stabilnom, manje promjenjivom vrijednošću.",
  "page-eth-value": "Zašto je Ether vrijedan",
  "page-eth-video-alt": "ETH glif videozapis",
  "page-eth-whats-eth": "Što je Ether (ETH)?",
  "page-eth-whats-eth-hero-alt": "Ilustracija skupine ljudi koja se u čudu čudi gliph-u Etera (ETH)",
  "page-eth-whats-eth-meta-desc": "Što trebate znati da biste razumjeli ETH i njegovo mjesto u Ethereumu.",
  "page-eth-whats-eth-meta-title": "Što je Ether (ETH)?",
  "page-eth-whats-ethereum": "Što je Ethereum?",
  "page-eth-whats-ethereum-desc": "Ako želite saznati više o Ethereumu, tehnologiji koja stoji iza ETH-a, pogledajte naš uvod.",
  "page-eth-whats-unique": "Što je jedinstveno u vezi s ETH-om?",
  "page-eth-whats-unique-desc": "Na Ethereumu postoji mnogo kriptovaluta i puno drugih tokena, ali postoje neke stvari koje može samo ETH.",
  "page-eth-where-to-buy": "Gdje nabaviti ETH",
  "page-eth-where-to-buy-desc": "ETH možete dobiti putem mjenjačnice ili novčanika, ali različite zemlje imaju različita pravila. Pogledajte usluge koje će vam omogućiti kupnju ETH-a.",
  "page-eth-yours": "Stvarno je vaš",
  "page-eth-yours-desc": "ETH vam omogućuje da budete vlastita banka. Svoja novčana sredstva možete kontrolirati novčanikom kao dokazom o vlasništvu – ne trebaju vam treće strane.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Eth2 adresa ugovora o pologu",
  "page-eth2-deposit-contract-address-caption": "Dodali smo razmake kako bismo adresu učinili lakšom za čitanje",
  "page-eth2-deposit-contract-address-check-btn": "Provjerite adresu ugovora o pologu",
  "page-eth2-deposit-contract-checkbox1": "Već sam upotrijebio lansirnu rampu za postavljanje svog validatora Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Razumijem da za ulog moram koristiti lansirnu rampu. Jednostavni transferi na ovu adresu neće funkcionirati.",
  "page-eth2-deposit-contract-checkbox3": "Provjerit ću adresu ugovora o pologu kod drugih izvora.",
  "page-eth2-deposit-contract-confirm-address": "Potvrdite da biste otkrili adresu",
  "page-eth2-deposit-contract-copied": "Kopirana adresa",
  "page-eth2-deposit-contract-copy": "Kopiraj adresu",
  "page-eth2-deposit-contract-etherscan": "Pogledajte ugovor na Etherscanu",
  "page-eth2-deposit-contract-h2": "Ovdje ne ulažete",
  "page-eth2-deposit-contract-launchpad": "Ulog putem lansirne rampe \"launchpad\"",
  "page-eth2-deposit-contract-launchpad-2": "Korištenje lansirne rampe",
  "page-eth2-deposit-contract-meta-desc": "Provjerite adresu ugovora o pologu za Eth2 ulaganje.",
  "page-eth2-deposit-contract-meta-title": "Eth2 adresa ugovora o pologu",
  "page-eth2-deposit-contract-read-aloud": "Pročitajte adresu naglas",
  "page-eth2-deposit-contract-reveal-address-btn": "Otkrivanje adrese",
  "page-eth2-deposit-contract-staking": " Da biste svoj ETH uložili u Eth2, morate koristiti namjenski proizvod za lansiranje i slijediti upute. Slanjem ETH-a na adresu na ovoj stranici nećete postati ulagač i rezultirat će neuspjelom transakcijom.",
  "page-eth2-deposit-contract-staking-check": "Provjerite ove izvore",
  "page-eth2-deposit-contract-staking-check-desc": "Očekujemo da će biti puno lažnih adresa i prevara. Da biste bili sigurni, provjerite adresu za ulaganje Eth2 koju upotrebljavate sa adresom na ovoj stranici. Preporučujemo da to provjerite i pomoću drugih pouzdanih izvora.",
  "page-eth2-deposit-contract-staking-more-link": "Više o ulaganju",
  "page-eth2-deposit-contract-stop-reading": "Prestanite čitati",
  "page-eth2-deposit-contract-subtitle": " Ovo je adresa za ugovor o ulozima Eth2. Pomoću ove stranice potvrdite da šaljete sredstva na ispravnu adresu kada ulažete.",
  "page-eth2-deposit-contract-warning": "Pažljivo provjerite svaki znak.",
  "page-eth2-deposit-contract-warning-2": "Slanje sredstava na ovu adresu neće uspjeti i nećete postati ulagač. Morate slijediti upute za lansiranje.",
  "page-eth2-bug-bounty-annotated-specs": "anotirana specifikacija",
  "page-eth2-bug-bounty-annotations": "Bilo bi korisno provjeriti sljedeće napomene:",
  "page-eth2-bug-bounty-client-bugs": "Programske pogreške klijenta Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Klijenti će pokrenuti Beacon Chain nakon što se izvrši nadogradnja. Klijenti će morati slijediti logiku navedenu u specifikaciji da budu sigurni od potencijalnih napada. Poreške koje želimo pronaći povezane su s implementacijom protokola.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Currently Lighthouse, Nimbus, Teku, and Prysm bugs are currently eligible for this bounty. More clients may be added as they complete audits and become production ready.",
  "page-eth2-bug-bounty-clients": "Klijenti predstavljeni u nagradama",
  "page-eth2-bug-bounty-clients-type-1": "problemi neusklađenosti specifikacije.",
  "page-eth2-bug-bounty-clients-type-2": "osjetljivost neočekivanih padova ili uskraćivanje usluge (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "bilo koji problemi koji uzrokuju nepopravljivi konsenzus odvajaju se od ostatka mreže.",
  "page-eth2-bug-bounty-docking": "spajanje",
  "page-eth2-bug-bounty-email-us": "Pošaljie nam poruku e-pošte:",
  "page-eth2-bug-bounty-help-links": "Korisne poveznice",
  "page-eth2-bug-bounty-hunting": "Pravila lova na pogreške",
  "page-eth2-bug-bounty-hunting-desc": "Program nagrađivanja lova na pogreške eksperimentalni je i diskrecijski program nagrađivanja za našu aktivnu Ethereumovu zajednicu za poticanje i nagrađivanje onih koji pomažu u poboljšanju platforme. To nije natjecanje. Trebate znati znati da program možemo otkazati u bilo kojem trenutku, a dodjela nagrada je isključivo diskrecijsko pravo odbora za nagrade za lov na programske pogreške zaklade Ethereum. Osim toga, nismo u mogućnosti dodijeliti nagrade osobama koje se nalaze na popisima sankcija ili su u zemljama koje su na popisima sankcija (npr. Sjeverna Koreja, Iran itd.). Sami ste dgovorni za sve poreze. Sve nagrade podliježu važećem zakonu. Na kraju, vaše testiranje ne smije kršiti nijedan zakon niti ugroziti podatke koji nisu vaši.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Klasifikacija lova na pogreške",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Pronađite pogreške Eth2 da biste došli na ovaj popis s rezultatima",
  "page-eth2-bug-bounty-hunting-li-1": "Problemi koje je već poslao drugi korisnik ili su već poznati osobama koje održavaju specifikacije i klijente ne ispunjavaju uvjete za nagrade.",
  "page-eth2-bug-bounty-hunting-li-2": "Javno otkrivanje ranjivosti čini je neprihvatljivom za nagradu.",
  "page-eth2-bug-bounty-hunting-li-3": "Istraživači zaklade Ethereum i zaposlenici timova klijenata Eth2 ne ispunjavaju uvjete za nagrade.",
  "page-eth2-bug-bounty-hunting-li-4": "Ethereum program nagrađivanja uzima u obzir brojne varijable u određivanju nagrada. Određivanje prihvatljivosti, rezultat i svi uvjeti koji se odnose na nagradu isključivo su odluka odbora za nagrade za lov na pogreške zaklade Ethereum.",
  "page-eth2-bug-bounty-leaderboard": "Pogledajte cijelu klasifikaciju",
  "page-eth2-bug-bounty-leaderboard-points": "bodovi",
  "page-eth2-bug-bounty-ledger-desc": "Specifikacija lanca Beacon Chain detaljno opisuje obrazloženje dizajna i predložene promjene u Ethereumu nadogradnjom Beacon Chain.",
  "page-eth2-bug-bounty-ledger-title": "Poreške u specifikaciji nadogradnje Beacon Chain",
  "page-eth2-bug-bounty-meta-description": "Pregled programa lova na pogreške Eth2: kako se uključiti i informacije o nagradama.",
  "page-eth2-bug-bounty-meta-title": "Program nagrađivanja lova na pogreške Eth2",
  "page-eth2-bug-bounty-not-included": "Nije uključeno",
  "page-eth2-bug-bounty-not-included-desc": "Nadogradnje lanca djelića i nadogradnje spajanja još su uvijek u aktivnom razvoju pa još nisu uključeni u ovaj program nagrađivanja.",
  "page-eth2-bug-bounty-owasp": "Pogledajte metodu OWASP",
  "page-eth2-bug-bounty-points": "EF će također dodjeljivati bodove na temelju:",
  "page-eth2-bug-bounty-points-error": "Pogreška pri učitavanju podataka... osvježite.",
  "page-eth2-bug-bounty-points-exchange": "Razmjena bodova",
  "page-eth2-bug-bounty-points-loading": "Učitavanje podataka...",
  "page-eth2-bug-bounty-points-payout-desc": " Zaklada Ethereum isplatit će vrijednost USD-a u ETH-u ili DAI-u.",
  "page-eth2-bug-bounty-points-point": "1 bod",
  "page-eth2-bug-bounty-points-rights-desc": "Zaklada Ethereum zadržava pravo to promijeniti bez prethodne najave.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Kvaliteta opisa",
  "page-eth2-bug-bounty-quality-desc": ": veće nagrade isplaćuju se za jasne, dobro napisane prijave.",
  "page-eth2-bug-bounty-quality-fix": "Kvaliteta popravka",
  "page-eth2-bug-bounty-quality-fix-desc": ", ako je uključeno: veće nagrade isplaćuju se za prijave s jasnim opisom načina rješavanja problema.",
  "page-eth2-bug-bounty-quality-repro": "Kvaliteta ponovljivosti",
  "page-eth2-bug-bounty-quality-repro-desc": ": uključite testni kod, skripte i detaljne upute. Što nam je lakše reproducirati i provjeriti ranjivost, to je veća nagrada.",
  "page-eth2-bug-bounty-questions": "Pitanja?",
  "page-eth2-bug-bounty-rules": "Pročitajte pravila",
  "page-eth2-bug-bounty-shard-chains": "lanci djelića",
  "page-eth2-bug-bounty-slogan": "Nagrade lova na Eth2 pogreške",
  "page-eth2-bug-bounty-specs": "Pročitajte cijelu specifikaciju",
  "page-eth2-bug-bounty-specs-docs": "Dokumentacija sa specifikacijama",
  "page-eth2-bug-bounty-submit": "Pošaljite pogrešku",
  "page-eth2-bug-bounty-submit-desc": "Za svaku otkrivenu programsku pogrešku dobit ćete bodove. Bodovi koje zaradite ovise o ozbiljnosti greške. Zaklada Ethereum (EF) utvrđuje ozbiljnost pomoću metode OWASP.",
  "page-eth2-bug-bounty-subtitle": "Pronalaženjem pogrešaka u protokolu Eth2 protokolu i klijentu zaradite do 50.000 USD i mjesto na ljestvici najboljih.",
  "page-eth2-bug-bounty-title": "Otvoreno za prijave",
  "page-eth2-bug-bounty-title-1": "Beacon Chain",
  "page-eth2-bug-bounty-title-2": "Izbor izdvajanja koda Fork",
  "page-eth2-bug-bounty-title-3": "Solidityjev ugovor o depozitu",
  "page-eth2-bug-bounty-title-4": "Umrežavanje ravnopravnih osoba",
  "page-eth2-bug-bounty-type-1": "pogreške u sigurnosti / prekidanju konačnosti.",
  "page-eth2-bug-bounty-type-2": "vektori uskraćivanja usluge (DOS)",
  "page-eth2-bug-bounty-type-3": "nedosljednosti u pretpostavkama, poput situacija u kojima se mogu degradirati pošteni validatori.",
  "page-eth2-bug-bounty-type-4": "izračun ili nedosljednosti parametara.",
  "page-eth2-bug-bounty-types": "Vrste programske pogreške",
  "page-eth2-bug-bounty-validity": "Važeće programske pogreške",
  "page-eth2-bug-bounty-validity-desc": "Ovaj program nagrađivanja pronalaženja pogrešaka usmjeren je na pronalaženje pogrešaka u osnovnoj specifikaciji Beacon Chain Eth2-a i klijentskim implementacijama Prysm, Lighthouse i Teku.",
  "page-eth2-bug-bountycard-critical": "Kritično",
  "page-eth2-bug-bountycard-critical-risk": "Prijavite pogrešku kritičnog rizika",
  "page-eth2-bug-bountycard-h2": "Srednje",
  "page-eth2-bug-bountycard-high": "Visoko",
  "page-eth2-bug-bountycard-high-risk": "Prijavite pogrešku visokog rizika",
  "page-eth2-bug-bountycard-label-1": "Do 1.000 bodova",
  "page-eth2-bug-bountycard-label-2": "Do 2.000 DAI",
  "page-eth2-bug-bountycard-label-3": "Do 5.000 bodova",
  "page-eth2-bug-bountycard-label-4": "Do 10.000 DAI",
  "page-eth2-bug-bountycard-label-5": "Do 10.000 bodova",
  "page-eth2-bug-bountycard-label-6": "Do 20.000 DAI",
  "page-eth2-bug-bountycard-label-7": "Do 25.000 bodova",
  "page-eth2-bug-bountycard-label-8": "Do 50.000 DAI",
  "page-eth2-bug-bountycard-li-1": "Nizak utjecaj, srednja vjerojatnost",
  "page-eth2-bug-bountycard-li-2": "Srednji utjecaj, mala vjerojatnost",
  "page-eth2-bug-bountycard-li-3": "Visok utjecaj, mala vjerojatnost",
  "page-eth2-bug-bountycard-li-4": "Srednji utjecaj, srednja vjerojatnost",
  "page-eth2-bug-bountycard-li-5": "Nizak utjecaj, visoka vjerojatnost",
  "page-eth2-bug-bountycard-li-6": "Visok utjecaj, srednja vjerojatnost",
  "page-eth2-bug-bountycard-li-7": "Srednji utjecaj, visoka vjerojatnost",
  "page-eth2-bug-bountycard-li-8": "Visok utjecaj, visoka vjerojatnost",
  "page-eth2-bug-bountycard-low": "Nisko",
  "page-eth2-bug-bountycard-low-risk": "Prijavite pogrešku niskog rizika",
  "page-eth2-bug-bountycard-medium-risk": "Prijavite pogrešku srednjeg rizika",
  "page-eth2-bug-bountycard-subheader": "Ozbiljnost",
  "page-eth2-bug-bountycard-subheader-2": "Primjer",
  "page-eth2-bug-bountycard-text": "Napadač ponekad može staviti čvor u stanje zbog kojeg će jedna od sto potvrda koje je izvršio validator biti ispuštena",
  "page-eth2-bug-bountycard-text-1": " Napadač može uspješno izvoditi skrivene napade na čvorove s peer-id-ovima s 4 vodeće nulta bajta",
  "page-eth2-bug-bountycard-text-2": "Postoji konsenzusna pogreška između dva klijenta, ali napadaču je teško ili nepraktično pokrenuti taj događaj.",
  "page-eth2-bug-bountycard-text-3": "  Postoji konsenzusna pogreška između dva klijenta, i napadaču je lako pokrenuti taj događaj.",
  "page-eth2-get-involved-btn-1": "Pogledajte klijente",
  "page-eth2-get-involved-btn-2": "Više o ulaganju",
  "page-eth2-get-involved-btn-3": "Pronađite pogreške",
  "page-eth2-get-involved-bug": "Pogreška može biti:",
  "page-eth2-get-involved-bug-hunting": "Krenite u lov na pogreške",
  "page-eth2-get-involved-bug-hunting-desc": "Pronađite i prijavite pogreške u specifikacijama nadogradnje Eth2 ili samim klijentima. Možete zaraditi do 50.000 USD i zaraditi mjesto na ljestvici.",
  "page-eth2-get-involved-bug-li": "problemi neusklađenosti specifikacije",
  "page-eth2-get-involved-bug-li-2": "pogreške u prekidanju konačnosti",
  "page-eth2-get-involved-bug-li-3": "vektori uskraćivanja usluge (DOS)",
  "page-eth2-get-involved-bug-li-4": "i više...",
  "page-eth2-get-involved-date": "Datum zatvaranja: 23. prosinca 2020.",
  "page-eth2-get-involved-desc-1": "Pokretanje klijenta znači da ćete biti aktivni sudionik Ethereuma. Vaš će klijent pomoći u evidentiranju transakcija i provjeri novih blokova.",
  "page-eth2-get-involved-desc-2": "Ako imate ETH, možete ga uložiti da postanete validator i pomognete u zaštiti mreže. Kao validator možete zaraditi ETH nagrade.",
  "page-eth2-get-involved-desc-3": "Pridružite se naporima testiranja u zajednici! Pomozite testirati nadogradnje Eth2 prije slanja, pronaći pogreške i zaraditi nagrade.",
  "page-eth2-get-involved-ethresearch-1": "Dijeljenje",
  "page-eth2-get-involved-ethresearch-2": "Prijelaz Eth1 u Eth2",
  "page-eth2-get-involved-ethresearch-3": "Djelići i stanja izvođenja",
  "page-eth2-get-involved-ethresearch-4": "Sve teme istraživanja",
  "page-eth2-get-involved-grants": "Ulaganje sredstava zajednice",
  "page-eth2-get-involved-grants-desc": "Pomozite u stvaranju alata i obrazovnog sadržaja za zajednicu ulagača",
  "page-eth2-get-involved-how": "Kako se želite uključiti?",
  "page-eth2-get-involved-how-desc": "Zajednica Ethereum uvijek će imati koristi od više ljudi koji pokreću klijente, uloge i lov na pogreške.",
  "page-eth2-get-involved-join": "Pridružite se istraživanju",
  "page-eth2-get-involved-join-desc": " Kao i većina stvari s Ethereumom i mnoga su istraživanja javna. To znači da možete sudjelovati u raspravama ili samo pročitati što kažu istraživači Ethereuma. Ethresear.ch pokriva više nego samo nadogradnje Eth2, ali fokus na Eth2 je velik.",
  "page-eth2-get-involved-meta-description": "Kako sudjelovati u Eth2: pokrenuti čvorove, uložiti, loviti pogreške i još mnogo toga.",
  "page-eth2-get-involved-more": "Više informacija",
  "page-eth2-get-involved-run-clients": "Pokretanje klijenata Beacon Chain",
  "page-eth2-get-involved-run-clients-desc": "Ključ dugoročne sigurnosti Ethereuma je snažna raspodjela klijenata. Klijent je softver koji pokreće lanac blokova, provjerava transakcije i stvara nove blokove. Svaki klijent ima svoje osobine, pa odaberite svoj na temelju onoga što vam odgovara.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Uložite svoj ETH",
  "page-eth2-get-involved-stake-desc": "Sada možete uložiti svoj ETH da biste pomogli osigurati Beacon Chain.",
  "page-eth2-get-involved-stake-eth": "Ulaganje ETH",
  "page-eth2-get-involved-subtitle": "Evo svih načina na koje možete pomoći u vezi s Ethereumom i budućim naporima vezanim uz Eth2.",
  "page-eth2-get-involved-title-1": "Pokrenite klijent",
  "page-eth2-get-involved-title-2": "Uložite svoj ETH",
  "page-eth2-get-involved-title-3": "Pronađite pogreške",
  "page-eth2-get-involved-written-go": "Napisano u Go",
  "page-eth2-get-involved-written-java": "Napisano u Javi",
  "page-eth2-get-involved-written-javascript": "Napisano u JavaScript-u",
  "page-eth2-get-involved-written-net": "Napisano u .NET",
  "page-eth2-get-involved-written-nim": "Napisano u Nim-u",
  "page-eth2-get-involved-written-python": "Napisano u Python-u",
  "page-eth2-get-involved-written-rust": "Napisano u Rust-u",
  "eth2-client-cortex-logo-alt": "Cortex logo",
  "eth2-client-lighthouse-logo-alt": "Lighthouse logo",
  "eth2-client-lodestar-logo-alt": "Lodestar logo",
  "eth2-client-nimbus-logo-alt": "Nimbus logo",
  "eth2-client-prysm-logo-alt": "Prysm logo",
  "eth2-client-teku-logo-alt": "Teku logo",
  "eth2-rhino-img-alt": "Grafika nasrtanja Eth 2 nosoroga",
  "page-eth2-answer-1": "Zamislite Eth2 kao skup nadogradnji koje se dodaju za poboljšanje Ethereuma koji danas koristimo. Te nadogradnje uključuju stvaranje novog lanca nazvanog Beacon Chain i do 64 lanca poznatih kao djelići.",
  "page-eth2-answer-2": "Oni su odvojeni od glavne mreže Ethereum kojom se danas koristimo, ali je neće zamijeniti. Umjesto toga, glavna mreža spojit će se ili sjediniti s ovim paralelnim sustavom koji se dodaje s vremenom.",
  "page-eth2-answer-4": "Drugim riječima, Ethereum kojim se danas koristimo na kraju će utjeloviti sve značajke na koje ciljamo u viziji Eth2.",
  "page-eth2-beacon-chain-btn": "Više o nadogradnji Beacon Chain",
  "page-eth2-beacon-chain-date": "Beacon Chain pušten je u rad 1. prosinca 2020.",
  "page-eth2-beacon-chain-desc": "Prvi Eth2 dodatak ekosustavu. Beacon Chain donosi ulog u Ethereum, postavlja temelje za buduće nadogradnje i na kraju će koordinirati novi sustav.",
  "page-eth2-beacon-chain-estimate": "Beacon Chain je aktiviran",
  "page-eth2-beacon-chain-title": "Beacon Chain",
  "page-eth2-bug-bounty": "Pogledajte program za nagrađivanje lova na pogreške",
  "page-eth2-clients": "Pogledajte klijente Eth2",
  "page-eth2-deposit-contract-title": "Provjerite adresu ugovora o pologu",
  "page-eth2-diagram-ethereum-mainnet": "Glavna mreža Ethereum",
  "page-eth2-diagram-h2": "Kako se nadogradnje slažu",
  "page-eth2-diagram-link-1": "Više o Proof of Work-u",
  "page-eth2-diagram-link-2": "Više o lancima djelića",
  "page-eth2-diagram-mainnet": "Glavna mreža",
  "page-eth2-diagram-p": "Glavna mrežaEthereum još će neko vrijeme postojati u svom sadašnjem obliku. To znači da nadogradnje Beacon Chain i djelići neće poremetiti mrežu.",
  "page-eth2-diagram-p-1": "Glavna mreža na kraju će se sjediniti s novim sustavom koji su uvele nadogradnje Eth2.",
  "page-eth2-diagram-p-2": "Beacon Chain postat će dirigent Ethereuma, koordinirajući validatore i postavljajući tempo stvaranja blokova.",
  "page-eth2-diagram-p-3": "Isprva će postojati odvojeno od glavne mreže i upravljati validatorima – neće imati nikakve veze s pametnim ugovorima, transakcijama ili računima.",
  "page-eth2-diagram-p-4": "Djelići će pružiti puno dodatnih podataka kako bi se povećala količina transakcija koje glavna mreža može podnijeti. Njima će koordinirati Beacon Chain.",
  "page-eth2-diagram-p-5": "No, sve će se transakcije i dalje oslanjati na glavnu mrežu koja će i dalje postojati takva kakvu danas poznajemo – osigurava je proof-of-work i rudari.",
  "page-eth2-diagram-p-6": "Glavna mreža spojit će se sa sustavom proof-of-stake, koji koordinira Beacon Chain.",
  "page-eth2-diagram-p-8": "To će glavnu matičnu mrežu u djelić unutar novog sustava. Rudari više neće biti potrebni jer će cijeli Ethereum biti osiguran validatorima.",
  "page-eth2-diagram-p10": "Eth2 nije migracija ili jedna stvar. Opisuje ga niz nadogradnji na kojima se radi kako bi se otkrio istinski potencijal Ethereuma. Na ovaj način se sve uklapa.",
  "page-eth2-diagram-scroll": "Pregledavajte za istraživanje – dodirnite za više informacija.",
  "page-eth2-diagram-shard": "Djelić (1)",
  "page-eth2-diagram-shard-1": "Djelić (...)",
  "page-eth2-diagram-shard-2": "Djelić (2)",
  "page-eth2-diagram-shard-3": "Djelić (...)",
  "page-eth2-diagram-validators": "Više o validatorima",
  "page-eth2-dive": "Prepustite se viziji",
  "page-eth2-dive-desc": "Kako ćemo Ethereum učiniti skalabilnijim, sigurnijim i održivijim? Sve uz zadržavanje osnovnog etosa Ethereuma u decentralizaciji.",
  "page-eth2-docking": "Spajanje",
  "page-eth2-docking-answer-1": "Spajanje je kada se glavna mreža pretvori u djelić. To će uslijediti nakon uspješne implementacije lanaca djelića.",
  "page-eth2-docking-btn": "Više o spajanju",
  "page-eth2-docking-desc": "Glavna mreža Ethereum trebat će se kad-tad spojiti ili sjediniti s nadogradnjom Beacon Chain. To će omogućiti ulaganje za cijelu mrežu i signalizirati kraj energetski intenzivnog rudarstva.",
  "page-eth2-docking-estimate": "Procjena: 2022.",
  "page-eth2-docking-mainnet": "Što je glavna mreža?",
  "page-eth2-docking-mainnet-eth2": "Spajanje glavne mreže s Eth2",
  "page-eth2-eth-blog": "blog ethereum.org ",
  "page-eth2-explore-btn": "Istražite nadogradnje",
  "page-eth2-get-involved": "Uključite se u Eth2",
  "page-eth2-get-involved-2": "Uključi se",
  "page-eth2-head-to": "Krenite prema",
  "page-eth2-help": "Želite li pomoći s Eth2?",
  "page-eth2-help-desc": "Puno je prilika za vrednovanje nadgradnji Eth2, pomoć u testiranju, pa čak i zarađivanje nagrada.",
  "page-eth2-index-staking": "Ulaganje je ovdje",
  "page-eth2-index-staking-desc": "Ključ nadogradnje Eth2 je uvođenje uloga. Ako svoj ETH želite upotrebljavati za zaštitu mreže Ethereum, slijedite ove korake.",
  "page-eth2-index-staking-learn": "Saznajte više o ulaganju",
  "page-eth2-index-staking-learn-desc": "Beacon Chain donijet će ulaganje u Ethereum. To znači da ako imate ETH, možete učiniti javno dobro osiguravajući mrežu i pritom zaraditi više ETH-a.",
  "page-eth2-index-staking-step-1": "1. Postavljanje pomoću lansirne rampe",
  "page-eth2-index-staking-step-1-btn": "Posjetite lansirnu rampu za ulaganje",
  "page-eth2-index-staking-step-1-desc": "Da biste uložili u Eth2, morat ćete upotrijebiti lansirnu rampu – ovo će vas provesti kroz postupak.",
  "page-eth2-index-staking-step-2": "2. Potvrdite adresu ulaganja",
  "page-eth2-index-staking-step-2-btn": "Potvrdite adresu ugovora o pologu",
  "page-eth2-index-staking-step-2-desc": "Prije nego što uložite svoj ETH, provjerite jeste li dobili pravu adresu. Morali ste proći kroz lansirnu rampu prije nego što ste to učinili.",
  "page-eth2-index-staking-sustainability": "Održivije",
  "page-eth2-just-docking": "Više o spajanju",
  "page-eth2-meta-desc": "Pregled nadogradnji Ethereuma 2.0 i vizija za koju se nadaju da će postati stvarnost.",
  "page-eth2-meta-title": "Nadogradnje Eth2",
  "page-eth2-miners": "Više o rudarima",
  "page-eth2-more-on-upgrades": "Više o nadogradnjama Eth2",
  "page-eth2-proof-of-stake": "Proof-of-stake",
  "page-eth2-proof-of-work": "Proof-of-work",
  "page-eth2-proof-stake-link": "Više o načinu proof-of-stake",
  "page-eth2-question-1-desc": "Eth2 je skup različitih nadogradnji s različitim datumima objave.",
  "page-eth2-question-1-title": "Kada će se Eth2 isporučiti?",
  "page-eth2-question-2-desc": "Nije precizno razmišljati o Eth2 kao o zasebnom lancu blokova.",
  "page-eth2-question-2-title": "Je li Eth2 zasebni lanac blokova?",
  "page-eth2-question-3-answer-2": "Nadogradnje lanca djelića i spajanja mogu utjecati na programere dapp-ova. No, specifikacije još nisu dovršene, tako da nije potrebno poduzimanje hitnih akcija.",
  "page-eth2-question-3-answer-3": "Razgovarajte s timom za istraživanje i razvoj Eth2 na adresi ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Posjetite ethresear.ch",
  "page-eth2-question-3-desc": "Trenutno ne morate ništa učiniti da biste se pripremili za Eth2.",
  "page-eth2-question-3-title": "Kako se pripremiti za Eth2?",
  "page-eth2-question-4-answer-1": "Kad god pošaljete transakciju ili upotrijebite dapp danas, upotrebljavate Eth1. To je Ethereum koji osiguravaju rudari.",
  "page-eth2-question-4-answer-2": "Eth1 ili glavna mreža nastavit će raditi normalno dok se ne spoji.",
  "page-eth2-question-4-answer-3": "Nakon sjedinjenja, validatori će osigurati cijelu mrežu provjerom uloga.",
  "page-eth2-question-4-answer-6": "Svatko može postati validator ulaganjem svog ETH-a.",
  "page-eth2-question-4-answer-7": "Više o ulaganju",
  "page-eth2-question-4-answer-8": "Nadogradnje Beacon Chain i lanac djelića neće poremetiti Eth1 pošto se grade zasebno.",
  "page-eth2-question-4-desc": "Eth1 se odnosi na glavnu mrežu Ethereuma kojom danas obavljate transakcije.",
  "page-eth2-question-4-title": "Što je Eth1?",
  "page-eth2-question-5-answer-1": "Da biste postali cjeloviti validator mreže, morat ćete uložiti 32 ETH-a. Ako nemate toliko, ili niste spremni toliko uložiti, možete se pridružiti skupinama za ulaganje. Te će vam skupine omogućiti da uložite manje i zaradite djeliće ukupnih nagrada.",
  "page-eth2-question-5-desc": "Morat ćete upotrijebiti lansirnu rampu za ulaganje ili se pridružiti skupini za ulaganje.",
  "page-eth2-question-5-title": "Kako uložiti?",
  "page-eth2-question-6-answer-1": "Za sada ništa ne treba činiti. No, preporučujemo vam da budete u tijeku s razvojem lanaca djelića i nadogradnjom sustava spajanja.",
  "page-eth2-question-6-answer-3": "Danny Ryan iz zaklade Ethereum redovito ažurira zajednicu:",
  "page-eth2-question-6-answer-4": "Ben Edgington iz ConsenSysa ima tjedni bilten Eth2:",
  "page-eth2-question-6-answer-5": "Također se možete pridružiti raspravi o istraživanju i razvoju Eth2 na ethresear.ch.",
  "page-eth2-question-6-desc": "Neizbježne nadogradnje neće utjecati na vaš dapp. Međutim, buduće nadogradnje mogu zahtijevati neke promjene.",
  "page-eth2-question-6-title": "Što trebam učiniti sa svojim dappom?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(.NET implementacija)",
  "page-eth2-question-7-desc": "Mnogo različitih timova iz cijele zajednice rade na raznim nadogradnjama Eth2.",
  "page-eth2-question-7-lighthouse": "Svjetionik",
  "page-eth2-question-7-lighthouse-lang": "(Rust implementacija)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(JavaScript implementacija)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Nim implementacija)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Go implementacija)",
  "page-eth2-question-7-teams": "Timovi Eth2 klijenta:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Java implementacija)",
  "page-eth2-question-7-title": "Tko gradi Eth2?",
  "page-eth2-question-7-trinity": "Trinity",
  "page-eth2-question-7-trinity-lang": "(Python implementacija)",
  "page-eth2-question-8-answer-1": "Nadogradnje Eth2 pomoći će Ethereumu da se skalira na decentraliziran način, uz održavanje sigurnosti i povećanje održivosti.",
  "page-eth2-question-8-answer-2": "Možda je najočitiji problem taj što Ethereum mora biti u mogućnosti obrađivati više od 15 – 45 transakcija u sekundi. No, nadogradnje Eth2 također rješavaju neke druge probleme s Ethereumom danas.",
  "page-eth2-question-8-answer-3": "Mreža je toliko tražena da Ethereum postaje skup za upotrebu. Čvorovi u mreži bore se s veličinom Ethereuma i količinom podataka koja njihova računala moraju obraditi. A osnovni algoritam koji Ethereum održava sigurnim i decentraliziranim je energetski intenzivan i mora biti zeleniji.",
  "page-eth2-question-8-answer-4": "Mnogo toga što se mijenja uvijek je bilo u planu Ethereuma, čak i od 2015. No trenutni uvjeti čine potrebu za nadogradnjom još većom.",
  "page-eth2-question-8-answer-6": "Istražite viziju Eth2",
  "page-eth2-question-8-desc": "Ethereum koji danas upotrebljavamo mora ponuditi bolje iskustvo krajnjim korisnicima i sudionicima mreže.",
  "page-eth2-question-8-title": "Zašto je potreban Eth2?",
  "page-eth2-question-9-answer-1": "Najaktivnija uloga koju možete igrati je da uložite svoj ETH.",
  "page-eth2-question-9-answer-2": "Možda ćete htjeti pokrenuti i drugog klijenta kako biste pomogli poboljšati različitost klijenta.",
  "page-eth2-question-9-answer-3": "Ako ste tehničar, možete pomoći u hvatanju pogrešaka kod novih klijenata.",
  "page-eth2-question-9-answer-4": "Također možete vrednovati tehničke rasprave s istraživačima Ethereuma na ethresear.ch.",
  "page-eth2-question-9-desc": "Ne morate biti tehnički potkovani za doprinos. Zajednica traži doprinose iz različitih vještina.",
  "page-eth2-question-9-stake-eth": "Ulaganje ETH",
  "page-eth2-question-9-title": "Kako mogu pridonjeti Eth2-u?",
  "page-eth2-question-10-answer-1": "Nerado previše pričamo u smislu tehničkog plana, jer ovo jesoftver: stvari se mogu promijeniti. Mislimo da je lakše razumjeti što se događa kada čitate o ishodima.",
  "page-eth2-question-10-answer-1-link": "Pogledajte nadogradnje",
  "page-eth2-question-10-answer-2": "Ali ako ste pratili rasprave, evo kako se nadogradnje uklapaju u tehničke planove.",
  "page-eth2-question-10-answer-3": "Faza 0 opisuje rad na pokretanju nadogradnje Beacon Chain.",
  "page-eth2-question-10-answer-5": "Faza 1 tehničkih planova usredotočena je na primjenu lanaca djelića.",
  "page-eth2-question-10-answer-6": "Spajanje glavne mreže na Eth2 nastavak je obavljenog posla na implementaciji lanaca djelića i naziva se Faza 1.5. Ali to je značajan trenutak jer se Ethereum koji danas poznajemo spaja s ostalim nadogradnjama Eth2. Osim toga, tada Ethereum u potpunosti prelazi na proof-of-stake.",
  "page-eth2-question-10-answer-7": "Trenutno nije jasno što će se dogoditi oko faze 2. To je još uvijek točka intenzivnih istraživanja i rasprava. Početni plan bio je dodati dodatnu funkcionalnost lancima djelića, ali to možda neće biti potrebno s planom usmjerenim na tehnologiju rollup.",
  "page-eth2-question-10-answer-8": "Više o planovima usredotočenim na tehnologiju rollup",
  "page-eth2-question-10-desc": "Faze se odnose na faze rada i fokus na tehničkom planu Eth2.",
  "page-eth2-question-10-title": "Koje su Eth2 faze?",
  "page-eth2-question-title": "Često postavljana pitanja",
  "page-eth2-question3-answer-1": "Vlasnici Eth-a ne trebaju ništa raditi. Vaš ETH neće trebati mijenjati ili nadograđivati. Gotovo sigurno postojat će prevare koje vam govore suprotno, zato budite oprezni.",
  "page-eth2-scalable": "Skalabilnije",
  "page-eth2-scalable-desc": "Ethereum mora podržati 1000 transakcija u sekundi kako bi upotreba aplikacija bila brža i jeftinija.",
  "page-eth2-secure": "Sigurnije",
  "page-eth2-secure-desc": "Ethereum mora biti sigurniji. Kako usvajanje Ethereuma raste, protokol mora postati siguran pred svim oblicima napada.",
  "page-eth2-shard-button": "Više o lancima djelića",
  "page-eth2-shard-date": "Uvođenje lanaca djelića, druga nadogradnja, očekuje se u neko doba 2021. godine.",
  "page-eth2-shard-desc": "Lanci djelića proširit će sposobnost Ethereuma za obradu transakcija i pohranu podataka. Krhotine će s vremenom dobiti sve više karakteristika, objavljenih u više faza.",
  "page-eth2-shard-estimate": "Procjena: 2021",
  "page-eth2-shard-lower": "Više o lancima djelića",
  "page-eth2-shard-title": "Lanci djelića",
  "page-eth2-stay-up-to-date": "Budite u tijeku",
  "page-eth2-stay-up-to-date-desc": "Doznajte najnovije od istraživača i programera koji rade na nadogradnji Eth2.",
  "page-eth2-sustainable-desc": "Ethereum mora biti bolji za okoliš. Današnja tehnologija zahtijeva previše računarske snage i energije.",
  "page-eth2-take-part": "Sudjelujte u istraživanju",
  "page-eth2-take-part-desc": "Istraživači i entuzijasti Ethereuma ovdje se sastaju kako bi razgovarali o istraživačkim naporima, uključujući sve vezano za Eth2.",
  "page-eth2-the-upgrades": "Nadogradnje Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 je skup nadogradnji koje poboljšavaju skalabilnost, sigurnost i održivost Ethereuma. Iako se na svakom od njih radi paralelno, oni imaju određene ovisnosti koje određuju kada će biti objavljene.",
  "page-eth2-unofficial-roadmap": "Ovo nije službeni plan. Ovako gledamo na ono što se događa na temelju informacija koje postoje. Ali ovo je tehnologija, stvari se u trenutku mogu promijeniti. Stoga, molim vas, nemojte ovo čitati kao obećanje.",
  "page-eth2-upgrade-desc": "Ethereum koji poznajemo i volimo, samo skalabilniji, sigurniji i održiviji...",
  "page-eth2-upgrades": "Nadogradnje Eth2",
  "page-eth2-upgrades-aria-label": "Izbornik Eth2 nadogradnji",
  "page-eth2-upgrades-beacon-chain": "Beacon Chain",
  "page-eth2-upgrades-docking": "Spajanje glavne mreže s Eth2",
  "page-eth2-upgrades-guide": "Vodič za nadogradnje Eth2",
  "page-eth2-upgrades-shard-chains": "Lanci djelića",
  "page-eth2-upgrading": "Nadogradnja Ethereuma na radikalno nove nivoe",
  "page-eth2-vision": "Vizija",
  "page-eth2-vision-btn": "Više o viziji Eth2",
  "page-eth2-vision-desc": "Da bismo Ethereum uveli u mainstream i služili cijelom čovječanstvu, moramo Ethereum učiniti skalabilnijim, sigurnijim i održivijim.",
  "page-eth2-vision-upper": "Vizija Eth2",
  "page-eth2-what-to-do": "Što trebate učiniti?",
  "page-eth2-what-to-do-desc": "Ako ste korisnik dapp-a ili vlasnik ETHa, ne trebate ništa raditi. Ako ste programer ili želite početi ulagati, postoje načini na koje se danas možete uključiti.",
  "page-eth2-whats-eth2": "Što je Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 se odnosi na skup međusobno povezanih nadogradnji koje će Ethereum učiniti skalabilnijim, sigurnijim i održivijim. Ove nadogradnje gradi više timova iz cijelog ekosustava Ethereum.",
  "page-eth2-whats-ethereum": "Čekaj, što je Ethereum?",
  "page-eth2-whats-new": "Što je novo u Eth2?",
  "page-eth2-just-staking": "Ulaganje",
  "page-eth2-staking-image-alt": "Slika maskote nosoroga za lansirnu rampu eth2.",
  "page-eth2-staking-51-desc-1": "Ulaganje čini pristupanje mreži u ulozi validatora pristupačnijim, tako da će vjerojatno u mreži biti više validatora nego što postoji danas. To će ovu vrstu napada učiniti još težim jer će troškovi napada rasti.",
  "page-eth2-staking-accessibility": "Pristupačnije",
  "page-eth2-staking-accessibility-desc": "Uz lakše hardverske zahtjeve i mogućnost udruživanja ukoliko nemate 32ETH, više će se ljudi moći pridružiti mreži. To će Ethereum učiniti decentraliziranijim i sigurnijim putem smanjenja površine napada.",
  "page-eth2-staking-at-stake": "Ulog je vaš ETH",
  "page-eth2-staking-at-stake-desc": "Budući da svoj ETH morate uložiti da biste potvrdili transakcije i stvorili nove blokove, možete ga izgubiti ako pokušate prevariti sustav.",
  "page-eth2-staking-benefits": "Prednosti ulaganja u Ethereum",
  "page-eth2-staking-check-address": "Provjerite adresu za polog",
  "page-eth2-staking-consensus": "Više o mehanizmima konsenzusa",
  "page-eth2-staking-deposit-address": "Provjerite adresu pologa",
  "page-eth2-staking-deposit-address-desc": "Ako ste već slijedili upute za postavljanje na lansirnoj rampi, znat ćete da trebate poslati transakciju na ugovor o depozitu. Preporučujemo da adresu pažljivo provjerite. Službenu adresu možete pronaći na ethereum.org i brojnim drugim pouzdanim stranicama.",
  "page-eth2-staking-desc-1": "Nagrade se dodjeljuju za radnje koje pomažu mreži da postigne konsenzus. Dobit ćete nagrade za grupiranje transakcija u novi blok ili provjeru rada drugih validatora, jer to održava lanac sigurnim.",
  "page-eth2-staking-desc-2": "Iako možete zaraditi nagrade za obavljanje poslova koji donose korist mreži, možete iizgubiti ETH za zlonamjerne radnje, ispad sa mreže i neuspjeh u potvrdi.",
  "page-eth2-staking-desc-3": "Trebat će vam 32 ETH da biste postali cjeloviti validator ili nekoliko ETH da biste se pridružili grupi za ulaganje. Također ćete morati pokrenuti klijent 'Eth1' ili glavnu mrežu. Lansirna ploča vodit će vas kroz procesne i hardverske zahtjeve. Možete upotrijebiti i pozadinski API.",
  "page-eth2-staking-description": "Ulaganje je čin polaganja 32 ETH za aktiviranje softvera za provjeru valjanosti. Kao validator bit ćete odgovorni za spremanje podataka, obradu transakcija i dodavanje novih blokova u lanac blokova. To će Ethereum održati sigurnim za sve i pritom ćete zaraditi novi ETH. Ovaj postupak, poznat kao dokaz o udjelu, uvodi Beacon Chain.",
  "page-eth2-staking-docked": "Više o spajanju",
  "page-eth2-staking-dyor": "Samostalno istražite",
  "page-eth2-staking-dyor-desc": "Nijedna od navedenih usluga učešća nije službena preporuka. Obavezno istražite kako biste utvrdili koja bi uloga mogla biti najbolja za vas.",
  "page-eth2-staking-header-1": "Uložite svoje ETH da biste postali Ethereumov validator",
  "page-eth2-staking-how-much": "Koliko ste spremni uložiti?",
  "page-eth2-staking-how-to-stake": "Kako uložiti",
  "page-eth2-staking-how-to-stake-desc": "Sve ovisi o tome koliko ste spremni uložiti. Trebat će vam 32 da biste postali cjeloviti validator, ali moguće je uložiti i manje.",
  "page-eth2-staking-join": "Pridružite se",
  "page-eth2-staking-join-community": "Pridružite se zajednici ulagača",
  "page-eth2-staking-join-community-desc": "r/ethstaker je zajednica za sve koji razgovaraju o ulozima na Ethereum – pridružite se za savjet, podršku i razgovarajte o svim stvarima.",
  "page-eth2-staking-less-than": "Manje od",
  "page-eth2-staking-link-1": "Pogledajte pozadinske API-je",
  "page-eth2-staking-meta-description": "Pregled ulaganja u Ethereum: rizici, nagrade, zahtjevi i gdje to učiniti.",
  "page-eth2-staking-meta-title": "Ethereum ulaganje",
  "page-eth2-staking-more-sharding": "Više o dijeljenju",
  "page-eth2-staking-pool": "Ulog u skupinu",
  "page-eth2-staking-pool-desc": "Ako imate manje od 32 ETH, moći ćete dodati manji ulog u skupinu za ulog. Neke tvrtke mogu sve učiniti u vaše ime, tako da nećete morati brinuti o ostajanju na mreži. Evo nekoliko tvrtki koje treba provjeriti.",
  "page-eth2-staking-pos-explained": "Objašnjenje načina proof-of-stake",
  "page-eth2-staking-pos-explained-desc": "Ulog je ono što trebate učiniti da biste postali validator u sustavu proof-of-stake. Ovo je konsenzusni mehanizam koji će zamijeniti trenutno uspostavljeni sustav proof-of-work. Konsenzusni mehanizmi su ono što lanac blokova poput Ethereuma održava sigurnima i decentraliziranima.",
  "page-eth2-staking-pos-explained-desc-1": "Proof-of-stake pomaže u zaštiti mreže na više načina:",
  "page-eth2-staking-services": "Pogledajte usluge ulaganja",
  "page-eth2-staking-sharding": "Otključavanje dijeljenja",
  "page-eth2-staking-sharding-desc": "Dijeljenje je moguće samo sustavom proof-of-stake. Dijeljenje sustava proof-of-work razrijedilo bi količinu računalne snage potrebne za dijeljenje mreže, što bi zlonamjernim rudarima olakšalo kontrolu nad djelićima. To nije slučaj sa slučajno dodijeljenim ulagačima u proof-of-stake.",
  "page-eth2-staking-solo": "Uložite sami i pokrenite validator",
  "page-eth2-staking-solo-desc": "Da biste započeli postupak ulaganja, morat ćete upotrijebiti lansirnu rampu Eth2. Ovo će vas voditi kroz sve postavke. Dio ulaganja je pokretanje klijenta Eth2, koji je lokalna kopija lanca blokova. Prijenos na računalo može potrajati.",
  "page-eth2-staking-start": "Počnite ulagati",
  "page-eth2-staking-subtitle": "Ulaganje je javno dobro za ekosustav Ethereum. Možete pomoći u zaštiti mreže i u tom postupku zaraditi nagrade.",
  "page-eth2-staking-sustainability": "Održivije",
  "page-eth2-staking-sustainability-desc": "Validatorima nisu potrebna energetski rastrošna računala da bi sudjelovali u sustavu provjere uloga – samo prijenosno računalo ili pametni telefon. To će Ethereum učiniti boljim za okoliš.",
  "page-eth2-staking-the-beacon-chain": "Više o nadogradnji Beacon Chain",
  "page-eth2-staking-title-1": "Nagrade",
  "page-eth2-staking-title-2": "Rizik",
  "page-eth2-staking-title-3": "Zahtjevi",
  "page-eth2-staking-title-4": "Kako uložiti svoje ETH",
  "page-eth2-staking-upgrades-li": "Beacon Chain upravlja sustavom proof-of-stake.",
  "page-eth2-staking-upgrades-li-2": "Ethereum će u dogledno vrijeme imati Beacon Chain i glavnu mrežu proof-of-stake. Glavna mreža je Ethereum koji koristimo godinama.",
  "page-eth2-staking-upgrades-li-3": "Za to vrijeme ulagači će dodavati nove blokove u Beacon Chain, ali neće obrađivati transakcije glavne mreže.",
  "page-eth2-staking-upgrades-li-4": "Ethereum će u potpunosti prijeći na sustav proof-of-stake kada glavna mreža Ethereuma postane djelić.",
  "page-eth2-staking-upgrades-li-5": "Tek tada možete povući svoj ulog.",
  "page-eth2-staking-upgrades-title": "Nadogradnje proof-of-stake i Eth2 ",
  "page-eth2-staking-validators": "Više validatora, više sigurnosti",
  "page-eth2-staking-validators-desc": "Lanac blokova kao što je Ethereum moguće je korumpirati ako kontrolirate 51 % mreže. Na primjer, mogli biste dobiti 51 % validatora da izjavi da vaše stanje glasi 1.000.000 ETH, a ne 1 ETH. Ali, da biste kontrolirali 51 % validatora, trebali biste posjedovati 51 % ETH-a u sustvu – a to je puno!",
  "page-eth2-staking-withdrawals": "Povlačenja se neće odmah objaviti",
  "page-eth2-staking-withdrawals-desc": "Nećete moći povući svoj ulog dok se ne izvrše buduće nadogradnje. Isplata novca trebala bi biti dostupna nakon što se glavna mreža spoji s sustavom Beacon Chain.",
  "page-eth2-vision-2014": "Pogledajte post na blogu iz 2014. godine s detaljima dokaza o proof-of-stake",
  "page-eth2-vision-beacon-chain": "Beacon Chain",
  "page-eth2-vision-beacon-chain-btn": "Više o nadogradnji Beacon Chain",
  "page-eth2-vision-beacon-chain-date": "Beacon lanac \"Beacon Chain\" je aktiviran",
  "page-eth2-vision-beacon-chain-desc": "Prvi Eth2 dodatak ekosustavu. Beacon Chain donosi ulog u Ethereum, postavlja temelje za buduće nadogradnje i na kraju će koordinirati novi sustav.",
  "page-eth2-vision-beacon-chain-upper": "Beacon Chain",
  "page-eth2-vision-desc-1": "Ethereum treba smanjiti zagušenje mreže i poboljšati brzine kako bi bolje opsluživao globalnu bazu korisnika.",
  "page-eth2-vision-desc-2": "Pokretanje čvora postaje sve teže kako mreža raste. To će postati teže samo s naporima na skaliranju mreže.",
  "page-eth2-vision-desc-3": "Ethereum koristi previše električne energije. Tehnologija koja mrežu održava sigurnom mora biti održivija.",
  "page-eth2-vision-docking-date": "Procjena: 2022.",
  "page-eth2-vision-docking-desc": "Glavna mreža Ethereum trebat će se kad-tad spojiti s nadogradnjom Beacon Chain. To će omogućiti ulaganje za cijelu mrežu i signalizirati kraj energetski intenzivnog rudarstva.",
  "page-eth2-vision-ethereum-node": "Više o čvorovima",
  "page-eth2-vision-explore-upgrades": "Istražite nadogradnje",
  "page-eth2-vision-future": "Digitalna budućnost na globalnoj razini",
  "page-eth2-vision-meta-desc": "Pregled utjecaja koji će nadogradnje Eth2 imati na Ethereum i izazova koje moraju prevladati.",
  "page-eth2-vision-meta-title": "Vizija Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Više o rudarenju",
  "page-eth2-vision-problems": "Današnji problemi",
  "page-eth2-vision-scalability": "Skalabilnost",
  "page-eth2-vision-scalability-desc": "Ethereum mora biti u mogućnosti obrađivati više transakcija u sekundi bez povećanja veličine čvorova u mreži. Čvorovi su vitalni sudionici mreže koji pohranjuju i pokreću lanac blokova. Povećavanje veličine čvora nije praktično jer bi to mogli učiniti samo oni s moćnim i skupim računalima. Da bi se skalirao, Ethereum treba više transakcija u sekundi, zajedno s više čvorova. Više čvorova znači veću sigurnost.",
  "page-eth2-vision-scalability-desc-3": "Nadogradnja lanaca djelića proširit će opterećenje mreže na 64 nova lanca. To će Ethereumu dati prostor za disanje smanjenjem zagušenja i poboljšanjem brzine iznad trenutnih 15 – 45 transakcija u sekundi.",
  "page-eth2-vision-scalability-desc-4": "Iako će biti više lanaca, ovo će zapravo zahtijevati manje posla od validatora – održavatelja mreže. Validatori će samo trebati pokrenuti svoju djelić, a ne i čitav Ethereum lanac. To čini čvorove lakšima, omogućujući Ethereumu da se skalira i ostane decentraliziran.",
  "page-eth2-vision-security": "Sigurnost",
  "page-eth2-vision-security-desc": "Nadogradnje Eth2 poboljšavaju Ethereumovu sigurnost protiv koordiniranih napada, poput napada od 51 %. Ovo je vrsta napada u kojem ako netko kontrolira većinu mreže može prisilno progurati lažne promjene.",
  "page-eth2-vision-security-desc-3": "Prijelaz na proof-of-stake znači da protokol Ethereum ima veće destimulativne mjere protiv napada. To je zato što u proof-of-stake validatori koji osiguravaju mrežu moraju u protokol uložiti značajne iznose ETH-a. Ako pokušaju napadati mrežu, protokol može automatski uništiti njihov ETH.",
  "page-eth2-vision-security-desc-5": "To nije moguće u proof-of-work, gdje je najbolje što protokol može učiniti prisiliti subjekte koji osiguravaju mrežu (rudare) da izgube rudarske nagrade koje bi inače zaradili. Da bi se postigao ekvivalentan učinak u proof-of-work, protokol bi trebao moći uništiti svu rudarsku opremu ako pokušaju varati.",
  "page-eth2-vision-security-desc-5-link": "Više o načino proof-of-work",
  "page-eth2-vision-security-desc-8": "Ethereumov sigurnosni model također se treba promijeniti zbog uvođenja",
  "page-eth2-vision-security-desc-10": "Ulog također znači da ne trebate ulagati u elitni hardver da biste pokrenuli Ethereumov čvor. To bi trebalo potaknuti više ljudi da postanu validatori, povećavajući decentralizaciju mreže i smanjujući površinu napada.",
  "page-eth2-vision-security-staking": "Ulaganje ETH",
  "page-eth2-vision-security-validator": "Možete postati validator ulaganjem svog ETH-a.",
  "page-eth2-vision-shard-chains": "lanci djelića",
  "page-eth2-vision-shard-date": "Procjena: 2021.",
  "page-eth2-vision-shard-desc-4": "Lanci djelića širit će opterećenje mreže na 64 nova lanca blokova. Djelići mogu drastično poboljšati brzinu transakcija – do 100 000 u sekundi.",
  "page-eth2-vision-shard-upgrade": "Više o lancima djelića",
  "page-eth2-vision-staking-lower": "Više o ulaganju",
  "page-eth2-vision-subtitle": "Gradimo Ethereum dok ne postane dovoljno moćan da pomogne čitavom čovječanstvu.",
  "page-eth2-vision-sustainability": "Održivost",
  "page-eth2-vision-sustainability-desc-1": "Nije tajna da su Ethereum i drugi lanci blokova poput Bitcoina energetski intenzivni zbog rudarstva.",
  "page-eth2-vision-sustainability-desc-2": "Međutim Ethereum ide prema tome da ga osigura ETH, a ne računalna snaga –ulaganjem.",
  "page-eth2-vision-sustainability-desc-3": "Iako će ulog uvesti Beacon Chain, Ethereum kojim se danas koristimo paralelno će raditi neko vrijeme, prije nego što se sjedini ili spoji s nadogradnjom Eth2. Jedan sustav osiguran je sa ETH, drugi računalnom snagom. To je zato što u početku lanci djelića neće moći obrađivati stvari poput naših računa ili dapps-a. Dakle, ne možemo samo zaboraviti na rudarstvo i glavnu mrežu.",
  "page-eth2-vision-sustainability-desc-8": "Nakon što nadograde Beacon Chain i lanci djelića počnu raditi, započet će radovi na spajanju glavne mreže s novim sustavom. To će pretvoriti glavnu mrežu mrežu u djelić, tako da bude osigurana ETH-om i daleko manje energetski intenzivna.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum mora biti zeleniji.",
  "page-eth2-vision-The": " ",
  "page-eth2-vision-title": "Vizija Eth2",
  "page-eth2-vision-title-1": "Zagušena mreža",
  "page-eth2-vision-title-2": "Prostor na disku",
  "page-eth2-vision-title-3": "Previše energije",
  "page-eth2-vision-trilemma-cardtext-1": "Nadogradnje Eth2 učinit će Ethereum skalabilnim, sigurnim i decentraliziranim. Dijeljenje će Ethereum učiniti skalabilnijim povećanjem transakcija u sekundi, a istovremeno će smanjiti snagu potrebnu za pokretanje čvora i validaciju lanca. Beacon Chain učinit će Ethereum sigurnim koordinirajući validatore na različitim djelićima. I smanjiti će se ulog za sudjelovanje, stvarajući veću, decentraliziraniju mrežu.",
  "page-eth2-vision-trilemma-cardtext-2": "Sigurne i decentralizirane mreže lanaca blokova zahtijevaju da svaki čvor provjeri svaku transakciju koju obrađuje lanac. Ta količina posla ograničava broj transakcija koje se mogu dogoditi u bilo kojem trenutku. Decentralizirano i sigurno odražava lanac Ethereum danas.",
  "page-eth2-vision-trilemma-cardtext-3": "Decentralizirane mreže rade slanjem informacija o transakcijama preko čvorova – cijela mreža mora znati o bilo kojoj promjeni stanja. Mijenjanje transakcija u sekundi u decentraliziranoj mreži predstavlja sigurnosni rizik jer što je više transakcija, što je veće kašnjenje, to je veća vjerojatnost napada, pošto su informacije u pokretu.",
  "page-eth2-vision-trilemma-cardtext-4": "Povećavanje veličine i snage Ethereumovih čvorova moglo bi povećati transakcije u sekundi na siguran način, ali hardverski zahtjev ograničio bi tko bi to mogao učiniti – to prijeti decentralizacijom. Nadamo se da će dijeljenje i proof-of-stake omogućiti Ethereumu skaliranje povećanjem količine čvorova, a ne veličine čvorova.",
  "page-eth2-vision-trilemma-h2": "Izazov decentraliziranog skaliranja",
  "page-eth2-vision-trilemma-modal-tip": "Dodirnite donje krugove da biste bolje razumjeli probleme decentraliziranog skaliranja",
  "page-eth2-vision-trilemma-p": "Naivan način rješavanja problema Ethereuma bio bi centraliziranijom. Ali decentralizacija je previše važna. To je decentralizacija koja Ethereumu daje otpornost na cenzuru, otvorenost, privatnost podataka i gotovo neprobojnu sigurnost.",
  "page-eth2-vision-trilemma-p-1": "Vizija Ethereuma je biti skalabilniji i sigurniji, ali i ostati decentraliziran. Postizanje ove 3 kvalitete problem je poznat kao trilema skalabilnosti.",
  "page-eth2-vision-trilemma-p-2": "Cilj nadogradnje Eth2 je riješiti trilemu, međutim postoje značajni izazovi.",
  "page-eth2-vision-trilemma-press-button": "Pritisnite gumbe na trokutu da biste bolje razumjeli probleme decentraliziranog skaliranja.",
  "page-eth2-vision-trilemma-text-1": "Decentralizacija",
  "page-eth2-vision-trilemma-text-2": "Sigurnost",
  "page-eth2-vision-trilemma-text-3": "Skalabilnost",
  "page-eth2-vision-trilemma-title-1": "Istražite trilemu skalabilnosti",
  "page-eth2-vision-trilemma-title-2": "Nadogradnje Eth2 i decentralizirano skaliranje",
  "page-eth2-vision-trilemma-title-3": "Sigurno i decentralizirano",
  "page-eth2-vision-trilemma-title-4": "Decentralizirano i skalabilno",
  "page-eth2-vision-trilemma-title-5": "Skalabilno i sigurno",
  "page-eth2-vision-understanding": "Razumijevanje vizije Eth2",
  "page-eth2-vision-upgrade-needs": "Potreba za nadogradnjom Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Protokol Ethereum koji je pokrenut 2015. godine postigao je nevjerojatan uspjeh. Ali Ethereumova zajednica uvijek je očekivala da će biti potrebno nekoliko ključnih nadogradnji kako bi se otkrio puni potencijal Ethereuma.",
  "page-eth2-vision-upgrade-needs-desc-2": "Velika potražnja potiče naknade za transakcije koje čine Ethereum skupim za prosječnog korisnika. Prostor na disku potreban za pokretanje klijenta Ethereum brzo raste. A temeljni algoritam konsenzusa proof-of-work koji Ethereum održava sigurnim i decentraliziranim ima velik utjecaj na okoliš.",
  "page-eth2-vision-upgrade-needs-desc-3": "Ono što se obično povezuje uz Eth2 je niz nadogradnji koje rješavaju ove probleme i još više. Ovaj skup nadogradnji izvorno se zvao „Serenity”, a aktivno su područje istraživanja i razvoja od 2014. godine.",
  "page-eth2-vision-upgrade-needs-desc-6": " To znači da ne postoji prekidač za Eth2. Poboljšanja će se vremenom postupno isporučivati.",
  "page-eth2-vision-upgrade-needs-serenity": "Pogledajte post na blogu iz 2015. godine o „Serenity”",
  "page-eth2-vision-uprade-needs-desc-5": "Sad kad je tehnologija spremna, ove će nadogradnje rekonstruirati Ethereum kako bi ga učinili skalabilnijim, sigurnijim i održivijim – kako bi poboljšali život postojećih korisnika i privukli nove. Sve uz očuvanje Ethereumove osnovne vrijednosti decentralizacije.",
  "page-get-eth-cex": "Centralizirane mjenjačnice",
  "page-get-eth-cex-desc": "Mjenjačnice su tvrtke koje vam omogućavaju da kupujete kripto koristeći tradicionalne valute. Imaju skrbništvo nad bilo kojim ETH-om koji kupite dok ga ne pošaljete u novčanik koji kontrolirate.",
  "page-get-eth-checkout-dapps-btn": "Pogledajte dapps",
  "page-get-eth-community-safety": "Objave zajednice o sigurnosti",
  "page-get-eth-description": "Ethereum i ETH ne kontrolira nijedna vlada ili tvrtka – oni su decentralizirani. To znači da je ETH svima otvoren za upotrebu.",
  "page-get-eth-dex": "Decentralizirane mjenjačnice (DEX)",
  "page-get-eth-dex-desc": "Ako želite veću kontrolu, kupite ETH od nekog ravnopravnog. S DEX-om možete trgovati bez davanja kontrole nad svojim sredstvima centraliziranoj tvrtki.",
  "page-get-eth-dexs": "Decentralizirane mjenjačnice (DEX)",
  "page-get-eth-dexs-desc": "Decentralizirane mjenjačnice otvoreno su tržište za ETH i druge tokene. One izravno povezuju kupce i prodavače.",
  "page-get-eth-dexs-desc-2": "Umjesto da se koriste pouzdanom trećom stranom za zaštitu sredstava u transakciji, oni se koriste kôdom. ETH prodavatelja prenosit će se samo kada je plaćanje zajamčeno. Ta vrsta koda poznata je kao pametni ugovor.",
  "page-get-eth-dexs-desc-3": "To znači da je manje geografskih ograničenja nego kod centraliziranih alternativa. Ako netko prodaje ono što želite i prihvaća način plaćanja koji pružate, možete to obaviti. DEX vam omogućuje kupnju ETH-a drugim tokenima, PayPalom ili čak osobnom dostavom gotovine.",
  "page-get-eth-do-not-copy": "PRIMJER: NEMOJTE KOPIRATI",
  "page-get-eth-exchanges-disclaimer": "Ove smo podatke prikupili ručno. Ako primijetite nešto pogrešno, javite nam na",
  "page-get-eth-exchanges-empty-state-text": "Unesite državu prebivališta da biste vidjeli popis novčanika i mjenjačnica pomoću kojih možete kupiti ETH",
  "page-get-eth-exchanges-except": "Osim",
  "page-get-eth-exchanges-header": "U kojoj državi živite?",
  "page-get-eth-exchanges-header-exchanges": "Mjenjačnice",
  "page-get-eth-exchanges-header-wallets": "Novčanici",
  "page-get-eth-exchanges-intro": "Mjenjačnice i novčanici imaju ograničenja gdje mogu prodavati kripto.",
  "page-get-eth-exchanges-no-exchanges": "Žao nam je, ne znamo nijednu mjenjačnicu koja vam omogućava kupnju ETH iz ove zemlje. Ako vi znate, javite nam na",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Nažalost, ne znamo nijednu mjenjačnicu ili novčanik koji bi vam omogućili kupnju ETH iz ove zemlje. Ako vama uspije, javite nam na",
  "page-get-eth-exchanges-no-wallets": "Žao nam je, ne znamo nijedan novčanik koji bi vam omogućio da kupite ETH iz ove zemlje. Ako to uspijete, javite nam na",
  "page-get-eth-exchanges-success-exchange": "Može potrajati nekoliko dana da se registrirate u mjenjačnici zbog njihovih zakonskih provjera.",
  "page-get-eth-exchanges-success-wallet-link": "novčanici",
  "page-get-eth-exchanges-success-wallet-paragraph": "Tamo gdje živite, ETH možete kupiti izravno iz ovih novčanika. Saznajte više o tome",
  "page-get-eth-exchanges-usa": "Sjedinjene Američke Države (SAD)",
  "page-get-eth-get-wallet-btn": "Nabavite novčanik",
  "page-get-eth-hero-image-alt": "Nabavite ETH, slika",
  "page-get-eth-keep-it-safe": "Čuvajte svoj ETH fond na sigurnom",
  "page-get-eth-meta-description": "Kako kupiti ETH na temelju mjesta na kojem živite i savjeti o tome kako se brinuti o fondu.",
  "page-get-eth-meta-title": "Kako kupiti ETH",
  "page-get-eth-need-wallet": "Za upotrebu DEX-a trebat će vam novčanik.",
  "page-get-eth-new-to-eth": "Novi ste na ETH? Evo objašnjenja za početak.",
  "page-get-eth-other-cryptos": "Kupujte s drugim kriptom",
  "page-get-eth-protect-eth-desc": "Ako planirate kupiti puno ETH-a, možda ćete ga htjeti držati u novčaniku koji kontrolirate, a ne u razmjeni. To je zato što je razmjena vjerojatna meta hakera. Ako haker dobije pristup, mogli biste izgubiti sredstva. U suprotnom, samo vi imate kontrolu nad svojim novčanikom.",
  "page-get-eth-protect-eth-in-wallet": "Zaštitite svoj ETH fond u novčaniku",
  "page-get-eth-search-by-country": "Traži prema državi",
  "page-get-eth-security": "Ali to također znači da morate ozbiljno shvatiti sigurnost svojih sredstava. Koristeći ETH ne povjeravate sredstva banci koja će se brinuti o vašem novcu, nego odgovornost preuzimate na sebe.",
  "page-get-eth-smart-contract-link": "Više o pametnim ugovorima",
  "page-get-eth-swapping": "Zamijenite svoje tokene za ETH sredstva drugih. I obrnuto.",
  "page-get-eth-traditional-currencies": "Kupujte tradicionalnim valutama",
  "page-get-eth-traditional-payments": "Kupite ETH tradicionalnim načinima plaćanja izravno od prodavača.",
  "page-get-eth-try-dex": "Isprobajte Dex",
  "page-get-eth-use-your-eth": "Upotrijebite svoja ETH sredstva",
  "page-get-eth-use-your-eth-dapps": "Sad kad posjedujete određena ETH sredstva, pogledajte neke Ethereum aplikacije (dapps). Postoje dapps aplikacije za financije, društvene medije, igre na sreću i puno drugih kategorija.",
  "page-get-eth-wallet-instructions": "Slijedite upute za novčanik",
  "page-get-eth-wallet-instructions-lost": "Ako izgubite pristup novčaniku, izgubit ćete pristup sredstvima. Novčanik bi vam trebao dati upute za zaštitu od toga. Obavezno ih pažljivo slijedite – u većini slučajeva nitko vam ne može pomoći ako izgubite pristup novčaniku.",
  "page-get-eth-wallets": "Novčanici",
  "page-get-eth-wallets-link": "Više o novčanicima",
  "page-get-eth-wallets-purchasing": "Neki novčanici omogućuju vam kupnju kripto podataka debitnom/kreditnom karticom, bankovnim prijenosom ili čak Apple Pay-om. Međutim, primjenjuju se zemljopisna ograničenja.",
  "page-get-eth-warning": "Ovi DEX-ovi nisu za početnike jer će vam trebati nešto ETH-a da biste ih upotrijebili.",
  "page-get-eth-what-are-DEX's": "Što su DEX-ovi?",
  "page-get-eth-whats-eth-link": "Što je ETH?",
  "page-get-eth-where-to-buy-desc": "ETH možete kupiti u mjenjačnicama ili izravno iz novčanika.",
  "page-get-eth-where-to-buy-desc-2": "Provjerite koje usluge možete upotrebljavati na temelju mjesta u kojem živite.",
  "page-get-eth-where-to-buy-title": "Gdje kupiti ETH",
  "page-get-eth-your-address": "Vaša ETH adresa",
  "page-get-eth-your-address-desc": "Kada preuzmete novčanik, stvorit će vam javnu ETH adresu. Evo primjera kako to izgleda:",
  "page-get-eth-your-address-desc-3": "Mislite na ovo kao na svoju e-adresu, ali umjesto pošte može primiti ETH. Ako želite prenijeti ETH iz mjenjačnice u novčanik, upotrijebite svoju adresu kao odredište. Obavezno provjerite prije slanja!",
  "page-get-eth-your-address-wallet-link": "Provjerite novčanike",
  "page-index-hero-image-alt": "slika heroja ethereum.org",
  "page-index-meta-description": "Ethereum je globalna, decentralizirana platforma za novac i nove vrste aplikacija. Na Ethereumu možete napisati kod koji kontrolira novac i izgraditi aplikacije dospune bilo gdje u svijetu.",
  "page-index-meta-title": "Početna",
  "page-index-title": "Ethereum je globalna platforma otvorenog koda za decentraizirane aplikacije.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Jezična podrška",
  "page-languages-interested": "Zanima vas doprinos?",
  "page-languages-learn-more": "Saznajte više o našem prevoditeljskom programu",
  "page-languages-meta-desc": "Resursi za sve podržane jezike ethereum.org i načini kako se uključiti kao prevoditelj.",
  "page-languages-meta-title": "ethereum.org prijevodi",
  "page-languages-p1": "Ethereum je globalni projekt i presudno je da ethereum.org bude dostupan svima, bez obzira na nacionalnost ili jezik. Naša zajednica naporno radi na tome da ova vizija postane stvarnost.",
  "page-languages-translations-available": "ethereum.org dostupan je na sljedećim jezicima",
  "page-languages-want-more-header": "Želite li vidjeti ethereum.org na drugom jeziku?",
  "page-languages-want-more-link": "Prevoditeljski program",
  "page-languages-want-more-paragraph": "prevoditelji ethereum.org-a uvijek prevode stranice na što više jezika. Da biste vidjeli na čemu trenutno rade ili da biste se prijavili da im se pridružite, pročitajte o našoj",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Kripto kolateral",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Uz Ethereum možete posuđivati izravno od drugih korisnika bez da trgujete svojim ETH-om. To vam može dati poticaj – neki to čine kako bi pokušali akumulirati više ETH-a.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Ali budući da je cijena ETH-a nestabilna, morat ćete zajam prekomjerno osigurati. To znači da ako želite posuditi 100 stabilnih kovanica, vjerojatno će vam trebati ETH u vrijednosti od najmanje 150 USD. To štiti sustav i zajmodavce.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Više o stabilnim kovanicama s kripto-podrškom",
  "page-stablecoins-accordion-borrow-pill": "Napredno",
  "page-stablecoins-accordion-borrow-places-intro": "Ovi vam dappovi omogućuju posudbu stabilnih kovanica koristeći se kriptom kao kolateralom. Neki prihvaćaju i druge tokene kao i ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Mjesta za posudbu stabilnih kovanica",
  "page-stablecoins-accordion-borrow-requirement-1": "Ethereumov Novčanik",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Za upotrebu dapp-a trebat će vam novčanik",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "ETH će vam trebati za kolateral i/ili naknade za transakcije",
  "page-stablecoins-accordion-borrow-requirements-description": "Da biste posudili stabilne kovanice, morat ćete upotrijebiti pravi dapp. Trebat će vam i novčanik i malo ETH-a.",
  "page-stablecoins-accordion-borrow-risks-copy": "Ako kao kolateral upotrebljavate ETH i vrijednost mu opadne, vaše jamstvo neće pokriti stabilne kovanice koje ste generirali. To će uzrokovati likvidaciju ETH-a i možda ćete dobiti kaznu. Dakle, ako posudite stabilne kovanice, morat ćete paziti na cijenu ETH-a.",
  "page-stablecoins-accordion-borrow-risks-link": "Najnovija cijena ETH-a",
  "page-stablecoins-accordion-borrow-risks-title": "Rizik",
  "page-stablecoins-accordion-borrow-text-preview": "Možete posuditi stabilne kovanice koristeći se kriptom kao kolateralom koji morate vratiti.",
  "page-stablecoins-accordion-borrow-title": "Posudite",
  "page-stablecoins-accordion-buy-exchanges-title": "Popularne mjenjačnice",
  "page-stablecoins-accordion-buy-requirement-1": "Kripto mjenjačnice i novčanici",
  "page-stablecoins-accordion-buy-requirement-1-description": "Provjerite koje usluge možete koristiti na temelju mjesta u kojem živite",
  "page-stablecoins-accordion-buy-requirements-description": "Račun s mjenjačnicom ili novčanikom s kojeg možete izravno kupiti kripto. Možda ste ga već upotrebljavali za nabavljanje ETH-a. Provjerite kojim se uslugama možete koristiti tamo gdje živite.",
  "page-stablecoins-accordion-buy-text-preview": "Mnoge mjenjačnice i novčanici omogućuju izravnu kupnju stabilnih kovanica. Primjenjivat će se zemljopisna ograničenja.",
  "page-stablecoins-accordion-buy-title": "Kupite",
  "page-stablecoins-accordion-buy-warning": "U centraliziranim mjenjačnicama mogu imati samo stabilne kovanice podržane fiat valutama poput USDC-a, Tethera i drugih. Možda ih nećete moći kupiti izravno, ali trebali biste ih moći zamijeniti iz ETH-a ili drugih kriptovaluta koje možete kupiti na platformi.",
  "page-stablecoins-accordion-earn-project-1-description": "Uglavnom tehnički rad na pokretanju softvera otvorenog koda.",
  "page-stablecoins-accordion-earn-project-2-description": "Tehnologija, sadržaj i ostali poslovi za zajednicu MakerDao (tim koji vam je donio Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Ako ste zaista poznavaoci, pronađite pogreške i zaradite Dai-je.",
  "page-stablecoins-accordion-earn-project-bounties": "Blagodati Gitcoin-a",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Nagrade lova na pogreške u Eth2",
  "page-stablecoins-accordion-earn-project-community": "Zajednica MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Ovo su platforme koje će vam za vaš rad platiti u stabilnim kovanicama.",
  "page-stablecoins-accordion-earn-projects-title": "Gdje zaraditi stabilane kovanice",
  "page-stablecoins-accordion-earn-requirement-1": "Ethereumov Novčanik",
  "page-stablecoins-accordion-earn-requirement-1-description": "Trebat će vam novčanik da biste primili zarađene stabilne kovanice",
  "page-stablecoins-accordion-earn-requirements-description": "Stabilne kovanice su izvrstan način plaćanja rada i usluga jer im je vrijednost stabilna. Ali trebat će vam novčanik za plaćanje.",
  "page-stablecoins-accordion-earn-text-preview": "Stabilne kovanice možete zaraditi radeći na projektima unutar ekosustava Ethereum.",
  "page-stablecoins-accordion-earn-title": "Zaradite",
  "page-stablecoins-accordion-less": "Manje",
  "page-stablecoins-accordion-more": "Više",
  "page-stablecoins-accordion-requirements": "Trebat će vam",
  "page-stablecoins-accordion-swap-dapp-intro": "Ako već imate ETH i novčanik, ove dappove možete koristiti za zamjenu za stabilne kovanice.",
  "page-stablecoins-accordion-swap-dapp-link": "Više o decentraliziranim mjenjačnicama",
  "page-stablecoins-accordion-swap-dapp-title": "Dapps za razamjenu tokena",
  "page-stablecoins-accordion-swap-editors-tip": "Savjet urednika",
  "page-stablecoins-accordion-swap-editors-tip-button": "Pronađi novčanike",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Nabavite si novčanik koji će vam omogućiti da kupite ETH i izravno ga zamijenite za žetone, uključujući stabilne kovanice.",
  "page-stablecoins-accordion-swap-pill": "Preporučeno",
  "page-stablecoins-accordion-swap-requirement-1": "Ethereumov Novčanik",
  "page-stablecoins-accordion-swap-requirement-1-description": "Trebat će vam novčanik za autorizaciju razmjene i spremanje svojih novčića",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Da biste platili zamjenu",
  "page-stablecoins-accordion-swap-text-preview": "Većinu stabilnih kovanica možete podići na decentraliziranim burzama. Tako možete zamijeniti bilo koji token koji imate za stabilne kovanice koje želite.",
  "page-stablecoins-accordion-swap-title": "Zamijeni",
  "page-stablecoins-algorithmic": "Algoritmično",
  "page-stablecoins-algorithmic-con-1": "Morate vjerovati algoritmu (ili ga znati čitati).",
  "page-stablecoins-algorithmic-con-2": "Stanje kovanica promijenit će se na temelju ukupne ponude.",
  "page-stablecoins-algorithmic-description": "Ove stabilne kovanice ne podupiru nijedno drugo sredstvo. Umjesto toga, algoritam će prodavati tokene ako cijena padne ispod željene vrijednosti i isporučivati tokene ako vrijednost prijeđe željeni iznos. Budući da se broj ovih tokena u optjecaju redovito mijenja, broj tokena u vašem vlasništvu će se promijeniti, ali uvijek će odražavati vaš udio.",
  "page-stablecoins-algorithmic-pro-1": "Nije potreban kolateral.",
  "page-stablecoins-algorithmic-pro-2": "Kontrolirano javnim algoritmom.",
  "page-stablecoins-bank-apy": "0,05 %",
  "page-stablecoins-bank-apy-source": "Prosječna stopa koju banke plaćaju na osnovne, federalno osigurane štedne račune, SAD.",
  "page-stablecoins-bank-apy-source-link": "Izvor",
  "page-stablecoins-bitcoin-pizza": "Čuvena Bitcoin pizza",
  "page-stablecoins-bitcoin-pizza-body": "2010. godine netko je kupio 2 pizze za 10.000 bitcoina. U to su vrijeme vrijedile ~ 41 USD. Na današnjem tržištu to su stotine milijuna dolara. U povijesti Ethereuma postoji mnogo sličnih žalosnih transakcija. Stabilne kovanice rješavaju ovaj problem, tako da možete uživati u svojoj pizzi i zadržati svoj ETH.",
  "page-stablecoins-coin-price-change": "Promjena cijene kovanica (posljednjih 30 dana)",
  "page-stablecoins-crypto-backed": "Podržano kriptom",
  "page-stablecoins-crypto-backed-con-1": "Manje stabilni od stabilnih fiat-kovanica.",
  "page-stablecoins-crypto-backed-con-2": "Morate pripaziti na vrijednost kripto kolaterala.",
  "page-stablecoins-crypto-backed-description": "Ove stabilne kovanice podržavaju druge kripto imovine, poput ETH-a. Njihova cijena ovisi o vrijednosti osnovne imovine (ili kolaterala), koja može biti nestabilna. Budući da vrijednost ETH-a može oscilirati, ove stabilne kovanice prekomjerno su osigurane kako bi se osiguralo da cijena ostane što stabilnija. To znači da skoromožemo reći da stabilne kovanice s kripto-jamstvom od 1 dolara imaju osnovnu vrijednost kripto imovine od najmanje 2 dolara. Dakle, ako cijena ETH-a padne, više ETH-a se mora upotrijebiti za potporu stabilnih kovanica, inače će stabilne kovanice izgubiti na vrijednosti.",
  "page-stablecoins-crypto-backed-pro-1": "Transparentno i potpuno decentralizirano.",
  "page-stablecoins-crypto-backed-pro-2": "Brzo promjenjivo u drugu kripto imovinu.",
  "page-stablecoins-crypto-backed-pro-3": "Nema vanjskih skrbnika – svu imovinu kontroliraju računi Ethereuma.",
  "page-stablecoins-dai-banner-body": "Dai je vjerojatno najpoznatija decentralizirana kovanica. Vrijednost mu je otprilike jedan dolar i široko je prihvaćen u različitim zemljama.",
  "page-stablecoins-dai-banner-learn-button": "Saznajte više o Dai-ju",
  "page-stablecoins-dai-banner-swap-button": "Zamijenite ETH za Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Dai logo",
  "page-stablecoins-editors-choice": "Urednikov izbor",
  "page-stablecoins-editors-choice-intro": "Ovo su vjerojatno najpoznatiji primjeri stabilnih kovanica trenutno i kovanice koje smatramo korisnima kada koristimo dapps.",
  "page-stablecoins-explore-dapps": "Istražite dapp-ove",
  "page-stablecoins-fiat-backed": "S podrškom Fiat valute",
  "page-stablecoins-fiat-backed-con-1": "Centralizirano – netko mora izdati tokene.",
  "page-stablecoins-fiat-backed-con-2": "Zahtijeva reviziju kako bi se osiguralo da tvrtka ima dovoljne rezerve.",
  "page-stablecoins-fiat-backed-description": "U osnovi IOU (dugujem vam) za tradicionalnu Fiat valutu (obično dolare). Koristite se svojom Fiat valutom za kupnju stabilnih kovanica koje kasnije možete unovčiti i upotrijebiti za svoju izvornu valutu.",
  "page-stablecoins-fiat-backed-pro-1": "Sigurnost od kripto volatilnosti.",
  "page-stablecoins-fiat-backed-pro-2": "Promjene u cijeni su minimalne.",
  "page-stablecoins-find-stablecoin": "Pronađite stabilne kovanice",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Kako doći do stabilnih kovanica",
  "page-stablecoins-find-stablecoin-intro": "Dostupne su stotine kovanica. Evo nekoliko, što će vam pomoći da započnete. Ako ste tek upoznali Ethereum, preporučujemo da prvo istražite.",
  "page-stablecoins-find-stablecoin-types-link": "Različite vrste stabilnih kovanica",
  "page-stablecoins-get-stablecoins": "Kako doći do stabilnih kovanica",
  "page-stablecoins-hero-alt": "Tri najveće stabilne kovanice po tržišnoj kapitalizaciji: Dai, USDC i Tether.",
  "page-stablecoins-hero-button": "Nabavite stabilane kovanice",
  "page-stablecoins-hero-header": "Digitalni novac za svakodnevnu upotrebu",
  "page-stablecoins-hero-subtitle": "Stabilne kovanice su Ethereumovi tokeni dizajnirani da ostanu na fiksnoj vrijednosti, čak i kada se cijena ETH-a promijeni.",
  "page-stablecoins-interest-earning-dapps": "Dapps za zaradu od kamata",
  "page-stablecoins-meta-description": "Uvod u Ethereumove stabilne kovanice: što je to, kako ih dobiti i zašto su važne.",
  "page-stablecoins-precious-metals": "Dragocjeni metali",
  "page-stablecoins-precious-metals-con-1": "Centralizirano – netko mora izdati tokene.",
  "page-stablecoins-precious-metals-con-2": "Morate vjerovati izdavaču tokena i rezervama dragocjenih metala.",
  "page-stablecoins-precious-metals-description": "Poput fiat valuta, umjesto toga, ove stabilne kovanice upotrebljavaju resurse poput zlata da bi održali svoju vrijednost.",
  "page-stablecoins-precious-metals-pro-1": "Sigurnost od kripto volatilnosti.",
  "page-stablecoins-prices": "Cijene stabilnih kovanica",
  "page-stablecoins-prices-definition": "Stabilne kovanice su kripto valute bez volatilnosti. Dijele puno istih moći kao i ETH, ali vrijednost im je stabilna, više poput tradicionalne valute. Dakle, imate pristup stabilnom novcu koji možete koristiti na Ethereumu. ",
  "page-stablecoins-prices-definition-how": "Kako stabilne kovanice dobivaju svoju stabilnost",
  "page-stablecoins-research-warning": "Ethereum je nova tehnologija i većina aplikacija je nova. Budite svjesni rizika i uložite samo onoliko koliko si možete priuštiti da izgubite.",
  "page-stablecoins-research-warning-title": "Samostalno istražite",
  "page-stablecoins-save-stablecoins": "Uštedite pomoću stabilnih kovanica",
  "page-stablecoins-save-stablecoins-body": "Stabilne kovanice često imaju natprosječnu kamatnu stopu, pošto je potražnja za posuđivanjem velika. Postoje dapp-ovi koji vam omogućuju da zaradite kamate na svojim stabilnim kovanicama u stvarnom vremenu polaganjem u fond za zajam. Kao i u bankarskom svijetu, predajete tokene za zajmoprimce, ali svoje žetone i svoje kamate možete povući u bilo kojem trenutku.",
  "page-stablecoins-saving": "Iskoristite svoju uštedu stabilnih kovanica i zaradite kamate. Kao i sve vezano za kripto, i predviđeni godišnji postotni prinosi (APY) može se mijenjati iz dana u dan, ovisno o ponudi/potražnji u stvarnom vremenu.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Pogledajte Ethereumove dapp-ove – stabilne kovanice često su korisnije za svakodnevne transakcije.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Ilustracija doge-a.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Upotrijebite svoje stabilne kovanice",
  "page-stablecoins-stablecoins-dapp-description-1": "Tržišta za puno stabilnih kovanica, uključujući Dai, USDC, TUSD, USDT i druge. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Posudite stabilne kovanice i zaradite kamate i $COMP, vlastiti token Compounda.",
  "page-stablecoins-stablecoins-dapp-description-3": "Platforme za trgovanje na kojima možete zaraditi kamate na svojim valutama Dai i USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Aplikacija dizajnirana za spremanje Dai-ja.",
  "page-stablecoins-stablecoins-feature-1": "Stabilne kovanice su globalne i mogu se slati putem interneta. Jednostavno ih je primiti ili poslati ako imate Ethereum račun.",
  "page-stablecoins-stablecoins-feature-2": "Potražnja za stabilnim kovanicama je velika, tako da možete zaraditi kamate za posudbu svojih. Prije posudbe budite svjesni rizika.",
  "page-stablecoins-stablecoins-feature-3": "Stabilne kovanice su zamjenjive za ETH i druge Ethereumove tokene. Mnogo dapp-ova oslanja se na stabilne kovanice.",
  "page-stablecoins-stablecoins-feature-4": "Stabilne kovanice osigurane su kriptografijom. Nitko ne može krivotvoriti transakcije u vaše ime.",
  "page-stablecoins-stablecoins-meta-description": "Uvod u Ethereumove stabilne kovanice: što je to, kako ih dobiti i zašto su važne.",
  "page-stablecoins-stablecoins-table-header-column-1": "Valuta",
  "page-stablecoins-stablecoins-table-header-column-2": "Tržišna kapitalizacija",
  "page-stablecoins-stablecoins-table-header-column-3": "Vrsta kolaterala",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Kripto",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Fiat",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Dragocjeni metali",
  "page-stablecoins-table-error": "Nije moguće učitati stabilne kovanice. Pokušajte osvježiti stranicu.",
  "page-stablecoins-table-loading": "Učitavanje podataka stabilnih kovanica...",
  "page-stablecoins-title": "Stabilne kovanice",
  "page-stablecoins-top-coins": "Najbolje stabilne kovanice prema tržišnoj kapitalizaciji",
  "page-stablecoins-top-coins-intro": "Tržišna kapitalizacija je",
  "page-stablecoins-top-coins-intro-code": "ukupan broj postojećih tokena pomnožen s vrijednošću po tokenu. Taj je popis dinamičan i ovdje navedene projekte ne mora nužno odobriti tim ethereum.org-a.",
  "page-stablecoins-types-of-stablecoin": "Kako funkcioniraju: vrste stabilnih kovanica",
  "page-stablecoins-usdc-banner-body": "USDc je vjerojatno najpoznatija stabilna kovanica spodrškom fiat-valute. Vrijednost mu je otprilike dolar, a podržavaju ga Circle i Coinbase.",
  "page-stablecoins-usdc-banner-learn-button": "Saznajte više o USDC-u",
  "page-stablecoins-usdc-banner-swap-button": "Zamijenite ETH za USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "USDC logo",
  "page-stablecoins-why-stablecoins": "Zašto stabilne kovanice?",
  "page-stablecoins-how-they-work-button": "Kako rade",
  "page-stablecoins-why-stablecoins-body": "ETH, poput Bitcoina, ima promjenjivu cijenu jer je to nova tehnologija. Stoga ga možda ne želite redovito trošiti. Stabilne kovanice odražavaju vrijednost tradicionalnih valuta kako bi vam se omogućio pristup stabilnom novcu kojim se možete koristiti na Ethereumu.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". ako želite da dodamo novčanik,",
  "page-find-wallet-alpha-logo-alt": "AlphaWallet logo",
  "page-find-wallet-ambo-logo-alt": "Ambo logo",
  "page-find-wallet-argent-logo-alt": "Argent logo",
  "page-find-wallet-buy-card": "Kupite kripto pomoću kartice",
  "page-find-wallet-buy-card-desc": "Kupite ETH izravno iz novčanika bankovnom karticom. Mogu se primijeniti zemljopisna ograničenja.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Pogledajte dapps",
  "page-find-wallet-clear": "Izbriši filtere",
  "page-find-wallet-coinbase-logo-alt": "Coinbase logo",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Stoga odaberite novčanik na temelju značajki koje želite.",
  "page-find-wallet-description": "Novčanici imaju mnoštvo dodatnih opcija koje bi vam se mogle svidjeti.",
  "page-find-wallet-description-alpha": "Potpuno otvoreni Ethereumov novčanik koji koristi sigurnu enklavu na mobilnom uređaju, nudi potpunu testnet podršku i prihvaća standard TokenScript.",
  "page-find-wallet-description-ambo": "Izravno pređite na ulaganje i ostvarite svoje prvo ulaganje u roku od nekoliko minuta nakon preuzimanja aplikacije",
  "page-find-wallet-description-argent": "Jednim dodirom zaradite kamate i uložite; posudite, pohranite i pošaljite. Posjedujte.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Sigurna aplikacija za pohranjivanje kripta",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma je vaš portal za DeFi. Nema više sjemenskih fraza, nema više Chrome-ovih proširenja.",
  "page-find-wallet-description-enjin": "Neprobojan, prepun mogućnosti i prikladan – napravljen za trgovce, igrače i programere",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Najpouzdanija platforma za pohranu digitalnih sredstava na Ethereumu",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken je jednostavan i siguran digitalni novčanik kojem vjeruju milijuni",
  "page-find-wallet-description-ledger": "Čuvajte svoju imovinu pomoću najviših sigurnosnih standarda",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Započnite s istraživanjem aplikacija lanaca blokova u nekoliko sekundi. Povjerenje preko 1 milijun korisnika širom svijeta.",
  "page-find-wallet-description-monolith": "Jedini samohrani novčanik na svijetu uparen s debitnom karticom Visa. Dostupno u Velikoj Britaniji i EU također globalno upotrebljivo.",
  "page-find-wallet-description-multis": "Multis je račun za kriptovalute namijenjen tvrtkama. Pomoću Multisa tvrtke mogu pohranjivati uz kontrole pristupa, zarađivati kamate na ušteđevinu i pojednostavljivati plaćanja i računovodstvo.",
  "page-find-wallet-description-mycrypto": "MyCrypto je sučelje za upravljanje svim vašim računima. Zamijenite, pošaljite i kupujte kripto s novčanicima poput MetaMaska, Ledgera, Trezora i drugih.",
  "page-find-wallet-description-myetherwallet": "Besplatno sučelje na strani klijenta pomaže vam u interakciji s Ethereumovim lancem blokova",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Novčanik koji nije u vlasništvu zajednice i ima vlastitu L2 mrežu za plaćanja.",
  "page-find-wallet-description-portis": "Blokirani novčanik bez skrbništva koji pojednostavnjuje aplikacije za sve",
  "page-find-wallet-description-rainbow": "Bolji dom za vašu Ethereum imovinu",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Sigurna aplikacija za razmjenu poruka, kripto novčanik i preglednik Web3 izgrađeni najsuvremenijom tehnologijom",
  "page-find-wallet-description-tokenpocket": "TokenPocket: siguran i prikladan vodeći svjetski novčanik za digitalne valute i portal za Dapps, s podrškom za više lanaca.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Prijava jednim klikom za Web 3.0",
  "page-find-wallet-description-trezor": "Prvi i originalni hardverski novčanik",
  "page-find-wallet-description-trust": "Trust Wallet je decentralizirani novčanik s kriptovalutama s više novčića. Kupujte kripto, istražujte dappove, zamijenite sredstva i još mnogo toga, a pritom kontrolirajte svoje ključeve.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo je prvi kripto novčanik bez ključa. Uz ZenGo ne postoje privatni ključevi, lozinke ili početne fraze kojima se može upravljati ili ih izgubiti. Kupite, trgujte, zaradite i pohranite Ethereum s neviđenom jednostavnošću i sigurnošću",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Dharma logo",
  "page-find-wallet-enjin-logo-alt": "Enjin logo",
  "page-find-wallet-Ethereum-wallets": "Novčanici Ethereum",
  "page-find-wallet-explore-dapps": "Istražite dapps-ove",
  "page-find-wallet-explore-dapps-desc": "Ovi novčanici osmišljeni su kako bi vam pomogli da se povežete s Ethereumovim dapps uređajima.",
  "page-find-wallet-feature-h2": "Odaberite značajke novčanika do kojih vam je stalo",
  "page-find-wallet-fi-tools": "Pristup financijskim alatima",
  "page-find-wallet-fi-tools-desc": "Posudite, iznajmite i zaradite kamate izravno iz svog novčanika.",
  "page-find-wallet-following-features": "sa sljedećim značajkama:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Gnosis Safe logo",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Slika heroja koji pronalazi novčanik",
  "page-find-wallet-imtoken-logo-alt": "imToken logo",
  "page-find-wallet-last-updated": "Posljednje ažuriranje",
  "page-find-wallet-ledger-logo-alt": "Ledger logo",
  "page-find-wallet-limits": "Ograničava zaštitu",
  "page-find-wallet-limits-desc": "Zaštitite svoju imovinu postavljanjem ograničenja koja sprečavaju iscrpljivanje vašeg računa.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "pravila popisa",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Pronađite i usporedite Ethereumove novčanike na temelju značajki koje želite.",
  "page-find-wallet-meta-title": "Pronađite Ethereumov novčanik",
  "page-find-wallet-metamask-logo-alt": "MetaMask logo",
  "page-find-wallet-monolith-logo-alt": "Monolith logo",
  "page-find-wallet-multis-logo-alt": "Multis logo",
  "page-find-wallet-multisig": "Računi s više potpisa",
  "page-find-wallet-multisig-desc": "Radi dodatne sigurnosti, novčanici s više potpisa trebaju više od jednog računa za autorizaciju određenih transakcija.",
  "page-find-wallet-mycrypto-logo-alt": "MyCrypto logo",
  "page-find-wallet-myetherwallet-logo-alt": "MyEtherWallet logo",
  "page-find-wallet-new-to-wallets": "Novi ste u novčanicima? Evo pregleda za početak.",
  "page-find-wallet-new-to-wallets-link": "Ethereumovi novčanici",
  "page-find-wallet-not-all-features": "Nijedan novčanik još nema sve ove",
  "page-find-wallet-not-endorsements": "Novčanici navedeni na ovoj stranici nisu službena preporuka i pružaju se samo u informativne svrhe. Njihove su opise dale same tvrtke novčanika. Na ovu stranicu dodajemo proizvode na temelju kriterija u",
  "page-find-wallet-overwhelmed": "Ethereumovim novčanicima dolje. Sve vam je to previše? Pokušajte filtrirati prema značajkama.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Pillar logo",
  "page-find-wallet-portis-logo-alt": "Portis logo",
  "page-find-wallet-rainbow-logo-alt": "Rainbow logo",
  "page-find-wallet-raise-an-issue": "prijavite problem u sustavu GitHub",
  "page-find-wallet-search-btn": "Pretražite odabrane značajke",
  "page-find-wallet-showing": "Prikazivanje ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Status logo",
  "page-find-wallet-swaps": "Decentralizirane zamjene tokena",
  "page-find-wallet-swaps-desc": "Trgujte između ETH-a i drugih tokena izravno iz svog novčanika.",
  "page-find-wallet-title": "Pronađite novčanik",
  "page-find-wallet-tokenpocket-logo-alt": "TokenPocket logo",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Torus logo",
  "page-find-wallet-trezor-logo-alt": "Trezor logo",
  "page-find-wallet-trust-logo-alt": "Trust logo",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Pokušajte ukloniti jednu ili dvije značajke",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Sad kad imate novčanik, provjerite neke Ethereumove aplikacije (dapps). Postoje dapp-ovi za financije, društvene medije, igre na sreću i puno drugih kategorija.",
  "page-find-wallet-use-your-wallet": "Koristite se svojim novčanikom",
  "page-find-wallet-voluem-desc": "Ako želite imati puno ETH-a, odaberite novčanik koji vam omogućuje kupnju više od 2000 ETH-a odjednom.",
  "page-find-wallet-volume": "Kupovine velike količine",
  "page-find-wallet-we-found": "Našli smo",
  "page-find-wallet-withdraw": "Podizanje u banku",
  "page-find-wallet-withdraw-desc": "ETH možete unovčiti izravno na svoj bankovni račun bez zamjene.",
  "page-find-wallet-yet": "značajke",
  "page-find-wallet-zengo-logo-alt": "ZenGo logo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Ulog ETH",
  "page-wallets-accounts-addresses": "Novčanici, računi i adrese",
  "page-wallets-accounts-addresses-desc": "Vrijedi razumjeti razlike između nekih ključnih pojmova.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Ilustracija robota sa sefom koji predstavlja Ethereumov novčanik umjesto tijela",
  "page-wallets-ethereum-account": "Ethereum račun",
  "page-wallets-blog": "Blog tvrtke Coinbase",
  "page-wallets-bookmarking": "Označite poveznicu do novčanika",
  "page-wallets-bookmarking-desc": "Ako upotrebljavate web-novčanik, označite web-stranicu oznakom kako biste se zaštitili od krađe identiteta.",
  "page-wallets-cd": "Hardverski novčanici koji vam omogućuju da kripto vezu držite izvan mreže – vrlo sigurno",
  "page-wallets-converted": "Kripto konvertirani?",
  "page-wallets-converted-desc": "Ako želite držati neku ozbiljnu vrijednost, preporučujemo hardverski novčanik jer su oni najsigurniji. Ili novčanik s upozorenjima o prijevarama i ograničenjima povlačenja.",
  "page-wallets-curious": "Kripto znatiželjan?",
  "page-wallets-curious-desc": "Ako ste novi u kripto radu i želite samo isprobati, preporučujemo nešto što će vam pružiti priliku za istraživanje Ethereumovih aplikacija ili kupnju prvog ETH-a izravno iz novčanika.",
  "page-wallets-desc-2": "Novčanik vam je potreban za slanje sredstava i upravljanje ETH fondom.",
  "page-wallets-desc-2-link": "Više o ETH-u",
  "page-wallets-desc-3": "Novčanik je samo alat za upravljanje Ethereum računom. To znači da u bilo kojem trenutku možete zamijeniti davatelje novčanika. Mnogi novčanici također vam omogućuju upravljanje s nekoliko Ethereum računa iz jedne aplikacije.",
  "page-wallets-desc-4": "To je zato što novčanici nemaju skrbništvo nad vašim sredstvima, vi imate. Oni su samo alat za upravljanje onim što je stvarno vaše.",
  "page-wallets-description": "Ethereum novčanici su aplikacije koje vam omogućuju interakciju s vašim Ethereum računom. Zamislite to kao aplikaciju za internetsko bankarstvo, ali bez banke. Novčanik vam omogućuje očitavanje stanja, slanje transakcija i povezivanje s aplikacijama.",
  "page-wallets-desktop": "Ako želite upravljati sredstvima putem programa MacOS, Windows ili Linuxna upotrebljavaje aplikacije na računalu",
  "page-wallets-ethereum-wallet": "Novčanik",
  "page-wallets-explore": "Istražite Ethereum",
  "page-wallets-features-desc": "Možemo vam pomoći u odabiru novčanika na temelju značajki do kojih vam je stalo.",
  "page-wallets-features-title": "Radije birate na temelju značajki?",
  "page-wallets-find-wallet-btn": "Pronađite novčanik",
  "page-wallets-find-wallet-link": "Pronađite novčanik",
  "page-wallets-get-some": "Nabavite nešto ETH-a",
  "page-wallets-get-some-alt": "Ilustracija ruke koja stvara ETH logotip od lego kockica",
  "page-wallets-get-some-btn": "Nabavite nešto ETH-a",
  "page-wallets-get-some-desc": "ETH je izvorni kripto Ethereuma. Za korištenje Ethereumovom aplikacijom trebat će vam malo ETH-a u novčaniku.",
  "page-wallets-get-wallet": "Nabavite novčanik",
  "page-wallets-get-wallet-desc": "Na izbor je puno različitih novčanika. Želimo vam pomoći odabrati najbolji za vas.",
  "page-wallets-get-wallet-desc-2": "Zapamtite: ova odluka nije zauvijek – vaš Ethereum račun nije vezan uz vašeg davatelja novčanika.",
  "page-wallets-how-to-store": "Kako pohraniti digitalnu imovinu na Ethereumu",
  "page-wallets-keys-to-safety": "Ključevi za zaštitu vašeg kripta",
  "page-wallets-manage-funds": "Aplikacija za upravljanje sredstvima",
  "page-wallets-manage-funds-desc": "Vaš novčanik prikazuje stanje vašeg računa, povijest transakcija i daje vam način slanja/primanja sredstava. Neki novčanici mogu ponuditi i više.",
  "page-wallets-meta-description": "Što trebate znati da biste koristili Ethereumove novčanike.",
  "page-wallets-meta-title": "Ethereumovi novčanici",
  "page-wallets-mobile": "Mobilne aplikacije koje čine vaša sredstva dostupnima s bilo kojeg mjesta",
  "page-wallets-more-on-dapps-btn": "Više o Dapp-ovima",
  "page-wallets-most-wallets": "Većina novčanika omogućit će vam generiranje Ethereum računa. Dakle, ne trebate ga prije nego što preuzmete novčanik.",
  "page-wallets-protecting-yourself": "Zaštite sebe i svoja sredstava",
  "page-wallets-seed-phrase": "Zapišite svoju sjemensku frazu",
  "page-wallets-seed-phrase-desc": "Novčanici će vam često dati sjemensku frazu koju morate zapisati negdje na sigurno. Jedino ćete tako moći obnoviti novčanik.",
  "page-wallets-seed-phrase-example": "Na primjer:",
  "page-wallets-seed-phrase-snippet": "formacija zrakoplovne krivulje doge-a moguće da se proizvod nazire pod čarobnom svjetiljkom",
  "page-wallets-seed-phrase-write-down": "Nemojte je čuvati na računalu. Zapišite to na papir i čuvajte na sigurnom.",
  "page-wallets-slogan": "Ključ vaše digitalne budućnosti",
  "page-wallets-stay-safe": "Kako ostati siguran",
  "page-wallets-stay-safe-desc": "Novčanici su pomalo pomak u razmišljanju. Financijska sloboda i mogućnost pristupa i korištenja sredstava bilo gdje dolaze s malo odgovornosti – u kriptu nema korisničke podrške.",
  "page-wallets-subtitle": "Novčanici omogućuju pristup vašim sredstvima i aplikacijama na Ethereumu. Pristup novčaniku trebali biste imati samo vi.",
  "page-wallets-take-responsibility": "Preuzmite odgovornost za vlastita sredstva",
  "page-wallets-take-responsibility-desc": "Centralizirane mjenjačnice povezat će vaš novčanik s korisničkim imenom i lozinkom koje možete obnoviti na tradicionalan način. Sjetite se samo da toj mjenjačnici povjeravate skrbništvo nad svojim sredstvima. Ako ta tvrtka bude napadnuta ili propadne, vaša su sredstva u opasnosti.",
  "page-wallets-tips": "Više savjeta o očuvanju sigurnosti",
  "page-wallets-tips-community": "Od zajednice",
  "page-wallets-title": "Ethereumovi novčanici",
  "page-wallets-triple-check": "Sve trostruko provjerite",
  "page-wallets-triple-check-desc": "Imajte na umu da se transakcije ne mogu stornirati, a novčanici se ne mogu lako oporaviti, pa pripazite.",
  "page-wallets-try-dapps": "Isprobajte neke dapp-ove",
  "page-wallets-try-dapps-alt": "Ilustracija zajedničkog rada članova zajednice Ethereum",
  "page-wallets-try-dapps-desc": "Dapps su aplikacije izgrađene na Ethereumu. Te su jeftinije, primjerenije i bolje u odnosu na vaše podatke od većine tradicionalnih aplikacija.",
  "page-wallets-types": "Vrste novčanika",
  "page-wallets-web-browser": "Web novčanici koji vam omogućuju interakciju s vašim računom putem web preglednika",
  "page-wallets-whats-a-wallet": "Što je Ethereumov novčanik?",
  "page-wallets-your-ethereum-account": "Vaš Ethereum račun",
  "page-wallets-your-ethereum-account-desc": "Vaš novčanik je vaš prozor u Ethereum račun – saldo, povijest transakcija i još mnogo toga. Ali davatelje novčanika možete zamijeniti u bilo kojem trenutku.",
  "page-wallets-your-login": "Vaša prijava u Ethereumove aplikacije",
  "page-wallets-your-login-desc": "Vaš novčanik omogućuje vam povezivanje s bilo kojom decentraliziranom aplikacijom pomoću Ethereum računa. To je poput prijave koju možete upotrebljavati na mnogim dapps uređajima.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum je tehnologija koja vam omogućuje slanje kriptovaluta bilo kome za malu naknadu. Također pokreće aplikacije koje svi mogu upotrebljavati i koje nitko ne može ukloniti.",
  "page-what-is-ethereum-101-desc-2": "Ethereum se nadovezuje na Bitcoinovu inovaciju, uz neke velike razlike.",
  "page-what-is-ethereum-101-desc-3": "Oba vam omogućuju upotrebu digitalnog novca bez davatelja usluga plaćanja ili banaka. Ali Ethereum je moguće programirati, pa ga također možete koristiti za mnoštvo različitih digitalnih sredstava – čak i za Bitcoin!",
  "page-what-is-ethereum-101-desc-4": "To također znači da je Ethereum za više od plaćanja. To je tržište financijskih usluga, igara i aplikacija koje ne mogu ukrasti vaše podatke niti vas cenzurirati.",
  "page-what-is-ethereum-101-italic": "svjetski programibilni lanac blokova.",
  "page-what-is-ethereum-101-strong": "To je ",
  "page-what-is-ethereum-accessibility": "Ethereum je otvoren svakome.",
  "page-what-is-ethereum-adventure": "Odaberite svoju avanturu!",
  "page-what-is-ethereum-alt-img-bazaar": "Ilustracija osobe koja viri u bazar, predstavlja Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Ilustracija zajedničkog rada članova zajednice Ethereum",
  "page-what-is-ethereum-alt-img-lego": "Ilustracija ruke koja stvara ETH logotip od lego kockica",
  "page-what-is-ethereum-alt-img-social": "Ilustracija likova u društvenom prostoru posvećenom Ethereumu s velikim logotipom ETH",
  "page-what-is-ethereum-banking-card": "Bankarstvo za svakoga",
  "page-what-is-ethereum-banking-card-desc": "Nemaju svi pristup financijskim uslugama. Ali sve što vam treba za pristup Ethereumu i proizvodima za posudbu, posuđivanje i štednju je internetska veza.",
  "page-what-is-ethereum-build": "Napravite nešto s Ethereumom",
  "page-what-is-ethereum-build-desc": "Ako želite pokušati graditi s Ethereumom, pročitajte naše dokumente, isprobajte neke vodiče ili provjerite alate potrebne za početak.",
  "page-what-is-ethereum-censorless-card": "Otporan na cenzuru",
  "page-what-is-ethereum-censorless-card-desc": "Nijedna vlada ni tvrtka nemaju kontrolu nad Ethereumom. Ova decentralizacija gotovo u potpunosti onemogućava bilo kome da vas spriječi u primanju plaćanja ili korištenju usluga na Ethereumu.",
  "page-what-is-ethereum-comm-desc": "Naša zajednica uključuje ljude iz svih sredina, uključujući umjetnike, kripto-anarhiste, tvrtke iz fortune 500, a sada i vas. Saznajte kako se danas možete uključiti.",
  "page-what-is-ethereum-commerce-card": "Komercijalne garancije",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum stvara glađu površinu. Korisnici dobivaju sigurnu, ugrađenu garanciju da će sredstva ići iz ruke u ruku samo ako daš što je dogovoreno. Ne treba vam utjecaj velike tvrtke za poslovanje.",
  "page-what-is-ethereum-community": "Ethereumova zajednica",
  "page-what-is-ethereum-compatibility-card": "Kompatibilnost za pobjedu",
  "page-what-is-ethereum-compatibility-card-desc": "Bolji prizvodi i iskustva grade se cijelo vrijeme jer su proizvodi Ethereuma zadano kompatibilni. Tvrtke mogu graditi uspjeh jedne drugima.",
  "page-what-is-ethereum-dapps-desc": "Proizvodi i usluge na Ethereumu. Postoje dapps za financije, posao, društvene mreže, igrice i više – upoznajte aplikacije naše digitalne budućnosti.",
  "page-what-is-ethereum-dapps-img-alt": "Ilistracija Doge-a kako upotrebljava aplikaciju Ethereum na računalu",
  "page-what-is-ethereum-dapps-title": "Ethereum dapps",
  "page-what-is-ethereum-desc": "Zaklada za digitalnu budućnost",
  "page-what-is-ethereum-explore": "Istražite Ethereum",
  "page-what-is-ethereum-get-started": "Najbolji način da saznate više je da preuzmete novčanik, nabavite nešto EHT-a i isprobate decentraliziranu aplikaciju Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum je otvoren pristup digitalnom novcu i jednostavna usluga za sve – bez obzira na porijeklo ili lokaciju. Tehnologija iza kriptovalute Ether (ETH) koju je izgradila zajednica i tisuće aplikacija kojima se možete poslužiti već danas.",
  "page-what-is-ethereum-internet-card": "Privatniji internet",
  "page-what-is-ethereum-internet-card-desc": "Ne morate dati svoje osobne podatke da biste se služili aplikacijom Ethereum. Ethereom gradi vrijednost koja se temelji na ekonomiji, a ne nadgledanju.",
  "page-what-is-ethereum-meet-comm": "Upoznajte zajednicu",
  "page-what-is-ethereum-meta-description": "Upoznajte Ethereum, što radi kak da isprobate i sami.",
  "page-what-is-ethereum-meta-title": "Što je Ethereum?",
  "page-what-is-ethereum-native-alt": "Simbol za Ether (ETH)",
  "page-what-is-ethereum-native-crypto": "Ethereumova kriptovaluta i ekvivalent Bitkoinu. ETHmožete upotrebljavati na Ethereumovim aplikacijama ili za slanje vrijednosti prijateljima ili obitelji.",
  "page-what-is-ethereum-native-img-alt": "Ilustracija robota sa sefom umjesto torza prije je predstavljala Ethereumove novčanile",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Ravnopravna mreža",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum vam dozvoljava da selite svoj novac, dogovarate se izravno s drugima. Nema potrebe za posredničkim tvrtkama.",
  "page-what-is-ethereum-singlecard-desc": "Ako vas zanima lanac blokova i tehnička strana Ethereuma, ovdje smo za vas.",
  "page-what-is-ethereum-singlecard-link": "Kako Ethereum radi",
  "page-what-is-ethereum-singlecard-title": "Kako Ethereum radi",
  "page-what-is-ethereum-start-building-btn": "Počnite graditi",
  "page-what-is-ethereum-title": "Što je Ethereum?",
  "page-what-is-ethereum-tools-needed": "Sve što vam je potrebno za sudjelovanje je novčanik.",
  "page-what-is-ethereum-try": "Isprobajte Ethereum",
  "page-what-is-ethereum-tryit": "Uđite u bazar iprobajte...",
  "page-what-is-ethereum-wallets": "Novčanici",
  "page-what-is-ethereum-wallets-desc": "Kako se upravlja ETH-om i Ethereumovim računom. Za početak vam je potreban novčanik – pomoći ćemo vam u odabiru.",
  "page-what-is-ethereum-welcome": "Dobro došli u Ethereum",
  "page-what-is-ethereum-welcome-2": "Nadamo se da ćete ostati.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Ulaganje je ovdje! Ako želite uložiti svoj ETH",
  "banner-staking-2": "potvrdite adresu ugovora o pologu",
  "docking": "Spajanje",
  "page-eth2-vision-security-desc-9": "omogućuje nam da nasumce dodijelimo validatore različitim krhotinama – to praktički onemogućava validatorima da se ikad dogovore napadajući određeni djelić. DIjeljenje nije toliko sigurno na lancu blokova s načinom proof-of-work, jer na taj način protokol ne može kontrolirati rudare.",
  "page-index-sections-developers-desc": "Saznajte više o tehnologiji koja stoji iza Ethereuma i njegovih aplikacija kako biste mogli početi graditi s njom.",
  "page-index-sections-developers-image-alt": "Ilustracija ruke koja gradi Ethereum glif od lego kockica",
  "page-index-sections-developers-link-text": "Počnite graditi",
  "page-index-sections-developers-title": "Programeri",
  "page-index-sections-enterprise-desc": "Pogledajte kako Ethereum može otvoriti nove poslovne modele, smanjiti troškove i osigurati poslovanje u budućnosti.",
  "page-index-sections-enterprise-image-alt": "Ilustracija grupe koja radi na projektu Ethereum oko prijenosnog računala",
  "page-index-sections-enterprise-link-text": "Ethereum za poduzeća",
  "page-index-sections-enterprise-title": "Poduzeće",
  "page-index-sections-individuals-desc": "Upoznajte Ethereum, Ether, novčanike, tokene i još mnogo toga da biste mogli početi koristiti Ethereumove programe.",
  "page-index-sections-individuals-image-alt": "Ilustracija psa Doge-a koji sjedi za računalom",
  "page-index-sections-individuals-link-text": "Započnite s Ethereumom",
  "page-index-sections-individuals-title": "O Ethereumu",
  "page-index-subtitle": "Na Ethereumu možete napisati kod koji kontrolira digitalnu vrijednost, radi točno onako kako je programiran i dostupan je bilo gdje u svijetu.",
  "page-find-wallet-authereum-logo-alt": "Authereum logo",
  "page-find-wallet-description-authereum": "Nema preuzimanja, nema sjemenskih fraza. Bilo koji preglednik, bilo kada, mobitel ili računalo",
  "page-wallets-accounts-addresses-desc-2": "je entitet koji može slati transakcije i ima saldo.",
  "page-wallets-accounts-has": "Račun Ethereuma ima",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "Adresa na Ethereumu",
  "page-wallets-ethereum-addresses-2": "je poput adrese e-pošte. Možete je upotrebljavati za slanje sredstava na račun.",
  "page-wallets-ethereum-wallet-2": "je proizvod koji vam omogućuje upravljanje Ethereum računom, poput pregleda stanja računa, slanja transakcija i još mnogo toga."
}
