{
  "1inch-logo": "1-calowe logo",
  "aave-logo": "Logo Aave",
  "about": "Informacje",
  "about-ethereum-org": "Informacje o ethereum.org",
  "about-us": "O nas",
  "alt-eth-blocks": "Illustration of blocks being organized like an ETH symbol",
  "aria-toggle-search-button": "Toggle search button",
  "aria-toggle-menu-button": "Toggle menu button",
  "zen-mode": "Zen Mode",
  "back-to-top": "Powrót na początek strony",
  "banner-page-incomplete": "Ta strona jest niekompletna. Jeśli jesteś ekspertem w temacie, edytuj tę stronę i wzbogać ją swą mądrością.",
  "beacon-chain": "Łańcuch śledzący",
  "binance-logo": "Logo Binance",
  "bittrex-logo": "Logo Bittrex",
  "brand-assets": "Aktywa marki",
  "bug-bounty": "Nagrody za bugi",
  "coinbase-logo": "Logo Coinbase",
  "coinmama-logo": "Logo Coinbase",
  "community": "Społeczność",
  "community-menu": "Community Menu",
  "compound-logo": "Logo Compound",
  "cons": "Zalety",
  "contact": "Kontakt",
  "content-versions": "Content Versions",
  "contributing": "Współpraca",
  "contributors": "Współpracownicy",
  "contributors-thanks": "Dziękujemy wszystkim, którzy przyczynili się do powstania tej strony!",
  "cookie-policy": "Polityka dotycząca plików cookie",
  "copied": "Skopiowane",
  "copy": "Kopiuj",
  "dark-mode": "Tryb ciemny",
  "data-provided-by": "Dane dostarczone przez",
  "decentralized-applications-dapps": "Aplikacje zdecentralizowane (dapps)",
  "deposit-contract": "Kontrakt depozytowy",
  "devcon": "Devcon",
  "developers": "Deweloperzy",
  "developers-home": "Strona główna dewelopera",
  "docs": "Dokumenty",
  "documentation": "Dokumentacja",
  "dydx-logo": "Logo Dydx",
  "ecosystem": "Ekosystem",
  "edit-page": "Edytuj stronę",
  "ef-blog": "Blog Fundacji Ethereum",
  "eips": "Propozycje ulepszeń w Ethereum",
  "enterprise": "Enterprise",
  "enterprise-menu": "Menu Enterprise",
  "esp": "Program wsparcia ekosystemów",
  "eth-current-price": "Aktualna cena ETH (USD)",
  "eth2": "Eth2",
  "eth2-beaconcha-in-desc": "Eksplorator open source łańcucha śledzącego Eth2",
  "eth2-beaconscan-desc": "Eksplorator open source łańcucha śledzącego Eth2 — Etherscan dla Eth2",
  "eth2-become-staker": "Zostań stakerem",
  "eth2-become-staker-desc": "Staking już jest! Jeśli chcesz stakować swoje ETH, by zabezpieczyć sieć, upewnij się, że zapoznałeś się z ryzykiem, jakie się z tym wiąże.",
  "eth2-explore": "Zbadaj dane",
  "eth2-no-action-needed": "Nie musisz nic robić z ETH, które już posiadasz. Uważaj na oszustów, którzy proszą Cię o wysłanie ETH w celu wymiany.",
  "eth2-run-beacon-chain": "Uruchom klienta łańcucha śledzącego",
  "eth2-run-beacon-chain-desc": "Ethereum potrzebuje jak największej liczby klientów. Pomóż w tym Ethereum!",
  "eth2-service-announcement": "Ogłoszenia serwisu Eth2",
  "eth2-what-shipping": "Kiedy to się stanie?",
  "ethereum": "Ethereum",
  "ethereum-2-0": "Ethereum 2.0",
  "ethereum-brand-assets": "Aktywa marki Ethereum",
  "ethereum-community": "Społeczność Ethereum",
  "ethereum-foundation": "Fundacja Ethereum",
  "ethereum-foundation-logo": "Logo fundacji Ethereum",
  "ethereum-glossary": "Słownik Ethereum",
  "ethereum-governance": "Ethereum Governance",
  "ethereum-logo": "Logo Ethereum",
  "ethereum-security": "Ethereum security and scam prevention",
  "ethereum-studio": "Studio Ethereum",
  "ethereum-wallets": "Portfele Ethereum",
  "ethereum-whitepaper": "Dokumentacja Ethereum",
  "example-projects": "Przykładowe projekty",
  "find-wallet": "Znajdź portfel",
  "foundation": "Fundacja",
  "gemini-logo": "Logo Gemini",
  "get-eth": "Zdobądź ETH",
  "get-involved": "Zaangażuj się",
  "get-started": "Rozpocznij",
  "gitcoin-logo": "Logo Gitcoin",
  "glossary": "Słownik",
  "governance": "Governance",
  "grants": "Grants",
  "grant-programs": "Ecosystem Grant Programs",
  "guides-and-resources": "Przewodniki i zasoby",
  "history": "Historia",
  "history-of-ethereum": "Historia Ethereum",
  "home": "Strona główna",
  "how-ethereum-works": "Jak działa Ethereum",
  "image": "obraz",
  "in-this-section": "W tej sekcji",
  "individuals": "Osoby",
  "individuals-menu": "Menu osoby",
  "jobs": "Jobs",
  "kraken-logo": "Logo Kraken",
  "language-ar": "Arabic",
  "language-bg": "Bulgarian",
  "language-bn": "Bengali",
  "language-ca": "Catalan",
  "language-cs": "Czech",
  "language-de": "German",
  "language-el": "Greek",
  "language-en": "English",
  "language-es": "Spanish",
  "language-fa": "Farsi",
  "language-fi": "Finnish",
  "language-fr": "French",
  "language-hu": "Hungarian",
  "language-hr": "Croatian",
  "language-hi": "Hindi",
  "language-id": "Indonesian",
  "language-ig": "Igbo",
  "language-it": "Italian",
  "language-ja": "Japanese",
  "language-ko": "Korean",
  "language-lt": "Lithuanian",
  "language-ml": "Malayalam",
  "language-nb": "Norwegian",
  "language-nl": "Dutch",
  "language-pl": "Polish",
  "language-pt": "Portuguese",
  "language-pt-br": "Portuguese (Brazilian)",
  "language-ro": "Romanian",
  "language-ru": "Russian",
  "language-se": "Swedish",
  "language-sk": "Slovak",
  "language-sl": "Slovenian",
  "language-support": "Wsparcie języków",
  "language-tr": "Turkish",
  "language-uk": "Ukranian",
  "language-vi": "Vietnamese",
  "language-zh": "Chinese Simplified",
  "language-zh-tw": "Chinese Traditional",
  "languages": "Języki",
  "last-24-hrs": "Ostatnie 24 godziny",
  "last-edit": "Ostatnia edycja",
  "learn": "Nauka",
  "learn-by-coding": "Nauka przez kodowanie",
  "learn-menu": "Menu nauki",
  "learn-more": "Dowiedz się więcej",
  "less": "Mniej",
  "light-mode": "Tryb jasny",
  "listing-policy-disclaimer": "Żadne produkty wymienione na tej stronie nie są oficjalnymi rekomendacjami i służą jedynie celom informacyjnym. Jeśli chcesz dodać produkt lub podzielić się opinią na temat tej polityki, zgłoś problem w GitHubi.",
  "listing-policy-raise-issue-link": "Zgłoś problem",
  "live-help": "Live help",
  "live-help-menu": "Live help menu",
  "loading": "Ładowanie...",
  "loading-error": "Błąd ładowania.",
  "loading-error-refresh": "Wystąpił błąd podczas ładowania danych, spróbuj odświeżyć stronę.",
  "logo": "logo",
  "loopring-logo": "Logo Loopring",
  "london-upgrade-banner": "The London Upgrade goes live in: ",
  "london-upgrade-banner-released": "The London Upgrade has been released!",
  "mainnet-ethereum": "Sieć główna Ethereum",
  "makerdao-logo": "Logo MakerDao",
  "matcha-logo": "Logo Matcha",
  "merge": "Merge",
  "more": "Więcej",
  "nav-beginners": "Początkujący",
  "next": "Dalej",
  "oasis-logo": "Logo Oasis",
  "on-this-page": "Na tej stronie",
  "page-content": "Treść strony",
  "page-enterprise": "Enterprise",
  "page-last-updated": "Strona ostatnio zaktualizowana",
  "previous": "Poprzednia",
  "privacy-policy": "Polityka prywatności",
  "private-ethereum": "Prywatne Ethereum",
  "pros": "Zalety",
  "read-more": "Czytaj więcej",
  "refresh": "Odśwież stronę.",
  "review-progress": "Przegląd postępów",
  "search": "Szukaj",
  "search-box-blank-state-text": "Szukaj!",
  "search-eth-address": "Wygląda to na adres Ethereum. Nie udostępniamy danych specyficznych dla adresów. Spróbuj wyszukać go na eksploratorze bloków, takim jak",
  "search-no-results": "Brak wyników wyszukiwania",
  "security": "Security",
  "see-contributors": "Zobacz współtwórców",
  "set-up-local-env": "Konfiguracja środowiska lokalnego",
  "shard-chains": "Łańcuchy odłamkowe",
  "show-all": "Pokaż wszystko",
  "show-less": "Pokaż mniej",
  "site-description": "Ethereum to globalna, zdecentralizowana platforma przeznaczona dla pieniędzy i nowych rodzajów aplikacji. Na Ethereum można pisać kod, który kontroluje pieniądze i budować aplikacje dostępne w dowolnym miejscu na świecie.",
  "site-title": "ethereum.org",
  "smart-contracts": "Smart contracts",
  "stablecoins": "Stablecoiny",
  "staking": "Staking",
  "summary": "Podsumowanie",
  "terms-of-use": "Warunki użytkowania",
  "transaction-fees": "Czym są opłaty transakcyjne?",
  "translation-banner-body-new": "Widzisz tę stronę w języku angielskim, ponieważ jeszcze nie została przetłumaczona. Pomóż nam ją przełożyć na język polski.",
  "translation-banner-body-update": "Dostępna jest nowsza wersja tej strony, ale tylko w języku angielskim. Pomóż nam przetłumaczyć najnowszą wersję.",
  "translation-banner-button-join-translation-program": "Dołącz do zespołu tłumaczeniowego",
  "translation-banner-button-learn-more": "Dowiedz się więcej",
  "translation-banner-button-see-english": "Patrz: angielski",
  "translation-banner-button-translate-page": "Przetłumacz stronę",
  "translation-banner-title-new": "Pomóż w przetłumaczeniu tej strony",
  "translation-banner-title-update": "Pomóż nam zaktualizować tę stronę",
  "translation-program": "Translation Program",
  "translation-progress": "Postęp tłumaczenia",
  "tutorials": "Samouczki",
  "uniswap-logo": "Logo Uniswap",
  "use": "Zastosowania",
  "use-ethereum": "Korzystanie z Ethereum",
  "use-ethereum-menu": "Korzystanie z menu Ethereum",
  "vision": "Wizja",
  "wallets": "Portfele",
  "website-last-updated": "Data ostatniej aktualizacji strony",
  "what-is-ether": "Czym jest eter (ETH)?",
  "what-is-ethereum": "Co to jest Ethereum?",
  "whitepaper": "Dokumentacja",
  "defi-page": "Decentralized finance (DeFi)",
  "dao-page": "Decentralized autonomous organisations (DAOs)",
  "nft-page": "Non-fungible tokens (NFTs)",
  "use_crypto": "Use Synquery",
  "page-about-h2": "Zaproponuj funkcję",
  "page-about-h3": "Praca w toku",
  "page-about-h3-1": "Wdrożone funkcje",
  "page-about-h3-2": "Planowane funkcje",
  "page-about-li-1": "w trakcie",
  "page-about-li-2": "zaplanowane",
  "page-about-li-3": "wdrożone",
  "page-about-li-4": "wdrożone",
  "page-about-link-1": "Kod źródłowy tego repozytorium jest na licencji MIT",
  "page-about-link-2": "GitHub",
  "page-about-link-3": "Zobacz pełną listę zadań w toku na GitHub",
  "page-about-link-4": "Dołącz do naszego serwera na Discord",
  "page-about-link-5": "Skontaktuj się z nami na Twitterze",
  "page-about-link-6": "Zobacz pełną listę zrealizowanych zadań na GitHub",
  "page-about-link-7": "Utwórz zgłoszenie na GitHub",
  "page-about-p-1": "Od momentu uruchomienia ethereum.org dokładamy wszelkich starań, aby nasze działania były przejrzyste. Jest to jedna z naszych podstawowych wartości, ponieważ uważamy, że przejrzystość ma kluczowe znaczenie dla sukcesu Ethereum.",
  "page-about-p-2": "Używamy",
  "page-about-p-3": "jako nasze podstawowe narzędzie do zarządzania projektami. Nasze zadania organizujemy w 3 kategoriach:",
  "page-about-p-4": "  Dokładamy wszelkich starań, aby na bieżąco informować społeczność o statusie danego zadania.",
  "page-about-p-5": "Zadania, które realizujemy.",
  "page-about-p-6": "Zadania oczekujące na wdrożenie.",
  "page-about-p-7": "Ostatnio ukończone zadania.",
  "page-about-p-8": "Masz pomysł, jak ulepszyć ethereum.org? Chcielibyśmy z Tobą współpracować!",
  "page-assets-bazaar": "Bazar Ethereum",
  "page-assets-beacon-chain": "Beacon Chain",
  "page-assets-blocks": "Bloki konstrukcyjne",
  "page-assets-dao": "DAO",
  "page-assets-defi": "DeFi",
  "page-assets-merge": "The Merge",
  "page-assets-doge": "Pieseł używający dapps",
  "page-assets-download-artist": "Autor:",
  "page-assets-download-download": "Pobierz",
  "page-assets-enterprise": "Przedsiębiorstwo Ethereum",
  "page-assets-eth": "Ether (ETH)",
  "page-assets-eth-diamond-color": "Diament ETH (kolor)",
  "page-assets-eth-diamond-glyph": "Diament ETH (glif)",
  "page-assets-eth-diamond-gray": "Diament ETH (szary)",
  "page-assets-eth-diamond-purple": "Diament ETH (fioletowy)",
  "page-assets-eth-diamond-white": "Diament ETH (biały)",
  "page-assets-eth-glyph-video-dark": "Wideo w glifie ETH (ciemne)",
  "page-assets-eth-glyph-video-light": "Wideo w glifie ETH (jasne)",
  "page-assets-eth-logo-landscape-gray": "Logo ETH poziomo (szare)",
  "page-assets-eth-logo-landscape-purple": "Logo ETH poziomo (fioletowe)",
  "page-assets-eth-logo-landscape-white": "Logo ETH poziomo (białe)",
  "page-assets-eth-logo-portrait-gray": "Logo ETH pionowo (szare)",
  "page-assets-eth-logo-portrait-purple": "Logo ETH pionowo (fioletowe)",
  "page-assets-eth-logo-portrait-white": "Logo ETH pionowo (białe)",
  "page-assets-eth-wordmark-gray": "Znak słowny ETH (szary)",
  "page-assets-eth-wordmark-purple": "Znak słowny ETH (fioletowy)",
  "page-assets-eth-wordmark-white": "Znak słowny ETH (biały)",
  "page-assets-ethereum-brand-assets": "Aktywa „marki” Ethereum",
  "page-assets-finance": "Finance",
  "page-assets-future": "Future",
  "page-assets-h1": "Aktywa ethereum.org",
  "page-assets-hero": "Główny obrazek ethereum.org",
  "page-assets-hero-particles": "Obraz ETH w postaci cząstek",
  "page-assets-historical-artwork": "Historyczna grafika",
  "page-assets-illustrations": "Ilustracje",
  "page-assets-impact": "Impact",
  "page-assets-infrastructure": "Infrastructure",
  "page-assets-meta-desc": "Poznaj i pobierz aktywa marki Ethereum i ethereum.org, ilustracje i multimedia.",
  "page-assets-meta-title": "Aktywa marki Ethereum",
  "page-assets-mainnet": "Mainnet",
  "page-assets-page-assets-solid-background": "Jednolite tło",
  "page-assets-page-assets-transparent-background": "Przezroczyste tło",
  "page-assets-robot": "Portfel robot",
  "page-assets-sharding": "Sharding",
  "page-assets-hackathon": "Hackathon",
  "page-dapps-1inch-logo-alt": "1-calowe logo",
  "page-dapps-aave-logo-alt": "Logo Aave",
  "page-dapps-add-button": "Zasugeruj dapp",
  "page-dapps-add-title": "Dodaj dapp",
  "page-dapps-audius-logo-alt": "Logo Augur",
  "page-dapps-augur-logo-alt": "Logo Augur",
  "page-dapps-axie-infinity-logo-alt": "Logo Axie Infinity",
  "page-dapps-brave-logo-alt": "Logo Brave",
  "page-dapps-category-arts": "Moda i styl",
  "page-dapps-category-browsers": "Przeglądarki",
  "page-dapps-category-collectibles": "Cyfrowe artykuły kolekcjonerskie",
  "page-dapps-category-competitive": "Konkurencja",
  "page-dapps-category-computing": "Narzędzia deweloperskie",
  "page-dapps-category-dex": "Zamiana tokenów",
  "page-dapps-category-investments": "Inwestycje",
  "page-dapps-category-lending": "Kredyty i pożyczki",
  "page-dapps-category-lottery": "Fundusze społecznościowe",
  "page-dapps-category-marketplaces": "Rynki",
  "page-dapps-category-music": "Muzyka",
  "page-dapps-category-payments": "Płatności",
  "page-dapps-category-insurance": "Insurance",
  "page-dapps-category-portfolios": "Portfolios",
  "page-dapps-category-trading": "Rynki handlowe i prognostyczne",
  "page-dapps-category-utilities": "Narzędzia",
  "page-dapps-category-worlds": "Wirtualne światy",
  "page-dapps-choose-category": "Wybierz kategorię",
  "page-dapps-collectibles-benefits-1-description": "Gdy dzieło sztuki jest tokenizowane na Ethereum, można udowodnić jego własność w sposób widoczny dla wszystkich. Można prześledzić drogę dzieła sztuki od momentu jego stworzenia do obecnego posiadacza. Zapobiega to fałszerstwom.",
  "page-dapps-collectibles-benefits-1-title": "Można udowodnić własność",
  "page-dapps-collectibles-benefits-2-description": "Płacenie za streaming muzyki lub zakup dzieł sztuki jest o wiele bardziej sprawiedliwe dla artystów. Dzięki Ethereum nie ma potrzeby korzystania z pośredników. A jeśli pośrednicy są potrzebni, ich koszty nie są tak wysokie, ponieważ platformy nie muszą płacić za infrastrukturę sieci.",
  "page-dapps-collectibles-benefits-2-title": "Sprawiedliwiej dla twórców",
  "page-dapps-collectibles-benefits-3-description": "Tokenizowane przedmioty kolekcjonerskie są powiązane z Twoim adresem Ethereum, a nie z platformą. Możesz więc sprzedawać rzeczy takie jak przedmioty w grze na dowolnym rynku Ethereum, nie tylko w samej grze.",
  "page-dapps-collectibles-benefits-3-title": "Przedmioty kolekcjonerskie zawsze podążają za Tobą",
  "page-dapps-collectibles-benefits-4-description": "Istnieją już narzędzia i produkty, które pozwolą Ci tokenizować Twoją sztukę i sprzedawać ją! A Twoje tokeny mogą być sprzedawane na każdej i wszystkich platformach kolekcjonerskich Ethereum.",
  "page-dapps-collectibles-benefits-4-title": "Infrastruktura jest już dostępna",
  "page-dapps-collectibles-benefits-description": "Są to aplikacje, które skupiają się na własności cyfrowej, zwiększaniu potencjału zarobkowego twórców oraz wymyślaniu nowych sposobów inwestowania w ulubionych twórców i ich pracę.",
  "page-dapps-collectibles-benefits-title": "zdecentralizowane przedmioty kolekcjonerskie i streaming",
  "page-dapps-collectibles-button": "Sztuka i przedmioty kolekcjonerskie",
  "page-dapps-collectibles-description": "Są to aplikacje, które skupiają się na własności cyfrowej, zwiększaniu potencjału zarobkowego twórców oraz wymyślaniu nowych sposobów inwestowania w ulubionych twórców i ich pracę.",
  "page-dapps-collectibles-title": "Zdecentralizowane przedmioty artystyczne i kolekcjonerskie",
  "page-dapps-compound-logo-alt": "Logo Compound",
  "page-dapps-cryptopunks-logo-alt": "Logo CryptoPunks",
  "page-dapps-cryptovoxels-logo-alt": "Logo Cryptovoxels",
  "page-dapps-dapp-description-1inch": "Pomaga uniknąć znaczących poślizgów cenowych dzięki agregacji najlepszych cen.",
  "page-dapps-dapp-description-aave": "Wypożycz swoje tokeny, aby zarobić odsetki i wypłacić je w dowolnym czasie.",
  "page-dapps-dapp-description-async-art": "Twórz, zbieraj i handluj #ProgrammableArt - obrazami cyfrowymi podzielonymi na \"Warstwy\", które możesz wykorzystać, aby wpłynąć na ogólny obraz. Każdy mistrz i warstwa jest tokenem ERC721.",
  "page-dapps-dapp-description-audius": "Zdecentralizowana platforma streamingowa. Słuchanie = pieniądze dla twórców, a nie etykiet.",
  "page-dapps-dapp-description-augur": "Obstawiaj wyniki sportowe, ekonomiczne i inne wydarzenia na świecie.",
  "page-dapps-dapp-description-axie-infinity": "Handluj i walcz z istotami zwanymi Axies.\nZarabiaj podczas grania — dostępne na urządzeniach mobilnych",
  "page-dapps-dapp-description-brave": "Zarabiaj tokeny za przeglądanie i wspieraj swoich ulubionych twórców.",
  "page-dapps-dapp-description-cent": "A social network where you earn money by posting NFTs.",
  "page-dapps-dapp-description-compound": "Wypożycz swoje tokeny, aby zarobić odsetki i wypłacić je w dowolnym czasie.",
  "page-dapps-dapp-description-cryptopunks": "Kupuj, składaj oferty i oferuj punki na sprzedaż — jednen z pierwszych tokenów kolekcjonerskich na Ethereum.",
  "page-dapps-dapp-description-cryptovoxels": "Stwórz galerię sztuki, buduj sklepy i kup ziemię — wirtualny świat Ethereum.",
  "page-dapps-dapp-description-dark-forest": "Podbijaj planety w nieskończonym, proceduralnie generowanym, kryptograficznie określonym wszechświecie.",
  "page-dapps-dapp-description-decentraland": "Zbieraj, handluj wirtualną ziemią w wirtualnym świecie, który możesz odkrywać.",
  "page-dapps-dapp-description-dydx": "Otwieraj krótkie pozycje lub pozycje z dźwignią finansową do 10x. Dostępne są również pożyczki i kredyty.",
  "page-dapps-dapp-description-ens": "Przyjazne dla użytkowników nazwy adresów Ethereum i zdecentralizowanych stron.",
  "page-dapps-dapp-description-foundation": "Inwestuj w unikalne edycje sztuki cyfrowej i handluj z innymi nabywcami.",
  "page-dapps-dapp-description-gitcoin": "Zarabiaj kryptowaluty, pracując nad oprogramowaniu open source.",
  "page-dapps-dapp-description-gitcoin-grants": "Finansowanie społecznościowe projektów społeczności Ethereum ze zwiększonym wkładem finansowym",
  "page-dapps-dapp-description-gods-unchained": "Strategiczna handlowa gra karciana. Grając, zdobywaj karty, które możesz sprzedać w prawdziwym życiu.",
  "page-dapps-dapp-description-golem": "Uzyskaj dostęp do współdzielonej mocy obliczeniowej lub wynajmuj własne zasoby.",
  "page-dapps-dapp-description-radicle": "Secure peer-to-peer code collaboration without intermediaries.",
  "page-dapps-dapp-description-loopring": "Platforma transakcyjna peer-to-peer została zbudowana dla szybkości.",
  "page-dapps-dapp-description-marble-cards": "Twórz unikalne karty cyfrowe oparte na adresach URL i handluj nimi.",
  "page-dapps-dapp-description-matcha": "Wyszukuje wiele giełd, aby pomóc Ci znaleźć najlepsze ceny.",
  "page-dapps-dapp-description-nifty-gateway": "Kupuj prace on-chain od najlepszych artystów, sportowców, marek i twórców.",
  "page-dapps-dapp-description-oasis": "Handluj, pożyczaj i oszczędzaj z Dai, stabilecoinem Ethereum.",
  "page-dapps-dapp-description-opensea": "Kupuj, sprzedaj, odkrywaj i handluj rzeczami o limitowanej edycji.",
  "page-dapps-dapp-description-opera": "Wyślij kryptowaluty ze swojej przeglądarki do sprzedawców, innych użytkowników i aplikacji.",
  "page-dapps-dapp-description-poap": "Collect NFTs proving you were at different virtual or in-person events. Use them to join raffles, vote, collaborate, or just to brag.",
  "page-dapps-dapp-description-polymarket": "Postaw na wyniki. Handluj na rynkach informacji.",
  "page-dapps-dapp-description-pooltogether": "Loteria, której nie możesz przegapić.\nNagrody przyznawane są co tydzień.",
  "page-dapps-dapp-description-index-coop": "A crypto index fund that gives your portfolio exposure to top DeFi tokens.",
  "page-dapps-dapp-description-nexus-mutual": "Coverage without the insurance company. Get protected against smart contract bugs and hacks.",
  "page-dapps-dapp-description-etherisc": "A decentralized insurance template anyone can use to create their own insurance coverage.",
  "page-dapps-dapp-description-zapper": "Track your portfolio and use a range of DeFi products from one interface.",
  "page-dapps-dapp-description-zerion": "Manage your portfolio and simply evaluate every single DeFi asset on the market.",
  "page-dapps-dapp-description-rotki": "Open source portfolio tracking, analytics, accounting and tax reporting tool that respects your privacy.",
  "page-dapps-dapp-description-rarible": "Twórz, sprzedawaj i kupuj tokenizowane przedmioty kolekcjonerskie.",
  "page-dapps-dapp-description-sablier": "Przesyłaj pieniądze w czasie rzeczywistym.",
  "page-dapps-dapp-description-superrare": "Kupuj dzieła cyfrowe bezpośrednio od artystów lub na rynkach wtórnych.",
  "page-dapps-dapp-description-token-sets": "Automatycznie rebalansujące strategie inwestowania kryptowalut.",
  "page-dapps-dapp-description-tornado-cash": "Wyślij anonimowe transakcje na Ethereum.",
  "page-dapps-dapp-description-uniswap": "Po prostu zamień tokeny lub udostępnij tokeny za % nagród.",
  "page-dapps-dark-forest-logo-alt": "Logo Dark Forest",
  "page-dapps-decentraland-logo-alt": "Logo Decentraland",
  "page-dapps-index-coop-logo-alt": "Index Coop logo",
  "page-dapps-nexus-mutual-logo-alt": "Nexus Mutual logo",
  "page-dapps-etherisc-logo-alt": "Etherisc logo",
  "page-dapps-zapper-logo-alt": "Zapper logo",
  "page-dapps-zerion-logo-alt": "Zerion logo",
  "page-dapps-rotki-logo-alt": "Rotki logo",
  "page-dapps-desc": "Znajdź aplikację Ethereum, aby wypróbować.",
  "page-dapps-doge-img-alt": "Ilustracja pieseła siedzącego przy komputerze",
  "page-dapps-dydx-logo-alt": "logo Dydx",
  "page-dapps-editors-choice-dark-forest": "Zagraj przeciwko innym, aby zdobyć planety i wypróbować wspaniałe skalowanie/technologię prywatności Ethereum. Może dla tych, którzy znają już Ethereum.",
  "page-dapps-editors-choice-description": "Kilka zdecentralizowanych aplikacji, które zespół ethereum.org uwielbia w tej chwili. Odkryj więcej zdecentralizowanych aplikacji poniżej.",
  "page-dapps-editors-choice-foundation": "Zainwestuj w kulturę. Kupuj, wymieniaj i sprzedawaj unikalne cyfrowe dzieła sztuki i modę od niesamowitych artystów, muzyków i marek.",
  "page-dapps-editors-choice-header": "Wybór redakcji",
  "page-dapps-editors-choice-pooltogether": "Kup bilet na loterię bez przegranych. Co tydzień, odsetki wygenerowane z całej puli losów trafiają do jednego szczęśliwego zwycięzcy. Odzyskaj swoje pieniądze kiedy tylko zechcesz.",
  "page-dapps-editors-choice-uniswap": "Z łatwością zamieniaj tokeny. Ulubiona aplikacja społeczności pozwalająca na handel tokenami z ludźmi w całej sieci.",
  "page-dapps-ens-logo-alt": "Nazwa serwisu logo Ethereum",
  "page-dapps-explore-dapps-description": "Wiele aplikacji zdecentralizowanych wciąż ma charakter eksperymentalny i testuje możliwości zdecentralizowanych sieci. Ale było kilka udanych wczesnych projektów w kategorii technologii, finansów, gier i przedmiotów kolekcjonerskich.",
  "page-dapps-explore-dapps-title": "Przeglądaj aplikacje",
  "page-dapps-features-1-description": "Po wdrożeniu w Ethereum kod aplikacji zdecentralizowanej nie może zostać usunięty i każdy może korzystać z jej funkcji. Nawet gdyby rozwiązał się zespół twórców aplikacji, rozwiązał się, nadal można by z niej korzystać. Raz umieszczona na Ethereum, pozostaje tam.",
  "page-dapps-features-1-title": "Brak właścicieli",
  "page-dapps-features-2-description": "Nie można zablokować możliwości korzystania z aplikacji zdecentralizowanej lub przesyłania transakcji. Na przykład, gdyby Twitter był na Ethereum, nikt nie mógłby zablokować Twojego konta lub powstrzymać Cię od tweetowania.",
  "page-dapps-features-2-title": "Brak cenzury",
  "page-dapps-features-3-description": "Ponieważ Ethereum ma ETH, płatności są natywne dla Ethereum. Deweloperzy nie muszą spędzać czasu na integracji z zewnętrznymi dostawcami płatności.",
  "page-dapps-features-3-title": "System płatności",
  "page-dapps-features-4-description": "Kod aplikacji zdecentralizowanej jest często otwarty i domyślnie kompatybilny. Zespoły regularnie korzystają z pracy innych zespołów. Jeśli chcesz, aby użytkownicy mogli wymieniać tokeny w Twojej aplikacji, możesz po prostu podłączyć kod innej aplikacji.",
  "page-dapps-features-4-title": "Plug and play",
  "page-dapps-features-5-description": "W przypadku większości aplikacji nie musisz ujawniać swojej rzeczywistej tożsamości. Twoje konto Ethereum jest Twoim loginem i potrzebujesz tylko portfela.",
  "page-dapps-features-5-title": "Jeden anonimowy login",
  "page-dapps-features-6-description": "Kryptografia zapewnia, że atakujący nie mogą sfałszować transakcji i innych interakcji aplikacji w Twoim imieniu. Autoryzujesz działania aplikacji za pomocą swojego konta Ethereum, zazwyczaj poprzez portfel, więc trzymaj swoje dane uwierzytelniające w bezpiecznym miejscu.",
  "page-dapps-features-6-title": "Zabezpieczone kryptograficznie",
  "page-dapps-features-7-description": "Gdy aplikacja jest już dostępna na Ethereum, przestanie działać tylko wtedy, gdy samo Ethereum przestanie działać. Sieci o wielkości Ethereum są wyjątkowo trudne do zaatakowania.",
  "page-dapps-features-7-title": "Brak przestojów",
  "page-dapps-finance-benefits-1-description": "Usługi finansowe działające na Ethereum nie wymagają rejestracji. Jeśli masz fundusze i połączenie z Internetem, możesz zaczynać.",
  "page-dapps-finance-benefits-1-title": "Otwarty dostęp",
  "page-dapps-finance-benefits-2-description": "Istnieje mnóstwo tokenów, z którymi można wchodzić w interakcje w ramach tych produktów finansowych. Ludzie cały czas tworzą nowe tokeny na bazie Ethereum.",
  "page-dapps-finance-benefits-2-title": "Nowa gospodarka tokenowa",
  "page-dapps-finance-benefits-3-description": "Zespoły stworzyły stablecoiny -- mniej zmienne kryptowaluty. Pozwalają one na eksperymentowanie i korzystanie z kryptowalut bez ryzyka i niepewności.",
  "page-dapps-finance-benefits-3-title": "Stablecoiny",
  "page-dapps-finance-benefits-4-description": "Wszystkie produkty finansowe w przestrzeni Ethereum są modułowe i wzajemnie kompatybilne. Nowe konfiguracje tych modułów trafiają na rynek cały czas, zwiększając to, co możesz zrobić ze swoją kryptowalutą.",
  "page-dapps-finance-benefits-4-title": "Powiązane usługi finansowe",
  "page-dapps-finance-benefits-description": "Co takiego jest w Ethereum, że umożliwia rozkwit zdecentralizowanych aplikacji finansowych?",
  "page-dapps-finance-benefits-title": "zdecentralizowane finanse",
  "page-dapps-finance-button": "Finanse",
  "page-dapps-finance-description": "Są to aplikacje, które koncentrują się na budowaniu usług finansowych przy użyciu kryptowalut. Oferują one takie możliwości jak udzielanie kredytów, pożyczek, zarabianie na odsetkach i prywatne płatności -- bez konieczności podawania danych osobowych.",
  "page-dapps-finance-title": "Zdecentralizowane finanse",
  "page-dapps-foundation-logo-alt": "Logo Fundacji",
  "page-dapps-gaming-benefits-1-description": "Bez względu na to, czy to wirtualna ziemia czy karty handlowe, Twoje przedmioty są zbywalne na rynkach kolekcjonerskich. Twoje przedmioty w grze mają wartość w świecie rzeczywistym.",
  "page-dapps-gaming-benefits-1-title": "Przedmioty z gier służą jako tokeny",
  "page-dapps-gaming-benefits-2-description": "To Ty jesteś właścicielem swoich przedmiotów, a w niektórych przypadkach także postępów, a nie firma odpowiedzialna za grę. Więc nie stracisz nic, jeśli firma odpowiedzialna za grę zostanie zaatakowana, doświadczy awarii serwera lub się rozpadnie.",
  "page-dapps-gaming-benefits-2-title": "Twoje zapisy są bezpieczne",
  "page-dapps-gaming-benefits-3-description": "W ten sam sposób, w jaki płatności Ethereum są dostępne dla każdego do sprawdzenia, gry mogą wykorzystać tę cechę do zapewnienia uczciwości. W teorii wszystko jest weryfikowalne, od liczby trafień krytycznych po wielkość skrzyni wojennej przeciwnika.",
  "page-dapps-gaming-benefits-3-title": "Zapewniona uczciwość",
  "page-dapps-gaming-benefits-description": "Co takiego jest w Ethereum, że pozwala na rozkwit zdecentralizowanych gier?",
  "page-dapps-gaming-benefits-title": "zdecentralizowane gry",
  "page-dapps-gaming-button": "Gry",
  "page-dapps-gaming-description": "Są to aplikacje, które skupiają się na tworzeniu wirtualnych światów i walce z innymi graczami przy użyciu przedmiotów kolekcjonerskich, które mają wartość w świecie rzeczywistym.",
  "page-dapps-gaming-title": "Zdecentralizowane gry",
  "page-dapps-get-some-eth-description": "Działania aplikacji wiążą się z opłatą transakcyjną",
  "page-dapps-get-started-subtitle": "Aby wypróbować aplikację, będziesz potrzebować portfela i trochę ETH. Portfel pozwoli Ci się połączyć lub zalogować. I będziesz potrzebować ETH, aby uregulować wszelkie opłaty transakcyjne.",
  "page-dapps-get-started-title": "Rozpocznij",
  "page-dapps-gitcoin-grants-logo-alt": "Logo Gitcoin Grants",
  "page-dapps-gitcoin-logo-alt": "Logo Gitcoin",
  "page-dapps-gods-unchained-logo-alt": "Logo Gods Unchained",
  "page-dapps-golem-logo-alt": "Logo Golem",
  "page-dapps-radicle-logo-alt": "Radicle logo",
  "page-dapps-hero-header": "Narzędzia i usługi oparte na Ethereum",
  "page-dapps-hero-subtitle": "Aplikacje zdecentralizowane stanowią rosnący trend wśród aplikacji wykorzystujących Ethereum do przekształcania modeli biznesowych lub tworzenia nowych.",
  "page-dapps-how-dapps-work-p1": "Dapps mają swój kod backendowy (inteligentne kontrakty) działający w zdecentralizowanej sieci, a nie na scentralizowanym serwerze. Używają one blockchainu Ethereum do przechowywania danych i inteligentnych kontraktów do logiki aplikacji.",
  "page-dapps-how-dapps-work-p2": "Inteligentny kontrakt jest jak zestaw reguł, które są dostępne on-chain dla wszystkich i działają dokładnie według tych reguł. Wyobraźcie sobie automat sprzedający: jeśli dostarczycie mu wystarczającą ilość środków i dokonacie właściwego wyboru, dostaniecie to, co chcecie. I tak jak automaty, inteligentne kontrakty mogą przechowywać środki podobnie jak konto Ethereum. Pozwala to kodowi pośredniczyć w umowach i transakcjach.",
  "page-dapps-how-dapps-work-p3": "Gdy aplikacje zostaną wdrożone w sieci Ethereum, nie można ich zmienić. Aplikacje mogą być zdecentralizowane, ponieważ są kontrolowane przez logikę zapisaną w kontrakcie, a nie przez osobę czy firmę.",
  "page-dapps-how-dapps-work-title": "Jak działają aplikacje zdecentralizowane",
  "page-dapps-learn-callout-button": "Zacznij tworzenie",
  "page-dapps-learn-callout-description": "Nasz portal dla deweloperów zawiera dokumenty, narzędzia i struktury, które pomogą Ci zacząć budować aplikacje.",
  "page-dapps-learn-callout-image-alt": "Ilustracja przedstawiająca dłoń tworzącą symbol ETH z klocków lego.",
  "page-dapps-learn-callout-title": "Naucz się tworzyć aplikacje",
  "page-dapps-loopring-logo-alt": "Logo Loopring",
  "page-dapps-magic-behind-dapps-description": "Aplikacje zdecentralizowane mogą wydawać się zwykłymi aplikacjami. Mają jednak pewne specjalne cechy, ponieważ dziedziczą wszystkie supermoce Ethereum. Dzięki temu aplikacje zdecentralizowane różnią się od zwykłych aplikacji.",
  "page-dapps-magic-behind-dapps-link": "Co decyduje o wspaniałości Ethereum?",
  "page-dapps-magic-behind-dapps-title": "Magia aplikacji zdecentralizowanych",
  "page-dapps-magic-title-1": "Magia",
  "page-dapps-magic-title-2": "za",
  "page-dapps-magician-img-alt": "Ilustracja przedstawiająca magików",
  "page-dapps-marble-cards-logo-alt": "Logo marble.cards",
  "page-dapps-matcha-logo-alt": "Logo Matcha",
  "page-dapps-mobile-options-header": "Przeglądaj inne kategorie",
  "page-dapps-nifty-gateway-logo-alt": "Logo Nifty Gateway",
  "page-dapps-oasis-logo-alt": "Logo Oasis",
  "page-dapps-opensea-logo-alt": "Logo OpenSea",
  "page-dapps-opera-logo-alt": "Logo Opera",
  "page-dapps-polymarket-logo-alt": "Logo Polymarket",
  "page-dapps-poap-logo-alt": "Proof of Attendance Protocol logo",
  "page-dapps-pooltogether-logo-alt": "Logo PoolTogether",
  "page-dapps-rarible-logo-alt": "Logo Rarible",
  "page-dapps-ready-button": "Przejdź",
  "page-dapps-ready-description": "Wybierz aplikację, aby wypróbować",
  "page-dapps-ready-title": "Gotów?",
  "page-dapps-sablier-logo-alt": "Logo Sablier",
  "page-dapps-set-up-a-wallet-button": "Znajdź portfel",
  "page-dapps-set-up-a-wallet-description": "Portfel jest twoim „loginem” dla aplikacji",
  "page-dapps-set-up-a-wallet-title": "Konfiguracja portfela",
  "page-dapps-superrare-logo-alt": "Logo SuperRare",
  "page-dapps-technology-button": "Technologia",
  "page-dapps-technology-description": "Są to aplikacje, które koncentrują się na decentralizacji narzędzi deweloperskich, włączaniu systemów kryptoekonomicznych do istniejących technologii oraz tworzeniu rynków dla prac rozwojowych typu open source.",
  "page-dapps-technology-title": "Technologia zdecentralizowana",
  "page-dapps-token-sets-logo-alt": "Logo Token Sets",
  "page-dapps-tornado-cash-logo-alt": "Logo Tornado cash",
  "page-dapps-uniswap-logo-alt": "Logo Uniswap",
  "page-dapps-wallet-callout-button": "Znajdź portfel",
  "page-dapps-wallet-callout-description": "Portfele są również aplikacjami. Znajdź jeden na podstawie funkcji, które Ci odpowiadają.",
  "page-dapps-wallet-callout-image-alt": "Ilustracja przedstawiająca robota.",
  "page-dapps-wallet-callout-title": "Wyświetl portfele",
  "page-dapps-warning-header": "Zawsze przeprowadzaj własne badania",
  "page-dapps-warning-message": "Ethereum to nowa technologia i większość aplikacji jest nowa. Przed wpłaceniem dużych ilości pieniędzy upewnij się, że rozumiesz ryzyko.",
  "page-dapps-what-are-dapps": "Czym są aplikacje zdecentralizowane?",
  "page-dapps-more-on-defi-button": "More on decentralized finance",
  "page-dapps-more-on-nft-button": "More on tokenised collectibles",
  "page-dapps-more-on-nft-gaming-button": "More on tokenised in-game items",
  "docs-nav-accounts": "Konta",
  "docs-nav-accounts-description": "Entities in the network that can hold a balance and send transactions",
  "docs-nav-advanced": "Zaawansowane",
  "docs-nav-backend-apis": "Backendowe API",
  "docs-nav-block-explorers": "Eksploratory bloków",
  "docs-nav-blocks": "Bloki",
  "docs-nav-blocks-description": "The way transactions are batched to ensure state is synchronised across all actors",
  "docs-nav-compiling-smart-contracts": "Kompilowanie inteligentnych kontraktów",
  "docs-nav-composability": "Komponowalność - o wzajemnej zależności komponentów",
  "docs-nav-consensus-mechanisms": "Mechanizmy konsensusu",
  "docs-nav-consensus-mechanisms-description": "How the individual nodes of a distributed network agree on the current state of the system",
  "docs-nav-data-and-analytics": "Dane i analityka",
  "docs-nav-data-and-analytics-description": "How blockchain data is aggregated, organized and implemented into dapps",
  "docs-nav-dart": "Dart",
  "docs-nav-delphi": "Delphi",
  "docs-nav-deploying-smart-contracts": "Wdrażanie inteligentnych kontraktów",
  "docs-nav-development-frameworks": "Ramy rozwojowe",
  "docs-nav-development-frameworks-description": "Tools that makes developing with Ethereum easier",
  "docs-nav-development-networks": "Sieci rozwojowe",
  "docs-nav-development-networks-description": "Local blockchain environments used to test dapps before deployment",
  "docs-nav-dot-net": ".NET",
  "docs-nav-erc-20": "ERC-20",
  "docs-nav-erc-721": "ERC-721",
  "docs-nav-erc-777": "ERC-777",
  "docs-nav-erc-1155": "ERC-1155",
  "docs-nav-ethereum-client-apis": "API klienta Ethereum",
  "docs-nav-ethereum-client-apis-description": "Convenience libraries that allow your web app to interact with Ethereum and smart contracts",
  "docs-nav-ethereum-stack": "Stack -- stos Ethereum",
  "docs-nav-evm": "Maszyna wirtualna Ethereum (EVM)",
  "docs-nav-evm-description": "The EVM handles all the computation on the Ethereum network",
  "docs-nav-foundational-topics": "Zagadnienia podstawowe",
  "docs-nav-gas": "Gaz",
  "docs-nav-gas-description": "Computational power required to process transactions, paid for in ETH by transaction senders",
  "docs-nav-golang": "Golang",
  "docs-nav-integrated-development-environments-ides": "Zintegrowane środowiska programistyczne (IDE)",
  "docs-nav-integrated-development-environments-ides-description": "The best environments to write dapp code",
  "docs-nav-intro-to-dapps": "Wprowadzenie do aplikacji zdecentralizowanych",
  "docs-nav-intro-to-dapps-description": "An introduction to decentralized applications",
  "docs-nav-intro-to-ether": "Intro to Ether",
  "docs-nav-intro-to-ether-description": "A quick overview of Ether",
  "docs-nav-intro-to-ethereum": "Wprowadzenie do Ethereum",
  "docs-nav-intro-to-ethereum-description": "A quick overview of Ethereum",
  "docs-nav-intro-to-the-stack": "Wprowadzenie do stosu",
  "docs-nav-intro-to-the-stack-description": "An overview of the Ethereum/web3 stack",
  "docs-nav-java": "Java",
  "docs-nav-java-script-apis": "Interfejsy API JavaScript",
  "docs-nav-javascript": "JavaScript",
  "docs-nav-json-rpc": "JSON-RPC",
  "docs-nav-mev": "Miner extractable value (MEV)",
  "docs-nav-mev-description": "How value is extracted from the Ethereum blockchain beyond the block reward",
  "docs-nav-mining": "Wydobywanie",
  "docs-nav-networks": "Sieci",
  "docs-nav-networks-description": "Implementations of Ethereum including test networks",
  "docs-nav-nodes-and-clients": "Węzły i klienci",
  "docs-nav-nodes-and-clients-description": "The individuals participating in the network and the software they run to verify transactions",
  "docs-nav-opcodes": "Opcodes",
  "docs-nav-run-a-node": "Run a node",
  "docs-nav-nodes-as-a-service": "Węzły jako usługa",
  "docs-nav-oracles": "Oracle",
  "docs-nav-oracles-description": "How information is injected into the Ethereum blockchain",
  "docs-nav-programming-languages": "Języki programowania",
  "docs-nav-programming-languages-description": "How to get started with Ethereum using languages you may already know",
  "docs-nav-proof-of-stake": "Proof-of-stake",
  "docs-nav-proof-of-work": "Proof-of-work",
  "docs-nav-python": "Python",
  "docs-nav-readme": "Plik readme",
  "docs-nav-rust": "Rust",
  "docs-nav-scaling": "Skalowanie",
  "docs-nav-scaling-description": "Methods for preserving decentralization and security as Ethereum grows",
  "docs-nav-scaling-layer-2": "Layer 2 Rollups",
  "docs-nav-scaling-channels": "State channels",
  "docs-nav-scaling-sidechains": "Sidechains",
  "docs-nav-scaling-plasma": "Plasma",
  "docs-nav-scaling-validium": "Validium",
  "docs-nav-smart-contract-security": "Ochrona",
  "docs-nav-smart-contract-security-description": "Best practices for managing smart contract attacks and vulnerabilities",
  "docs-nav-smart-contract-anatomy": "Anatomia inteligentnego kontraktu",
  "docs-nav-smart-contract-languages": "Języki inteligentnego kontraktu",
  "docs-nav-smart-contracts": "Inteligentne kontrakty",
  "docs-nav-smart-contracts-description": "Programs that reside at an Ethereum address and run functions when triggered by transactions",
  "docs-nav-smart-contracts-libraries": "Biblioteki inteligentnych kontraktów",
  "docs-nav-standards": "Standardy",
  "docs-nav-standards-description": "Agreed upon protocols for maintaining efficiency and accessibility of projects to the community",
  "docs-nav-storage": "Przechowywanie",
  "docs-nav-storage-description": "Decentralized storage structures and mechanism",
  "docs-nav-testing-smart-contracts": "Testowanie inteligentnych kontraktów",
  "docs-nav-token-standards": "Standardy tokenów",
  "docs-nav-transactions": "Transakcje",
  "docs-nav-transactions-description": "Transfers and other actions that cause Ethereum's state to change",
  "docs-nav-web2-vs-web3": "Web2 w porównaniu z Web3",
  "docs-nav-web2-vs-web3-description": "The fundamental differences that blockchain-based applications provide",
  "page-calltocontribute-desc-1": "Jeśli jesteś ekspertem w temacie i chcesz podzielić się wiedzą, edytuj tę stronę i wzbogać ją swoją mądrością.",
  "page-calltocontribute-desc-2": "Zyskasz uznanie i pomożesz społeczności Ethereum!",
  "page-calltocontribute-desc-3": "Użyj tego elastycznego",
  "page-calltocontribute-desc-4": "Pytania? Zadaj je na kanale #content na naszym",
  "page-calltocontribute-link": "szablonu dokumentacji",
  "page-calltocontribute-link-2": "serwerze Discord",
  "page-calltocontribute-span": "Edytuj stronę",
  "page-calltocontribute-title": "Pomóż nam zaktualizować tę stronę",
  "page-developer-meta-title": "Zasoby Ethereum dla deweloperów",
  "page-developers-about": "Więcej informacji o zasobach dla deweloperów",
  "page-developers-about-desc": "ethereum.org zawiera zasoby, które pomogą Ci tworzyć Ethereum. Znajdziesz tu dokumentację dotyczącą założeń koncepcyjnych, a także podstawowe narzędzia deweloperskie. Możesz też skorzystać z samouczków, aby od razu rozpocząć pracę.",
  "page-developers-about-desc-2": "Do stworzenia tych zasobów zainspirowała nas Mozilla Developer Network (MDN). Uznaliśmy, że Ethereum także potrzebuje jednego miejsca, z którego można pobrać wszystkie narzędzia i zasoby programistyczne. Podobnie jak u naszych kolegów odpowiedzialnych za Mozillę, wszystkie udostępniane składniki mają licencję open-source i mogą posłużyć Ci jako platforma do wprowadzania ulepszeń i poprawek.",
  "page-developers-account-desc": "Kontrakty lub osoby w sieci",
  "page-developers-accounts-link": "Konta",
  "page-developers-advanced": "Zaawansowane",
  "page-developers-api-desc": "Używanie bibliotek do interakcji z inteligentnymi kontraktami",
  "page-developers-api-link": "Backendowe API",
  "page-developers-aria-label": "Strona główna dewelopera",
  "page-developers-block-desc": "Partie transakcji dodane do blockchain",
  "page-developers-block-explorers-desc": "Twój portal do danych Ethereum",
  "page-developers-block-explorers-link": "Eksplorator bloków",
  "page-developers-blocks-link": "Bloki",
  "page-developers-browse-tutorials": "Przeglądaj samouczki",
  "page-developers-choose-stack": "Wybierz swoje narzędzia",
  "page-developers-contribute": "Weź udział",
  "page-developers-dev-env-desc": "IDE, które nadają się do tworzenia dapp",
  "page-developers-dev-env-link": "Środowiska programistyczne",
  "page-developers-discord": "Dołącz do kanału Discord",
  "page-developers-docs-introductions": "Wprowadzenie",
  "page-developers-evm-desc": "Maszyna, która przetwarza transakcje",
  "page-developers-evm-link": "Maszyna wirtualna Ethereum (EVM)",
  "page-developers-explore-documentation": "Przejrzyj dokumentację",
  "page-developers-feedback": "Jeśli chcesz nam przekazać swoją opinię, jesteśmy dostępni poprzez GitHub lub na serwerze Discord.",
  "page-developers-frameworks-desc": "Narzędzia służące przyspieszeniu rozwoju",
  "page-developers-frameworks-link": "Ramy rozwojowe",
  "page-developers-fundamentals": "Podstawy",
  "page-developers-gas-desc": "Ether potrzebny do zasilania transakcji",
  "page-developers-gas-link": "Paliwo",
  "page-developers-get-started": "Jak chcesz rozpocząć?",
  "page-developers-improve-ethereum": "Pomóż nam ulepszyć ethereum.org",
  "page-developers-improve-ethereum-desc": "Podobnie jak ethereum.org, te dokumenty są wynikiem wspólnych wysiłków naszej społeczności. Utwórz PR, jeśli widzisz jakiś błąd albo uważasz, że coś się da poprawić lub gdy widzisz nowe szanse rozwoju dla deweloperów Ethereum.",
  "page-developers-into-eth-desc": "Wprowadzenie do blockchain i Ethereum",
  "page-developers-intro-ether-desc": "An introduction to cryptocurrency and Ether",
  "page-developers-intro-dapps-desc": "Wprowadzenie do zdecentralizowanych aplikacji",
  "page-developers-intro-dapps-link": "Wprowadzenie do dapps",
  "page-developers-intro-eth-link": "Wprowadzenie do Ethereum",
  "page-developers-intro-ether-link": "Intro to Ether",
  "page-developers-intro-stack": "Wprowadzenie do stosu",
  "page-developers-intro-stack-desc": "Wprowadzenie do stosu Ethereum",
  "page-developers-js-libraries-desc": "Używanie javascript do interakcji z inteligentnymi kontraktami",
  "page-developers-js-libraries-link": "Biblioteki Javascript",
  "page-developers-language-desc": "Używanie Ethereum z językami, które są Ci znane",
  "page-developers-languages": "Języki programowania",
  "page-developers-learn": "Naucz się, jak tworzyć Ethereum",
  "page-developers-learn-desc": "Dowiedz się więcej o podstawowych pojęciach i stosie Ethereum z naszej dokumentacji",
  "page-developers-learn-tutorials": "Ucz się, korzystając z samouczków",
  "page-developers-learn-tutorials-cta": "Zobacz samouczki",
  "page-developers-learn-tutorials-desc": "Naucz się rozwoju Ethereum. Krok po kroku od twórców, którzy już to umieją.",
  "page-developers-meta-desc": "Przewodniki, zasoby i narzędzia dla programistów budujących Ethereum.",
  "page-developers-mev-desc": "An introduction to miner extractable value (MEV)",
  "page-developers-mev-link": "Miner extractable value (MEV)",
  "page-developers-mining-desc": "Jak tworzy się nowe bloki i osiąga konsensus",
  "page-developers-mining-link": "Wydobywanie",
  "page-developers-networks-desc": "Przegląd sieci głównych i sieci testowych",
  "page-developers-networks-link": "Sieci",
  "page-developers-node-clients-desc": "W jaki sposób bloki i transakcje są weryfikowane w sieci",
  "page-developers-node-clients-link": " Węzły i klienci",
  "page-developers-oracle-desc": "Pobieranie danych poza łańcuchem dostaw do inteligentnych kontraktów",
  "page-developers-oracles-link": "Oracle",
  "page-developers-play-code": "Gra z kodem",
  "page-developers-read-docs": "Przeczytaj dokumenty",
  "page-developers-scaling-desc": "Rozwiązania dla szybszych transakcji",
  "page-developers-scaling-link": "Skalowanie",
  "page-developers-smart-contract-security-desc": "Środki bezpieczeństwa do rozważenia podczas tworzenia",
  "page-developers-smart-contract-security-link": "Ochrona",
  "page-developers-set-up": "Konfiguracja środowiska lokalnego",
  "page-developers-setup-desc": "Przygotuj swój stos, odpowiednio konfigurując środowisko programistyczne.",
  "page-developers-smart-contracts-desc": "Logika leżąca u podstaw dapps – samorealizujące się umowy",
  "page-developers-smart-contracts-link": "Inteligentne kontrakty",
  "page-developers-stack": "Stos",
  "page-developers-start": "Rozpocznij eksperymentowanie",
  "page-developers-start-desc": "Czy najpierw chcesz poeksperymentować, a pytania zadawać później?",
  "page-developers-storage-desc": "Jak obsługiwać pamięć dapp",
  "page-developers-storage-link": "Pamięć",
  "page-developers-subtitle": "Podręcznik dla programistów tworzących Ethereum. Stworzony przez twórców, dla twórców.",
  "page-developers-title-1": "Ethereum",
  "page-developers-title-2": "deweloper",
  "page-developers-title-3": "źródła",
  "page-developers-token-standards-desc": "Przegląd przyjętych standardów tokenów",
  "page-developers-token-standards-link": "Standardy tokenów",
  "page-developers-transactions-desc": "Sposób, w jaki zmienia się stan Ethereum",
  "page-developers-transactions-link": "Transakcje",
  "page-developers-web3-desc": "Różnice istniejące w rozwoju sieci 3",
  "page-developers-web3-link": "Web2 vs Web3",
  "page-learning-tools-bootcamps": "Szkolenia dla deweloperów",
  "page-learning-tools-bootcamps-desc": "Płatne kursy online, dzięki którym szybko będziesz na bieżąco.",
  "page-learning-tools-browse-docs": "Przeglądaj dokumenty",
  "page-learning-tools-capture-the-ether-description": "Capture the Ether is a game in which you hack Ethereum smart contracts to learn about security.",
  "page-learning-tools-capture-the-ether-logo-alt": "Capture the Ether logo",
  "page-learning-tools-chainshot-description": "Remote, instructor-led Ethereum developer bootcamp and additional courses.",
  "page-learning-tools-chainshot-logo-alt": "Logo ChainShot",
  "page-learning-tools-coding": "Nauka przez kodowanie",
  "page-learning-tools-coding-subtitle": "Jeżeli wolisz naukę poprzez interakcję, te narzędzia pomogą ci w eksperymentowaniu z Ethereum.",
  "page-learning-tools-consensys-academy-description": "Szkolenia deweloperskie z Ethereum online.",
  "page-learning-tools-consensys-academy-logo-alt": "Logo ConsenSys Academy",
  "page-learning-tools-lambdaschool-description": "Learn Ethereum Blockchain development.",
  "page-learning-tools-lambdaschool-logo-alt": "Lambda School logo",
  "page-learning-tools-buildspace-description": "Learn about crypto by building cool projects.",
  "page-learning-tools-buildspace-logo-alt": "_buildspace Logo",
  "page-learning-tools-cryptozombies-description": "Naucz się Solidity tworząc własną grę o Zombie.",
  "page-learning-tools-cryptozombies-logo-alt": "Logo CryptoZombies",
  "page-learning-tools-documentation": "Dowiedz się więcej dzięki dokumentacji",
  "page-learning-tools-documentation-desc": "Chcesz dowiedzieć się więcej? Przejdź do dokumentacji, żeby znaleźć potrzebne informacje.",
  "page-learning-tools-eth-dot-build-description": "Edukacyjna piaskownica dla web3, zawierająca programowanie metodą „przeciągnij i upuść” oraz bloki konstrukcyjne open-source.",
  "page-learning-tools-eth-dot-build-logo-alt": "Logo Eth.build",
  "page-learning-tools-ethernauts-description": "Zaliczaj poziomy, hakując inteligentne kontrakty.",
  "page-learning-tools-ethernauts-logo-alt": "Logo Ethernauts",
  "page-learning-tools-game-tutorials": "Interaktywne samouczki do gier",
  "page-learning-tools-game-tutorials-desc": "Ucz się, grając. Dzięki tym samouczkom nauczysz się podstaw podczas rozgrywki.",
  "page-learning-tools-meta-desc": "Narzędzia internetowe do kodowania i interaktywne środowiska edukacyjne, które pomogą Ci eksperymentować z rozwojem Ethereum.",
  "page-learning-tools-meta-title": "Narzędzia edukacyjne dla deweloperów",
  "page-learning-tools-questbook-description": "Self paced tutorials to learn Web 3.0 by building",
  "page-learning-tools-questbook-logo-alt": "Questbook Logo",
  "page-learning-tools-remix-description": "Rozwijaj inteligentne kontrakty dla Ethereum, wdrażaj je i zarządzaj nimi. Korzystaj z samouczków za pomocą wtyczki Learneth.",
  "page-learning-tools-remix-description-2": "Remix to nie tylko piaskownica. Wielu deweloperów pisze, kompiluje i wdraża swoje inteligentne kontrakty za pomocą Remixa.",
  "page-learning-tools-remix-logo-alt": "Logo Remix",
  "page-learning-tools-sandbox": "Piaskownice kodu",
  "page-learning-tools-sandbox-desc": "Te piaskownice dadzą Ci przestrzeń do eksperymentowania z pisaniem inteligentnych kontraktów i zrozumieniem Ethereum.",
  "page-learning-tools-studio-description": "Oparte na Internecie IDE, w którym możesz korzystać z samouczków do tworzenia i testowania inteligentnych kontraktów, a także budować dla nich frontend.",
  "page-learning-tools-vyperfun-description": "Naucz się Vyper, tworząc własną grę w Pokémony.",
  "page-learning-tools-vyperfun-logo-alt": "Logo Vyper.fun",
  "page-learning-tools-nftschool-description": "Explore what's going on with non-fungible tokens, or NFTs from the technical side.",
  "page-learning-tools-nftschool-logo-alt": "NFT school Logo",
  "page-local-environment-brownie-desc": "Oparta na języku Python platforma programistyczna i testowa dla inteligentnych kontraktów ukierunkowana na maszynę wirtualną Ethereum.",
  "page-local-environment-brownie-logo-alt": "Logo Brownie",
  "page-local-environment-dapptools-desc": "A suite of Ethereum focused CLI tools following the Unix design philosophy, favoring composability, configurability and extensibility.",
  "page-local-environment-dapptools-logo-alt": "Dapptools logo",
  "page-local-environment-embark-desc": "Platforma deweloperska typu „wszystko w jednym” do budowania i wdrażania zdecentralizowanych aplikacji.",
  "page-local-environment-embark-logo-alt": "Logo Embark",
  "page-local-environment-epirus-desc": "Platforma twoerzenia, wdrażania i monitorowania aplikacji blockchain w maszynie wirtualnej Java",
  "page-local-environment-epirus-logo-alt": "Logo Epirus",
  "page-local-environment-eth-app-desc": "Twórz za pomocą jednego polecenia aplikacje działające w oparciu o Ethereum. Zawiera szeroką ofertę platform UI i szablonów DeFi do wyboru.",
  "page-local-environment-eth-app-logo-alt": "Utwórz logo aplikacji Eth",
  "page-local-environment-framework-feature-1": "Funkcje do uruchomienia lokalnej instancji blockchain.",
  "page-local-environment-framework-feature-2": "Narzędzia do kompilacji i testowania Twoich inteligentnych kontraktów.",
  "page-local-environment-framework-feature-3": "Dodatki programistyczne do tworzenia aplikacji użytkownika w ramach tego samego projektu/repozytorium.",
  "page-local-environment-framework-feature-4": "Konfiguracja do łączenia się z sieciami Ethereum i wdrażania kontraktów, zarówno do lokalnie działającej instancji, jak i do jednej z publicznych sieci Ethereum.",
  "page-local-environment-framework-feature-5": "Zdecentralizowana dystrybucja aplikacji -- integracja z opcjami przechowywania, takimi jak IPFS.",
  "page-local-environment-framework-features": "Te frameworki mają wiele gotowych funkcji, takich jak:",
  "page-local-environment-frameworks-desc": " Zalecamy wybranie frameworka, szczególnie jeśli dopiero zaczynasz. Zbudowanie pełnoprawnej zdecentralizowanej aplikacji (dapp) wymaga różnych elementów technologii. Frameworki zawierają wiele potrzebnych funkcji lub zapewniają łatwe systemy wtyczek do wyboru pożądanych narzędzi.",
  "page-local-environment-frameworks-title": "Frameworki i wcześniej przygotowane stosy",
  "page-local-environment-hardhat-desc": "Hardhat to rozwojowe środowisko programistyczne Ethereum dla profesjonalistów.",
  "page-local-environment-hardhat-logo-alt": "Logo Hardhat",
  "page-local-environment-openZeppelin-desc": "Zaoszczędź godziny pracy programistycznej, kompilując, aktualizując, wdrażając i wchodząc w interakcję z inteligentnymi umowami za pomocą naszego CLI.",
  "page-local-environment-openZeppelin-logo-alt": "Logo OpenZeppelin",
  "page-local-environment-scaffold-eth-desc": "Hardhat + Utwórz Eth App: wszystko, czego potrzebujesz, aby rozpocząć tworzenie zdecentralizowanych aplikacji opartych na inteligentnych kontraktach",
  "page-local-environment-scaffold-eth-logo-alt": "logo scaffold-eth",
  "page-local-environment-setup-meta-desc": "Wskazówki dotyczące wyboru stosu oprogramowania dla rozwoju Ethereum.",
  "page-local-environment-setup-meta-title": "Konfiguracja lokalnego rozwoju Ethereum",
  "page-local-environment-setup-subtitle": "Jeśli jesteś gotowy do rozpoczęcia budowy, nadszedł czas, aby wybrać swój stos.",
  "page-local-environment-setup-subtitle-2": " Oto narzędzia i frameworki, których możesz użyć, aby łatwiej zbudować swoją aplikację Ethereum.",
  "page-local-environment-setup-title": "Skonfiguruj swoje lokalne środowisko programistyczne",
  "page-local-environment-solidity-template-desc": "Szablon GitHub gotowej konfiguracji inteligentnych kontraktów Solidity. Zawiera sieć lokalną Hardhat, Waffle do testów, etery do implementacji portfela i wiele innych.",
  "page-local-environment-solidity-template-logo-alt": "Logo Solidity template",
  "page-local-environment-truffle-desc": "Truffle Suite umożliwia programistom przejście od pomysłu do zdecentralizowanej aplikacji tak wygodnie, jak to tylko możliwe.",
  "page-local-environment-truffle-logo-alt": "Logo Truffle",
  "page-local-environment-waffle-desc": "Najbardziej zaawansowana baza testowa inteligentnych kontraktów. Używaj samodzielnie lub ze Scaffold-eth lub Hardhat.",
  "page-local-environment-waffle-logo-alt": "Logo Waffle",
  "comp-tutorial-metadata-minute-read": "minuta czytania",
  "page-tutorial-listing-policy-intro": "Przed przesłaniem samouczka zapoznaj się z naszą polityką publikacji.",
  "comp-tutorial-metadata-tip-author": "Autor porady",
  "page-tutorial-listing-policy": "polityka publikowania artykułów",
  "page-tutorial-new-github": "Nowy na GitHub?",
  "page-tutorial-new-github-desc": "Zgłoś problem -- wystarczy wpisać żądane informacje i wkleić samouczek.",
  "page-tutorial-pull-request": "Utwórz pull request",
  "page-tutorial-pull-request-btn": "Utwórz pull request",
  "page-tutorial-pull-request-desc-1": "Postępuj zgodnie ze strukturą nazewnictwa",
  "page-tutorial-pull-request-desc-2": "tutorials/nazwa-twojego-samouczka/index.md",
  "page-tutorial-pull-request-desc-3": ".",
  "page-tutorial-raise-issue-btn": "Zgłoś problem",
  "page-tutorial-read-time": "min",
  "page-tutorial-submit-btn": "Prześlij samouczek",
  "page-tutorial-submit-tutorial": "Aby przesłać samouczek, musisz użyć GitHuba. Zapraszamy do utworzenia zgłoszenia lub pull requesta.",
  "page-tutorial-subtitle": "Zapraszamy do naszej listy samouczków społecznościowych.",
  "page-tutorial-tags-error": "Żadne samouczki nie mają tych wszystkich tagów",
  "page-tutorial-title": "Samouczki na temat rozwoju Ethereum",
  "page-tutorials-meta-description": "Przeglądaj i filtruj sprawdzone samouczki społeczności Ethereum według tematu.",
  "page-tutorial-external-link": "External",
  "page-tutorials-meta-title": "Samouczki na temat rozwoju Ethereum",
  "page-eth-buy-some": "Chcesz kupić trochę Ethereum?",
  "page-eth-buy-some-desc": "Powszechne jest mylenie Ethereum i ETH. Ethereum to blockchain, a ETH to podstawowy składnik aktywów Ethereum. ETH jest tym, co prawdopodobnie chcesz kupić.",
  "page-eth-cat-img-alt": "Grafika glifu ETH z kalejdoskopem kotów",
  "page-eth-collectible-tokens": "Tokeny przedmiotów kolekcjonerskich",
  "page-eth-collectible-tokens-desc": "Tokeny reprezentujące przedmioty kolekcjonerskie, sztukę cyfrową lub inne wyjątkowe aktywa. Często znane jako tokeny niewymienialne (NFT).",
  "page-eth-cryptography": "Zabezpieczone kryptograficznie",
  "page-eth-cryptography-desc": "Pieniądze internetowe mogą być nowe, ale są zabezpieczone sprawdzoną kryptografią. To chroni Twój portfel, ETH i Twoje transakcje. ",
  "page-eth-currency-for-apps": "To jest waluta aplikacji Ethereum.",
  "page-eth-currency-for-future": "Waluta na naszą cyfrową przyszłość",
  "page-eth-description": "ETH jest kryptowalutą. Są to rzadkie cyfrowe pieniądze, z których możesz korzystać w Internecie, podobnie jak z bitcoinów. Jeśli jesteś nowy w kryptowalutach, oto jak ETH różni się od tradycyjnych pieniędzy.",
  "page-eth-earn-interest-link": "Zarabiaj odsetki",
  "page-eth-ethhub-caption": "Często aktualizowane",
  "page-eth-ethhub-overview": "EthHub oferuje świetny przegląd jeśli chcesz",
  "page-eth-flexible-amounts": "Dostępne w postaci kwot elastycznych",
  "page-eth-flexible-amounts-desc": "ETH jest podzielny do 18 miejsc po przecinku, więc nie musisz kupować 1 całego ETH. Możesz kupować jednorazowo części -- jeśli chcesz, to nawet 0,000000000000000001 ETH.",
  "page-eth-fuels": "Paliwa ETH i zabezpieczenia Ethereum",
  "page-eth-fuels-desc": "ETH jest siłą napędową Ethereum. Kiedy wysyłasz ETH lub korzystasz z aplikacji Ethereum, wnosisz niewielką opłatę w ETH za korzystanie z sieci Ethereum. Ta opłata jest zachętą dla górnika do przetwarzania i weryfikacji tego, co próbujesz zrobić.",
  "page-eth-fuels-desc-2": "Górnicy są jak właściciele rejestrów Ethereum – sprawdzają i dowodzą, że nikt nie oszukuje. Górnicy, którzy wykonują tę pracę, są również nagradzani niewielkimi kwotami nowo wyemitowanych ETH.",
  "page-eth-fuels-desc-3": "Praca górników sprawia, że Ethereum jest bezpieczne i wolne od scentralizowanej kontroli. Innymi słowy,",
  "page-eth-fuels-more-staking": "Więcej o stakowaniu",
  "page-eth-fuels-staking": "ETH nabierze jeszcze większego znaczenia dzięki stakowaniu. Jeśli zestakujesz swój ETH, będziesz mógł pomóc w zabezpieczeniu Ethereum i zdobyć nagrody. W tym systemie groźba utraty ETH zniechęca do ataków.",
  "page-eth-get-eth-btn": "Zdobądź ETH",
  "page-eth-gov-tokens": "Tokeny zarządzania",
  "page-eth-gov-tokens-desc": "Tokeny, które reprezentują siłę głosu w zdecentralizowanych organizacjach.",
  "page-eth-has-value": "Dlaczego ETH ma wartość?",
  "page-eth-has-value-desc": "ETH jest cenny na różne sposoby dla różnych ludzi.",
  "page-eth-has-value-desc-2": "Dla użytkowników Ethereum ETH jest wartościowy, ponieważ pozwala na regulowanie opłat za transakcje.",
  "page-eth-has-value-desc-3": "Inni postrzegają go jako cyfrowy magazyn wartości, ponieważ tworzenie nowych ETH z czasem zwalnia.",
  "page-eth-has-value-desc-4": "Ostatnio ETH stał się cenny dla użytkowników aplikacji finansowych na Ethereum. To dlatego, że możesz użyć ETH jako zabezpieczenia kredytów kryptowalutowych lub jako systemu płatności.",
  "page-eth-has-value-desc-5": "Oczywiście wielu użytkowników postrzega ETH również jako inwestycję podobną do bitcoina lub innych kryptowalut.",
  "page-eth-how-to-buy": "Jak kupić Ether",
  "page-eth-how-to-buy-caption": "Często aktualizowane",
  "page-eth-is-money": "ETH to cyfrowy, globalny pieniądz.",
  "page-eth-last-updated": "Styczeń 2019",
  "page-eth-mining-link": "Więcej o wydobywaniu",
  "page-eth-monetary-policy": "Polityka pieniężna Ethereum",
  "page-eth-more-on-ethereum-link": "Więcej o Ethereum",
  "page-eth-no-centralized": "Bez scentralizowanej kontroli ",
  "page-eth-no-centralized-desc": "ETH jest zdecentralizowany i globalny. Nie ma firmy ani banku, który zdecydowałby się wydrukować więcej ETH lub zmienić warunki użytkowania.",
  "page-eth-non-fungible-tokens-link": "Tokeny niewymienialne",
  "page-eth-not-only-crypto": "ETH nie jest jedyną kryptowalutą na Ethereum",
  "page-eth-not-only-crypto-desc": "Każdy może tworzyć nowe rodzaje aktywów i handlować nimi na Ethereum. Są one znane jako „tokeny”. Ludzie tokenizują tradycyjne waluty, swoje nieruchomości, swoją sztukę, a nawet samych siebie!",
  "page-eth-not-only-crypto-desc-2": "Ethereum jest domem dla tysięcy tokenów -- niektóre są bardziej użyteczne i cenniejsze niż inne. Deweloperzy stale tworzą nowe tokeny, które otwierają nowe możliwości i nowe rynki.",
  "page-eth-not-only-crypto-desc-3": "Jeśli chcesz dowiedzieć się więcej o tokenach, nasi przyjaciele w EthHub zamieścili kilka świetnych opisów:",
  "page-eth-open": "Otwarte dla każdego",
  "page-eth-open-desc": "Potrzebujesz tylko połączenia z Internetem i portfela do akceptowania ETH. Nie potrzebujesz dostępu do konta bankowego, aby akceptować płatności. ",
  "page-eth-p2p-payments": "Płatności peer-to-peer",
  "page-eth-p2p-payments-desc": "Możesz wysłać ETH bez żadnej usługi pośredniczącej, jak bank. To jak przekazywanie gotówki osobiście, ale możesz to robić bezpiecznie z każdym, gdziekolwiek, w dowolnym momencie.",
  "page-eth-period": ".",
  "page-eth-popular-tokens": "Popularne typy tokenów",
  "page-eth-powers-ethereum": "ETH zasila Ethereum",
  "page-eth-shit-coins": "Sh*tcoiny",
  "page-eth-shit-coins-desc": "Ponieważ tworzenie nowych tokenów jest łatwe, każdy może to zrobić – nawet osoby o złych lub błędnych intencjach. Zawsze przeprowadzaj badania przed ich użyciem!",
  "page-eth-stablecoins": "Stablecoiny",
  "page-eth-stablecoins-desc": "Tokeny, które odzwierciedlają wartość tradycyjnych walut, takich jak dolary. Rozwiązuje to problem zmienności w wielu kryptowalutach.",
  "page-eth-stablecoins-link": "Zdobądź stablecoiny",
  "page-eth-stream-link": "Streamowanie ETH",
  "page-eth-tokens-link": "Tokeny Ethereum",
  "page-eth-trade-link-2": "Zamiana tokenów",
  "page-eth-underpins": "ETH stanowi podstawę systemu finansowego Ethereum",
  "page-eth-underpins-desc": "Społeczność Ethereum nie poprzestaje na płatnościach, ale buduje cały system finansowy, który jest systemem peer-to-peer i jest dostępny dla każdego.",
  "page-eth-underpins-desc-2": "Możesz użyć ETH jako zabezpieczenia do wygenerowania zupełnie innych tokenów kryptowalutowych na Ethereum. Ponadto możesz pożyczać ETH i zarabiać na odsetkach od ETH i innych tokenów opartych na ETH.",
  "page-eth-uses": "Zastosowania ETH rosną każdego dnia",
  "page-eth-uses-desc": "Ponieważ Ethereum jest programowalne, deweloperzy mogą kształtować ETH na niezliczone sposoby.",
  "page-eth-uses-desc-2": "W 2015 roku wszystko, co można było zrobić, to wysłać ETH z jednego konta Ethereum na drugie. Oto tylko niektóre z rzeczy, które możesz zrobić dzisiaj.",
  "page-eth-uses-desc-3": "zapłać komuś lub otrzymuj środki w czasie rzeczywistym.",
  "page-eth-uses-desc-4": "możesz wymieniać ETH na inne tokeny, w tym bitcoiny.",
  "page-eth-uses-desc-5": "na ETH i innych tokenach opartych na Ethereum.",
  "page-eth-uses-desc-6": "uzyskaj dostęp do wielu kryptowalut o stałej i niezmiennej wartości.",
  "page-eth-value": "Dlaczego ether jest wartościowy",
  "page-eth-video-alt": "Wideo w glifie ETH",
  "page-eth-whats-eth": "Czym jest eter (ETH)?",
  "page-eth-whats-eth-hero-alt": "Ilustracja przedstawiająca grupę ludzi podziwiających glif eteru (ETH)",
  "page-eth-whats-eth-meta-desc": "Co musisz wiedzieć, aby zrozumieć ETH i jego miejsce w Ethereum.",
  "page-eth-whats-eth-meta-title": "Czym jest eter (ETH)?",
  "page-eth-whats-ethereum": "Co to jest Ethereum?",
  "page-eth-whats-ethereum-desc": "Jeśli chcesz dowiedzieć się więcej o Ethereum, technologii leżącej u podstaw ETH, sprawdź nasze wprowadzenie.",
  "page-eth-whats-unique": "Co jest wyjątkowego w ETH?",
  "page-eth-whats-unique-desc": "Istnieje wiele kryptowalut i wiele innych tokenów na Ethereum, ale pewne rzeczy można robić tylko za pomocą ETH.",
  "page-eth-where-to-buy": "Gdzie uzyskać ETH",
  "page-eth-where-to-buy-desc": "Możesz uzyskać ETH z giełdy lub portfela, ale różne kraje mają różne polityki. Sprawdź usługi, które pozwolą Ci kupić ETH.",
  "page-eth-yours": "Jest naprawdę Twój",
  "page-eth-yours-desc": "ETH pozwala Ci być swoim własnym bankiem. Możesz kontrolować własne środki za pomocą portfela jako dowodu własności – nie są potrzebne strony trzecie.",
  "page-eth-more-on-tokens": "More on tokens and their uses",
  "page-eth-button-buy-eth": "Get ETH",
  "page-eth-tokens-stablecoins": "Stablecoins",
  "page-eth-tokens-defi": "Decentralized finance (DeFi)",
  "page-eth-tokens-nft": "Non-fungible tokens (NFTs)",
  "page-eth-tokens-dao": "Decentralized autonomous organisations (DAOs)",
  "page-eth-tokens-stablecoins-description": "More on the least volatile of Ethereum tokens.",
  "page-eth-tokens-defi-description": "The financial system for Ethereum tokens.",
  "page-eth-tokens-nft-description": "Tokens that represent ownership of items on Ethereum.",
  "page-eth-tokens-dao-description": "Internet communities often goverened by token holders.",
  "page-eth-whats-defi": "More on DeFi",
  "page-eth-whats-defi-description": "DeFi is the decentralized financial system built on Ethereum. This overview explains what you can do.",
  "page-eth2-deposit-contract-address": "Adres kontraktu depozytowego Eth2",
  "page-eth2-deposit-contract-address-caption": "Dodaliśmy spacje, aby adres był łatwiejszy do przeczytania",
  "page-eth2-deposit-contract-address-check-btn": "Sprawdź adres kontraktu depozytowego",
  "page-eth2-deposit-contract-checkbox1": "Użyłem już launchpada do konfiguracji mojego walidatora Eth2.",
  "page-eth2-deposit-contract-checkbox2": "Rozumiem, że muszę użyć launchpada by zacząć stakować. Proste przelewy na ten adres nie zadziałają.",
  "page-eth2-deposit-contract-checkbox3": "Zamierzam sprawdzić adres kontraktu depozytowego z innymi źródłami.",
  "page-eth2-deposit-contract-confirm-address": "Potwierdź, aby ujawnić adres",
  "page-eth2-deposit-contract-copied": "Adres skopiowany",
  "page-eth2-deposit-contract-copy": "Kopiuj adres",
  "page-eth2-deposit-contract-etherscan": "Zobacz kontrakt na Etherscan",
  "page-eth2-deposit-contract-h2": "To nie jest miejsce, w którym się stakuje",
  "page-eth2-deposit-contract-launchpad": "Stakuj, używając launchpada",
  "page-eth2-deposit-contract-launchpad-2": "Użyj launchpada",
  "page-eth2-deposit-contract-meta-desc": "Zweryfikuj adres kontraktu depozytowego do stakowania Eth2.",
  "page-eth2-deposit-contract-meta-title": "Adres kontraktu depozytowego Eth2",
  "page-eth2-deposit-contract-read-aloud": "Przeczytaj adres na głos",
  "page-eth2-deposit-contract-reveal-address-btn": "Pokaż adres",
  "page-eth2-deposit-contract-staking": " Aby zestakowac ETH w Eth2, musisz użyć dedykowanego launchpada i postępować zgodnie z instrukcjami. Wysłanie ETH na adres podany na tej stronie nie sprawi, że będziesz stakerem i spowoduje nieudaną transakcję.",
  "page-eth2-deposit-contract-staking-check": "Sprawdź te źródła",
  "page-eth2-deposit-contract-staking-check-desc": "Oczekujemy, że tam będzie wiele fałszywych adresów i oszustw. Aby być bezpiecznym, sprawdź adres Eth2, którego używasz, względem adresu na tej stronie. Zalecamy sprawdzenie go również z innymi godnymi zaufania źródłami.",
  "page-eth2-deposit-contract-staking-more-link": "Więcej o stakowaniu",
  "page-eth2-deposit-contract-stop-reading": "Przerwij czytanie",
  "page-eth2-deposit-contract-subtitle": " Jest to adres kontraktu stakowania Eth2. Użyj tej strony, aby potwierdzić, że wysyłasz środki na prawidłowy adres, kiedy stakujesz.",
  "page-eth2-deposit-contract-warning": "Sprawdź dokładnie każdy znak.",
  "page-eth2-deposit-contract-warning-2": "Wysyłanie środków na ten adres nie zadziała i nie sprawi, że będziesz stakerem. Musisz postępować zgodnie z instrukcjami launchpada.",
  "page-eth2-bug-bounty-annotated-specs": "specyfikacja z adnotacjami",
  "page-eth2-bug-bounty-annotations": "Pomocne może być sprawdzenie następujących adnotacji:",
  "page-eth2-bug-bounty-client-bugs": "Błędy klienta Eth2",
  "page-eth2-bug-bounty-client-bugs-desc": "Klienty będą uruchamiać łańcuch śledzący po wdrożeniu uaktualnienia. Będą musiały działać zgodnie z logiką przedstawioną w specyfikacji i muszą być zabezpieczone przed potencjalnymi atakami. Błędy, które chcemy znaleźć, są związane z implementacją protokołu.",
  "page-eth2-bug-bounty-client-bugs-desc-2": "Obecnie błędy Lighthouse, Nimbus, Teku i Prysm kwalifikują się do tej nagrody. Więcej klientów może zostać dodanych po zakończeniu audytów i osiągnięciu gotowości produkcyjnej.",
  "page-eth2-bug-bounty-clients": "Klienty uwzględnione w nagrodach",
  "page-eth2-bug-bounty-clients-type-1": "problemy z niezgodnością specyfikacji.",
  "page-eth2-bug-bounty-clients-type-2": "nieoczekiwane awarie lub odmowa usługi (DOS).",
  "page-eth2-bug-bounty-clients-type-3": "wszelkie problemy powodujące nieodwracalne oddzielenie konsensusu od reszty sieci.",
  "page-eth2-bug-bounty-docking": "dokowanie",
  "page-eth2-bug-bounty-email-us": "Napisz do nas:",
  "page-eth2-bug-bounty-help-links": "Przydatne linki",
  "page-eth2-bug-bounty-hunting": "Reguły polowań na błędy",
  "page-eth2-bug-bounty-hunting-desc": "Program bug bounty to eksperymentalny i uznaniowy program nagród dla naszej aktywnej społeczności Ethereum, który ma zachęcać i nagradzać tych, którzy pomagają ulepszać platformę. To nie zawody. Trzeba wiedzieć, że możemy anulować program w dowolnym momencie, a nagrody są w wyłącznej gestii panelu nagród za błędy Fundacji Ethereum. Ponadto nie jesteśmy w stanie przyznawać nagród osobom, które znajdują się na listach objętych sankcjami lub znajdują się w krajach objętych listami sankcyjnymi (np. Korea Północna, Iran itp.). Jesteś odpowiedzialny za wszystkie podatki. Wszystkie nagrody podlegają obowiązującym przepisom prawa. Wreszcie, Twoje testy nie mogą naruszać żadnego prawa ani narażać żadnych nienależących do Ciebie danych.",
  "page-eth2-bug-bounty-hunting-leaderboard": "Tablica wyników polowań na błędy",
  "page-eth2-bug-bounty-hunting-leaderboard-subtitle": "Znajdź błędy Eth2, aby zostać dodanym do tej tablicy wyników",
  "page-eth2-bug-bounty-hunting-li-1": "Błędy, które zostały już zgłoszone przez innego użytkownika lub są już znane specjalistom i administratorom klienta, nie kwalifikują się do nagród.",
  "page-eth2-bug-bounty-hunting-li-2": "Publiczne ujawnienie luki powoduje, że nie może ona zostać uznana za kwalifikację do nagrody.",
  "page-eth2-bug-bounty-hunting-li-3": "Naukowcy Fundacji Ethereum i pracownicy zespołów klientów Eth2 nie kwalifikują się do otrzymania nagród.",
  "page-eth2-bug-bounty-hunting-li-4": "Program nagród Ethereum bierze pod uwagę liczbę zmiennych przy określaniu nagród. Ustalenia kwalifikowalności, wyniku i wszystkich warunków związanych z nagrodą zależą od wyłącznej i ostatecznej decyzji panelu nagród za błędy Fundacji Ethereum.",
  "page-eth2-bug-bounty-leaderboard": "Zobacz pełną tablicę wyników",
  "page-eth2-bug-bounty-leaderboard-points": "punkty",
  "page-eth2-bug-bounty-ledger-desc": "Specyfikacja łańcucha śledzącego szczegółowo opisuje uzasadnienie projektu i proponowane zmiany w Ethereum poprzez aktualizację łańcucha śledzącego.",
  "page-eth2-bug-bounty-ledger-title": "Błędy specyfikacji łańcucha śledzącego",
  "page-eth2-bug-bounty-meta-description": "Przegląd programu polowania na błędy Eth2: jak się zaangażować i informacje o nagrodach.",
  "page-eth2-bug-bounty-meta-title": "Program nagród polowania na błędy Eth2",
  "page-eth2-bug-bounty-not-included": "Nieuwzględniona",
  "page-eth2-bug-bounty-not-included-desc": "Łańcuch odłamkowy i ulepszenia dokowania są nadal aktywnie rozwijane i dlatego nie są jeszcze uwzględnione jako część tego programu nagród.",
  "page-eth2-bug-bounty-owasp": "Wyświetl metodę OWASP",
  "page-eth2-bug-bounty-points": "EF przyzna również punkty na podstawie:",
  "page-eth2-bug-bounty-points-error": "Błąd ładowania danych... odśwież.",
  "page-eth2-bug-bounty-points-exchange": "Wymiana punktów",
  "page-eth2-bug-bounty-points-loading": "Ładowanie danych...",
  "page-eth2-bug-bounty-points-payout-desc": "Fundacja Ethereum wypłaci wartość USD w ETH lub DAI.",
  "page-eth2-bug-bounty-points-point": "1 punkt",
  "page-eth2-bug-bounty-points-rights-desc": "Fundacja Ethereum zastrzega sobie prawo do zmiany tego bez uprzedniego powiadomienia.",
  "page-eth2-bug-bounty-points-usd": "2 USD",
  "page-eth2-bug-bounty-quality": "Jakość opisu",
  "page-eth2-bug-bounty-quality-desc": ": Wyższe nagrody są wypłacane za jasne, dobrze napisane zgłoszenia.",
  "page-eth2-bug-bounty-quality-fix": "Jakość naprawy",
  "page-eth2-bug-bounty-quality-fix-desc": "jeśli są uwzględnione: wyższe nagrody są wypłacane za zgłoszenia z jasnym opisem sposobu rozwiązania problemu.",
  "page-eth2-bug-bounty-quality-repro": "Jakość odtwarzalności",
  "page-eth2-bug-bounty-quality-repro-desc": ": Podaj kod testowy, skrypty i szczegółowe instrukcje. Im łatwiej nam odtworzyć i zweryfikować podatność na zagrożenia, tym wyższa nagroda.",
  "page-eth2-bug-bounty-questions": "Pytania?",
  "page-eth2-bug-bounty-rules": "Przeczytaj zasady",
  "page-eth2-bug-bounty-shard-chains": "łańcuchy odłamkowe",
  "page-eth2-bug-bounty-slogan": "Nagrody za błędy Eth2",
  "page-eth2-bug-bounty-specs": "Przeczytaj pełną specyfikację",
  "page-eth2-bug-bounty-specs-docs": "Dokumentacja specyfikacji",
  "page-eth2-bug-bounty-submit": "Prześlij błąd",
  "page-eth2-bug-bounty-submit-desc": "Za każdy znaleziony błąd otrzymasz punkty. Zdobyte punkty zależą od powagi błędu. Fundacja Ethereum Foundation (EF) określa powagę metodą OWASP.",
  "page-eth2-bug-bounty-subtitle": "Zarabiaj do 50 000 USD i miejsca na tablicy wyników, wykrywając błędy klienta i protokołu Eth2.",
  "page-eth2-bug-bounty-title": "Otwarte na zgłoszenia",
  "page-eth2-bug-bounty-title-1": "Łańcuch śledzący",
  "page-eth2-bug-bounty-title-2": "Wybór forka",
  "page-eth2-bug-bounty-title-3": "Kontrakt na depozyt stały",
  "page-eth2-bug-bounty-title-4": "Sieć peer-to-peer",
  "page-eth2-bug-bounty-type-1": "błędy naruszające bezpieczeństwo/nieodwołalność.",
  "page-eth2-bug-bounty-type-2": "wektory ataków odmowy usługi (DOS)",
  "page-eth2-bug-bounty-type-3": "niespójności w założeniach, takie jak sytuacje, w których można odciąć uczciwych walidatorów.",
  "page-eth2-bug-bounty-type-4": "obliczenia lub niezgodności parametrów.",
  "page-eth2-bug-bounty-types": "Typy błędów",
  "page-eth2-bug-bounty-validity": "Ważne błędy",
  "page-eth2-bug-bounty-validity-desc": "Ten program nagród za błędy koncentruje się na znajdowaniu błędów w podstawowej specyfikacji łańcucha śledzącego Eth2 oraz implementacji klientów Prysm, Lighthouse i Teku.",
  "page-eth2-bug-bountycard-critical": "Krytyczny",
  "page-eth2-bug-bountycard-critical-risk": "Prześlij błąd krytycznego ryzyka",
  "page-eth2-bug-bountycard-h2": "Średni",
  "page-eth2-bug-bountycard-high": "Wysoki",
  "page-eth2-bug-bountycard-high-risk": "Prześlij błąd krytycznego ryzyka",
  "page-eth2-bug-bountycard-label-1": "Do 1000 punktów",
  "page-eth2-bug-bountycard-label-2": "Do 2000 DAI",
  "page-eth2-bug-bountycard-label-3": "Do 5000 punktów",
  "page-eth2-bug-bountycard-label-4": "Do 10 000 DAI",
  "page-eth2-bug-bountycard-label-5": "Do 10 000 punktów",
  "page-eth2-bug-bountycard-label-6": "Do 20 000 DAI",
  "page-eth2-bug-bountycard-label-7": "Do 25 000 punktów",
  "page-eth2-bug-bountycard-label-8": "Do 50 000 DAI",
  "page-eth2-bug-bountycard-li-1": "Niski wpływ, średnie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-2": "Średni wpływ, niskie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-3": "Wysoki wpływ, niskie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-4": "Średni wpływ, średnie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-5": "Niski wpływ, wysokie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-6": "Wysoki wpływ, średnie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-7": "Średni wpływ, wysokie prawdopodobieństwo",
  "page-eth2-bug-bountycard-li-8": "High impact, high likelihood",
  "page-eth2-bug-bountycard-low": "Niskie",
  "page-eth2-bug-bountycard-low-risk": "Prześlij błąd niskiego ryzyka",
  "page-eth2-bug-bountycard-medium-risk": "Prześlij błąd średniego ryzyka",
  "page-eth2-bug-bountycard-subheader": "Powaga zgłoszenia",
  "page-eth2-bug-bountycard-subheader-2": "Przykład",
  "page-eth2-bug-bountycard-text": "Atakujący może czasami wprowadzić węzeł w stan, który powoduje odrzucenie jednego na sto potwierdzeń dokonanych przez walidatora",
  "page-eth2-bug-bountycard-text-1": "Atakujący może z powodzeniem przeprowadzać ataki typu eclipse (zaćmienia informacji) na węzły z identyfikatorami równorzędnymi z 4 wiodącymi bajtami zerowymi",
  "page-eth2-bug-bountycard-text-2": "Istnieje błąd konsensusu między dwoma klientami, ale wywołanie zdarzenia przez atakującego jest trudne lub niewykonalne.",
  "page-eth2-bug-bountycard-text-3": "Istnieje błąd konsensusu między dwoma klientami, ale wywołanie zdarzenia przez atakującego jest trudne lub niewykonalne.",
  "page-eth2-get-involved-btn-1": "Zobacz klientów",
  "page-eth2-get-involved-btn-2": "Więcej o stakowaniu",
  "page-eth2-get-involved-btn-3": "Znajdź błędy",
  "page-eth2-get-involved-bug": "Błędem może być:",
  "page-eth2-get-involved-bug-hunting": "Zapoluj na błędy",
  "page-eth2-get-involved-bug-hunting-desc": "Znajdź i zgłoś błędy w specyfikacji aktualizacji Eth2 lub samych klientów. Możesz zarobić do 50 000 USD i zdobyć miejsce w rankingu.",
  "page-eth2-get-involved-bug-li": "problemy z niezgodnością specyfikacji",
  "page-eth2-get-involved-bug-li-2": "błędy naruszenia nieodwołalności",
  "page-eth2-get-involved-bug-li-3": "wektory ataków odmowy usługi (DOS)",
  "page-eth2-get-involved-bug-li-4": "i więcej...",
  "page-eth2-get-involved-date": "Data zamknięcia: 23 grudnia 2020 r",
  "page-eth2-get-involved-desc-1": "Uruchomienie klienta oznacza, że będziesz aktywnym uczestnikiem Ethereum. Twój klient pomoże śledzić transakcje i sprawdzać nowe bloki.",
  "page-eth2-get-involved-desc-2": "Jeśli posiadasz ETH, możesz je zestakować, aby stać się walidatorem i pomóc zabezpieczyć sieć. Jako walidator możesz zdobyć nagrody za ETH.",
  "page-eth2-get-involved-desc-3": "Dołącz do społeczności testerów! Pomóż testować ulepszenia Eth2 przed ich wysłaniem, znajduj błędy i zdobywaj nagrody.",
  "page-eth2-get-involved-ethresearch-1": "Sharding",
  "page-eth2-get-involved-ethresearch-2": "Przejście z Eth1 do Eth2",
  "page-eth2-get-involved-ethresearch-3": "Odłamki i wykonanie stanu",
  "page-eth2-get-involved-ethresearch-4": "Wszystkie tematy wyszukiwań",
  "page-eth2-get-involved-grants": "Dotacje dla społeczności stakerów",
  "page-eth2-get-involved-grants-desc": "Pomóż tworzyć narzędzia i treści edukacyjne dla społeczności stakerów",
  "page-eth2-get-involved-how": "Jak chcesz się włączyć?",
  "page-eth2-get-involved-how-desc": "Społeczność Ethereum zawsze będzie czerpać korzyści z większej liczby osób uruchamiających klienty, stakujących i polujących na błędy.",
  "page-eth2-get-involved-join": "Dołącz do wyszukiwań",
  "page-eth2-get-involved-join-desc": " Podobnie jak większość rzeczy związanych z Ethereum, wiele badań jest publicznych. Oznacza to, że możesz wziąć udział w dyskusjach lub po prostu przeczytać, co mają do powiedzenia badacze Ethereum. ethresear.ch obejmuje więcej niż tylko aktualizacje Eth2, ale w znacznym stopniu koncentruje się na Eth2.",
  "page-eth2-get-involved-meta-description": "Jak uczestniczyć w Eth2: uruchamiaj węzły, stakuj, poluj na błędy i nie tylko.",
  "page-eth2-get-involved-more": "Więcej informacji",
  "page-eth2-get-involved-run-clients": "Uruchom klienty łańcucha śledzącego",
  "page-eth2-get-involved-run-clients-desc": "Kluczem do długoterminowego bezpieczeństwa Ethereum jest silne rozproszenie klientów. Klient to oprogramowanie, które obsługuje blockchain, sprawdza transakcje i tworzy nowe bloki. Każdy klient ma swoje własne funkcje, więc wybierz jedną dla siebie na podstawie tego, w czym czujesz się komfortowo.",
  "page-eth2-get-involved-run-clients-production": "Production-ready Eth2 clients",
  "page-eth2-get-involved-run-clients-experimental": "Experimental Eth2 clients",
  "page-eth2-get-involved-stake": "Stakuj swoje ETH",
  "page-eth2-get-involved-stake-desc": "Teraz możesz stakować swoje ETH, aby pomóc zabezpieczyć łańcuch śledzący.",
  "page-eth2-get-involved-stake-eth": "Stakuj ETH",
  "page-eth2-get-involved-subtitle": "Oto wszystkie sposoby, jakimi możesz pomóc w Ethereum i przyszłych działaniach związanych z Eth2.",
  "page-eth2-get-involved-title-1": "Uruchom klienta",
  "page-eth2-get-involved-title-2": "Stakuj swoje ETH",
  "page-eth2-get-involved-title-3": "Znajdź błędy",
  "page-eth2-get-involved-written-go": "Napisany w Go",
  "page-eth2-get-involved-written-java": "Napisany w Java",
  "page-eth2-get-involved-written-javascript": "Napisany w JavaScript",
  "page-eth2-get-involved-written-net": "Napisany w .NET",
  "page-eth2-get-involved-written-nim": "Napisany w Nim",
  "page-eth2-get-involved-written-python": "Napisany w Pythonie",
  "page-eth2-get-involved-written-rust": "Napisany w Rust",
  "eth2-client-cortex-logo-alt": "Logo Cortex",
  "eth2-client-lighthouse-logo-alt": "Logo Lighthouse",
  "eth2-client-lodestar-logo-alt": "Logo Lodestar",
  "eth2-client-nimbus-logo-alt": "Logo Nimbus",
  "eth2-client-prysm-logo-alt": "Logo Prysm",
  "eth2-client-teku-logo-alt": "Logo Teku",
  "eth2-rhino-img-alt": "Wykres Eth 2 Rhino przed załadowaniem",
  "page-eth2-answer-1": "Pomyśl o Eth2 jako zestawie ulepszeń dodawanych w celu ulepszenia Ethereum, którego używamy dzisiaj. Ulepszenia te obejmują utworzenie nowego łańcucha zwanego łańcuchem śledzącym i do 64 łańcuchów zwanych odłamkami.",
  "page-eth2-answer-2": "Są one niezależne od głównej sieci Ethereum, której używamy dzisiaj, ale jej nie zastąpią. Zamiast tego sieć główna zadokuje się lub „połączy” z tym równoległym systemem, który będzie dodawany z czasem.",
  "page-eth2-answer-4": "Innymi słowy, Ethereum, którego używamy dzisiaj, będzie ostatecznie posiadać wszystkie funkcje, do których dążymy w wizji Eth2.",
  "page-eth2-beacon-chain-btn": "Więcej o łańcuchu śledzącym",
  "page-eth2-beacon-chain-date": "Łańcuch śledzący istnieje od 1 grudnia 2020 r.",
  "page-eth2-beacon-chain-desc": "Pierwszy dodatek Eth2 do ekosystemu. Łańcuch śledzący przenosi staking do Ethereum, kładzie podwaliny pod przyszłe ulepszenia i ostatecznie będzie koordynować nowy system.",
  "page-eth2-beacon-chain-estimate": "Łańcuch śledzący jest aktywny",
  "page-eth2-beacon-chain-title": "Łańcuch śledzący",
  "page-eth2-bug-bounty": "Zobacz program nagród za znalezienie błędów",
  "page-eth2-clients": "Sprawdź klientów Eth2",
  "page-eth2-deposit-contract-title": "Sprawdź adres kontraktu depozytowego",
  "page-eth2-diagram-ethereum-mainnet": "Sieć główna Ethereum",
  "page-eth2-diagram-h2": "Wzajemne dopasowanie ulepszeń",
  "page-eth2-diagram-link-1": "Więcej o proof of work",
  "page-eth2-diagram-link-2": "Więcej na temat łańcuchów odłamkowych",
  "page-eth2-diagram-mainnet": "Sieć główna",
  "page-eth2-diagram-p": "Sieć główna Ethereum będzie nadal przez jakiś czas istnieć w swojej obecnej formie. Oznacza to, że aktualizacje łańcucha śledzącego i odłamków nie zakłócą sieci.",
  "page-eth2-diagram-p-1": "Sieć główna zostanie ostatecznie połączona z nowym systemem wprowadzonym przez modernizację Eth2.",
  "page-eth2-diagram-p-2": "Łańcuch śledzący stanie się przewodnikiem Ethereum, koordynującym walidatorów i określającym tempo tworzenia bloków.",
  "page-eth2-diagram-p-3": "Na początku będzie istnieć niezależnie od sieci głównej i zarządzać walidatorami – nie będzie mieć nic wspólnego z inteligentnymi kontraktami, transakcjami czy kontami.",
  "page-eth2-diagram-p-4": "Odłamki dostarczą wielu dodatkowych danych, aby pomóc zwiększyć liczbę transakcji obsługiwanych przez sieć główną. Będą koordynowane przez łańcuch śledzący.",
  "page-eth2-diagram-p-5": "Ale wszystkie transakcje nadal będą opierać się na sieci głównej, która będzie nadal istnieć w takiej postaci, w jakiej znamy ją dzisiaj – zabezpieczona dowodami proof of work i górnikami.",
  "page-eth2-diagram-p-6": "Sieć główna połączy się z systemem proof-of-stake, koordynowanym przez łańcuch śledzący.",
  "page-eth2-diagram-p-8": "To zmieni sieć główną w odłamek w nowym systemie. Górnicy nie będą już potrzebni, ponieważ całe Ethereum będzie zabezpieczone przez walidatorów.",
  "page-eth2-diagram-p10": "Eth2 nie jest migracją ani jedną rzeczą. Przedstawia zestaw ulepszeń, nad którymi pracujemy, aby odblokować prawdziwy potencjał Ethereum. Oto jak one wszystkie do siebie pasują.",
  "page-eth2-diagram-scroll": "Przewiń, aby zbadać – naciśnij, aby uzyskać więcej informacji.",
  "page-eth2-diagram-shard": "Odłamek (1)",
  "page-eth2-diagram-shard-1": "Odłamek (...)",
  "page-eth2-diagram-shard-2": "Odłamek (2)",
  "page-eth2-diagram-shard-3": "Odłamek (...)",
  "page-eth2-diagram-validators": "Więcej o walidatorach",
  "page-eth2-dive": "Zagłęb się w wizji",
  "page-eth2-dive-desc": "Jak zamierzamy uczynić Ethereum bardziej skalowalnym, bezpiecznym i zrównoważonym? Wszystko przy zachowaniu podstawowego etosu decentralizacji Ethereum.",
  "page-eth2-docking": "Dokowanie",
  "page-eth2-docking-answer-1": "Dokowanie ma miejsce, kiedy sieć główna przekształca się w odłamek. Nastąpi to po udanym wdrożeniu łańcuchów odłamkowych.",
  "page-eth2-docking-btn": "Więcej na temat dokowania",
  "page-eth2-docking-desc": "Sieć główna Ethereum będzie musiała w pewnym momencie „zadokować się” lub „połączyć” z łańcuchem śledzącym. Umożliwi to stakowanie w całej sieci i będzie sygnałem końca energochłonnego wydobycia.",
  "page-eth2-docking-estimate": "Szacunkowy: 2022 r.",
  "page-eth2-docking-mainnet": "Co to jest sieć główna?",
  "page-eth2-docking-mainnet-eth2": "Dokowanie sieci głównej do Eth2",
  "page-eth2-eth-blog": "Blog ethereum.org",
  "page-eth2-explore-btn": "Poznaj ulepszenia",
  "page-eth2-get-involved": "Włącz się do Eth2",
  "page-eth2-get-involved-2": "Włącz się",
  "page-eth2-head-to": "Przejdź do",
  "page-eth2-help": "Chcesz pomóc w Eth2?",
  "page-eth2-help-desc": "Jest wiele możliwości, aby wziąć udział w pracach nad ulepszeniem Eth2, pomóc w testach, a nawet zdobyć nagrody.",
  "page-eth2-index-staking": "Staking jest tutaj",
  "page-eth2-index-staking-desc": "Kluczem do modernizacji Eth2 jest wprowadzenie stakingu. Jeśli chcesz użyć ETH do zabezpieczenia sieci Ethereum, upewnij się, że wykonujesz te kroki.",
  "page-eth2-index-staking-learn": "Dowiedz się o stakingu",
  "page-eth2-index-staking-learn-desc": "Łańcuch śledzący przeniesie staking do Ethereum. Oznacza to, że jeśli masz ETH, coś dla dobra publicznego poprzez zabezpieczenie sieci i zarobić więcej ETH w tym procesie.",
  "page-eth2-index-staking-step-1": "1. Skonfiguruj za pomocą launchpada",
  "page-eth2-index-staking-step-1-btn": "Odwiedź launchpad skakingu",
  "page-eth2-index-staking-step-1-desc": "Aby stakować w Eth2, musisz użyć launchpada – to przeprowadzi Cię przez cały proces.",
  "page-eth2-index-staking-step-2": "2. Potwierdź adres stakowania",
  "page-eth2-index-staking-step-2-btn": "Potwierdź adres umowy depozytu",
  "page-eth2-index-staking-step-2-desc": "Zanim zaczniesz stakować na ETH, upewnij się, że masz prawidłowy adres. Musisz przejść przez launchpad, zanim to zrobisz.",
  "page-eth2-index-staking-sustainability": "Bardziej zrównoważony",
  "page-eth2-just-docking": "Więcej na temat dokowania",
  "page-eth2-meta-desc": "Przegląd ulepszeń Ethereum 2.0 i wizji, którą mają urzeczywistnić.",
  "page-eth2-meta-title": "Ulepszenia Eth2",
  "page-eth2-miners": "Więcej o górnikach",
  "page-eth2-more-on-upgrades": "Więcej o ulepszeniach Eth2",
  "page-eth2-proof-of-stake": "proof-of-stake",
  "page-eth2-proof-of-work": "proof-of-work",
  "page-eth2-proof-stake-link": "Więcej o proof of stake",
  "page-eth2-question-1-desc": "Eth2 to zestaw różnych ulepszeń z różnymi datami wydania.",
  "page-eth2-question-1-title": "Kiedy Eth2 zostanie wydane?",
  "page-eth2-question-2-desc": "Myślenie o Eth2 jako o oddzielnym łańcuchu bloków nie jest właściwe.",
  "page-eth2-question-2-title": "Czy Eth2 to osobny łańcuch bloków?",
  "page-eth2-question-3-answer-2": "Łańcuch odłamkowy i ulepszenia dokowania mogą mieć wpływ na deweloperów zdecentralizowanych aplikacji. Jednak specyfikacje nie zostały jeszcze ukończone, więc nie jest wymagane natychmiastowe działanie.",
  "page-eth2-question-3-answer-3": "Porozmawiaj z zespołem badawczo-rozwojowym Eth2 na ethresear.ch.",
  "page-eth2-question-3-answer-3-link": "Odwiedź ethresear.ch",
  "page-eth2-question-3-desc": "Nie musisz teraz robić nic, aby przygotować się do Eth2.",
  "page-eth2-question-3-title": "Jak przygotować się do Eth2?",
  "page-eth2-question-4-answer-1": "Za każdym razem, gdy obecnie wysyłasz transakcję lub korzystasz z decentralizowanej aplikacji, korzystasz z Eth1. To jest Ethereum zabezpieczone przez górników.",
  "page-eth2-question-4-answer-2": "„Eth1” lub „sieć główna” będzie działać jak zwykle, aż do dokowania.",
  "page-eth2-question-4-answer-3": "Po dokowaniu walidatorzy zabezpieczą całą sieć za pomocą proof-of-stake.",
  "page-eth2-question-4-answer-6": "Każdy może stać się walidatorem poprzez zestakowanie swojego ETH.",
  "page-eth2-question-4-answer-7": "Więcej o stakingu",
  "page-eth2-question-4-answer-8": "Ulepszenia łańcucha śledzącego i łańcucha fragmentacji nie zakłócą Eth1, ponieważ są one budowane oddzielnie.",
  "page-eth2-question-4-desc": "Eth1 odnosi się do sieci głównej Ethereum w której działasz obecnie.",
  "page-eth2-question-4-title": "Co to jest Eth1?",
  "page-eth2-question-5-answer-1": "Aby zostać pełnym walidatorem w sieci, musisz zestakować 32 ETH. Jeśli nie masz tak dużo lub nie chcesz tak dużo stakować, możesz dołączyć do puli stawek. Te pule pozwolą ci stawiać mniej i zarabiać ułamki całkowitych nagród.",
  "page-eth2-question-5-desc": "Będziesz musiał użyć launchpada do stakowania lub dołączyć do puli do stakowania.",
  "page-eth2-question-5-title": "Jak mogę zacząć stakować?",
  "page-eth2-question-6-answer-1": "Na razie nie ma żadnych działań do podjęcia. Zalecamy jednak bycie na bieżąco z nowościami dotyczącymi łańcuchów odłamkowych i ulepszeń dokowania.",
  "page-eth2-question-6-answer-3": "Danny Ryan z fundacji Ethereum regularnie przekazuje społeczności najnowsze informacje:",
  "page-eth2-question-6-answer-4": "Ben Edgington of ConsenSys ma cotygodniowy biuletyn Eth2:",
  "page-eth2-question-6-answer-5": "Możesz także dołączyć do dyskusji na temat badań i rozwoju Eth2 na ethresear.ch.",
  "page-eth2-question-6-desc": "Na Twoją zdecentralizowaną aplikację nie będą miały wpływu żadne nadchodzące aktualizacje. Jednak przyszłe aktualizacje mogą wymagać pewnych zmian.",
  "page-eth2-question-6-title": "Co muszę zrobić z moją zdecentralizowaną aplikacją?",
  "page-eth2-question-7-cortex": "Cortex",
  "page-eth2-question-7-cortex-lang": "(implementacja.NET)",
  "page-eth2-question-7-desc": "Wiele różnych zespołów z całej społeczności pracuje nad różnymi ulepszeniami Eth2.",
  "page-eth2-question-7-lighthouse": "Lighthouse",
  "page-eth2-question-7-lighthouse-lang": "(Implementacja języka Rust)",
  "page-eth2-question-7-lodestar": "Lodestar",
  "page-eth2-question-7-lodestar-lang": "(implementacja JavaScript)",
  "page-eth2-question-7-nimbus": "Nimbus",
  "page-eth2-question-7-nimbus-lang": "(Implementacja języka Nim)",
  "page-eth2-question-7-prysm": "Prysm",
  "page-eth2-question-7-prysm-lang": "(Implementacja Go)",
  "page-eth2-question-7-teams": "Zespoły klientów Eth2:",
  "page-eth2-question-7-teku": "Teku",
  "page-eth2-question-7-teku-lang": "(Implementacja języka Java)",
  "page-eth2-question-7-title": "Kto buduje Eth2?",
  "page-eth2-question-7-trinity": "Trójca",
  "page-eth2-question-7-trinity-lang": "(Implementacja języka Python)",
  "page-eth2-question-8-answer-1": "Aktualizacje Eth2 pomogą skalować Ethereum w sposób zdecentralizowany, przy jednoczesnym zachowaniu bezpieczeństwa i zwiększeniu trwałości.",
  "page-eth2-question-8-answer-2": "Być może najbardziej oczywistym problemem jest to, że Ethereum musi być w stanie obsłużyć więcej niż 15-45 transakcji na sekundę. Ale aktualizacje Eth2 rozwiązują również inne problemy z dzisiejszym Ethereum.",
  "page-eth2-question-8-answer-3": "Sieć cieszy się tak dużym zainteresowaniem, że korzystanie z Ethereum jest drogie. Węzły w sieci borykają się z rozmiarem Ethereum i ilością danych, które muszą przetwarzać ich komputery. A podstawowy algorytm, który utrzymuje bezpieczeństwo i decentralizację Ethereum, jest energochłonny i musi być bardziej ekologiczny.",
  "page-eth2-question-8-answer-4": "Wiele z tego, co się zmienia, zawsze znajdowało się na mapie drogowej Ethereum, nawet od 2015 roku. Ale obecne warunki sprawiają, że potrzeba ulepszeń jest jeszcze większa.",
  "page-eth2-question-8-answer-6": "Odkryj wizję Eth2",
  "page-eth2-question-8-desc": "Ethereum, którego używamy dzisiaj, musi oferować lepsze wrażenia użytkownikom końcowym i uczestnikom sieci.",
  "page-eth2-question-8-title": "Dlaczego Eth2 jest potrzebne?",
  "page-eth2-question-9-answer-1": "Najbardziej aktywną rolą, jaką możesz odegrać, jest zestakowanie Twojego ETH.",
  "page-eth2-question-9-answer-2": "Możesz także uruchomić drugiego klienta, aby poprawić różnorodność klientów.",
  "page-eth2-question-9-answer-3": "Jeśli jesteś bardziej techniczny, możesz pomóc wyłapać błędy u nowych klientów.",
  "page-eth2-question-9-answer-4": "Możesz także wziąć udział w technicznych dyskusjach z badaczami Ethereum na ethresear.ch.",
  "page-eth2-question-9-desc": "Nie musisz być techniczny, aby wnieść swój wkład. Społeczność poszukuje wkładu ze wszystkich rodzajów umiejętności.",
  "page-eth2-question-9-stake-eth": "Stakuj ETH",
  "page-eth2-question-9-title": "Jak mogę przyczynić się do tworzenia Eth2?",
  "page-eth2-question-10-answer-1": "Nie chcemy zbyt wiele mówić o technicznej mapie drogowej, ponieważ jest to oprogramowanie: wszystko może się zmienić. Uważamy, że łatwiej jest zrozumieć, co się dzieje, gdy czytasz o wynikach.",
  "page-eth2-question-10-answer-1-link": "Wyświetl ulepszenia",
  "page-eth2-question-10-answer-2": "Ale jeśli śledziłeś dyskusje, oto jak ulepszenia pasują do technicznych planów mapy drogowej.",
  "page-eth2-question-10-answer-3": "Faza 0 opisuje pracę nad uruchomieniem łańcucha śledzącego.",
  "page-eth2-question-10-answer-5": "Faza 1 technicznych planów mapy drogowej, działania koncentrują się na wdrażaniu łańcuchów odłamkowych.",
  "page-eth2-question-10-answer-6": "Dokowanie sieci głównej do Eth2 jest rozszerzeniem pracy wykonanej w celu wdrożenia łańcuchów odłamkowych i zostało określone jako Faza 1.5. Ale to ważny moment, ponieważ Ethereum, które znamy dzisiaj, łączy się z innymi ulepszeniami Eth2. Plus to wtedy, gdy Ethereum w pełni przechodzi na proof of stake.",
  "page-eth2-question-10-answer-7": "Obecnie nie jest jasne, co stanie się wokół fazy 2. Jest to nadal punkt intensywnych badań i dyskusji. Początkowym planem było dodanie dodatkowej funkcjonalności do łańcuchów odłamkowych, ale może to nie być konieczne w przypadku mapy drogowej skoncentrowanej na pakietach zbiorczych.",
  "page-eth2-question-10-answer-8": "Więcej informacji na temat planu działania zorientowanego na pakiety zbiorcze",
  "page-eth2-question-10-desc": "Fazy ​​odnoszą się do faz pracy i skupiają się na technicznym planie wdrożenia Eth2.",
  "page-eth2-question-10-title": "Jakie są fazy Eth2?",
  "page-eth2-question-title": "Najczęściej zadawane pytania (FAQ)",
  "page-eth2-question3-answer-1": "Posiadacze Eth z pewnością nie muszą nic robić. Twój ETH nie będzie wymagał zmiany ani aktualizacji. Prawie na pewno zdarzają się oszustwa, które mówią inaczej, więc bądź ostrożny.",
  "page-eth2-scalable": "Bardziej skalowalne",
  "page-eth2-scalable-desc": "Ethereum musi obsługiwać 1000 transakcji na sekundę, aby aplikacje były szybsze i tańsze.",
  "page-eth2-secure": "Bardziej bezpieczne",
  "page-eth2-secure-desc": "Ethereum musi być bezpieczniejsze. Wraz z przyjęciem Ethereum powinno się zwiększyć bezpieczeństwo protokołu przed wszelkimi formami ataku.",
  "page-eth2-shard-button": "Więcej na temat łańcuchów odłamkowych",
  "page-eth2-shard-date": "Przewiduje się, że wprowadzenie łańcuchów odłamkowych, drugie ulepszenie, nastąpi w2021 r.",
  "page-eth2-shard-desc": "Łańcuchy odłamkowe zwiększą zdolność Ethereum do przetwarzania transakcji i przechowywania danych. Same fragmenty z czasem zyskają więcej funkcji, wprowadzanych w wielu fazach.",
  "page-eth2-shard-estimate": "Szacunkowo: 2021 r.",
  "page-eth2-shard-lower": "Więcej na temat łańcuchów odłamkowych",
  "page-eth2-shard-title": "Łańcuchy odłamkowe",
  "page-eth2-stay-up-to-date": "Bądź na bieżąco",
  "page-eth2-stay-up-to-date-desc": "Zdobądź najnowsze informacje od badaczy i programistów pracujących nad ulepszeniami Eth2.",
  "page-eth2-sustainable-desc": "Ethereum musi być lepsze dla środowiska. Dzisiejsza technologia wymaga zbyt dużej mocy obliczeniowej i energii.",
  "page-eth2-take-part": "Weź udział w badaniach",
  "page-eth2-take-part-desc": "Zarówno badacze Ethereum, jak i entuzjaści spotykają się tutaj, aby omówić wysiłki badawcze, w tym wszystkie działania dotyczące Eth2.",
  "page-eth2-the-upgrades": "Ulepszenia Eth2",
  "page-eth2-the-upgrades-desc": "Eth2 to zestaw ulepszeń, które poprawiają skalowalność, bezpieczeństwo i trwałość Ethereum. Chociaż każde z nich pracuje równolegle, mają one pewne zależności, które decydują o tym, kiedy zostaną wdrożone.",
  "page-eth2-unofficial-roadmap": "To nie jest oficjalny plan wdrożenia. W ten sposób postrzegamy to, co się dzieje na podstawie dostępnych informacji. Ale to jest technologia, wszystko może się zmienić w jednej chwili. Więc proszę, nie traktuj tego jako zobowiązania.",
  "page-eth2-upgrade-desc": "Ethereum, jakie znamy i kochamy, po prostu bardziej skalowalne, bezpieczniejsze i bardziej zrównoważone...",
  "page-eth2-upgrades": "Ulepszenia Eth2",
  "page-eth2-upgrades-aria-label": "Menu ulepszeń Eth2",
  "page-eth2-upgrades-beacon-chain": "Łańcuch śledzący",
  "page-eth2-upgrades-docking": "Dokowanie sieci głównej do Eth2",
  "page-eth2-upgrades-guide": "Przewodnik po ulepszeniach Eth2",
  "page-eth2-upgrades-shard-chains": "Łańcuchy odłamkowe",
  "page-eth2-upgrading": "Ulepszenie Ethereum do radykalnie nowych poziomów",
  "page-eth2-vision": "Wizja",
  "page-eth2-vision-btn": "Więcej informacji na temat wizji Eth2",
  "page-eth2-vision-desc": "Aby włączyć Ethereum do głównego nurtu i służyć całej ludzkości, musimy uczynić Ethereum bardziej skalowalnym, bezpiecznym i zrównoważonym.",
  "page-eth2-vision-upper": "Wizja Eth2",
  "page-eth2-what-to-do": "Co musisz zrobić?",
  "page-eth2-what-to-do-desc": "Jeśli jesteś użytkownikiem Zdecentralizowanej Aplikacji lub posiadaczem ETH, nie musisz nic robić. Jeśli jesteś programistą lub chcesz zacząć stakować, istnieją sposoby w które możesz się zaangażować już dziś.",
  "page-eth2-whats-eth2": "Co to jest Eth2?",
  "page-eth2-whats-eth2-desc": "Eth2 odnosi się do zestawu połączonych ulepszeń, które sprawią, że Ethereum będzie bardziej skalowalne, bezpieczniejsze i bardziej zrównoważone. Te ulepszenia są tworzone przez wiele zespołów z całego ekosystemu Ethereum.",
  "page-eth2-whats-ethereum": "Zaczekaj, czym jest Ethereum?",
  "page-eth2-whats-new": "Co nowego w Eth2?",
  "page-eth2-just-staking": "Staking",
  "page-eth2-staking-image-alt": "Obraz nosorożca, maskotki launchpada eth2.",
  "page-eth2-staking-51-desc-1": "Staking sprawia, że ​​dołączenie do sieci jako walidator jest bardziej dostępne, więc jest prawdopodobne, że w sieci będzie więcej walidatorów niż obecnie. To sprawi, że ten rodzaj ataku będzie jeszcze trudniejszy, ponieważ koszt ataku wzrośnie.",
  "page-eth2-staking-accessibility": "Bardziej dostępne",
  "page-eth2-staking-accessibility-desc": "Dzięki łatwiejszym wymaganiom sprzętowym i możliwości łączenia w pule, nawet jeśli nie masz 32 ETH, więcej osób będzie mogło dołączyć do sieci. To sprawi, że Ethereum będzie bardziej zdecentralizowane i bezpieczne poprzez zmniejszenie powierzchni ataku.",
  "page-eth2-staking-at-stake": "Twoje ETH są zestakowane",
  "page-eth2-staking-at-stake-desc": "Ponieważ musisz zestakować ETH, aby zweryfikować transakcje i utworzyć nowe bloki, możesz je stracić, jeśli zdecydujesz się oszukać system.",
  "page-eth2-staking-benefits": "Korzyści ze stakingu na Ethereum",
  "page-eth2-staking-check-address": "Sprawdź adres kontraktu depozytowego",
  "page-eth2-staking-consensus": "Więcej na temat mechanizmów konsensusu",
  "page-eth2-staking-deposit-address": "Sprawdź adres kontraktu depozytowego",
  "page-eth2-staking-deposit-address-desc": "Jeśli już postępowałeś zgodnie z instrukcjami konfiguracji na launchpadzie, będziesz wiedział, że musisz wysłać transakcję do kontraktu depozytowego na staking. Zalecamy bardzo dokładne sprawdzenie adresu. Oficjalny adres można znaleźć na ethereum.org i wielu innych zaufanych witrynach.",
  "page-eth2-staking-desc-1": "Nagrody są przyznawane za działania, które pomagają sieci osiągnąć konsensus. Otrzymasz nagrody za grupowanie transakcji w nowy blok lub sprawdzanie pracy innych walidatorów, ponieważ dzięki temu łańcuch działa bezpiecznie.",
  "page-eth2-staking-desc-2": "Chociaż możesz zdobywać nagrody za pracę przynoszącą korzyści sieci, możesz stracić ETH za złośliwe działania, przejście w tryb offline i nieudaną walidację.",
  "page-eth2-staking-desc-3": "Będziesz potrzebować 32 ETH, aby zostać pełnym walidatorem lub trochę ETH, aby dołączyć do puli stawek. Będziesz także musiał uruchomić klienta 'Eth1' lub sieć główną. Launchpad przeprowadzi Cię przez proces i wymagania sprzętowe. Alternatywnie możesz użyć backendowego API.",
  "page-eth2-staking-description": "Staking to czynność deponowania 32 ETH w celu aktywacji oprogramowania walidatora. Jako walidator będziesz odpowiedzialny za przechowywanie danych, przetwarzanie transakcji i dodawanie nowych bloków do łańcucha bloków. Dzięki temu Ethereum będzie bezpieczne dla wszystkich i zapewni Ci nowe ETH. Proces ten, znany jako proof-of-stake, jest wprowadzany przez łańcuch śledzący.",
  "page-eth2-staking-docked": "Więcej na temat dokowania",
  "page-eth2-staking-dyor": "Przeprowadź własne wyszukiwania",
  "page-eth2-staking-dyor-desc": "Żadna z wymienionych usług stakingowych nie jest oficjalną rekomendacją. Pamiętaj, aby przeprowadzić rozeznanie, aby dowiedzieć się, która usługa może być dla Ciebie najlepsza.",
  "page-eth2-staking-header-1": "Zestakuj swój ETH, aby zostać walidatorem Ethereum",
  "page-eth2-staking-how-much": "Ile chcesz zestakować?",
  "page-eth2-staking-how-to-stake": "Jak mogę stakować",
  "page-eth2-staking-how-to-stake-desc": "Wszystko zależy od tego, ile chcesz stakować. Będziesz potrzebować 32, aby stać się pełnym walidatorem, ale możesz stakować mniej.",
  "page-eth2-staking-join": "Dołącz",
  "page-eth2-staking-join-community": "Dołącz do społeczności stakerów",
  "page-eth2-staking-join-community-desc": "r/ethstaker to społeczność, w której wszyscy mogą dyskutować o stakingu na Ethereum – dołącz, aby uzyskać porady, wsparcie i porozmawiać o wszystkich sprawach dotyczących stakowania.",
  "page-eth2-staking-less-than": "Mniej niż",
  "page-eth2-staking-link-1": "Wyświetl backendowe API",
  "page-eth2-staking-meta-description": "Przegląd stakingu Ethereum: \nryzyko, nagrody, wymagania i gdzie to zrobić.",
  "page-eth2-staking-meta-title": "Staking Ethereum",
  "page-eth2-staking-more-sharding": "Więcej o shardingu",
  "page-eth2-staking-pool": "Stakowanie w puli",
  "page-eth2-staking-pool-desc": "Jeśli masz mniej niż 32 ETH, będziesz mógł dodać mniejszą stawkę do puli stawek. Niektóre firmy mogą to wszystko zrobić w Twoim imieniu, więc nie musisz się martwić o pozostanie online. Oto kilka firm do sprawdzenia.",
  "page-eth2-staking-pos-explained": "Wyjaśnienie proof-of-stake",
  "page-eth2-staking-pos-explained-desc": "Staking jest tym, co musisz zrobić, aby zostać walidatorem w systemie proof-of-stake. Jest to mechanizm konsensusu, który zastąpi obecnie obowiązujący system proof-of-work. Mechanizmy konsensusu sprawiają, że łańcuchy bloków, takie jak Ethereum, są bezpieczne i zdecentralizowane.",
  "page-eth2-staking-pos-explained-desc-1": "Proof-of-stake pomaga zabezpieczyć sieć na wiele sposobów:",
  "page-eth2-staking-services": "Zobacz usługi stakingu",
  "page-eth2-staking-sharding": "Odblokowuje sharding",
  "page-eth2-staking-sharding-desc": "Sharding jest możliwy tylko z systemem proof-of-stake. Fragmentacja (sharding) systemu sprawdzającego pracę zmniejszyłaby ilość mocy obliczeniowej potrzebnej do uszkodzenia sieci, ułatwiając złośliwym górnikom kontrolowanie odłamków. Tak nie jest w przypadku losowo przydzielanych stakerów w systemie proof-of-stake.",
  "page-eth2-staking-solo": "Stakuj solo i uruchom walidator",
  "page-eth2-staking-solo-desc": "Aby rozpocząć proces stakowania, musisz użyć launchpada Eth2. To przeprowadzi Cię przez całą konfigurację. Częścią stakingu jest uruchomienie klienta Eth2, który jest lokalną kopią łańcucha bloków. Pobieranie na komputer może chwilę potrwać.",
  "page-eth2-staking-start": "Rozpocznij staking",
  "page-eth2-staking-subtitle": "Staking jest dobrem publicznym dla ekosystemu Ethereum. Możesz pomóc zabezpieczyć sieć i zdobywać nagrody w tym procesie.",
  "page-eth2-staking-sustainability": "Bardziej zrównoważony",
  "page-eth2-staking-sustainability-desc": "Walidatorzy nie potrzebują energochłonnych komputerów, aby uczestniczyć w systemie proof-of-stake – wystarczy laptop lub smartfon. Dzięki temu Ethereum stanie się lepsze dla środowiska.",
  "page-eth2-staking-the-beacon-chain": "Więcej o łańcuchu śledzącym",
  "page-eth2-staking-title-1": "Nagrody",
  "page-eth2-staking-title-2": "Ryzyko",
  "page-eth2-staking-title-3": "Wymagania",
  "page-eth2-staking-title-4": "Jak stakować swoje ETH",
  "page-eth2-staking-upgrades-li": "Proof-of-stake jest zarządzany przez łańcuch śledzący.",
  "page-eth2-staking-upgrades-li-2": "Ethereum będzie miało łańcuch śledzący i sieć główną proof-of-work w dającej się przewidzieć przyszłości. Sieć główna to Ethereum, którego używamy od lat.",
  "page-eth2-staking-upgrades-li-3": "W tym czasie osoby stakujące będą dodawać nowe bloki do łańcucha śledzącego, ale nie będą przetwarzać transakcji w sieci głównej.",
  "page-eth2-staking-upgrades-li-4": "Ethereum w pełni przejdzie na system proof-of-stake, gdy sieć główna Ethereum stanie się odłamkiem.",
  "page-eth2-staking-upgrades-li-5": "Dopiero wtedy możesz wypłacić swój staking.",
  "page-eth2-staking-upgrades-title": "Ulepszenia proof-of-stake i Eth2",
  "page-eth2-staking-validators": "Więcej walidatorów, większe bezpieczeństwo",
  "page-eth2-staking-validators-desc": "Blockchain taki jak Ethereum można uszkodzić, jeśli kontrolujesz 51% sieci. Na przykład możesz uzyskać 51% walidatorów, aby stwierdzili, że Twoje saldo wskazuje 1 000 000 ETH, a nie 1 ETH. Ale aby kontrolować 51% walidatorów, musiałbyś posiadać 51% ETH w systemie – to dużo!",
  "page-eth2-staking-withdrawals": "Wypłaty nie będą dostępne od razu",
  "page-eth2-staking-withdrawals-desc": "Nie będziesz mógł wycofać swojego stakingu, dopóki nie zostaną wdrożone przyszłe aktualizacje. Wypłaty powinny być dostępne po zadokowaniu sieci głównej z systemem łańcucha śledzącego.",
  "page-eth2-vision-2014": "Zobacz post na blogu z 2014 r. zawierający szczegółowy opis proof-of-stake",
  "page-eth2-vision-beacon-chain": "Łańcuch śledzący",
  "page-eth2-vision-beacon-chain-btn": "Więcej na łańcuchu śledzącym",
  "page-eth2-vision-beacon-chain-date": "Łańcuch śledzący jest aktywny",
  "page-eth2-vision-beacon-chain-desc": "Pierwszy dodatek Eth2 do ekosystemu. Łańcuch śledzący przenosi staking do Ethereum, kładzie podwaliny pod przyszłe ulepszenia i ostatecznie będzie koordynować nowy system.",
  "page-eth2-vision-beacon-chain-upper": "Łańcuch śledzący",
  "page-eth2-vision-desc-1": "Ethereum musi zmniejszyć przeciążenie sieci i poprawić prędkość, aby lepiej obsługiwać globalną bazę użytkowników.",
  "page-eth2-vision-desc-2": "Uruchamianie węzła staje się trudniejsze wraz z rozwojem sieci. Będzie to tylko trudniejsze przy zwiększaniu skali sieci.",
  "page-eth2-vision-desc-3": "Ethereum zużywa zbyt dużo energii elektrycznej. Technologia, która zapewnia bezpieczeństwo sieci, musi być bardziej zrównoważona.",
  "page-eth2-vision-docking-date": "Szacunkowo: 2022 r.",
  "page-eth2-vision-docking-desc": "Sieć główna Ethereum będzie musiała w pewnym momencie „zadokować się” lub „połączyć” z łańcuchem śledzącym. Umożliwi to stakowanie w całej sieci i będzie sygnałem końca energochłonnego wydobycia.",
  "page-eth2-vision-ethereum-node": "Więcej o węzłach",
  "page-eth2-vision-explore-upgrades": "Poznaj ulepszenia",
  "page-eth2-vision-future": "Cyfrowa przyszłość na ogólnoświatową skalę",
  "page-eth2-vision-meta-desc": "Przegląd wpływu, jaki ulepszenia Eth2 będą miały na Ethereum, oraz wyzwań, z jakimi muszą się zmierzyć.",
  "page-eth2-vision-meta-title": "Wizja Ethereum 2.0 (Eth2)",
  "page-eth2-vision-mining": "Więcej o wydobywaniu",
  "page-eth2-vision-problems": "Dzisiejsze problemy",
  "page-eth2-vision-scalability": "Skalowalność",
  "page-eth2-vision-scalability-desc": "Ethereum musi być w stanie obsłużyć więcej transakcji na sekundę bez zwiększania rozmiaru węzłów w sieci. Węzły są kluczowymi uczestnikami sieci przechowującymi i obsługującymi blockchain. Zwiększanie rozmiaru węzła nie jest praktyczne, ponieważ mogą to robić tylko osoby z wydajnymi i drogimi komputerami. Aby się skalować, Ethereum potrzebuje więcej transakcji na sekundę w połączeniu z większą liczbą węzłów. Więcej węzłów oznacza większe bezpieczeństwo.",
  "page-eth2-vision-scalability-desc-3": "Aktualizacja łańcuchów odłamkowych rozłoży obciążenie sieci na 64 nowe łańcuchy. Dzięki temu Ethereum będzie mogło oddychać, zmniejszając zatory i zwiększając prędkość poza obecny limit 15-45 transakcji na sekundę.",
  "page-eth2-vision-scalability-desc-4": "I nawet jeśli będzie więcej łańcuchów, w rzeczywistości będzie to wymagać mniej pracy od walidatorów – opiekunów sieci”. Walidatorzy będą musieli „uruchomić” tylko swój fragment, a nie cały łańcuch Ethereum. Dzięki temu węzły są lżejsze, dzięki czemu Ethereum może się skalować i pozostać zdecentralizowane.",
  "page-eth2-vision-security": "Ochrona",
  "page-eth2-vision-security-desc": "Ulepszenia Eth2 poprawiają bezpieczeństwo Ethereum przed skoordynowanymi atakami, jak atak 51%. Jest to rodzaj ataku, w którym, jeśli ktoś kontroluje większość sieci, może wymusić nieuczciwe zmiany.",
  "page-eth2-vision-security-desc-3": "Przejście na proof-of-stake oznacza, że ​​protokół Ethereum ma większe czynniki zniechęcające do ataków. Dzieje się tak, ponieważ w ramach dowodu stawki (proof-of-stake), walidatorzy, którzy zabezpieczają sieć, muszą wstawić znaczne ilości ETH do protokołu. Jeśli spróbują zaatakować sieć, protokół może automatycznie zniszczyć ich ETH.",
  "page-eth2-vision-security-desc-5": "Nie jest to możliwe w przypadku proof-of-work (dowodu pracy), gdzie najlepszym protokołem jest zmuszenie podmiotów zabezpieczających sieć (górników), do utraty nagród za wydobycie, które w innym przypadku by zarobili. Aby osiągnąć równoważny efekt w proof-of-work, protokół musiałby być w stanie zniszczyć cały sprzęt górnika, gdyby ten próbował oszukiwać.",
  "page-eth2-vision-security-desc-5-link": "Więcej o proof of work",
  "page-eth2-vision-security-desc-8": "Model bezpieczeństwa Ethereum również musi się zmienić z powodu wprowadzenia",
  "page-eth2-vision-security-desc-10": "Staking oznacza również, że nie musisz inwestować w elitarny sprzęt, aby „uruchomić” węzeł Ethereum. Powinno to zachęcić więcej osób do zostania walidatorem, zwiększając decentralizację sieci i zmniejszając obszar powierzchni ataku.",
  "page-eth2-vision-security-staking": "Stakuj ETH",
  "page-eth2-vision-security-validator": "Każdy może stać się walidatorem poprzez zestakowanie swojego ETH.",
  "page-eth2-vision-shard-chains": "łańcuchy odłamkowe",
  "page-eth2-vision-shard-date": "Szacunkowo: 2021 r.",
  "page-eth2-vision-shard-desc-4": "Łańcuchy odłamkowe rozłożą obciążenie sieci na 64 nowe blockchain. Odłamki mogą radykalnie poprawić szybkość transakcji – do 100 000 na sekundę.",
  "page-eth2-vision-shard-upgrade": "Więcej na temat łańcuchów odłamkowych",
  "page-eth2-vision-staking-lower": "Więcej o stakingu",
  "page-eth2-vision-subtitle": "Rozwiń Ethereum, aż będzie wystarczająco potężne, aby pomóc całej ludzkości.",
  "page-eth2-vision-sustainability": "Zrównoważony rozwój",
  "page-eth2-vision-sustainability-desc-1": "Nie jest tajemnicą, że Ethereum i inne blockchainy, takie jak bitcoin, są energochłonne ze względu na wydobycie.",
  "page-eth2-vision-sustainability-desc-2": "Ale Ethereum zmierza w kierunku zabezpieczenia przez ETH, a nie moc obliczeniową – poprzez staking.",
  "page-eth2-vision-sustainability-desc-3": "Chociaż staking zostanie wprowadzony przez łańcuch odłamkowy, Ethereum, którego używamy dzisiaj, będzie działać równolegle przez pewien czas, zanim „połączy się” lub „zadokuje” z aktualizacjami Eth2. Jeden system zabezpieczony ETH, drugi mocą obliczeniową. Dzieje się tak, ponieważ na początku łańcuchy odłamków nie będą w stanie obsłużyć takich rzeczy, jak nasze konta lub aplikacje zdecentralizowane. Nie możemy więc po prostu zapomnieć o górnictwie i sieci głównej.",
  "page-eth2-vision-sustainability-desc-8": "Po uruchomieniu łańcucha śledzącego i łańcucha odłamkowego rozpocznie się praca nad dokowaniem sieci głównej do nowego systemu. Dzięki temu sieć główna zmieni się w odłamek, co sprawi że, będzie zabezpieczona przez ETH i znacznie mniej energochłonna.",
  "page-eth2-vision-sustainability-subtitle": "Ethereum musi być bardziej ekologiczne.",
  "page-eth2-vision-The": ". The",
  "page-eth2-vision-title": "Wizja Eth2",
  "page-eth2-vision-title-1": "Zapchana sieć",
  "page-eth2-vision-title-2": "Miejsce na dysku",
  "page-eth2-vision-title-3": "Za dużo energii",
  "page-eth2-vision-trilemma-cardtext-1": "Aktualizacje Eth2 sprawią, że Ethereum będzie skalowalne, bezpieczne i zdecentralizowane. Fragmentacjia sprawi, że Ethereum będzie bardziej skalowalne poprzez zwiększenie liczby transakcji na sekundę przy jednoczesnym zmniejszeniu mocy potrzebnej do uruchomienia węzła i weryfikacji łańcucha. Łańcuch śledzący zapewni bezpieczeństwo Ethereum, koordynując walidatory między fragmentacjami. A staking obniży barierę uczestnictwa, tworząc większą – bardziej zdecentralizowaną – sieć.",
  "page-eth2-vision-trilemma-cardtext-2": "Bezpieczne i zdecentralizowane sieci blockchain wymagają od każdego węzła weryfikacji każdej transakcji przetwarzanej przez łańcuch. Ta ilość pracy ogranicza liczbę transakcji, które mogą się zdarzyć w danym momencie. Zdecentralizowany i bezpieczny odzwierciedla dzisiejszy łańcuch Ethereum.",
  "page-eth2-vision-trilemma-cardtext-3": "Sieci zdecentralizowane działają poprzez wysyłanie informacji o transakcjach między węzłami – cała sieć musi wiedzieć o każdej zmianie stanu. Skalowanie transakcji na sekundę w zdecentralizowanej sieci stwarza zagrożenie bezpieczeństwa, ponieważ im więcej transakcji, tym dłuższe opóźnienie, tym większe prawdopodobieństwo ataku podczas przesyłania informacji.",
  "page-eth2-vision-trilemma-cardtext-4": "Zwiększenie rozmiaru i mocy węzłów Ethereum może w bezpieczny sposób zwiększyć liczbę transakcji na sekundę, ale wymagania sprzętowe ograniczyłyby, kto mógłby to zrobić – grozi to decentralizacji. Mamy nadzieję, że fragmentacje (sharding) i dowód stawki (proof-of-stake) pozwolą na skalowanie Ethereum poprzez zwiększenie liczby węzłów, a nie rozmiaru węzła.",
  "page-eth2-vision-trilemma-h2": "Wyzwanie związane z zdecentralizowanym skalowaniem",
  "page-eth2-vision-trilemma-modal-tip": "Dotknij kręgów poniżej, aby lepiej zrozumieć problemy związane ze skalowaniem zdecentralizowanym",
  "page-eth2-vision-trilemma-p": "Naiwnym sposobem na rozwiązanie problemów Ethereum byłoby uczynienie go bardziej scentralizowanym. Ale decentralizacja jest zbyt ważna. To decentralizacja, która daje opór cenzurze Ethereum, otwartość i prywatność danych oraz niemal nienaruszalne bezpieczeństwo.",
  "page-eth2-vision-trilemma-p-1": "Wizją Ethereum jest zwiększenie skalowalności i bezpieczeństwa, ale także zachowanie decentralizacji. Osiągnięcie tych 3 cech jest problemem znanym jako trylemat skalowalności.",
  "page-eth2-vision-trilemma-p-2": "Ulepszenia Eth2 mają na celu rozwiązanie tego trylematu, ale są to poważne wyzwania.",
  "page-eth2-vision-trilemma-press-button": "Dotknij kręgów poniżej, aby lepiej zrozumieć problemy związane ze skalowaniem zdecentralizowanym.",
  "page-eth2-vision-trilemma-text-1": "Decentralizacja",
  "page-eth2-vision-trilemma-text-2": "Ochrona",
  "page-eth2-vision-trilemma-text-3": "Skalowalność",
  "page-eth2-vision-trilemma-title-1": "Poznaj trylemat skalowalności",
  "page-eth2-vision-trilemma-title-2": "Aktualizacje Eth2 i zdecentralizowane skalowanie",
  "page-eth2-vision-trilemma-title-3": "Bezpieczne i zdecentralizowane",
  "page-eth2-vision-trilemma-title-4": "Zdecentralizowane oraz skalowalne",
  "page-eth2-vision-trilemma-title-5": "Skalowane i bezpieczne",
  "page-eth2-vision-understanding": "Zrozumienie wizji Eth2",
  "page-eth2-vision-upgrade-needs": "Potrzeba ulepszeń Eth2",
  "page-eth2-vision-upgrade-needs-desc": "Protokół Ethereum, który został uruchomiony w 2015 roku, odniósł niesamowity sukces. Jednak społeczność Ethereum zawsze oczekiwała, że do odblokowania pełnego potencjału Ethereum będzie potrzebnych kilka kluczowych ulepszeń.",
  "page-eth2-vision-upgrade-needs-desc-2": "Wysoki popyt powoduje wzrost opłat transakcyjnych, które sprawiają, że Ethereum jest drogie dla przeciętnego użytkownika. Miejsce na dysku potrzebne do uruchomienia klienta Ethereum rośnie w szybkim tempie. A bazowy algorytm konsensusu proof-of-work, który zapewnia bezpieczeństwo i decentralizację Ethereum, ma duży wpływ na środowisko.",
  "page-eth2-vision-upgrade-needs-desc-3": "To, co jest powszechnie określane jako Eth2, to zestaw ulepszeń, które rozwiązują te problemy i nie tylko. Ten zestaw ulepszeń pierwotnie nosił nazwę „Serenity” i jest aktywnym obszarem badań i rozwoju od 2014 roku.",
  "page-eth2-vision-upgrade-needs-desc-6": " Oznacza to, że nie ma włącznika dla Eth2. Ulepszenia będą dostarczane stopniowo w czasie.",
  "page-eth2-vision-upgrade-needs-serenity": "Zobacz post na blogu w 2015 r. omawiający temat „Serenity”",
  "page-eth2-vision-uprade-needs-desc-5": "Teraz, gdy technologia jest już gotowa, te aktualizacje przeorganizują Ethereum, aby uczynić je bardziej skalowalnym, bezpiecznym i zrównoważonym — aby uczynić życie lepszym dla obecnych użytkowników i zachęcić nowych. Wszystko to przy zachowaniu podstawowej wartości Ethereum, jaką jest decentralizacja.",
  "page-get-eth-cex": "Giełdy scentralizowane",
  "page-get-eth-cex-desc": "Giełdy to firmy, które pozwalają kupować kryptowaluty za pomocą tradycyjnych walut. Mają pieczę nad każdym zakupionym ETH, dopóki nie wyślesz go do portfela, który kontrolujesz.",
  "page-get-eth-checkout-dapps-btn": "Wyprubuj zdecentralizowane aplikacje",
  "page-get-eth-community-safety": "Posty społeczności dotyczące bezpieczeństwa",
  "page-get-eth-description": "Ethereum i ETH nie są kontrolowane przez żaden rząd ani firmę — są zdecentralizowane. Oznacza to, że ETH jest dostępne dla każdego.",
  "page-get-eth-dex": "Giełdy zdecentralizowane (DEX)",
  "page-get-eth-dex-desc": "Jeśli chcesz mieć większą kontrolę, kup ETH peer-to-peer. Dzięki DEX możesz handlować bez oddawania kontroli nad swoimi środkami scentralizowanej firmie.",
  "page-get-eth-dexs": "Giełdy zdecentralizowane (DEX)",
  "page-get-eth-dexs-desc": "Zdecentralizowane giełdy to otwarte rynki dla ETH i innych tokenów. Łączą bezpośrednio kupujących i sprzedających.",
  "page-get-eth-dexs-desc-2": "Zamiast korzystać z zaufanej strony trzeciej do zabezpieczenia środków w transakcji, używają kodu. ETH sprzedającego zostanie przeniesiony tylko wtedy, gdy płatność jest gwarantowana. Ten rodzaj kodu jest znany jako inteligentny kontrakt.",
  "page-get-eth-dexs-desc-3": "Oznacza to, że istnieje mniej ograniczeń geograficznych niż w przypadku scentralizowanych alternatyw. Jeśli ktoś sprzedaje to, co chcesz, i akceptuje metodę płatności, którą możesz zapewnić, możesz działać. DEX pozwalają kupować ETH za pomocą innych tokenów, PayPala, a nawet osobiście dostarczać gotówkę.",
  "page-get-eth-do-not-copy": "PRZYKŁAD: NIE KOPIUJ",
  "page-get-eth-exchanges-disclaimer": "Zebraliśmy te informacje ręcznie. Jeśli zauważysz, że coś jest nie tak, daj nam znać",
  "page-get-eth-exchanges-empty-state-text": "Wprowadź swój kraj zamieszkania, aby zobaczyć listę portfeli i giełd, których możesz użyć do zakupu ETH",
  "page-get-eth-exchanges-except": "Z wyjątkiem",
  "page-get-eth-exchanges-header": "W jakim kraju mieszkasz?",
  "page-get-eth-exchanges-header-exchanges": "Giełdy",
  "page-get-eth-exchanges-header-wallets": "Portfele",
  "page-get-eth-exchanges-intro": "Giełdy i portfele mają ograniczenia co do tego, gdzie mogą sprzedawać kryptowalutę.",
  "page-get-eth-exchanges-no-exchanges": "Przepraszamy, nie znamy żadnej giełdy, która pozwoli Ci kupić ETH w tym kraju. Jeśli zrobisz, powiedz nam na",
  "page-get-eth-exchanges-no-exchanges-or-wallets": "Przepraszamy, nie znamy żadnych giełd ani portfeli, które pozwalają Ci kupić ETH w tym kraju. Jeśli to zrobisz, powiedz nam na",
  "page-get-eth-exchanges-no-wallets": "Przepraszamy, nie znamy żadnych portfeli, które pozwolą Ci kupić ETH w tym kraju. Jeśli to zrobisz, powiedz nam na",
  "page-get-eth-exchanges-success-exchange": "Rejestracja na giełdzie może potrwać kilka dni ze względu na ich prawne kontrole.",
  "page-get-eth-exchanges-success-wallet-link": "portfele",
  "page-get-eth-exchanges-success-wallet-paragraph": "Tam, gdzie mieszkasz, możesz kupić ETH bezpośrednio z tych portfeli. Dowiedz się więcej o",
  "page-get-eth-exchanges-usa": "Stany Zjednoczone (USA)",
  "page-get-eth-get-wallet-btn": "Zdobądź portfel",
  "page-get-eth-hero-image-alt": "Pobierz obraz główny ETH",
  "page-get-eth-keep-it-safe": "Dbanie o bezpieczeństwo ETH",
  "page-get-eth-meta-description": "Jak kupować ETH w zależności od miejsca zamieszkania i porady, jak się nim zajmować.",
  "page-get-eth-meta-title": "Jak kupić ETH",
  "page-get-eth-need-wallet": "Będziesz potrzebować portfela, aby korzystać ze zdecentralizowanej giełdy.",
  "page-get-eth-new-to-eth": "ETH jest dla Ciebie nowością? Oto przegląd na początek.",
  "page-get-eth-other-cryptos": "Kupuj za inne kryptowaluty",
  "page-get-eth-protect-eth-desc": "Jeśli planujesz kupić dużo ETH, możesz chcieć trzymać go w portfelu, który kontrolujesz, a nie na giełdzie. To dlatego, że giełda jest prawdopodobnym celem hakerów. Jeśli haker uzyska dostęp, możesz stracić swoje środki. Alternatywnie tylko Ty masz kontrolę nad swoim portfelem.",
  "page-get-eth-protect-eth-in-wallet": "Zabezpiecz swój ETH w portfelu",
  "page-get-eth-search-by-country": "Szukaj według kraju",
  "page-get-eth-security": "Ale oznacza to również, że musisz poważnie traktować bezpieczeństwo swoich funduszy. Dzięki ETH nie ufasz bankowi, że zajmie się Twoimi pieniędzmi, ufasz sobie.",
  "page-get-eth-smart-contract-link": "Więcej na temat inteligentnych kontraktów",
  "page-get-eth-swapping": "Zamień swoje tokeny na ETH innych osób. I wzajemnie.",
  "page-get-eth-traditional-currencies": "Kup za pomocą tradycyjnych walut",
  "page-get-eth-traditional-payments": "Kupuj ETH za pomocą tradycyjnych typów płatności bezpośrednio od sprzedawców.",
  "page-get-eth-try-dex": "Wypróbuj Dex",
  "page-get-eth-use-your-eth": "Użyj swojego ETH",
  "page-get-eth-use-your-eth-dapps": "Teraz, gdy jesteś właścicielem ETH, sprawdź niektóre aplikacje Ethereum (aplikacje zdecentralizowane). Istnieją aplikacje dla finansów, mediów społecznościowych, gier i wielu innych kategorii.",
  "page-get-eth-wallet-instructions": "Postępuj zgodnie z instrukcją portfela",
  "page-get-eth-wallet-instructions-lost": "Jeśli utracisz dostęp do swojego portfela, stracisz dostęp do swoich środków. Twój portfel powinien zawierać instrukcje dotyczące ochrony przed tym. Pamiętaj, aby dokładnie ich przestrzegać — w większości przypadków nikt nie może Ci pomóc, jeśli stracisz dostęp do swojego portfela.",
  "page-get-eth-wallets": "Portfele",
  "page-get-eth-wallets-link": "Więcej o portfelach",
  "page-get-eth-wallets-purchasing": "Niektóre portfele pozwalają na zakup kryptowalut za pomocą karty debetowej/kredytowej, przelew bankowy lub nawet Apple Pay. Zastosowanie mają ograniczenia geograficzne.",
  "page-get-eth-warning": "Te DEXy nie są dla początkujących, ponieważ będziesz potrzebować ETH, aby z nich korzystać.",
  "page-get-eth-what-are-DEX's": "Czym jest DEX?",
  "page-get-eth-whats-eth-link": "Co to jest ETH?",
  "page-get-eth-where-to-buy-desc": "Możesz kupić ETH bezpośrednio z giełd lub z portfeli.",
  "page-get-eth-where-to-buy-desc-2": "Sprawdź usługi, z których możesz korzystać w miejscu zamieszkania.",
  "page-get-eth-where-to-buy-title": "Gdzie uzyskać ETH",
  "page-get-eth-your-address": "Twój adres ETH",
  "page-get-eth-your-address-desc": "Kiedy pobierzesz portfel, stworzy dla Ciebie publiczny adres ETH. Oto jak wygląda:",
  "page-get-eth-your-address-desc-3": "To coś podobnego do adresu e-mail, ale zamiast wysyłać wiadomość, możesz odbierać ETH. Jeśli chcesz przenieść ETH z giełdy do swojego portfela, użyj swojego adresu jako miejsca docelowego. Upewnij się, że zawsze sprawdzasz dokładnie przed wysłaniem!",
  "page-get-eth-your-address-wallet-link": "Sprawdź portfele",
  "page-index-hero-image-alt": "Główny obrazek ethereum.org",
  "page-index-meta-description": "Ethereum jest globalną, zdecentralizowaną platformą łączącą waluty i nowe aplikacje. Na Ethereum możesz napisać kod, który zarządza działaniem pieniądza i tworzyć aplikacje, do których dostęp można uzyskać na całym świecie.",
  "page-index-meta-title": "Strona główna",
  "page-index-title": "Ethereum to globalna platforma open source dla zdecentralizowanych aplikacji.",
  "page-index-description": "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.",
  "page-index-title-button": "Explore Ethereum",
  "page-index-get-started": "Get started",
  "page-index-get-started-description": "ethereum.org is your portal into the world of Ethereum. The tech is new and ever-evolving – it helps to have a guide. Here's what we recommend you do if you want to dive in.",
  "page-index-get-started-image-alt": "Illustration of a person working on a computer.",
  "page-index-get-started-wallet-title": "Pick a wallet",
  "page-index-get-started-wallet-description": "A wallet lets you connect to Ethereum and manage your funds.",
  "page-index-get-started-wallet-image-alt": "Illustration of a robot with a vault for a body, representing an Ethereum wallet.",
  "page-index-get-started-eth-title": "Get ETH",
  "page-index-get-started-eth-description": "ETH is the currency of Ethereum – you can use it in applications.",
  "page-index-get-started-eth-image-alt": "Illustration of a group of people marvelling at an ether (ETH) glyph in awe.",
  "page-index-get-started-dapps-title": "Use a dapp",
  "page-index-get-started-dapps-description": "Dapps are applications powered by Ethereum. See what you can do.",
  "page-index-get-started-dapps-image-alt": "Illustration of a doge using a computer.",
  "page-index-get-started-devs-title": "Start building",
  "page-index-get-started-devs-description": "If you want to start coding with Ethereum, we have documentation, tutorials, and more in our developer portal.",
  "page-index-get-started-devs-image-alt": "An illustration of a hand creating an ETH logo made of lego bricks.",
  "page-index-what-is-ethereum": "What is Ethereum?",
  "page-index-what-is-ethereum-description": "Ethereum is a technology that's home to digital money, global payments, and applications. The community has built a booming digital economy, bold new ways for creators to earn online, and so much more. It's open to everyone, wherever you are in the world – all you need is the internet.",
  "page-index-what-is-ethereum-button": "What is Ethereum?",
  "page-index-what-is-ethereum-secondary-button": "More on digital money",
  "page-index-what-is-ethereum-image-alt": "Illustration of a person peering into a bazaar, meant to represent Ethereum.",
  "page-index-defi": "A fairer financial system",
  "page-index-defi-description": "Today, billions of people can’t open bank accounts, others have their payments blocked. Ethereum's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world.",
  "page-index-defi-button": "Explore DeFi",
  "page-index-defi-image-alt": "Illustration of hands offering an ETH symbol.",
  "page-index-internet": "An open internet",
  "page-index-internet-description": "Today, we gain access to 'free' internet services by giving up control of our personal data. Ethereum services are open by default – you just need a wallet. These are free and easy to set up, controlled by you, and work without any personal info.",
  "page-index-internet-button": "Explore the open internet",
  "page-index-internet-secondary-button": "More on wallets",
  "page-index-internet-image-alt": "Illustration of a futuristic computer set up, powered by Ethereum crystals.",
  "page-index-developers": "A new frontier for development",
  "page-index-developers-description": "Ethereum and its apps are transparent and open source. You can fork code and re-use functionality others have already built. If you don't want to learn a new language you can just interact with open-sourced code using JavaScript and other existing languages.",
  "page-index-developers-button": "Developer portal",
  "page-index-developers-code-example-title-0": "Your own bank",
  "page-index-developers-code-example-description-0": "You can build a bank run by logic you've programmed.",
  "page-index-developers-code-example-title-1": "Your own currency",
  "page-index-developers-code-example-description-1": "You can create tokens that you can transfer and use across applications.",
  "page-index-developers-code-example-title-2": "A JavaScript Ethereum wallet",
  "page-index-developers-code-example-description-2": "You can use existing languages to interact with Ethereum and other applications.",
  "page-index-developers-code-example-title-3": "An open, permissionless DNS",
  "page-index-developers-code-example-description-3": "You can reimagine existing services as decentralized, open applications.",
  "page-index-network-stats-title": "Ethereum today",
  "page-index-network-stats-subtitle": "The latest network statistics",
  "page-index-network-stats-eth-price-description": "ETH price (USD)",
  "page-index-network-stats-eth-price-explainer": "The latest price for 1 ether. You can buy as little as 0.000000000000000001 – you don't need to buy 1 whole ETH.",
  "page-index-network-stats-tx-day-description": "Transactions today",
  "page-index-network-stats-tx-day-explainer": "The number of transactions successfully processed on the network in the last 24 hours.",
  "page-index-network-stats-value-defi-description": "Value locked in DeFi (USD)",
  "page-index-network-stats-value-defi-explainer": "The amount of money in decentralized finance (DeFi) applications, the Ethereum digital economy.",
  "page-index-network-stats-nodes-description": "Nodes",
  "page-index-network-stats-nodes-explainer": "Ethereum is run by thousands of volunteers around the globe, known as nodes.",
  "page-index-touts-header": "Explore ethereum.org",
  "page-index-contribution-banner-title": "Contribute to ethereum.org",
  "page-index-contribution-banner-description": "This website is open source with hundreds of community contributors. You can propose edits to any of the content on this site, suggest awesome new features, or help us squash bugs.",
  "page-index-contribution-banner-image-alt": "An Ethereum logo made of lego bricks.",
  "page-index-contribution-banner-button": "More on contributing",
  "page-index-tout-eth2-title": "Upgrade your Eth2 knowledge",
  "page-index-tout-eth2-description": "Ethereum 2.0 is a program of interconnected upgrades designed to make Ethereum more scalable, secure, and sustainable.",
  "page-index-tout-eth2-image-alt": "Illustration of a spaceship representing the increased power of Eth2.",
  "page-index-tout-enterprise-title": "Ethereum for enterprise",
  "page-index-tout-enterprise-description": "See how Ethereum can open up new business models, reduce your costs and future-proof your business.",
  "page-index-tout-enterprise-image-alt": "Illustration of a futuristic computer/device.",
  "page-index-tout-community-title": "The Ethereum community",
  "page-index-tout-community-description": "Ethereum is all about community. It's made up of people from all different backgrounds and interests. See how you can join in.",
  "page-index-tout-community-image-alt": "Illustration of a group of builders working together.",
  "page-index-nft": "The internet of assets",
  "page-index-nft-description": "Ethereum isn't just for digital money. Anything you can own can be represented, traded and put to use as non-fungible tokens (NFTs). You can tokenise your art and get royalties automatically every time it's re-sold. Or use a token for something you own to take out a loan. The possibilities are growing all the time.",
  "page-index-nft-button": "More on NFTs",
  "page-index-nft-alt": "An Eth logo being displayed via hologram.",
  "page-languages-h1": "Obsługa języków",
  "page-languages-interested": "Chcesz wziąć udział?",
  "page-languages-learn-more": "Dowiedz się więcej o naszym programie tłumaczeń",
  "page-languages-meta-desc": "Zasoby do wszystkich obsługiwanych języków ethereum.org i sposoby zaangażowania się jako tłumacz.",
  "page-languages-meta-title": "Tłumaczenia języków ethereum.org",
  "page-languages-p1": "Ethereum to projekt globalny i bardzo ważne jest, aby ethereum.org był dostępny dla każdego, niezależnie od narodowości czy języka. Nasza społeczność ciężko pracuje nad urzeczywistnieniem tej wizji.",
  "page-languages-translations-available": "ethereum.org jest dostępny w następujących językach",
  "page-languages-want-more-header": "Chcesz zobaczyć ethereum.org w innym języku?",
  "page-languages-want-more-link": "programie tłumaczenia",
  "page-languages-want-more-paragraph": "tłumacze ethereum.org zawsze tłumaczą strony na jak najwięcej języków. Aby zobaczyć, nad czym teraz pracują, lub aby się zarejestrować, aby dołączyć do nich, przeczytaj o naszym",
  "page-languages-filter-placeholder": "Filter",
  "page-stablecoins-accordion-borrow-crypto-collateral": "Zabezpieczenie kryptowalutowe",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy": "Dzięki Ethereum możesz pożyczać bezpośrednio od innych użytkowników bez wymiany swojego ETH. To może dać ci dźwignię – niektórzy robią to, aby spróbować zgromadzić więcej ETH.",
  "page-stablecoins-accordion-borrow-crypto-collateral-copy-p2": "Ale ponieważ cena ETH jest zmienna, będziesz musiał zawyżać zabezpieczenie. Oznacza to, że jeśli chcesz pożyczyć 100 stabicoinów, prawdopodobnie potrzebujesz co najmniej 150 dolarów ETH. To chroni system i kredytodawców.",
  "page-stablecoins-accordion-borrow-crypto-collateral-link": "Więcej o stablecoinach zabezpieczonych kryptowalutami",
  "page-stablecoins-accordion-borrow-pill": "Zaawansowane",
  "page-stablecoins-accordion-borrow-places-intro": "Te aplikacje pozwalają pożyczać stablecoiny za pomocą kryptowalut. Niektóre z nich akceptują inne tokeny, jak również ETH.",
  "page-stablecoins-accordion-borrow-places-title": "Miejsca do pożyczenia stablecoinów",
  "page-stablecoins-accordion-borrow-requirement-1": "Portfel Ethereum",
  "page-stablecoins-accordion-borrow-requirement-1-description": "Będziesz potrzebował portfela aby korzystać z decentralizowanej aplikacji",
  "page-stablecoins-accordion-borrow-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-borrow-requirement-2-description": "Będziesz potrzebował ETH do zabezpieczenia i/lub opłat transakcyjnych",
  "page-stablecoins-accordion-borrow-requirements-description": "Aby pożyczyć stablecoiny, musisz użyć odpowiedniej zdecentralizowanej aplikacji. Będziesz również potrzebował portfela i trochę ETH.",
  "page-stablecoins-accordion-borrow-risks-copy": "Jeśli użyjesz ETH jako zabezpieczenia, a jego wartość spadnie, Twoje zabezpieczenie nie pokryje wygenerowanych stablecoinów. Spowoduje to zlikwidowanie ETH i może grozić Ci kara. Więc jeśli pożyczysz stablecoiny, będziesz musiał obserwować cenę ETH.",
  "page-stablecoins-accordion-borrow-risks-link": "Najnowsza cena ETH",
  "page-stablecoins-accordion-borrow-risks-title": "Ryzyko",
  "page-stablecoins-accordion-borrow-text-preview": "Możesz pożyczyć trochę stablecoinów, używając kryptowaluty jako zabezpieczenia, które musisz spłacić.",
  "page-stablecoins-accordion-borrow-title": "Pożyczaj",
  "page-stablecoins-accordion-buy-exchanges-title": "Popularne giełdy",
  "page-stablecoins-accordion-buy-requirement-1": "Wymiana kryptowalut i portfele",
  "page-stablecoins-accordion-buy-requirement-1-description": "Sprawdź usługi, z których możesz korzystać w miejscu zamieszkania",
  "page-stablecoins-accordion-buy-requirements-description": "Konto z giełdą lub portfelem, z którego możesz kupić kryptowalutę bezpośrednio. Być może użyłeś już jednego, aby uzyskać trochę ETH. Sprawdź, z jakich usług możesz korzystać w swojej okolicy.",
  "page-stablecoins-accordion-buy-text-preview": "Wiele giełd i portfeli pozwala kupować stablecoiny bezpośrednio. Obowiązują ograniczenia geograficzne.",
  "page-stablecoins-accordion-buy-title": "Kup",
  "page-stablecoins-accordion-buy-warning": "Scentralizowane giełdy mogą wymieniać tylko stablecoiny wspierane przez pieniądz fiducjarny oparty na złocie, takie jak USDC, Tether i inne. Możesz nie być w stanie ich kupić bezpośrednio, ale powinieneś być w stanie wymienić je na ETH lub inne kryptowaluty, które możesz kupić na platformie.",
  "page-stablecoins-accordion-earn-project-1-description": "Głównie prace techniczne dla ruchu oprogramowania open source.",
  "page-stablecoins-accordion-earn-project-2-description": "Technologia, treść i inne prace dla społeczności MakerDao (zespół, który przyniósł Ci Dai).",
  "page-stablecoins-accordion-earn-project-3-description": "Jeśli naprawdę znasz się na rzeczy, znajdź błędy, aby zarobić Dai.",
  "page-stablecoins-accordion-earn-project-bounties": "Nagrody Gitcoin",
  "page-stablecoins-accordion-earn-project-bug-bounties": "Nagrody za błędy Eth2",
  "page-stablecoins-accordion-earn-project-community": "Społeczność MakerDao",
  "page-stablecoins-accordion-earn-projects-copy": "Są to platformy, które za Twoją pracę zapłacą Ci w stablecoinach.",
  "page-stablecoins-accordion-earn-projects-title": "Gdzie zarabiać stablecoiny",
  "page-stablecoins-accordion-earn-requirement-1": "Portfel Ethereum",
  "page-stablecoins-accordion-earn-requirement-1-description": "Będziesz potrzebować portfela, aby otrzymać zarobione stablecoiny",
  "page-stablecoins-accordion-earn-requirements-description": "Stablecoiny są świetną metodą płatności za pracę i usługi, ponieważ wartość jest stabilna. Jednak potrzebujesz portfela do zapłaty.",
  "page-stablecoins-accordion-earn-text-preview": "Możesz zarabiać stablecoiny, pracując nad projektami w ramach ekosystemu Ethereum.",
  "page-stablecoins-accordion-earn-title": "Zarabiaj",
  "page-stablecoins-accordion-less": "Mniej",
  "page-stablecoins-accordion-more": "Więcej",
  "page-stablecoins-accordion-requirements": "Czego będziesz potrzebować",
  "page-stablecoins-accordion-swap-dapp-intro": "Jeśli masz już ETH i portfel, możesz użyć tych aplikacji do wymiany na stablecoiny.",
  "page-stablecoins-accordion-swap-dapp-link": "Więcej na temat zdecentralizowanych giełd",
  "page-stablecoins-accordion-swap-dapp-title": "Zdecentralizowane aplikacje do wymiany tokenów",
  "page-stablecoins-accordion-swap-editors-tip": "Wskazówka redaktora",
  "page-stablecoins-accordion-swap-editors-tip-button": "Znajdź portfel",
  "page-stablecoins-accordion-swap-editors-tip-copy": "Zdobądź portfel, który pozwoli Ci kupić ETH i zamienić go bezpośrednio na tokeny, w tym na stablecoiny.",
  "page-stablecoins-accordion-swap-pill": "Rekomendowane",
  "page-stablecoins-accordion-swap-requirement-1": "Portfel Ethereum",
  "page-stablecoins-accordion-swap-requirement-1-description": "Będziesz potrzebował portfela aby autoryzować wymianę i przechowywać swoje monety",
  "page-stablecoins-accordion-swap-requirement-2": "Ether (ETH)",
  "page-stablecoins-accordion-swap-requirement-2-description": "Zapłać za wymianę",
  "page-stablecoins-accordion-swap-text-preview": "Większość stablecoinów możesz odebrać na zdecentralizowanych giełdach. Możesz więc zamienić dowolne posiadane tokeny na żądany stablecoin.",
  "page-stablecoins-accordion-swap-title": "Zamień",
  "page-stablecoins-algorithmic": "Algorytmicznie",
  "page-stablecoins-algorithmic-con-1": "Musisz zaufać algorytmowi (lub być w stanie go odczytać).",
  "page-stablecoins-algorithmic-con-2": "Twoje saldo monet zmieni się w zależności od całkowitej podaży.",
  "page-stablecoins-algorithmic-description": "Te stablecoiny nie są wspierane żadnymi innymi aktywami. Zamiast tego algorytm będzie sprzedawał tokeny, jeśli cena spadnie poniżej pożądanej wartości, i dostarczy tokeny, jeśli wartość przekroczy pożądaną kwotę. Ponieważ liczba tych tokenów w obiegu zmienia się regularnie, liczba posiadanych przez Ciebie tokenów będzie się zmieniać, ale zawsze będzie odzwierciedlać Twój udział.",
  "page-stablecoins-algorithmic-pro-1": "Zabezpieczenie nie jest wymagane.",
  "page-stablecoins-algorithmic-pro-2": "Kontrolowany przez algorytm publiczny.",
  "page-stablecoins-bank-apy": "0,05%",
  "page-stablecoins-bank-apy-source": "Średnia stawka płacona przez banki na podstawie rachunków oszczędnościowych ubezpieczonych federalnie, w USA.",
  "page-stablecoins-bank-apy-source-link": "Źródło",
  "page-stablecoins-bitcoin-pizza": "Niesamowita pizza bitcoin",
  "page-stablecoins-bitcoin-pizza-body": "W 2010 roku ktoś kupił 2 pizze za 10 000 bitcoinów. W tamtym czasie były one warte ~41 USD. Na dzisiejszym rynku to miliony dolarów. W historii Ethereum jest wiele podobnych żałosnych transakcji. Stablecoins rozwiązują ten problem, dzięki czemu możesz cieszyć się pizzą i trzymać się ETH.",
  "page-stablecoins-coin-price-change": "Zmiana cen monet (ostatnie 30 dni)",
  "page-stablecoins-crypto-backed": "Zabezpieczone kryptowalutą",
  "page-stablecoins-crypto-backed-con-1": "Mniej stabilne niż stablecoiny wspierane przez pieniądz fiducjarny.",
  "page-stablecoins-crypto-backed-con-2": "Musisz przyglądać się wartości zabezpieczenia kryptowalutą.",
  "page-stablecoins-crypto-backed-description": "Te stablecoiny są wspierane przez inne aktywa kryptowalutowe, takie jak ETH. Ich cena zależy od wartości aktywów bazowych (lub zabezpieczenia), które mogą być zmienne. Ponieważ wartość ETH może ulegać wahaniom, te stablecoiny są zabezpieczane, aby zapewnić jak najbardziej stabilną cenę. Oznacza to, że bliższe jest stwierdzenie, że stablecoin wspierany kryptowalutą o wartości 1 USD ma bazowy zasób kryptowalutowy o wartości co najmniej 2 USD. Więc jeśli cena ETH spadnie, więcej ETH musi zostać wykorzystane do wsparcia stablecoina, w przeciwnym razie stablecoiny stracą swoją wartość.",
  "page-stablecoins-crypto-backed-pro-1": "Przejrzysty i w pełni zdecentralizowany.",
  "page-stablecoins-crypto-backed-pro-2": "Szybko zamieni się w inne aktywa kryptowalutowe.",
  "page-stablecoins-crypto-backed-pro-3": "Brak zewnętrznych powierników – wszystkie aktywa są kontrolowane przez konta Ethereum.",
  "page-stablecoins-dai-banner-body": "Dai jest prawdopodobnie najbardziej znanym zdecentralizowanym stablecoinem. Jego wartość wynosi około dolara i jest powszechnie akceptowana w różnych zdecentralizowanych aplikacjach.",
  "page-stablecoins-dai-banner-learn-button": "Dowiedz się więcej o Dai",
  "page-stablecoins-dai-banner-swap-button": "Zamień ETH na Dai",
  "page-stablecoins-dai-banner-title": "Dai",
  "page-stablecoins-dai-logo": "Logo Dai",
  "page-stablecoins-editors-choice": "Wybór redakcji",
  "page-stablecoins-editors-choice-intro": "Są to prawdopodobnie najbardziej znane przykłady stablecoinów i monety, które uznaliśmy za przydatne podczas korzystania ze zdecentralizowanych aplikacji.",
  "page-stablecoins-explore-dapps": "Przeglądaj zdecentralizowane aplikacje",
  "page-stablecoins-fiat-backed": "Pieniądz fiducjarny oparty na złocie",
  "page-stablecoins-fiat-backed-con-1": "Centralizacja – ktoś musi wydać tokeny.",
  "page-stablecoins-fiat-backed-con-2": "Wymaga audytu, aby zapewnić posiadanie przez przedsiębiorstwo wystarczających rezerw.",
  "page-stablecoins-fiat-backed-description": "Zasadniczo tokeny IOU (I owe you) za tradycyjną walutę fiducjarną (zwykle dolary). Używasz swojej waluty fiducjarnej do zakupu stablecoina, który możesz później spieniężyć i wymienić na oryginalną walutę.",
  "page-stablecoins-fiat-backed-pro-1": "Bezpieczny przed zmiennością kryptowalut.",
  "page-stablecoins-fiat-backed-pro-2": "Zmiany w cenie są minimalne.",
  "page-stablecoins-find-stablecoin": "Znajdź stablecoina",
  "page-stablecoins-find-stablecoin-how-to-get-them": "Jak zdobyć stablecoiny",
  "page-stablecoins-find-stablecoin-intro": "Dostępne są setki stablecoinów. Oto kilka, które pomogą Ci zacząć. Jeśli jesteś nowy w Ethereum, zalecamy najpierw przeprowadzić pewne badania.",
  "page-stablecoins-find-stablecoin-types-link": "Różne rodzaje stablecoinów",
  "page-stablecoins-get-stablecoins": "Jak zdobyć stablecoiny",
  "page-stablecoins-hero-alt": "Trzy największe stablecoiny według kapitalizacji rynkowej: Dai, USDC i Tether.",
  "page-stablecoins-hero-button": "Zdobądź stablecoiny",
  "page-stablecoins-hero-header": "Pieniądze cyfrowe do codziennego użytku",
  "page-stablecoins-hero-subtitle": "Stablecoins to tokeny Ethereum zaprojektowane tak, aby utrzymywać stałą wartość, nawet gdy zmienia się cena ETH.",
  "page-stablecoins-interest-earning-dapps": "Zdecentralizowane Aplikacje przynoszące odsetki",
  "page-stablecoins-meta-description": "Wprowadzenie do stablecoinów Ethereum: czym są, jak je zdobyć i dlaczego są ważne.",
  "page-stablecoins-precious-metals": "Metale szlachetne",
  "page-stablecoins-precious-metals-con-1": "Scentralizowany – ktoś musi wydać tokeny.",
  "page-stablecoins-precious-metals-con-2": "Musisz zaufać emitentowi tokenów i rezerwom metali szlachetnych.",
  "page-stablecoins-precious-metals-description": "Podobnie jak pieniądze fiducjarne oparte na złocie, te stablecoiny wykorzystują zasoby takie jak złoto, aby utrzymać swoją wartość.",
  "page-stablecoins-precious-metals-pro-1": "Bezpieczny przed zmiennością kryptowalut.",
  "page-stablecoins-prices": "Ceny stablecoinów",
  "page-stablecoins-prices-definition": "Stablecoiny to kryptowaluty bez zmienności. Mają wiele takich samych właściwości jak ETH, ale ich wartość jest stała, bardziej jak tradycyjna waluta. Masz więc dostęp do stabilnych pieniędzy, które możesz wykorzystać na Ethereum. ",
  "page-stablecoins-prices-definition-how": "Jak stablecoiny zyskują stabilność",
  "page-stablecoins-research-warning": "Ethereum to nowa technologia i większość aplikacji jest nowa. Upewnij się, że jesteś świadomy ryzyka i deponuj tylko tyle, ile możesz stracić.",
  "page-stablecoins-research-warning-title": "Zawsze wykonuj własne wyszukiwania",
  "page-stablecoins-save-stablecoins": "Oszczędzaj za pomocą stablecoinów",
  "page-stablecoins-save-stablecoins-body": "Stablecoiny często mają ponadprzeciętne oprocentowanie, ponieważ istnieje duże zapotrzebowanie na ich pożyczanie. Istnieją zdecentralizowane aplikacje, które pozwalają zarabiać odsetki na stablecoinach w czasie rzeczywistym, deponując je w puli pożyczek. Podobnie jak w świecie bankowym, dostarczasz tokeny dla pożyczkobiorców, ale w każdej chwili możesz wypłacić swoje tokeny i odsetki.",
  "page-stablecoins-saving": "Umieść swoje oszczędności w stablecoinach i zarób trochę odsetek. Jak wszystko w kryptowalutach, przewidywane roczne zyski procentowe (APY) mogą zmieniać się z dnia na dzień w zależności od podaży/popytu w czasie rzeczywistym.",
  "page-stablecoins-stablecoins-dapp-callout-description": "Sprawdź zdecentralizowane aplikacje Ethereum – stablecoiny są często bardziej przydatne do codziennych transakcji.",
  "page-stablecoins-stablecoins-dapp-callout-image-alt": "Ilustracja pieseła.",
  "page-stablecoins-stablecoins-dapp-callout-title": "Użyj swoich stablecoinów",
  "page-stablecoins-stablecoins-dapp-description-1": "Rynki dla wielu stablecoinów, w tym Dai, USDC, TUSD, USDT i innych. ",
  "page-stablecoins-stablecoins-dapp-description-2": "Pożyczaj stablecoiny i zarabiaj odsetki oraz $COMP, własny token Compound.",
  "page-stablecoins-stablecoins-dapp-description-3": "Platforma handlowa, na której możesz zarabiać na swoich Dai i USDC.",
  "page-stablecoins-stablecoins-dapp-description-4": "Aplikacja zaprojektowana do zapisywania Dai.",
  "page-stablecoins-stablecoins-feature-1": "Stablecoins są globalne i można je wysyłać przez Internet. Łatwo je otrzymać lub wysłać, gdy masz konto Ethereum.",
  "page-stablecoins-stablecoins-feature-2": "Popyt na stablecoiny jest wysoki, więc możesz zarabiać odsetki za pożyczanie swoich. Upewnij się, że jesteś świadomy ryzyka przed pożyczeniem.",
  "page-stablecoins-stablecoins-feature-3": "Stablecoiny są wymienialne na ETH i inne tokeny Ethereum. Wiele aplikacji zdecentralizowanych opiera się na stablecoinach.",
  "page-stablecoins-stablecoins-feature-4": "Stablecoiny są zabezpieczone kryptograficznie. Nikt nie może fałszować transakcji w Twoim imieniu.",
  "page-stablecoins-stablecoins-meta-description": "Wprowadzenie do stablecoinów Ethereum: czym są, jak je zdobyć i dlaczego są ważne.",
  "page-stablecoins-stablecoins-table-header-column-1": "Waluta",
  "page-stablecoins-stablecoins-table-header-column-2": "Kapitalizacja rynkowa",
  "page-stablecoins-stablecoins-table-header-column-3": "Rodzaj zabezpieczenia",
  "page-stablecoins-stablecoins-table-type-crypto-backed": "Kryptowaluta",
  "page-stablecoins-stablecoins-table-type-fiat-backed": "Waluta fiducjarna",
  "page-stablecoins-stablecoins-table-type-precious-metals-backed": "Metale szlachetne",
  "page-stablecoins-table-error": "Nie udało się wczytać stablecoinów. Spróbuj odświeżyć stronę.",
  "page-stablecoins-table-loading": "Ładowanie danych stablecoina...",
  "page-stablecoins-title": "Stablecoiny",
  "page-stablecoins-top-coins": "Najlepsze stablecoiny według kapitalizacji rynkowej",
  "page-stablecoins-top-coins-intro": "Kapitalizacja rynkowa to",
  "page-stablecoins-top-coins-intro-code": "łączna liczba istniejących tokenów pomnożona przez wartość na token. Ta lista jest dynamiczna, a wymienione tutaj projekty niekoniecznie są wspierane przez zespół ethereum.org.",
  "page-stablecoins-types-of-stablecoin": "Jak działają: rodzaje stablecoin",
  "page-stablecoins-usdc-banner-body": "Dai jest prawdopodobnie najbardziej znanym zdecentralizowanym stablecoinem. Jego wartość wynosi około dolara i jest powszechnie akceptowana w różnych zdecentralizowanych aplikacjach.",
  "page-stablecoins-usdc-banner-learn-button": "Dowiedz się więcej o USDC",
  "page-stablecoins-usdc-banner-swap-button": "Zamień ETH na USDC",
  "page-stablecoins-usdc-banner-title": "USDC",
  "page-stablecoins-usdc-logo": "Logo USDC",
  "page-stablecoins-why-stablecoins": "Dlaczego stablecoiny?",
  "page-stablecoins-how-they-work-button": "Jak one działają",
  "page-stablecoins-why-stablecoins-body": "ETH, podobnie jak Bitcoin, ma zmienną cenę, ponieważ jest to nowa technologia. Możesz więc nie chcieć wydawać go regularnie. Stablecoiny odzwierciedlają wartość tradycyjnych walut, dając Ci dostęp do stabilnych pieniędzy, których możesz używać na Ethereum.",
  "page-stablecoins-more-defi-button": "More on decentralized finance (DeFi)",
  "page-find-wallet-add-wallet": ". Jeśli chcesz, abyśmy dodali portfel,",
  "page-find-wallet-alpha-logo-alt": "Logo AlphaWallet",
  "page-find-wallet-ambo-logo-alt": "Logo Ambo",
  "page-find-wallet-argent-logo-alt": "Logo Argent",
  "page-find-wallet-buy-card": "Kup kryptowaluty za pomocą karty",
  "page-find-wallet-buy-card-desc": "Kup ETH bezpośrednio z portfela za pomocą karty bankowej. Mogą obowiązywać ograniczenia geograficzne.",
  "page-find-wallet-card-yes": "Yes",
  "page-find-wallet-card-no": "No",
  "page-find-wallet-card-go": "Go",
  "page-find-wallet-card-hardware": "Hardware",
  "page-find-wallet-card-mobile": "Mobile",
  "page-find-wallet-card-desktop": "Desktop",
  "page-find-wallet-card-web": "Web",
  "page-find-wallet-card-more-info": "More info",
  "page-find-wallet-card-features": "Features",
  "page-find-wallet-card-has-bank-withdraws": "Withdraw to bank",
  "page-find-wallet-card-has-card-deposits": "Buy ETH with card",
  "page-find-wallet-card-has-defi-integration": "Access to DeFi",
  "page-find-wallet-card-has-explore-dapps": "Explore dapps",
  "page-find-wallet-card-has-dex-integrations": "Swap tokens",
  "page-find-wallet-card-has-high-volume-purchases": "Buy in high volume",
  "page-find-wallet-card-has-limits-protection": "Transaction limits",
  "page-find-wallet-card-has-multisig": "Multi-sig protection",
  "page-find-wallet-checkout-dapps": "Wypróbuj zdecentralizowane aplikacje",
  "page-find-wallet-clear": "Wyczyść filtry",
  "page-find-wallet-coinbase-logo-alt": "Logo Coinbase",
  "page-find-wallet-coinomi-logo-alt": "Coinomi logo",
  "page-find-wallet-coin98-logo-alt": "Coin98 logo",
  "page-find-wallet-dcent-logo-alt": "D'CENT logo",
  "page-find-wallet-desc-2": "Wybierz więc portfel w oparciu o żądane funkcje.",
  "page-find-wallet-description": "Portfele mają wiele opcjonalnych funkcji, które mogą Ci się spodobać.",
  "page-find-wallet-description-alpha": "W pełni otwarty portfel Ethereum, który wykorzystuje bezpieczną enklawę na urządzeniach mobilnych, oferuje pełną obsługę sieci testowej i obsługuje standard TokenScript.",
  "page-find-wallet-description-ambo": "Przejdź od razu do inwestowania i uzyskaj pierwszą inwestycję w ciągu kilku minut od pobrania aplikacji",
  "page-find-wallet-description-argent": "Jedno dotknięcie, aby zarabiać odsetki i inwestować; pożyczać, przechowywać i wysyłać. Posiadać.",
  "page-find-wallet-description-bitcoindotcom": "The Bitcoin.com Wallet now supports Ethereum! Buy, hold, send, and trade ETH using a fully non-custodial wallet trusted by millions.",
  "page-find-wallet-description-coinbase": "Bezpieczna aplikacja do przechowywania kryptowalut",
  "page-find-wallet-description-coinomi": "Coinomi is the oldest multi-chain, defi-ready, cross-platform wallet for bitcoin, altcoins & tokens - never hacked, with millions of users.",
  "page-find-wallet-description-coin98": "A Non-Custodial, Multi-Chain Wallet & DeFi Gateway",
  "page-find-wallet-description-dcent": "D'CENT Wallet is the über convenient multi-cryptocurrency wallet with built-in DApp browser for easy access to DeFi, NFT, and variety of services.",
  "page-find-wallet-description-dharma": "Dharma to Twój portal do DeFi. Nigdy więcej fraz seedów, żadnych rozszerzeń Chrome.",
  "page-find-wallet-description-enjin": "Nieprzenikniony, pełen funkcji i wygodny — zbudowany dla traderów, graczy i deweloperów",
  "page-find-wallet-description-fortmatic": "Access Ethereum apps from anywhere with just an email or phone number. No more browser extensions and seed phrases.",
  "page-find-wallet-description-gnosis": "Najbardziej zaufana platforma do przechowywania zasobów cyfrowych w Ethereum",
  "page-find-wallet-description-guarda": "Secure, feature-packed, non-custodial crypto wallet with support for over 50 blockchains. Easy stakings, exchange and purchase of crypto assets.",
  "page-find-wallet-description-hyperpay": "HyperPay is a multi-platform universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-imtoken": "imToken to łatwy i bezpieczny portfel cyfrowy, któremu zaufały miliony",
  "page-find-wallet-description-ledger": "Dbaj o bezpieczeństwo swoich aktywów z zachowaniem najwyższych standardów bezpieczeństwa",
  "page-find-wallet-description-linen": "Mobile smart contract wallet: earn yield, buy crypto, and participate in DeFi. Earn rewards and governance tokens.",
  "page-find-wallet-description-mathwallet": "MathWallet is a multi-platform (mobile/extension/web) universal crypto wallet that supports 50+ blockchains and 2000+ dApps.",
  "page-find-wallet-description-metamask": "Zacznij eksplorować aplikacje blockchain w kilka sekund. Zaufało nam ponad 1 milion użytkowników na całym świecie.",
  "page-find-wallet-description-monolith": "Jedyny na świecie samopowierniczy portfel połączony z kartą debetową Visa. Dostępne w Wielkiej Brytanii i UE i używane na całym świecie.",
  "page-find-wallet-description-multis": "Multis to konto kryptowalutowe przeznaczone dla firm. Dzięki Multis firmy mogą przechowywać z kontrolą dostępu, uzyskiwać odsetki od swoich oszczędności oraz usprawniać płatności i księgowość.",
  "page-find-wallet-description-mycrypto": "MyCrypto to interfejs do zarządzania wszystkimi Twoimi kontami. Zamieniaj, wysyłaj i kupuj kryptowaluty za pomocą portfeli takich jak MetaMask, Ledger, Trezor i innych.",
  "page-find-wallet-description-myetherwallet": "Darmowy interfejs po stronie klienta pomagający w interakcji z blockchainem Ethereum",
  "page-find-wallet-description-opera": "Built-in crypto wallet in Opera Touch on iOS and Opera for Android. The first major browser to integrate a crypto wallet, enabling seamless access to the emerging web of tomorrow (Web 3).",
  "page-find-wallet-description-pillar": "Portfel niepowierniczy, należący do społeczności z własną siecią płatności L2.",
  "page-find-wallet-description-portis": "Niestandardowy portfel blockchain, który sprawia, że aplikacje są proste dla wszystkich",
  "page-find-wallet-description-rainbow": "Lepszy dom dla Twoich zasobów Ethereum",
  "page-find-wallet-description-samsung": "Keep your valuables safe and secure with Samsung Blockchain.",
  "page-find-wallet-description-status": "Bezpieczna aplikacja do przesyłania wiadomości, portfel kryptograficzny i przeglądarka Web3 zbudowane w oparciu o najnowocześniejszą technologię",
  "page-find-wallet-description-tokenpocket": "TokenPocket: Bezpieczny i wygodny, wiodący na świecie portfel walut cyfrowych oraz portal do zdecentralizowanych aplikacji z obsługą wielu łańcuchów.",
  "page-find-wallet-description-bitkeep": "BitKeep is a decentralized multi-chain digital wallet, dedicated to providing safe and convenient one-stop digital asset management services to users around the world.",
  "page-find-wallet-description-torus": "Logowanie jednym kliknięciem do Web 3.0",
  "page-find-wallet-description-trezor": "Pierwszy i oryginalny portfel sprzętowy",
  "page-find-wallet-description-trust": "Trust Wallet to zdecentralizowany portfel kryptowalut na wiele monet. Kupuj kryptowaluty, eksploruj aplikacje, wymieniaj zasoby i nie tylko, zachowując kontrolę nad kluczami.",
  "page-find-wallet-description-unstoppable": "Unstoppable Wallet is an open-source, non-custodial storage solution known for its intuitive design and frictionless user experience. Natively integrates decentralized trading/exchange capabilities.",
  "page-find-wallet-description-zengo": "ZenGo to pierwszy bezkluczowy portfel kryptograficzny. W ZenGo nie ma kluczy prywatnych, haseł ani fraz początkowych, którymi można by zarządzać lub je stracić. Kupuj, handluj, zarabiaj i przechowuj Ethereum z niespotykaną prostotą i bezpieczeństwem",
  "page-find-wallet-description-walleth": "100% open source (GPLv3) and native Android Ethereum Wallet for your service since 2017. Connect to your favorite dapp via WalletConnect and use it directly with hardware wallets.",
  "page-find-wallet-description-safepal": "SafePal's wallet is a secure, decentralized, easy-to-use, and free application to manage more than 10,000 cryptocurrencies.",
  "page-find-wallet-dharma-logo-alt": "Logo Dharma",
  "page-find-wallet-enjin-logo-alt": "Logo Enjin",
  "page-find-wallet-Ethereum-wallets": "Portfele Ethereum",
  "page-find-wallet-explore-dapps": "Przeglądaj zdecentralizowane aplikacje",
  "page-find-wallet-explore-dapps-desc": "Portfele te są zaprojektowane tak, aby pomóc Ci połączyć się z decentralizowanymi aplikacjami Ethereum.",
  "page-find-wallet-feature-h2": "Wybierz funkcje portfela, na których Ci zależy",
  "page-find-wallet-fi-tools": "Dostęp do instrumentów finansowych",
  "page-find-wallet-fi-tools-desc": "Wypożycz, pożyczaj i zarabiaj odsetki bezpośrednio z portfela.",
  "page-find-wallet-following-features": "o następujących cechach:",
  "page-find-wallet-fortmatic-logo-alt": "Fortmatic logo",
  "page-find-wallet-gnosis-logo-alt": "Logo Gnosis Safe",
  "page-find-wallet-guarda-logo-alt": "Guarda logo",
  "page-find-wallet-hyperpay-logo-alt": "HyperPay logo",
  "page-find-wallet-image-alt": "Obraz główny Znajdź portfel",
  "page-find-wallet-imtoken-logo-alt": "logo imToken",
  "page-find-wallet-last-updated": "Ostatnia aktualizacja",
  "page-find-wallet-ledger-logo-alt": "Logo Ledger",
  "page-find-wallet-limits": "Ochrona przed ograniczeniami",
  "page-find-wallet-limits-desc": "Chroń swoje aktywa, ustalając limity, które zapobiegają wyczerpaniu konta.",
  "page-find-wallet-linen-logo-alt": "Linen logo",
  "page-find-wallet-listing-policy": "polityka wyświetlania",
  "page-find-wallet-mathwallet-logo-alt": "MathWallet logo",
  "page-find-wallet-meta-description": "Znajdź i porównaj portfele Ethereum w oparciu o pożądane funkcje.",
  "page-find-wallet-meta-title": "Znajdź portfel Ethereum",
  "page-find-wallet-metamask-logo-alt": "Logo MetaMask",
  "page-find-wallet-monolith-logo-alt": "Logo Monolith",
  "page-find-wallet-multis-logo-alt": "Logo Multis",
  "page-find-wallet-multisig": "Portfel z wieloma podpisami",
  "page-find-wallet-multisig-desc": "Dla dodatkowego bezpieczeństwa portfele z wieloma podpisami wymagają więcej niż jednego konta do autoryzacji niektórych transakcji.",
  "page-find-wallet-mycrypto-logo-alt": "Logo MyCrypto",
  "page-find-wallet-myetherwallet-logo-alt": "Logo MyEtherWallet",
  "page-find-wallet-new-to-wallets": "Nowy w portfelach? Oto przegląd na początek.",
  "page-find-wallet-new-to-wallets-link": "Portfele Ethereum",
  "page-find-wallet-not-all-features": "Żaden portfel nie ma tych wszystkich funkcji",
  "page-find-wallet-not-endorsements": "Portfele wymienione na tej stronie nie są oficjalnymi rekomendacjami i służą wyłącznie celom informacyjnym. Ich opisy zostały dostarczone przez same firmy zajmujące się portfelami. Dodajemy produkty do tej strony na podstawie kryteriów w naszym",
  "page-find-wallet-overwhelmed": "Portfele Ethereum poniżej. Przytłoczony? Spróbuj filtrować według funkcji.",
  "page-find-wallet-opera-logo-alt": "Opera logo",
  "page-find-wallet-pillar-logo-alt": "Logo filaru",
  "page-find-wallet-portis-logo-alt": "Logo Portis",
  "page-find-wallet-rainbow-logo-alt": "Logo Rainbow",
  "page-find-wallet-raise-an-issue": "zgłoś problem na GitHubie",
  "page-find-wallet-search-btn": "Szukaj wybranych funkcji",
  "page-find-wallet-showing": "Pokazuje ",
  "page-find-wallet-samsung-logo-alt": "Samsung Blockchain Wallet logo",
  "page-find-wallet-status-logo-alt": "Logo statusu",
  "page-find-wallet-swaps": "Zdecentralizowane wymiany tokenu",
  "page-find-wallet-swaps-desc": "Wymiana pomiędzy ETH i innymi tokenami bezpośrednio z Twojego portfela.",
  "page-find-wallet-title": "Znajdź portfel",
  "page-find-wallet-tokenpocket-logo-alt": "Logo TokenPocket",
  "page-find-wallet-bitkeep-logo-alt": "BitKeep logo",
  "page-find-wallet-torus-logo-alt": "Logo Torus",
  "page-find-wallet-trezor-logo-alt": "Logo Trezor",
  "page-find-wallet-trust-logo-alt": "Logo Trust",
  "page-find-wallet-safepal-logo-alt": "SafePal logo",
  "page-find-wallet-try-removing": "Spróbuj usunąć jedną lub dwie funkcje",
  "page-find-wallet-unstoppable-logo-alt": "Unstoppable logo",
  "page-find-wallet-use-wallet-desc": "Teraz, gdy masz portfel, sprawdź niektóre aplikacje Ethereum (dapps). Istnieją zdecentralizowane Aplikacje do finansów, mediów społecznościowych, gier i wielu innych kategorii.",
  "page-find-wallet-use-your-wallet": "Użyj swojego portfela",
  "page-find-wallet-voluem-desc": "Jeśli chcesz przechowywać dużo ETH, wybierz portfel, który pozwala na jednorazowy zakup ponad 2000 USD w ETH.",
  "page-find-wallet-volume": "Zakupy o dużym wolumenie",
  "page-find-wallet-we-found": "Znaleźliśmy",
  "page-find-wallet-withdraw": "Wypłata na konto bankowe",
  "page-find-wallet-withdraw-desc": "Możesz wypłacić swój ETH bezpośrednio na swoje konto bankowe bez przechodzenia przez giełdę.",
  "page-find-wallet-yet": "jeszcze",
  "page-find-wallet-zengo-logo-alt": "Logo ZenGo",
  "page-find-wallet-walleth-logo-alt": "WallETH logo",
  "page-stake-eth": "Stakuj ETH",
  "page-wallets-accounts-addresses": "Portfele, konta i adresy",
  "page-wallets-accounts-addresses-desc": "Warto rozumieć różnice między niektórymi kluczowymi terminami.",
  "page-wallets-accounts-ethereum-addresses": "An Ethereum account has an Ethereum address, like an inbox has an email address. You can use this to send funds to an account.",
  "page-wallets-alt": "Ilustracja robota ze skarbcem w charakterze ciała, przedstawiająca portfel Ethereum",
  "page-wallets-ethereum-account": "Konto Ethereum",
  "page-wallets-blog": "Blog Coinbase",
  "page-wallets-bookmarking": "Dodaj swój portfel do zakładek",
  "page-wallets-bookmarking-desc": "Jeśli korzystasz z portfela internetowego, dodaj witrynę do zakładek, aby chronić się przed oszustwami typu phishing.",
  "page-wallets-cd": "Fizyczne portfele sprzętowe, które pozwalają Ci na przechowywanie kryptowalut w trybie offline – bardzo bezpieczne",
  "page-wallets-converted": "Kryptowaluta przekonwertowana?",
  "page-wallets-converted-desc": "Jeśli chcesz utrzymać poważną wartość, zalecamy portfel sprzętowy, ponieważ są one najbezpieczniejsze. Lub portfel z alertami o oszustwach i limitami wypłat.",
  "page-wallets-curious": "Ciekawy kryptowalut?",
  "page-wallets-curious-desc": "Jeśli dopiero zaczynasz przygodę z kryptowalutą i po prostu chcesz to poczuć, polecamy coś, co da Ci możliwość zapoznania się z aplikacjami Ethereum lub kupienia pierwszego ETH bezpośrednio z portfela.",
  "page-wallets-desc-2": "Potrzebujesz portfela, aby wysyłać środki i zarządzać ETH.",
  "page-wallets-desc-2-link": "Więcej na temat ETH",
  "page-wallets-desc-3": "Twój portfel to tylko narzędzie do zarządzania kontem Ethereum. Oznacza to, że w każdej chwili możesz zmieniać dostawców portfeli. Wiele portfeli umożliwia również zarządzanie kilkoma kontami Ethereum z jednej aplikacji.",
  "page-wallets-desc-4": "Dzieje się tak dlatego, że portfele nie przechowują twoich funduszy. Są tylko narzędziem do zarządzania tym, co naprawdę należy do Ciebie.",
  "page-wallets-description": "Portfele Ethereum to aplikacje, które umożliwiają interakcję z kontem Ethereum. Pomyśl o tym jak o aplikacji do bankowości internetowej - bez banku. Twój portfel umożliwia odczytywanie salda, wysyłanie transakcji i łączenie się z aplikacjami.",
  "page-wallets-desktop": "Aplikacje stacjonarne, jeśli wolisz zarządzać środkami za pośrednictwem MacOS, Windows lub linux",
  "page-wallets-ethereum-wallet": "Portfel",
  "page-wallets-explore": "Odkryj Ethereum",
  "page-wallets-features-desc": "Możemy pomóc Ci wybrać Twój portfel w oparciu o funkcje, których potrzebujesz.",
  "page-wallets-features-title": "Wolisz wybierać na podstawie funkcji?",
  "page-wallets-find-wallet-btn": "Znajdź portfel",
  "page-wallets-find-wallet-link": "Znajdź portfel",
  "page-wallets-get-some": "Weź trochę ETH",
  "page-wallets-get-some-alt": "Ilustracja przedstawiająca dłoń tworzącą logo ETH z klocków lego",
  "page-wallets-get-some-btn": "Weź trochę ETH",
  "page-wallets-get-some-desc": "ETH to natywna kryptowaluta Ethereum. Aby korzystać z aplikacji Ethereum, będziesz potrzebować trochę ETH w portfelu.",
  "page-wallets-get-wallet": "Wybierz portfel",
  "page-wallets-get-wallet-desc": "Do wyboru jest wiele różnych portfeli. Chcemy pomóc Ci wybrać najlepszy dla Ciebie.",
  "page-wallets-get-wallet-desc-2": "Pamiętaj: ta decyzja nie jest na zawsze - Twoje konto Ethereum nie jest powiązane z dostawcą portfela.",
  "page-wallets-how-to-store": "Jak przechowywać aktywa cyfrowe na Ethereum",
  "page-wallets-keys-to-safety": "Klucze do zachowania bezpieczeństwa kryptowalut",
  "page-wallets-manage-funds": "Aplikacja do zarządzania Twoimi środkami",
  "page-wallets-manage-funds-desc": "Twój portfel pokazuje salda, historię transakcji i umożliwia wysyłanie / otrzymywanie środków. Niektóre portfele mogą oferować więcej.",
  "page-wallets-meta-description": "Co musisz wiedzieć, aby korzystać z portfeli Ethereum.",
  "page-wallets-meta-title": "Portfele Ethereum",
  "page-wallets-mobile": "Aplikacje mobilne, dzięki którym Twoje środki są dostępne z dowolnego miejsca",
  "page-wallets-more-on-dapps-btn": "Więcej na temat aplikacji zdecentralizowanych",
  "page-wallets-most-wallets": "Większość portfeli umożliwia wygenerowanie konta Ethereum. Więc nie potrzebujesz go przed pobraniem portfela.",
  "page-wallets-protecting-yourself": "Chroń siebie i swoje fundusze",
  "page-wallets-seed-phrase": "Zapisz swoją frazę seed",
  "page-wallets-seed-phrase-desc": "Portfele często zawierają frazę seed, którą musisz zapisać w bezpiecznym miejscu. Tylko w ten sposób odzyskasz swój portfel.",
  "page-wallets-seed-phrase-example": "Tutaj jest przykład:",
  "page-wallets-seed-phrase-snippet": "there aeroplane curve vent formation doge possible product distinct under spirit lamp",
  "page-wallets-seed-phrase-write-down": "Nie przechowuj go na komputerze. Zapisz go i przechowuj w bezpiecznym miejscu.",
  "page-wallets-slogan": "Klucz do Twojej cyfrowej przyszłości",
  "page-wallets-stay-safe": "Jak pozostać bezpiecznym",
  "page-wallets-stay-safe-desc": "Portfele zmieniają sposób myślenia. Swoboda finansowa i możliwość dostępu do funduszy i korzystania z nich w dowolnym miejscu wiąże się z pewną odpowiedzialnością - w przypadku kryptowalut nie ma wsparcia klienta.",
  "page-wallets-subtitle": "Portfele umożliwiają dostęp do funduszy i aplikacji Ethereum. Tylko Ty powinieneś mieć dostęp do swojego portfela.",
  "page-wallets-take-responsibility": "Weź odpowiedzialność za swoje własne środki",
  "page-wallets-take-responsibility-desc": "Scentralizowane giełdy połączą Twój portfel z nazwą użytkownika i hasłem, które możesz odzyskać w tradycyjny sposób. Pamiętaj tylko, że musisz ufać tej giełdzie i powierzyć swoje fundusze. Jeśli ta firma zostanie zaatakowana lub upadnie, Twoje fundusze są zagrożone.",
  "page-wallets-tips": "Więcej wskazówek na temat bezpieczeństwa",
  "page-wallets-tips-community": "Od społeczności",
  "page-wallets-title": "Portfele Ethereum",
  "page-wallets-triple-check": "Sprawdź wszystko trzy razy",
  "page-wallets-triple-check-desc": "Pamiętaj, że transakcji nie można cofnąć, a portfeli nie można łatwo odzyskać, więc zachowaj ostrożność.",
  "page-wallets-try-dapps": "Wypróbuj zdecentralizowane aplikacje (dapps)",
  "page-wallets-try-dapps-alt": "Ilustracja przedstawiająca współpracujących ze sobą członków społeczności Ethereum",
  "page-wallets-try-dapps-desc": "Aplikacje zdecentralizowane (dapps) są aplikacjami zbudowanymi na Ethereum. Są tańsze, bardziej sprawiedliwe i bardziej przyjazne dla Twoich danych niż większość tradycyjnych aplikacji.",
  "page-wallets-types": "Typy portfeli",
  "page-wallets-web-browser": "Portfele internetowe, które pozwalają na interakcję z kontem za pośrednictwem przeglądarki internetowej",
  "page-wallets-whats-a-wallet": "Czym jest portfel Ethereum?",
  "page-wallets-your-ethereum-account": "Twoje konto Ethereum",
  "page-wallets-your-ethereum-account-desc": "Twój portfel to okno na konto Ethereum - Twoje saldo konta, historia transakcji i inne. Możesz jednak zmienić dostawców portfela w dowolnym momencie.",
  "page-wallets-your-login": "Twój login dla aplikacji Ethereum",
  "page-wallets-your-login-desc": "Twój portfel pozwala Ci połączyć się z dowolną zdecentralizowaną aplikacją przy użyciu konta Ethereum. To jest jak logowanie, którego możesz używać w wielu aplikacjach.",
  "page-what-is-ethereum-101": "Ethereum 101",
  "page-what-is-ethereum-101-desc": "Ethereum to technologia, która pozwala wysyłać kryptowalutę do każdego, za niewielką opłatą. Napędza ona także aplikacje, z których każdy może korzystać i których nikt nie może zlikwidować.",
  "page-what-is-ethereum-101-desc-2": "Ethereum opiera się na innowacjach Bitcoina, z kilkoma dużymi różnicami.",
  "page-what-is-ethereum-101-desc-3": "Oba sposoby pozwalają na korzystanie z pieniądza cyfrowego bez dostawców płatności lub banków. Ethereum jest jednak programowalne, więc możesz użyć go również do wielu innych zasobów cyfrowych – nawet do bitcoinów!",
  "page-what-is-ethereum-101-desc-4": "Oznacza to również, że Ethereum to więcej niż tylko płatności. Jest to rynek usług finansowych, gier i aplikacji, które nie mogą kraść Twoich danych ani Cię cenzurować.",
  "page-what-is-ethereum-101-italic": "globalny programowalny blockchain.",
  "page-what-is-ethereum-101-strong": "To jest ",
  "page-what-is-ethereum-accessibility": "Ethereum jest otwarte dla wszystkich.",
  "page-what-is-ethereum-adventure": "Wybierz swoją przygodę!",
  "page-what-is-ethereum-alt-img-bazaar": "Ilustracja osoby przyglądającej się bazarowi ma reprezentować Ethereum",
  "page-what-is-ethereum-alt-img-comm": "Ilustracja współdziałających członków społeczności Ethereum",
  "page-what-is-ethereum-alt-img-lego": "Ilustracja ręki układającej logo Ethereum wykonane z klocków lego",
  "page-what-is-ethereum-alt-img-social": "Ilustracja postaci w przestrzeni publicznej poświęconej Ethereum z dużym logo ETH",
  "page-what-is-ethereum-banking-card": "Bankowość dla wszystkich",
  "page-what-is-ethereum-banking-card-desc": "Nie każdy ma dostęp do usług finansowych. Jedyne, czego potrzebujesz by zyskać dostęp do Ethereum, jego produktów kredytowych i oszczędnościowych, to połączenie internetowe.",
  "page-what-is-ethereum-build": "Stwórz coś z Ethereum",
  "page-what-is-ethereum-build-desc": "Jeśli chcesz spróbować budować z Ethereum, przeczytaj naszą dokumentację, wypróbuj kilka samouczków lub sprawdź narzędzia, których potrzebujesz, aby rozpocząć.",
  "page-what-is-ethereum-censorless-card": "Odporne na cenzurę",
  "page-what-is-ethereum-censorless-card-desc": "Żaden rząd ani przedsiębiorstwo nie ma kontroli nad Ethereum. Ta decentralizacja sprawia, że nikt nie może powstrzymać Cię od otrzymywania płatności lub korzystania z usług na Ethereum.",
  "page-what-is-ethereum-comm-desc": "Nasza społeczność obejmuje ludzi ze wszystkich środowisk, w tym artystów, kryptoanarchistów, firmy notowane na Fortune 500, a teraz także i Ciebie. Dowiedz się jak możesz zaangażować się już dziś.",
  "page-what-is-ethereum-commerce-card": "Gwarancje handlowe",
  "page-what-is-ethereum-commerce-card-desc": "Ethereum tworzy bardziej równe reguły gry. Klienci mają bezpieczną, wbudowaną gwarancję, że środki zmienią posiadacza tylko wtedy, gdy spełni się to, co zostało uzgodnione. Nie potrzebujesz prestiżu dużej firmy, aby prowadzić działalność.",
  "page-what-is-ethereum-community": "Społeczność Ethereum",
  "page-what-is-ethereum-compatibility-card": "Przez kompatybilność do zwycięstwa",
  "page-what-is-ethereum-compatibility-card-desc": "Lepsze produkty i doświadczenia są budowane przez cały czas, ponieważ produkty Ethereum są domyślnie kompatybilne. Firmy mogą budować na swoich wzajemnych osiągnięciach.",
  "page-what-is-ethereum-dapps-desc": "Produkty i usługi działające na Ethereum. Istnieją aplikacje dla finansów, pracy, mediów społecznościowych, gier i innych – poznaj aplikacje naszej cyfrowej przyszłości.",
  "page-what-is-ethereum-dapps-img-alt": "Ilustracja pieseła korzystającego z aplikacji Ethereum na komputerze",
  "page-what-is-ethereum-dapps-title": "Zdecentralizowane aplikacje Ethereum (dappy)",
  "page-what-is-ethereum-desc": "Fundament naszej cyfrowej przyszłości",
  "page-what-is-ethereum-explore": "Odkryj Ethereum",
  "page-what-is-ethereum-get-started": "Najlepszym sposobem, aby dowiedzieć się więcej jest pobranie portfela, pozyskanie ETH i wypróbowanie aplikacji zdecentralizowanych Ethereum.",
  "page-what-is-ethereum-in-depth-description": "Ethereum to otwarty dostęp do cyfrowych pieniędzy i przyjaznych dla danych usług dla wszystkich – niezależnie od zaplecza czy lokalizacji. To technologia zbudowana przez społeczność na eterze (ETH) oraz tysiące aplikacji, z których możesz dziś korzystać.",
  "page-what-is-ethereum-internet-card": "Bardziej prywatny Internet",
  "page-what-is-ethereum-internet-card-desc": "Nie musisz podawać wszystkich swoich danych osobowych, aby używać aplikacji Ethereum. Ethereum buduje gospodarkę opartą na wartości, a nie na nadzorze.",
  "page-what-is-ethereum-meet-comm": "Poznaj społeczność",
  "page-what-is-ethereum-meta-description": "Poznaj Ethereum, dowiedz się co robi i jak wypróbować je dla siebie.",
  "page-what-is-ethereum-meta-title": "Czym jest Ethereum?",
  "page-what-is-ethereum-native-alt": "Symbol eteru (ETH)",
  "page-what-is-ethereum-native-crypto": "Natywna kryptowaluta Ethereum i odpowiednik bitcoina. Możesz używać ETH w aplikacjach Ethereum lub do przesyłania wartości do znajomych i rodziny.",
  "page-what-is-ethereum-native-img-alt": "Ilustracja robota z sejfem jako tułowiem używana do reprezentowania portfeli Ethereum",
  "page-what-is-ethereum-native-title": "ETH",
  "page-what-is-ethereum-p2p-card": "Sieć typu peer-to-peer",
  "page-what-is-ethereum-p2p-card-desc": "Ethereum pozwala na przesyłanie pieniędzy lub zawieranie umów bezpośrednio z innymi. Nie musisz korzystać z firm pośredniczących.",
  "page-what-is-ethereum-singlecard-desc": "Jeśli interesuje Cię blockchain i techniczna strona Ethereum, to mamy coś dla Ciebie.",
  "page-what-is-ethereum-singlecard-link": "Jak działa Ethereum",
  "page-what-is-ethereum-singlecard-title": "Jak działa Ethereum",
  "page-what-is-ethereum-start-building-btn": "Zacznij tworzenie",
  "page-what-is-ethereum-title": "Co to jest Ethereum?",
  "page-what-is-ethereum-tools-needed": "Wszystko, czego potrzebujesz, aby zacząć to portfel.",
  "page-what-is-ethereum-try": "Wypróbuj Ethereum",
  "page-what-is-ethereum-tryit": "A więc przejdź do bazaru i spróbuj...",
  "page-what-is-ethereum-wallets": "Portfele",
  "page-what-is-ethereum-wallets-desc": "Sposób zarządzania posiadanym ETH i Twoim kontem Ethereum. Będziesz potrzebował portfela, aby rozpocząć – pomożemy Ci wybrać jeden.",
  "page-what-is-ethereum-welcome": "Witamy w Ethereum",
  "page-what-is-ethereum-welcome-2": "Mamy nadzieję, że zostaniesz.",
  "page-what-is-ethereum-defi-title": "Decentralized finance (DeFi)",
  "page-what-is-ethereum-defi-description": "A more open financial system that gives you more control over your money and unlocks new possibilities.",
  "page-what-is-ethereum-defi-alt": "An Eth logo made of lego bricks.",
  "page-what-is-ethereum-nft-title": "Non-fungible tokens (NFTs)",
  "page-what-is-ethereum-nft-description": "A way to represent unique items as Ethereum assets that can be traded, used as proof of ownership, and create new opportunities for creators.",
  "page-what-is-ethereum-nft-alt": "An Eth logo being displayed via hologram.",
  "page-what-is-ethereum-dao-title": "Decentralized autonomous organisations (DAOs)",
  "page-what-is-ethereum-dao-description": "A new way to collaborate and set up online communities with shared goals and pooled funds.",
  "page-what-is-ethereum-dao-alt": "A representation of a DAO voting on a proposal.",
  "page-what-is-ethereum-use-cases-title": "Discover Ethereum use cases",
  "page-what-is-ethereum-use-cases-subtitle": "Ethereum has led to the creation of new products and services that can improve different areas of our lives.",
  "page-what-is-ethereum-use-cases-subtitle-two": "We're still in the early stages but there's a lot to be excited about.",
  "template-usecase-dropdown-defi": "Decentralized finance (DeFi)",
  "template-usecase-dropdown-nft": "Non-fungible tokens (NFTs)",
  "template-usecase-dropdown-dao": "Decentralized autonomous organisations (DAOs)",
  "template-usecase-dropdown": "Ethereum use cases",
  "template-usecase-banner": "Uses of Ethereum are always developing and evolving. Add any info you think will make things clearer or more up to date.",
  "template-usecase-edit-link": "Edit page",
  "template-usecase-dropdown-aria": "Use case dropdown menu",
  "banner-staking-1": "Staking już tu jest! Jeśli szukasz informacji odnośnie do stakowania swoich ETH",
  "banner-staking-2": "potwierdź adres kontraktu depozytowego",
  "docking": "Dokowanie",
  "page-eth2-vision-security-desc-9": "pozwala nam losowo przypisać walidatorów do różnych odłamków — to praktycznie uniemożliwia walidatorom zmowę poprzez atakowanie konkretnego odłamka. Udostępnianie nie jest tak bezpieczne w łańcuchu bloków proof-of-work, ponieważ górnicy nie mogą być w ten sposób kontrolowani przez protokół.",
  "page-index-sections-developers-desc": "Dowiedz się więcej o technologii leżącej u podstaw Ethereum i powiązanych aplikacjach, aby szybko rozpocząć pracę.",
  "page-index-sections-developers-image-alt": "Ilustracja ręki układającej glif Ethereum wykonany z cegiełek lego",
  "page-index-sections-developers-link-text": "Zacznij tworzenie",
  "page-index-sections-developers-title": "Deweloperzy",
  "page-index-sections-enterprise-desc": "Zobacz, w jaki sposób Ethereum może tworzyć nowe modele biznesowe, zmniejszać koszty i zabezpieczać działalność na potrzeby rozwoju w przyszłości.",
  "page-index-sections-enterprise-image-alt": "Ilustracja grupy ludzi pracującej nad projektem Ethereum siedzących wokół laptopa",
  "page-index-sections-enterprise-link-text": "Ethereum for Enterprise",
  "page-index-sections-enterprise-title": "Enterprise",
  "page-index-sections-individuals-desc": "Poznaj Ethereum, Ether, wallety, tokeny i wiele więcej, dzięki czemu możesz zacząć korzystać z aplikacji Ethereum.",
  "page-index-sections-individuals-image-alt": "Ilustracja psa Pieseła siedzącego przy komputerze",
  "page-index-sections-individuals-link-text": "Pierwsze kroki z Ethereum",
  "page-index-sections-individuals-title": "Więcej informacji o Ethereum",
  "page-index-subtitle": "Dzięki Ethereum, możesz tworzyć kod który pozwala utrzymać cyfrową jakość, działa dokładnie tak, jak został zaprogramowany i jest dostępny na całym świecie.",
  "page-find-wallet-authereum-logo-alt": "Logo Autherum",
  "page-find-wallet-description-authereum": "Bez pobierania, bez fraz seedów. Dowolna przeglądarka, o każdej porze, mobilna lub stacjonarna",
  "page-wallets-accounts-addresses-desc-2": "jest podmiotem, który może wysyłać transakcje i ma saldo.",
  "page-wallets-accounts-has": "Konto Ethereum ma",
  "page-wallets-an": "An",
  "page-wallets-ethereum-addresses": "Adres Ethereum",
  "page-wallets-ethereum-addresses-2": ", podobnie jak skrzynka odbiorcza ma adres e-mail. Możesz użyć tego do wysyłania środków na konto.",
  "page-wallets-ethereum-wallet-2": "to produkt, który umożliwia zarządzanie kontem Ethereum, na przykład przeglądanie salda konta, wysyłanie transakcji i nie tylko."
}
